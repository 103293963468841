import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { Link } from "react-router-dom";

import "./ProductView.css";


import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "axios";

import {  useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'



export function Editpro(props) {
  useEffect(() => {
    props.Auth()
    }, [])
  let [searchParams, setSearchParams] = useSearchParams();

  function change_image(e) {
    var img = document.getElementById("main-image");

    img.src = e.target.src;
   
  }
  //    document.addEventListener("DOMContentLoaded", function(event) {

  //     });

  let navigate = useNavigate()



  const [first, setfirst] = useState(false);
  const [image, setimage] = useState(false);
  const [ima, setima] = useState(false);

  var idd = searchParams.get("id");


  useEffect(() => {
    axios
      .post("/api/ActiveUser/Getproductmy", {
        idd,
      })
      .then((res) => {
        setfirst(res.data[0]);
        console.log(res.data[0]);
        setimage(res.data[0].File);
        setima(res.data[0].id);

        setValues({
          ...valuessss,
          name: res.data[0].name,
          brand: res.data[0].brand,
          mrp: res.data[0].mrp,
          category: res.data[0].category,
          subcategory: res.data[0].subcategory,
          price: res.data[0].price,
          discription: res.data[0].discription,
          speciality: res.data[0].speciality,
          image: res.data[0].image,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [valuessss, setValues] = useState({
    name: "",
    brand: "",
    mrp: "",
    category: "",
    subcategory: "",
    price: "",
    discription: "",
    speciality: "",
    image: "",
  });

  const [error, setError] = useState("");

  const [Submit, setSubmit] = useState(false);
  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [loading, setloading] = useState(false)
  var data;
  const [datas, setDatas] = useState(false);
  const [value, setValue] = useState(false);

  useEffect(() => {
    var values = Object.values(value);
    data = values.length
      ? values.map((pos) => {
          if (pos.lastModified) {
            return (
              <img
                key={pos.lastModified}
                width="70"
                src={URL.createObjectURL(pos)}
                alt="..."
              />
            );
          }
        })
      : null;
    setDatas(data);
    setValues({ ...valuessss, image: value });
  }, [value]);

  const sabari = (e) => {
    console.log(e.target.value);
    var anadhan;
    setValues({ ...valuessss, category: e.target.value });
    cat.forEach((element) => {
      console.log(element._id);
      if (element._id === e.target.value) {
        anadhan = element.sub.map((elements) => {
          return <option value={elements._id}>{elements.sub}</option>;
        });
      } else {
      }
    });
    console.log(anadhan);
    setSubcategory(anadhan);
  };

  useEffect(() => {
    axios
      .get("/api/api/getprocategory")
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setCat(res.data);
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  }, []);

  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
    } else {
      console.log("nothing");
    }
  }, [cat]);

  function validation() {
    if (valuessss.name.length > 0) {
      setError("");
    } else {
      return setError("Enter a Name");
    }

    if (valuessss.brand.length > 0) {
      setError("");
    } else {
      return setError("Enter a Brand Name");
    }

    if (valuessss.category.length > 0) {
      setError("");
    } else {
      return setError("Select the Main category");
    }

    if (valuessss.subcategory.length > 0) {
      setError("");
    } else {
      return setError("Select the sub category");
    }

    if (valuessss.mrp.length > 0) {
      setError("");
    } else {
      return setError("Enter a MRP");
    }

    if (valuessss.price.length > 0) {
      setError("");
    } else {
      return setError("Enter a Selling Price");
    }

    // if(valuessss.image.length>0){

    //   setError('')
    // }
    // else{
    //    return setError('Upload a photo')
    // }

    if (valuessss.discription.length > 0) {
      setError("");
    } else {
      return setError("Enter a Product discription");
    }

    if (valuessss.speciality.length > 0) {
      setError("");
    } else {
      return setError("Enter a Speciality ");
    }

    return true;
  }
  const formData = new FormData();
  const submit = (e) => {
    console.log(valuessss);

    e.preventDefault();
    var status = false;
    setSubmit(true);
    status = validation();
    if (status == true) {
      
      setloading(true)

    formData.delete("data");
    formData.delete("file");
    var sab = valuessss.image;
    console.log(typeof sab);
if(sab){
  for (var i = 0; i < sab.length; i++) {
    console.log(sab[i]);
    formData.append("file", sab[i]);
  }
}
  

    valuessss.image = "";
    var dom = JSON.stringify(valuessss);
    formData.append("data", dom);
    var deleted = JSON.stringify(Deleted);
    formData.append("delete", deleted);
    var id = JSON.stringify(first._id);
    formData.append("id", id);
    var imo = JSON.stringify(image);
    formData.append("images", imo);
    axios
      .post("/api/ActiveUser/UpdateProduct", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setloading(false)
        console.log(res);
        if (res.status === 200) {
          console.log("Succesfully posed");
          navigate('/home/My_product')
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        setloading(false)
        console.log(err);
      });
  };
}

  useEffect(() => {
    if (Submit) {
      validation();
    }
  }, [valuessss]);
  const [Deleted, setDeleted] = useState([]);
  const deleteMe = (e, name) => {
    Deleted.push(name);
    document.getElementById("cc" + name).classList.add("d-none");
  };
  function test() {
    console.log(Deleted);
  }
  return (
    <div className="container containerkefnek justify-content-center ">
      <>
        <div className="card-body px-sm-4 px-0">
          <div className="row justify-content-center round">
            <div className="col-lg-10 col-md-12 ">
              <div className="card shadow-lg card-1">
                <div className="card-body inner-card">
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="first-name">Product Name</label>
                        <input
                          type="text"
                          className="form-control eofeojfoeinput"
                          id="first-name"
                          placeholder=""
                          value={valuessss.name}
                          onChange={(e) =>
                            setValues({ ...valuessss, name: e.target.value })
                          }
                        />{" "}
                      </div>
                      <div className="form-group">
                        {" "}
                        <label htmlFor="Mobile-Number">Brand Name</label>{" "}
                        <input
                          type="text"
                          className="form-control eofeojfoeinput"
                          id="Mobile-Number"
                          placeholder=""
                          value={valuessss.brand}
                          onChange={(e) =>
                            setValues({ ...valuessss, brand: e.target.value })
                          }
                        />{" "}
                      </div>
                      <div className="form-group">
                        <label htmlFor="brand-name">MRP</label>
                        <input
                          type="number"
                          className="form-control eofeojfoeinput"
                          id="brand-name"
                          placeholder="In INR"
                          value={valuessss.mrp}
                          onChange={(e) =>
                            setValues({ ...valuessss, mrp: e.target.value })
                          }
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <div className="form-group ">
                        <label htmlFor="first-name">Selling Price</label>
                        <input
                          type="number"
                          className="form-control eofeojfoeinput"
                          id="first-name"
                          placeholder="In INR"
                          value={valuessss.price}
                          onChange={(e) =>
                            setValues({ ...valuessss, price: e.target.value })
                          }
                        />{" "}
                      </div>
                      <div className="form-group">
                        {" "}
                        <label htmlFor="inputEmail4">Category</label>{" "}
                        <select
                          className="form-control"
                          value={valuessss.category}
                          onChange={(e) => sabari(e)}
                        >
                          <option value="" disabled selected>
                            Select your category
                          </option>
                          {category ? category : ""}
                        </select>{" "}
                      </div>
                      <div className="form-group mt-3">
                        {" "}
                        <label htmlFor="inputEmail4">Sub Category</label>{" "}
                        <select
                          className="form-control"
                          value={valuessss.subcategory}
                          onChange={(e) =>
                            setValues({
                              ...valuessss,
                              subcategory: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled selected>
                            Select your subcategory
                          </option>
                          {subcategory ? subcategory : ""}
                        </select>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10 col-12">
                      <div className="form-group files">
                        <label className="my-auto">Upload Photo </label> <br />
                        {/* <span>* You can Upload upto 4 photos</span> */}
                        <input
                          id="file"
                          type="file"
                          accept="image/*"
                          multiple
                          className="form-control eofeojfoeinput"
                          onChange={(e) => setValue(e.target.files)}
                        />
                      </div>
                    </div>
                    <div className="text-center"> {datas ? datas : null} </div>
                    <hr />

                    <center>
                      <h5>existing images</h5>
                    </center>
                    <br />
                    <div className="text-center">
                      {image
                        ? image.map((element) => {
                            return (
                              <span id={"cc" + element.filename}>
                                {" "}
                                <img
                                  id=""
                                  src={
                                   
                                    element.filelink
                                  }
                                  width="80px"
                                />
                                <AiOutlineCloseCircle
                                  onClick={(e) => deleteMe(e, element.filename)}
                                  size="30"
                                />
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10 col-12">
                      <div className="form-group">
                        {" "}
                        <label htmlFor="exampleFormControlTextarea2">
                          Product Description
                        </label>{" "}
                        <textarea
                          className="form-control text"
                          id="exampleFormControlTextarea2"
                          rows="5"
                          value={valuessss.discription}
                          onChange={(e) =>
                            setValues({
                              ...valuessss,
                              discription: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      <div className="form-group mt-2">
                        {" "}
                        <label htmlFor="exampleFormControlTextarea2">
                          Speciality of the product (Why should customer choose
                          this product)
                        </label>{" "}
                        <textarea
                          className="form-control text"
                          id="exampleFormControlTextarea2"
                          rows="5"
                          value={valuessss.speciality}
                          onChange={(e) =>
                            setValues({
                              ...valuessss,
                              speciality: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      <div className="row justify-content-end mb-5">
                        <h6 className="mt-3">{error ? error : ""}</h6>
                        <div className="col-lg-4 col-auto ">
                        {!loading &&  <button
                            onClick={(e) => submit(e)}
                            className="btn btnehfi btn-pur btn-block"
                          >
                            <small className="font-weight-bold">
                              Update Product
                            </small>
                          </button>}

                          {loading && 
                          <button   className="btn btnehfi btn-pur btn-block" type="button" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Updating...
                      </button>
                      }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
