import React, { useEffect, useState } from "react";

export const Timer = (props) => {
const [Seconds, setSeconds] = useState(props.seconds)
const [Minutes, setMinutes] = useState(props.minutes)
const [Trigger, setTrigger] = useState()

const run = () => {
  setTrigger(setInterval(() => {
    setSeconds((pre) => pre - 1)
    console.log(Seconds)
  }, 1000))
}

useEffect(() => {
  run()
}, [])


useEffect(() => {
  if (Seconds === 0 && Minutes > 0) {
    setMinutes(Minutes - 1)
    setSeconds(59)
  }
  if (Seconds === 0 && Minutes === 0) {
    if (props.callback) {
      props.callback()
    }
    clear()
  }
}, [Seconds])



const clear = () => {
  clearInterval(Trigger)
}

return (
  <> 
    {/* <button type="button" onClick={run} >Run</button> */}
    {/* <button type="button" onClick={clear} >Clear</button> */}
    {Minutes}:{Seconds}
  </>
)
}

export const TimeSelectBox = ({type}) => {
return (
  <>
    <option value="" disabled selected>Time</option>
    <option value="Nil">Nil</option>
    <option value="4 AM">4 AM</option>
    <option value="4.30 AM">4.30 AM</option>
    <option value="5 AM">5 AM</option>
    <option value="5.30 AM">5.30 AM</option>
    <option value="6 AM">6 AM</option>
    <option value="6.30 AM">6.30 AM</option>
    <option value="7 AM">7 AM</option>
    <option value="7.30 AM">7.30 AM</option>
    <option value="8 AM">8 AM</option>
    <option value="8.30 AM">8.30 AM</option>
    <option value="9 AM">9 AM</option>
    <option value="9.30 AM">9.30 AM</option>
    <option value="10 AM">10 AM</option>
    <option value="10.30 AM">10.30 AM</option>
    <option value="11 AM">11 AM</option>
    <option value="11.30 AM">11.30 AM</option>
    <option value="12 PM">12 PM</option>
    <option value="12.30 PM">12.30 PM</option>
    <option value="1 PM">1 PM</option>
    <option value="1.30 PM">1.30 PM</option>
    <option value="2 PM">2 PM</option>
    <option value="2.30 PM">2.30 PM</option>
    <option value="3 PM">3 PM</option>
    <option value="3.30 PM">3.30 PM</option>
    <option value="4 PM">4 PM</option>
    <option value="4.30 PM">4.30 PM</option>
    <option value="5 PM">5 PM</option>
    <option value="5.30 PM">5.30 PM</option>
    <option value="6 PM">6 PM</option>
    <option value="6.30 PM">6.30 PM</option>
    <option value="7 PM">7 PM</option>
    <option value="7.30 PM">7.30 PM</option>
    <option value="8 PM">8 PM</option>
    <option value="8.30 PM">8.30 PM</option>
    <option value="9 PM">9 PM</option>
    <option value="9.30 PM">9.30 PM</option>
    <option value="10 PM">10 PM</option>
    <option value="10.30 PM">10.30 PM</option>
    <option value="11 PM">11 PM</option>
    <option value="11.30 PM">11.30 PM</option>
    <option value="12 AM">12 AM</option>
    <option value="12.30 AM">12.30 AM</option>
    <option value="1 AM">1 AM</option>
    <option value="1.30 AM">1.30 AM</option>
    <option value="2 AM">2 AM</option>
    <option value="2.30 AM">2.30 AM</option>
    <option value="3 AM">3 AM</option>
    <option value="3.30 AM">3.30 AM</option>
  </>
)
}