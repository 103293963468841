

import React, { useState, useEffect, useRef } from "react";
import axios, { Axios } from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./ChatPrevious.css";
import Moment from 'moment';
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function ShoutOut() {

  const notify = (message,sab) =>{
    if(sab){
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }else{
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    
  }

  let navigate = useNavigate();

  useEffect(() => {

    ShoutOut()

  }, [])


  const [shoutOut, setshoutOut] = useState()

  const ShoutOut = async () => {
    var id = localStorage.getItem('Sub_cat')
    console.log(id)
    var data = await axios.post('/api/User/ShoutOutView', { id })
    if (data) {
      setshoutOut(data.data)
      console.log(data.data)
    }

  };
  const shoutCommunicate = async (id, name, mess) => {
    console.log(id)
    try {
      let dataSend = {
        id, name, mess
      }
      var tata = await axios.post('/api/User/shoutCommunicate', { dataSend })
      if (tata.status === 200) {
        navigate('/cubicles')
      } else if (tata.status === 201) {
        navigate('/cubicles')
      }
    } catch (err) {
      // toast
    }
  }
const BlockMember=(id)=>{
console.log(id)
axios.post("/api/User/BlockShoutOut",{id}).then((res)=>{
  console.log(res.data)
  if (res.status==200) {
    ShoutOut()
    notify("Blocked",true);
  }
}).catch((err)=>{
  console.log(err)
  notify("Something went wrong",false);

})
}


  return (
    <div className="container  egfiegifgiegfiuegdbwlbclwu">
      <div className="d-flex justify-content-end p-4">
      <div style={{ "alignItems": "center", "display": "flex" }}> <Link to={"/shoutOut_blocked_list"}><button className="btn pro-spaces-button2" >View blocked members</button></Link> </div>
      </div>
      <div className=" bg-light ">
        <div className="d-flex justify-content-between">
          <span className="  p-4 m-0  ">ENQUIRIES</span>
        </div>
        <br></br>
        <hr className="my-2 mt-1" />
        <div className="p-5 pt-2">
          {shoutOut ?
            shoutOut.length > 0 ?
              shoutOut.map((item,index) => {
                var date = Moment(item.Sended_on).format('DD-MM-YYYY')

                return <>
                  <div className="container-fluid  m-0 p-2 row w-100">
                    {/* <div className="col-12 ps-0">
                    <div className="row">
                      <div className="col-12 col-md-6">{item.Account_id.pname} |&nbsp;[{date}]</div>
              
                      <div className="col-12 col-md-6 text-end pe-0 d-flex justify-content-end  ">    {
                        item.Account_id._id == localStorage.getItem('User') ?
                          <div className=""><button className="btn pro-spaces-button2" disabled>Communicate</button></div>
                          :
                          <div className=""><button onClick={(e) => shoutCommunicate(item.Account_id._id, item.Account_id.firstname, item.Message)} className="btn pro-spaces-button2" >Communicate</button></div>
                      }</div>
                    </div>
                    <div className="m-1 ps-4">{item.Message}</div>
                  </div> */}

                    <div>
                      <div className="d-flex justify-content-between">
                      <span style={{ "fontWeight": "bold" }}>  {item.Account_id.pname}</span>
                        <div style={{ "alignItems": "center", "display": "flex" }}>
                        <div className="dropdown dropstart">
                          <Link to="" id={`dropdownMenuButton1qq${index}` }data-bs-toggle="dropdown" aria-expanded="false" >
                          <BsThreeDotsVertical color="black"  />
                          </Link>
                          <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton1qq${index}`}>
                        <li><Link className="dropdown-item" to="" onClick={(e)=>BlockMember(item.Account_id._id)}>Block This Member</Link></li>
                        </ul>
                         </div>
                        </div>
                      </div>
                      <p>[{date}]</p>

                      <p>
                        {item.Message}
                      </p>
                      <div className="d-flex justify-content-center" >
                        {
                          item.Account_id._id == localStorage.getItem('User') ?
                            <div className=""><button className="btn pro-spaces-button2" disabled>Communicate</button></div>
                            :
                            <div className=""><button onClick={(e) => shoutCommunicate(item.Account_id._id, item.Account_id.pname, item.Message)} className="btn pro-spaces-button2" >Communicate</button></div>
                        }
                      </div>
                    </div>
                  </div>
                  <hr className="m-2" />
                </>

              })
              :
              "No new enquiries"
            : ""
          }</div>


      </div>
    <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>


  );
}
