import React, { useEffect, useState, useRef, createContext } from 'react'

import './ChatFinal.css'
import { ChatLeft } from "../../Pages/chat/chatLeft";
import Chat from '../../Pages/chat/chat';
import { useSelector } from 'react-redux';

export const ChatContext = createContext()
export function ChatFinal(props) {
    const [Reload, setReload] = useState(true)
    const childRef = useRef(null);
    const chatLeftList = useRef(null);
    let socket = useSelector((state) => state.cubicle.value);
    useEffect(() => {
        props.Auth()
    }, [])

    async function ReloadList() {
        chatLeftList.current.chatuserListLoader()
    }

    const Chatwith = (id, name, group, admin,lock) => {
        
        childRef.current.changeMessage(id, group, admin, name,lock)
    };
    const [RoomDetails, setRoomDetails] = useState({
       
    })
    if(socket){
        return (
            <ChatContext.Provider value={{ ReloadList, setRoomDetails, RoomDetails }}>
                <div className='pt-3'>
                    <div className="  mainCon  bg-light">
                        <div className="row w-100 m-0">
                            {/* chat left */}
                            <ChatLeft
                                ref={chatLeftList}
                                Reload={Reload}
                                Chatwith={Chatwith}
                            />
                            <Chat
                                ReloadList={ReloadList}
                                setReload={setReload}
                                Reload={Reload}
                                ref={childRef}
                                 />
                        </div>
                        {/* <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#createroom" href="#">Create Conference Room</a></li> */}
                    </div>
                </div>
            </ChatContext.Provider>
        )
    }else{
        return<></>
    }
  
}
