import { useEffect, useState } from "react";
import  Axios  from "axios";
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import Moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SpinnerBig } from "../../Utility/spinner";

export const Ask_us_view = () => {

  let [searchParams, setSearchParams] = useSearchParams(); 
  var Id = searchParams.get('k');
  useEffect(() => {
    get()
  }, [])
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!Replaytext) {
      return setError("Please enter reply")
    }
    setError("")
    setShow(true)
  };
  const [Loading, setLoading] = useState(true)
  const [Feedback, setFeedback] = useState(false)
  const [name, setname] = useState('')
  const [email, setemail] = useState("")

  const get=()=>{
    Axios.post('/api/User/FeedbackViewById',{Id})
    .then(res => {
        if (res.status === 200) {
            console.log('Feedback View')
            console.log(res.data)
            setFeedback(res.data)
            setLoading(false)
    }
}).catch((err) => {
        console.log(err)
    
       })
  }

  const [Replaytext, setReplaytext] = useState("")
  const [Error, setError] = useState("")
  const Replay=()=>{

    Axios.post("/api/Admin/ask_us_reply",{Id,Replaytext}).then((res)=>{
      if (res.status==200) {
        console.log(res.data)
        get()
        handleClose()
      }
    }).catch((err)=>{
      console.log(err)
    })
  }


  return (
    <>
      <div className="  bg-light pt-5 mt-5">
      {
            Loading?
            <SpinnerBig/>
            :""
        }
        <div className="text-center py-4" style={{ fontSize: '30px' }}>
          ASK US
        </div>
        <div className="container">
        {/* <div className="row d-flex mx-2  ">
          <div className="col-3  pt-3">Name</div>

          <div className="col-auto   pt-3">{name?name:"Deleted Account"}</div>
          </div>

          <div className="row d-flex mx-2  ">
          <div className="col-3   pt-3 ">Email</div>
 
          <div className="col-auto  pt-3 ">{email?email:"Deleted Account"}</div>
          </div> */}
          <div className="row d-flex mx-2  ">
          <div className="col-3 pt-3 ">Subject</div>
          {/* <div className="col-1 pt-3">:</div> */}
          <div className="col-auto pt-3 ">{Feedback?.Option}</div>
          </div>
          <div className="row d-flex mx-2  ">
          <div className="col-3 pt-3 ">Created Date</div>
          {/* <div className="col-1 pt-3">:</div> */}
          <div className="col-auto d-flex  pt-3">{Moment(Feedback.Date).format('DD-MM-YYYY')}</div>
          </div>
          <div className="row d-flex mx-2  ">
          <div className="col-3  pt-3">Status</div>
          {/* <div className="col-1 pt-3">:</div> */}
          <div className="col-auto d-flex  pt-3">{Feedback?.Status=="Pending"?<><span style={{color:"red"}}>Pending</span></>:<><span style={{color:"green"}}>Success</span></>} </div>
          </div>
          <div className="row d-flex mx-2  ">
          <div className="col-3  pt-3"><b>Message</b> </div>
          {/* <div className="col-1 pt-3">:</div> */}
          <div className="col-12 d-flex  pt-3">
            {Feedback?.Message}
          </div>
          </div>
          {
            Feedback?.Reply?
            <div className="row d-flex mx-2  ">
            <div className="col-3  py-3"><b>Reply</b></div>
            {/* <div className="col-1 py-3">:</div> */}
  
            <div className="col-12 d-flex ">
              {
                Feedback?.Reply?
               <>
               <p>
               {Feedback?.Reply}
                </p>
                </>
                :
                 ""
              }
            </div>
            </div>
            :""
          }


     </div>
        
      </div>
    </>
  );
};
