import  axios  from 'axios'
import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

export  function Profile(props) {
  useEffect(() => {
    props.Auth()
    }, [])
   
    let navigate = useNavigate()
    useEffect(() => {
      axios.get('/api/User/profileactive',{

      }).then((res)=>{
        console.log(res.data)
        if(res.status==200){
            if(res.data){
                if(res.data==="Consultant"){
                    
                    return navigate('/home/My_profile')
                }else if(res.data==="Product"){
                   
                    return navigate('/home/My_profile_productstore')
                }
            }
        }
      }).catch((err)=>{
        console.log(err)
      })
    
      
    }, [])
    
  return (
    <div>


    </div>
  )
}
