import { AiOutlineClose, AiFillInfoCircle } from "react-icons/ai";
import { IoIosHelpCircle } from "react-icons/io";

export const dataTable = [
  {
    head: "PROFILE CREATION ",
    data: [
      {
        label: "Number of Profiles from Single Registration / Subscription",
        value: {
          pro: "20",
          active: "20",
          static: "1",
          passive: "N/A",
        },
        modalss: {
          pro: {
            element: (
              <AiFillInfoCircle
                data-bs-toggle="modal"
                data-bs-target={`#primeprofilemodalussss`}
                style={{ marginTop: "-3px", color: "#0d6efd" }}
              />
            ),
          },
          active: {
            element: (
              <AiFillInfoCircle
                // data-bs-toggle="modal"
                // data-bs-target={`#basicprofilemodalussss`}
                data-bs-toggle="modal"
                data-bs-target={`#primeprofilemodalussss`}
                style={{ marginTop: "-3px", color: "#0d6efd" }}
              />
            ),
          },
        },
      },
    ],
  },
  {
    head: "PORTFOLIO, CATALOGUE CREATION",
    data: [
      {
        label: "In each profile as a Service Provider, Product Retailer",
        value: {
          pro: "YES",
          active: "YES",
          static: "5 images",
          passive: "N/A",
        },
        noChange: true,
      },
    ],
  },
  {
    head: "ADDITIONAL BRANCHES",
    data: [
      {
        label:
          "Add any number of branches of your business entity / offices in a single profile",
        value: {
          pro: "YES",
          active: "YES",
          static: "NO",
          passive: "N/A",
        },
        noChange: true,
      },
    ],
  },
  // {
  //   head: 'TO BE FEATURED',
  //   data: [
  //     {
  //       label: 'Services / Projects / Products being',
  //       value:
  //       {
  //         pro: 'SIXTY',
  //         active: 'NO',
  //         passive: 'N/A'
  //       }
  //     },
  //     {
  //       label: 'FEATURED as an Article / Endorsement',
  //       value:
  //       {
  //         pro: '(in a year) **',
  //         active: '',
  //         passive: ''
  //       }
  //     }
  //   ]
  // },
  {
    head: "DISPLAY OF EXCLUSIVE OFFERS and DISCOUNTS",
    data: [],

    headData: {
      pro: "YES",
      active: "YES",
      static: "NO",
      passive: "N/A",
    },
  },
  {
    head: "DISPLAY OF BRAND ADVERTISEMENTS",
    data: [],

    headData: {
      pro: "NO",
      active: "YES",
      static: "NO",
      passive: "N/A",
    },
  },
  // {
  //   head: "BRAND, SERVICE, PRODUCT ENDORSEMENT",
  //   data: [
  //     {
  //       label: "As a Scroll Band at the top of the screen",
  //       value: {
  //         pro: "YES",
  //         active: "NO",
  //         static: "NO",
  //         passive: "N/A",
  //       },
  //     },
  //   ],
  // },
  {
    head: "AUTHENTICATION BADGE ",
    data: [
      {
        label: "Eligibility as a Service Provider, Product Retailer",
        value: {
          pro: "YES",
          active: "YES",
          static: "NO",
          passive: "N/A",
        },
      },
    ],
    element: (
      <IoIosHelpCircle
        style={{ marginTop: "-3px", color: "#0d6efd" }}
        data-bs-toggle="modal"
        data-bs-target={`#qiyte7tekqeiugflwihroiwgorwogruoegfsnskdbsab`}
      />
    ),
  },
  {
    head: "DISPLAYING UNAVAILABILITY AT WORK / BUSINESS",
    data: [
      {
        label: "If you are away on leave or on a short break",
        value: {
          pro: "YES",
          active: "YES",
          static: "YES",
          passive: "N/A",
        },
      },
    ],
  },
  {
    head: "DEVICE USAGE",
    data: [
      {
        label: "Simultaneous Log-in limit on Multiple Devices",
        value: {
          pro: "3",
          active: "5",
          static: "1",
          passive: "3",
        },
      },
    ],
  },
  {
    head: "PRO-FORUM ",
    data: [
      {
        label: "Post Articles / Initiate Discussions",
        value: {
          pro: "YES",
          active: "YES",
          static: "NO",
          passive: "N/A",
        },
      },
      {
        label: "Be a Participant in Discussions",
        value: {
          pro: "YES",
          active: "YES",
          static: "NO",
          passive: "NO",
        },
      },
    ],
    element: (
      <IoIosHelpCircle
        style={{ marginTop: "-3px", color: "#0d6efd" }}
        data-bs-toggle="modal"
        data-bs-target={`#qiyte7tekqeiugflwihroiwgorwogruoegfsnskdbsabprofo`}
      />
    ),
  },
  {
    head: "JOBS",
    data: [
      {
        label: "View Job Offers / Job Applications",
        value: {
          pro: "YES",
          active: "YES",
          static: "NO",
          passive: "YES",
        },
      },
      {
        label: "Post Job Offers",
        value: {
          pro: "YES",
          active: "YES",
          static: "NO",
          passive: "NO",
        },
      },
      // {
      //   label: 'View Job Applications',
      //   value: {
      //     pro: 'YES',
      //     active: 'YES',
      //     passive: 'YES'
      //   },
      // },
      {
        label: "Post Job Applications",
        value: {
          pro: "YES",
          active: "YES",
          static: "NO",
          passive: "YES",
        },
      },
    ],
  },
  {
    head: "SHOUT-OUT ",
    headData: {
      pro: "YES",
      active: "YES",
      static: "NO",
      passive: "YES",
    },
    data: [],
    element: (
      <IoIosHelpCircle style={{ marginTop: "-3px", color: "#0d6efd" }} />
    ),
    modalId: "wefgigwfijiqiueugfiuilqeiuegfi",
  },
  {
    head: "KNOW MY CLIENT ",
    headData: {
      pro: "YES",
      active: "YES",
      static: "NO",
      passive: "N/A",
    },
    data: [],
    element: (
      <IoIosHelpCircle
        color={"primary"}
        style={{ marginTop: "-3px", color: "#0d6efd" }}
      />
    ),
    modalId: "qiyte7tekqsnskdb",
  },

  {
    head: "CUBICLES ",
    headData: {
      pro: "YES",
      active: "YES",
      static: "NO",
      passive: "YES",
    },
    data: [],
    element: (
      <IoIosHelpCircle
        color={"primary"}
        style={{ marginTop: "-3px", color: "#0d6efd" }}
      />
    ),
    modalId: "qiyte7tekqsnskdbsab",
  },
  {
    head: "FAVOURITES ",
    headData: {
      pro: "YES",
      active: "YES",
      static: "YES",
      passive: "YES",
    },
    data: [],
    element: (
      <IoIosHelpCircle
        color={"primary"}
        style={{ marginTop: "-3px", color: "#0d6efd" }}
      />
    ),
    // modalId: "qiyte7tekqsnskdbsab0",
  },

  {
    head: "VIRTUAL SECRETARY ",
    data: [],
    headData: {
      pro: "YES",
      active: "YES",
      static: "YES",
      passive: "YES",
    },
    element: (
      <IoIosHelpCircle
        color={"primary"}
        style={{ marginTop: "-3px", color: "#0d6efd" }}
        // data-bs-toggle="modal"
        // data-bs-target={`#qiyte7tekqeiugfruoegfsnskdbsab`}
      />
    ),
    modalId: "qiyte7tekqeiugfruoegfsnskdbsab",
  },
];
