import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { bucketName } from "../../../config";
import "./Pview.css";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Edit";
import { FaShareAlt } from "react-icons/fa";
import Portfolio from "./portfolio";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Timelines } from "../../../Components/Timeline/Timeline";

import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import { Mask } from "../../../Utility/mask";
import { Selfdeclaration } from "../../../Components/Selfdeclaration/Selfdeclaration";
import { ShareThis } from "../../ShareThis/ShareThis";
import { BussinessInformation } from "../../../Components/ProfileComponents/BussinessInformation";
import { ContactInformation } from "../../../Components/ProfileComponents/ContactInformation";
import { PersonalInformationStatic } from "../../../Components/ProfileComponents/PersonalInformationStatic";

function Pview(props) {
  let [searchParams, setSearchParams] = useSearchParams();

  let success = searchParams.get("success");

  const [expanded, setExpanded] = useState(false);
  const [DP, setDp] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    props.Auth();

    if (success) {
      notify("Profile Updated", true);
      console.log("updated");
    }
  }, []);

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const Clickhandler = (cont) => {
    console.log(cont);
    if (cont == "image") {
      document.getElementById("image").click();
    } else {
      document.getElementById("video").click();
    }
  };
  useEffect(() => {
    console.log(getval);
  }, [getval]);

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // init
  useEffect(() => {
    axios
      .post("/api/ActiveUser/ConProfile", {})
      .then((res) => {
        setGetval(res.data[0]);
        const uniqueValue = new Date().getTime();
        setDp(
          bucketName +
            `/${res?.data[0]?._id}/avatar.png?timestamp=${uniqueValue}`
        );
        // console.log(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [myarr, setMyarr] = useState(false);
  useEffect(() => {
    if (getval.serviceprovided) {
      // console.log(getval)
      setMyarr(getval.serviceprovided.split(","));
      //  console.log(myarr)
    }
  }, [getval]);
  var sab;

  if (getval.experience > 1) {
    sab = "years";
  } else {
    sab = "year";
  }

  // );

  const [shareshow, setshareshow] = useState(false);

  const copytoclip = () => {
    navigator.clipboard.writeText(
      `www.prochure.in/find_profile?profile=services_profile&&Ac_id=${Mask.encode(
        getval._id
      )}`
    );
    notify("URL copied to your clipboard", true);
  };

  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(
      `See the Profile of ${
        getval.pname
      }\nwww.prochure.in/find_profile?profile=services_profile&&Ac_id=${Mask.encode(
        getval._id
      )}`
    );
  }, [getval]);

  const [first8, setfirst8] = useState(false);

  return (
    <>
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border ">
          {/* whgiowhroighsoihlznvlsnd */}
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-3">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="profilee-img ">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={DP}
                  alt=""
                />
                {/* <div className="file btn btn-lg btn-primary">
                  Change Photo
                  <input onChange={(e)=>changeDp(e.target.files[0])} type="file" name="ProfilePic" accept="image/*"/>
                </div> */}
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  <h5>{getval.pname ? getval.pname.toUpperCase() : ""}</h5>
                </div>
                <div className="d-block d-md-none">
                  <center>
                    <h5>{getval.pname ? getval.pname.toUpperCase() : ""}</h5>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                        {getval.string ? getval.string[0].category : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                          {getval.string ? getval.string[0].category : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="d-block d-md-none">
                  <center>
                    <Link to="/edit_profile_services">
                      <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                        style={{ borderRadius: "25px" }}
                        value="Edit Profile"
                      />
                    </Link>
                    <button
                      className="btn pro-spaces-button ms-3 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      // onClick={copytoclip}
                      onClick={(e) => setshareshow(true)}
                    >
                      <FaShareAlt />
                    </button>
                  </center>
                </div>
                <div className="d-none d-md-block">
                  <Link to="/edit_profile_services">
                    <input
                      type="submit"
                      className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      name="btnAddMore"
                      value="Edit Profile"
                    />
                  </Link>
                  <button
                    className="btn pro-spaces-button ms-3 ps-2 pe-2 me-2"
                    style={{ borderRadius: "25px" }}
                    // onClick={copytoclip}
                    onClick={(e) => setshareshow(true)}
                  >
                    <FaShareAlt />
                  </button>
                </div>
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {getval.Type === "Consultantv3" ? (
                  <>
                    <Accordion
                      expanded={expanded === "panelewrg4"}
                      onChange={handleChange("panelewrg4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Service Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p
                            style={{ whiteSpace: "pre-line" }}
                            className="py-2"
                          >
                            {getval.Description}
                          </p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <PersonalInformationStatic getval={getval} vendor={false} />

                    <BussinessInformation getval={getval} />
                    <ContactInformation getval={getval} />
                  </>
                ) : (
                  <></>
                )}
                <Selfdeclaration type="vdp" />
              </div>
            </center>
          </div>
        </div>
      </div>
      <ShareThis shareshow={shareshow} setshareshow={setshareshow} url={url} />

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Pview;
// new
