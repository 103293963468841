import React from "react";
import "./Anime.css";

export function Anime() {
  return (
    <>
      <div class="mir">
        <div class="mainContainerAnime">
          <span class="TitleAnimeIcon">
            <img src="/proicon.png" height="80px" />
          </span>
          <span class="TitleAnime">
            {" "}
            PROchure
            <span
              style={{ fontSize: "0.4em", position: "relative", top: "-35px" }}
            >
              ®
            </span>
          </span>
          {/* <div class="strikeAnime"></div> */}
          <span class="line2Anime "> an E-Brochure of</span>
          <span class="line3Anime "> Professional Services and Products</span>
          <span class="line4Anime"> a global app from INDIA</span>
          <span class="line5Anime">
            {" "}
            a <span className="monotype">Vipar</span> product
          </span>
        </div>
        <div class="mainContainerAnime2">
          <span class="line7Anime invisible"> LAUNCHING SOON</span>
        </div>

        <div className="proaniminaruto1">
          <p class="line6Anime ">
            Designed and Powered by
            <br />
            <span className=" monotype">Vipar</span>
            &nbsp;CONNECT
          </p>
        </div>
      </div>
    </>
  );
}
