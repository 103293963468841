import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const PersonalInformationVendor = ({ getval,vendor }) => {
  console.log(getval)
  const { firstname, lastname, gender, age, language,blood_Group } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Accordion
        expanded={expanded === "panel299"}
        onChange={handleChange("panel299")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <span>
              <b>
                {" "}
                Personal Information
                <br />
              </b>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="row py-2">
              <div className="col-md-6">
                <span>
                </span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {firstname ? firstname.toUpperCase() : ""}{" "}
                  {lastname ? lastname.toUpperCase() : ""}
                </label>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-6">
                <span>Gender</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {gender ? gender : ""}
                </label>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-6">
                <span>Age</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {age ? age + " years" : ""}
                </label>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-6">
                <span>Blood Group</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {blood_Group? blood_Group: ""}
                </label>
              </div>
            </div>
            {language ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Languages Known</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{ color: "var(--blue)" }}>
                    {language
                      ? language.map((element, index) => {
                          if (index == language?.length - 1) {
                            return element;
                          } else {
                            return element + ", ";
                          }
                        })
                      : ""}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
