import { useEffect, useState } from "react"

export const ProductDiscription = (props) => {
    const [Value, setValue] = useState({
        product_varint: props.value && props.value[props.index] ? props.value[props.index].product_varint : '',
        color: props.value && props.value[props.index] ? props.value[props.index].color : '',
        dimention: props.value && props.value[props.index] ? props.value[props.index].dimention : '',
        width: props.value && props.value[props.index] ? props.value[props.index].width : '',
        length: props.value && props.value[props.index] ? props.value[props.index].length : '',
        depth: props.value && props.value[props.index] ? props.value[props.index].depth : '',
        product_usp: props.value && props.value[props.index] ? props.value[props.index].product_usp : '',
        mrp: props.value && props.value[props.index] ? props.value[props.index].mrp : '',
        selling_price: props.value && props.value[props.index] ? props.value[props.index].selling_price : '',
    })

    useEffect(() => {
        props.Handler(Value, props.index, true)
        return () => {

        }
    }, [Value])


    return <div className="mt-4">
        {props.index ? <div> <button onClick={(e) => { props.Handler('', props.index, false) }} className="btn btn-close float-end pe-1"></button> </div> : <></>}
        <label className="ms-1">Product Variant</label>
        <input className="mb-2 eihfioehfoieh  w-100 form-control " defaultValue={Value.product_varint} onChange={(e) => setValue({ ...Value, product_varint: e.target.value })} placeholder={'Mention product variant name if any'} />
        <label className="ms-1">Product Specifications :</label><br />
        <label className="ms-1">1. Colours available </label>
        <input className="mb-2 eihfioehfoieh  w-100 form-control " defaultValue={Value.color} onChange={(e) => setValue({ ...Value, color: e.target.value })} placeholder={'Mention available colours'} />
        <label className="ms-1">2. Dimensions / Sizes</label>
        <select className="mb-2 form-select eihfioehfoieh" defaultValue={Value.dimention} aria-label="Default select example" onChange={(e) => setValue({ ...Value, dimention: e.target.value })}>
            <option value="" disabled selected></option>
            <option value="1">Inches</option>
            <option value="2">Feet</option>
            <option value="3">Centimeters</option>
            <option value="2">Millimeters</option>
            <option value="3">Meters</option>
        </select>
        <label className="ms-1">Width</label>
        <input type="number" className="mb-2 eihfioehfoieh  w-100 form-control " value={Value.width} onChange={(e) => setValue({ ...Value, width: e.target.value })} />
        <label className="ms-1">Length / Height</label>
        <input type="number" className="mb-2 eihfioehfoieh  w-100 form-control " value={Value.length} onChange={(e) => setValue({ ...Value, length: e.target.value })} />
        <label className="ms-1">Depth / Thickness</label>
        <input type="number" className=" mb-2 eihfioehfoieh  w-100 form-control " value={Value.depth} onChange={(e) => setValue({ ...Value, depth: e.target.value })} />
        <label className="ms-1">Product USP </label>
        <textarea className="mb-2 postInputx eihfioehfoieh ps-2 form-control" value={Value.product_usp} style={{ 'minHeight': '100px' }} onChange={(e) => setValue({ ...Value, product_usp: e.target.value })} ></textarea>
        <label className="ms-1">MRP </label>
        <input type="number" className="mb-2 eihfioehfoieh  w-100 form-control " value={Value.mrp} onChange={(e) => setValue({ ...Value, mrp: e.target.value })} />
        <label className="ms-1">Selling Price </label>
        <input type="number" className="mb-2 eihfioehfoieh  w-100 form-control " value={Value.selling_price} onChange={(e) => setValue({ ...Value, selling_price: e.target.value })} />
    </div>
}