import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./ProductList.css";
import { Link } from "react-router-dom";
import { HiSpeakerphone } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Vendorcom } from "../ProductList/Vendorcom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiReset } from "react-icons/bi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FcInfo } from "react-icons/fc";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { BsSearch } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { print_city, print_state } from "../../Utility/stateCity";
import { Mask } from "../../Utility/mask";
import { ExistingData, InitializeCountry } from "../../Utility/Country";
import { useSelector } from "react-redux";
import { ProfileCard } from "../../Components/ProfileCard/ProfileCard";
export function ProductList() {
  const [open, setOpen] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const [first, setfirst] = useState(false);
  const [data, setdata] = useState(false);
  const [cat, setCat] = useState(false);
  const [Follower, setFollower] = useState();
  const [Foll, setFoll] = useState();
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");
  const [Keyer, setKeyer] = useState(1);
  const [Stateload, setStateload] = useState(true);

  let [searchParams, setSearchParams] = useSearchParams();
  var id = Mask.decode(searchParams.get("Sub_id"));
  let userData = useSelector((state) => state.user);
  const [Search, setSearch] = useState({
    Subcategory: id,
    Country: userData.value.Country ? userData.value.Country : "",
    SearchKeyword: "",
  });
  useEffect(() => {
    daswe2();
  }, [first]);

  const [Results, setResults] = useState();

  const daswe2 = () => {
    axios
      .post("/api/Free/vensearchingbrand", {
        id,
      })
      .then((res) => {
        setCat(res.data);
        setResults(res.data.map((sr) => sr._id));
        const postSummaries = res.data.map((post) => ({
          id: post._id,
          key: post.id,
          data: post,
        }));
        setdata(postSummaries);
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [DisplayNo, setDisplayNo] = useState();

  useEffect(() => {
    console.log(data);

    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data.length > 0) {
        setoutput(
          data.map((number) => (
            // <Vendorcom
            //   key={number.id}
            //   ids={makeid()}
            //   data={number.data}
            // ></Vendorcom>
            <ProfileCard key={number.id} ids={makeid()} data={number.data} />
          ))
        );
        if (data.length > 0) {
          if (data.length === 1) {
            setDisplayNo(
              <center>
                <h4>Displaying&nbsp;{data.length}&nbsp;profile</h4>
              </center>
            );
          } else {
            setDisplayNo(
              <center>
                <h4>Displaying&nbsp;{data.length}&nbsp;profiles</h4>
              </center>
            );
          }
        }
      } else {
        setoutput(
          <center>
            <h4 style={{ fontWeight: "400", color: "#5A5A5A" }}>
              No profiles created yet
            </h4>
          </center>
        );
        setDisplayNo("");
      }
    }
  }, [error, load, data]);

  // const daswe2 = () => {
  //   var id = searchParams.get("Sub_id");

  //   axios
  //     .post("/api/User/vensearching", {
  //       id,
  //     })
  //     .then((res) => {
  //       setCat(res.data);
  //       console.log(res.data);
  //       const postSummaries = res.data.map((post) => ({
  //         id: post._id,
  //         key: post.id,
  //         data: post,
  //       }));
  //       setdata(postSummaries);
  //       setload(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [value, setValue] = useState({
    country: userData.value.Country ? userData.value.Country : "",
    state: "",
    city: "",
    pincode: "",
    WE: false,
    Type_of_Vendor: "",
    Type_of_Entity: "",
    Mall_Building_Name: "",
    Street_Lane_RoadName: "",
    Area_Locality_Name: "",
  });

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city("City", index);
    setValue({ ...value, state: e.target.value });
  }

  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);

  const vaid = () => {
    if (value.country.length == 0) {
      return seterror("Please select a country");
    }
    if (value.state.length > 0) {
      seterror("");
    } else {
      return seterror("Please select a state / Province");
    }

    return true;
  };

  const [onetime, setonetime] = useState(false);

  useEffect(() => {
    if (onetime) {
      vaid();
    }
  }, [value]);

  const dva = () => {
    setonetime(true);
    var status = false;
    status = vaid();
    if (status == true) {
      axios
        .post("/api/Free/findpro", {
          value,
          id,
        })
        .then((res) => {
          if (res.data.length === 0) {
            setModalShow(true);
          }
          setResults(res.data.map((sr) => sr._id));
          const postSummaries = res.data.map((post) => ({
            id: post._id,
            key: post.id,
            data: post,
          }));
          setdata(postSummaries);
          setload(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [ShoutMass, setShoutMass] = useState();
  const shoutOut = async () => {
    var dataSend = {
      sub_id: id,
      massage: ShoutMass,
      cat_id: localStorage.getItem("Cat"),
      Results,
    };
    console.log(dataSend);
    // return
    var send = await axios.post("/api/User/shoutOut", { dataSend });
    if (send.status == 200) {
      // setViewTopicMember(data.data)
      // console.log(data.data)
      notify("SHOUT-OUT sent successfully", true);
    } else {
      notify("SHOUT-OUT sent failed", false);
    }
  };

  const Reset = (e) => {
    document.getElementById("searchForm").reset();
    setValue({ ...value, city: "", state: "", WE: !value.WE, pincode: "" });
    daswe2();
    setDisplayNo("");
  };

  const SearchKeyword = async () => {
    console.log(Search);
    if (Search.SearchKeyword.length === 0) {
      return;
    }
    // return
    try {
      var SearchKeywords = await axios.post("/api/Free/SearchKeywordVender", {
        Search,
      });
      console.log(SearchKeywords.data);
      if (SearchKeywords.data.length === 0) {
        setModalShow(true);
      }
      if (SearchKeywords.data.length > 0) {
        setoutput(
          SearchKeywords.data.map((number) => {
            return <ProfileCard ids={makeid()} data={number} />;
          })
        );
      } else {
        setoutput(
          <center>
            <h4 style={{ fontWeight: "400", color: "#5A5A5A" }}>
              No profiles created yet
            </h4>
          </center>
        );
        setDisplayNo("");
      }

      if (SearchKeywords.data.length > 0) {
        if (SearchKeywords.data.length === 1) {
          setDisplayNo(
            <center>
              <h4>Displaying&nbsp;{SearchKeywords.data.length}&nbsp;profile</h4>
            </center>
          );
        } else {
          setDisplayNo(
            <center>
              <h4>
                Displaying&nbsp;{SearchKeywords.data.length}&nbsp;profiles
              </h4>
            </center>
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  let countryRef = useRef();
  let stateRef = useRef();
  let cityRef = useRef();
  useEffect(() => {
    ExistingData(countryRef, stateRef, cityRef, userData.value.Country, "");

    InitializeCountry(countryRef, stateRef, cityRef);
    return () => {};
  }, []);
  return (
    <div className="container-fluid bg-trasparent  wieugfiqgefnqenc2rnewiuhgiuhq ">
      {/* <div className="d-flex justify-content-end">
        <Link
          to={"/home/Vendor_Result2?Sub_id=" + id}
          className="btn pro-spaces-button me-1 ps-3 pe-3"
          style={{"textTransform":"none"}}
        >
          ACTIVE Profiles
        </Link>
      </div>
      <hr /> */}
      {/* <center><h5 className="parapara">PRO-ACTIVE Profiles</h5></center> */}
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={(e) => setModalShow(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            No profiles to display. A difference in the spellings mentioned in
            the profile and the ones typed by you in certain fields may not
            fetch desired result. In some cases, fewer search criteria would
            fetch better results.
          </p>
        </Modal.Body>
      </Modal>
      <div className="p-0 py-1">
        <div className="sabariforsearch p-0 ">
          <div className="searchtollbarparent">
            <input
              type="text"
              value={Search.SearchKeyword}
              onChange={(e) =>
                setSearch({ ...Search, SearchKeyword: e.target.value })
              }
              placeholder="Type keywords to search"
              name="searchInput"
              className="newsearch px-4 "
              style={{
                backgroundColor: "white",
                border: "1px solid var(--blue)",
                color: "black",
              }}
            ></input>
            <button type="submit" className="searchIco" onClick={SearchKeyword}>
              {" "}
              <BsSearch size="25" style={{ color: "var(--blue)" }} />
            </button>
          </div>
          <div className="resetsearchbutton" style={{ marginTop: "2px" }}>
            <button
              className="btn nfsjfbgevoug btnjui2 ps-3 pe-3 pt-1 pb-1  "
              type="button"
              onClick={(e) => Reset(e)}
            >
              <BiReset size={24} />
            </button>
          </div>
        </div>
      </div>
      <div className="mb-2 me-2">
        {open ? (
          <button
            className="btn pro-spaces-button-semi btn-rounded ps-3 pe-3 mt-1 mb-2"
            onClick={() => setOpen(!open)}
            aria-controls="AdvancedSearch"
            aria-expanded={open}
          >
            Advanced Search Filters&nbsp;
            <MdKeyboardArrowUp />
          </button>
        ) : (
          <>
            <button
              className="btn pro-spaces-button3 btn-rounded ps-3 pe-3 mt-1 mb-2"
              onClick={() => setOpen(!open)}
              aria-controls="AdvancedSearch"
              aria-expanded={open}
            >
              Advanced Search Filters&nbsp;
              <MdKeyboardArrowDown />
            </button>
            <FcInfo
              size={25}
              className="ms-2"
              data-bs-toggle="modal"
              data-bs-target="#advancesearchinfo"
            />
          </>
        )}

        <Collapse in={open} className="ms-1">
          <div id="AdvancedSearch" className=" row bg-light  p-2 ">
            <form id="searchForm">
              <div className="col-12 ">
                <div className="row g-1">
                  <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <select
                        id="hihish"
                        value={value.country}
                        ref={countryRef}
                        className="form-select"
                        onChange={(e) =>
                          setValue({
                            ...value,
                            country: e.target.value,
                            state: "",
                            city: "",
                          })
                        }
                      ></select>
                      <label htmlFor="Country">Country</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <select
                        ref={stateRef}
                        className="form-select"
                        value={value.state}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            state: e.target.value,
                            city: "",
                          })
                        }
                      ></select>
                      <label htmlFor="State">State / Province</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <select
                        value={value.city}
                        className="form-select"
                        onChange={(e) =>
                          setValue({ ...value, city: e.target.value })
                        }
                        ref={cityRef}
                      ></select>
                      <label htmlFor="City">City</label>
                    </div>
                  </div>

                  <div className="col-6 col-lg-3">
                    <div className="form-floating">
                      <input
                        type="number"
                        className="form-control"
                        name="pin"
                        value={value.pincode}
                        id="a"
                        onChange={(e) =>
                          setValue({ ...value, pincode: e.target.value })
                        }
                      ></input>
                      <label htmlFor="a">Pin code / Zip code</label>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="form-floating">
                      <select
                        type="number"
                        className="form-select"
                        name="Type_of_Vendor"
                        value={value.Type_of_Vendor}
                        id="Type_of_Vendor"
                        onChange={(e) =>
                          setValue({ ...value, Type_of_Vendor: e.target.value })
                        }
                      >
                        <option value="" disabled selected></option>

                        <option>Any</option>
                        <option>Retailers</option>
                        <option>Wholesalers</option>
                        <option>Both</option>
                      </select>
                      <label htmlFor="Type_of_Vendor">Type of Vendor</label>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="form-floating">
                      <select
                        type="number"
                        className="form-select"
                        name="Type_of_Entity"
                        value={value.Type_of_Entity}
                        id="Type_of_Entity"
                        onChange={(e) =>
                          setValue({ ...value, Type_of_Entity: e.target.value })
                        }
                      >
                        <option value="" disabled selected></option>

                        <option>Any</option>
                        <option value="Physical">Physical Store</option>
                        <option>E-commerce</option>
                        <option>Both</option>
                      </select>
                      <label htmlFor="Type_of_Entity">Type of Entity</label>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        name="Mall_Building_Name"
                        value={value.Mall_Building_Name}
                        id="Mall_Building_Name"
                        onChange={(e) =>
                          setValue({
                            ...value,
                            Mall_Building_Name: e.target.value,
                          })
                        }
                      ></input>
                      <label htmlFor="Mall_Building_Name">
                        Mall / Building Name
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <input
                        name="Street_Lane_RoadName"
                        className="form-control"
                        id="Street_Lane_RoadName"
                        value={value.Street_Lane_RoadName}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            Street_Lane_RoadName: e.target.value,
                          })
                        }
                      ></input>{" "}
                      <label htmlFor="Street_Lane_RoadName">
                        Street / Lane / Road Name
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <input
                        name="Area_Locality_Name"
                        className="form-control"
                        id="Area_Locality_Name"
                        value={value.Area_Locality_Name}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            Area_Locality_Name: e.target.value,
                          })
                        }
                      ></input>{" "}
                      <label htmlFor="Area_Locality_Name">
                        Area / Locality Name
                      </label>
                    </div>
                  </div>

                  <div className="col-6 col-lg-3 pt-2">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        onChange={(e) => setValue({ ...value, WE: !value.WE })}
                        id="flexCheckCheckedEntrepreneur"
                      />
                      <label
                        class="form-check-label"
                        for="flexCheckCheckedEntrepreneur"
                      >
                        Woman Entrepreneur
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 mb-1 mt-2">
                <div className=" d-flex justify-content-end">
                  <div className="   me-2 ">
                    <button
                      className="btn btnjui2 ps-4 pe-4 d-flex justify-content-center"
                      type="button"
                      onClick={(e) => Reset(e)}
                    >
                      <BiReset size={24} />
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btnjui "
                    onClick={(e) => dva()}
                  >
                    Go
                  </button>
                </div>

                <span style={{ color: "red" }}>{error ? error : ""}</span>
              </div>
            </form>
          </div>
        </Collapse>
      </div>

      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          <div className="row">
            <div className="col-12 pb-3">
              {/* <button
                  className="btn btnsabari px-3 py-1 me-2  btn-rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#shoutoutmodal1"
                >
                  {" "}
                  <HiSpeakerphone />
                  &nbsp;Shout-out
                </button> */}
              <button
                className="btn btnsabari px-3 py-1 me-2 me-2  btn-rounded"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalsabari"
              >
                {" "}
                <HiSpeakerphone />
                &nbsp;Shout-out
              </button>

              <button
                data-bs-toggle="modal"
                data-bs-target="#exampleModalsabari"
                className="btn btnsabari px-3 py-1   btn-rounded"
              >
                View Short-listed
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div>  
          <center><button className="btn pro-spaces-button3" id="tooglebutton" onClick={(e)=>setfirst(!first)}>{first?'Show Vendors Only':'Show Products Only'}</button></center>
        </div> */}
      {DisplayNo}
      <br></br>
      <div className={`row car ${load === false ? "" : "notranslate"}`}>
        {output}
      </div>

      <div
        className="modal fade"
        id="shoutoutmodal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Shout-Out
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <textarea
                className="form-control"
                placeholder="max 500 characters"
                maxLength="200"
                onChange={(e) => setShoutMass(e.target.value)}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn pro-spaces-button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn pro-spaces-button3"
                onClick={(e) => shoutOut()}
                data-bs-dismiss="modal"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
