import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Link } from "react-router-dom";
import { AiFillFire, AiOutlineSearch } from "react-icons/ai";
import { BiDotsVerticalRounded, BiDotsHorizontalRounded } from "react-icons/bi";
import { BsDot } from "react-icons/bs";
import { IoMdArrowDropdown, } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios"
let checker

const ChatHeader = forwardRef((props, ref) => {
  // console.log(props)

  let navigate = useNavigate()


  useImperativeHandle(ref, () => ({
    TheHeader(foks) {
      // console.log('at header')
      // console.log(foks)
      ListGroupTopic()
    }
  }));

  function keyGen() {
    var length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const [Online, setOnline] = useState(false)

  useEffect(() => {
    clearInterval(checker);
    setlistTopic()
    if (props.RoomDetails.id != null) {
      ListGroupTopic()
      if (!props.RoomDetails.group) {
        // console.log(props)
        if (!props.RoomDetails.id)
          return

        let rd = props.RoomDetails.id
        checker = setInterval(() => {
          axios.post('/api/User/OnlineStatus', { rd }).then((res) => {
            // console.log(res.data)
            setOnline(res.data)
          })
        }, 10000);
      }

    }

  }, [props.RoomDetails.id])
  const [listTopic, setlistTopic] = useState()

  async function ListGroupTopic() {
    var id = props.RoomDetails.id
    // return
    var data = await axios.post('/api/User/ListGroupTopic', { id })
    if (data) {
      // console.log(("I am from backend"))
      setlistTopic(data.data)
      // console.log(data.data)
    }
  };
  const Backto = () => {
    var left = document.getElementById('leftRoll')
    left.classList.remove('d-none')
  }
  const Changetopic = (e) => {
    let text = listTopic.find(o => o._id === e);
    if (text) {

      text = text.Topic
    }
    if (e.length === 24) {
      props.Changetopic(e, text)
    }
  }
  // const Online_status = async (e) => {
  //   let id = RoomDetails.id
  //   try {
  //     let ax = await axios.post('/user/OnlineStatus', { id })
  //     if (ax) {
  //       // console.log(ax)
  //     }
  //   } catch (error) {
  //     // console.log(error)
  //   }
  // }



  const RemoveFromChatList = async (id) => {

    // console.log(id)
    var remove = await axios.post("/api/User/RemoveFromChatList", { id });
    // console.log(remove)
    if (remove.status === 200) {
      // console.log("it worked")
      navigate('/home/cubicles')

    }
    else {
      // console.log("It have some error")

    }


  }



  if (!props.RoomDetails.id) {
    return <div className="row risp f px-1 pe-5 m-0">
      <div className="col-12 d-flex  dkhs align-items-center ">
        <div className="row w-100">
          <div className="col-11" style={{ color: "white" }}>
            {" "}
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-3">
                    <div id='chatGo' className=" d-none" >
                      <HiArrowNarrowLeft size={'35'} onClick={(e) => Backto()}>Back</HiArrowNarrowLeft>
                    </div>
                  </div>
                  <div className="col-9 pt-1">
                    <h5 className="groundName">{props.RoomDetails.name}</h5>
                  </div>
                </div>
              </div>
              <div className="col-12 pe-0">
              </div>
            </div>
          </div>
          <div className="col-1 ">
          </div>
        </div>
      </div>
    </div>
  }
  else if (props.RoomDetails.group) {
    return (
      <div className="row risp f px-1 pe-5 m-0">
        <button className="invisible" onClick={(e) => { }}></button>
        <div className="col-12 d-flex  dkhs align-items-center ">
          <div className="row w-100">
            <div className="col-11" style={{ color: "white" }}>
              {" "}
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      <div id='chatGo' className=" d-none" >
                        <HiArrowNarrowLeft size={'35'} onClick={(e) => Backto()}>Back</HiArrowNarrowLeft>
                      </div>
                    </div>
                    <div className="col-9 pt-1">
                      <h5 className="groundName text-center">{props.RoomDetails.name}</h5>
                    </div>
                  </div>
                </div>
                <div className="col-12 pe-0">
                  {props.RoomDetails.group ?
                    <div className="row">
                      <div className="col-10 pe-0">
                        <div class="dropdown">
                          <select className="form-select   py-1" onChange={(e) => Changetopic(e.target.value)}>
                            <option selected  >Select Cubicle</option>
                            {listTopic ?
                              listTopic.map((item) => {
                                return <><option value={item._id}> {item.Topic}</option></>
                              })
                              :
                              "Something went Wrong "
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-2 px-0 ">
                        {
                          props.RoomDetails.topic ?
                            props.RoomDetails.admin ?
                              <>
                                <><BiDotsHorizontalRounded size={'30'} id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" color='white' /></>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                  <>
                                    <li className="text-center">{props.RoomDetails.topicName ? props.RoomDetails.topicName : 'Cubicle'}
                                      <hr className="my-1" />
                                    </li>
                                    <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#viewnewmemberintopic" onClick={(e) => props.viewTopicMember(props.RoomDetails.topic)}>View members</a></li>

                                    <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#InviteMember" onClick={(e) => props.TopicMembersAddView(props.RoomDetails.topic)} >Add members </a></li>
                                    <li><a class="dropdown-item" onClick={(e) => props.LockTopic(props.RoomDetails.topic)}>Lock {props.RoomDetails.topicName ? props.RoomDetails.topicName : 'Cubicle'}</a></li>
                                  </>


                                </ul>
                              </>
                              : ''
                            :
                            ""
                        }
                      </div>
                    </div>
                    : <></>}

                </div>
              </div>
            </div>
            <div className="col-1 ">

              {props.RoomDetails.group ?
                <div className="dropdown ">
                  <button className=" btn pt-2 " id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"
                  >
                    <BiDotsVerticalRounded color="white" size={40} />
                  </button>
                  {/* a */}
                  <ul
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <li className="text-center">Conference room
                      <hr className="my-1" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" data-bs-toggle="modal"
                        data-bs-target="#viewmember" onClick={(e) => props.ShowMember()} >
                        View members
                      </a>
                    </li>
                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addmembermodal" onClick={(e) => props.GroupInvite(props.RoomDetails.id)}     >Invite members
                    </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" data-bs-toggle="modal"
                        data-bs-target="#viewnewmember" onClick={(e) => props.NoMembers(props.RoomDetails.id)}>
                        View New members
                      </a>
                    </li>
                    {props.RoomDetails.admin ?
                      <li>
                        <a className="dropdown-item" href="#" data-bs-toggle="modal"
                          data-bs-target="#CreateTopic"   >
                          Initiate New cubicle
                        </a>
                      </li>
                      : ''}
                  </ul>
                </div>
                : <></>}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="row f px-1 pe-4  m-0">
      <div className="col-12 d-flex  dkhs align-items-center ">
        <div className="row w-100">
          <div className="col-11" style={{ color: "white" }}>
            {" "}
            <div className="row">
              <div className="col-12 pt-2">
                <div className="row">
                  <div className="col-3">
                    <div id='chatGo' className=" d-none" >
                      <HiArrowNarrowLeft size={'35'} onClick={(e) => Backto()}>Back</HiArrowNarrowLeft>
                    </div>
                  </div>
                  <div className="col-9 pt-1">
                    <h5 className="groundName">{props.RoomDetails.name}

                      <BsDot size={40} color={Online ? "green" : 'red'} />

                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1 pt-1 ps-0">
            <div className="dropdown ">
              <button className=" btn  pt-2" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                <BiDotsVerticalRounded color="white" size={40} />
              </button>
              <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton2">

                <li>
                  <a className="dropdown-item" onClick={(e) => props.Block(props.RoomDetails.id)}>
                    Block member
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={(e) => RemoveFromChatList(props.RoomDetails.id)} >
                    Remove from chat
                  </a>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

})

export default ChatHeader
