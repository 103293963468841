import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useReducer, 
} from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Mask } from '../Utility/mask';
import axios from 'axios';
import { keyGen } from '../Utility/helper';
import { Carousel, Dropdown, Modal } from 'react-bootstrap';
import { Cropper } from 'react-cropper';
import { notify } from '../Utility/notify';
import { RiUpload2Fill } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';

export const Newidfind = () => {
  const [pvalue, setpvalue] = useState(null);
  const [parmas, setparams] = useSearchParams();
  var id = parmas.get('post');
  var _id = Mask.decode(id);

  useEffect(() => {
    axios
      .get(`/api/User/Newidfind?post=${_id}`)
      .then((res) => {
        console.log(res.data);
        SelectController(res.data[0].Type, res.data[0])
        setpvalue(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const activeComponentRef = useRef();

  const [ProForumTopic, setProForumTopic] = useState([]);
  useEffect(() => {
    // load pro forum topic
    axios
      .get('/api/ProtectedApi/ProForumTopics')
      .then((res) => {
        console.log(res);
        setProForumTopic(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => { };
  }, []);

  const reducer = (state, action) => {
    console.log(action);
    switch (action.type) {
      case 1:
        return {
          ...state,
          render: <TextOnly defaultValue={action.defaultValue} ref={activeComponentRef} />,
          type: action.type,
        };
      case 2:
        return {
          ...state,
          render: <ImageOnly defaultValue={action.defaultValue} ref={activeComponentRef} />,
          type: action.type,
        };
      case 3:
        return {
          ...state,
          render: <TextWithImage defaultValue={action.defaultValue} ref={activeComponentRef} />,
          type: action.type,
        };
      case 4:
        return {
          ...state,
          render: (
            <ImageUploadWithDiscriptionComponent defaultValue={action.defaultValue} ref={activeComponentRef} />
          ),
          type: action.type,
        };
      case 5:
        return {
          ...state,
          render: (
            <ImageUploadWithTextandDiscriptionComponent
              defaultValue={action.defaultValue}
              ref={activeComponentRef}
            />
          ),
          type: action.type,
        };

      default:
        return { ...state, render: <h1>default</h1> };
    }
  };
  const [state, dispatch] = useReducer(reducer, { render: <></> });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [value, setvalue] = useState({
    Topic: '',
  });
  const [Error, setError] = useState('');
  const SelectController = (value, defaultValue) => {
    dispatch({ type: parseInt(value), defaultValue });
  };

  const submit = () => {
    // if (value.Topic.length == 0) {
    //   return setError('Please select a Topic');
    // }
    // if (![1, 2, 3, 4, 5].includes(state.type)) {
    //   return setError('Please select a type');
    // }

    setError('');

    if (activeComponentRef.current) {
      const data = activeComponentRef.current.getData();
      let formData = new FormData();
      console.log(data);
      let Titles = [];
      // if (data.Data) {
      //   data.Data.forEach((element, index) => {
      //     let metadata = {
      //       type: element.file.type,
      //     };
      //     console.log(element);
      //     if (element.file.type.indexOf('video') != -1) {
      //       var ads = `prochure_pro_forum_${index}_${keyGen() + Date.now()
      //         }.mp4`;
      //     }
      //     if (element.file.type.indexOf('image') != -1) {
      //       var ads = `prochure_pro_forum_${index}_${keyGen() + Date.now()
      //         }.png`;
      //     }

      //     let file = new File([element.file], ads, metadata);
      //     formData.append('file', file);
      //     Titles = [...Titles, element.title];
      //   });
      // }
      let tempFile = pvalue.Files.map((elem) => {
        let temp = elem
        delete temp.filename
        return temp
      })
      formData.append(
        'data',
        JSON.stringify({
          Description: data.Discription,
          Data: tempFile,
          id:pvalue._id
        })
      );

      axios
        .put('/api/User/ProForum', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log(res);
          notify('Posted', true);
        })
        .catch((err) => {
          console.log(err);
          notify('Something went wrong !', false);
        });
    }
  };
  const [showdeletepost, setShowdeletepost] = useState(false);

  const handleClosedeletepost = () => setShowdeletepost(false);
  const handleShowdeletepost = () => setShowdeletepost(true);
  let navigate = useNavigate();
  const deletepost = () => {
    axios
      .post('/api/user/postdelete', { _id })
      .then((res) => {
        console.log(res.data);
        handleClosedeletepost();
        notify('Post deleted Successfully', true);
        navigate('/Newfind', { replace: true });
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  return (
    <div className="container" style={{ marginTop: '100px' }}>
      {pvalue && (
        <>
          <div>
            <div className="d-flex justify-content-end">
              <Dropdown className="dropv2Head">
                <Dropdown.Toggle className="dropV2" id="dropdown-basic">
                  <BsThreeDotsVertical color="black" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleShowdeletepost}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='d-none'>
              <div className="form-group py-2">
                <label htmlFor="selectFormatr">Topic</label>
              </div>
              <select
                id="selectFormatr"
                className="form-select"
                onChange={(e) => {
                  setvalue((prev) => ({ ...prev, Topic: e.target.value }));
                }}
              >
                <option selected></option>
                {ProForumTopic.map((element) => {
                  return <option value={element._id}> {element.Topic}</option>;
                })}
              </select>
            </div>
          </div>

          <div>
            <div className=" d-none form-group py-2">
              <label htmlFor="selectFormat">Select Format</label>
              <select
                id="selectFormat"
                className="form-select"
                onChange={(e) => SelectController(e.target.value)}
              >
                <option value={9999} selected>
                  select
                </option>
                <option value={1}>Only Text</option>
                <option value={2}>Only Images</option>
                <option value={4}>Text with Images</option>
                <option value={3}>
                  Images with Description
                </option>
                <option value={5}>
                  Images with Text and Description
                </option>
              </select>
            </div>
            {/* <TextOnly ref={activeComponentRef} /> */}
            {state.render}
          </div>
          <div className='d-flex justify-content-end'>
            <button className='btn pro-spaces-button3 mt-3 ' onClick={() => submit()} >update</button>
          </div>
          {/* #postdeleteconfirmation */}
          <Modal show={showdeletepost} onHide={handleClosedeletepost} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>Are you sure you want to delete the post ?</Modal.Body>
            <Modal.Footer>
              <button className="btn pro-spaces-button3" onClick={deletepost}>
                CONFIRM
              </button>
            </Modal.Footer>
          </Modal>
          {/* #postdeleteconfirmation */}
        </>
      )}
    </div>
  );
};

const TextOnly = forwardRef((props, ref) => {
  const [Discription, setDiscription] = useState('');
  let defaultValue = props.defaultValue

  useEffect(() => {
    console.log(defaultValue);
    setDiscription(defaultValue.Description)
   
    return () => {

    }
  }, [])
  useImperativeHandle(ref, () => ({
    getData() {
      return { Discription };
    },
  }));
  return <TextComponent onChange={(e) => setDiscription(e.target.value)} />;
});
const ImageOnly = forwardRef((props, ref) => {
  const [Data, setData] = useState([]);
  let defaultValue = props.defaultValue

  useEffect(() => {
    console.log(defaultValue);
  
    setData(defaultValue.Files)
    return () => {

    }
  }, [])
  const FileInput = (files) => {
    setData((prev) => [...prev, { title: '', file: files }]);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return { Data };
    },
  }));
  return (
    <div className="py-2">
      <MyCarousel data={Data} />
      {/* <ImageUploadComponent onChange={FileInput} /> */}
    </div>
  );
});
const TextWithImage = forwardRef((props, ref) => {
  const [Data, setData] = useState([]);
  let defaultValue = props.defaultValue

  useEffect(() => {
    console.log(defaultValue);

    setData(defaultValue.Files)
    return () => {

    }
  }, [])
  const FileInput = (files) => {
    setData((prev) => [...prev, { title: '', file: files }]);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return { Data };
    },
  }));

  const ModifyData = (data, index) => {
    let temp = [...Data];
    temp[index].title = data;
    setData(temp);
  };

  return (
    <div>
      <MyCarousel titleEnabled={true} data={Data} onChange={ModifyData} />
      {/* <ImageUploadComponent onChange={FileInput} /> */}
    </div>
  );
});
const ImageUploadWithDiscriptionComponent = forwardRef((props, ref) => {
  const [Data, setData] = useState([]);
  const [Discription, setDiscription] = useState('');
  let defaultValue = props.defaultValue

  useEffect(() => {
    console.log(defaultValue);
    setDiscription(defaultValue.Description)
    setData(defaultValue.Files)
    return () => {

    }
  }, [])
  const FileInput = (files) => {
    setData((prev) => [...prev, { title: '', file: files }]);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return { Discription, Data };
    },
  }));

  const ModifyData = (data, index) => {
    let temp = [...Data];
    temp[index].title = data;
    setData(temp);
  };

  return (
    <div>
      <MyCarousel titleEnabled={false} data={Data} onChange={ModifyData} />
      {/* <ImageUploadComponent onChange={FileInput} /> */}
      <TextComponent
        onChange={(e) => {
          setDiscription(e.target.value);
        }}
      />
    </div>
  );
});
const ImageUploadWithTextandDiscriptionComponent = forwardRef((props, ref) => {
  const [Data, setData] = useState([]);
  const [Discription, setDiscription] = useState('');
  let defaultValue = props.defaultValue
  useEffect(() => {
    console.log(defaultValue);
    setDiscription(defaultValue.Description)
    setData(defaultValue.Files)
    return () => {

    }
  }, [])

  const FileInput = (files) => {
    setData((prev) => [...prev, { title: '', file: files }]);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return { Discription, Data };
    },
  }));

  const ModifyData = (data, index) => {
    let temp = [...Data];
    temp[index].title = data;
    setData(temp);
  };

  return (
    <div>
      <MyCarousel titleEnabled={true} data={Data} onChange={ModifyData} />
      {/* <ImageUploadComponent onChange={FileInput} /> */}
      <TextComponent
        value={Discription}
        onChange={(e) => {
          setDiscription(e.target.value);
        }}
      />
    </div>
  );
});
const MyCarousel = ({ titleEnabled, data, onChange }) => {

  const [activeIndex, setActiveIndex] = useState(0);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setActiveIndex((prevIndex) => (prevIndex + 1) % 3); // Adjust the number of slides here
  //     }, 2000); // Adjust the interval time as per your needs (in milliseconds)
  //     return () => clearInterval(interval);
  //   }, []);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const preview = (file) => {
    return URL.createObjectURL(file);
  };

  return (
    <div className="caro-pro-249882">
      {data.length ? (
        <Carousel indicators={false} controls={data?.length > 1 ? true : false}>
          {data.map((elem, index) => {
            if (elem.typeOf.indexOf('image') != -1) {
              return (
                <Carousel.Item>
                  <div className='d-flex justify-content-center'>
                    <img className=' img-fluid' src={elem.filename} />
                  </div>
                  {titleEnabled && (
                    <div className="py-2">
                      <input
                        defaultValue={elem.title}
                        onChange={(e) => onChange(e.target.value, index)}
                        className="form-control"
                      ></input>
                    </div>
                  )}
                </Carousel.Item>
              );
            }
            if (elem.typeOf.indexOf('video') != -1) {
              return (
                <Carousel.Item>
                  <div className='d-flex justify-content-center'>
                    <video className='img-fluid' controls>
                      <source src={elem.filename}></source>
                    </video>
                  </div>

                  {titleEnabled && (
                    <div className="py-2">
                      <input
                        defaultValue={elem.title}
                        onChange={(e) => onChange(e.target.value, index)}
                        className="form-control"
                      ></input>
                    </div>
                  )}
                </Carousel.Item>
              );
            }
          })}
        </Carousel>
      ) : (
        <></>
      )}
    </div>
  );
};
const TextComponent = ({ label, onChange, value }) => {
  console.log(value);
  const getData = () => { };
  return (
    <div className="form-group">
      <label htmlFor="exampleTextarea">{label}</label>
      <textarea
        onChange={onChange}
        className="form-control"
        id="exampleTextarea"
        rows="3"
        value={value}
      ></textarea>
    </div>
  );
};
const ImageUploadComponent = ({ onChange }) => {
  const fileInputRef = useRef(null);

  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleOpen = () => {
    setshow(true);
  };
  const [ImageToCrop, setImageToCrop] = useState();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    if (event.target.files[0].type.indexOf('image') != -1) {
      let file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageToCrop(reader.result);
        handleOpen();
      };
      reader.readAsDataURL(file);
      // onChange(event.target.files)
    } else if (event.target.files[0].type.indexOf('video') != -1) {
      onChange(event.target.files[0]);
    }
    event.target.value = null;
  };
  const [cropper, setCropper] = useState();
  const crop = () => {
    if (typeof cropper !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          handleClose();
          onChange(blob);
          setCropper();
          setImageToCrop();
        },
        'image/jpeg',
        1
      );
    }
  };

  return (
    <>
      <div className="form-group">
        <div className="input-group">
          <input
            ref={fileInputRef}
            type="file"
            className="d-none"
            accept="image/*,video/*"
            onChange={handleImageUpload}
          />
          <div className="input-group-append">
            <button
              className="btn pro-spaces-button3"
              type="button"
              onClick={handleButtonClick}
            >
              <RiUpload2Fill size={24} />
            </button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} backdrop={'static'} centered>
        <Modal.Header>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ImageToCrop && (
            <>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={false}
                aspectRatio={4 / 3}
                preview=".img-preview"
                src={ImageToCrop}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={0}
                checkOrientation={false} // github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
              <div>
                {' '}
                <button
                  type="button"
                  className="btn pro-spaces-button3 float-end"
                  onClick={crop}
                >
                  ok
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
