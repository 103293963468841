import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

import { ProfileCreation } from "../Components/ProfileCreation/ProfileCreation";
import { PassiveProfileCreation } from "../Components/PassiveProfileCreation/PassiveProfileCreation";
import { Signin } from "../Components/SignIn/signin";
import { ActiveRegister } from "../Components/Signup/ActiveRegister";
import { PassiveRegister } from "../Components/Signup/PassiveRegister";
import { Payment } from "../Pages/Payment/payment";
import { Homecopo } from "../Components/Homecopo/Homecopo";
import { Priceing } from "../Components/Priceing/Priceing";
// import { Home } from './Active/Home/Home';

// import JobSeekMyPost from '../Components/JobSeekMyPost/JobSeekMyPost';
// import { Link } from 'react-router-dom';
import { ForgetPass } from "../Components/ForgetPass/ForgetPass";
import { Privacy } from "../Components/Privacy/Privacy";
import { Disclimer } from "../Components/Disclimer/Disclimer";
import { OutsidePcat } from "../Components/OutsideCat/OutsidePcat";
import { OutsideCat } from "../Components/OutsideCat/OutsideCat";
import Welcome from "../Pages/Welcome/welcome";

/////Freee///////
import { Vertual } from "../FreeComponents/Vertual/Vertual";
import { Home } from "./Free/Home/Home";
import { CommingSoon } from "../FreeComponents/CommingSoon/CommingSoon";
import { Subscrption } from "../FreeComponents/Subscrption/Subscrption";
import { Job } from "../FreeComponents/Job/Job";
import { ChatFinal } from "../FreeComponents/ChatFinal/ChatFinal";
import { Informationview2 } from "../FreeComponents/Informationview/Informationview2";
/////Freee///////

import { Categories } from "../FreeComponents/ConsCategories/Categories";
import { ConSubcategory } from "../FreeComponents/ConSubcategory/ConSubcategory";
import { SearchResult } from "../FreeComponents/ConSearchResult/SearchResult";
import { ProductMainCategory } from "../FreeComponents/ProductMainCategory/ProductMainCategory";
import { ProductSubCategory } from "../FreeComponents/ProductSubCategory/ProductSubCategory";
import { ProductList } from "../FreeComponents/ProductList/ProductList";
import { InformationConRes } from "../FreeComponents/InformationConRes/InformationConRes";
import { InformationProRes } from "../FreeComponents/InformationProRes/InformationProRes";

import { ActiveRegisterv2 } from "../Components/Signup/ActiverRegisterv2";
import { ActiveRegisterv3 } from "../Components/Signup/ActiverRegisterv3";

import { Error } from "../Components/Error/Error";
import { ProfileCreation2 } from "../Components/ProfileCreation/ProfileCreationV2";
import { UserFeatures } from "../Components/userFeatures/UserFeatures";
import { Priceing2 } from "../Components/Priceing/Priceing2";
import { About } from "../Components/About/About";
import { NotFount } from "../Components/404/404";
import { CancelationPolicies } from "../Components/CancelationPolicies/CancelationPolicies";

import Propostlist1ext from "../Components/propostPostVender/propostlistext";
import Propostlistext from "../Components/propostPost/propostlistext";
import { ProductSubSubCategory } from "../FreeComponents/ProductSubSubCategory/ProductSubSubCategory";
import { ConSubcategory as ConSubSubcategory } from "../FreeComponents/ConSubSubcategory/ConSubcategory";
import { Search } from "../Components/Search/Search";
import Pextenalview from "../FreeComponents/Active/ProductProfile/Pextenalview";
import ExternalPview from "../FreeComponents/Active/ActiveProfile/externalPview";
import { SearchResult as SearchResultBrand } from "../Components/ConSearchResultBrand/SearchResultfree";
import { ProductList as ListBrand } from "../FreeComponents/ProductListBrand/ProductList";
import { ProfileCreation3 } from "../Components/ProfileCreation/ProfileCreationV3";
import { Quickfix } from "../Components/Quickfix/Quickfix";
import { Termsofuse2 } from "../Components/Termsofuse/Termsofuse2";
import { Feedback } from "../Components/Feedback/Feedback";
import { CreateConseltent as DirectLinkForService } from "../Components/DirectLinkForService/CreateConseltent";
import { CreateConseltent as DirectLinkForServiceStatic } from "../Components/DirectLinkForServiceStatic/CreateConseltent";
import { CreateProduct as DirectLinkForProduct } from "../Components/DirectLinkForProduct/CreateProduct";
import { CreateConseltent as DirectLinkForProductStatic } from "../Components/DirectLinkForProductStatic/CreateConseltent";

import { CreateConseltentSpecialV3 } from "../Components/FormForStaticConseltent/CreateConseltent";
import { PaymentSpecialV3 } from "./Payment/SpecialPayments/paymentSpecialV3";
import ConsultantEdit from "../Pages/Payment/SpecialPayments/ProfileTrialView/ConsultantEdit";
import ConsultantEditStatic from "../Pages/Payment/SpecialPayments/ProfileTrialView/ConsultantEditStatic";

import VendorEdit from "./Payment/SpecialPayments/ProfileTrialView/VendorEdit";
import { ProfileFinder } from "../Utility/ProfileFinder";
import { NewNavigationBarDissabled } from "./Free/NewNavigationBar/NewNavigationBarDissabled";
import { DemoProfileViaSubCategory } from "../Components/DemoProfileViaSubCategory/DemoProfileViaSubCategory";
import { SearchResult as SearchResultfordemo } from "../Components/DemoProfileViaSubCategory/SearchResult";
import { DirectLinkBasicService } from "./Payment/SpecialPayments/DirectLinkBasicService";
import { DirectLinkBasicProduct } from "./Payment/SpecialPayments/DirectLinkBasicProduct";
import VendorEditStatic from "./Payment/SpecialPayments/ProfileTrialView/VendorEditStatic";
import ProForum from "./ProForum/ProForum";
import { Newfind } from "../Components/Newfind";
import { Newidfind } from "../Components/Newidfind";

import { Information_Subcategory_list } from "../FreeComponents/Information_View/Information_Subcategory_list";
import { Information_Category_list } from "../FreeComponents/Information_View/Information_Category_list";
import { Information_Subsubcategory_list } from "../FreeComponents/Information_View/Information_Subsubcategory_list";
import { Information_listing } from "../FreeComponents/Information_View/Information_listing";
import { Information_Medical_Store } from "../FreeComponents/Information_View/Information_Medical_Store";
import Information_Medical_Store_profile from "../FreeComponents/Information_View/SIngle_page/Information_Medical_Store_profile";
import Information_Type1 from "../FreeComponents/Information_View/SIngle_page/Information_Type1";

import { Landing } from "../Components/Landing/Landing";
import { NewNavigationBar } from "./Free/NewNavigationBar/NewNavigationBar";
import Edit2 from "./Active/ProductProfile/Edit";
import Edit from "./Active/ActiveProfile/Edit";
import Edit3c from "./Activev3/ActiveProfile/Edit";
import Edit3p from "./Activev3/ProductProfile/Edit";
import { GlobalErrorHandler } from "../Utility/components/GlobalErrorHandler";

export function PublicRoutes() {
  return (
    <div>
      <Routes>
        {/* public routes */}
        {/* <Route element={<Landing />} exact path="/"></Route> */}
        <Route element={<Ex />} exact path="/lobby/*"></Route>
        <Route element={<Ex />} exact path="/pre_launch/*"></Route>
        <Route element={<Priceing />} path="/registration"></Route>
        <Route element={<Priceing2 />} path="/register"></Route>
        <Route element={<Signin />} path="/signin"></Route>
        <Route element={<ForgetPass />} path="/forget"></Route>
        <Route element={<Payment />} path="/payment"></Route>
        <Route element={<ActiveRegister />} path="/pro-active_register"></Route>
        <Route element={<ActiveRegisterv2 />} path="/active_register"></Route>
        <Route
          element={<ActiveRegisterv3 />}
          path="/static_listing_register"
        ></Route>
        <Route element={<PassiveRegister />} path="/passive_register"></Route>
        <Route element={<ProfileCreation />} path="next/*"></Route>
        <Route element={<ProfileCreation2 />} path="next_/*"></Route>
        <Route element={<ProfileCreation3 />} path="next__/*"></Route>
        <Route element={<PassiveProfileCreation />} path="/passive"></Route>
        <Route element={<Homecopo />} path="/*"></Route>
        <Route element={<OutsideCat />} path="/services_categories"></Route>
        <Route element={<OutsidePcat />} path="/products_categories"></Route>
        <Route element={<ProfileFinder />} path="/find_profile"></Route>
        <Route element={<Error />} path="/404"></Route>
        {/* DirectLink registration ---------only temporary---------*/}

        <Route
          element={<DirectLinkForService basicprofile={false} />}
          path="/services_prime"
        ></Route>
        <Route
          element={<DirectLinkForProduct basicprofile={false} />}
          path="/products_prime"
        ></Route>
        <Route
          element={<DirectLinkForService basicprofile={true} />}
          path="/services_elite"
        ></Route>
        <Route
          element={<DirectLinkForProduct basicprofile={true} />}
          path="/products_elite"
        ></Route>
        <Route
          element={<DirectLinkForServiceStatic />}
          path="/services_static"
        ></Route>
        <Route
          element={<DirectLinkForProductStatic />}
          path="/products_static"
        ></Route>
        <Route
          element={<DirectLinkBasicService />}
          path="/DirectLinkPayment"
        ></Route>
        <Route
          element={<DirectLinkBasicProduct />}
          path="/DirectLinkPaymentProduct"
        ></Route>

        <Route
          element={<CreateConseltentSpecialV3 />}
          path="/pro_listing"
        ></Route>

        <Route
          element={<ConsultantEdit />}
          path="edit_profile_services_free"
        ></Route>
        <Route
          element={<ConsultantEditStatic />}
          path="edit_profile_services_free_static"
        ></Route>
        <Route
          element={<VendorEdit />}
          path="edit_profile_products_free"
        ></Route>
        <Route
          element={<VendorEditStatic />}
          path="edit_profile_products_free_static"
        ></Route>

        {/* special paymens ---------only temporary---------*/}

        <Route
          element={<PaymentSpecialV3 />}
          path="/special_static_payment"
        ></Route>
        <Route
          element={<DemoProfileViaSubCategory />}
          path="/pre_launch_prochure"
        ></Route>
        <Route
          element={<SearchResultfordemo />}
          path="/Profile_results"
        ></Route>
        <Route
          element={<ExternalPview demo={true} />}
          path="services_profile_demo"
        ></Route>
        <Route
          element={<Pextenalview demo={true} />}
          path="products_profile_demo"
        ></Route>
        <Route
          element={
            <>
              <NewNavigationBarDissabled />
              <Edit2 Auth={() => {}} />
            </>
          }
          path="/edit_profile_products_dce"
        ></Route>
        <Route
          element={
            <>
              <NewNavigationBarDissabled />
              <Edit Auth={() => {}} />
            </>
          }
          path="/edit_profile_services_dce"
        ></Route>
        <Route
          element={
            <>
              <NewNavigationBarDissabled />
              <Edit3c Auth={() => {}} />
            </>
          }
          path="/edit_profile_for_v3_service_dce"
        ></Route>
        <Route
          element={
            <>
              <NewNavigationBarDissabled />
              <Edit3p Auth={() => {}} />
            </>
          }
          path="/edit_profile_for_v3_product_dce"
        ></Route>
      </Routes>
    </div>
  );
}

export function Ex() {
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();

  const Auth = () => {
    Axios.get("/api/verify/authenticateduser")
      .then((res) => {
        if (res.status === 200) {
          console.log("workd");
          setAuthenticated(true);
          navigate("/");
        } else {
          localStorage.clear();
        }
      })
      .catch((err) => {
        if (err) {
          localStorage.clear();

          console.log(err);
        }
      });
  };
  useEffect(() => {
    Auth();
  }, []);

  return (
    <div className="container-fluid p-0">
      {/* <NewNavigationBarDissabled /> */}
      <NewNavigationBar />
      <div className="">
        <div className="col-12">
          {/* Include /lobby to acces these routes */}
          <GlobalErrorHandler>
            <Routes>
              <Route element={<Home />} path="/"></Route>
              <Route element={<NotFount />} exact path="/*"></Route>
              <Route element={<Vertual />} path="virtual_secretary"></Route>
              <Route
                element={<Categories />}
                path="services_categories"
              ></Route>
              <Route element={<UserFeatures />} path="user_features"></Route>
              <Route
                element={<ConSubcategory />}
                path="services_sub_categories"
              ></Route>
              <Route
                element={<ConSubSubcategory />}
                path="services_brand_categories"
              ></Route>
              <Route
                element={<SearchResultBrand free={true} />}
                path="services_result_by_brand"
              ></Route>
              <Route element={<SearchResult />} path="services_result"></Route>
              <Route
                element={<Search visibility={"public"} />}
                path="search"
              ></Route>
              <Route element={<ProductList />} path="products_result"></Route>
              <Route element={<SearchResult />} path="Result"></Route>
              <Route element={<InformationConRes />} path="Result2"></Route>
              <Route element={<CommingSoon />} path="coming_soon"></Route>
              <Route element={<Subscrption />} path="subscription_fees"></Route>
              <Route element={<Feedback />} path="suggestions"></Route>
              <Route
                element={<ProductMainCategory />}
                path="products_categories"
              ></Route>
              <Route
                element={<ProductSubCategory />}
                path="products_sub_categories"
              ></Route>
              <Route
                element={<ProductSubSubCategory />}
                path="products_brand_categories"
              ></Route>
              <Route element={<Pextenalview />} path="products_profile"></Route>
              <Route
                element={<ExternalPview />}
                path="services_profile"
              ></Route>

              <Route
                element={<ListBrand />}
                path="products_result_by_brand"
              ></Route>
              <Route element={<Quickfix />} path="quick_fix"></Route>
              <Route element={<ProductList />} path="products_result"></Route>
              <Route
                element={<InformationProRes />}
                path="products_Result2"
              ></Route>
              <Route element={<Job />} path="jobs"></Route>
              <Route element={<ChatFinal />} path="cubicles"></Route>
              <Route
                element={<Informationview2 />}
                path="my_active_profile"
              ></Route>
              <Route element={<About />} path="inception"></Route>
              <Route
                element={<CancelationPolicies />}
                path="/cancellation"
              ></Route>
              <Route element={<Disclimer />} path="/Disclaimer"></Route>
              <Route element={<Privacy />} path="/Privacy"></Route>
              <Route element={<Termsofuse2 />} path="/Termsofuse"></Route>
              <Route
                element={<Propostlist1ext />}
                path="/pro_featured_vendors"
              ></Route>
              <Route
                element={<Propostlistext />}
                path="/pro_featured_consultants"
              ></Route>
              <Route element={<ProForum Auth={Auth} />} path="/pro-forum" />

              <Route element={<Newfind />} path="/my-pro-forums" />
              <Route element={<Newidfind />} path="/Newidfind" />
              <Route
                element={<Information_Category_list />}
                path="/Information_Category"
              />
              <Route
                element={<Information_Subcategory_list />}
                path="/information_subcategory"
              />
              <Route
                element={<Information_Subsubcategory_list />}
                path="/information_sub_sub_category"
              />
              <Route
                element={<Information_listing />}
                path="/Information_listing"
              />
              <Route
                element={<Information_Type1 />}
                path="/Information_profile"
              />
              <Route
                element={<Information_Medical_Store />}
                path="/Information_medical_store"
              />
              <Route
                element={<Information_Medical_Store_profile />}
                path="/Information_medical_store_profile"
              />
            </Routes>
          </GlobalErrorHandler>
        </div>
      </div>
    </div>
  );
}
