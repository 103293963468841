import React, { useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BiDotsVerticalRounded } from "react-icons/bi";
import axios from "axios"
import { ChatContext } from '../../Components/ChatFinal/ChatFinal'
let w 

export function ChatTab(props) {
  let chatContext = useContext(ChatContext)

  const ForwardTo = (e) => {
    console.log(e.target.id)
    if (e.target.id != 'ft832737') {
      return
    }
    if (props.data.isGroupChat) {
      var admin = false
      let lock = false
      for (let t = 0; t < props.data.groupAdmin.length; t++) {
        if (props.data.groupAdmin[t] === localStorage.getItem('User')) {
          admin = true
        }
      }
      chatContext.setRoomDetails({ id: props.data._id, name: props.data.chatName, group: true, admin, lock })
      // props.Chatwith(props.data._id, props.data.chatName, true, admin,lock)
    } else {
      let lock = false
      console.log(props.data.ChatStatus)
      // for (let t = 0; t < props.data.users.length; t++) {
      //   if (props.data.users[t]._id !== localStorage.getItem('User')) {
      //     console.log('lp')
      //     if(props.data.users[t].Chat){
      //       console.log('locked')
      //       lock = true
      //     }else{
      //       console.log('unlocked') 
      //     }
      //   }
      // } 
      chatContext.setRoomDetails({ id: props.data._id, name: props.data.chatName, group: false, admin: true, lock: !props.data.ChatStatus })
      // props.Chatwith(props.data._id, props.data.chatName, false, true,!props.data.ChatStatus)
    }
    if (w <= 991) {
      var left = document.getElementById('leftRoll')
      left.classList.add('d-none')
    }
  }

  function button() {
    w = window.innerWidth
    if (w > 991) {
      document.getElementById("chatGo").classList.add("d-none");
      var left = document.getElementById('leftRoll')
      left.classList.remove('d-none')
    } else {
      document.getElementById("chatGo").classList.remove("d-none");
    }
  }
  useEffect(() => {
    button()
  })

  window.onresize = button;

  const RemoveFromChatList = async (id) => {
    var remove = await axios.post("/api/User/RemoveFromChatList", { id });
    if (remove.status === 200) {
      props.Chatwith('', '', false, false)
      props.chatuserListLoad()
    }
    else {

    }
  }

  return (
    <div className="chatTabpro" onClick={(e) => ForwardTo(e)}>
      <div className="container-fluid  m-0 p-2 row w-100">
        <div id='ft832737' className="col-2 p-0 pt-2">
          {" "}
          <img id='ft832737'
            src={props.data.DP}
            alt="avatar"
            className="rounded-circle wkdcyki4fyvlk5yvy d-flex align-self-center me-3 shadow-1-strong "
            width="50"
            height="50"
            onError={({ currentTarget }) => {
              currentTarget.onerror = true; // prevents looping
              props.data.isGroupChat ?
                currentTarget.src =
                "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/groupIcon.png"
                :
                currentTarget.src =
                "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png"
            }}
          />
        </div>

        <div className="col-10 ps-0">
          <div className="row">
            <div id='ft832737' className="col-8 py-3">{props.data.chatName}</div>
            {/* <div className="col-4 text-end pe-0">Yesterday</div> */}
            <div className="col-4 text-end pe-0 ">
              <div className="dropdown ft832737">
                <button className=" btn ft832737 pt-2" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                  <BiDotsVerticalRounded className="ft832737" color="white" size={40} />
                </button>
                <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton2">
                  <li>
                    <a className="dropdown-item" onClick={(e) => RemoveFromChatList(props.data._id)} >
                      Remove from chat
                    </a>

                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="m-1">Hello wolrd ! this is last mess</div> */}
        </div>
      </div>
      <hr className=" invisible mt-2" />
    </div>
  );
}
