import React, {  useState, useRef } from 'react'
import './ChatFinal.css'
import { ChatLeft } from "../../Pages/chatfree/chatLeft";

// import { ChatList } from '../ChatList/ChatList';
// import { ChatMessage } from '../ChatMessage/ChatMessage';
// import { ChatConference } from '../ChatConference/ChatConference';
// import { ChatPrevious } from '../ChatPrevious/ChatPrevious';
// import { ChatBlank } from '../ChatBlank/ChatBlank';
import Chat from '../../Pages/chatfree/chat';

export function ChatFinal(props) {

    const [Reload, setReload] = useState(true)
    const childRef = useRef(null);
    const chatLeftList = useRef(null);

  

    async function ReloadList() {
        chatLeftList.current.chatuserListLoader()
    }

    const Chatwith = (id, name, group, admin) => {
        childRef.current.changeMessage(id, group, admin, name)
     };
    return (

        <div className='pt-3'>
            <div className="  mainCon  bg-light">
                <div className="row w-100 m-0">
                    {/* chat left */}
                    <ChatLeft
                        ref={chatLeftList}
                        Reload={Reload}
                        Chatwith={Chatwith}                      
                    />
                    <Chat
                        ReloadList={ReloadList}
                        setReload={setReload}
                        Reload={Reload}
                        ref={childRef}
                        socket={props.socket} />
                </div>
                {/* <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#createroom" href="#">Create Conference Room</a></li> */}
            </div>
        </div>
    )
}
