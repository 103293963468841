import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router";
import "./globalErrorHandler.style.css";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const isBuild = process.env?.NODE_ENV === "production";
  return (
    <div className="main-error-div">
      <div role="alert" className="global-error-wrap">
        <div>
          <span>{isBuild ? "Something went wrong!" : error.message}</span>
        </div>
        <button
          type="button"
          className="btn pro-spaces-button3 px-4 mt-3"
          onClick={resetErrorBoundary}
        >
          Go to Lobby
        </button>
      </div>
    </div>
  );
};

export const GlobalErrorHandler = ({ children }) => {
  const navigate = useNavigate();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        navigate("/lobby", { replace: true });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
