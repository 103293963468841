import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function displayRazorpay(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export function PaymentNewProfile(props) {
  useEffect(() => {
    props.Auth();
  }, []);
  let navigate = useNavigate();
  async function RazorPay() {
    const res = await displayRazorpay(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("failed sdk to load");
      return;
    }
    var tx = {
      Coupon,
    };
    axios
      .post("/api/payment/CreateNewProfilesPayment", {
        method: "POST",
        body: Coupon,
      })
      .then((t) => {
        var data = t.data;
        console.log(data);
        var options = {
          key: process.env.REACT_APP_KEY_ID, // Enter the Key ID generated from the Dashboard S6PuOik0Pm6t7RcAyX8Ws5i2
          amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "PROchure",
          //  "description": 1,

          order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            success(response);
          },

          theme: {
            color: "#55374a",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
        // document.getElementById('rzp-button1').onclick = function (e) {
        rzp1.open();
        // e.preventDefault();
      });
    function success(res) {
      alert("Congratulations....You are now a PRO- member !!");
      console.log(res);
      axios
        .post("/api/payment/success", { res })
        .then((res) => {
          if (res.status === 200) {
            return navigate("/");
          } else {
          }
        })
        .catch((err) => {});
    }
  }

  const [couponError, setcouponError] = useState("");
  const [couponsuccess, setcouponsuccess] = useState("");
  const [Coupon, setCoupon] = useState();

  const checkCoupe = async () => {
    setCoupon("");
    setcouponError("");
    setcouponsuccess("");
    let coupon = document.getElementById("coupe").value;
    let dataSend = {
      coupon,
    };
    try {
      if (coupon.length > 5) {
        let result = await axios.post("/api/coupon/check", { dataSend });
        if (result) {
          setCoupon(coupon);
          setcouponError("");
          setcouponsuccess("Coupon applied ! ");
        }
      } else {
        setcouponError("Invalid coupon / coupon withdrawn");
        console.log("first");
      }
    } catch (error) {
      console.log(error.response.data);
      setcouponError(error.response.data);
    }
  };
  return (
    <div className=" container d-flex justify-content-center pt-5">
      <div style={{ maxWidth: "500px" }} className="card  carrrrbuss">
        <div className="container p-1">
          <div className="row ">
            <center>
              {" "}
              <h3 className="eufgiueg mb-3">Make Payment</h3>
            </center>
          </div>
          <div className="row">
            <p className="invisible">
              wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww
            </p>
            <h6 className="eufgiueg">Apply Coupon</h6>
          </div>
          <div className="row d-flex justify-content-center">
            <input type="text" id="coupe" className="textboxdrete"></input>
          </div>
          <div className="row">
            <div className="col"></div>
            <p className="text-success">{couponsuccess ? couponsuccess : ""}</p>
            <p className="text-danger">{couponError ? couponError : ""}</p>

            <div className="col d-flex justify-content-end">
              <button
                onClick={(e) => checkCoupe(e)}
                className="btn wygdiwgdigwidg"
              >
                Apply
              </button>
            </div>
          </div>
          <div className="row mt-2">
            <button className="btn btn-udaguadgouao" onClick={RazorPay}>
              Make Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
