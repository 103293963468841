import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const DetailedServiceInformation = ({ getval }) => {
  const { formType, DSI, Profile } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  var name = "";
  if (Profile === "Company") {
    name = "Facility / ";
  } else {
    name = "";
  }
  return (
    <>
      {DSI ? (
        DSI?.length > 0 ? (
          <></>
        ) : (
          // <Accordion
          //   expanded={expanded === "detailedserviceinformationaccodian"}
          //   onChange={handleChange("detailedserviceinformationaccodian")}
          // >
          //   <AccordionSummary
          //     expandIcon={<ExpandMoreIcon />}
          //     aria-controls="panel1bh-content"
          //     id="panel1bh-header"
          //   >
          //     <Typography sx={{ width: "100%", flexShrink: 0 }}>
          //       <span>
          //         <b> Detailed Service Information</b>
          //       </span>
          //     </Typography>
          //   </AccordionSummary>
          //   <AccordionDetails>
          //     <Typography>
          //       {DSI ? (
          //         DSI?.length > 0 ? (
          //           DSI.map((elem) => {
          //             return (
          //               <>
          //                 <div className="row py-2">
          //                   <div className="col-md-6">
          //                     <span>{name}Service Title</span>
          //                   </div>
          //                   <div className="col-md-6 ">
          //                     <label style={{ color: "var(--blue)" }}>
          //                       {elem.serviceTitle ? elem.serviceTitle : ""}
          //                     </label>
          //                   </div>
          //                 </div>
          //                 <div className="row py-2">
          //                   <div className="col-md-6">
          //                     <span>{name}Service Description</span>
          //                   </div>
          //                   <div className="col-md-6 ">
          //                     <label style={{ color: "var(--blue)" }}>
          //                       {elem.serviceDescription
          //                         ? elem.serviceDescription
          //                         : ""}
          //                     </label>
          //                   </div>
          //                 </div>
          //                 <div className="row py-2">
          //                   <div className="col-md-6">
          //                     <span>Service Fees / Charges</span>
          //                   </div>
          //                   <div className="col-md-6 ">
          //                     <label style={{ color: "var(--blue)" }}>
          //                       {elem.serviceCharge ? elem.serviceCharge : ""}
          //                     </label>
          //                   </div>
          //                 </div>
          //                 <div className="row py-2">
          //                   <div className="col-md-6">
          //                     <span>Payment Terms</span>
          //                   </div>
          //                   <div className="col-md-6 ">
          //                     <label style={{ color: "var(--blue)" }}>
          //                       {elem.paymentTerms ? elem.paymentTerms : ""}
          //                     </label>
          //                   </div>
          //                 </div>
          //                 <hr />
          //               </>
          //             );
          //           })
          //         ) : (
          //           <></>
          //         )
          //       ) : (
          //         <></>
          //       )}
          //     </Typography>
          //   </AccordionDetails>
          // </Accordion>

          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};
