import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import { SpinnerBig } from './spinner'
import { Mask } from "../Utility/mask";
import axios from 'axios';


export const ProfileFinder = () => {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    var Ac = searchParams.get("Ac_id");
    var Profile = searchParams.get("profile");

    console.log(Ac, Profile)

    useEffect(() => {
        axios
            .get("/api/verify/whois")
            .then((res) => {
                console.log(res.data);
                if (res.status === 200) {
                    // setIam({
                    //     iam: res.data.iam,
                    //     status: res.data.status,
                    // });
                    // 
                    if (res.data.iam === "Consultant" || res.data.iam === "Consultantv2" || res.data.iam === "Consultantv3") {
                        navigate(`/services_profile?Ac_id=${Ac}`, { replace: true });
                    } else if (res.data.iam === "Product" || res.data.iam === "Productv2" || res.data.iam === "Productv3") {
                        navigate(`/products_profile?Ac_id=${Ac}`, { replace: true });
                    }
                } else {
                    console.log('222')
                    if (Profile === 'services_profile') {
                        navigate(`/lobby/services_profile?Ac_id=${Ac}`, { replace: true })
                    } else if (Profile === 'products_profile') {
                        navigate(`/lobby/products_profile?Ac_id=${Ac}`, { replace: true })
                    }
                    // navigate("/");
                    // navigate("/lobby/");
                }
            })
            .catch((res) => {
                console.log(res)
                console.log('333')
                if (Profile === 'services_profile') {
                    navigate(`/lobby/services_profile?Ac_id=${Ac}`, { replace: true })
                } else if (Profile === 'products_profile') {
                    navigate(`/lobby/products_profile?Ac_id=${Ac}`, { replace: true })
                }
                // navigate("/");

            });

        return () => {

        }
    }, [])


    // https://www.prochure.in/find_profile?profile=services_profile&&Ac_id=U2FsdGVkX1/wW7JhNB6HUvk4aT/5zapa8vEsIZzMc2QvhAyYLcpOleU40TU8bDwL
    return (

        <>
            <SpinnerBig />
        </>
    )
}
