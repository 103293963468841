import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react"
import { BsArrowLeft } from "react-icons/bs";
import { AiFillFileAdd } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import Spinner from 'react-bootstrap/Spinner';
import Carousel from 'react-bootstrap/Carousel';
import { FolderContext } from "../folder";
import { keyGen } from "../../../Utility/helper";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../chat.css'
const formData = new FormData()
const centerText = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px'
}
const notify = (message, sab) => {
    if (sab) {
        toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.warning(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

}
export const Insider = (props) => {
    let Fcontext = useContext(FolderContext)
    const [Loading, setLoading] = useState(true)
    const [Content, setContent] = useState([])
    const [Folder, setFolder] = useState(props.Folder)

    useEffect(() => {
        FolderExtractor()
        return () => {

        }
    }, [])

    const FolderExtractor = async () => {
        try {
            console.log('reload')
            let Folder = Fcontext.Folder
            let result = await axios.post('/api/User/Cubicle/FolderExtractor', { Folder })
            if (result.data) {
                setLoading(false)
                console.log(result.data)
                setContent(result.data)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }


    return <div className="">
        <div className="p-0">{Loading ? <div className="text-center p-1"><Spinner animation="border" />
            <span style={centerText} className="">Hang on ! Loading ...</span> </div> :
            <>                {
                Content.Files && Content.Files.length === 0 ? <p className="text-center">Folder is Empty</p> : <>

                    <SliderPro FolderExtractor={FolderExtractor} admin={Fcontext.AdminEdit} Edit={false} setContent={setContent} file={Content.Files} />
                </>
            }
            </>
        } </div>
    </div >
}

export const InsiderHeader = () => {

    let Fcontext = useContext(FolderContext)

    const NavigateBack = () => {
        Fcontext.dispatch({ type: "INITIAL" })
    }
    const NewFiles = (e) => {
        console.log('heret')
        Fcontext.dispatch({ type: "NEWFILEUPLOAD" })
    }

    return <div className="container">
        <div className="row ">
            <div className="col-12">
                <BsArrowLeft onClick={(e) => NavigateBack()} size={30} />
                <AiFillFileAdd style={{ 'cursor': 'pointer' ,color:'var(--blue)'}} onClick={(e) => NewFiles()} size={30} />
                {Fcontext.Rdetails.admin &&    <MdModeEditOutline color={Fcontext.AdminEdit ? 'green' : 'var(--blue)'} onClick={(e) => Fcontext.setAdminEdit(!Fcontext.AdminEdit)} style={{ 'cursor': 'pointer' }} size={30} />}
            </div>
        </div>
    </div>
}

export const NewFilesUploader = () => {
    let Fcontext = useContext(FolderContext)
    const [Files, setFiles] = useState([])
    const [Titles, setTitles] = useState([])

    const inputFile = useRef()
    const uploadTrigger = () => {
        inputFile.current.click()
    }
    const upload = (e) => {
        console.log(e.target.files[0])
        let temp = e.target.files
        setFiles([...Files, ...temp])
    }
    const test = (ev) => {
        console.log('File(s) dropped');
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        let temp = []
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    const file = ev.dataTransfer.items[i].getAsFile();
                    console.log(`… file[${i}].name = ${file.name}`);
                    temp = [...temp, file]
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                console.log(`… file[${i}].name = ${ev.dataTransfer.files[i].name}`);
                const file = ev.dataTransfer.items[i].getAsFile();
                temp = [...temp, file]
            }
        }
        setFiles([...Files, ...temp])
    }
    function dragOverHandler(ev) {
        console.log('File(s) in drop zone');
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }
    const check = () => {
        console.log(Files)
    }
    const Titlehandler = (e, index) => {
        console.log(e, index)
        let t = [...Titles]
        t[index] = e
        console.log(t)
        setTitles(t)
    }
    const RemoveFile = (index) => {
        console.log(index)
        let t = [...Titles]
        let tt = [...Files]
        if (t[index]) {
            let c = t.splice(index, 1)

        }
        let cc = tt.splice(index, 1)
        console.log(t)
        console.log(Files)
        console.log(cc)
        setFiles(tt)
        setTitles(t)
    }

    useEffect(() => {
        console.log(Titles)

        return () => {

        }
    }, [Titles])

    useEffect(() => {

        console.log(Files)
        return () => {

        }
    }, [Files])

    const [UploadPrecentage, setUploadPrecentage] = useState(0);

    const UploadNow = () => {
        console.log(Files, Titles)
        console.log(Fcontext.Folder)
        // return
        if (Files.length > 0) {
            // console.log(fil)
            formData.delete('data')
            formData.delete('file')


            Files.forEach(element => {
                formData.append("file", element);

            });

            let data = {
                Titles, folder: Fcontext.Folder
            }
            data = JSON.stringify(data)
            console.log(data)
            formData.append("data", data);


            console.log(formData)
            // return
            ///////////////////////////
            const options = {
                onUploadProgress: (progressEvent) => {
                    console.log(progressEvent)
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor(((loaded / 1000) * 100) / (total / 1000));
                    console.log(percent)
                    // move(percent)/
                    if (percent < 100) {
                        setUploadPrecentage(percent);
                        console.log(UploadPrecentage)
                    }

                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
            axios.post('/api/User/Cubicle/InsertFileToFolder', formData, options).then((res) => {
                console.log(res.data)
                if (res.status == 200) {
                    console.log('Succesfully uploded')
                    notify("Posted Successfully ", true);
                    setUploadPrecentage(100);

                }
                else {
                    console.log('something went wrong')
                    notify("Something went wrong", false);
                }
            }).catch((err) => {
                console.log(err)
                setUploadPrecentage(0);
            })

        }


    }
    return <div>


        <SliderPro Edit={true} setTitles={setTitles} Titlehandler={Titlehandler} RemoveFile={RemoveFile} file={Files} />

        <input type={'file'} className='d-none ' onChange={(e) => upload(e)} accept="/images" ref={inputFile} />
        <div onClick={(e) => uploadTrigger()} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => test(e)} className="bg-pr border my-2 p-4" style={{ 'height': '100px' }} >
            <span className="text-lght" style={centerText}> Drop in files or Click to add File </span>
        </div>
        <div >
            {UploadPrecentage > 0 ? <>        <ProgressBar variant="SOME_NAME" now={UploadPrecentage} label={`${UploadPrecentage}%`} />

            </>
                : ""}

        </div>
        <button type="button" className="btn pro-spaces-button3 float-end" onClick={(e) => UploadNow()} >Upload File  </button>

        {/* progressbar */}

    </div>
}

export const SliderPro = (props) => {
    const [first, setfirst] = useState(true)
    const [Index, setIndex] = useState(undefined)
    const [Current, setCurrent] = useState()
    const [Files, setFiles] = useState([])
    let Fcontext = useContext(FolderContext)
    console.log(props)
    const Arrow = useRef()
    useEffect(() => {
        if (props.Edit) {
            if (props.file.length > 0) {
                if (first) {
                    let tr = []
                    let Title = []
                    props.file.forEach((element, index) => {
                        tr = [...tr, '']

                    });
                    console.log(tr)
                    props.setTitles(tr)
                    setfirst(false)
                }
            } else {
                Fcontext.setAdminEdit(false)
            }
            return () => {
            }
        }
        setFiles(props.file)


    }, [props.file, props.admin])

    const Delete = (e) => {
        try {
            if (props.file.length - 1 === Current) {
                Arrow.current.element.childNodes[2].click()
            }
            props.RemoveFile(e)

        } catch (error) {
            console.log(error)
        }
    }
    const DeleteFromServer = async (e, id, name) => {
        const index = e
        const Folder = Fcontext.Folder
        let cpyFile = props.file
        cpyFile.splice(index, 1)
        let data = {
            index, Folder, id, name
        }
        try {
            let res = await axios.post('/api/User/Cubicle/DeleteFileSingle', { data })
            console.log(res)
            Arrow.current.element.childNodes[2].click()
            props.FolderExtractor()
        } catch (error) {
            console.log(error)
        }
    }
    const TitleUpdater = async (e, index) => {
        console.log(props.file)
        console.log(e.target.value)
        console.log(index)
        let fil = [...Files]
        fil[index].titles = e.target.value
        setFiles(fil)
    }
    const SaveChanges = async () => {
        console.log(Files)
        let id = Fcontext.Folder
        let FileTo = Files.slice()
       
     
        let data = {
            id, File: FileTo
        }
        try {
            console.log(data)
            let res = await axios.post('/api/User/Cubicle/FolderUpdateData', { data })
            console.log(res)
        } catch (error) {
            console.log(error)
        }
        Fcontext.setAdminEdit(false)
    }
    if (props.file) {
        return <><Carousel indicators={false} ref={Arrow} key={Index} onSelect={setCurrent} interval={null} className="specscarosel23876487236 my-2" >
            {props.file.map((elem, index) => {
                if (props.Edit ? elem.type.match(/image/) : elem.filetype.match(/image/)) {
                    return <Carousel.Item className="superFit">
                        {props.Edit && <button onClick={(e) => Delete(index)} className="btn float-end btn-close btn-close-white"></button>}
                        {props.admin && <button onClick={(e) => DeleteFromServer(index, elem.id, elem.filename)} className="btn float-end btn-close btn-close-white"></button>}
                        <img className="carItem" src={props.Edit ? URL.createObjectURL(elem) : elem.url} alt="First slide" />
                        {/* <span style={bythe} className="bg-light text-dark">{Current ? Current + 1 : 1}/{props.file.length} </span> */}
                        {props.Edit && <input placeholder='Type here' onChange={(e) => props.Titlehandler(e.target.value, index)} value={elem.titles} className='form-control TilrIn ' ></input>}
                        {props.admin && <textarea rows={1} placeholder='Type here' onChange={(e) => TitleUpdater(e, index)} className='form-control TilrIn '  >{elem.titles}</textarea>}
                        {!props.Edit && !props.admin ? <p className="text-light text-start ps-2">{elem.titles}</p> : <></>}
                    </Carousel.Item>
                } else if (props.Edit ? elem.type.match(/video/) : elem.filetype.match(/video/)) {
                    return <Carousel.Item className="superFit">
                        {props.Edit && <button onClick={(e) => Delete(index)} className="btn float-end btn-close btn-close-white"></button>}
                        <video className="carItem" controls>
                            <source src={props.Edit ? URL.createObjectURL(elem) : elem.url} />
                        </video>
                        {/* <span className="bg-light text-dark">{Current ? Current + 1 : 1}/{props.file.length} </span> */}
                        {props.Edit && <input placeholder='Type here' onChange={(e) => props.Titlehandler(e.target.value, index)} className='form-control TilrIn '  ></input>}
                        {props.admin && <input placeholder='Type here' defaultValue={elem.titles} className='form-control TilrIn '  ></input>}
                        <p className="text-light text-start ps-2">{elem.titles}</p>
                    </Carousel.Item>
                }
            })
            }

        </Carousel >
            <span className="bg-dark bytheBy text-light">{Current ? Current + 1 : 1}/{props.file.length} </span>
            {props.admin && <button type="button" className="btn pro-spaces-button3 float-end" onClick={(e) => SaveChanges()} >Save Changes</button>}
        </>

    } else {
        return <></>
    }
}
export const InsiderHeaderNew = () => {

    let Fcontext = useContext(FolderContext)

    const NavigateBack = () => {
        Fcontext.dispatch({ type: "OPENFOLDER" })
    }
    const NewFiles = (e) => {
        console.log('heret')
        Fcontext.dispatch({ type: "NEWFILEUPLOAD" })
    }
    const AdminEdit = (e) => {

    }
    return <div className="container">
        <div className="row ">
            <div className="col-12">
                <BsArrowLeft onClick={(e) => NavigateBack()} size={30} />
                {/* <AiFillFileAdd style={{ 'cursor': 'pointer' }} onClick={(e) => NewFiles()} size={30} /> */}
                {/* <MdModeEditOutline onClick={(e) => AdminEdit()} style={{ 'cursor': 'pointer' }} size={30} /> */}
            </div>
        </div>

    </div>
}