import React, { useState } from 'react'
import { Container, Row, Col, Card, Button, Modal, Carousel, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import { BsThreeDotsVertical } from 'react-icons/bs';

const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
export const Information_card2 = ({ data, Edit, Get }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var account_type = localStorage.getItem("Account_type")
  var account_id = localStorage.getItem("AccountID")
  var id = data._id
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const Delete = () => {
    Axios.post('/api/User/Information_delete', { id }).then((res) => {
      if (res.status == 200) {
        notify("Deleted successfully", true)
        Get()
        handleClose1()
      }
    }).catch((err) => {
      notify("Something went wrong", false)

    })

  }

  return (
    <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 "><>
      <div className=" card-box p-0" style={{ overflow: 'hidden' }} >
        {
          Edit ?
            account_type === "SUPERADMIN" || account_id === data?.Created_by ?
              <div className='d-flex justify-content-end'>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" style={{ background: 'none', border: 'none' }}>
                    <BsThreeDotsVertical color='black' size={15} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item >
                      <Link className='react-bootstrap-dropdown-item' to={`/lobby/information_forms_edit?k=${data._id}`} style={{ "textDecoration": "none", color: "black" }} >
                        Edit
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(e) => handleShow1()}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              :
              <></>
            : <></>
        }

        <div className="member-card pb-1">
          {
            data?.newImage?.length > 0 ?
              <div className="">
                <Carousel controls={data?.newImage?.length > 1} indicators={false}>
                  {data?.newImage?.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100" src={image.link} alt={`Image ${index + 1}`} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              : ""
          }
          <div className="p-1">
            <h4 className='text-center' >{data.title ? data.title.toUpperCase() : ''}</h4>


            {
              data.description ?
                <span>
                  <p className='m-0 '>Description :</p>
                  <p className='ms-2' style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 5,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}>
                    {data.description ? data.description : ""}
                  </p>
                </span>
                :
                ""
            }


          </div>
          <div className="row mt-2">

            <div className="d-flex justify-content-center align-items-center">

              <button
                type="button"
                className="btn pro-spaces-button3 btn-rounded ps-3 pe-3 "
                onClick={(e) => { handleShow() }}
              >
                View
              </button>



            </div>
          </div>
        </div>
      </div>
    </>


      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className=" card-box p-0" style={{ overflow: 'hidden' }} >
              <div className="member-card pb-1">
                {
                  data?.newImage?.length > 0 ?
                    <div className="">
                      <Carousel controls={data?.newImage?.length > 1} indicators={false}>
                        {data?.newImage?.map((image, index) => (
                          <Carousel.Item key={index}>
                            <img className="d-block w-100" src={image.link} alt={`Image ${index + 1}`} />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                    : ""
                }
                <div className="p-1">
                  <h4 className='text-center' >{data.title ? data.title.toUpperCase() : ''}</h4>


                  {
                    data.description ?
                      <span>
                        <p className='m-0 '>Description :</p>
                        <p className='ms-2'>
                          {data.description ? data.description : ""}
                        </p>
                      </span>
                      :
                      ""
                  }


                </div>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={(e) => Delete()}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
