import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import "./Lobby.css";
import axios from "axios";
import { Search } from "../Search/Search";
import { useNavigate } from "react-router-dom";
import { Mask } from "../../Utility/mask";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Select from "react-select";
import { BiReset } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import AdView from "./adView";
import { Ad_card } from "../Ad_Card/Ad_card";
import ProForum from "../../Pages/ProForum/ProForum";

export function Lobby() {
  let navigate = useNavigate();

  const [first, setfirst] = useState(0);
  const [first2, setfirst2] = useState(0);
  const [first3, setfirst3] = useState(0);
  const [first4, setfirst4] = useState(0);

  const newStyle = {
    paddingTop: "80px",
  };

  const newStyle2 = {
    width: "100%",
    zIndex: 100,
    backgroundColor: "#fff",
  };

  const cardEdge = {
    borderRadius: "10px",
    backgroundColor: "var(--blue)",
    color: "white",
    // border:'1px solid var(--blue)',
    overflow: "hidden",
    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    boxShadow: "none",
  };

  const cardEdgefornewbox = {
    borderRadius: "10px",
    backgroundColor: "white",
    color: "var(--blue)",
    border: "1px solid var(--blue)",
    // border:'1px solid var(--blue)',
    overflow: "hidden",
    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    boxShadow: "none",
  };

  const cardEdge3 = {
    borderRadius: "10px",
    backgroundColor: "transparent",
    // border:'1px solid var(--blue)',
    overflow: "hidden",
    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    boxShadow: "none",
    border: "none",
  };

  const navbar = {
    position: "fixed",
    top: "70px",
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    padding: "10px 5px",
    zIndex: 200,
  };

  const cardEdge2 = {
    borderRadius: "0px",
    overflow: "hidden",
    backgroundColour: "transparent",
  };
  const cardEdge5 = {
    borderRadius: "0px",
    overflow: "hidden",
    backgroundColour: "transparent",
    border: 0,
  };

  const linkStyle = {
    textDecoration: "none",
    color: "var(--blue)",
  };

  useEffect(() => {
    axios
      .get("/api/Free/lobbyconcount", {})
      .then((res) => {
        setfirst(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/Free/lobbyvendorcount", {})
      .then((res) => {
        setfirst2(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/Free/consatewiselist", {})
      .then((res) => {
        setfirst3(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/Free/vensatewiselist", {})
      .then((res) => {
        setfirst4(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [Options, setOptions] = useState([]);
  useEffect(() => {
    axios
      .get("/api/api/getallcategory")
      .then((res) => {
        console.log(res.data);
        let op = res.data?.map((element, index) => {
          return element.sub.map((elm, ind) => {
            let value = elm.sub.toString();
            if (elm.keyword?.length) {
              var sabari = elm.keyword.map((keywordsmap) => keywordsmap.label);
              value = value + "" + sabari.toString();
            }
            let brand;
            if (elm.brand?.length) {
              brand = true;
            } else {
              brand = false;
            }
            return {
              value,
              label: `${elm.sub} [ ${element.category} ]`,
              id: elm._id,
              categoryName: element.category,
              brand,
              type: element.type,
            };
          });
        });
        op = op.flat(1);
        setOptions(op);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const redmik20pro = (id) => {
    let catid = id.id;
    let subcategoryName = id.categoryName;
    if (id.type == true) {
      if (id.brand == true) {
        navigate(
          `/services_brand_categories?brand_id=${Mask.encode(
            catid
          )}&&sub_category=${subcategoryName}`
        );
      } else {
        navigate(`/services_result?Sub_id=${Mask.encode(catid)}`);
      }
    } else if (id.type == false) {
      if (id.brand == true) {
        navigate(
          `/products_brand_categories?brand_id=${Mask.encode(
            catid
          )}&&sub_category=${subcategoryName}`
        );
      } else {
        navigate(`/products_result?Sub_id=${Mask.encode(catid)}`);
      }
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selectedOption) => {
    redmik20pro(selectedOption);
  };

  const handle = (e) => {
    if (e.target.value.length > 2) {
      let elem = document.querySelector(":root");
      elem.style.setProperty("--display", "block  ");
    } else {
      let elem = document.querySelector(":root");
      elem.style.setProperty("--display", "none  ");
    }
  };

  useEffect(() => {
    try {
      rt();

      let element = document.getElementsByClassName("catSubCatSearchBox");
      element[0].children[2].children[0].children[1].children[0].addEventListener(
        "input",
        handle
      );
      element[0].children[2].children[1].classList.add("d-none");
    } catch (error) {
      console.log(error);
    }
    return () => {
      try {
        let element = document.getElementsByClassName("catSubCatSearchBox");
        element[0].children[2].children[0].children[1].children[0].removeEventListener(
          "input",
          handle
        );
      } catch (err) {}
    };
  }, []);

  const rt = () => {
    let elem = document.querySelector(":root");
    elem.style.setProperty("--display", "none ");
  };

  ////// Ad ////////
  const [addivider, setAddivider] = useState({ a: [], b: [], c: [] });

  const [ad, setad] = useState([]);
  useEffect(() => {
    axios
      .get("/api/Free/getAllAd")
      .then((res) => {
        console.log("s");
        console.log(res.data);
        setad(res.data);
        let s = 1;
        for (const key of res.data) {
          let index = res.data.indexOf(key);

          key.index = index;
          // console.o
          switch (s) {
            case 1:
              setAddivider((prev) => ({ ...prev, a: [...prev.a, key] }));
              s += 1;
              break;
            case 2:
              setAddivider((prev) => ({ ...prev, b: [...prev.b, key] }));
              s += 1;
              break;
            case 3:
              setAddivider((prev) => ({ ...prev, c: [...prev.c, key] }));
              s = 1;
              break;
            default:
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Index, setIndex] = useState(null);

  const linktovebsite = (website) => {
    // window.open("http://vipar.in");
    console.log(website);
    // window.open("http://" + website, "_blank");

    if (website.indexOf("http") > -1) {
      window.open(website, "_blank");
    } else {
      window.open("http://" + website, "_blank");
    }
  };

  /////// Ad end ///////

  /////////////////information
  const [Category, setCategory] = useState([]);
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get("/api/api/Information_category_only")
      .then((res) => {
        if (res.status == 200) {
          setCategory(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  ////////////////information end

  return (
    <>
      <div className="bg-white">
        <div
          className="container-fluid ps-0 pe-0 loby-page position-relative"
          style={newStyle}
        >
          {/* <div>
              <Ad_card />
            </div> */}
          <div style={navbar}>
            <div className="row">
              <div className="col-12 col-md-6 mb-2">
                <div
                  style={{
                    display: "inline-block",
                    width: "calc( 100% - 70px )",
                    "margin-right": "10px",
                  }}
                >
                  <Select
                    value={selectedOption}
                    className="catSubCatSearchBox "
                    placeholder="What are you searching for ?"
                    onChange={(e) => handleChange(e)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "#ced4da" : "#ced4da",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#ced4da",

                        primary: "#ced4da",
                      },
                    })}
                    options={Options}
                  />
                </div>
                <button
                  className="btn nfsjfbgevoug pro-spaces-button ps-3 pe-3 pt-1 pb-1  "
                  style={{ borderRadius: "10px" }}
                  type="button"
                >
                  <BiReset size={24} />
                </button>
              </div>
              <div className="col-12 col-md-6 mb-2 ps-md-0">
                <div className="d-flex">
                  <span className="me-2 ms-0">or</span>{" "}
                  <Search
                    pass={true}
                    linkto={"/search"}
                    visibility={"private"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "100px" }}>
            <ProForum />
          </div>
          {/* </div> */}
          {/* <div className="d-block d-lg-none mt-4"> */}
          <div className="d-none">
            <div className="row g-2 gy-3 justify-content-center">
              <div className="col-12">
                <b>CONSULTANT and BUSINESS PROFILES</b>
              </div>
              <div style={{ overflow: "hidden" }} className=" col-6 ">
                <div style={{ width: "117%", height: "1px" }}>
                  <span
                    className="float-end icononlobby"
                    style={{ color: "var(--blue)" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalsabari2wekjfg"
                  >
                    <AiOutlineExclamationCircle />
                  </span>
                  &nbsp;
                </div>
                {/* <div  style={{ 'position': 'relative', 'bottom':'-10px','right':'30px','zIndex':'10000000','color':'white'}}><AiOutlineExclamationCircle className='float-end'/></div> */}
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "var(--blue)" }}
                >
                  <div className="card" style={cardEdgefornewbox}>
                    <div className="card-content">
                      <div className="card-body p-2">
                        <div>
                          <center>
                            {/* <BsFillPersonCheckFill
                              size={22}
                              style={{ marginTop: "-5px" }}
                            /> */}
                            {/* <BsFillPersonCheckFill size={22} style={{marginLeft:'-53px',}}/>&nbsp;&nbsp;<span>SERVICE</span> */}
                          </center>
                          <center>
                            <b className="fontonlobby">SERVICES</b>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div style={{ overflow: "hidden" }} className=" col-6 ">
                <div style={{ width: "117%", height: "1px" }}>
                  <span
                    className="float-end icononlobby"
                    style={{ color: "var(--blue)" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModefveelkfnvalsabari2wekjfg"
                  >
                    <AiOutlineExclamationCircle />
                  </span>
                  &nbsp;
                </div>

                {/* <div  style={{ 'position': 'relative', 'bottom':'-10px' ,'right':'30px','zIndex':'10000000','color':'white'}}><AiOutlineExclamationCircle className='float-end'/></div> */}
                <Link
                  to="/products"
                  style={{ textDecoration: "none", color: "var(--blue)" }}
                >
                  <div className="card" style={cardEdgefornewbox}>
                    <div className="card-content">
                      <div className="card-body p-2 ">
                        <div>
                          <center>
                            {/* <GiShoppingBag
                              size={22}
                              style={{ marginTop: "-5px" }}
                            /> */}
                            {/* <GiShoppingBag size={22} style={{marginLeft:'-33px',}}/>&nbsp;&nbsp;<span>PRODUCT</span> */}
                          </center>
                          <center>
                            <b className="fontonlobby">PRODUCTS</b>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              {Category.length ? (
                <div className="col-12">
                  <b>INFORMATION</b>
                </div>
              ) : (
                <></>
              )}
              {Category ? (
                Category.map((e, index) => {
                  return (
                    <>
                      <div className=" col-6 " id={index}>
                        <Link
                          to={`/information_subcategory?k=${Mask.encode(
                            e._id
                          )}`}
                          style={{
                            textDecoration: "none",
                            color: "var(--blue)",
                          }}
                        >
                          {/* <a data-bs-toggle="modal" data-bs-target="#achiversmodal1"> */}
                          <div className="card" style={cardEdgefornewbox}>
                            <div className="card-content">
                              <div className="card-body p-2 ">
                                <div>
                                  <center>
                                    {/* <TiInfoLarge
                              size={22}
                              style={{ marginTop: "-5px" }}
                            /> */}
                                    {/* <GiShoppingBag size={22} style={{marginLeft:'-33px',}}/>&nbsp;&nbsp;<span>PRODUCT</span> */}
                                  </center>
                                  <center>
                                    <b className="fontonlobby fontonlobbysabbbbsss text-uppercase">
                                      {e.category}
                                    </b>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* </a> */}
                      </div>
                    </>
                  );
                })
              ) : (
                <></>
              )}

              {/* new section end*/}
            </div>
          </div>
          {/* <div className="d-none d-lg-block mb-3 py-4"> */}
          <div className="d-none">
            <div className=" row g-2  justify-content-center">
              <div className="col-12">
                <b>CONSULTANT and BUSINESS PROFILES</b>
              </div>
              <div style={{ overflow: "hidden" }} className=" col-lg-3 ">
                <div style={{ width: "117%", height: "1px" }}>
                  <span
                    className="float-end icononlobby"
                    style={{ color: "var(--blue)" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalsabari2wekjfg"
                  >
                    <AiOutlineExclamationCircle />
                  </span>
                  &nbsp;
                </div>
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "var(--blue)" }}
                >
                  <div className="card" style={cardEdgefornewbox}>
                    <div className="card-content">
                      <div className="card-body p-2">
                        <div>
                          <center>
                            <span>
                              {/* <BsFillPersonCheckFill className="iconsizelobbylg" /> */}

                              <b className="iconlobbytextfont">SERVICES</b>
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div style={{ overflow: "hidden" }} className=" col-lg-3 ">
                <div style={{ width: "117%", height: "1px" }}>
                  <span
                    className="float-end icononlobby"
                    style={{ color: "var(--blue)" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModefveelkfnvalsabari2wekjfg"
                  >
                    <AiOutlineExclamationCircle />
                  </span>
                  &nbsp;
                </div>
                <Link
                  to="/products"
                  style={{ textDecoration: "none", color: "var(--blue)" }}
                >
                  <div className="card" style={cardEdgefornewbox}>
                    <div className="card-content">
                      <div className="card-body p-2">
                        <div>
                          <center>
                            <b className="iconlobbytextfont">
                              {/* <GiShoppingBag className="iconsizelobbylg" /> */}
                              PRODUCTS
                            </b>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              {Category.length ? (
                <div className="col-12">
                  <b>INFORMATION</b>
                </div>
              ) : (
                <></>
              )}
              {Category ? (
                Category.map((e, index) => {
                  return (
                    <>
                      <div
                        style={{ overflow: "hidden" }}
                        className=" col-lg-3 "
                      >
                        <div style={{ width: "117%", height: "1px" }}>
                          <span className="float-end icononlobby invisible">
                            <AiOutlineExclamationCircle />
                          </span>
                          &nbsp;
                        </div>
                        <Link
                          to={`/information_subcategory?k=${Mask.encode(
                            e._id
                          )}`}
                          style={{
                            textDecoration: "none",
                            color: "var(--blue)",
                          }}
                        >
                          {/* <a data-bs-toggle="modal" data-bs-target="#achiversmodal1"> */}
                          <div className="card" style={cardEdgefornewbox}>
                            <div className="card-content">
                              <div className="card-body p-2">
                                <div>
                                  <center>
                                    <b className="iconlobbytextfont text-uppercase ">
                                      {/* <TiInfoLarge className="iconsizelobbylg" /> */}
                                      {e.category}
                                    </b>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* </a> */}
                      </div>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* <div className={"d-none d-lg-block  mb-3"}> */}
          <div className="d-none">
            <div className=" row g-2  justify-content-center">
              <div className=" col-lg-4 col-xl-3 ">
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "var(--blue)" }}
                >
                  <div className="card" style={cardEdge3}>
                    <Carousel
                      interval={1500}
                      indicators={false}
                      controls={false}
                    >
                      <Carousel.Item>
                        <img
                          className="d-block w-100 card-img-top p-0"
                          style={{ objectFit: "fill" }}
                          src="/con1.jpeg"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100 card-img-top p-0"
                          style={{ objectFit: "fill" }}
                          src="/con2.jpeg"
                        />
                      </Carousel.Item>
                      {/* <Carousel.Item>
                    <img className='d-block w-100'  className="card-img-top" src='./3.jpg' />
                  </Carousel.Item> */}
                    </Carousel>
                  </div>
                </Link>
              </div>
              <div className=" col-lg-4 col-xl-3 ">
                <Link
                  to="/products"
                  style={{ textDecoration: "none", color: "var(--blue)" }}
                >
                  <div className="card" style={cardEdge3}>
                    <Carousel
                      interval={1500}
                      indicators={false}
                      controls={false}
                    >
                      <Carousel.Item>
                        <img
                          className="d-block w-100 card-img-top p-0"
                          style={{ objectFit: "fill" }}
                          src="/ven1.jpeg"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100 card-img-top p-0"
                          style={{ objectFit: "fill" }}
                          src="/ven2.jpeg"
                        />
                      </Carousel.Item>
                      {/* <Carousel.Item>
                    <img className='d-block w-100'  className="card-img-top" src='./3.jpg' />
                  </Carousel.Item> */}
                    </Carousel>
                  </div>
                </Link>
              </div>
              <div className=" col-lg-4 col-xl-3 ">
                {/* <Link to="/essential_information" style={{'textDecoration':'none','color':'var(--blue)'}} > */}
                <a data-bs-toggle="modal" data-bs-target="#achiversmodal1">
                  <div className="card" style={cardEdge3}>
                    <Carousel
                      interval={1500}
                      indicators={false}
                      controls={false}
                    >
                      <Carousel.Item>
                        <img
                          className="d-block w-100 card-img-top p-0"
                          style={{ objectFit: "fill" }}
                          src="/info1.jpeg"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100 card-img-top p-0"
                          style={{ objectFit: "fill" }}
                          src="/info2.jpeg"
                        />
                      </Carousel.Item>
                      {/* <Carousel.Item>
                    <img className='d-block w-100'  className="card-img-top" src='./3.jpg' />
                  </Carousel.Item> */}
                    </Carousel>
                  </div>

                  {/* </Link> */}
                </a>
              </div>
            </div>
          </div>
          <div className="row g-3 justify-content-center d-none">
            {/* <center><h5 style={{'color':'var(--blue)'}}>This Week's FEATURED Consultants and Vendors</h5></center> */}

            <div className="d-none">
              <div className="col-12 col-sm-6 col-lg-4 col-xxl-3 d-block d-lg-none">
                <div className="card" style={cardEdge2}>
                  <Carousel interval={1500} indicators={false} controls={false}>
                    <Carousel.Item>
                      <img className="d-block w-100" src="/con1.jpeg" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="/ven1.jpeg" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="/info1.jpeg" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="/con2.jpeg" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="/ven2.jpeg" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="/info2.jpeg" />
                    </Carousel.Item>
                  </Carousel>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade elfkbnleribndnvlrwnkvwevdherio"
            id="achiversmodal1chenda"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="d-flex justify-content-end">
                      <button
                        id="achiversmodalbtn"
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <br className="mb-2" />
                  {first3 ? (
                    first3.map((element, index) => {
                      return (
                        <div className="row mb-3">
                          <div className="col-2">{index + 1}.</div>
                          <div className="col-7">{element._id}</div>
                          <div className="col-3">
                            :&nbsp;&nbsp;&nbsp;{element.count}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <center>
                      <h6>Nothing to show !</h6>
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade elfkbnleribndnvlrwnkvwevdherio"
            id="achiversmodal1chenda2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="d-flex justify-content-end">
                      <button
                        id="achiversmodalbtn"
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <br className="mb-2" />
                  {first4 ? (
                    first4.map((element, index) => {
                      return (
                        <div className="row mb-3">
                          <div className="col-2">{index + 1}.</div>
                          <div className="col-7">{element._id}</div>
                          <div className="col-3">
                            :&nbsp;&nbsp;&nbsp;{element.count}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <center>
                      <h6>Nothing to show !</h6>
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} backdrop={"static"} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {ad[Index]?.Account_id.Type === "Consultant" ||
            ad[Index]?.Account_id.Type === "Consultantv2" ||
            ad[Index]?.Account_id.Type === "Consultantv3" ? (
              <Link
                to={
                  "/services_profile?Ac_id=" +
                  Mask.encode(ad[Index]?.userData[0]?._id)
                }
              >
                <button type="button" className="btn pro-spaces-button3">
                  Visit this Profile
                </button>
              </Link>
            ) : (
              <Link
                to={
                  "/products_profile?Ac_id=" +
                  Mask.encode(ad[Index]?.userData[0]?._id)
                }
              >
                <center>
                  {" "}
                  <button type="button" className="btn pro-spaces-button">
                    Visit this Profile
                  </button>
                </center>
              </Link>
            )}

            {ad[Index]?.userData[0]?.website?.length ? (
              <>
                <br />
                <center>
                  <button
                    type="button"
                    className="btn pro-spaces-button"
                    onClick={(e) =>
                      linktovebsite(ad[Index]?.userData[0]?.website)
                    }
                  >
                    Visit their Website
                  </button>
                </center>
              </>
            ) : (
              <></>
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
