import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./recruitPostForm.css";
import AddQ from "./addQ";
import { keyGen } from "../../Utility/helper";
import { notify } from "../../Utility/notify";
// import './jobPostForm.css'

export function ConsultFaq(props) {
  let [searchParams, setSearchParams] = useSearchParams();

  const [welcome, setwelcome] = useState();
  useEffect(() => {
    props.Auth();
    console.log(searchParams.get("welcome"));
    if (searchParams.get("welcome")) {
      setwelcome(true);
    } else {
      setwelcome(false);
    }
  }, []);
  let navigate = useNavigate();
  const [faqval, setfaqval] = useState(false);

  const sab = () => {
    //     console.log(Deleted)
    //     var final = []

    //     for (var i = 0; i < Screenquestions.length; i++) {

    //         if (Deleted.indexOf(i) == -1) {
    //             if (Screenquestions[i].Question.length >= 1) {
    //                 final.push(Screenquestions[i])
    //             }

    //         }
    //         // final.forEach(element => {
    //         //     if(ele)
    //         // });

    //     }
    if (!faqval) {
      axios
        .post("/api/ActiveUser/ConProfileFaq", { Questions })
        .then(() => {
          notify("Created", true);
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          notify("Something went wrong !", false);
        });
    } else {
      axios
        .put("/api/ActiveUser/ConProfileFaq", { Questions })
        .then(() => {
          notify("Updated", true);
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          notify("Something went wrong !", false);
        });
    }
  };

  useEffect(() => {
    axios
      .get("/api/ActiveUser/ConProfileFaq")
      .then((res) => {
        // if (res.data.FAQquestions.length > 1)
        setEdit(true);
        console.log(res.data);
        setQuestionCount(res.data.FAQquestions.map((e) => keyGen()));
        setQuestions(res.data.FAQquestions);
        setfaqval(res.data.FAQquestions.length > 0 ? true : false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setload(false);
      });
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //ends

  const [Questions, setQuestions] = useState([]);
  const [Error, setError] = useState("");
  const [load, setload] = useState(true);
  const [Edit, setEdit] = useState(false);
  const deleteMe = (index) => {
    console.log(index);
    let temp = [...Questions];
    console.log(temp.splice(index, 1));
    setQuestions(temp);
    let tempC = [...QuestionCount];
    console.log(tempC.splice(index, 1));
    setQuestionCount(tempC);
  };
  const saveMe = (data, index) => {
    let temp = [...Questions];
    temp[index] = data;
    setQuestions(temp);
    // sab()
  };
  const Addmore = () => {
    if (validator(Questions) == true) {
      setQuestionCount((prev) => [...prev, keyGen()]);
    }
  };
  const validator = () => {
    try {
      for (const key of Questions) {
        if (key?.length === 0) {
          setError("PLEASE Enter a question");
          return false;
        }
      }
      setError("");
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  const [QuestionCount, setQuestionCount] = useState([]);
  if (!load) {
    return (
      <div className="p-1 pt-4">
        <div className="container">
          <h3 className="mt-5 pt-3 mb-3 text-center">KNOW MY CLIENT</h3>
          {welcome && (
            <Link
              to="/my_profile_services"
              className="btn pro-spaces-button float-end mt-1 mb-2 me-1"
            >
              SKIP
            </Link>
          )}
          <br />
          <br />

          <p>
            To the mutual benefit of both the Service Providers and their
            clients, ‘Know My Client’ is a feature where the Service Providers
            have the option to prepare a set of questions that their prospective
            clients must answer when the client clicks on the Communicate button
            on the profile of the service provider. This feature is available
            only for service providers.
          </p>
          <p>
            Your list of questions will be sent as a direct message to your
            client’s inbox.
          </p>
          <p>
            These questions can either be deleted or be replaced with new ones
            at any time.
          </p>

          {Questions.length == 0 && (
            <button
              id="addQbtn"
              className="btn btnskip float-start mt-1 mb-2 ms-1"
              onClick={() => Addmore()}
              htmlFor="exampleInputEmail1"
            >
              Add a question
            </button>
          )}

          {QuestionCount.map((e, index) => {
            return (
              <AddQ
                key={e}
                Edit={Edit}
                questions={Questions}
                index={index}
                deleteMe={deleteMe}
                saveMe={saveMe}
              />
            );
          })}

          {/* {
                    Screenquestions.length == 0 && <button id='addQbtn' className='btn btnskip float-start mt-1 mb-2 ms-1' onClick={() => addQuestionOnScreen()} htmlFor="exampleInputEmail1" >Add a question</button>}
                {Qall ? Qall : 'Something Went Wrong'} */}
          {Questions.length > 0 ? (
            <>
              <p className="text-danger">{Error}</p>
              <div className="container p-0">
                <div className="row g-1">
                  <div className="col-md-6 col-sm-12 justify-content-start">
                    <button
                      id="addQbtn"
                      className="btn btnskip  "
                      onClick={() => Addmore()}
                      htmlFor="exampleInputEmail1"
                    >
                      Add Questions
                    </button>
                  </div>
                  <div className="col-sm-12 py-4 justify-content-center">
                    <button
                      className="btn btnskip float-end "
                      onClick={(e) => sab(e)}
                    >
                      {" "}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
