import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  createContext,
  useContext
} from 'react'
import Map from './Maps'
import { keyGen } from '../../Utility/helper'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { EditContext } from '../CreateConseltent/FormConsultent'
let count = 0

let done = false
export default function MapWithSwitch2(props) {
  const Edit = useContext(EditContext)
  const [AddingLocation, setAddingLocation] = useState(false)

  const [location, setlocation] = useState({
    lat: false, 
    lng: false,
    status: false
  })


  useEffect(() => {
    if (props.Location)
      setlocation(props.Location)

    return () => {

    }
  }, [props])


  useEffect(() => {
    console.log(location)

    return () => {

    }
  }, [location])

  let infos = ''

  const [status, setStatus] = useState(false)
  const [mapPosition, setmapPosition] = useState({
    lng: '',
    lat: ''
  })
  const handleClose = () => {
    setAddingLocation(false)
    // console.log(+)
    setTimeout(() => {
      setlocation(infos)
      props.setLocation(infos)
    }, 400)
  }
  const handleCloseOnly = () => {
    console.log('closeonly')
    setAddingLocation(false)
  }
  const handleShow = () => setAddingLocation(true)
  useEffect(() => {
    console.log(infos)
    return () => { }
  }, [AddingLocation])

  const [key, setkey] = useState(keyGen())

  useEffect(() => {
    console.log(props)
    if (!done) {
      if (Edit) {
        console.log(props)
        if (Edit[0].Location_Coordinates) {
          // setlocation(Edit[0].Location_Coordinates)
          // setStatus(Edit[0].Location)
          done = true
        }
      }
    }
    return () => { }
  }, [props])

  const setInfo = e => {
    console.log(e)
    infos = e
  }

  useEffect(() => {
    return () => {
      done = false
      console.log('doneeeeee')
    }
  }, [])

  const doit = () => {
    handleShow()
    console.log(props)
  }
  const handleRemove = () => {
    setAddingLocation(false)
    setTimeout(() => {
      infos = ''
      setlocation({
        lat: false,
        lng: false,
        status: false
      })
      props.setLocation({
        lat: false,
        lng: false,
        status: false
      })
    }, 400);
  }
  useEffect(() => {
    console.log(AddingLocation)
    return () => { }
  }, [AddingLocation])

  return (
    <div>
      <button
        type='button'
        style={{ fontSize: '12px' }}
        className='btn pro-spaces-button mt-2'
        onClick={e => {
          doit()
        }}
      >
        GPS LOCATION
      </button>
      <Modal id='mapwithswitche' show={AddingLocation} onHide={handleCloseOnly} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title>Choose a Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '400px' }} className='mt-3'>
            <Map
              key={key}
              center={location}
              height='300px'
              zoom={10}
              setmapPosition={setInfo}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn pro-spaces-button' onClick={() => handleRemove()}>Remove</button>
          <button className='btn pro-spaces-button' onClick={() => handleClose()}>Continue</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
