import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./NewNavigationBar.css";

import { Notification } from "../../../Components/Notifications/Notification";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { AiOutlineClose } from "react-icons/ai";
import {
  AiFillHome,
  AiFillRobot,
  AiOutlineMenu,
  AiOutlineFileSearch,
  AiOutlineExclamationCircle,
  AiFillInfoCircle,
} from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { MdFeaturedPlayList, MdFeed, MdLanguage } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { GiShoppingBag, GiAwareness } from "react-icons/gi";
import { SiContactlesspayment } from "react-icons/si";
import { RiToolsFill, RiUserSettingsFill } from "react-icons/ri";
import {
  BsFillPersonCheckFill,
  BsFillBriefcaseFill,
  BsInfoLg,
  BsFillBellFill,
  BsFillFileEarmarkPersonFill,
  BsChatQuoteFill,
} from "react-icons/bs";

import { IoMdColorPalette, IoIosSettings } from "react-icons/io";
import { IoClose, IoSettingsSharp } from "react-icons/io5";
import { HiLightBulb } from "react-icons/hi";
import { BiMoviePlay, BiMedal } from "react-icons/bi";
import { Speaker } from "../../../Components/speaker/speaker";
import { TiInfoLarge } from "react-icons/ti";

export function NewNavigationBar(props) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [state, setstate] = useState(true);
  let navigate = useNavigate();

  const [chat, setChat] = useState();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    console.log(localStorage.getItem("Chat"));
    if (localStorage.getItem("Chat") === "true") {
      setChat(true);
      console.log("first");
    } else if (localStorage.getItem("Chat") === "false") {
      setChat(false);
      console.log("first2");
    }
    return () => {};
  }, []);
  let location = useLocation();
  const settingChat = () => {
    setChat(!chat);
    axios
      .post("/api/User/chatSettings", { status: !chat })
      .then((res) => {
        console.log(res);
        localStorage.setItem("Chat", !chat);
        if (
          location.pathname == "/services_profile" ||
          location.pathname == "/products_profile"
        ) {
          try {
            document.getElementById("prochureButtonSpecialPviewRe").click();
          } catch (error) {}
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#fff",
      "&:hover": {
        backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#5BC236",
    },
  }));

  const notify = (message) => {
    toast.warning(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const logout = (e) => {
    axios
      .post("/api/Logout")
      .then((res) => {
        // alert('logout')
        localStorage.clear();
        return navigate("/signin");
      })
      .catch((re) => {});
  };

  function openNav() {
    if (state) {
      setstate(false);
      document.getElementById("mySidenav").style.width = "250px";
    } else {
      setstate(true);
      document.getElementById("mySidenav").style.width = "0";
    }
  }

  const ShowAccounts = () => {
    props.ShowAccounts();
  };

  const [first, setfirst] = useState(false);

  useEffect(() => {
    axios
      .get("/api/User/getnotifoenav")
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setfirst(true);
        } else {
          setfirst(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sabari = (e) => {
    openNav();
    var Notiview = 1;
    axios
      .post("/api/User/updatenoti", {
        Notiview,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const openNavlan = () => {
    document.getElementById("googleTommyBtn").click();
    openNav();
  };

  const checkerkuttapan = () => {
    axios
      .get("/api/ActiveUser/accountswitch")
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200) {
          if (res.data.length >= 15) {
            notify("You have reached the maximum limit of profiles");
          } else {
            navigate("/new_profile");
          }
        }
      })
      .catch((res) => {});
  };

  const navsabari = () => {
    document.getElementById("dismissmodalforpassiveinnavbar").click();
    openNav();
  };

  return (
    <>
      <nav class="navbar newNavBackground ">
        <div class="container-fluid">
          <div className="eihfiwfhih navbar-brand ">
            <Link to="/" style={{ textDecoration: "none" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                color="fff"
                width="240"
                viewBox="0 0 906 258"
              >
                <defs></defs>
                <text
                  id="PRO-SPACES"
                  className="cls-8"
                  transform="translate(37 155.05) scale(1.172 1.069)"
                >
                  PROchure
                  <tspan font-size="0.5em" baseline-shift=".8em">
                    ®
                  </tspan>
                </text>
              </svg>
            </Link>
            <span style={{ marginLeft: "-75px" }}>
              <Speaker />
            </span>
          </div>
          <form class="toogleButton">
            <button
              className="btn pe-2 pt-0 ps-0"
              type="button"
              id="sabarivrtst"
              onClick={() => openNav()}
            >
              {" "}
              {state ? (
                <AiOutlineMenu size="30" className="floattog" />
              ) : (
                <AiOutlineClose size="30" className="floattog" />
              )}
            </button>
          </form>
        </div>
      </nav>

      <div id="mySidenav" className="sidenav">
        {/* <div className='d-flex justify-content-end pe-3 closeinto'><AiOutlineClose onClick={() => openNav()} /></div> */}

        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a>
                  <CgProfile className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a style={{ width: "210px", wordWrap: "break-word" }}>
                  {localStorage.getItem("pname")
                    ? localStorage.getItem("pname").toUpperCase()
                    : ""}
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#staticBackdrop"
                  aria-controls="offcanvasRight"
                  onClick={(e) => sabari(e)}
                >
                  <BsFillBellFill className="sidenavicon" />
                  {first ? (
                    <AiFillInfoCircle className="ksjdbgvkjaviubeiwuvcmewiobv" />
                  ) : (
                    ""
                  )}
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#staticBackdrop"
                  aria-controls="offcanvasRight"
                  onClick={(e) => sabari(e)}
                >
                  &nbsp;NOTIFICATIONS
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/" onClick={() => openNav()}>
                  <AiFillHome className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/" onClick={() => openNav()}>
                  &nbsp;LOBBY, PRO-FORUM
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/virtual_secretary" onClick={() => openNav()}>
                  <AiFillRobot className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/virtual_secretary" onClick={() => openNav()}>
                  &nbsp;VIRTUAL SECRETARY
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalewgvougwouegv"
                  onClick={() => openNav()}
                >
                  <RiUserSettingsFill className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalewgvougwouegv"
                  onClick={() => openNav()}
                >
                  &nbsp;ACCOUNT SETTINGS
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  id="quicksettingmodalatag"
                  data-bs-toggle="modal"
                  data-bs-target="#quickSetings"
                  onClick={() => openNav()}
                >
                  <IoSettingsSharp className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#quickSetings"
                  onClick={() => openNav()}
                >
                  &nbsp;QUICK SETTINGS
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          <center>information.&nbsp;&nbsp;&nbsp;interaction.</center>
        </div>

        <div className="row mt-1">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link
                  to="/services"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  <BsFillPersonCheckFill className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link
                  to="/services"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  &nbsp;SERVICES
                </Link>
              </div>
              {/* <div className="col-5 p-0 mx-0 hai">
                <span className="invisible">abcdefghijklmnop</span>&nbsp;
                <AiOutlineExclamationCircle
                  style={{ marginTop: "-58px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalsabari2wekjfg"
                  onClick={() => openNav()}
                />
              </div> */}
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link
                  to="/products"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  <GiShoppingBag className="sidenavicon" />
                </Link>
              </div>
              <div className="col-5 p-0 mx-0 hai">
                <Link
                  to="/products"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  &nbsp;PRODUCTS
                </Link>
              </div>
              {/* <div className="col-5 p-0 mx-0 hai">
                <span className="invisible">abcdefghijklm</span>
                <AiOutlineExclamationCircle
                  style={{ marginTop: "-45px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModefveelkfnvalsabari2wekjfg"
                  onClick={() => openNav()}
                />
              </div> */}
            </div>{" "}
          </div>
        </div>
        {/* <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#achiversmodal1" onClick={() => openNav()}><TiInfoLarge className="sidenavicon" /></a></div><div className="col-4 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#achiversmodal1" onClick={() => openNav()}>&nbsp;UTILITIES & EMERGENCY</a></div></div>   </div>
        </div>
        <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#achiversmodal1" onClick={() => openNav()}><TiInfoLarge className="sidenavicon" /></a></div><div className="col-4 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#achiversmodal1" onClick={() => openNav()}>&nbsp;GENERAL</a></div></div>   </div>
        </div> */}
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/information_category" onClick={() => openNav()}>
                  <TiInfoLarge size={"35"} className="sidenavicon fvuohf" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/information_category" onClick={() => openNav()}>
                  &nbsp;GENERAL&nbsp;INFORMATION
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/cubicles" onClick={() => openNav()}>
                  <img
                    src="/whitegold.png"
                    width="35px"
                    height="15px"
                    className="sidenavicon"
                  />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai" onClick={() => openNav()}>
                <Link to="/cubicles">&nbsp;CUBICLES</Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/jobs" onClick={() => openNav()}>
                  <BsFillBriefcaseFill className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/jobs" onClick={() => openNav()}>
                  &nbsp;JOBS
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        {/* <hr />
        <div className='row mt-2'><center>interaction.</center></div> */}

        <hr />
        <div className="row mt-2">
          <center>tools</center>
        </div>

        <div className="row mt-1">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/quick_fix" onClick={() => openNav()}>
                  <RiToolsFill size={"35"} className="sidenavicon fvuohf" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/quick_fix" onClick={() => openNav()}>
                  &nbsp;QUICK FIX
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/suggestions" onClick={() => openNav()}>
                  <GrMail className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/suggestions" onClick={() => openNav()}>
                  &nbsp;ASK US
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          <center>about !</center>
        </div>
        <div className="row mt-1">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/inception" onClick={() => openNav()}>
                  <HiLightBulb className="sidenavicon " />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/inception" onClick={() => openNav()}>
                  &nbsp;INCEPTION
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#appoverviewmodal"
                  onClick={() => openNav()}
                >
                  <AiOutlineFileSearch className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#appoverviewmodal"
                  onClick={() => openNav()}
                >
                  &nbsp;APP OVERVIEW
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        {/* <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><Link to="/user_features" onClick={() => openNav()}><BsFillFileEarmarkPersonFill className="sidenavicon" /></Link></div><div className="col-4 p-0 mx-0 hai"><Link to="/user_features" onClick={() => openNav()}>&nbsp;USER FEATURES</Link></div></div>   </div>
        </div> */}
        {/* <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><Link to="/subscription_fees" onClick={() => openNav()}><SiContactlesspayment size={'35'} className="sidenavicon fvuohf" /></Link></div><div className="col-4 p-0 mx-0 hai"><Link to="/subscription_fees" onClick={() => openNav()}>&nbsp;SUBSCRIPTION, AD FEES</Link></div></div>   </div>
        </div> */}
        <div className="row ">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#subscrptionfeesmodal"
                  onClick={() => openNav()}
                >
                  <SiContactlesspayment size={"35"} className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#subscrptionfeesmodal"
                  onClick={() => openNav()}
                >
                  &nbsp;SUBSCRIPTION FEES
                </a>
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a data-bs-toggle="modal" data-bs-target="#apppolicies2">
                  <MdFeaturedPlayList
                    size={"35"}
                    className="sidenavicon fvuohf"
                  />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a data-bs-toggle="modal" data-bs-target="#apppolicies2">
                  &nbsp;APP POLICIES
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#subscrptionfeesmodal"
                  onClick={() => openNav()}
                >
                  <GiAwareness className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#beawaremodal"
                  onClick={() => openNav()}
                >
                  &nbsp;BE AWARE (DISCLAIMER)
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <hr />
        <div className="row poprjpgvijspjva pt-4 pb-3">
          <center>
            <img
              src="/FOOTERPROCHURE.svg"
              style={{ width: "200px", height: "75px" }}
            />
          </center>
        </div>
      </div>

      {state ? (
        <div className="poooooperkechu d-none"></div>
      ) : (
        <div className="poooooperkechu d-block" onClick={() => openNav()}></div>
      )}

      {/* Modalsss */}

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="apppolicies2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="dismissmodalforpassiveinnavbar"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Link
                    to="/Termsofuse"
                    className="dropdown-item"
                    onClick={(e) => navsabari()}
                  >
                    Terms of Use
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to="/Privacy"
                    className="dropdown-item"
                    onClick={(e) => navsabari()}
                  >
                    Privacy Policy
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to="/cancellation"
                    className="dropdown-item"
                    onClick={(e) => navsabari()}
                  >
                    Cancellation / Refund Policy
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to="/Disclaimer"
                    className="dropdown-item"
                    onClick={(e) => navsabari()}
                  >
                    Disclaimer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end offcanvasheightfornotications"
        data-bs-scroll="false"
        tabindex="-1"
        id="staticBackdrop"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">NOTIFICATIONS</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <Notification />
        </div>
      </div>

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="achiversmodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="achiversmodalbtn"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>

              <center>
                <h6>Will be accessible shortly !</h6>
              </center>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="modal fade elfkbnleribndnvlrwnkvwevdherio" id="apppolicies3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">

            <div className="modal-body">
              <div className='row'>
                <div className="d-flex justify-content-end">

                  <button id="dismissmodalforpassiveinnavbar" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              </div>

              <div className='row'>
                <div className="col-12">
                  <Link to="/Termsofuse" className="dropdown-item" onClick={(e) => navsabari()}>
                    Terms of Use
                  </Link>
                </div>
                <div className="col-12">
                  <Link to="/Privacy" className="dropdown-item" onClick={(e) => navsabari()}>
                    Privacy Policy
                  </Link>
                </div>
                <div className="col-12">
                  <Link to="/cancellation" className="dropdown-item" onClick={(e) => navsabari()}>
                    Cancellation / Refund Policy
                  </Link>
                </div>
                <div className="col-12">
                  <Link to="/Disclaimer" className="dropdown-item" onClick={(e) => navsabari()}>
                    Disclaimer
                  </Link>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div> */}

      {/*---------------Quick Settings ------------------*/}

      <div
        class="modal fade"
        id="quickSetings"
        aria-hidden="true"
        aria-labelledby="quickSetingsLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal-content"
            style={{ backgroundColor: "var(--blue)", color: "white" }}
          >
            <div class="modal-body">
              <div class="row d-flex justify-content-between">
                <div className="col-auto">
                  <h5>QUICK SETTINGS</h5>
                </div>
                <div className="col-auto">
                  <IoClose
                    size={25}
                    className="float-end"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
              </div>
              <br />
              <div className="row pb-2">
                <div className="col-12">
                  <a
                    data-bs-target="#googleTommy"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    &nbsp;&nbsp;
                    <MdLanguage className="sidenavicon" />
                    &nbsp;&nbsp;SELECT LANGUAGE
                  </a>
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <a
                    data-bs-target="#colorwheelmodalprochure"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    &nbsp;&nbsp;
                    <IoMdColorPalette className="sidenavicon" />
                    &nbsp;&nbsp;SELECT APP COLOUR
                  </a>
                </div>
              </div>
              <div className="row ">
                <div className="col-12">
                  <a>
                    &nbsp;&nbsp;
                    <BsChatQuoteFill className="sidenavicon" />
                    &nbsp;&nbsp;CHAT&nbsp;
                    <GreenSwitch
                      {...label}
                      checked={chat}
                      color="default"
                      onChange={settingChat}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
