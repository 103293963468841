import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const PersonalEntityInfornation = ({ getval }) => {
  const {
    formType,
    firstname,
    lastname,
    gender,
    dob,
    age,
    language,
    Profile,
    Partner,
  } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "personalentity"}
      onChange={handleChange("personalentity")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "100%", flexShrink: 0 }}>
          <span>
            <b> Personal Information&nbsp;</b>
            {formType === 1 || formType === 2 ? (
              <>
                <br />
                (of any one Owner / Consultant)
              </>
            ) : formType === 3 || formType === 4 ? (
              <>(of any one Owner)</>
            ) : formType === 5 ? (
              <>
                (of the {Profile === "Associated" ? "" : "Chief "}Doctor /
                Consultant)
              </>
            ) : (
              <></>
            )}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {firstname && (
            <div className="row py-2">
              <div className="col-md-6">
                <span>
                  {formType === 1 || formType === 2
                    ? "Name of any one Owner / Consultant"
                    : formType === 3 || formType === 4
                    ? "Name of any one Owner"
                    : formType === 5
                    ? `Name of the Doctor / Consultant`
                    : ""}
                </span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {firstname ? firstname.toUpperCase() : ""}{" "}
                  {lastname ? lastname.toUpperCase() : ""}
                </label>
              </div>
            </div>
          )}
          {gender && (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Gender</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {gender ? gender : ""}
                </label>
              </div>
            </div>
          )}
          {(dob || age) && (
            <div className="row py-2">
              <div className="col-md-6">
                <span> Age</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {formType === 1 || formType === 2 || formType === 5
                    ? Math.floor(
                        parseInt(new Date() - new Date(dob)) /
                          1000 /
                          60 /
                          60 /
                          24 /
                          365
                      ) + " years"
                    : age + " years"
                    ? age + " years"
                    : ""}
                </label>
              </div>
            </div>
          )}
          {language ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Languages Known</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {language
                    ? language.map((element, index) => {
                        if (index == language?.length - 1) {
                          return element;
                        } else {
                          return element + ", ";
                        }
                      })
                    : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {Partner ? (
            Partner[0]?.name?.length || Partner[0]?.designation?.length > 0 ? (
              <div className="row py-2">
                <div className="col-12 mb-3">
                  <b>Partner / Business Associate</b>
                </div>
                {Partner
                  ? Partner.map((element, index) => {
                      return (
                        <div className="row mb-3">
                          {element?.name ? (
                            <>
                              <div className="col-md-6">
                                <span>
                                  Name of Partner / Business Associate
                                </span>
                              </div>
                              <div className="col-md-6 ">
                                <label style={{ color: "var(--blue)" }}>
                                  {element.name}
                                </label>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {element?.name ? (
                            <>
                              <div className="col-md-6">
                                <span>Designation</span>
                              </div>
                              <div className="col-md-6 ">
                                <label style={{ color: "var(--blue)" }}>
                                  {element.designation}
                                </label>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })
                  : ""}
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
