import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useState } from "react";
// import './jobPostForm.css'
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import Axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { set } from 'react-hook-form';
import { ImProfile, } from 'react-icons/im'
import { HiDownload, } from 'react-icons/hi'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'moment';

import { print_state, print_city, state_arr,s_a,printlocation } from '../../../../Utility/stateCity'


export default function DeceasedAnimalBurialGroundsEdit() {
    let navigate = useNavigate()

    
    useEffect(() => {
        // props.Auth()
    }, [])
    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };




    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("pi")
    // var id = "62b5b8c5b44ac0fe19a83e77"


    // setValue({...value,id:id})

    const [value, setValue] = useState({
        id: "",
        name: "",
        phone: "",
        state: "",
        city: "",
        ServiceCharges: "",
        ServicesProvided: "",
        pincode: "",
        locations: [],
    });





    const [Stateload, setStateload] = useState(true)


    
    function keyGen() {
        var length = 5
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex
        print_city('City', index)
    }


    useEffect(() => {
        if (Stateload)
            print_state("State")
        printlocation('jobLocation')
        setStateload(false)
    }, [])

    // end state


    // job title starts

    const [loc, setloc] = useState([])
    var locked = ''
    const [viewLoc, setviewLoc] = useState([])

    const locationSelection = (e) => {
        var st = loc.find(ef => ef === e)
        if (st) {
            return
        }
        loc.push(e)
        var len = 0
        locked = loc.map((myList) => {
            return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                </button>
            </div>;
        });
        setviewLoc(locked)
        setValue({ ...value, locations: loc })
    }
    const removeLocation = (e) => {
        var red = e.substring(1)
        red = red - 1
        loc.splice(red, 1);
        var len = 0
        console.log(loc)
        locked = loc.map((myList) => {
            return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                </button>
            </div>;
        });
        setviewLoc(locked)
        setValue({ ...value, locations: loc })

    }
    useEffect(() => {
        if (viewLoc.length >= 4) {
            document.getElementById('jobLocation').setAttribute('disabled', 'true')
            document.getElementById('jobLocation').setAttribute('placeholder', 'Reached maximum')
        }
        else {
            document.getElementById('jobLocation').removeAttribute('disabled')
            document.getElementById('jobLocation').setAttribute('placeholder', 'Add more')
        }

    }, [viewLoc])





    //end job title
    const Statefix = () => {
        const City = document.getElementById('City').value
        const Statee = document.getElementById('State').value
        setValue({ ...value, state: Statee, city: City })
    }


    // Validation Starts here
    const [Error, setError] = useState(false)



    // Validation Ends here

    const [loading, setloading] = useState(false)

    function up1(e) {

        setloading(true)

        console.log(value)
        // return

        Axios.post('/api/Admin/DeceasedAnimalBurialGroundsEdit', value

        )
            .then(res => {
                if (res.status == 200) {
                    console.log('internupdate ')
                    console.log(res.data)
                    // return navigate(`/home/jobseeker_view?k=${id}`)
                    notify("Post updated successfully",true);

                    setTimeout(() => {

                        setloading(false)

                        return navigate(`/deceased_animal_burial_grounds`)
                    }, 1000);
                }

            }).catch((err) => {
                console.log(err.response.data)
                notify("Post updated unsuccessfully",false);

            })
        console.log('first')



    }

    const [Details, setDetails] = useState(false)



    const [loader, setLoader] = useState(<div id='iooioooo1231312' className='container  d-flex mt-5 pt-5  justify-content-center p-3 p-md-5'><div class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
    </div></div>)
    const [Loading, setLoading] = useState(true)
    // var id = "625541f375d16debd14ced72"
    useEffect(() => {
        console.log("haiiiii")
        Axios.post('/api/Admin/DeceasedAnimalBurialGroundsEditView', { id })
            .then(res => {
                if (res.status === 200) {
                    console.log('jobView Edit')
                    console.log(res.data)
                    // console.log(Id)
                    // setDetails(res.data);
                    setValue({
                        id: res.data._id,
                        name:res.data.name,
                        phone:res.data.phone,
                        state:res.data.state ,
                        city:res.data.city ,
                        ServiceCharges: res.data.ServiceCharges,
                        ServicesProvided:res.data.ServicesProvided,
                        pincode: res.data.pincode,
                        locations: res.data.locations,
                    })

                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                alert("sonthing went wrong");
                setLoading(false)

            })
    }, [])

    const Eyod = (e) => {
        console.log('f')
        console.log(e.target.checked)
        if (e.target.checked) {
            console.log('f1')
            document.getElementById('EndY').classList.add('d-none')
        } else {
            console.log('f2')
            document.getElementById('EndY').classList.remove('d-none')
        }
    }



    function print_state(state_id) {
        // given the id of the <select> tag as function argument, it inserts <option> tags
        var option_str = document.getElementById(state_id);
        option_str.length = 0;
        option_str.options[0] = new Option('Select State', '');
        option_str.selectedIndex = 0;
        for (var i = 0; i < state_arr.length; i++) {
            option_str.options[option_str.length] = new Option(state_arr[i], state_arr[i]);
        }
    }
    function print_city(city_id, city_index) {
        var option_str = document.getElementById(city_id);
        option_str.length = 0;
        option_str.options[0] = new Option('Select City', '');
        option_str.selectedIndex = 0;
        var city_arr = s_a[city_index].split("|");
        for (var i = 0; i < city_arr.length; i++) {
            option_str.options[option_str.length] = new Option(city_arr[i], city_arr[i]);
        }

    }

    const [tri, settri] = useState(false)
    useEffect(() => {

        if (value.state.length >= 1) {
            if (!tri) {
                settri(true)
                var index = state_arr.indexOf(value.state)
                // var index = 2
                print_city('City', index + 1)
                console.log(index)
            }

        }

    }, [value.state])

    //  console.log("Haiiii"+Q1.jobTitles)


    const [Joob, setJoob] = useState(true)

    useEffect(() => {

        // loc.push(e)

        console.log("length+" + value.locations.length)

        if (value.locations.length >= 1) {
            if (Joob == true) {
                setloc(value.locations)
                setJoob(false)
                var len = 0
                locked = value.locations.map((myList) => {
                    return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                        {myList}
                        <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                        </button>
                    </div>;
                });
                setviewLoc(locked)
            }
        }

    }, [value.locations])

    const [Lrt, setLrt] = useState(false)
   
   

//     useEffect(() => {
//  print_city('City', document.getElementById('State').selectedIndex)
//     }, [Details.state])

    // useEffect(() => {
    //     if (Loading) {
    //         document.getElementById('iooioooo1231312').classList.add('d-none')
    //         document.getElementById('fgt1').classList.remove('d-none')
    //     }
    // }, [Loading])


    return (
        <div className="container pt-5 mt-3">
            <h1>Deceased Animal Burial Grounds</h1>
            <div className='row'>
                <div className='col'><label className="labels">Name</label></div><div className='col'><input className="form-control" type="text" value={value.name}
                    onChange={(e) =>
                        setValue({ ...value, name: e.target.value })
                    }></input></div>
            </div><br />

            <div className='row'>
                <div className='col'> <label className="labels">State</label></div><div className='col'>
                    <select className="form-select" onChange={(e) => Cityshow(e)} id="State" name="City" value={value.state} />
                </div>
            </div><br />
            <div className='row'>
                <div className='col'> <label className="labels">City</label></div><div className='col'>
                    <select
                        className="form-select"
                        name="City"
                        value={value.city}
                        id="City"
                        onChange={(e) =>
                            setValue({ ...value, city: e.target.value })
                        }
                    >
                        <option>Select City</option>
                    </select></div>
            </div><br />
            <div className='row'>
                <div className='col'><label className="labels">PIN Code</label></div><div className='col'><input type="number" className="form-control" value={value.pincode}
                    onChange={(e) =>
                       {if (e.target.value.length == 7) return false; setValue({ ...value, pincode: e.target.value })}
                    }></input></div>
            </div><br />
            <div className='row'>
                <div className='col'>  <label className="labels">Contact Number</label></div><div className='col'>
                    <input type="number" value={value.phone} className="form-control"
                        onChange={(e) => { if (e.target.value.length == 11) return false;  setValue({ ...value, phone: e.target.value }) }
                        }></input></div>
            </div><br />
            <div className="row">
                <div className="col">
                    <label className="labels ">Locations </label>
                    <p style={{ 'fontSize': '10px' }} className="text-start my-2">You can select up to 4 preferred locations</p>

                </div>
                <div className="col">
                    <div id='loc'>
                        {viewLoc ? viewLoc : ''}
                    </div>

                    <select onChange={(e) => locationSelection(e.target.value)} type="text" id='jobLocation' className="form-select" >
                    </select>

                </div>


            </div>
            <br></br>
            <div className='row'>
                <div className='col'>  <label className="labels">Services Provided</label></div><div className='col'>
                    <textarea value={value.ServicesProvided} className="form-control"
                        onChange={(e) =>
                            setValue({ ...value, ServicesProvided: e.target.value })
                        }></textarea>
                </div>
            </div><br />
            <div className='row'>
                <div className='col'><label className="labels">Service Charges in Indian Rupee</label></div><div className='col'><input type="number" className="form-control" value={value.ServiceCharges}
                    onChange={(e) =>
                        setValue({ ...value, ServiceCharges: e.target.value })
                    }></input></div>
            </div><br />


            {/* <div className='row'>
                <div className='col'> <label className="labels">Category</label></div><div className='col'>
                <select
                      className="form-select"
                      value={value.category}
                      onChange={(e) => sabari(e)}
                    >
                      <option value="" disabled selected>
                        Select your category
                      </option>
                      {category ? category : ""}
                    </select>
                </div>
            </div><br />
    
            <div className='row'>
                <div className='col'> <label className="labels">sub Category</label></div><div className='col'>
                <select
                      className="form-select"
                      value={value.subcategory}
                      onChange={(e) =>
                        setValue({ ...value, subcategory: e.target.value })
                      }
                    >
                      <option value="" disabled selected>
                        Select your sub category
                      </option>
                      {subcategory ? subcategory : ""}
                    </select>
                </div>
            </div><br /> */}
            <div className="row">
                <div className="col"><p style={{ "color": 'red' }}>{Error ? Error : ''}</p></div>
                <div className="col">
                    {!loading && <button className="btn pro-spaces-button3" onClick={(e) => up1(e)}>Update</button>}
                    {loading && <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>}

                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}
