import React, { useContext, useEffect, useState } from 'react'
import { keyGen } from '../../Utility/helper'
import { CatsContext } from './FormConsultent'
import { SelectedSubcategoryContext } from './AddOn'
import Modal from 'react-bootstrap/Modal';
function AddOnField({ id, index, deleteMe, saveMe }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let selectedSubcategoryContexts = useContext(SelectedSubcategoryContext)



    let catscontext = useContext(CatsContext)
    const [Details, setDetails] = useState({
        subcategory: '',
        formType: ''
    })
    const [subcategory, setsubcategory] = useState()
    const [Brand, setBrand] = useState([])
    const [Selected, setSelected] = useState({
        subcategory: '',
        Brand: '',
        BrandOn: ''
    })
    useEffect(() => {
        console.log(catscontext);
        setDetails({ ...Details, ...catscontext.value })
        setsubcategory(catscontext?.subcat)
        return () => {
        }
    }, [catscontext])

    const subcategorySelection = (sub) => {
        setSelected({ ...Selected, subcategory: sub, Brand: '' })
        let details = subcategory.filter((elem) => {
            if (elem._id === sub) {
                return elem
            }
        })
        if (details[0].brand?.length) {
            setBrand([...details[0].brand])
            setSelected({ ...Selected, subcategory: sub, Brand: '', BrandOn: true })

        } else {
            setSelected({ ...Selected, subcategory: sub, Brand: '', BrandOn: false })

            setBrand([])
        }
    }

    useEffect(() => {
        if (Selected.subcategory || Selected.Brand)
            saveMe(index, Selected)
        return () => {
        }
    }, [Selected])
    const newsubcat = (e) => {

        subcategorySelection(e)


    }
    return (
        <div className='row border p-1'>
            <div className='col-12 p-1 d-flex flex-row-reverse '>
                <button onClick={(e) => deleteMe(index)} type='button' className='btn btn-close ps-0' ></button>
            </div>

            <div className="col-md-12">
                <label className="labels">Sub Category * {catscontext.value.formType} </label>
                <select id="vip227y78t87t7" className="form-select"
                    value={Selected.subcategory}
                    onChange={(e) => newsubcat(e.target.value)}
                >
                    <option disabled selected>
                    </option>
                    {subcategory?.map((sub) => {
                        console.log(selectedSubcategoryContexts.subcategorySelected,sub._id,sub.formType,catscontext.value.formType);
                        if ((selectedSubcategoryContexts.subcategorySelected.indexOf(sub._id) == -1) && sub.formType == catscontext.value.formType) {
                            return <option key={keyGen()} formtype={sub.formType} value={sub._id}>
                                {sub.sub}
                            </option>
                        } else {
                            return <option key={keyGen()} onClick={(e) => console.log('object')} disabled formtype={sub.formType} value={sub._id}>
                                {sub.sub}
                            </option>
                        }
                    })}
                </select>
            </div>

            {Brand.length ?
                <div className='col-md-12'>
                    <label className='labels'>Brand *</label>
                    <select className='form-select' value={Selected.Brand} onChange={(e) => setSelected({ ...Selected, Brand: e.target.value })}>
                        <option></option>
                        {Brand?.map((ele) => <option value={ele._id} >{ele.brand}</option>)}
                    </select>
                </div>
                : <></>
            }
            <Modal
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>

            </Modal>
        </div>
    )
}

export default AddOnField