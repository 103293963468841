import React, { useEffect, useState } from 'react'

function CommentInput({ submit }) {
    const [comment, setcomment] = useState('')

    return (
        <div className='py-3'>
            <textarea type='' onChange={(e) => setcomment(e.target.value)} className='form-control' rows={3} >{comment}</textarea>
          <div className='d-flex justify-content-end align-items-end py-1'>
             <button className='btn  pro-spaces-button3' onClick={() => submit(comment)} >Post</button>
             </div> 
        </div>
    )
}

export default CommentInput