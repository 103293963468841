import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Pview.css";
import { FaShareAlt } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Edit";
import AddOn from "../../Active/ActiveProfile/addAnother";
import Portfolio from "../../Active/ActiveProfile/portfolio";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { notify } from "../../../Utility/notify";
import { PersonalEntityInfornation } from "../../../Components/ProfileComponents/PersonalEntityInfornation";
import { QualificationsandEnrolmentInformation } from "../../../Components/ProfileComponents/QualificationsandEnrolmentInformation";
import { DetailedServiceInformation } from "../../../Components/ProfileComponents/DetailedServiceInformation";
import { WorkServiceRelatedInformation } from "../../../Components/ProfileComponents/WorkServiceRelatedInformation";
import { BussinessInformation } from "../../../Components/ProfileComponents/BussinessInformation";
import { ContactInformation } from "../../../Components/ProfileComponents/ContactInformation";
import { HospitalsClinicsAssociatedWith } from "../../../Components/ProfileComponents/HospitalsClinicsAssociatedWith";

import { Mask } from "../../../Utility/mask";
import OfferPortfolio from "../../Active/ActiveProfile/Offerportfolio";
import NewLaunchportfolio from "../../Active/ActiveProfile/NewLaunchportfolio";
import { Selfdeclaration } from "../../../Components/Selfdeclaration/Selfdeclaration";
import { ShareThis } from "../../ShareThis/ShareThis";
import { bucketName } from "../../../config";

function Pview(props) {
  let [searchParams, setSearchParams] = useSearchParams();

  let success = searchParams.get("success");

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [isLogo, setIsLogo] = useState(false);
  const [logo, setLogo] = useState(false);
  const [DP, setDp] = useState(false);

  useEffect(() => {
    props.Auth();

    if (success) {
      notify("Profile Updated", true);
      console.log("updated");
    }
  }, []);

  const [shareshow, setshareshow] = useState(false);

  const copytoclip = () => {
    navigator.clipboard.writeText(
      `www.prochure.in/find_profile?profile=services_profile&&Ac_id=${Mask.encode(
        getval._id
      )}`
    );
    notify("URL copied to your clipboard", true);
  };

  const [url, setUrl] = useState("");

  const [getval, setGetval] = useState(false);

  useEffect(() => {
    setUrl(
      `See the Profile of ${
        getval.pname
      }\nwww.prochure.in/find_profile?profile=services_profile&&Ac_id=${Mask.encode(
        getval._id
      )}`
    );
  }, [getval]);

  useEffect(() => {
    console.log(getval);
  }, [getval]);

  // init
  useEffect(() => {
    axios
      .post("/api/ActiveUser/ConProfile", {})
      .then((res) => {
        setGetval(res.data[0]);
        const uniqueValue = new Date().getTime();
        setLogo(
          bucketName +
            `/${res.data[0]?._id}/company_logo.png?timestamp=${uniqueValue}`
        );
        // console.log(res.data[0]);
        setDp(
          bucketName +
            `/${res?.data[0]?._id}/avatar.png?timestamp=${uniqueValue}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [myarr, setMyarr] = useState(false);
  useEffect(() => {
    if (getval.serviceprovided) {
      // console.log(getval)
      setMyarr(getval.serviceprovided.split(","));
      //  console.log(myarr)
    }
  }, [getval]);
  var sab;

  if (getval.experience > 1) {
    sab = "years";
  } else {
    sab = "year";
  }

  return (
    <>
      <div>
        <div className="container emp-profile border ">
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-3">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-center">
                <div style={{ position: "relative" }}>
                  <img id="DP" className="new-vp-image" src={DP} alt="" />
                  {logo && !isLogo && (
                    <img
                      className="new-card-logo"
                      src={logo}
                      onError={() => setIsLogo(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5 className="mb-1">
                    {getval.pname ? getval.pname.toUpperCase() : ""}
                  </h5>
                  <p className="mt-0 pt-0 mb-2">
                    {getval?.brandtagline ? getval.brandtagline : ""}
                  </p>
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5 className="mb-1">
                      {getval.pname ? getval.pname.toUpperCase() : ""}
                    </h5>
                    <p className="mt-0 pt-0 mb-2">
                      {getval?.brandtagline ? getval.brandtagline : ""}
                    </p>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                        {getval.string ? getval.string[0].category : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                          {getval.string ? getval.string[0].category : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="d-block d-md-none">
                  <center>
                    <Link to="/edit_profile_services">
                      <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                        style={{ borderRadius: "25px" }}
                        value="Edit Profile"
                      />
                    </Link>
                    <Link to="/know_my_client">
                      <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                        style={{ borderRadius: "25px" }}
                        name="btnAddMore"
                        value="Know My Client"
                      />
                    </Link>
                    <button
                      className="btn pro-spaces-button ms-3 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      // onClick={copytoclip}
                      onClick={(e) => setshareshow(true)}
                    >
                      <FaShareAlt />
                    </button>
                  </center>
                  <center>
                    <button
                      className="btn pro-spaces-button mt-2 ms-1 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px", fontSize: ".8rem" }}
                      data-bs-target="#modalofaddanother"
                      data-bs-toggle="modal"
                    >
                      ADD THIS PROFILE TO ANOTHER SUB CATEGORY
                    </button>
                  </center>
                </div>
                <div className="d-none d-md-block">
                  <Link to="/edit_profile_services">
                    <input
                      type="submit"
                      className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      name="btnAddMore"
                      value="Edit Profile"
                    />
                  </Link>
                  <Link to="/know_my_client">
                    <input
                      type="submit"
                      className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      name="btnAddMore"
                      value="Know My Client"
                    />
                  </Link>
                  <button
                    className="btn pro-spaces-button ms-3 ps-2 pe-2 me-2"
                    style={{ borderRadius: "25px" }}
                    // onClick={copytoclip}
                    onClick={(e) => setshareshow(true)}
                  >
                    <FaShareAlt />
                  </button>
                  <br />
                  <button
                    className="btn pro-spaces-button mt-2 ms-1 ps-2 pe-2 me-2"
                    style={{ borderRadius: "25px" }}
                    data-bs-target="#modalofaddanother"
                    data-bs-toggle="modal"
                  >
                    ADD THIS PROFILE TO ANOTHER SUB CATEGORY
                  </button>
                </div>
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {getval.formType ? (
                  getval.formType === 1 ? (
                    <>
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Works / Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Portfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel1099"}
                        onChange={handleChange("panel1099")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>NEWLY LAUNCHED Service</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <NewLaunchportfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel109"}
                        onChange={handleChange("panel109")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Exclusive OFFERS / DISCOUNTS</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <OfferPortfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 2 ? (
                    <>
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Works / Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Portfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 3 ? (
                    <>
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Portfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel1099"}
                        onChange={handleChange("panel1099")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>NEWLY LAUNCHED Service</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <NewLaunchportfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel109"}
                        onChange={handleChange("panel109")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Exclusive OFFERS / DISCOUNTS</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <OfferPortfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      {getval.Profile === "Individual" ? (
                        <PersonalEntityInfornation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 4 ? (
                    <>
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Portfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel1099"}
                        onChange={handleChange("panel1099")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>NEWLY LAUNCHED Service</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <NewLaunchportfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel109"}
                        onChange={handleChange("panel109")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Exclusive OFFERS / DISCOUNTS</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <OfferPortfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      {getval.Profile === "Individual" ? (
                        <PersonalEntityInfornation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 5 ? (
                    <>
                      {getval.Profile === "Private" ||
                      getval.Profile === "Privateand" ? (
                        <DetailedServiceInformation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />

                      {getval.Profile === "Private" ? (
                        <>
                          {/* Private */}
                          <BussinessInformation getval={getval} />
                          <ContactInformation getval={getval} />
                        </>
                      ) : getval.Profile === "Associated" ? (
                        <>
                          {/* Associated */}
                          <BussinessInformation getval={getval} />
                          {/* <ContactInformation getval={getval} /> */}
                        </>
                      ) : (
                        <>
                          {/* Privateand */}
                          <BussinessInformation getval={getval} />
                          <HospitalsClinicsAssociatedWith getval={getval} />
                          {/* <ContactInformation getval={getval} /> */}
                        </>
                      )}
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Portfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel1099"}
                        onChange={handleChange("panel1099")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>NEWLY LAUNCHED Service</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <NewLaunchportfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel109"}
                        onChange={handleChange("panel109")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Exclusive OFFERS / DISCOUNTS</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <OfferPortfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  ""
                )}
                <Selfdeclaration type="vdp" />
              </div>
            </center>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalofaddanother"
        tabindex="-1"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="buttoncloseforaddanther"
              ></button>
            </div>
            <div className="modal-body">
              <AddOn getval={getval} />
            </div>
          </div>
        </div>
      </div>
      <ShareThis shareshow={shareshow} setshareshow={setshareshow} url={url} />

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Pview;
// new
