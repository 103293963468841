import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './jobView.css'
import "./JobList.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import {  useSearchParams } from 'react-router-dom';

export function Applicants() {


    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("k")

    const [Loader, setLoader] = useState(<center>

        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </center>)

    const [count, setcount] = useState(false)

    function Applicant() {

        // return
        Axios.post('/api/User/ApplicantsView', { id })
            .then(res => {
                if (res.status == 200) {
                   
                    setcount(res.data)

                    var jobDetails = res.data.result.map((myList) => {
                       
                        //  return      
                        var age = Math.floor(
                            parseInt(new Date() - new Date(myList.JobPostForm1?.JobSeeker_dob)) / 1000 / 60 / 60 / 24 / 365
                        );
                      if (myList.qualify == 1) {
                        
                      
                        if (  myList.JobPostForm1?.length>0 ) {
                            return <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <div className="card carded shadow-sm">
                                <div className="card-body ">


                                <div className="row">
                                    <div className="entityJobs  col-12">
                                        <b>Name</b>
                                        <p className="ms-2 JobVal ">{myList.JobPostForm1[0].JobSeeker_Name}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Gender </b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_gender}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Age </b>
                                        <p className="ms-2 JobVal">{age}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Job Category </b>
                                        <p className="ms-2 JobVal">{myList.category[0]?myList.category[0].category:''}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Sub Category </b>
                                        <p className="ms-2 JobVal">{myList.subcategory[0]?myList.subcategory[0].sub:''}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Title</b>
                                        <p className="ms-2 JobVal" >{myList.JobPostForm1[0].JobSeeker_jobTitles}</p>
                                    </div>

                                    {/* <div className="entityJobs col-12">
                                        <b>Job Locations</b>
                                        <p className="ms-2 JobVal">
                                        <div className="row">
                                    {myList.JobSeeker_jobLocations ?

                                      myList.JobSeeker_jobLocations                                 
                                            .map((e) => {

                                                return <div className='col'>{e}</div>

                                            }) : 'Some thing went wrong!!!'}
                                </div>
                                        </p>
                                    </div> */}
                                   

                                    <div className="entityJobs col-12">
                                        <b>Work Mode</b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_workPlace}</p>
                                    </div>
   

                                    <div className="entityJobs col-12">
                                        <b>Employment Type </b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_empType}</p>
                                    </div>
                                    {
                                        myList.Type==1?
                                        <>

                                  <div className="entityJobs col-12">
                                        <b>Currently (Employed / Unemployed) </b>

                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_currentlyWorking==true?"Employed ":"Unemployed"}</p>
                                    </div>

                                    <div className="col-1"></div>
                                    <div className="entityJobs col-12">
                                        {/* <b>Available for Immediate Joining / On Notice Period </b> */}
                                        {myList.JobPostForm1[0].JobSeeker_availability==true ||"true"?<b>Available for Immediate Joining</b> : <b>On Notice Period</b>}
                                        <p></p>
                                    </div>

                                        
                                        </>

                                     

                                        :""
                                    }

                                    
                                    




                                   {


                                    myList.JobPostForm1[0].Type!=2?

                                    myList.JobPostForm1[0].JobSeeker_qualification.length>0?

                                        <>
                                         <div className="entityJobs col-12">
                                        <b>Degree </b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_qualification?myList.JobPostForm1[0].JobSeeker_qualification[0].Q5_1.degree :""}&nbsp;</p>
                                         </div>
                                         
                                         <>
                                         {myList.JobPostForm1[0].JobSeeker_qualification?myList.JobPostForm1[0].JobSeeker_qualification[0].Q5_1.special? 
                                        <div className="entityJobs col-12">
                                        <b>Specialization</b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_qualification?myList.JobPostForm1[0].JobSeeker_qualification[0].Q5_1.special :""}</p>
                                    </div>
                                        :""  :""}
                                         </>
                                      
                                        </>
    
                                        :''

                                    :''



                                   }
                                   
                                   {
                                    myList.Type==2?

                                    <div className="entityJobs col-12">
                                        <b>Internship period </b>

                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_period}</p>
                                    </div>


                                    :""
                                   }
                                  

                                    

                                   

                                    {
                                        myList.JobPostForm1[0].JobSeeker_expMonths||myList.JobPostForm1[0].JobSeeker_expYears?

                                        <div className="entityJobs col-12    ">
                                        <b>Work Experience  </b>
                                        <p className="ms-2 JobVal"> 
                                                                     {myList.JobPostForm1[0].JobSeeker_expMonths?myList.JobPostForm1[0].JobSeeker_expMonths=="1"?myList.JobPostForm1[0].JobSeeker_expMonths+" Month ":myList.JobPostForm1[0].JobSeeker_expMonths+" Months ":""}

                                                                     {myList.JobPostForm1[0].JobSeeker_expYears?myList.JobPostForm1[0].JobSeeker_expYears=="1"?myList.JobPostForm1[0].JobSeeker_expYears+" Year ":myList.JobPostForm1[0].JobSeeker_expYears+" Years ":""}
                                                                     {/* {myList.Type==2?"Intern":""}
                                                                     {myList.Type==3?"Fresher":""} */}
                                                                     </p>
                                    </div>


                                        :''
                                    }
                                    <div className="col-1"></div>
                                   
                                </div>
                            
                                 

                                    <div className="mb-2">
                                        <div className='text-center mt-1'>
                                            <Link to={`/view_applicants_single?k=${myList.JobPostForm1[0]._id}&&p=${id}`} className="btn btn-war" >VIEW CANDIDATE PROFILE</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div >;
                        }
                        else {
                          return  <p>Post deleted !</p>
                        }
                    }
                    });
                    setLoding(false)
                    setContent(jobDetails)

                    var jobDetails1 = res.data.result.map((myList) => {
                         
                        var age = Math.floor(
                            parseInt(new Date() - new Date(myList.JobPostForm1[0]?.JobSeeker_dob)) / 1000 / 60 / 60 / 24 / 365
                        );

                        if (myList.qualify == 0 && myList.JobPostForm1?.length>0) {
                            return <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <div className="card carded shadow-sm">
                                <div className="card-body ">
                                    
                                    <div className="row">
                                    <div className="entityJobs  col-12">
                                        <b>Name</b>
                                        <p className="ms-2 JobVal ">{myList.JobPostForm1[0].JobSeeker_Name}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Gender </b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_gender}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Age </b>
                                        <p className="ms-2 JobVal">{age}</p>
                                    </div>

                                    {/* <div className="entityJobs col-12">
                                        <b>Languages Known to Communicate Fluently </b>
                                        <p className="ms-2 JobVal">
                                        <div className="row">
                                    {myList.JobSeeker_languageFluent ?

                                      myList.JobSeeker_languageFluent                                 
                                            .map((e) => {

                                                return <div className='col'>{e}</div>

                                            }) : 'Some thing went wrong!!!'}
                                </div>
                                        </p>
                                    </div> */}

                                    <div className="entityJobs col-12">
                                        <b>Job Category </b>
                                        <p className="ms-2 JobVal">{myList.category[0]?myList.category[0].category:''}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Sub Category </b>
                                        <p className="ms-2 JobVal">{myList.subcategory[0]?myList.subcategory[0].sub:''}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Title</b>
                                        <p className="ms-2 JobVal" >{myList.JobPostForm1[0].JobSeeker_jobTitles}</p>
                                    </div>

                                    {/* <div className="entityJobs col-12">
                                        <b>Job Locations</b>
                                        <p className="ms-2 JobVal">
                                        <div className="row">
                                    {myList.JobSeeker_jobLocations ?

                                      myList.JobSeeker_jobLocations                                 
                                            .map((e) => {

                                                return <div className='col'>{e}</div>

                                            }) : 'Some thing went wrong!!!'}
                                </div>
                                        </p>
                                    </div> */}
                                   

                                    <div className="entityJobs col-12">
                                        <b>Work Mode</b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_workPlace}</p>
                                    </div>
   

                                    <div className="entityJobs col-12">
                                        <b>Employment Type </b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_empType}</p>
                                    </div>
                                    {
                                        myList.Type==1?
                                        <>

                                  <div className="entityJobs col-12">
                                        <b>Currently (Employed / Unemployed) </b>

                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_currentlyWorking==true?"Employed ":"Unemployed"}</p>
                                    </div>

                                    <div className="col-1"></div>
                                    <div className="entityJobs col-12">
                                        {/* <b>Available for Immediate Joining / On Notice Period </b> */}
                                        {myList.JobPostForm1[0].JobSeeker_availability==true ||"true"?<b>Available for Immediate Joining</b> : <b>On Notice Period</b>}
                                        <p></p>
                                    </div>

                                        
                                        </>

                                     

                                        :""
                                    }

                                    
                                    




                                   {


                                    myList.JobPostForm1[0].Type!=2?

                                    myList.JobPostForm1[0].JobSeeker_qualification.length>0?

                                        <>
                                         <div className="entityJobs col-12">
                                        <b>Degree </b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_qualification?myList.JobPostForm1[0].JobSeeker_qualification[0].Q5_1.degree :""}&nbsp;</p>
                                         </div>
                                         
                                         <>
                                         {myList.JobPostForm1[0].JobSeeker_qualification?myList.JobPostForm1[0].JobSeeker_qualification[0].Q5_1.special? 
                                        <div className="entityJobs col-12">
                                        <b>Specialization</b>
                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_qualification?myList.JobPostForm1[0].JobSeeker_qualification[0].Q5_1.special :""}</p>
                                    </div>
                                        :""  :""}
                                         </>
                                      
                                        </>
    
                                        :''

                                    :''



                                   }
                                   
                                   {
                                    myList.Type==2?

                                    <div className="entityJobs col-12">
                                        <b>Internship period </b>

                                        <p className="ms-2 JobVal">{myList.JobPostForm1[0].JobSeeker_period}</p>
                                    </div>


                                    :""
                                   }
                                  

                                    

                                   

                                    {
                                        myList.JobPostForm1[0].JobSeeker_expMonths||myList.JobPostForm1[0].JobSeeker_expYears?

                                        <div className="entityJobs col-12    ">
                                        <b>Work Experience  </b>
                                        <p className="ms-2 JobVal"> 
                                                                     {myList.JobPostForm1[0].JobSeeker_expMonths?myList.JobPostForm1[0].JobSeeker_expMonths=="1"?myList.JobPostForm1[0].JobSeeker_expMonths+" Month ":myList.JobPostForm1[0].JobSeeker_expMonths+" Months ":""}

                                                                     {myList.JobPostForm1[0].JobSeeker_expYears?myList.JobPostForm1[0].JobSeeker_expYears=="1"?myList.JobPostForm1[0].JobSeeker_expYears+" Year ":myList.JobPostForm1[0].JobSeeker_expYears+" Years ":""}
                                                                     {/* {myList.Type==2?"Intern":""}
                                                                     {myList.Type==3?"Fresher":""} */}
                                                                     </p>
                                    </div>


                                        :''
                                    }
                                    <div className="col-1"></div>
                                   
                                </div>

                                    <div className="mb-2">
                                        <div className='text-center mt-1'>
                                            <Link to={`/view_applicants_single?k=${myList.JobPostForm1[0]._id}&&p=${id}`} className="btn btn-war" >VIEW CANDIDATE PROFILE</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div >;
                        }
                        else {
                            <p>Nothing To Show</p>
                        }
                    });
                    setLoding(false)
                    setContent1(jobDetails1)

                }



            }).catch((err) => {
                setLoding(false);
                setError('Something went wrong !')
                console.log(err)
            })
    }

    const [Loding, setLoding] = useState(true)
    const [Error, setError] = useState(false)
    const [Content, setContent] = useState(false)

    const [Content1, setContent1] = useState(false)

    useEffect(() => {
        // return
        Applicant()
    }, [])


    return (
        <div className="container mt-5 bg-light pt-5">
            <b>Total Applicants:{count.countt}  &nbsp; Qualified Applicants:{count.countQ}   &nbsp;{count.countDQ ? count.countDQ > 0 ? <>Disqualified Applicants:{count.countDQ}</> : "" : ""}  </b>
            <hr />
             
             {count.countQ==0&&count.countDQ==0?<b>Nothing to Show</b>:""}

{    count.countQ?count.countQ > 0 ?        <div>
                <h5 className="p-0 pt-1">Qualified Applicants</h5>
                <div className="">
                    <div className="container-fluid bg-trasparent my-4 p-3 ">
                        <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-3 g-3 car">
                            {Loding ? Loader : Error ? Error : Content}
                        </div>
                    </div>
                </div>
            </div>:<></>:<></>}

            {
                count.countDQ ? count.countDQ > 0 ?
                    <div>
                        <div>Disqualified Applicants</div>
                        <div className="">
                            <div className="container-fluid bg-trasparent my-4 p-3 ">
                                <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-3 g-3 car">
                                    {Loding ? Loader : Error ? Error : Content1}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></> : <></>
            }
        </div>
    )
}
