
import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import { GoogleMapsAPI } from './client-config';
import Spinner from 'react-bootstrap/Spinner';
import dotenv from 'dotenv'
import './map.css'

Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();



const Loki = () => {
	return new Promise((resolve, reject) => {
		if (!navigator.geolocation) {
			alert('Geolocation is not supported by your browser')
			let position = {
				lat: 28.633008623330056,
				lng: 77.22197631835937
			}
			return resolve(position)
		}
		else {
			navigator.geolocation.getCurrentPosition((position) => {
				// alert('Geolocati')
				console.log(position)
				// return positio/n
				resolve(position)
			}, () => {
				alert('Unable to retrieve your location')
				reject()

			});
		}
	});
}


class Map extends Component {


	// mapPosition: {
	// 	lat: 28.633008623330056,
	// 	lng: 77.22197631835937
	// },
	// markerPosition: {
	// 	lat: 28.633008623330056,
	// 	lng: 77.22197631835937
	// }
	constructor(props) {
		console.log(props)

		if (props.center.lat && props.center.lng) {
			console.log(props)
			super(props);
			this.state = {
				address: '',
				city: '',
				area: '',
				state: '',
				mapPosition: {
					lat: this.props.center.lat,
					lng: this.props.center.lng
				},
				markerPosition: {
					lat: this.props.center.lat,
					lng: this.props.center.lng
				}
			}
		}
		else {
			console.log(props)
			super(props);
			this.state = {
				address: '',
				city: '',
				area: '',
				state: '',
				mapPosition: {
					lat: 28.633008623330056,
					lng: 77.22197631835937
				},
				markerPosition: {
					lat: 28.633008623330056,
					lng: 77.22197631835937
				}
			}
		}


		this.props.setmapPosition({
			lng: this.props.center.lng,
			lat: this.props.center.lat
		})


		// console.log(process.env.REACT_APP_GoogleMapsAPI)
	}
	/**
	 * Get the current address from the default map position and set those values in the state
	 */

	componentDidUpdate(props) {
		console.log(props)
	}

	async componentDidMount() {
		if (!this.props.center.lat) {
			let sp = await Loki()
			console.log(sp.coords.latitude)
			let lng = sp.coords.longitude
			let lat = sp.coords.latitude
			// Geocode.fromLatLng(lat, lng).then(
			// 	response => {
			this.setState({
				mapPosition: {
					lat: lat,
					lng: lng
				},
				markerPosition: {
					lat: lat,
					lng: lng
				}
			})
		}
	}
	// 	error => {
	// 		console.error(error);
	// 	}
	// );

	/**
	 * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
	 *
	 * @param nextProps
	 * @param nextState
	 * @return {boolean}
	 */
	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.markerPosition.lat !== this.props.center.lat ||
			this.state.address !== nextState.address ||
			this.state.city !== nextState.city ||
			this.state.area !== nextState.area ||
			this.state.state !== nextState.state
		) {
			return true
		} else if (this.props.center.lat === nextProps.center.lat) {
			return false
		}
	}
	/**
	 * Get the city and set the city input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getCity = (addressArray) => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};
	/**
	 * Get the area and set the area input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getArea = (addressArray) => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};
	/**
	 * Get the address and set the address input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getState = (addressArray) => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};
	/**
	 * And function for city,state and address input
	 * @param event
	 */
	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	/**
	 * This Event triggers when the marker window is closed
	 *
	 * @param event
	 */
	onInfoWindowClose = (event) => {

	};

	/**
	 * When the marker is dragged you get the lat and long using the functions available from event object.
	 * Use geocode to get the address, city, area and state from the lat and lng positions.
	 * And then set those values in the state.
	 *
	 * @param event
	 */
	onMarkerDragEnd = (event) => {
		console.log(event)
		let newLat = event.latLng.lat()
		let newLng = event.latLng.lng();
		console.log(newLat)
		console.log(newLng)
		this.props.setmapPosition({
			lng: newLng,
			lat: newLat
		})
		// this.setState({

		// 	markerPosition: {
		// 		lat: newLat,
		// 		lng: newLng
		// 	},


		// })
		return
		Geocode.fromLatLng(newLat, newLng).then(
			response => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				this.setState({
					address: (address) ? address : '',
					area: (area) ? area : '',
					city: (city) ? city : '',
					state: (state) ? state : '',
					markerPosition: {
						lat: newLat,
						lng: newLng
					},

				})
			},
			error => {
				console.error(error);
			}
		);
	};

	/**
	 * When the user types an address in the search box
	 * @param place
	 */
	onPlaceSelected = (place) => {
		console.log('plc', place);
		if (place.formatted_address) {
			console.log('first')
			console.log(place)
			const address = place.formatted_address,
				addressArray = place.address_components,
				city = this.getCity(addressArray),
				area = this.getArea(addressArray),
				state = this.getState(addressArray),
				latValue = place.geometry.location.lat(),
				lngValue = place.geometry.location.lng();
			// Set these values in the state.
			console.log(latValue)
			console.log(lngValue)

			this.props.setmapPosition({
				lng: lngValue,
				lat: latValue
			})

			// return

			this.setState({
				address: (address) ? address : '',
				area: (area) ? area : '',
				city: (city) ? city : '',
				state: (state) ? state : '',
				markerPosition: {
					lat: latValue,
					lng: lngValue
				},
				mapPosition: {
					lat: latValue,
					lng: lngValue
				},
			})
		}
	};

	handleert(e) {
		console.log(e)
	}
	render() {
		const AsyncMap = withScriptjs(
			withGoogleMap(
				props => (
					<GoogleMap google={this.props.google}
						defaultZoom={this.props.zoom}
						onZoomChanged={this.handleert}
						onZoomOut={this.handleert}

						defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
					>
						{/* InfoWindow on top of marker */}
						{/* <InfoWindow
							onClose={this.onInfoWindowClose}
							position={{ lat: (this.state.markerPosition.lat + 0.0018), lng: this.state.markerPosition.lng }}
						>
							<div>
								<span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
							</div>
						</InfoWindow> */}
						{/*Marker*/}
						<Marker
							google={this.props.google}
							name={'Dolores park'}
							draggable={true}
							onDragEnd={this.onMarkerDragEnd}
							position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
						/>
						<Marker />
						{/* For Auto complete Search Box */}
						<Autocomplete
							className="form-control"
							style={{
								width: '100%',
								height: '40px',
								paddingLeft: '16px',
								marginTop: '2px',

							}}
							onPlaceSelected={this.onPlaceSelected}
							types={['(regions)']}
						/>
					</GoogleMap>
				)
			)
		);
		let map;
		if (this.props.center.lat !== undefined) {
			map = <div>
				

				<AsyncMap
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
					loadingElement={
						<> <div className='d-flex align-items-center justify-content-center'>
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</div>
						</>
					}
					containerElement={
						<div style={{ height: this.props.height }} />
					}
					mapElement={
						<div style={{ height: this.props.height }} />
					}
				/>
			</div>
		} else {
			map = <div style={{ height: this.props.height }} />
		}
		return (map)
	}
}
export default Map
