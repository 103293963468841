import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import { InlineShareButtons } from "sharethis-reactjs";
import { Mask } from "../../Utility/mask";
import CubicleAPI from "../CubicleAPI/CubicleAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosSend } from "react-icons/io";
import { SpinnerSmall } from "../../Utility/spinner";
import { useNavigate } from "react-router-dom";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { ModalBody } from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { reportTags } from "../../Utility/languages";
import OutlinedInput from "@mui/material/OutlinedInput";
import { bucketName } from '../../config'

export const StaticProfileModal = (props) => {
  const [value, setValue] = useState(false);
  const [sabari, setsabari] = useState({
    report: "",
    tags: [],
  });
  const [sabarierr, setsabarierr] = useState("");
  const [renderThis, setRenderThis] = useState(false);
  const isService = props.Type.startsWith("C" || "c");
  const [isLogo, setIsLogo] = useState(false)
  const [logo, setLogo] = useState(false)
  useEffect(() => {
    const idd = props.id;
    const Ac = props.id;
    const URl = isService
      ? "/api/Free/ConProfileexterbal"
      : "/api/Free/ProProfileexternal";
    axios
      .post(URl, {
        idd,
        Ac,
      })
      .then((res) => {
        console.log(res.data[0]);
        setValue(res.data[0]);
        const uniqueValue = new Date().getTime();
        setLogo(bucketName + `/${res?.data[0]?._id}/company_logo.png?timestamp=${uniqueValue}`)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [renderThis]);
  const linktovebsite = (website) => {
    if (website.indexOf("http") > -1) {
      window.open(website, "_blank");
    } else {
      window.open("http://" + website, "_blank");
    }
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
  };
  const commu = () => {

    try {
      setcommunicateModal2(false)
      props?.onHide()
      // document.getElementById('quicksettingmodalatag')?.click()
      setTimeout(() => {

        console.log(document.getElementById('quicksettingmodalatag').click())
      }, 100);
    } catch (error) {
      console.log(error)
    }
  }
  const reprtsabari = () => {
    const Acc = props.id
    if (sabari.report?.length === 0) {
      return setsabarierr("Please enter a reason");
    } else {
      axios
        .post("api/User/reportabuse", {
          sabari,
          Acc,
        })
        .then((res) => {
          notify("Reported", true);
        })
        .catch((err) => {
          notify("Something went wrong !", false);
        });
    }
  };
  const {
    pname,
    Location_Coordinates,
    Location,
    Description,
    firstname,
    lastname,
    gender,
    age,
    address1,
    language,
    EntityName,
    brandName,
    brandtagline,
    typeoffirm,
    address_floor,
    address,
    address_building,
    address_door,
    address_street,
    address_nearest,
    city,
    state,
    country,
    pincode,
    localityh,
    who,
    TypeofStore,
    yearofb,
    licence,
    gst,
    website,
    workingdaysOffnew,
    worktimimng,
    workingdaysfullnew,
    working_hour_1,
    break_time_1,
    workingdayshalfnew,
    working_hour_3,
    working_hour_2,
    break_time_2,
    working_hour_4,
    email,
    countrycode,
    statecode,
    CON,
    Status1,
    Status2,
    verified,
    WomanOwned,
    string,
    string2,
    link,
  } = !!value && value;

  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(
      `See the Profile of ${pname}\nwww.prochure.in/find_profile?profile=services_profile&&Ac_id=${Mask.encode(
        props.id
      )}`
    );
  }, []);
  const [first, setfirst] = useState(false);
  useEffect(() => {
    var accid = props.id;
    axios
      .post("/api/User/rateingforconCheck", {
        accid,
      })
      .then((res) => {
        console.log(res.data);
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const notify = (message, stat) => {
    // console.log(message);
    // console.log(stat)

    if (stat == true) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == false) {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [loading, setloading] = useState(false);
  const [Email, setEmail] = useState([]);
  const [AllEmail, setAllEmail] = useState([]);
  const [error, seterror] = useState(false);
  const [EmailRef, setEmailRef] = useState({
    email: [],
    subject: "",
    massage: "",
    sub: "",
    id: props.id,
  });
  let Navigate = useNavigate()
  const makeEmail = (x, Lorum) => {
    console.log(Lorum);
    console.log(x);
    x = document.getElementById(x);
    x.style.color = "black";
    var val = x.value;
    if (val.charAt(val.length - 1) === " ") {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
    if (Lorum) {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
  };

  const [Arr, setArr] = useState([]);
  var listItems = "";

  useEffect(() => {
    setEmailRef({ ...EmailRef, email: AllEmail });
  }, [AllEmail]);

  const removeEmail = (e) => {
    var index = AllEmail.indexOf(e);
    AllEmail.splice(index, 1);
    setEmailRef({ ...EmailRef, email: AllEmail });
  };

  const addEmail = (vall) => {
    vall = vall.trim();
    var dep = AllEmail;
    dep.push(vall);
    setAllEmail(dep);
    var x = React.createElement(
      "div",
      {
        className:
          "p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1",
        role: "alert",
      },
      [
        React.createElement(
          "strong",
          {},
          vall,
          <>&nbsp;&nbsp;&nbsp;</>,
          React.createElement(
            "button",
            {
              onClick: (e) => removeEmail(vall),
              type: "button",
              className: "btn-close ms-5 Gbt p-0 pt-2 ",
              "data-bs-dismiss": "alert",
              "aria-label": "Close",
            },
            ""
          )
        ),
      ]
    );
    Arr.push(x);
    listItems = Arr.map((myList) => {
      return <>{myList}</>;
    });
    setEmail(listItems);
  };
  const communicate = (e) => {
    e.preventDefault();
    let st = e.target.elements.option.value === "cc" ? true : false;
    let id = props.id;
    // document.getElementById("slr").click();
    let dataSend = {
      id,
      st,
    };
    e.target.reset();
    axios
      .post("/api/User/communicate", { dataSend })
      .then((res) => {
        if (res.status === 200) {
          Navigate("/cubicles?consultantTrue=" + res.data);
          console.log(res);
        } else {
          Navigate("/cubicles?consultantTrue=" + res.data);
        }
      })
      .catch((res) => {
        console.log(res.response);
        if (res.response.status === 400) {
          // Navigate('/cubicles?consultantTrue='+id)
        } else if (res.response.status === 401) {
          // TOAST SOMETHING WRONG SAME PERSON
        }
      });
  };
  const validation = () => {
    if (EmailRef.email?.length > 0) {
      seterror("");
    } else {
      return seterror("PLEASE ADD a Email Id");
    }
    if (EmailRef.massage.length > 0) {
      seterror("");
    } else {
      return seterror("PLEASE ENTER Message");
    }

    return true;
  };

  const [submittrue, setsubmittrue] = useState(false);
  const SendRef = (e) => {
    setsubmittrue((prev) => !prev);
    var status = false;
    const url = isService ? "/api/User/Refer" : "/api/User/ReferVendor";
    status = validation();
    if (status) {
      setloading(true);
      axios
        .post(url, {
          EmailRef,
        })
        .then((res) => {
          if (res.status === 200) {
            notify("Referred successfully", true);
            setloading(false);
            setEmailRef({
              email: [],
              subject: "",
              massage: "",
              sub: value.string2[0].sub ? value.string2[0].sub : "",
              id: props.id,
            });
            setAllEmail([]);
            setEmail([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
          notify("Something went wrong !", false);
        });
    }
  };

  useEffect(() => {
    if (submittrue) {
      validation();
    }
  });

  const addreating = (e, status) => {
    // console.log(id)
    let idd = props.id;
    if (e.target.checked) {
      var status = status;
      axios
        .post("/api/User/rateingforcon", {
          status,
          idd,
        })
        .then((res) => {
          setRenderThis((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .delete("/api/User/rateingdelcon", {
          data: {
            status,
            idd,
          },
        })
        .then((res) => {
          setRenderThis((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [CommunicationStatus, setCommunicationStatus] = useState("Loading");
  const [communicateModal, setcommunicateModal] = useState(false)
  const [communicateModal2, setcommunicateModal2] = useState(false)

  useEffect(() => {
    axios
      .post("/api/User/CommunicationStatus", { id: props.id })
      .then((res) => {
        console.log(res);
        setCommunicationStatus(res.data.Chat);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(CommunicationStatus);
    return () => { };
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header>
        {!value ? (
          <Modal.Body>
            <SpinnerSmall />
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div className="row">
              {WomanOwned && (
                <div className="col-12 ">
                  <center>
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </center>
                </div>
              )}
              {verified && (
                <div className="col-12 ">
                  {" "}
                  <center>
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </center>
                </div>
              )}
            </div>
            <center>
              <div className="d-flex justify-content-center">
                <div style={{ position: 'relative' }}>
                  <img
                    id="DP"
                    className="new-vp-image"
                    src={link}
                    alt=""
                  />
                  {logo && !isLogo &&
                    <img className="new-card-logo" src={logo} onError={() => setIsLogo(true)} />
                  }
                </div>
              </div>
              <h5 className="mb-1">{pname ? pname.toUpperCase() : ""}</h5>
              <p className="mt-0 pt-0 mb-2">
                {brandtagline ? brandtagline : ""}
              </p>
            </center>
            <center>
              <h6>
                {string ? string2[0].sub + " | " + string[0].category : ""}
                <br />
              </h6>
            </center>
            <center>
              {CommunicationStatus === true && (
                <>
                  <input
                    type="submit"
                    className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                    style={{ borderRadius: "25px" }}
                    name="btnAddMore"
                    value="COMMUNICATE VIA CHAT"
                    onClick={() => localStorage.getItem("Chat") === "false" ? setcommunicateModal2(true) : setcommunicateModal(true)}
                  // data-bs-toggle="modal"
                  // data-bs-target={`${localStorage.getItem("Chat") === "false"
                  //     ? "#turnonchat23423"
                  //     : "#communi"
                  //   }`}
                  />
                </>
              )}
            </center>
            {Description && (
              <div className="row py-2 borderBottom">
                <div className="col-12 mb-3">
                  <b>{isService ? "Service" : "Product"} Description</b>
                </div>
                <div className="col-12">
                  <label style={{ color: "var(--blue)" }}>{Description}</label>
                </div>
              </div>
            )}
            {(firstname || gender || age || language?.length > 0) && (
              <div className="row py-2 borderBottom">
                <div className="col-12 mb-3">
                  <span>
                    <b>
                      Personal Information
                      <br />
                    </b>
                    (of the Service Provider / Proprietor)
                  </span>
                </div>
                {firstname && (
                  <>
                    <div className="col-md-6">
                      <span>
                        {!isService
                          ? "Name of the Proprietor / Business Owner"
                          : "Name of the Proprietor / Service Provider"}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label style={{ color: "var(--blue)" }}>
                        {firstname.toUpperCase()}{" "}
                        {lastname ? lastname.toUpperCase() : ""}
                      </label>
                    </div>
                  </>
                )}
                {gender && (
                  <>
                    <div className="col-md-6">
                      <span>Gender</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>{gender}</label>
                    </div>
                  </>
                )}
                {age && (
                  <>
                    <div className="col-md-6">
                      <span>Age</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>
                        {age + " years"}
                      </label>
                    </div>
                  </>
                )}
                {language && language?.length > 0 && (
                  <>
                    <div className="col-md-6">
                      <span>Languages Known</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>
                        {language.map((element, index) => {
                          if (index == language?.length - 1) {
                            return element;
                          } else {
                            return element + ", ";
                          }
                        })}
                      </label>
                    </div>
                  </>
                )}
              </div>
            )}
            {(EntityName ||
              brandName ||
              brandtagline ||
              typeoffirm ||
              address_floor ||
              address ||
              address_building ||
              address_door ||
              address_street ||
              address_nearest ||
              state ||
              city ||
              country ||
              workingdaysOffnew ||
              worktimimng ||
              workingdaysfullnew ||
              working_hour_1 ||
              break_time_1 ||
              workingdayshalfnew ||
              working_hour_3 ||
              pincode ||
              who ||
              gst ||
              Location ||
              email ||
              countrycode ||
              statecode ||
              CON) && (
                <div className="row py-2 borderBottom">
                  <div className="col-12 mb-3">
                    <b>Business and Contact Information</b>
                  </div>
                  {EntityName && (
                    <>
                      <div className="col-md-6">
                        <span>Entity Name</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>
                          {EntityName}
                        </label>
                      </div>
                    </>
                  )}
                  {brandName && (
                    <>
                      <div className="col-md-6">
                        <span>Brand Name</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>
                          {brandName}
                        </label>
                      </div>
                    </>
                  )}
                  {brandtagline && (
                    <>
                      <div className="col-md-6">
                        <span>Entity / Brand Tag line</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>
                          {brandtagline}
                        </label>
                      </div>
                    </>
                  )}
                  {typeoffirm?.length && (
                    <>
                      <div className="col-md-6">
                        <span>Type of Firm</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>
                          {typeoffirm}
                        </label>
                      </div>
                    </>
                  )}
                  {(address_floor ||
                    address ||
                    address1 ||
                    address_building ||
                    address_door ||
                    address_street ||
                    address_nearest) && (
                      <>
                        <div className="col-12 mb-2">
                          <label style={{ color: "var(--blue)" }}>Address</label>
                        </div>
                        {address_building && (
                          <>
                            <div className="col-md-6">
                              <span>Building / Mall / Property Name</span>
                            </div>
                            <div className="col-md-6">
                              <label style={{ color: "var(--blue)" }}>
                                {address_building}
                              </label>
                            </div>
                          </>
                        )}
                        {address_door && (
                          <>
                            <div className="col-md-6">
                              <span>Door / Shop No.</span>
                            </div>
                            <div className="col-md-6">
                              <label style={{ color: "var(--blue)" }}>
                                {address_door}
                              </label>
                            </div>
                          </>
                        )}
                        {address_floor && (
                          <>
                            <div className="col-md-6">
                              <span>Floor</span>
                            </div>
                            <div className="col-md-6">
                              <label style={{ color: "var(--blue)" }}>
                                {address_floor}
                              </label>
                            </div>
                          </>
                        )}
                        {address_street && (
                          <>
                            <div className="col-md-6">
                              <span>Street / Lane / Road Name</span>
                            </div>
                            <div className="col-md-6">
                              <label style={{ color: "var(--blue)" }}>
                                {address_street}
                              </label>
                            </div>
                          </>
                        )}
                        {(address || address1) && (
                          <>
                            <div className="col-md-6">
                              <span>Area / Locality Name</span>
                            </div>
                            <div className="col-md-6">
                              <label style={{ color: "var(--blue)" }}>
                                {address || address1}
                              </label>
                            </div>
                          </>
                        )}
                        {address_nearest && (
                          <>
                            <div className="col-md-6">
                              <span>Nearest Landmark</span>
                            </div>
                            <div className="col-md-6">
                              <label style={{ color: "var(--blue)" }}>
                                {address_nearest}
                              </label>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  {state && (
                    <>
                      <div className="col-md-6">
                        <span>City, State / Province, Country</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {city}, {state}, {country}
                        </label>
                      </div>
                    </>
                  )}
                  {localityh && (
                    <>
                      <div className="col-md-6">
                        <span>Locality</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>
                          {localityh}
                        </label>
                      </div>
                    </>
                  )}
                  {pincode && (
                    <>
                      <div className="col-md-6">
                        <span>Pin code</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>{pincode}</label>
                      </div>
                    </>
                  )}
                  {Location && Location_Coordinates && (
                    <>
                      <p>GPS Location</p>
                      <div className="container">
                        <a
                          target="_blank"
                          href={`http://maps.google.com/maps?z=18&q=${Location_Coordinates.lat},${Location_Coordinates.lng}`}
                        >
                          <StaticGoogleMap
                            zoom="15"
                            size="600x400"
                            className="img-fluid"
                            apiKey="AIzaSyDv5zqgtn-X-lNQJoaOWwgBa_f0kpCLyDg"
                          >
                            <Marker
                              location={`${Location_Coordinates.lat},${Location_Coordinates.lng}`}
                              color="blue"
                              label="P"
                            />
                          </StaticGoogleMap>
                        </a>
                      </div>
                    </>
                  )}

                  {who && (
                    <>
                      <div className="col-md-6">
                        <span>Type of Vendor / Business</span>
                      </div>
                      <div className="col-md-6 address">
                        <label style={{ color: "var(--blue)" }}>
                          {" "}
                          {who
                            ? who == "Both"
                              ? "Wholesalers and Retailers"
                              : who
                            : ""}
                        </label>
                      </div>
                    </>
                  )}
                  {TypeofStore && (
                    <>
                      <div className="col-md-6">
                        <span>Type of Entity</span>
                      </div>
                      <div className="col-md-6 address">
                        <label style={{ color: "var(--blue)" }}>
                          {" "}
                          {TypeofStore
                            ? TypeofStore == "Both"
                              ? "Physical Store and E-commerce"
                              : TypeofStore == "Physical"
                                ? "Physical Store"
                                : "E-commerce"
                            : ""}
                        </label>
                      </div>
                    </>
                  )}
                  {yearofb && (
                    <>
                      <div className="col-md-6">
                        <span>Year of Business Inception</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>{yearofb}</label>
                      </div>
                    </>
                  )}
                  {licence && (
                    <>
                      <div className="col-md-6">
                        <span>Have Trade Licence</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>{licence}</label>
                      </div>
                    </>
                  )}
                  {gst && (
                    <>
                      <div className="col-md-6">
                        <span>GST registered</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>{gst}</label>
                      </div>
                    </>
                  )}

                  {website && (
                    <>
                      <div className="col-md-6">
                        <span>Website</span>
                      </div>
                      <div className="col-md-6 ">
                        <a href="#" onClick={(e) => linktovebsite(website)}>
                          {website}
                        </a>
                      </div>
                    </>
                  )}
                  {(workingdaysOffnew?.length > 0 ||
                    worktimimng?.length > 0 ||
                    workingdaysfullnew?.length > 0 ||
                    working_hour_1?.length > 0 ||
                    break_time_1?.length > 0 ||
                    workingdayshalfnew?.length > 0 ||
                    working_hour_3?.length > 0) && (
                      <>
                        <div className="col-12 my-2">
                          <b>Work Timings</b>
                        </div>
                        {worktimimng && (
                          <div className="col-md-12">
                            <label style={{ color: "var(--blue)" }}>
                              We are open 24 hours
                            </label>
                          </div>
                        )}
                        {workingdaysfullnew?.length > 0 && (
                          <>
                            <div className="col-md-6">
                              <span>Working Days (full)</span>
                            </div>
                            <div className="col-md-6 address">
                              <label style={{ color: "var(--blue)" }}>
                                {workingdaysfullnew
                                  ? workingdaysfullnew.map((element, index) => {
                                    if (index == workingdaysfullnew?.length - 1) {
                                      return element;
                                    } else {
                                      return element + ", ";
                                    }
                                  })
                                  : ""}
                              </label>
                            </div>
                          </>
                        )}
                        {working_hour_1?.length > 0 && (
                          <>
                            <div className="col-md-6">
                              <span>Working Hours</span>
                            </div>
                            <div className="col-md-6 address">
                              <label style={{ color: "var(--blue)" }}>
                                {working_hour_1 ? working_hour_1 + " to " : ""}{" "}
                                {working_hour_2 ? working_hour_2 : ""}{" "}
                              </label>
                            </div>
                          </>
                        )}
                        {break_time_1?.length > 0 && (
                          <>
                            <div className="col-md-6">
                              <span>Break Time</span>
                            </div>
                            <div className="col-md-6 address">
                              <label style={{ color: "var(--blue)" }}>
                                {break_time_1 ? break_time_1 + " to " : ""}{" "}
                                {break_time_2 ? break_time_2 : ""}{" "}
                              </label>
                            </div>
                          </>
                        )}
                        {workingdayshalfnew?.length > 0 && (
                          <>
                            <div className="col-md-6">
                              <span>Working Days (half)</span>
                            </div>
                            <div className="col-md-6 address">
                              <label style={{ color: "var(--blue)" }}>
                                {workingdayshalfnew
                                  ? workingdayshalfnew.map((element, index) => {
                                    if (index == workingdayshalfnew?.length - 1) {
                                      return element;
                                    } else {
                                      return element + ", ";
                                    }
                                  })
                                  : ""}
                              </label>
                            </div>
                          </>
                        )}
                        {(working_hour_3 && working_hour_3 != "Nil") ? (
                          <>
                            <div className="col-md-6">
                              <span>Working Hours</span>
                            </div>
                            <div className="col-md-6 address">
                              <label style={{ color: "var(--blue)" }}>
                                {working_hour_3 ? working_hour_3 + " to " : ""}{" "}
                                {working_hour_4 ? working_hour_4 : ""}{" "}
                              </label>
                            </div>
                          </>
                        ) : (<></>
                        )}

                        {workingdaysOffnew?.length > 0 && (
                          <>
                            <div className="col-md-6">
                              <span>Weekly Off</span>
                            </div>
                            <div className="col-md-6 address">
                              <label style={{ color: "var(--blue)" }}>
                                {workingdaysOffnew
                                  ? workingdaysOffnew.map((element, index) => {
                                    if (index == workingdaysOffnew?.length - 1) {
                                      return element;
                                    } else {
                                      return element + ", ";
                                    }
                                  })
                                  : ""}
                              </label>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  {email && (
                    <>
                      <div className="col-md-6">
                        <span>Email Id</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>{email}</label>
                      </div>
                    </>
                  )}
                  {countrycode && (
                    <>
                      <div className="col-md-6">
                        <span>Country Code</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {countrycode}
                        </label>
                      </div>
                    </>
                  )}

                  {statecode && (
                    <>
                      <div className="col-md-6">
                        <span>State Code</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {statecode}
                        </label>
                      </div>
                    </>
                  )}

                  {CON[0]?.contact2 && (
                    <>
                      <div className="col-md-6">
                        <span>Mobile Number / Landline Number</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {CON[0]?.contact2}
                        </label>
                      </div>
                    </>
                  )}
                  {CON[0]?.contactpersonsname2 && (
                    <>
                      <div className="col-md-6">
                        <span>Contact Person</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {CON[0]?.contactpersonsname2}
                        </label>
                      </div>
                    </>
                  )}
                  {CON[0]?.contactpersonsdesignation2 && (
                    <>
                      <div className="col-md-6">
                        <span>Contact Person’s Designation</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {CON[0]?.contactpersonsdesignation2}
                        </label>
                      </div>
                    </>
                  )}
                  {CON[0]?.preferredlanguage2 && (
                    <>
                      <div className="col-md-6">
                        <span>
                          Most Comfortable / Preferred Language for Communication
                        </span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {CON[0]?.preferredlanguage2}
                        </label>
                      </div>
                    </>
                  )}
                </div>
              )}
            <div className="row py-2 ">
              <div className="col-12 mb-2">
                <b>Rate, Refer, Report this Profile</b>
              </div>
              <div className="col-12 mb-2">
                <b>Rate</b>
              </div>
              <div className="row">
                <div className="col-auto">
                  <span>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      onChange={(e) => {
                        addreating(e, 1);
                        setfirst((prev) => ({
                          ...prev,
                          good: e.target.checked,
                        }));
                      }}
                      checked={first.good ? first.good : false}
                    />
                  </span>
                </div>
                <div className="col">
                  <span>Good</span>
                </div>
                <div className="col-auto">
                  <span>: {Status1 ? Status1.length : ""}</span>
                </div>
              </div>
              <div className="row  mb-2">
                <div className="col-auto">
                  <span>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      onChange={(e) => {
                        addreating(e, 2);
                        setfirst((prev) => ({
                          ...prev,
                          high: e.target.checked,
                        }));
                      }}
                      checked={first?.high ? first.high : false}
                    />
                  </span>
                </div>
                <div className="col ">
                  <span>Highly Recommended (HR)</span>
                </div>
                <div className="col-auto">
                  <span>: {Status2 ? Status2.length : ""}</span>
                </div>
              </div>
              <div className="col-12 mb-2">
                <b>Refer</b>
              </div>
              <div className="col-12  mb-2" style={{ display: "inline-flex" }}>
                <div className="px-2">
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <InlineShareButtons
                      config={{
                        color: "social", // set the color of buttons (social, white)
                        enabled: true, // show/hide buttons (true, false)
                        font_size: 16, // font size for the buttons
                        labels: { cat: "hai da" }, // button labels (cta, counts, null)
                        language: "en", // which language to use (see LANGUAGES)
                        networks: ["whatsapp"],
                        padding: 12, // padding within buttons (INTEGER)
                        radius: 4, // the corner radius on each button (INTEGER)
                        size: 40, // the size of each button (INTEGER)
                        url: url, // (defaults to current url)
                      }}
                    />
                  </div>
                  <p className="m-0 shareabel">WhatsApp</p>
                </div>
                <div className="px-2">
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <button
                      className="cubiclebutton"
                      onClick={(e) => {
                        setShow1(!show1);
                      }}
                    >
                      <img src="/cubicleshare.png" />
                    </button>
                  </div>
                  <p className="m-0 shareabel">Cubicles</p>
                </div>
              </div>
              <div className="col-12 mb-2">
                <b>Report</b>
              </div>
              <div className="col-12 mb-5">
                <a href="#" onClick={() => setShow2(!show2)}>
                  Report this Profile
                </a>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
      <Modal
        show={show1}
        onHide={() => {
          handleClose1();
        }}
        backdrop={"static"}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <CubicleAPI callback={handleClose1} url={url} />
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={() => {
          handleClose2();
        }}
        backdrop={"static"}
        centered
        scrollable
        size="sm"
        style={{ backgroundColor: "#000000ad" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report this Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2">
          <p>
            Please BE INFORMED that you must Report Abuse about a
            Profile ONLY for a genuine valid reason which you MUST
            mention with evidence.
          </p>
          <p>
            <b>
              Fake / False reporting about a member as an act of
              prank will result in deactivation of your membership
              without refund.
            </b>
          </p>
          <p>Why are you Reporting this Profile ?</p>
          <span>This is :</span>
          <FormControl
            className="form-control"
            style={{ zIndex: 99999999999 }}
          >
            <>
              <Select
                id="demo-multiple-checkbox"
                multiple
                className="form-select"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    display: "none",
                  },
                  "& .MuiSvgIcon-root": { display: "none" },
                  "& .MuiSelect-select": { padding: "0" },
                }}
                value={sabari.tags}
                onChange={(e) =>
                  setsabari({ ...sabari, tags: e.target.value })
                }
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {reportTags.map((languages) => (
                  <MenuItem key={languages} value={languages}>
                    <Checkbox
                      checked={
                        sabari.tags.indexOf(languages) > -1
                      }
                    />
                    <ListItemText primary={languages} />
                  </MenuItem>
                ))}
              </Select>
            </>
          </FormControl>

          <textarea
            className="form-control mt-2"
            rows={6}
            onChange={(e) =>
              setsabari({ ...sabari, report: e.target.value })
            }
            placeholder="Type your Reason"
          ></textarea>
          <p className="text-danger">
            {sabarierr ? sabarierr : ""}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn pro-spaces-button3"
            onClick={reprtsabari}
          >
            Report
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={communicateModal} onHide={() => setcommunicateModal(false)} centered  >
        <ModalHeader closeButton>
          Specify your Requirement *

        </ModalHeader>
        <ModalBody>
          <form onSubmit={(e) => communicate(e)}>
            <div className="modal-body">
              <div className="form-check  ms-5 mt-2 mb-3">
                <input
                  type="radio"
                  className="form-check-input"
                  value="cc"
                  id="flexCheckDefault"
                  name="option"
                // onChange={(e) => communicate(e, true)}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault"
                >
                  Consultation (as a Client)
                </label>
              </div>
              <div className="form-check ms-5 mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  value="si"
                  name="option"
                  id="flexCheckDefaultw"
                // onChange={(e) => communicate(e, false)}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefaultw"
                >
                  Service Introduction
                </label>
              </div>
              <div className="form-check ms-5">
                <input
                  className="form-check-input"
                  type="radio"
                  value="pm"
                  name="option"
                  id="flexCheckDefaulte"
                // onChange={(e) => communicate(e, false)}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefaulte"
                >
                  Product Marketing
                </label>
              </div>
              {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
            </div>

            <div class="modal-footer">
              <button
                className="btn pro-spaces-button2 float-end"
                type="submit"
              >
                Go
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal show={communicateModal2} onHide={() => setcommunicateModal2(false)} centered>

        <ModalHeader closeButton  >
          You need to Activate Chat to communicate with this
          member.
        </ModalHeader>
        <ModalBody>
          {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
          <p>
            Your One-to-One Chatting feature is currently
            Disabled.
          </p>
          <p
            style={{
              color: "#0a58ca",
              textDecoration: "underline",
            }}

            onClick={commu}
            to="/profile_details_and_settings"
          >
            Enable Chatting now
          </p>
        </ModalBody>
      </Modal>


    </>
  );
};
