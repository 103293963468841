import { Accordion, AccordionSummary, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { keyGen } from '../../Utility/helper';
const CubicleAPI = ({ callback ,url}) => {

    const [Loading, setLoading] = useState(false)
    const [Result, setResult] = useState([]);
    const chatuserListLoad = async () => {
        setLoading(true)
        // var data = await axios.get("/api/User/communications");
        var data = await axios.get("/api/User/listTopicsGroupChat");
        // console.log(datanew);
        let temp = []
        temp = data.data
        // .map((e) => {
        //     if (e.isGroupChat) {
        //         return e.topic.map((er) => {
        //             return er
        //         })
        //     }else{
        //         return e
        //     }
        // })
        // temp = temp.flat(1).filter((er)=>{
        //     return er!=undefined
        // })
        console.log(temp);
        setLoading(false)
        if (data) {
            setResult(temp);
        }
    };

    useEffect(() => {
        chatuserListLoad()
    }, [])
    const [Error, setError] = useState("")
    const submit = () => {
        console.log(Selected);
        if (Selected.length == 0 && SelectedTopic.length==0) {
            return setError("Select at least one ")
        }
        let message =url
        let data = [...Selected]
        data = data.map((e) => {
            return e._id
        })
        console.log(data);
        axios.post('/api/User/ShareToCubicle', { data: { to: data, message, topic: SelectedTopic } }).then((res) => {
            setSelected([])
            if (callback) {
                callback()
            }
        }).catch((err) => {
            console.log(err);
        })

    }
    const [Selected, setSelected] = useState([])
    const [SelectedTopic, setSelectedTopic] = useState([])
    const action = (data, callback) => {
        let index = Selected.indexOf(data)
        if (index >= 0) {
            let temp = [...Selected]
            temp.splice(index, 1)
            setSelected([...temp])
            callback((prev) => !prev)
        } else {
            setSelected((prev) => [...prev, data])
            callback((prev) => !prev)

        }
    }
    const actionNew = (data, callback) => {
        let index = SelectedTopic.indexOf(data)
        if (index >= 0) {
            let temp = [...SelectedTopic]
            temp.splice(index, 1)
            setSelectedTopic([...temp])
            callback((prev) => !prev)
        } else {
            setSelectedTopic((prev) => [...prev, data])
            callback((prev) => !prev)

        }
    }
    const [toggle, settoggle] = useState(false)
    return (
        <div>
            <div className='container'>

                <div style={{ height: '50px' }} className='row p-1'>
                    <div
                        className={`col-6 px-0 d-flex justify-content-center align-items-center ${!toggle && 'pro-spaces-button2'}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => settoggle(false)}
                    >
                        <div className='text-center' >
                            PRIVATE
                        </div>
                    </div>
                    <div
                        className={`col-6 px-0 d-flex justify-content-center align-items-center ${toggle && 'pro-spaces-button2'}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => settoggle(true)}
                    >
                        <div className='text-center' >
                            GROUPS
                        </div>
                    </div>
                </div>
            </div>

            <div
                style={{
                    "max-height": "calc( 100vh - 250px)",
                    "overflow": "scroll"
                }}
            >
                <div
                    className={`${!toggle && 'd-none'}`}
                >
                    <div class="accordion" id="accordionExample">
                        {Result.map((e,index) => {
                            let groupDP = "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/groupIcon.png"
                            if (e.isGroupChat) {
                                console.log(e);
                                let id =  keyGen()+index 
                                return <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="true" aria-controls="collapseOne">
                                            {e.chatName}
                                        </button>
                                    </h2>
                                    <div id={`collapse${id}`}class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body p-0">
                                            {e.topic.map((ele) => {
                                                return <SingleSelectable Result={Result} data={ele._id} action={actionNew} name={ele.Topic} DP={groupDP} />

                                            })}
                                        </div>
                                    </div>
                                </div>


                            }
                        })}
                    </div>

                </div>

                <div
                    className={`${toggle && 'd-none'}`}
                >

                    {Result.map((e) => {
                        if (e.isGroupChat == false) {
                            return <SingleSelectable Result={Result} data={e} action={action} name={e.chatName} DP={e.DP} />
                        }
                    })}
                </div>
            </div>
            <div className='container'>

                <div className='row'>
                    <div className='col-9 align-items-center d-flex'>
                        <span className='text-danger'>{Error}</span>
                    </div>
                    <div className='col-3 p-2 d-flex justify-content-end'>
                        <button
                            className='pro-spaces-button btn '
                            onClick={(e) => submit()}
                        >send&nbsp;{(Selected.length > 0 || SelectedTopic.length > 0) && `(${Selected.length + SelectedTopic.length})`}</button></div>
                </div>
            </div>
        </div >
    )
}

export default CubicleAPI

const SingleSelectable = ({ DP, name, action, data, Result }) => {
    const [Selected, setSelected] = useState(false)
    useEffect(() => {


        return () => {
            setSelected(false)

        }
    }, [])

    return <div
        className={`py-1 px-3 mb-1  ${Selected ? 'selectedE' : 'SingleSelectableEach'}`}
        onClick={() => action(data, setSelected)}
    >
        <img
            style={{ borderRadius: '50%' }}
            height={45}
            width={45}
            src={DP}
        ></img>
        <span className='px-3'>
            {name}
        </span>
    </div>
}