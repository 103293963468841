import React, { useEffect, useReducer, useState } from "react";
import { Route, Routes } from "react-router";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Active } from "../../Pages/Active";
import { Passive } from "../../Pages/Passive";
import io from "socket.io-client";
import { Link } from "react-router-dom";
import { Activeexp } from "../../Pages/Activeexp";
import { Passiveexp } from "../../Pages/Passiveexp";
import { Activev2 } from "../../Pages/Activev2";
import { Activeexpv2 } from "../../Pages/Activeexpv2";
import { Activev3 } from "../../Pages/Activev3";
import { Activeexpv3 } from "../../Pages/Activeexpv3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal } from "react-bootstrap";
import { notify as sabaritost } from "../../Utility/notify";
import { SketchPicker } from "react-color";
import { IoClose } from "react-icons/io5";
import { Anime } from "../../Components/Anime/Anime";
import { useDispatch } from "react-redux";
import { socketConnection } from "../../redux/Prochure/cubicleSlice";
import { useSelector } from "react-redux";
import { addUser } from "../../redux/Prochure/userSlice";

var r = document.querySelector(":root");

r.style.setProperty("--blue", "#55374a");
r.style.setProperty("--display", "none !important");

var serve = "https://prochure.in";
// var serve = "http://127.0.0.1:8080";
let socket;
let bgh;
let prev = null;
const fcheck = (ev) => {
  if (ev == prev) {
    return false;
  } else {
    prev = ev;
    return true;
  }
};
let user;
export function Homecopo(props) {
  const [SocketConnected, setSocketConnected] = useState();
  const [thisRoom, setthisRoom] = useState();
  const [firstsd, setfirstsd] = useState();
  let dispatch = useDispatch();
  useEffect(() => {
    whois();
    localStorageData();
    socket = io(serve);
    socket.on("connected", () => {
      console.log("connection done");
      setSocketConnected(true);
      dispatch(socketConnection({ socket }));
    });
    var user = localStorage.getItem("User");
    // console.log(user);
    socket.emit("setup", user);
  }, []);
  let sockete = useSelector((state) => state.cubicle.value);
  console.log(sockete);
  let navigate = useNavigate();
  const [Iam, setIam] = useState(false);

  const localStorageData = async () => {
    //  this function is used to fetch userData from server and store in localstorage, redux
    //  these values fetched and stored is used in different parts
    //  this include the color theme of app and location of user preferences which affect search functions
    axios
      .get("/api/ProtectedApi/LocalData")
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          var r = document.querySelector(":root");
          localStorage.setItem("Firstname", res.data.firstname);
          localStorage.setItem("Lastname", res.data.lastname);
          localStorage.setItem("Type", res.data.Type);
          localStorage.setItem("Cat", res.data.category);
          localStorage.setItem("User", res.data._id);
          localStorage.setItem("propic", res.data.propic);
          localStorage.setItem("Color", res.data.Color);
          localStorage.setItem("propic", res.data.filename);
          localStorage.setItem("Sub_cat", res.data.subcategory);
          localStorage.setItem("pname", res.data.pname);
          localStorage.setItem("Chat", res.data.Chat);
          localStorage.setItem("Validity", res.data.Validity);
          localStorage.setItem("Country", res.data.country);
          dispatch(
            addUser({
              Country: res.data.country,
              quickLocation: res.data.quickLocation,
            })
          );
          r.style.setProperty(
            "--blue",
            res.data.Color ? res.data.Color : "#55374a"
          );
          r.style.setProperty("--display", "none !important");
          setfirstsd(res.data.Color ? res.data.Color : "#55374a");
        }
      })
      .catch((res) => {
        console.log("111");
        // navigate("/");
        navigate("/lobby/");
      });
  };

  const whois = () => {
    // this function is used to fetch user details, to determine user type, status and allocate routes according to that
    axios
      .get("/api/verify/whois")
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setIam({
            iam: res.data.iam,
            status: res.data.status,
          });
          //
        } else {
          console.log("222");
          // navigate("/");
          navigate("/lobby/");
        }
      })
      .catch((res) => {
        console.log(res);
        console.log("333");
        navigate("/");
      });
  };

  const [AccountSwh, setAccountSwh] = useState();
  const ShowAccounts = () => {
    // this function returns all profiles of the user
    axios
      .get("/api/ActiveUser/accountswitch")
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setAccountSwh(res.data);
        }
      })
      .catch((res) => {});
  };

  const notify = (message) => {
    toast.warning(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function Switch(id) {
    // this function will help user to switch between profiles
    var dataSend = {
      Account: id,
    };
    axios
      .post("/api/AuthenticatedUser/Switch", { dataSend })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          // setAccountSwh(res.data)
          localStorageData(); // set localstorage again with new profile
          navigate("/");
          window.location.reload();
        }
      })
      .catch((res) => {});
  }

  const logout = (e) => {
    axios
      .post("/api/Logout")
      .then((res) => {
        // alert('logout')
        handleClose();
        localStorage.clear();
        window.location.reload();
        return navigate("/signin");
      })
      .catch((re) => {});
  };

  useEffect(() => {
    socket.on("controll", (val) => {
      var op = fcheck(JSON.stringify(val));
      if (!op) {
        return;
      }
      chatHandler(val);
    });
  });
  const chatHandler = (r) => {
    // console.log(r)
    let uri = window.location.href;
    // console.log(uri)
    let tr = uri.indexOf("cubicles") != -1;
    if (!tr) {
      console.log("chat notification");
    }
  };

  const checkerkuttapan = () => {
    document.getElementById("ekufgugeqlfehouqgfuoqoebfu").click();
    console.log("check for ac");
    axios
      .get("/api/ActiveUser/accountswitch")
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          if (res.data.length >= 20) {
            notify("You have reached the maximum limit of profiles");
          } else {
            navigate("/new_profile");
          }
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const checkerkuttapanforBasic = () => {
    document.getElementById("ekufgugeqlfehouqgfuoqoebfu").click();
    axios
      .get("/api/ActiveUser/accountswitch")
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          if (res.data.length >= 10) {
            notify("You have reached the maximum limit of profiles");
          } else {
            navigate("/new_profile");
          }
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const LogOutModal = () => {
    // logout(e);
    document.getElementById("ekufgugeqlfehouqgfuoqoebfu").click();
    setTimeout(() => {
      handleShow();
    }, 250);
  };

  const elem = <h1>working</h1>;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const LogoutFromall = () => {
    //
    axios
      .post("/api/Logout/FromAll")
      .then((res) => {
        // alert('logout')
        handleClose();
        localStorage.clear();
        window.location.reload();
        return navigate("/signin");
      })
      .catch((re) => {});
  };

  const handleChangeComplete = (color) => {
    // this.setState({ background: color.hex });
    var r = document.querySelector(":root");
    r.style.setProperty("--blue", color.hex);
    setfirstsd(color.hex);
    // sab = color.hex
  };

  const colorChangeButton = (e, svr) => {
    // console.log(firstsd)
    var sab;
    if (svr) {
      var r = document.querySelector(":root");
      r.style.setProperty("--blue", svr);
      setfirstsd(svr);
      sab = svr;
    } else {
      sab = firstsd;
    }

    axios
      .post("/api/User/colorchange", {
        sab,
      })
      .then((res) => {
        sabaritost("Theme Colour Updated", true);
        document.getElementById("ekufgugeqlfehouqgfkjsbvjsvffuoqoebfu").click();
      })
      .catch((err) => {
        console.log(err);
        sabaritost("Something went wrong !", false);
      });
  };

  return (
    <div>
      {/* <input type="text" id="cokm"></input>
      <button onClick={() => check()}> click </button> */}
      <Routes>
        {Iam.status === "Active" ? (
          Iam.iam === "Product" ||
          Iam.iam === "Consultant" ||
          Iam.iam === "inspiration." ||
          Iam.iam === "sponsored" ? (
            <Route
              element={<Active ShowAccounts={ShowAccounts} />}
              path="/*"
            ></Route>
          ) : Iam.iam === "Passive" ? (
            <Route Route element={<Passive />} path="/*"></Route>
          ) : Iam.iam === "Productv2" || Iam.iam === "Consultantv2" ? (
            <Route
              element={<Activev2 ShowAccounts={ShowAccounts} />}
              path="/*"
            ></Route>
          ) : Iam.iam === "Productv3" || Iam.iam === "Consultantv3" ? (
            <Route
              element={<Activev3 ShowAccounts={ShowAccounts} />}
              path="/*"
            ></Route>
          ) : (
            ""
          )
        ) : Iam.status === "Expired" ? (
          Iam.iam === "Product" ||
          Iam.iam === "Consultant" ||
          Iam.iam === "inspiration." ||
          Iam.iam === "sponsored" ? (
            <Route
              element={<Activeexpv3 ShowAccounts={ShowAccounts} />}
              path="/*"
            ></Route>
          ) : Iam.iam === "Passive" ? (
            <Route
              Route
              element={<Activeexpv3 ShowAccounts={ShowAccounts} />}
              path="/*"
            ></Route>
          ) : Iam.iam === "Productv2" || Iam.iam === "Consultantv2" ? (
            <Route
              element={<Activeexpv3 ShowAccounts={ShowAccounts} />}
              path="/*"
            ></Route>
          ) : Iam.iam === "Productv3" || Iam.iam === "Consultantv3" ? (
            <Route
              element={<Activeexpv3 ShowAccounts={ShowAccounts} />}
              path="/*"
            ></Route>
          ) : (
            ""
          )
        ) : (
          <>{/* <Route element={<Anime />} exact path="/"></Route> */}</>
        )}
      </Routes>

      {/* <------------------------Modal-------------------------> */}

      {/* <---------------Account Switch------------------> */}
      <div
        className="modal fade"
        id="staticBackdrop69"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div>
                {AccountSwh ? (
                  AccountSwh.map((item) => {
                    return (
                      <div className="col-12">
                        <div className="row">
                          <div className="col-8 mt-2 mb-2">{item.pname}</div>
                          <div className="col-4 mt-2 mb-2">
                            {localStorage.getItem("User") === item._id ? (
                              <button
                                className="btn pro-spaces-button px-3"
                                onClick={(e) => Switch(item._id)}
                                disabled
                              >
                                Switch
                              </button>
                            ) : (
                              <button
                                className="btn pro-spaces-button px-3"
                                onClick={(e) => Switch(item._id)}
                              >
                                Switch
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <button className="btn pro-spaces-button">
                    Make a new profile
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <---------------Account Switch------------------> */}

      {/* <---------------Mobile navigation bar switch account modal------------------> */}

      <div
        className="modal fade"
        id="exampleModalewgvougwouegv"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "1000000000" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{ backgroundColor: "var(--blue)", color: "white" }}
          >
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                {/* <div className=''>
                <IoClose size={25} className='float-end' data-bs-dismiss="modal" aria-label="Close" id="ekufgugeqlfehouqgfuoqoebfu"/>
              </div> */}
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    id="ekufgugeqlfehouqgfuoqoebfu"
                    className="btn pe-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <IoClose size={25} style={{ color: "white" }} />
                  </button>
                </div>
              </div>
              <div className="row">
                {Iam.iam != "Passive" && (
                  <p className="mt-0 mb-2">PROFILE &nbsp;-related :</p>
                )}
                {localStorage.getItem("Cat") !== "undefined" ? (
                  <>
                    {Iam.iam === "Product" ||
                    Iam.iam === "Productv2" ||
                    Iam.iam === "Productv3" ? (
                      <div className="col-12">
                        <Link
                          to="/my_profile_products"
                          className="modalfontcolor"
                          onClick={(e) =>
                            document
                              .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                              .click()
                          }
                        >
                          View and Edit Profile
                        </Link>
                      </div>
                    ) : Iam.iam === "inspiration." ? (
                      <div className="col-12">
                        <Link
                          to="/my_profile_pro-spaces"
                          className="modalfontcolor"
                          onClick={(e) =>
                            document
                              .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                              .click()
                          }
                        >
                          View and Edit Profile
                        </Link>
                      </div>
                    ) : Iam.iam === "sponsored" ? (
                      <></>
                    ) : Iam.iam === "Consultant" ||
                      Iam.iam === "Consultantv2" ||
                      Iam.iam === "Consultantv3" ? (
                      <div className="col-12">
                        <Link
                          to="/my_profile_services"
                          className="modalfontcolor"
                          onClick={(e) =>
                            document
                              .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                              .click()
                          }
                        >
                          View and Edit Profile
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}

                    {Iam.iam === "Product" || Iam.iam === "Consultant" ? (
                      <>
                        <div className="col-12">
                          <a
                            className="modalfontcolor"
                            id="switchingprofilesnow"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop69"
                            onClick={() => ShowAccounts()}
                          >
                            Switch Profile
                          </a>
                        </div>
                        <div className="col-12">
                          <Link
                            to="/advertisement"
                            className="modalfontcolor"
                            onClick={(e) =>
                              document
                                .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                                .click()
                            }
                          >
                            Create Profile Advertisement
                          </Link>
                        </div>
                        <div className="col-12">
                          <Link
                            to="/profile_details_and_settings"
                            className="modalfontcolor"
                            onClick={(e) =>
                              document
                                .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                                .click()
                            }
                          >
                            Profile Details and Settings
                          </Link>
                        </div>
                        <div className="col-12">
                          <a
                            className="modalfontcolor"
                            onClick={(e) => checkerkuttapan()}
                          >
                            Create a New Profile
                          </a>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {Iam.iam === "Productv2" || Iam.iam === "Consultantv2" ? (
                      <>
                        <div className="col-12">
                          <a
                            className="modalfontcolor"
                            id="switchingprofilesnow"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop69"
                            onClick={() => ShowAccounts()}
                          >
                            Switch Profile
                          </a>
                        </div>
                        <div className="col-12">
                          <Link
                            to="/advertisement"
                            className="modalfontcolor"
                            onClick={(e) =>
                              document
                                .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                                .click()
                            }
                          >
                            Create Profile Advertisement
                          </Link>
                        </div>
                        <div className="col-12">
                          <Link
                            to="/profile_details_and_settings"
                            className="modalfontcolor"
                            onClick={(e) =>
                              document
                                .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                                .click()
                            }
                          >
                            Profile Details and Settings
                          </Link>
                        </div>
                        <div className="col-12">
                          <a
                            className="modalfontcolor"
                            onClick={(e) => checkerkuttapanforBasic()}
                          >
                            Create a New Profile
                          </a>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {Iam.iam === "Productv3" || Iam.iam === "Consultantv3" ? (
                      <>
                        {/* <div className="col-12">
                          <Link
                            to="/advertisement"
                            className="modalfontcolor"
                            onClick={(e) =>
                              document
                                .getElementById('ekufgugeqlfehouqgfuoqoebfu')
                                .click()
                            }
                          >
                            Create Profile Advertisement
                          </Link>
                        </div> */}
                        <div className="col-12">
                          <Link
                            to="/profile_details_and_settings"
                            className="modalfontcolor"
                            onClick={(e) =>
                              document
                                .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                                .click()
                            }
                          >
                            Profile Details and Settings
                          </Link>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {Iam.iam != "Passive" && (
                      <p className="mt-3 mb-2">ACCOUNT &nbsp;-related :</p>
                    )}

                    {/*prime membership start */}
                    {Iam.iam === "Product" ||
                    Iam.iam === "Consultant" ||
                    Iam.iam === "inspiration." ||
                    Iam.iam === "sponsored" ? (
                      <>
                        <div className="col-12">
                          <a
                            className="modalfontcolor"
                            data-bs-toggle="modal"
                            data-bs-target="#dthdjtxfgefwweugfoefsjwsjymtmumyg"
                          >
                            Membership Features
                          </a>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* membership end */}
                  </>
                ) : (
                  <></>
                )}
                {/*elite membership start */}
                {Iam.iam === "Productv2" || Iam.iam === "Consultantv2" ? (
                  <>
                    <div className="col-12">
                      <a
                        className="modalfontcolor"
                        data-bs-toggle="modal"
                        data-bs-target="#dthdjtxasjdewfwfgugafgsjwsjymtmumyg"
                      >
                        Membership Features (ELITE)
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {Iam.iam === "Productv3" || Iam.iam === "Consultantv3" ? (
                  <>
                    <div className="col-12">
                      <a
                        className="modalfontcolor"
                        data-bs-toggle="modal"
                        data-bs-target="#dthdjtxasjdewfwfgugafeoifhoegsjwsjymtmumyg"
                      >
                        Membership Features (BASIC)
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {Iam.iam === "Passive" ? (
                  <>
                    <br />

                    <div className="col-12">
                      <a
                        className="modalfontcolor"
                        data-bs-toggle="modal"
                        data-bs-target="#shygciasiucgwrfviklskjgcklbkjas"
                      >
                        Membership Features
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="col-12">
                  <Link
                    to="/Account"
                    className="modalfontcolor"
                    onClick={(e) =>
                      document
                        .getElementById("ekufgugeqlfehouqgfuoqoebfu")
                        .click()
                    }
                  >
                    Subscription Details and Account Settings
                  </Link>
                </div>
                <div className="col-12">
                  <a
                    onClick={(e) => {
                      LogOutModal();
                    }}
                    className="modalfontcolor"
                  >
                    Log Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <--------------color wheel modal------------------> */}

      <div
        className="modal fade"
        id="colorwheelmodalprochure"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  id="ekufgugeqlfehouqgfkjsbvjsvffuoqoebfu"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div>
                <div id="theme">
                  <center>
                    <SketchPicker
                      color={firstsd ? firstsd : "black"}
                      onChangeComplete={handleChangeComplete}
                      presetColors={[
                        "#3A6436",
                        "#333333",
                        "#666666",
                        "#999999",
                        "#000000",
                        "#34568B",
                        "#FF6F61",
                        "#6B5B95",
                        "#88B04B",
                        "#92A8D1",
                        "#955251",
                        "#B565A7",
                        "#009B77",
                        "#DD4124",
                        "#D65076",
                        "#45B8AC",
                        "#EFC050",
                        "#5B5EA6",
                        "#9B2335",
                        "#55B4B0",
                        "#E15D44",
                        "#7FCDCD",
                        "#BC243C",
                        "#C3447A",
                      ]}
                    />
                  </center>
                  <div className="d-grid  p-2">
                    <button
                      className="btn pro-spaces-button2 btn-block"
                      onClick={(e) => colorChangeButton(e)}
                    >
                      change colour
                    </button>
                  </div>
                  <div className="px-2">
                    <button
                      className="btn pro-spaces-button btn-rounded btn-block w-100 "
                      onClick={(e) => {
                        colorChangeButton(e, "#55374a");
                      }}
                    >
                      Select Default App Colour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <---------------LogOut Modal------------------> */}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Do you want to Log Out from :</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row gy-2">
            <div className="col-12">
              <button
                class="btn pro-spaces-button w-100 px-1"
                onClick={() => logout()}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault112"
              >
                Only this device ?
              </button>
            </div>
            <div className="col-12">
              <button
                class="btn pro-spaces-button w-100 px-1"
                onClick={(e) => LogoutFromall()}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              >
                All logged in devices ?
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
