import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Link } from "react-router-dom";
import { AiFillFire, AiOutlineSearch } from "react-icons/ai";
import { GrGroup } from "react-icons/gr";
let w
export function ChatTab(props) {

  const ForwardTo = () => {
    // console.log(props.data)
    if (props.data.isGroupChat) {
      // console.log('admin access')
      var admin = false
      for (let t = 0; t < props.data.groupAdmin.length; t++) {
        if (props.data.groupAdmin[t] === localStorage.getItem('User')) {
          admin = true
        }
      }
      props.Chatwith(props.data._id, props.data.chatName, true, admin)
    } else {
      props.Chatwith(props.data._id, props.data.chatName, false, true)
    }
    // console.log(props.data)
    // console.log(w)
    if (w <= 991) {
      // console.warning('hide')
      var left = document.getElementById('leftRoll')
      left.classList.add('d-none')
    }
  }

  function button() {
    w = window.innerWidth
    // console.log(w)
    if (w > 991) {
      document.getElementById("chatGo").classList.add("d-none");

      var left = document.getElementById('leftRoll')
      left.classList.remove('d-none')
    } else {
      document.getElementById("chatGo").classList.remove("d-none");

    }
  }
  useEffect(() => {
    button()
  })

// console.log(props)

  window.onresize = button;

  return (
    <div className="chatTabpro" onClick={(e) => ForwardTo()}>
      <div className="container-fluid  m-0 p-2 row w-100">
        <div className="col-2 p-0 pt-2">
          {" "}



          <img
            src={props.data.DP}
            alt="avatar"
            className="rounded-circle wkdcyki4fyvlk5yvy d-flex align-self-center me-3 shadow-1-strong "
            width="50"
            height="50"
            

            onError={({ currentTarget }) => {
              currentTarget.onerror = true; // prevents looping
            props.data.isGroupChat?
              currentTarget.src =
                "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/groupIcon.png"
                :
                currentTarget.src =
                "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png"
            }}
          />

        </div>

        <div className="col-10 ps-0">
          <div className="row">
            <div className="col-8 py-3">{props.data.chatName}</div>
            {/* <div className="col-4 text-end pe-0">Yesterday</div> */}
          </div>
          {/* <div className="m-1">Hello wolrd ! this is last mess</div> */}
        </div>
      </div>
      <hr className=" invisible mt-2" />
    </div>
  );
}
