import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { Mask } from "../../Utility/mask";
import { Link } from "react-router-dom";
import "../../Components/Lobby//Lobby.css";
const cardEdge5 = {
  borderRadius: "0px",
  overflow: "hidden",
  backgroundColour: "transparent",
  border: 0,
};
export const Ad_card = (props) => {
  ////// Ad ////////
  const [addivider, setAddivider] = useState({ a: [], b: [], c: [] });

  const [ad, setad] = useState([]);
  useEffect(() => {
    axios
      .get("/api/Free/getAllAd")
      .then((res) => {
        console.log("s");
        console.log(res.data);
        setad(res.data);
        let s = 1;
        for (const key of res.data) {
          let index = res.data.indexOf(key);

          key.index = index;
          // console.o
          switch (s) {
            case 1:
              setAddivider((prev) => ({ ...prev, a: [...prev.a, key] }));
              s += 1;
              break;
            case 2:
              setAddivider((prev) => ({ ...prev, b: [...prev.b, key] }));
              s += 1;
              break;
            case 3:
              setAddivider((prev) => ({ ...prev, c: [...prev.c, key] }));
              s = 1;
              break;
            default:
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Index, setIndex] = useState(null);

  const linktovebsite = (website) => {
    // window.open("http://vipar.in");
    console.log(website);
    // window.open("http://" + website, "_blank");

    if (website.indexOf("http") > -1) {
      window.open(website, "_blank");
    } else {
      window.open("http://" + website, "_blank");
    }
  };

  /////// Ad end ///////

  return (
    <div>
      <div>
        <div className="row g-3 justify-content-center ">
          <div className="col-12 col-sm-6 col-lg-4 col-xxl-3 d-block d-lg-none">
            <div className="card" style={cardEdge5}>
              <Carousel interval={4000} indicators={false} controls={false}>
                {ad?.map((ele, index) => {
                  console.log(ele);
                  return (
                    <Carousel.Item
                      onClick={(e) => {
                        setIndex(index);
                        // handleShow();
                      }}
                      className="askkdfjkhf"
                    >
                      <img
                        style={{ width: "100%" }}
                        className="img-fluid"
                        src={ele?.File[0]?.link}
                      />
                      {ele?.File[0]?.link ? (
                        <div class="img-overlaysss">
                          <Link
                            to={
                              ele?.userData[0]?.Type === "Consultant" ||
                              ele?.userData[0]?.Type === "Consultantv2" ||
                              ele?.userData[0]?.Type === "Consultantv3"
                                ? "/lobby/services_profile?Ac_id=" +
                                  Mask.encode(ele?.userData[0]?._id)
                                : "/lobby/products_profile?Ac_id=" +
                                  Mask.encode(ele?.userData[0]?._id)
                            }
                          >
                            <button
                              class="btn  btn-dark p-2 me-3"
                              style={{
                                textTransform: "none",
                                fontSize: "11px",
                              }}
                            >
                              Visit their Profile
                            </button>
                          </Link>
                          {ele?.userData[0]?.website?.length ? (
                            <button
                              class="btn  btn-dark p-2"
                              style={{
                                textTransform: "none",
                                fontSize: "11px",
                              }}
                              onClick={(e) =>
                                linktovebsite(ele?.userData[0]?.website)
                              }
                            >
                              Visit their Website
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </div>
          {addivider?.a.length ? (
            <div className="col-12 col-sm-6 col-lg-3 col-xxl-3 d-none d-lg-block">
              <div className="card" style={cardEdge5}>
                <Carousel interval={4000} indicators={false} controls={false}>
                  {addivider?.a?.map((ele, index) => {
                    return (
                      <Carousel.Item
                        className="askkdfjkhf"
                        onClick={(e) => {
                          setIndex(index);
                          // handleShow();
                        }}
                      >
                        {/* <Link
                              to={ele?.userData[0]?.Type === "Consultant" ||
                                ele?.userData[0]?.Type === "Consultantv2" ||
                                ele?.userData[0]?.Type === "Consultantv3" ?
                                "/services_profile?Ac_id=" + Mask.encode(ele?.userData[0]?._id) : "/products_profile?Ac_id=" +
                                Mask.encode(ele?.userData[0]?._id)
                              }
                            > */}
                        <img
                          style={{ width: "100%" }}
                          className="img-fluid"
                          src={ele.File[0].link}
                        />
                        {ele?.File[0]?.link ? (
                          <div class="img-overlaysss">
                            <Link
                              to={
                                ele?.userData[0]?.Type === "Consultant" ||
                                ele?.userData[0]?.Type === "Consultantv2" ||
                                ele?.userData[0]?.Type === "Consultantv3"
                                  ? "/lobby/services_profile?Ac_id=" +
                                    Mask.encode(ele?.userData[0]?._id)
                                  : "/lobby/products_profile?Ac_id=" +
                                    Mask.encode(ele?.userData[0]?._id)
                              }
                            >
                              <button
                                class="btn  btn-dark p-2 me-3"
                                style={{
                                  textTransform: "none",
                                  fontSize: "11px",
                                }}
                              >
                                Visit their Profile
                              </button>
                            </Link>
                            {ele?.userData[0]?.website?.length ? (
                              <button
                                class="btn  btn-dark p-2"
                                style={{
                                  textTransform: "none",
                                  fontSize: "11px",
                                }}
                                onClick={(e) =>
                                  linktovebsite(ele?.userData[0]?.website)
                                }
                              >
                                Visit their Website
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* </Link> */}
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : (
            <></>
          )}
          {addivider?.b.length ? (
            <div className="col-12 col-sm-6 col-lg-3 col-xxl-3 d-none d-lg-block">
              <div className="card" style={cardEdge5}>
                <Carousel interval={4000} indicators={false} controls={false}>
                  {addivider?.b?.map((ele, index) => {
                    return (
                      <Carousel.Item
                        className="askkdfjkhf"
                        onClick={(e) => {
                          setIndex(index);
                          // handleShow();
                        }}
                      >
                        {/* <Link
                              to={ele?.userData[0]?.Type === "Consultant" ||
                                ele?.userData[0]?.Type === "Consultantv2" ||
                                ele?.userData[0]?.Type === "Consultantv3" ?
                                "/services_profile?Ac_id=" + Mask.encode(ele?.userData[0]?._id) : "/products_profile?Ac_id=" +
                                Mask.encode(ele?.userData[0]?._id)
                              }
                            > */}
                        <img
                          style={{ width: "100%" }}
                          className="img-fluid"
                          src={ele.File[0].link}
                        />
                        {ele?.File[0]?.link ? (
                          <div class="img-overlaysss">
                            <Link
                              to={
                                ele?.userData[0]?.Type === "Consultant" ||
                                ele?.userData[0]?.Type === "Consultantv2" ||
                                ele?.userData[0]?.Type === "Consultantv3"
                                  ? "/lobby/services_profile?Ac_id=" +
                                    Mask.encode(ele?.userData[0]?._id)
                                  : "/lobby/products_profile?Ac_id=" +
                                    Mask.encode(ele?.userData[0]?._id)
                              }
                            >
                              <button
                                class="btn  btn-dark p-2 me-3"
                                style={{
                                  textTransform: "none",
                                  fontSize: "11px",
                                }}
                              >
                                Visit their Profile
                              </button>
                            </Link>

                            {ele?.userData[0]?.website?.length ? (
                              <button
                                class="btn  btn-dark p-2"
                                style={{
                                  textTransform: "none",
                                  fontSize: "11px",
                                }}
                                onClick={(e) =>
                                  linktovebsite(ele?.userData[0]?.website)
                                }
                              >
                                Visit their Website
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* </Link> */}
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : (
            <></>
          )}
          {addivider?.c.length ? (
            <div className="col-12 col-sm-6 col-lg-3 col-xxl-3 d-none d-lg-block">
              <div className="card" style={cardEdge5}>
                <Carousel interval={4000} indicators={false} controls={false}>
                  {addivider?.c?.map((ele, index) => {
                    return (
                      <Carousel.Item
                        className="askkdfjkhf"
                        onClick={(e) => {
                          setIndex(index);
                          // handleShow();
                        }}
                      >
                        {/* <Link
                              to={ele?.userData[0]?.Type === "Consultant" ||
                                ele?.userData[0]?.Type === "Consultantv2" ||
                                ele?.userData[0]?.Type === "Consultantv3" ?
                                "/services_profile?Ac_id=" + Mask.encode(ele?.userData[0]?._id) : "/products_profile?Ac_id=" +
                                Mask.encode(ele?.userData[0]?._id)
                              }
                            > */}
                        <img
                          style={{ width: "100%" }}
                          className="img-fluid"
                          src={ele.File[0].link}
                        />
                        {ele?.File[0]?.link ? (
                          <div class="img-overlaysss">
                            <Link
                              to={
                                ele?.userData[0]?.Type === "Consultant" ||
                                ele?.userData[0]?.Type === "Consultantv2" ||
                                ele?.userData[0]?.Type === "Consultantv3"
                                  ? "/lobby/services_profile?Ac_id=" +
                                    Mask.encode(ele?.userData[0]?._id)
                                  : "/lobby/products_profile?Ac_id=" +
                                    Mask.encode(ele?.userData[0]?._id)
                              }
                            >
                              <button
                                class="btn  btn-dark p-2 me-3"
                                style={{
                                  textTransform: "none",
                                  fontSize: "11px",
                                }}
                              >
                                Visit their Profile
                              </button>
                            </Link>
                            {ele?.userData[0]?.website?.length ? (
                              <button
                                class="btn  btn-dark p-2"
                                style={{
                                  textTransform: "none",
                                  fontSize: "11px",
                                }}
                                onClick={(e) =>
                                  linktovebsite(ele?.userData[0]?.website)
                                }
                              >
                                Visit their Website
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* </Link> */}
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
