import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Collapse, Dropdown, Modal } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { notify } from "../../Utility/notify";
// var bucketName = 'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/profile'
var bucketName = `https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/profile`;
const CommentMain = ({ postId, commentSend }) => {
  console.log(commentSend);
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [Comments, setComments] = useState([]);
  const [id, setid] = useState()





  useEffect(() => {
    axios
      .get(
        `/api/Free/getcomments?postId=${postId}&&pageLength=${Comments.length}`
      )
      .then((res) => {
        console.log(res);
        setComments(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => { };
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(true);

  const idpass =(id)=>{
    handleShow(id)
    setid(id)
  }
   
  const deletee = (id)=>{
    console.log(id)
    axios.post("/api/User/commentdelete?params", { id }).then((res) => {
      console.log("asd")
      notify("Deleted", true)
    }).catch((err) => {
      console.log(err)
      console.log("Something Went Wrong", false)
    })
  }

  return (
    <div>
      <span
        style={{ cursor: "pointer", fontSize: "12px" }}
        className="py-5"
        onClick={() => setOpen(!open)}
      >
        {!open ? "View " : "Hide "}comments
      </span>

      <div>
        <Collapse in={open}>
          <div id="example-collapse-text">
            {Comments.map((elem, index) => {
              console.log(elem);
              return (
                <div id="example-collapse-text">
                  <div className="row ms-1">
                  
                      <div
                        className="col-10"
                        style={{ display: "contents" }}
                      >
                        <img
                          alt=""
                          className="selfthumbnailcommentt"
                          src={`${bucketName}/${elem.User[0]._id}/avatar.png`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = true; // prevents looping
                            currentTarget.src =
                              "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
                          }}
                        />
                        <p className="m-0 ps-1">
                          {elem?.User[0]?.pname}
                          <br />
                          {moment(elem?.Post_on).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </p><br/>
                     
                      </div>

                    <div className="col">
                      
                       
                        <div className=" ">
                      <Dropdown className="dropv2Head float-end">
                        <Dropdown.Toggle className="dropV2" id="dropdown-basic">
                          <BsThreeDotsVertical color="black" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={(e)=>{handleShow();setid(elem._id)}}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                   
                        {/* <hr className="m-0"/> */}
                    
                    </div>
                    <div className="row ps-5">
                        
                    <p style={{ fontSize: "12px" }} className="m-0">
                          {elem?.Content}
                        </p>
                    </div>




                 


                    <div></div>
                  </div>

                  <CommentReplies
                    postId={postId}
                    commentId={elem._id}
                    name={elem?.User[0]?.pname}
                    commentSend={commentSend}
                    index={index}
                    idpass={idpass}
                  />
                  <hr />
                 

                </div>
              );
            })}
          </div>
        </Collapse>
      </div>
     




      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button className="btn pro-spaces-button3" onClick={(e)=>{deletee(id);handleClose()}}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CommentMain;

const CommentInput = ({ commentSend, commentId ,callback}) => {
  console.log({ commentSend, commentId });
  const [data, setdata] = useState("");
  return (
    <div className="grid-container">
      <textarea
        onChange={(e) => setdata(e.target.value)}
        value={data}
        className="commentInput grid-item1"
        type="text"
        row="4"
      />
      <div className="grid-item2  ">
        <button
          type="submit"
          onClick={(e) => {
            commentSend(data, commentId,callback);
            setdata("");
          }}
          className="btn pro-spaces-button3 d-flex justify-content-center"
          style={{ width: "70px" }}
        >
          Post
        </button>
      </div>
    </div>
  );
};

const CommentReplies = ({ commentId, index, name, commentSend, postId,idpass }) => {
    console.log(idpass)
  const [state, setstate] = useState(false);
  const [comments, setcomments] = useState([]);
  const fetch = ()=>{
    axios
    .get(
      `/api/User/loadCommentReply?postId=${postId}&&pageLength=${0}&&commentId=${commentId}`
    )
    .then((res) => {
      console.log(res);
      setcomments(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  useEffect(() => {
  fetch()

    return () => { };
  }, []);
    return (
      <div>
        <div>
          <div className="d-flex justify-content-end pe-3">
          {comments.length ?
            <span
              style={{ cursor: "pointer", fontSize: "10px" }}
              className="px-2"
              onClick={(e) => setstate(!state)}
            >
              {state ? 'Hide' : 'View'} replies
            </span>
            :<>
            </>
  }
            <span
              className=""
              style={{ fontSize: "10px" }}
              data-bs-toggle="collapse"
              id={"collap" + commentId}
              href={"#collapseExamplenewlaste" + commentId + index}
              role="button"
              aria-expanded="false"
              aria-controls=""
            >
              Reply
            </span>
          </div>
          <div
            id={"collapseExamplenewlaste" + commentId + index}
            style={{ "font-size": "12px" }}
            className="collapse ms-5 "
          >
            Replying to&nbsp;{name}
            <div className="p-0 ">
              <CommentInput commentId={commentId} callback={fetch} commentSend={commentSend} />
            </div>
          </div>
          <div>
            <Collapse in={state}>
              <div>
                {comments.map((elem) => {
                  return (
                    <div className="ps-5 px-2">
                  <hr />

                      <div className="row ms-1">
                  
                  <div
                    className="col-10"
                    style={{ display: "contents" }}
                  >
                    <img
                      alt=""
                      className="selfthumbnailcommentt"
                      src={`${bucketName}/${elem.User[0]._id}/avatar.png`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = true; // prevents looping
                        currentTarget.src =
                          "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
                      }}
                    />
                    <p className="m-0 ps-1">
                      {elem?.User[0]?.pname}
                      <br />
                      {moment(elem?.Post_on).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </p><br/>
                 
                  </div>
                     
                <div className="col">
                  
                   
                    <div className=" ">
                  <Dropdown className="dropv2Head float-end">
                    <Dropdown.Toggle className="dropV2" id="dropdown-basic">
                      <BsThreeDotsVertical color="black" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(e)=>{idpass()}}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
               
                    {/* <hr className="m-0"/> */}
                
                </div>
                <div className="row ps-5">
                    
                <p style={{ fontSize: "12px" }} className="m-0">
                      {elem?.Content}
                    </p>
                </div>




             


                <div></div>
              </div>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </div>
        </div>











      </div>
    );
};
