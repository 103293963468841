import React, { useRef, useEffect } from 'react'
import { ExistingData, InitializeCountry } from "../../Utility/Country";
import { useSearchParams } from 'react-router-dom';

export const StateCity = ({ setValue, value, Edit }) => {

    let countryRef = useRef()
    let stateRef = useRef()
    let cityRef = useRef()

    useEffect(() => {
        InitializeCountry(countryRef, stateRef, cityRef)
        if (Edit) {

            var sab = []
            sab = Edit[0]
            ExistingData(countryRef, stateRef, cityRef, sab.country, sab.state, sab.city)
        }
        return () => {

        }
    }, [])
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("superlink")) {
            let mType = searchParams.get("mType")
            let uType = searchParams.get("uType")
            var category = searchParams.get("category")
            let subcategory = searchParams.get("subcategory")
            let profileName = searchParams.get("profileName")
            let country = searchParams.get("country")
            let state = searchParams.get("state")
            let city = searchParams.get("city")
            ExistingData(countryRef, stateRef, cityRef, country, state, city)
            if (profileName.length) {
            }
            if(country.length){
                countryRef.current.disabled = true
            }
            if(state.length){
                stateRef.current.disabled = true
            }
            if(city.length){
                cityRef.current.disabled = true



                
            }
            console.log(category, subcategory);
            console.log(subcategory);
            console.log(searchParams.get("category"));

        }
        return () => {
        }
    }, [])


    return (
        <>
            <div className="col-md-12">
                <label className="labels">Country *</label>
                <select
                    required
                    ref={countryRef}
                    className="form-select"
                    value={value.country}
                    // id="State" 
                    name="City"
                    onChange={(e) => setValue((prev) => ({ ...prev, country: e.target.value }))}
                >
                    {/* <select onChange={(e) =>}  className="form-select" >
                                          </select> */}
                </select>
                <div class="invalid-feedback">
                    PLEASE SELECT Country
                </div>
            </div>
            <div className="col-md-12">
                <label className="labels">State / Province *</label>
                <select
                    ref={stateRef}
                    required
                    className="form-select"
                    value={value.state}
                    // id="State"
                    onChange={(e) => setValue((prev) => ({ ...prev, state: e.target.value }))}

                    name="City"
                // onChange={(e) => Cityshow(e)}
                >
                    {/* <select onChange={(e) =>}  className="form-select" >
                                          </select> */}
                </select>
                <div class="invalid-feedback">
                    PLEASE SELECT State / Province
                </div>
            </div>
            <div className="col-md-12">
                <label className="labels">City *</label>
                <select
                    ref={cityRef}
                    required
                    className="form-select"
                    name="City"
                    value={value.city}
                    // id="City"
                    onChange={(e) => setValue((prev) => ({ ...prev, city: e.target.value }))}

                // onChange={(e) =>
                //     setValue({ ...value, city: e.target.value })
                // }
                >
                    <option></option>
                </select>
                <div class="invalid-feedback">
                    PLEASE SELECT City
                </div>
            </div>
        </>
    )
}
