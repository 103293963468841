import { useEffect, useState } from 'react';
import { IoIosHelpCircle } from 'react-icons/io';
import { AiOutlineClose, AiFillInfoCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { dataTable } from '../../Utility/userFeaturesData';
export function UserFeatures() {
  const [Price, setPrice] = useState({});
  useEffect(() => {
    axios
      .get('/api/free/getsubscriptionfees')
      .then((res) => {
        console.log(res);
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    document.getElementsByTagName('body')[0].style.backgroundColor = 'black';
    document.getElementById('root').style.backgroundColor = 'black';
    return () => {
      document.getElementsByTagName('body')[0].style.backgroundColor = 'white';
      document.getElementById('root').style.backgroundColor = 'white';
    };
  }, []);

  console.log(localStorage.getItem('Type'));

  return (
    <>
      {localStorage.getItem('Type') ? (
        localStorage.getItem('Type').indexOf('Product') >= 0 ||
        localStorage.getItem('Type').indexOf('Consultant') >= 0 ? (
          <AMember Price={Price} dataTable={dataTable} />
        ) : (
          <PMember Price={Price} dataTable={dataTable} />
        )
      ) : (
        <>
          <NonMember Price={Price} dataTable={dataTable} />
        </>
      )}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqsnskdb"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: '15.2px', color: '#ffffff' }}
                >
                  <span style={{ color: '#c85366' }}>KNOW MY CLIENT</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <p>
                    To the mutual benefit of both the Service Providers and
                    their clients, ‘Know My Client’ is a feature where the
                    Service Providers have the option to prepare a set of
                    questions that their prospective clients must answer when
                    the client clicks on the Communicate button on the profile
                    of the service provider. This feature is available only for
                    service providers.
                  </p>
                  <p>
                    Your list of questions will be sent as a direct message to
                    your client’s inbox.
                  </p>
                  <p>
                    These questions can either be deleted or be replaced with
                    new ones at any time.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: '15.2px', color: '#ffffff' }}
                >
                  <span style={{ color: '#c85366' }}>CUBICLES</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <p>
                    CUBICLES are chat rooms for project / product /
                    service-related discussions between members
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqeiugfruoegfsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">VIRTUAL SECRETARY</h4>
                </div>
                <div className="row">
                  <p>
                    Any member can use this feature to Assign personal and
                    professional Tasks and Set Reminders of Birthdays,
                    Anniversaries, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqeiugflwihroiwgorwogruoegfsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">AUTHENTICATION BADGE</h4>
                </div>
                <div className="row">
                  <p>
                    A tag labeled ‘
                    <span style={{ color: '#c85366' }}>PRO-</span> AUTHENTIC !’
                    on the profile card means that PROchure has verified the
                    authenticity of that respective Service Provider / Product
                    Retailer. That means, we have verified the following via
                    periodic checks :
                  </p>
                  <p>
                    <ol>
                      <li>
                        Operating Address and/or Contact Information of the
                        SERVICE PROVIDER / PRODUCT RETAILER.
                      </li>
                      <li>
                        Whether the SERVICE PROVIDER is currently actively
                        providing consultation / services pertinent to the
                        category in which the profile is created.
                      </li>
                      <li>
                        Whether the PRODUCT RETAILER is currently actively
                        running the business and if it is pertinent to the
                        category in which the profile is created.
                      </li>
                      <li>
                        Whether the SERVICE PROVIDERS are genuinely registered
                        with the statutory body (constituted by the Government
                        of India) as claimed by them.
                      </li>
                    </ol>

                    <p>Please note :</p>
                    <ol>
                      <li>
                        Only ELITE and PRIME members are Eligible for this
                        badge.
                      </li>
                      <li>
                        Not all SERVICE PROVIDERS need to be mandatorily
                        registered with any statutory body to offer professional
                        consultation / commercial services.
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="wefgigwfijiqiueugfiuilqeiuegfi"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: '15.2px', color: '#ffffff' }}
                >
                  <span style={{ color: '#c85366' }}>SHOUT-OUT</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <p>
                    To help the clients / customers avoid the hassle of
                    approaching multiple Service Providers, Product Retailers
                    one-by-one, the client, customer can SHOUT-OUT his / her
                    query / requirement to the Service Providers, Product
                    Retailers in a particular sub-category. This query goes to
                    ALL the Service Providers, Product Retailers (of that
                    sub-category) as a Direct Message.
                  </p>
                  <p>
                    You can also send a SHOUT-OUT to only selected Service
                    Providers, Product Retailers after SHORT-LISTING them.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="primeprofilemodalussss"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <h4>TWENTY PROFILES in both SERVICES and PRODUCTS</h4>
                  <p>
                    You can create upto a maximum of 20 (TWENTY) Profiles in
                    total in various sub-categories of various categories in
                    both classifications, that is either as Service Providers
                    and/or as Product Retailers.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="basicprofilemodalussss"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <h4>TEN PROFILES either in SERVICES OR in PRODUCTS</h4>
                  <p>
                    You can create upto a maximum of 10 (TEN) Profiles in total
                    in multiple sub-categories of any one category within any
                    one classification (either in Services or in Products).
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

const NonMember = ({ Price }) => {
  useEffect(() => {
    console.log('76876');
    let first = navigator.userAgent;
    setTimeout(() => {
      if (first) {
        var sp = first.indexOf('Mobile');
        var sup = first.indexOf('iPad');
        var w = window.innerWidth;
        if (sup >= 0) {
          return;
        }
        console.log(sup, sp);
        if (sp >= 0) {
          console.log('kkkll');
          document.getElementById('root').classList.remove('mobileclass');
          window.addEventListener(
            'load',
            function () {
              if (w > 320)
                setTimeout(() => {
                  let tommy = document.getElementById('Tommy');
                  tommy.children[0].innerHTML = ``;
                  tommy.children[1].innerHTML = ``;
                  tommy.classList.add('invisible');
                }, 1);
            },
            false
          );
        }
      }
    }, 1000);

    return () => {
      let first = navigator.userAgent;
      if (first) {
        var sp = first.indexOf('Mobile');
        var sup = first.indexOf('iPad');
        console.log(first);
        var w = window.innerWidth;
        if (sup >= 0) {
          return;
        }
        if (sp >= 0) {
          document.getElementById('root').classList.add('mobileclass');
          console.log('kkkll');

          window.addEventListener(
            'load',
            function () {
              // if(w>320)
              setTimeout(() => {
                let tommy = document.getElementById('Tommy');
                tommy.classList.remove('invisible');
                tommy.children[0].innerHTML = `Please rotate your device`;
                tommy.children[1].innerHTML = `We don't support Landscape mode. Please switch to Portrait mode for best experience.`;
              }, 4000);
            },
            false
          );
        }
      }
    };
  }, []);

  useEffect(() => {
    try {
      console.log('mounted');
      document.getElementById('Tommy').classList.add('d-none');
    } catch (error) {
      console.log(error);
    }
    return () => {
      console.log('unmounted');
      try {
        document.getElementById('Tommy').classList.remove('d-none');
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  return (
    <>
      <div
        id="hoo1"
        className="d-block d-md-none container-fluid  jrgfrjflobjgt "
        style={{ paddingTop: '80px' }}
      >
        <div className="bg-black">
          <div className="py-1">
            <i className="text-light" style={{ fontSize: '13px' }}>
              scroll down or switch to landscape mode to see the full tabular
              chart.
            </i>
          </div>
          <div className="text-center text-light">
            <h1 className="mb-3">PAID MEMBERSHIP FEATURES</h1>
            <h4 className=" fw-bold" style={{ color: '#c85366' }}>
              For Service Providers and Product Retailers
            </h4>
            <h5
              className="text-primary"
              data-bs-toggle="modal"
              data-bs-target="#dthdjtxasjdewfwfgugafeoifhoegsjwsjymtmumyg"
            >
              BASIC <IoIosHelpCircle style={{ marginTop: '-3px' }} />
            </h5>
            <h6 className="text-center">Rs {Price?.Activev3}/- per year</h6>
            <div className="col-12 text-center pt-0 pb-2">
              (including 18% GST)
            </div>
            <div className="py-1 pt-2">
              <Link to="/static_listing_register">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="px-3 btn   pricebuttoncolor"
                  style={{ fontSize: '14px' }}
                  // onClick={(e)=>alerttt()}
                >
                  Subscribe
                </button>
              </Link>
            </div>
          </div>
          <div className="pfeatures897 container-fluid">
            {dataTable.map((data) => {
              return (
                <div className="py-2">
                  <div className="row">
                    <div className="col-8 ">
                      <span
                        data-bs-toggle={data?.modalId ? 'modal' : ''}
                        data-bs-target={
                          data?.modalId ? `#${data?.modalId}` : ''
                        }
                        className={
                          data.head == 'AUTHENTICATION BADGE '
                            ? 'spaceNoWrap'
                            : ' '
                        }
                        style={{ color: '#c85366' }}
                      >
                        {data.head}
                        {data.element && data.element}
                      </span>
                    </div>
                    <div className="col-4 text-center text-light">
                      {data.headData ? data.headData.static : ''}
                    </div>
                  </div>
                  {data.data.map((pop) => {
                    return (
                      <div className="row text-light">
                        <div className="col-8 ">
                          <span className=" ">{pop.label}&nbsp;</span>
                        </div>
                        <div
                          className={` ${
                            pop.noChange
                              ? 'col-4 text-center '
                              : 'col-4 text-center '
                          }`}
                        >
                          {pop.value.static}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <br />
            {/* <p className="text-light">** in a Subscription year</p> */}
          </div>
        </div>
        <hr style={{ color: 'white' }} />

        <div className="bg-black">
          <div className="text-center">
            <h4 className=" fw-bold" style={{ color: '#c85366' }}>
              For Service Providers and Product Retailers
            </h4>
            <h5
              className="text-primary"
              data-bs-toggle="modal"
              data-bs-target="#dthdjtxfgefwweugfoefsjwsjymtmumyg"
            >
              PRIME <IoIosHelpCircle style={{ marginTop: '-3px' }} />
            </h5>
            <h6 className="text-center text-light">
              Rs {Price?.Active}/- per year
            </h6>
            <div className="col-12 text-center  text-light pt-0 pb-2">
              (including 18% GST)
            </div>

            <div className="py-1 pt-2">
              <Link to="/pro-active_register">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="px-3 btn   pricebuttoncolor"
                  style={{ fontSize: '14px' }}
                  // onClick={(e)=>alerttt()}
                >
                  Subscribe
                </button>
              </Link>
            </div>
          </div>
          <div className="pfeatures897 container-fluid">
            <div className="row">
              <div className="col-8 text-center">
                <h6 className="fw-bold text-light"></h6>
              </div>
              <div className="col-4"></div>
            </div>

            {dataTable.map((data) => {
              return (
                <div className="py-2">
                  <div className="row">
                    <div className="col-8 ">
                      <span
                        data-bs-toggle={data?.modalId ? 'modal' : ''}
                        data-bs-target={
                          data?.modalId ? `#${data?.modalId}` : ''
                        }
                        className={
                          data.head == 'AUTHENTICATION BADGE '
                            ? 'spaceNoWrap'
                            : ' '
                        }
                        style={{ color: '#c85366' }}
                      >
                        {data.head}
                        {data.element && data.element}
                      </span>
                    </div>
                    <div className="col-4 text-center text-light">
                      {data.headData ? data.headData.pro : ''}
                    </div>
                  </div>
                  {data.data.map((pop) => {
                    return (
                      <div className="row text-light">
                        <div className="col-8 ">
                          <span className=" ">{pop.label}</span>
                        </div>
                        <div
                          className={` ${
                            pop.noChange
                              ? 'col-4 text-center '
                              : 'col-4 text-center '
                          }`}
                        >
                          {pop.value.pro}

                          {pop?.modalss?.pro?.element ? (
                            <>&nbsp;{pop?.modalss?.pro?.element}</>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <br />
          </div>
        </div>
        <hr style={{ color: 'white' }} />

        <div className="bg-black">
          <div className="text-center text-light">
            {/* <h1 className="mb-3">USER FEATURES</h1> */}
            <h4 className=" fw-bold" style={{ color: '#c85366' }}>
              For Service Providers and Product Retailers
            </h4>
            <h5
              className="text-primary"
              data-bs-toggle="modal"
              data-bs-target="#dthdjtxasjdewfwfgugafgsjwsjymtmumyg"
            >
              ELITE <IoIosHelpCircle style={{ marginTop: '-3px' }} />
            </h5>
            <h6 className="text-center">Rs {Price?.Activev2}/- per year</h6>
            <div className="col-12 text-center pt-0 pb-2">
              (including 18% GST)
            </div>
            <div className="py-1 pt-2">
              <Link to="/active_register">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="px-3 btn   pricebuttoncolor"
                  style={{ fontSize: '14px' }}
                  // onClick={(e)=>alerttt()}
                >
                  Subscribe
                </button>
              </Link>
            </div>
          </div>

          <div className="pfeatures897 container-fluid">
            <div className="row">
              <div className="col-8 text-center">
                <h6 className="fw-bold text-light"></h6>
              </div>
              <div className="col-4"></div>
            </div>
            {dataTable.map((data) => {
              return (
                <div className="py-2">
                  <div className="row">
                    <div className="col-8 ">
                      <span
                        data-bs-toggle={data?.modalId ? 'modal' : ''}
                        data-bs-target={
                          data?.modalId ? `#${data?.modalId}` : ''
                        }
                        className={
                          data.head == 'AUTHENTICATION BADGE '
                            ? 'spaceNoWrap'
                            : ' '
                        }
                        style={{ color: '#c85366' }}
                      >
                        {data.head}
                        {data.element && data.element}
                      </span>
                    </div>
                    <div className="col-4 text-center text-light">
                      {data.headData ? data.headData.active : ''}
                    </div>
                  </div>
                  {data.data.map((pop) => {
                    return (
                      <div className="row text-light">
                        <div className="col-8 ">
                          <span className=" ">{pop.label}</span>
                        </div>
                        <div
                          className={` ${
                            pop.noChange
                              ? 'col-4 text-center '
                              : 'col-4 text-center '
                          }`}
                        >
                          {pop.value.active}

                          {pop?.modalss?.active?.element ? (
                            <>&nbsp;{pop?.modalss?.active?.element}</>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <br />
            {/* <p className="text-light">** in a Subscription year</p> */}
          </div>
        </div>
        <hr style={{ color: 'white' }} />
        <div className="bg-black">
          <div className="text-center">
            <h4 className=" fw-bold" style={{ color: '#c85366' }}>
              For Clients, Customers
            </h4>
            <h5
              className="text-primary"
              data-bs-toggle="modal"
              data-bs-target="#shygciasiucgwrfviklskjgcklbkjas"
            >
              Membership features{' '}
              <IoIosHelpCircle style={{ marginTop: '-3px' }} />
            </h5>
            <h6 className="text-center text-light">
              Rs {Price?.Passive}/- per year
            </h6>
            <div className="col-12 text-center pt-0 pb-2 text-light">
              (including 18% GST)
            </div>
            <div className="py-1 pt-2">
              <Link to="/passive_register">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="px-3 btn   pricebuttoncolor"
                  style={{ fontSize: '14px' }}
                  // onClick={(e)=>alerttt()}
                >
                  Subscribe
                </button>
              </Link>
            </div>
          </div>
          <div className="pfeatures897 container-fluid">
            <div className="row">
              <div className="col-8 text-center">
                <h6 className="fw-bold text-light"></h6>
              </div>
              <div className="col-4"></div>
            </div>

            {dataTable.map((data) => {
              return (
                <div className="py-2">
                  <div className="row">
                    <div className="col-8 ">
                      <span
                        data-bs-toggle={data?.modalId ? 'modal' : ''}
                        data-bs-target={
                          data?.modalId ? `#${data?.modalId}` : ''
                        }
                        className={
                          data.head == 'AUTHENTICATION BADGE '
                            ? 'spaceNoWrap'
                            : ' '
                        }
                        style={{ color: '#c85366' }}
                      >
                        {data.head}
                        {data.element && data.element}
                      </span>
                    </div>
                    <div className="col-4 text-center text-light">
                      {data.headData ? data.headData.passive : ''}
                    </div>
                  </div>
                  {data.data.map((pop) => {
                    return (
                      <div className="row text-light">
                        <div className="col-8 ">
                          <span className=" ">{pop.label}</span>
                        </div>
                        <div
                          className={` ${
                            pop.noChange
                              ? 'col-4 text-center '
                              : 'col-4 text-center '
                          }`}
                        >
                          {pop.value.passive}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <br />

            <p className="text-light">N/A = Not Applicable</p>
            <br />

            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>

      <div
        id="pc"
        className="d-none d-md-block w-100 nonMemberUserFeatureMObileLandscape container-fluid p-0 pt-5 jrgfrjflobjgt"
      >
        <div className="bg-black">
          <div className="container-fluid pt-5">
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6 text-light text-center">
                <div className="row">
                  <div className="col-9">
                    <h5
                      className=" fw-bold m-1 text-center pricelasttextW"
                      style={{ color: '#c85366', fontSize: '12px' }}
                    >
                      For Service Providers and Product Retailers
                    </h5>
                  </div>
                  <div className="col-3">
                    <h5
                      className=" fw-bold m-1 text-center pricelasttextW"
                      style={{ color: '#c85366', fontSize: '12px ' }}
                    >
                      For Clients, Customers
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-6 text-light d-flex justify-content-center align-items-center">
                USER FEATURES
              </div>
              <div className="col-6">
                <div className="row text-light p-0">
                  <div className="col-3 text-center">
                    <p
                      className="text-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#dthdjtxasjdewfwfgugafeoifhoegsjwsjymtmumyg"
                    >
                      BASIC&nbsp;
                      <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                    </p>
                  </div>
                  <div className="col-3 text-center">
                    <p
                      className="text-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#dthdjtxfgefwweugfoefsjwsjymtmumyg"
                    >
                      PRIME&nbsp;
                      <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                    </p>
                  </div>
                  <div className="col-3 text-center">
                    <p
                      className="text-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#dthdjtxasjdewfwfgugafgsjwsjymtmumyg"
                    >
                      ELITE&nbsp;
                      <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                    </p>
                  </div>
                  <div className="col-3 text-center">
                    <p
                      className="text-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#shygciasiucgwrfviklskjgcklbkjas"
                    >
                      Membership Features{' '}
                      <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                    </p>
                  </div>
                  <div className=" col-3 text-center text-light">
                    Rs {Price?.Activev3}/- per yaer
                    <br /> (including 18% GST)
                  </div>
                  <div className=" col-3 text-center text-light">
                    Rs {Price?.Active}/- per year
                    <br /> (including 18% GST)
                  </div>
                  <div className=" col-3 text-center text-light">
                    Rs {Price?.Activev2}/- per year
                    <br /> (including 18% GST)
                  </div>{' '}
                  <div className=" col-3 text-center text-light">
                    Rs {Price?.Passive}/- per year
                    <br /> (including 18% GST)
                  </div>
                  {/* <div className="col-12 text-center pt-2 pb-2">
                    * Only a One-time Registration fee
                    <br />
                    2nd year onwards : Rs 500/- per year
                  </div> */}
                  <div className=" col-3 text-center text-light">
                    <Link to="/static_listing_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activBtn activeSubscribeBtn px-1 btn btn-lg pricebuttoncolor"
                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                  <div className=" col-3 text-center text-light">
                    <Link to="/pro-active_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activeSubscribeBtn activBtn px-1 btn btn-lg pricebuttoncolor"
                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                  <div className=" col-3 text-center text-light">
                    <Link to="/active_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activeSubscribeBtn activBtn px-1 btn btn-lg pricebuttoncolor"
                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                  <div className=" col-3 text-center text-light">
                    <Link to="/passive_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activeSubscribeBtn px-3 btn activPassiveBtn btn-lg pricebuttoncolor"
                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 text-center ">
                <span className="fw-bold text-light"></span>
              </div>
            </div>
          </div>

          <div className="pfeatures897 container-fluid">
            {dataTable.map((data) => {
              return (
                <div className="py-2">
                  <div className="row">
                    <div className="col-6 ">
                      <span
                        data-bs-toggle={data?.modalId ? 'modal' : ''}
                        data-bs-target={
                          data?.modalId ? `#${data?.modalId}` : ''
                        }
                        className={
                          data.head == 'AUTHENTICATION BADGE '
                            ? 'spaceNoWrap'
                            : ' '
                        }
                        style={{ color: '#c85366' }}
                      >
                        {data.head}
                        {data.element && data.element}
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="row text-light text-center">
                        <div className="col-3">
                          {data.headData ? data.headData.static : ''}
                        </div>
                        <div className="col-3">
                          {data.headData ? data.headData.pro : ''}
                        </div>
                        <div className="col-3">
                          {data.headData ? data.headData.active : ''}
                        </div>

                        <div className="col-3">
                          {data.headData ? data.headData.passive : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  {data.data.map((pop) => {
                    return (
                      <div className="row text-light">
                        <div className="col-6 ">
                          <span className=" ">{pop.label}</span>
                        </div>
                        <div className="col-6">
                          <div className="row text-light text-center">
                            <div
                              className={` ${
                                pop.noChange
                                  ? 'col-3 text-center '
                                  : 'col-3 text-center '
                              }`}
                            >
                              {pop.value.static}
                            </div>
                            <div
                              className={` ${
                                pop.noChange
                                  ? 'col-3 text-center '
                                  : 'col-3 text-center '
                              }`}
                            >
                              {pop.value.pro}
                              {pop?.modalss?.pro?.element ? (
                                <>&nbsp;{pop?.modalss?.pro?.element}</>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className={` ${
                                pop.noChange
                                  ? 'col-3 text-center '
                                  : 'col-3 text-center '
                              }`}
                            >
                              {pop.value.active}
                              {pop?.modalss?.active?.element ? (
                                <>&nbsp;{pop?.modalss?.active?.element}</>
                              ) : (
                                ''
                              )}
                            </div>

                            <div
                              className={` ${
                                pop.noChange
                                  ? 'col-3 text-center '
                                  : 'col-3 text-center '
                              }`}
                            >
                              {pop.value.passive}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <br />

            <p className="text-light">N/A = Not Applicable</p>

            <br />

            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

const AMember = ({ dataTable }) => {
  const notify = (message, stat) => {
    console.log(message);

    if (stat === 'success') {
      console.log('toasfted');
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat === 'danger') {
      toast.error(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const toProActivate = async () => {
    try {
      let go = await axios.post('/api/Payment/toProActivate');
      if (go) {
        console.log(go);
      }
    } catch (error) {
      // console.log(error.response.data)

      notify('Something went wrong !', 'danger');
    }
  };
  const toBasic = async () => {
    try {
      let go = await axios.post('/api/Payment/toBasic');
      if (go) {
        console.log(go);
      }
    } catch (error) {
      // console.log(error.response.data)

      notify('Something went wrong !', 'danger');
    }
  };

  return (
    <div
      id="pc"
      className="pricingAllUni container-fluid bg-black p-0 pt-5 jrgfrjflobjgt"
    >
      <div className="">
        <div className="container-fluid pt-5">
          <div className="row">
            <div className="col-12">
              {/* <h4 className=" fw-bold m-1 py-2 text-center pricelasttextW" style={{ color: "#c85366" }}>
              For Service Provider and Product Retailer
            </h4> */}
            </div>

            <div className="col-6"></div>
            <div className="col-6  text-center"></div>
            <div className="col-6 text-center text-light">PAID MEMBERSHIP FEATURES</div>
            <div className="col-6">
              <div className="row  p-0">
                <div className="col-12"></div>
                <div
                  className={
                    localStorage.getItem('Type') == 'Productv3' ||
                    localStorage.getItem('Type') == 'Consultantv3'
                      ? 'col-4 px-1 text-center currentborder1'
                      : 'col-4 px-1 text-center'
                  }
                >
                  <p
                    className="text-primary m-0"
                    data-bs-toggle="modal"
                    data-bs-target="#dthdjtxasjdewfwfgugafeoifhoegsjwsjymtmumyg"
                  >
                    BASIC&nbsp;
                    <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                  </p>
                </div>

                <div
                  className={
                    localStorage.getItem('Type') == 'Product' ||
                    localStorage.getItem('Type') == 'Consultant'
                      ? 'col-4 px-1 text-center currentborder1'
                      : 'col-4 px-1 text-center'
                  }
                >
                  <p
                    className="text-primary m-0"
                    data-bs-toggle="modal"
                    data-bs-target="#dthdjtxfgefwweugfoefsjwsjymtmumyg"
                  >
                    PRIME&nbsp;
                    <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                  </p>
                </div>
                <div
                  className={
                    localStorage.getItem('Type') == 'Productv2' ||
                    localStorage.getItem('Type') == 'Consultantv2'
                      ? 'col-4 px-1 text-center currentborder1'
                      : 'col-4 px-1 text-center'
                  }
                >
                  <p
                    className="text-primary m-0"
                    data-bs-toggle="modal"
                    data-bs-target="#dthdjtxasjdewfwfgugafgsjwsjymtmumyg"
                  >
                    ELITE&nbsp;
                    <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                  </p>
                </div>
                {/* <div className="col-4 px-1 pb-1">
                  <p className="text-center text-light m-0">
                    Rs 1000/- per year
                  </p>
                </div>
                <div className="col-4 px-1 pb-1">
                  <p className="text-center text-light m-0">
                    Rs 3000/- per year
                  </p>
                </div>
                <div className="col-4 px-1 pb-1">
                  <p className="text-center text-light m-0">
                    Rs 6000/- per year
                  </p>
                </div> */}
                {/* <div className="col-12 text-light text-center pt-0 pb-2">
                  ( per year fees including gst )
                </div> */}
                <div
                  className={
                    localStorage.getItem('Type') == 'Productv3' ||
                    localStorage.getItem('Type') == 'Consultantv3'
                      ? 'col-4 p-0 px-1 currentborder2'
                      : 'col-4 p-0 px-1'
                  }
                >
                  {localStorage.getItem('Type') == 'Productv3' ||
                  localStorage.getItem('Type') == 'Consultantv3' ? (
                    <p className="text-center cstatusuf py-2 m-0 text-light">
                      Current Subscription
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className={
                    localStorage.getItem('Type') == 'Product' ||
                    localStorage.getItem('Type') == 'Consultant'
                      ? 'col-4 p-0 px-1 currentborder2'
                      : 'col-4 p-0 px-1'
                  }
                >
                  {localStorage.getItem('Type') == 'Product' ||
                  localStorage.getItem('Type') == 'Consultant' ? (
                    <p className="text-center cstatusuf py-2 m-0 text-light">
                      Current Subscription
                    </p>
                  ) : (
                    ''
                  )}
                  {localStorage.getItem('Type') == 'Productv3' ||
                  localStorage.getItem('Type') == 'Consultantv3' ? (
                    // localStorage.getItem("Type") == "Productv2" ||
                    // localStorage.getItem("Type") == "Consultantv2"
                    <div
                      style={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                        }
                      }
                    >
                      <Link to={'/upgrade_to_proactive'}>
                        <div>
                          <button
                            className="w-100 activBtn px-1 btn btn-lg pricebuttoncolor mt-2"
                            onClick={toProActivate}
                          >
                            Upgrade
                          </button>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className={
                    localStorage.getItem('Type') == 'Productv2' ||
                    localStorage.getItem('Type') == 'Consultantv2'
                      ? 'col-4 p-0 px-1 currentborder2'
                      : 'col-4 p-0 px-1'
                  }
                >
                  {localStorage.getItem('Type') == 'Productv2' ||
                  localStorage.getItem('Type') == 'Consultantv2' ? (
                    <p className="text-center cstatusuf py-2 m-0 text-light">
                      Current Subscription
                    </p>
                  ) : (
                    ''
                  )}
                  {localStorage.getItem('Type') == 'Productv3' ||
                  localStorage.getItem('Type') == 'Consultantv3' ||
                  localStorage.getItem('Type') == 'Product' ||
                  localStorage.getItem('Type') == 'Consultant' ? (
                    <Link to={`/upgrade_to_basic`}>
                      <div
                        style={
                          {
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                          }
                        }
                      >
                        <button
                          className="w-100 activBtn px-1 btn btn-lg pricebuttoncolor mt-2"
                          onClick={toBasic}
                        >
                          Upgrade
                        </button>
                      </div>
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 text-center ">
              <span className="fw-bold "></span>
            </div>
          </div>
        </div>
        <div className="pfeatures897 container-fluid">
          {dataTable.map((data) => {
            return (
              <div className="py-1 py-md-2">
                <div className="row">
                  <div className="col-6 ">
                    <span
                      data-bs-toggle={data?.modalId ? 'modal' : ''}
                      data-bs-target={data?.modalId ? `#${data?.modalId}` : ''}
                      className={
                        data.head == 'AUTHENTICATION BADGE '
                          ? 'spaceNoWrap'
                          : ' '
                      }
                      style={{ color: '#c85366' }}
                    >
                      {data.head}
                      {data.element && data.element}
                    </span>
                  </div>
                  <div className="col-6">
                    <div className="row text-light text-center">
                      <div className="col-4">
                        {data.headData ? data.headData.static : ''}
                      </div>
                      <div className="col-4">
                        {data.headData ? data.headData.pro : ''}
                      </div>
                      <div className="col-4">
                        {data.headData ? data.headData.active : ''}
                      </div>
                    </div>
                  </div>
                </div>
                {data.data.map((pop) => {
                  return (
                    <div className="row ">
                      <div className="col-6 ">
                        <span className="text-light ">{pop.label}</span>
                      </div>
                      <div className="col-6">
                        <div className="row text-light  text-center">
                          <div
                            className={` ${
                              pop.noChange
                                ? 'col-4 text-center '
                                : 'col-4 text-center '
                            }`}
                          >
                            {pop.value.static}
                          </div>

                          <div
                            className={` ${
                              pop.noChange
                                ? 'col-4 text-center '
                                : 'col-4 text-center '
                            }`}
                          >
                            {pop.value.pro}
                            {pop?.modalss?.pro?.element ? (
                              <>&nbsp;{pop?.modalss?.pro?.element}</>
                            ) : (
                              ''
                            )}
                          </div>
                          <div
                            className={` ${
                              pop.noChange
                                ? 'col-4 text-center '
                                : 'col-4 text-center '
                            }`}
                          >
                            {pop.value.active}
                            {pop?.modalss?.active?.element ? (
                              <>&nbsp;{pop?.modalss?.active?.element}</>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div className="row">
            <div className="col-6"></div>
            <div className="col-6"></div>
          </div>
          <br />

          {localStorage.getItem('Type') == 'Product' ||
          localStorage.getItem('Type') == 'Consultant' ? (
            <></>
          ) : (
            <p className="pricelasttextW text-center text-light"></p>
          )}
          <br />

          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

const PMember = ({ dataTable }) => {
  return (
    <>
      <div>
        <div
          id="hoo1"
          className="pricingAllUniPassive bg-black container-fluid  jrgfrjflobjgt "
          style={{ paddingTop: '80px' }}
        >
          <div className="text-black">
            <div className="pfeatures897 p-0 container-fluid">
              <div className="row">
                <div className="col-8">
                  <div className="text-center">
                    {/* <h4 className=" fw-bold" style={{ color: "#c85366" }}>
                      For Clients, Customers, Students{" "}
                    </h4> */}
                  </div>
                </div>
                <div className="col-4"></div>
                <div className="col-8 text-center">
                  <div className="text-center">
                    <h4 className=" text-center m-0 text-light ">
                      USER FEATURES
                    </h4>
                  </div>
                </div>
                <div className="col-4 p-0">
                  <p
                    data-bs-toggle="modal"
                    data-bs-target="#shygciasiucgwrfviklskjgcklbkjas"
                    className="text-center text-primary m-0 pb-1"
                  >
                    Membership Features&nbsp;
                    <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                  </p>
                  {/* <p className="card-title text-light text-center pricing-card-title p-0">
                    ₹ 1000/- per year
                  </p>
                  <div className=" text-light text-center pt-0 pb-2">
                    ( per year fees including gst )
                  </div> */}
                </div>
              </div>
              <div className="row d-none">
                <div className="col-8"></div>
                <div className="col-4 ">
                  <Link to="/passive_register">
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="w-100 activPassiveBtn px-3 btn btn-lg pricebuttoncolor"
                      // onClick={(e)=>alerttt()}
                    >
                      Subscribe
                    </button>
                  </Link>
                </div>
              </div>

              {dataTable.map((data) => {
                if (data?.headData?.passive != 'N/A') {
                  let sp = true;
                  data.data.forEach((er) => {
                    if (er.value.passive == 'N/A') {
                      sp = false;
                    }
                  });
                  if (sp)
                    return (
                      <div className="py-1 py-md-2">
                        <div className="row">
                          <div className="col-8 ">
                            <span
                              data-bs-toggle={data?.modalId ? 'modal' : ''}
                              data-bs-target={
                                data?.modalId ? `#${data?.modalId}` : ''
                              }
                              className={
                                data.head == 'AUTHENTICATION BADGE '
                                  ? 'spaceNoWrap'
                                  : ' '
                              }
                              style={{ color: '#c85366' }}
                            >
                              {data.head}
                              {data.element && data.element}
                            </span>
                          </div>
                          <div className="col-4 text-light text-center ">
                            {data.headData ? data.headData.passive : ''}
                          </div>
                        </div>
                        {data.data.map((pop) => {
                          return (
                            <div className="row  text-light">
                              <div className="col-8 ">
                                <span className=" ">{pop.label}</span>
                              </div>
                              <div
                                className={` ${
                                  pop.noChange
                                    ? 'col-4 text-center '
                                    : 'col-4 text-center '
                                }`}
                              >
                                {pop.value.passive}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                }
              })}

              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
