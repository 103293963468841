import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BsInfoCircle } from "react-icons/bs";
import { Mask } from "../../Utility/mask";

export function SubCat(props) {
  const liquermodal = () => {
    document.getElementById("liqquermodalprochuresabaribtn").click();
  };

  return (
    <div
      id={`Cat-` + props.data.brand.slice(0, 1) + props.index}
      className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4   mb-3"
    >
      <Link
        to={`/lobby/services_result_by_brand?Sub_id=${Mask.encode(
          props.data.id
        )}&&sub_category=${props.data.sub}`}
        className="twedfqtfudc"
      >
        {/* {props.data.brand > 0 ? : "/lobby/services_result?Sub_id=" + props.data.id} */}

        <div className="card carderghibgibgp p-3 pb-2 pt-2">
          <div>
            <div className="d-flex align-items-center">
              <div className="ml-3 w-100">
                <h4 className="mb-2 mt-0">{props.data.brand}</h4>{" "}
                <div class="d-flex justify-content-end">
                  {props.data.origin == "Indian" && (
                    <img
                      src="/india-flag-large.jpg"
                      width={"5%"}
                      height={"10%"}
                    ></img>
                  )}
                  {props.data.origin == "Global" && (
                    <img
                      src="/global-flag.png"
                      width={"5%"}
                      height={"10%"}
                    ></img>
                  )}
                </div>
                <ul></ul>
              </div>
            </div>
          </div>
        </div>
      </Link>
      {props.data.id === "6308629f75901fee6d0d49c7" ? (
        <div style={{ position: "relative", right: "30px", top: "-65px" }}>
          <BsInfoCircle
            style={{ color: "red" }}
            className="float-end"
            onClick={liquermodal}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
