import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const PersonalInformationVendor = ({ getval }) => {
  const { firstname, lastname, Partner } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <span>
              <b>
                {" "}
                Personal Information
                <br />
              </b>
              (of any one Owner)
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {firstname && (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Name of the Proprietor / Business Owner</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{ color: "var(--blue)" }}>
                    {firstname ? firstname.toUpperCase() : ""}{" "}
                    {lastname ? lastname.toUpperCase() : ""}
                  </label>
                </div>
              </div>
            )}
            {Partner ? (
              Partner[0]?.name?.length ||
              Partner[0]?.designation?.length > 0 ? (
                <div className="row py-2">
                  <div className="col-12 mb-3">
                    <b>Partner / Business Associate</b>
                  </div>
                  {Partner
                    ? Partner.map((element, index) => {
                        return (
                          <div className="row mb-3">
                            {element?.name ? (
                              <>
                                <div className="col-md-6">
                                  <span>
                                    Name of Partner / Business Associate
                                  </span>
                                </div>
                                <div className="col-md-6 ">
                                  <label style={{ color: "var(--blue)" }}>
                                    {element.name}
                                  </label>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {element?.name ? (
                              <>
                                <div className="col-md-6">
                                  <span>Designation</span>
                                </div>
                                <div className="col-md-6 ">
                                  <label style={{ color: "var(--blue)" }}>
                                    {element.designation}
                                  </label>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      })
                    : ""}
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
