import React, { useState, useEffect, useReducer, createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./CreateProduct.css";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { CreateProductA } from "./forms/CreateProductA";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CreateProductC } from "./forms/CreateProductC";
import { useRef } from "react";

export const CatsContext = createContext();
export const ConvertContext = createContext();
export const EditContext = createContext();

export function FormProduct(props) {
  var sp_cat = props.catid; // for setting predefind category
  var sp_subcat = props.subid; // for setting predefind category

  let [searchParams, setSearchParams] = useSearchParams();

  const reducer = (state, action) => {
    switch (action.type) {
      case "1":
        return <CreateProductA postTo={props.postTo} type={1} formType={1} />;
      case "2":
        return <CreateProductA postTo={props.postTo} type={1} formType={2} />;
      case "3":
        return <CreateProductC postTo={props.postTo} type={1} />;
      default:
        <></>;
      // throw new Error();
    }
  };
  let navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, <></>);

  const [value, setValue] = useState({
    category: "",
    subcategory: "",
    formType: "",
  });
  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [brand, setBrand] = useState([]);
  const [subcat, setsubcat] = useState();

  const sabari = (e) => {
    var anadhan;
    setValue({ ...value, category: e });
    dispatch({ type: "." });
    cat.forEach((element) => {
      if (element._id === e) {
        setsubcat(element.sub);
        anadhan = element.sub.map((elements) => {
          return (
            <option formtype={elements.formType} value={elements._id}>
              {elements.sub}
            </option>
          );
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };

  useEffect(() => {
    axios
      .get("/api/api/getprocategory")
      .then((res) => {
        if (res.status === 200) {
          setCat(res.data);
          console.log(res);
        }
      })
      .catch((err) => {
        if (err) {
          navigate("/");
        }
        console.log(err.response.status);
      });
  }, []);
  const [data, setdata] = useState();

  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      setCategory(
        cat.map((element) => {
          if (value.category) {
            if (element._id == value.category) {
              console.log(value.category);
              console.log(element);

              setSubcategory(element.sub.map((elements) => {
                if (value.subcategory == elements._id) {
                  console.log(elements.formType);
                  setValue((prev) => ({ ...prev, formType: elements.formType }))
                  dispatch({ type: elements.formType.toString() });
                }
                return <option
                  selected
                  formtype={elements.formType}
                  value={elements._id}
                >
                  {elements.sub}
                </option>
              }))
            }
          }
          if (sp_cat) {
            if (element._id === sp_cat) {
              return (
                <option value={element._id} selected>
                  {element.category}
                </option>
              );
            }
          } else {
            return <option value={element._id}>{element.category}</option>;
          }
        })
      );
      if (sp_cat) {
        setValue({ ...value, category: sp_cat });
        setSubcategory(
          cat.map((element) => {
            if (sp_cat) {
              if (element._id === sp_cat) {
                setsubcat(element.sub);
                return element.sub.map((elements) => {

                  if (sp_subcat) {
                    if (elements._id === sp_subcat) {
                      console.log(elements.formType);
                      setValue((prev) => ({ ...prev, formType: elements.formType }))
                      dispatch({ type: elements.formType.toString() });
                      return (
                        <option
                          selected
                          formtype={elements.formType}
                          value={elements._id}
                        >
                          {elements.sub}
                        </option>
                      );
                    }
                  }
                  else {
                    return (
                      <option formtype={elements.formType} value={elements._id}>
                        {elements.sub}
                      </option>
                    );
                  }
                });
              }
            }
            // else {
            //   return (
            //     <option formtype={elements.formType} value={elements._id}>
            //       {elements.sub}
            //     </option>
            //   );
            // }
          })
        );
      }
    } else {
    }
  }, [cat]);

  const formLoader = () => {
    let e = document.getElementById("subcatanandsfdfdsfda83389");
    let ft = e.options[e.selectedIndex].getAttribute("formtype");
    dispatch({ type: ft });
    setValue({ ...value, subcategory: e.value, formType: ft });
    cat.find((elem) => {
      if (elem._id === value.category) {
        elem.sub.find((element) => {
          if (element._id == e.value) {
            setBrand(element.brand);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (props.edit) {
      axios
        .post("/api/ActiveUser/ConProfile", {})
        .then((res) => {
          cat.find((elem) => {
            if (elem._id === res.data[0].category) {
              elem.sub.find((element) => {
                if (element._id == res.data[0].subcategory) {
                  setBrand(element.brand);
                }
              });
            }
          });
          setValue({
            ...value,
            category: res.data[0].category,
            subcategory: res.data[0].subcategory,
            Brand: res.data[0].Brand,
          });
          let e = document.getElementById("subcatanandsfdfdsfda83389");
          let anadhan;
          let ft;
          cat.forEach((element) => {
            if (element._id === res.data[0].category) {
              anadhan = element.sub.map((elements) => {
                if (elements._id === res.data[0].subcategory) {
                  ft = elements.formType;
                } else {
                  // console.log(elements._id,res.data[0].subcategory)
                }
                return (
                  <option formtype={elements.formType} value={elements._id}>
                    {elements.sub}
                  </option>
                );
              });
            }
          });
          setSubcategory(anadhan);
          if (ft) {
            ft = ft.toString();
            dispatch({ type: ft });
          }

          setdata(res.data);
          cat.find((elem) => {
            if (elem._id === value.category) {
              elem.sub.find((element) => {
                if (element._id == e.value) {
                  setBrand(element.brand);
                }
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [category]);


  let categoryRef = useRef()
  let subcategoryRef = useRef()

  useEffect(() => {
    if (searchParams.get("superlink")) {
      let mType = searchParams.get("mType")
      let uType = searchParams.get("uType")
      var category = searchParams.get("category")
      let subcategory = searchParams.get("subcategory")
      let profileName = searchParams.get("profileName")
      let country = searchParams.get("country")
      let state = searchParams.get("state")
      let city = searchParams.get("city")
      console.log(category, subcategory);
      console.log(subcategory);
      console.log(searchParams.get("category"));
      if (category.length) {
        categoryRef.current.disabled = true
        if (subcategory.length) {
          subcategoryRef.current.disabled = true
        }
        setValue({ ...value, category: category, subcategory: subcategory });
      }

    }


    return () => {

    }
  }, [])

  return (
    <div className="container rounded bg-white pt-5 pb-0">
      <center>
        <h5 className="pt-4">Profile Creation Form</h5>
      </center>
      <center>
        <p>' * ' are all mandatory fields</p>
      </center>
      <div className="row">
        <h6>Select your appropriate Sub Category</h6>
        <div className="col-md-12">
          <label className="labels">Category *</label>
          <select
            ref={categoryRef}
            className="form-select"
            value={value.category}
            onChange={(e) => sabari(e.target.value)}
          >
            {props.catid ? <></> : <option selected disabled></option>}
            {category ? category : ""}
          </select>
        </div>
        <div className="col-md-12">
          <label className="labels">Sub Category *</label>
          <select
            ref={subcategoryRef}
            id="subcatanandsfdfdsfda83389"
            className="form-select"
            value={value.subcategory}
            onChange={(e) => formLoader(e)}
          >
            <option></option>
            {subcategory ? subcategory : ""}
          </select>
        </div>
      </div>

      {brand.length ? (
        <>
          <div className="col-md-12">
            <label className="labels">Brand *</label>
            <select
              value={value.Brand}
              className="form-select"
              onChange={(e) => setValue({ ...value, Brand: e.target.value })}
            >
              <option></option>
              {brand.map((elem) => {
                return <option value={elem._id}>{elem.brand}</option>;
              })}
            </select>
          </div>
        </>
      ) : (
        <></>
      )}

      <EditContext.Provider value={data}>
        <CatsContext.Provider value={{ value, brand: brand, subcat }}>
          <ConvertContext.Provider value={props.sab}>
            {state}
          </ConvertContext.Provider>
        </CatsContext.Provider>
      </EditContext.Provider>
    </div>
  );
}
