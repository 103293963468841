import React from "react";
// import {NewNavigationBar} from "../../Pages/Free/NewNavigationBar/NewNavigationBar"

export function CancelationPolicies() {
  return (
    <div>
      {/* <NewNavigationBar /> */}
      <div className="container notranslate" style={{ paddingTop: "100px" }}>
        <div className="pos">
          <span>
            <h4>
              <center>
                <u>
                  <b>CANCELLATION / REFUND POLICY</b>
                </u>
              </center>
            </h4>
          </span>
        </div>
        <br />
        <div className="pos">
          <span>
            <p>
              We strictly DO NOT have a Refund Policy on Cancellation or
              Deletion of Subscription.
            </p>
            <p>
              For any grievances related to the subscription, please go to ‘ASK
              US’ (on the navigation bar) and send us a message by selecting the
              Subject of the message as GRIEVANCES.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
}
