import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./ViewSelectedCon.css";
import { ToastContainer, toast } from "react-toastify";

import { BiArrowBack } from "react-icons/bi";
import { HiSpeakerphone } from "react-icons/hi";
import { Link } from "react-router-dom";
import axios from "axios";
import { Vendorcom } from "../ProductList/Vendorcom";
import { ProfileCard } from "../ProfileCard/ProfileCard";
export function ViewSelectedPro(props) {
  useEffect(() => {
    props.Auth();
  }, []);

  const [data, setdata] = useState(false);
  const [cat, setCat] = useState(false);
  const [Follower, setFollower] = useState();
  const [Foll, setFoll] = useState();
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    axios
      .post("/api/User/viewselectedpro", {})
      .then((res) => {
        if (res.status == 201) {
          setload(false);
          setoutput(
            <h3>
              <center>No SHORT-LISTED Products yet !</center>
            </h3>
          );
        } else {
          setResults(res.data.map((p) => p._id));
          setCat(res.data);
          const postSummaries = res.data.map((post) => ({
            id: post._id,
            key: post.id,
            data: post,
          }));
          setdata(postSummaries);
          setload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //  console.log(id)
    // const postSummaries = id.map((post) => ({ id: post._id, key: post.id, sub: post.sub,}));
    // setdata(postSummaries)
    // setload(false)
  }, []);

  useEffect(() => {
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {" "}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data) {
        setoutput(
          data.map((number) => (
            <ProfileCard key={number.id} ids={makeid()} data={number.data} />
            // <Vendorcom
            //   key={number.id}
            //   ids={makeid()}
            //   data={number.data}
            // ></Vendorcom>
          ))
        );
      } else {
        seterror("");
      }
    }
  }, [error, load]);

  const [ShoutMass, setShoutMass] = useState();
  const [Results, setResults] = useState();

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const shoutOut = async () => {
    var dataSend = {
      massage: ShoutMass,
      cat_id: localStorage.getItem("Cat"),
      Results,
    };
    console.log(dataSend);
    // return
    var send = await axios.post("/api/User/shoutOut", { dataSend });
    if (send.status == 200) {
      // setViewTopicMember(data.data)
      // console.log(data.data)
      notify("SHOUT-OUT sent successfully", true);
    } else {
      notify("SHOUT-OUT sent failed", false);
    }
  };
  return (
    <>
      <div className=" content">
        <div className="container-fluid pt-5 ">
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <div className="row">
                <div className="col-12">
                  <button
                    className="btn btnsabari px-3 py-1 me-2 mt-5  btn-rounded"
                    data-bs-toggle="modal"
                    data-bs-target="#shoutoutmodal1"
                  >
                    {" "}
                    <HiSpeakerphone />
                    &nbsp;Shout-out
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="col d-flex justify-content-end"><button  className="btn btn-rounded btn3 mb-2 me-3" data-bs-toggle="modal" data-bs-target="#shoutoutmodal1"> <HiSpeakerphone /> Shout-out</button><button  className="btn btn-rounded btn3 mb-2 me-2" >View Selected</button></div> */}
            {/* <!-- end col --> */}
          </div>
          {/* <!-- end row --> */}
          <div className=" row pt-5">
            {output.length === 0 ? (
              <h5>
                <center>No SHORT-LISTED Products yet&nbsp;!</center>
              </h5>
            ) : (
              output
            )}

            {/* <div className="col-12 col-md-6 col-lg-6 col-xl-4">
            <div className="text-center card-box">
              <div className="form-check d-flex justify-content-end">
                <input
                  className="form-check-input float-end checkboxpro me-3 mt-3"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
              </div>
              <div className="member-card pt-2 pb-2">
                <div className="thumb-lg member-thumb mx-auto">
                  <img
                    src="https://bootdey.com/img/Content/avatar/avatar2.png"
                    className="rounded-circle img-thumbnail"
                    alt="profile-image"
                  />
                </div>
                <div className="">
                  <h4>Sabari V R</h4>
                  <p className="text-muted">
                    <b>Sub Category | Main Category</b>
                    <span> </span>
                    <span>
                      <b>Location : Kollam</b>
                    </span>
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn3 px-0 py-1  mt-3 btn-rounded waves-effect w-md waves-light"
                    >
                      View Profile
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn3 px-0 py-1  mt-3 btn-rounded waves-effect w-md waves-light"
                    >
                      Follow
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn3 px-0 py-1  mt-3 btn-rounded waves-effect w-md waves-light"
                    >
                      Communicate
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="row font ">
                    <div className="col-4 ">
                      <div className="mt-3">
                        <h4 className="fon">130</h4>
                        <p className="mb-0 text-muted">Posts</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mt-3">
                        <h4 className="fon">6952</h4>
                        <p className="mb-0 text-muted">Followers</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mt-3">
                        <h4 className="fon">11/11/2021</h4>
                        <p className="mb-0 text-muted">Joined on</p>
                      </div>
                    </div>
                  </div>
                  <div className="row font ">
                    <div className="col-4 ">
                      <div className="mt-3">
                        <h4 className="fon">100</h4>
                        <p className="mb-0 text-muted">Good</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mt-3">
                        <h4 className="fon">50</h4>
                        <p className="mb-0 text-muted fon">HR</p> 
                          
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mt-3">
                        <h4 className="fon">17</h4>
                        <p className="mb-0 text-muted">Referred</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

            {/* <!-- end col --> */}
          </div>

          {/* <!-- end row --> */}
        </div>

        {/* <!-- container --> */}

        <div
          className="modal fade"
          id="shoutoutmodal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Shout-Out
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <textarea
                  className="form-control"
                  placeholder="max 500 characters"
                  maxLength="500"
                  onChange={(e) => setShoutMass(e.target.value)}
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn pro-spaces-button"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={(e) => shoutOut()}
                  data-bs-dismiss="modal"
                  className="btn pro-spaces-button3"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}
