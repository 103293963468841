import React, { useEffect, useRef, useState } from "react";
import "./SearchToolbar.css";
import { BiReset } from "react-icons/bi";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { JobSeekersList } from "./jobSeekerList";
// import './JobSearch.css'
import { print_state, print_city, s_a } from "../../Utility/stateCity";
import { keyGen } from "../../Utility/helper";
import { AllCity, ExistingData, InitializeCountry } from "../../Utility/Country";
import { useSelector } from "react-redux";

export const JobSearch = (props) => {
  const [cat, setCat] = useState([]);
  const [data, setdata] = useState(false);
  const [load, setload] = useState();
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [SearchValidationError, setSearchValidationError] = useState(false);
  const [SearchValidated, setSearchValidated] = useState(false);
  const [Stateload, setStateload] = useState(true);
  const [error, seterror] = useState("");
  const [err, setError] = useState("");
  const [output, setoutput] = useState("");
  const [Search, setSearch] = useState({
    Category: "",
    Subcategory: "",
    Country:"",
    State: "",
    City: "",
    Experience: "",
    Workplace: "",
    Emptype: "",
    age: "",
    gender: "",
  });
  const [value, setValue] = useState();
  let navigate = useNavigate();

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city("City", index);
    setSearch({ ...Search, State: e.target.value });
  }

  const sabari = (e) => {
    console.log(e.target.value);
    var anadhan;
    setSearch({ ...Search, Category: e.target.value });
    // setValue({ ...value, category: e.target.value })
    cat.forEach((element) => {
      console.log(element._id);
      if (element._id === e.target.value) {
        anadhan = element.sub.map((elements) => {
          return <option value={elements._id}>{elements.sub}</option>;
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };

  useEffect(() => {
    axios
      .get("/api/api/getcategory")
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setCat(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          navigate("/");
        }
        console.log(err.response.status);
      });
  }, []);

  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
      console.log(cat);
    } else {
      console.log("nothing");
    }
  }, [cat]);
  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);

  async function SearchHandler() {
    // console.log(setSearchValidated)

    console.log("hii" + Search.Pin.length);
    if (Search.Pin.length > 0) {
      if (Search.Pin.length == 6) {
        console.log("no error");
        setSearchValidationError(false);
        return true;
      } else {
        console.log(" errro");
        setSearchValidationError("Please Enter a valid Pincode");
        return false;
      }
    } else {
      setSearchValidationError(false);
      return true;
    }
  }

  async function SearchHandlerX(e) {
    setSearchValidated(true);
    var status = SearchHandler();
    if (!status) {
      e.preventDefault();
    } else {
      console.log("here we go !");
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (SearchValidated) {
      SearchHandler();
    }
  }, [Search.Pin]);

  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);

  function validation() {
    if (Search.Category.length > 0) {
      setError("");
    } else {
      return setError("Select a category");
    }
    if (Search.Subcategory.length > 0) {
      setError("");
    } else {
      return setError("Select a Subcategory");
    }
    // if (Search.State.length > 0) {
    //     setError('')
    // }
    // else {
    //     return setError('Select a State')
    // }

    // if (Search.Pin.length == 6 || Search.Pin.length== 0) {
    //     setError('')
    // }
    // else {
    //     return setError('invalid pincode')
    // }

    return true;
  }

  const knvpins = () => {
    // console.log(Search)
   
    var status = false;
    // setSubmit(true)
    status = validation();

    if (status == true) {
      setload(true);
      setoutput(Loader);
      // document.getElementById('gtrgtr').classList.remove('d-none')
      axios
        .post("/api/User/findjob", { Search })
        .then((res) => {
          console.log(res.data);
          const postSummaries = res.data.map((post) => ({
            id: post._id,
            key: post.id,
            data: post,
          }));
          setdata(postSummaries);
          setload(false);
        })
        .catch((err) => {
          console.log(err);
          setload(false);
        });
    }
  };

  useEffect(() => {
    {
      if (load) {
      } else {
        if (data)
          if (data.length > 0) {
            setoutput(
              data.map((number) => (
                <JobSeekersList
                  key={number.id}
                  data={number.data}
                ></JobSeekersList>
              ))
            );
            console.log("haiii");
          } else {
            setoutput(<h3>No Result Found</h3>);
          }
      }
    }
  }, [load]);

  const [Loader, setLoader] = useState(
    <center>
      <div className="spinner-border text-dark" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </center>
  );

  let userData = useSelector((state) => state.user);

  let countryRef = useRef();
  let stateRef = useRef();
  let cityRef = useRef();
  let AllCityRef = useRef()

  useEffect(() => {
      ExistingData(countryRef, stateRef, cityRef, userData.value.Country, "");
      InitializeCountry(countryRef, stateRef, cityRef);
      return () => { };
  }, []);
  return (
    <div className="col-12 py-3 pt-4">
      <div className="container-fluid p-0  pt-0">
        <br />
        {localStorage.getItem("Type") != "Passive" ? (
          <hr className="hrclass" />
        ) : (
          ""
        )}

        <p className="mb-1 text-center" style={{ fontSize: 14 }}>
          SEARCH FOR JOBS AS PER YOUR REQUIREMENT
        </p>
        <form onSubmit={(e) => SearchHandlerX(e)} action="" method="">
          {/* <div className="p-0 py-1">

                        <div className="container-fluid Scont p-0 ">
                            <input type="text" value={Search.Value} onChange={(e) => setSearch({ ...Search, Value: e.target.value })} placeholder="Search" name="searchInput" className="searchInput px-4"></input>
                            <button type="submit" className="searchIco" >   <BsSearch size="30" color="white" /></button>
                        </div>
                    </div> */}

          <div id="AdvancedSearch" className="bg-light p-2 ">
            <div>
              <p data-bs-toggle="" className="">
                Advanced Search Filters
              </p>
            </div>

            <div className=" container-fluid p-0">
              <div className="row g-1">
                <div className="col-12 col-md-3 ">
                  <div className="form-floating">
                    <select
                      id="sub"
                      className="form-select"
                      value={Search.Category}
                      onChange={(e) => sabari(e)}
                    >
                      <option value="" disabled selected></option>
                      {category ? category : ""}
                    </select>
                    <label htmlFor="Sub">Category</label>
                  </div>
                </div>
                <div className="col-12 col-md-3 ">
                  <div className="form-floating">
                    <select
                      name="sub"
                      className="form-select"
                      id="sub"
                      value={Search.Subcategory}
                      onChange={(e) =>
                        setSearch({ ...Search, Subcategory: e.target.value })
                      }
                    >
                      <option value="" disabled selected></option>
                      {subcategory ? subcategory : ""}
                      {/* <option>Male</option>
                                            <option>Female</option> */}
                    </select>{" "}
                    <label htmlFor="Experience">Sub Category</label>
                  </div>
                </div>
                <div className="col-6 col-md-3 ">
                  <div className="form-floating">
                    <select
ref={countryRef}
                      name="City"
                      className="form-select"
                      value={Search.Country}
                      onChange={(e) =>
                        setSearch({ ...Search, Country: e.target.value,State:'' ,City:'' })
                      }
                    ></select>
                    <label htmlFor="State">Country</label>
                  </div>
                </div>
                <div className="col-6 col-md-3 ">
                  <div className="form-floating">
                    <select
                    ref={stateRef}
                      name="City"
                      value={Search.State}
                      className="form-select"
                      onChange={(e) =>
                        setSearch({ ...Search, State: e.target.value,City:'' })
                      }
                    ></select>
                    <label htmlFor="State">State</label>
                  </div>
                </div>
                <div className="col-6 col-md-3 ">
                  <div className="form-floating">
                    <select
                      name="City"
                      ref={cityRef}
                      className="form-select"
                      value={Search.City}
                      onChange={(e) =>
                        setSearch({ ...Search, City: e.target.value })
                      }
                    ></select>
                    <label htmlFor="City">City</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="51"
                      onChange={(e) =>
                        setSearch({ ...Search, gender: e.target.value })
                      }
                    >
                      <option value="none" selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option>Any</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Transgender</option>
                    </select>
                    <label htmlFor="51">Gender</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="52"
                      onChange={(e) =>
                        setSearch({ ...Search, age: e.target.value })
                      }
                    >
                      <option value="none" selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option>Any</option>
                      <option>18 to 30</option>
                      <option>30 to 45</option>
                      <option>45 to 60</option>
                    </select>
                    <label htmlFor="52">Age</label>
                  </div>
                </div>

                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="54"
                      onChange={(e) =>
                        setSearch({ ...Search, Immediate: e.target.value })
                      }
                    >
                      <option value="none" selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option>Any</option>
                      <option>Immediate</option>
                      <option>Not Immediate</option>
                    </select>
                    <label htmlFor="54">Availablity</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="55"
                      onChange={(e) =>
                        setSearch({ ...Search, Workplace: e.target.value })
                      }
                    >
                      <option value="none" selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option>On-site</option>
                      <option>Remote</option>
                      <option>Hybrid</option>
                    </select>
                    <label htmlFor="55">Work Mode</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="56"
                      value={Search.Emptype}
                      onChange={(e) =>
                        setSearch({ ...Search, Emptype: e.target.value })
                      }
                    >
                      <option value="none" selected></option>
                      {/* <option value="" disabled selected>
                                Select Employment Type</option> */}
                      <option>Full-time</option>
                      <option>Part-time</option>
                    </select>
                    <label htmlFor="56">Employment Type </label>
                  </div>
                </div>
                <div className="col-6 col-md-3 ">
                  <div className="form-floating">
                    <select
                      name="Experience"
                      className="form-select"
                      id="Experience"
                      value={Search.Experience}
                      onChange={(e) =>
                        setSearch({ ...Search, Experience: e.target.value })
                      }
                    >
                      <option value="none" selected></option>

                      <option value="Intern">Intern</option>
                      <option value="Fresher">Fresher</option>
                      <option value="0 - 1 year">0-1 year</option>
                      <option value="1-5 years">1-5 years</option>
                      <option value="5-10 years">5-10 years</option>
                      <option value="10+ years">10+ years</option>

                      {/* <option>Male</option>
                                            <option>Female</option> */}
                    </select>{" "}
                    <label htmlFor="Experience">Experience</label>
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-end  p-2  ">
                  <button
                    className="btn btnjui2 ps-4 me-1 pe-4 "
                    type="button"
                    onClick={(e) => props.setreset(props.reset + 1)}
                  >
                    <BiReset size={25} />
                  </button>
                  <button
                    className="btn btnjui  "
                    type="button"
                    onClick={(e) => knvpins(e)}
                  >
                    Go
                  </button>
                </div>

                <span>
                  {SearchValidationError ? (
                    <div className="alert alert-danger" role="alert">
                      {SearchValidationError}
                    </div>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {/* <input type="submit" value="Search" className="btn btn-success"></input> */}
            </div>
            <span style={{ color: "red" }}>{err ? err : ""}</span>
          </div>
        </form>
        <div className="row pt-4">
          {load ? 'Loader' : ''}
          <div className="container-fluid bg-trasparent my-4 p-3 ">
            <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-3 g-3 car">
              {output}
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
