import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function Quickfix() {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="container mayanfont" style={{ paddingTop: "80px" }}>
      {/* <center>
        <h5>For Service Provider and Product Retailer Profiles</h5>
      </center> */}
      <br />
      <br />
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                CANNOT FIND APPROPRIATE CATEGORY / SUB CATEGORY ?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I cannot find an appropriate category / sub category /
              both in order to create a profile for my business entity. What can
              I do ?
            </p>
            <p>
              <b>A.</b> Please click on ‘ASK US’ on the navigation bar, fill in
              your requirement and send it to us. We shall create the required
              sub category / category / both accordingly for you.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                FORGOT SIGN IN / LOG IN CREDENTIALS ?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I have created a Business Profile as a Service Provider
              / Product Retailer. But now I have FORGOTTEN the SIGN IN / LOG IN
              CREDENTIALS (LOG IN ID and PASSWORD) ?
            </p>
            <p>
              <b>A.</b> Click on ‘forgot sign in / log in id’ on the Log In
              page.
            </p>
            <p>
              Check for your Business Profile (with the search criteria). Once
              you have identified Your Profile, click on the ‘CLAIM’ button of
              the respective profile and follow the instructions.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                CHANGE SIGN IN / LOG IN ID&nbsp;?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I want to CHANGE my SIGN IN / LOG IN ID. Can I ?
            </p>
            <p>
              <b>A.</b> Yes. You can easily change your Log In Id from :
              <ul>
                <li>A Mobile Number to a New Mobile Number / Email Id</li>
                <li>An Email Id to a New Email Id / Mobile Number</li>
              </ul>
            </p>
            <p>Steps to Change :</p>
            <p>{`Go to your ACCOUNT SETTINGS  >  Click on ‘Subscription Details and Account Settings’  >  Click on ‘LOGIN CREDENTIALS’  >  Click on ‘CHANGE’ on the Login Id tab > Enter your existing password > Enter your New Mobile Number or New Email Id and Update it.`}</p>
            <p>
              Please note that an OTP will be sent to your New Mobile Number /
              Email Id for Validation.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                SELECTED WRONG PROFILE CLASSIFICATION&nbsp;?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I have selected the WRONG PROFILE CLASSIFICATION by
              mistake ?
            </p>
            <p>
              <center>OR</center>
            </p>
            <p>
              I have created a profile as a Service Provider and now I have
              stopped offering those services and become a Product Retailer (or
              vice-versa) ?
            </p>
            <p>
              <b>A.</b> Steps to Transfer :
            </p>
            <p>{`Go to your ACCOUNT SETTINGS  >  Click on ‘Profile Details and Settings’  >  Go to ‘Actions’ of the respective Profile > Click on ‘CHANGE’ on the Profile Transfer tab > Create a new profile > Select your appropriate Profile Classification (Services / Products) and GO LIVE with your new profile.`}</p>
            <p>
              Your old profile will get deleted automatically. Your LOG IN
              credentials will remain the same.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                CREATED PROFILE IN WRONG CATEGORY / SUB CATEGORY&nbsp;?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I have created a Profile in the WRONG CATEGORY / SUB
              CATEGORY ?
            </p>
            <p>
              <center>OR</center>
            </p>
            <p>
              I have created a Profile as a Service Provider / Product Retailer
              in a particular Category / Sub Category and now I want to change
              my Profile to another Category / Sub Category ?
            </p>
            <p>
              <b>A.</b> Steps to Transfer :
            </p>
            <p>{`Go to your ACCOUNT SETTINGS  >  Click on ‘View and Edit Profile’ > Click on ‘EDIT PROFILE’  >  Select the New Category or Sub Category of your choice.`}</p>
            <p>
              Your Profile will now be displayed in the new category /
              sub-category.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                CHANGE PROFILE DISPLAY NAME TO A NEW NAME&nbsp;?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I have created a profile as a Service Provider / Product
              Retailer in my Individual name but now I have started a Company /
              Enterprise and would like to CHANGE this PROFILE in the NAME of my
              Company / Enterprise ?
            </p>
            <p>
              <b>A.</b> Simple ! You do NOT have to discard or delete this
              existing account and create a new account by making another
              payment.
            </p>
            <p>All you have to do is :</p>
            <p>{`Go to your ACCOUNT SETTINGS  >  Click on ‘View and Edit Profile’ > Click on ‘EDIT PROFILE’  >  Change your PROFILE DISPLAY NAME.`}</p>
            <p>
              The Works / Products added to your Portfolio / Catalogue will all
              remain as it is unless you would like to edit / delete that.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel71"}
        onChange={handleChange("panel71")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                NEED DUPLICATE GST INVOICE&nbsp;?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I deleted the soft copy of my Subscription GST Invoice
              by mistake. How do I get a copy of the same ?
            </p>
            <p>
              <b>A.</b>{" "}
              {`Go to your ACCOUNT SETTINGS >  Click on ‘Subscription Details and Account Settings’  >  Click on ‘MEMBERSHIP SUBSCRIPTION DETAILS’. You can see your Account Subscription Details with options to View and Download all your Invoice copies at any time.`}
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>PROFILE DELETION&nbsp;?</b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I have a Service Provider / Product Retailer Profile. If
              I DELETE my PROFILE, within how many days must I Log In to
              Re-activate my profile before permanent deletion ?
            </p>
            <p>
              <b>A.</b> Why would you want to delete or deactivate your profile,
              when you can HIDE your profile (s) if you are going on a long and
              indefinite period of break from work / business. Please refer the
              Membership Features on your ACCOUNT SETTINGS to know more about
              this feature.
            </p>
            <p>
              Only our business profile (s), if you have more than one, is/are
              hidden But you can Continue to Use the other Features of the app.
            </p>
            <p>
              However, If you permanently Delete your profile, then it can Never
              be retrieved again.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>ACCOUNT DELETION&nbsp;?</b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I have a Service Provider / Product Retailer Profile. If
              I DELETE my ACCOUNT, within how many days must I Log In to
              Re-activate my account before permanent deletion ?
            </p>
            <p>
              <b>A.</b> Within 30 days of permanent Deletion, after which the
              account can Never be retrieved again.
            </p>
            {/* <p>
              When 'On A Break', only your business profile (the selected
              profile(s) if you have more than one) is hidden But you can
              Continue to Use All Features of the app.
            </p>
            <p>
              However, If you permanently Delete your account, then it can Never
              be retrieved again.
            </p> */}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <br />
      <br />
    </div>
  );
}
