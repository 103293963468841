import axios from 'axios'
import { notify } from 'jquery';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Mask } from '../Utility/mask';
import { SearchComponent } from './ConSearchResult/SearchComponent';
import { ProfileCard } from './ProfileCard/ProfileCard';
function EliteService() {


  const [data, setdata] = useState(false);
  const [cat, setCat] = useState(false);
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");
  const [SearchOn, setSearchOn] = useState(false);
  const [Keyer, setKeyer] = useState(1);
  // id=JSON.parse(id)
  let [searchParams, setSearchParams] = useSearchParams();
  var id = Mask.decode(searchParams.get("Sub_id"));
  let userData = useSelector((state) => state.user.value)

  useEffect(() => {
    axios
      .post("/api/User/consearchingElite", {
        id, quickLocation: userData.quickLocation
      })
      .then((res) => {
        setCat(res.data);
        setResults(res.data.map((sr) => sr._id));
        const postSummaries = res.data.map((post) => ({
          id: post._id,
          key: post.id,
          data: post,
        }));
        setdata(postSummaries);
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {" "}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data.length > 0) {
        setoutput(
          data.map((number) => (
            <ProfileCard key={number.id} ids={makeid()} data={number.data} />

            // <SearchComponent
            //   key={number.id}
            //   ids={makeid()}
            //   data={number.data}
            // ></SearchComponent>
          ))
        );
        if (data.length > 0) {
          if (data.length === 1) {
            setDisplayNo(
              <center>
                <h4>Displaying&nbsp;{data.length}&nbsp;profile</h4>
              </center>
            );
          } else {
            setDisplayNo(
              <center>
                <h4>Displaying&nbsp;{data.length}&nbsp;profiles</h4>
              </center>
            );
          }
        }
      } else {
        setoutput(
          <center>
            <h4 style={{ fontWeight: "400", color: "#5A5A5A" }}>
              No profiles created yet
            </h4>
          </center>
        );
        setDisplayNo("");
      }
    }
  }, [error, load]);

  const [DisplayNo, setDisplayNo] = useState();

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  const [ShoutMass, setShoutMass] = useState();
  const [Results, setResults] = useState();
  const shoutOut = async () => {
    var dataSend = {
      sub_id: id,
      massage: ShoutMass,
      cat_id: localStorage.getItem("Cat"),
      Results,
    };
    // return
    var send = await axios.post("/api/User/shoutOut", { dataSend });
    if (send.status == 200) {
      // setViewTopicMember(data.data)
      // console.log(data.data)
      notify("SHOUT-OUT sent successfully", true);
    } else {
      notify("SHOUT-OUT sent failed", false);
    }
  };

  useEffect(() => {
    setResults();
  }, [Keyer]);

  return (
    <div className=" content">
      <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
        {/* <SearchToolbar
          key={Keyer}
          setResults={setResults}
          Keyer={Keyer}
          setKeyer={setKeyer}
          SearchOn={SearchOn}
          setSearchOn={setSearchOn}
        /> */}
        {DisplayNo}
        <div className="row car">{output}</div>
      </div>
    </div>
  )
}

export default EliteService