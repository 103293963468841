import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IoIosSend } from "react-icons/io";

export const ShortlistFreee = ({
  addreating,
  makeEmail,
  setEmailRef,
  SendRef,
  mappas,
  getval,
  referCount,
  yeser,
  first,
  EmailRef,
  Ac,
  Email,
  loading,
  error,
}) => {
  const { formType, Status1, Status2 } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === "panerhngryl623"}
      onChange={handleChange("panerhngryl623")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
      >
        <Typography sx={{ width: "100%", flexShrink: 0 }}>
          {" "}
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <b> Rate, Refer and Report this Profile</b>
          </Typography>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <>
            <div
              className="form-check col-12 d-flex  justify-content-start mb-3 pe-0 d-none"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalsabari"
            >
              <input
                className="form-check-input float-end checkboxpro me-3 mt-3"
                type="checkbox"
                disabled
                // onClick={(e) => checkaeijfpi(e)}
              />
              <span className="iorughoisrhgiohs">Short-list this Profile</span>
            </div>
            <div className="row">
              <div className="col-12">
                <a
                  className="card-title sgknriog"
                  //  data-bs-toggle="modal" data-bs-target="#exampleModal"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalsabari"
                >
                  Rate this Profile
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p className="card-text">Good</p>
              </div>
              <div className="col-6">
                <p className="card-text">{Status1 ? Status1?.length : ""}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p className="card-text">Highly Recommended (HR)</p>
              </div>
              <div className="col-6">
                <p className="card-text">{Status2 ? Status2?.length : ""}</p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <a
                  className="card-title sgknriog"
                  //  data-bs-toggle="modal" data-bs-target="#examplewkligheModal"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalsabari"
                >
                  Refer this Profile
                </a>
              </div>
            </div>
            {yeser ? (
              <div className="row">
                <div className="col-6">
                  <p className="card-text"> Has been Referred</p>
                </div>
                <div className="col-6">
                  <p className="card-text">
                    {referCount ? referCount : ""} {yeser ? yeser : ""}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <br />
            <div className="row">
              <a
                href=""
                //  data-bs-toggle="modal" data-bs-target="#reportthismodal"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalsabari"
              >
                Report this Profile
              </a>
            </div>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Rate this Profile{" "}
                    </h5>
                    <button
                      type="button"
                      id="closebuttonforreport"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div>
                      <div className="form-check  ms-5 m-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault12"
                          onChange={(e) => addreating(e, 1)}
                          defaultChecked={
                            first ? (first.good ? true : false) : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault12"
                        >
                          Good
                        </label>
                      </div>
                      <div className="form-check ms-5 m-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault122"
                          onChange={(e) => addreating(e, 2)}
                          defaultChecked={
                            first ? (first.high ? true : false) : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault122"
                        >
                          Highly Recommended (HR)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="examplewkligheModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Refer this Profile{" "}
                    </h5>
                    <button
                      type="button"
                      id="closebuttonforreport"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div>
                      {/* <label className="form-label" htmlFor="flexCheckDefault1222">
                  Refer
                </label> */}
                      <div className="col-md-12 p-1 mt-2">
                        <label className="form-label">Email Id *</label>
                        <p>
                          Enter the email ids of the persons to whom you would
                          like to refer this Profile
                        </p>

                        {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                        <div className="row py-2 ps-4">
                          {Email ? Email : ""}
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="input-group w-100">
                          <input
                            maxLength=""
                            className="form-control"
                            id="skills"
                            onChange={(e) => makeEmail(e.target.id)}
                            type="text"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                          <button
                            className="btn pro-spaces-button"
                            onClick={(e) => makeEmail("skills", true)}
                            type="button"
                            id="button-addon2"
                          >
                            Add
                          </button>
                        </div>
                      </div>

                      <div className="">
                        <label>Subject</label>
                        <input
                          type="text"
                          className="form-control"
                          value="PROchure Consultant / Service Provider Referral"
                          onChange={(e) =>
                            setEmailRef({
                              ...EmailRef,
                              subject: e.target.value,
                            })
                          }
                          disabled
                        ></input>
                      </div>
                      <br></br>
                      <div className="">
                        <label>Message</label>
                        <textarea
                          className="form-control"
                          value={EmailRef.massage}
                          onChange={(e) =>
                            setEmailRef({
                              ...EmailRef,
                              massage: e.target.value,
                            })
                          }
                        ></textarea>
                        {/* <input type="text" className="form-control" onChange={(e)=>setEmailRefer({...EmailRefer,massage:e.target.value})} ></input> */}
                      </div>
                      <br />

                      <p className="text-danger">{error ? error : ""}</p>
                      <div className="button-section d-flex justify-content-end">
                        {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                        {!loading && (
                          <button
                            className="btn pro-spaces-button3"
                            type="submit"
                            onClick={(e) => SendRef()}
                          >
                            <IoIosSend />
                          </button>
                        )}
                        {loading && (
                          <button
                            className="btn pro-spaces-button3"
                            type="submit"
                            onClick={(e) => SendRef()}
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
