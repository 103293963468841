import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Timelines } from "../../../Components/Timeline/Timeline";

import { BussinessInformationVendor } from "../../../../Components/ProfileComponents/BussinessInformationVendor";
import { BussinessInformation } from "../../../../Components/ProfileComponents/BussinessInformation";
import { ContactInformation } from "../../../../Components/ProfileComponents/ContactInformation";
import { PersonalInformationStatic } from "../../../../Components/ProfileComponents/PersonalInformationStatic";

function ProfileTrialViewVendor({
  profileId,
  first,
  RazorPay,
  setRefCode,
  Error,
  setGSTIN,
  checkCoupe,
  couponsuccess,
  couponError,
}) {
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [loading, setloading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var Ac = profileId;

  useEffect(() => {
    if (profileId) {
      axios
        .post("/api/Free/ProProfileexternal", {
          Ac,
        })
        .then((res) => {
          console.log(res.data);
          setGetval(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  const [getval, setGetval] = useState(false);

  const [type, settype] = useState();
  useEffect(() => {
    if (getval.formType === 1) {
      settype(true);
    } else if (getval.formType === 2) {
      settype(false);
    }
  }, [getval]);

  const login = () => {
    setloading(true);
    var dataSend = {
      Contact: getval?.email,
      Password: getval?.email,
    };
    console.log("status2");

    axios
      .post("/api/auth/login", { dataSend })
      .then((res) => {
        if (res.status == "200") {
          return navigate("/");
        } else if (res.status == "206") {
          if (res.data === "Active") {
            return navigate("/next/");
          } else if (res.data === "Passive") {
            return navigate("/passive/");
          } else if (res.data === "Activev2") {
            return navigate("/next_/");
          } else if (res.data === "Activev3") {
            return navigate("/next__/");
          }
        } else {
          console.log("le");
          return;
        }
      })
      .catch((err) => {
        if (err.response.status == "400" || err.response.status == "401") {
        } else if (err.response.status == "402") {
          return navigate("/payment");
        } else if (err.response.status == "500") {
          console.log("status:500");
        } else if (err.response.status == "406") {
          console.log("status:406");
        }
        setloading(false);
      });
  };

  return (
    <>
      {/* <NavBar /> */}
      <div style={{ paddingTop: "80px" }}>
        <div className="row m-0 w-100 mt-2 d-flex justify-content-center">
          <center className="mb-2">
            This is how your PROchure Profile would be
            visible&nbsp;to&nbsp;others&nbsp;once&nbsp;activated.
          </center>
          {/* 
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-6 m-0 p-0">
          <h6 className="eufgiueg mt-2">Referral Code / Name</h6>
                 
                    <div className="row">
                      <div className="col-12 m-0 p-0">
                        {" "}
                        <input
                          type="text"
                          placeholder="Enter Referral Code / Name "
                          className="form-control bg-light"
                          onChange={(e) => setRefCode(e.target.value)}
                        />
                      </div>
                    </div>
                  
                  </div>
            </div>
          </div>

          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 m-0 p-0">
          
                <p>
                  If you need a GST invoice for your subscription, then
                  please mention your GST number below
                </p>
              <div className="">
                
                <input
                  onChange={(e) => setGSTIN(e.target.value)}
                  placeholder="Enter GST registration number"
                  className="form-control"
                  type="text"
                />
              </div>
                  </div>
            </div>
          </div> */}

          <div
            className=" container mx-5  justify-content-center pt-2"
            style={{ width: "700px" }}
          >
            {/* <h6 className="eufgiueg mt-2">Referral Code / Name</h6>
            <input
              type="text"
              placeholder="Enter Referral Code / Name "
              className="form-control bg-light"
              onChange={(e) => setRefCode(e.target.value)}
            />
            <h6 className="eufgiueg mt-3 mb-0">Discount Coupon</h6>
            <div className="pt-2">
              <div className="d-flex bd-highlight">
                <div className="flex-grow-1 bd-highlight">
                  {" "}
                  <input
                    type="text"
                    id="coupe"
                    placeholder="Enter Coupon Code "
                    className="form-control bg-light"
                  />
                </div>
                <div className="bd-highlight ms-1">
                  {" "}
                  <button
                    onClick={(e) => checkCoupe(e)}
                    className="btn px-3 w-100 wygdiwgdigwidg"
                  >
                    APPLY
                  </button>
                </div>
              </div>
              <p className="text-success">
                {couponsuccess ? couponsuccess : ""}
              </p>
              <p className="text-danger">{couponError ? couponError : ""}</p>
            </div>
            <div className="pt-2">
              If you need a GST invoice for your subscription, then please
              mention your GST number below
            </div>
            <div className="pt-2">
              <input
                onChange={(e) => setGSTIN(e.target.value)}
                placeholder="Enter GST registration number"
                className="form-control "
                type="text"
              />
            </div>

            <div className="row mt-2">
              <p>
                A copy of your Invoice will be sent to your email id. You can
                also View and Download your Invoice from 'MEMBERSHIP
                SUBSCRIPTION DETAILS' in 'Subscription Details and Account
                Settings' on your Profile tab.
              </p>
            </div>
            <div className="pt-2">
              <p className="text-danger">{Error}</p>
            </div> */}

            <div>
              <center>
                {!loading && (
                  <button
                    className="btn btn-udaguadgouao mt-3 "
                    style={{ maxWidth: "325px", textTransform: "none" }}
                    onClick={login}
                  >
                    {first
                      ? first === "Product" || first === "Consultant"
                        ? "PAY and ACTIVATE ACCOUNT"
                        : "PAY and ACTIVATE ACCOUNT"
                      : "Please Wait"}
                  </button>
                )}
                {loading && (
                  <button
                    className="btn btn-udaguadgouao mt-3 "
                    style={{ maxWidth: "325px" }}
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
              </center>
            </div>
          </div>

          {/* <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-10 col-md-6 m-0 p-0">
                <h6 className="eufgiueg mt-4">Referral Code</h6>{" "}
                <input
                  type="text"

                  placeholder="Enter Referral Code "
                  className="form-control bg-light"
                  onChange={(e) => setRefCode(e.target.value)}
                />
              </div>
            </div>
          </div> */}
        </div>

        <div className="container emp-profile border ">
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-3">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="profilee-img">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={getval.link}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5>{getval.pname ? getval.pname.toUpperCase() : ""}</h5>
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5>{getval.pname ? getval.pname.toUpperCase() : ""}</h5>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                        {getval.string ? getval.string[0].category : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                          {getval.string ? getval.string[0].category : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
                {/* <div className="d-none d-md-block">
                  <Link
                    to={
                      getval.Type === "Product_Unique_v3"
                        ? "/edit_profile_products_free_static"
                        : "/edit_profile_products_free"
                    }
                  >
                    <input
                      type="submit"
                      className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      name="btnAddMore"
                      value="Edit Profile"
                    />
                  </Link>
                </div>
                <div className="d-block d-md-none pt-2">
                  <center>
                    <Link
                      to={
                        getval.Type === "Product_Unique_v3"
                          ? "/edit_profile_products_free_static"
                          : "/edit_profile_products_free"
                      }
                    >
                      <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                        style={{ borderRadius: "25px" }}
                        name="btnAddMore"
                        value="Edit Profile"
                      />
                    </Link>
                  </center>
                </div> */}
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {getval.Type === "Productv3" ? (
                  <>
                    <Accordion
                      expanded={expanded === "panelewrg4"}
                      onChange={handleChange("panelewrg4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Product Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p className="py-2">{getval.Description}</p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <PersonalInformationStatic getval={getval} vendor={true} />

                    <BussinessInformation getval={getval} />
                    <ContactInformation getval={getval} />
                    {/* <ShorotlistAccodian checkaeijfpi2={checkaeijfpi2} addreating={addreating} makeEmail={makeEmail} setEmailRef={setEmailRef} SendRef={SendRef} mappas={mappas} getval={getval} referCount={referCount} yeser={yeser} first={first} EmailRef={EmailRef} Ac={Ac} Email={Email} error={error} loading={loading} /> */}
                  </>
                ) : (
                  <>
                    <Accordion
                      expanded={expanded === "panel2dd"}
                      onChange={handleChange("panel2dd")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <span>
                            <b>
                              {" "}
                              Personal Information
                              <br />
                            </b>
                            (of the Proprietor / Business Owner)
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="row py-2">
                            <div className="col-md-6">
                              <span>
                                Name of the Proprietor / Business Owner
                              </span>
                            </div>
                            <div className="col-md-6 ">
                              <label style={{ color: "var(--blue)" }}>
                                {getval.firstname
                                  ? getval.firstname.toUpperCase()
                                  : ""}{" "}
                                {getval.lastname
                                  ? getval.lastname.toUpperCase()
                                  : ""}
                              </label>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <BussinessInformationVendor getval={getval} />
                    <Accordion
                      expanded={expanded === "panel4er"}
                      onChange={handleChange("panel4er")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Contact Information</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {getval.countrycode ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Country Code</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.countrycode}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.statecode ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>State Code</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.statecode}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.CON ? (
                            getval.CON.map((elment, ind) => {
                              console.log(elment);

                              return (
                                <>
                                  {elment.contact2 ? (
                                    <>
                                      <div className="row py-2">
                                        <div className="col-md-6">
                                          <span>
                                            Mobile Number / Landline Number
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label
                                            style={{ color: "var(--blue)" }}
                                          >
                                            {elment.contact2}
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {elment.contactpersonsname2 ? (
                                    <>
                                      <div className="row py-2">
                                        <div className="col-md-6">
                                          <span>Contact Person</span>
                                        </div>
                                        <div className="col-md-6">
                                          <label
                                            style={{ color: "var(--blue)" }}
                                          >
                                            {elment.contactpersonsname2}
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {elment.contactpersonsdesignation2 ? (
                                    <>
                                      <div className="row py-2">
                                        <div className="col-md-6">
                                          <span>
                                            Contact Person’s Designation
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label
                                            style={{ color: "var(--blue)" }}
                                          >
                                            {elment.contactpersonsdesignation2}
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {elment.preferredlanguage2 ? (
                                    <>
                                      <div className="row py-2">
                                        <div className="col-md-6">
                                          <span>
                                            Most Comfortable / Preferred
                                            Language for Communication
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label
                                            style={{ color: "var(--blue)" }}
                                          >
                                            {elment.preferredlanguage2}
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}

                          {/* landline */}

                          {getval.contact3 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Landline Number</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contact3}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.contactpersonsname3 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Contact Person</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contactpersonsname3}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.contactpersonsdesignation3 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Contact Person’s Designation</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contactpersonsdesignation3}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.preferredlanguage3 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>
                                  Most Comfortable / Preferred Language for
                                  Communication
                                </span>
                              </div>
                              <div className="col-md-6 ">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.preferredlanguage3}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* landline additional*/}

                          {getval.contact4 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Landline Number (additional)</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contact4}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.contactpersonsname4 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Contact Person</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contactpersonsname4}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.contactpersonsdesignation4 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Contact Person’s Designation</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contactpersonsdesignation4}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.preferredlanguage4 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>
                                  Most Comfortable / Preferred Language for
                                  Communication
                                </span>
                              </div>
                              <div className="col-md-6 ">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.preferredlanguage4}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* mobile */}

                          {getval.contact ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Mobile Number</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contact}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.contactpersonsname ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Contact Person</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contactpersonsname}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.contactpersonsdesignation ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Contact Person’s Designation</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contactpersonsdesignation}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.preferredlanguage ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>
                                  Most Comfortable / Preferred Language for
                                  Communication
                                </span>
                              </div>
                              <div className="col-md-6 ">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.preferredlanguage}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* mobile adiitional */}

                          {getval.contact2 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Mobile Number (additional)</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contact2}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.contactpersonsname2 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Contact Person</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contactpersonsname2}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.contactpersonsdesignation2 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Contact Person’s Designation</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.contactpersonsdesignation2}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {getval.preferredlanguage2 ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>
                                  Most Comfortable / Preferred Language for
                                  Communication
                                </span>
                              </div>
                              <div className="col-md-6 ">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.preferredlanguage2}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {/*//////////\\\\\\\\\\\*/}

                          {getval.email ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Email Id</span>
                              </div>
                              <div className="col-md-6">
                                <label style={{ color: "var(--blue)" }}>
                                  {getval.email}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </div>
            </center>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8"></div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ProfileTrialViewVendor;
