import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import { BsFillBriefcaseFill, BsArrowLeft, BsFillPeopleFill } from "react-icons/bs";

import './jobView.css'
import Axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import { SpinnerBig } from "../../Utility/spinner";

let answerPro = []
export const
    JobView = (props) => {
        useEffect(() => {
            props.Auth()
        }, [])
        let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
        var Id = searchParams.get("k")

        const [expanded, setExpanded] = useState(false);

        const handleChange = (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };



        const [Error, setError] = useState(false)
        const [Content, setContent] = useState(false)


        const [currentIndex, setcurrentIndex] = useState(1)
        const [total, settotal] = useState()
        const [CollectedAnswers, setCollectedAnswers] = useState([])

        const [Errorr, setErrorr] = useState('')

        const collectAnswer = (e, index) => {
            console.log(index)

            console.log(e.target.value)

            var myr = {
                Answer: e.target.value,
                IndexNo: jobDetails.JobRecruit_Screenquestions[index].indexNo,
                Question: jobDetails.JobRecruit_Screenquestions[index].Question
            }

            answerPro[index] = myr

            console.log(myr)

            console.log('success')
            return true

        }




        const validateAsync = async (ind) => {
            if (ind == 1) {
                if (!ProfileId) {
                    return false
                }
                return true
            }
            var id = ind - 2
            if (answerPro.length === 0) {
                return false
            }
            if (answerPro[id].Answer.length >= 1) {
                return true
            } else {
                return false
            }

        }

        const Nextone = async (s, indi = false) => {

            console.log(indi)
            if (indi) {
                let validate = await validateAsync(indi)
                if (validate == false) {
                    console.log(currentIndex)
                    if (currentIndex == 1) {
                        return setErrorr("Select job profile")
                    }
                    setErrorr('Please Answer')

                    console.log('validation false')
                    return
                } else {
                    setErrorr('')
                    console.log('validated')
                }
            }
            console.log(total)
            if (s) {
                console.log('forward')

                if (currentIndex == total + 1) {
                    console.log('reached')
                    return

                } else {
                    document.getElementById('fq' + currentIndex).classList.add('d-none')
                    document.getElementById('fq' + (currentIndex + 1)).classList.remove('d-none')
                    setcurrentIndex(currentIndex + 1)
                }

            }
            else {
                console.log('backward')

                if (currentIndex == 1) {
                    console.log('reached')

                    return

                } else {
                    document.getElementById('fq' + currentIndex).classList.add('d-none')
                    document.getElementById('fq' + (currentIndex - 1)).classList.remove('d-none')
                    setcurrentIndex(currentIndex - 1)
                }



            }
        }

        useEffect(() => {
            if (currentIndex == 1) {
                document.getElementById('bBtn').classList.add('d-none')
            } else {
                document.getElementById('bBtn').classList.remove('d-none')
            }
            if (currentIndex == total + 1) {
                document.getElementById('btnNext1').classList.add('d-none')
                document.getElementById('btnNext2').classList.remove('d-none')
            }
            else {
                document.getElementById('btnNext1').classList.remove('d-none')
                document.getElementById('btnNext2').classList.add('d-none')
            }
        }, [currentIndex])


        const [jobDetails, setjobDetails] = useState(false)
        const [loader, setloader] = useState(<div id="iooioooo1231s312" class="spinner-border text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>)
        const [Loading, setLoading] = useState(true)
        useEffect(() => {
            // var Id='6209f5e094805605d016655d';

            setLoading(true)

            Axios.post('/api/User/jobView', { Id })
                .then(res => {
                    if (res.status == 200) {
                        console.log('jobView')
                        console.log(res.data[0])
                        console.log(Id)
                        console.log(res.data[0].category[0].category)
                        setjobDetails(res.data[0]);
                        setLoading(false)
                        settotal(res.data[0].JobRecruit_screenQustionNumber)
                    }
                }).catch((err) => {
                    console.log(err.response)
                    setLoading(false)
                })
        }, [])


        function reloads() {
            Axios.post('/api/User/JobApplyOrNot', { Id })
                .then(res => {
                    if (res.status == 200) {
                        console.log('job applied')
                        setApply(true)

                    }
                }).catch((err) => {
                    console.log(err.response)

                })

        }


        const [Apply, setApply] = useState(false)
        useEffect(() => {
            Axios.post('/api/User/JobApplyOrNot', { Id })
                .then(res => {
                    if (res.status == 200) {
                        console.log('job applied')
                        setApply(true)

                    }
                }).catch((err) => {
                    console.log(err.response)

                })
        }, [])




        function doit(e) {
            e.preventDefault();
            console.log("haiiii")
            console.log(answerPro)
            console.log(jobDetails.JobRecruit_screenQustionNumber)
            var count = 0
            var qualify = 1
            if (total > 0) {
                if (ProfileId) {
                    if (answerPro[jobDetails.JobRecruit_screenQustionNumber - 1]) {
                        console.log("ok monuse")
                        setErrorr('')

                        for (var i = 0; i <= jobDetails.JobRecruit_screenQustionNumber - 1; i++) {
                            console.log("my+->" + i)

                            if (jobDetails.JobRecruit_Screenquestions[i].Compulsory == true) {
                                if (jobDetails.JobRecruit_Screenquestions[i].Answer != answerPro[i].Answer) {
                                    count++
                                    console.log(count)
                                }
                            }
                        }
                        if (count > 0) {
                            qualify = 0
                        }

                        var sendData = {

                            Jobpostid: jobDetails._id,
                            JobSeekerpostId: ProfileId,
                            qualify,
                            answerPro
                        }

                        sendData = JSON.stringify(sendData)

                        console.log(sendData)
                        // return
                        Axios.post('/api/User/JobAnswer', { sendData })
                            .then(res => {
                                if (res.status == 200) {
                                    console.log('jobView')
                                    reloads()
                                    document.getElementById("12nj3ytkiol0up0rftg6yh6uj8i9kee").click()


                                }
                            }).catch((err) => {
                                console.log(err.response)

                            })


                    }
                    else {
                        return setErrorr('Please Answer')
                    }

                }
                else {
                    return setErrorr('Select job profile')
                }
            }
            else {
                if (ProfileId) {
                    var sendData = {

                        Jobpostid: jobDetails._id,
                        JobSeekerpostId: ProfileId,
                        qualify,
                        answerPro
                    }

                    sendData = JSON.stringify(sendData)

                    console.log(sendData)
                    // return
                    Axios.post('/api/User/JobAnswer', { sendData })
                        .then(res => {
                            if (res.status == 200) {
                                console.log('jobView')
                                reloads()
                                document.getElementById("12nj3ytkiol0up0rftg6yh6uj8i9kee").click()


                            }
                        }).catch((err) => {
                            console.log(err.response)

                        })


                }
                else {
                    return setErrorr('Select job profile')
                }

            }











        }
        const [LoadingState, setLoadingState] = useState(true)
        // var Id='6209f5e094805605d016655d';














        const [postList, setpostList] = useState()
        useEffect(() => {
            // var Id='6209f5e094805605d016655d';


            Axios.get('/api/User/JobMyPostList1')
                .then(res => {
                    if (res.status == 200) {
                        console.log('jobView')
                        console.log(res.data)
                        console.log(Id)
                        setpostList(res.data)
                        //    setjobDetails(res.data);
                        setLoading(false)
                        //    settotal(res.data.JobRecruit_screenQustionNumber)
                    }
                }).catch((err) => {
                    console.log(err)

                })
        }, [])

        // 
        const [ProfileId, setProfileId] = useState()
        var user = localStorage.getItem("User")

        useEffect(() => {
            if (!Loading) {
                document.getElementById('asdasd23423434534').classList.add('d-none')
                document.getElementById('hjfgjhghdgshjgf').classList.remove('d-none')
            }
        }, [Loading])


        return (

            <>



                <div id="fgt1" className="container  srpojgpishrgps">


                    <div style={{
                        borderRadius: '15px'
                    }} className='mt-3 container card border pb-2 pt-2 px-4'>

                        {LoadingState ? <div id='asdasd23423434534' className="text-center">
                            {/* {loader} */}
                            <SpinnerBig/>
                        </div> : ''}
                        <div id='hjfgjhghdgshjgf' className="d-none">
                            <h3 className='m-0'>{jobDetails ? jobDetails.JobRecruit_companyName : ''}</h3>
                            {/* <p className='m-0'>{jobDetails ? jobDetails.JobRecruit_companyType : ''}</p> */}

                            {/* <p>{jobDetails ? jobDetails.JobRecruit_companyName : ''},{jobDetails ? jobDetails.JobRecruit_companyCity : ''},{jobDetails ? jobDetails.JobRecruit_companyState : ''}</p> */}
                            <b>Job Title : {jobDetails ? jobDetails.JobRecruit_jobTitles : ''}</b>

                            <div className="row">
                                <div className="entityJobs  col-12">
                                    <b>Vacancies </b>
                                    <p className="ms-2 JobVal ">{jobDetails.JobRecruit_Anyvacancies > 0 ? "Any : " + jobDetails.JobRecruit_Anyvacancies+"," : ""}&nbsp;
                                        {jobDetails.JobRecruit_Malevacancies > 0 ||jobDetails.JobRecruit_Anyvacancies == null? jobDetails.JobRecruit_Malevacancies > 0 ? "Male : " + jobDetails.JobRecruit_Malevacancies+"," : "" : ""}&nbsp;
                                        {jobDetails.JobRecruit_Femalevacancies > 0 ||jobDetails.JobRecruit_Anyvacancies == null? jobDetails.JobRecruit_Femalevacancies > 0 ? "Female : " + jobDetails.JobRecruit_Femalevacancies+"," : "" : ""}&nbsp;
                                        {jobDetails.JobRecruit_Othervacancies > 0 ||jobDetails.JobRecruit_Anyvacancies == null? jobDetails.JobRecruit_Othervacancies > 0 ? "Transgender : " + jobDetails.JobRecruit_Othervacancies : "" : ""}&nbsp;</p>
                                </div>

                                <div className="entityJobs col-6">
                                    <b>Age </b>
                                    <p className="ms-2 JobVal">{jobDetails.JobRecruit_AgeLvl}</p>
                                </div>

                                {/* <div className="col-1"></div> */}
                                <div className="entityJobs col-6">
                                    <b>Gender </b>
                                    <p className="ms-2 JobVal">
                                        {
                                            jobDetails.JobRecruit_Anyvacancies > 0 ||(jobDetails.JobRecruit_Malevacancies>0&&jobDetails.JobRecruit_Femalevacancies > 0&&jobDetails.JobRecruit_Othervacancies > 0)?
                                            "Any, ":""
                                        }
                                        {/* {jobDetails.JobRecruit_Anyvacancies > 0 ? "Any" : ""}&nbsp; */}
                                        {jobDetails.JobRecruit_Malevacancies>0||jobDetails.JobRecruit_Anyvacancies == null ? jobDetails.JobRecruit_Malevacancies > 0 ? "Male," : "" : ""}&nbsp;
                                        {jobDetails.JobRecruit_Femalevacancies > 0||jobDetails.JobRecruit_Anyvacancies == null ? jobDetails.JobRecruit_Femalevacancies > 0 ? "Female," : "" : ""}&nbsp;
                                        {jobDetails.JobRecruit_Othervacancies > 0||jobDetails.JobRecruit_Anyvacancies == null ? jobDetails.JobRecruit_Othervacancies > 0 ? "Transgender" : "" : ""}&nbsp;
                                    </p>
                                </div>
                                <div className="entityJobs col-6">
                                    <b>Work Mode  </b>
                                    <p className="ms-2 JobVal">{jobDetails.JobRecruit_workPlace}</p>
                                </div>
                                {/* <div className="col-1"></div> */}

                                <div className="entityJobs col-6">
                                    <b>Employment Type  </b>
                                    <p className="ms-2 JobVal">{jobDetails.JobRecruit_empType}</p>
                                </div>

                                <div className="entityJobs col-6">
                                    <b>Posted by  </b>

                                    <p className="ms-2 JobVal">{jobDetails.JobRecruit_recrutingAgency ? jobDetails.JobRecruit_recrutingAgency ? "Recruiting agency" : "Organization" : "Recruiting agency"}</p>
                                </div>
                                {/* <div className="col-1"></div> */}
                                <div className="entityJobs col-6">
                                    <b>Recruitment time </b>
                                    <p className="ms-2 JobVal">{jobDetails.JobRecruit_Immediaterecruitment == true || "true" ? "Immediate" : "After " + jobDetails.JobRecruit_AfterDays + " days"}</p>
                                </div>

                                <div className="entityJobs col-6">
                                    <b>Interview Process  </b>
                                    <p className="ms-2 JobVal">{jobDetails.JobRecruit_JobRecruit_InterviewMode == "Ready for Online Interviews" ? "Ready for Online Interviews" : jobDetails.JobRecruit_JobRecruit_InterviewMode == "One-to-One Interviews" ? "One-to-One Interviews" : " Ready for Online Interviews / One-to-One Interviews"}</p>
                                </div>
                                {/* <div className="col-1"></div> */}

                                <div className="entityJobs col-6">
                                    <b>Educational Qualification  </b>
                                    <p className="ms-2 JobVal">{jobDetails.JobRecruit_Qualification ? jobDetails.JobRecruit_Qualification : ""}&nbsp;</p>
                                </div>

                                {
                                    jobDetails.JobRecruit_WomanEN ?

                                        <div className="entityJobs col-6">
                                            <p>This is a Woman-Owned Enterprise  </p>
                                            {/* <p className="ms-2 JobVal">{jobDetails.JobRecruit_JobRecruit_InterviewMode=="Ready for Online Interviews"?"Ready for Online Interviews" :jobDetails.JobRecruit_JobRecruit_InterviewMode=="One-to-One Interviews"?"One-to-One Interviews":" Ready for Online Interviews / One-to-One Interviews"}</p> */}
                                        </div>

                                        : ''

                                }
                                {/* <div className="col-1"></div> */}


                                {
                                    jobDetails.JobRecruit_differentlyAbled == true ?

                                        <div className="entityJobs col-6">
                                            <p className="">We are open to employing differently-abled candidates </p>
                                            {/* <p className="ms-2 JobVal">{jobDetails.JobRecruit_JobRecruit_InterviewMode=="Ready for Online Interviews"?"Ready for Online Interviews" :jobDetails.JobRecruit_JobRecruit_InterviewMode=="One-to-One Interviews"?"One-to-One Interviews":" Ready for Online Interviews / One-to-One Interviews"}</p> */}
                                        </div>

                                        : ''

                                }

                                {
                                    jobDetails.JobRecruit_additionalPay ?

                                        <div className="entityJobs col-6">
                                            <p>We will pay our employees for additional hours of work </p>
                                        </div>

                                        : ''

                                }
                                <div className="col-1"></div>

                                {

                                }




                                {/* <div className="col-1"></div> */}
                                <div className="entityJobs col-12    ">
                                    <b>Experience Level  </b>

                                    <div className=" row ms-2 JobVal"> {jobDetails.JobRecruit_expLvl ? jobDetails.JobRecruit_expLvl
                                        .map((e) => {

                                            return <div className='col-auto'>{e}</div>



                                        }) : 'Some thing went wrong!!!'}
                                    </div>

                                </div>





                            </div>
                            {
                                user != jobDetails.Account_id ?
                                    Apply ?
                                        <>
                                            <button type='button' className='btn w-100 qeuifgqevojhowiqhvipqenkvh mt-3' disabled >Your have already Applied</button>

                                        </>
                                        :
                                        <>
                                            <button type='button' className='btn  w-100 qeuifgqevojhowiqhvipqenkvh mt-3' data-bs-toggle="modal" data-bs-target="#jobFillUp">Apply</button>
                                        </>
                                    : ""
                            }
                            <hr />
                            <b>About the Organization</b>
                            <div className='p-1'>
                                {jobDetails ? jobDetails.JobRecruit_description : ''}
                            </div>
                            <hr />

                            <b>Leaves</b>
                            <div className='p-1'>
                                {
                                    jobDetails ? jobDetails.JobRecruit_leaveF ?
                                        <>
                                            <b> Menstrual Leave </b>
                                            <p>
                                                We are ready to grant 3 days of menstrual leave every month to our women employees
                                            </p>
                                        </>
                                        : "" : ""
                                }
                            </div>
                            <div className='p-1'>
                                {
                                    jobDetails ? jobDetails.JobRecruit_maternityleave ? <>
                                        <b>Maternity Leave</b>
                                        <p>
                                            We are ready to grant maternity leave to our women employees on request
                                        </p>

                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="row" >
                                                    <div className=" col-6">
                                                        Paid leave:
                                                    </div>
                                                    <div className="col-6 ms-0">
                                                        {jobDetails.JobRecruit_maternityleave ? jobDetails.JobRecruit_maternityleavePaidInput ?
                                                            jobDetails.JobRecruit_maternityleavePaidInput == 1 ? jobDetails.JobRecruit_maternityleavePaidInput + " Year" : jobDetails.JobRecruit_maternityleavePaidInput > 1 ?
                                                                jobDetails.JobRecruit_maternityleavePaidInput + " Years" : ""
                                                            : "" : ""} &nbsp;
                                                        {jobDetails.JobRecruit_maternityleave ? jobDetails.JobRecruit_maternityleavePaidInputMonths ?
                                                            jobDetails.JobRecruit_maternityleavePaidInputMonths == 1 ? jobDetails.JobRecruit_maternityleavePaidInputMonths + " Month" : jobDetails.JobRecruit_maternityleavePaidInputMonths > 1 ?
                                                                jobDetails.JobRecruit_maternityleavePaidInputMonths + " Months" : ""
                                                            : "" : ""}
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="row" >
                                                    <div className="col-6">
                                                        Unpaid leave:
                                                    </div>
                                                    <div className="col-6">
                                                        {jobDetails.JobRecruit_maternityleave ? jobDetails.JobRecruit_maternityleaveUnPaidInput ?
                                                            jobDetails.JobRecruit_maternityleaveUnPaidInput == 1 ? jobDetails.JobRecruit_maternityleaveUnPaidInput + " Year" : jobDetails.JobRecruit_maternityleaveUnPaidInput > 1 ?
                                                                jobDetails.JobRecruit_maternityleaveUnPaidInput + " Years" : ""
                                                            : "" : ""} &nbsp;
                                                        {jobDetails.JobRecruit_maternityleave ? jobDetails.JobRecruit_maternityleavePaidInputMonths ?
                                                            jobDetails.JobRecruit_maternityleavePaidInputMonths == 1 ? jobDetails.JobRecruit_maternityleavePaidInputMonths + " Month" : jobDetails.JobRecruit_maternityleavePaidInputMonths > 1 ?
                                                                jobDetails.JobRecruit_maternityleavePaidInputMonths + " Months" : ""
                                                            : "" : ""}
                                                    </div>


                                                </div>
                                            </div>


                                        </div>


                                    </> : "" : ""
                                }
                            </div>


                            <div className='p-1'>
                                {

                                    <>
                                        <div className='p-1'>

                                            <div className="row">

                                                <div className="col-8 col-md-4 col-lg-3">Casual paid leaves</div>
                                                <div className="col-auto  ">:</div>
                                                <div className="col-auto pe-0 ps-0">
                                                    {
                                                        jobDetails ? jobDetails.JobRecruit_casualleaves ? jobDetails.JobRecruit_casualleaves == 1 ? jobDetails.JobRecruit_casualleaves + " Day" :
                                                            jobDetails.JobRecruit_casualleaves > 1 ?
                                                                jobDetails.JobRecruit_casualleaves + " Days"
                                                                : ""
                                                            : jobDetails.JobRecruit_casualleaves + " Day" : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-1'>
                                            <div className="row">
                                                <div className="col-8 col-md-4 col-lg-3">Maximum unpaid leaves</div>
                                                <div className="col-auto ">:</div>
                                                <div className="col-auto pe-0 ps-0">
                                                    {
                                                        jobDetails ? jobDetails.JobRecruit_MaxUnpaidleaves ? jobDetails.JobRecruit_MaxUnpaidleaves == 1 ? jobDetails.JobRecruit_MaxUnpaidleaves + " Day" :
                                                            jobDetails.JobRecruit_MaxUnpaidleaves > 1 ?
                                                                jobDetails.JobRecruit_MaxUnpaidleaves + " Days"
                                                                : ""
                                                            : jobDetails.JobRecruit_MaxUnpaidleaves + " Day" : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-1'>
                                            <div className="row">
                                                <div className="col-8 col-md-4 col-lg-3">Medical paid leaves</div>
                                                <div className="col-auto  ">:</div>
                                                <div className="col-auto pe-0 ps-0">
                                                    {
                                                        jobDetails ? jobDetails.JobRecruit_MedicalPaidleaves ? jobDetails.JobRecruit_MedicalPaidleaves == 1 ? jobDetails.JobRecruit_MedicalPaidleaves + " Day" :
                                                            jobDetails.JobRecruit_MedicalPaidleaves > 1 ?
                                                                jobDetails.JobRecruit_MedicalPaidleaves + " Days"
                                                                : ""
                                                            : jobDetails.JobRecruit_MedicalPaidleaves + " Day" : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-1'>
                                            <div className="row">
                                                <div className="col-8 col-md-4 col-lg-3">Medical unpaid leaves</div>
                                                <div className="col-auto  ">:</div>
                                                <div className="col-auto pe-0 ps-0">
                                                    {
                                                        jobDetails ? jobDetails.JobRecruit_MedicalUnPaidleaves ? jobDetails.JobRecruit_MedicalUnPaidleaves == 1 ? jobDetails.JobRecruit_MedicalUnPaidleaves + " Day" :
                                                            jobDetails.JobRecruit_MedicalUnPaidleaves > 1 ?
                                                                jobDetails.JobRecruit_MedicalUnPaidleaves + " Days"
                                                                : ""
                                                            : jobDetails.JobRecruit_MedicalUnPaidleaves + " Day" : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-1'>
                                            <div className="row">
                                                <div className="col-8 col-md-4 col-lg-3">Annual paid leaves</div>
                                                <div className="col-auto  ">:</div>
                                                <div className="col-auto pe-0 ps-0">
                                                    {
                                                        jobDetails ? jobDetails.JobRecruit_AnnualPaidleaves ? jobDetails.JobRecruit_AnnualPaidleaves == 1 ? jobDetails.JobRecruit_AnnualPaidleaves + " Day" :
                                                            jobDetails.JobRecruit_AnnualPaidleaves > 1 ?
                                                                jobDetails.JobRecruit_AnnualPaidleaves + " Days"
                                                                : ""
                                                            : jobDetails.JobRecruit_AnnualPaidleaves + " Day" : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-1'>
                                            <div className="row">
                                                <div className="col-8 col-md-4 col-lg-3 ">Annual unpaid leaves</div>
                                                <div className="col-auto  ">:</div>
                                                <div className="col-auto pe-0 ps-0">
                                                    {
                                                        jobDetails ? jobDetails.JobRecruit_AnnualUnPaidleaves ? jobDetails.JobRecruit_AnnualUnPaidleaves == 1 ? jobDetails.JobRecruit_AnnualUnPaidleaves + " Day" :
                                                            jobDetails.JobRecruit_AnnualUnPaidleaves > 1 ?
                                                                jobDetails.JobRecruit_AnnualUnPaidleaves + " Days"
                                                                : ""
                                                            : jobDetails.JobRecruit_AnnualUnPaidleaves + " Day" : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>




                                    </>

                                }
                            </div>



                            <hr />



                            {jobDetails.JobRecruit_CodeofConduct != "" ?
                                <> <b>Employee Code of Conduct</b>
                                    <div className='p-1'>
                                        {jobDetails ? jobDetails.JobRecruit_CodeofConduct : ''}
                                    </div>
                                    <hr />
                                </> : ""}
                            <b>Job Description</b>
                            <div className='p-1'>
                                {jobDetails ? jobDetails.JobRecruit_JobDescription : ''}
                            </div>
                            <hr />
                            <div>
                                <div>
                                    <p>
                                        <b className='mb-2'>Skills Required</b>
                                    </p>
                                    <div className=''>


                                        {jobDetails ?

                                            jobDetails.JobRecruit_skills.map((item) => {

                                                return <><div className='px-3  skillItem'>{item}</div></>
                                            })

                                            : ''}




                                    </div>
                                </div>
                                <hr />
                                <b className='mb-2 '> Salary Offered in LPA (Lakh Per Annum)</b>
                                <p className='pb-2'>
                                    {jobDetails ? jobDetails.JobRecruit_minPay : ''} - {jobDetails ? jobDetails.JobRecruit_maxPay : ''} L.P.A

                                </p>

                            </div>
                             {user===jobDetails.Account_id?

                             jobDetails?
                             jobDetails.JobRecruit_screenQustionNumber!==0?
                             <div> 
                                  {/* <b>hai</b> */}
                                  <hr/>
                                  {
                                  jobDetails.JobRecruit_Screenquestions.map((question,index)=>{

                                    return <div className="row">
                                        <div className="col-12">
                                         <b>{index+1}&nbsp;.&nbsp;{question.Question}</b>
                                        </div>
                                        <div className="col-6">
                                            <p className="mt-2">Answer&nbsp;:&nbsp;{question.Answer}</p>
                                        </div>
                                        <div className="col-6">
                                            {
                                                question.Compulsory?
                                            <p className="mt-2">Must-have qualification</p>
                                             :""
                                            }
                                        </div>
                                    </div>

                                  })
                                }
                                  </div>

                                :""
                                :""
                                :""

                             }
                            



                            {/* //////////////////////////////////////////////////// */}










                            {/* ////////////////////////////////////////////////////////// */}






                            <div className="modal fade" id="jobFillUp" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-xl">

                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Apply Now</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="12nj3ytkiol0up0rftg6yh6uj8i9kee"></button>
                                        </div>
                                        <form onSubmit={(e) => doit(e)} >
                                            <div className="modal-body" style={{
                                                minHeight: '180px'
                                            }}>


                                                <div id={'fq1'} className={''}  >

                                                    <> <p>Select Job Profile from your post</p>
                                                        {postList ?
                                                            <><select className="form-control" onChange={(e) => setProfileId(e.target.value)}>
                                                                <option disabled selected>Select</option>
                                                                {
                                                                    postList ?
                                                                        postList.map((Q) => {
                                                                            return <option value={Q._id}>{Q.JobSeeker_jobTitles}</option>
                                                                        }) : ""
                                                                }
                                                            </select>
                                                                <br />
                                                                <a href="/post_job" className="btn pro-spaces-button3">Add new post</a>

                                                            </>
                                                            :
                                                            <></>
                                                        }


                                                    </>
                                                </div>

                                                {/* : */}

                                                <div>


                                                    {jobDetails.JobRecruit_screenQustionNumber != 0 ?
                                                        <>
                                                            <p>
                                                                <button id='bBtn' className='d-none' type='button' onClick={() => Nextone(false)}>
                                                                    <BsArrowLeft size='30' />
                                                                </button>
                                                                {/* Answer the following */}
                                                            </p></>
                                                        :
                                                        ""

                                                    }

                                                    {/* 1 */}
                                                    {jobDetails ?

                                                        jobDetails.JobRecruit_Screenquestions.map((Q, index) => {
                                                            //  console.log(t)

                                                            return <>
                                                                <div id={'fq' + (parseInt(Q.indexNo) + 2)} className={'d-none'}  >
                                                                    <h6 type='text' style={{ fontWeight: '600' }} className='text-dark' placeholder=''>
                                                                        {parseInt(Q.indexNo) + 1} : {Q.Question}
                                                                    </h6>
                                                                    {
                                                                        Q.Type == 'numeric' ?
                                                                            <div className='py-1'>
                                                                                <div className='row ps-1 mt-4 d-one'>
                                                                                    <input name={'ans' + Q.indexNo} onChange={(e) => collectAnswer(e, index)} type='number' className='formcontrol'></input>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='py-1'>
                                                                                <div className='row ps-4 mt-4 d-one'>
                                                                                    <div className="form-check col-2 col-md-1">
                                                                                        <input className="form-check-input" type="radio" value={true} onChange={(e) => collectAnswer(e, index)} name={'ans' + Q.indexNo} id="flexRadioDefault1" />
                                                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                            Yes
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check col-2 ms-2 col-md-1">
                                                                                        <input className="form-check-input" type="radio" value={false} onChange={(e) => collectAnswer(e, index)} name={'ans' + Q.indexNo} id="flexRadioDefault2" />
                                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                            No
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <br></br>
                                                            </>
                                                        })
                                                        : ''}
                                                </div>
                                                {/* } */}

                                            </div>
                                            <div class="modal-footer">
                                                <p className="text-danger">{Errorr ? Errorr : ''}</p>
                                                {jobDetails.JobRecruit_screenQustionNumber === 0 ? <>
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {/* <button id='btnNext1' className='' onClick={() => Nextone(true)} type="button" class="btn btn-primary">Next</button> */}
                                                    <button id='btnNext2' className='' type="submit" class="btn pro-spaces-button3">Finish</button>
                                                </> : <> <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <button id='btnNext1' className='' onClick={() => Nextone(true, currentIndex)} type="button" class="btn pro-spaces-button3">Next</button>
                                                    <button id='btnNext2' className='' type="submit" class="btn pro-spaces-button3" >Finish</button></>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div></div>
            </>
        )

    }
