import React, { useEffect, useState } from "react";
// import {Post} from "../../../Components/Post/Post"
import { Post } from "../propostPost/repost"
import Axios from 'axios'
import { PostToolBar } from "../postToolBarPro/postToolBar";
import { Postext } from "./repostext";
// import './Home.css'
import { SpinnerBig } from "../../Utility/spinner";


let page = 0
let fetchreq = false

export default function Propostlistext() {


    const [data, setdata] = useState([])
    const [state, setstate] = useState(false)//ini
    const [load, setload] = useState(true)
    const [error, seterror] = useState('')
    const [output, setoutput] = useState([])
    const [first, setfirst] = useState(1)
    function makeid(length) {
        length = 5
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    useEffect(() => {
        FetchMessage()
        return () => {
            FetchMessage('')
        }
    }, [])

    const [FetchStatus, setFetchStatus] = useState(false)
    const FetchMessage = (last) => {
        console.log('[[[[[[[[[[[[[[[[[[[[[[[[[')
        Axios.post("/api/Free/progetpost", { first }).then((response) => {
            setload(false)
            console.log(response)
            if (response.data.length > 0) {
                setfirst(first + response.data.length)
            }
            // console.log('success')
            // console.log(response.data)
            if (response.data.length === 0) {
                fetchreq = false
                return console.log('done')
            }
            const postSummaries = response.data.map((post) => ({
                id: post._id, key: post.id, Post_Id: post.Post_Id, Content: post.Discription,
                Author: post.User_id, File: post.Files, Posted_On: post.Posted_On,
                Commentcount: post.commentcount, merge: post.string, merge2: post.string2,
                merge3: post.subcat, com: post.com, Likecount: post.likecount,
                Likestatus: post.likestatus, profilepic: post.profilepic,
                titleA: post.titleA, titleB: post.titleB,User:post.User,type:post.type
            }));
            let t = postSummaries.map((number) => {
                return <Postext key={number.id} ids={makeid()} dat={number}></Postext>
            })
            fetchreq = false
            setload(false)
            setoutput([...output, ...t])
        }).catch(
            (res) => {
                fetchreq = false
                setload(false)
                setdata(false)
                seterror('Something went wrong')
                console.log('Something went wrong')
                setFetchStatus(false);
            }
        )
        setstate(true)
    }
    const FetchMessageOne = (last) => {
        console.log('[[[[[[[[[[[[[[[[[[[[[[[[[')
        let t = 1
        Axios.post("/api/ActiveUser/prolastgetpost", { t }).then((response) => {
            setload(false)
            console.log(response)
            if (response.data.length > 0) {
                setfirst(first + response.data.length)
            }
            // console.log('success')
            // console.log(response.data)
            if (response.data.length === 0) {
                fetchreq = false
                return console.log('done')
            }
            const postSummaries = response.data.map((post) => ({
                id: post._id, key: post.id, Post_Id: post.Post_Id, Content: post.Content,
                Author: post.Account_id, File: post.File, Posted_On: post.Posted_On,
                Commentcount: post.commentcount, merge: post.string, merge2: post.string2,
                com: post.com, Likecount: post.likecount, Likestatus: post.likestatus,
                profilepic: post.profilepic,
                titleA: post.titleA, titleB: post.titleB,User:post.User,type:post.type

            }));
            let t = postSummaries.map((number) => {
                return <Postext key={number.id} ids={makeid()} dat={number}></Postext>
            })
            fetchreq = false
            setload(false)
            setoutput([...t, ...output])
        }).catch(
            (res) => {
                fetchreq = false
                setload(false)
                setdata(false)
                seterror('Something went wrong')
                console.log('Something went wrong')
                setFetchStatus(false);
            }
        )
        setstate(true)
    }


    useEffect(() => {
        // console.log(first)
    }, [first])

    async function ScrollListener(e) {
        console.log('lllllllllllll')
        let P = Math.abs(e.target.scrollTop * 100 / (e.target.scrollHeight - e.target.clientHeight));
        P = Math.round(P);
        console.log(P)
        console.log(fetchreq)
        if (P > 50) {
            if (!fetchreq) {
                fetchreq = true
                console.log(page)
                FetchMessage();
                // setoutpot([...outpot, ...ans]);
            }
        }
    }



    // Loadpost()
    var loader = {
        width: '5rem',
        height: '5rem',
        color:'var(--blue)'
    }





const [Keyr, setKeyr] = useState(0)


    return (
        <div className="container-fluid  ">
            <div className="row" >
                <div className="col-3 d-none d-md-block "></div>
                <div id='postFt' onScroll={(e) => ScrollListener(e)} className="dfrty  pb-0 pt-5  mt-1 col-md-6 col-12 ijfsvwijiviwrvnvnjw">
                    {/* {localStorage.getItem('Type')==='inspiration.' || localStorage.getItem('Type')==='sponsored' ? 
        <PosttoolBar FetchMessageOne={FetchMessageOne} />: <div className="pt-3"></div>} */}



                    {output ? output : ''}

                    {load ?
                    <SpinnerBig />
                     : output.length === 0 && <div className='d-flex justify-content-center align-items-center' style={{'height':'calc(100vh - 70px)'}} ><h6  style={{'color':'var(--blue)'}}>No PRO- FEATURED CONSULTANTS to show !</h6></div>} 


                </div>
                <div className="col-3 d-none d-md-block "></div>
            </div>


            {/* <Lobby /> */}
        </div>
    )
}
