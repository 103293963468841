import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Navoutside } from "../Navoutside/Navoutside";
import axios from "axios";
import { NewNavigationBarDissabled } from "../../Pages/Free/NewNavigationBar/NewNavigationBarDissabled";

export function OutsideCat() {
  const [first, setfirst] = useState("");

  useEffect(() => {
    axios
      .get("/api/Api/getcategory", {})
      .then((res) => {
        // console.log(res)
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <NewNavigationBarDissabled />

      <div className="container-fluid">
        <div className="d-flex justify-content-end ">
          {/* <button onClick={(e)=>window.history.back()} className='btn pro-spaces-button  mt-3' >Go Back</button> */}
        </div>

        <div className="row mt-5 pt-5">
          {first
            ? first.map((element) => {
                console.log(element.sub);
                return (
                  <>
                    <div className="col-12 col-md-6  col-lg-6 col-xl-4 col-xxl-4 mb-3 ">
                      <div className="gtyurie p-3 ">
                        <div className="d-flex align-items-center">
                          <div className="ml-3 w-100">
                            <h4 className="mb-2 mt-0 pt-3 pb-3 center">
                              {element.category}
                            </h4>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
        </div>

        <div className="my-3 d-flex justify-content-center">
          <nav aria-label="Page navigation example ">
            <ul className="pagination">
              <li className="page-item">
                <span className="page-link">Previous</span>
              </li>
              <li className="page-item">
                <span className="page-link">1</span>
              </li>
              <li className="page-item">
                <span className="page-link">2</span>
              </li>
              <li className="page-item">
                <span className="page-link">3</span>
              </li>
              <li className="page-item">
                <span className="page-link">Next</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
