import { Link } from "react-router-dom"
import ActionAreaCard from "./movieTiles"
import axios from "axios"
import { useEffect, useRef, useState } from "react"

export const InnovationTlieList = () => {
    const [innovation, setInnovation] = useState([])
    useEffect(() => {

        axios.get('/api/api/GetInnovationCat').then((res) => {
            console.log(res)
            setInnovation(res.data)
        }).catch((error) => {
            console.log(error)
        })

    }, [])


    let stylecontainer = {
        position: 'relative',
        float: 'left',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

    }
    return <div className="row  g-1 p-2" style={{'marginTop':'80px'}}>

{localStorage.getItem("Type")==="sponsored" ?
<div> <div className="d-flex justify-content-end">
<Link to="/movies_and_innovation">
    <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh" >Add New</button>
    </Link>
       </div>
       </div>
:""} 




        {
            innovation.map((m) => {
                return  <div className="col-12 col-md-6  col-lg-6 col-xl-4 col-xxl-4  ">
                   
                    <div className="gtyurie p-3 ">
                        <Link to={`/innovation_show?type=${m._id}`
                        } className="linkoflink">
                            <div className="d-flex align-items-center">
                                <div className=" w-100">
                                    <h4 className="m-0 mt-2" style={stylecontainer}>{m.name}</h4>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

            })

        }




    </div >

}