import React, {
  useRef,

  forwardRef,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import ChatHeader from "./chatHeader";
import { ChatGround } from "./chatGround";


export const ChatRight = forwardRef((props, ref) => {
  const childFunc = React.useRef(null);
  const childRef = useRef(null);

  const ChangerToHeaderlite = () => {
    // console.log('reached')
    childRef.current.TheHeader("pass");
  };
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8  verticalStu  dkhs">
      <ChatHeader
        ref={childRef}
        force={props.force}
        socket={props.socket}
        NoMembers={props.NoMembers}
        viewTopicMember={props.viewTopicMember}
        Changetopic={props.Changetopic}
        TopicMembersAddView={props.TopicMembersAddView}
        ShowMember={props.ShowMember}
        GroupInvite={props.GroupInvite}
        CreateNewTopic={props.CreateNewTopic}
        LockTopic={props.LockTopic}
        RoomDetails={props.RoomDetails}
        Block={props.Block}
      />
      <ChatGround
        ReloadList={props.ReloadList}
        ChangerToHeader={ChangerToHeaderlite}
        Changetopic={props.Changetopic}
        force={props.force}
        socket={props.socket}
        RoomDetails={props.RoomDetails}
        childFunc={childFunc}
      />
    </div>
  );
});
