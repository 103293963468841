import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./ConSubcategory.css";

import { useSearchParams } from "react-router-dom";
import { SubCat } from "../ConSubcategory/SubCat";
import axios from "axios";
import { Mask } from "../../Utility/mask";
import ModalForThings from "../../Components/Modal/Modal";

export function ConSubcategory(props) {
  const handleClose = () =>
    setKnowThisService({ show: false, description: "" });
  const handleShow = (description) =>
    setKnowThisService({ show: true, description: description });
  const [knowThisService, setKnowThisService] = useState({
    show: false,
    description: "",
  });

  const [data, setdata] = useState(false);
  const [cat, setCat] = useState([]);
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  var cate = searchParams.get("category");
  useEffect(() => {
    /////getting id from the url the id of the jobs id
    var id = Mask.decode(searchParams.get("_id"));

    console.log(id);

    axios
      .post("/api/Free/subcategorycon", { id })
      .then((res) => {
        console.log(res.data);
        const postSummaries = res.data.map((post) => ({
          id: post._id,
          key: post.id,
          sub: post.sub,
          // num: post.num,
          cons: post?.cons[0]?.totalCount ? post?.cons[0]?.totalCount : 0,
          conss: post.conss.length,
          brand: post.brand.length,
          description: post.description,

          // brandUser:post.brand.
        }));
        setdata(postSummaries);
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });

    // axios.get('/api/User/subconcount?subid='+id).then((res)=>{
    //   console.log(res)
    //   setadhhzd(res)
    // }).catch((err)=>{console.log(err)})

    // console.log(con)

    // console.log(JSON.parse(id));
    // id = JSON.parse(id);
    // setCat(id);
    // console.log(id);
  }, []);

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {" "}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data) {
        setoutput(
          data.map((number) => {
            if (number.cons > 0) {
              return (
                <SubCat
                  key={number.id}
                  ids={makeid()}
                  data={number}
                  handleShow={handleShow}
                ></SubCat>
              );
            }
          })
        );
      } else {
        setoutput(error);
      }
    }
  }, [error, load]);

  const [Available, setAvailable] = useState([]);

  useEffect(() => {
    console.log(data);
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {" "}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data) {
        var temp = "";
        setoutput(
          data.map((number, ind) => {
            // let ind = 1
            if (number.cons > 0) {
              return (
                <SubCat
                  key={number.id}
                  index={ind}
                  ids={makeid()}
                  data={number}
                  handleShow={handleShow}
                ></SubCat>
              );
            }
          })
        );
      } else {
        setoutput(error);
      }
    }
  }, [error, load]);
  let alpha = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
  const [One, setOne] = useState("");
  const controller = (e) => {
    if (One === e.target.value) {
      setOne("");
    } else {
      setOne(e.target.value);
    }
  };
  useEffect(() => {
    if (data.length)
      if (One.length === 0) {
        setoutput(
          data.map((number, ind) => {
            // let ind = 1
            if (number.cons > 0) {
              return (
                <SubCat
                  key={number.id}
                  index={ind}
                  ids={makeid()}
                  data={number}
                  handleShow={handleShow}
                ></SubCat>
              );
            }
          })
        );
      } else {
        let temp = [...data];
        let render = temp.filter((ev) => {
          console.log(ev.sub);
          let reg = new RegExp(`^${One}`, "i");
          console.log(reg);
          if (ev.sub.match(reg)) {
            return ev;
          } else {
          }
        });
        console.log(render);
        setoutput(
          render.map((number, ind) => {
            // let ind = 1
            if (number.cons > 0) {
              return (
                <SubCat
                  key={number.id}
                  index={ind}
                  ids={makeid()}
                  data={number}
                ></SubCat>
              );
            }
          })
        );
      }
    return () => {};
  }, [One]);

  var catlength = data.length;

  return (
    <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
      <div className={`row ${load === false ? "" : "notranslate"}`}>
        <center>
          <h5 className="parapara">
            SUB CATEGORIES
            {/* ({catlength}) */}
          </h5>
        </center>
        <center>
          <p>{cate}</p>
        </center>
        <center>
          <div className="p-2">
            <div className="AlphaNav border grid-containerX p-1 m-0">
              {alpha.map((elem, index) => {
                return (
                  <div id="ck-button">
                    <label>
                      <input
                        onChange={(e) => controller(e)}
                        checked={elem === One ? true : false}
                        value={elem}
                        style={{ textTransform: "capitalize" }}
                        href={`#Cat-` + elem.toUpperCase() + "0"}
                        type="checkbox"
                      ></input>
                      <span className="forntweightreducer">{elem}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </center>
        {output}
      </div>
      <ModalForThings
        knowThisService={knowThisService}
        handleClose={handleClose}
        title="KNOW THIS SERVICE"
      />
    </div>
  );
}
