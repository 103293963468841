import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./NewNavigationBar.css";

import { Notification } from "../../../Components/Notifications/Notification";

import { AiFillSetting, AiOutlineClose, AiOutlineLogin } from "react-icons/ai";
import {
  AiFillHome,
  AiFillRobot,
  AiOutlineMenu,
  AiOutlineExclamationCircle,
  AiOutlineFileSearch,
  AiFillInfoCircle,
} from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import {
  MdFeaturedPlayList,
  MdPlaylistAddCircle,
  MdProductionQuantityLimits,
  MdScience,
  MdVolunteerActivism,
  MdLanguage,
  MdFeed,
} from "react-icons/md";
import { GrMail, GrLogin } from "react-icons/gr";
import { GiShoppingBag, GiTrophyCup, GiAwareness } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";
import { GrEmergency } from "react-icons/gr";
import { SiContactlesspayment } from "react-icons/si";
import { RiToolsFill } from "react-icons/ri";
import {
  BsFillPersonCheckFill,
  BsInfoLg,
  BsFillBellFill,
  BsFillFileEarmarkPersonFill,
  BsFillBriefcaseFill,
} from "react-icons/bs";
import { IoMdColorPalette } from "react-icons/io";
import { IoClose, IoLocationSharp, IoSettingsSharp } from "react-icons/io5";
import { FiLogIn } from "react-icons/fi";
import { TiInfoLarge } from "react-icons/ti";
import { HiLightBulb } from "react-icons/hi";
import { TbListSearch } from "react-icons/tb";
import { Speaker } from "../../../Components/speaker/speaker";
import QuickLocation from "../../../Components/QuickLocation/QuickLocation";

export function NewNavigationBar(props) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [state, setstate] = useState(true);
  let navigate = useNavigate();

  const notify = (message) => {
    toast.warning(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const linkStyle = {
    textDecoration: "none",
    color: "white",
  };

  const logout = (e) => {
    axios
      .post("/api/Logout")
      .then((res) => {
        // alert('logout')
        localStorage.clear();
        return navigate("/signin");
      })
      .catch((re) => {});
  };

  function openNav() {
    if (state) {
      setstate(false);
      document.getElementById("mySidenav").style.width = "250px";
    } else {
      setstate(true);
      document.getElementById("mySidenav").style.width = "0";
    }
  }

  const ShowAccounts = () => {
    props.ShowAccounts();
  };

  const [first, setfirst] = useState(false);

  const handleMessage = (event) => {
    if (event.data === "closeModal") {
      try {
        setTimeout(() => {
          document.getElementById("exampleModaliframeregisterbtn").click();
        }, 2000);
      } catch (error) {}
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    axios
      .get("/api/User/getnotifoenav")
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setfirst(true);
        } else {
          setfirst(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sabari = (e) => {
    openNav();
    var Notiview = 1;
    axios
      .post("/api/User/updatenoti", {
        Notiview,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const openNavlan = () => {
    document.getElementById("googleTommyBtn").click();
    openNav();
  };

  const checkerkuttapan = () => {
    axios
      .get("/api/ActiveUser/accountswitch")
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200) {
          if (res.data.length >= 15) {
            notify("You have reached the maximum limit of profiles");
          } else {
            navigate("/new_profile");
          }
        }
      })
      .catch((res) => {});
  };

  const changeLanButtonHanddler = () => {
    document.getElementById("googleTommyBtn").click();
  };

  const navsabari = () => {
    openNav();
    document
      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubtapppolicies")
      .click();
  };

  return (
    <>
      <nav class="navbar newNavBackground ">
        <div class="container-fluid">
          <div className="eihfiwfhih navbar-brand ">
            <Link to="/lobby/" style={{ textDecoration: "none" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                color="fff"
                width="240"
                viewBox="0 0 906 258"
              >
                <defs></defs>
                <text
                  id="PRO-SPACES"
                  className="cls-8"
                  transform="translate(37 155.05) scale(1.172 1.069)"
                >
                  PROchure
                  <tspan font-size="0.5em" baseline-shift=".8em">
                    ®
                  </tspan>
                </text>
              </svg>
            </Link>
            <span style={{ marginLeft: "-75px" }}>
              <Speaker />
            </span>
          </div>
          <form class="toogleButton">
            <button
              className="btn pe-2 pt-0 ps-0"
              // data-bs-dismiss="modal" aria-label="Close"
              type="button"
              id="sabarivrtst"
              onClick={() => openNav()}
            >
              {" "}
              {state ? (
                <AiOutlineMenu size="30" className="floattog" />
              ) : (
                <AiOutlineClose size="30" className="floattog" />
              )}
            </button>
          </form>
        </div>
      </nav>

      <div id="mySidenav" className="sidenav">
        {/* <div className='d-flex justify-content-end pe-3 closeinto' ><AiOutlineClose onClick={() => openNav()} /></div> */}

        {/* <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#exampleModalsabariregister"><AiOutlineLogin className="sidenavicon" /></a></div><div className="col-10 p-0 mt-2"><a data-bs-toggle="modal" data-bs-target="#exampleModalsabariregister" id='eibfiegfkakwfhwofhowfhocnwkf' className='pe-0 pt-2' onClick={() => openNav()}>&nbsp;REGISTER&nbsp;&nbsp;&nbsp;|</a><Link to="/signin" id='eibfiegfxcckakwfhwofhowfhocnwkf' className='pe-0 pt-2' onClick={() => openNav()}>SIGN&nbsp;IN</Link></div></div>   </div>
        </div> */}
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaliframeregister"
                  onClick={() => openNav()}
                >
                  <TbListSearch className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaliframeregister"
                  onClick={() => openNav()}
                >
                  &nbsp;CREATE FREE LISTING
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalsabariregister"
                  onClick={() => openNav()}
                >
                  <AiOutlineLogin className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalsabariregister"
                  onClick={() => openNav()}
                >
                  &nbsp;GET PAID MEMBERSHIP
                </a>
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/signin" onClick={() => openNav()}>
                  <FiLogIn className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/signin" onClick={() => openNav()}>
                  &nbsp;SIGN IN / LOG IN
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/lobby/" onClick={() => openNav()}>
                  <AiFillHome className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/lobby/" onClick={() => openNav()}>
                  &nbsp;LOBBY / PRO-FORUM
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/lobby/virtual_secretary" onClick={() => openNav()}>
                  <AiFillRobot className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/lobby/virtual_secretary" onClick={() => openNav()}>
                  &nbsp;VIRTUAL SECRETARY
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  id="quicksettingmodalatag"
                  data-bs-toggle="modal"
                  data-bs-target="#quickSetings"
                  onClick={() => openNav()}
                >
                  <IoSettingsSharp className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#quickSetings"
                  onClick={() => openNav()}
                >
                  &nbsp;QUICK SETTINGS
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          <center>information.&nbsp;&nbsp;&nbsp;interaction.</center>
        </div>

        <div className="row mt-1">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link
                  to="/lobby/services_categories"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  <BsFillPersonCheckFill className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link
                  to="/lobby/services_categories"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  &nbsp;SERVICES
                </Link>
              </div>
              {/* <div className="col-5 p-0 mx-0 hai">
                <span className="invisible">abcdefghijklmnop</span>&nbsp;
                <AiOutlineExclamationCircle
                  style={{ marginTop: "-58px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalsabari2wekjfg"
                  onClick={() => openNav()}
                />
              </div> */}
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link
                  to="/lobby/products_categories"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  <GiShoppingBag className="sidenavicon" />
                </Link>
              </div>
              <div className="col-5 p-0 mx-0 hai">
                <Link
                  to="/lobby/products_categories"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  &nbsp;PRODUCTS
                </Link>
              </div>
              {/* <div className="col-5 p-0 mx-0 hai">
                <span className="invisible">abcdefghijklm</span>
                <AiOutlineExclamationCircle
                  style={{ marginTop: "-45px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModefveelkfnvalsabari2wekjfg"
                  onClick={() => openNav()}
                />
              </div> */}
            </div>{" "}
          </div>
        </div>
        {/* <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#achiversmodal1" onClick={() => openNav()}><TiInfoLarge className="sidenavicon" /></a></div><div className="col-4 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#achiversmodal1" onClick={() => openNav()}>&nbsp;UTILITIES & EMERGENCY</a></div></div>   </div>
        </div>
        <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#achiversmodal1" onClick={() => openNav()}><TiInfoLarge className="sidenavicon" /></a></div><div className="col-4 p-0 mx-0 hai"><a data-bs-toggle="modal" data-bs-target="#achiversmodal1" onClick={() => openNav()}>&nbsp;GENERAL</a></div></div>   </div>
        </div> */}

        {/* <hr />
        <div className='row mt-2'><center>interaction.</center></div> */}
        {/* <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><a><MdFeed size={'35'} className="sidenavicon fvuohf" /></a></div><div className="col-4 p-0 mx-0 hai"><a>&nbsp;PRO-FORUM</a></div></div>   </div>
        </div> */}
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link
                  to="/lobby/Information_Category"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  <TiInfoLarge size={35} className="sidenavicon" />
                </Link>
              </div>
              <div className="col-5 p-0 mx-0 hai">
                <Link
                  to="/lobby/Information_Category"
                  style={{ width: "min-content" }}
                  onClick={() => openNav()}
                >
                  &nbsp;GENERAL&nbsp;INFORMATION
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalsabaricubicle"
                  onClick={() => openNav()}
                >
                  <img
                    src="/whitegold.png"
                    width="35px"
                    height="15px"
                    className="sidenavicon"
                  />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai" onClick={() => openNav()}>
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalsabaricubicle"
                >
                  &nbsp;CUBICLES
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a onClick={() => openNav()}>
                  <BsFillBriefcaseFill className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a onClick={() => openNav()}>&nbsp;JOBS</a>
              </div>
            </div>{" "}
          </div>
        </div>
        {/* <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><Link to="/lobby/pro-forum" onClick={() => openNav()}><MdFeed size={'35'} className="sidenavicon fvuohf" /></Link></div><div className="col-4 p-0 mx-0 hai"><Link to="/lobby/pro-forum" onClick={() => openNav()}>&nbsp;PRO-FORUM</Link></div></div>   </div>
        </div> */}
        <hr />
        <div className="row mt-2">
          <center>tools</center>
        </div>

        <div className="row mt-1">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/lobby/quick_fix" onClick={() => openNav()}>
                  <RiToolsFill size={"35"} className="sidenavicon fvuohf" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/lobby/quick_fix" onClick={() => openNav()}>
                  &nbsp;QUICK FIX
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/lobby/suggestions" onClick={() => openNav()}>
                  <GrMail className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/lobby/suggestions" onClick={() => openNav()}>
                  &nbsp;ASK US
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          <center>about !</center>
        </div>
        <div className="row mt-1">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/lobby/inception" onClick={() => openNav()}>
                  <HiLightBulb className="sidenavicon " />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/lobby/inception" onClick={() => openNav()}>
                  &nbsp;INCEPTION
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#appoverviewmodal"
                  onClick={() => openNav()}
                >
                  <AiOutlineFileSearch className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#appoverviewmodal"
                  onClick={() => openNav()}
                >
                  &nbsp;APP OVERVIEW
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        {/* <div className="row mt-2">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><Link to="/about_us" onClick={() => openNav()}><AiOutlineFileSearch className="sidenavicon" /></Link></div><div className="col-4 p-0 mx-0 hai"><Link to="/about_us" onClick={() => openNav()}>&nbsp;ABOUT US</Link></div></div>   </div>
        </div> */}
        {/* <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <Link to="/lobby/user_features" onClick={() => openNav()}>
                  <BsFillFileEarmarkPersonFill className="sidenavicon" />
                </Link>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <Link to="/lobby/user_features" onClick={() => openNav()}>
                  &nbsp;USER FEATURES
                </Link>
              </div>
            </div>{" "}
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col"><div className="row  container-fluid"><div className="col-2 p-0 mx-0 hai"><Link to="/lobby/subscription_fees" onClick={() => openNav()}><SiContactlesspayment size={'35'} className="sidenavicon fvuohf" /></Link></div><div className="col-4 p-0 mx-0 hai"><Link to="/lobby/subscription_fees" onClick={() => openNav()}>&nbsp;SUBSCRIPTION, AD FEES</Link></div></div>   </div>
        </div> */}
        <div className="row ">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#subscrptionfeesmodal"
                  onClick={() => openNav()}
                >
                  <SiContactlesspayment size={"35"} className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#subscrptionfeesmodal"
                  onClick={() => openNav()}
                >
                  &nbsp;SUBSCRIPTION FEES
                </a>
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a data-bs-toggle="modal" data-bs-target="#apppolicies">
                  <MdFeaturedPlayList
                    size={"35"}
                    className="sidenavicon fvuohf"
                  />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a data-bs-toggle="modal" data-bs-target="#apppolicies">
                  &nbsp;APP POLICIES
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <div className="row  container-fluid">
              <div className="col-2 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#subscrptionfeesmodal"
                  onClick={() => openNav()}
                >
                  <GiAwareness className="sidenavicon" />
                </a>
              </div>
              <div className="col-4 p-0 mx-0 hai">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#beawaremodal"
                  onClick={() => openNav()}
                >
                  &nbsp;BE AWARE (DISCLAIMER)
                </a>
              </div>
            </div>{" "}
          </div>
        </div>
        <hr />

        <div className="row poprjpgvijspjva pt-4 pb-3">
          <center>
            <img
              src="/FOOTERPROCHURE.svg"
              style={{ width: "200px", height: "75px" }}
            />
          </center>
        </div>
      </div>

      {state ? (
        <div className="poooooperkechu d-none"></div>
      ) : (
        <div className="poooooperkechu d-block" onClick={() => openNav()}></div>
      )}

      {/* Modalsss */}

      <div
        className="offcanvas offcanvas-end weiuogweiutieufwhgiw"
        data-bs-scroll="false"
        tabindex="-1"
        id="staticBackdrop"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">NOTIFICATIONS</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <Notification />
        </div>
      </div>

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="apppolicies"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubtapppolicies"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Link
                    to="/lobby/Termsofuse"
                    className="dropdown-item"
                    onClick={(e) => navsabari()}
                  >
                    Terms of Use
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to="/lobby/Privacy"
                    className="dropdown-item"
                    onClick={(e) => navsabari()}
                  >
                    Privacy Policy
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to="/lobby/cancellation"
                    className="dropdown-item"
                    onClick={(e) => navsabari()}
                  >
                    Cancellation / Refund Policy
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to="/lobby/Disclaimer"
                    className="dropdown-item"
                    onClick={(e) => navsabari()}
                  >
                    Disclaimer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="nomodalforsabaribtn"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#nomodalforsabari"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade "
        style={{ zIndex: "10000000" }}
        id="nomodalforsabari"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          style={{ marginTop: "40px" }}
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="row p-3 pb-1">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxohfyfutfuougobgoeuuobvbetuovoubt"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div>
                <center>
                  <h4 className="aldfhahfoiheaf shgoshigpiscmgih jrgfrjflobjgt notranslate">
                    PROchure<sup>®</sup>
                  </h4>
                </center>
              </div>
              <div
                className="w-100 p-2 mt-2 mb-2"
                style={{ backgroundColor: "#55374a", color: "white" }}
              >
                <center>
                  an e-brochure of
                  professional&nbsp;services&nbsp;and&nbsp;products
                </center>
              </div>

              <div className="p-3">
                <center>
                  <span className="jrgfrjflobjgt notranslate">PROchure </span>is
                  available in English, 17 Indian and 18 International Languages
                </center>
                <br />
                {/* <center>If you are A&nbsp;Professional Consultant&nbsp;/ An&nbsp;Artist&nbsp;/ A&nbsp;Woman Entrepreneur&nbsp;/ A&nbsp;Service Provider&nbsp;/ A&nbsp;Product Vendor, REGISTER yourself as a Active / Pro-Active&nbsp;member.<p>( To View Categories, Go to Consultants and Vendors on the navigation bar )</p></center><br />
     
     <center>If you are A&nbsp;Prospective Client&nbsp;/&nbsp;Customer looking for Consultants and Vendors for their services and products, REGISTER yourself as a Passive&nbsp;member.</center><br />
   
   <center>Be a PRO- member....Be Professional !</center><br /> */}

                {/* <center>So <Link to="/registration" className='aldfhahfoiheaf shgoshigpiscmgih' onClick={(e)=>document.getElementById('wjrbgkvbwlvbvxohfyfutfuougobgoeuuobvbetuovoubt').click()}>GET STARTED !</Link></center> */}
                <center>
                  <span
                    className="aldfhahfoiheaf shgoshigpiscmgih"
                    onClick={changeLanButtonHanddler}
                    data-bs-dismiss="modal"
                  >
                    Click here to CHANGE LANGUAGE
                  </span>
                </center>
                <br />
                <center>
                  <span
                    className="aldfhahfoiheaf shgoshigpiscmgih"
                    data-bs-target="#exampleModalsabariregister"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    NEW REGISTRATION
                  </span>
                </center>
              </div>
              <hr />
              {/* Click here for a one day free trial */}
              {/* <hr /> */}
              <div className="p-3 pt-0 pb-0">
                <center>
                  I am a{" "}
                  <span className="aldfhahfoiheaf shgoshigpiscmgih jrgfrjflobjgt">
                    PRO-
                  </span>{" "}
                  member...{" "}
                  <Link
                    to="/signin"
                    className="aldfhahfoiheaf shgoshigpiscmgih"
                    onClick={(e) =>
                      document
                        .getElementById(
                          "wjrbgkvbwlvbvxohfyfutfuougobgoeuuobvbetuovoubt"
                        )
                        .click()
                    }
                  >
                    SIGN IN
                  </Link>
                </center>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button className='d-none' id="exampleModalsabaribtt" ></button> */}
      <div
        className="modal fade"
        id="exampleModalsabari"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "1000000000" }}
      >
        <div className="modal-dialog  modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="row p-3">
                <div className="d-flex justify-content-end ">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubt"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <center className="ps-3 pe-3">
                <Link
                  to="/registration"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt")
                      .click()
                  }
                >
                  Click here to <b>REGISTER</b> as a SERVICE PROVIDER / PRODUCT
                  RETAILER{" "}
                  <span style={{ color: "black" }}>
                    (lower age limit : 18 years)
                  </span>
                </Link>
              </center>
              <br />
              <center className="ps-3 pe-3">
                <Link
                  to="/register"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt")
                      .click()
                  }
                >
                  Click here to <b>REGISTER</b> as a CLIENT / CUSTOMER{" "}
                  <span style={{ color: "black" }}>
                    (lower age limit : 18 years)
                  </span>
                </Link>
              </center>
              <br />
              <div
                className="w-100 p-2 mt-2 mb-2"
                style={{ backgroundColor: "#55374a", color: "white" }}
              >
                <center>
                  Be a <span className="jrgfrjflobjgt">PRO-</span> member…… Be
                  Professional !
                </center>
              </div>
              <center className="ps-3 pe-3 pb-3 pt-3">
                I am a{" "}
                <span className="aldfhahfoiheaf shgoshigpiscmgih jrgfrjflobjgt">
                  PRO-
                </span>{" "}
                member...{" "}
                <Link
                  to="/signin"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt")
                      .click()
                  }
                >
                  SIGN IN
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalsabari2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body ps-0 pe-0">
              <div className="row p-2">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubt2"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <center className="pt-2">
                <h5 style={{ color: "#55374a" }}>CONSULTANTS</h5>
                <p>
                  Qualified Professionals, Artists, Academicians, all
                  Technically and Technologically Knowledgeable Persons and
                  Service Providers, and Commercial Service Providers across
                  industries
                </p>
              </center>
              <center className="ps-3 pe-3">
                <Link
                  to="/services_categories"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt2")
                      .click()
                  }
                >
                  VIEW CATEGORIES
                </Link>{" "}
              </center>
              <hr />
              <center className="ps-3 pe-3">
                <Link
                  to="/registration"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt2")
                      .click()
                  }
                >
                  Click here to <b>REGISTER</b> as a CONSULTANT{" "}
                  <span style={{ color: "black" }}>
                    (lower age limit : 18 years)
                  </span>
                </Link>
              </center>
              <br />
              <center className="ps-3 pe-3">
                <Link
                  to="/register"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt2")
                      .click()
                  }
                >
                  Click here to <b>REGISTER</b> as a CLIENT / CUSTOMER{" "}
                  <span style={{ color: "black" }}>
                    (lower age limit : 18 years)
                  </span>
                </Link>
              </center>
              <br />
              <div
                className="w-100 p-2 mt-2 mb-2"
                style={{ backgroundColor: "#55374a", color: "white" }}
              >
                <center>
                  Be a <span className="jrgfrjflobjgt">PRO-</span> member…… Be
                  Professional !
                </center>
              </div>
              <center className="ps-3 pe-3">
                I am a{" "}
                <span className="aldfhahfoiheaf shgoshigpiscmgih jrgfrjflobjgt">
                  PRO-
                </span>{" "}
                member...{" "}
                <Link
                  to="/signin"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt2")
                      .click()
                  }
                >
                  SIGN IN
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalsabari3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body ps-0 pe-0">
              <div className="row p-3">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <center className="pt-2">
                <h5 style={{ color: "#55374a" }}>VENDORS</h5>
                <p>
                  Builders and Developers, Product Wholesalers and Retailers,
                  Art Dealers
                </p>
              </center>
              <center className="ps-3 pe-3">
                <Link
                  to="/products_categories"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3")
                      .click()
                  }
                >
                  VIEW CATEGORIES
                </Link>{" "}
              </center>
              <hr />
              <center className="ps-3 pe-3">
                <Link
                  to="/registration"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3")
                      .click()
                  }
                >
                  Click here to <b>REGISTER</b> as a VENDOR{" "}
                  <span style={{ color: "black" }}>
                    (lower age limit : 18 years)
                  </span>
                </Link>
              </center>
              <br />
              <center className="ps-3 pe-3">
                <Link
                  to="/register"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3")
                      .click()
                  }
                >
                  Click here to <b>REGISTER</b> as a CLIENT / CUSTOMER{" "}
                  <span style={{ color: "black" }}>
                    (lower age limit : 18 years)
                  </span>
                </Link>
              </center>
              <br />
              <div
                className="w-100 p-2 mt-2 mb-2"
                style={{ backgroundColor: "#55374a", color: "white" }}
              >
                <center>
                  Be a <span className="jrgfrjflobjgt">PRO-</span> member…… Be
                  Professional !
                </center>
              </div>
              <center className="ps-3 pe-3">
                I am a{" "}
                <span className="aldfhahfoiheaf shgoshigpiscmgih jrgfrjflobjgt">
                  PRO-
                </span>{" "}
                member...{" "}
                <Link
                  to="/signin"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3")
                      .click()
                  }
                >
                  SIGN IN
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalsabaricubicle"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "1000000000" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body ps-0 pe-0">
              <div className="row p-3">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3saba"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <center className="pt-2">
                <h5 style={{ color: "#55374a" }}>CUBICLES</h5>
                <p>
                  CUBICLES are chat rooms for project / product /
                  service-related discussions between members
                </p>
              </center>
              <center>
                You need to be a{" "}
                <span className="aldfhahfoiheaf shgoshigpiscmgih jrgfrjflobjgt">
                  PRO-
                </span>{" "}
                member to move forward
              </center>
              <br />
              <center className="ps-3 pe-3">
                <Link
                  to="/registration"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3saba")
                      .click()
                  }
                >
                  Click here to <b>REGISTER</b> as a SERVICE PROVIDER / PRODUCT
                  RETAILER{" "}
                  <span style={{ color: "black" }}>
                    (lower age limit : 18 years)
                  </span>
                </Link>
              </center>
              <br />
              <center className="ps-3 pe-3">
                <Link
                  to="/register"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3saba")
                      .click()
                  }
                >
                  Click here to <b>REGISTER</b> as a CLIENT / CUSTOMER{" "}
                  <span style={{ color: "black" }}>
                    (lower age limit : 18 years)
                  </span>
                </Link>
              </center>
              <br />
              <div
                className="w-100 p-2 mt-2 mb-2"
                style={{ backgroundColor: "#55374a", color: "white" }}
              >
                <center>
                  Be a <span className="jrgfrjflobjgt">PRO-</span> member…… Be
                  Professional !
                </center>
              </div>
              <center className="ps-3 pe-3">
                I am a{" "}
                <span className="aldfhahfoiheaf shgoshigpiscmgih jrgfrjflobjgt">
                  PRO-
                </span>{" "}
                member...{" "}
                <Link
                  to="/signin"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("wjrbgkvbwlvbvxobgoeuuobvbetuovoubt3saba")
                      .click()
                  }
                >
                  SIGN IN
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalsabariregister"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "1000000000" }}
      >
        <div className="modal-dialog  modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="exampleModalsabariregisterbtn"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              {/* <center><h4 style={{'color':'#55374a'}}>REGISTER as a</h4></center><br /> */}
              <center>
                <Link
                  to="/pro-active_register"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("exampleModalsabariregisterbtn")
                      .click()
                  }
                >
                  CREATE a BRAND &amp; BUSINESS PROFILE
                </Link>
              </center>
              <br />
              <center>OR</center>
              <br />
              <center>
                <Link
                  to="/passive_register"
                  className="aldfhahfoiheaf shgoshigpiscmgih"
                  onClick={(e) =>
                    document
                      .getElementById("exampleModalsabariregisterbtn")
                      .click()
                  }
                >
                  REGISTER as a CLIENT, CUSTOMER
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModaliframeregister"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "1000000000" }}
      >
        <div className="modal-dialog  modal-fullscreen ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="exampleModaliframeregisterbtn"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div>
                <iframe
                  className="w-100"
                  style={{ width: "100%", height: "95dvh" }}
                  src="https://www.vipar-connect.in/prochure_free_listing"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="quickSetings"
        aria-hidden="true"
        aria-labelledby="quickSetingsLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal-content"
            style={{ backgroundColor: "var(--blue)", color: "white" }}
          >
            <div class="modal-body">
              <div class="row d-flex justify-content-between">
                <div className="col-auto">
                  <h5>QUICK SETTINGS</h5>
                </div>
                <div className="col-auto">
                  <IoClose
                    size={25}
                    className="float-end"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
              </div>
              <br />
              <div className="row pb-2">
                <div className="col-12">
                  <a
                    data-bs-target="#googleTommy"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    &nbsp;&nbsp;
                    <MdLanguage className="sidenavicon" />
                    &nbsp;&nbsp;SELECT LANGUAGE
                  </a>
                </div>
                <div className="col-12">
                  &nbsp;&nbsp;
                  <IoLocationSharp className="sidenavicon" />
                  &nbsp;&nbsp;SELECT LOCATION
                  {/* <QuickLocation /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
