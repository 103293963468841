import { red } from 'react-color/lib/helpers/color';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Settings } from './PassiveAccountSettings';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Moment from 'moment';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Invoice_Download from '../../Active/ActiveAccountsettings/Invoice_Download';
import Add_Invoice_Download from '../../Active/ActiveAccountsettings/Add_Invoice_Download';
import { Deleteaccount } from '../../../Components/Delete/Deleteaccount';

export const Inital = (props) => {
  const SettingsContext = useContext(Settings);
  const [Details, setDetails] = useState('');
  const [LoginId, setLoginId] = useState();
  const [PrimeOTP, setPrimeOTP] = useState();
  const [PrimeOTPlite, setPrimeOTPlite] = useState();
  const [Loading, setLoading] = useState(false); // => LoginModal
  const [Loading2, setLoading2] = useState(false); // => Login change
  const [Loading3, setLoading3] = useState(false); // => Email**COntact
  const [chat, setchat] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [ListAccounts1, setListAccounts1] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    if (localStorage.getItem('Chat') === 'true') {
      setchat(true);
      console.log('first');
    }
    console.log('status', chat);
    console.log('loc', localStorage.getItem('Chat'));

    return () => {};
  }, []);
  const Loader = (
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  );

  const Inital = () => {
    axios
      .get('/api/User/poookuttti')
      .then((res) => {
        console.log(res);
        setDetails(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    Inital();
  }, []);
  const notify = (message, stat) => {
    console.log(message);

    if (stat === 'success') {
      console.log('toasfted');
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat === 'danger') {
      toast.error(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {
    console.log(Details);
  }, [Details]);

  const updateEmail = async () => {
    setLoading3(true);
    let Emailelem = document.getElementById('emailasdasdasfgsrg22');
    let Passwordelem = document.getElementById('LoginIdxUpdsatedxTrtr');

    let EmailNew = Emailelem.value;
    let Password = Passwordelem.value;
    let dataSend = {
      EmailNew,
      Password,
    };
    let t = await axios
      .post('/api/AuthenticatedUser/UpdateEmail', { dataSend })
      .then((res) => {
        notify('Email Updated', 'success');
        // Inital()
        props.setReloader(props.Reloader + 1);
        Emailelem.value = '';
        Passwordelem.value = '';
      })
      .catch((err) => {
        console.log(err);
        notify('Something went wrong !', 'danger');
      });
    setLoading3(false);
  };

  const updateContact = async () => {
    setLoading3(true);
    let Phoneelem = document.getElementById('wifgwiwifgwifdufgdugfwf');
    let Passwordelem = document.getElementById('wifgwifdufgdugfw');

    let PhoneNew = Phoneelem.value;
    let Password = Passwordelem.value;
    let dataSend = {
      PhoneNew,
      Password,
    };
    let t = await axios
      .post('/api/AuthenticatedUser/updateNumber', { dataSend })
      .then((res) => {
        notify('Phone Number Updated', 'success');
        // Inital()
        props.setReloader(props.Reloader + 1);
        Phoneelem.value = '';
        Passwordelem.value = '';
      })
      .catch((err) => {
        console.log(err.response.data);
        notify(err.response.data, 'danger');
      });
    setLoading3(false);
  };

  const LoaderLite = () => {
    let loger = Details.EmailLogin ? Details.Email : Details.Contact;
  };

  const updateLoginId = async () => {
    setLoading2(true);
    let LoginIdelem = document.getElementById('skjfjehuiweyuy54y5io232255');
    let Passwordelem = document.getElementById('wjberhjwegrhwegrui34544445');

    let LoginId = LoginIdelem.value;
    let Password = Passwordelem.value;
    setLoginId(LoginId);
    let dataSend = {
      LoginId,
      Password,
    };
    console.log(dataSend);
    LoaderLite();
    let t = await axios
      .post('/api/AuthenticatedUser/UpdateLogin', { dataSend })
      .then((res) => {
        document.getElementById('LoginIdModalTrigger').click();
        notify('OTP Send successful !', 'success');
        // LoginIdelem.value = ''
        // Passwordelem.value = ''
      })
      .catch((err) => {
        console.log(err.response.data);
        notify(err.response.data, 'danger');
      });
    setLoading2(false);
  };
  const EmailTemplete = (
    <>
      {' '}
      <div className="list-group-item">
        <div className="row align-items-center">
          <div className="col">
            <strong className="mb-2">
              {' '}
              Email Id : {Details.Email && Details.Email}
            </strong>
          </div>
          <div className="col-auto">
            <button
              className="btn pro-spaces-button"
              data-bs-toggle="collapse"
              data-bs-target="#email"
            >
              Change
            </button>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col">
          <div id="email" className="collapse bg-light  p-3">
            <input
              id="LoginIdxUpdsatedxTrtr"
              type="text"
              placeholder="Enter your password"
              className="form-control my-4"
            />
            <input
              id="emailasdasdasfgsrg22"
              type="text"
              placeholder="New Email Id"
              className="form-control my-4"
            />
            <div className="d-grid p-2">
              <button
                className="btn pro-spaces-button2 btn-block "
                onClick={(e) => updateEmail()}
              >
                {Loading3 ? <>Updating {Loader}</> : <>Update</>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const PhoneTemplete = (
    <>
      <div className="list-group-item">
        <div className="row align-items-center">
          <div className="col">
            <strong className="mb-2">
              {' '}
              Phone Number : {Details.Contact && Details.Contact}
            </strong>
          </div>
          <div className="col-auto">
            <button
              className="btn pro-spaces-button"
              data-bs-toggle="collapse"
              data-bs-target="#phone"
            >
              Change
            </button>
          </div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col">
          <div id="phone" className="collapse bg-light  p-3">
            <h6 style={{ color: 'red' }}>
              *The new Mobile Number / Email Id will be your default Login Id
              and will be used for OTPs in the future
            </h6>
            <input
              type="text"
              placeholder="Enter your password"
              className="form-control my-4"
              id="wifgwifdufgdugfw"
            />
            <input
              type="number"
              placeholder="New Phone Number"
              className="form-control my-4"
              id="wifgwiwifgwifdufgdugfwf"
            />
            <p className="text-danger" id="err2"></p>
            <div className="d-grid p-2">
              <button
                className="btn pro-spaces-button2 btn-block "
                // data-bs-toggle="modal"
                // data-bs-target="#phonemodal"
                onClick={(e) => updateContact(e)}
              >
                {Loading3 ? <>Updating {Loader}</> : <>Update</>}
              </button>
              <button
                className="invisible"
                id="gchkjbhlkbnkmnlknkjvkb"
                data-bs-toggle="modal"
                data-bs-target="#phonemodal"
              ></button>
              <div className="modal" id="phonemodal">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title"> Phone Number</h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <h3>Enter OTP sent to new Phone Number</h3>
                      <input
                        id="Otpy"
                        type="text"
                        className="form-control"
                        placeholder="OTP"
                      />
                    </div>
                    <p id="errrTopSec" className="text-danger ps-2"></p>
                    <div className="modal-footer">
                      <button
                        id="LoginIadxUpdated"
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={(e) => props.updateMe(e)}
                        className="btn pro-spaces-button"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const PLogin = (
    <>
      {' '}
      <div className="list-group-item">
        <div className="row align-items-center">
          <div className="col">
            <strong className="mb-2">
              {' '}
              Login Id : {Details.Contact && Details.Contact}
            </strong>
          </div>
          <div className="col-auto">
            <button
              className="btn pro-spaces-button"
              data-bs-toggle="collapse"
              data-bs-target="#phone"
            >
              Change
            </button>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col">
          <div id="phone" className="collapse bg-light  p-3">
            <h6 style={{ color: 'red' }}>
              *The new Mobile Number / Email Id will be your default Login Id
              and will be used for OTPs in the future
            </h6>
            <input
              type="text"
              placeholder="Enter your password"
              className="form-control my-4"
              id="wjberhjwegrhwegrui34544445"
            />
            <input
              type="text"
              placeholder="New Login Id"
              className="form-control my-4"
              id="skjfjehuiweyuy54y5io232255"
            />
            <p className="text-danger" id="err2"></p>
            <div className="d-grid p-2">
              <button
                className="btn pro-spaces-button2 btn-block "
                // data-bs-toggle="modal"
                // data-bs-target="#phonemodal"
                onClick={(e) => updateLoginId(e)}
              >
                {Loading2 ? <>Updating {Loader}</> : <>Update</>}
              </button>
              <button
                className="invisible"
                id="gchkjbhlkbnkmnlknkjvkb"
                data-bs-toggle="modal"
                data-bs-target="#phonemodal"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const ELogin = (
    <>
      <div className="list-group-item">
        <div className="row align-items-center">
          <div className="col">
            <strong className="mb-2">
              {' '}
              Login Id : {Details.Email && Details.Email}
            </strong>
          </div>
          <div className="col-auto">
            <button
              className="btn pro-spaces-button"
              data-bs-toggle="collapse"
              data-bs-target="#email"
            >
              Change
            </button>
          </div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col">
          <div id="email" className="collapse bg-light  p-3">
            <input
              id="wjberhjwegrhwegrui34544445"
              type="text"
              placeholder="Enter your password"
              className="form-control my-4"
            />
            <input
              id="skjfjehuiweyuy54y5io232255"
              type="text"
              placeholder="New Login Id"
              className="form-control my-4"
            />
            <div className="d-grid p-2">
              <button
                className="btn pro-spaces-button2 btn-block "
                onClick={(e) => updateLoginId()}
              >
                {Loading2 ? <>Updating {Loader}</> : <>Update</>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const changeLOGIN = async () => {
    setLoading(true);
    console.log(PrimeOTP);
    console.log(PrimeOTPlite);
    let dataSend = {
      PrimeOTP,
      PrimeOTPlite,
      LoginId,
    };
    let t = await axios
      .post('/api/AuthenticatedUser/UpdateLoginPrime', { dataSend })
      .then((res) => {
        console.log('done');
        notify('Updated', 'success');
        document.getElementById('changePasasdasdassBtnId348').click();
        props.setReloader(props.Reloader + 1);
      })
      .catch((err) => {
        console.log(err.response.data);
        notify(err.response.data, 'danger');
        console.log(err);
      });
    setLoading(false);
  };

  let navigate = useNavigate();
  const settingChat = () => {
    setchat(!chat);
    axios
      .post('/api/User/chatSettings', { status: !chat })
      .then((res) => {
        console.log(res);
        localStorage.setItem('Chat', !chat);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [History, setHistory] = useState();
  const [Vali, setVali] = useState();

  function payHistory() {
    axios.get('/api/User/GetPayHistory').then((res) => {
      setHistory(res.data);
      console.log(res.data);
      setVali(res.data[0].string[0].Validity);
    });
  }
  useEffect(() => {
    payHistory();
    listALLAccounts();
  }, []);

  const listALLAccounts = () => {
    axios.get('/api/User/listALLAccounts').then((res) => {
      console.log(res.data);
      setListAccounts1(res.data);
    });
  };

  useEffect(() => {
    try {
      document.getElementById('Tommy').classList.add('d-none');
    } catch (error) {
      console.log(error);
    }
    return () => {
      try {
        document.getElementById('Tommy').classList.remove('d-none');
      } catch (error) {
        console.log(error);
      }
    };
  }, []);
  return (
    <div id="Privacy">
      <div style={{ marginTop: '50px' }}>
        <h6 className="mb-3">Subscription Details and Account Settings</h6>
        <br />
        <Accordion
          expanded={expanded === 'profileaccountdetails'}
          onChange={handleChange('profileaccountdetails')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-headerprofiledetails"
          >
            <Typography sx={{ width: '100%', flexShrink: 0 }}>
              <b>MEMBERSHIP SUBSCRIPTION DETAILS </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="table1">
                <div className="md-container" style={{ overflow: 'scroll' }}>
                  <table className="table table-striped table-hover table-bordered bg-white">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={2}>
                          <center>Membership</center>
                        </th>
                        <th scope="col" colSpan={2}>
                          <center>Subscription Period</center>
                        </th>

                        <th scope="col " className="centerProMax" rowSpan={2}>
                          <div className="centerProMax2">Invoices</div>
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">
                          <center>Type</center>
                        </th>
                        <th scope="col">
                          <center>Fees</center>
                        </th>
                        <th scope="col">
                          <center>From</center>
                        </th>
                        <th scope="col">
                          <center>To</center>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {History
                        ? History.map((item) => {
                            var date = Moment(item.string[0].Created_on).format(
                              'DD-MM-YY'
                            );
                            var date1 = Moment(item.Validity).format(
                              'DD-MM-YY'
                            );
                            var s = Moment.unix(item.created_at).format(
                              'DD-MM-YY'
                            );

                            if (item.signature && item.Payment_id) {
                              return (
                                <tr>
                                  {item.Account_type ? (
                                    item.Account_type === 'Consultant' ||
                                    item.Account_type === 'Product' ? (
                                      <td>
                                        {' '}
                                        <center>PRIME</center>
                                      </td>
                                    ) : item.Account_type === 'Consultantv2' ||
                                      item.Account_type === 'Productv2' ? (
                                      <td>
                                        {' '}
                                        <center>ELITE</center>
                                      </td>
                                    ) : item.Account_type === 'Consultantv3' ||
                                      item.Account_type === 'Productv3' ? (
                                      <td>
                                        {' '}
                                        <center>BASIC</center>
                                      </td>
                                    ) : item.Account_type === 'Passive' ? (
                                      <td>
                                        {' '}
                                        <center>PASSIVE</center>
                                      </td>
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    <td></td>
                                  )}
                                  <td>
                                    <center>₹&nbsp;{item.amount}/-</center>
                                  </td>
                                  <td>
                                    <center>{s}</center>
                                  </td>
                                  <td>
                                    <center>{date1}</center>
                                  </td>
                                  <td>
                                    <Invoice_Download id={item._id} />
                                  </td>
                                </tr>
                              );
                            }
                          })
                        : ''}
                      {/* <tr>
                   <center><td>₹ 100/-</td></center>
                    <td> <center>12/2/2024</center></td>
                    <td><center>12/2/2025</center></td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br />
        <Accordion
          expanded={expanded === 'logincred'}
          onChange={handleChange('logincred')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-headerlogincred"
          >
            <Typography sx={{ width: '100%', flexShrink: 0 }}>
              <b>LOGIN CREDENTIALS</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>These settings help you keep your account secure.</p>
              <div className="list-group mb-5 shadow">
                <div className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col">
                      <strong className="mb-2"> Password</strong>
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn pro-spaces-button"
                        data-bs-toggle="collapse"
                        data-bs-target="#pass"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col">
                    <div id="pass" className="collapse bg-light  p-3">
                      <input
                        type="text"
                        placeholder="Current Password"
                        className="form-control my-4"
                        id="oldpassword"
                      />
                      <input
                        type="text"
                        placeholder="New Password"
                        className="form-control my-4"
                        id="newpassword"
                      />
                      <input
                        type="text"
                        placeholder="Confirm New Password"
                        className="form-control my-4"
                        id="repassword"
                      />
                      <p className="text-danger" id="err"></p>
                      <div className="d-grid p-2">
                        <button
                          className={`btn pro-spaces-button2 btn-block ${
                            SettingsContext.PLoading ? ' disabled ' : ''
                          }`}
                          onClick={(e) => props.passwordupdatefun(e)}
                        >
                          {SettingsContext.PLoading ? Loader : 'Update'}
                        </button>
                      </div>
                      {/* data-bs-toggle="modal" data-bs-target="#passmodal"/ */}
                    </div>
                  </div>
                </div>
                {Details ? (
                  Details.EmailLogin ? (
                    <>
                      {' '}
                      {PhoneTemplete} {ELogin}{' '}
                    </>
                  ) : (
                    <>
                      <>
                        {EmailTemplete}
                        {PLogin}
                      </>
                      {/* phone login */}
                    </>
                  )
                ) : (
                  'loading'
                )}

                <div
                  className="modal fade"
                  id="exampleModalforvendor"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title invisible"
                          id="exampleModalLabel"
                        >
                          Modal title
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <h6>
                          Are you sure you want to change your Consultant
                          profile '{localStorage.getItem('pname')}' to a Vendor
                          profile ?
                        </h6>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn pro-spaces-button3"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) =>
                            navigate(
                              '/home/convert_profile_to_products_profile'
                            )
                          }
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="exampleModalforconsultant"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title invisible"
                          id="exampleModalLabel"
                        >
                          Modal title
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <h6>
                          Are you sure you want to change your Vendor profile '
                          {localStorage.getItem('pname')}' to a Consultant
                          profile ?
                        </h6>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn pro-spaces-button3"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) =>
                            navigate(
                              '/home/convert_profile_to_services_profile'
                            )
                          }
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br />
        <Deleteaccount passive={true} downGrade={false} />
        <br />
      </div>
      {/* <button onClick={(e) => props.setReloader(props.Reloader + 1)}>reload</button> */}

      {/* <div className="list-group-item shadow "> */}
      <h5 className="mb-0 mt-0 d-none">One-to-One Chatting</h5>
      {/* <p>This action will not affect group chatting</p> */}
      {/* <p>These settings help you keep your account secure.</p> */}
      <div className="list-group-item shadow d-none">
        <div className="row align-items-center">
          <div className="col">
            <strong className="mb-2">
              {chat
                ? 'Deactivate chat to stop sending and receiving messages'
                : 'Activate chat to start sending and receiving messages'}
            </strong>
          </div>
          <div className="col-auto">
            {/* <FormGroup>
                        <FormControlLabel control={<Switch checked={chat} onChange={(e) => settingChat()} />} />
                    </FormGroup> */}
            <>
              {chat ? (
                <button
                  className="btn pro-spaces-button "
                  onClick={(e) => settingChat(false)}
                >
                  DISABLE
                </button>
              ) : (
                <button
                  className="btn pro-spaces-button "
                  onClick={(e) => settingChat(true)}
                >
                  ENABLE
                </button>
              )}
            </>
          </div>
        </div>
      </div>
      {/* </div> */}
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#LoginIder"
        id="LoginIdModalTrigger"
      ></button>
      <div className="modal" id="LoginIder">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title"> Authentication</h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <p className="m-0">
                Enter OTP sent to{' '}
                {Details.EmailLogin ? Details.Email : Details.Contact}
              </p>
              <input
                id="Otpy"
                type="text"
                className="form-control"
                placeholder="OTP"
                onChange={(e) => setPrimeOTP(e.target.value)}
              />
              <p className="m-0 mt-3">Enter OTP sent to {LoginId}</p>
              <input
                id="Otpy"
                type="text"
                className="form-control"
                placeholder="OTP"
                onChange={(e) => setPrimeOTPlite(e.target.value)}
              />
            </div>

            <p id="errrTopSec" className="text-danger ps-2"></p>
            <div className="modal-footer">
              <button
                id="changePasasdasdassBtnId348"
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={(e) => changeLOGIN(e)}
                disabled={Loading}
                className="btn  pro-spaces-button"
              >
                {Loading ? <>Updating {Loader}</> : <>Update</>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
