import { useEffect, useState } from "react"
import './grid.css'
import { High } from "./High"

export const NewGrid = ({ files, titles, discriptions }) => {
    const [Files, setFiles] = useState(files)
    console.log(Files)
    console.log('first')
    const [Images, setImages] = useState({ q: [], w: [], s: [], d: [] })
    var width = window.innerWidth;
    useEffect(() => {
        let s = 1;
        for (const key of Files) {
            let index = Files.indexOf(key)
            console.log(index)
            switch (s) {
                case 1:
                    setImages((prev) => ({ ...prev, q: [...prev.q, { key, title: titles[index]?.title, discription: discriptions[index]?.Disc },] }))
                    s += 1
                    break;
                case 2:
                    setImages((prev) => ({ ...prev, w: [...prev.w, { key, title: titles[index]?.title, discription: discriptions[index]?.Disc },] }))
                    s += 1;
                    break;
                case 3:
                    setImages((prev) => ({ ...prev, s: [...prev.s, { key, title: titles[index]?.title, discription: discriptions[index]?.Disc },] }))
                    s += 1;
                    break;
                case 4:
                    setImages((prev) => ({ ...prev, d: [...prev.d, { key, title: titles[index]?.title, discription: discriptions[index]?.Disc },] }))
                    s = 1;
                    break;
                default:
                    break;
            }
        }
        return () => {

        }
    }, [])

    useEffect(() => {
        console.log(Images)

        return () => {
        }
    }, [Images])

    const [Elem, setElem] = useState()
    const Clicker = (e) => {
        setElem(e)
        console.log(e)
    }
    return <>
        {Elem ?
            <div   className="procxthe83">
              
                <div class="podfiowdf p-1">
                    <div>
                <div className="d-flex justify-content-end  ">
                    <button type="button" onClick={(e) => setElem(undefined)} class="btn-close-white pe-0 btn btn-close" >
                    </button>
                </div>
                    {/* <div className=" d-flex justify-content-center "> */}
                    {Elem.key.filetype.match(/audio/) ? <><div className=" d-flex justify-content-center ">  <audio className='inssmss' controls ><source src={Elem.key.filename} type='audio/ogg' /></audio></div><h6>{Elem.title}</h6> <p>{Elem.discription}</p>  </> : <></>}
                    {Elem.key.filetype.match(/video/) ? <><div className=" d-flex justify-content-center "> <video className='inssmss' controls ><source src={Elem.key.filename} /></video> </div><h6>{Elem.title}</h6> <p>{Elem.discription}</p>  </> : <></>}
                    {Elem.key.filetype.match(/image/) ? <><div className=" d-flex justify-content-center "> <img style={{maxHeight:'90vh'}} className='img-fluid' src={Elem.key.filename} /></div> <h6>{Elem.title}</h6> <p>{Elem.discription}</p> </>
                        : <></>}
                    {/* {Elem.key.filetype} */}
                    {/* </div> */}
                </div>
                </div>
            </div>

            :
            <div class="rowPro">
           { width>800?
           <>
                <div class="columnPro" >
                    {Images.q.map((element) => {
                        if ("image" === element.key.filetype.slice(0, 5)) {
                            return <img onClick={(e) => Clicker(element)} src={element.key.filename} />
                        } else if ("video" === element.key.filetype.slice(0, 5)) {
                            return <video className="" controls ><source src={element.key.filename} /></video>
                        } else if ('audio' === element.key.filetype.slice(0, 5)) {
                            return <audio controls className=""><source src={element.key.filename} type='audio/ogg' /></audio>
                        }
                    })}
                </div>
                <div class="columnPro">
                    {Images.w.map((element) => {
                        if ("image" === element.key.filetype.slice(0, 5)) {
                            return < img onClick={(e) => Clicker(element)} src={element.key.filename} />
                        } else if ("video" === element.key.filetype.slice(0, 5)) {
                            return <video onClick={(e) => Clicker(element)} className="" controls ><source src={element.key.filename} /></video>
                        } else if ('audio' === element.key.filetype.slice(0, 5)) {
                            return <audio controls className=""><source src={element.key.filename} type='audio/ogg' /></audio>
                        }
                    })}
                </div>
                <div class="columnPro">
                    {Images.s.map((element) => {
                        if ("image" === element.key.filetype.slice(0, 5)) {
                            return < img onClick={(e) => Clicker(element)} src={element.key.filename} />
                        } else if ("video" === element.key.filetype.slice(0, 5)) {
                            return <video className="" controls ><source src={element.key.filename} /></video>
                        } else if ('audio' === element.key.filetype.slice(0, 5)) {
                            return <audio controls className=""><source src={element.key.filename} type='audio/ogg' /></audio>
                        }
                    })}
                </div>
                <div class="columnPro">
                    {Images.d.map((element) => {
                        if ("image" === element.key.filetype.slice(0, 5)) {
                            return < img onClick={(e) => Clicker(element)} src={element.key.filename} />
                        } else if ("video" === element.key.filetype.slice(0, 5)) {
                            return <video className="" controls ><source src={element.key.filename} /></video>
                        } else if ('audio' === element.key.filetype.slice(0, 5)) {
                            return <audio controls className=""><source src={element.key.filename} type='audio/ogg' /></audio>
                        }
                    })}
                </div>
                </>
            :<>
            <div class="columnPro" >
                    {Images.q.map((element) => {
                        if ("image" === element.key.filetype.slice(0, 5)) {
                            return <img onClick={(e) => Clicker(element)} src={element.key.filename} />
                        } else if ("video" === element.key.filetype.slice(0, 5)) {
                            return <video className="" controls ><source src={element.key.filename} /></video>
                        } else if ('audio' === element.key.filetype.slice(0, 5)) {
                            return <audio controls className=""><source src={element.key.filename} type='audio/ogg' /></audio>
                        }
                    })}
              
                    {Images.w.map((element) => {
                        if ("image" === element.key.filetype.slice(0, 5)) {
                            return < img onClick={(e) => Clicker(element)} src={element.key.filename} />
                        } else if ("video" === element.key.filetype.slice(0, 5)) {
                            return <video onClick={(e) => Clicker(element)} className="" controls ><source src={element.key.filename} /></video>
                        } else if ('audio' === element.key.filetype.slice(0, 5)) {
                            return <audio controls className=""><source src={element.key.filename} type='audio/ogg' /></audio>
                        }
                    })}
                </div>
            <div class="columnPro">
                    {Images.s.map((element) => {
                        if ("image" === element.key.filetype.slice(0, 5)) {
                            return < img onClick={(e) => Clicker(element)} src={element.key.filename} />
                        } else if ("video" === element.key.filetype.slice(0, 5)) {
                            return <video className="" controls ><source src={element.key.filename} /></video>
                        } else if ('audio' === element.key.filetype.slice(0, 5)) {
                            return <audio controls className=""><source src={element.key.filename} type='audio/ogg' /></audio>
                        }
                    })}
                     {Images.d.map((element) => {
                        if ("image" === element.key.filetype.slice(0, 5)) {
                            return < img onClick={(e) => Clicker(element)} src={element.key.filename} />
                        } else if ("video" === element.key.filetype.slice(0, 5)) {
                            return <video className="" controls ><source src={element.key.filename} /></video>
                        } else if ('audio' === element.key.filetype.slice(0, 5)) {
                            return <audio controls className=""><source src={element.key.filename} type='audio/ogg' /></audio>
                        }
                    })}
                </div>
                </>}
            </div>

        }   
        {/* {/* <High doit={doit} Clicker={Clicker} /> */}
        {/* {doit ?

            ReactDOM.render(
                <div onClick={(e) => Clicker(false)} className="shower d-flex justify-content-center align-items-center">
                    <div class="container p-1">
                        <img width="100%" src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
                    </div>
                </div>
                ,
                document.getElementById("SpecialModal")
            )
            : ReactDOM.render(
                <div>
                    hi
                </div>
                ,
                document.getElementById("SpecialModalw")
            ) */}
        {/* } */}

    </>
}

export const  HighPreview = ({Elem,onClose})=>{
    console.log(Elem);
if(Elem){
    return   <div   className="procxthe83">
              
    <div class="podfiowdf p-1">
        <div>
    <div className="d-flex justify-content-end  ">
        <button type="button" style={{padding:'0px'}} onClick={(e) => onClose(undefined)} class="btn-close-white pddfg0 btn btn-close" >
        </button>
    </div>
        {/* <div className=" d-flex justify-content-center "> */}
        {Elem.filetype.match(/audio/) ? <><div className=" d-flex justify-content-center ">  <audio className='inssmss' controls ><source src={Elem.filename} type='audio/ogg' /></audio></div><h6>{Elem.title}</h6> <p>{Elem.discription}</p>  </> : <></>}
        {Elem.filetype.match(/video/) ? <><div className=" d-flex justify-content-center "> <video className='inssmss' controls ><source src={Elem.filename} /></video> </div><h6>{Elem.title}</h6> <p>{Elem.discription}</p>  </> : <></>}
        {Elem.filetype.match(/image/) ? <><div className=" d-flex justify-content-center "> <img style={{maxHeight:'90vh'}} className='img-fluid' src={Elem.filename} /></div> <h6>{Elem.title}</h6> <p>{Elem.discription}</p> </>
            : <></>}
        {/* {Elem.key.filetype} */}
        {/* </div> */}
    </div>
    </div>
</div>
}else{
    return<></>
}
   
}