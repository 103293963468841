import React,{useState} from 'react'
import { Container, Row, Col, Card, Button, Modal, Carousel } from 'react-bootstrap';
export const ImageCarousel = ({data}) => {
  console.log(data)
  return (
    <div className='container mb-2' style={{"alignItems":"center"}} >
      <div style={{}}> 
      <center>
{
      data?.newImage?.length>0?

  <div className="" style={{maxHeight:"500px",maxWidth:"500px"}}>
   
 <Carousel controls={data?.newImage?.length > 1} indicators={false} >
                {data?.newImage?.map((image, index) => (
                  <Carousel.Item key={index}>
  

                    <img className="d-block w-100"  src={image.link} alt={`Image ${index + 1}`} />
                  
                  </Carousel.Item>
                ))}
              </Carousel>
             
  </div>
  :""
}
</center>
</div>
    </div>
  )
}
