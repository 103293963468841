import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Mask } from '../../Utility/mask';
import { useNavigate } from 'react-router-dom';
import { StaticProfileModal } from './StaticProfileModal';
import { bucketName } from '../../config'
import { FiEyeOff, FiEye } from "react-icons/fi";

export const ProfileCard = ({ data }) => {
    const [modalShow, setModalShow] = useState(false);
    const { WomanOwned, verified, profilepic, pname, city, state, contact, brandtagline, Validity, Location, _id, Type, address1, adminAproved, CON, address } = data
    const [shortlisted, setshortlisted] = useState(false);
    const currentURL = window.location.href;
    const [logo, setLogo] = useState(false)
    const [isLogo, setIsLogo] = useState(false)
    const isLobby = currentURL.includes('/lobby') || currentURL.includes("Profile_results")
    let navigate = useNavigate();
    const isService = Type.startsWith("C" || "c")
    useEffect(() => {
        if (!(adminAproved && !Validity)) {
            const id = _id
            const URL = isService ? "/api/User/selselectcon" : "/api/User/selselectpro"
            axios
                .post(URL, {
                    id,
                })
                .then((res) => {
                    if (res.status === 200) {
                        setshortlisted(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);
    useEffect(() => {
        const uniqueValue = new Date().getTime();
        setLogo(bucketName + `/${_id}/company_logo.png?timestamp=${uniqueValue}`)
    }, [])
    const navigateToPage = () => {
        if (isService) {
            navigate(`${isLobby ? "/lobby" : ''}/services_profile?Ac_id=` + Mask.encode(_id))
        } else {
            navigate(`${isLobby ? "/lobby" : ''}/products_profile?Ac_id=` + Mask.encode(_id))
        }
    }
    return (
        <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 ">
            {adminAproved && !Validity ?
                //DCE or STATIC or FREE
                <div className='new-profilecard position-relative' style={{ boxShadow: 'none' }}>
                    <div className='d-flex justify-content-end me-2 mt-1 position-absolute' style={{ right: 0 }}>
                        <FiEyeOff />
                    </div>
                    <center>
                        <h4 className="mt-2">{pname.toUpperCase()}</h4>
                        <div className="mt-2  ufghsuhie rigehioerhog ">
                            {city} | {state}
                        </div>
                        <div className="mt-2 mb-2  ufghsuhie rigehioerhog ">
                            {address1 ? address1 : ''} {(address1 && contact) ? ' | ' : ''} {contact ? contact : ''}
                        </div>
                    </center>
                </div> :
                //ELITE
                Type.endsWith("v2") ?
                    <div className='new-profilecard card-box-elite position-relative' onClick={navigateToPage}>
                        <div className='d-flex justify-content-end me-2 mt-1 position-absolute' style={{ right: 0 }}>
                            <FiEye />
                        </div>
                        {!shortlisted ? <></> :
                            <span className="mt-1 ps-3 eofuvhoefhv">
                                SHORT-LISTED
                            </span>
                        }
                        {verified && (
                            <div className="row mt-1">
                                <div className="col d-flex justify-content-center">
                                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                                    <span className="notranslate">AUTHENTIC !</span>
                                </div>
                            </div>
                        )}
                        {WomanOwned && (
                            <div className="row mt-1">
                                <div className="col d-flex justify-content-center">
                                    <img width="30" height={30} src="/wpower.png" alt="w" />
                                    &nbsp;WOMAN ENTREPRENEUR
                                </div>
                            </div>
                        )}
                        <div className={`d-flex justify-content-center ${WomanOwned || verified ? 'border-top-newcard' : ''}`}
                        >
                            <div className="profilepic-new-card" style={{ position: 'relative' }}>
                                <img
                                    src={profilepic}
                                    style={{ borderRadius: "5px", height: '170px', width: '170px' }}
                                />
                                {logo && !isLogo &&
                                    <img className='new-card-logo' src={logo} onError={() => setIsLogo(true)} />
                                }
                            </div>
                        </div>
                        <center>
                            <h4 className='mb-0 mt-1 py-1'>{pname ? pname.toUpperCase() : ''}</h4>
                            <p className='mb-2'>{brandtagline ? <>{brandtagline}<br /></> : <></>}{city} | {state}<br />{address1 ?? <></>}</p>
                        </center>
                    </div> :
                    //BASIC
                    Type.endsWith("v3") ?
                        <>
                            <div className='new-profilecard position-relative' onClick={() => setModalShow(true)}>
                                <div className='d-flex justify-content-end me-2 mt-1 position-absolute' style={{ right: 0 }}>
                                    <FiEye />
                                </div>
                                <center>
                                    <h4 className="mt-2">{pname.toUpperCase()}</h4>
                                    <div className="mt-2   rigehioerhog ">
                                        {city} | {state}
                                    </div>
                                    <div className="mt-2 mb-2   rigehioerhog ">
                                        {address1 || address}
                                        {/* {((address1?.length || address?.length) && CON[0]?.contact2) ? ' | ' : ''}{CON[0]?.contact2} */}
                                    </div>
                                </center>
                            </div>
                            {modalShow &&
                                <StaticProfileModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    id={_id}
                                    Type={Type}
                                />
                            }
                        </>
                        :
                        //PRIME
                        <div className='new-profilecard position-relative' onClick={navigateToPage}>
                            <div className='d-flex justify-content-end me-2 mt-1 position-absolute' style={{ right: 0 }}>
                                <FiEye />
                            </div>
                            {!shortlisted ? <></> :
                                <span className="mt-1 ps-3 eofuvhoefhv">
                                    SHORT-LISTED
                                </span>
                            }
                            {verified && (
                                <div className="row mt-1">
                                    <div className="col d-flex justify-content-center">
                                        <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                                        <span className="notranslate">AUTHENTIC !</span>
                                    </div>
                                </div>
                            )}
                            {WomanOwned && (
                                <div className="row mt-1">
                                    <div className="col d-flex justify-content-center">
                                        <img width="30" height={30} src="/wpower.png" alt="w" />
                                        &nbsp;WOMAN ENTREPRENEUR
                                    </div>
                                </div>
                            )}
                            <div className={`d-flex justify-content-center ${WomanOwned || verified ? 'border-top-newcard' : ''}`}
                            >
                                <div className="profilepic-new-card" style={{ position: 'relative' }}>
                                    <img
                                        src={profilepic}
                                        style={{ borderRadius: "5px", height: '170px', width: '170px' }}
                                    />
                                    {logo && !isLogo &&
                                        <img className='new-card-logo' src={logo} onError={() => setIsLogo(true)} />
                                    }
                                </div>
                            </div>
                            <center>
                                <h4 className='mb-0 mt-1 py-1'>{pname ? pname.toUpperCase() : ''}</h4>
                                <p className='mb-2'>{brandtagline ? <>{brandtagline}<br /></> : <></>}{city} | {state}<br />{(address1 || address) ?? <></>}</p>
                            </center>
                        </div>
            }
        </div>
    )
}

