import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { TimeSelectBox } from '../../Utility/timer';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps, languages, arrayofdays, arrayofdays3 } from '../../Utility/languages'
import { OutlinedInput } from '@mui/material';
import { useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

function Outcount({ index, prev, onChange, outHandle }) {

    const [value, setValue] = useState({
        Day: prev?.[index]?.Day ? prev[index].Day : [],
        From: prev?.[index]?.From ? prev[index].From : '',
        To: prev?.[index]?.To ? prev[index].To : ''
    })

    useEffect(() => {
        outHandle(index, true, value)
        return () => {
        }
    }, [value])

    return (<div className='py-1'>
        {/* <div className='row'>
            {
            index != 0 &&
                <div className='float-end pe-1 ' onClick={(e) => outHandle(index, false)} style={{ "cursor": 'pointer' }} >
                    <AiOutlineCloseCircle size={20} />
                </div>
            }
        </div> */}
        <div className="row">
            <label className="labels">{
                index != 0 &&
                <div className='float-end pe-1 ' onClick={(e) => outHandle(index, false)} style={{ "cursor": 'pointer' }} >
                    <AiOutlineCloseCircle size={20} />
                </div>
            }</label>
            <div className="col-4">
                <label className="labels">Day (s) </label>
                <FormControl className="form-control">
                    <Select id="demo-multiple-checkbox" multiple className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                        value={value.Day} onChange={(e) => setValue({ ...value, Day: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                    >
                        {arrayofdays3.map((arrayofdays3) => (
                            <MenuItem key={arrayofdays3} value={arrayofdays3}>
                                <Checkbox checked={value.Day.indexOf(arrayofdays3) > -1} />
                                <ListItemText primary={arrayofdays3} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="col-8">
                <label className="labels"> </label>
                <div className="row">
                    <div className="col-5 px-0">
                        <select className="form-select"
                            value={value.From}
                            onChange={(e) => {
                                setValue((ele) => ({
                                    ...ele, From: e.target.value
                                }))
                            }}
                        >
                            <TimeSelectBox />
                        </select>
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                        <label className="labels">To</label>
                    </div>
                    <div className="col-5 px-0">
                        <select
                            value={value.To}
                            className="form-select"
                            onChange={(e) => {
                                setValue((ele) => ({
                                    ...ele, To: e.target.value
                                }))
                            }}
                        >
                            <TimeSelectBox />
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Outcount