import { useState, useEffect } from "react"

export const ProjectDiscriptions = (props) => {

    const [value, setValue] = useState({
        number_of_bedroom: props.value && props.value[props.index] ? props.value[props.index].number_of_bedroom : '',
        area: props.value && props.value[props.index] ? props.value[props.index].area : '',
        carpet_area: props.value && props.value[props.index] ? props.value[props.index].carpet_area : '',
    })

    useEffect(() => {
        props.Handler(value, props.index, true)
        return () => {
        }
    }, [value])

    return <div className="mt-4">
        {props.index ? <div> <button onClick={(e) => { props.Handler('', props.index, false) }} className="btn btn-close float-end pe-1"></button> </div> : <></>}
        <label className="ms-1" >Number of Bedrooms of Unit *</label>
        <div className="trysizeplace">
            <input type="number" className="mb-2 eihfioehfoieh  w-100 form-control " defaultValue={value.number_of_bedroom} onChange={(e) => setValue({ ...value, number_of_bedroom: e.target.value })} placeholder='Mention how many bedrooms are there in the available unit' />
        </div>
        <label className="ms-1">Built-up Area of Unit  *</label>
        <div className="trysizeplace">
            <input className="mb-2 eihfioehfoieh  w-100 form-control " defaultValue={value.area} onChange={(e) => setValue({ ...value, area: e.target.value })} placeholder="Mention number followed by ‘SQFT’ or ‘SQCM’" />
        </div>
        <label className="ms-1">Carpet Area of Unit *</label>
        <div className="trysizeplace">
            <input className="mb-2 eihfioehfoieh  w-100 form-control " defaultValue={value.carpet_area} onChange={(e) => setValue({ ...value, carpet_area: e.target.value })} placeholder="Mention number followed by ‘SQFT’ or ‘SQCM’" />
        </div>
    </div>

}