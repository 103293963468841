
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import PostToolBarv2 from '../../Components/PostToolBarv2/PostToolBarv2';

const ProForum = ({ isDisableFooter = false }) => {


    return (
        <div className=' container pt-2'>
            {/* <h1>ProForum</h1> */}
            <div className='postToolbarv2'>
                <PostToolBarv2 isDisableFooter={isDisableFooter} />
            </div>
        </div>
    )
}

export default ProForum