import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'


export  function NotFount() {

    let navigate = useNavigate()

    useEffect(()=>{
        navigate('/404')
    })
  return (
    <div>
        
    </div>
  )
}
