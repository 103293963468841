import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// let item = {
//     productId :'',
//     varientId:'',
//     Qty:'',
// }
export const cubicleSlice = createSlice({
  name: 'cubicle',
  initialState: {
    value: undefined,
  },
  reducers: {
    socketConnection: (state, action) => {
      let socket = action.payload.socket;
      state.value = socket
    }
  },
});

export const {
  socketConnection,
} = cubicleSlice.actions;
export default cubicleSlice.reducer;
