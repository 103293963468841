import axios from 'axios';
import React, {useState, useEffect } from 'react'
import {Link} from "react-router-dom"
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export function Informationview2() {
    const [loading, setloading] = useState(false)

  const [error, seterror] = useState(false);
   
    let [searchParams, setSearchParams] = useSearchParams();  
    var Ac = searchParams.get("id")  
    const [first, setfirst] = useState(false)
    useEffect(() => {
     axios.post('/api/Free/getinformationyeah',{Ac}).then((res)=>{
        console.log(res.data[0])
        setfirst(res.data[0])
     }).catch((err)=>{
        console.log(err)
     })
    }, [])
    useEffect(() => {
      setValue({...value, conemmail:first.email})
    }, [first])
    
    const [value, setValue] = useState({
        senderemail: "",
        conemmail:first.email,
        subject: "",
        query: "",
      });
    const notify = (message, sab) => {
        if (sab) {
          toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.warning(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
    const lolappanpillla = async(e) => {
        if (value.senderemail.length > 0) {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.senderemail)) {
            seterror("");
          } else {
            return seterror("Invalid Email Id");
          }
        } else {
          return seterror("Email Id is missing !");
        }
        
        if (value.subject.length === 0) return seterror("Subject is missing !");
        if (value.query.length === 0) return seterror("Query is missing !");
        // console.log(value)
        setloading(true)
    
     let sab = await   axios.post('/api/User/sendquery',{
          value
        }).then((res)=>{
          if(res.status==200){
            notify(" Query sented successfully", true);
            
          }
        }).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          
        })
        document.getElementById(first._id).click()
        setloading(false)
      };
    
    return (
        <div className='container' style={{ "paddingTop": "80px" }}>
           
            <h5 style={{"textTransform":"uppercase"}}>{first.firstname?first.firstname:''} {first.lastname?first.lastname:''} </h5>
            <h6>{first.string?first.string[0].category:''} | {first.string2?first.string2[0].sub:''}</h6>
            <p>{first.state?first.state:''}, {first.city?first.city:''}</p>
            <p>About : {first.about?first.about:''}</p>
            <p>Works :</p>
            <div className=" container-fluid p-0">
                <div
                    id="carouselExampleControlss"
                    className="carousel slide"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner">
                        {first.File&&first.File.length>0?
                        first.File.map((element, ind)=>{
                            if(ind==0){
                       return <div className="carousel-item active brokendrtff">
                            
                            <img
                                alt=""
                                className="snblsnlbns"
                                src={element}
                            />
                        </div>
                            }else{
                       return <div className="carousel-item brokendrtff">
                            
                            <img
                                alt=""
                                className="snblsnlbns"
                                src={element}
                            />
                        </div>
                            }
                         
                        })
                        
                    :''}
                        {/* <div className="carousel-item active brokendrtff">
                            
                            <img
                                alt=""
                                className="snblsnlbns"
                                src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                            />
                        </div> */}
                       
                    </div>

                    {first.File&&first.File.length>1?
                    <>
                     <button
                        id="Bbtn"
                        className="carousel-control-prev ekgdfukweguobwljbvuwgcuonew"
                        type="button"
                        data-bs-target="#carouselExampleControlss"
                        data-bs-slide="prev"
                    >
                        <span
                            className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">
                            Previous
                        </span>
                    </button>
                    <button
                        id="Fbtn"
                        className="carousel-control-next ekgdfukweguobwljbvuwgcuonew"
                        type="button"
                        data-bs-target="#carouselExampleControlss"

                        data-bs-slide="next"
                    >
                        <span
                            className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">
                            Next
                        </span>
                    </button>
                    </>
                    :<></>}
                   
                   
                </div>
            </div><br />
            <p>UPGRADE your <b style={{"color":"#55374a"}}>ACTIVE PROFILE</b> to a <b style={{"color":"#55374a"}}>PRO-ACTIVE</b> PROFILE to :</p>
            <p>ADD WORK PORTFOLIO / PRODUCT CATALOGUE.</p>
            <p>POST articles or information on your services or products.</p>
            <p>CREATE ADDITIONAL PROFILES in other categories / sub categories.</p>
            <p>POST JOB APPLICATIONS / OFFERS.</p>
            <p>Use Value-added Features such as CUBICLES, KNOW MY CLIENT, SHOUT-OUT and VIRTUAL SECRETARY.</p>
            <center><Link to="/active_register" className="btn pro-spaces-button2 ps-3 pe-3 ">UPGRADE</Link></center> <br />
           
      <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        </div>
    )
}
