import { createSlice } from '@reduxjs/toolkit';

let userSlice = createSlice({
    name: 'user',
    initialState: {
        value: {
            UserName: null,
            Status: false,
            AccountType: null,
            Country: null,
            quickLocation: {
                type: 'global',// global, custom
                data:[],
                country: [],
                state: [],
                city: []
            }
        },
    },
    reducers: {
        addUser: (state, action) => {
            try {
                console.log(action.payload);
                let item = action.payload
                // state.value.UserName = item.UserName
                // state.value.Status = item.Status
                // state.value.Email = item.Email
                // state.value.AccountType = item.AccountType
                state.value.Country = item.Country
                state.value.quickLocation = item.quickLocation
            } catch (err) {
                console.log(err);
            }
        },
        updateUserQuicklocation: (state, action) => {
            try {
                let item = action.payload
                state.value.quickLocation = item.quickLocation
            } catch (err) {
                console.log(err);
            }
        },
        RemoveUserAddress: (state, action) => {
            try {

            } catch (error) {
                console.log(error);
            }
        }
    },
});

export const { addUser ,updateUserQuicklocation} = userSlice.actions;
export default userSlice.reducer;
