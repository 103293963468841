import React, { useEffect, useState } from "react";
// import {Post} from "../../Components/Post/Post"
import {TimeLinePostView} from "../../Components/Post2/TimeLinePostView"
import Axios from 'axios'


export const TimeLineExternal = (props) => {

    const [data, setdata] = useState()
    const [state, setstate] = useState(false)//ini
    const [load, setload] = useState(true)
    const [error, seterror] = useState('')
    const [output, setoutput] = useState('')

    function makeid(length) {
        length = 5
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    var sab = props.acid
   
useEffect(() => {
    Axios.post("/api/User/TimeExt",{sab}).then((response) => {
        console.log('success')
        console.log(response.data)
        const postSummaries = response.data.map((post) => ({ id: post._id, key: post.id, Post_Id: post.Post_Id, Content: post.Content, Author: post.Account_id, File: post.File, Posted_On: post.Posted_On, Commentcount: post.commentcount, merge:post.string, merge2:post.string2, com:post.com, Likecount: post.likecount, Likestatus: post.likestatus,  profilepic:post.profilepic}));
        setdata(postSummaries)
        props.setfirst8(postSummaries.length)
        setload(false)
        seterror('')
    }).catch(
        res => {
            setload(false)
            setdata(false)
            seterror('Something went wrong')
            console.log('Something went wrong')
        }
    )
}, [])

    
   
     
       
    useEffect(() => {
        if (load) {
            setoutput(<><br /><div className="text-center "> <div style={loader} className="spinner-border text-dark"></div></div></>)
        }
        else {
            if (data) {
                setoutput(data.map((number) =>
                    <TimeLinePostView key={number.id} ids={makeid()} dat={number} view='ext'></TimeLinePostView>
                )
                )
            }
            else {
                setoutput(error)
            }
        }

    }, [error, load])

console.log('working here')
  // Loadpost()
    var loader = {
        width: '5rem',
        height: '5rem'
    }
    return (

        <div className="container-fluid">
          

                <div className="col-md-12 tab p-0" >
                    {/* <PosttoolBar /> */}
                    {/* <Post dat={data}></Post> */}



                    {output}
                    {/* {load ? <div className="text-center cell"> <div style={loader} className="spinner-border text-dark"></div></div> :


                        data.map((number) =>
                            <Post key={number.id} dat={number}></Post>
                        )




                    }
                    {error ? error : ''} */}
                </div>
               
            
        </div>

    )

}
