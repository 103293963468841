import React from 'react'
import { useEffect, useState, useRef } from "react"
import { print_state_v2, print_city_v2, print_city_inputstate_v2 } from '../../Utility/stateCity'
import { MenuProps, languages, arrayofdays, arrayofdays2 } from '../../Utility/languages'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { TimeSelectBox } from '../../Utility/timer';
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FormHelperText } from '@mui/material';


const AddOnHospitaity_Availability_Field = (props) => {
    console.log(props);
    let stateRef = useRef()
    let cityRef = useRef()
    const [Data, setData] = useState({
        hospitalname: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hospitalname ? props.AOHAF[props.index].hospitalname : '' : '' : '',
        state: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].state ? props.AOHAF[props.index].state : '' : '' : '',
        city: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].city ? props.AOHAF[props.index].city : '' : '' : '',
        localityh: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].localityh ? props.AOHAF[props.index].localityh : '' : '' : '',
        pincode: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].pincode ? props.AOHAF[props.index].pincode : '' : '' : '',
        hworking_days_1: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_days_1 ? props.AOHAF[props.index].hworking_days_1 : '' : '' : '',
        hworking_days_2: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_days_2 ? props.AOHAF[props.index].hworking_days_2 : '' : '' : '',
        hworking_hour_1: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_hour_1 ? props.AOHAF[props.index].hworking_hour_1 : '' : '' : '',
        hworking_hour_2: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_hour_2 ? props.AOHAF[props.index].hworking_hour_2 : '' : '' : '',
        hworking_days_3: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_days_3 ? props.AOHAF[props.index].hworking_days_3 : '' : '' : '',
        hworking_days_4: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_days_4 ? props.AOHAF[props.index].hworking_days_4 : '' : '' : '',
        hworking_hour_3: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_hour_3 ? props.AOHAF[props.index].hworking_hour_3 : '' : '' : '',
        hworking_hour_4: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_hour_4 ? props.AOHAF[props.index].hworking_hour_4 : '' : '' : '',
        hworking_leave: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworking_leave ? props.AOHAF[props.index].hworking_leave : '' : '' : '',
        hworkingdaysfullnew: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworkingdaysfullnew ? props.AOHAF[props.index].hworkingdaysfullnew : [] : [] : [],
        hworkingdayshalfnew: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworkingdayshalfnew ? props.AOHAF[props.index].hworkingdayshalfnew : [] : [] : [],
        hworkingdaysOffnew: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hworkingdaysOffnew ? props.AOHAF[props.index].hworkingdaysOffnew : [] : [] : [],
        OnlyOn: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].OnlyOn ? props.AOHAF[props.index].OnlyOn : false : false : false,
        OnlyOn: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].OnlyOn ? props.AOHAF[props.index].OnlyOn : false : false : false,
        break_time_2: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].break_time_2 ? props.AOHAF[props.index].break_time_2 : '' : '' : '',
        break_time_1: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].break_time_1 ? props.AOHAF[props.index].break_time_1 : '' : '' : '',
        hcountrycode: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hcountrycode ? props.AOHAF[props.index].hcountrycode : '' : '' : '',
        hmobilenumber: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hmobilenumber ? props.AOHAF[props.index].hmobilenumber : '' : '' : '',
        hstatecode: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].hstatecode ? props.AOHAF[props.index].hstatecode : '' : '' : '',
        contacthospitalforappoiment: props.AOHAF ? props.AOHAF[props.index] ? props.AOHAF[props.index].contacthospitalforappoiment ? props.AOHAF[props.index].contacthospitalforappoiment : '' : '' : '',
    })

    useEffect(() => {
        props.handleAOHAF(Data, props.index)
        console.log(Data)
    }, [Data])

    useEffect(() => {
        print_state_v2(stateRef.current)
        try {
            if (props?.AOHAF?.[props.index]?.city) {
                print_city_inputstate_v2(cityRef.current, props.AOHAF[props.index].state)
            }
        } catch (error) {
            console.log(error);
        }

        return () => {
        }

    }, [])

    function Cityshow(e) {
        var index = stateRef.current.selectedIndex
        print_city_v2(cityRef.current, index)
        setData({ ...Data, state: e.target.value })
    }

    const isOptionDisabled = (name, value) => {
        // Add your condition to disable multiple options here
        // console.log(name)
        if (value.length > 0) {
            if (value.indexOf('Nil') >= 0) {
                return name === 'Monday' || name === 'Tuesday' || name === "Wednesday" || name === "Thursday" || name === "Friday" || name === "Saturday" || name === "Sunday";
            } else {
                return name === 'Nil'
            }
        }

    };

    return <fieldset className="mt-3">
        <hr />
        {props.index != 0 && <div className='float-end pe-1' onClick={(e) => props.handleAOHAF('remove', props.index)} style={{ "cursor": 'pointer' }} ><AiOutlineCloseCircle size={20} /></div>}

        <h6 className="mt-4">Hospital Consultation Information</h6>

        <div className="row mt-2">
            <h6 className="mt-4">Hospital Associated with / Working in</h6>
            <div className="col-md-12">
                <label className="labels">Name of the Hospital *</label>
                <input
                    required
                    type="text"
                    className="form-control"
                    value={Data.hospitalname}
                    onChange={(e) =>
                        setData({ ...Data, hospitalname: e.target.value })
                    }
                />
                <div class="invalid-feedback">
                    PLEASE ENTER Name of the Hospital
                </div>
            </div>
            <div className="col-md-12">
                <label className="labels">State / Province *</label>
                <select
                    required
                    className="form-select"
                    value={Data.state}
                    ref={stateRef}
                    name="City2"
                    onChange={(e) => Cityshow(e)}
                >

                </select>
                <div class="invalid-feedback">
                    PLEASE SELECT State / Province
                </div>
            </div>
            <div className="col-md-12">
                <label className="labels">City *</label>
                <select
                    required
                    className="form-select"
                    name="City2"
                    value={Data.city}
                    ref={cityRef}
                    onChange={(e) =>
                        setData({ ...Data, city: e.target.value })
                    }
                >
                    <option></option>
                </select>
                <div class="invalid-feedback">
                    PLEASE SELECT City
                </div>
            </div>
            <div className="col-md-12">
                <label className="labels">Locality *</label>
                <input
                    required
                    type="text"
                    className="form-control"
                    value={Data.localityh}
                    onChange={(e) =>
                        setData({ ...Data, localityh: e.target.value })
                    }
                />
                <div class="invalid-feedback">
                    PLEASE ENTER Locality
                </div>
            </div>
            <div className="col-md-12">
                <label className="labels">Pin code / Zip code</label>
                <input
                    type="text"
                    className="form-control"
                    value={Data.pincode}
                    onChange={(e) => {
                        if (e.target.value.length == 7) return false;
                        setData({ ...Data, pincode: e.target.value });
                    }}
                />
            </div>
        </div>

        <h6 className="mt-4">Availability at Hospital</h6>
        {props.type === 'Privateand' ?
            <div className="form-check">
                <input
                    onChange={(e) =>
                        setData({ ...Data, OnlyOn: e.target.checked })
                    }
                    value={Data.OnlyOn}
                    checked={Data.OnlyOn}
                    className="form-check-input"
                    type="checkbox"
                    id="Enterprise"
                />
                <label className="labels" for="Enterprise">
                    Only On-Call, No Specific Day
                </label>
            </div> : <></>
        }
        {!Data.OnlyOn && <>
            <div className="row mt-2">
                <div className="col-md-12">
                    <label className="labels">Working Days (full) *</label>
                    <FormControl className="form-control">
                        <Select id="demo-multiple-checkbox" required error={props.Submit2 ? Data?.hworkingdaysfullnew?.length != 0 ? false : true : false} multiple className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                            value={Data.hworkingdaysfullnew} onChange={(e) => setData({ ...Data, hworkingdaysfullnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                        >
                            {arrayofdays.map((arrayofdays) => (
                                <MenuItem key={arrayofdays} value={arrayofdays}
                                    disabled={isOptionDisabled(arrayofdays, Data.hworkingdaysfullnew)}
                                >
                                    <Checkbox checked={Data.hworkingdaysfullnew.indexOf(arrayofdays) > -1} />
                                    <ListItemText primary={arrayofdays} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{props.Submit2 ? Data?.hworkingdaysfullnew?.length != 0 ? false : 'PLEASE SELECT Working Days (full)' : false}</FormHelperText>
                    </FormControl>
                </div>
                <div className="col-md-12">
                    <label className="labels">Working Hours *</label>
                    <div className="row">
                        <div className="col-5">
                            <select
                                required
                                className="form-select"
                                value={Data.hworking_hour_1}
                                onChange={(e) =>
                                    setData({ ...Data, hworking_hour_1: e.target.value })
                                }
                            >
                                <TimeSelectBox />


                            </select>
                            <div class="invalid-feedback">
                                PLEASE ENTER Working Hours
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                        <div className="col-5">
                            <select
                                required
                                className="form-select"
                                value={Data.hworking_hour_2}
                                onChange={(e) =>
                                    setData({ ...Data, hworking_hour_2: e.target.value })
                                }
                            >
                                <TimeSelectBox />

                            </select>
                            <div class="invalid-feedback">
                                PLEASE ENTER Working Hours
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Break Time *</label>
                    <div className="row">
                        <div className="col-5">
                            <select
                                required
                                className="form-select"
                                value={Data.break_time_1}
                                onChange={(e) =>
                                    setData({ ...Data, break_time_1: e.target.value })
                                }
                            >
                                <TimeSelectBox type={'break'} />


                            </select>
                            <div class="invalid-feedback">
                                PLEASE ENTER Break Time
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                        <div className="col-5">
                            <select
                                required
                                className="form-select"
                                value={Data.break_time_2}
                                onChange={(e) =>
                                    setData({ ...Data, break_time_2: e.target.value })
                                }
                            >
                                <TimeSelectBox type={'break'} />

                            </select>
                            <div class="invalid-feedback">
                                PLEASE ENTER Break Time
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Working Days (half) *</label>
                    <FormControl className="form-control">
                        <Select id="demo-multiple-checkbox" required error={props.Submit2 ? Data?.hworkingdayshalfnew?.length != 0 ? false : true : false} multiple className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                            value={Data.hworkingdayshalfnew} onChange={(e) => setData({ ...Data, hworkingdayshalfnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                        >
                            {arrayofdays2.map((arrayofdays2) => (
                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                    disabled={isOptionDisabled(arrayofdays2, Data.hworkingdayshalfnew)}
                                >
                                    <Checkbox checked={Data.hworkingdayshalfnew.indexOf(arrayofdays2) > -1} />
                                    <ListItemText primary={arrayofdays2} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{props.Submit2 ? Data?.hworkingdayshalfnew?.length != 0 ? false : 'PLEASE SELECT Working Days (half)' : false}</FormHelperText>

                    </FormControl>
                </div>
                <div className="col-md-12">
                    <label className="labels">Working Hours *</label>
                    <div className="row">
                        <div className="col-5">
                            <select
                                required
                                className="form-select"
                                value={Data.hworking_hour_3}
                                onChange={(e) =>
                                    setData({ ...Data, hworking_hour_3: e.target.value })
                                }
                            >
                                <TimeSelectBox />


                            </select>
                            <div class="invalid-feedback">
                                PLEASE ENTER Working Hours
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                        <div className="col-5">
                            <select
                                required
                                className="form-select"
                                value={Data.hworking_hour_4}
                                onChange={(e) =>
                                    setData({ ...Data, hworking_hour_4: e.target.value })
                                }
                            >
                                <TimeSelectBox />

                            </select>
                            <div class="invalid-feedback">
                                PLEASE ENTER Working Hours
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Weekly Off *</label>
                    <FormControl className="form-control">
                        <Select id="demo-multiple-checkbox" required error={props.Submit2 ? Data?.hworkingdaysOffnew?.length != 0 ? false : true : false} multiple className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                            value={Data.hworkingdaysOffnew} onChange={(e) => setData({ ...Data, hworkingdaysOffnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                        >
                            {arrayofdays2.map((arrayofdays2) => (
                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                    disabled={isOptionDisabled(arrayofdays2, Data.hworkingdaysOffnew)}
                                >
                                    <Checkbox checked={Data.hworkingdaysOffnew.indexOf(arrayofdays2) > -1} />
                                    <ListItemText primary={arrayofdays2} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{props.Submit2 ? Data?.hworkingdaysOffnew?.length != 0 ? false : 'PLEASE SELECT Weekly Off' : false}</FormHelperText>

                    </FormControl>
                </div>
            </div>

        </>}
        <>
            <h6 className="mt-4">Contact Information of Hospital</h6>

            <div className="row mt-2">
                <div className="col-md-12"><label className="labels">Country Code *</label>
                    <select
                        required
                        className="form-select"
                        value={Data.hcountrycode}
                        onChange={(e) =>
                            setData({ ...Data, hcountrycode: e.target.value })
                        }
                    >
                        <option value="" disabled selected></option>
                        <option value="+91">+91</option>

                    </select>
                    <div class="invalid-feedback">
                        PLEASE SELECT Country Code
                    </div>
                </div>
                {/* <div className="col-md-12"><label className="labels">State Code *</label><input type="number" required className="form-control" value={Data.hstatecode} onChange={(e) => setData({ ...Data, hstatecode: e.target.value })} />
                    <div class="invalid-feedback">
                        PLEASE ENTER State Code
                    </div>
                </div> */}
                <div className="col-md-12">
                    <label className="labels">Mobile Number / Landline Number</label>
                    <input
                        type="number"
                        className="form-control"
                        value={Data.hmobilenumber} onChange={(e) =>
                            setData({ ...Data, hmobilenumber: e.target.value })
                        }
                    />
                </div>

                <div className="col-12 pt-1">
                    <div className="form-check">
                        <input
                            onChange={(e) =>
                                setData({
                                    ...Data,
                                    contacthospitalforappoiment: !Data.contacthospitalforappoiment,
                                })
                            }
                            className="form-check-input"
                            type="checkbox"
                            id="contacthospitalforappoiment"
                            checked={Data.contacthospitalforappoiment}
                        />
                        <label className="labels" for="contacthospitalforappoiment">
                            Contact Hospital for an Appointment
                        </label>
                    </div>
                </div>
            </div>
            <hr />
        </>
    </fieldset>


}
export default AddOnHospitaity_Availability_Field