import React, { useState, useEffect } from "react";
import { InlineShareButtons } from "sharethis-reactjs";
import { Modal } from "react-bootstrap";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import axios from "axios";
import CubicleAPI from "../../Components/CubicleAPI/CubicleAPI";


export const ShareThis = ({ shareshow, setshareshow, url }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setshareshow(false);
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
  };
  //   console.log(shareshow);

  return (
    <>
      <Modal
        show={shareshow}
        onHide={() => {
          handleClose();
        }}
        backdrop={"static"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>

            <div className="px-2" >
              <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>

                <InlineShareButtons
                  config={{
                    // alignment: "center", // alignment of buttons (left, center, right)
                    color: "social", // set the color of buttons (social, white)
                    enabled: true, // show/hide buttons (true, false)
                    font_size: 16, // font size for the buttons
                    labels: { cat: "hai da" }, // button labels (cta, counts, null)
                    language: "en", // which language to use (see LANGUAGES)
                    networks: [
                      // which networks to include (see SHARING NETWORKS)
                      "whatsapp",
                      // "linkedin",
                      // "messenger",
                      // "facebook",
                      // "twitter",
                    ],
                    padding: 12, // padding within buttons (INTEGER)
                    radius: 4, // the corner radius on each button (INTEGER)
                    // show_total: true,
                    size: 40, // the size of each button (INTEGER)

                    // OPTIONAL PARAMETERS
                    url: url, // (defaults to current url)
                    // image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
                    // description: "custom text", // (defaults to og:description or twitter:description)
                    // title: "custom title", // (defaults to og:title or twitter:title)
                    // message: "custom email text", // (only for email sharing)
                    // subject: "custom email subject", // (only for email sharing)
                    // username: "custom twitter handle", // (only for twitter sharing)
                  }}

                />
              </div>
              <p className="m-0 shareabel">WhatsApp</p>
            </div>
            <div className="px-2" >
              <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <button onClick={(e) => { setShow1(!show1) }} className="cubiclebutton"><img src="/cubicleshare.png" /></button>
              </div>
              <p className="m-0 shareabel">Cubicles</p>
            </div>

            {/* <BsFillChatLeftTextFill size={50} style={{ paddingLeft: "20px" }} onClick={(e) => { setShow1(!show1) }} /> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={() => {
          handleClose1();
        }}
        backdrop={"static"}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0" >
          <CubicleAPI callback={handleClose1} url={url} />
        </Modal.Body>
      </Modal>



    </>
  );
};
