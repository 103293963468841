import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ShoutOut } from "../Components/shoutOUT/ShoutOut";
import { ChatPrevious } from "../Components/ChatPrevious/ChatPrevious";

import { Advertisementt } from "../Components/advertisement/Advertisementt";

import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Vertual } from "../Components/Vertual/Vertual";
import { Feedback } from "../Components/Feedback/Feedback";
// import { ConsultentRoute } from "../Components/ConsultentRoute/ConsultentRoute"
// import { ProductRoute } from "../Components/ProductRoute/ProductRoute"
import { ChatFinal } from "../Components/ChatFinal/ChatFinal";
import { Home } from "./Activev3/Home/Home";
import { Information_Category_list } from "../Components/Information_View/Information_Category_list";

import ActiveAccountsettings from "../Pages/Activev3/ActiveAccountsettings/ActiveAccountsettings";
// import { ProfileCreation } from "../Components/ProfileCreation/ProfileCreation"
import { JobMyPost } from "../Components/JobMyPost/JobMyPost";
import { JobPostInternUpdate } from "../Components/JobPostInternUpdate/JobPostInternUpdate";
import { JobPostFresherUpdate } from "../Components/JobPostFresherUpdate/JobPostFresherUpdate";
import { JobPostForm } from "../Components/JobSeekerPostForm/jobPostForm";
import { JobPostIntern } from "../Components/JobPostIntern/jobPostIntern";
import { JobPostFresher } from "../Components/JobPostFresher/jobPostFresher";
import { JobPostFormUpdate } from "../Components/JobSeekerPostFormUpdate/jobPostFormupdate";
import { Applicants } from "../Components/Applicants/Applicants";
import { ApplicantsSingleView } from "../Components/ApplicantsSingleView/ApplicantsSingleView";
import { Job } from "../Components/Job/Job";
import { JobReqPost } from "../Components/JobVac/JobReqPost";
import { JobView } from "../Components/JobVacancyView/jobView";
import { JobReqPost2 } from "../Components/JobReq/JobReqPost2";
import { JobSeeker } from "../Components/JobSeekerView/JobSeeker";
import { JobSeekMyPost } from "../Components/JobSeekMyPost/JobSeekMyPost";
// import { RecruiterPost } from "../Components/JobRecruitForm/recruitPostForm"
// import { JobPostedView } from "../Components/JobPostedView/JobPostedView";
// import { JobPostedEdite } from "../Components/JobPostedEdite/JobPostedEdite";

// import { Categories } from "../Components/ConsCategories/Categories"
import { ConSubcategory } from "../Components/ConSubcategory/ConSubcategory";
import { SearchResult } from "../Components/ConSearchResult/SearchResult";
import { ProductSubCategory } from "../Components/ProductSubCategory/ProductSubCategory";
import { ProductList } from "../Components/ProductList/ProductList";
import { ProductList as ListBrand } from "../Components/ProductListBrand/ProductList";
import { ProductView } from "../Components/ProductView/ProductView";
import { Profile } from "./Activev3/profile/Profile";

import "../Pages/Active.css";
import { Categories } from "../Components/ConsCategories/Categories";
import { ProductMainCategory } from "../Components/ProductMainCategory/ProductMainCategory";
import ActiveProfile from "../Pages/Active/ActiveProfile/ActiveProfile";
import ProductProfile from "../Pages/Active/ProductProfile/ProductProfile";
import ExternalPview from "../Pages/Active/ActiveProfile/externalPview";
import { ConsultFaq } from "../Components/ConsultFaq/ConsultFaq";
import { CommingSoon } from "../Components/CommingSoon/CommingSoon";

import { Editpro } from "../Components/ProductView/Editpro";
import { ViewSelectedCon } from "../Components/ViewSelectedCon/ViewSelectedCon";
import { ProfileCreation } from "../Components/NewProfile/ProfileCreation";
import { PaymentNewProfile } from "../Pages/Payment/newProfilePayment";
import { PostSingle } from "../Components/Post2/PostSingle";

import Pextenalview from "../Pages/Active/ProductProfile/Pextenalview";
import { ConvertPro } from "../Components/CreateProductV3/ConvertPro";
import { ConvertCon } from "../Components/CreateConseltentV3/ConvertCon";

import Marketing from "./Marketing/Marketing";
import { Quickfix } from "../Components/Quickfix/Quickfix";

import Pview from "./Activev3/ActiveProfile/Pview";
import Edit from "./Activev3/ActiveProfile/Edit";
import Pview2 from "./Activev3/ProductProfile/Pview";
import Edit2 from "./Activev3/ProductProfile/Edit";
import { NotFount } from "../Components/404/404";
import { UserFeatures } from "../Components/userFeatures/UserFeatures";
// import {Error} from '../Components/Error/Error'
import { Cub } from "../Components/Cubiclefeatures/cub";
import { ProUpgrade } from "./Payment/ProUpgrade";
import { About } from "../Components/About/About";

import Adminpexternal from "./Admininside/Profile/Adminpexternal";
import { NewNavigationBar } from "./Activev3/NewNavigationBar/NewNavigationBar";
import { CancelationPolicies2 } from "../Components/CancelationPolicies/CancelationPolicies2";
import { Disclimer2 } from "../Components/Disclimer/Disclimer2";
import { Privacy2 } from "../Components/Privacy/Privacy2";
import { Termsofuse2 } from "../Components/Termsofuse/Termsofuse2";

import { ProPostView } from "../Components/postToolBarPro/view";
import Propostlist from "../Components/propostPost/propostlist";
import Propostlist1 from "../Components/propostPostVender/propostlist";

import { InnovationGridView } from "./Movies_Innovation/innovationTiles";
import {
  InnovationTlieList,
  innovationTlieList,
} from "./Movies_Innovation/innovationTlieList";
import { TileMovies } from "./Movies_Innovation/tlie";
import { MovieGridView } from "./Movies_Innovation/movieTiles";

import EmergencyServices from "../Components/EmergencyServices/EmergencyServices";
import { AmbulanceServicesAnimalsList } from "../Components/EmergencyServices/AmbulanceServicesAnimals/AmbulanceServicesAnimalsList";
import { AmbulanceServicesHumans } from "../Components/EmergencyServices/AmbulanceServicesHumans";
import { BloodDonors } from "../Components/EmergencyServices/BloodDonors";
import { DeceasedAnimalBurialGrounds } from "../Components/EmergencyServices/DeceasedAnimalBurialGrounds";
import { ViewSelectedPro } from "../Components/ViewSelectedCon/ViewSelectedPro";
import { ProductSubSubCategory } from "../Components/ProductSubSubCategory/ProductSubSubCategory";
// import { Vertual } from "../Components/Vertual/Vertual";

import { ConSubcategory as ConSubSubcategory } from "../Components/ConSubSubcategory/ConSubcategory";
import { SearchResult as SearchResultBrand } from "../Components/ConSearchResultBrand/SearchResult";
import { Search } from "../Components/Search/Search";
import { UpgradToBasic } from "./Payment/UpgradToBasic";
import { Profiledetailsandsettings } from "./Activev3/ActiveAccountsettings/Profiledetailsandsettings";
import ShoutOutBlockedList from "../Components/shoutOUT/ShoutOutBlokedList";
import { Ask_us_view } from "../Components/Feedback/Ask_us_view";
import { Prochure_Ask_Us_List } from "../Components/Feedback/Ask_us_list";
import Welcome from "./Welcome/welcome";
import ProForum from "./ProForum/ProForum";
import { Newfind } from "../Components/Newfind";
import { Newidfind } from "../Components/Newidfind";
import { Information_Subcategory_list } from "../Components/Information_View/Information_Subcategory_list";
import { Information_Subsubcategory_list } from "../Components/Information_View/Information_Subsubcategory_list";
import { Information_listing } from "../Components/Information_View/Information_listing";
import { Information_Medical_Store } from "../Components/Information_View/Information_Medical_Store";
import Information_Medical_Store_profile from "../Components/Information_View/SIngle_page/Information_Medical_Store_profile";
import Information_Type1 from "../Components/Information_View/SIngle_page/Information_Type1";
import { GlobalErrorHandler } from "../Utility/components/GlobalErrorHandler";

export function Activev3(props) {
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();

  const Auth = () => {
    Axios.get("/api/verify/authenticateduser")
      .then((res) => {
        if (res.status === 200) {
          console.log("workd");
          setAuthenticated(true);
        } else {
          localStorage.clear();
          navigate("/");
        }
      })
      .catch((err) => {
        if (err) {
          localStorage.clear();
          navigate("/");
          console.log(err);
        }
      });
  };
  useEffect(() => {
    console.log("reeee");
    Auth();
  }, []);

  return (
    <>
      {Authenticated ? (
        <div className="container-fluid p-0">
          {/* <NavOrg ShowAccounts={props.ShowAccounts} />
        <SideNav /> */}
          <NewNavigationBar ShowAccounts={props.ShowAccounts} />
          <div className="">
            <div className="col-12">
              <GlobalErrorHandler>
                <Routes>
                  <Route element={<Home />} exact path="/"></Route>
                  <Route element={<NotFount />} exact path="/*"></Route>
                  <Route element={<Welcome />} path="/welcome"></Route>
                  <Route
                    element={<Categories Auth={Auth} />}
                    path="services"
                  ></Route>
                  <Route
                    element={<ConSubcategory Auth={Auth} />}
                    path="services_sub_categories"
                  ></Route>
                  <Route
                    element={<SearchResult Auth={Auth} />}
                    path="services_result"
                  ></Route>
                  <Route
                    element={<Vertual Auth={Auth} />}
                    path="virtual_secretary"
                  ></Route>

                  <Route
                    element={<ConSubSubcategory Auth={Auth} />}
                    path="services_brand_categories"
                  ></Route>
                  <Route
                    element={<SearchResultBrand Auth={Auth} />}
                    path="services_result_by_brand"
                  ></Route>
                  <Route
                    element={<Search visibility={"private"} />}
                    path="search"
                  ></Route>

                  <Route
                    element={<ActiveAccountsettings Auth={Auth} />}
                    path="Account"
                  ></Route>
                  <Route
                    element={<Profiledetailsandsettings Auth={Auth} />}
                    path="profile_details_and_settings"
                  ></Route>

                  <Route
                    element={<ProductMainCategory Auth={Auth} />}
                    path="products"
                  ></Route>
                  <Route
                    element={<ProductSubCategory Auth={Auth} />}
                    path="products_sub_categories"
                  ></Route>
                  <Route
                    element={<ProductSubSubCategory Auth={Auth} />}
                    path="products_brand_categories"
                  ></Route>
                  <Route
                    element={<ProductList Auth={Auth} />}
                    path="products_result"
                  ></Route>
                  <Route
                    element={<ListBrand Auth={Auth} />}
                    path="products_result_by_brand"
                  ></Route>
                  <Route
                    element={<ProductView Auth={Auth} />}
                    path="View_Product"
                  ></Route>
                  <Route
                    element={<Feedback Auth={Auth} />}
                    path="suggestions"
                  ></Route>
                  <Route
                    element={<Pview Auth={Auth} />}
                    path="my_profile_services"
                  ></Route>
                  <Route
                    element={<Edit Auth={Auth} />}
                    path="edit_profile_services"
                  ></Route>
                  <Route
                    element={<Pview2 Auth={Auth} />}
                    path="my_profile_products"
                  ></Route>
                  <Route
                    element={<Edit2 Auth={Auth} />}
                    path="edit_profile_products"
                  ></Route>
                  <Route
                    element={<ChatFinal Auth={Auth} socket={props.socket} />}
                    path="cubicles"
                  ></Route>
                  <Route
                    element={<ChatPrevious Auth={Auth} />}
                    path="LockedTopic"
                  ></Route>
                  <Route element={<Cub />} path="/cubicle_features"></Route>

                  <Route
                    element={<ExternalPview Auth={Auth} />}
                    path="services_profile"
                  ></Route>
                  <Route
                    element={<CommingSoon Auth={Auth} />}
                    path="coming_soon"
                  ></Route>
                  <Route
                    element={<Profile Auth={Auth} />}
                    path="profile"
                  ></Route>
                  <Route
                    element={<ProfileCreation Auth={Auth} />}
                    path="new_profile/*"
                  ></Route>
                  <Route
                    element={<PaymentNewProfile Auth={Auth} />}
                    path="PaymentNewProfile"
                  ></Route>
                  <Route
                    element={<Pextenalview Auth={Auth} />}
                    path="products_profile"
                  ></Route>
                  <Route
                    element={<Editpro Auth={Auth} />}
                    path="Edit_my_product"
                  ></Route>
                  <Route
                    element={<ViewSelectedCon Auth={Auth} />}
                    path="my_selected_c"
                  ></Route>
                  <Route
                    element={<ViewSelectedPro Auth={Auth} />}
                    path="my_selected_vendors"
                  ></Route>
                  <Route
                    element={<PostSingle Auth={Auth} />}
                    path="Post_single"
                  ></Route>
                  <Route
                    element={<ShoutOut Auth={Auth} />}
                    path="ShoutOut_view"
                  ></Route>
                  <Route
                    element={<Quickfix Auth={Auth} />}
                    path="quick_fix"
                  ></Route>
                  <Route
                    element={<ConvertPro Auth={Auth} />}
                    path="convert_profile_to_products_profile"
                  ></Route>
                  <Route
                    element={<ConvertCon Auth={Auth} />}
                    path="convert_profile_to_services_profile"
                  ></Route>
                  <Route
                    element={<Marketing Auth={Auth} />}
                    path="/Marketing"
                  ></Route>

                  <Route
                    element={<UserFeatures />}
                    path="user_features"
                  ></Route>
                  <Route element={<About />} path="/inception"></Route>
                  <Route
                    element={<ViewSelectedCon Auth={Auth} />}
                    path="short_listed_services"
                  ></Route>
                  <Route
                    element={<ViewSelectedPro Auth={Auth} />}
                    path="short_listed_products"
                  ></Route>
                  <Route
                    element={<ProUpgrade Auth={Auth} />}
                    path="upgrade_to_proactive"
                  ></Route>
                  <Route
                    element={<UpgradToBasic Auth={Auth} />}
                    path="upgrade_to_basic"
                  ></Route>
                  <Route
                    element={<Advertisementt Auth={Auth} />}
                    path="/Advertisement"
                  />

                  <Route
                    element={<Adminpexternal Auth={Auth} />}
                    path="profile_pro"
                  ></Route>
                  <Route
                    element={<CancelationPolicies2 />}
                    path="/cancellation"
                  ></Route>
                  <Route element={<Disclimer2 />} path="/Disclaimer"></Route>
                  <Route element={<Privacy2 />} path="/Privacy"></Route>
                  <Route element={<Termsofuse2 />} path="/Termsofuse"></Route>
                  <Route
                    element={<Propostlist Auth={Auth} />}
                    path="pro_featured_consultants"
                  ></Route>
                  <Route
                    element={<Propostlist1 Auth={Auth} />}
                    path="pro_featured_vendors"
                  ></Route>
                  <Route
                    element={<InnovationGridView Auth={Auth} />}
                    path="/innovation_show"
                  />
                  <Route
                    element={<InnovationTlieList Auth={Auth} />}
                    path="/innovations"
                  />
                  <Route element={<TileMovies Auth={Auth} />} path="/movies" />
                  <Route
                    element={<MovieGridView Auth={Auth} />}
                    path="/movie_show"
                  />
                  <Route
                    element={<EmergencyServices Auth={Auth} />}
                    path="/emergency_services"
                  ></Route>
                  <Route
                    element={<AmbulanceServicesAnimalsList Auth={Auth} />}
                    path="/ambulance_services_animals"
                  ></Route>
                  <Route
                    element={<AmbulanceServicesHumans Auth={Auth} />}
                    path="/ambulance_services_humans"
                  ></Route>
                  <Route
                    element={<BloodDonors Auth={Auth} />}
                    path="/blood_donors"
                  ></Route>
                  <Route
                    element={<DeceasedAnimalBurialGrounds Auth={Auth} />}
                    path="/deceased_animal_burial_grounds"
                  ></Route>
                  <Route
                    element={<ShoutOutBlockedList Auth={Auth} />}
                    path="shoutOut_blocked_list"
                  ></Route>

                  <Route
                    element={<Ask_us_view Auth={Auth} />}
                    path="/ask_us_view"
                  />
                  <Route
                    element={<Prochure_Ask_Us_List Auth={Auth} />}
                    path="/ask_us"
                  />
                  <Route element={<ProForum Auth={Auth} />} path="/pro-forum" />

                  <Route element={<Newfind />} path="/my-pro-forums" />
                  <Route element={<Newidfind />} path="/Newidfind" />
                  <Route
                    element={<Information_Category_list />}
                    path="/information_category"
                  />
                  <Route
                    element={<Information_Subcategory_list />}
                    path="/information_subcategory"
                  />
                  <Route
                    element={<Information_Subsubcategory_list />}
                    path="/information_sub_sub_category"
                  />
                  <Route
                    element={<Information_listing />}
                    path="/Information_listing"
                  />
                  <Route
                    element={<Information_Type1 />}
                    path="/Information_profile"
                  />
                  <Route
                    element={<Information_Medical_Store />}
                    path="/Information_medical_store"
                  />
                  <Route
                    element={<Information_Medical_Store_profile />}
                    path="/Information_medical_store_profile"
                  />
                </Routes>
              </GlobalErrorHandler>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
