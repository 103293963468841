
import React,{useEffect,useState} from 'react'
import './Notification.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {BsThreeDotsVertical} from "react-icons/bs"
import axios from 'axios';

export const ChatNotification = () => {

useEffect(() => {
    var modal = document.getElementById("Notification");

    window.onclick = function (event) {
        if (event.target === modal) {
      
        }
        
        
    }
})

const [getval, setGetval] = useState();

useEffect(() => {
    // console.log('notowork')
    axios.get('/api/User/ChatNotification').then((res)=>{
        console.log(res.data)
        setGetval(res.data)
        
    }).catch((err)=>{
        console.log(err)
    })
   
}, [])
// console.log(getval)

    return (
        
        <div >
            {
                getval?
                getval.map((Element)=>{
                    console.log(Element)
                    return <div className=" notificationBg  s ">
            < >
                {/* <div id="Notification"  className="col-lg-8 col-md-3   "></div> */}
                < >
       
                        {/* <div className="text-center  mt-3">NOTIFICATIONS</div>
                        <hr /> */}
                     
                            {/* <div className="box-title border-bottom p-3">
                                <h6 className="m-0">Recent</h6>
                            </div> */}
                   

                                {/* <div className="box-title border-bottom p-3">
                                    <h6 className="m-0">Earlier</h6>
                                </div> */}
                                <div className="box-body p-0 mt-1">
            <>
            <div id="a12" className="p-3 border-bottom weingoucerh9guoheuozgb3o osahan-post-header mb-1" style={{"borderRadius":"7px"}}  data-bs-dismiss="offcanvas">
                                                    <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0">You&nbsp;have&nbsp;a&nbsp;new&nbsp;message&nbsp;in&nbsp;{Element.pname}  </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    </div>
                                                    </div>
                                                    </div>
                                               
                                            </div>
                                 
                                            </>

                                </div>
                     
                 
                </>
            </>


        </div>

                })
                :""
            }


        
        </div>
    )

}
