import React, { useState, useEffect, useRef, useReducer, createContext, useContext } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import "./CreateConseltent.css";
import { AiFillCamera,AiFillExclamationCircle } from "react-icons/ai";
import { FormConsultent } from "./FormConsultent";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";


export function ConvertCon(defaultSrc) {
  let navigate = useNavigate()


  const submit = async (formData) => {
    // console.log(value);
    return new Promise((resolve,reject)=>{
   
      axios
        .post("/api/ActiveUser/ConvertConProfile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
         
            navigate('/')
            window.location.reload();
           
          }
          resolve(true)
        })
        .catch((err) => {
          resolve(false)
          if (err.response.status == 304) {
            // toast warning
            return navigate('/')
          } else {
           
            return navigate('/')
          }
          
          
        });
      })
  };
  

 

  return (
    <>
    
    <div className="container rounded bg-white pt-5 pb-5">
    
        <FormConsultent postTo={submit} sab={true}/>
     
    </div>
    
    </>
  );
}
