import React, { useEffect } from "react";
import "./About.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

export function About() {
  useEffect(() => {
    document.getElementById("root").style.backgroundColor = "var(--blue)";
    return () => {
      document.getElementById("root").style.backgroundColor = "white";
    };
  }, []);

  return (
    <div className="subscrption_font container">
      <h5>INCEPTION</h5>
      <br />
      <p>
        <span style={{ color: "#fccc0a" }}>PROchure</span>
        <sup>®</sup> is a path-breaking concept envisioned by Architect Vinod
        Venugopal, a registered architect with the Council of Architecture
        (CA/2011/51213).
      </p>
      <br />
      <p>
        A ’96 batch graduate from M S Ramaiah Institute of Technology in
        Bangalore, Ar. Vinod with over 2 decades of professional experience and
        expertise in the field of architecture, has always been extremely
        passionate about creating innovative concepts.
      </p>
      <br />
      <p>
        <span style={{ color: "#fccc0a" }}>PROchure</span>
        <sup>®</sup> is an application developed by{" "}
        <span className=" monotypepolicies">Vipar</span> CONNECT, a wing of{" "}
        <span className=" monotypepolicies">Vipar</span> <sup>®</sup> (founded
        by Ar. Vinod)
      </p>
    </div>
  );
}
