import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useState, useRef } from "react";
import './jobPostForm.css'
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import Axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MenuProps, languages } from '../../Utility/languages'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { keyGen } from '../../Utility/helper';
import { AllCity, InitializeCountry,ExistingData } from '../../Utility/Country';


export const JobPostFormUpdate = (props) => {
    let countryRef = useRef();
    let stateRef = useRef();
    let cityRef = useRef();
    let AllCityRef = useRef()

    useEffect(() => {
        AllCity(AllCityRef)
        InitializeCountry(countryRef, stateRef, cityRef);
        return () => { };
    }, []);
    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("k")

    let navigate = useNavigate()

    const [File, setFile] = useState([])
    const formData = new FormData()
    const [first, setfirst] = useState(

    )

    const InterviewMode1 = useRef()
    const InterviewMode2 = useRef()
    const InterviewMode3 = useRef()



    const readyBond = useRef()


    const notify = (message) =>
        toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
   

    useEffect(() => {
        console.log(File)

    }, [File])

    useEffect(() => {
        Axios.get('/api/api/getcategory').then(res => {
            if (res.status === 200) {
                console.log('workd');
                setCat(res.data)
            }
        }).catch((err) => {

            console.log(err.response.status);
        })
    }, []);

    const [formDatas, setformDatas] = useState()

    var i = 0;
    const [Qstate, setQstate] = useState('Q1')
    function move(x) {

        if (i === 0) {
            i = 1;
            var elem = document.getElementById("myBar");
            var width = 1;
            var id = setInterval(frame, 100);
            function frame() {
                if (width >= 100 || width >= x) {
                    clearInterval(id);
                    i = 0;
                }
                else {
                    width += x;
                    elem.style.width = width + "%";
                    if (width > 99) {
                        elem.style.background = 'green'
                    }
                    else {
                        elem.style.background = '#733380'
                    }
                }
            }
        }
    }

    function Qchanger(nxt) {

        document.getElementById(Qstate).classList.add('d-none')
        document.getElementById(nxt).classList.remove('d-none')
        setQstate(nxt)
        var x = parseInt(nxt.slice(1))
        move(x * (100 / 11))
    }
    const [xCount, setxCount] = useState(false)

    function addMore(stat) {
        var addBtn = document.getElementById('addMoreBtn')
        var xP1 = document.getElementById('X1')     //first close button
        var xP2 = document.getElementById('X2')     //second close button
        var Op1 = document.getElementById('Op1')    // 1 added block
        var Op2 = document.getElementById('Op2')    //2nd added block

        if (stat) {     //add block
            setQ5({ ...Q5, qualificationNumber: Q5.qualificationNumber + 1 })
            if (xCount === 1) {
                setxCount(2)
                xP1.classList.add('d-none')
                Op2.classList.remove('d-none')
                addBtn.classList.add('d-none')
            }
            if (!xCount) {
                setxCount(1)
                Op1.classList.remove('d-none')
            }
        }
        else {      //remove block
            setQ5({ ...Q5, qualificationNumber: Q5.qualificationNumber - 1 })
            if (xCount === 1) {
                setQ5_2({ ...Q5_2, degree: '', special: '', college: '', sy: '', ey: '', certificateObtained: "" })
                setxCount(false)
                Op1.classList.add('d-none')
            }
            if (xCount === 2) {
                setxCount(1)
                setQ5_3({ ...Q5_3, degree: '', special: '', college: '', sy: '', ey: '', certificateObtained: "" })
                Op2.classList.add('d-none')
                xP1.classList.remove('d-none')
                addBtn.classList.remove('d-none')
            }
        }
    }
    function addMorePro(stat, xCount) {
        var addBtn = document.getElementById('addMoreBtn')
        var xP1 = document.getElementById('X1')     //first close button
        var xP2 = document.getElementById('X2')     //second close button
        var Op1 = document.getElementById('Op1')    // 1 added block
        var Op2 = document.getElementById('Op2')    //2nd added block

        if (stat) {     //add block
            setQ5({ ...Q5, qualificationNumber: Q5.qualificationNumber + 1 })
            if (xCount === 1) {
                setxCount(2)
                xP1.classList.add('d-none')
                Op2.classList.remove('d-none')
                addBtn.classList.add('d-none')
            }
            if (!xCount) {
                setxCount(1)
                Op1.classList.remove('d-none')
            }
        }
        else {      //remove block
            setQ5({ ...Q5, qualificationNumber: Q5.qualificationNumber - 1 })
            if (xCount === 1) {
                setxCount(false)
                Op1.classList.add('d-none')
            }
            if (xCount === 2) {
                setxCount(1)
                Op2.classList.add('d-none')
                xP1.classList.remove('d-none')
                addBtn.classList.remove('d-none')
            }
        }
    }

    // const makeSkill = (x) => {
    //     var val = x.value
    //     if (val.charAt(val.length - 1) === ' ') {
    //         var skill = val.trim()
    //         skill = skill.replace(/ +/g, "");

    //         if (skill.length >= 3) {
    //             x.value = ''
    //             // return console.log(skill.length+fina)
    //             x.style.color = 'black'
    //             addSkill(skill)
    //         }
    //         else {
    //             x.style.color = 'red'
    //         }
    //     }
    // }
    const makeSkill = (x, Lorum) => {
        console.log(Lorum)
        console.log(x)
        x = document.getElementById(x)

        var val = x.value
        if (val.charAt(val.length - 1) === ' ') {
            var skill = val.trim()
            skill = skill.replace(/ +/g, "");

            if (skill.length >= 3) {
                x.value = ''
                // return console.log(skill.length+fina)
                x.style.color = 'black'
                addSkill(skill)
            }
            else {
                x.style.color = 'red'
            }
        }
        if (Lorum) {
            var skill = val.trim()
            skill = skill.replace(/ +/g, "");

            if (skill.length >= 3) {
                x.value = ''
                // return console.log(skill.length+fina)
                x.style.color = 'black'
                addSkill(skill)
            }
            else {
                x.style.color = 'red'
            }
        }
    }

    const [Skill, setSkill] = useState([])
    const [Arr, setArr] = useState([])
    var listItems = ''
    const [AllSkills, setAllSkills] = useState([])

    const addSkill = (vall) => {
        vall = vall.trim()
        var dep = AllSkills
        dep.push(vall)
        setAllSkills(dep)
        var x = React.createElement('div',
            { className: 'p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1', role: 'alert' },
            [React.createElement('strong', {}, vall, <>&nbsp;&nbsp;&nbsp;</>,
                React.createElement('button', { onClick: (e) => removeSkill(vall), type: "button", className: "btn-close ms-5 Gbt p-0 pt-2 ", 'data-bs-dismiss': "alert", 'aria-label': "Close" }, ''),
            ),
            ]
        )
        Arr.push(x)
        listItems = Arr.map((myList) => {
            return <>{myList}</>;
        });
        setSkill(listItems)
        setQ12({ ...Q12, skills: AllSkills })
    }

    useEffect(() => {
        setQ12({ ...Q12, skills: AllSkills })
    }, [AllSkills])

    const removeSkill = (e) => {
        var index = AllSkills.indexOf(e)
        AllSkills.splice(index, 1);
        setQ12({ ...Q12, skills: AllSkills })
    }

   

    const [loc, setloc] = useState([])
    var locked = ''
    const [viewLoc, setviewLoc] = useState([])

    const locationSelection = (e) => {
        var st = loc.find(ef => ef === e)
        if (st) {
            return
        }
        loc.push(e)
        var len = 0
        locked = loc.map((myList) => {
            return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                </button>
            </div>;
        });
        setviewLoc(locked)
        setQ2({ ...Q2, jobLocations: loc })
    }
    const removeLocation = (e) => {
        var red = e.substring(1)
        red = red - 1
        loc.splice(red, 1);
        var len = 0
        locked = loc.map((myList) => {
            return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                </button>
            </div>;
        });
        setviewLoc(locked)
        setQ2({ ...Q2, jobLocations: loc })

    }
    useEffect(() => {
        if (viewLoc.length >= 4) {
            document.getElementById('jobLocation').setAttribute('disabled', 'true')
            document.getElementById('jobLocation').setAttribute('placeholder', 'Reached maximum')
        }
        else {
            document.getElementById('jobLocation').removeAttribute('disabled')
            document.getElementById('jobLocation').setAttribute('placeholder', '')
        }

    }, [viewLoc])


    const [jobTitles, setjobTitles] = useState([])



    const [optionLists, setoptionLists] = useState([])
    useEffect(() => {
        var rs = []
        jobTitles.forEach(element => {
            rs.push(<option key={'op' + keyGen()} value={element.jobTitle} />)

        });
        setoptionLists(rs)

    }, [jobTitles])





    const [JT, setJT] = useState([])
    var lockedJT = ''
    const [viewJT, setviewJT] = useState([])
    const jtSelection = (e) => {
        if (e === ' ') {
            return
        }
        if (e.length === 0) {
            return
        }
        var st = JT.find(ef => ef === e)
        if (st) {
            return
        }
        JT.push(e)
        var len = 0
        lockedJT = JT.map((myList) => {
            return <div className='disposeBox m-1' id={'JT' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'JTB' + (len)} onClick={(e) => removeJT(e.target.id)} >
                </button>
            </div>;
        });
        setviewJT(lockedJT)
        setQ2({ ...Q2, jobTitles: JT })
    }
    const removeJT = (e) => {
        // console.log('enter 1')
        var red = e.slice(-1)
        red = red - 1
        JT.splice(red, 1);
        var len = 0
        lockedJT = JT.map((myList) => {
            return <div className='disposeBox m-1' id={'JT' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'JTB' + (len)} onClick={(e) => removeJT(e.target.id)} >
                </button>
            </div>;
        });
        setviewJT(lockedJT)
        setQ2({ ...Q2, jobTitles: JT })
    }
    const addBtn = (e) => {
        if (e) {
            document.getElementById('addBtn').classList.remove('d-none')
        }

    }
    const addJobTitile = (e) => {
        var xp = document.getElementById('jobTitle').value
        jtSelection(xp)
        document.getElementById('jobTitle').value = ''
        document.getElementById('addBtn').classList.add('d-none')
    }
    useEffect(() => {
        // console.log('works')
        if (JT.length >= 4) {
            document.getElementById('jobTitle').setAttribute('disabled', 'true')
            document.getElementById('jobTitle').setAttribute('placeholder', 'Reached maximum')
        }
        else {
            document.getElementById('jobTitle').removeAttribute('disabled')
            document.getElementById('jobTitle').setAttribute('placeholder', '')
        }

    }, [viewJT])


    const [Q1, setQ1] = useState({
        country: "", states: '', city: '', name: '', dob: '', gender: '', InterviewMode: '', language: [], languageFluent: []

    })
    const [Q2, setQ2] = useState({
        jobTitles: [],
        jobLocations: [],
        mainCat: '',
        subCat: '',
        workPlace: '',
        empType: '',
        readyBond: false,
        Interest: ''
    })
    const [Q3, setQ3] = useState({
        jobTitle: '',
        company: '',
        location: '',
        currentlyWorking: true,
        breakSince: '',
        Reason: '',
        EndY: '',
        StartY: ''

    })
    const [Q4, setQ4] = useState({
        available: true,
        noticeDays: '',
        relocate: false
    })
    const [Q5, setQ5] = useState({
        qualificationNumber: 1,
        qualification: []
    })
    const [Q6, setQ6] = useState({
        years: '',
        months: ''
    })
    const [Q7, setQ7] = useState()
    const [Q8, setQ8] = useState({
        recentCTC: ''
    })
    const [Q9, setQ9] = useState({
        expectedCTC: ''
    })
    const [Q10, setQ10] = useState(false)
    const [Q11, setQ11] = useState({
        email: '',
        phone: ''
    })
    const [Q12, setQ12] = useState({
        about: '',
        hobbies: '',
        skills: [],
        Requisitions: ''
    })
    const [Q5_1, setQ5_1] = useState({
        college: '',
        degree: '',
        special: '',
        sy: '',
        ey: '',
        certificateObtained: ''
    })
    const [Q5_2, setQ5_2] = useState({
        college: '',
        degree: '',
        special: '',
        sy: '',
        ey: '',
        certificateObtained: ''
    })
    const [Q5_3, setQ5_3] = useState({
        college: '',
        degree: '',
        special: '',
        sy: '',
        ey: '',
        certificateObtained: ''
    })

    // Validation Starts here
    const [Error, setError] = useState(false)
    const [Error2, setError2] = useState(false)
    const [Error3, setError3] = useState(false)
    const [Error4, setError4] = useState(false)
    const [Error5, setError5] = useState(false)
    const [Error6, setError6] = useState(false)
    const [Error7, setError7] = useState(false)
    const [Error8, setError8] = useState(false)
    const [Error9, setError9] = useState(false)
    const [Error10, setError10] = useState(false)
    const [Error11, setError11] = useState(false)
    const [Error12, setError12] = useState(false)

    const v = () => {
        Qchanger('Q1')
    }
    const v1 = () => {

        if (!Q1.name.length > 0) {
            return setError('Enter your name')
        }
        if (!Q1.gender.length > 0) {
            return setError('Select your gender')
        }
        if (Q1.dob.length > 0) {
            console.log(Q1.dob);
            var age = Math.floor(
                parseInt(new Date() - new Date(Q1.dob)) / 1000 / 60 / 60 / 24 / 365
            );
            if (age > 17) {
                setError("");
            } else {
                return setError("Sorry ! You should be atleast 18 years of age");
            }

        } else {
            return setError("Select your date of birth");
        }

        if (Q1.language.length === 0) {
            return setError("Select language");
        }

        if (Q1.languageFluent.length === 0) {
            return setError("Select languages known to communicate fluently");
        }






        console.log(Q1.states)
        if (!Q1.states) {
            return setError('Select your state')
        }

        if (!Q1.city) {
            return setError('Select your city')
        }

        if (!Q11.email) {

            return setError('Enter your email')
        }
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Q11.email)) {
            setError('')
        }
        else {
            return setError('Invalid Email')
        }
        if (!Q11.phone) {
            return setError('Enter your contact number')
        }
        if (Q11.phone.length != 10) {
            return setError('Enter a valid contact number')
        }


        Qchanger('Q2')
        setError('')
    }
    const v2 = () => {
        if (!Q2.mainCat) {
            return setError2('Select a job category ')
        }
        if (!Q2.subCat) {
            return setError2('Select a job sub category ')
        }
        if (Q2.jobTitles.length < 1) {
            return setError2('Enter job title ')
        }
        if (Q2.jobLocations.length < 1) {
            return setError2('Select atleast one job location ')
        }

        if (!Q2.workPlace) {
            return setError2('Select a workplace mode ')
        }
        if (!Q2.empType) {
            return setError2('Select a employment type ')
        }
        if (Q12.skills.length == 0) {
            return setError2('Enter skills')
        }
        setError2('')
        Qchanger('Q3')
    }
    const v3 = () => {
        if (!Q3.jobTitle) {
            return setError3('Enter your recent job title')
        }
        if (!Q3.company) {
            return setError3('Enter your recent company name')
        }
        if (!Q3.location) {
            return setError3('Enter your recent job location')
        }
        if (!Q3.StartY) {
            return setError3('Enter starting date')
        }
        if (Q3.currentlyWorking) {

            // if (!Q3.EndY) {
            //     return setError3('Enter ending date')
            // }
            // console.log(Q3)
            // var a = Date.parse(Q3.StartY)
            // var b = Date.parse(Q3.EndY)
            // console.log(a)
            // if (a > b) {
            //     return setError3('Enter valid date')
            // }
        }
        else {
            if (!Q3.breakSince) {
                return setError3('Enter a valid date')
            }
            if (!Q3.Reason) {
                return setError3('Enter a reason ')
            }
        }

        if (!Q4.available) {
            if (!Q4.noticeDays) {
                return setError3('Enter number of days of notice period')
            }

            if (parseInt(Q4.noticeDays) < 1) {
                return setError3('Enter valid number of days of notice period')
            }

        }

        setError3('')
        Qchanger('Q4')
    }
    const v4 = () => {
        console.log(Q4)

        if (Q5.qualificationNumber >= 1) {
            if (!Q5_1.degree) {
                return setError4('Enter degree name')
            }
            // if (!Q5_1.special) {
            //     return setError4('Enter specialization')
            // }
            if (!Q5_1.college) {
                return setError4('Enter college name')
            }

            if (!Q5_1.sy) {
                return setError4('Enter Start year')
            }
            if (!Q5_1.ey) {
                return setError4('Enter End year')
            }
            var a = Date.parse(Q5_1.sy)
            var b = Date.parse(Q5_1.ey)
            console.log(Q5_1.certificateObtained)
            if (a > b) {
                return setError4('Enter valid year')
            }
            if (!Q5_1.certificateObtained) {

                return setError4('Degree Certificate ? ')

            }
        }
        if (Q5.qualificationNumber >= 2) {
            if (!Q5_2.degree) {
                return setError4('Enter degree name')
            }
            // if (!Q5_2.special) {
            //     return setError4('Enter specialization')
            // }
            if (!Q5_2.college) {
                return setError4('Enter college name')
            }

            if (!Q5_2.sy) {
                return setError4('Enter Start year')
            }
            if (!Q5_2.ey) {
                return setError4('Enter End year')
            }
            var a = Date.parse(Q5_1.sy)
            var b = Date.parse(Q5_1.ey)
            console.log(a)
            if (a > b) {
                return setError4('Enter valid year')
            }
            if (!Q5_2.certificateObtained) {

                return setError4('Degree Certificate ?')

            }
        }
        if (Q5.qualificationNumber >= 3) {
            if (!Q5_3.degree) {
                return setError4('Enter degree name')
            }
            // if (!Q5_3.special) {
            //     return setError4('Enter specialization')
            // }
            if (!Q5_3.college) {
                return setError4('Enter college name')
            }

            if (!Q5_3.sy) {
                return setError4('Enter Start year')
            }
            if (!Q5_3.ey) {
                return setError4('Enter End year')
            }
            var a = Date.parse(Q5_1.sy)
            var b = Date.parse(Q5_1.ey)
            console.log(a)
            if (a > b) {
                return setError4('Enter valid year')
            }
            if (!Q5_3.certificateObtained) {

                return setError4('Degree Certificate ?')

            }
        }
        setQ5({ ...Q5, qualification: { Q5_1, Q5_2, Q5_3 } })
        setError4('')


        Qchanger('Q5')
    }
    const v5 = () => {
        if (!Q7) {
            // return setError5('Upload cv or resume ')
        }
        if (!Q1.InterviewMode) {
            return setError5('Select type of interview process')

        }
        setError5('')

        Qchanger('Q6')
    }
    const v6 = () => {
        if (Q6.years.length > 3) {
            return setError6('Enter valid year')
        }
        if (Q6.years.length == 0) {
            return setError6('Enter years of experience ')
        }


        if (Q6.months.length != 0) {
            if (Q6.months < 12 && Q6.months > 0) {

            } else {
                return setError6('Enter a figure between 1 to 11')

            }
        }
        if (!Q8.recentCTC) {
            return setError6('Enter your last CTC')
        }
        if (!Q9.expectedCTC) {
            return setError6('Enter your expected salary')
        }

        setError6('')
        Qchanger('Q7')
    }
    const v7 = () => {
        if (!Q12.about) {
            return setError7('Write about yourself')
        }
        if (!Q12.hobbies) {
            return setError7('Enter your hobbies')
        }

        setError7('')

        upload1();



    }
    const v8 = () => {
        if (!Q8.recentCTC) {
            return setError8('Enter your last CTC')
        }
        setError8('')
        Qchanger('Q9')
    }
    const v9 = () => {
        if (!Q9.expectedCTC) {
            return setError9('Enter your expected salary')
        }
        setError9('')
        Qchanger('Q10')
    }
    const v10 = () => {
        // if (!Q10) {
        //     return setError10('Upload Your work')
        // }
        setError10('')
        Qchanger('Q11')
    }
    const v11 = () => {
        if (!Q11.email) {
            return setError11('Enter your email')
        }
        if (!Q11.phone) {
            return setError11('Enter your phone number')
        }
        setError11('')
        Qchanger('Q12')
    }
    const v12 = async () => {
        if (!Q12.about) {
            return setError12('Write about yourself')
        }
        if (!Q12.hobbies) {
            return setError12('Enter your hobbies')
        }
        if (Q12.skills.length == 0) {
            return setError12('Enter skills')
        }

        setError12('')
        console.log('done')

        // me();
    }
    // Validation Ends here

    const [loading, setloading] = useState()

    function upload1() {
        setloading(true)

        formData.delete('data')
        formData.delete('datas')

        console.log(File);
        console.log('first');
        var hell = {
            name: 'ananda',
            address: 'kishnan'
        }
        console.log(Q10)
        var cv
        var work

        var cv
        if (Q7) {
            var

                cv = Q7.name

        }


        var work
        if (Q10) {
            work = Q10.name
        }

        var final = {
            Q1,
            Q2,
            Q3,
            Q4,
            Q5,
            Q6,
            Q8,
            Q9,
            Q11,
            Q12,
            cv,
            work
        }
        console.log(final)
        const data = JSON.stringify(final)
        // console.log(data)
        // return
        formData.append("data", Q7);
        formData.append("data", Q10);
        formData.append("datas", data);


        console.log(formData)
        // return

        Axios.post('/api/User/JobSeekerPostUpdate', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',

                }
            }
        )
            .then(res => {
                if (res.status == 200) {
                    console.log('jobdetails ')
                    console.log(res.data)
                    // return navigate(`/home/jobseeker_view?k=${id}&&tosttype=${2}`)
                    notify("Post updateed successfully");

                    setTimeout(() => {

                        setloading(false)

                        return navigate(`/job_seeker_view?k=${id}`)
                    }, 1000);
                }
            }).catch((err) => {
                console.log(err.response.data)
                setloading(false)


            })
        console.log('first')



    }

    function Eyod(e) {
        // if (e.target.checked) {
        //     document.getElementById('EndY').classList.add('d-none')
        // } else {
        //     document.getElementById('EndY').classList.remove('d-none')
        // }
    }





    const [jobDetails, setjobDetails] = useState(false)
    const [Quli, setQuli] = useState()
    const [Qno, setQno] = useState()



    const [loader, setLoader] = useState(<div id='iooioooo1231312' className='container  d-flex mt-5 pt-5  justify-content-center p-3 p-md-5'><div class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
    </div></div>)
    const [Loading, setLoading] = useState(true)
    useEffect(() => {
        Axios.post('/api/User/JobSeekerView', { id })
            .then(res => {
                if (res.status === 200) {
                    setLoader(true)
                    console.log('jobView Edit')
                    console.log(res.data)
                    // console.log(Id)
                    res.data.data1 = res.data.data1[0]
                    setjobDetails(res.data.data1);
                    setQuli(res.data.JobSeeker_qualification)
                    setQno(res.data.JobSeeker_qualificationNo)
                    // getAge(jobDetails.JobSeeker_dob)

                    ExistingData(countryRef, stateRef, cityRef, res.data.data1.JobSeeker_country, res.data.data1.JobSeeker_state);

                    setQ1({
                        ...Q1,
                        id: res.data.data1._id,
                        name: res.data.data1.JobSeeker_Name,
                        dob: res.data.data1.JobSeeker_dob,
                        gender: res.data.data1.JobSeeker_gender,
                        country: res.data.data1.JobSeeker_country,
                        states: res.data.data1.JobSeeker_state,
                        city: res.data.data1.JobSeeker_city,
                        cv: res.data.data1.JobSeeker_cvfile,
                        work: res.data.data1.JobSeeker_workefile,
                        linkResume: res.data.Resume,
                        linkWork: res.data.Work,
                        InterviewMode: res.data.data1.JobSeeker_InterviewMode,
                        language: res.data.data1.JobSeeker_language,
                        languageFluent: res.data.data1.JobSeeker_languageFluent,
                        InterviewMode: res.data.data1.JobSeeker_InterviewMode,


                    })
                    setQ2({
                        ...Q2, jobTitles: res.data.data1.JobSeeker_jobTitles,
                        jobLocations: res.data.data1.JobSeeker_jobLocations,
                        mainCat: res.data.data1.JobSeeker_mainCat,
                        subCat: res.data.data1.JobSeeker_subCat,
                        workPlace: res.data.data1.JobSeeker_workPlace,
                        empType: res.data.data1.JobSeeker_empType,
                        readyBond: res.data.data1.JobSeeker_readyBond,
                        Interest: res.data.data1.JobSeeker_Interest

                    })
                    setQ3({
                        ...Q3, jobTitle: res.data.data1.JobSeeker_currentjobTitle,
                        company: res.data.data1.JobSeeker_company,
                        location: res.data.data1.JobSeeker_currentJoblocations,
                        currentlyWorking: res.data.data1.JobSeeker_currentlyWorking,
                        StartY: res.data.data1.JobSeeker_workingstart,
                        EndY: res.data.data1.JobSeeker_workingend,
                        breakSince: res.data.data1.JobSeeker_breakSince,
                        Reason: res.data.data1.JobSeeker_Reason,
                    })

                    setQ4({
                        ...Q4,
                        available: res.data.data1.JobSeeker_availability,
                        noticeDays: res.data.data1.JobSeeker_noticeDay,
                        relocate: res.data.data1.JobSeeker_relocate
                    })

                    setQ5({
                        ...Q5,
                        qualificationNumber: res.data.data1.JobSeeker_qualificationNo,
                        qualification: res.data.data1.JobSeeker_qualification
                    })
                    setQ6({
                        ...Q6,
                        years: res.data.data1.JobSeeker_expYears,
                        months: res.data.data1.JobSeeker_expMonths
                    })

                    setQ8({
                        ...Q8,
                        recentCTC: res.data.data1.JobSeeker_recentCTC


                    })
                    setQ9({
                        ...Q9,
                        expectedCTC: res.data.data1.JobSeeker_expectedCTC


                    })


                    setQ11({
                        ...Q11,
                        email: res.data.data1.JobSeeker_email,
                        phone: res.data.data1.JobSeeker_phone

                    })

                    setQ12({
                        about: res.data.data1.JobSeeker_about,
                        hobbies: res.data.data1.JobSeeker_hobbies,
                        skills: res.data.data1.JobSeeker_skills,
                        Requisitions: res.data.data1.JobSeeker_Requisitions
                    })

                    setQ5_1({
                        college: res.data.data1.JobSeeker_qualification[0].Q5_1.college,
                        degree: res.data.data1.JobSeeker_qualification[0].Q5_1.degree,
                        special: res.data.data1.JobSeeker_qualification[0].Q5_1.special,
                        sy: res.data.data1.JobSeeker_qualification[0].Q5_1.sy,
                        ey: res.data.data1.JobSeeker_qualification[0].Q5_1.ey,
                        certificateObtained: res.data.data1.JobSeeker_qualification[0].Q5_1.certificateObtained


                    })

                    setQ5_2({
                        college: res.data.data1.JobSeeker_qualification[0].Q5_2.college,
                        degree: res.data.data1.JobSeeker_qualification[0].Q5_2.degree,
                        special: res.data.data1.JobSeeker_qualification[0].Q5_2.special,
                        sy: res.data.data1.JobSeeker_qualification[0].Q5_2.sy,
                        ey: res.data.data1.JobSeeker_qualification[0].Q5_2.ey,
                        certificateObtained: res.data.data1.JobSeeker_qualification[0].Q5_2.certificateObtained

                    })
                    setQ5_3({
                        college: res.data.data1.JobSeeker_qualification[0].Q5_3.college,
                        degree: res.data.data1.JobSeeker_qualification[0].Q5_3.degree,
                        special: res.data.data1.JobSeeker_qualification[0].Q5_3.special,
                        sy: res.data.data1.JobSeeker_qualification[0].Q5_3.sy,
                        ey: res.data.data1.JobSeeker_qualification[0].Q5_3.ey,
                        certificateObtained: res.data.data1.JobSeeker_qualification[0].Q5_3.certificateObtained
                    })
                    if (res.data.data1.JobSeeker_qualification[0].Q5_2.college.length > 0) {
                        if (res.data.data1.JobSeeker_qualification[0].Q5_3.college.length > 0) {
                            addMorePro(true, false)
                            addMorePro(true, 1)
                        } else {
                            addMorePro(true, false)
                        }
                    }
                    if (res.data.data1.JobSeeker_InterviewMode === "Ready for Online Interviews") {
                        InterviewMode1.current.checked = true
                    }
                    else if (res.data.data1.JobSeeker_InterviewMode === "One-to-One Interviews") {
                        InterviewMode2.current.checked = true
                    }
                    else {
                        InterviewMode3.current.checked = true
                    }

                    if (res.data.data1.JobSeeker_readyBond === true || res.data.data1.JobSeeker_readyBond === "true") {
                        readyBond.current.checked = true
                    }




                    console.log("test" + res.data.data1.JobSeeker_cvfile)

                    if (res.data.data1._id) {
                        setLoading(false)
                    }
                }
            }).catch((err) => {
                console.log(err)
                setLoading(false)
                alert("somthing went wrong");
            })
    }, [])





   

    //  console.log("Haiiii"+Q1.jobTitles)

    useEffect(() => {
        var len = 0
        var JT = Q2.jobTitles
        if (JT) {

            var lockedJT = <div className='disposeBox m-1' id={'JT'} key={keyGen()} >
                {JT}
                <button className='btn-close pt-1' aria-label='Close' id={'JTB'} onClick={(e) => setviewJT()} >
                </button>
            </div>;

            setviewJT(lockedJT)
            setQ2({ ...Q2, jobTitles: JT })

        }


    }, [Q2.jobTitles])



    const [JobT, setJobT] = useState(true)
    useEffect(() => {
        if (JobT == true) {
            setJobT(false)

            setviewJT(Q2.jobTitles)
            // setoptionLists(Q2.jobTitles)

        }
    }, [Q2.jobTitles])





    const [Joob, setJoob] = useState(true)

    useEffect(() => {

        // loc.push(e)

        console.log("length+" + Q2.jobLocations.length)

        if (Q2.jobLocations.length >= 1) {
            if (Joob == true) {
                setloc(Q2.jobLocations)
                setJoob(false)
                var len = 0
                locked = Q2.jobLocations.map((myList) => {
                    return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                        {myList}
                        <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                        </button>
                    </div>;
                });
                setviewLoc(locked)


            }
        }

    }, [Q2.jobLocations])



    const [faq, setfaq] = useState(true)

    useEffect(() => {
        // console.log('poda')

        if (Q12.skills.length >= 1) {
            if (faq == true) {
                setfaq(false)
                Q12.skills.map((item) => {
                    const vall = item.trim()
                    var dep = AllSkills
                    dep.push(vall)
                    setAllSkills(dep)
                    var x = React.createElement('div',
                        { className: 'p-0 boXe px-2 alert alert-dismissible fade show m-0 mb-1 me-1', role: 'alert' },
                        [React.createElement('strong', {}, vall, <>&nbsp;&nbsp;&nbsp;</>,
                            React.createElement('button', { onClick: (e) => removeSkill(vall), type: "button", className: "btn-close ms-5 Gbt p-0 pt-2 ", 'data-bs-dismiss': "alert", 'aria-label': "Close" }, ''),
                        ),
                        ]
                    )
                    Arr.push(x)
                    listItems = Arr.map((myList) => {
                        return <>{myList}</>;
                    });
                    // var extskill= Q2.skills.map((item)=>{ 

                    //   return<>{item}</>   
                    // });
                    setSkill(listItems)//setSkill()////,extskill//Q2.skills
                });


            }
        }

    }, [Q12.skills])





    useEffect(() => {

        console.log(Q3.currentlyWorking)

    }, [Q3.currentlyWorking])

    // useEffect(() => {
    //  console.log(Q4)
    //  setQ4({...Q4,available:false})
    // }, [Q4])


    let load = true
    const [cat, setCat] = useState([])
    const [category, setCategory] = useState(false)
    const [subcategory, setSubcategory] = useState(false)

    const sabari = (e) => {
        console.log(e)
        var anadhan
        setQ2({ ...Q2, mainCat: e })
        cat.forEach(element => {
            console.log(element._id)
            if (element._id === e) {
                anadhan = element.sub.map((elements) => {
                    return <option value={elements._id}>{elements.sub}</option>
                })
            } else { }
        });
        setSubcategory(anadhan)
    }




    var sab = []
    useEffect(() => {
        if (cat.length >= 1) {
            cat.forEach(element => {
                sab.push(<option value={element._id}>{element.category}</option>)
            });
            setCategory(sab)
        } else {
            console.log('nothing')
        }
    }, [cat])

    useEffect(() => {
        if (cat.length > 0) {
            sabari(Q2.mainCat)
        }
        console.log(cat)
    }, [Q2.mainCat])


    const [Today, setToday] = useState(false)
    useEffect(() => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();

        setToday(year + '-' + month + '-' + day)
    }, [])
    useEffect(() => {
        if (Loading) {
            document.getElementById('iooioooo1231312').classList.add('d-none')
            document.getElementById('fgt1').classList.remove('d-none')
        }
    }, [Loading])


    return (

        <>
            <>{loader} </>
            <div id='fgt1' className="container mt-4 border d-none p-3 p-md-5 ">
                <div className='bg-ligjht container cont py-3 px-3' style={{
                    position: '',
                    width: '100%',
                    right: '0'
                }} >
                    <center>
                        <div className="" id="myProgress">
                            <div id="myBar"></div>
                        </div>
                    </center>

                </div>

                <div id="Q1" className={"container cont d-nne p-3 p-md-5 "}>
                    <div className="row mt-3">
                        <h5 className="text-center ">Personal Information </h5>
                        <div className="col-md-12"><label className="labels fw-light">Name *</label>
                            <input onChange={(e) => setQ1({ ...Q1, name: e.target.value })} value={Q1.name} type="text" className="form-control" />
                        </div>

                        <div className="col-md-12"><label className="labels fw-light">Gender *</label><br></br>
                            {Q1.gender ? Q1.gender == 'Male' ?
                                <>

                                    &nbsp; &nbsp; &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Male" defaultChecked /> Male
                                    &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Female" /> Female
                                    &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Transgender" /> Transgender
                                </> : Q1.gender == "Female" ? <>

                                    &nbsp; &nbsp; &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Male" /> Male
                                    &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Female" defaultChecked /> Female
                                    &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Transgender" /> Transgender
                                </> : <>&nbsp; &nbsp; &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Male" /> Male
                                    &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Female" /> Female
                                    &nbsp; &nbsp;<input onChange={(e) => setQ1({ ...Q1, gender: e.target.value })} type="radio" className="form-check-input" name="gender" value="Transgender" defaultChecked /> Transgender </>
                                : ""}
                        </div>

                        <div className="col-md-12"><label className="labels fw-light">Date Of Birth *</label>
                            <input onChange={(e) => setQ1({ ...Q1, dob: e.target.value })} max={Today} type="date" className='form-control' value={Q1.dob ? Q1.dob.slice(0, 10) : ""} />
                        </div>



                        <div className="col-md-12">
                            {/* working */}
                            <label className="labels  fw-light">Languages Known *</label>

                            <FormControl className="form-control">
                                <Select
                                    id="demo-multiple-checkbox"
                                    multiple
                                    className="form-select"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            display: "none"
                                        }, '& .MuiSvgIcon-root': {
                                            display: 'none',
                                        },
                                        '& .MuiSelect-select': {
                                            padding: '0'
                                        }
                                    }}
                                    value={Q1.language}
                                    onChange={(e) =>
                                        setQ1({ ...Q1, language: e.target.value })
                                    }
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {languages.map((languages) => (
                                        <MenuItem key={languages} value={languages}>
                                            <Checkbox checked={Q1.language.indexOf(languages) > -1} />
                                            <ListItemText primary={languages} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </div>
                        <div className="col-md-12">
                            {/* working */}
                            <label className="labels  fw-light">Languages Known to Communicate Fluently *</label>

                            <FormControl className="form-control">
                                <Select
                                    id="demo-multiple-checkbox"
                                    multiple
                                    className="form-select"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            display: "none"
                                        }, '& .MuiSvgIcon-root': {
                                            display: 'none',
                                        },
                                        '& .MuiSelect-select': {
                                            padding: '0'
                                        }
                                    }}
                                    value={Q1.languageFluent}
                                    onChange={(e) =>
                                        setQ1({ ...Q1, languageFluent: e.target.value })
                                    }
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {languages.map((languages) => (
                                        <MenuItem key={languages} value={languages}>
                                            <Checkbox checked={Q1.languageFluent.indexOf(languages) > -1} />
                                            <ListItemText primary={languages} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </div>


                        <div className="col-md-12"><label className="labels fw-light">Country *</label>
                            <select className="form-select " ref={countryRef} value={Q1.country} onChange={(e) => setQ1({ ...Q1, country: e.target.value, states: '', city: '' })} />
                        </div>
                        <div className="col-md-12"><label className="labels fw-light">State *</label>
                            <select className="form-select" ref={stateRef} value={Q1.states} onChange={(e) => setQ1({ ...Q1, states: e.target.value, city: '' })} />
                        </div>
                        <div className="col-md-12 mt-2"><label className="labels fw-light " >City *</label>
                            <select name="City" ref={cityRef} className="form-select" value={Q1.city} onChange={(e) => setQ1({ ...Q1, city: e.target.value })} >
                                {/* <option>Select City</option> */}
                            </select>
                        </div>


                        <div className="col-md-12">
                            <label className="labels fw-light">Email *</label>
                            <input onChange={(e) => setQ11({ ...Q11, email: e.target.value })} type="email" className="form-control" accept="application/pdf" value={Q11.email} />
                        </div>
                        <div className="col-md-12 mt-2">
                            <label className="labels fw-light">Contact Number *</label>
                            <input onChange={(e) => { if (e.target.value.length == 11) return false; setQ11({ ...Q11, phone: e.target.value }) }} type="tel" className="form-control" accept="application/pdf" value={Q11.phone} />
                        </div>

                        <p className='text-danger m-0'>{Error ? Error : ''}</p>
                        <div className="col-md-12">
                            <input type="button" onClick={() => v1()} className="btn  w-100 mt-3 pro-spaces-button4" value="Next" />
                        </div>
                    </div>
                </div>
                <div id="Q2" className="container cont d-none  p-3 p-md-5">
                    <p className="mb-0"> <button className="spBtn" onClick={() => Qchanger('Q1')}><BsArrowLeft size="20" /></button></p>

                    <div className="row mt-3">
                        <h5 className="text-center">Job Preferences</h5>

                        {/* <div className="col-md-12 d-none">
                        <label className="labels fw-light">Job titles *</label>
                        <div id='jobTit'>
                            {viewJT ? viewJT : ''}
                        </div>
                        <div className="input-group ">
                            <input id='jobTitle' type="text" list="browsers" onChange={(e) => addBtn(e.target.value)} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <datalist id="browsers">
                                {optionLists ? optionLists : ''}
                            </datalist>
                            <div id='addBtn' className="input-group-append d-none">
                                <button onClick={() => addJobTitile()} className="btn btn-outline-secondary" type="button">Add</button>
                            </div>
                        </div>

                    </div> */}

                        <div className="mb-1 mb-md-3">
                            <label htmlFor="" className="labels fw-light">Job Category *</label>
                            <select value={Q2.mainCat} onChange={(e) => sabari(e.target.value)} type="text" className="form-select" >
                                {/* <option value="none" selected>Select</option> */}
                                <option value="" disabled selected>
                                    {/* Select your Category */}
                                </option>
                                {category ? category : ''}
                            </select>  {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>

                        <div className="mb-1 mb-md-3">
                            <label htmlFor="" className="labels fw-light">Job Sub Category *</label>
                            <select value={Q2.subCat} onChange={(e) => setQ2({ ...Q2, subCat: e.target.value })} type="text" className="form-select" >
                                <option value="" disabled selected>
                                    {/* Select your Sub-category */}
                                </option>
                                {subcategory ? subcategory : ''}
                            </select> {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>

                        <div className="mb-1 mb-md-3">
                            <label htmlFor="" className="labels fw-light">Job Title *</label>
                            <input id='jobTitle' type="text" list="browsers" className="form-control" onChange={(e) => setQ2({ ...Q2, jobTitles: e.target.value })} value={Q2.jobTitles} aria-label="Recipient's username" aria-describedby="basic-addon2" />

                        </div>
                        <div className="col-md-12  mt-2">
                            <label className="labels fw-light">Job Locations *</label>
                            <p style={{ 'fontSize': '10px' }} className="text-start my-2">You can select up to 4 preferred locations</p>
                            <div id='loc'>
                                {viewLoc ? viewLoc : ''}
                            </div>

                            <select ref={AllCityRef} onChange={(e) => locationSelection(e.target.value)} type="text" id='jobLocation' className="form-select" >
                            </select>
                        </div>
                        <div className="col-md-12  mt-2">
                            <label className="labels fw-light">Work Mode *</label>
                            <select value={Q2.workPlace} onChange={(e) => setQ2({ ...Q2, workPlace: e.target.value })} type="text" className="form-select" >
                                <option value="" disabled selected>
                                </option>
                                <option>On-site</option>
                                <option>Remote</option>
                                <option>Hybrid</option>
                            </select>
                        </div>
                        <div className="col-md-12  mt-2">
                            <label className="labels fw-light">Employment Type *</label>
                            <select type="text" value={Q2.empType} onChange={(e) => setQ2({ ...Q2, empType: e.target.value })} className="form-select" >
                                <option value="" disabled selected>
                                </option>
                                <option>Full-time</option>
                                <option>Part-time</option>
                            </select>
                        </div>

                        <div className="col-md-12 p-1 mt-2">
                            <label className="labels my-1 ms-2 fw-light">Key Skills *</label>
                            {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                            <div className="row ps-4">

                                {Skill ? Skill : ''}

                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <div className="input-group w-100">
                                <input maxLength='18' className='form-control' id="skills" onChange={(e) => makeSkill(e.target.id)} type="text" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button className="btn pro-spaces-button3" onClick={(e) => makeSkill('skills', true)} type="button" id="button-addon2">Add</button>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <label className="labels fw-light">Areas of Interest</label>
                            <textarea onChange={(e) => setQ2({ ...Q2, Interest: e.target.value })} style={{
                                height: '85px'
                            }} className="form-control " value={Q2.Interest} />
                        </div>

                        <div className="col-md-12  mt-3">


                            <div className="form-check ms-1">
                                <input className="form-check-input" onChange={(e) => setQ2({ ...Q2, readyBond: (!Q2.readyBond) })} type="checkbox" value="" ref={readyBond} id="flexCheckCheckedDisabled" />
                                <label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
                                    I am ready for a bond agreement job
                                </label>
                            </div>

                        </div>
                        <p className='text-danger'>{Error2 ? Error2 : ''}</p>
                        <div className="col-md-12">
                            <input type="button" onClick={() => v2()} className="btn  w-100 mt-3 pro-spaces-button4" value="Next" />
                        </div>
                    </div>
                </div>
                <div id="Q3" className="container cont d-none p-3 p-md-5">
                    <p className="mb-0"> <button className="spBtn" onClick={() => Qchanger('Q2')}><BsArrowLeft size="20" /></button></p>

                    <div className="row mt-3">
                        <h5 className="text-center">Current / Immediate Previous Occupational Status</h5>
                        <div className="col-md-12"><label className="labels fw-light">Job Title *</label>
                            <input onChange={(e) => setQ3({ ...Q3, jobTitle: e.target.value })} value={Q3.jobTitle} type="text" className="form-control" />
                        </div>
                        <div className="col-md-12"><label className="labels fw-light">Company / Organization *</label>
                            <input onChange={(e) => setQ3({ ...Q3, company: e.target.value })} value={Q3.company} type="text" className="form-control" />
                        </div>
                        <div className="col-md-12  mt-2"><label className="labels fw-light">Job Location *</label>
                            <input onChange={(e) => setQ3({ ...Q3, location: e.target.value })} value={Q3.location} type="text" className="form-control" />
                        </div>

                        <div className='col-md-12  mt-2'>
                            <label className="labels fw-light">Start Year *</label>
                            <input onChange={(e) => setQ3({ ...Q3, StartY: e.target.value })} value={Q3.StartY} max={Today} type="month" className="form-control w-100" /></div>


                        {/* <div className="col-md-12  mt-2"><label className="labels fw-light">Years of experience *</label>
                        <input onChange={(e) => setQ3({ ...Q3, yearos: e.target.value })} type="number" className="form-control" />
                    </div> */}
                    </div>
                    <div className="row  mt-3">


                        {Q3.currentlyWorking ?

                            <>
                                <div className="form-check col-md-6 ps-5  mt-2 ">
                                    <input name='a' onChange={(e) => setQ3({ ...Q3, currentlyWorking: (true), breakSince: '', Reason: '' })} onClick={(e) => Eyod(e)} defaultChecked={true} className="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I am currently working here
                                    </label>
                                </div>
                                <div className="form-check col-md-6 ps-5  mt-2 ">
                                    <input name='a' onChange={(e) => setQ3({ ...Q3, currentlyWorking: (false) })} onClick={(e) => Eyod(e)} className="form-check-input" type="radio" value="" id="flexCheckDefaultrty" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaultrty">
                                        I am on a break from work
                                    </label>
                                </div>
                            </>
                            :
                            <>
                                <div className="form-check col-md-6 ps-5  mt-2 ">
                                    <input name='a' onChange={(e) => setQ3({ ...Q3, currentlyWorking: (true), breakSince: '', Reason: '' })} onClick={(e) => Eyod(e)} className="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I am currently working here
                                    </label>
                                </div>
                                <div className="form-check col-md-6 ps-5  mt-2 ">
                                    <input name='a' onChange={(e) => setQ3({ ...Q3, currentlyWorking: (false) })} onClick={(e) => Eyod(e)} defaultChecked={true} className="form-check-input" type="radio" value="" id="flexCheckDefaultrty" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaultrty">
                                        I am on a break from work
                                    </label>
                                </div>
                            </>
                        }



                    </div>
                    <div className="row mt-3">
                        {!Q3.currentlyWorking ? <>


                            <div className="col-md-6  mt-2"><label className="labels fw-light">On a break since *</label>
                                <input onChange={(e) => setQ3({ ...Q3, breakSince: (e.target.value) })} value={Q3.breakSince} max={Today} type="month" className="form-control " />
                            </div>


                            <div className="col-md-12  mt-2"><label className="labels fw-light">Reason *</label>
                                <textarea onChange={(e) => setQ3({ ...Q3, Reason: (e.target.value) })} value={Q3.Reason} type="text" className="form-control " />
                            </div>
                        </>
                            :
                            ""
                        }


                    </div>
                    <div className="row mt-1">




                        {Q4.available ?
                            <>
                                <div className="form-check col-md-6 ps-5 mt-2">
                                    <input defaultChecked={true} name='as' onChange={(e) => setQ4({ ...Q4, available: (!Q4.available), noticeDays: '' })} className="form-check-input" type="radio" value="" id="flexCheckDefault1" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault1">
                                        I am available for Immediate Joining
                                    </label>
                                </div>

                                <div className="form-check col-md-6 ps-5 mt-3 ">
                                    <input name='as' onChange={(e) => setQ4({ ...Q4, available: (!Q4.available) })} className="form-check-input" type="radio" value="" id="flexCheckDefault2" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault2rtr">
                                        No, I am on my Notice Period
                                    </label>
                                </div>
                            </>
                            :
                            <>
                                <div className="form-check col-md-6 ps-5 mt-2">
                                    <input name='as' onChange={(e) => setQ4({ ...Q4, available: (!Q4.available), noticeDays: '' })} className="form-check-input" type="radio" value="" id="flexCheckDefault1" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault1">
                                        I am available for immediate joining
                                    </label>
                                </div>

                                <div className="form-check col-md-6 ps-5 mt-3 ">
                                    <input name='as' onChange={(e) => setQ4({ ...Q4, available: (!Q4.available) })} defaultChecked={true} className="form-check-input" type="radio" value="" id="flexCheckDefault2" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault2rtr">
                                        I am on my Notice Period
                                    </label>
                                </div>
                            </>
                        }




                    </div>
                    <div className="row mt-3">
                        {!Q4.available ?
                            <div className="col-md-12  mt-2">
                                <label className="labels fw-light">Notice period in days *</label>
                                <input type="number" onChange={(e) => { if (e.target.value.length == 3) return false; setQ4({ ...Q4, noticeDays: e.target.value }) }} value={Q4.noticeDays} className="form-control" />
                            </div> : ''}


                        {Q4.relocate == true ?
                            <div className="form-check col-md-12  mt-2 ">
                                <div className="form-check ms-0">
                                    <input className="form-check-input" onClick={(e) => setQ4({ ...Q4, relocate: (!Q4.relocate) })} defaultChecked={true} type="checkbox" id="flexCheckCheckedDisabledxyz" />
                                    <label className="form-check-label" htmlFor="flexCheckCheckedDisabledxyz">
                                        I am ready to re-locate
                                    </label>
                                </div>
                            </div> :
                            <div className="form-check col-md-12  mt-2 ">
                                <div className="form-check ms-0">
                                    <input className="form-check-input" onClick={(e) => setQ4({ ...Q4, relocate: (!Q4.relocate) })} type="checkbox" id="flexCheckCheckedDisabledxyz" />
                                    <label className="form-check-label" htmlFor="flexCheckCheckedDisabledxyz">
                                        I am ready to re-locate
                                    </label>
                                </div>
                            </div>

                        }
                        {/* {console.log(Q4.relocate)} */}
                        <p className='text-danger m-0'>{Error3 ? Error3 : ''}</p>
                        <div className="col-md-12">
                            <input onClick={() => v3()} type="button" className="btn  w-100 mt-3 pro-spaces-button4" value="Next" />
                        </div>
                    </div>
                </div>
                <div id="Q4" className="container cont d-none  p-3 p-md-5">
                    <p className="mb-0"> <button className="spBtn" onClick={() => Qchanger('Q3')}><BsArrowLeft size="20" /></button></p>

                    <div className="row mt-3">
                        <h5 className="text-center">Educational Qualifications</h5>
                        {/* <p className="text-center">Provide your highest educational qualification</p> */}
                        <div className="col-md-12 mt-2"><label className="labels fw-light">Degree *</label>
                            <input onChange={(e) => setQ5_1({ ...Q5_1, degree: e.target.value })} value={Q5_1.degree} type="text" className="form-control" />
                        </div>
                        <div className="col-md-12 mt-2"><label className="labels fw-light">Specialization </label>
                            <input onChange={(e) => setQ5_1({ ...Q5_1, special: e.target.value })} type="text" className="form-control" value={Q5_1.special} />
                            <div className="col-md-12"><label className="labels fw-light">College / University *</label>
                                <input onChange={(e) => setQ5_1({ ...Q5_1, college: e.target.value })} type="text" className="form-control" value={Q5_1.degree} />
                            </div>
                        </div>
                        <div className="col-md-12 mt-2"><label className="labels fw-light">Course Period *</label>
                            <div className="row">
                                <div className="col-6">  <label className="labels fw-light"> Start Year</label>  <input type="month" max={Today} value={Q5_1.sy} onChange={(e) => setQ5_1({ ...Q5_1, sy: e.target.value })} className="form-control" /></div>
                                <div className="col-6"> <label className="labels fw-light"> End Year</label>  <input type="month" value={Q5_1.ey} onChange={(e) => setQ5_1({ ...Q5_1, ey: e.target.value })} className="form-control" /></div>
                            </div>
                        </div>
                        <div className="col-md-12"><label className="labels fw-light">Obtained Degree Certificate * </label>
                            {/* <input onChange={(e) => setQ5_1({ ...Q5_1, college: e.target.value })} type="text" className="form-control" /> */}
                            <select onChange={(e) => setQ5_1({ ...Q5_1, certificateObtained: e.target.value })} value={Q5_1.certificateObtained} className="form-select">
                                <option value="" desabled selected></option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>

                        <div id="Op1" className="d-none mt-1">
                            <hr />
                            <p id="X1" className="mb-0  mt-3   " style={{ "backgroundColor": "var(--blue)" }}>&nbsp; <button className="spBtn float-end p-0" onClick={() => addMore(false)}><AiOutlineClose color="white" style={{
                                marginTop: '-4px'
                            }} className="p-0" size="20" /></button></p>

                            {/* <p className="text-center  mt-2">Provide your next higher educational qualification</p> */}
                            <div className="col-md-12 mt-2"><label className="labels fw-light">Degree *</label>
                                <input onChange={(e) => setQ5_2({ ...Q5_2, degree: e.target.value })} value={Q5_2.degree} type="text" className="form-control" />
                            </div>
                            <div className="col-md-12 mt-2"><label className="labels fw-light">Specialization </label>
                                <input type="text" onChange={(e) => setQ5_2({ ...Q5_2, special: e.target.value })} value={Q5_2.special} className="form-control" />
                            </div>
                            <div className="col-md-12"><label className="labels fw-light">College / University *</label>
                                <input onChange={(e) => setQ5_2({ ...Q5_2, college: e.target.value })} type="text" className="form-control" value={Q5_2.college} />
                            </div>
                            <div className="col-md-12 mt-2"><label className="labels fw-light">Course Period *</label>
                                <div className="row">
                                    <div className="col-6"> <label className="labels fw-light"> Start Year</label>  <input max={Today} onChange={(e) => setQ5_2({ ...Q5_2, sy: e.target.value })} value={Q5_2.sy} type="month" className="form-control" /></div>
                                    <div className="col-6"> <label className="labels fw-light"> End Year</label>  <input onChange={(e) => setQ5_2({ ...Q5_2, ey: e.target.value })} value={Q5_2.ey} type="month" className="form-control" /></div>
                                </div>
                            </div>
                            <div className="col-md-12"><label className="labels fw-light">Obtained Degree Certificate * </label>

                                <select onChange={(e) => setQ5_2({ ...Q5_2, certificateObtained: e.target.value })} value={Q5_2.certificateObtained} className="form-select">
                                    <option value="" desabled selected></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>

                        </div>

                        <div id="Op2" className="d-none mt-2">
                            <hr />
                            <p id="X2" className="mb-0  mt-3 " style={{ "backgroundColor": "var(--blue)" }}>&nbsp; <button className="spBtn float-end p-0" onClick={() => addMore(false)}><AiOutlineClose color="white" style={{
                                marginTop: '-4px'
                            }} className="p-0" size="20" /></button></p>
                            {/* <p className="text-center  mt-2">Provide your next higher educational qualification</p> */}
                            <div className="col-md-12  mt-2"><label className="labels fw-light">Degree *</label>
                                <input type="text" onChange={(e) => setQ5_3({ ...Q5_3, degree: e.target.value })} value={Q5_3.degree} className="form-control" />
                            </div>
                            <div className="col-md-12 mt-2"><label className="labels fw-light">Specialization </label>
                                <input type="text" onChange={(e) => setQ5_3({ ...Q5_3, special: e.target.value })} value={Q5_3.special} className="form-control" />
                            </div>
                            <div className="col-md-12"><label className="labels fw-light">College / University *</label>
                                <input type="text" onChange={(e) => setQ5_3({ ...Q5_3, college: e.target.value })} value={Q5_3.college} className="form-control" />
                            </div>
                            <div className="col-md-12 mt-2"><label className="labels fw-light">Course Period *</label>
                                <div className="row">
                                    <div className="col-6">  <label className="labels fw-light"> Start Year</label>  <input max={Today} value={Q5_3.sy} onChange={(e) => setQ5_3({ ...Q5_3, sy: e.target.value })} type="month" className="form-control" /></div>
                                    <div className="col-6">  <label className="labels fw-light"> End Year</label>  <input onChange={(e) => setQ5_3({ ...Q5_3, ey: e.target.value })} value={Q5_3.ey} type="month" className="form-control" /></div>
                                </div>
                            </div>
                            <div className="col-md-12"><label className="labels fw-light">Obtained Degree Certificate * </label>
                                {/* <input onChange={(e) => setQ5_1({ ...Q5_1, college: e.target.value })} type="text" className="form-control" /> */}
                                <select onChange={(e) => setQ5_3({ ...Q5_3, certificateObtained: e.target.value })} value={Q5_3.certificateObtained} className="form-select">
                                    <option value="" desabled selected></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>

                        </div>

                        <div id="addMoreBtn" className="col-md-12">
                            <button onClick={() => addMore(true)} className="btn pro-spaces-button4 mt-3 text-light ">Add more</button>
                        </div>
                        <p className='text-danger m-0'>{Error4 ? Error4 : ''}</p>
                        <div className="col-md-12">
                            <input onClick={() => v4()} type="button" className="btn  w-100 mt-3 pro-spaces-button4" value="Next" />
                        </div>
                    </div>

                </div>
                <div id="Q5" className="container cont d-none   p-3 p-md-5">
                    <p className="mb-0"> <button className="spBtn" onClick={() => Qchanger('Q4')}><BsArrowLeft size="20" /></button></p>
                    <div className="row mt-3">
                        <h5 className="text-center">CV / Resumé and Work Portfolio</h5>
                        <p className="text-center">(upload file/files in pdf format only)</p>
                        <div className="row">
                            <div className='col-6 '>
                                <a target='_blank' className='btn  pro-spaces-button4 me-1 w-100' href={Q1.linkResume} >&nbsp;Resume</a>
                            </div>
                            <div className='col-6'>
                                <input onChange={(e) => { setQ7(e.target.files[0]) }} type="file" className="form-control  w-100" accept="application/pdf" />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <label className="labels fw-light">Showcase your works to get better results</label>
                            {
                                Q1.work != "NULL" || null ?
                                    <div className="row">
                                        <div className='col-6 '>
                                            {
                                                Q1.work != "NULL" || null ?
                                                    <a target='_blank' className='btn pro-spaces-button4 me-1 w-100' href={Q1.linkWork}>&nbsp;Works</a>
                                                    :
                                                    ""
                                            }

                                        </div>
                                        <div className='col-6'>
                                            <input onChange={(e) => setQ10(e.target.files[0])} type="file" className="form-control  w-100" accept="application/pdf" />
                                        </div>
                                    </div>
                                    :
                                    <input type="file" onChange={(e) => setQ10(e.target.files[0])} className="form-control" accept="application/pdf" />



                            }

                            <div className="col-md-12"><label className="labels fw-light">Interview Process *</label><br></br>


                                <input onChange={(e) => setQ1({ ...Q1, InterviewMode: e.target.value })} ref={InterviewMode1} type="radio" className="form-check-input" name="InterviewMode" value="Ready for Online Interviews" />
                                &nbsp;&nbsp;Ready&nbsp;for&nbsp;Online&nbsp;Interviews&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp; <input onChange={(e) => setQ1({ ...Q1, InterviewMode: e.target.value })} ref={InterviewMode2} type="radio" className="form-check-input" name="InterviewMode" value="One-to-One Interviews" />
                                &nbsp;&nbsp;<span style={{ whiteSpace: "nowrap" }}>One-to-One Interviews</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp; <span style={{ whiteSpace: "nowrap" }}><input onChange={(e) => setQ1({ ...Q1, InterviewMode: e.target.value })} ref={InterviewMode3} type="radio" className="form-check-input" name="InterviewMode" value="Both" />&nbsp;&nbsp;Both</span>
                            </div>

                        </div>
                        <p className='text-danger m-0'>{Error5 ? Error5 : ''}</p>
                        <div className="col-md-12">
                            <input onClick={() => v5()} type="button" className="btn  w-100 mt-3 pro-spaces-button4" value="Next" />
                        </div>
                    </div>

                </div>
                <div id="Q6" className="container cont d-none  p-3 p-md-5">
                    <p className="mb-0"> <button className="spBtn" onClick={() => Qchanger('Q5')}><BsArrowLeft size="20" /></button></p>
                    <div className="row g-1 mt-3">
                        <h5 className="text-center">Work Experience, Latest CTC and Salary Expectation </h5>
                        <div className="col-md-6">
                            <label className="labels fw-light">Work experience in Years *</label>
                            <input onChange={(e) => { if (e.target.value.length == 3) return false; setQ6({ ...Q6, years: e.target.value }) }} type="number" value={Q6.years} className="form-control" />
                        </div>
                        <div className="col-md-6  "><label className="labels fw-light">Work experience in Months </label>
                            <input onChange={(e) => { if (e.target.value.length == 3) return false; setQ6({ ...Q6, months: e.target.value }) }} type="number" value={Q6.months} className="form-control" />
                        </div>



                    </div>
                    <div className="row g-1 mt-1">
                        {/* <h5 className="text-center">Latest CTC & Salary Expectation</h5> */}
                        {/* <p className="text-center">Provide your last CTC </p> */}
                        <div className="col-md-12">
                            <label className="labels fw-light">Recent CTC in LPA (Lakh Per Annum) *</label>
                            <input onChange={(e) => { if (e.target.value.length == 5) return false; setQ8({ ...Q8, recentCTC: e.target.value }) }} value={Q8.recentCTC} type="number" className="form-control" accept="application/pdf" />
                        </div>
                        <div className="col-md-12">
                            <label className="labels fw-light">Expected Salary in LPA (Lakh Per Annum) *</label>
                            <input onChange={(e) => { if (e.target.value.length == 5) return false; setQ9({ ...Q9, expectedCTC: e.target.value }) }} value={Q9.expectedCTC} type="number" className="form-control" accept="application/pdf" />
                        </div>
                        <p className='text-danger m-0'>{Error6 ? Error6 : ''}</p>
                        <div className="col-md-12">
                            <input onClick={() => v6()} type="button" className="btn  w-100 mt-3 pro-spaces-button4" value="Next" />
                        </div>
                    </div>

                </div>
                <div id="Q7" className="container cont  d-none   p-3 p-md-5">
                    <p className="mb-0"> <button className="spBtn" onClick={() => Qchanger('Q6')}><BsArrowLeft size="20" /></button></p>
                    <div className="row mt-3">
                        <h5 className="text-center">Self Description</h5>
                        <p className="text-center mb-0">Say something about yourself</p>
                        <div className="col-md-12">
                            <label className="labels fw-light"></label>
                            <textarea onChange={(e) => setQ12({ ...Q12, about: e.target.value })} type="number" style={{
                                height: '200px'
                            }} className="form-control " value={Q12.about} />
                        </div>
                        <div className="col-md-12  mt-2">
                            <label className="labels my-1  ms-0 fw-light">Hobbies *</label>
                            <textarea onChange={(e) => setQ12({ ...Q12, hobbies: e.target.value })} className='form-control' value={Q12.hobbies}></textarea>
                        </div>

                        <div className="col-md-12  mt-2">
                            <label className="labels my-1  ms-0 fw-light">Requisitions to Organization / Employer</label>
                            <textarea onChange={(e) => setQ12({ ...Q12, Requisitions: e.target.value })} value={Q12.Requisitions} className='form-control'></textarea>
                        </div>
                        <p className='text-danger m-0'>{Error7 ? Error7 : ''}</p>
                        <div className="col-md-12 mt-3">
                            {/* <input type="button" onClick={(e) => v12()} className="btn btn-primary w-100 mt-3 rnd" value="Post" /> */}
                            <div className='row'>

                                {/* <div className='col-6'>
                                <input className="btn btnpost w-100 mt-2 pro-spaces-button" data-bs-toggle="modal" data-bs-target="#SureYouWanna" type="submit" value="Reset" />

                            </div> */}

                                <div className='col-12 w-100'>
                                    {!loading && <center>
                                        <input onClick={(e) => v7()} className="btn w-100 btnpost mt-2" type="submit" value="Upadte" />
                                    </center>}
                                    {loading && <center>
                                        <button className="btn btnpost w-100  mt-2" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            &nbsp;Updating...
                                        </button>
                                    </center>}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="SureYouWanna" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Are you sure? !!! This will erase all the fields on all the pages.
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn pro-spaces-button" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" onClick={(e) => {
                                        props.setReset(!props.Reset)
                                    }} data-bs-dismiss="modal" className="btn pro-spaces-button3">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </div>
        </>
    )

}
