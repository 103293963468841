import React, { useState, useEffect, useContext, createContext } from 'react'
import { keyGen } from '../../Utility/helper'
import { CatsContext } from './FormProduct'
import { EditContext } from './FormProduct'
import { ConvertContext } from './FormProduct'
import AddOnField from './AddOnField'
import axios from 'axios'

export const SelectedSubcategoryContext = createContext()

export default function AddOn({ setError, setAddons, sab }) {
    const [show, setShow] = useState(false);

    let catscontext = useContext(CatsContext)
    let editcontext = useContext(EditContext)
    let convertcontext = useContext(ConvertContext)
    const [Count, setCount] = useState([])
    const [Maximum, setMaximum] = useState(10)

    useEffect(() => {
        axios
            .get("/api/ActiveUser/accountswitch")
            .then((res) => {
                if (res.data.length) {
                    setMaximum(10 - res.data.length)
                }
            }).catch((err) => {
                console.log(err)
            })
        return () => {
        }
    }, [])

    const [subcategory, setsubcategory] = useState()
    const [Details, setDetails] = useState({
        subcategory: '',
        formType: ''
    })
    const [Disbled, setDisbled] = useState([])
    useEffect(() => {
        console.log(catscontext.subcat)
        setDetails({ ...Details, ...catscontext.value })
        setsubcategory(catscontext.subcat)
        setDisbled([catscontext.value.subcategory])
        return () => {
        }
    }, [catscontext])

    useEffect(() => {
        let dis = []
        dis = Count.map((el) => el.subcategory)
        setDisbled([...dis, catscontext.value.subcategory])
        setAddons(Count)
        return () => {
        }
    }, [Count])

    const deleteMe = (index) => {
        let temp = [...Count]
        temp.splice(index, 1)
        setCount(temp)
    }

    const saveMe = (index, data) => {
        let temp = [...Count]
        temp[index].subcategory = data.subcategory
        temp[index].brand = data.Brand
        temp[index].brandOn = data.BrandOn
        setCount(temp)
    }


    const Addmore = () => {
        if (validator(Count)) {
            setCount([...Count, { id: keyGen() }])
        }
    }

    const validator = () => {
        try {
            for (const key of Count) {
                if (!key.subcategory || key.subcategory?.length === 0) {
                    setError('PLEASE SELECT A Sub category')
                    return false
                }
                if (key.brandOn === true && key.brand.length == 0) {
                    setError('PlEASE SELECT A Brand')
                    return false
                }
            }
            setError('')
            return true
        } catch (error) {
            console.log(error)
        }
    }

    if (editcontext || convertcontext) {
        return <></>
    } else {
        return (
            <SelectedSubcategoryContext.Provider value={{ subcategorySelected: Disbled }}>
                <div className='container' >
                    <p>
                        If you wish to create profiles in multiple sub categories within the same category using the same information, Click on “Add another Sub category”. {sab ? '' : 'This is if your business deals with more than one type of product / commodity.'} By adding, Profiles will be created in all selected sub categories.
                    </p>
                    <>
                        {Count.map((elem, index) => {
                            if (index < Maximum) {
                                return <AddOnField key={elem.id} id={elem.id} index={index} deleteMe={deleteMe} saveMe={saveMe} />
                            }
                        })}
                    </>

                    <div className='text-center p-1' >
                        {Disbled.length < Maximum ?
                            <button type='button' onClick={(e) => Addmore()} className='btn pro-spaces-button-semi ps-3 pe-3 mt-2' >
                                Add another Sub Category
                            </button>
                            : <></>}
                    </div>
                </div>
            </SelectedSubcategoryContext.Provider>
        )
    }
}
