import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Vertual.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineClose } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import axios from "axios";
import Moment from "moment";
import { Modal } from "react-bootstrap";
import { print_city, print_state } from "../../Utility/stateCity";
import { Events } from "./Events";

export function Virtualmob() {
  const colorstyle = {
    color: "var(--blue)",
  };
  return (
    <div className="container fiwjgpjwjgwjgpwjfpxmpxm  d-block d-md-none">
      <div className="row mb-2">
        <div className="col d-flex justify-content-end">
          {/* <Link to="/understand_this_feature_c"> */}
          <button
            type="button"
            className="btn pro-spaces-button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModefeqaeifhiehflsabari2wekjfg"
          >
            UNDERSTAND THIS FEATURE
          </button>
          {/* </Link> */}
        </div>
      </div>
      <hr />

      <div className="mt-4">
        <h4 style={colorstyle}>OFFICIAL / PERSONAL TASKS</h4>
        <p>
          Add an official task or a personal task such as purchase list,
          household chores, etc
        </p>
        <button className="btn btn-vs" type="button">
          Add a new task
        </button>
      </div>
      <hr />
      <div className="mt-4">
        <h4 style={colorstyle}>BIRTHDAYS, ANNIVERASRIES, OCCASIONS</h4>
        <p>
          Add Birthdays, Anniversaries and other Occassions to set Reminders
          either permanently or for a day
        </p>
      </div>
      <Events />

      {/* <div className="ewhjbgfiuwbgfuowrnviwrnobviwr">

        
      </div> */}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt  "
        id="exampleModefeqaeifhiehflsabari2wekjfg"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">VIRTUAL SECRETARY</h4>
                </div>
                <div className="row">
                  <p>
                    Any member can use this feature to Assign personal and
                    professional Tasks and Set Reminders of Birthdays,
                    Anniversaries, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <hr />
      {/* space for shedule */}
    </div>
  );
}
