import React, { useState, useEffect, useReducer, createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./CreateConseltent.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CreateConseltentA } from "./forms/CreateConseltentA";
import Select from "react-select";

export const CatsContext = createContext();
export const ConvertContext = createContext();
export const EditContext = createContext();

export function FormConsultent(props) {
  const reducer = (state, action) => {
    console.log(action);
    switch (action.type) {
      case "1":
        return <CreateConseltentA postTo={props.postTo} />;
      case "2":
        return <CreateConseltentA postTo={props.postTo} />;
      case "3":
        return <CreateConseltentA postTo={props.postTo} />;
      case "4":
        return <CreateConseltentA postTo={props.postTo} />;
      case "5":
        return <CreateConseltentA postTo={props.postTo} />;
      default:
        console.log("invalid");
      // throw new Error();
    }
  };
  let navigate = useNavigate();

  const [value, setValue] = useState({
    category: "",
    subcategory: "",
    formType: "",
    Type: "Consultant_Unique_v3",
  });
  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [brand, setBrand] = useState([]);

  const [state, dispatch] = useReducer(reducer, <></>);
  const sabari = (e) => {
    var anadhan;
    setValue({ ...value, category: e });
    cat.forEach((element) => {
      if (element._id === e) {
        anadhan = element.sub.map((elements) => {
          return (
            <option formtype={elements.formType} value={elements._id}>
              {elements.sub}
            </option>
          );
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };
  useEffect(() => {
    axios
      .get("/api/api/getcategory")
      .then((res) => {
        if (res.status === 200) {
          setCat(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          navigate("/");
        }
      });
  }, []);
  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
    } else {
    }
  }, [cat]);
  const formLoader = () => {
    let e = document.getElementById("subcatananda83389");
    let ft = e.options[e.selectedIndex].getAttribute("formtype");
    dispatch({ type: ft });
    setValue({ ...value, subcategory: e.value, formType: ft });
    cat.find((elem) => {
      if (elem._id === value.category) {
        elem.sub.find((element) => {
          if (element._id == e.value) {
            setBrand(element.brand);
          }
        });
      }
    });
  };
  const [data, setdata] = useState();
  useEffect(() => {
    if (props.edit) {
      axios
        .post("/api/ActiveUser/ConProfile", {})
        .then((res) => {
          setValue({
            ...value,
            category: res.data[0].category,
            subcategory: res.data[0].subcategory,
            // formType: res.data[0].formType
            Brand: res.data[0].Brand,
          });
          let e = document.getElementById("subcatananda83389");
          let anadhan;
          let ft;
          cat.forEach((element) => {
            if (element._id === res.data[0].category) {
              anadhan = element.sub.map((elements) => {
                if (elements._id === res.data[0].subcategory) {
                  ft = elements.formType;
                } else {
                }
                return (
                  <option formtype={elements.formType} value={elements._id}>
                    {elements.sub}
                  </option>
                );
              });
            }
          });
          console.log(anadhan);
          setSubcategory(anadhan);
          if (ft) {
            ft = ft.toString();
            dispatch({ type: ft });
          }
          setdata(res.data);
          cat.find((elem) => {
            if (elem._id === value.category) {
              elem.sub.find((element) => {
                if (element._id == e.value) {
                  setBrand(element.brand);
                }
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [category]);

  // -------------input catagory box start------------------

  const [Options, setOptions] = useState([]);
  useEffect(() => {
    axios
      .get("/api/api/getcategory")
      .then((res) => {
        let op = res.data?.map((element, index) => {
          return element.sub.map((elm, ind) => {
            let value = elm.sub.toString();
            if (elm.keyword?.length) {
              var sabari = elm.keyword.map((keywordsmap) => keywordsmap.label);
              value = value + "" + sabari.toString();
            }
            let brand;
            if (elm.brand?.length) {
              brand = true;
            } else {
              brand = false;
            }
            return {
              value,
              label: `${elm.sub} [ ${element.category} ]`,
              id: elm._id,
              categoryName: element.category,
              brand,
              type: element.type,
              catid: element._id,
              ft: elm.formType,
            };
          });
        });
        op = op.flat(1);
        setOptions(op);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const redmik20pro = (id) => {
    let catid = id.catid;
    let subcatid = id.id;
    let anadhan;
    let ft = id.ft;
    cat.forEach((element) => {
      if (element._id === catid) {
        anadhan = element.sub.map((elements) => {
          if (elements._id === subcatid) {
            ft = elements.formType;
          } else {
            // console.log(elements._id,res.data[0].subcategory)
          }
          return (
            <option formtype={elements.formType} value={elements._id}>
              {elements.sub}
            </option>
          );
        });
      }
    });
    setSubcategory(anadhan);
    setValue((rev) => ({
      ...rev,
      category: catid,
      subcategory: subcatid,
      formType: ft,
    }));
    console.log(ft);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selectedOption) => {
    redmik20pro(selectedOption);
  };

  const handle = (e) => {
    if (e.target.value.length > 2) {
      let elem = document.querySelector(":root");
      elem.style.setProperty("--display", "block  ");
    } else {
      let elem = document.querySelector(":root");
      elem.style.setProperty("--display", "none  ");
    }
  };

  useEffect(() => {
    try {
      rt();

      let element = document.getElementsByClassName("inputselectcat");
      element[0].children[2].children[0].children[1].children[0].addEventListener(
        "input",
        handle
      );
      element[0].children[2].children[1].classList.add("d-none");
    } catch (error) {
      console.log(error);
    }
    return () => {
      try {
        let element = document.getElementsByClassName("inputselectcat");
        element[0].children[2].children[0].children[1].children[0].removeEventListener(
          "input",
          handle
        );
      } catch (err) {}
    };
  }, []);

  const rt = () => {
    let elem = document.querySelector(":root");
    elem.style.setProperty("--display", "none ");
  };

  // -------------input catagory box end------------------
  useEffect(() => {
    if (value.category.length > 0 ) {
      dispatch({ type: "1" });
    }
  }, [value]);

  return (
    <div className="container rounded bg-white pt-5 pb-0">
      <>
        <center className="pt-4">
          <h5>Profile Creation Form</h5>
        </center>
        <center>
          <p>' * ' are all mandatory fields</p>
        </center>
        <div className="row">
          <h6 className="mt-1">Select your Profile Classification</h6>
          <div className=" col-md-12 col-12 ">
            <div class="form-check">
              <input
                onChange={(e) =>
                  setValue({ ...value, Type: "Consultant_Unique_v3" })
                }
                class="form-check-input"
                type="radio"
                name="AccType"
                value="Activev3"
                id="AccTypeActivev3"
                defaultChecked
              />
              <label class="form-check-label " for="AccTypeActivev3">
                Service Provider
              </label>
            </div>
          </div>
          <div className=" col-md-12 col-12">
            <div class="form-check">
              <input
                onChange={(e) =>
                  setValue({ ...value, Type: "Product_Unique_v3" })
                }
                class="form-check-input"
                type="radio"
                name="AccType"
                value="Activev2"
                id="AccTypeActivev2"
              />
              <label class="form-check-label " for="AccTypeActivev2">
                Product Retailer
              </label>
            </div>
          </div>
          <div className=" col-md-12 col-12">
            <div class="form-check">
              <input
                onChange={(e) =>
                  setValue({ ...value, Type: "Utility_Unique_v3" })
                }
                class="form-check-input"
                type="radio"
                name="AccType"
                value="Active"
                id="AccTypeActive"
              />
              <label class="form-check-label " for="AccTypeActive">
                Utility
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <h6 className="mt-1">
            Type your appropriate Category and Sub Category
          </h6>
          <div className="col-md-12">
            <label className="labels">Category *</label>
            <input
              className="form-control"
              type={"text"}
              value={value.category}
              onChange={(e) => setValue({ ...value, category: e.target.value })}
            ></input>
          </div>

          <div className="col-md-12">
            <label className="labels">Sub Category </label>

            <input
              id={"subcatananda83389"}
              type={"text"}
              className="form-control"
              value={value.subcategory}
              onChange={(e) =>
                setValue({ ...value, subcategory: e.target.value })
              }
            ></input>
            {/* {value.subcategory} */}
          </div>
        </div>
        {brand.length ? (
          <>
            <div className="col-md-12">
              <label className="labels">Brand *</label>
              <select
                value={value.Brand}
                className="form-select"
                onChange={(e) => setValue({ ...value, Brand: e.target.value })}
              >
                <option></option>
                {brand.map((elem) => {
                  return <option value={elem._id}>{elem.brand}</option>;
                })}
              </select>
            </div>
          </>
        ) : (
          <></>
        )}
        <EditContext.Provider value={data}>
          <CatsContext.Provider value={{ value, brand: brand }}>
            <ConvertContext.Provider value={props.sab}>
              {state}
            </ConvertContext.Provider>
          </CatsContext.Provider>
        </EditContext.Provider>
      </>
    </div>
  );
}
