import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./Pview.css";
import { BsFillPlusCircleFill } from "react-icons/bs";
import axios from "axios";
import "./Edit";
import { PortAdd } from "./PortAdd";
import { PortTile } from "./portTile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Portfolio(props) {
  const [dopper, setDooper] = useState(false);
  const [forume, setforume] = useState();
  const [filedel, setFiledel] = useState([]);
  const [PortFolios, setPortFolios] = useState([]);
  const [KeyPortAdd, setKeyPortAdd] = useState(1);

  useEffect(() => {
    axios
      .get("/api/ActiveUser/getportfolio")
      .then((res) => {
        setDooper(res.data);
        let rtr = res.data.map((element) => {
          return element._id;
        });
        // console.log(rtr)
        setPortFolios(rtr);
        // console.log(res.data);
        var dopper = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const setport = async () => {
    // var dop = await getport();
    // console.log(dop);
    // setContent(dop);
  };

  // useEffect(() => {
  //   getport();
  // }, []);

  function buds(e, iuy) {
    console.log(iuy);
    var arr = filedel;
    arr.push(iuy);
    setFiledel(arr);

    // setValue(...value, File:e.target.value)
  }

  function lossupennu(e, ind) {
    console.log(dopper);
    ind = parseInt(ind);
    // return;
    dopper[ind].File.map((element1, index) => {
      //   document.getElementById("trkor" + index + ind).classList.remove("d-none");
    });

    document.getElementById("trkorrr" + ind).classList.remove("d-none");
    // document.getElementById("uhf"+ind).classList.remove('d-none')
    document.getElementById("gana" + ind).removeAttribute("disabled");
  }

  function losserionvrbv(e, ind, idp) {
    console.log(idp);

    axios
      .delete("/api/ActiveUser/deleteport", {
        data: {
          idp,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  function pyari(e, ind) {
    dopper[ind].File.map((element1, index) => {
      //   document.getElementById("trkor" + index + ind).classList.add("d-none");
    });
    document.getElementById("trkorrr" + ind).classList.add("d-none");
    // document.getElementById("uhf"+ind).classList.add('d-none')
    document.getElementById("gana" + ind).setAttribute("disabled", true);
  }

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function cater(e) {
    // console.log(e.target.value)
    setforume(e.target.value);
  }
  useEffect(() => {
    // console.log(forume);
  }, [forume]);

  function jvur(e, fr) {
    document.getElementById(fr).click();
  }

  const Remove = async (id) => {
    try {
      let f = await axios.post("/api/ActiveUser/DelportSingle", { id });
      const newList = PortFolios.filter((item) => item !== id);
      setPortFolios(newList);
      notify("Successfully Deleted", true);
    } catch (err) {
      console.log(err);
      notify("Something Went Wrong", false);
    }
  };

  // update port
  function fastandf(e, content, ee, elem, id) {
    console.log(elem);
    var pit = document.getElementById(content).value;
    console.log(content);
    // var acer = document.getElementById(eyfye).files;
    console.log(pit);
    // console.log(acer);
    var data = {
      content: pit,
      deleted: filedel,
      elem,
      id,
    };
    var m = JSON.stringify(data);
    axios
      .put("/api/ActiveUser/updateportfolio", { m })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(forume)
    // console.log(file)
    // console.log(filedel)
  }

  useEffect(() => {
    // console.log(PortFolios)
    // props.setValue({ ...props.value, portfolios: PortFolios })
    setKeyPortAdd(KeyPortAdd + 1);
  }, [PortFolios]);

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const Remove2 = async (id) => {
    try {
      console.log(id);

      console.log(PortFolios);
      let f = await axios.post("/api/ActiveUser/DelportSingle", { id });
      if (f.status == 201) {
        notify("Cannot delete", false);
      } else if (f.status == 200) {
        const newList = PortFolios.filter((item) => item !== id);
        setPortFolios(newList);
      }
      console.log(f);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <PortAdd
        key={KeyPortAdd}
        keyGen={keyGen}
        setKeyPortAdd={setKeyPortAdd}
        id={KeyPortAdd}
        PortFolios={PortFolios}
        setPortFolios={setPortFolios}
      />

      <div className="row text-dark">
        <>
          {" "}
          {PortFolios.map((elem, index) => {
            return (
              <div className="col-12 col-xl-6 mb-3 mt-2">
                <PortTile
                  Remove={Remove}
                  Remove2={Remove2}
                  title={"here"}
                  index={index + 1}
                  id={elem}
                />
              </div>
            );
          })}
        </>

        {/* portfolio modal starts */}
      </div>
      {/* portfolio modal ends */}

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
