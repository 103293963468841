import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../payment.css";
import { Navoutside } from "../../../Components/Navoutside/Navoutside";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileTrialViewConsultant from "./ProfileTrialView/ProfileTrialViewConsultant";
import { NewNavigationBarDissabled } from "../../Free/NewNavigationBar/NewNavigationBarDissabled";

function displayRazorpay(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export function DirectLinkBasicService() {
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [loading, setloading] = useState(false);
  const [first, setfirst] = useState(null);
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    axios
      .get("/api/verify/meatsignup")
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setfirst(res.data);
          setAuthenticated(true);
        }
      })
      .catch((err) => {
        if (err) {
          navigate("/");
        }
        console.log(err.response.status);
      });
  }, []);
  const [RefCode, setRefCode] = useState("");
  const [Error, setError] = useState("");
  async function RazorPay() {
    setloading(true);
    const res = await displayRazorpay(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("failed sdk to load");
      setloading(false);
      return;
    }
    var tx = {
      Coupon,
    };
    axios
      .post("/api/payment/createSpecialPay", {
        method: "POST",
        body: Coupon,
      })
      .then((t) => {
        setloading(false);
        var data = t.data;
        console.log(data);
        var options = {
          key: process.env.REACT_APP_KEY_ID, // Enter the Key ID generated from the Dashboard S6PuOik0Pm6t7RcAyX8Ws5i2
          amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "PROchure",
          order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            success(response);
          },

          theme: {
            color: "#55374a",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          setloading(false);
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
        // document.getElementById('rzp-button1').onclick = function (e) {
        rzp1.open();
        // e.preventDefault();
      })
      .catch((err) => {
        if (err.response.status == 409) {
          setError(err.response.data);
        }
        setloading(false);
      });

    function success(res) {
      // alert('Congratulations....You are now a PRO- member !!')
      console.log(res);
      axios
        .post("/api/payment/successsSpecial", { res, GSTIN, RefCode })
        .then((res) => {
          if (res.status === 200) {
            notify("Payment successfull", true);
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
        })
        .catch((err) => {});
    }
  }

  const [couponError, setcouponError] = useState("");
  const [couponsuccess, setcouponsuccess] = useState("");
  const [Coupon, setCoupon] = useState();

  const StartMyFreeTrail = async () => {
    try {
      let send = await axios.post("/api/coupon/StartFreeTrail");
      if (send.status === 200) {
        navigate("/");
      }
    } catch (err) {}
  };
  const [GSTIN, setGSTIN] = useState();

  const [profileId, setProfileId] = useState(null);
  useEffect(() => {
    axios
      .get("api/verify/accountidatpayment")
      .then((res) => {
        console.log(res.data);
        setProfileId(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);

  const checkCoupe = async () => {
    setCoupon("");
    setcouponError("");
    setcouponsuccess("");
    let coupon = document.getElementById("coupe").value;
    let dataSend = {
      coupon,
    };
    try {
      if (coupon.length > 5) {
        let result = await axios.post("/api/coupon/check", { dataSend });
        if (result) {
          console.log(result);
          setCoupon(coupon);
          setcouponError("");
          setcouponsuccess(
            "Coupon applied ! Now click on Make Payment to proceed further"
          );
        }
      } else {
        setcouponError("Invalid coupon / coupon withdrawn");
        console.log("first");
      }
    } catch (error) {
      console.log(error.response.data);
      setcouponError(error.response.data);
    }
  };

  return (
    <>
      <NewNavigationBarDissabled />

      <ProfileTrialViewConsultant
        profileId={profileId}
        RazorPay={RazorPay}
        first={first}
        loading={loading}
        setRefCode={setRefCode}
        setGSTIN={setGSTIN}
        Error={Error}
        checkCoupe={checkCoupe}
        couponsuccess={couponsuccess}
        couponError={couponError}
      />

      {/* <div className=" container d-flex justify-content-center pt-5 ">
                <div style={{ 'maxWidth': '500px' }} className="card  carrrrbuss">


                    <div className='container p-1'>

                        {first ? first === "Product" || first === "Consultant" || first === "Productv2" || first === "Consultantv2" || first === "Productv3" || first === "Consultantv3"  ?
                            <>
                                <div className='row '>
                                    <center> <h3 className='eufgiueg mb-1'> {first ? first === "Product" || first === "Consultant" ? 'PRIME MEMBERSHIP' : first === "Productv2" || first === "Consultantv2" ? 'BASIC MEMBERSHIP' : 'STATIC LISTING' : '' }</h3>
                                    </center>
                                </div>
                                <div className='row'>
                                    <p className='invisible mb-1'>wwwwwwwwwwwwwwwwwwwwwwww</p>
                                    
                                </div>
                               

                               
                                <h6 className='eufgiueg mt-4 d-none'>Discount Coupon</h6>
                                <div className='container d-none'>
                                    <div className="row">
                                        <div className='col-md-9 col-8 m-0 p-0'>
                                             <input disabled type="text" id='coupe' value={'LAUNCHOFFER'} placeholder='Enter Coupon Code ' className='form-control bg-light' />
                                             </div>
                                        <div className='col-md-3 col-4 m-0 p-0 ps-1'>  
                                          <button disabled onClick={(e) => checkCoupe(e)} className='btn px-3 w-100 wygdiwgdigwidg'>APPLY</button></div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <p>Subscription fees : ₹ 2000/- for 2 years<br />( ₹ 1000/- per year including GST )</p>
                                </div>
                                <div className='row d-none'>
                                    <div className='col'></div>
                                    <p className='text-success'>{couponsuccess ? couponsuccess : ''}</p>
                                    <p className='text-danger'>{couponError ? couponError : ''}</p>

                                </div>
                            </>
                            :
                            <>
                                <div className='row '>
                                    <center> 
                                    <p>A Service Fee of ₹ 100/- is applicable</p></center>
                                </div>
                                <div className='mt-2 mb-4'>
                                 
                                </div>
                            </> : <></>}


                        <div className='row mt-2'>
                        {!loading &&
                            <button className='btn btn-udaguadgouao paymentbuttonsabari' onClick={RazorPay}>{first ? first === "Product" || first === "Consultant" ? "PAY" : "PAY" : "Please Wait"}</button>
                        }
                        {loading &&
                            <button className='btn btn-udaguadgouao paymentbuttonsabari' disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                        }
                        </div>
                        <div className='row mt-3 in d-none'>
                            <button className='btn btn-udaguadgouao' onClick={(e) => StartMyFreeTrail()} >Start One Day Free Trial</button>
                        </div>

                    </div>
                </div>







            </div> */}
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
