import React, { useContext, useEffect, useState } from 'react'
import { keyGen } from '../../Utility/helper'
import { CatsContext } from './FormProduct'
import { SelectedSubcategoryContext } from './AddOn'
function AddOnField({ id, index, deleteMe, saveMe }) {


    let selectedSubcategoryContexts = useContext(SelectedSubcategoryContext)

   

    let catscontext = useContext(CatsContext)
    const [Details, setDetails] = useState({
        subcategory: '',
        formType: ''
    })
    const [subcategory, setsubcategory] = useState()
    const [Brand, setBrand] = useState([])
    const [Selected, setSelected] = useState({
        subcategory: '',
        Brand: '',
        BrandOn: ''
    })
    useEffect(() => {
        setDetails({ ...Details, ...catscontext.value })
        setsubcategory(catscontext.subcat.filter((elem) => {
            if (elem.formType?.toString() === catscontext.value.formType) {
                return elem
            }
        }))
        return () => {
        }
    }, [catscontext])



    const subcategorySelection = (sub) => {
        setSelected({ ...Selected, subcategory: sub, Brand: '' })
        let details = subcategory.filter((elem) => {
            if (elem._id === sub) {
                return elem
            }
        })
        if (details[0].brand?.length) {
            setBrand([...details[0].brand])
            setSelected({ ...Selected, subcategory: sub, Brand: '', BrandOn: true })

        } else {
            setSelected({ ...Selected, subcategory: sub, Brand: '', BrandOn: false })

            setBrand([])
        }
    }

    useEffect(() => {
        if (Selected.subcategory || Selected.Brand)
            saveMe(index, Selected)
        return () => {
        }
    }, [Selected])

    return (
        <div className='row border p-1'>
            <div className='col-12 p-1 d-flex flex-row-reverse '>
                <button onClick={(e) => deleteMe(index)} type='button' className='btn btn-close ps-0' ></button>
            </div>

            <div className="col-md-12">
                <label className="labels">Sub Category *</label>
                <select className="form-select"
                    value={Selected.subcategory}
                    onChange={(e) => subcategorySelection(e.target.value)}
                >
                    <option disabled selected>
                    </option>
                    {subcategory?.map((sub) => {
                        if (selectedSubcategoryContexts.subcategorySelected.indexOf(sub._id) == -1) {
                            return <option key={keyGen()} formtype={sub.formType} value={sub._id}>
                                {sub.sub}
                            </option>
                        } else {
                            return <option key={keyGen()} disabled formtype={sub.formType} value={sub._id}>
                                {sub.sub}
                            </option>
                        }
                    })}
                </select>
            </div>

            {Brand.length ?
                <div className='col-md-12'>
                    <label className='labels'>Brand *</label>
                    <select className='form-select' value={Selected.Brand} onChange={(e) => setSelected({ ...Selected, Brand: e.target.value })}>
                        <option></option>
                        {Brand?.map((ele) => <option value={ele._id} >{ele.brand}</option>)}
                    </select>
                </div>
                : <></>
            }

        </div>

    )
}

export default AddOnField