
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Link } from "react-router-dom";
import { AiFillFire, AiOutlineSearch } from "react-icons/ai";
import { FaLock } from "react-icons/fa"
import axios from "axios";
import moment from 'moment'
import { Button } from "react-bootstrap";
import Moment from 'moment';


export function RVideo(props) {

    return (
        <div className="row">
            <div className="col-10 ps-4 ">
                <div className="  p-1 m-2">
                    <div className="row ps-3 ">
                        <div className="col-12 p-0 pb-1 m-0">
                            {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp" alt="avatar"
                            className="rounded-circle chatAvatar me-2 align-self-center  shadow-1-strong" width="30" /> */}
                            {props.data.sender.firstname}
                        </div>
                        <div className="m-0 ms-0 p-0">

                            <div className="row p-0">
                                <div className="p-0 ms-3 mesbox">
                                    <p className="message mb-0 text-light bg-secondary px-3">
                                        <small>
                                            <video width={'150px'} className="" controls> <source src={props.data.content} />  </video>
                                        </small>
                                    </p>
                                    <p className="mt-0  pe-4">  {Moment(props.data.createdAt ? props.data.createdAt.slice(0, 24) : '').format(' h:mm:ss a')}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-2">
            </div>
        </div>

    )
}