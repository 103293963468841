import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Mask } from "../../../../Utility/mask";

import { PersonalEntityInfornation } from "../../../../Components/ProfileComponents/PersonalEntityInfornation";
import { QualificationsandEnrolmentInformation } from "../../../../Components/ProfileComponents/QualificationsandEnrolmentInformation";
import { DetailedServiceInformation } from "../../../../Components/ProfileComponents/DetailedServiceInformation";
import { WorkServiceRelatedInformation } from "../../../../Components/ProfileComponents/WorkServiceRelatedInformation";
import { BussinessInformation } from "../../../../Components/ProfileComponents/BussinessInformation";
import { ContactInformation } from "../../../../Components/ProfileComponents/ContactInformation";
import { HospitalsClinicsAssociatedWith } from "../../../../Components/ProfileComponents/HospitalsClinicsAssociatedWith";
import { PersonalInformationStatic } from "../../../../Components/ProfileComponents/PersonalInformationStatic";

function ProfileTrialViewConsultant({
  profileId,
  first,
  RazorPay,
  setRefCode,
  Error,
  setGSTIN,
  checkCoupe,
  couponsuccess,
  couponError,
}) {
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  let success = searchParams.get("updated");

  const [expanded, setExpanded] = useState(false);
  const [loading, setloading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (success) {
      notify("Profile Updated", true);
      console.log("updated");
    }
  }, []);

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  // init
  console.log(profileId);
  var idd = profileId;
  useEffect(() => {
    if (profileId) {
      axios
        .post("/api/Free/ConProfileexterbal", {
          idd,
        })
        .then((res) => {
          console.log(res.data);
          setGetval(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  const [myarr, setMyarr] = useState(false);
  useEffect(() => {
    if (getval.serviceprovided) {
      setMyarr(getval.serviceprovided.split(","));
      //  console.log(myarr)
    }
  }, [getval]);
  var sab;

  if (getval.experience > 1) {
    sab = "years";
  } else {
    sab = "year";
  }

  //   );

  const login = () => {
    setloading(true);
    var dataSend = {
      Contact: getval?.email,
      Password: getval?.email,
    };
    console.log("status2");

    axios
      .post("/api/auth/login", { dataSend })
      .then((res) => {
        if (res.status == "200") {
          return navigate("/");
        } else if (res.status == "206") {
          if (res.data === "Active") {
            return navigate("/next/");
          } else if (res.data === "Passive") {
            return navigate("/passive/");
          } else if (res.data === "Activev2") {
            return navigate("/next_/");
          } else if (res.data === "Activev3") {
            return navigate("/next__/");
          }
        } else {
          console.log("le");
          return;
        }
      })
      .catch((err) => {
        if (err.response.status == "400" || err.response.status == "401") {
        } else if (err.response.status == "402") {
          return navigate("/payment");
        } else if (err.response.status == "500") {
          console.log("status:500");
        } else if (err.response.status == "406") {
          console.log("status:406");
        }
        setloading(false);
      });
  };

  return (
    <>
      {/* <NavBar /> */}
      <div style={{ paddingTop: "80px" }}>
        {/* <center>
            <div className="row d-flex justify-content-center px-3 carrrrbuss" style={{'maxWidth':'350px'}}>
                <div className="col-12" style={{'borderRadius':'15px'}}>
                    <div className='row'>
                        <p>Subscription fees : ₹ 2000/- for 2 years<br />( ₹ 1000/- per year including GST )</p>
                    </div>
                   
                </div>
            </div>
            
        </center> */}
        <div className=" row m-0 w-100 mt-2  justify-content-center">
          <center className="mb-2">
            This is how your PROchure Profile would be
            visible&nbsp;to&nbsp;others&nbsp;once&nbsp;activated.
          </center>
          <div
            className=" container mx-5  justify-content-center pt-2"
            style={{ width: "700px" }}
          >
            {/* <h6 className="eufgiueg mt-2">Referral Code / Name</h6>
            <input
              type="text"
              placeholder="Enter Referral Code / Name "
              className="form-control bg-light"
              onChange={(e) => setRefCode(e.target.value)}
            />

            <h6 className="eufgiueg mt-3 mb-0">Discount Coupon</h6>
            <div className="pt-2">
              <div className="d-flex bd-highlight">
                <div className="flex-grow-1 bd-highlight">
                  {" "}
                  <input
                    type="text"
                    id="coupe"
                    placeholder="Enter Coupon Code "
                    className="form-control bg-light"
                  />
                </div>
                <div className="bd-highlight ms-1">
                  {" "}
                  <button
                    onClick={(e) => checkCoupe(e)}
                    className="btn px-3 w-100 wygdiwgdigwidg"
                  >
                    APPLY
                  </button>
                </div>
              </div>
              <p className="text-success">
                {couponsuccess ? couponsuccess : ""}
              </p>
              <p className="text-danger">{couponError ? couponError : ""}</p>
            </div>

            <div className="pt-2">
              If you need a GST invoice for your subscription, then please
              mention your GST number below
            </div>
            <div className="pt-2">
              <input
                onChange={(e) => setGSTIN(e.target.value)}
                placeholder="Enter GST registration number"
                className="form-control "
                type="text"
              />
            </div>

            <div className="row mt-2">
              <p>
                A copy of your Invoice will be sent to your email id. You can
                also View and Download your Invoice from 'MEMBERSHIP
                SUBSCRIPTION DETAILS' in 'Subscription Details and Account
                Settings' on your Profile tab.
              </p>
            </div> */}

            <div>
              <center>
                {!loading && (
                  <button
                    className="btn btn-udaguadgouao mt-3 "
                    style={{ maxWidth: "325px", textTransform: "none" }}
                    onClick={login}
                  >
                    {first
                      ? first === "Product" || first === "Consultant"
                        ? "PAY and ACTIVATE ACCOUNT"
                        : "PAY and ACTIVATE ACCOUNT"
                      : "Please Wait"}
                  </button>
                )}
                {loading && (
                  <button
                    className="btn btn-udaguadgouao mt-3 "
                    style={{ maxWidth: "325px" }}
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
              </center>
            </div>
          </div>
        </div>
        <div
          className="container emp-profile border "
          style={{ paddingTop: "0px" }}
        >
          {/* whgiowhroighsoihlznvlsnd */}
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-3">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-3">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="profilee-img ">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={getval.link}
                  alt=""
                />
                {/* <div className="file btn btn-lg btn-primary">
                  Change Photo
                  <input onChange={(e)=>changeDp(e.target.files[0])} type="file" name="ProfilePic" accept="image/*"/>
                </div> */}
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  <h5>{getval.pname ? getval.pname.toUpperCase() : ""}</h5>
                </div>
                <div className="d-block d-md-none">
                  <center>
                    <h5>{getval.pname ? getval.pname.toUpperCase() : ""}</h5>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval?.string2 ? getval.string2[0].sub : ""} |{" "}
                        {getval?.string ? getval.string[0].category : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval?.string2 ? getval.string2[0].sub : ""} |{" "}
                          {getval?.string ? getval.string[0].category : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
                {/* <div className="d-block d-md-none">
                  <center>
                    <Link
                      to={
                        getval.Type === "Consultant_Unique_v3"
                          ? "/edit_profile_services_free_static"
                          : "/edit_profile_services_free"
                      }
                    >
                      <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                        style={{ borderRadius: "25px" }}
                        value="Edit Profile"
                      />
                    </Link>
                  </center>
                </div>
                <div className="d-none d-md-block">
                  <Link
                    to={
                      getval.Type === "Consultant_Unique_v3"
                        ? "/edit_profile_services_free_static"
                        : "/edit_profile_services_free"
                    }
                  >
                    <input
                      type="submit"
                      className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      name="btnAddMore"
                      value="Edit Profile"
                    />
                  </Link>
                </div> */}
                {/* <p className="proile-rating">
                  About: {" "}
                  <span>
                    I'm Yuki. Full Stack Designer I enjoy creating user-centric,
                    delightful and human experiences.I'm Yuki.
                  </span>
                </p> */}
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {getval.Type === "Consultantv3" ? (
                  <>
                    <Accordion
                      expanded={expanded === "panelewrg4"}
                      onChange={handleChange("panelewrg4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Service Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p className="py-2">{getval.Description}</p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <PersonalInformationStatic getval={getval} vendor={true} />

                    <BussinessInformation getval={getval} />
                    <ContactInformation getval={getval} />
                    {/* <ShorotlistAccodian checkaeijfpi2={checkaeijfpi2} addreating={addreating} makeEmail={makeEmail} setEmailRef={setEmailRef} SendRef={SendRef} mappas={mappas} getval={getval} referCount={referCount} yeser={yeser} first={first} EmailRef={EmailRef} Ac={Ac} Email={Email} error={error} loading={loading} /> */}
                  </>
                ) : getval.formType ? (
                  getval.formType === 1 ? (
                    <>
                      {/* <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Expert Views and Advice, Portfolio of Works</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>          
                          <Typography>
                              <Portfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion> */}

                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 2 ? (
                    <>
                      {/* <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Expert Views and Advice, Portfolio of Works</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>          
                          <Typography>
                              <Portfolio />
                          </Typography>
                        </AccordionDetails>
                      </Accordion> */}

                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 3 ? (
                    <>
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      {getval.Profile === "Individual" ? (
                        <PersonalEntityInfornation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 4 ? (
                    <>
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      {getval.Profile === "Individual" ? (
                        <PersonalEntityInfornation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 5 ? (
                    <>
                      {getval.Profile === "Private" ||
                      getval.Profile === "Privateand" ? (
                        <DetailedServiceInformation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />

                      {getval.Profile === "Private" ? (
                        <>
                          {/* Private */}
                          <BussinessInformation getval={getval} />
                          <ContactInformation getval={getval} />
                        </>
                      ) : getval.Profile === "Associated" ? (
                        <>
                          {/* Associated */}
                          <BussinessInformation getval={getval} />
                          {/* <ContactInformation getval={getval} /> */}
                        </>
                      ) : (
                        <>
                          {/* Privateand */}
                          <BussinessInformation getval={getval} />
                          <HospitalsClinicsAssociatedWith getval={getval} />
                          {/* <ContactInformation getval={getval} /> */}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  ""
                )}
              </div>
            </center>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ProfileTrialViewConsultant;
