import React,{ useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";



import { useSearchParams } from 'react-router-dom'

import { SearchToolbar2 } from "../../FreeComponents/SearchToolbar2/SearchToolbar2";

import axios from "axios";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchComponent3 } from "./SearchComponent3";


export function InformationConRes() {

  const [load, setload] = useState(true)

  const [data2, setdata2] = useState([]);
  const [error2, seterror2] = useState('')
  const [output2, setoutput2] = useState(false)


  // id=JSON.parse(id)
  let [searchParams, setSearchParams] = useSearchParams(); 
  var id=  searchParams.get("Sub_id")
 
  

  useEffect(() => {
    axios.post('/api/Free/infoconsearching',{
      id
  }).then((res)=>{
    // setCat(res.data);
    console.log(res.data)
    const postSummaries = res.data.map((post) => ({ id: post._id, key: post.id, data: post}));
    setdata2(postSummaries)
    setload(false)
  }).catch((err)=>{
    console.log(err)
  })
  }, [])
  

  function makeid(length) {
    length = 5
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}









useEffect(() => {
 
      if (data2.length>0) {
          setoutput2(data2.map((number) =>  <SearchComponent3 key={number.id} ids={makeid()} data={number.data}></SearchComponent3>
          )
          )
      }
      else {
          setoutput2(error2)
      }
  

}, [data2])
  return (
    <div className=" content">
      <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
      <div className="row">
        <h5>Work portfolio, Qualification details, Work experience and other information of Featured Consultants will not be available.</h5>
      </div>
        <SearchToolbar2 />
        <center><h3>Featured Consultants : {data2.length}</h3></center>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
         
            <div className="row">
              <div className="col-12 pb-3">
                {/* <button
                  className="btn btnsabari px-3 py-1 me-2  btn-rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#shoutoutmodal1"
                >
                  {" "}
                  <HiSpeakerphone />
                  &nbsp;Shout-out
                </button> */}
               
               
              </div>
            </div>
          </div>
          {/* <div className="col d-flex justify-content-end"><button  className="btn btn-rounded btn3 mb-2 me-3" data-bs-toggle="modal" data-bs-target="#shoutoutmodal1"> <HiSpeakerphone /> Shout-out</button><button  className="btn btn-rounded btn3 mb-2 me-2" >View Selected</button></div> */}
          {/* <!-- end col --> */}
        </div>
        {/* <!-- end row --> */}
       
        <div className=" row">
         

{output2}

          {/* <div className="col-12 col-md-6 col-lg-6 col-xl-4">
            <div className="text-center card-box">
              <div className="form-check d-flex justify-content-end">
                <input
                  className="form-check-input float-end checkboxpro me-3 mt-3"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
              </div>
              <div className="member-card pt-2 pb-2">
                <div className="thumb-lg member-thumb mx-auto">
                  <img
                    src="https://bootdey.com/img/Content/avatar/avatar2.png"
                    className="rounded-circle img-thumbnail"
                    alt="profile-image"
                  />
                </div>
                <div className="">
                  <h4>Sabari V R</h4>
                  <p className="text-muted">
                    <b>Sub Category | Main Category</b>
                    <span> </span>
                    <span>
                      <b>Location : Kollam</b>
                    </span>
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn3 px-0 py-1  mt-3 btn-rounded waves-effect w-md waves-light"
                    >
                      View Profile
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn3 px-0 py-1  mt-3 btn-rounded waves-effect w-md waves-light"
                    >
                      Follow
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn3 px-0 py-1  mt-3 btn-rounded waves-effect w-md waves-light"
                    >
                      Communicate
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="row font ">
                    <div className="col-4 ">
                      <div className="mt-3">
                        <h4 className="fon">130</h4>
                        <p className="mb-0 text-muted">Posts</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mt-3">
                        <h4 className="fon">6952</h4>
                        <p className="mb-0 text-muted">Followers</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mt-3">
                        <h4 className="fon">11/11/2021</h4>
                        <p className="mb-0 text-muted">Joined on</p>
                      </div>
                    </div>
                  </div>
                  <div className="row font ">
                    <div className="col-4 ">
                      <div className="mt-3">
                        <h4 className="fon">100</h4>
                        <p className="mb-0 text-muted">Good</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mt-3">
                        <h4 className="fon">50</h4>
                        <p className="mb-0 text-muted fon">HR</p> 
                          
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mt-3">
                        <h4 className="fon">17</h4>
                        <p className="mb-0 text-muted">Referred</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          


          

          {/* <!-- end col --> */}
        </div>

        {/* <!-- end row --> */}
      </div>

      {/* <!-- container --> */}

      

      <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  );
}
