import React from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Navoutside } from "../../Components/Navoutside/Navoutside";
import "./Welcome.css";
import axios from "axios";
function Welcome() {
  const [line, setline] = React.useState("/");
  let [searchParams, setSearchParams] = useSearchParams();
  const [firstName, setfirstName] = React.useState(false);
  React.useEffect(() => {
    var id = searchParams.get("welcome");
    console.log(id);
    if (id == "success") {
      setline("/know_my_client?welcome=true");
    }

    axios.get("/api/ProtectedApi/LocalData").then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        setfirstName(res.data.pname);
        console.log(res.data);
      }
    });
  }, []);

  return (
    <>
      <div className="container" style={{ paddingTop: "100px" }}>
        <center>
          <h4>
            Congratulations {firstName} <br />
            <br />
            You are now a PRO- member !!
          </h4>
        </center>
        <div className="row ">
          <div className="col-md-6 col-12">
            <Link to="/New_Profile" className="wgfiuwrgiu">
              <div className="card carrrrbusss" style={{ marginTop: "35px" }}>
                <div className="padunnorssd">
                  <center>
                    I would like to create a new Service Provider / Product
                    Retailer profile in another Category / Sub Category
                    {/* <Link to={"/subscription_fees?status=true"} style={{'textDecoration':'none'}}>
                      {" "}
                      (&nbsp;SUBSCRIPTION FEES for additional profiles&nbsp;)
                    </Link>{" "} */}
                  </center>
                </div>
              </div>
            </Link>
          </div>
          {localStorage.getItem("Type") === "Consultant" || "Product" ? (
            <div className=" col-md-6 col-12">
              <Link to={line} className="wgfiuwrgiu">
                <div
                  className="card carrrrbusss2"
                  style={{ marginTop: "35px" }}
                >
                  <div className="padunnorssd">
                    <center>Go to Lobby</center>
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Welcome;
