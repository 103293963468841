import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AdContext } from "../../Components/advertisement/Advertisementt";

const successCallback = "/api/payment/successAddPayment";
const defaultCreation = "/api/payment/CreateAddPayment";
function displayRazorpay(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
export function AddPayment({ paddingTop, onSuccess, close }) {
  const [creation, setcreation] = useState(defaultCreation);
  const [callback, setcallback] = useState(successCallback);
  const [GSTIN, setGSTIN] = useState("");
  const adContext = useContext(AdContext);
  let setPay = adContext.setPay;
  useEffect(() => {
    // adContext.dispatch({type:"ACCESS"})
    return () => {};
  }, []);

  useEffect(() => {
    console.log(creation, callback);
  }, [creation, callback]);

  let navigate = useNavigate();
  async function RazorPay() {
    console.log(creation);
    console.log(callback);
    const res = await displayRazorpay(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("failed sdk to load");
      return;
    }
    let Edition = "";
    var tx = {
      Coupon,
      Edition,
    };
    axios
      .post(creation, {
        method: "POST",
        body: Coupon,
        Edition,
      })
      .then((t) => {
        var data = t.data;
        console.log(data);
        var options = {
          key: process.env.REACT_APP_KEY_ID, // Enter the Key ID generated from the Dashboard S6PuOik0Pm6t7RcAyX8Ws5i2
          amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "PROchure",
          //  "description": 1,
          order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            success(response);
          },

          theme: {
            color: "#55374a",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
        // document.getElementById('rzp-button1').onclick = function (e) {
        rzp1.open();
        // e.preventDefault();
      });
    function success(res) {
      // alert('payment successful !!')
      console.log(res);
      axios
        .post(callback, { res, Edition, GSTIN })
        .then((res) => {
          console.log("opeiroweir90897");
          setPay(true);
          close();
          console.log(close);
          if (onSuccess) {
            onSuccess();
          }
          if (res.status === 200) {
            console.log("opeiroweir90897");
            // navigate('/')
            // window.location.reload();
          } else {
          }
        })
        .catch((err) => {});
    }
  }

  const [couponError, setcouponError] = useState("");
  const [couponsuccess, setcouponsuccess] = useState("");
  const [Coupon, setCoupon] = useState();

  const checkCoupe = async () => {
    setCoupon("");
    setcouponError("");
    setcouponsuccess("");
    let coupon = document.getElementById("coupe").value;
    let dataSend = {
      coupon,
    };
    setCoupon(coupon);
    try {
      if (coupon.length > 5) {
        let result = await axios.post("/api/coupon/adcheck", { dataSend });
        if (result) {
          setCoupon(coupon);
          setcouponError("");

          setcouponsuccess("Coupon applied ! ");
        }
      } else {
        setcouponError("Invalid coupon / coupon withdrawn");
        console.log("first");
      }
    } catch (error) {
      console.log(error.response.data);
      setcouponError(error.response.data);
    }
  };

  if (paddingTop == "0px") {
  } else {
    paddingTop = "90px";
  }

  return (
    <div style={{ paddingTop }}>
      <div className=" container d-flex justify-content-center">
        <div>
          <div className="container p-1">
            <div className="row ">
              <center>
                {" "}
                <h3 className="eufgiueg mb-3">Make Payment</h3>
              </center>
            </div>
            <div className="mt-2">
              <p>
                If you need a GST invoice for your subscription, then please
                mention your GST number below
              </p>
              <input
                onChange={(e) => setGSTIN(e.target.value)}
                placeholder="Enter GST registration number"
                className="form-control"
                type={"text"}
              />
            </div>
            <h6 className="eufgiueg mt-4">Discount Coupon</h6>
            <div className="container ">
              <div className="row">
                <div className=" col-8 m-0 p-0">
                  {" "}
                  <input
                    type="text"
                    id="coupe"
                    placeholder="Enter Coupon Code "
                    className="form-control bg-light"
                  />
                </div>
                <div className="col-4 m-0 p-0 ps-1">
                  {" "}
                  <button
                    onClick={(e) => checkCoupe(e)}
                    className="btn px-3 w-100 wygdiwgdigwidg"
                  >
                    APPLY
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col"></div>
                <p className="text-success m-0 py-1">
                  {couponsuccess ? couponsuccess : ""}
                </p>
                <p className="text-danger m-0 py-1">
                  {couponError ? couponError : ""}
                </p>

                {/* <div className='col d-flex justify-content-end'>
                                <button onClick={(e) => checkCoupe(e)} className='btn wygdiwgdigwidg'>Apply</button>
                            </div> */}
              </div>
            </div>
            <div className="row">
              <p>
                A copy of your Invoice will be sent to your email id. You can
                also View and Download your Invoice from 'AD SUBSCRIPTION
                DETAILS' in 'Subscription Details and Account Settings' on your
                Profile tab.
              </p>
            </div>
            <div className="row mt-4">
              <button className="btn btn-udaguadgouao" onClick={RazorPay}>
                Pay
              </button>
            </div>

            {/* <div className='row'>
                           
                            <p className='invisible'>wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww</p>
                            <h6 className='eufgiueg'>Apply Coupon</h6>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <input type="text" id='coupe' className='textboxdrete bg-light'  ></input>
                        </div>
                        <div className='row'>
                            <div className='col'></div>
                           

                            <div className='col d-flex justify-content-end'>
                                <button onClick={(e) => checkCoupe(e)} className='btn wygdiwgdigwidg'>Apply</button>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <button className='btn btn-udaguadgouao' onClick={RazorPay}>Pay</button>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
