import React, { useState,  useRef } from "react";
import './post.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import axios from "axios";

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}

export function CommentReply(props) {

    const [Comment, setComment] = useState('')
    // console.log(props.data)
    const [commenter, setcommenter] = useState('@' + props.data.string[0].pname)



    const Replyhere = (val) => {
        // console.log(props.data)
        // console.log(props.dat.merge)
    }
    const commentHandlerPro = (e) => {
        e.preventDefault();
        // console.log(props)
        // console.log(e)
        var btn = 'combtn' + e.target.id.substring(7)
        var data = {
            comment: commenter,
            postid: props.data.Post_id,
            recipient: props.data.Account_id,
            CommentId: props.data.CommentId
        }
        if(commenter.length>0){
        axios.post("/api/User/postreplycomment", { data }).then((response) => {
            if (response.status === 200) {
                // console.log('posted')
                setcommenter('')
                document.getElementById("collap" + props.data._id).click()
                props.liteLoad()
            }
        }).catch(
            res => {
                console.log('Something went wrong')
            }
        )
        }
    }
    return (

        <div>
            <div className="tab ms-1">
                <div className="celle cot"><img alt="" className="selfthumbnailcommentt" src={props.data.profilepic}  onError={({ currentTarget }) => {
                currentTarget.onerror = true; // prevents looping        
                  currentTarget.src =
                  "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png"
              }}/>
                </div>
                <div className="cell ps-0">
                    <div className="Commentcontainer p-1 ">
                        <div className="row">
                            <p className="col-9 commentauthors m-0">{props.data.string[0].pname}<br />
                            </p>
                            <div className="col-2 text-end dropdown dropstart  pe-4">  <a type="button" href="/" className="dotoptionbtn   cell" data-bs-toggle="dropdown" aria-expanded="false"></a>
                                {/* <ul className="dropdown-menu ">
                                    <li><a className="dropdown-item" href="/">Report</a></li>
                                    
                                </ul> */}
                            </div>
                        </div>
                        <p className="commentbody">
                            {/* <a className="referenceat" href="/">@Ananda krishnan gr </a> */}
                            {props.data.Content}
                        </p>
                        {/* <hr className="m-0"/> */}
                    </div>

                </div>

            </div>
            <p onClick={(e) => Replyhere(props.ids + props.ids)} className="replybtn ms-5" data-bs-toggle="collapse" id={"collap" + props.data._id} href={"#collapseExamplenewlaste" + props.data._id} role="button" aria-expanded="false" aria-controls="">Reply</p>
            <div id={"collapseExamplenewlaste" + props.data._id} className="collapse ms-5 " >Replying to {props.data.string[0].pname}
                <div className="p-0 ">
                    <form className="grid-container" onSubmit={(e) => commentHandlerPro(e)}>
                        {/* <div className="row">
                <div className=""></div>
                </div> */}
                        <textarea onChange={(e) => setcommenter(e.target.value)} id={"col" + props.data._id} className="commentInput grid-item1" defaultValue={commenter} type="text" row="4" />
                        <div className="grid-item2  ">
                            <button type="submit" className="btn btn-primary d-flex justify-content-center" style={{ width: '70px' }} >Post</button></div>
                    </form>
                </div> </div>
        </div>

    )

}