
import React, { useState, useEffect } from "react";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./ChatPrevious.css";
import Moment from 'moment';


export function ChatPrevious() {

  useEffect(() => {

    lockeTopics()

  }, [])


  const [LockeTopics, setLockeTopics] = useState([])

  const lockeTopics = async () => {
    var data = await axios.get('/api/User/LokedTopicList')

    if (data.data) {
      setLockeTopics(data.data)
    }

  };
  const UnlockTopic = async (roomId, Topic_id) => {
    var dataSend = {
      roomId: roomId, Topic_id: Topic_id
    }
    // return
    var data = await axios.post('/api/User/UnlockTopic', { dataSend })
    if (data) {
      lockeTopics()
    }
  }

  return (
   
    <div className="p-3 pt-5 mt-5 col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3">
        {LockeTopics ?
          LockeTopics.length > 0  ?



            LockeTopics.map((item) => {
              var date = Moment(item.Startdate).format('DD-MM-YYYY')
              var date1 = Moment(item.Enddate).format('DD-MM-YYYY')
              return <>
     <div className="car p-3 bg-light caded shadow-sm">
                                <div className="card-bdy">

                <div className="row">
                  <div className="col-12">

                    {item.chat.chatName ? item.chat.chatName : 'Nill'}

                  </div>
                </div>

                <div className="row">
                  <div className="col-4"></div>
                  {/* <div className="col-4 center">
            <AiFillMedicineBox size="40" />{" "}
          </div> */}
                  <div className="col-4"></div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="ml-3 w-100">
                    <h4 className="mb-2 mt-0 pt-3 pb-3 center">
                      {item.Topic}
                    </h4>{" "}

                    <div className="row">
                      <div className="col-5 d-flex justify-content-around">Started on</div>
                      <div className="col-2    "></div>
                      <div className="col-5 d-flex justify-content-around">{date}</div>
                    </div>
                    <div className="row">
                      <div className="col-5 d-flex justify-content-around">Ended on</div>
                      <div className="col-2    "></div>
                      <div className="col-5 d-flex justify-content-around">{date1}</div>
                    </div>
                    <div className="row mt-2">
                 
                      <div className="d-flex justify-content-center"><button className="btn pro-spaces-button3" onClick={(e) => UnlockTopic(item.chat._id, item._id)}>Unlock</button></div>
                      
                    </div>
                  </div>
                </div>
      </div>
      </div>

              </>
            })

            :<center><h5>Nothing To Show !</h5></center>
          : ""
        }

    </div>

  );
}
