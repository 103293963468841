import React, { useEffect, useRef, useState, useContext } from 'react'
import CropperModal from '../CropperModal/CropperModal'
import axios from 'axios';
import moment from 'moment';
import { SpinnerSmall, SpinnerSmallTransparent } from '../../Utility/spinner';
import { Modal } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { notify } from '../../Utility/notify'
import { TbReplace } from 'react-icons/tb';
import Archive from './archive';
import { AddPayment } from '../../Pages/Payment/PayForAd'
import { AdContext } from './Advertisementt'

const Occational = () => {
    const today = new Date();
    let adContext = useContext(AdContext)
    let pay = adContext.Pay
    const year = today.getFullYear();
    const [Edit, setEdit] = useState(false) // Edit state
    const [show, setShow] = useState(false) // delete_warning modal state
    const handleClose = () => {
        setcall(null)
        setShow(false)
    }; //delete_warning modal handle
    const [call, setcall] = useState(null)
    const handleShow = (ev) => {
        setcall(ev)
        setShow(true)
    };  //delete_warning modal handle
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const PastDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const dateTime = new Date(localStorage.getItem('Validity'));
    const MaxDate = dateTime.toISOString().split("T")[0];
    console.log(MaxDate);
    const fileInputRef = useRef()
    const [toCrop, settoCrop] = useState(null)
    const [Image, setImage] = useState(null)
    const [Data, setData] = useState({
        from: '',
        to: ''
    })

    const [archiveShow, setarchiveShow] = useState(false)
    const handleArchiveShow = () => {
        setarchiveShow(true)
    }
    const handleArchiveHide = () => {
        setarchiveShow(false)
    }
    const load = () => {
        return new Promise((reolve, reject) => {
            setactionLoading(true)
            axios.get(`/api/User/getOccasionalAdd`).then((res) => {
                console.log(res.data);
                setPageLoading(false)
                setData({
                    from: moment(res.data.from).format('YYYY-MM-DD'),
                    to: moment(res.data.to).format('YYYY-MM-DD')
                })
                if (res.data) {
                    console.log(res.data.File);
                    setImage(res.data.File[0])
                    setEdit(true)
                }
            }).catch((err) => {
                console.log(err);
                setPageLoading(false)
                setData({
                    from: '',
                    to: ''
                })
                setImage(null)
            }).finally(() => {
                setactionLoading(false)
            })
        })
    }
    useEffect(() => {
        // initial loading of previous rotational add
        load()
    }, [])
    const [Error, setError] = useState('')

    const [actionLoading, setactionLoading] = useState(false)
    const [PageLoading, setPageLoading] = useState(true) // PageLoading state
    const validation = () => {
        console.log(Image)
        if (!Image && !Image?.link && !Image?.File) {
            return setError("Upload an image")
        }
        if (Data.to.length == 0) {
            return setError("select a date")
        }
        if (Data.from.length == 0) {
            return setError("select a date")
        }
        setError("")
        return true
    }
    const submit = (e) => {
        e.preventDefault()
        if (validation() != true) {
            return
        }
        if (!pay) {
            return handlePayOpen()
        }
        let formData = new FormData()
        // formData.append('file', Image)
        formData.append('Data', JSON.stringify(Data))
        let metadata = {
            type: 'image/png'
        };

        if (Edit) {
            if (Image.File) {
                formData.append('file', new File([Image.File], `Occasional_prochure.png`, metadata))
            }
            axios.put(`/api/User/OccasionalAdd`, formData).then((res) => {
                console.log(res.data);
                if (res.data) {
                }
                notify("Updated", true)

            }).catch((err) => {
                notify("Something went wrong !", false)
                console.log(err);
            })
        } else {
            if (Image.link) {
                formData.append('Archive', JSON.stringify(Image))
            } else if (Image.File) {
                formData.append('file', new File([Image.File], `Occasional_prochure.png`, metadata))
            }
            axios.post(`/api/User/OccasionalAdd`, formData).then((res) => {
                console.log(res.data);
                if (res.data) {
                    setEdit(true)
                }
                notify("Created", true)
            }).catch((err) => {
                notify("Something went wrong !", false)
                console.log(err);
            })
        }
    }
    const [payShow, setpayShow] = useState(false)
    const handlePayClose = () => {
        setpayShow(false)
    }
    const handlePayOpen = () => {
        setpayShow(true)
    }
    const Delete = (id) => {
        // Fn for delete older images
        if (call) {
            setImage(null)
            handleClose()
            return
        }
        setactionLoading(true)
        axios.delete('/api/User/OccationalDelete').then(async (res) => {
            if (res.data) {
                handleClose()
                setEdit(false)  
                notify("Deleted", true)
                let s = await load()
            }
        }).catch((err) => {
            console.log(err);
            notify("Something went wrong !", false)
        }).finally(() => {
            setactionLoading(false)
        })
    }

    const replaceFromArchive = (ArchiveImageDetails) => {
        console.log(ArchiveImageDetails)
        // return
        if (Edit) {
            // setImage(ArchiveImageDetails)
            axios.post('/api/User/OccationalImageReplaceUsingArchive', { ArchiveImageDetails }).then(async (res) => {
                if (res.data) {
                    notify("Updated", true)
                    let s = await load()
                }
            }).catch((err) => {
                console.log(err);
                notify("Something went wrong !", false)
            }).finally(() => {
                setactionLoading(false)
            })
        } else {
            // fresh
            setImage(ArchiveImageDetails)
        }
    }
    const [choose, setchoose] = useState(false)

    if (!PageLoading) {
        return (
            <form
                onSubmit={submit}
                style={{ position: 'relative', overflow: 'hidden', }}
            >
                {actionLoading ? <SpinnerSmallTransparent /> : ''}
                {/* <b>OCCASION ADs for a DAY / WEEK</b> */}
                <Archive
                    choose={choose}
                    onChange={(e) => {
                        replaceFromArchive(e)
                    }}
                    onClose={() => {
                        setchoose(false)
                    }}
                />
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    {Image ?
                        <img
                            className='img-fluid'
                            style={{ width: '400px' }}
                            src={Image.link ? Image.link : URL.createObjectURL(Image.File)}


                        ></img>
                        :

                        <div className='d-flex justify-content-center align-items-center '
                            style={{ height: '225px', width: '400px', border: '1px dotted black' }}><div>
                                <p className='m-0'>
                                    Select Advertisement Artwork
                                </p>
                                <p className='text-muted m-0'>
                                    <center>Artwork resolution - 16 : 9</center>
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <div className='w-100 d-flex justify-content-end'>
                    {Image && <>
                        <button
                            type='button'

                            className='advertisementimgbtn'
                            onClick={() => {
                                handleArchiveShow()
                            }} >
                            <TbReplace />
                        </button>
                        <button
                            type='button'
                            onClick={() => {
                                if (Image?.link) {
                                    handleShow();
                                } else {
                                    handleShow(true);
                                }
                            }
                            }
                            className='advertisementimgbtn'>
                            <AiFillDelete />
                        </button>

                    </>}
                </div>
                {/* <p>
                    ADs during a festival / occassion can be displayed for a
                    selected duration of time. During this period, your annual
                    rotational ads will stop by default.
                </p> */}
                <p className='mt-5'>
                    <b>SCHEDULE DATES</b>
                </p>
                <div className='row'>
                    <div className='col-5 col-md-5'>
                        <input
                            min={PastDate}
                            type={'date'}
                            value={Data.from}

                            className='form-control px-0 p-md-2'
                            onChange={(e) => {
                                console.log(e.target.value);
                                setData((prev) => ({ ...prev, from: e.target.value }))
                            }}
                        />
                    </div>
                    <div className='col-2 col-md-2 text-center align-items-center justify-content-center py-2'>
                        to
                    </div>
                    <div className='col-5 col-md-5'>
                        <input
                            type={'date'}
                            className='form-control px-0 p-md-2'
                            max={MaxDate}
                            value={Data.to}
                            onChange={(e) => {
                                setData((prev) => ({ ...prev, to: e.target.value }))
                            }}
                        />
                    </div>
                </div>
                <p className='text-danger'>{Error}</p>
                <center>
                    <button
                        type='button'
                        style={{ 'width': '270px' }}

                        className='btn pro-spaces-button my-2 mt-4'
                        onClick={() => {
                            fileInputRef.current.click()
                        }} >
                        SELECT AD ARTWORK
                    </button>
                    <br />
                    <button
                        type='submit'
                        style={{ 'width': '270px' }}

                        className='btn pro-spaces-button3 my-2'
                    >UPLOAD AD</button>
                </center>
                <input
                    ref={fileInputRef}
                    type={'file'}
                    className='d-none'
                    accept="image/*"
                    onChange={(e) => {
                        if (e.target.files.length) {
                            const reader = new FileReader();
                            reader.onload = () => {
                                settoCrop({ file: reader.result })

                            };
                            reader.readAsDataURL(e.target.files[0]);
                        }
                    }}
                ></input>
                <CropperModal toCrop={toCrop} setImage={(e) => setImage({ File: e })} />
                <>
                    <Modal
                        show={show}
                        onHide={() => {
                            handleClose();
                        }}
                        backdrop={'static'}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p className='text-danger'>
                                 Are you sure you want to delete this image ? This action cannot be undone.
                                 </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn pro-spaces-button3"
                                onClick={() => {
                                    Delete()
                                }}
                            >ok</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={payShow}
                        onHide={() => {
                            handlePayClose()
                        }}
                        backdrop={'static'}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddPayment paddingTop={'0px'}  onSuccess={submit} close={handlePayClose} />
                        </Modal.Body>
                    </Modal>
                    <Modal
                        show={archiveShow}
                        onHide={() => {
                            handleArchiveHide()
                        }}
                        backdrop={'static'}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        Where would you like to add the image from?
                            <div className='row g-1'>
                                <div className='col-12 col-md-6 d-flex justify-content-center'>
                                    <button
                                        onClick={() => {
                                            fileInputRef.current.click();
                                            handleArchiveHide()
                                        }}
                                        type='button'
                                        className='btn pro-spaces-button'
                                    >
                                        upload from device
                                    </button>
                                </div>
                                <div className='col-12 col-md-6 d-flex justify-content-center'>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            setchoose(true);
                                            handleArchiveHide();
                                        }}
                                        className='btn pro-spaces-button'

                                    >
                                        upload from archive
                                    </button>
                                </div>
                            </div>


                        </Modal.Body>

                    </Modal>
                </>
            </form>
        )
    } else {
        return <>
            <SpinnerSmall />
        </>
    }
}

export default Occational