import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './recruitPostForm.css'
import { BsFillBriefcaseFill, BsArrowLeft, BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { FaPhoneAlt, } from 'react-icons/fa'
import { ImProfile, } from 'react-icons/im'
import { HiDownload, } from 'react-icons/hi'
import './jobView.css'
import Axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'moment';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExperiencedView from "./experiencedView";
import InternView from "./internView";
import FresherView from "./fresherView";





export const JobSeeker = (props) => {



    useEffect(() => {
        // props.Auth()
        }, [])

        const [expanded, setExpanded] = useState(false);

        const handleChange = (panel) => (event, isExpanded) => {
          setExpanded(isExpanded ? panel : false);
        };


        const notify = (message) =>
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("k")
  
  
    // console.log(id)
    useEffect(() => {
        var tosttype=0
        var count1=0
       
        tosttype = searchParams.get("tosttype")
        if(count1==0)
        {
            if(tosttype==1)
            {
              notify("Posted successfully");
              count1++
        
             
            }
        
        
            else if(tosttype==2)
            {
                notify("Posted updated successfully");
                count1++
            }

        }

      

    
     
    }, [])
    

   





    const [jobDetails, setjobDetails] = useState(false)
    const [Quli, setQuli] = useState()
    const [Qno, setQno] = useState()
    const [Resume,setResume]= useState()
    const [Work,setWork]=useState()
    const [type,setType]=useState()

    const [loader, setloader] = useState(<center><div class="spinner-border " role="status">
        <span class="visually-hidden">Loading...</span>
    </div></center>)
    const [Loading, setLoading] = useState(true)
    useEffect(() => {
        view()
        console.log(jobDetails)
       
    }, [])

    function view(){
        Axios.post('/api/User/JobSeekerView', { id })
        .then(res => {
            if (res.status === 200) {
                console.log(res)
                console.log('jobView Edit')
                // console.log(Id)
                setjobDetails(res.data.data1[0]);
                setQuli(res.data.data1[0].JobSeeker_qualification)
                setQno(res.data.data1[0].JobSeeker_qualificationNo)
                getAge(jobDetails.JobSeeker_dob)
                console.log(jobDetails)
                setResume(res.data.Resume)
                setWork(res.data.Work)
                setType(res.data.data1[0])
                setLoading(false)
                console.log(res.data.data1[0].Type)
               if (res.data.data1[0].Type===1) {
                // console.log("haiii")
                    <ExperiencedView value={res.data.data1[0]} />
               }
               if (res.data.data1[0].Type===2) {
                <InternView value={res.data.data1[0]} />
                }
                if (res.data.data1[0].Type===3) {
                  <FresherView value={res.data.data1[0]} />
                  }
                 

            }
        }).catch((err) => {
            setLoading(false)
            console.log(err)
            alert("sonthing went wrong");

        })

    }


    //   console.log("hai"+Quli)




    const [currentIndex, setcurrentIndex] = useState(1)
    var total = 5

    const Nextone = (s) => {


        if (s) {
            console.log('forward')

            if (currentIndex == total) {
                console.log('reached')
                return

            } else {
                document.getElementById('fq' + currentIndex).classList.add('d-none')
                document.getElementById('fq' + (currentIndex + 1)).classList.remove('d-none')
                setcurrentIndex(currentIndex + 1)

            }

        }
        else {
            console.log('backward')

            if (currentIndex == 1) {
                console.log('reached')

                return

            } else {
                document.getElementById('fq' + currentIndex).classList.add('d-none')
                document.getElementById('fq' + (currentIndex - 1)).classList.remove('d-none')
                setcurrentIndex(currentIndex - 1)
            }



        }
    }

    // useEffect(() => {
    //     if (currentIndex == 1) {
    //         document.getElementById('bBtn').classList.add('d-none')
    //     } else {
    //         document.getElementById('bBtn').classList.remove('d-none')
    //     }
    //     if (currentIndex == total) {
    //         document.getElementById('btnNext1').classList.add('d-none')
    //         document.getElementById('btnNext2').classList.remove('d-none')
    //     }
    //     else {
    //         document.getElementById('btnNext1').classList.remove('d-none')
    //         document.getElementById('btnNext2').classList.add('d-none')
    //     }
    // }, [currentIndex])



    function getAge() {
        var today = new Date();
        var birthDate = new Date();
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
 
    var date1=Moment(jobDetails.JobSeeker_workingstart).format('MM-YYYY')
    var date2=Moment(jobDetails.JobSeeker_breakSince).format('MM-YYYY')


// 

    return (
        <div className='wleklghoiwhgihepighpiwh'>

{Loading?loader:
<>
            <form style={{
                borderRadius: '15px'
            }} >
              {/* className=' container conter card border pb-2 pt-2 px-4' */}
       
  

                     {
                      jobDetails.Type===1?
                      <ExperiencedView value={jobDetails} resume={Resume} work={Work} /> 
                      :jobDetails.Type===2?  <InternView value={jobDetails} resume={Resume} work={Work} />:jobDetails.Type===3?<FresherView value={jobDetails} resume={Resume} work={Work} ></FresherView>:''
                     }

            </form>
            </>

}
<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        </div>)
}
