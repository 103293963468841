import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { JobSearch } from "../../Components/JobSearch/JobSearch";
import "./JobReqPost.css";
import { Joblist } from "../../Components/JobVacancyList/jobList";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { JobSeekersList } from "../../Components/JobSeekerList/jobSeekerList";

export const JobReqPost = (props) => {
  const [reset, setreset] = useState(0);
  useEffect(() => {
    props.Auth();
  }, []);

  return (
    <div className="container-fluid dslihgluho ">
      {localStorage.getItem("Type") === "Consultant" ||
      localStorage.getItem("Type") === "Product"||localStorage.getItem("Type") === "Consultantv2"||localStorage.getItem("Type") === "Productv2" ? (
        <p className="float-end mt-1">
          <div className="row g-1">
            <div className="col-12 col-sm-6">
              <Link to="/post_job_vacancy" className="btn btnpost w-100">
                POST&nbsp;A&nbsp;NEW&nbsp;JOB&nbsp;VACANCY
              </Link>
            </div>
            <div className="col-12 col-sm-6">
              <Link to="/job_vacancy_my_post" className=" btn btnpost w-100">
                VIEW&nbsp;EXISTING&nbsp;JOB&nbsp;VACANCY
              </Link>
            </div>
          </div>
        </p>
      ) : (
        ""
      )}

      <br />

      <div className="collsapse" id="jo">
        <JobSearch key={reset} setreset={setreset} reset={reset} />
      </div>
      <div>{/* <JobSeekersList /> */}</div>
    </div>
  );
};
