import React from 'react'

export const Selfdeclaration = (props) => {

    return (
        <>
            {props.type === 'normal' &&
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" required id="flexCheckCheckeddsdf" onChange={(e) => props.setSelfDeclaration((prev) => !prev)} />
                    <label className="form-check-label" style={{ fontWeight: 'normal' }} for="flexCheckCheckeddsdf">
                        I/We hereby declare that all the information provided in this profile is true.
                    </label>
                    <div class="invalid-feedback">
                        You must agree before submitting.
                    </div>
                </div>
            }

            {props.type === 'job' &&
                <>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="flexCheckCheckeddsdf" onChange={(e) => props.setSelfDeclaration((prev) => !prev)} />
                        <label className="labels" for="flexCheckCheckeddsdf">
                            I hereby declare that I have No disposed off or pending criminal case (s) against me anywhere within India or outside India.
                        </label>
                    </div>
                    <br />
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="flexCheckCheckeddsdf" onChange={(e) => props.setSelfDeclaration2((prev) => !prev)} />
                        <label className="labels" for="flexCheckCheckeddsdf">
                            I hereby declare that all the information provided in this job application form is true.
                        </label>
                    </div>
                </>
            }

            {props.type === 'vdp' &&
                <p className='mt-3'>
                    <center>I/We hereby declare that all the information provided in this profile is true.</center>
                </p>
            }

            {props.type === 'jobview' &&
                <>
                    <p>
                        I hereby declare that I have No disposed off or pending criminal case (s) against me anywhere within India or outside India.

                    </p>
                    <p>
                        I hereby declare that all the information provided in this job application form is true.
                    </p>
                </>
            }
        </>

    )
}
