import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./chat.css";
import { ChatRight } from "./chatRight";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChatinviteSearch } from "../../Components/ChatinviteSearch/ChatinviteSearch";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Folder } from './folder'
import { ChatContext } from '../../Components/ChatFinal/ChatFinal'
import { useSelector } from 'react-redux';

let prev = null
const fcheck = (ev) => {
  if (ev == prev) {
    return false
  } else {
    prev = ev
    return true
  }
}

var user = localStorage.getItem("User");

const Chat = forwardRef((props, ref) => {
  let chatContext = useContext(ChatContext)
  console.log(chatContext)
let socket = useSelector((state) => state.cubicle.value);
console.log(socket);
  const [RoomDetails, setRoomDetails] = useState({
    id: '', group: '', admin: "", name: '', lock: ''
  });

  useEffect(() => {
    setRoomDetails(chatContext.RoomDetails)
    return () => {

    }
  }, [chatContext.RoomDetails])

  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams(); /////getting id from the url the id of the jobs id
  var id = false;
  id = searchParams.get("consultantTrue");
  const childFunc = useRef(null);
  const [Reload, setReload] = useState(false);
  const [room, setroom] = useState();

  const [groupInvite, setgroupInvite] = useState();
  const [GroupMembers, setGroupMembers] = useState();
  const [MemeberNT, setMemeberNT] = useState();
  const [force, setforce] = useState(true)
  const [listTopic, setlistTopic] = useState();
  const [noMembers, setnoMembers] = useState(false);
  const [ViewTopicMember, setViewTopicMember] = useState();
  const chatrightref = useRef(null);

  useImperativeHandle(ref, () => ({
    changeMessage(id, group, admin, name, lock) {
      setRoomDetails({ id, group, admin, name, lock })
    }
  }));

  useEffect(() => {
    if (id)
      if (id.length === 24) {
        setRoomDetails({ id, group: false, name: "" });
        // redirect set name
      }
  }, []);

  useEffect(() => {
    // console.log(RoomDetails);
  }, [RoomDetails]);

  useEffect(() => {
    if (RoomDetails.id)
      if (RoomDetails.id.length == 24) {
        ListGroupTopic();
      }
  }, [RoomDetails.id]);

  function keyGen() {
    var length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const notify = (message, stat) => {
    // console.log(message);
    // console.log(stat)

    if (stat == "success") {
      // console.log("toasfted");
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == "danger") {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const createroom = async (e, move = false) => {
    if (!move) {
      if (e.key != "Enter") {
        return;
      }
    }

    var name = document.getElementById("roomname").value;
    var topic = document.getElementById("Topic").value;
    // // console.log("enter room name", name);
    // toast fix
    if (name.length === 0) {
      // // console.log("enter room name");
      // toast fix
      return notify("Enter a room name !", 'danger');
      // return alert("Enter a room name");
    }

    if (topic.length === 0) {
      // // console.log("enter topic name");
      // fix
      return notify("Topic name !", 'danger');
      // return alert("topic name");
    }
    let data = {
      name,
      topic,
    };
    var res = await axios.post("/api/User/newroom", { data });
    if (res) {
      document.getElementById("createroombtncls12").click();
      document.getElementById("roomname").value = "";
      document.getElementById("Topic").value = "";

      props.setReload(!props.Reload);
      notify("Room Created !", 'success');
    }
    // // console.log(res);
  };

  const GroupInvite = async (Room) => {
    // console.log('first')
    // console.log(Room)
    var data = await axios.get("/api/User/communications");
    // // console.log(data.data);
    if (data) {
      setgroupInvite(data.data);
    }
  };
  const ShowMember = async () => {
    let dataSend = { roomId: RoomDetails.id };
    var data = await axios.post("/api/User/groupMembers", { dataSend });
    if (data) {
      setGroupMembers(data.data);
    }
  };
  const CreateNewTopic = async (e) => {
    //  if (!move) {
    //   if (e.key != "Enter") {
    //     return;
    //   }
    // }

    var topic = document.getElementById("CreateNewTopicrtr").value;
    if (topic.length === 0) {
      // // console.log("enter topic name");
      // fix
      return alert("topic name");
    }

    if (topic.length > 0) {
      let dataSend = {
        roomId: RoomDetails.id,
        Topic: topic,
      };
      // // console.log(dataSend);fix
      //  return
      var data = await axios.post("/api/User/CreatNewTopic", { dataSend });
      if (data) {
        // setGroupMembers(data.data)
        document.getElementById("CreateNewTopicrtrss").click();
        document.getElementById("CreateNewTopicrtr").value = "";
        setReload(!Reload);
        chatrightref.current.ChangerToHeader('gooo')
        notify("Topic Created !", 'success');

        // // console.log(data);
      }
    }
  };
  const TopicMembersAddView = async (Topicid) => {
    // // console.log("oddd");
    // // console.log(Topicid, RoomDetails.id);
    let dataSend = { roomId: RoomDetails.id, Topic_id: Topicid };
    // return
    var data = await axios.post("/api/User/TopicMembersAddView", { dataSend });
    // // console.log(data);
    if (data) {
      setMemeberNT(data.data);
    }
  };
  const LockTopic = async (Topicid) => {
    var dataSend = {
      roomId: RoomDetails.id,
      Topic_id: Topicid,
    };
    // socket.emit("LockTopic", dataSend);
    // return

    // console.log('topic lock')
    // console.log(dataSend)

    var data = await axios.post("/api/User/LockTopic", { dataSend });
    if (data) {
      notify("Locked Topic", 'success');
      chatrightref.current.ChangerToHeader('LockedTopic')
      let room = RoomDetails.id
      socket.emit("LockTopic", dataSend);
      setRoomDetails({ ...RoomDetails, topic: null });
    }
  };
  function button() {
    var w = window.innerWidth;
    console.log(w)
    if (w > 991) {
      document.getElementById("chatGo").classList.add("d-none");
      // document.getElementById('df').classList.add('col-lg-12')
    } else {
      console.log(document.getElementById("chatGo").classList)
      document.getElementById("chatGo").classList.remove("d-none");
      console.log(document.getElementById("chatGo").classList)

      // document.getElementById('df').classList.add('col-lg-9')
      // document.getElementById('df').classList.remove('col-lg-12')
    }
  }
  const Changetopic = (topic, text) => {
    // // console.log(topic);
    setforce(!force)
    setRoomDetails({ ...RoomDetails, topic: topic, topicName: text });
    // setRoomDetails({ ...RoomDetails, topicName: text });

    // setRemoveTopic(topic)
  };
  // window.onresize = button;
  const viewTopicMember = async (Topicid) => {
    var dataSend = {
      roomId: RoomDetails.id,
      Topic_id: RoomDetails.topic,
    };
    // // console.log(dataSend);
    // return
    var data = await axios.post("/api/User/viewTopicMember", { dataSend });
    if (data) {
      setViewTopicMember(data.data);
      // // console.log(data.data);
    }
  };
  const sendInvite = async (user, stat) => {
    // console.log(user);

    let id;
    if (stat) {
      id = user
    } else {
      if (user[0] === localStorage.getItem("User")) {
        id = user[1];
      } else {
        id = user[0];
      }
    }



    if (id && RoomDetails.id) {
      let dataSend = {
        target: id,
        group: RoomDetails.id,
      };
      var result = await axios.post("/api/user/sendGroupInvitation", {
        dataSend,
      });
      console.log(result)
      socket.emit('getMesswith', result.data)
      notify('Invited', 'success')
    }
  };
  const RemovefromTopic = async (userId, Topicid, roomId) => {
    var dataSend = {
      userId: userId,
      Topicid: Topicid,
      roomId: roomId,
    };
    // // console.log(dataSend);
    // return
    dataSend.setup = 'RemoveFromTopic'
    dataSend.userid = userId

    var remove = await axios.post("/api/User/RemovefromTopic", { dataSend });
    if (remove) {
      notify("Removed from topic", 'success');
      socket.emit('controller', dataSend)

      viewTopicMember();
    } else {
      notify("Sorry, Something happened!!!!", 'danger');
    }
  };
  const RemovefromGroup = async (userId, roomId) => {
    var dataSend = {
      userId: userId,
      roomId: roomId,
    };
    // // console.log(dataSend);

    var remove = await axios.post("/api/User/RemovefromGroup", { dataSend });
    if (remove) {
      notify("Removed from group", 'success');
      ShowMember();
      dataSend.setup = 'RemoveFromGroup'
      dataSend.userid = userId
      socket.emit('controller', dataSend)

      return
    } else {
      notify("Sorry, Something happened!!!!", 'danger');
    }
  };
  const NoMembers = async () => {
    var dataSend = {
      roomId: RoomDetails.id,
    };
    // // console.log(dataSend);
    // return
    var datas = await axios.post("/api/User/NoInGroup", { dataSend });
    if (datas) {
      // // console.log(datas.data);
      setnoMembers(datas.data);
    }
  };
  const AddToTopic = async (userid) => {
    var dataSend = {
      roomId: RoomDetails.id,
      userid: userid,
      Topicid: RoomDetails.topic,
    };
    // // console.log(dataSend);
    // return
    var datas = await axios.post("/api/User/AddToTopic", { dataSend });
    if (datas) {
      // // console.log(datas.data);
      dataSend.setup = 'AddToTopic'
      socket.emit('controller', dataSend)
      notify("Added to topic!!", 'success');
      TopicMembersAddView();
    }
  };
  async function ListGroupTopic() {
    var id = RoomDetails.id;
    // return
    // console.log(id)
    if (id.length != 24) return
    var data = await axios.post("/api/User/ListGroupTopicx", { id });
    if (data) {
      // // console.log("I am from backend");
      setlistTopic(data.data);
      // // console.log(data.data);
    }
  }
  const ChangetopicNE = async (Topicid, userId) => {
    var dataSend = {
      roomId: RoomDetails.id,
      userid: userId,
      Topicid: Topicid,
    };
    // // console.log(dataSend);
    // return
    var datas = await axios.post("/api/User/AddToTopic", { dataSend });
    if (datas) {
      // // console.log(datas.data);
      notify("Added to topic!!", 'success');
      NoMembers();
    }
  };
  async function Block() {

    var roomId = RoomDetails.id
    // // console.log(roomId)
    if (roomId) {
      axios.post('/api/User/BlockChat', { roomId })
        .then(res => {
          if (res.status === 200) {
            notify("The Account is Blocked !!", 'success');
            setRoomDetails('');
            // // console.log('chat blocked') fix
            // // console.log(res.data)
            navigate('/cubicles')
          }
        }).catch((err) => {
          // // console.log(err.response.data)
          notify("Sorry !!", 'danger');
        })
    }
    else {
      // // console.log("Somthing Happand")fix
    }

  }

  useEffect(() => {
    // console.log(user)
    socket.on('controll', (val) => {
      // console.log('controll')
      var op = fcheck(JSON.parse(JSON.stringify(val)))
      if (!op) {
        return
      }
      chatHandler(val)
    })
  })

  const chatHandler = (r) => {

    if (r.setup === 'AddToTopic') {
      if (r.roomId === RoomDetails.id) {
        chatrightref.current.ChangerToHeader('AddedToTopic')
      }
    } else if (r.setup === 'RemoveFromTopic') {
      if (RoomDetails.topic === r.Topicid) {
        socket.emit('remove', RoomDetails.id + RoomDetails.topic)
        chatrightref.current.ChangerToHeader('AddedToTopic')
        setRoomDetails({ ...RoomDetails, topic: null });
      }
    }
    else if (r.setup === 'RemoveFromGroup') {
      socket.emit('remove', RoomDetails.id)
      socket.emit('remove', RoomDetails.id + RoomDetails.topic)
      setRoomDetails({ ...RoomDetails, topic: null, id: null });
      props.ReloadList()
    }
  }

  var bucketName = 'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/profile/'
  // var bucketName='https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/profile'


  return (
    <>
      {/* <button className="mt-5" onClick={(e)=>LockTopic()}>vvv</button> */}
      <ChatRight
        ReloadList={props.ReloadList}
        ref={chatrightref}
        force={force} 
        Block={Block}
        viewTopicMember={viewTopicMember}
        NoMembers={NoMembers}
        GroupInvite={GroupInvite}
        Changetopic={Changetopic}
        ShowMember={ShowMember}
        CreateNewTopic={CreateNewTopic}
        TopicMembersAddView={TopicMembersAddView}
        socket={socket}
        LockTopic={LockTopic}
        RoomDetails={RoomDetails}
      />

      <div style={{
        'position': 'fixed'
      }}>
        <div class="modal" tabindex="-1" id="createroom" data-bs-backdrop="false" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <label>Name of Conference room</label>
                <input
                  type="text"
                  id="roomname"
                  placeholder="Give a Name to the chat group"
                  onKeyPress={(e) => createroom(e)}
                  className="form-control"
                />

                <label>Name of Cubicle ( topic of discussion )</label>
                <input
                  type="text"
                  placeholder="Give a Name to the chat topic"
                  id="Topic"
                  onKeyPress={(e) => createroom(e)}
                  className="form-control"
                />
              </div>
              <div className="modal-footer pt-0">
                <button
                  type="button"
                  className="btn pro-spaces-button"
                  id="createroombtncls12"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={(e) => createroom(e, true)}
                  className="btn pro-spaces-button3"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="addmembermodal" data-bs-backdrop="false"
          tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <span className="viewmemberHeading" id="addmembermodalid">
                  Invite Members
                </span>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {groupInvite
                  ?

                  groupInvite.length > 0 ?
                    groupInvite.map((item) => {
                      var dp = `${bucketName}/${item._id}/avatar.png`
                      console.log(dp)
                      if (item.isGroupChat == false) {
                        return (
                          <div key={keyGen()}
                            id="a3"
                            className="p-3 d-flex align-items-center border-bottom  "
                          >
                            <div className="dropdown-list-image me-3">
                              <img
                                className="rounded-circle chatAvatar me-2 align-self-center  shadow-1-strong"
                                src={dp}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
                                }}
                              />
                            </div>

                            <div className="font-weight-bold me-3">
                              <div className="text-truncate">
                                <b className="htyyth" >{item.chatName}</b>
                              </div>
                            </div>

                            <span className="ms-auto mb-auto">
                              <div className="">
                                <button
                                  type="button"
                                  onClick={(e) => sendInvite(item.users, false)}
                                  className="btn pro-spaces-button2   "
                                >
                                  Invite
                                </button>
                              </div>
                            </span>
                          </div>
                        );
                      }
                    })

                    : "No one to invite"
                  : "No one to invite"}

                <a
                  data-bs-toggle="collapse"
                  href="#invite"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  className="btn pro-spaces-button3 my-2"
                >
                  Search for Active Members
                </a>
                <div className="collapse" id="invite">
                  <div className="card   card-body">
                    <ChatinviteSearch sendInvite={sendInvite} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="viewmember" data-bs-backdrop="false"
          tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <span className="viewmemberHeading">All members of Conference room</span>
                <button type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div className="modal-body">






                {GroupMembers ?
                  GroupMembers.length > 1 ?
                    (
                      GroupMembers.map((elem) => {
                        if (localStorage.getItem("User") != elem.user._id) {
                          return (
                            <div key={keyGen()}
                              id="a3"
                              className="p-3 d-flex align-items-center border-bottom  "
                            >
                              <div className="dropdown-list-image me-3">
                                <img
                                  className="imageofc"
                                  src={`${bucketName}/${elem.user._id}/avatar.png`}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                      "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
                                  }}
                                />
                              </div>
                              <div className="font-weight-bold me-3">
                                <div className="text-truncate">
                                  <b className="inviteName">{elem.user.pname}</b>
                                </div>
                              </div>
                              <span className="ms-auto mb-auto">
                                <div className="">
                                  {RoomDetails.admin ? (
                                    <button
                                      type="button"
                                      className="btn px-2 pro-spaces-button2 "
                                      onClick={(e) =>
                                        RemovefromGroup(elem.user._id, RoomDetails.id)
                                      }
                                    >
                                      Remove
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </span>
                            </div>
                          );
                        }
                      })
                    )
                    : "No members in conference room"
                  : (
                    <></>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="viewnewmember" data-bs-backdrop="false"
          tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <span className="viewmemberHeading">Members unassigned to any Cubicle</span>
                <button type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div className="modal-body">


                {noMembers

                  ?
                  noMembers.length > 0 ?
                    noMembers.map((item) => {
                      return (
                        <div key={keyGen()}
                          id="a3"
                          className="p-3 d-flex align-items-center border-bottom  "
                        >
                          <div className="dropdown-list-image me-3">
                            <img className="imageofc" src={`${bucketName}/${item._id}/avatar.png`}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
                              }} />
                          </div>
                          <div className="font-weight-bold me-3">
                            <div className="text-truncate">
                              <b>{item.pname}</b>
                            </div>
                          </div>
                          <span className="ms-auto mb-auto">
                            <div className="">
                              <select
                                onChange={(e) =>
                                  ChangetopicNE(e.target.value, item._id)
                                }
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected option value={"0"}>
                                  Add to a topic
                                </option>
                                {listTopic
                                  ? listTopic.map((item1) => {
                                    return (
                                      <option key={keyGen()} value={item1._id}>
                                        {item1.Topic}
                                      </option>
                                    );
                                  })
                                  : "No topic to select"}
                                {/* <option>Female</option> */}
                                {/* <option>Other</option> */}
                              </select>
                            </div>
                          </span>
                        </div>
                      );
                    })
                    : "No new members"
                  : "No new members"}
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="viewnewmemberintopic" data-bs-backdrop="false"
          tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <span className="viewmemberHeading">Members in {RoomDetails ? RoomDetails.topicName && RoomDetails.topicName : 'Cubicle'}</span>
                <button type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div className="modal-body">


                {ViewTopicMember ?
                  ViewTopicMember.length > 1 ?
                    (
                      ViewTopicMember.map((elem) => {
                        if (localStorage.getItem("User") != elem.dta._id) {
                          return (
                            <div key={keyGen()}
                              id="a3"
                              className="p-3 d-flex align-items-center border-bottom  "
                            >
                              <div className="dropdown-list-image me-3">
                                <img className="imageofc" src={`${bucketName}/${elem.dta._id}/avatar.png`}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                      "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
                                  }} alt="" />
                              </div>
                              <div className="font-weight-bold me-3">
                                <div className="text-truncate">
                                  <b>{elem.dta.pname}</b>
                                </div>
                              </div>
                              <span className="ms-auto mb-auto">
                                <div className="">
                                  <button
                                    type="button"
                                    className="btn  pro-spaces-button2 "
                                    onClick={(e) =>
                                      RemovefromTopic(
                                        elem.dta._id,
                                        RoomDetails.topic,
                                        RoomDetails.id
                                      )
                                    }
                                  >
                                    Remove
                                  </button>
                                </div>
                              </span>
                            </div>
                          );
                        }
                      })
                    )
                    : 'No one here !'

                  : (
                    <></>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="CreateTopic" data-bs-backdrop="false"
          tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <label>Cubicle</label>
                <input
                  type="text"
                  id="CreateNewTopicrtr"
                  className="form-control"
                />
              </div>
              <div className="modal-footer  pt-0">
                <button
                  type="button"
                  className="btn pro-spaces-button"
                  id="CreateNewTopicrtrss"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={(e) => CreateNewTopic(e)}
                  className="btn pro-spaces-button3"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="InviteMember" data-bs-backdrop="false"
          tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <span className="viewmemberHeading" id="addmembermodalid">
                  Add Members
                </span>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {MemeberNT ? MemeberNT.length > 0
                  ? MemeberNT.map((item) => {
                    return (
                      <div key={keyGen()}
                        id="a3"
                        className="p-3 d-flex align-items-center border-bottom  "
                      >
                        <div className="dropdown-list-image me-3">
                          <img className="imageofc" src={`${bucketName}/${item.ioo._id}/avatar.png`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
                            }} alt="" />
                        </div>
                        <div className="font-weight-bold me-3">
                          <div className="text-truncate">
                            <b>{item.ioo.pname}</b>
                          </div>
                        </div>
                        <span className="ms-auto mb-auto">
                          <div className="">
                            <button
                              type="button"
                              className="btn  btn-sm   pro-spaces-button2 "
                              onClick={(e) => AddToTopic(item.ioo._id)}
                            >
                              Add
                            </button>
                          </div>
                        </span>
                      </div>
                    );
                  })
                  : " No members available from this group "
                  : " "}
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade podamonea pt-5" id="Folder" data-bs-backdrop="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered ">



            <Folder RoomDetails={RoomDetails} />

            {/* <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div> */}

          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
})

export default Chat;
