import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './Priceing.css';
import { Navoutside } from '../Navoutside/Navoutside';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  AiFillExclamationCircle,
  AiOutlineClose,
  AiFillInfoCircle,
} from 'react-icons/ai';
import { IoIosHelpCircle } from 'react-icons/io';
import axios from 'axios';
import { NewNavigationBar } from '../../Pages/Free/NewNavigationBar/NewNavigationBar';
import { dataTable } from '../../Utility/userFeaturesData';

export function Priceing() {
  const [first, setfirst] = useState(0);
  const [first1, setfirst1] = useState(0);
  const [first2, setfirst2] = useState(0);
  const [first3, setfirst3] = useState(0);
  const [first4, setfirst4] = useState(0);

  const alerttt = (e) => {
    alert('Registration now open only for Active members.');
  };

  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  const Auth = () => {
    axios
      .get('/api/verify/authenticateduser')
      .then((res) => {
        if (res.status === 200) {
          console.log('workd');
          setAuthenticated(true);
          navigate('/');
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  };

  const [Price, setPrice] = useState({});
  useEffect(() => {
    axios
      .get('/api/free/getsubscriptionfees')
      .then((res) => {
        console.log(res);
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    Auth();
  }, []);

  useEffect(() => {
    axios
      .get('/api/Free/passivecount', {})
      .then((res) => {
        setfirst(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get('/api/Free/concount', {})
      .then((res) => {
        setfirst1(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get('/api/Free/procount', {})
      .then((res) => {
        setfirst2(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get('/api/Free/ConsultantCountV2', {})
      .then((res) => {
        setfirst3(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get('/api/Free/ProCountV2', {})
      .then((res) => {
        setfirst4(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <NewNavigationBar />

      <div className="container d-none py-3 " style={{ marginTop: '70px' }}>
        <center className="py-2">
          <h5>Select your Membership</h5>
          <p className="m-0 p-0">(Choose between BASIC, PRIME and ELITE)</p>
        </center>
        <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-3 mb-3 text-center d-flex justify-content-center">
          <div className="col ">
            <div className="card mb-4 rounded-3 shadow-sm ">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">
                  <b style={{ color: '#55374a' }}></b>For Services, Products
                </h4>
              </div>
              <div className="card-body">
                <span
                  style={{ color: '#55374a' }}
                  data-bs-toggle="modal"
                  data-bs-target="#dthdjtxasjdewfwfgugafeoifhoegsjwsjymtmumyg"
                >
                  BASIC listing{' '}
                  <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                </span>
                <h1
                  className="card-title pricing-card-title pt-3"
                  style={{ fontSize: '1.6em' }}
                >
                  ₹ 500{' '}
                  <small style={{ fontSize: '16px' }}>/- per&nbsp;year</small>
                </h1>
                <p>( Fee Inclusive of 18% GST )</p>
                <Link to="/static_listing_register">
                  <button
                    type="button"
                    className="w-100 btn btn-lg pricebuttoncolor"
                    // onClick={(e)=>alerttt()}
                  >
                    Get started
                  </button>
                </Link>
              </div>
              <div
                className="card-header py-3 d-none"
                style={{ borderTop: '1px solid rgba(0,0,0,.125)' }}
              >
                <p className="my-0 fw-normal">ONE Profile</p>
                <p className="my-0 fw-normal">
                  SINGLE Portfolio / Catalogue creation
                </p>
                <p className="my-0 fw-normal">
                  <Link
                    to="/lobby/user_features"
                    style={{ textDecoration: 'none', color: '#55374a' }}
                  >
                    Other User features{' '}
                    <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm ">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">
                  <b style={{ color: '#55374a' }}></b>For Services, Products
                </h4>
              </div>
              <div className="card-body">
                <span
                  style={{ color: '#55374a' }}
                  data-bs-toggle="modal"
                  data-bs-target="#dthdjtxasjdewfwfgugafgsjwsjymtmumyg"
                >
                  ELITE membership{' '}
                  <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                </span>
                <h1
                  className="card-title pricing-card-title pt-3"
                  style={{ fontSize: '1.6em' }}
                >
                  ₹ {Price?.Activev2}{' '}
                  <small style={{ fontSize: '16px' }}>/- per&nbsp;year</small>
                </h1>

                <p>( Fee Inclusive of 18% GST )</p>
                <Link to="/active_register">
                  <button
                    type="button"
                    className="w-100 btn btn-lg pricebuttoncolor"
                    // onClick={(e)=>alerttt()}
                  >
                    Get started
                  </button>
                </Link>
              </div>
              <div
                className="card-header py-3 d-none"
                style={{ borderTop: '1px solid rgba(0,0,0,.125)' }}
              >
                <p className="my-0 fw-normal">ONE Profile</p>
                <p className="my-0 fw-normal">
                  SINGLE Portfolio / Catalogue creation
                </p>
                <p className="my-0 fw-normal">
                  <Link
                    to="/lobby/user_features"
                    style={{ textDecoration: 'none', color: '#55374a' }}
                  >
                    Other User features{' '}
                    <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">
                  <b style={{ color: '#55374a' }}></b>For Services, Products
                </h4>
              </div>
              <div className="card-body">
                <span
                  style={{ color: '#55374a' }}
                  data-bs-toggle="modal"
                  data-bs-target="#dthdjtxfgefwweugfoefsjwsjymtmumyg"
                >
                  PRIME membership{' '}
                  <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                </span>
                <h1
                  className="card-title pricing-card-title pt-3"
                  style={{ fontSize: '1.6em' }}
                >
                  ₹ {Price?.Active}{' '}
                  <small style={{ fontSize: '16px' }}>/- per&nbsp;year</small>
                </h1>

                <p>( Fee Inclusive of 18% GST )</p>

                <Link to="/pro-active_register">
                  <button
                    type="button"
                    className="w-100 btn btn-lg pricebuttoncolor"
                  >
                    Get started
                  </button>
                </Link>
              </div>
              <div
                className="card-header py-3 d-none"
                style={{ borderTop: '1px solid rgba(0,0,0,.125)' }}
              >
                <p className="my-0 fw-normal">TEN Profiles</p>
                <p className="my-0 fw-normal">
                  UNLIMITED Portfolio / Catalogue creation
                </p>
                <p className="my-0 fw-normal">
                  <Link
                    to="/lobby/user_features"
                    style={{ textDecoration: 'none', color: '#55374a' }}
                  >
                    Other User features{' '}
                    <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="dthdjtxfgsjwsjymtmumyg"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div class="modal-header" style={{ backgroundColor: 'black' }}>
                <div className="weigh3rohbiwhbroiwehg">
                  <AiOutlineClose
                    style={{ color: 'white' }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></AiOutlineClose>
                </div>
              </div>
              <div className="modal-body" style={{ backgroundColor: 'black' }}>
                <div>
                  <p style={{ color: 'white' }}>
                    <span style={{ color: '#c85366' }}>PRO-ACTIVE</span> members
                    : Members with professional / business profiles as
                    Consultants / Vendors. <br />
                    Professional Consultants, Entrepreneurs, Artists, Artisans,
                    Art Dealers, Trainers, Technically Skilled Service
                    Providers, Commercial Service Providers, Product Vendors,
                    Business Enterprises. <br />
                    <br />
                    Pro-Active members can create single or multiple profiles
                    upto a maximum of 15 (fifteen) in total in various
                    sub-categories of various categories as Consultants and/or
                    as Vendors depending on the different types of consultation
                    / services / products they are offering.
                    <br />
                    Pro-Active members can post anything related to their
                    professional services, job applications, job offers,
                    showcase their works, products & catalogues in the Portfolio
                    / Catalogue sections. Pro-Active members have access to ALL
                    the features of the platform. (Please refer USER FEATURES on
                    nav bar).
                    <br />
                    <br />A PRO-ACTIVE profile can be downgraded to an ACTIVE
                    profile but only during renewal after a year of
                    subscription.
                  </p>
                  <br />
                  <h5 style={{ color: '#c85366' }}>PROFILE SWITCHING</h5>
                  <br />
                  <p style={{ color: 'white' }}>
                    Profile Switching is only visible and applicable to
                    Pro-Active members who can create more than one profile from
                    a single registration.
                  </p>
                  <br />
                  <p style={{ color: 'white' }}>
                    1. This is very useful inorder to ensure that the ‘Post is
                    Relevant to the Profile’ from which it is posted.
                  </p>
                  <br />
                  <p style={{ color: 'white' }}>
                    It is a Feature of Convenience during Real-time Posts. A
                    Pro-Active member can choose from which profile he/she must
                    Post on the News Feed since Posts will reflect the Profile
                    Name, Category and Sub Category of the profile that is
                    currently selected.
                  </p>
                  <br />
                  <p style={{ color: 'white' }}>
                    2. In order to Add a Portfolio / Catalogue or Communicate
                    from a particular profile, members with multiple profiles
                    will need to Switch to the Relevant Profile.
                  </p>
                  <br />
                  <h5 style={{ color: '#c85366' }}>PASSIVELY ACTIVE</h5>
                  <br />
                  <p style={{ color: 'white' }}>
                    Pro-Active members who are on a long temporary break from
                    work / business can keep their profiles inactive for a while
                    WITHOUT DEACTIVATING the account.
                  </p>
                  <br />
                  <p style={{ color: 'white' }}>
                    The profile can be hidden and continue to use the platform
                    with the privileges of a Passive member.
                  </p>
                  <br />
                  <br />

                  <center>
                    <div
                      className="card mb-4 rounded-3 shadow-sm "
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">Active members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">FREE</h1>

                        <Link to="/active_register">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="w-100 btn btn-lg pricebuttoncolor"
                            // onClick={(e)=>alerttt()}
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div
                      className="card mb-4 rounded-3 shadow-sm"
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">PRO-ACTIVE members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ 5000<small className="">/-</small>
                        </h1>

                        <Link to="/pro-active_register">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="w-100 btn btn-lg pricebuttoncolor"
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div
                      className="card mb-4 rounded-3 shadow-sm "
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">PASSIVE members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ 500 <small className="">/-</small>
                          {/* text-muted fw-light */}
                        </h1>

                        <Link to="/passive_register">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="w-100 btn btn-lg pricebuttoncolor"
                            // onClick={(e)=>alerttt()}
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="dthdjtxasjdgugafgsjwsjymtmumyg"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div class="modal-header" style={{ backgroundColor: 'black' }}>
                <div className="weigh3rohbiwhbroiwehg">
                  <AiOutlineClose
                    style={{ color: 'white' }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></AiOutlineClose>
                </div>
              </div>
              <div className="modal-body" style={{ backgroundColor: 'black' }}>
                <div>
                  <p style={{ color: 'white' }}>
                    <span style={{ color: '#c85366' }}>ACTIVE</span> members :
                    Members with professional / business profiles as Consultants
                    / Vendors.
                    <br />
                    Professional Consultants, Entrepreneurs, Artists, Artisans,
                    Art Dealers, Trainers, Technically Skilled Service
                    Providers, Commercial Service Providers, Product Vendors,
                    Business Enterprises.
                    <br />
                    An ACTIVE profile has lesser User Features when compared to
                    a PRO-ACTIVE profile. (Please refer USER FEATURES on nav
                    bar).
                    <br />
                    Active members can create only a single profile in any one
                    sub-category of a category either as a Consultant or as a
                    Vendor.
                    <br />
                    Active members cannot post anything related to their
                    professional services, job applications, job offers. They
                    can only view and rate the posts of Pro-Active members.
                    However they can showcase their works, products & catalogues
                    in the Portfolio / Catalogue sections.
                    <br />
                    An ACTIVE profile can be upgraded to a PRO-ACTIVE profile at
                    any time within the current year of subscription by paying
                    the difference amount in subscription fees on pro-rata
                    basis.
                    <br />
                  </p>
                  <br />
                  <h5 style={{ color: '#c85366' }}>PASSIVELY ACTIVE</h5>
                  <br />
                  <p style={{ color: 'white' }}>
                    Active members who are on a long temporary break from work /
                    business can keep their profiles inactive for a while
                    WITHOUT DEACTIVATING the account.
                  </p>
                  <br />
                  <p style={{ color: 'white' }}>
                    The profile can be hidden and continue to use the platform
                    with the privileges of a Passive member.
                  </p>
                  <br />

                  <br />
                  <br />

                  <center>
                    <div
                      className="card mb-4 rounded-3 shadow-sm "
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">Active members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ 500 <small className="">/-</small>
                          {/* text-muted fw-light */}
                        </h1>

                        <Link to="/active_register">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="w-100 btn btn-lg pricebuttoncolor"
                            // onClick={(e)=>alerttt()}
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div
                      className="card mb-4 rounded-3 shadow-sm"
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">PRO-ACTIVE members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ 5000<small className="">/-</small>
                        </h1>

                        <Link to="/pro-active_register">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="w-100 btn btn-lg pricebuttoncolor"
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div
                      className="card mb-4 rounded-3 shadow-sm "
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">PASSIVE members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ 500 <small className="">/-</small>
                          {/* text-muted fw-light */}
                        </h1>

                        <Link to="/passive_register">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="w-100 btn btn-lg pricebuttoncolor"
                            // onClick={(e)=>alerttt()}
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="shygciasiucgiklskjgcklbkjas"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div class="modal-header" style={{ backgroundColor: 'black' }}>
                <div className="weigh3rohbiwhbroiwehg">
                  <AiOutlineClose
                    style={{ color: 'white' }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></AiOutlineClose>
                </div>
              </div>
              <div className="modal-body" style={{ backgroundColor: 'black' }}>
                <div>
                  <p style={{ color: 'white' }}>
                    <span style={{ color: '#c85366' }}>PASSIVE</span> members :
                    Members without professional / business profiles who are
                    Clients / Customers. <br />
                    Anyone above 12 years of age.
                  </p>
                  <br />

                  <p style={{ color: 'white' }}>
                    Passive members have access to the profiles, portfolios and
                    catalogues of all Pro-Active and Active members. Passive
                    members can connect and interact with Pro-Active and Active
                    members for their services / products.
                  </p>
                  <br />
                  <p style={{ color: 'white' }}>
                    Passive members cannot put a post of their own. They can
                    only view and rate the posts of Pro-Active members.
                  </p>
                  <br />
                  <p style={{ color: 'white' }}>
                    Passive members can post job applications.
                  </p>
                  <br />

                  <br />
                  <br />

                  <center>
                    <div
                      className="card mb-4 rounded-3 shadow-sm "
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">Active members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ 500 <small className="">/-</small>
                          {/* text-muted fw-light */}
                        </h1>

                        <Link to="/active_register">
                          <button
                            type="button"
                            className="w-100 btn btn-lg pricebuttoncolor"
                            data-bs-dismiss="modal"
                            // onClick={(e)=>alerttt()}
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div
                      className="card mb-4 rounded-3 shadow-sm"
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">PRO-ACTIVE members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ 5000<small className="">/-</small>
                        </h1>

                        <Link to="/pro-active_register">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="w-100 btn btn-lg pricebuttoncolor"
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div
                      className="card mb-4 rounded-3 shadow-sm "
                      style={{ maxWidth: '350px' }}
                    >
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">PASSIVE members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ 500 <small className="">/-</small>
                          {/* text-muted fw-light */}
                        </h1>

                        <Link to="/passive_register">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="w-100 btn btn-lg pricebuttoncolor"
                            // onClick={(e)=>alerttt()}
                          >
                            Get started
                          </button>
                        </Link>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="display-6 text-center mb-4  d-none">USER features</h2>

        <div className="table-responsive  d-none">
          <table className="table text-center">
            <thead>
              <tr>
                <th></th>
                <th>Active</th>
                <th>Passive</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  REAL-TIME POSTS
                </th>
                <td>can post, view, review, comment, share</td>
                <td>can view, review, comment, share but cannot post</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  VIRTUAL SECRETARY
                </th>
                <td colspan="2">can schedule tasks and reminders</td>
              </tr>

              <tr>
                <th scope="row" className="text-start">
                  CONSULTANT PROFILES
                </th>
                <td>
                  can create consultant / firm profiles showcasing work
                  portfolio
                </td>
                <td>
                  can view consultants' works and initiate one-on-one
                  conversations with the consultant
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  VENDOR PROFILES
                </th>
                <td>
                  can create vendor profiles showcasing product catalogues
                </td>
                <td>
                  can view products, availability and initiate one-on-one
                  conversations with the vendor
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  CUBICLES
                </th>
                <td colspan="2">
                  can create a conference room, add members, initiate
                  discussions on multiple topics within the same single chat /
                  conference room
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  OPPORTUNITIES
                </th>
                <td>can post job offers / vacancies</td>
                <td>can post job applications</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  ACHIEVERS
                </th>
                <td colspan="2">accessible to view</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  SUGGESTIONS
                </th>
                <td colspan="2">can send feedback</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        id="pc"
        className="pricingAllUni container-fluid p-0 pt-5 jrgfrjflobjgt"
      >
        <div className="">
          <div className="container-fluid pt-5">
            <div className="row">
              <div className="col-12">
                <h4
                  className=" fw-bold m-1 text-center pricelasttextW"
                  style={{ color: '#c85366' }}
                >
                  For Service Providers and Product Retailers
                </h4>
                <p className="text-center mb-1">
                  <b style={{ fontSize: '14px' }}>
                    Select your Membership Type
                  </b>
                </p>
                <p className="text-center">
                  (Choose between BASIC, PRIME and ELITE)
                </p>
              </div>

              <div className="col-6"></div>
              <div className="col-6  text-center"></div>
              <div className="col-6 text-center ">
                <b>USER FEATURES</b>
              </div>
              <div className="col-6">
                <div className="row  p-0">
                  <div className="col-12"></div>
                  <div className="col-4 px-1 text-center">
                    <p
                      className="text-primary m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#dthdjtxasjdewfwfgugafeoifhoegsjwsjymtmumyg"
                    >
                      <b>BASIC</b>&nbsp;
                      <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                    </p>
                  </div>

                  <div className="col-4 px-1 text-center">
                    <p
                      className="text-primary m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#dthdjtxfgefwweugfoefsjwsjymtmumyg"
                    >
                      <b>PRIME</b>&nbsp;
                      <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                    </p>
                  </div>
                  <div className="col-4 px-1 text-center">
                    <p
                      className="text-primary m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#dthdjtxasjdewfwfgugafgsjwsjymtmumyg"
                    >
                      <b>ELITE</b>&nbsp;
                      <IoIosHelpCircle style={{ marginTop: '-3px' }} />
                    </p>
                  </div>
                  <div className="col-4 px-1 pb-1">
                    <p className="text-center m-0">
                      Rs {Price?.Activev3}/- per&nbsp;year
                      <br />
                      (including 18% GST)
                    </p>
                  </div>
                  <div className="col-4 px-1 pb-1">
                    <p className="text-center m-0">
                      Rs {Price?.Active}/- per&nbsp;year
                      <br />
                      (including 18% GST)
                    </p>
                  </div>
                  <div className="col-4 px-1 pb-1">
                    <p className="text-center m-0">
                      Rs {Price?.Activev2}/- per&nbsp;year
                      <br />
                      (including 18% GST)
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-6"></div>
              <div className="col-6">
                <div className="row">
                  <div className="col-4 p-0 px-1">
                    <Link to="/static_listing_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activBtn px-1 btn btn-lg pricebuttoncolor"

                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>

                  <div className="col-4 p-0 px-1">
                    <Link to="/pro-active_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activBtn px-1 btn btn-lg pricebuttoncolor"
                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                  <div className="col-4 p-0 px-1">
                    <Link to="/active_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activBtn px-1 btn btn-lg pricebuttoncolor"

                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-6 text-center ">
                <span className="fw-bold "></span>
              </div>
            </div>
          </div>
          <div className="pfeatures897 container-fluid">
            {dataTable.map((data) => {
              return (
                <div className="py-1 py-md-2">
                  <div className="row">
                    <div className="col-6 ">
                      <span
                        data-bs-toggle="modal"
                        data-bs-target={`#${data.modalId}`}
                        className={
                          data.head == 'AUTHENTICATION BADGE '
                            ? 'spaceNoWrap'
                            : ' '
                        }
                        style={{ color: '#c85366' }}
                      >
                        {data.head}
                        {data.element && data.element}
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="row  text-center">
                        <div className="col-4">
                          {data.headData ? data.headData.static : ''}
                        </div>
                        <div className="col-4">
                          {data.headData ? data.headData.pro : ''}
                        </div>
                        <div className="col-4">
                          {data.headData ? data.headData.active : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  {data.data.map((pop) => {
                    return (
                      <div className="row ">
                        <div className="col-6 ">
                          <span className=" ">{pop.label}</span>
                        </div>
                        <div className="col-6">
                          <div className="row  text-center">
                            <div
                              className={` ${
                                pop.noChange
                                  ? 'col-4 text-center '
                                  : 'col-4 text-center '
                              }`}
                            >
                              {pop.value.static}
                            </div>

                            <div
                              className={` ${
                                pop.noChange
                                  ? 'col-4 text-center '
                                  : 'col-4 text-center '
                              }`}
                            >
                              {pop.value.pro}
                              {pop?.modalss?.pro?.element ? (
                                <>&nbsp;{pop?.modalss?.pro?.element}</>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className={` ${
                                pop.noChange
                                  ? 'col-4 text-center '
                                  : 'col-4 text-center '
                              }`}
                            >
                              {pop.value.active}
                              {pop?.modalss?.active?.element ? (
                                <>&nbsp;{pop?.modalss?.active?.element}</>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div className="row">
              <div className="col-6"></div>
              <div className="col-6">
                <div className="row">
                  <div className="col-4 p-0 px-1">
                    <Link to="/static_listing_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activBtn px-1 btn btn-lg pricebuttoncolor"

                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                  <div className="col-4 p-0 px-1">
                    <Link to="/active_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activBtn px-1 btn btn-lg pricebuttoncolor"

                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                  <div className="col-4 p-0 px-1">
                    <Link to="/pro-active_register">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="w-100 activBtn px-1 btn btn-lg pricebuttoncolor"
                        // onClick={(e)=>alerttt()}
                      >
                        Subscribe
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <br />

            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////// */}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqsnskdb"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: '15.2px', color: '#ffffff' }}
                >
                  <span style={{ color: '#c85366' }}>KNOW MY CLIENT</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <p>
                    To the mutual benefit of both the Service Providers and
                    their clients, ‘Know My Client’ is a feature where the
                    Service Providers have the option to prepare a set of
                    questions that their prospective clients must answer when
                    the client clicks on the Communicate button on the profile
                    of the service provider. This feature is available only for
                    service providers.
                  </p>
                  <br />
                  <p>
                    Your list of questions will be sent as a direct message to
                    your client’s inbox.
                  </p>
                  <p>
                    These questions can either be deleted or be replaced with
                    new ones at any time.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: '15.2px', color: '#ffffff' }}
                >
                  <span style={{ color: '#c85366' }}>CUBICLES</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <p>
                    CUBICLES are chat rooms for project / product /
                    service-related discussions between members
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqeiugfruoegfsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">VIRTUAL SECRETARY</h4>
                </div>
                <div className="row">
                  <p>
                    Any member can use this feature to Assign personal and
                    professional Tasks and Set Reminders of Birthdays,
                    Anniversaries, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqeiugflwihroiwgorwogruoegfsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">AUTHENTICATION BADGE</h4>
                </div>
                <div className="row">
                  <p>
                    A tag labeled ‘
                    <span style={{ color: '#c85366' }}>PRO-</span> AUTHENTIC !’
                    on the profile card means that PROchure has verified the
                    authenticity of that respective Service Provider / Product
                    Retailer. That means, we have verified the following via
                    periodic checks :
                  </p>
                  <p>
                    <ol>
                      <li>
                        Operating Address and/or Contact Information of the
                        SERVICE PROVIDER / PRODUCT RETAILER.
                      </li>
                      <li>
                        Whether the SERVICE PROVIDER is currently actively
                        providing consultation / services pertinent to the
                        category in which the profile is created.
                      </li>
                      <li>
                        Whether the PRODUCT RETAILER is currently actively
                        running the business and if it is pertinent to the
                        category in which the profile is created.
                      </li>
                      <li>
                        Whether the SERVICE PROVIDERS are genuinely registered
                        with the statutory body (constituted by the Government
                        of India) as claimed by them.
                      </li>
                    </ol>

                    <p>Please note :</p>
                    <ol>
                      <li>
                        Only ELITE and PRIME members are Eligible for this
                        badge.
                      </li>
                      <li>
                        Not all SERVICE PROVIDERS need to be mandatorily
                        registered with any statutory body to offer professional
                        consultation / commercial services.
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="wefgigwfijiqiueugfiuilqeiuegfi"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: '15.2px', color: '#ffffff' }}
                >
                  <span style={{ color: '#c85366' }}>SHOUT-OUT</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <p>
                    To help the clients / customers avoid the hassle of
                    approaching multiple Service Providers, Product Retailers
                    one-by-one, the client, customer can SHOUT-OUT his / her
                    query / requirement to the Service Providers, Product
                    Retailers in a particular sub-category. This query goes to
                    ALL the Service Providers, Product Retailers (of that
                    sub-category) as a Direct Message.
                  </p>
                  <p>
                    You can also send a SHOUT-OUT to only selected Service
                    Providers, Product Retailers after SHORT-LISTING them.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="primeprofilemodalussss"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <h4>TWENTY PROFILES in both SERVICES and PRODUCTS</h4>
                  <p>
                    You can create upto a maximum of 20 (TWENTY) Profiles in
                    total in various sub-categories of various categories in
                    both classifications, that is either as Service Providers
                    and/or as Product Retailers.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="basicprofilemodalussss"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: 'white' }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: '15.2px', color: '#ffffff' }}>
                  <h4>TEN PROFILES either in SERVICES OR in PRODUCTS</h4>
                  <p>
                    You can create upto a maximum of 10 (TEN) Profiles in total
                    in multiple sub-categories of any one category within any
                    one classification (either in Services or in Products).
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
