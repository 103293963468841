import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import axios from "axios";

import { Link } from "react-router-dom";

import { Timelines } from "../../../Components/Timeline/Timeline";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Adminpview(props) {
  useEffect(() => {
    props.Auth();
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();

  let success = searchParams.get("success");

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    axios
      .post("/api/ActiveUser/Adminprofile", {})
      .then((res) => {
        setGetval(res.data[0]);
        console.log(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [getval, setGetval] = useState(false);

  const [forume, setforume] = useState();
  const [first8, setfirst8] = useState(false);
  useEffect(() => {
    console.log(forume);
  }, [forume]);

  return (
    <>
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border whgiowhroighsoihlznvlsnd">
          <div className="row">
            <div className="col-md-4">
              <div className="profilee-img">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={getval.link}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5>{getval.pname ? getval.pname.toUpperCase() : ""}</h5>
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5>{getval.pname ? getval.pname.toUpperCase() : ""}</h5>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.pname
                          ? "interaction. information. inspiration."
                          : ""}

                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.pname
                            ? "interaction. information. inspiration."
                            : ""}

                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="d-none d-md-block">
                    <Link to="/edit_profile_products">
                      <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                        style={{'borderRadius':'25px'}}
                        name="btnAddMore"
                        value="Edit Profile"
                      />
                    </Link>
                    </div>
                    <div className="d-block d-md-none pt-2">
                      <center>
                      <Link to="/edit_profile_products">
                      <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                        style={{'borderRadius':'25px'}}
                        name="btnAddMore"
                        value="Edit Profile"
                      />
                    </Link>
                      </center>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col"></div>
                </div>
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>Contact Information</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {getval.email ? (
                        <div className="row">
                          <div className="col-md-6">
                            <span>Email Id</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ color: "var(--blue)" }}>
                              {getval.email ? getval.email : ""}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {getval.pincode ? (
                        <div className="row">
                          <div className="col-md-6">
                            <span>Pin code</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ color: "var(--blue)" }}>
                              {getval.pincode ? getval.pincode : ""}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>
                        Timeline{" "}
                        {first8
                          ? first8 > 1
                            ? "(" + first8 + " posts)"
                            : "(" + first8 + " post)"
                          : ""}
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Timelines setfirst8={setfirst8} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleChange("pane20")}
                >
                  <AccordionSummary
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>
                        Followers{" "}
                        {getval.followers ? getval.followers.length : ""}
                      </b>
                    </Typography>
                  </AccordionSummary>
                </Accordion>
              </div>
            </center>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8"></div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Adminpview;
