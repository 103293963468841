import React, { useState,useEffect, useRef } from "react";
import './post.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {ThreeDotOption} from "./ThreeDotOption"
import {ThreeDotOption2} from "./ThreeDotOption2"
import {LikeAndComment} from "./LikeAndComment"
import Moment from 'moment';
const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}
export function TimeLinePostView(props) {

    
 
    // const [bool, setbool] = useState(false)
    // const [inputRef, setInputFocus] = useFocus()
    // const [Comment, setComment] = useState()
    // const [Viewcomment, setViewcomment] = useState('Load Comments')
    // const [Follower, setFollower] = useState(false)
    // const [Loader, setLoader] = useState(<div className="text-center "> <div className="spinner-border text-dark"></div></div>)
    // var commentSendbutton = "violet"
    // const [CommentContent, setCommentContent] = useState([])
    // var booler = false


    // console.log(props.dat)
    var readToogle = {
        display: 'none'
    }

    function keyGen() {
        var length = 5
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    function readToggle(e) {
        // console.log(e.target.id)
        var dots = document.getElementById(e.target.id + 'secondary');
        var moreText = document.getElementById(e.target.id + 'primary');
        var btnText = document.getElementById(e.target.id);

        // console.log(dots + moreText + btnText)

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "Read more";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Read less";
            moreText.style.display = "inline";
        }
    }

    const [dasu, setdasu ]= useState('')
 
    useEffect(() => {
   if(props.dat.merge[0].Type==="Consultant"){
    setdasu("CONSULTANT")
   }else if (props.dat.merge[0].Type==="Product"){
    setdasu("VENDOR")
   } else {
    setdasu("")
   }  
    }, [])
    
 
    var date=Moment(props.dat.Posted_On.slice(0, 10)).format('DD-MM-YYYY')

    return (

        // #733380
        <div className="container-fluid p-0">
            <div className=" post mb-2 ">
                <div className="postHeader p-1">
                    <div className="headerinfo tab mx-0 ">

                        <div className=" text-start pThumbcontainer  cell px-0 cot ">

                            {/* post author profile picture starts */}
                            <img alt="" className="thumbnailpost " src={props.dat.profilepic} />
                            {/* post author profile picture ends */}

                        </div>
                        <div id="postinfo" className="postinfo px-0 ms-1 cell">

                            {/* post author name  starts*/}
                            <h6 id="authorName" className=" pt-1 m-0">{props.dat.merge[0].pname}</h6>
                            {/* post author name  ends*/}
                            <p className="m-0 wiuofgwudgiuswgufigwiugvuig">{
                              
                              props.dat.merge2[0]&&props.dat.merge2[0].category?props.dat.merge2[0].category:'' 
                                
                                } {dasu?' | '+dasu:'' }</p>
                            <p className="wiuofgwudgiuswgufigwiugvuig invisible">{date}<i className="fa fa-globe" aria-hidden="true"></i></p>
                        </div>

                        {/* post 3dot option starts*/}
                          {props.view==='my'?<ThreeDotOption2 dat={props.dat}></ThreeDotOption2>:<ThreeDotOption dat={props.dat}></ThreeDotOption>}
                         
                        {/* post 3dot option ends*/}

                    </div>




                    {/* post body starts */}
                    {props.dat.Content != 'undefined' ?
                        props.dat.Content.length < 360 ? <div className="headerContent mt-1">{props.dat.Content}</div> : <div className="headerContent mt-1">{props.dat.Content.slice(0, 360)}<span id={props.dat.id + 'secondary'}>...</span><span id={props.dat.id + 'primary'} style={readToogle}>{props.dat.Content.slice(360 - props.dat.Content.length)}</span>
                            <b className="text-primary" id={props.dat.id} onClick={(e) => readToggle(e)}>Read more</b>


                        </div> : ''}
                    {/* post body ends */}

                    {/* post image / video starts */}
                    <div className="postContent container-fluid m-0">
                        {/* {props.File.map((e)=>{
                           
                            return<p>{e}</p>
                        })} */}
                        <div id={'poda' + props.dat.id} className="carousel slide" data-bs-ride="carousel">

                            <div className="carousel-inner">
                                {props.dat.File.map((element, index) => {
{/* <p>{element}</p> */}
                                    if (index === 0) {
                                        if ('image' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item active afhouweh brokendrtff">
                                                <img alt="" className="carItemmm" src={element.filename} />

                                                {/* <img src="..." className="d-block w-100" alt="..." /> */}
                                            </div>
                                        } else if ('video' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item afhouweh active brokendrtff">
                                                <video className="carItemmm" controls controlsList="nodownload"> <source src={element.filename} />  </video>
                                            </div>
                                        }
                                    } else {
                                        if ('image' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item  afhouweh brokendrtff">
                                                <img alt="" className="carItemmm" src={element.filename} />
                                                {/* <img alt="" className="carItemmm" src=" https://my-test-eg.s3.ap-south-1.amazonaws.com/post/624be1106f4b4e1899a25e56/sabari_v_r_02017-11-09-888.jpg" /> */}
                                               
                                                {/* <img src="..." className="d-block w-100" alt="..." /> */}
                                            </div>
                                        } else if ('video' === element.filetype.slice(0, 5)) {
                                            //    
                                            return <div key={keyGen()} className="carousel-item afhouweh brokendrtff">
                                                <video className="carItemmm" controls controlsList="nodownload"> <source src={element.filename} />  </video>
                                            </div>
                                        }
                                    }
                                })}



                            </div>
                            {props.dat.File.length>1?
                            <>
                            <button className="carousel-control-prev ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target={'#poda' + props.dat.id} data-bs-slide="prev" >
                                <span className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target={'#poda' + props.dat.id} data-bs-slide="next" >
                                <span className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                            </>:<></> }
                        </div>
                    </div>

                    {/* post image / video ends */}

                    {/* post comment like count starts */}
                    {/* <div className="postinfobottom row my-2  px-3">
                        <div className="col-6">   {props.dat.Likecount} Likes <i className="fa fa-star" aria-hidden="true"></i></div>
                        <div className="col-6 text-end"> {props.dat.Commentcount} Comments</div>
                    </div> */}
                    {/* post comment like count ends */}

                    {/* <hr className="m-0 my-1 hrline" /> */}

                    {/* post like,share button starts */}




                    
                    <LikeAndComment dat={props.dat}></LikeAndComment>





                </div>
            </div>
        </div>
    )

}