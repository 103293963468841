import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ContactInformationVendorMedical_Store = ({ getval }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {
    formType,
    Type,
    email,
    countrycode,
    statecode,
    CON,
    contact3,
    contactpersonsname3,
    contactpersonsdesignation3,
    contact4,
    preferredlanguage3,
    contactpersonsname4,
    contactpersonsdesignation4,
    preferredlanguage4,
    contact,
    contactpersonsdesignation,
    contactpersonsname,
    preferredlanguage,
    contact2,
    contactpersonsdesignation2,
    contactpersonsname2,
    preferredlanguage2,
  } = { ...getval };

  return (
    <Accordion
      expanded={expanded === "panel4dfdgf"}
      onChange={handleChange("panel4dfdgf")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
      >
        <Typography sx={{ width: "100%", flexShrink: 0 }}>
          <b>Contact Information</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {email ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Email Id</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>{email}</label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {countrycode ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Country Code</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>{countrycode}</label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {statecode ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>State Code</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>{statecode}</label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {formType === 1 || formType === 2 ? (
            CON ? (
              CON.map((elment, ind) => {
                console.log(elment);

                return (
                  <>
                    {elment.contact2 ? (
                      <>
                        <div className="row py-2">
                          <div className="col-md-6">
                            <span>Mobile Number / Landline Number</span>
                          </div>
                          <div className="col-md-6">
                            <label style={{ color: "var(--blue)" }}>
                              {elment.contact2}
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {elment.contactpersonsname2 ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <span>Contact Person</span>
                          </div>
                          <div className="col-md-6">
                            <label style={{ color: "var(--blue)" }}>
                              {elment.contactpersonsname2}
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {elment.contactpersonsdesignation2 ? (
                      <>
                        <div className="row py-2">
                          <div className="col-md-6">
                            <span>Contact Person’s Designation</span>
                          </div>
                          <div className="col-md-6">
                            <label style={{ color: "var(--blue)" }}>
                              {elment.contactpersonsdesignation2}
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {elment.preferredlanguage2 ? (
                      <>
                        <div className="row py-2">
                          <div className="col-md-6">
                            <span>
                              Most Comfortable / Preferred Language for
                              Communication
                            </span>
                          </div>
                          <div className="col-md-6">
                            <label style={{ color: "var(--blue)" }}>
                              {elment.preferredlanguage2}
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {/* landline */}
          {formType === 3 && Type != "Productv3" && (
            <>
              {contact3 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Landline Number</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>{contact3}</label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {contactpersonsname3 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Contact Person</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>
                      {contactpersonsname3}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {contactpersonsdesignation3 ? (
                <div className="row">
                  <div className="col-md-6">
                    <span>Contact Person’s Designation</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>
                      {contactpersonsdesignation3}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {preferredlanguage3 ? (
                <div className="row">
                  <div className="col-md-6">
                    <span>
                      Most Comfortable / Preferred Language for Communication
                    </span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{ color: "var(--blue)" }}>
                      {preferredlanguage3}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* landline additional*/}

              {contact4 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Landline Number (additional)</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>{contact4}</label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {contactpersonsname4 ? (
                <div className="row">
                  <div className="col-md-6">
                    <span>Contact Person</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>
                      {contactpersonsname4}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {contactpersonsdesignation4 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Contact Person’s Designation</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>
                      {contactpersonsdesignation4}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {preferredlanguage4 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>
                      Most Comfortable / Preferred Language for Communication
                    </span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{ color: "var(--blue)" }}>
                      {preferredlanguage4}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* mobile */}

              {contact ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Mobile Number</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>{contact}</label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {contactpersonsname ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Contact Person</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>
                      {contactpersonsname}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {contactpersonsdesignation ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Contact Person’s Designation</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>
                      {contactpersonsdesignation}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {preferredlanguage ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>
                      Most Comfortable / Preferred Language for Communication
                    </span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{ color: "var(--blue)" }}>
                      {preferredlanguage}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* mobile adiitional */}

              {contact2 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Mobile Number (additional)</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>{contact2}</label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {contactpersonsname2 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Contact Person</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>
                      {contactpersonsname2}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {contactpersonsdesignation2 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Contact Person’s Designation</span>
                  </div>
                  <div className="col-md-6">
                    <label style={{ color: "var(--blue)" }}>
                      {contactpersonsdesignation2}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {preferredlanguage2 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>
                      Most Comfortable / Preferred Language for Communication
                    </span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{ color: "var(--blue)" }}>
                      {preferredlanguage2}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {/*//////////\\\\\\\\\\\*/}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
