import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";


export  function Vendorcom(props) {

 
 

 

    
  return (
    <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-3">
      <div className="text-center card-box p-0">

        <div className="member-card pt-2 pb-2">
         
          <div className="">
            <h4>{props.data.firstname.toUpperCase()}</h4>
            <p className="ufghsuhie rigehioerhog">
              <span className="ufghsuhie">
                {props.data.string[0].category} | {props.data.string2[0].sub}
              </span>
             
              <br />
              <span>
                <p className="ufghsuhie">{props.data.state} | {props.data.city}</p>
                <div className="ps-2 pe-2"><hr /></div>
                <p>{props.data.about?props.data.about:''}</p>
              </span>
              <button
                className="btn pro-spaces-buttonFree2 ps-3 pe-3"
               
              >
                SEND QUERY
              </button>
            </p>
          </div>

        </div>
      </div>
     
    
    </div>
  )
}
