import React, { useState, useEffect } from "react";

import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./ProductMainCategory.css";

// import { Link } from "react-router-dom";
import { Proin } from "../ProductMainCategory/Proin";
import { Mask } from "../../Utility/mask";
import Select from "react-select";
import { BiReset } from "react-icons/bi";

export function ProductMainCategory(props) {
  useEffect(() => {
    props.Auth();
  }, []);

  let navigate = useNavigate();

  const [follow, setFollow] = useState("follow");
  const [data, setdata] = useState(false);
  const [cat, setCat] = useState([]);
  const [Follower, setFollower] = useState();
  const [Foll, setFoll] = useState();
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");

  // const [first, setfirst] = useState()
  // useEffect(() => {
  //   axios
  //     .get('/api/api/getprocategory')
  //     .then(res => {
  //       console.log(res.data)
  //       setfirst(res.data)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }, [])

  useEffect(() => {
    axios
      .get("/api/User/procategory", {})
      .then((res) => {
        setCat(res.data);
        const postSummaries = res.data.map((post) => ({
          id: post._id,
          key: post.id,
          sub: post.sub,
          category: post.category,
          con: post?.con[0]?.totalCount ? post?.con[0]?.totalCount : 0,
          con2: post.con2.length,
        }));
        setdata(postSummaries);
        // setTimeout(()=>{ // using to not get loading error if we are using google transalate
        setload(false);
        // },1000)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(cat);
  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const [Available, setAvailable] = useState([]);

  useEffect(() => {
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {" "}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      var temp = "";

      if (data) {
        setoutput(
          data.map((number) => {
            // if(number.con>0){
            let ind = 1;
            if (temp === number.sub.slice(0, 1)) {
              ind = 1;
            } else {
              setAvailable((prev) => [...prev, number.sub.slice(0, 1)]);
              temp = number.sub.slice(0, 1);
              ind = 0;
            }
            return (
              <Proin
                key={number.id}
                index={ind}
                ids={makeid()}
                data={number}
              ></Proin>
            );
            // }
          })
        );
      } else {
        setoutput(error);
      }
    }
  }, [error, load]);

  var catlength = data.length;

  const [Options, setOptions] = useState([]);
  useEffect(() => {
    axios
      .get("/api/api/getprocategory")
      .then((res) => {
        let op = res.data?.map((element, index) => {
          return element.sub.map((elm, ind) => {
            let value = elm.sub.toString();
            if (elm.keyword?.length) {
              var sabari = elm.keyword.map((keywordsmap) => keywordsmap.label);
              value = value + "" + sabari.toString();
            }
            let brand;
            if (elm.brand?.length) {
              brand = true;
            } else {
              brand = false;
            }
            return {
              value,
              label: `${elm.sub} [ ${element.category} ]`,
              id: elm._id,
              categoryName: element.category,
              brand,
              type: element.type,
            };
          });
        });
        op = op.flat(1);
        setOptions(op);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const redmik20pro = (id) => {
    let catid = id.id;
    let subcategoryName = id.categoryName;
    if (id.type == true) {
      if (id.brand == true) {
        navigate(
          `/services_brand_categories?brand_id=${Mask.encode(
            catid
          )}&&sub_category=${subcategoryName}`
        );
      } else {
        navigate(`/services_result?Sub_id=${Mask.encode(catid)}`);
      }
    } else if (id.type == false) {
      if (id.brand == true) {
        navigate(
          `/products_brand_categories?brand_id=${Mask.encode(
            catid
          )}&&sub_category=${subcategoryName}`
        );
      } else {
        navigate(`/products_result?Sub_id=${Mask.encode(catid)}`);
      }
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selectedOption) => {
    redmik20pro(selectedOption);
  };

  const handle = (e) => {
    if (e.target.value.length > 2) {
      let elem = document.querySelector(":root");
      elem.style.setProperty("--display", "block  ");
    } else {
      let elem = document.querySelector(":root");
      elem.style.setProperty("--display", "none  ");
    }
  };

  useEffect(() => {
    try {
      rt();

      let element = document.getElementsByClassName("catSubCatSearchBox");
      element[0].children[2].children[0].children[1].children[0].addEventListener(
        "input",
        handle
      );
      element[0].children[2].children[1].classList.add("d-none");
    } catch (error) {
      console.log(error);
    }
    return () => {
      try {
        let element = document.getElementsByClassName("catSubCatSearchBox");
        element[0].children[2].children[0].children[1].children[0].removeEventListener(
          "input",
          handle
        );
      } catch (err) {}
    };
  }, []);

  const rt = () => {
    let elem = document.querySelector(":root");
    elem.style.setProperty("--display", "none ");
  };

  let alpha = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  return (
    <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
      {/* <div className="row ">
        <div className="col d-flex justify-content-end">
          // 
          <button className="btn pro-spaces-button" data-bs-toggle="modal" data-bs-target="#exampleModefveelkfnvalsabari2wekjfg"> KEY ATTRIBUTES</button>
          
        </div>
      </div>
      <hr /> */}
      <div className="mb-2">
        <div>
          <div
            style={{
              display: "inline-block",
              width: "calc( 100% - 70px )",
              "margin-right": "10px",
            }}
          >
            <Select
              value={selectedOption}
              className="catSubCatSearchBox"
              placeholder="What are you searching for ?"
              onChange={(e) => handleChange(e)}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#ced4da" : "#ced4da",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#ced4da",

                  primary: "#ced4da",
                },
              })}
              options={Options}
            />
          </div>
          <button
            className="btn nfsjfbgevoug pro-spaces-button ps-3 pe-3 pt-1 pb-1  "
            type="button"
            style={{ borderRadius: "10px" }}
          >
            <BiReset size={24} />
          </button>
        </div>
      </div>
      {/* <div>
        <input
          onInput={e => redmik20pro(e)}
          id="sareraliyasjacky"
          type='text'
          placeholder='What are you searching for ?'
          className='w-100 searchforsubcategory'
          list='cars'
        ></input>
        {twys.length > 1 ? (
          <datalist id='cars'>
            {first?.map((element, index) => {
              return element.sub.map((elm, ind) => {
                return (
                  <option id={elm.catid}>
                    {elm.sub} [ {element.category} ]
                  </option>
                )
              })
            })}
          </datalist>
        ) : (
          ''
        )}
      </div> */}
      <div className={`row ${load === false ? "" : "notranslate"}`}>
        <center>
          <h5 className="parapara">{catlength} CATEGORIES ( PRODUCTS )</h5>
        </center>
        <center>
          <div className="p-2">
            <div className="AlphaNav border grid-containerX p-1 m-0">
              {alpha.map((elem, index) => {
                return (
                  <a
                    style={{ textTransform: "capitalize" }}
                    href={`#Cat-` + elem.toUpperCase() + "0"}
                  >
                    {elem}
                  </a>
                );
              })}
            </div>
          </div>
        </center>
        {output}
      </div>
    </div>
  );
}
