import React, { useState } from 'react'
import { Container, Row, Col, Card, Button, Modal, Carousel, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Moment from 'moment';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import { BsThreeDotsVertical } from 'react-icons/bs';

const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
export const Information_card3 = ({ data, Edit, Get }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var account_type = localStorage.getItem("Account_type")
  var account_id = localStorage.getItem("AccountID")
  var id = data._id
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const Delete = () => {
    Axios.post('/api/User/Information_delete', { id }).then((res) => {
      if (res.status == 200) {
        notify("Deleted successfully", true)
        Get()
        handleClose1()
      }
    }).catch((err) => {
      notify("Something went wrong", false)

    })

  }


  return (
    <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 "><>
      <div className=" card-box p-0" style={{ overflow: 'hidden' }} >
        {
          Edit ?
            account_type === "SUPERADMIN" || account_id === data?.Created_by ?
              <div className='d-flex justify-content-end'>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" style={{ background: 'none', border: 'none' }}>
                    <BsThreeDotsVertical color='black' size={15} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item >
                      <Link className='react-bootstrap-dropdown-item' to={`/lobby/information_forms_edit?k=${data._id}`} style={{ "textDecoration": "none", color: "black" }} >
                        Edit
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(e) => handleShow1()}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              :
              <></>
            : <></>
        }

        <div className="member-card pb-1">
          {
            data?.newImage?.length > 0 ?
              <div className="">
                <Carousel controls={data?.newImage?.length > 1} indicators={false}>
                  {data?.newImage?.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100" src={image.link} alt={`Image ${index + 1}`} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              : ""
          }
          <div className="p-3">
            <h4 className='text-center' >{data.title ? data.title.toUpperCase() : ''}</h4>

            {
              data.genre.length > 0 ?
                <span>
                  <p className='m-0 '>Genre :</p>
                  <p className='ms-2' >
                    {
                      data.genre.map((e) => {
                        return <>    <Stack direction="row" spacing={1}>
                          <Chip label={e} variant="outlined" />
                        </Stack> </>
                      })
                    }
                  </p>
                </span>
                : ""
            }

            {
              data.status ?
                <span>
                  <p className='m-0 '>Status :</p>
                  <p className='ms-2' >
                    {data.status}
                  </p>
                </span>
                : ""
            }

            {
              data.actors_actresses ?
                <span>
                  <p className='m-0 '>Actors / Actresses :</p>
                  <p className='ms-2' >
                    {data.actors_actresses}
                  </p>
                </span>
                : ""
            }
            {
              data.director ?
                <span>
                  <p className='m-0 '>Director :</p>
                  <p className='ms-2' >
                    {data.director}
                  </p>
                </span>
                : ""
            }

          </div>
          <div className="row mt-2">

            <div className="d-flex justify-content-center align-items-center">

              <button
                type="button"
                className="btn pro-spaces-button3 btn-rounded ps-3 pe-3"
                onClick={(e) => handleShow()}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className=" card-box p-0" style={{ overflow: 'hidden' }} >
              <div className="member-card pb-1">
                {
                  data?.newImage?.length > 0 ?
                    <div className="">
                      <Carousel controls={data?.newImage?.length > 1} indicators={false}>
                        {data?.newImage?.map((image, index) => (
                          <Carousel.Item key={index}>
                            <img className="d-block w-100" src={image.link} alt={`Image ${index + 1}`} />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                    : ""
                }

                <div className="p-3">
                  <h4 className='text-center' >{data.title ? data.title.toUpperCase() : ''}</h4>

                  {
                    data.genre.length > 0 ?
                      <span>
                        <p className='m-0 '>Genre :</p>
                        <p className='ms-2' >
                          {
                            data.genre.map((e) => {
                              return <>    <Stack direction="row" spacing={1}>
                                <Chip label={e} variant="outlined" />
                              </Stack> </>
                            })
                          }
                        </p>
                      </span>
                      : ""
                  }

                  {
                    data.status ?
                      <span>
                        <p className='m-0 '>Status :</p>
                        <p className='ms-2' >
                          {data.status}
                        </p>
                      </span>
                      : ""
                  }

                  {
                    data.synopsis ?
                      <span>
                        <p className='m-0 '>Synopsis :</p>
                        <p className='ms-2' >
                          {data.synopsis}
                        </p>
                      </span>
                      : ""
                  }

                  {
                    data.actors_actresses ?
                      <span>
                        <p className='m-0 '>Actors / Actresses :</p>
                        <p className='ms-2' >
                          {data.actors_actresses}
                        </p>
                      </span>
                      : ""
                  }
                  {
                    data.director ?
                      <span>
                        <p className='m-0 '>Director :</p>
                        <p className='ms-2' >
                          {data.director}
                        </p>
                      </span>
                      : ""
                  }
                  {
                    data.number_of_sessions ?
                      <span>
                        <p className='m-0 '>Number of sessions :</p>
                        <p className='ms-2' >
                          {data.number_of_sessions}
                        </p>
                      </span>
                      : ""
                  }
                  {
                    data.total_number_of_episodes ?
                      <span>
                        <p className='m-0 '>Total number of episodes :</p>
                        <p className='ms-2' >
                          {data.total_number_of_episodes}
                        </p>
                      </span>
                      : ""
                  }
                  {
                    data.theater_release_date ?
                      <span>
                        <p className='m-0 '>Theater release date :</p>
                        <p className='ms-2' >
                          {Moment(data.total_number_of_episodes).format('DD-MM-YYYY')}
                        </p>
                      </span>
                      : ""
                  }
                  {
                    data.ott_release_date ?
                      <span>
                        <p className='m-0 '>OTT release date :</p>
                        <p className='ms-2' >
                          {Moment(data.ott_release_date).format('DD-MM-YYYY')}
                        </p>
                      </span>
                      : ""
                  }

                  {
                    data.ott_platform.length > 0 ?
                      <span>
                        <p className='m-0 '>OTT platform :</p>
                        <p className='ms-2' >
                          {
                            data.ott_platform.map((e) => {
                              return <>    <Stack direction="row" spacing={1}>
                                <Chip label={e} variant="outlined" />
                              </Stack> </>
                            })
                          }
                        </p>
                      </span>
                      : ""
                  }

                  {
                    data.tv_channel ?
                      <span>
                        <p className='m-0 '>TV channel :</p>
                        <p className='ms-2' >
                          {data.tv_channel}
                        </p>
                      </span>
                      : ""
                  }

                </div>

              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={(e) => Delete()}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
