import { useEffect, useState, useRef } from "react"

import { AiFillExclamationCircle } from 'react-icons/ai'
import { AiOutlineCloseCircle } from 'react-icons/ai'

export const Partners = (props) => {
    console.log(props)
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [Data, setData] = useState({
        name: props.Partner ? props.Partner[props.index] ? props.Partner[props.index].name ? props.Partner[props.index].name : '' : '' : '',
        designation: props.Partner ? props.Partner[props.index] ? props.Partner[props.index].designation ? props.Partner[props.index].designation : '' : '' : '',
    })
    useEffect(() => {
        props.handlePartner(Data, props.index)
        console.log(Data)
    }, [Data])
    return (
        <>
            {props.index != 0 ?
                // <div className="w-100">
                <AiOutlineCloseCircle size={25} className='float-end pe-1 pt-2' onClick={(e) => props.handlePartner('remove', props.index)} style={{ "cursor": 'pointer' }} ></AiOutlineCloseCircle>
                // </div>
                : <></>}
            <div className="col-md-12 ">
                <label className="labels w-100 mb-2">Name of Partner / Business Associate</label>
                <input className="form-control" value={Data.name} onChange={(e) => setData((prev) => ({
                    ...prev, name: e.target.value
                }))} type={'text'} />
            </div>
            <div className="col-md-12">
                <label className="labels">Designation</label>
                <input type='text' onChange={(e) => setData((prev) => ({
                    ...prev, designation: e.target.value
                }))} className="form-control" value={Data.designation} />
            </div>
        </>
    )
}
