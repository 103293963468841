import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { notify } from "../../Utility/notify";
import { useNavigate } from "react-router-dom";

export const Deleteprofile = ({ row }) => {
  let navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const [Password, setPassword] = useState('')

  const [value, setvalue] = useState({
    Reason: "",
  });
  const handleClose = () => {
    setShow(false);
    setError("")
  }
  const handleShow = () => setShow(true);
  const handleClose2 = () => {
    setShow2(false);
    setError("")
  }
  const handleShow2 = () => setShow2(true);
  const [Error, setError] = useState('')


  const Delete = () => {
    axios.post("/api/AuthenticatedUser/DeleteProfile", { dataSend: { Password, profile: row._id }, value }).then((res) => {
      console.log(res);
      handleClose2()
      setError("")
      notify("Account Deleted", true)
      navigate('/')

    }).catch((err) => {
      console.log(err.response.data);
      setError(err.response.data)
    })
  }

  useEffect(() => {
    if (value.Reason.length != 0) setError("")
  }, [value.Reason])


  return (
    <>
      <button
        className="btn pro-spaces-button p-0 d-flex  align-items-center justify-content-center"
        style={{ width: "130px" }}
        onClick={handleShow}
      >
        DELETE
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>
              Reason
            </label>
          </div>
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => {
              setvalue({ ...value, Reason: e.target.value });
            }}
          >
            <option></option>
            <option
              value="Service / Business Discontinued
"
            >
              {" "}
              Service / Business Discontinued
            </option>
            <option value="Taking a Break from Work / Business">
              {" "}
              Taking a Break from Work / Business
            </option>
          </select>

          <div
            className="pt-3 fw-bolder"
            hidden={value.Reason !== "Taking a Break from Work / Business"}
          >
            <p>
              Why do you want to delete your Profile when you can simply Hide it !
            </p>
            <p>
              If on a Long Break or Sabbatical for an indefinite period :
            </p>
            <p>
              If you are on a long break from work / business, you can keep your profile (s) Inactive for a while WITHOUT DEACTIVATING or DELETING the account. Your profile card will be hidden to other members until the time you undo this action. This feature is seen on ‘Profile Details and Account Settings’ on your Profile tab.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row g-2">
            <div className="col-12">
              <Button variant="success" style={{ width: "100%" }} onClick={handleClose} >
                GO BACK TO ACCOUNT
              </Button>
            </div>

            <div className="col-12">
              <Button variant="danger" style={{ width: "100%" }} onClick={(e) => {
                if (value.Reason.length) {
                  setError("")
                  handleShow2(); handleClose();

                } else {
                  setError("Select a reason")
                }
              }}>
                PERMANENTLY DELETE
              </Button>
              <p className="text-danger mt-1">{Error}</p>
            </div>
          </div>
        </Modal.Footer>
      </Modal>




      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete your profile {row.pname} ?</p>
          <input onChange={(e) => { setPassword(e.target.value) }} placeholder="Enter your password here" className='form-control' type='password' />
          <p className="text-danger">{Error}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn pro-spaces-button3' onClick={handleClose2}>
            Close
          </button>
          {Error === 'This is your only Profile. So you have to delete your Account.' ?
            <></> :
            <button className='btn btn-danger ' onClick={Delete}>
              delete
            </button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};
