import React, { useState, useEffect } from 'react'

const AddQ = ({ index, questions, deleteMe, saveMe }) => {
    const [question, setquestion] = useState(questions?questions[index]:'')
    console.log(questions);
    useEffect(() => {
      
        saveMe(question, index)
        return () => {
        }
    }, [question])

    return ( 
        <div className='py-2'>
            <span><b>Question #{index+1}</b></span>
            <button type="button" className="btn-close float-end" 
            onClick={(e) => deleteMe(index)} aria-label="Close"></button>
                <textarea id={index + '-Qust'} type='text'
                    style={{ fontWeight: '600' }}
                    onChange={(e) => setquestion(e.target.value)}
                    className='w-100 form-control'
                    placeholder='Enter your question here'
                    >{question}</textarea>
        </div>
    )
}

export default AddQ