import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";


export default function EmergencyServices() {
    // useEffect(() => {
    //     props.Auth();
    //   }, []);
    
    // const FollowOrNot = (followme) => {
    //     try {
    //       axios
    //         .post("/api/User/subcatfollowornot", { followme })
    //         .then((response) => {
    //           console.log("success");
    //           if (response.status === 200) {
    //             console.log("first");
    //             setFollower(false);
    //           }
    //         })
    //         .catch((res) => {
    //           console.log("Something went wrong");
    //         });
    //     } catch (er) { }
    //   };
    
    //   const follow = (e, id) => {
    //     console.log(id)
    
    //     var followme = id;
    //     axios
    //       .post("/api/User/subfollow", { followme })
    //       .then((response) => {
    //         console.log("success");
    //         console.log(response)
    //         if (response.status === 200) {
    //           setFollower(false);
    //         }
    //       })
    //       .catch((res) => {
    //         console.log("Something went wrong");
    //       });
    //   };
    
    //   const unfollow = (e, id) => {
    //     console.log(id)
    //     var followme = id;
    //     axios
    //       .post("/api/User/subcatunfollow", { followme })
    //       .then((response) => {
    //         console.log("unfollow");
    //         if (response.status === 200) {
    //           setFollower(true);
    //         }
    //       })
    //       .catch((res) => {
    //         console.log("Something went wrong");
    //       });
    //   };
      
      return (
        <div className='container'>
       <div className=' row text-center pt-3 ps-1 pe-1' style={{'marginTop':'90px'}}>

       <div className="col-12 col-md-6  col-lg-6 col-xl-4 col-xxl-4 mb-3 ">
        <div className="gtyurie p-3 ">
          
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4 center"></div>
            <div className="col-4">
            </div>
          </div>
          <Link to={"/ambulance_services_animals"} className="twedfqtfudc">
          <div className="d-flex align-items-center">
            <div className="ml-3 w-100">
              <h4 className="mb-2 mt-0">Ambulance Services Animals<spam className="invisible">hai</spam></h4>{" "}
              {/* <ul>
                <li>Consultants : {props.data.cons+props.data.conss} </li>
              </ul> */}
            </div>
          </div>
          </Link>
        </div>
      </div>
      <div className="col-12 col-md-6  col-lg-6 col-xl-4 col-xxl-4 mb-3 ">
        <div className="gtyurie p-3 ">
        
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4 center"></div>
          <div className="col-4">
          </div>
        </div>
        <Link to={"/ambulance_services_humans"} className="twedfqtfudc">
        <div className="d-flex align-items-center">
          <div className="ml-3 w-100">
            <h4 className="mb-2 mt-0">Ambulance Services Humans <spam className="invisible">hai</spam></h4>{" "}
            {/* <ul>
              <li>Consultants : {props.data.cons+props.data.conss} </li>
            </ul> */}
          </div>
        </div>
        </Link>
      </div>
    </div>


    <div className="col-12 col-md-6  col-lg-6 col-xl-4 col-xxl-4 mb-3 ">
        <div className="gtyurie p-3 ">
        
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4 center"></div>
          <div className="col-4">
          </div>
        </div>
        <Link to={"/blood_donors"} className="twedfqtfudc">
        <div className="d-flex align-items-center">
          <div className="ml-3 w-100">
            <h4 className="mb-2 mt-0">Blood Donors </h4>{" "}
            {/* <ul>
              <li>Consultants : {props.data.cons+props.data.conss} </li>
            </ul> */}
          </div>
        </div>
        </Link>
      </div>
    </div>

    <div className="col-12 col-md-6  col-lg-6 col-xl-4 col-xxl-4 mb-3 ">
        <div className="gtyurie p-3 ">
        
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4 center"></div>
          <div className="col-4">
          </div>
        </div>
        <Link to={"/deceased_animal_burial_grounds"} className="twedfqtfudc">
        <div className="d-flex align-items-center">
          <div className="ml-3 w-100">
            <h4 className="mb-2 mt-0">Deceased Animal Burial Grounds</h4>{" "}
            {/* <ul>
              <li>Consultants : {props.data.cons+props.data.conss} </li>
            </ul> */}
          </div>
        </div>
        </Link>
      </div>
    </div>

       </div>
     
        </div>
     
      )
    }
    