import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Pview.css";
import axios from "axios";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Edit";
// import { TimeLineExternal } from "../../../Components/Timeline/TimeLineExternal"
import { useNavigate, useSearchParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetportExternal1 } from "./GetportExternal1";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosSend } from "react-icons/io";
import { GetOfferExternal } from "./GetOfferExternal";
import { MdMyLocation } from "react-icons/md";

import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import { GetNewLaunchExternal } from "./GetNewLaunchExternal";
import { Mask } from "../../../Utility/mask";
import { BussinessInformationVendor } from "../../../Components/ProfileComponents/BussinessInformationVendor";
import { PersonalInformationVendor } from "../../../Components/ProfileComponents/PersonalInformationVendor";
import { ContactInformationVendor } from "../../../Components/ProfileComponents/ContactInformationVendor";
import { reportTags } from "../../../Utility/languages";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Selfdeclaration } from "../../../Components/Selfdeclaration/Selfdeclaration";
import { ShareThis } from "../../ShareThis/ShareThis";
import { PersonalInformationStatic } from "../../../Components/ProfileComponents/PersonalInformationStatic";
import { bucketName } from "../../../config";

function Pview(props) {
  useEffect(() => {
    props.Auth();
  }, []);

  const [expanded, setExpanded] = useState(false);
  const [loading, setloading] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const [Follower, setFollower] = useState(false);
  let Navigate = useNavigate();
  var Ac = Mask.decode(searchParams.get("Ac_id"));
  var idd = Mask.decode(searchParams.get("Ac_id"));
  var Acc = Mask.decode(searchParams.get("Ac_id"));

  const astyle = {
    color: "#0a58ca",
    textDecoration: "underline",
  };

  const enablechat = () => {
    document
      .getElementById("closebuttonforreportkgwfiuwsgfbvsdjbfuisea")
      .click();
    document.getElementById("quicksettingmodalatag").click();
  };
  const [isLogo, setIsLogo] = useState(false);
  const [logo, setLogo] = useState(false);
  const [EmailRef, setEmailRef] = useState({
    email: [],
    subject: "",
    massage: "",

    id: Acc,
  });
  const makeEmail = (x, Lorum) => {
    console.log(Lorum);
    console.log(x);
    x = document.getElementById(x);
    x.style.color = "black";
    var val = x.value;
    if (val.charAt(val.length - 1) === " ") {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
    if (Lorum) {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
  };

  const notify = (message, stat) => {
    // console.log(message);
    // console.log(stat)

    if (stat == true) {
      // console.log("toasfted");
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == false) {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Email, setEmail] = useState([]);
  const [Arr, setArr] = useState([]);
  var listItems = "";
  const [AllEmail, setAllEmail] = useState([]);

  const addEmail = (vall) => {
    vall = vall.trim();
    var dep = AllEmail;
    dep.push(vall);
    setAllEmail(dep);
    var x = React.createElement(
      "div",
      {
        className:
          "p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1",
        role: "alert",
      },
      [
        React.createElement(
          "strong",
          {},
          vall,
          <>&nbsp;&nbsp;&nbsp;</>,
          React.createElement(
            "button",
            {
              onClick: (e) => removeEmail(vall),
              type: "button",
              className: "btn-close ms-5 Gbt p-0 pt-2 ",
              "data-bs-dismiss": "alert",
              "aria-label": "Close",
            },
            ""
          )
        ),
      ]
    );
    Arr.push(x);
    listItems = Arr.map((myList) => {
      return <>{myList}</>;
    });
    setEmail(listItems);
  };

  useEffect(() => {
    setEmailRef({ ...EmailRef, email: AllEmail });
  }, [AllEmail]);

  const removeEmail = (e) => {
    var index = AllEmail.indexOf(e);
    AllEmail.splice(index, 1);
    setEmailRef({ ...EmailRef, email: AllEmail });
  };

  const [submittrue, setsubmittrue] = useState(false);
  const SendRef = (e) => {
    setsubmittrue((prev) => !prev);
    console.log("haii");
    console.log(EmailRef);
    var status = false;
    status = validation();
    if (status) {
      setloading(true);
      axios
        .post("/api/User/ReferVendor", {
          EmailRef,
        })
        .then((res) => {
          console.log(res.data);
          //  alert("Refereed successfully !!!!!")
          if (res.status === 200) {
            document.getElementById("closebuttonforreport123").click();
            notify("Referred successfully", true);
            setloading(false);
            setEmailRef({
              email: [],
              subject: "",
              massage: "",
              sub: getval.string2[0].sub ? getval.string2[0].sub : "",
              id: Acc,
            });
            setAllEmail([]);
            setEmail([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
          document.getElementById("closebuttonforreport123").click();
          notify("Something went wrong !", false);
        });
    }
  };

  const [rateingfor, setrateingfor] = useState([]);
  useEffect(() => {
    axios
      .post("/api/User/getportfolio", {
        Acc,
      })
      .then((res) => {
        setrateingfor(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addreating = (e, status) => {
    // console.log(id)
    if (e.target.checked) {
      var status = status;
      axios
        .post("/api/User/rateingforcon", {
          status,
          idd,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .delete("/api/User/rateingdelcon", {
          data: {
            status,
            idd,
          },
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const communicate = (e) => {
    let id = Ac;
    let dataSend = {
      id,
    };
    console.log(id);
    axios
      .post("/api/User/communicate", { dataSend })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          Navigate("/cubicles?consultantTrue=" + res.data);
          console.log(res);
        } else {
          Navigate("/cubicles?consultantTrue=" + res.data);
        }
      })
      .catch((res) => {
        console.log(res.response);
        if (res.response.status === 400) {
          // Navigate('/cubicles')
        } else if (res.response.status === 401) {
          // TOAST SOMETHING WRONG SAME PERSON
        }
      });
  };

  const [shortlistcheck, setshortlistcheck] = useState(false);

  useEffect(() => {
    let id = Ac;
    axios
      .post("/api/User/selselectpro", {
        id,
      })
      .then((res) => {
        try {
          if (res.status === 200) {
            document.getElementById(Ac).checked = true;
            document.getElementById(Ac + "2").checked = true;
            // setFollower(true);
            setmappas(true);
            console.log(res.status);
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        try {
          console.log(err);
          setmappas(false);
          document.getElementById(Ac).checked = false;
          document.getElementById(Ac + "2").checked = false;
        } catch (error) {
          console.log(error);
        }
      });
  }, [shortlistcheck]);

  const checkaeijfpi = () => {
    var id = Ac;
    try {
      if (document.getElementById(id).checked) {
        axios
          .post("/api/User/selectpro", {
            id,
          })
          .then((res) => {
            setmappas(true);
            setshortlistcheck((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post("/api/User/disselectpro", {
            id,
          })
          .then((res) => {
            setmappas(false);
            setshortlistcheck((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkaeijfpi2 = () => {
    var id = Ac;
    try {
      if (document.getElementById(id + "2").checked) {
        axios
          .post("/api/User/selectpro", {
            id,
          })
          .then((res) => {
            setmappas(true);
            setshortlistcheck((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post("/api/User/disselectpro", {
            id,
          })
          .then((res) => {
            setmappas(false);
            setshortlistcheck((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [mappas, setmappas] = useState();

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const [first, setfirst] = useState(false);
  useEffect(() => {
    var accid = Acc;
    axios
      .post("/api/User/rateingforconCheck", {
        accid,
      })
      .then((res) => {
        console.log(res.data);
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Clickhandler = (cont) => {
    console.log(cont);
    if (cont == "image") {
      document.getElementById("image").click();
    } else {
      document.getElementById("video").click();
    }
  };

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [button, setButton] = useState();
  useEffect(() => {
    axios
      .post("/api/User/ProProfileexternal", {
        Ac,
      })
      .then((res) => {
        setGetval(res.data[0]);
        const uniqueValue = new Date().getTime();
        setLogo(
          bucketName + `/${Ac}/company_logo.png?timestamp=${uniqueValue}`
        );
        setEmailRef((prev) => ({ ...prev, sub: res.data[0].string2[0].sub }));
        setButton(res.data[0].string2[0]);
        // console.log(res.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function cater(e) {
    // console.log(e.target.value)
    setforume(e.target.value);
  }
  useEffect(() => {
    console.log(forume);
  }, [forume]);

  function jvur(e, fr) {
    document.getElementById(fr).click();
  }

  function fastandf(e, content, ee, elem, id) {
    console.log(elem);
    var pit = document.getElementById(content).value;
    console.log(content);
    // var acer = document.getElementById(eyfye).files;
    console.log(pit);
    // console.log(acer);
    var data = {
      content: pit,
      deleted: filedel,
      elem,
      id,
    };
    var m = JSON.stringify(data);
  }

  useEffect(() => {
    try {
      if (first) {
        if (first.good) document.getElementById("GoodVendor").checked = true;

        if (first.high) {
          document.getElementById("HRvendor").checked = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [first]);

  const [referCount, setreferCount] = useState(false);
  const [yeser, setyeser] = useState(false);

  useEffect(() => {
    var ids = Acc;
    axios
      .post("/api/User/ReferCount", {
        ids,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data === "0" || res.data === 0) {
          return;
        } else if (res.data === "1" || res.data === 1) {
          setreferCount(res.data);
          setyeser("time");
        } else {
          setreferCount(res.data);
          setyeser("times");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);

  const [error, seterror] = useState(false);
  const validation = () => {
    if (EmailRef.email.length > 0) {
      seterror("");
    } else {
      return seterror("Please add a Email Id");
    }
    if (EmailRef.massage.length > 0) {
      seterror("");
    } else {
      return seterror("PLEASE ENTER Message");
    }
    return true;
  };

  const [Content, setContent] = useState(
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  useEffect(() => {
    if (submittrue) {
      validation();
    }
  });

  const [Final, setFinal] = useState();
  const [FinalVideo, setFinalVideo] = useState();

  function PortfolioHandler() {
    if (Video.length > 0) {
      var V_initId = Video[0].lastModified;
      var V_value = Object.values(Video);

      var V_r = V_value.length
        ? V_value.map((V_pos) => {
            if (V_pos.lastModified == V_initId && Media.length < 1) {
              return (
                <div
                  key={V_pos.lastModified}
                  id={V_pos.lastModified}
                  className="itemx carousel-item active"
                >
                  <video className="img-thumbnaili" controls>
                    <source
                      src={URL.createObjectURL(V_pos)}
                      height="100%"
                      width="100%"
                    />
                  </video>
                </div>
              );
            } else {
              return (
                <div
                  key={V_pos.lastModified}
                  id={V_pos.lastModified}
                  className="itemx carousel-item "
                >
                  <video className="img-thumbnaili" controls>
                    <source
                      src={URL.createObjectURL(V_pos)}
                      height="100%"
                      width="100%"
                    />
                  </video>
                </div>
              );
            }
          })
        : null;

      setFinalVideo(V_r);
    }
    if (Media.length > 0) {
      var initId = Media[0].lastModified;
      var value = Object.values(Media);

      var r = value.length
        ? value.map((pos) => {
            if (pos.lastModified == initId && Video.length < 1) {
              return (
                <div
                  key={pos.lastModified}
                  id={pos.lastModified}
                  className="itemx carousel-item active"
                >
                  <img
                    src={URL.createObjectURL(pos)}
                    className="img-thumbnaili"
                    alt="..."
                  />
                </div>
              );
            } else {
              return (
                <div
                  key={pos.lastModified}
                  id={pos.lastModified}
                  className="itemx carousel-item "
                >
                  <img
                    src={URL.createObjectURL(pos)}
                    className="img-thumbnaili"
                    alt="..."
                  />
                </div>
              );
            }
          })
        : null;

      setFinal(r);
    }
  }

  useEffect(() => {
    if (Media.length > 0) {
      PortfolioHandler();
    }
    if (Video.length > 0) {
      PortfolioHandler();
    }
  }, [Media, Video]);

  function changeDp(file) {
    var DP = document.getElementById("DP");
    DP.src = URL.createObjectURL(file);
    console.log(DP.src);
    console.log("DP.src");
  }

  const [type, settype] = useState();
  useEffect(() => {
    if (getval.formType === 1 || getval.formType === 2) {
      settype(true);
    } else if (getval.formType === 3) {
      settype(false);
    }
  }, [getval]);

  const [first8, setfirst8] = useState(false);

  const [sabari, setsabari] = useState({
    report: "",
    tags: [],
  });

  const [sabarierr, setsabarierr] = useState("");

  const reprtsabari = () => {
    if (sabari.report.length === 0) {
      return setsabarierr("Please enter a reason");
    } else {
      axios
        .post("api/User/reportabuse", {
          sabari,
          Acc,
        })
        .then((res) => {
          document.getElementById("closebuttonforreport").click();
          notify("Reported", true);
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("closebuttonforreport").click();
          notify("Something went wrong !", false);
        });
    }
  };

  const [CommunicationStatus, setCommunicationStatus] = useState("Loading");

  useEffect(() => {
    axios
      .post("/api/User/CommunicationStatus", { id: Acc })
      .then((res) => {
        console.log(res);
        setCommunicationStatus(res.data.Chat);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(CommunicationStatus);
    return () => {};
  }, []);

  const [firstst, setfirstst] = useState();
  const [firstst2, setfirstst2] = useState();

  const [inProgress, setinProgress] = useState(false);
  const [ErrorBookAppoinment, setErrorBookAppoinment] = useState();
  const bookAppoinment = async (e) => {
    try {
      e.preventDefault();
      setinProgress(true);
      if (!Ac) throw new Error("Invalid recipient");
      console.log(e.target.elements);
      let dataCollected = e.target.elements;
      let data = {
        recipient: Ac,
        date: dataCollected.date.value,
        time: dataCollected.time.value,
        urgent: dataCollected.urgent.checked,
        purpose: dataCollected.purpose.value.trim(),
        mentionInfo: dataCollected.mentionInfo.checked,
      };

      if (!data.date) {
        setinProgress(false);
        return setErrorBookAppoinment("Please select a date !");
      }
      if (!data.time) {
        setinProgress(false);
        return setErrorBookAppoinment("Please select a time !");
      }
      if (!data.purpose) {
        setinProgress(false);
        return setErrorBookAppoinment("Please enter purpose !");
      }
      setErrorBookAppoinment("");

      let result = await axios.post("/api/ActiveUser/bookAppoinment", { data });
      console.log(result);
      setinProgress(false);
      e.target.reset();
      document.getElementById("slranan").click();
      if (result.status === 200) {
        notify("Completed", true);
        console.log("done");
      } else {
        notify("Something went wrong !", false);
      }
    } catch (error) {
      console.log(error);
      setinProgress(false);

      notify("Something went wrong !", false);
    }
  };
  const [Low, setLow] = useState(0);

  const [url, setUrl] = useState("");
  const [shareshow, setshareshow] = useState(false);

  useEffect(() => {
    setUrl(
      `See the Profile of ${
        getval.pname ? getval.pname : ""
      }\nwww.prochure.in/find_profile?profile=services_profile&&Ac_id=${Mask.encode(
        getval._id
      )}`
    );
  }, [getval]);

  return (
    <>
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border ">
          <button
            className="d-none"
            id="prochureButtonSpecialPviewRe"
            onClick={(e) => {
              setLow((rev) => rev + 1);
            }}
          >
            up
          </button>
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-3">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-center">
                <div style={{ position: "relative" }}>
                  <img
                    id="DP"
                    className="new-vp-image"
                    src={getval.link}
                    alt=""
                  />
                  {logo && !isLogo && (
                    <img
                      className="new-card-logo"
                      src={logo}
                      onError={() => setIsLogo(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5 className="mb-1">
                    {getval.pname ? getval.pname.toUpperCase() : ""}
                  </h5>
                  <p className="mt-0 pt-0 mb-2">
                    {getval?.brandtagline ? getval.brandtagline : ""}
                  </p>
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5 className="mb-1">
                      {getval.pname ? getval.pname.toUpperCase() : ""}
                    </h5>
                    <p className="mt-0 pt-0 mb-2">
                      {getval?.brandtagline ? getval.brandtagline : ""}
                    </p>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                        {getval.string ? getval.string[0].category : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                          {getval.string ? getval.string[0].category : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="row pe-0">
                  <div className="col pe-0">
                    <div className="d-none d-md-block">
                      {CommunicationStatus === true ? (
                        localStorage.getItem("Chat") === "false" ? (
                          <>
                            <input
                              type="submit"
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ borderRadius: "25px" }}
                              name="btnAddMore"
                              value="COMMUNICATE VIA CHAT"
                              data-bs-toggle="modal"
                              data-bs-target="#turnonchat23423"
                            />
                          </>
                        ) : (
                          <>
                            <input
                              type="button"
                              onClick={(e) => {
                                communicate();
                              }}
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ borderRadius: "25px" }}
                              name="btnAddMore"
                              value="COMMUNICATE VIA CHAT"
                            />
                          </>
                        )
                      ) : (
                        <></>
                      )}
                      {button?.book_an_appointment ? (
                        <input
                          type="submit"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{ borderRadius: "25px" }}
                          name="btnAddMore"
                          value="BOOK AN APPOINTMENT"
                          data-bs-toggle="modal"
                          data-bs-target="#bookappoimentmodal"
                        />
                      ) : (
                        <></>
                      )}
                      {button?.book_a_table ? (
                        <input
                          type="submit"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{ borderRadius: "25px" }}
                          name="btnAddMore"
                          value="BOOK A TABLE"
                          data-bs-toggle="modal"
                          data-bs-target="#bookatablemodal"
                        />
                      ) : (
                        <></>
                      )}
                      {button?.place_an_order ? (
                        <input
                          type="submit"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{ borderRadius: "25px" }}
                          name="btnAddMore"
                          value="PLACE AN ORDER"
                          data-bs-toggle="modal"
                          data-bs-target="#placeanordermodal"
                        />
                      ) : (
                        <></>
                      )}
                      {button?.make_a_purchase ? (
                        <input
                          type="submit"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{ borderRadius: "25px" }}
                          name="btnAddMore"
                          value="MAKE A PURCHASE"
                          data-bs-toggle="modal"
                          data-bs-target="#makeapurchasemodal"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {CommunicationStatus === true ? (
                          localStorage.getItem("Chat") === "false" ? (
                            <>
                              <input
                                type="submit"
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="COMMUNICATE VIA CHAT"
                                data-bs-toggle="modal"
                                data-bs-target="#turnonchat23423"
                              />
                            </>
                          ) : (
                            <>
                              <input
                                type="button"
                                onClick={(e) => {
                                  communicate();
                                }}
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="COMMUNICATE VIA CHAT"
                              />
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {button?.book_an_appointment ? (
                          <input
                            type="submit"
                            className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                            style={{ borderRadius: "25px" }}
                            name="btnAddMore"
                            value="BOOK AN APPOINTMENT"
                            data-bs-toggle="modal"
                            data-bs-target="#bookappoimentmodal"
                          />
                        ) : (
                          <></>
                        )}
                        <div>
                          {button?.book_a_table ? (
                            <input
                              type="submit"
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ borderRadius: "25px" }}
                              name="btnAddMore"
                              value="BOOK A TABLE"
                              data-bs-toggle="modal"
                              data-bs-target="#bookatablemodal"
                            />
                          ) : (
                            <></>
                          )}
                          {button?.place_an_order ? (
                            <input
                              type="submit"
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ borderRadius: "25px" }}
                              name="btnAddMore"
                              value="PLACE AN ORDER"
                              data-bs-toggle="modal"
                              data-bs-target="#placeanordermodal"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {button?.make_a_purchase ? (
                          <input
                            type="submit"
                            className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                            style={{ borderRadius: "25px" }}
                            name="btnAddMore"
                            value="MAKE A PURCHASE"
                            data-bs-toggle="modal"
                            data-bs-target="#makeapurchasemodal"
                          />
                        ) : (
                          <></>
                        )}
                      </center>
                    </div>
                  </div>
                </div>
                <div className="row  ms-3">
                  {getval.Type === "Productv3" ? (
                    <div className="form-check col-12 d-flex justify-content-start mb-3 pe-0 invisible">
                      <input
                        className="form-check-input float-end checkboxpro me-3 mt-3"
                        type="checkbox"
                        value=""
                        id={Ac}
                        onClick={(e) => checkaeijfpi(e)}
                      />
                      <span className="iorughoisrhgiohs">
                        {!mappas ? (
                          <span>Short&#8209;list&nbsp;this&nbsp;Profile</span>
                        ) : (
                          "Short-listed"
                        )}
                      </span>
                    </div>
                  ) : (
                    <div className="form-check col-12 d-flex justify-content-start mb-3 pe-0">
                      <input
                        className="form-check-input float-end checkboxpro me-3 mt-3"
                        type="checkbox"
                        value=""
                        id={Ac}
                        onClick={(e) => checkaeijfpi(e)}
                      />
                      <span className="iorughoisrhgiohs">
                        {!mappas ? (
                          <span>Short&#8209;list&nbsp;this&nbsp;Profile</span>
                        ) : (
                          "Short-listed"
                        )}
                      </span>
                    </div>
                  )}
                  <div className="col-3 col-md-3 col-lg-5 d-none"></div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="reportthismodal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              data-bs-backdrop="static"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Report this Profile
                    </h5>
                    <button
                      type="button"
                      id="closebuttonforreport"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                    <p>
                      Please BE INFORMED that you must Report Abuse about a
                      Profile ONLY for a genuine valid reason which you MUST
                      mention with evidence.
                    </p>
                    <p>
                      <b>
                        Fake / False reporting about a member as an act of prank
                        will result in deactivation of your membership without
                        refund.
                      </b>
                    </p>
                    <p>Why are you Reporting this Profile ?</p>
                    <span>This is :</span>
                    <FormControl
                      className="form-control"
                      style={{ zIndex: 99999999999 }}
                    >
                      <>
                        <Select
                          id="demo-multiple-checkbox"
                          multiple
                          className="form-select"
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              display: "none",
                            },
                            "& .MuiSvgIcon-root": { display: "none" },
                            "& .MuiSelect-select": { padding: "0" },
                          }}
                          value={sabari.tags}
                          onChange={(e) =>
                            setsabari({ ...sabari, tags: e.target.value })
                          }
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {reportTags.map((languages) => (
                            <MenuItem key={languages} value={languages}>
                              <Checkbox
                                checked={sabari.tags.indexOf(languages) > -1}
                              />
                              <ListItemText primary={languages} />
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    </FormControl>

                    <textarea
                      className="form-control mt-2"
                      rows={6}
                      onChange={(e) =>
                        setsabari({ ...sabari, report: e.target.value })
                      }
                      placeholder="Type your Reason"
                    ></textarea>
                    <p className="text-danger">{sabarierr ? sabarierr : ""}</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn pro-spaces-button3"
                      onClick={reprtsabari}
                    >
                      Report
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="turnonchat23423"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      You need to Activate Chat to communicate with this member.
                    </h5>
                    <button
                      type="button"
                      id="closebuttonforreportkgwfiuwsgfbvsdjbfuisea"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                    <p>
                      Your One-to-One Chatting feature is currently Disabled.
                    </p>
                    <p
                      style={astyle}
                      onClick={enablechat}
                      to="/profile_details_and_settings"
                    >
                      Enable Chatting now
                    </p>
                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn pro-spaces-button3" onClick={reprtsabari}>Report</button> */}
                  </div>
                </div>
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {getval.Type === "Productv3" ? (
                  <>
                    <Accordion
                      expanded={expanded === "panel128"}
                      onChange={handleChange("panel128")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Rate, Refer and Report this Profile</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <>
                            <div className="form-check col-12 d-flex justify-content-start mb-3  pe-0 d-none">
                              <input
                                className="form-check-input float-end checkboxpro me-3 mt-3"
                                type="checkbox"
                                value=""
                                id={Ac + "2"}
                                onClick={(e) => checkaeijfpi2(e)}
                              />
                              <span className="iorughoisrhgiohs">
                                {!mappas
                                  ? "Short-list this Profile"
                                  : "Short-listed"}
                              </span>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <a
                                  className="card-title sgknriog"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  {getval.formType
                                    ? getval.formType === 1
                                      ? "Rate this Profile"
                                      : "Rate this Profile"
                                    : ""}
                                </a>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <p className="card-text">Good</p>
                              </div>
                              <div className="col-6">
                                <p className="card-text">
                                  {getval.Status1 ? getval.Status1.length : ""}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <p className="card-text">
                                  Highly Recommended (HR)
                                </p>
                              </div>
                              <div className="col-6">
                                <p className="card-text">
                                  {getval.Status2 ? getval.Status2.length : ""}
                                </p>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-12">
                                <a
                                  className="card-title sgknriog"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#examplewkligheModal"
                                  onClick={(e) => setshareshow(true)}
                                >
                                  {getval.formType
                                    ? getval.formType === 1
                                      ? "Refer this Profile"
                                      : "Refer this Profile"
                                    : ""}
                                </a>
                              </div>
                            </div>
                            {/* {yeser ? (
                              <div className="row">
                                <div className="col-6">
                                  <p className="card-text">
                                    {" "}
                                    Has been Referred
                                  </p>
                                </div>
                                <div className="col-6">
                                  <p className="card-text">
                                    {referCount ? referCount : ""}{" "}
                                    {yeser ? yeser : ""}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )} */}
                            <br />
                            <div className="row">
                              <a
                                href=""
                                data-bs-toggle="modal"
                                data-bs-target="#reportthismodal"
                              >
                                {getval.formType
                                  ? getval.formType === 1
                                    ? "Report this Profile"
                                    : "Report this Profile"
                                  : ""}
                              </a>
                            </div>

                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      {getval.formType
                                        ? getval.formType === 1
                                          ? "Rate this Profile"
                                          : "Rate this Profile"
                                        : ""}
                                    </h5>
                                    <button
                                      type="button"
                                      id="closebuttonforreport"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <div>
                                      <div className="form-check  ms-5 m-3">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="GoodVendor"
                                          onChange={(e) => addreating(e, 1)}
                                          defaultChecked={
                                            first
                                              ? first.good
                                                ? true
                                                : false
                                              : false
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="GoodVendor"
                                        >
                                          Good
                                        </label>
                                      </div>
                                      <div className="form-check ms-5 m-3">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="HRvendor"
                                          onChange={(e) => addreating(e, 2)}
                                          defaultChecked={
                                            first
                                              ? first.high
                                                ? true
                                                : false
                                              : false
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="HRvendor"
                                        >
                                          Highly Recommended (HR)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="modal fade"
                              id="examplewkligheModal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      {getval.formType
                                        ? getval.formType === 1
                                          ? "Refer this Profile"
                                          : "Refer this Profile"
                                        : ""}
                                    </h5>
                                    <button
                                      type="button"
                                      id="closebuttonforreport123"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <div>
                                      {/* <label className="form-label" htmlFor="flexCheckDefault1222">
                  Refer
                </label> */}
                                      <div className="col-md-12 p-1 mt-2">
                                        <label className="form-label">
                                          Email Id *
                                        </label>
                                        <p>
                                          Enter the email ids of the persons to
                                          whom you would like to refer this
                                          Profile
                                        </p>
                                        {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                                        <div className="row ps-4">
                                          {Email ? Email : ""}
                                        </div>
                                      </div>
                                      <div className="col-md-12 mt-2">
                                        <div className="input-group w-100">
                                          <input
                                            maxLength=""
                                            className="form-control"
                                            id="skills"
                                            onChange={(e) =>
                                              makeEmail(e.target.id)
                                            }
                                            type="text"
                                            aria-label="Recipient's username"
                                            aria-describedby="button-addon2"
                                          />
                                          <button
                                            className="btn pro-spaces-button"
                                            onClick={(e) =>
                                              makeEmail("skills", true)
                                            }
                                            type="button"
                                            id="button-addon2"
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>

                                      <div className="">
                                        <label>Subject</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value="PROchure Referral of Product Retailer"
                                          onChange={(e) =>
                                            setEmailRef({
                                              ...EmailRef,
                                              subject: e.target.value,
                                            })
                                          }
                                          disabled
                                        ></input>
                                      </div>
                                      <br></br>
                                      <div className="">
                                        <label>Message *</label>
                                        <textarea
                                          className="form-control"
                                          value={EmailRef.massage}
                                          onChange={(e) =>
                                            setEmailRef({
                                              ...EmailRef,
                                              massage: e.target.value,
                                            })
                                          }
                                        ></textarea>
                                        {/* <input type="text" className="form-control" onChange={(e)=>setEmailRefer({...EmailRefer,massage:e.target.value})} ></input> */}
                                      </div>
                                      <br />

                                      <p className="text-danger">
                                        {error ? error : ""}
                                      </p>
                                      <div className="button-section d-flex justify-content-end">
                                        {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                                        {!loading && (
                                          <button
                                            className="btn pro-spaces-button3"
                                            type="submit"
                                            onClick={(e) => SendRef()}
                                          >
                                            <IoIosSend />
                                          </button>
                                        )}
                                        {loading && (
                                          <button
                                            className="btn pro-spaces-button3"
                                            type="submit"
                                            onClick={(e) => SendRef()}
                                            disabled
                                          >
                                            <span
                                              className="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panelewrg4"}
                      onChange={handleChange("panelewrg4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Product Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p
                            style={{ whiteSpace: "pre-line" }}
                            className="py-2"
                          >
                            {getval.Description}
                          </p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <PersonalInformationStatic getval={getval} vendor={true} />

                    <BussinessInformationVendor getval={getval} />
                    <ContactInformationVendor getval={getval} />
                  </>
                ) : (
                  <>
                    <Accordion
                      expanded={expanded === "panel128"}
                      onChange={handleChange("panel128")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Rate, Refer and Report this Profile</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <>
                            <div className="form-check col-12 d-flex justify-content-start mb-3  pe-0 d-none">
                              <input
                                className="form-check-input float-end checkboxpro me-3 mt-3"
                                type="checkbox"
                                value=""
                                id={Ac + "2"}
                                onClick={(e) => checkaeijfpi2(e)}
                              />
                              <span className="iorughoisrhgiohs">
                                {!mappas
                                  ? "Short-list this Profile"
                                  : "Short-listed"}
                              </span>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <a
                                  className="card-title sgknriog"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  {getval.formType
                                    ? getval.formType === 1
                                      ? "Rate this Profile"
                                      : "Rate this Profile"
                                    : ""}
                                </a>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <p className="card-text">Good</p>
                              </div>
                              <div className="col-6">
                                <p className="card-text">
                                  {getval.Status1 ? getval.Status1.length : ""}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <p className="card-text">
                                  Highly Recommended (HR)
                                </p>
                              </div>
                              <div className="col-6">
                                <p className="card-text">
                                  {getval.Status2 ? getval.Status2.length : ""}
                                </p>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-12">
                                <a
                                  className="card-title sgknriog"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#examplewkligheModal"
                                  onClick={(e) => setshareshow(true)}
                                >
                                  {getval.formType
                                    ? getval.formType === 1
                                      ? "Refer this Profile"
                                      : "Refer this Profile"
                                    : ""}
                                </a>
                              </div>
                            </div>
                            {/* {yeser ? (
                              <div className="row">
                                <div className="col-6">
                                  <p className="card-text">
                                    {" "}
                                    Has been Referred
                                  </p>
                                </div>
                                <div className="col-6">
                                  <p className="card-text">
                                    {referCount ? referCount : ""}{" "}
                                    {yeser ? yeser : ""}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )} */}
                            <br />
                            <div className="row">
                              <a
                                href=""
                                data-bs-toggle="modal"
                                data-bs-target="#reportthismodal"
                              >
                                {getval.formType
                                  ? getval.formType === 1
                                    ? "Report this Profile"
                                    : "Report this Profile"
                                  : ""}
                              </a>
                            </div>

                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      {getval.formType
                                        ? getval.formType === 1
                                          ? "Rate this Profile"
                                          : "Rate this Profile"
                                        : ""}
                                    </h5>
                                    <button
                                      type="button"
                                      id="closebuttonforreport"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <div>
                                      <div className="form-check  ms-5 m-3">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="GoodVendor"
                                          onChange={(e) => addreating(e, 1)}
                                          defaultChecked={
                                            first
                                              ? first.good
                                                ? true
                                                : false
                                              : false
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="GoodVendor"
                                        >
                                          Good
                                        </label>
                                      </div>
                                      <div className="form-check ms-5 m-3">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="HRvendor"
                                          onChange={(e) => addreating(e, 2)}
                                          defaultChecked={
                                            first
                                              ? first.high
                                                ? true
                                                : false
                                              : false
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="HRvendor"
                                        >
                                          Highly Recommended (HR)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="modal fade"
                              id="examplewkligheModal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      {getval.formType
                                        ? getval.formType === 1
                                          ? "Refer this Profile"
                                          : "Refer this Profile"
                                        : ""}
                                    </h5>
                                    <button
                                      type="button"
                                      id="closebuttonforreport123"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <div>
                                      {/* <label className="form-label" htmlFor="flexCheckDefault1222">
                  Refer
                </label> */}
                                      <div className="col-md-12 p-1 mt-2">
                                        <label className="form-label">
                                          Email Id *
                                        </label>
                                        <p>
                                          Enter the email ids of the persons to
                                          whom you would like to refer this
                                          Profile
                                        </p>
                                        {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                                        <div className="row ps-4">
                                          {Email ? Email : ""}
                                        </div>
                                      </div>
                                      <div className="col-md-12 mt-2">
                                        <div className="input-group w-100">
                                          <input
                                            maxLength=""
                                            className="form-control"
                                            id="skills"
                                            onChange={(e) =>
                                              makeEmail(e.target.id)
                                            }
                                            type="text"
                                            aria-label="Recipient's username"
                                            aria-describedby="button-addon2"
                                          />
                                          <button
                                            className="btn pro-spaces-button"
                                            onClick={(e) =>
                                              makeEmail("skills", true)
                                            }
                                            type="button"
                                            id="button-addon2"
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>

                                      <div className="">
                                        <label>Subject</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value="PROchure Referral of Product Retailer"
                                          onChange={(e) =>
                                            setEmailRef({
                                              ...EmailRef,
                                              subject: e.target.value,
                                            })
                                          }
                                          disabled
                                        ></input>
                                      </div>
                                      <br></br>
                                      <div className="">
                                        <label>Message *</label>
                                        <textarea
                                          className="form-control"
                                          value={EmailRef.massage}
                                          onChange={(e) =>
                                            setEmailRef({
                                              ...EmailRef,
                                              massage: e.target.value,
                                            })
                                          }
                                        ></textarea>
                                        {/* <input type="text" className="form-control" onChange={(e)=>setEmailRefer({...EmailRefer,massage:e.target.value})} ></input> */}
                                      </div>
                                      <br />

                                      <p className="text-danger">
                                        {error ? error : ""}
                                      </p>
                                      <div className="button-section d-flex justify-content-end">
                                        {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                                        {!loading && (
                                          <button
                                            className="btn pro-spaces-button3"
                                            type="submit"
                                            onClick={(e) => SendRef()}
                                          >
                                            <IoIosSend />
                                          </button>
                                        )}
                                        {loading && (
                                          <button
                                            className="btn pro-spaces-button3"
                                            type="submit"
                                            onClick={(e) => SendRef()}
                                            disabled
                                          >
                                            <span
                                              className="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>
                            {getval
                              ? type
                                ? "Catalogue of Products"
                                : "Catalogue of Projects"
                              : "Catalogue"}
                          </b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <GetportExternal1 Acc={Acc} sab={type} />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    {getval ? (
                      getval.Type === "Product" ||
                      getval.Type === "Productv2" ? (
                        <>
                          {firstst === 0 ? (
                            <></>
                          ) : (
                            <>
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      sab={type}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          )}
                          {firstst2 === 0 ? (
                            <></>
                          ) : (
                            <Accordion
                              expanded={expanded === "panel3322"}
                              onChange={handleChange("panel3322")}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                              >
                                <Typography
                                  sx={{ width: "100%", flexShrink: 0 }}
                                >
                                  <b>
                                    <span className="blink_me">
                                      NEWLY LAUNCHED
                                    </span>{" "}
                                    {getval
                                      ? type
                                        ? "Product"
                                        : "Project"
                                      : ""}
                                  </b>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  <GetNewLaunchExternal
                                    Acc={Acc}
                                    sab={type}
                                    setfirstst2={setfirstst2}
                                  />
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                    <PersonalInformationVendor getval={getval} />

                    <BussinessInformationVendor getval={getval} />
                    <ContactInformationVendor getval={getval} />
                  </>
                )}
                <Selfdeclaration type="vdp" />
              </div>
            </center>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8"></div>
          </div>
        </div>

        {/* Book An Appoiment */}
        <div
          className="modal fade"
          id="bookappoimentmodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  BOOK AN APPOINTMENT
                </h6>
                <button
                  id="slranan"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <form onSubmit={(e) => bookAppoinment(e)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <label className="labels">Date *</label>
                      <input name="date" type="date" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label className="labels">Preferred Time Slot *</label>
                      <input name="time" type="time" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label className="labels">Purpose of Visit *</label>
                      <textarea
                        rows={3}
                        name="purpose"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-check  mt-2 mb-3">
                    <input
                      className="form-check-input mt-2"
                      type="checkbox"
                      value=""
                      name="mentionInfo"
                      id="flexChsourgeckDefault"
                    />
                    <label className="labels" htmlFor="flexChsourgeckDefault">
                      Please mention your consultation fees / service charges
                      for my requirement
                    </label>
                  </div>
                  <div className="form-check  mb-3">
                    <input
                      className="form-check-input mt-2"
                      type="checkbox"
                      name="urgent"
                      value=""
                      id="flexChecugfwwkDefault"
                    />
                    <label className="labels" htmlFor="flexChecugfwwkDefault">
                      This is an Urgent Requirement
                    </label>
                  </div>
                  {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
                  <p className="text-danger">{ErrorBookAppoinment}</p>
                </div>
                <div class="modal-footer">
                  {inProgress ? (
                    <button disabled={true} class="btn pro-spaces-button3">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button type="submit" class="btn pro-spaces-button3">
                      SEND REQUEST
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* BOOK A TABLE */}
        <div
          className="modal fade"
          id="bookatablemodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  BOOK A TABLE
                </h6>
                <button
                  id="slr"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <label className="labels">Date *</label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="labels">Time *</label>
                    <input type="time" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="labels">Table for *</label>
                    <input
                      type="number"
                      placeholder="Mention number of people"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn pro-spaces-button3">
                  SEND REQUEST
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* PLACE AN ORDER */}
        <div
          className="modal fade"
          id="placeanordermodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  PLACE AN ORDER
                </h6>
                <button
                  id="slr"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <label className="labels">Type of Order *</label>
                    <select className="form-select">
                      <option></option>
                      <option>Take Away</option>
                      <option>Home Delivery</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="labels">Preferred Payment Option *</label>
                    <select className="form-select">
                      <option></option>
                      <option>Cash</option>
                      <option>Card</option>
                      <option>UPI</option>
                      <option>Cheque</option>
                      <option>Net transfer</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="labels">Select from Menu *</label>
                    <select className="form-select">
                      <option></option>
                    </select>
                  </div>
                </div>

                {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn pro-spaces-button3">
                  PLACE ORDER
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* MAKE A PURCHASE */}
        <div
          className="modal fade"
          id="makeapurchasemodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  MAKE A PURCHASE
                </h6>
                <button
                  id="slr"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <label className="labels">
                      Delivery Requirement Date *
                    </label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="labels">
                      Delivery Requirement Time *
                    </label>
                    <input type="time" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="labels">Preferred Payment Option *</label>
                    <select className="form-select">
                      <option></option>
                      <option>Cash</option>
                      <option>Card</option>
                      <option>UPI</option>
                      <option>Cheque</option>
                      <option>Net transfer</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="labels">Select from Menu *</label>
                    <select className="form-select">
                      <option></option>
                    </select>
                  </div>
                </div>

                {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn pro-spaces-button3">
                  PLACE ORDER
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareThis shareshow={shareshow} setshareshow={setshareshow} url={url} />

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Pview;
