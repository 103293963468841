import { useEffect, useState, useRef } from "react";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { keyGen } from "../../Utility/helper";
import { ProductDiscription } from "./port/PortProductDiscription";
import { ProjectDiscriptions } from "./port/ProjectDiscriptions";
import { print_state, print_city, state_arr } from "../../Utility/stateCity";
import Form from "react-bootstrap/Form";
import { ExistingData, InitializeCountry } from "../../Utility/Country";

let old = [];
export const PortTile = (props) => {
  let countryRef = useRef();
  let stateRef = useRef();
  let cityRef = useRef();
  useEffect(() => {
    InitializeCountry(countryRef, stateRef, cityRef);

    return () => {};
  }, []);
  const formData = new FormData();
  const [show, setShow] = useState(false);
  const target1 = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);
  const target4 = useRef(null);
  const target5 = useRef(null);
  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState(false);
  const [Schedule, setSchedule] = useState(false);
  const [first, setfirst] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [showMediaI, setshowMediaI] = useState([]);
  const [content, setContent] = useState("");
  const [tittle, setTittle] = useState("");
  const [error, setError] = useState();
  const [halt, sethalt] = useState(false);
  const [Progress, setProgress] = useState(0);
  const [value, setValue] = useState({
    type: "",
    id: "",
    brand: "",
    project_name: "",
    project_locality: "",
    project_type_radio: "",
    project_type: "",
    number_of_units: "",
    number_of_floor: "",
    number_of_bedroom: "",
    area: "",
    carpet_area: "",
    project_discription: "",
    project_highlights: "",
    project_status: "",
    rera: "",
    vasstu: "",
    occupancy_avalibility: "",
    occupancy_certificate: "",
    association: "",
    rate: "",
    offers: "",
    keyword: "",
    country: "",
    state: "",
    city: "",
    product_name: "",
    brand: "",
    product_discription: "",
    product_varint: "",
    color: "",
    dimention: "",
    width: "",
    length: "",
    depth: "",
    product_usp: "",
    mrp: "",
    selling_price: "",
    keyword: "",
    File: [],
    discriptions: [],
    titles: [],
    titleB: [],
    titleC: [],
    Product_Discriptions: [],
    Project_Discriptions: [],
    catalouge_title: "",
  });

  const [data, setdata] = useState();
  useEffect(() => {
    doRender();
  }, []);
  const [FileOld, setFileOld] = useState([]);
  const doRender = async () => {
    try {
      let id = props.id;
      let a = await axios.post("/api/Free/GetPortfolioSingleT", { id });
      setdata(a.data[0]);

      setValue({
        ...value,
        type: a.data[0].type,
        id: a.data[0]._id,
        brand: a.data[0].brand,
        project_name: a.data[0].project_name,
        project_locality: a.data[0].project_locality,
        project_type_radio: a.data[0].project_type_radio,
        project_type: a.data[0].project_type,
        number_of_units: a.data[0].number_of_units,
        number_of_floor: a.data[0].number_of_floor,
        number_of_bedroom: a.data[0].number_of_bedroom,
        area: a.data[0].area,
        carpet_area: a.data[0].carpet_area,
        project_discription: a.data[0].project_discription,
        project_highlights: a.data[0].project_highlights,
        project_status: a.data[0].project_status,
        rera: a.data[0].rera,
        vasstu: a.data[0].vasstu,
        occupancy_avalibility: a.data[0].occupancy_avalibility,
        occupancy_certificate: a.data[0].occupancy_certificate,
        association: a.data[0].association,
        rate: a.data[0].rate,
        offers: a.data[0].offers,
        keyword: a.data[0].keyword,
        country: a.data[0].country,
        state: a.data[0].state,
        city: a.data[0].city,
        product_name: a.data[0].product_name,
        brand: a.data[0].brand,
        product_discription: a.data[0].product_discription,
        product_varint: a.data[0].product_varint,
        color: a.data[0].color,
        dimention: a.data[0].dimention,
        width: a.data[0].width,
        length: a.data[0].length,
        depth: a.data[0].depth,
        product_usp: a.data[0].product_usp,
        mrp: a.data[0].mrp,
        selling_price: a.data[0].selling_price,
        keyword: a.data[0].keyword,
        catalouge_title: a.data[0].catalouge_title,
        File: a.data[0].File,
        titles: a.data[0].titles,
        discriptions: a.data[0].discriptions,
        titleB: a.data[0].titleB,
        titleC: a.data[0].titleC,

        Project_Discriptions: a.data[0].Project_Discriptions,
        Product_Discriptions: a.data[0].Product_Discriptions,
      });
      InitializeCountry(countryRef, stateRef, cityRef);

      ExistingData(
        countryRef,
        stateRef,
        cityRef,
        a.data[0].country,
        a.data[0].state,
        a.data[0].city
      );
      setFileOld(a.data[0].File);
    } catch (err) {
      console.log(err);
    }
  };
  const [Done, setDone] = useState(false);
  useEffect(() => {
    if (!Done) {
      if (value.File.length > 0) {
        setDone(true);
        PremediaHandler(value.File);
      }
      if (value.Product_Discriptions) {
        let temp = [];
        value.Product_Discriptions.forEach((element) => {
          temp = [...temp, keyGen()];
        });
        setProductDiscriptions(temp);
      }
      if (value.Project_Discriptions) {
        let temp = [];
        value.Project_Discriptions.forEach((element) => {
          temp = [...temp, keyGen()];
        });
        setProjectDiscription(temp);
      }
    }
  }, [value]);

  function validation() {
    if (fil.length > 0) {
      setError("");
    } else {
      return setError("PLEASE ADD a Picture or Video");
    }

    if (value.type === "product") {
      if (value.catalouge_title.length > 0) {
        setError("");
      } else {
        return setError("PLEASE ADD Catalogue Title");
      }

      // if (value.product_name.length > 0) {
      //     setError("");
      // }

      // else {
      //     return setError("PLEASE ENTER Product Name");
      // }

      // if (value.brand.length > 0) {
      //     setError("");
      // }

      // else {
      //     return setError("PLEASE ENTER Product Brand");
      // }

      // if (value.color.length > 0) {
      //     setError("");
      // }

      // else {
      //     return setError("PLEASE ENTER Colours available");
      // }

      // if (value.mrp.length > 0) {
      //     setError("");
      // }

      // else {
      //     return setError("PLEASE ENTER MRP");
      // }
    } else if (value.type === "project") {
      if (value.catalouge_title.length > 0) {
        setError("");
      } else {
        return setError("PLEASE ADD Catalogue Title");
      }

      if (value.project_name.length > 0) {
        setError("");
      } else {
        return setError("PLEASE ENTER Project Name");
      }

      if (value.country.length > 0) {
        setError("");
      } else {
        return setError("PLEASE ENTER Project Location (Country)");
      }

      if (value.state.length > 0) {
        setError("");
      } else {
        return setError("PLEASE ENTER Project Location (State)");
      }

      if (value.city.length > 0) {
        setError("");
      } else {
        return setError("PLEASE ENTER Project Location (City)");
      }

      if (value.project_type_radio === "on-going") {
        if (value.project_locality.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Project Location (Locality)");
        }

        if (value.number_of_units.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Total Number of Units");
        }

        if (value.number_of_floor.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Total Number of Floors");
        }
        for (const iterator of value.Project_Discriptions) {
          if (iterator.number_of_bedroom.length > 0) {
            setError("");
          } else {
            return setError("PLEASE ENTER Total Number of Bedrooms");
          }

          if (iterator.area.length > 0) {
            setError("");
          } else {
            return setError("PLEASE ENTER Unit Built-up Area");
          }

          if (iterator.carpet_area.length > 0) {
            setError("");
          } else {
            return setError("PLEASE ENTER Unit Carpet Area");
          }
        }

        if (value.project_highlights.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Project Highlights / USP");
        }

        if (value.project_status.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Project Status");
        }

        if (value.rera.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER RERA Compliant");
        }

        if (value.vasstu.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Vaastu Compliant");
        }

        if (value.occupancy_avalibility.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Occupancy Availability");
        }

        if (value.occupancy_certificate.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Occupancy Certificate");
        }

        if (value.association.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Association Formation");
        }

        if (value.rate.length > 0) {
          setError("");
        } else {
          return setError("PLEASE ENTER Rate per SQFT / SQCM on Built-up Area");
        }
      }
    }

    return true;
  }

  const Clickhandler = (cont) => {
    if (cont == "imagexr") {
      document.getElementById(`imagexr${props.id}`).click();
    } else if (cont == "videoxr") {
      document.getElementById(`videoxr${props.id}`).click();
    } else if (cont == "audioxr") {
      document.getElementById(`audioxr${props.id}`).click();
    }
  };

  //////////////////////////////
  var arr = [];
  const [Titles, setTitles] = useState([]);

  const [fil, setfil] = useState([]);
  const [Count, setCount] = useState(0);
  const [Disc, setDisc] = useState([]);
  const DiscHandler = (elem) => {
    let id = elem.target.id;
    let pos = id.substring(5);
    let ob = {
      position: pos,
      Disc: elem.target.value,
    };
    // let Disce = Disc
    // Disce.push(ob)
    Disc[pos] = ob;
    // setDisc({ ...Disc, pos: ob })
  };
  const TitleHandler = (elem) => {
    let id = elem.target.id;
    let pos = id.substring(4);
    let ob = {
      position: pos,
      title: elem.target.value,
    };
    Titles[pos] = ob;
  };

  const DeleteThis = (e) => {
    try {
      let data = e.target.getAttribute("data");
      let pos = parseInt(data.substring(4));
      fil[pos] = null;
      next();
      document.getElementById("car" + data).remove();
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteThisPro = (e) => {
    try {
      let data = e.target.getAttribute("data");
      let pos = parseInt(data.substring(4));
      old = [...old, pos];
      next();
      let idd = "car" + data + props.id;
      document.getElementById(idd).remove();
    } catch (err) {
      console.log(err);
    }
  };

  const [newVal, setnewVal] = useState([]);
  const [newDiscVal, setnewDiscVal] = useState([]);
  const [newTitleB, setnewTitleB] = useState([]);
  const [newTitleC, setnewTitleC] = useState([]);

  const preTitle = (e) => (newVal[e.target.name] = e.target.value);
  const preDisc = (e) => (newDiscVal[e.target.name] = e.target.value);
  const preTitleB = (e) => (newTitleB[e.target.name] = e.target.value);
  const preTitleC = (e) => (newTitleC[e.target.name] = e.target.value);

  const PremediaHandler = (files) => {
    try {
      let temp = [];
      let Counter = Count;
      if (files)
        files.forEach((element) => {
          let file = element.link;
          let type = element.filetype.substring(0, 5);

          if (type === "image") {
            let ide = "port" + Counter;
            let newId = "port" + Counter;
            if (Counter === 0) {
              temp.push(
                <div
                  id={`car${newId + props.id}`}
                  className="carousel-item active "
                >
                  <button
                    data={ide}
                    type="button"
                    onClick={(e) => DeleteThisPro(e)}
                    className="btn-close btn-close-dark float-end"
                    aria-label="Close"
                  ></button>
                  <img className="carItem" src={file}></img>
                  <br />
                  <br />
                  <input
                    id={newId}
                    onChange={(e) => preTitle(e)}
                    name={Counter}
                    className={`${
                      value.type === "product" ? "d-noe" : ""
                    } form-control`}
                    defaultValue={
                      value.titles[Counter] && value.titles[Counter].title
                    }
                  ></input>
                  <br />
                  <input
                    id={`B` + newId}
                    onChange={(e) => preTitleB(e)}
                    placeholder=""
                    name={Counter}
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleB[Counter] && value.titleB[Counter].title
                    }
                  />
                  <br />
                  <input
                    id={`C` + newId}
                    onChange={(e) => preTitleC(e)}
                    placeholder=""
                    name={Counter}
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleC[Counter] && value.titleC[Counter].title
                    }
                  />
                  <br />
                  <textarea
                    name={Counter}
                    className={`${
                      value.type === "product" ? "d-nne" : ""
                    } form-control`}
                    onChange={(e) => preDisc(e)}
                    id={`A` + newId}
                  >
                    {value.discriptions[Counter] &&
                      value.discriptions[Counter].Disc}
                  </textarea>
                  <br />
                </div>
              );
            } else {
              temp.push(
                <div id={`car${newId + props.id}`} className="carousel-item   ">
                  <button
                    data={ide}
                    type="button"
                    onClick={(e) => DeleteThisPro(e)}
                    className="btn-close btn-close-dark float-end"
                    aria-label="Close"
                  ></button>
                  <img className="carItem" src={file}></img>
                  <br />
                  <br />
                  <input
                    id={newId}
                    onChange={(e) => preTitle(e)}
                    name={Counter}
                    className={`${
                      value.type === "product" ? "dnone" : ""
                    } form-control`}
                    defaultValue={
                      value.titles[Counter] && value.titles[Counter].title
                    }
                  ></input>
                  <br />
                  <input
                    id={`B` + newId}
                    onChange={(e) => preTitleB(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleB[Counter] && value.titleB[Counter].title
                    }
                  />
                  <br />
                  <input
                    id={`C` + newId}
                    onChange={(e) => preTitleC(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleC[Counter] && value.titleC[Counter].title
                    }
                  />
                  <br />
                  <textarea
                    name={Counter}
                    className={`${
                      value.type === "product" ? "dnone" : ""
                    } form-control`}
                    onChange={(e) => preDisc(e)}
                    id={`A` + newId}
                  >
                    {value.discriptions[Counter] &&
                      value.discriptions[Counter].Disc}
                  </textarea>
                  <br />
                </div>
              );
            }
            Counter++;
          }
          if (type === "video") {
            let ide = "port" + Counter;
            let newId = "port" + Counter;
            if (Counter === 0) {
              temp.push(
                <div
                  id={`car${newId + props.id}`}
                  className="carousel-item  active"
                >
                  <button
                    data={ide}
                    type="button"
                    onClick={(e) => DeleteThisPro(e)}
                    className="btn-close btn-close-dark float-end"
                    aria-label="Close"
                  ></button>
                  <video className="carItem" controls>
                    <source src={file} />
                  </video>
                  <br />
                  <input
                    id={newId}
                    onChange={(e) => preTitle(e)}
                    name={Counter}
                    className={`${
                      value.type === "product" ? "d-one" : ""
                    } form-control`}
                    defaultValue={
                      value.titles[Counter] && value.titles[Counter].title
                    }
                  ></input>
                  <br />
                  <input
                    id={`B` + newId}
                    onChange={(e) => preTitleB(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleB[Counter] && value.titleB[Counter].title
                    }
                  />
                  <br />
                  <input
                    id={`C` + newId}
                    onChange={(e) => preTitleC(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleC[Counter] && value.titleC[Counter].title
                    }
                  />
                  <br />
                  <textarea
                    name={Counter}
                    className={`${
                      value.type === "product" ? "d-one" : ""
                    } form-control`}
                    onChange={(e) => preDisc(e)}
                    id={`A` + newId}
                  >
                    {value.discriptions[Counter] &&
                      value.discriptions[Counter].Disc}
                  </textarea>
                  <br />
                </div>
              );
            } else {
              temp.push(
                <div
                  id={`car${newId + props.id}`}
                  className="carousel-item    "
                >
                  <button
                    data={ide}
                    type="button"
                    onClick={(e) => DeleteThisPro(e)}
                    className="btn-close btn-close-dark float-end"
                    aria-label="Close"
                  ></button>
                  <video className="carItem" controls>
                    <source src={file} />
                  </video>
                  <br />
                  <input
                    id={newId}
                    onChange={(e) => preTitle(e)}
                    name={Counter}
                    className={`${
                      value.type === "product" ? "-none" : ""
                    } form-control`}
                    defaultValue={
                      value.titles[Counter] && value.titles[Counter].title
                    }
                  ></input>
                  <br />
                  <input
                    id={`B` + newId}
                    onChange={(e) => preTitleB(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleB[Counter] && value.titleB[Counter].title
                    }
                  />
                  <br />
                  <input
                    id={`C` + newId}
                    onChange={(e) => preTitleC(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleC[Counter] && value.titleC[Counter].title
                    }
                  />
                  <br />
                  <textarea
                    name={Counter}
                    className={`${
                      value.type === "product" ? "d-one" : ""
                    } form-control`}
                    onChange={(e) => preDisc(e)}
                    id={`A` + newId}
                  >
                    {value.discriptions[Counter] &&
                      value.discriptions[Counter].Disc}
                  </textarea>
                  <br />
                </div>
              );
            }
            Counter++;
          }
          if (type === "audio") {
            let ide = "port" + Counter;
            let newId = "port" + Counter;
            if (Counter === 0) {
              temp.push(
                <div
                  id={`car${newId + props.id}`}
                  className="carousel-item active"
                >
                  <button
                    data={ide}
                    type="button"
                    onClick={(e) => DeleteThisPro(e)}
                    className="btn-close btn-close-dark float-end"
                    aria-label="Close"
                  ></button>
                  <div
                    style={{
                      minHeight: "340px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <audio controls>
                      <source src={file} type="audio/ogg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                  <br />
                  <input
                    id={newId}
                    onChange={(e) => preTitle(e)}
                    name={Counter}
                    className={`${
                      value.type === "product" ? "d-one" : ""
                    } form-control`}
                    defaultValue={
                      value.titles[Counter] && value.titles[Counter].title
                    }
                  ></input>
                  <br />
                  <input
                    id={`B` + newId}
                    onChange={(e) => preTitleB(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleB[Counter] && value.titleB[Counter].title
                    }
                  />
                  <br />
                  <input
                    id={`C` + newId}
                    onChange={(e) => preTitleC(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleC[Counter] && value.titleC[Counter].title
                    }
                  />
                  <br />
                  <textarea
                    name={Counter}
                    className={`${
                      value.type === "product" ? "d-one" : ""
                    } form-control`}
                    onChange={(e) => preDisc(e)}
                    id={`A` + newId}
                  >
                    {value.discriptions[Counter] &&
                      value.discriptions[Counter].Disc}
                  </textarea>
                  <br />
                </div>
              );
            } else {
              temp.push(
                <div id={`car${newId + props.id}`} className="carousel-item   ">
                  <button
                    data={ide}
                    type="button"
                    onClick={(e) => DeleteThisPro(e)}
                    className="btn-close btn-close-dark float-end"
                    aria-label="Close"
                  ></button>
                  <div
                    style={{
                      minHeight: "340px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <audio controls>
                      <source src={file} type="audio/ogg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                  <br />
                  <input
                    id={newId}
                    onChange={(e) => preTitle(e)}
                    name={Counter}
                    className={`${
                      value.type === "product" ? "dnone" : ""
                    } form-control`}
                    defaultValue={
                      value.titles[Counter] && value.titles[Counter].title
                    }
                  ></input>
                  <br />
                  <input
                    id={`B` + newId}
                    onChange={(e) => preTitleB(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleB[Counter] && value.titleB[Counter].title
                    }
                  />
                  <br />
                  <input
                    id={`C` + newId}
                    onChange={(e) => preTitleC(e)}
                    name={Counter}
                    placeholder=""
                    className={`${
                      first === "Product" ? "Product" : ""
                    } form-control `}
                    defaultValue={
                      value.titleC[Counter] && value.titleC[Counter].title
                    }
                  />
                  <br />
                  <textarea
                    name={Counter}
                    className={`${
                      value.type === "product" ? "d-noe" : ""
                    } form-control`}
                    onChange={(e) => preDisc(e)}
                    id={`A` + newId}
                  >
                    {value.discriptions[Counter] &&
                      value.discriptions[Counter].Disc}
                  </textarea>
                  <br />
                </div>
              );
            }
            Counter++;
          }
        });
      setCount(Counter);
      arr = temp;
      if (rerender) {
        setshowMediaI(arr);
        setRerender(!rerender);
      } else {
        setshowMediaI(temp);
        setRerender(!rerender);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [TitleC, setTitleC] = useState([]);
  const [TitleB, setTitleB] = useState([]);
  const TitleHandlerB = (elem) => {
    let id = elem.target.id;
    let pos = id.substring(5);
    let ob = {
      position: pos,
      title: elem.target.value,
    };
    TitleB[pos] = ob;
  };
  const TitleHandlerC = (elem) => {
    let id = elem.target.id;
    let pos = id.substring(5);
    let ob = {
      position: pos,
      title: elem.target.value,
    };
    TitleC[pos] = ob;
  };

  const mediaHandlerT = (file, type) => {
    if (fil.length != Count) {
      fil[Count - 1] = null;
    }
    for (var i = 0; i < file.length; i++) {
      var sab = fil;
      sab.push(file[i]);
    }
    if (showMediaI.length > 0) {
      arr = showMediaI;
    }
    let CounterPro = 0;
    fil.forEach((esc) => {
      esc != null && CounterPro++;
    });
    var temp = arr;
    let Counter = Count;
    let acti = false;
    let d = document.getElementById(`innerProx${props.id}`).childElementCount;
    if (d === 0) {
      acti = true;
    }
    if (type === "img") {
      for (var i = 0; i < file.length; i++) {
        let newId = "port" + Counter;
        if (acti) {
          temp.push(
            <div id={`car${newId}`} className="carousel-item active ">
              <button
                data={newId}
                type="button"
                onClick={(e) => DeleteThis(e)}
                className="btn-close btn-close-dark float-end"
                aria-label="Close"
              ></button>
              <img className="carItem" src={URL.createObjectURL(file[i])}></img>
              <br />
              <br />
              <input
                id={newId}
                onChange={(e) => TitleHandler(e)}
                className="form-control"
              />
              <br />
              <input
                id={`B` + newId}
                onChange={(e) => TitleHandlerB(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />
              <input
                id={`C` + newId}
                onChange={(e) => TitleHandlerC(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />
              <textarea
                className="form-control"
                onChange={(e) => DiscHandler(e)}
                id={`A` + newId}
              ></textarea>
              <br />
            </div>
          );
        } else {
          temp.push(
            <div id={`car${newId}`} className="carousel-item   ">
              <button
                data={newId}
                type="button"
                onClick={(e) => DeleteThis(e)}
                className="btn-close btn-close-dark float-end"
                aria-label="Close"
              ></button>
              <img className="carItem" src={URL.createObjectURL(file[i])}></img>
              <br />
              <br />
              <input
                id={newId}
                onChange={(e) => TitleHandler(e)}
                className="form-control"
              />
              <br />
              <input
                id={`B` + newId}
                onChange={(e) => TitleHandlerB(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />
              <input
                id={`C` + newId}
                onChange={(e) => TitleHandlerC(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />

              <textarea
                className="form-control"
                onChange={(e) => DiscHandler(e)}
                id={`A` + newId}
              ></textarea>
              <br />
            </div>
          );
        }
        Counter++;
      }
    }
    if (type === "vid") {
      for (var i = 0; i < file.length; i++) {
        let newId = "port" + Counter;

        if (acti) {
          temp.push(
            <div id={`car${newId}`} className="carousel-item  active">
              <button
                data={newId}
                type="button"
                onClick={(e) => DeleteThis(e)}
                className="btn-close btn-close-dark float-end"
                aria-label="Close"
              ></button>
              <video className="carItem" controls>
                <source src={URL.createObjectURL(file[i])} />
              </video>
              <br />
              <input
                id={newId}
                onChange={(e) => TitleHandler(e)}
                className="form-control"
              />
              <br />
              <input
                id={`B` + newId}
                onChange={(e) => TitleHandlerB(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />
              <input
                id={`C` + newId}
                onChange={(e) => TitleHandlerC(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />

              <textarea
                className="form-control"
                onChange={(e) => DiscHandler(e)}
                id={`A` + newId}
              ></textarea>
              <br />
            </div>
          );
        } else {
          temp.push(
            <div id={`car${newId}`} className="carousel-item    ">
              <button
                data={newId}
                type="button"
                onClick={(e) => DeleteThis(e)}
                className="btn-close btn-close-dark float-end"
                aria-label="Close"
              ></button>
              <video className="carItem" controls>
                <source src={URL.createObjectURL(file[i])} />
              </video>
              <br />
              <input
                id={newId}
                onChange={(e) => TitleHandler(e)}
                className="form-control"
              />
              <br />
              <input
                id={`B` + newId}
                onChange={(e) => TitleHandlerB(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />
              <input
                id={`C` + newId}
                onChange={(e) => TitleHandlerC(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />

              <textarea
                className="form-control"
                onChange={(e) => DiscHandler(e)}
                id={`A` + newId}
              ></textarea>
              <br />
            </div>
          );
        }
        Counter++;
      }
    }
    if (type === "aud") {
      let newId = "port" + Counter;

      for (var i = 0; i < file.length; i++) {
        if (acti) {
          temp.push(
            <div id={`car${newId}`} className="carousel-item active">
              <button
                data={newId}
                type="button"
                onClick={(e) => DeleteThis(e)}
                className="btn-close btn-close-dark float-end"
                aria-label="Close"
              ></button>

              <div
                style={{
                  minHeight: "340px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <audio controls>
                  <source src={URL.createObjectURL(file[i])} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <br />
              <input
                id={newId}
                onChange={(e) => TitleHandler(e)}
                className="form-control"
              />
              <br />
              <input
                id={`B` + newId}
                onChange={(e) => TitleHandlerB(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />
              <input
                id={`C` + newId}
                onChange={(e) => TitleHandlerC(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />

              <textarea
                className="form-control"
                onChange={(e) => DiscHandler(e)}
                id={`A` + newId}
              ></textarea>
              <br />
            </div>
          );
        } else {
          temp.push(
            <div id={`car${newId}`} className="carousel-item   ">
              <button
                data={newId}
                type="button"
                onClick={(e) => DeleteThis(e)}
                className="btn-close btn-close-dark float-end"
                aria-label="Close"
              ></button>

              <div
                style={{
                  minHeight: "340px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <audio controls>
                  <source src={URL.createObjectURL(file[i])} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <br />
              <input
                id={newId}
                onChange={(e) => TitleHandler(e)}
                className="form-control"
              />
              <br />
              <input
                id={`B` + newId}
                onChange={(e) => TitleHandlerB(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />
              <input
                id={`C` + newId}
                onChange={(e) => TitleHandlerC(e)}
                placeholder=""
                className={`${
                  first === "Product" ? "Product" : ""
                } form-control `}
              />
              <br />

              <textarea
                className="form-control"
                onChange={(e) => DiscHandler(e)}
                id={`A` + newId}
              ></textarea>
              <br />
            </div>
          );
        }
        Counter++;
      }
    }
    setfil(sab);

    setCount(Counter);
    arr = temp;
    if (rerender) {
      setshowMediaI(arr);
      setRerender(!rerender);
    } else {
      setshowMediaI(temp);
      setRerender(!rerender);
    }
  };

  const [Stateload, setStateload] = useState(true);

  useEffect(() => {
    if (value.type)
      if (Stateload) {
        if (value.type === "project") {
          print_state(`State${props.index}`);
        }
        setStateload(false);
      }
  }, [value]);

  const [tri, settri] = useState(false);

  useEffect(() => {
    if (value.state)
      if (value.state.length >= 1) {
        if (!tri) {
          settri(true);
          var index = state_arr.indexOf(value.state);
          // var index = 2
          print_city(`City${props.index}`, index + 1);
          console.log(index);
        }
      }
    console.log(value);
  }, [value.state]);

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const Tr = () => {
    let c = [];
    let titles = [];
    let discs = [];
    let titleB = [];
    let titleC = [];
    fil.forEach((element, index) => {
      if (element) {
        titles = [...titles, Titles[index] ? Titles[index] : null];
        discs = [...discs, Disc[index] ? Disc[index] : null];
        titleB = [...discs, TitleB[index] ? TitleB[index] : null];
        titleC = [...discs, TitleC[index] ? TitleC[index] : null];
      }
    });
    let final = {
      titles,
      discs,
      titleB,
      titleC,
    };
    return final;
    let Tit = [];
    let Disk = [];

    for (let i = 0; i < Count; i++) {
      // console.log(i)
      Tit.push(document.getElementById("port" + i).value);
    }
    for (let i = 0; i < Count; i++) {
      // console.log(i)
      Disk.push(document.getElementById("Aport" + i).value);
    }
    let lit = {
      Tit,
      Disk,
    };
    console.log(Tit);
    return lit;
  };
  const titleCorrection = (titles) => {
    let tick = [];
    try {
      let prev = null;
      titles.forEach((element, pos) => {
        let dum = {
          position: pos,
          title: "",
        };
        if (element.position != pos) {
          let p;
          if (prev) {
            p = element.position - (prev.position - 1);
          } else {
            p = pos;
          }
          for (let i = 0; i < p; i++) {
            let dummy = {
              position: pos + i,
              title: "",
            };
            tick = [...tick, dummy];
          }
          tick = [...tick, element];
        } else {
          tick = [...tick, element];
        }
        prev = element;
      });
      return tick;
    } catch (err) {
      console.log(err);
    }
  };
  const DiscCorrection = (titles) => {
    let tick = [];
    try {
      let prev = null;
      titles.forEach((element, pos) => {
        let dum = {
          position: pos,
          Disc: "",
        };
        if (element.position != pos) {
          let p;
          if (prev) {
            p = element.position - (prev.position - 1);
          } else {
            p = pos;
          }
          for (let i = 1; i < p - 2; i++) {
            let dummy = {
              position: pos + i,
              Disc: "",
            };
            tick = [...tick, dummy];
          }
          tick = [...tick, element];
        } else {
          tick = [...tick, element];
        }
        prev = element;
      });
      return tick;
    } catch (err) {
      console.log(err);
    }
  };
  const dommerdup = () => {
    let len = value.File.length;
    let titles = [];
    if (len) {
      for (let i = 0; i < len; i++) {
        let position = i;
        let title;
        if (newVal[i] || newVal[i] === "") {
          title = newVal[i];
        } else {
          title = value.titles[i] ? value.titles[i].title : "";
        }
        let dt = {
          position,
          title,
        };
        titles = [...titles, dt];
        // console.log(dt)
      }
    }
    let len2 = value.File.length;
    let discriptions = [];
    if (len2) {
      for (let i = 0; i < len2; i++) {
        let position = i;
        let Disc;
        if (newDiscVal[i] || newDiscVal[i] === "") {
          Disc = newDiscVal[i];
        } else {
          Disc = value.discriptions[i] ? value.discriptions[i].Disc : "";
        }
        let dt = {
          position,
          Disc,
        };
        discriptions = [...discriptions, dt];
      }
    }
    let len3 = value.File.length;
    let titleB = [];
    if (len3) {
      for (let i = 0; i < len3; i++) {
        let position = i;
        let title;
        if (newTitleB[i] || newTitleB[i] === "") {
          title = newTitleB[i];
        } else {
          title = value.titleB[i] ? value.titleB[i].title : "";
        }
        let dt = {
          position,
          title,
        };
        titleB = [...titleB, dt];
      }
    }
    let len4 = value.File.length;
    let titleC = [];
    if (len4) {
      for (let i = 0; i < len4; i++) {
        let position = i;
        let title;
        if (newTitleC[i] || newTitleC[i] === "") {
          title = newTitleC[i];
        } else {
          title = value.titleC[i] ? value.titleC[i].title : "";
        }
        let dt = {
          position,
          title,
        };
        titleC = [...titleC, dt];
      }
    }

    let f = Tr();
    titles = [...titles, ...f.titles];
    discriptions = [...discriptions, ...f.discs];
    titleB = [...titleB, ...f.titleB];
    titleC = [...titleC, ...f.titleC];
    // let titleCorrections = titleCorrection(titles)
    // let DiscCorrections = DiscCorrection(discriptions)

    let newTitles = value.titlesNew;
    let oldTitles = value.titles;

    // if (newTitles.length) {
    //     newTitles.forEach((elem) => {
    //         console.log(elem.position)
    //         let Rpos = oldTitles.find(o => o.position === elem.position);
    //         console.log(Rpos)
    //         oldTitles[Rpos] = elem
    //         console.log(Rpos)
    //         console.log(elem)
    //         console.log(value)
    //     })
    // }

    // return
    let c = document.getElementById(`closer9655${props.index}`);
    let TitlesToDb = Tr();
    c.setAttribute("disabled", true);
    var status = false;
    status = true; // validation();
    if (status == true) {
      formData.delete("data");
      formData.delete("file");

      fil.forEach((element) => {
        if (element) {
          formData.append("file", element);
        }
      });
      var sab = {
        value,
        titles,
        discriptions,
        old,
        titleB,
        titleC,
      };
      var das = JSON.stringify(sab);
      formData.append("data", das);

      // console.log(content)
      // console.log(tittle)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor(((loaded / 1000) * 100) / (total / 1000));
          // move(percent)/
          if (percent < 100) {
            setProgress(percent);
          }
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .put("/api/Free/updateportfolioT", formData, options)
        .then((res) => {
          if (res.status === 200) {
            setProgress(100);
            let ids = res.data;
            // props.setPortFolios([...props.PortFolios, ids])

            // props.activate("Portfolio-tab")
            notify("Portfolio Added ", true);
          } else {
            notify("something went wrong ", false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    c.removeAttribute("disabled");
    c.click();
  };
  const next = () => document.getElementById(`next${props.id}`).click();

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city(`City${props.index}`, index);
    setValue({ ...value, state: e.target.value });
  }

  const [psy, setPsy] = useState(false);
  useEffect(() => {
    if (psy) {
      validation();
    }
  }, [value]);
  const [ProductDiscriptions, setProductDiscriptions] = useState([keyGen()]);
  const [ProjectDiscription, setProjectDiscription] = useState([keyGen()]);
  const HandlerProductDiscription = (e, index, boo) => {
    let pd = [...value.Product_Discriptions];
    if (boo) {
      pd[index] = e;
    } else {
      pd.splice(index, 1);
      let pds = [...ProductDiscriptions];
      pds.splice(index, 1);
      setProductDiscriptions(pds);
    }
    setValue({ ...value, Product_Discriptions: pd });
  };
  const ProjectDiscriptionsHandler = (e, index, boo) => {
    let pd = [...value.Project_Discriptions];
    if (boo) {
      pd[index] = e;
    } else {
      let pds = [...ProjectDiscription];
      pd.splice(index, 1);
      pds.splice(index, 1);
      setProductDiscriptions(pds);
    }
    setValue({ ...value, Project_Discriptions: pd });
  };

  try {
    return (
      <div style={{ cursor: "pointer" }} className="border mb-1 PortTitle p-2">
        <div className="row">
          <div
            data-bs-toggle="modal"
            data-bs-target={`#Post11rtyrty${props.index}`}
            className=" texteclipsforsab col-10"
          >
            {props.index}.{" "}
            {data ? (
              "Catalogue"
            ) : (
              <div className="text-center">
                <div className="spinner-border" role="status"></div>
              </div>
            )}
          </div>
          <div className="col-2">
            <button
              type="button"
              className="btn-close btn-close-dark float-end  justify-content-end"
              onClick={(e) => props.Remove(props.id)}
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div
          className="modal fade podamonea"
          data-bs-backdrop="static"
          id={`Post11rtyrty${props.index}`}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl  modal-dialog-scrollable ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {props.type ? "CATALOGUE" : "PORTFOLIO"}
                </h5>
                {/* <button type="button" onClick={(e) => check()}>check</button> */}
                <button
                  type="button"
                  id={`closer9655${props.index}`}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {/*  */}
              <div className="modal-body">
                {props.type ? (
                  <></>
                ) : (
                  <Form className="mb-3 d-flex justify-content-center">
                    <>
                      <Form.Check
                        label="COMPLETED PROJECTS"
                        className="me-3"
                        checked={value.project_type_radio === "completed"}
                        onChange={() =>
                          setValue({
                            ...value,
                            project_type_radio: "completed",
                          })
                        }
                        name="group1"
                        type="radio"
                        id={`inline-radio-1`}
                      />
                      <Form.Check
                        label="ON-GOING PROJECTS"
                        checked={value.project_type_radio === "on-going"}
                        onChange={() =>
                          setValue({ ...value, project_type_radio: "on-going" })
                        }
                        name="group1"
                        type="radio"
                        id={`inline-radio-2`}
                      />
                    </>
                  </Form>
                )}
                <div className="mb-3">
                  <label className="ms-1 ">
                    {props.type ? "Catalogue" : "Portfolio"} Title *
                  </label>

                  <input
                    className="eihfioehfoieh  w-100 form-control "
                    value={value.catalouge_title}
                    onChange={(e) =>
                      setValue({ ...value, catalouge_title: e.target.value })
                    }
                  />
                </div>
                <label className="ms-1">
                  {value.type === "product"
                    ? "Product Photos / Videos *"
                    : "Project Photos / Videos *"}
                </label>
                <div
                  id={`carouselExampleIndicators${props.id}`}
                  className="carousel slide"
                  data-bs-interval="false"
                >
                  {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div> */}
                  <div id={`innerProx${props.id}`} className="carousel-inner">
                    {showMediaI ? showMediaI : "no data"}
                  </div>
                  {showMediaI.length > 0 ? (
                    <>
                      <button
                        className="carousel-control-prev ekgdfukweguobwljbvuwgcuonewgg"
                        type="button"
                        data-bs-target={`#carouselExampleIndicators${props.id}`}
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        id={`next${props.id}`}
                        className="carousel-control-next ekgdfukweguobwljbvuwgcuonewgg"
                        type="button"
                        data-bs-target={`#carouselExampleIndicators${props.id}`}
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="ps-2">
                  <FaPhotoVideo
                    size="25"
                    onClick={() => Clickhandler("imagexr")}
                    style={{ color: "var(--blue)" }}
                  ></FaPhotoVideo>
                  &nbsp;&nbsp;
                  <FaVideo
                    style={{ color: "var(--blue)" }}
                    onClick={() => Clickhandler("videoxr")}
                    size="25"
                  />
                  {/* &nbsp;&nbsp;<AiFillAudio style={{ 'color': 'var(--blue)' }} onClick={() => Clickhandler('audioxr')} size="25" /> */}
                </div>

                <input
                  id={`imagexr${props.id}`}
                  type="file"
                  className="d-none"
                  name="Media"
                  onChange={(e) => mediaHandlerT(e.target.files, "img")}
                  accept="image/*"
                />
                <input
                  id={`videoxr${props.id}`}
                  type="file"
                  className="d-none"
                  name="Media"
                  onChange={(e) => mediaHandlerT(e.target.files, "vid")}
                  accept="video/*"
                />
                {/* <input id={`audioxr${props.id}`} type="file" className="d-none" name="Media" onChange={(e) => mediaHandlerT(e.target.files, 'aud')} multiple accept="audio/*" /> */}

                {Image ? (
                  <div className="container postMediacontainer">
                    {" "}
                    <img
                      className="luide d-flex"
                      src={URL.createObjectURL(Image)}
                    />{" "}
                  </div>
                ) : (
                  ""
                )}
                {Video ? (
                  <div className="container postMediacontainer">
                    <video className="luide d-flex" controls>
                      <source
                        src={URL.createObjectURL(Video)}
                        height="100%"
                        width="100%"
                      />
                    </video>{" "}
                  </div>
                ) : (
                  ""
                )}
                <>
                  {value.type === "product" ? (
                    <div id="caet2" className="d-">
                      {/* first */}
                      <>
                        <label className="ms-1">Product Name</label>
                        <input
                          className="eihfioehfoieh  w-100 form-control "
                          value={value.product_name}
                          onChange={(e) =>
                            setValue({ ...value, product_name: e.target.value })
                          }
                          placeholder={""}
                        />
                        <hr />
                        <label className="ms-1">Product Brand</label>
                        <input
                          className="eihfioehfoieh  w-100 form-control "
                          value={value.brand}
                          onChange={(e) =>
                            setValue({ ...value, brand: e.target.value })
                          }
                          placeholder={""}
                        />
                        <hr />
                        <label className="ms-1">Product Description </label>
                        <textarea
                          className="postInputx eihfioehfoieh ps-2 form-control"
                          value={value.product}
                          onChange={(e) =>
                            setValue({
                              ...value,
                              product_discription: e.target.value,
                            })
                          }
                          placeholder="Mention material specification"
                        ></textarea>
                        <hr />

                        {ProductDiscriptions.map((elem, index) => {
                          return (
                            <ProductDiscription
                              key={elem}
                              edit={true}
                              value={value.Product_Discriptions}
                              index={index}
                              Handler={HandlerProductDiscription}
                            />
                          );
                        })}
                        {/* /////////////////////////////////////// */}
                        <p className="mb-3">
                          To Add more Variants, click on{" "}
                          <button
                            onClick={(e) =>
                              setProductDiscriptions([
                                ...ProductDiscriptions,
                                keyGen(),
                              ])
                            }
                            className="btn pro-spaces-button3"
                          >
                            ADD VARIANT
                          </button>
                        </p>

                        {/* <input id="image" type="file" className="d-none" name="Media" onChange={(e) => setImage(e.target.files[0])} multiple accept="image/*" />
              <input id="video" type="file" className="d-none" name="Media" onChange={(e) => setVideo(e.target.files[0])} multiple accept="video/*" /> */}
                        <hr />
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                  {value.type === "project" ? (
                    <div id="caet1" className="d-one">
                      <>
                        <label className="ms-1">Project Name *</label>
                        <input
                          className="eihfioehfoieh  w-100 form-control "
                          value={value.project_name}
                          placeholder={""}
                          onChange={(e) =>
                            setValue({ ...value, project_name: e.target.value })
                          }
                        />
                        <label className="ms-1">Project Location *</label>

                        <div className="ms-1">
                          <div className="col-md-12">
                            <label className="labels">Country *</label>
                            <select
                              ref={countryRef}
                              className="form-select"
                              value={value.country}
                              onChange={(e) =>
                                setValue({ ...value, country: e.target.value })
                              }
                              name="City"
                            ></select>
                          </div>
                          <div className="col-md-12">
                            <label className="labels">State / Province *</label>
                            <select
                              ref={stateRef}
                              className="form-select"
                              value={value.state}
                              name="City"
                              onChange={(e) =>
                                setValue({ ...value, state: e.target.value })
                              }
                            ></select>
                          </div>
                          <div className="col-md-12">
                            <label className="labels">City *</label>
                            <select
                              ref={cityRef}
                              className="form-select"
                              name={"City"}
                              value={value.city}
                              onChange={(e) =>
                                setValue({ ...value, city: e.target.value })
                              }
                            >
                              <option></option>
                            </select>
                          </div>
                        </div>
                        <label className="labels ms-1">Locality *</label>
                        <textarea
                          className="postInputx eihfioehfoieh ps-2 form-control"
                          value={value.project_locality}
                          onChange={(e) =>
                            setValue({
                              ...value,
                              project_locality: e.target.value,
                            })
                          }
                        ></textarea>
                        {value.project_type_radio === "on-going" && (
                          <>
                            <hr />

                            <label className="ms-1">
                              Total Number of Units *
                            </label>

                            <div className="trysizeplace">
                              <input
                                type="number"
                                className="eihfioehfoieh   w-100 form-control "
                                value={value.number_of_units}
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    number_of_units: e.target.value,
                                  })
                                }
                                placeholder="Mention how many units are there in the entire project"
                              />
                            </div>
                            <hr />
                            <label className="ms-1">
                              Total Number of Floors *{" "}
                            </label>

                            <div className="trysizeplace">
                              <input
                                type="number"
                                className="eihfioehfoieh  w-100 form-control "
                                value={value.number_of_floor}
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    number_of_floor: e.target.value,
                                  })
                                }
                                placeholder="Mention how many floors are there"
                              />
                            </div>
                            <hr />
                            {ProjectDiscription.map((elem, index) => {
                              return (
                                <ProjectDiscriptions
                                  Handler={ProjectDiscriptionsHandler}
                                  value={value.Project_Discriptions}
                                  index={index}
                                  key={elem}
                                />
                              );
                            })}

                            <p className="mb-3">
                              To Add more Variants, click on{" "}
                              <button
                                onClick={(e) =>
                                  setProjectDiscription([
                                    ...ProjectDiscription,
                                    keyGen(),
                                  ])
                                }
                                className="btn pro-spaces-button3"
                              >
                                ADD VARIANT
                              </button>
                            </p>

                            <label className="ms-1">Project Description</label>
                            <input
                              className="eihfioehfoieh  w-100 form-control "
                              value={value.project_discription}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  project_discription: e.target.value,
                                })
                              }
                            />
                            <hr />

                            <label className="ms-1">
                              Project Highlights / USP *
                            </label>
                            <div className="trysizeplace">
                              <input
                                className="eihfioehfoieh  w-100 form-control "
                                value={value.project_highlights}
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    project_highlights: e.target.value,
                                  })
                                }
                                placeholder=" Mention amenities / facilities available in the project"
                              />
                            </div>
                            <hr />

                            <label className="ms-1">Project Status *</label>
                            <select
                              className="form-select eihfioehfoieh"
                              aria-label="Default select example"
                              value={value.project_status}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  project_status: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled selected></option>
                              <option value="Yet to Commence">
                                Yet to Commence{" "}
                              </option>
                              <option value="On-Going">On-Going </option>
                              <option value="Completed">Completed </option>
                            </select>
                            <hr />
                            <label className="ms-1">RERA Compliant *</label>
                            <select
                              className="form-select eihfioehfoieh"
                              aria-label="Default select example"
                              value={value.project_status}
                              onChange={(e) =>
                                setValue({ ...value, rera: e.target.value })
                              }
                            >
                              <option value="" disabled selected></option>
                              <option value="Yes">Yes </option>
                              <option value="No">No </option>
                            </select>
                            <hr />
                            <label className="ms-1">Vaastu Compliant *</label>
                            <select
                              className="form-select eihfioehfoieh"
                              aria-label="Default select example"
                              value={value.vasstu}
                              onChange={(e) =>
                                setValue({ ...value, vasstu: e.target.value })
                              }
                            >
                              <option value="" disabled selected></option>
                              <option value="Yes">Yes </option>
                              <option value="No">No </option>
                              <option value="Partially">Partially </option>
                            </select>
                            <hr />

                            <label className="ms-1">
                              Occupancy Availability *
                            </label>
                            <div className="trysizeplace">
                              <input
                                className="eihfioehfoieh  w-100 form-control "
                                value={value.occupancy_avalibility}
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    occupancy_avalibility: e.target.value,
                                  })
                                }
                                placeholder="Mention whether it is Ready-to-move-in / how many days or months or years to complete"
                              />
                            </div>
                            <hr />
                            <label className="ms-1">
                              Occupancy Certificate *
                            </label>
                            <select
                              className="form-select eihfioehfoieh"
                              value={value.occupancy_certificate}
                              aria-label="Default select example"
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  occupancy_certificate: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled selected></option>
                              <option value="Yes">Yes</option>
                              <option value="Not yet">Not yet </option>
                            </select>
                            <hr />

                            <label className="ms-1">
                              Association Formation *
                            </label>
                            <select
                              className="form-select eihfioehfoieh"
                              value={value.association}
                              aria-label="Default select example"
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  association: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled selected></option>
                              <option value="Yes">Yes </option>
                              <option value="Not yet">Not yet </option>
                            </select>
                            <hr />
                            <label className="ms-1">
                              Rate per SQFT / SQCM on Built-up Area *
                            </label>
                            <input
                              type="number"
                              className="eihfioehfoieh  w-100 form-control "
                              value={value.rate}
                              onChange={(e) =>
                                setValue({ ...value, rate: e.target.value })
                              }
                            />
                            <hr />
                            <label className="ms-1">
                              Offers{" "}
                              <span style={{ fontWeight: "400" }}>
                                (if any)
                              </span>
                            </label>
                            <input
                              className="eihfioehfoieh  w-100 form-control "
                              value={value.offers}
                              onChange={(e) =>
                                setValue({ ...value, offers: e.target.value })
                              }
                            />
                            <hr />
                          </>
                        )}

                        {/* <input id="image" type="file" className="d-none" name="Media" onChange={(e) => setImage(e.target.files[0])} multiple accept="image/*" />
              <input id="video" type="file" className="d-none" name="Media" onChange={(e) => setVideo(e.target.files[0])} multiple accept="video/*" /> */}
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                </>

                {/* <ProgressBar Progress={Progress} halt={halt} d_color='red' f_color='yellow' color='white' bgcolor='dark' /> */}
                <ProgressBar
                  variant="SOME_NAME"
                  now={Progress}
                  label={`${Progress}%`}
                />
              </div>
              {/*  */}
              <div className="modal-footer">
                <div className="">
                  <div>
                    <h4 style={{ color: "red" }}>{error ? error : ""}</h4>
                  </div>
                  <button
                    type="button"
                    className="btn pro-spaces-button me-2 ps-4 pe-4"
                    onClick={(e) =>
                      props.Remove2(props.id, `closer9655${props.index}`)
                    }
                  >
                    delete
                  </button>
                  <button
                    type="button"
                    className="btn pro-spaces-button3"
                    onClick={(e) => dommerdup(e)}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  } catch (err) {
    console.log(err);
    return (
      <div>
        Loading
        {/* <button type='button' onClick={(e) => doRender()} >Retry</button> */}
      </div>
    );
  }
};
