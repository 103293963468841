import React, { useEffect, useState } from "react";
// import {Post} from "../../../Components/Post/Post"
// import { Post } from "../../../Components/Post2/repost"
// import Axios from 'axios'
import './Home.css'
// import { PosttoolBar } from "../../../Components/PostToolBar/PostToolBar";
// import {Notification} from "../../../Components/Notifications/Notification"
import { Lobby } from "../../../Components/Lobby/Lobby";

let page = 0
let fetchreq = false
export const Home = () => {

    // const [data, setdata] = useState([])
    // const [state, setstate] = useState(false)//ini
    // const [load, setload] = useState(true)
    // const [error, seterror] = useState('')
    // const [output, setoutput] = useState([])
    // const [first, setfirst] = useState(1)
    // function makeid(length) {
    //     length = 5
    //     var result = '';
    //     var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    //     var charactersLength = characters.length;
    //     for (var i = 0; i < length; i++) {
    //         result += characters.charAt(Math.floor(Math.random() *
    //             charactersLength));
    //     }
    //     return result;
    // }
    // useEffect(() => {
    //     FetchMessage()
    // }, [])

    // const [FetchStatus, setFetchStatus] = useState(false)
    // const FetchMessage = (last) => {
    //     // console.log(page)
    //     Axios.post("/api/User/getpost", { first }).then((response) => {
    //         setload(false)

    //         if (response.data.length > 0) {
    //             setfirst(first + response.data.length)
    //         }
    //         // console.log('success')
    //         // console.log(response.data)
    //         if (response.data.length === 0) {
    //             fetchreq = false
    //             return console.log('done')
    //         }
    //         const postSummaries = response.data.map((post) => ({ id: post._id, key: post.id, Post_Id: post.Post_Id, Content: post.Content, Author: post.Account_id, File: post.File, Posted_On: post.Posted_On, Commentcount: post.commentcount, merge: post.string, merge2: post.string2, com: post.com, Likecount: post.likecount, Likestatus: post.likestatus, profilepic: post.profilepic }));
    //         let t = postSummaries.map((number) => {
    //             return <Post key={number.id} ids={makeid()} dat={number}></Post>
    //         })
    //         fetchreq = false
    //         setload(false)
    //         setoutput([...output, ...t])
    //     }).catch(
    //         (res) => {
    //             fetchreq = false
    //             setload(false)
    //             setdata(false)
    //             seterror('Something went wrong')
    //             console.log('Something went wrong')
    //             setFetchStatus(false);
    //         }
    //     )
    //     setstate(true)
    // }


    // useEffect(() => {
    //     // console.log(first)
    // }, [first])

    // async function ScrollListener(e) {
      
    //     console.log('lllllllllllll')
    //     let P = Math.abs(e.target.scrollTop * 100 / (e.target.scrollHeight - e.target.clientHeight));
    //     P = Math.round(P);
    //     console.log(P)
    //     console.log(fetchreq)
    //     if (P > 50) {
    //         if (!fetchreq) {
    //             fetchreq = true
    //             console.log(page)
    //             FetchMessage();
    //             // setoutpot([...outpot, ...ans]);
    //         }
    //     }
    // }



    // Loadpost()
    // var loader = {
    //     width: '5rem',
    //     height: '5rem'
    // }
    return (

        <div className="container-fluid ">
            {/* <div className="row">
                <div id='postFt' onScroll={(e) => ScrollListener(e)} className="dfrty  pb-0 pt-0 pt-5 mt-1 col-lg-8 col-12 ijfsvwijiviwrvnvnjw"> 

               
               
                <div className="pt-3"></div>

                <div className="container-fluid p-0 mt-1">
                    <div className="row p-3 d-flex justify-content-center">
                       
                        <div className="col-12 ms-0 ps-0">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#Post" className="btn dknfksndakf toolbarbtn w-100 p-0 "  >Choice of information</button>

                        </div>
                        <div className="col-12 ms-0 ps-0">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#Post" className="btn dknfksndakf toolbarbtn w-100 p-0 "  >Zone of information</button>

                        </div>
                        <div className="col-12 me-0 pe-0">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#Post" className="btn dknfksndakf toolbarbtn w-100 p-0" >period of information</button>

                        </div>

                           
                    </div>
                </div>

                  
                    {output ? output : ''}

                    {load ? <div className="text-center "> <div style={loader} className="spinner-border text-dark"></div></div> : output.length === 0 && <center className='mt-5'>No PRO- post to show !</center>}

                </div>
                <div className="col-4 d-none d-lg-block "> <Ads /></div>
            </div> */}

<Lobby />
        </div>

    )

}
