import React, { useState, useEffect, useRef, useReducer, createContext, useContext } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import './CreateProduct.css'
import "cropperjs/dist/cropper.css";
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { FormProduct } from './FormProduct'
export function ConvertPro() {
    console.log('v2 convert pto')
    let navigate = useNavigate()
    const submit = (formData) => {
        return new Promise((resolve, reject) => {
            axios.post('/api/ActiveUser/updatetoproductV3', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                if (res.status == 200) {
                    console.log('product Created')
                    // console.log(res.data)
                    navigate('/')
                    window.location.reload();
                }
                resolve(true)
            }).catch((err) => {
                resolve(false)
                return navigate('/')
            })
        }) 
    }
    return (
        <>
            <div className="container rounded bg-white pt-5 pb-5">
                <FormProduct postTo={submit} sab={true} />
            </div>
        </>
    )
}
