import CryptoJS from 'crypto-js'
class Masks {

    #encoder(message) {
        try {
           return CryptoJS.AES.encrypt(message, ' ').toString().replace(/[&]/g, '_and_')
        } catch (error) {
            console.log(error)
        }
    }
    #decoder(message) {
        try {
            message = message.replace(/\s/g, '+')
            message = message.replace(/['"]+/g, '')
            return CryptoJS.AES.decrypt(message, ' ').toString(CryptoJS.enc.Utf8)
        } catch (error) {
            console.log(error)
        }
    }
    encode(message) {
        return this.#encoder(message)
    }
    decode(message) {
        return this.#decoder(message)
    }
}

const toURL = (elem) => {
    let f = elem.replace(/\s/g, '+')
    return f.replace(/[&]/g, '_and_')
}
const toData = (elem) => {
    let f = elem.replace(/[+]/g, ' ')
    return f.replace(/_and_/g, '&')
}

export const Mask = new Masks()