import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const QualificationsandEnrolmentInformation = ({ getval }) => {
  const {
    formType,
    chif,
    skills,
    univesity,
    yearofpassing,
    qualification,
    foreigntrained,
    forginuniversity,
    forgincontry,
    additionaldegree,
    Profile,
  } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "qualificationaccodian"}
      onChange={handleChange("qualificationaccodian")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "100%", flexShrink: 0 }}>
          <span>
            <b> Qualifications and Enrolment Information&nbsp;</b>
            {formType === 1 || formType === 2 ? (
              <>
                <br />
                (of any one Owner / Consultant)
              </>
            ) : formType === 3 || formType === 4 ? (
              <>(of any one Owner)</>
            ) : formType === 5 ? (
              <>
                (of the {Profile === "Associated" ? "" : "Chief "}Doctor /
                Consultant)
              </>
            ) : (
              <></>
            )}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {qualification ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Highest Qualification</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {qualification ? qualification : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {yearofpassing ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Year of Obtaining highest qualification</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {yearofpassing ? yearofpassing : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {univesity ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>University / Institution</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {" "}
                  {univesity ? univesity : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {formType === 1 || formType === 2 ? (
            skills ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Technical Skills</span>
                </div>
                <div className="col-md-6 ">
                  <label
                    style={{ color: "var(--blue)", whiteSpace: "pre-line" }}
                  >
                    {skills ? skills : "Nil"}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {formType === 5 ? (
            additionaldegree ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Additional Degrees / Fellowships</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{ color: "var(--blue)" }}>
                    {" "}
                    {additionaldegree ? additionaldegree : ""}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {formType === 5 ? (
            foreigntrained === "Yes" ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Foreign trained</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{ color: "var(--blue)" }}>
                    {" "}
                    {forginuniversity
                      ? "Yes, from " + forginuniversity + ", "
                      : "Yes"}
                    {forgincontry ? forgincontry : ""}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {formType === 1 || formType === 2 || formType === 5 ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Statutory body Registration details</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {chif ? chif : "N/A"}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
