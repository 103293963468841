import React, { useEffect, useRef, useState } from 'react'
import { ExistingData, InitializeCountry } from "../../../Utility/Country";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Collapse } from 'react-bootstrap';
import { BiReset } from 'react-icons/bi';

export const Information_Search4 = ({setValue,value,dva,Reset}) => {
    const [open, setOpen] = useState(false);
    let countryRef = useRef()
    let stateRef = useRef()
    let cityRef = useRef()
    useEffect(() => {

        InitializeCountry(countryRef, stateRef, cityRef)
        ExistingData(countryRef, stateRef, cityRef, value.country, value.state, value.city)
        return () => {

        }
    }, [])
  return (
    <div>
              <div className="mb-2 me-2">
        {open ? (
          <button
            className="btn pro-spaces-button-semi btn-rounded ps-3 pe-3 mt-1 mb-2"
            onClick={() => setOpen(!open)}
            aria-controls="AdvancedSearch"
            aria-expanded={open}
          >
            Advanced Search Filters&nbsp;
            <MdKeyboardArrowUp />
          </button>
        ) : (
          <button
            className="btn pro-spaces-button3 btn-rounded ps-3 pe-3 mt-1 mb-2"
            onClick={() => setOpen(!open)}
            aria-controls="AdvancedSearch"
            aria-expanded={open}
          >
            Advanced Search Filters&nbsp;
            <MdKeyboardArrowDown />
          </button>
        )}

        <Collapse in={open} className="ms-1 mb-3">
          <div id="AdvancedSearch" className=" row bg-light  p-2 ">
            <div className="px-2 ms-1">
            </div>
            <form id="searchForm">
              <div className="col-12 ">
                <div className="row g-1">
                <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        name="Name"
                        value={value.title}
                        id="Name"
                        onChange={(e) =>
                          setValue({
                            ...value,
                            title: e.target.value,
                          })
                        }
                      ></input>
                      <label htmlFor="Name">
                        Name
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <select
                        id="hihish"
                        value={value.country}
                        ref={countryRef}
                        className="form-select"
                        onChange={(e) =>
                          setValue({
                            ...value,
                            country: e.target.value,
                            state: "",
                            city: "",
                          })
                        }
                      ></select>
                      <label htmlFor="Country">Country</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <select
                        ref={stateRef}
                        className="form-select"
                        value={value.state}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            state: e.target.value,
                            city: "",
                          })
                        }
                      ></select>
                      <label htmlFor="State">State / Province</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <select
                        value={value.city}
                        className="form-select"
                        onChange={(e) =>
                          setValue({ ...value, city: e.target.value })
                        }
                        ref={cityRef}
                      ></select>
                      <label htmlFor="City">City</label>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-12 col-md-12 mb-1 mt-2">
                <div className=" d-flex justify-content-end">
                  <div className="   me-2 ">
                    <button
                      className="btn btnjui2 ps-4 pe-4 d-flex justify-content-center"
                      type="button"
                      onClick={(e) => Reset(e)}
                    >
                      <BiReset size={24} />
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btnjui "
                    onClick={(e) => dva()}
                  >
                    Go
                  </button>
                </div>

                {/* <span style={{ color: "red" }}>{error ? error : ""}</span> */}
              </div>
            </form>
          </div>
        </Collapse>
      </div>
    </div>
  )
}
