import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const WorkServiceRelatedInformation = ({ getval }) => {
  const {
    formType,
    TypeofConsultation,
    serviceprovided,
    specialization,
    experience,
    yearofb,
    currentn_work,
    previousorganization,
    subspecialization,
    Profile,
    email,
    mobilenumber2,
    DSI,
  } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var name = "";
  if (formType === 1 || formType === 2) {
    name = "Chief Consultant / Proprietor";
  } else if (formType === 3 || formType === 4) {
    name = "Service Provider / Proprietor";
  } else if (formType === 5) {
    name = "Chief Doctor / Consultant";
  }

  var sab;
  if (experience > 1) {
    sab = "years";
  } else {
    sab = "year";
  }

  var name2 = "";
  if (Profile === "Company") {
    name2 = "Facility / ";
  } else {
    name2 = "";
  }

  return (
    <Accordion
      expanded={expanded === "WorkServiceRelatedInformation"}
      onChange={handleChange("WorkServiceRelatedInformation")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "100%", flexShrink: 0 }}>
          <span>
            <b>
              {" "}
              {Profile === "Company" ? "Facility" : "Work"} / Service-related
              Information
            </b>
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {formType === 1 || (formType === 2 && TypeofConsultation) ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Type of Consultation</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {" "}
                  {TypeofConsultation
                    ? TypeofConsultation == "both"
                      ? "Online and Offline"
                      : getval.TypeofConsultation == "online"
                      ? "Online"
                      : "Offline"
                    : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {experience ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Total Work Experience (of the {name})</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {experience ? experience : "Nil"} {sab}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {formType === 1 ||
          formType === 2 ||
          (formType === 5 && Profile != "Associated") ? (
            yearofb ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Practicing Independently since (Chief {name})</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{ color: "var(--blue)" }}>
                    {yearofb ? yearofb : ""}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {formType != 5 ? (
            currentn_work ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>
                    Organization / Institution currently associated with
                  </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{ color: "var(--blue)" }}>
                    {currentn_work ? currentn_work : ""}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {formType === 1 ||
          formType === 2 ||
          (formType === 5 && Profile != "Associated") ? (
            previousorganization ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Prior Work Experience (of the Chief {name})</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{ color: "var(--blue)" }}>
                    {previousorganization ? previousorganization : "Nil"}{" "}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {specialization ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Specialization</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)", whiteSpace: "pre-line" }}>
                  {specialization ? specialization : "Nil"}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {formType === 5 ? (
            subspecialization ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Sub Specializations</span>
                </div>
                <div className="col-md-6 ">
                  <label
                    style={{ color: "var(--blue)", whiteSpace: "pre-line" }}
                  >
                    {subspecialization ? subspecialization : "Nil"}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {serviceprovided ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>
                  {Profile === "Company" ? "Facility / " : ""}Services Provided
                </span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)", whiteSpace: "pre-line" }}>
                  {serviceprovided ? serviceprovided : "Nil"}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* {formType===5?Profile!='Associated'?yearofb?
          <div className="row py-2">
            <div className="col-md-6">
              <span>Practicing Independently since (Chief {name})</span>
            </div>
            <div className="col-md-6 ">
              <label style={{'color':'var(--blue)'}}>{yearofb?yearofb:''}</label>
            </div>
          </div>:<></>:<></>:<></>} */}

          {DSI ? (
            DSI?.length > 0 ? (
              DSI.map((elem) => {
                return (
                  <>
                    {elem.serviceTitle && (
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>{name2}Service Title</span>
                        </div>
                        <div className="col-md-6 ">
                          <label style={{ color: "var(--blue)" }}>
                            {elem.serviceTitle ? elem.serviceTitle : ""}
                          </label>
                        </div>
                      </div>
                    )}
                    {elem.serviceDescription && (
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>{name2}Service Description</span>
                        </div>
                        <div className="col-md-6 ">
                          <label style={{ color: "var(--blue)" }}>
                            {elem.serviceDescription
                              ? elem.serviceDescription
                              : ""}
                          </label>
                        </div>
                      </div>
                    )}
                    {elem.serviceCharge && (
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>Service Fees / Charges</span>
                        </div>
                        <div className="col-md-6 ">
                          <label style={{ color: "var(--blue)" }}>
                            {elem.serviceCharge ? elem.serviceCharge : ""}
                          </label>
                        </div>
                      </div>
                    )}
                    {elem.paymentTerms && (
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>Payment Terms</span>
                        </div>
                        <div className="col-md-6 ">
                          <label style={{ color: "var(--blue)" }}>
                            {elem.paymentTerms ? elem.paymentTerms : ""}
                          </label>
                        </div>
                      </div>
                    )}
                    <hr />
                  </>
                );
              })
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {formType === 5 && Profile === "Associated" ? (
            email ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Email Id</span>
                </div>
                <div className="col-md-6">
                  <label style={{ color: "var(--blue)" }}>{email}</label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {formType === 5 && Profile === "Associated" ? (
            mobilenumber2 ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Mobile Number / Landline Number</span>
                </div>
                <div className="col-md-6">
                  <label style={{ color: "var(--blue)" }}>
                    {mobilenumber2}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
