import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './jobView.css'
import { BsFillBriefcaseFill, BsFillPeopleFill, } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { MdLocationPin, MdSettingsAccessibility } from 'react-icons/md'
import "./JobList.css";
import { Link } from "react-router-dom";
import Axios from "axios";

export const JobSeekersList = () => {
    const [Loader, setLoader] = useState(<center><div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
    </div></center>)

    const [Loding, setLoding] = useState(true)
    const [Error, setError] = useState(false)
    const [Content, setContent] = useState(false)
    useEffect(() => {
        Axios.get('/api/User/JobVaccencyList')
            .then(res => {
                console.log(res.data)
                if (res.status == 200) {
                    // console.log('jobView')
                    console.log(res.data)
                    // console.log(Id)
                    var jobDetails = res.data.map((myList) => {
                        var age = Math.floor(
                            parseInt(new Date() - new Date(myList.JobSeeker_dob)) / 1000 / 60 / 60 / 24 / 365
                        );

                        var a=myList.JobRecruit_Malevacancies+myList.JobRecruit_Femalevacancies+myList.JobRecruit_Othervacancies+myList.JobRecruit_Anyvacancies
                        console.log(a)
                        return (

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <div className="card carded shadow-sm">
                                <div className="card-body Textuppercase">
                                    <div className="clearfix mb-3">
                                        {" "}
                                        {/* <h2 className='fh1'>{myList.Type==1?"Experienced":myList.Type==2?"Intern":"Fresher"}</h2> */}
                                        <h4 style={{
                                            'fontWeight': 700,

                                        }} className='text-center  '>{myList.JobRecruit_jobTitles?myList.JobRecruit_jobTitles[0]:""}</h4>
                                        <h4 style={{
                                            'fontWeight': 600,
                                        }} className="text-center "> {myList.subcategory?myList.subcategory[0].sub:""}| {myList.category[0]?myList.category[0].category:""}</h4>
                                        {/* <h6>E-commerce</h6> */}
                                    </div>
                                    <div className="row">
                                        <div className="entityJobs  col-12">
                                            <b>vacancies </b>
                                            <p className="ms-2 JobVal ">{a}</p>
                                        </div>

                                        <div className="entityJobs col-6">
                                            <b>AGE </b>
                                            <p className="ms-2 JobVal">{myList.JobRecruit_AgeLvl}</p>
                                        </div>
                                        
                                        <div className="col-1"></div>
                                        <div className="entityJobs col-5">
                                            <b>GENDER </b>
                                            <p className="ms-2 JobVal">
                                                {myList.JobRecruit_Anyvacancies>0?"Any":""}&nbsp;
                                            {myList.JobRecruit_Anyvacancies==0?myList.JobRecruit_Malevacancies>0?"Male":"":""}&nbsp;
                                            {myList.JobRecruit_Anyvacancies==0?myList.JobRecruit_Femalevacancies>0?"Female":"":""}&nbsp;
                                            {myList.JobRecruit_Anyvacancies==0?myList.JobRecruit_Othervacancies>0?"Transgender":"":""}&nbsp;


                                            
                                            </p>
                                        </div>
                                        <div className="entityJobs col-6">
                                            <b>WORK MODE </b>
                                            <p className="ms-2 JobVal">{myList.JobRecruit_workPlace}</p>
                                        </div>
                                        <div className="col-1"></div>

                                        <div className="entityJobs col-5">
                                            <b>EMPLOYMENT TYPE </b>
                                            <p className="ms-2 JobVal">{myList.JobRecruit_empType}</p>
                                        </div>

                                        <div className="entityJobs col-6">
                                            <b>RECURTING AGENCY </b>

                                            <p className="ms-2 JobVal">{myList.JobRecruit_recrutingAgency?myList.JobRecruit_recrutingAgency?"Yes":"No":""}</p>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="entityJobs col-5">
                                            <b>AVAILABLITY </b>
                                            <p className="ms-2 JobVal">{myList.JobRecruit_Immediaterecruitment==true ||"true"?"YES":"NO"}</p>
                                        </div>
                                        <div className="entityJobs col-6">
                                            <b>EDUCATIONAL QUALIFICATION </b>
                                            <p className="ms-2 JobVal">{myList.JobRecruit_Qualification?myList.JobRecruit_Qualification:""}&nbsp;</p>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="entityJobs col-5    ">
                                            <b>WORK EXPERIENCE </b>
                                            <p className="ms-2 JobVal"> {myList.JobRecruit_expLvl?myList.JobRecruit_expLvl[0]:""}
                                                                         </p>
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <div className='text-center mt-1'>
                                            <Link to={`/job_vacancy_view?k=${myList._id}`} className="btn btn-war" >VIEW JOB PROFILE</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div >
                   


                        )

                    });
                    setLoding(false)
                    setContent(jobDetails)
                }
            }).catch((err) => {
                setLoding(false);
                setError('Something went wrong!')
                console.log(err)

            })

    }, [])

    // <Link to={`/home/jobvacview?k=${myList._id}`} className="btn btn-war" >View Post</Link>


    return (
        <div>

            <div className="container-fluid bg-trasparent my-4 p-3 ">
                <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-3 g-3 car">



                    {Loding ? Loader : Error ? Error : Content}


                </div>  </div>

        </div>





    )
}
