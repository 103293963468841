import React, { useEffect, useState } from "react"
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import { AiFillAudio } from "react-icons/ai";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { keyGen } from "../../Utility/helper";

export const PortAdd = (props) => {
    const formData = new FormData()
    const [Image, setImage] = useState(false)
    const [Video, setVideo] = useState(false)
    const [Schedule, setSchedule] = useState(false)
    const [first, setfirst] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [showMedia, setshowMedia] = useState([])
    const [content, setContent] = useState('');
    const [tittle, setTittle] = useState('');
    const [error, seterror] = useState();
    const [halt, sethalt] = useState(false)
    const [Progress, setProgress] = useState(0)
    const [intailtext, setintailtext] = useState('')
    const [value, setValue] = useState({
        title: '',
        discription: '',
        serviceFee: '',
        paymentTerms: '',
        keywords: '', titles: [], discriptions: []
    })

    const Clickhandler = (cont) => {
        if( document.getElementsByClassName('carousel-item').length>=50){
            setintailtext('Basic members can add only a maximum of 50 photos to the Portfolio. UPGRADE to a PRIME membership to add Unlimited Portfolios with Unlimited Images and Videos.')
            return
          
           }else{

           }
        if (cont == 'imagexr') {
            document.getElementById("imagexr").click();
        } else if (cont == 'videoxr') {
            document.getElementById("videoxr").click();
        } else if (cont == 'audioxr') {
            document.getElementById("audioxr").click();
        }
    }


    //////////////////////////////
    var arr = []
    const [Titles, setTitles] = useState([])

    const [fil, setfil] = useState([])
    const [Count, setCount] = useState(0)
    const [Disc, setDisc] = useState([])
    const DiscHandler = (elem) => {
        let id = elem.target.id
        let pos = id.substring(5)
        let ob = {
            position: pos,
            Disc: elem.target.value
        }
        Disc[pos] = ob
        if( document.getElementsByClassName('carousel-item').length<50){
            setintailtext('')
            
          
           }else{
            
           }
    }
    const TitleHandler = (elem) => {
        let id = elem.target.id
        let pos = id.substring(4)
        let ob = {
            position: pos,
            title: elem.target.value
        }
        Titles[pos] = ob
    }

    const DeleteThis = (e) => {
        let data = e.target.getAttribute('data')
        let pos = parseInt(data.substring(4))
        fil[pos] = null
        next()
        document.getElementById('car' + data).remove()
        if( document.getElementsByClassName('carousel-item').length<50){
            // console.log('elihelifh')
            setintailtext('')
            
          
           }else{
            
           }
    }

    const mediaHandler = (file, type) => {
        for (var i = 0; i < file.length; i++) {
            var sab = fil
            sab.push(file[i])
        };
        if (showMedia.length > 0) {
            arr = showMedia
        }
        let CounterPro = 0;
        fil.forEach((esc) => {  
            esc != null && CounterPro++
        })
        var temp = arr
        let Counter = Count
        if (type === 'img') {
            for (var i = 0; i < file.length; i++) {
                let newId = 'port' + Counter
                if (temp.length === 0 || CounterPro == 1) {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item active ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                <img className='carItem' src={URL.createObjectURL(file[i])}></img><br /><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                                <textarea className="form-control"  onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />

                            </div>
                        )
                }
                else {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item   ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                <img className='carItem' src={URL.createObjectURL(file[i])}></img><br /><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                                <textarea className="form-control" onChange={(e) => DiscHandler(e)} id={`A` + newId} ></textarea><br />
                            </div>
                        )
                }
                Counter++
            }
        }
        if (type === 'vid') {

            for (var i = 0; i < file.length; i++) {
                let newId = 'port' + Counter
                if (temp.length === 0 || CounterPro == 1) {

                    temp.push(
                        <div id={`car${newId}`} className="carousel-item  active">
                            <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                            <video className="carItem" controls>
                                <source src={URL.createObjectURL(file[i])} />
                            </video><br />
                            <input id={newId} placeholder="Video Title" onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                            <textarea className="form-control"  onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />

                        </div>
                    )
                }
                else {
                    temp.push(
                        <div id={`car${newId}`} className="carousel-item    ">
                            <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                            <video className="carItem" controls>
                                <source src={URL.createObjectURL(file[i])} />
                            </video><br />
                            <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                            <textarea className="form-control"  onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                        </div>
                    )
                }
                Counter++

            }
        }
        if (type === 'aud') {
            let newId = 'port' + Counter
            for (var i = 0; i < file.length; i++) {
                if (temp.length === 0 || CounterPro == 1) {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item active">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>

                                <div style={{ 'minHeight': '340px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                    <audio controls>
                                        <source src={URL.createObjectURL(file[i])} type="audio/ogg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)} className="form-control" /><br />
                                <textarea className="form-control"  onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                else {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item   ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                <div style={{ 'minHeight': '340px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                    <audio controls>
                                        <source src={URL.createObjectURL(file[i])} type="audio/ogg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                                <textarea className="form-control"  onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                Counter++

            }
        }
        setfil(sab)

        setCount(Counter)
        arr = temp
        if (rerender) {
            setshowMedia(arr)
            setRerender(!rerender)
        } else {
            setshowMedia(temp)
            setRerender(!rerender)
        }
    }
    useEffect(() => {
        if (showMedia.length > 0) {
            // dorender(showMedia)
        }
    }, [showMedia]);

    const [ss, setss] = useState(0);
    useEffect(() => {
    }, [ss]);
    const valid = () => {
    let sab = false
        fil.forEach(element => {
            if (element) {
              sab = true
            }
        });

        if(!sab){
           
            seterror("")
            return false
        }
        

        if (value.title.length > 0) {
            seterror()
        }
        else {
            return seterror("PLEASE ENTER Portfolio Title")
        }

      

        return true;
    }

    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    const Tr = () => {
        let c = []
        let titles = []
        let discs = []
        fil.forEach((element, index) => {
            if (element) {
                titles = [...titles, Titles[index]]
                discs = [...discs, Disc[index]]
            }
        });
        let final = {
            titles, discs
        }
        return final
        let Tit = []
        let Disk = []

        for (let i = 0; i < Count; i++) {
            Tit.push(document.getElementById('port' + i).value)
        }
        for (let i = 0; i < Count; i++) {
            Disk.push(document.getElementById('Aport' + i).value)
        }
        let lit = {
            Tit, Disk
        }
        return lit
    }

    const dommerdup = () => {
        let c = document.getElementById(`closer9655${props.KeyPortAdd}`)
        var status = false;
        setPsy(true)
        let TitlesToDb = Tr()
        status = valid();
        if (status == true) {
            c.setAttribute('disabled', true)
            formData.delete('data')
            formData.delete('file')

            fil.forEach(element => {
                if (element) {
                    formData.append("file", element);
                }
            });
            var sab = {
                value, TitlesToDb
            }
            var das = JSON.stringify(sab)
            formData.append("data", das);
            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor(((loaded / 1000) * 100) / (total / 1000));
                    // move(percent)/
                    if (percent < 100) {
                        setProgress(percent);
                    }

                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            axios.post('/api/Free/Post_PortfolioTemp', formData, options).then((res) => {
                if (res.status === 200) {
                    setProgress(100)
                    let ids = res.data
                    c.removeAttribute('disabled')
                    c.click()
                    props.setPortFolios([...props.PortFolios, ids])
                    // props.activate("Portfolio-tab")
                    notify("Portfolio Added ", true);
                }
                else {
                    c.removeAttribute('disabled')
                    c.click()
                    console.log('something went wrong')
                    notify("something went wrong ", false);
                }
            }).catch((err) => {
                c.removeAttribute('disabled')
                c.click()
                console.log(err)
            })
        }


    }

    const [psy, setPsy]=useState(false)
    useEffect(() => {
        if (psy) {
          valid();
        }
      }, [value]);


    const next = () => document.getElementById('next').click()

    var newTitle
    if(props?.text==='new'){
        newTitle='ADD PORTFOLIO'
    }else {
        newTitle='ADD PORTFOLIO'
    }

    return <>
         {props.PortFolios.length==0?
        <div className="d-flex justify-content-center">
            <button className="btn pro-spaces-button mt-3" data-bs-toggle="modal" data-bs-target={`#Post11${props.KeyPortAdd}`} type="button" >{props.PortFolios.length==0?props.changersab?`${newTitle} *`:`${newTitle} `:'ADD MORE'}</button>
        </div>
     :<></>} 

        <div className="modal fade podamonea" data-bs-backdrop="static" id={`Post11${props.KeyPortAdd}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl  modal-dialog-scrollable ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Portfolio</h5>
                        {/* <button type="button" onClick={(e) => check()}>check</button> */}
                        <button type="button" id={`closer9655${props.KeyPortAdd}`} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {/*  */}
                    <div className="modal-body">
                        <label className="ms-1 ">Portfolio Title *</label>
                        <input className="eihfioehfoieh  w-100 form-control mb-3" onChange={(e) => setValue({ ...value, title: e.target.value })} />
                        {/* <hr />
                        <label className="ms-1">Service Description * </label>
                        <textarea className="postInputx ps-2" onChange={(e) => setValue({ ...value, discription: e.target.value })} placeholder={'Mention what services you provide'}></textarea>
                        <hr />
                        <label className="ms-1">Service Fees / Charges</label>
                        <input className="eihfioehfoieh  w-100 form-control " onChange={(e) => setValue({ ...value, serviceFee: e.target.value })} placeholder={''} />
                        <hr />
                        <label className="ms-1">Payment Terms</label>
                        <input className="eihfioehfoieh  w-100 form-control " onChange={(e) => setValue({ ...value, paymentTerms: e.target.value })} placeholder={''} />
                        <hr /> */}
                        <label className="ms-1" >
                            {props.porttitlechanger?
                            props.porttitlechanger==1?'Service-related Photographs / Video clips':'Facilities / Service-related Photographs / Video clips'
                            :'Works / Projects / Service-related Photographs / Video clips / Audio clips'
                            }
                        </label>
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-interval="false">
                            {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div> */}
                            <div className="carousel-inner">
                                {showMedia ? showMedia : ''}
                            </div>
                            {showMedia.length > 0 ?
                                <>
                                    <button className="carousel-control-prev ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button id="next" className="carousel-control-next ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                        <span className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </>
                                : <></>}

                        </div>
                        <div className="ps-2">
                            <FaPhotoVideo size="25" onClick={() => Clickhandler('imagexr')} style={{ 'color': 'var(--blue)' }}></FaPhotoVideo>
                            &nbsp;&nbsp;<FaVideo style={{ 'color': 'var(--blue)' }} onClick={() => Clickhandler('videoxr')} size="25" />
                            &nbsp;&nbsp;<AiFillAudio style={{ 'color': 'var(--blue)' }} onClick={() => Clickhandler('audioxr')} size="25" />

                        </div>
                        <hr />
                        {/* <label className="ms-1">Service Keyword Search Preferences</label>
                        <textarea className="postInputx ps-2" style={{ 'minHeight': '100px' }} onChange={(e) => setValue({ ...value, keywords: e.target.value })} placeholder={'Mention search words that can help your prospective clients/customers find you easily'}></textarea> */}


                        <input id="imagexr" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'img')}  accept="image/*" />
                        <input id="videoxr" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'vid')}  accept="video/*" />
                        <input id="audioxr" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'aud')}  accept="audio/*" />
                        <p className="text-danger">{intailtext?intailtext:''}</p>
                        {Image ? <div className="container postMediacontainer"> <img className="luide d-flex" src={URL.createObjectURL(Image)} />  </div> : ''}
                        {Video ? <div className="container postMediacontainer"><video className="luide d-flex" controls><source src={URL.createObjectURL(Video)} height="100%" width="100%" /></video> </div> : ''}

                       
                        {/* <ProgressBar Progress={Progress} halt={halt} d_color='red' f_color='yellow' color='white' bgcolor='dark' /> */}
                        {Progress!=0?
                        <ProgressBar variant="SOME_NAME" now={Progress} label={`${Progress}%`} />
                    :<></>}
                    </div>
                    {/*  */}
                    <div className="modal-footer">
                        <div className="">
                        <div>
                            <h4 style={{ color: 'red' }}>{error ? error : ''}</h4>
                        </div>
                            <button type="button" className="btn pro-spaces-button me-2 ps-4 pe-4" onClick={(e)=>{
                                document.getElementById(`closer9655${props.KeyPortAdd}`).click();
                                props.setKeyPortAdd((prev)=>prev+1)}} >discard</button>
                            <button type="button" className="btn pro-spaces-button3" onClick={(e) => dommerdup(e)} >SAVE</button>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        /></>
}