import React, { useState, useEffect } from 'react';
import { NewNavigationBar } from '../Pages/Activev3/NewNavigationBar/NewNavigationBar';
import { Rechargex } from './Payment/Recharge';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export function Activeexpv3(props) {
  const [Iam, setIam] = useState(false);
  let navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const [Membership, setMembership] = useState('');
  const [first, setfirst] = useState();
  const [Feess, setFeess] = useState('');
  useEffect(() => {
    axios
      .get('/api/free/getsubscriptionfees')
      .then((res) => {
        console.log(res);
        setFeess(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    whois();
  }, []);
  const onPay = (membership) => {
    console.log(membership);
    setMembership(membership);
  };
  const whois = () => {
    axios
      .get('/api/verify/whois')
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          if (res.data.iam == 'Consultantv3' || res.data.iam == 'Productv3') {
            setIam('static');
          } else if (
            res.data.iam == 'Consultantv2' ||
            res.data.iam == 'Productv2'
          ) {
            setIam('elite');
          } else if (
            res.data.iam == 'Consultant' ||
            res.data.iam == 'Product'
          ) {
            setIam('prime');
          } else if (res.data.iam == 'Passive') {
            console.log('passive');
            setIam('passive');
          }
        } else {
          console.log('222');
          navigate('/');
        }
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        console.log('333');
        navigate('/');
      });
  };

  if (Loading) {
    return <></>;
  } else {
    return (
      <>
        <NewNavigationBar ShowAccounts={props.ShowAccounts} />
        {Membership.length == 0 ? (
          <>
            <br />
            <br />
            <br />
            <br />
            <div className="container-fluid">
              <div className="row row-cols-1 row-cols-md-3 mb-3 text-center d-flex justify-content-center">
                {/* {Iam} */}
                {Iam == 'passive' && (
                  <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm ">
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">Passive members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ {Feess?.Passive} <small className="">/ year</small>
                          {/* text-muted fw-light */}
                        </h1>
                        {/* <ul className="list-unstyled mt-3 mb-4">
                                                <li style={{ fontSize: "15px" }}>
                                                    Members&nbsp;without&nbsp;Profiles&nbsp;:
                                                </li>
                                                <li >Anyone above 12 years of age </li>
                                                <li className="invisible mangandiiimode">Activists, Artists, Artisans, weugfouwiouogf</li>
                                                <li className="invisible mangandiiimode">Art Dealers, Trainers, Service Providers,</li>
                                                <li className="invisible mangandiiimode">Product Vendors, Business Enterprises</li>
                                            </ul> */}
                        <button
                          type="button"
                          className="w-100 btn btn-lg pricebuttoncolor"
                          onClick={(e) => onPay('passive')}
                        >
                          {Iam == 'passive' && 'continue'}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {Iam == 'static' && (
                  <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm ">
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">Basic members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ {Feess?.Activev3} <small className="">/ year</small>
                          {/* text-muted fw-light */}
                        </h1>
                        {/* <ul className="list-unstyled mt-3 mb-4">
                                                <li style={{ fontSize: "15px" }}>
                                                    Members&nbsp;without&nbsp;Profiles&nbsp;:
                                                </li>
                                                <li >Anyone above 12 years of age </li>
                                                <li className="invisible mangandiiimode">Activists, Artists, Artisans, weugfouwiouogf</li>
                                                <li className="invisible mangandiiimode">Art Dealers, Trainers, Service Providers,</li>
                                                <li className="invisible mangandiiimode">Product Vendors, Business Enterprises</li>
                                            </ul> */}
                        <button
                          type="button"
                          className="w-100 btn btn-lg pricebuttoncolor"
                          onClick={(e) => onPay('static')}
                        >
                          {Iam == 'static' && 'continue'}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {(Iam == 'static' || Iam == 'prime') && (
                  <div className="col ">
                    <div className="card border mb-4 rounded-3 shadow-sm">
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">Prime members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ {Feess?.Active} <small className="">/ year</small>
                        </h1>
                        {/* <ul className="list-unstyled mt-3 mb-4">
                                                    <li>Members with Profiles :</li>
                                                    <li>Professional Consultants, Entrepreneurs</li>
                                                    <li>Artists, Artisans, Art Dealers, Trainers</li>
                                                    <li>Skilled and Commercial Service Providers</li>
                                                    <li>Product Vendors, Business Enterprises</li>
                                                </ul> */}
                        <button
                          type="button"
                          className="w-100 btn btn-lg pricebuttoncolor"
                          onClick={(e) => onPay('prime')}
                        >
                          {Iam == 'prime' ? 'Continue' : 'Upgrade'}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {Iam == 'static' || Iam == 'prime' || Iam == 'elite' ? (
                  <div className="col ">
                    <div className="card border mb-4 rounded-3 shadow-sm">
                      <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">Elite members</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title pricing-card-title">
                          ₹ {Feess.Activev2} <small className="">/ year</small>
                        </h1>
                        {/* <ul className="list-unstyled mt-3 mb-4">
                                                <li>Members with Profiles :</li>
                                                <li>Professional Consultants, Entrepreneurs</li>
                                                <li>Artists, Artisans, Art Dealers, Trainers</li>
                                                <li>Skilled and Commercial Service Providers</li>
                                                <li>Product Vendors, Business Enterprises</li>
                                            </ul> */}
                        <button
                          type="button"
                          className="w-100 btn btn-lg pricebuttoncolor"
                          // onClick={(e) => setType('pro')}
                          onClick={(e) => onPay('elite')}
                        >
                          {Iam == 'elite' ? 'Continue' : 'Upgrade'}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <Rechargex edition={Membership} />
        )}
      </>
    );
  }
}
