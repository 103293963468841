import axios from "axios"
import { useState, useReducer, useEffect, useRef } from "react"
import { Modal } from "react-bootstrap";
import { disablePastDate } from "../../Utility/helper";
import { BsThreeDotsVertical } from "react-icons/bs";
export const Events = (props) => {

    const initialState = { count: 0 };

    function reducer(state, action) {
        switch (action.type) {
            case 'increment':
                return { count: state.count + 1 };
            case 'decrement':
                return { count: state.count - 1 };
            default:
                throw new Error();
        }
    }

    const [event, setevent] = useState({
        eventName: '', date: ''
    })
    const [EventError, setEventError] = useState('')
    const validateEvent = () => {
        if (event.eventName.length === 0) {
            throw new Error('Enter an event name')
        }

        if (event.date.length === 0) {
            throw new Error('Enter a date')
        }
        setEventError('')
        return true
    }

    const saveEvent = () => {
        try {
            const { eventName, date } = event
            let validate = validateEvent()
            if (!validate) return

            let s = axios.post('/api/User/addEvent', event)
            props.notify("Event added !", true);
            setevent({ ...event, eventName: '', date: '' })
            clsbtn.current.click()

        } catch (error) {
            setEventError(error.message)
            props.notify("Something went wrong !", false);
        }
    }
    //view
    const [Events, setEvents] = useState([])
    const [EditStatus, setEditStatus] = useState()
    const getEvents = () => {
        axios.get('/api/User/viewEvent').then((res) => {
            setEvents(res.data)
            console.table(res.data)
        }).catch((respo) => {
            console.log(respo)
        })
    }
    useEffect(() => {
        getEvents()

        return () => {

        }
    }, [])

    const deletetask = (index, id) => {

        axios.post('/api/User/deleteEvent', { id }).then((res) => {
            let temp = [...Events]
            temp.splice(index, 1)
            setEvents(temp)
            props.notify("Deleted !", true);
        }).catch((err) => {
            console.log(err)
            props.notify("Something went wrong !", false);
        })

    }
    const [Update, setUpdate] = useState({
        eventName: '', date: ''
    })
    const task = useRef()
    const taskDate = useRef()

    const [Edit, setEdit] = useState(false)
    const [EditIndex, setEditIndex] = useState(undefined)
    const EditMode = (index, id) => {
        console.log(index, id)
        setEdit(true)
        setEditIndex(index)


    }
    const updateEvent = () => {
        let temp = [...Events]
        let data = {
            task: task.current.value,
            taskdate: taskDate.current.value,
            id: temp[EditIndex]._id
        }
        axios.post('/api/User/updateEvent', { data }).then((res) => {
            temp[EditIndex].task = data.task
            temp[EditIndex].taskdate = data.taskdate
            setEvents(temp)
            setEdit(false)
            props.notify("Event updated !", true);
        }).catch((err) => {
            console.log(err)
            props.notify("Something went wrong !", false);

        })

        console.log(data)

    }
    let clsbtn = useRef()
    return (<div>
        <div className="d-none d-md-block">
            <button type="button" className="btn pro-spaces-button3 me-3" data-bs-toggle="modal"
                data-bs-target="#eventvirtualsec237">Add&nbsp;Event</button>
            <button type="button" className="btn pro-spaces-button3  " data-bs-toggle="modal"
                data-bs-target="#eventvirtualqwewqesec237">View&nbsp;Events</button>
        </div>

        <div className="d-block d-md-none">
            <div className="row">
                <div className="col-auto  ">
                    <button className="btn pro-spaces-button3 px-4" data-bs-toggle="modal"
                        data-bs-target="#eventvirtualsec237">Add&nbsp;Event</button>
                </div>
                <div className="col-auto ">

                    <button className="btn pro-spaces-button3  px-4" data-bs-toggle="modal"
                        data-bs-target="#eventvirtualqwewqesec237">View&nbsp;Events</button>
                </div>
            </div>
        </div>


        <div class="modal fade" id="eventvirtualsec237" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class=" modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="seventvirtualsec237taticBackdropLabel">Add an Event</h5>
                        <button type="button" ref={clsbtn} class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="mb-3">
                                <label for="e2123123" class="form-label">Event</label>
                                <input class="form-control" value={event.eventName} onChange={(e) => setevent({
                                    ...event,
                                    eventName: e.target.value
                                })} type="text" id="e2123123" />
                            </div>
                            <div class="mb-3">
                                <label for="e21231q23" class="form-label">Date</label>
                                <input class="form-control" value={event.date} onChange={(e) => setevent({
                                    ...event, date:
                                        e.target.value
                                })} min={disablePastDate()} type="date" id="e21231q23" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <p className="text-danger">{EventError}</p>
                        <button type="button" class="btn pro-spaces-button3 " onClick={(e) => saveEvent()} >Save</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="eventvirtualqwewqesec237" data-bs-backdrop="static" data-bs-keyboard="false"
            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class=" modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="seventvirtualsec237taticBackdropLabel"> Events</h5>
                        <button type="button" onClick={(e) => setEdit(false)} class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        {!Edit ?
                            Events.map((elem, index) => {
                                return <div style={{ borderBottom: ' 1px solid rgb(198 202 196)' }} className="row p-1">
                                    <div className="col-1">{index + 1}</div>
                                    <div className="col-5"> {elem.task}</div>
                                    <div className="col-4"> {elem.taskdate.slice(0, 10)}</div>
                                    <div className="col-2">
                                        <div className="dropdown ">
                                            <a role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                <BsThreeDotsVertical style={{ color: 'black' }} />
                                            </a>
                                            <ul className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                                <li>
                                                    <a className="dropdown-item" onClick={(e) => EditMode(index, elem._id)}
                                                    >
                                                        Edit
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={(e) => deletetask(index, elem._id)}
                                                    >
                                                        Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            })
                            :
                            <div>
                                <div class="mb-3">
                                    <label for="e2123123" class="form-label">Event</label>
                                    <input class="form-control" ref={task} defaultValue={Events[EditIndex].task} type="text"
                                        id="e2123123" />
                                </div>
                                <div class="mb-3">
                                    <label for="e21231q23" class="form-label">Date</label>
                                    <input class="form-control" ref={taskDate} defaultValue={Events[EditIndex].taskdate.slice(0,
                                        10)} min={disablePastDate()} type="date" id="e21231q23" />
                                </div>
                            </div>
                        }
                    </div>
                    <div class="modal-footer">
                        {!Edit ? <>
                            {/* <p className="text-danger">{EventError}</p> */}
                            {/* <button type="button" class="btn pro-spaces-button3 " onClick={(e)=> saveEvent()}
                            >Save</button> */}
                        </> : <>
                            <button type="button" class="btn pro-spaces-button3 " onClick={(e) => setEdit(false)}
                            >Cancel</button>
                            <button type="button" class="btn pro-spaces-button3 " onClick={(e) => updateEvent()}
                            >Update</button>
                        </>
                        }

                    </div>
                </div>
            </div>
        </div>
    </div>)
}