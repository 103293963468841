import React, { useEffect, useState } from "react";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./CreateProfile.css";

import { Link } from "react-router-dom";
import { Navoutside } from "../Navoutside/Navoutside";
import { AiFillExclamationCircle } from "react-icons/ai";
import { NewNavigationBarDissabled } from "../../Pages/Free/NewNavigationBar/NewNavigationBarDissabled";

export function CreateProfile() {
  const [first, setfirst] = useState("");

  useEffect(() => {
    axios
      .get("/api/Api/getcategory", {})
      .then((res) => {
        // console.log(res)
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [first2, setfirst2] = useState("");

  useEffect(() => {
    axios
      .get("/api/Api/getprocategory", {})
      .then((res) => {
        // console.log(res)
        setfirst2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <NewNavigationBarDissabled />

      {/* <div className="continer">
      <div className="cardBox11">
        <div className="row">
          <div className="col lg-4 md-6 sm-12">
          <Link to="CreateConseltent" className="wgfiuwrgiuddd">
            <div className="card carrrrrr">
              
              <div>
                <div className="numberser">Create a Consultant Profile</div>
              </div>
              
            </div>
            </Link>
          </div>
          <div className="col lg-4 md-6 sm-12">
          <Link to="CreateProduct" className="wgfiuwrgiuddd">
            <div className="card carrrrrr">
            
              <div>
                <div className="numberser">Create a Vendor Profile</div>
              </div>
              
            </div>
            </Link>
          </div>
        </div>
      </div>
    </div> */}

      <div className="container ">
        <div className="row slhishieo3jfiehf9999">
          <center>
            <h5 style={{ color: "var(--blue)" }}>
              Select your <span>Profile Classification</span>
            </h5>
          </center>
          <center>
            <p className="mb-0" style={{ color: "var(--blue)" }}>
              (Choose between Services and Products)
            </p>
          </center>
          <div className="col-12 col-lg-6 d-flex align-items-stretch">
            <div className="card  magiccard ">
              <Link
                to="CreateConseltent"
                style={{ textDecoration: "none", color: "var(--blue" }}
              >
                <center className="mb-3">
                  <span style={{ fontSize: "25px" }}>SERVICES</span>
                </center>
              </Link>
              <Link
                to="CreateConseltent"
                style={{ textDecoration: "none", color: "var(--blue" }}
              >
                <center className="pt-2">
                  <p className="mb-0">
                    Qualified Professionals, Artists, Academicians, all
                    Technically and Technologically Knowledgeable Persons and
                    Service Providers, and Commercial Service Providers across
                    industries.
                  </p>
                </center>
                <center>
                  <button className="btn pro-spaces-button3 px-3 my-4">
                    CREATE PROFILE
                  </button>
                </center>
              </Link>
              <center>
                <button
                  className="btn pro-spaces-button px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#uugfuewfhfhgflqeougeoufoew"
                >
                  <span>VIEW CATEGORIES</span>
                </button>
              </center>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-stretch">
            <div className="card  magiccard ">
              <Link
                to="CreateProduct"
                style={{ textDecoration: "none", color: "var(--blue" }}
              >
                <center className="mb-3">
                  <span style={{ fontSize: "25px" }}>PRODUCTS</span>
                </center>
              </Link>
              <Link
                to="CreateProduct"
                style={{ textDecoration: "none", color: "var(--blue" }}
              >
                <center className="pt-2">
                  <p className="mb-0">
                    All Product Retailers and Vendors, Builders and Developers,
                    Art Dealers.
                  </p>
                </center>
                <center>
                  <button className="btn pro-spaces-button3 px-3 my-4">
                    CREATE PROFILE
                  </button>
                </center>
              </Link>
              <center>
                <button
                  className="btn pro-spaces-button px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#uugfuewgflqeougeoufoewdsssieryfg"
                >
                  <span>VIEW CATEGORIES</span>
                </button>
              </center>
            </div>
          </div>
        </div>

        <p className="invisible">hai</p>

        <div
          className="modal fade"
          id="uugfuewfhfhgflqeougeoufoew"
          style={{ zIndex: "10000000" }}
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                {first
                  ? first.map((element) => {
                      // console.log(element.sub)
                      return (
                        <>
                          <div>
                            <div className="Categories pb-3 pt-3">
                              <b className="">{element.category}</b>
                            </div>
                            <div className="">
                              <ul>
                                {element.sub
                                  ? element.sub.map((elm) => {
                                      return (
                                        <li>
                                          <div className="row">
                                            <div className="col-auto">
                                              {elm.sub}
                                            </div>
                                            {/* <div className='col-auto'>: 10</div> */}
                                          </div>
                                        </li>
                                      );
                                    })
                                  : ""}
                              </ul>
                            </div>
                          </div>
                          <hr />
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="uugfuewgflqeougeoufoewdsssieryfg"
          style={{ zIndex: "10000000" }}
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                {first2
                  ? first2.map((element) => {
                      // console.log(element.sub)
                      return (
                        <>
                          <div>
                            <div className="Categories pb-3 pt-3">
                              <b className="">{element.category}</b>
                            </div>
                            <div className="">
                              <ul>
                                {element.sub
                                  ? element.sub.map((elm) => {
                                      return (
                                        <li>
                                          <div className="row">
                                            <div className="col-auto">
                                              {elm.sub}
                                            </div>
                                            {/* <div className='col-auto'>: 10</div> */}
                                          </div>
                                        </li>
                                      );
                                    })
                                  : ""}
                              </ul>
                            </div>
                          </div>
                          <hr />
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
