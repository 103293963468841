import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Priceing.css";
import { Navoutside } from "../Navoutside/Navoutside";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  AiFillExclamationCircle,
  AiOutlineClose,
  AiFillInfoCircle,
} from "react-icons/ai";
import { IoIosHelpCircle } from "react-icons/io";
import axios from "axios";
import { NewNavigationBar } from "../../Pages/Free/NewNavigationBar/NewNavigationBar";
import { dataTable } from "../../Utility/userFeaturesData";
export function Priceing2() {
  const [first, setfirst] = useState(0);
  const [first1, setfirst1] = useState(0);
  const [first2, setfirst2] = useState(0);
  const [first3, setfirst3] = useState(0);
  const [first4, setfirst4] = useState(0);

  const alerttt = (e) => {
    alert("Registration now open only for Active members.");
  };

  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  const Auth = () => {
    axios
      .get("/api/verify/authenticateduser")
      .then((res) => {
        if (res.status === 200) {
          console.log("workd");
          setAuthenticated(true);
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  };

  useEffect(() => {
    Auth();
  }, []);

  useEffect(() => {
    axios
      .get("/api/Free/passivecount", {})
      .then((res) => {
        setfirst(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/Free/concount", {})
      .then((res) => {
        setfirst1(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/Free/procount", {})
      .then((res) => {
        setfirst2(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/Free/ConsultantCountV2", {})
      .then((res) => {
        setfirst3(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/Free/ProCountV2", {})
      .then((res) => {
        setfirst4(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [Price, setPrice] = useState({});
  useEffect(() => {
    axios
      .get("/api/free/getsubscriptionfees")
      .then((res) => {
        console.log(res);
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <NewNavigationBar />

      <div>
        <div
          id="hoo1"
          className="pricingAllUniPassive container-fluid  jrgfrjflobjgt "
          style={{ paddingTop: "100px" }}
        >
          <div className="text-black">
            <div className="pfeatures897 p-0 container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <h4 className=" fw-bold" style={{ color: "#c85366" }}>
                      For Clients, Customers
                      {/* and Students{" "} */}
                    </h4>
                  </div>
                </div>

                <div className="col-8 text-center">
                  <div className="text-center">
                    <h4 className=" text-center m-0  ">USER FEATURES</h4>
                  </div>
                </div>
                <div className="col-4 ">
                  <p
                    style={{ color: "#55374a" }}
                    data-bs-toggle="modal"
                    data-bs-target="#shygciasiucgwrfviklskjgcklbkjas"
                    className="text-primary text-center m-0 pb-1"
                  >
                    Membership Features&nbsp;
                    <IoIosHelpCircle style={{ marginTop: "-3px" }} />
                  </p>
                  <p className="card-title text-center mb-1 pricing-card-title p-0">
                    ₹ {Price?.Passive}/- per year
                  </p>
                  {/* <p className=" text-center m-0 pt-0 pb-2">
                    ( per year fees including gst )
                  </p> */}
                </div>
              </div>
              <div className="row d-none">
                <div className="col-8"></div>
                <div className="col-4 ">
                  <Link to="/passive_register">
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="w-100 activPassiveBtn px-3 btn btn-lg pricebuttoncolor"
                      // onClick={(e)=>alerttt()}
                    >
                      Subscribe
                    </button>
                  </Link>
                </div>
              </div>

              {dataTable.map((data) => {
                if (data?.headData?.passive != "N/A") {
                  let sp = true;
                  data.data.forEach((er) => {
                    if (er.value.passive == "N/A") {
                      sp = false;
                    }
                  });
                  if (sp)
                    return (
                      <div className="py-1 py-md-2">
                        <div className="row">
                          <div className="col-8 ">
                            <span
                              data-bs-toggle="modal"
                              data-bs-target={`#${data.modalId}`}
                              className={
                                data.head == "AUTHENTICATION BADGE "
                                  ? "spaceNoWrap"
                                  : " "
                              }
                              style={{ color: "#c85366" }}
                            >
                              {data.head}
                              {data.element && data.element}
                            </span>
                          </div>
                          <div className="col-4 text-center ">
                            {data.headData ? data.headData.passive : ""}
                          </div>
                        </div>
                        {data.data.map((pop) => {
                          return (
                            <div className="row ">
                              <div className="col-8 ">
                                <span className=" ">{pop.label}</span>
                              </div>
                              <div
                                className={` ${
                                  pop.noChange
                                    ? "col-4 text-center "
                                    : "col-4 text-center "
                                }`}
                              >
                                {pop.value.passive}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                }
              })}
              <div className="row">
                <div className="col-8"></div>
                <div className="col-4 py-2 ">
                  <Link to="/passive_register">
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="w-100 px-3 btn activPassiveBtn btn-lg pricebuttoncolor"
                      // onClick={(e)=>alerttt()}
                    >
                      Subscribe
                    </button>
                  </Link>
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////// */}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqsnskdb"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>KNOW MY CLIENT</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <p>
                    To the mutual benefit of both the Service Providers and
                    their clients, ‘Know My Client’ is a feature where the
                    Service Providers have the option to prepare a set of
                    questions that their prospective clients must answer when
                    the client clicks on the Communicate button on the profile
                    of the service provider. This feature is available only for
                    service providers.
                  </p>
                  <br />
                  <p>
                    Your list of questions will be sent as a direct message to
                    your client’s inbox.
                  </p>
                  <p>
                    These questions can either be deleted or be replaced with
                    new ones at any time.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>CUBICLES</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <p>
                    CUBICLES are chat rooms for project / product /
                    service-related discussions between members
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqeiugfruoegfsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">VIRTUAL SECRETARY</h4>
                </div>
                <div className="row">
                  <p>
                    Any member can use this feature to Assign personal and
                    professional Tasks and Set Reminders of Birthdays,
                    Anniversaries, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqeiugflwihroiwgorwogruoegfsnskdbsab"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">AUTHENTICATION BADGE</h4>
                </div>
                <div className="row">
                  <p>
                    A tag labeled ‘
                    <span style={{ color: "#c85366" }}>PRO-</span> AUTHENTIC !’
                    on the profile card means that PROchure has verified the
                    authenticity of that respective Service Provider / Product
                    Retailer. That means, we have verified the following via
                    periodic checks :
                  </p>
                  <p>
                    <ol>
                      <li>
                        Operating Address and/or Contact Information of the
                        SERVICE PROVIDER / PRODUCT RETAILER.
                      </li>
                      <li>
                        Whether the SERVICE PROVIDER is currently actively
                        providing consultation / services pertinent to the
                        category in which the profile is created.
                      </li>
                      <li>
                        Whether the PRODUCT RETAILER is currently actively
                        running the business and if it is pertinent to the
                        category in which the profile is created.
                      </li>
                      <li>
                        Whether the SERVICE PROVIDERS are genuinely registered
                        with the statutory body (constituted by the Government
                        of India) as claimed by them.
                      </li>
                    </ol>

                    <p>Please note :</p>
                    <ol>
                      <li>
                        Only ELITE and PRIME members are Eligible for this
                        badge.
                      </li>
                      <li>
                        Not all SERVICE PROVIDERS need to be mandatorily
                        registered with any statutory body to offer professional
                        consultation / commercial services.
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="wefgigwfijiqiueugfiuilqeiuegfi"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>SHOUT-OUT</span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <p>
                    To help the clients / customers avoid the hassle of
                    approaching multiple Service Providers, Product Retailers
                    one-by-one, the client, customer can SHOUT-OUT his / her
                    query / requirement to the Service Providers, Product
                    Retailers in a particular sub-category. This query goes to
                    ALL the Service Providers, Product Retailers (of that
                    sub-category) as a Direct Message.
                  </p>
                  <p>
                    You can also send a SHOUT-OUT to only selected Service
                    Providers, Product Retailers after SHORT-LISTING them.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="primeprofilemodalussss"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <h4>TWENTY PROFILES in both SERVICES and PRODUCTS</h4>
                  <p>
                    You can create upto a maximum of 20 (TWENTY) Profiles in
                    total in various sub-categories of various categories in
                    both classifications, that is either as Service Providers
                    and/or as Product Retailers.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="basicprofilemodalussss"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <h4>TEN PROFILES either in SERVICES OR in PRODUCTS</h4>
                  <p>
                    You can create upto a maximum of 10 (TEN) Profiles in total
                    in multiple sub-categories of any one category within any
                    one classification (either in Services or in Products).
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
