import React, { useState, useEffect, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { AiFillCamera, AiFillExclamationCircle, AiOutlinePlusCircle } from "react-icons/ai";
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Port } from '../port';
import {
    MenuProps,
    languages,
    arrayofdays,
    arrayofdays2, preferdmodeofcommunication
} from '../../../Utility/languages';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { keyGen } from '../../../Utility/helper';
import Dsi from '../../Dsi/dsi';
import { print_state, print_city, state_arr } from '../../../Utility/stateCity';

import { CatsContext } from '../FormConsultent'
import { ConvertContext } from '../FormConsultent'
import { EditContext } from '../FormConsultent'



import MapWithSwitch from '../../Mapsv2/MapWithSwitch';
import MobileAddOn from '../../MobileAddOn/mobileAddOn';
import { TimeSelectBox } from '../../../Utility/timer';
import Modal from 'react-bootstrap/Modal';
import Outcount from "../OutCount";
import { Selfdeclaration } from "../../Selfdeclaration/Selfdeclaration";
import { FormHelperText } from '@mui/material';
import { StateCity } from '../../CreateConseltent/StateCity';
import { useSearchParams } from 'react-router-dom';
import AddOn from "../AddOn";
import { Partners } from '../../FormComponents/Partners';



export function CreateConseltentC(props) {
    let x = [...Array(100).keys()];
    const [Stateload, setStateload] = useState(true);

    const Edit = useContext(EditContext);
    const cats = useContext(CatsContext);
    const convert = useContext(ConvertContext)
    const [PartnerCount, setPartnerCount] = useState([keyGen()])

    const individual = useRef();
    const [CONCount, setConCount] = useState([keyGen()]);

    const company = useRef();
    useEffect(() => {
        if (Stateload) print_state('State');
        setStateload(false);
    }, []);

    const formData = new FormData();
    const [AddressCount, setAddressCount] = useState([]);
    const [addressBranch, setaddressBranch] = useState([]);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow1(false);
    const handleShow = () => setShow1(true);

    const [loading, setloading] = useState(false);
    const [DsiCount, setDsiCount] = useState([keyGen()]);
    const [error, setError] = useState('');
    const [Submit, setSubmit] = useState(false);
    const [image, setImage] = useState();
    const [cropData, setCropData] = useState(false);
    const [cropper, setCropper] = useState();
    const [Today, setToday] = useState(false);
    const [value, setValue] = useState({
        Profile: 'Individual',
        type: 'active',
        varient: 'consultent',
        firstname: '',
        lastname: '',
        consultantType: '',
        address_door: '',
        address_floor: '',
        address_building: '',
        address_nearest: '',
        address_street: '',
        address1: '',
        WomanOwned: false,
        state: '',
        city: '',
        pincode: '',
        email: '',
        website: '',
        gender: '',
        dob: '',
        TypeofConsultation: '',
        yearofb: '',
        qualification: '',
        univesity: '',
        yearofpassing: '',
        worktimimng: false,
        category: cats.value.category,
        subcategory: cats.value.subcategory,
        Brand: cats.value.Brand,
        licence: '',
        currentorganization: '',
        previousorganization: '',
        specialization: '',
        serviceprovided: '',
        experience: '',
        skills: '',
        language: [],
        preferredlanguage: '',
        image: '',
        gst: '',
        chif: '',
        contact: '',
        EntityName: '',
        contact2: '',
        contact3: '',
        country: "",
        contact4: '',
        currentn_work: '',
        pname: '',
        formType: props.formType,
        age: '',
        portfolios: [],
        working_leave: '',
        working_hour_2: '',
        working_hour_1: '',
        working_hour_3: '',
        working_hour_4: '',
        working_days_2: '',
        working_days_1: '',
        working_days_3: '',
        working_days_4: '',
        countrycode: '',
        statecode: '',
        contactpersonsname: '',
        contactpersonsdesignation: '',
        contactpersonsname2: '',
        contactpersonsdesignation2: '',
        preferredlanguage2: '',
        contactpersonsname3: '',
        contactpersonsdesignation3: '',
        preferredlanguage3: '',
        contactpersonsname4: '',
        contactpersonsdesignation4: '',
        preferredlanguage4: '',
        workingdaysfullnew: [],
        workingdayshalfnew: [],
        workingdaysOffnew: [],
        break_time_2: '',
        break_time_1: '',
        preferedDaysandTime: '',
        brandNamenew: '',
        brandtagline: '',
        taglineCheck: false,
        responiceness: '',
        preferdmodeofcommunication: [],
        typeoffirm: '',

    });
    const [newb, setnewb] = useState();
    const [DSI, setDSI] = useState([]);
    const [Location, setLocation] = useState({ lat: '', lng: '' });
    const [LocationOn, setLocationOn] = useState();

    const validatorAddon = (Count) => {
        try {
            for (const key of Count) {
                if (!key.subcategory || key.subcategory?.length === 0) {
                    setError('PLEASE SELECT A Subcategory');
                    return false;
                }
                if (key.brandOn === true && key.brand.length == 0) {
                    setError('PlEASE SELECT A Brand');
                    return false;
                }
            }
            setError('');
            return true;
        } catch (error) {
            console.log(error);
        }
    };
    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city('City', index);
        setValue({ ...value, state: e.target.value });
    }
    useEffect(() => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10) month = '0' + month.toString();
        if (day < 10) day = '0' + day.toString();
        setToday(year + '-' + month + '-' + day);
    }, []);

    useEffect(() => {
        setValue({
            ...value,
            subcategory: cats.value.subcategory,
            category: cats.value.category,
            Brand: cats.value.Brand,
        });
        return () => { };
    }, [cats]);

    const onChange = async (e) => {
        // $("#profileUpdation").modal("show");
        var myModal = new bootstrap.Modal(
            document.getElementById('profileUpdation')
        );
        myModal.show();
        var myModalEl = document.getElementById('profileUpdation');
        myModalEl.addEventListener('shown.bs.modal', function (event) {
            cropData ? setCropData(false) : <></>;
            e.preventDefault();
            let files;
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }
            if (files[0]) {
                const reader = new FileReader();
                reader.onload = () => {
                    setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);
            }
            e.target.value = null
        });

        var x = document.getElementById('profileUpdation').offsetWidth;
        //    var s=document.getElementById('profileUpdation')
    };

    const [imageinserted, setimageinserted] = useState(false)
    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            // setCropData(cropper.getCroppedCanvas().toDataURL());
            setimageinserted(true)
            setCropData();
            cropper.getCroppedCanvas().toBlob(
                (blob) => {
                    setCropData(URL.createObjectURL(blob));
                    setnewb(blob);
                },
                'image/jpeg',
                0.5
            );
        }
    };

    const uploadme = () => {
        var DP = document.getElementById('DP');
        DP.src = cropData;
    };

    const photo = () => document.getElementById('image').click();

    useEffect(() => {
        setValue({ ...value, image: cropData });
    }, [cropData]);

    const [selfDeclaration, setSelfDeclaration] = useState(false)

    const [Submit2, setSubmit2] = useState(false);
    const [error2, setError2] = useState("PLEASE ENTERT Age");


    function validation() {
        if (!Edit) {
            if (cropData) {
                var imgsize = cropData.length - 22;
                var imgsizeinkb =
                    (4 * Math.ceil(imgsize / 3) * 0.5624896334383812) / 1000;
                if (cropData) {
                    setError();
                } else {
                    return setError('Photo size is greater than 2MB');
                }
            } else {
                return setError('PLEASE upload PROFILE DISPLAY PICTURE');
            }
        }
        if (cats.brand.length > 0) {
            if (value.Brand === undefined) {
                return setError('PLEASE SELECT A BRAND');
            }
            if (value.Brand.length === 0) {
                return setError('PLEASE SELECT A BRAND');
            }
        }

        for (const element of addressBranch) {

            if (element.state.length === 0) {
                return setError('PLEASE ENTER STATE for branch');
            }
            if (element.city.length === 0) {
                return setError('PLEASE ENTER CITY for branch');
            }
            if (element.pincode.length === 0) {
                return setError('PLEASE ENTER PINCODE for branch');
            }
            if (element.licence.length === 0) {
                return setError('PLEASE SELECT Do you have a Trade Licence for branch');
            }
            if (element.gst.length === 0) {
                return setError(
                    'PLEASE SELECT Is your entity GST registered for branch'
                );
            }
            if (element.working_days_1.length === 0) {
                return setError('PLEASE ENTER Working Days (full) for branch');
            }
            if (element.working_days_2.length === 0) {
                return setError('PLEASE ENTER Working Days (full) for branch');
            }
            if (element.working_hour_1.length === 0) {
                return setError('PLEASE ENTER Working Hours for branch');
            }
            if (element.working_hour_1.length === 0) {
                return setError('PLEASE ENTER Working Hours for branch');
            }
            if (element.working_days_3.length === 0) {
                return setError('PLEASE ENTER Working Days (half) for branch');
            }
            if (element.working_days_4.length === 0) {
                return setError('PLEASE ENTER Working Days (half) for branch');
            }
            if (element.working_hour_3.length === 0) {
                return setError('PLEASE ENTER Working Hours for branch');
            }
            if (element.working_hour_4.length === 0) {
                return setError('PLEASE ENTER Working Hours for branch');
            }
            if (element.working_leave.length === 0) {
                return setError('PLEASE ENTER Weekly Off for branch');
            }
        }

        if (value.pname.length > 0) {
            setError('');
        } else {
            return setError('PLEASE ENTER PROFILE DISPLAY NAME');
        }

        if (value.Profile === 'Individual') {
            if (value.firstname.length > 0) {
                setError('');
            } else {
                return setError('PLEASE ENTER First Name');
            }
            if (value.lastname.length > 0) {
                setError('');
            } else {
                return setError('PLEASE ENTER Last Name');
            }
            if (value.gender.length > 0) {
                setError('');
            } else {
                return setError('PLEASE ENTER gender');
            }

            if (value.age) {
                if (value.age > 17) {
                    setError('');
                } else {
                    return setError('Sorry ! You should be atleast 18 years of age');
                }
            } else {
                return setError('PLEASE ENTER Age');
            }
            if (value.language.length > 0) {
                setError('');
            } else {
                return setError('PLEASE ENTER Languages Known');
            }

            if (value.experience.length > 0) {
                setError('');
            } else {
                return setError('PLEASE ENTER Total Work Experience');
            }
        } else {
            if (value.yearofb.length != 4) {
                return setError('PLEASE ENTER Year of Business Inception');
            }
        }

        if (value.state.length > 0) {
            setError('');
        } else {
            return setError('PLEASE ENTER State');
        }

        if (value.city.length > 0) {
            setError('');
        } else {
            return setError('PLEASE ENTER City');
        }
        if (value.pincode.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Pin code / Zip code ");
        }
        if (value.licence.length > 0) {
            setError('');
        } else {
            return setError('PLEASE ENTER Do you have a Trade Licence ?');
        }

        if (value.gst.length > 0) {
            setError('');
        } else {
            return setError('PLEASE ENTER Is your entity GST registered ?');
        }
        if (!value.worktimimng) {
            if (value.workingdaysfullnew.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Days (full)");
            }

            if (value.working_hour_1.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Hours");
            }

            if (value.working_hour_2.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Hours");
            }
            if (value.break_time_1.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Break Time");
            }
            if (value.workingdayshalfnew.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Days (half)");
            }

            if (value.working_hour_3.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Hours");
            }

            if (value.working_hour_4.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Hours");
            }
        }

        if (value.workingdaysOffnew.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Weekly Off");
        }

        // if (value.licence.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE ENTER Do you have a Trade Licence ?");
        // }

        // if (value.gst.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE ENTER Is your entity GST registered ?");
        // }
        if (value.countrycode.length > 0) {
            setError('');
        } else {
            return setError('PLEASE SELECT Country Code');
        }
        // if (value.statecode.length > 0) {
        //     setError('');
        // } else {
        //     return setError('PLEASE SELECT State Code');
        // }

        for (const element of CON) {
            if (CON.indexOf(element) === 0) {
                if (element.contact2.length < 6 || element.contact2.length > 12) {
                    return setError(
                        'PLEASE ENTER VALID A Mobile Number / Landline Number'
                    );
                }
                if (element.contactpersonsname2.length == 0) {
                    return setError(`PLEASE ENTER Contact Person’s Name`);
                }
                if (element.contactpersonsdesignation2.length == 0) {
                    return setError(`PLEASE ENTER Contact Person’s Designation`);
                }
                if (element.preferredlanguage2.length == 0) {
                    return setError(
                        `PLEASE SELECT A Most Comfortable / Preferred Language for Communication for ${element.contact2}`
                    );
                }
            }
        }
        if (value.preferdmodeofcommunication.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Preferred Mode of Communication");
        }

        if (value.preferedDaysandTime.length === 0) {
            return setError("PLEASE Preferred Days and Time of Communication via Voice Calls")
        }

        if (value.email.length > 0) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.email)) {
                setError('');
            } else {
                return setError('PLEASE ENTER a valid email id');
            }
        } else {
            return setError('PLEASE ENTER email id');
        }

        if (validatorAddon(Addons)) {
        } else {
            return false;
        }

        if (!selfDeclaration) {
            return setError("PLEASE CHECK the self declaration above !");
        }

        return true;
    }
    const submit = async (e) => {
        e.preventDefault();

        formData.delete('file');
        formData.delete('data');
        let loc = false;
        if (Location.lng) {
            loc = true;
        }
        var dataSend = JSON.stringify({
            value,
            DSI,
            Location,
            LocationOn: loc,
            Addons,
            addressBranch,
            CON,
            Out,
            Partner
        });
        console.log(dataSend);
        var status = false;
        let metadata = {
            type: 'image/jpeg',
        };
        if (newb) {
            let file = new File([newb], 'test.jpg', metadata);
            formData.append('file', file);
        }
        formData.append('data', dataSend);
        setSubmit(true);
        status = validation();
        if (status == true) {
            setloading(true);

            let a = await props.postTo(formData);
            if (!a) {
                setloading(false);
            }
            // axios.post("/api/accounts/register/consultant", formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     }
            // }).then(res => {
            //     if (res.status == 200) {
            //         setloading(false)
            //         console.log('product Created')
            //         // console.log(res.data)
            //         return navigate('/payment')
            //     }
            // }).catch((err) => {
            //     if (err.response.status == 304) {
            //         console.log(err.response.data)
            //         // toast warning
            //         navigate('/payment')

            //     } else {
            //         setloading(false)
            //     }
            // })
        }
    };

    useEffect(() => {
        if (Submit) {
            validation();
        }
    }, [value, selfDeclaration]);

    const handleDSI = (e, index) => {
        try {
            let DSIcollection = DSI;
            if (e === 'remove') {
                DSIcollection.splice(index, 1);
                let dsicount = DsiCount.slice();
                let c = dsicount.splice(index, 1);
                setDsiCount(dsicount);
            } else {
                DSIcollection[index] = e;
            }
            setDSI(DSIcollection);
        } catch (error) {
            console.log(error);
        }
    };
    const [CON, setCON] = useState([]);
    const [Partner, setPartner] = useState([])

    const handleCON = (e, index) => {
        try {
            console.log({ e, index }, { CON });
            let CONcollection = CON;
            if (e === 'remove') {
                CONcollection.splice(index, 1);
                let dsicount = CONCount.slice();
                let c = dsicount.splice(index, 1);
                setConCount(dsicount);
            } else {
                CONcollection[index] = e;
            }
            setCON(CONcollection);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePartner = (e, index) => {
        try {
            console.log({ e, index }, { Partner });
            let CONcollection = Partner
            if (e === 'remove') {
                CONcollection.splice(index, 1)
                let dsicount = PartnerCount.slice()
                let c = dsicount.splice(index, 1)
                setPartnerCount(dsicount)
            } else {
                CONcollection[index] = e
            }
            setPartner(CONcollection)
        } catch (error) {
            console.log(error)
        }
    }
    const [OutCount, setOutCount] = useState([crypto.randomUUID()])
    const [Out, setOut] = useState([])
    const outHandle = (index, e, data) => {

        let temp = [...Out]
        if (e) {
            temp[index] = data
        } else {
            let tep = [...OutCount]
            temp.splice(index, 1)
            tep.splice(index, 1)
            setOutCount(tep)
        }
        setOut([...temp])
    }

    useEffect(() => {
        if (Edit) {
            setDSI(Edit[0].DSI ? Edit[0].DSI : []);
            setCON(Edit[0].CON ? Edit[0].CON : []);
            setPartner(Edit[0].Partner ? Edit[0].Partner : [])

            let temp = [];
            let temp2 = [];
            if (Edit[0].language) {
                if (typeof Edit[0].language === 'string') {
                    temp = [...temp, Edit[0].language];
                } else {
                    temp = [...Edit[0].language];
                }
            }

            setValue({
                ...value,
                age: Edit[0].age,
                pname: Edit[0].pname ? Edit[0].pname : '',
                id: Edit[0]._id,
                firstname: Edit[0].firstname,
                lastname: Edit[0].lastname,
                EntityName: Edit[0].EntityName ? Edit[0].EntityName : '',
                currentn_work: Edit[0].currentn_work ? Edit[0].currentn_work : '',
                address_door: Edit[0].address_door ? Edit[0].address_door : '',
                address_floor: Edit[0].address_floor ? Edit[0].address_floor : '',
                address_building: Edit[0].address_building
                    ? Edit[0].address_building
                    : '',
                address_nearest: Edit[0].address_nearest ? Edit[0].address_nearest : '',
                address_street: Edit[0].address_street ? Edit[0].address_street : '',
                address1: Edit[0].address1 ? Edit[0].address1 : '',
                country: Edit[0].country,
                state: Edit[0].state,
                city: Edit[0].city,
                pincode: Edit[0].pincode,
                email: Edit[0].email,
                website: Edit[0].website,
                gender: Edit[0].gender,
                consultantType: Edit[0].consultantType ? Edit[0].consultantType : '',
                WomanOwned: Edit[0].WomanOwned ? Edit[0].WomanOwned : false,
                dob: Edit[0].dob,
                category: cats.category,
                subcategory: cats.subcategory,
                TypeofConsultation: Edit[0].TypeofConsultation
                    ? Edit[0].TypeofConsultation
                    : '',
                yearofb: Edit[0].yearofb ? Edit[0].yearofb : '',
                qualification: Edit[0].qualification,
                univesity: Edit[0].univesity,
                yearofpassing: Edit[0].yearofpassing,
                licence: Edit[0].licence,
                currentorganization: Edit[0].currentorganization,
                previousorganization: Edit[0].previousorganization,
                specialization: Edit[0].specialization,
                serviceprovided: Edit[0].serviceprovided,
                experience: Edit[0].experience ? Edit[0].experience : '',
                skills: Edit[0].skills,
                language: temp,
                Profile: Edit[0].Profile ? Edit[0].Profile : 'Individual',
                link: Edit[0].link ? Edit[0].link : '',
                gst: Edit[0].gst ? Edit[0].gst : '',
                chif: Edit[0].chif ? Edit[0].chif : '',
                image: '',
                DSI: Edit[0].DSI ? Edit[0].DSI : [],
                CON: Edit[0].CON ? Edit[0].CON : [],
                Partner: Edit[0].Partner ? Edit[0].Partner : [],
                typeoffirm: Edit[0].typeoffirm ? Edit[0].typeoffirm : '',
                working_leave: Edit[0].working_leave ? Edit[0].working_leave : '',
                working_hour_2: Edit[0].working_hour_2 ? Edit[0].working_hour_2 : '',
                working_hour_1: Edit[0].working_hour_1 ? Edit[0].working_hour_1 : '',
                working_days_2: Edit[0].working_days_2 ? Edit[0].working_days_2 : '',
                working_days_1: Edit[0].working_days_1 ? Edit[0].working_days_1 : '',
                working_hour_3: Edit[0].working_hour_3 ? Edit[0].working_hour_3 : '',
                working_hour_4: Edit[0].working_hour_4 ? Edit[0].working_hour_4 : '',
                working_days_3: Edit[0].working_days_3 ? Edit[0].working_days_3 : '',
                working_days_4: Edit[0].working_days_4 ? Edit[0].working_days_4 : '',
                countrycode: Edit[0].countrycode ? Edit[0].countrycode : '',
                statecode: Edit[0].statecode ? Edit[0].statecode : '',

                contact: Edit[0].contact ? Edit[0].contact : '',

                brandNamenew: Edit[0].brandNamenew ? Edit[0].brandNamenew : '',
                brandtagline: Edit[0].brandtagline ? Edit[0].brandtagline : '',
                taglineCheck: Edit[0].taglineCheck ? Edit[0].taglineCheck : false,

                contactpersonsname: Edit[0].contactpersonsname
                    ? Edit[0].contactpersonsname
                    : '',
                contactpersonsdesignation: Edit[0].contactpersonsdesignation
                    ? Edit[0].contactpersonsdesignation
                    : '',
                preferredlanguage: Edit[0].preferredlanguage
                    ? Edit[0].preferredlanguage
                    : '',

                contact2: Edit[0].contact2 ? Edit[0].contact2 : '',
                contactpersonsname2: Edit[0].contactpersonsname2
                    ? Edit[0].contactpersonsname2
                    : '',
                contactpersonsdesignation2: Edit[0].contactpersonsdesignation2
                    ? Edit[0].contactpersonsdesignation2
                    : '',
                preferredlanguage2: Edit[0].preferredlanguage2
                    ? Edit[0].preferredlanguage2
                    : '',

                contact3: Edit[0].contact3 ? Edit[0].contact3 : '',
                contactpersonsname3: Edit[0].contactpersonsname3
                    ? Edit[0].contactpersonsname3
                    : '',
                contactpersonsdesignation3: Edit[0].contactpersonsdesignation3
                    ? Edit[0].contactpersonsdesignation3
                    : '',
                preferredlanguage3: Edit[0].preferredlanguage3
                    ? Edit[0].preferredlanguage3
                    : '',

                contact4: Edit[0].contact4 ? Edit[0].contact4 : '',
                contactpersonsname4: Edit[0].contactpersonsname4
                    ? Edit[0].contactpersonsname4
                    : '',
                contactpersonsdesignation4: Edit[0].contactpersonsdesignation4
                    ? Edit[0].contactpersonsdesignation4
                    : '',
                preferredlanguage4: Edit[0].preferredlanguage4
                    ? Edit[0].preferredlanguage4
                    : '',

                // formType:Edit[0].formtype,
            });
            if (Edit[0].state.length >= 1) {
                var index = state_arr.indexOf(Edit[0].state);
                print_city('City', index + 1);
            }
            let com = [];
            if (Edit[0].CON) {
                Edit[0].CON.forEach((element) => {
                    com = [...com, keyGen()];
                });
                setConCount(com);
            }
            let come = []
            if (Edit[0].Partner) {
                Edit[0].Partner.forEach(element => {
                    come = [...come, keyGen()]
                });
                setPartnerCount(come)
            }

            if (Edit[0].WomanOwned) {
                document.getElementById('Enterprise').checked = true;
            }
            if (Edit[0].Profile === 'Individual') {
                individual.current.checked = true;
            } else if (Edit[0].Profile === 'Company') {
                company.current.checked = true;
            }

            //   if(Edit[0].TypeofConsultation==='offline')
            //   {
            //     document.getElementById('offline').checked=true
            //     console.log("offline")

            //   }
            //   if(Edit[0].TypeofConsultation==='online')
            //   {
            //     document.getElementById('online').checked=true
            //     console.log('online')

            //   }
            //   if(Edit[0].TypeofConsultation==='both')
            //   {
            //     document.getElementById('both').checked=true
            //     console.log("both")

            //   }

            let dem = [];
            if (Edit[0].DSI) {
                Edit[0].DSI.forEach((element) => {
                    dem = [...dem, keyGen()];
                });
                setDsiCount(dem);
            }
            if (Edit[0].Location) {
                setLocationOn(Edit[0].Location);
                setLocation(Edit[0].Location_Coordinates);
            }
        }
        return () => { };
    }, []);

    const [show2, setShow2] = useState(false);

    const target2 = useRef(null);

    // var bucketName='https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/profile'
    var bucketName =
        'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/profile';
    const SpecializationRef = useRef();
    const [Addons, setAddons] = useState([]);
    const handleaddressBranch = (e, index) => {
        try {
            let addressBranchcollection = addressBranch;
            if (e === 'remove') {
                addressBranchcollection.splice(index, 1);
                let dsicount = AddressCount.slice();
                let c = dsicount.splice(index, 1);
                setAddressCount(dsicount);
            } else {
                addressBranchcollection[index] = e;
            }
            setaddressBranch(addressBranchcollection);
        } catch (error) {
            console.log(error);
        }
    };

    const isOptionDisabled = (name, value) => {
        // Add your condition to disable multiple options here
        // console.log(name)
        if (value.length > 0) {
            if (value.indexOf('Nil') >= 0) {
                return name === 'Monday' || name === 'Tuesday' || name === "Wednesday" || name === "Thursday" || name === "Friday" || name === "Saturday" || name === "Sunday";
            } else {
                return name === 'Nil'
            }
        }

    };

    let pnameRef = useRef()
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("superlink")) {
            let mType = searchParams.get("mType")
            let uType = searchParams.get("uType")
            var category = searchParams.get("category")
            let subcategory = searchParams.get("subcategory")
            let profileName = searchParams.get("profileName")
            let country = searchParams.get("country")
            let state = searchParams.get("state")
            let city = searchParams.get("city")
            // setValue((prev) => ({ ...prev,}))

            setValue((prev) => ({ ...prev, pname: profileName, country: country, state: state, city: city }))
            if (profileName.length) {
                pnameRef.current.disabled = true
            }
            console.log(category, subcategory);
            console.log(subcategory);
            console.log(searchParams.get("category"));

        }


        return () => {

        }
    }, [])
    return (
        <>
            <div className="container rounded bg-white pt-5 pb-5">
                <form id="f1" onSubmit={(e) => submit(e)}
                    novalidate
                    className={Submit2 ? 'was-validated' : ''}
                >
                    <div
                        className="modal fade"
                        id="profileUpdation"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel">
                                        Profile Picture
                                    </h6>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div id="bod" className="modal-body ">
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            {cropData ? (
                                                <div
                                                    className="box"
                                                    style={{ width: '50%', float: 'right' }}
                                                >
                                                    <img
                                                        style={{ width: '100%' }}
                                                        src={cropData}
                                                        alt="cropped"
                                                    />
                                                    {/* <button className="btn btn-success mt-2" style={{ float: "right" }}  >Save</button> */}
                                                </div>
                                            ) : (
                                                <div style={{ width: '100%' }}>
                                                    {' '}
                                                    <Cropper
                                                        style={{ height: 400, width: '100%' }}
                                                        zoomTo={false}
                                                        aspectRatio={45 / 45}
                                                        preview=".img-preview"
                                                        src={image}
                                                        viewMode={1}
                                                        minCropBoxHeight={10}
                                                        minCropBoxWidth={10}
                                                        background={false}
                                                        responsive={true}
                                                        autoCropArea={0}
                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                        onInitialized={(instance) => {
                                                            setCropper(instance);
                                                        }}
                                                        guides={true}
                                                    />
                                                    {/* <button className="btn btn-info text-light mt-2" style={{ float: "right" }} onClick={getCropData}>Ok</button> */}
                                                </div>
                                            )}
                                        </div>
                                        {/* <button className="btn btn-info text-light" style={{ float: "right" }}  onClick={getCropData}>Ok</button> */}

                                        {/* <button onClick={() => uploadme()}>Start upload</button> */}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {/* <button onClick={() => setcp(true)}>doit</button> */}
                                    {!cropData ? (
                                        <button
                                            type="button"
                                            className="btn pro-spaces-button3"
                                            onClick={getCropData}
                                        >
                                            Ok
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn pro-spaces-button3"
                                            onClick={() => uploadme()}
                                            data-bs-dismiss="modal"
                                        >
                                            Save changes
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.basicprofile === true ? <AddOn setError={setError} setAddons={setAddons} /> : <AddOn setError={setError} setAddons={setAddons} />}

                    <div className="row">
                        <div className="col-md-3 border-right ">
                            <div className="d-flex flex-column align-items-center text-center pb-3 ps-0 pe-0 pt-5 ">
                                <img
                                    id="DP"
                                    className=" mt-5"
                                    width="150px"
                                    src={`${bucketName}/${value.id}/avatar.png`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = '/blank.png';
                                    }}
                                />
                                <div id="idtestingprofilepicvalidation" className="upload">
                                    <input
                                        required={imageinserted === false && !Edit}
                                        type="file"
                                        accept="image/*"
                                        onChange={onChange}
                                        id="image"
                                        className="form-control"
                                    />
                                    <label for="image"><AiFillCamera size={"40px"} /></label>
                                    <div class="invalid-feedback" style={{ 'backgroundColor': 'white' }}>
                                        PLEASE upload PROFILE DISPLAY PICTURE

                                    </div>
                                </div>
                                <span className="text-50 mt-3 up" onClick={() => photo()}>
                                    PROFILE DISPLAY PICTURE *
                                </span>
                                <span> </span>
                            </div>
                        </div>

                        <div className="col-md-9 border-right">
                            <div className="pb-3 ps-0 pe-0 pt-5">
                                <h6>Create Profile as </h6>
                                <div className="row my-2">
                                    <div className="col-12  col-lg-6">
                                        <div class="form-check">
                                            <input
                                                class="form-check-input blink_check"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault1Individual"
                                                value={value.Profile}
                                                ref={individual}
                                                defaultChecked={value.Profile === 'Individual'}
                                                onChange={(e) =>
                                                    setValue({ ...value, Profile: 'Individual' })
                                                }
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexRadioDefault1Individual"
                                            >
                                                Individual
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12  col-lg-6">
                                        <div class="form-check">
                                            <input
                                                class="form-check-input blink_check"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault1Company"
                                                value={value.Profile}
                                                defaultChecked={value.Profile === 'Company'}
                                                ref={company}
                                                onChange={(e) =>
                                                    setValue({ ...value, Profile: 'Company' })
                                                }
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexRadioDefault1Company"
                                            >
                                                Company / Organization / Institution
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <h6>PROFILE DISPLAY NAME *</h6>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <input
                                            required
                                            type="text"
                                            ref={pnameRef}
                                            className="form-control"
                                            placeholder={
                                                value.Profile === 'Individual'
                                                    ? 'Individual Name'
                                                    : value.Profile === 'Company' &&
                                                    ' Entity / Company / Brand Name'
                                            }
                                            value={value.pname}
                                            onChange={(e) =>
                                                setValue({ ...value, pname: e.target.value })
                                            }
                                        />
                                        <div class="invalid-feedback">
                                            PLEASE ENTER PROFILE DISPLAY NAME
                                        </div>
                                    </div>
                                </div>
                                <h6 className="mt-2">Entity Information </h6>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <label className="labels">Type of Firm *</label>
                                        <select
                                            required
                                            className="form-select"
                                            value={value.typeoffirm}
                                            onChange={(e) =>
                                                setValue({ ...value, typeoffirm: e.target.value })
                                            }
                                        >
                                            <option value="" disabled selected></option>
                                            <option>Sole Proprietorship</option>
                                            <option>Partnership</option>
                                            <option>Private Limited</option>
                                            <option>Corporation</option>
                                            <option>OPC / One Person Company</option>
                                            <option>LLP / Limited Liability Partnership</option>
                                            <option>LLC / Limited Liability Company</option>
                                            <option>Financial Cooperative</option>

                                        </select>
                                        <div class="invalid-feedback">
                                            PLEASE ENTER Type of Firm
                                        </div>
                                        {PartnerCount.map((dsi, index) => {
                                            return <Partners key={dsi} id={dsi} Partner={value.Partner} handlePartner={handlePartner} index={index} ></Partners>
                                        })}
                                        <button style={{ fontSize: '12px' }} className="btn pro-spaces-button my-3" type="button" onClick={(e) => setPartnerCount([...PartnerCount, keyGen()])} >ADD MORE </button>
                                    </div>
                                </div>
                                {value.Profile === 'Individual' && (
                                    <>
                                        <h6 className="mt-4">
                                            Personal Information{' '}
                                            <p style={{ fontWeight: '400', fontSize: '15px' }}>
                                                (of the Service Provider / Proprietor)
                                            </p>
                                        </h6>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <label className="labels">First Name *</label>
                                                <input
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    value={value.firstname}
                                                    onChange={(e) =>
                                                        setValue({ ...value, firstname: e.target.value })
                                                    }
                                                />
                                                <div class="invalid-feedback">
                                                    PLEASE ENTER First Name
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label className="labels">Last Name *</label>
                                                <input
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    value={value.lastname}
                                                    onChange={(e) =>
                                                        setValue({ ...value, lastname: e.target.value })
                                                    }
                                                />
                                                <div class="invalid-feedback">
                                                    PLEASE ENTER Last Name
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <label className="labels">Gender *</label>
                                                <select
                                                    required
                                                    className="form-select"
                                                    value={value.gender}
                                                    onChange={(e) =>
                                                        setValue({ ...value, gender: e.target.value })
                                                    }
                                                >
                                                    <option value="" disabled selected></option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Transgender">Transgender</option>
                                                </select>
                                                <div class="invalid-feedback">
                                                    PLEASE SELECT Gender
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="labels">Age *</label>
                                                <input
                                                    required
                                                    type="tel"
                                                    maxLength="3"
                                                    className="form-control"
                                                    value={value.age}
                                                    onChange={(e) => {
                                                        const age = parseInt(e.target.value);
                                                        if (age < 18) {
                                                            // If age is not a number or less than 18, set an error message
                                                            e.target.setCustomValidity("Age must be at least 18 years");
                                                            setError2("Age must be at least 18 years")
                                                        } else {
                                                            // If age is valid, clear the error message
                                                            e.target.setCustomValidity("");
                                                        }
                                                        setValue({ ...value, age: e.target.value });
                                                    }}
                                                />
                                                {error2 && <div className="invalid-feedback">{error2}</div>}
                                            </div>
                                            <div className="col-md-12">
                                                {/* working */}
                                                <label className="labels">Languages Known *</label>

                                                <FormControl className="form-control">
                                                    <Select
                                                        required error={Submit2 ? value?.language?.length != 0 ? false : true : false}
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        className="form-select"
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                display: 'none',
                                                            },
                                                            '& .MuiSvgIcon-root': {
                                                                display: 'none',
                                                            },
                                                            '& .MuiSelect-select': {
                                                                padding: '0',
                                                            },
                                                        }}
                                                        value={value.language}
                                                        onChange={(e) =>
                                                            setValue({ ...value, language: e.target.value })
                                                        }
                                                        input={<OutlinedInput label="Tag" />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {languages.map((languages) => (
                                                            <MenuItem key={languages} value={languages}>
                                                                <Checkbox
                                                                    checked={
                                                                        value.language.indexOf(languages) > -1
                                                                    }
                                                                />
                                                                <ListItemText primary={languages} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.language?.length != 0 ? false : 'PLEASE SELECT Languages Known' : false}</FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 pt-1">
                                                <div className="form-check">
                                                    <input
                                                        onChange={(e) =>
                                                            setValue({
                                                                ...value,
                                                                WomanOwned: !value.WomanOwned,
                                                            })
                                                        }
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="Enterprise"
                                                    />
                                                    <label className="labels" for="Enterprise">
                                                        I am a Woman Entrepreneur
                                                        <span style={{ fontWeight: '500' }}>
                                                            {' '}
                                                            (check box to get a profile badge)
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <h6 className="mt-4">
                                    {value.Profile === 'Individual'
                                        ? 'Work / Service-related Information'
                                        : value.Profile === 'Company' &&
                                        ' Facilities / Service-related Information'}
                                </h6>
                                <div className="row mt-2">
                                    {value.Profile === 'Individual' && (
                                        <>
                                            <div className="col-md-12 mt-2">
                                                <label className="labels">
                                                    Total Work Experience *{' '}
                                                    <span style={{ fontWeight: '400' }}>
                                                        (of the Service Provider / Proprietor)
                                                    </span>
                                                </label>
                                                <input
                                                    required
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Overall experience in months / years"
                                                    // placeholder="Mention overall work experience in 00 months or 00 years"
                                                    value={value.experience}
                                                    onChange={(e) => {
                                                        if (e.target.value.length == 4) return false;
                                                        setValue({ ...value, experience: e.target.value });
                                                    }}
                                                />
                                                <div class="invalid-feedback">
                                                    PLEASE ENTER Total Work Experience
                                                </div>
                                            </div>
                                            <div className="col-md-12 pb-1">
                                                <label className="labels">
                                                    Other Organizations / Institutions you are associated with
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={value.currentn_work}
                                                    onChange={(e) =>
                                                        setValue({ ...value, currentn_work: e.target.value })
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}

                                    {value.Profile === 'Company' && (
                                        <div className="col-md-12 mt-2">
                                            <label className="labels">
                                                <>
                                                    Year of Business Inception *
                                                    <span style={{ fontWeight: '400' }}></span>
                                                </>
                                            </label>
                                            <input
                                                required
                                                type="number"
                                                className="form-control"
                                                placeholder={'Which year did you start your business ?'}
                                                // placeholder="Mention overall work experience in 00 months or 00 years"
                                                value={value.yearofb}
                                                onChange={(e) => {
                                                    if (e.target.value.length == 5) return false;
                                                    setValue({ ...value, yearofb: e.target.value });
                                                }}
                                            />
                                            <div class="invalid-feedback">
                                                PLEASE ENTER Year of Business Inception
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-md-12">
                                        <label className="labels">Specialization</label>
                                        <textarea
                                            className="form-control"
                                            value={value.specialization}
                                            ref={SpecializationRef}
                                            onChange={(e) =>
                                                setValue({ ...value, specialization: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label className="labels">
                                            {value.Profile === 'Individual'
                                                ? 'Services Provided'
                                                : value.Profile === 'Company' &&
                                                ' Facilities / Services Provided'}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            placeholder="You can add many services using ' , '"
                                            onChange={(e) =>
                                                setValue({ ...value, serviceprovided: e.target.value })
                                            }
                                        >
                                            {value.serviceprovided}
                                        </textarea>
                                    </div>


                                    {/* working */}

                                    <div className="col-md-12">
                                        <h6 className="mt-4">
                                            {value.Profile === 'Individual'
                                                ? 'Detailed Service Information '
                                                : value.Profile === 'Company' &&
                                                'Detailed Facilities / Services Information'}
                                        </h6>

                                        {DsiCount.map((dsi, index) => {
                                            return (
                                                <Dsi
                                                    SpecializationRef={SpecializationRef}
                                                    changersab={
                                                        value.Profile === 'Company' ? true : false
                                                    }
                                                    key={dsi}
                                                    id={dsi}
                                                    dsi={value.DSI}
                                                    handleDSI={handleDSI}
                                                    index={index}
                                                ></Dsi>
                                            );
                                        })}

                                        <button
                                            style={{ fontSize: '12px' }}
                                            type="button"
                                            className="btn pro-spaces-button"
                                            onClick={(e) => setDsiCount([...DsiCount, keyGen()])}
                                        >
                                            Add more
                                        </button>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label className="labels" ref={target2} onClick={() => setShow2(!show2)}>Keyword Search Preferences
                                            &nbsp;<AiFillExclamationCircle type="button" style={{ 'marginTop': '-3px', 'color': '#55374a' }}
                                            /></label>
                                        <label className="labels"></label>
                                        <input onChange={(e) => setValue((prev) => ({
                                            ...prev, serviceKeywordsss: e.target.value
                                        }))} className="form-control" type={'text'} value={value.serviceKeywordsss} name='serviceTitle' />

                                        <Overlay target={target2.current} show={show2} placement="top">
                                            {(props) => (
                                                <Tooltip id="overlay-example" {...props} placement="top">
                                                    Mention search words that can help your prospective clients/customers find you easily
                                                </Tooltip>
                                            )}
                                        </Overlay>
                                    </div>
                                </div>
                            </div>

                            <div className="p-3 ps-0 pe-0 pt-2">
                                <h6 className="mt-4">Business Information</h6>

                                <h6 style={{ fontSize: '0.8rem' }}>
                                    Head Office / Registered Office / Main Branch{' '}
                                </h6>
                                {value.Profile === 'Company' &&
                                    <>
                                        <div className="col-md-12">
                                            <label className="labels">Entity Name *</label>
                                            <input
                                                required
                                                type="text"
                                                className="form-control"
                                                value={value.EntityName}
                                                onChange={(e) =>
                                                    setValue({ ...value, EntityName: e.target.value })
                                                }
                                            />
                                            <div class="invalid-feedback">
                                                PLEASE ENTER Entity Name
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="labels">Brand Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={value.brandNamenew}
                                                onChange={(e) =>
                                                    setValue({ ...value, brandNamenew: e.target.value })
                                                }
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="labels">Entity / Brand Tag line</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={value.brandtagline}
                                                onChange={(e) =>
                                                    setValue({ ...value, brandtagline: e.target.value })
                                                }
                                            />
                                        </div>
                                        <div className="col-12 pt-1">
                                            <div className="form-check">
                                                <input
                                                    onChange={(e) =>
                                                        setValue({
                                                            ...value,
                                                            taglineCheck: !value.taglineCheck,
                                                        })
                                                    }
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="Enterprise"
                                                    checked={value.taglineCheck}
                                                />
                                                <label className="labels" for="Enterprise">
                                                    I want the tag line to be displayed below the Profile Display Name
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                }
                                <label className="labels mt-3">Address *</label>
                                <div className="col-md-12">
                                    <label className="labels">
                                        Building / Mall / Property Name{' '}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_building}
                                        onChange={(e) =>
                                            setValue({ ...value, address_building: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Door / Shop No.</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_door}
                                        onChange={(e) =>
                                            setValue({ ...value, address_door: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Floor </label>
                                    <select
                                        className="form-select"
                                        value={value.address_floor}
                                        onChange={(e) =>
                                            setValue({ ...value, address_floor: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option>Basement</option>
                                        <option>Ground</option>
                                        <option>Mezzanine</option>
                                        {x.map((elem, index) => {
                                            let temp = index + 1;
                                            if (index < 10) temp += 10;
                                            if (index + 1 <= 10 || index + 1 >= 20)
                                                if (temp % 10 === 1)
                                                    return <option>{index + 1}st</option>;
                                                else if (temp % 10 === 2)
                                                    return <option>{index + 1}nd</option>;
                                                else if (temp % 10 === 3)
                                                    return <option>{index + 1}rd</option>;
                                                else return <option>{index + 1}th</option>;
                                            else return <option>{index + 1}th</option>;
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-12">
                                    <label className="labels">Street / Lane / Road Name </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_street}
                                        onChange={(e) =>
                                            setValue({ ...value, address_street: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Area / Locality Name </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address1}
                                        onChange={(e) =>
                                            setValue({ ...value, address1: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Nearest Landmark</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_nearest}
                                        onChange={(e) =>
                                            setValue({ ...value, address_nearest: e.target.value })
                                        }
                                    />
                                </div>

                                <StateCity setValue={setValue} value={value} Edit={Edit} />

                                <div className="col-md-12">
                                    <label className="labels">Pin code / Zip code *</label>
                                    <input
                                        required
                                        type="tel"

                                        className="form-control"
                                        value={value.pincode}
                                        onChange={(e) => {
                                            setValue({ ...value, pincode: e.target.value });
                                        }}
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Pin code / Zip code
                                    </div>
                                </div>
                                <br />
                                <MapWithSwitch
                                    Location={Location}
                                    setLocation={setLocation}
                                    LocationOn={LocationOn}
                                    setLocationOn={setLocationOn}
                                    key={'aouhduahdiuqbduiqderhetjyvq65564'}
                                />
                                <br />

                                <div className="col-md-12">
                                    <label className="labels">Do you have a Trade Licence ? *</label>
                                    <select
                                        required
                                        className="form-select"
                                        value={value.licence}
                                        onChange={(e) =>
                                            setValue({ ...value, licence: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                        <option value="N/A">N/A</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Do you have a Trade Licence ?
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Is your entity GST registered ? *</label>
                                    <select
                                        required
                                        className="form-select"
                                        value={value.gst}
                                        onChange={(e) => setValue({ ...value, gst: e.target.value })}
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Is your entity GST registered ?
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">
                                        Website / Web App, Mobile App
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Names of all Apps with ',' separation"
                                        value={value.website}
                                        onChange={(e) =>
                                            setValue({ ...value, website: e.target.value })
                                        }
                                    />
                                </div>
                                <label className="labels mt-3">Work Timings *</label>
                                <div className="col-12 pt-1">
                                    <div className="form-check">
                                        <input
                                            onChange={(e) =>
                                                setValue({
                                                    ...value,
                                                    worktimimng: !value.worktimimng,
                                                })
                                            }
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Enterprsdffise"
                                            checked={value.worktimimng}
                                        />
                                        <label className="labels" for="Enterprsdffise">
                                            We are open 24 hours
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label className="labels">Working Days (full) {value.worktimimng ? '' : '*'}</label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple required={Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : true : false} error={Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdaysfullnew} onChange={(e) => setValue({ ...value, workingdaysfullnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays.map((arrayofdays) => (
                                                <MenuItem key={arrayofdays} value={arrayofdays}
                                                    disabled={isOptionDisabled(arrayofdays, value.workingdaysfullnew)}
                                                >
                                                    <Checkbox checked={value.workingdaysfullnew.indexOf(arrayofdays) > -1} />
                                                    <ListItemText primary={arrayofdays} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : 'PLEASE SELECT Working Days (full)' : false}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Hours {value.worktimimng ? '' : '*'}</label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_1}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_1: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_2}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_2: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />

                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Break Time {value.worktimimng ? '' : '*'}</label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.break_time_1}
                                                onChange={(e) =>
                                                    setValue({ ...value, break_time_1: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox type={'break'} />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Break Time
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.break_time_2}
                                                onChange={(e) =>
                                                    setValue({ ...value, break_time_2: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox type={'break'} />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Break Time
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Days (half) {value.worktimimng ? '' : '*'}</label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple required={Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : true : false} error={Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdayshalfnew} onChange={(e) => setValue({ ...value, workingdayshalfnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays2.map((arrayofdays2) => (
                                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                                    disabled={isOptionDisabled(arrayofdays2, value.workingdayshalfnew)}
                                                >
                                                    <Checkbox checked={value.workingdayshalfnew.indexOf(arrayofdays2) > -1} />
                                                    <ListItemText primary={arrayofdays2} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : 'PLEASE SELECT Working Days (half)' : false}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Hours {value.worktimimng ? '' : '*'}</label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_3}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_3: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_4}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_4: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />

                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Weekly Off *</label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple required error={Submit2 ? value?.workingdaysOffnew?.length != 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdaysOffnew} onChange={(e) => setValue({ ...value, workingdaysOffnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays2.map((arrayofdays2) => (
                                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                                    disabled={isOptionDisabled(arrayofdays2, value.workingdaysOffnew)}
                                                >
                                                    <Checkbox checked={value.workingdaysOffnew.indexOf(arrayofdays2) > -1} />
                                                    <ListItemText primary={arrayofdays2} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.workingdaysOffnew?.length != 0 ? false : 'PLEASE SELECT Weekly Off' : false}</FormHelperText>
                                    </FormControl>
                                </div>

                                <h6 className="mt-4">Contact Information</h6>
                                <div className="col-md-12">
                                    <label className="labels">Email Id *</label>
                                    <input
                                        required
                                        type="email"
                                        className="form-control"
                                        value={value.email}
                                        onChange={(e) =>
                                            setValue({ ...value, email: e.target.value })
                                        }
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Email Id
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Country Code *</label>
                                    <select
                                        required

                                        className="form-select"
                                        value={value.countrycode}
                                        onChange={(e) =>
                                            setValue({ ...value, countrycode: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="+91">+91</option>

                                    </select>
                                    <div class="invalid-feedback">
                                        PLEASE SELECT Country Code
                                    </div>
                                </div>
                                {/* <div className="col-md-12"><label className="labels">State Code *</label><input type="number" required className="form-control" value={value.statecode} onChange={(e) => setValue({ ...value, statecode: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER State Code
                                    </div>
                                </div> */}

                                <>
                                    {CONCount.map((dsi, index) => {
                                        return (
                                            <MobileAddOn
                                                languages={languages}
                                                key={dsi}
                                                id={dsi}
                                                con={value.CON}
                                                handleCON={handleCON}
                                                index={index}
                                            ></MobileAddOn>
                                        );
                                    })}
                                    <button
                                        style={{ fontSize: '12px' }}
                                        disabled={CONCount.length >= 5}
                                        className="btn pro-spaces-button"
                                        type="button"
                                        onClick={(e) => setConCount([...CONCount, keyGen()])}
                                    >
                                        ADD ANOTHER MOBILE / LANDLINE NUMBER{' '}
                                    </button>
                                </>
                                <>
                                    <div className="col-md-12">
                                        <label className="labels">Preferred Mode of Communication *</label>
                                        <FormControl className="form-control">
                                            <Select
                                                id="demo-multiple-checkbox"
                                                required error={Submit2 ? value?.preferdmodeofcommunication?.length != 0 ? false : true : false}
                                                multiple
                                                className="form-select"
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        display: "none"
                                                    }, '& .MuiSvgIcon-root': {
                                                        display: 'none',
                                                    },
                                                    '& .MuiSelect-select': {
                                                        padding: '0'
                                                    }
                                                }}
                                                value={value.preferdmodeofcommunication}
                                                onChange={(e) =>
                                                    setValue({ ...value, preferdmodeofcommunication: e.target.value })
                                                }
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {preferdmodeofcommunication.map((preferdmodeofcommunication) => (
                                                    <MenuItem key={preferdmodeofcommunication} value={preferdmodeofcommunication}>
                                                        <Checkbox checked={value.preferdmodeofcommunication?.indexOf(preferdmodeofcommunication) > -1} />
                                                        <ListItemText primary={preferdmodeofcommunication} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.preferdmodeofcommunication?.length != 0 ? false : 'PLEASE ENTER Preferred Mode of Communication' : false}</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <label className="labels">Preferred Days and Time of Communication via Voice Calls *</label>
                                        <div className="form-check">
                                            <input
                                                required
                                                className="form-check-input"
                                                checked={value.preferedDaysandTime == 'Only during Working Hours'}
                                                type="radio" onChange={(e) => {
                                                    setValue({
                                                        ...value, preferedDaysandTime: e.target.value
                                                    })
                                                }}
                                                value="Only during Working Hours"
                                                name="pdat"
                                                id="asdasdasdasd112" />
                                            <label className="form-check-label" style={{ fontWeight: 'normal' }} for="asdasdasdasd112">
                                                Only during Working Hours
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                required
                                                checked={value.preferedDaysandTime == 'Anytime'}
                                                onChange={(e) => {
                                                    setValue({
                                                        ...value, preferedDaysandTime: e.target.value
                                                    })
                                                }}
                                                value="Anytime"
                                                name="pdat"
                                                id="asdasdasdasd12"
                                            />
                                            <label className="form-check-label" style={{ fontWeight: 'normal' }} for="asdasdasdasd12">
                                                Anytime during Working Hours and Specified Timings after Working Hours
                                            </label>
                                            <div class="invalid-feedback">
                                                Preferred Days and Time of Communication via Voice Calls
                                            </div>
                                        </div>
                                    </div>
                                    {value.preferedDaysandTime == 'Anytime' &&
                                        <>
                                            <label className="labels mt-2">Outside Working Hours</label>
                                            {OutCount.map((ele, index) => {
                                                return <Outcount
                                                    index={index}
                                                    prev={Out}
                                                    onChange={setOutCount}
                                                    key={ele}
                                                    outHandle={outHandle}
                                                />
                                            })}
                                            <button style={{ fontSize: '12px' }} onClick={(e) => {
                                                setOutCount((prev) => [...prev, keyGen()])
                                            }}
                                                className={'btn pro-spaces-button mt-3 mb-3'}
                                                type="button"
                                            >Add more</button>
                                        </>
                                    }
                                    <div className="col-md-12">
                                        <label className="labels">Responsiveness</label>
                                        <select
                                            className="form-select"
                                            value={value.responiceness}
                                            onChange={(e) =>
                                                setValue({ ...value, responiceness: e.target.value })
                                            }
                                        >
                                            <option value="" disabled selected></option>
                                            <option value="Immediate">Immediate</option>
                                            <option value="Within a few hours on working days">Within a few hours on working days</option>
                                            <option value="Within one working day">Within one working day</option>
                                            <option value="Within one week">Within one week</option>
                                            <option value="Within one month">Within one month</option>
                                        </select>
                                    </div>
                                </>


                                {convert == false ? (
                                    <div className="col-12 mt-5">
                                        <h6>
                                            {' '}
                                            {value.Profile === 'Individual'
                                                ? 'Portfolio of Services'
                                                : value.Profile === 'Company' &&
                                                'Portfolio of Services / Facilities'}
                                        </h6>
                                        {value.Profile === 'Individual' ? (
                                            <p>
                                                As a Service Provider, you need to create an impression
                                                among those in seek of your services. Show them your
                                                service-related photographs and also give a brief
                                                description of your services to stand-out amongst your
                                                competitors so that people can short-list you, give you
                                                a rating, refer you to others, follow your posts, and
                                                communicate with you.
                                            </p>
                                        ) : (
                                            value.Profile === 'Company' && (
                                                <p>
                                                    As a Service Provider, you need to create an
                                                    impression among those in seek of your services. Show
                                                    them your facility / service-related photographs and
                                                    also give a brief description of your services /
                                                    facilities to stand-out amongst your competitors so
                                                    that people can short-list you, give you a rating,
                                                    refer you to others, follow your posts, and
                                                    communicate with you.
                                                </p>
                                            )
                                        )}
                                        <Port
                                            value={value}
                                            setValue={setValue}
                                            porttitlechanger={1}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {/* <div className="col-md-12">
                  <br />
                  <button
                    style={{ fontSize: '12px' }}
                    className="btn pro-spaces-button"
                    type="button"
                    onClick={handleShow}
                  >
                    Add a branch
                  </button>
                  <br />
                </div>
                <Modal show={show1} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    To Add Additional Branches of your Service / Business
                    Entity, you must UPGRADE to a Prime membership
                  </Modal.Body>
                </Modal> */}
                                <div className="col-md-12 mt-4">
                                    <Selfdeclaration type='normal' setSelfDeclaration={setSelfDeclaration} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 style={{ color: 'red' }}>
                        <center>{error ? error : ''}</center>
                    </h6>
                    <div className="mt-2 text-center">
                        {!loading && (
                            <button
                                className="btn mb-3 mt-4"
                                style={{ backgroundColor: '#55374a', color: 'white' }}
                                onClick={() => setSubmit2(true)}
                            >
                                {Edit ? 'Update' : 'Create Profile'}
                            </button>
                        )}
                        {loading && (
                            <button
                                className="btn  mb-3 mt-4"
                                disabled
                                style={{ backgroundColor: '#55374a', color: 'white' }}
                            >
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
}
