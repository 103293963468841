import React, { useContext, useEffect, useState } from 'react'
// import {Post} from "../../../Components/Post/Post"
// import { Post } from '../../../Components/PostFree/repost'
import Axios from 'axios'
import './Home.css'
// import { PosttoolBar } from "../../../Components/PostToolBar/PostToolBar";
// import {Notification} from "../../../Components/Notifications/Notification"

import { Lobby2 } from '../../../Components/Lobby/Lobby2'
import { ProContext } from '../../../App'
let page = 0
let fetchreq = false
export const Home = () => {
  let proContextt = useContext(ProContext)
  // console.log(proContextt.splash)
  // const [data, setdata] = useState([])
  // const [state, setstate] = useState(false) //ini
  // const [load, setload] = useState(true)
  // const [error, seterror] = useState('')
  // const [output, setoutput] = useState([])
  // const [first, setfirst] = useState(1)
  // function makeid (length) {
  //   length = 5
  //   var result = ''
  //   var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  //   var charactersLength = characters.length
  //   for (var i = 0; i < length; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  //   }
  //   return result
  // }

  // function getCookie (name) {
  //   const value = `; ${document.cookie}`
  //   const parts = value.split(`; ${name}=`)
  //   if (parts.length === 2)
  //     return parts
  //       .pop()
  //       .split(';')
  //       .shift()
  // }

  function fk () {
    // var cook = getCookie('Splash')

    // console.log(document.cookie);
    // if (cook) {
    //     document.getElementById('nomodalforsabaribtn').click()

    // }else{
    if (mount) {
      return false
    } else {
      return true
    }
    // }
  }

  useEffect(() => {
    fk()
  }, [])
  const [mount, setmount] = useState(false)
  useEffect(() => {
   
    setmount(true)

    // if(proContextt.splash){
    //     console.log(proContextt.splash)
    //     setTimeout(() => {
    //         let a = fk()
    //         console.log(a)
    //         console.log('getout2')
    //         if (a) {
    //           document.getElementById('nomodalforsabaribtn').click()
    //         }
    //       }, 6000)
    // }else{
    //   console.log('getout')
        

    // }
   
    return () => {
      setmount(false)
    }
  }, [])

  // const [FetchStatus, setFetchStatus] = useState(false)
  // const FetchMessage = last => {
  //   // console.log(page)
  //   Axios.post('/api/Free/getpost', { first })
  //     .then(response => {
  //       setload(false)
  //       if (response.data.length > 0) {
  //         setfirst(first + response.data.length)
  //       }
  //       // console.log('success')
  //       // console.log(response.data)
  //       if (response.data.length === 0) {
  //         fetchreq = false
  //         return console.log('done')
  //       }
  //       const postSummaries = response.data.map(post => ({
  //         id: post._id,
  //         key: post.id,
  //         Post_Id: post.Post_Id,
  //         Content: post.Content,
  //         Author: post.Account_id,
  //         File: post.File,
  //         Posted_On: post.Posted_On,
  //         Commentcount: post.commentcount,
  //         merge: post.string,
  //         merge2: post.string2,
  //         com: post.com,
  //         Likecount: post.likecount,
  //         Likestatus: post.likestatus,
  //         profilepic: post.profilepic
  //       }))
  //       let t = postSummaries.map(number => {
  //         return <Post key={number.id} ids={makeid()} dat={number}></Post>
  //       })
  //       fetchreq = false
  //       setload(false)
  //       setoutput([...output, ...t])
  //     })
  //     .catch(res => {
  //       fetchreq = false
  //       setload(false)
  //       setdata(false)
  //       seterror('Something went wrong')
  //       console.log('Something went wrong')
  //       setFetchStatus(false)
  //     })
  //   setstate(true)
  // }

  // useEffect(() => {
  //   console.log(first)
  // }, [first])

  // async function ScrollListener (e) {
  //   console.log('lllllllllllll')
  //   let P = Math.abs(
  //     (e.target.scrollTop * 100) /
  //       (e.target.scrollHeight - e.target.clientHeight)
  //   )
  //   P = Math.round(P)
  //   console.log(P)
  //   console.log(fetchreq)
  //   if (P > 50) {
  //     if (!fetchreq) {
  //       fetchreq = true
  //       console.log(page)
  //       FetchMessage()
  //       // setoutpot([...outpot, ...ans]);
  //     }
  //   }
  // }

  // Loadpost()
  // var loader = {
  //   width: '5rem',
  //   height: '5rem'
  // }
  return (
    <div className='container-fluid '>
      {/* <div className="row">
                <div id='postFt' onScroll={(e) => ScrollListener(e)} className="dfrty  pb-0 pt-0 pt-5 mt-1 col-lg-8 col-12 ijfsvwijiviwrvnvnjw">
                    <div className="pt-3"></div>
                    {output ? output : ''}
                    {load ? <div className="text-center "> <div style={loader} className="spinner-border text-dark"></div></div> : output.length === 0 && <center className='mt-5'>No PRO- post to show !</center>}
                </div>
                <div className="col-4 d-none d-lg-block "> <Ads /></div>
            </div> */}

      <Lobby2 />
    </div>
  )
}
