import React, { useState, useEffect } from 'react'
import { MenuProps, languages } from '../../Utility/languages'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { countryData } from '../../Utility/Country';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { updateUserQuicklocation } from '../../redux/Prochure/userSlice';
import { keyGen } from '../../Utility/helper';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../Utility/notify';
import { IoLocationSharp } from 'react-icons/io5';

function QuickLocation() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let userData = useSelector((state) => state.user.value)
    const [CountryOptions, setCountryOptions] = useState([])
    const [StateOptions, setStateOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [Data, setData] = useState({
        type: userData?.quickLocation?.type,// global, custom
        data: userData?.quickLocation?.data ? userData.quickLocation.data : [],
        country: userData?.quickLocation?.data.length ? userData.quickLocation.data.map((elem) => elem.country) : [],
        state: userData?.quickLocation?.state ? userData.quickLocation.state : [],
        city: userData?.quickLocation?.city ? userData.quickLocation.city : [],
    })
    // console.log(userData.quickLocation.data.map((elem)=>elem.country));
    useEffect(() => {
        countryData.map((e) => {
            setCountryOptions((prev) => [...prev, e.country])
        })
        return () => {
        }
    }, [])

    useEffect(() => {
        if (show) {
            setData({
                type: userData?.quickLocation?.type,// global, custom
                data: userData?.quickLocation?.data ? userData.quickLocation.data : [],

                country: userData?.quickLocation?.data.length ? userData.quickLocation.data.map((elem) => elem.country) : [],
                state: userData?.quickLocation?.data.length ? userData.quickLocation.data.map((elem) => elem.states.map((element) => element.state)).flat() : [],
                city: userData?.quickLocation?.data.length ? userData.quickLocation.data.map((elem) => elem.states.map((element) => element.cities.map(elementt => `${elementt} (${elem.country}, ${element.state})`))).flat(2).filter((e) => e.length > 0) : []

            })
        }
        return () => {

        }
    }, [show])


    useEffect(() => {
        setStateOptions([])
        countryData.map((e) => {
            if (Data.country.indexOf(e.country) >= 0) {
                console.log(Data);
                console.log(Data.country.indexOf(e.country >= 0));
                e.states.map((el) => {
                    setStateOptions((prev) => [...prev, el.state])
                })
            }
        })
        return () => {
        }
    }, [Data.country])

    useEffect(() => {
        setCityOptions([])
        countryData.map((e) => {
            if (Data.country.indexOf(e.country) >= 0) {
                e.states.map((el) => {
                    if (Data.state.indexOf(el.state) >= 0)
                        el.cities.map((elem) => {
                            setCityOptions((prev) => [...prev, { value: elem, text: `${elem} (${e.country}, ${el.state})` }])
                        })
                })
            }
        })
        return () => {
        }
    }, [Data.state])

    useEffect(() => {
        console.log(CityOptions);

        return () => {

        }
    }, [CityOptions])


    const update = () => {
        console.log(Data);


        // const worldData = [
        //   {
        //     country: "India",
        //     states: [
        //       {
        //         state: "Andaman & Nicobar",
        //         cities: ["Alipur", "Andaman Island"]
        //       },
        //       {
        //         state: "kerala",
        //         cities: ['Kollam', 'Thiruvananthapuram', 'Alappuzha']
        //       },
        //       {
        //         state: "Karnataka",
        //         cities: ['Bengaluru', 'Mysuru', 'Hubballi', 'Mangaluru']
        //       },
        //       {
        //         state: "Andhra Pradesh",
        //         cities: ['Nalgonda', 'City2', 'City3']
        //       }
        //     ]
        //   },
        //   {
        //     country: "America",
        //     states: [
        //       {
        //         state: "New York",
        //         cities: ['New York City', 'Buffalo', 'Rochester']
        //       },
        //       {
        //         state: "Wisconsin",
        //         cities: ['West Bend']
        //       }
        //     ]
        //   },
        //   // Add more data objects if needed
        // ];

        // const cities = [
        //   "Alipur (India, Andaman & Nicobar)",
        //   "Andaman Island (India, Andaman & Nicobar)",
        //   "West Bend (America, Wisconsin)",
        //   "Nalgonda (India, Andhra Pradesh)"
        // ];
        // const countries = ['India', 'America'];
        // const states = ['Andaman & Nicobar', 'Andhra Pradesh', 'Wisconsin'];

        const data = [];

        Data.country.forEach(country => {
            const countryDatas = {
                country,
                states: []
            };

            let cdata = countryData.find(obj => obj.country == country)

            Data.state.forEach(state => {
                const stateData = {
                    state,
                    cities: []
                };

                if (cdata.states.find(obj => obj.state == state)) {
                    Data.city.forEach(city => {
                        const cityData = city.split(' (')[0];

                        const countryRegex = /(?<=\()\w+(?=\s*,)/;
                        const stateRegex = /(?<=,\s).+(?=\))/;

                        const cityCountry = city.match(countryRegex)[0];
                        const cityState = city.match(stateRegex)[0];
                        if (cityCountry === country && cityState === state) {
                            stateData.cities.push(cityData);
                        }
                    });


                    countryDatas.states.push(stateData);
                }
            });


            data.push(countryDatas);

        });

        console.log(JSON.stringify(data));

        // let result = Data.country.map(country => {

        //     let countryDatas = {
        //       country: country,
        //       states: [],
        //       cities: []
        //     };

        //     let countryInfo = countryData.find(data => data.country.toLowerCase() === country.toLowerCase());
        //     console.log(countryInfo);
        //     if (countryInfo) {
        //       countryDatas.states = countryInfo.states
        //         .filter(state => Data.state.includes(state.state))
        //         .map(state => state.state);

        //       countryDatas.cities = countryInfo.states
        //         .filter(state => Data.state.includes(state.state))
        //         .reduce((acc, state) => acc.concat(state.cities), [])
        //         .filter(city => Data.city.includes(city));
        //     }

        //     return countryDatas;
        //   });
        // console.log(result);
        let temp = Data
        temp.data = data
        if (localStorage.getItem('User')) {

            axios.post('/api/User/updateQuickLocation', { Data: { type: Data.type, data: data } }).then((res) => {
                dispatch(updateUserQuicklocation({ quickLocation: Data }))
                notify('Updated', true)
                setTimeout(() => {
                    window.location.reload();
                }, 1000);

            }).catch((err) => {
                notify("something went wrong !", false)
            })
        } else {
            dispatch(updateUserQuicklocation({ quickLocation: Data }))
            handleClose()
            notify('Updated', true)

            navigate('/lobby')

        }
    }


    useEffect(() => {
        console.log(Data);

        return () => {

        }
    }, [Data])

    return (
        <>
            <p onClick={handleShow} className='m-0' ><span className='ps-2 pe-1'><IoLocationSharp size={20} /></span><span className='ps-3'>SELECT LOCATION</span> </p>

            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title><h6>SELECT LOCATION</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body> <div>
                    <p>You will be able to See Profiles and Information of Regions / Locations of your Preference.</p>

                    <div class="form-check">
                        <input checked={Data.type == 'global'} onChange={() => { setData({ ...Data, type: 'global', country: [], state: [], city: [] }) }} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            GLOBAL
                        </label>
                    </div>
                    <div class="form-check">
                        <input checked={Data.type == 'custom'} onChange={() => { setData({ ...Data, type: 'custom' }) }} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                        <label class="form-check-label" for="flexRadioDefault2">
                            MY CHOICE
                        </label>
                    </div>
                    {Data.type == 'custom' && <>
                        <div>
                            <label className="labels  fw-light">Country *</label>

                            <FormControl className="form-control">
                                <Select
                                    id="demo-multiple-checkbox"
                                    multiple
                                    className="form-select"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            display: "none"
                                        }, '& .MuiSvgIcon-root': {
                                            display: 'none',
                                        },
                                        '& .MuiSelect-select': {
                                            padding: '0'
                                        }
                                    }}
                                    value={Data.country}
                                    onChange={(e) => {
                                        setData({ ...Data, country: e.target.value })
                                    }}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {CountryOptions.map((options) => (
                                        <MenuItem key={options} value={options}>
                                            <Checkbox checked={Data.country.indexOf(options) > -1} />
                                            <ListItemText primary={options} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={`${Data.country.length?'':'invisible'}`}>
                            <label className="labels  fw-light">State </label>

                            <FormControl className="form-control">
                                <Select
                                    id="demo-multiple-checkbox"
                                    multiple
                                    className="form-select"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            display: "none"
                                        }, '& .MuiSvgIcon-root': {
                                            display: 'none',
                                        },
                                        '& .MuiSelect-select': {
                                            padding: '0'
                                        }
                                    }}
                                    value={Data.state}
                                    onChange={(e) =>
                                        setData({ ...Data, state: e.target.value })
                                    }
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {StateOptions.map((options) => (
                                        <MenuItem key={keyGen() + 's'} value={options}>
                                            <Checkbox checked={Data.state.indexOf(options) > -1} />
                                            <ListItemText primary={options} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={`${Data.state.length?'':'invisible'}`}>
                            <label className="labels  fw-light">City </label>

                            <FormControl className="form-control">
                                <Select
                                    id="demo-multiple-checkbox"
                                    multiple
                                    className="form-select"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            display: "none"
                                        }, '& .MuiSvgIcon-root': {
                                            display: 'none',
                                        },
                                        '& .MuiSelect-select': {
                                            padding: '0'
                                        }
                                    }}
                                    value={Data.city}
                                    onChange={(e) =>
                                        setData({ ...Data, city: e.target.value })
                                    }
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {CityOptions.map((options) => (
                                        <MenuItem key={keyGen()} value={options.text}>
                                            <Checkbox checked={Data.city.indexOf(options.text) > -1} />
                                            <ListItemText primary={options.text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </>
                    }
                    <div>

                    </div>
                </div></Modal.Body>
                <Modal.Footer>

                    <button type='button' onClick={() => {
                        update()
                    }} className='btn pro-spaces-button2'> Save </button>

                </Modal.Footer>
            </Modal>
        </>

    )
}

export default QuickLocation