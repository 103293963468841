import axios from 'axios'
import { createContext, useEffect, useState, useContext, useRef } from 'react'
import { Carousel, CarouselItem } from 'react-bootstrap'
import { AiTwotoneFileAdd } from 'react-icons/ai'
import './proposttoolbar.css'
import { SearchInput } from './postToolBar'

import { print_state, print_city, state_arr } from '../../Utility/stateCity'

import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

let PostContext = createContext()

export const ProPostView1 = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  var id = searchParams.get('Ac_id')

  const [Posts, setPosts] = useState(<></>)

  const Load = () => {
    axios
      .post('/api/User/ProPosts', { id })
      .then(res => {
        console.log(res.data)
        setPosts(
          <Singles key={'key' + '12345'} index={6576 & 87} data={res.data[0]} />
        )
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    Load()

    console.log('loding')
  }, [])

  return <div className='mt-5 container pt-5'>{Posts}</div>
}
export const Singles = props => {
  console.log(props)
  const [Error, setError] = useState()

  const [Posts, setPosts] = useState([])

  const [Data, setData] = useState({
    titleA: props.data.titleA,
    titleB: props.data.titleB,
    Discription: props.data.Discription,
    User: props.data.User,
    state: props.data.state,
    id: props.data._id,
    cat: props.data.category,
    sub: props.data.subcategory
  })
  const [Files, setFiles] = useState(props.data.Files)
  const [Titles, setTitles] = useState([])

  useEffect(() => {
    print_state('State1')
    axios
      .get('/api/api/getprocategory')
      .then(res => {
        if (res.status === 200) {
          console.log('workd')
          setCat(res.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  const formData = new FormData()
  const [UploadPercentage, setUploadPercentage] = useState(0)

  useEffect(() => {
    console.log(props.data)

    return () => {}
  }, [props])

  function Validation () {
    if (!Data.cat) {
      setError('Select category')
    }

    if (!Data.sub) {
      setError('Select sub category')
    }
    setError('')

    saveChanges()
  }

  const saveChanges = () => {
    console.log(Files, Data)
    // return
    formData.delete('file')
    formData.delete('data')
    let title1 = []
    let file = []
    let title2 = []
    let temp = [...Files]
    temp.forEach((element, index) => {
      if (element.name) {
        title1 = [...title1, element]
      } else {
        let s = element
        file = [...file, element.file]
        // s.file = ''
        title2 = [...title2, s]
      }
    })
    console.log(title1)
    console.log(title2)
    console.log(file)
    let data = {
      Data,
      title1,
      title2
    }
    data = JSON.stringify(data)
    console.log(data)
    console.log(file)
    file.forEach(elem => {
      formData.append('files', elem)
    })
    formData.append('data', data)

    const options = {
      onUploadProgress: progressEvent => {
        console.log(progressEvent)
        const { loaded, total } = progressEvent
        let percent = Math.floor(((loaded / 1000) * 100) / (total / 1000))
        console.log(percent)
        // move(percent)/
        if (percent < 100) {
          setUploadPercentage(percent)
          console.log(UploadPercentage)
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    axios
      .post('/api/User/ProPostUpdate', formData, options)
      .then(res => {
        console.log(res)
        window.history.back()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [subcategory, setSubcategory] = useState(false)
  const [category, setCategory] = useState(false)
  const [cat, setCat] = useState([])
  const [Params, setParams] = useState({
    cat: '',
    sub: '',
    state: '',
    city: ''
  })

  const [dataFetched, setdataFetched] = useState([])

  var sab = []
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach(element => {
        sab.push(<option value={element._id}>{element.category}</option>)
      })
      setCategory(sab)
    } else {
      console.log('nothing')
    }
  }, [cat])

  function Cityshow (e) {
    setData({ ...Data, state: e.target.value })
    var index = document.getElementById(e.target.id).selectedIndex
    print_city('City', index)
    //     setData((prev) => ({ ...prev, State: e.target.value }))
  }
  const sabari = e => {
    setData({ ...Data, cat: e })
    console.log(e)
    console.log(e)
    var anadhan
    console.log(cat)
    cat.forEach(element => {
      console.log(element._id)
      if (element._id === e) {
        console.log(element._id)
        anadhan = element.sub.map(elements => {
          return <option value={elements._id}>{elements.sub}</option>
        })
      } else {
        console.log(element._id)
      }
    })
    setSubcategory(anadhan)
  }

  useEffect(() => {
    if (cat.length) {
      sabari(props.data.category)
    }
  }, [cat])

  return (
    <div>
      <PostContext.Provider
        value={{ setFiles, Files, setTitles, Titles, setData }}
      >
        <div
          type='button'
          className=''
          data-bs-toggle='modal'
          data-bs-target={'#asd1687126873' + props.index}
        ></div>
        {props.data.Status1[0] ? (
          props.data.Status1[0].pname ? (
            <div>Taged Person&nbsp;:&nbsp; {props.data.Status1[0].pname}</div>
          ) : (
            ''
          )
        ) : (
          ''
        )}

        <div className='modal-bod'>
          <div>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <label>Category</label>
                <select
                  id='sel1'
                  className='form-select'
                  onChange={e => sabari(e.target.value)}
                  value={Data.sub}
                >
                  <option value='' disabled selected></option>
                  {category ? category : ''}
                </select>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <label>Sub Category</label>
                <select
                  id='sel2'
                  className='form-select'
                  onChange={e => setData({ ...Data, sub: e.target.value })}
                  value={Data.sub}
                >
                  <option value='' disabled selected></option>
                  {subcategory ? subcategory : ''}
                </select>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <label>State</label>

                <select
                  onChange={e => Cityshow(e)}
                  id='State1'
                  name='City'
                  className='form-select'
                  value={Data.state}
                ></select>
              </div>
            </div>
          </div>

          <div className=''>
            <div className='row g-1 mt-2 mb-2'>
              <input
                placeholder='Title '
                value={Data.titleA}
                onChange={e => setData({ ...Data, titleA: e.target.value })}
                className='form-control'
              ></input>
              <input
                placeholder='Title '
                value={Data.titleB}
                onChange={e => setData({ ...Data, titleB: e.target.value })}
                className='form-control d-none'
              ></input>
              <textarea
                placeholder='Type Discription here !'
                value={Data.Discription}
                onChange={e =>
                  setData({ ...Data, Discription: e.target.value })
                }
                className='form-control'
                rows={6}
              ></textarea>
            </div>

            <div id='uploadContainer' className='container-fluid mt-2 mb-2'>
              <SliderPost />
            </div>
            <hr />
            <SearchInput />
          </div>
        </div>
        <p className='text-danger m-0'>{Error ? Error : ''}</p>

        <div className='mt-3 mb-3'>
          {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
          <button
            type='button'
            className='btn pro-spaces-button3'
            onClick={e => Validation()}
          >
            Save
          </button>
          <p className='invisible'>mknn</p>
        </div>
      </PostContext.Provider>
    </div>
  )
}
export const SliderPost = () => {
  const FileInput = useRef()
  const postContext = useContext(PostContext)
  // const [setFiles, files] = postContext4
  const Unload = index => {
    let files = [...postContext.Files]
    console.log(index)
    files.splice(index, 1)
    postContext.setFiles(files)
  }
  const handleFiles = e => {
    console.log(e.target)
    let index = postContext.Files.length - 1

    let tm = []
    Array.from(e.target.files).forEach(element => {
      tm = [...tm, { title: '', file: element, type: element.type }]
    })

    postContext.setFiles(prev => [...prev, ...tm])
    if (index >= 0) {
      let titles = [...postContext.Titles]
      titles[index] = ''
      postContext.setTitles(titles)
    } else {
      let titles = ['']
      postContext.setTitles(titles)
    }
  }
  const handleTitle = (e, index) => {
    let titles = [...postContext.Files]
    titles[index].title = e.target.value
    postContext.setFiles(titles)
  }

  return (
    <div className='container-fluid ioioooii'>
      <input
        ref={FileInput}
        className='d-none'
        onChange={e => {
          handleFiles(e)
        }}
        type={'file'}
      ></input>
      {postContext.Files.length >= 1 && (
        <Carousel indicators={false} className='specscarosel23876487236'>
          {postContext.Files.map((elem, index) => {
            if (elem.type.match(/image/)) {
              return (
                <CarouselItem>
                  <div>
                    <button
                      className='btn btn-close px-0 float-end'
                      onClick={e => Unload(index)}
                    ></button>{' '}
                  </div>
                  <img
                    className='carItem'
                    src={!elem.url ? URL.createObjectURL(elem.file) : elem.url}
                  ></img>
                  <input
                    onChange={e => handleTitle(e, index)}
                    className='form-control'
                  ></input>
                </CarouselItem>
              )
            } else if (elem.type.match(/video/)) {
              return (
                <CarouselItem>
                  <div>
                    <button
                      className='btn btn-close px-0 float-end'
                      onClick={e => Unload(index)}
                    ></button>{' '}
                  </div>
                  <video className='carItem' controls>
                    <source
                      src={
                        !elem.url ? URL.createObjectURL(elem.file) : elem.url
                      }
                    />
                  </video>
                  <input
                    onChange={e => handleTitle(e, index)}
                    className='form-control'
                  ></input>
                </CarouselItem>
              )
            }
          })}
        </Carousel>
      )}
      <button
        className='btn pro-spaces-button3 p-0'
        onClick={e => FileInput.current.click()}
      >
        <AiTwotoneFileAdd color='' size={30} />
      </button>
    </div>
  )
}
