import { useEffect, useState } from 'react'
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import { AiFillAudio } from "react-icons/ai";
import axios from "axios";
import { FiSend } from 'react-icons/fi'
import { FaTrash } from 'react-icons/fa'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressBar from "react-bootstrap/ProgressBar";


let old = []
export const NewLaunchportTile  = (props) => {

    const formData = new FormData()
    const [Image, setImage] = useState(false)
    const [Video, setVideo] = useState(false)
    const [Schedule, setSchedule] = useState(false)
    const [first, setfirst] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [showMediaI, setshowMediaI] = useState([])
    const [content, setContent] = useState('');
    const [tittle, setTittle] = useState('');
    const [error, seterror] = useState();
    const [halt, sethalt] = useState(false)
    const [Progress, setProgress] = useState(0)
    const [value, setValue] = useState({
        id: '',
        title: '',
        discription: '',
        serviceFee: '',
        paymentTerms: '',
        keywords: '',
        titles: [],
        discriptions: [],
        titlesNew: [],
        discriptionsNew: [],
        File:[]
    })

    const [data, setdata] = useState()
    useEffect(() => {
        doRender()
    }, [])
    const [FileOld, setFileOld] = useState([])
    const doRender = async () => {
        try {
            let id = props.id
            let a = await axios.post('/api/ActiveUser/NewLaunchGetPortfolioSingleInside', { id })
            // console.log(a.data)
            setdata(a.data[0])
            setValue({
                ...value,
                id: a.data[0]._id,
                title: a.data[0].title,
                discription: a.data[0].discription,
                serviceFee: a.data[0].serviceFee,
                paymentTerms: a.data[0].paymentTerms,
                keywords: a.data[0].keywords,
                titles: a.data[0].titles,
                discriptions: a.data[0].discriptions,
                File: a.data[0].File

            })
            setFileOld(a.data[0].File)

        }
        catch (err) {
            console.log(err)
        }
    }
    const [Done, setDone] = useState(false)
    useEffect(() => {
        // console.log(value.titles)
        if (!Done)
            if (value.File.length > 0) {
                setDone(true)
                PremediaHandler(value.File)
            }

        // console.log(value)
    }, [value])




    const Clickhandler = (cont) => {
        console.log(cont)
        if (cont == 'imagexrtst') {
            document.getElementById(`imagexrtst${props.id}`).click();
        } else if (cont == 'videoxrtst') {
            document.getElementById(`videoxrtst${props.id}`).click();
        } else if (cont == 'audioxrtst') {
            document.getElementById(`audioxrtst${props.id}`).click();
        }
    }


    //////////////////////////////
    var arr = []
    const [Titles, setTitles] = useState([])

    const [fil, setfil] = useState([])
    const [Count, setCount] = useState(0)
    const [Disc, setDisc] = useState([])
    const DiscHandler = (elem) => {
        console.log(Disc)
        let id = elem.target.id
        let pos = id.substring(5)
        console.log(pos)
        let ob = {
            position: pos,
            Disc: elem.target.value
        }
        // let Disce = Disc
        // Disce.push(ob)
        Disc[pos] = ob
        // setDisc({ ...Disc, pos: ob })
        console.log(Disc)
    }
    const TitleHandler = (elem) => {
        console.log(Titles)
        let id = elem.target.id
        console.log(id)
        let pos = id.substring(4)
        console.log(pos)
        let ob = {
            position: pos,
            title: elem.target.value
        }
        Titles[pos] = ob
        console.log(Titles)
    }

    const DeleteThis = (e) => {
        try {
            let data = e.target.getAttribute('data')
            console.log(data)
            console.log(data.substring(4))
            let pos = parseInt(data.substring(4))
            fil[pos] = null
            next()
            check()
            document.getElementById('car' + data).remove()
        } catch (err) {
            console.log(err)
        }
    }

    const DeleteThisPro = (e) => {
        try {
            let data = e.target.getAttribute('data')
            console.log(data)
            console.log(data.substring(4))
            let pos = parseInt(data.substring(4))
            old = [...old, pos]
            next()
            check()
            console.log(pos)
            let idd = 'car' + data + props.id
            console.log(idd)
            document.getElementById(idd).remove()
        } catch (err) {
            console.log(err)
        }
    }

    const check = () => {
        // console.log(showMediaI)
        // console.log(fil)
        // console.log(Disc)
        // console.log(old)
    }
    useEffect(() => {
        // console.log(fil)
    }, [fil])
    const [newVal, setnewVal] = useState([])
    const [newDiscVal, setnewDiscVal] = useState([])
    const preTitle = (e) => newVal[e.target.name] = e.target.value
    const preDisc = (e) => newDiscVal[e.target.name] = e.target.value
    const PremediaHandler = (files) => {
        try {
            let temp = []
            let Counter = Count
            console.log(files)
            if (files)
                files.forEach(element => {
                    console.log(files.length)
                    console.log(element)
                    let file = element.link
                    let type = element.filetype.substring(0, 5)

                    if (type === 'image') {
                        let ide = 'port' + Counter
                        let newId = 'port' + Counter
                        console.log(temp.length)
                        if (Counter === 0) {
                            console.log(value.titles[Counter])
                            console.log(Counter)
                            temp.push
                                (
                                    <div id={`car${newId + props.id}`} className="carousel-item active ">
                                        <button data={ide} type="button" onClick={(e) => DeleteThisPro(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                        <img className='carItem' src={file}></img><br /><br />
                                        <input id={newId} onChange={(e) => preTitle(e)} name={Counter}  className="form-control" defaultValue={value.titles[Counter] && value.titles[Counter].title} ></input><br />
                                        <textarea name={Counter}  className="form-control" onChange={(e) => preDisc(e)} id={`A` + newId}>{value.discriptions[Counter] && value.discriptions[Counter].Disc}</textarea><br />
                                    </div >
                                )
                        }
                        else {
                            temp.push
                                (
                                    <div id={`car${newId + props.id}`} className="carousel-item   ">
                                        <button data={ide} type="button" onClick={(e) => DeleteThisPro(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                        <img className='carItem' src={file}></img><br /><br />
                                        <input id={newId} onChange={(e) => preTitle(e)} name={Counter} className="form-control" defaultValue={value.titles[Counter] && value.titles[Counter].title} ></input><br />
                                        <textarea name={Counter}  className="form-control" onChange={(e) => preDisc(e)} id={`A` + newId}>{value.discriptions[Counter] && value.discriptions[Counter].Disc}</textarea><br />
                                    </div>
                                )
                        }
                        Counter++
                    }
                    if (type === 'video') {
                        let ide = 'port' + Counter
                        let newId = 'port' + Counter
                        if (Counter === 0) {

                            temp.push(
                                <div id={`car${newId + props.id}`} className="carousel-item  active">
                                    <button data={ide} type="button" onClick={(e) => DeleteThisPro(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                    <video className="carItem" controls>
                                        <source src={file} />
                                    </video><br />
                                    <input id={newId} onChange={(e) => preTitle(e)} name={Counter}  className="form-control" defaultValue={value.titles[Counter] && value.titles[Counter].title} ></input><br />
                                    <textarea name={Counter}  className="form-control" onChange={(e) => preDisc(e)} id={`A` + newId}>{value.discriptions[Counter] && value.discriptions[Counter].Disc}</textarea><br />
                                </div>
                            )
                        }
                        else {
                            temp.push(
                                <div id={`car${newId + props.id}`} className="carousel-item    ">
                                    <button data={ide} type="button" onClick={(e) => DeleteThisPro(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                    <video className="carItem" controls>
                                        <source src={file} />
                                    </video><br />
                                    <input id={newId} onChange={(e) => preTitle(e)} name={Counter}  className="form-control" defaultValue={value.titles[Counter] && value.titles[Counter].title} ></input><br />
                                    <textarea name={Counter} className="form-control" onChange={(e) => preDisc(e)} id={`A` + newId}>{value.discriptions[Counter] && value.discriptions[Counter].Disc}</textarea><br />
                                </div>
                            )
                        }
                        Counter++
                    }
                    if (type === 'audio') {
                        let ide = 'port' + Counter
                        let newId = 'port' + Counter
                        if (Counter === 0) {
                            temp.push
                                (
                                    <div id={`car${newId + props.id}`} className="carousel-item active">
                                        <button data={ide} type="button" onClick={(e) => DeleteThisPro(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                        <div style={{ 'minHeight': '340px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                            <audio controls>
                                                <source src={file} type="audio/ogg" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div><br />
                                        <input id={newId} onChange={(e) => preTitle(e)} name={Counter}  className="form-control" defaultValue={value.titles[Counter] && value.titles[Counter].title} ></input><br />
                                        <textarea name={Counter}  className="form-control" onChange={(e) => preDisc(e)} id={`A` + newId}>{value.discriptions[Counter] && value.discriptions[Counter].Disc}</textarea><br />
                                    </div>
                                )
                        }
                        else {
                            temp.push
                                (
                                    <div id={`car${newId + props.id}`} className="carousel-item   ">
                                        <button data={ide} type="button" onClick={(e) => DeleteThisPro(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                        <div style={{ 'minHeight': '340px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                            <audio controls>
                                                <source src={file} type="audio/ogg" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div><br />
                                        <input id={newId} onChange={(e) => preTitle(e)} name={Counter}  className="form-control" defaultValue={value.titles[Counter] && value.titles[Counter].title} ></input><br />
                                        <textarea name={Counter}  className="form-control" onChange={(e) => preDisc(e)} id={`A` + newId}>{value.discriptions[Counter] && value.discriptions[Counter].Disc}</textarea><br />
                                    </div>
                                )
                        }
                        Counter++
                    }

                });
            setCount(Counter)
            arr = temp
            if (rerender) {
                setshowMediaI(arr)
                setRerender(!rerender)
            } else {
                setshowMediaI(temp)
                setRerender(!rerender)
            }
        } catch (err) {
            console.log(err)
        }

    }
    const mediaHandlerT = (file, type) => {
        console.log(Count)
        if (fil.length != Count) {
            fil[Count - 1] = null
        }
        console.log('first')
        for (var i = 0; i < file.length; i++) {
            var sab = fil
            sab.push(file[i])
        };
        console.log(file)
        console.log('media handler');
        console.log(showMediaI);
        if (showMediaI.length > 0) {
            arr = showMediaI
        }
        let CounterPro = 0;
        console.log(fil)
        fil.forEach((esc) => {
            console.log(esc)
            esc != null && CounterPro++
        })
        console.log(CounterPro)
        console.log(fil)
        console.log(fil.length)
        var temp = arr
        let Counter = Count
        let acti = false
        let d = document.getElementById(`innerProx${props.id}`).childElementCount
        if (d === 0) {
            acti = true
        }
        if (type === 'img') {
            for (var i = 0; i < file.length; i++) {
                let newId = 'port' + Counter
                console.log(temp.length, CounterPro)
                if (acti) {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item active ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                <img className='carItem' src={URL.createObjectURL(file[i])}></img><br /><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                                <textarea className="form-control"   onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                else {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item   ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                <img className='carItem' src={URL.createObjectURL(file[i])}></img><br /><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                                <textarea className="form-control"  onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                Counter++
            }
        }
        if (type === 'vid') {

            for (var i = 0; i < file.length; i++) {
                let newId = 'port' + Counter
                console.log(temp.length, CounterPro)

                if (acti) {

                    temp.push(
                        <div id={`car${newId}`} className="carousel-item  active">
                            <button data={newId} type="button" onClick={(e) => DeleteThis(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                            <video className="carItem" controls>
                                <source src={URL.createObjectURL(file[i])} />
                            </video><br />
                            <input id={newId} onChange={(e) => TitleHandler(e)} className="form-control" /><br />
                            <textarea className="form-control"   onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />

                        </div>
                    )
                }
                else {
                    temp.push(
                        <div id={`car${newId}`} className="carousel-item    ">
                            <button data={newId} type="button" onClick={(e) => DeleteThis(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>
                            <video className="carItem" controls>
                                <source src={URL.createObjectURL(file[i])} />
                            </video><br />
                            <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                            <textarea className="form-control"   onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                        </div>
                    )
                }
                Counter++

            }
        }
        if (type === 'aud') {
            let newId = 'port' + Counter
            console.log(temp.length, CounterPro)

            for (var i = 0; i < file.length; i++) {
                if (acti) {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item active">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>

                                <div style={{ 'minHeight': '340px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                    <audio controls>
                                        <source src={URL.createObjectURL(file[i])} type="audio/ogg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                                <textarea className="form-control"   onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                else {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item   ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} className="btn-close btn-close-dark float-end" aria-label="Close"></button>

                                <div style={{ 'minHeight': '340px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                    <audio controls>
                                        <source src={URL.createObjectURL(file[i])} type="audio/ogg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)}  className="form-control" /><br />
                                <textarea className="form-control"   onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                Counter++

            }
        }
        setfil(sab)

        setCount(Counter)
        arr = temp
        if (rerender) {
            setshowMediaI(arr)
            setRerender(!rerender)
        } else {
            setshowMediaI(temp)
            setRerender(!rerender)
        }
    }

    // function dorender(media) {
    //     console.log('works fine');
    //     setfirst(media)
    // }
    useEffect(() => {
        // console.log('first');
        if (showMediaI.length > 0) {
            // dorender(showMediaI)
        }
    }, [showMediaI]);

    const [ss, setss] = useState(0);
    useEffect(() => {
        // console.log('works');
    }, [ss]);
    const valid = () => {
      

   
        return true;
    }

    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    const Tr = () => {
        let c = []
        // console.log(fil)
        // console.log(Titles)
        // console.log(Disc)
        let titles = []
        let discs = []
        fil.forEach((element, index) => {
            if (element) {
                console.log(element)
                console.log(Titles[index])
                titles = [...titles, Titles[index] ? Titles[index] : null]
                discs = [...discs, Disc[index] ? Disc[index] : null]
            }
        });
        console.log(c)
        let final = {
            titles, discs
        }
        console.log(final)
        return final
        console.log(showMediaI.length)
        let Tit = []
        let Disk = []

        for (let i = 0; i < Count; i++) {
            // console.log(i)
            Tit.push(document.getElementById('port' + i).value)
        }
        for (let i = 0; i < Count; i++) {
            // console.log(i)
            Disk.push(document.getElementById('Aport' + i).value)
        }
        let lit = {
            Tit, Disk
        }
        console.log(Tit)
        return lit
    }
    const titleCorrection = (titles) => {
        let tick = []
        console.log(titles)
        try {
            let prev = null
            titles.forEach((element, pos) => {

                console.log(element, pos)
                let dum = {
                    position: pos,
                    title: ''
                }
                if (element.position != pos) {
                    console.log(`missing${pos}`)
                    let p
                    if (prev) {
                        console.log('prev')
                        console.log(prev.position)
                        p = element.position - (prev.position - 1)
                    } else {
                        p = pos
                    }
                    console.log(p)
                    for (let i = 0; i < p; i++) {
                        let dummy = {
                            position: pos + i,
                            title: ''
                        }
                        tick = [...tick, dummy]
                    }
                    tick = [...tick, element]
                } else {
                    tick = [...tick, element]

                }
                prev = element
            });
            console.log(tick)
            return tick
        } catch (err) {
            console.log(err)
        }
    }
    const DiscCorrection = (titles) => {
        let tick = []
        console.log(titles)
        try {
            let prev = null
            titles.forEach((element, pos) => {

                console.log(element, pos)
                let dum = {
                    position: pos,
                    Disc: ''
                }
                if (element.position != pos) {
                    console.log(`missing${pos}`)
                    let p
                    if (prev) {
                        console.log('prev')
                        console.log(prev.position)
                        p = element.position - (prev.position - 1)
                    } else {
                        p = pos
                    }
                    console.log(p)
                    for (let i = 1; i < p - 2; i++) {
                        let dummy = {
                            position: pos + i,
                            Disc: ''
                        }
                        tick = [...tick, dummy]
                    }
                    tick = [...tick, element]
                } else {
                    tick = [...tick, element]

                }
                prev = element
            });
            console.log(tick)
            return tick
        } catch (err) {
            console.log(err)
        }
    }
    const dommerdup = () => {
        // console.log(newVal)
        // console.log(newDiscVal)
        let len = value.titles.length
        let titles = []
        if (len) {

            for (let i = 0; i < len; i++) {
                let position = i
                let title
                if (newVal[i]||newVal[i]==='') {
                    title = newVal[i]
                } else {
                    title = value.titles[i] ? value.titles[i].title : ''
                }
                let dt = {
                    position, title
                }
                titles = [...titles, dt]
                // console.log(dt)

            }
        }
        // console.log(titles)
        let len2 = value.discriptions.length
        // console.log(len2)
        // return
        let discriptions = []
        if (len2) {
            for (let i = 0; i < len2; i++) {
                let position = i
                let Disc
                if (newDiscVal[i]||newDiscVal[i]==='') {
                    Disc = newDiscVal[i]
                } else {
                    Disc = value.discriptions[i] ? value.discriptions[i].Disc : ''
                }
                let dt = {
                    position, Disc
                }
                discriptions = [...discriptions, dt]
            }
        }

        let f = Tr()
        // console.log(titles)
        // console.log(discriptions)
        titles = [...titles, ...f.titles]
        discriptions = [...discriptions, ...f.discs]
        // let titleCorrections = titleCorrection(titles)
        // let DiscCorrections = DiscCorrection(discriptions)

        // console.log(old)
        // console.log('start')
        let newTitles = value.titlesNew
        let oldTitles = value.titles

        // if (newTitles.length) {
        //     newTitles.forEach((elem) => {
        //         console.log(elem.position)
        //         let Rpos = oldTitles.find(o => o.position === elem.position);
        //         console.log(Rpos)
        //         oldTitles[Rpos] = elem
        //         console.log(Rpos)
        //         console.log(elem)
        //         console.log(value)
        //     })
        // }

        // return
        console.log(value)
        let c = document.getElementById('closer9655sabaree')
        let TitlesToDb = Tr()
        c.setAttribute('disabled', true)
        var status = false;
        console.log(TitlesToDb)
        status = valid();
        if (status == true) {
            formData.delete('data')
            formData.delete('file')

            fil.forEach(element => {
                if (element) {
                    formData.append("file", element);
                    console.log(element)
                }
            });

            var sab = {
                value, titles, discriptions, old
            }
            
            console.log(sab)
            var das = JSON.stringify(sab)
            formData.append("data", das);

            // console.log(content)
            // console.log(tittle)
            const options = {
                onUploadProgress: (progressEvent) => {
                    console.log(progressEvent)
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor(((loaded / 1000) * 100) / (total / 1000));
                    console.log(percent)
                    // move(percent)/
                    if (percent < 100) {
                        setProgress(percent);
                    }

                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            axios.put('/api/ActiveUser/NewLaunchUpdate', formData, options).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    console.log('Succesfully posed', res.data)
                    setProgress(100)
                    let ids = res.data
                    // props.setPortFolios([...props.PortFolios, ids])
                    document.getElementById('closer9655sabaree').click()

                    // props.activate("Portfolio-tab")
                    notify("NEW Service Updated ", true);
                }
                else {
                    console.log('something went wrong')
                    notify("something went wrong ", false);
                    document.getElementById('closer9655sabaree').click()
                }
            }).catch((err) => {
                console.log(err)
                document.getElementById('closer9655sabaree').click()
            })
        }

        c.removeAttribute('disabled')
        c.click()
    }
    const next = () => document.getElementById(`next${props.id}`).click()

    
    const sabari=(e)=>{
        document.getElementById(`discardeiygmoadltsttst${props.index}`).classList.add('d-none')
        document.getElementById(`discareroihgpqipehdmoadl2tsttst${props.index}`).classList.remove('d-none')
        }
        
        const sabari2=(sab)=>{
            if(sab){
              
            }
            document.getElementById(`discardeiygmoadltsttst${props.index}`).classList.remove('d-none')
            document.getElementById(`discareroihgpqipehdmoadl2tsttst${props.index}`).classList.add('d-none')
            }



            





    try {
        return <div style={{ 'cursor': 'pointer', }} className="border mb-1 PortTitle">
                    <div
                        className="portfolioLink"
                        data-bs-toggle="modal" data-bs-target={`#Post11korgtrstary1${props.index}`}
                      >
                        <div className="card">
                          <img
                            src={value.File[0]?value.File[0].link:'/port.jpeg'}
                            className="img-fluid wrighirhgipjpihdfpjhpidfpi"
                            alt="..."
                          />
                          <div className="card-body text-dark">
                            <center><h5 className="card-title">{value.title?value.title:''}</h5></center>
                          </div>
                        </div>
                      </div>
            {/* <div className="d-flex">
                <div data-bs-toggle="modal" data-bs-target={`#Post11rtyrty${props.index}`} className=" texteclipsforsab">
                    {props.index}. {data && data.title && data.title.length > 0 ? data.title : <div className="text-center">
                        <div className="spinner-border" role="status">

                        </div>
                    </div>}
                </div>
                <button type="button" className="btn-close btn-close-dark d-flex justify-content-end" onClick={(e) => props.Remove(props.id)} aria-label="Close"></button>
            </div> */}
           <div className="modal fade podamonea" data-bs-backdrop="static" id={`Post11korgtrstary1${props.index}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl  modal-dialog-scrollable ">
                    <div className="modal-content" id={`discardeiygmoadltsttst${props.index}`}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">NEW Service</h5>
                            {/* <button type="button" onClick={(e) => check()}>check</button> */}
                            <button type="button" id='closer9655sabaree' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {/*  */}
                        <div className="modal-body">
                            <label className="ms-1">NEW Service Title *</label>
                            <input className="eihfioehfoieh  w-100 form-control " value={value.title} onChange={(e) => setValue({ ...value, title: e.target.value })} placeholder={''} />
                            {/* <hr />
                            <label className="ms-1">Service Description * </label>
                            <textarea className="postInputx ps-2" defaultValue={value.discription} onChange={(e) => setValue({ ...value, discription: e.target.value })} placeholder={'Mention what services you provide'}></textarea>
                            <hr />
                            <label className="ms-1">Service Fees / Charges</label>
                            <input className="eihfioehfoieh  w-100 form-control " value={value.serviceFee} onChange={(e) => setValue({ ...value, serviceFee: e.target.value })} placeholder={''} />
                            <hr />
                            <label className="ms-1">Payment Terms</label>
                            <input className="eihfioehfoieh  w-100 form-control " value={value.paymentTerms} onChange={(e) => setValue({ ...value, paymentTerms: e.target.value })} placeholder={''} />
                            <hr /> */}
                            <label className="ms-1" >
                                Works / Projects / Service-related Photographs / Video clips / Audio clips
                            </label>
                            <div id={`carouselExampleIndicatorssreehariop${props.id}`} className="carousel slide" data-bs-interval="false">
                                {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div> */}
                               <div id={`innerProx${props.id}`} className="carousel-inner">
                                    {showMediaI ? showMediaI : ''}
                                </div>
                                {showMediaI.length > 0 ?
                                    <>
                                        <button className="carousel-control-prev ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target={`#carouselExampleIndicatorssreehariop${props.id}`} data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button id={`next${props.id}`} className="carousel-control-next ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target={`#carouselExampleIndicatorssreehariop${props.id}`} data-bs-slide="next">
                                            <span className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </>
                                    : <></>}

                            </div>
                            <div className="ps-2">
                                <FaPhotoVideo size="25" onClick={() => Clickhandler('imagexrtst')} style={{ 'color': 'var(--blue)' }}></FaPhotoVideo>
                                &nbsp;&nbsp;<FaVideo style={{ 'color': 'var(--blue)' }} onClick={() => Clickhandler('videoxrtst')} size="25" />
                                &nbsp;&nbsp;<AiFillAudio style={{ 'color': 'var(--blue)' }} onClick={() => Clickhandler('audioxrtst')} size="25" />

                            </div>
                            <hr />
                            {/* <label className="ms-1">Service Keyword Search Preferences</label>
                            <textarea className="postInputx ps-2" style={{ 'minHeight': '100px' }} defaultValue={value.keywords} onChange={(e) => setValue({ ...value, keywords: e.target.value })} placeholder={'Mention search words that can help your prospective clients/customers find you easily'}></textarea> */}


                            <input id={`imagexrtst${props.id}`} type="file" className="d-none" name="Media" onChange={(e) => mediaHandlerT(e.target.files, 'img')}  accept="image/*" />
                            <input id={`videoxrtst${props.id}`} type="file" className="d-none" name="Media" onChange={(e) => mediaHandlerT(e.target.files, 'vid')}  accept="video/*" />
                            <input id={`audioxrtst${props.id}`} type="file" className="d-none" name="Media" onChange={(e) => mediaHandlerT(e.target.files, 'aud')}  accept="audio/*" />

                            {Image ? <div className="container postMediacontainer"> <img className="luide d-flex" src={URL.createObjectURL(Image)} />  </div> : ''}
                            {Video ? <div className="container postMediacontainer"><video className="luide d-flex" controls><source src={URL.createObjectURL(Video)} height="100%" width="100%" /></video> </div> : ''}

                          

                            {/* <ProgressBar Progress={Progress} halt={halt} d_color='red' f_color='yellow' color='white' bgcolor='dark' /> */}
                            <ProgressBar variant="SOME_NAME" now={Progress} label={`${Progress}%`} />
                        </div>
                        {/*  */}
                        <div className="modal-footer">
                            <div className="">
                            <div>
                            <h4 style={{ color: 'red' }}>{error ? error : ''}</h4>
                        </div>
                                <button type="button" className="btn pro-spaces-button me-2 ps-4 pe-4" onClick={(e)=>sabari(e)}>delete</button>
                                <button type="button" className="btn pro-spaces-button3" onClick={(e) => dommerdup(e)} >SAVE</button>

                            </div>

                        </div>

                    </div>
                    <div className="modal-content d-none" id={`discareroihgpqipehdmoadl2tsttst${props.index}`}>
                    <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">NEW Service</h5>
                            {/* <button type="button" onClick={(e) => check()}>check</button> */}
                            <button type="button" id='closer9655sabaree' className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                            <div className="modal-body">
                            <h5>Are you sure you want to Delete ?</h5>
                            
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn pro-spaces-button" onClick={(e)=>sabari2(false)}>Go back</button>
                            <button type="button" className="btn pro-spaces-button3" onClick={(e)=>props.Remove2(props.id)}  data-bs-dismiss="modal">DELETE</button>
                                
                            </div>
                 </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    } catch (err) {
        console.log(err)
        return <div>Loading
            {/* <button type='button' onClick={(e) => doRender()} >Retry</button> */}
        </div>
    }


}