import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";

const CropperModal = ({ toCrop,setImage }) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [cropper, setCropper] = useState();
    const getCropData = () => {
        console.log(cropper, toCrop);
        if (typeof cropper !== "undefined") {
            cropper.getCroppedCanvas().toBlob((blob) => {
                setImage(blob)
                // if (toCrop.type == 'cover') {
                //     setcover(blob)
                // } else {
                //     setrandom((prev) => [...prev, blob])
                // }
                handleClose()
            }, 'image/jpeg', 0.5)
        }
    };
    const [file, setfile] = useState(null)
    useEffect(() => {
        // console.log(toCrop?.file);
        // setfile(toCrop?.file)
        if (toCrop?.file) {
            handleShow()
        }
        return () => {

        }
    }, [toCrop])

    return (
        <><Modal show={show} onHide={handleClose} backdrop={'static'} centered>
            <Modal.Header closeButton>
                <Modal.Title>Crop this image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {toCrop?.file &&
                <>
                {/* <img src={URL.createObjectURL(toCrop.file)} /> */}
                
                    <Cropper
                        style={{ height: 400, width: "100%" }}
                        zoomTo={false}
                        aspectRatio={16 / 9}
                        preview=".img-preview"
                        src={toCrop.file}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={0}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}

                    />
                    
                </>
                    }

            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn pro-spaces-button3"
                    onClick={getCropData}
                >ok</button>
            </Modal.Footer>
        </Modal>

        </>
    )
}

export default CropperModal