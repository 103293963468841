import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import { SpinnerBig } from '../../Utility/spinner';
import { Mask } from '../../Utility/mask';

export const Information_Category_list = () => {
  const [Category, setCategory] = useState([])
  const [Loading, setLoading] = useState(true)
  useEffect(() => {

    Axios.get('/api/api/Information_category_only').then((res) => {
      if (res.status == 200) {
        setCategory(res.data)
        setLoading(false)
      }
    }).catch((err) => {
      setLoading(false)

    })
  }, [])

  const cardEdgefornewbox = {
    borderRadius: "10px",
    backgroundColor: "white",
    color: "var(--blue)",
    border: "1px solid var(--blue)",
    // border:'1px solid var(--blue)',
    overflow: "hidden",
    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    boxShadow: "none",
  };


  return (
    <div>

      <div className='container'>
        {
          Loading ?
            <SpinnerBig />
            :
            <>
              <div className='d-flex justify-content-end'>
                <Link to={"/lobby/information_forms"}>
                  <button className='btn pro-spaces-button3'>Add Information</button>
                </Link>

              </div>

              <div className="row mt-5 g-3">
                {
                  Category ?
                    Category.map((e) => {
                      return <>
                        {/* <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
                          <Link to={`/lobby/information_subcategory?k=${e._id}`} className="wgfiuwrgiu">
                            <div className="card carrrrbus">
                              <div className="padunnor"><center>{e.category}<br>
                              </br> </center></div>
                            </div>
                          </Link>
                        </div> */}

                        <div className=" col-6">
                          <Link
                            to={`/lobby/information_subcategory?k=${Mask.encode(
                              e._id
                            )}`}
                            style={{
                              textDecoration: "none",
                              color: "var(--blue)",
                            }}
                          >
                            {/* <a data-bs-toggle="modal" data-bs-target="#achiversmodal1"> */}
                            <div className="card" style={cardEdgefornewbox}>
                              <div className="card-content">
                                <div className="card-body p-2 ">
                                  <div>
                                    <center>
                                      <b className="fontonlobby fontonlobbysabbbbsss text-uppercase">
                                        {e.category}
                                      </b>
                                    </center>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                          {/* </a> */}
                        </div>

                      </>
                    })
                    : <center><h3>Nothing to show !</h3></center>
                }
              </div>
            </>
        }
      </div>
    </div>

  )
}
