import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { Mask } from "../../Utility/mask";
import { FcInfo } from "react-icons/fc";

export function SubCat(props) {
  const liquermodal = () => {
    document.getElementById("liqquermodalprochuresabaribtn").click();
  };

  const liquermodal2 = () => {
    document.getElementById("liqquermodalprochuresabaribtn2").click();
  };

  return (
    <div
      id={`Cat-` + props.data.sub.slice(0, 1) + props.index}
      className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4   mb-3"
    >
      {props.data.description ? (
        <div
          style={{
            position: "absolute",
            left: "-30px",
            width: "inherit",
            display: "flex",
            justifyContent: "end",
            paddingTop: "20px",
            zIndex: "1",
          }}
        >
          <FcInfo
            style={{ color: "red" }}
            className="float-end"
            onClick={() => props.handleShow(props.data.description)}
          />
        </div>
      ) : (
        <></>
      )}

      {props.data.id === "6308629f75901fee6d0d49c7" ? (
        <div
          style={{
            position: "relative",
            right: "30px",
            top: "40px",
            zIndex: "1",
          }}
        >
          <BsExclamationTriangleFill
            style={{ color: "red" }}
            className="float-end"
            onClick={liquermodal}
          />
        </div>
      ) : (
        <></>
      )}

      {props.data.id === "63071b769b4ba432c41ee60d" ? (
        <div
          style={{
            position: "relative",
            right: "30px",
            top: "40px",
            zIndex: "1",
          }}
        >
          <BsExclamationTriangleFill
            style={{ color: "red" }}
            className="float-end"
            onClick={liquermodal2}
          />
        </div>
      ) : (
        <></>
      )}
      <Link
        to={
          props.data.brand > 0
            ? `/services_brand_categories?brand_id=${Mask.encode(
                props.data.id
              )}&&sub_category=${props.data.sub}`
            : "/services_result?Sub_id=" +
              Mask.encode(props.data.id) +
              "&count=" +
              props?.data?.cons
        }
        className="twedfqtfudc"
      >
        <div className="card carderghibgibgp p-3 pb-2 pt-2">
          <div>
            <div className="d-flex align-items-center">
              <div className="ml-3 w-100">
                <h4 className="mb-2 mt-0">{props.data.sub}</h4>{" "}
                <ul>
                  {/* <li>type : {props.data.num} </li> */}
                  <li>Members : {props.data.cons} </li>
                  {/* */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
