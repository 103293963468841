import React from 'react'
import { Link } from 'react-router-dom'
import './Error.css'

export  function Error() {
  
  return (



    <div className="page-wrap d-flex flex-row align-items-center">
    <div className="container soniyaaaaaaa">
        <div className="row justify-content-center">
            <div className="col-md-12 text-center">
                <span className="display-1 d-block" style={{'color':'#55374a'}}>404</span>
                <div className="mb-4 lead">The page you are looking for was not found.</div>
                <Link to='/' className="btn pro-spaces-button3" style={{'color':'white','textTransform':'none'}}>Back to PROchure</Link>
            </div>
        </div>
    </div>
</div>
  )
}
