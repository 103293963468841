import React from 'react';

import './Privacy.css';

export function Privacy2() {
  return (
    <>
      <div className="container notranslate" style={{ paddingTop: '90px' }}>
        <div className="d-flex justify-content-end ">
          {/* <button onClick={(e)=>window.history.back()} className='btn pro-spaces-button mt-3' >Go Back</button> */}
        </div>

        <div className="pos pb-3">
          <span id="_16.5" style={{ color: '#000000' }}>
            <h4>
              <center>
                <b>
                  <u>PRIVACY POLICY</u>
                </b>
              </center>
            </h4>
          </span>
        </div>
        <div className="pos" id="_100:151" style={{ top: 151, left: 100 }}>
          <span id="_18.3" style={{ fontSize: '16px', color: '#000000' }}>
            This privacy policy (the “Privacy Policy”), together with the terms
            of use, describes the{' '}
            <span className=" monotypepolicies">Vipar</span>’s policies and
            procedures on the collection, use and disclosure of the information
            provided by Members of the Site/app. Every User who accesses or uses
            the Site/app shall be bound by this Privacy Policy.{' '}
          </span>
        </div>
        <br />
        <div className="pos" id="_125:319" style={{ top: 319, left: 125 }}>
          <span
            id="_19.0"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            1. Introduction
          </span>
        </div>
        <br />
        <div className="pos" id="_150:369" style={{ top: 369, left: 150 }}>
          <span id="_19.0" style={{ fontSize: '16px', color: '#000000' }}>
            Our registered users (“Members”) share their professional, personal
            identities, engage with their network, exchange knowledge and
            professional insights, post and view relevant content, learn and
            develop skills, and find business and career opportunities.{' '}
          </span>
        </div>
        <br />

        <div className="pos" id="_150:505" style={{ top: 505, left: 150 }}>
          <span
            id="_18.0"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Services
          </span>
        </div>

        <div className="pos" id="_150:554" style={{ top: 554, left: 150 }}>
          <span id="_18.0" style={{ fontSize: '16px', color: '#000000' }}>
            This Privacy Policy applies to www.PROchure.in,{' '}
            <span className=" monotypepolicies">Vipar</span>-branded apps and
            other <span className=" monotypepolicies">Vipar</span> -related
            sites, apps, communications and services (“Services”), including
            off-site Services, such as our ad services and the “Apply with{' '}
            <span className=" monotypepolicies">Vipar</span>” and “Share with{' '}
            <span className=" monotypepolicies">Vipar</span>” plugins, but
            excluding services that state that they are offered under a
            different privacy policy.{' '}
          </span>
        </div>
        <br />

        <div className="pos" id="_150:750" style={{ top: 750, left: 150 }}>
          <span
            id="_17.0"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Change
          </span>
        </div>

        <div className="pos" id="_150:798" style={{ top: 798, left: 150 }}>
          <span id="_18.9" style={{ fontSize: '16px', color: '#000000' }}>
            <span className=" monotypepolicies">Vipar</span> (“we” or “us”) can
            modify this Privacy Policy, and if we make material changes to it,
            we will provide notice through our Services, or by other means, to
            provide you the opportunity to review the changes before they become
            effective. If you object to any changes, you may close your account.
          </span>
        </div>

        <div className="pos" id="_150:965" style={{ top: 965, left: 150 }}>
          <span id="_18.9" style={{ fontSize: '16px', color: '#000000' }}>
            You acknowledge that your continued use of our Services after we
            publish or send a notice about our changes to this Privacy Policy
            means that the collection, use and sharing of your personal data is
            subject to the updated Privacy Policy, as of its effective date.
          </span>
        </div>
        <br />

        <div className="pos" id="_125:1350" style={{ top: 1350, left: 125 }}>
          <span
            id="_18.6"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2. Data We Collect
          </span>
        </div>
        <br />
        <div className="pos" id="_150:1400" style={{ top: 1400, left: 150 }}>
          <span
            id="_18.6"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2.1 Data You Provide To Us
          </span>
        </div>
        <br />
        <div className="pos" id="_150:1449" style={{ top: 1449, left: 150 }}>
          <span
            id="_18.6"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Registration
          </span>
        </div>

        <div className="pos" id="_150:1498" style={{ top: 1498, left: 150 }}>
          <span id="_19.6" style={{ fontSize: '16px', color: '#000000' }}>
            To create an account you need to provide data including your name,
            email address and/or mobile number, password and payment details
            (e.g., credit card) and billing information.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:1605" style={{ top: 1605, left: 150 }}>
          <span
            id="_17.0"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Profile
          </span>
        </div>

        <div className="pos" id="_150:1654" style={{ top: 1654, left: 150 }}>
          <span id="_19.3" style={{ fontSize: '16px', color: '#000000' }}>
            You have choices about the information on your profile, such as your
            education, work experience, skills, photo, city or area and
            endorsements. You don’t have to provide additional information on
            your profile; however, profile information helps you to get more
            from our Services. It’s your choice whether to include sensitive
            information on your profile and to make that sensitive information
            public. Please do not post or add personal data to your profile that
            you would not want to be publicly available.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:1908" style={{ top: 1908, left: 150 }}>
          <span
            id="_18.8"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Posting and Uploading
          </span>
        </div>

        <div className="pos" id="_150:1957" style={{ top: 1957, left: 150 }}>
          <span id="_18.8" style={{ fontSize: '16px', color: '#000000' }}>
            We collect personal data from you when you provide, post or upload
            it to our Services, such as when you fill out a form, respond to a
            survey etc. on our Services.{' '}
          </span>
        </div>
        <br />

        <div className="pos" id="_150:2065" style={{ top: 2065, left: 150 }}>
          <span
            id="_18.8"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2.2 Data From Others
          </span>
        </div>
        <br />
        <div className="pos" id="_150:2113" style={{ top: 2113, left: 150 }}>
          <span
            id="_18.8"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Content and News
          </span>
        </div>

        <div className="pos" id="_150:2162" style={{ top: 2162, left: 150 }}>
          <span id="_19.3" style={{ fontSize: '16px', color: '#000000' }}>
            You and others may post content that includes information about you
            (as part of articles, posts, comments, videos) on our Services. We
            also may collect public information about you, such as
            professional-related news and accomplishments, and make it available
            as part of our Services, including, as permitted by your settings,
            in notifications to others of mentions in the news.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:2578" style={{ top: 2578, left: 150 }}>
          <span
            id="_17.2"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Partners
          </span>
        </div>

        <div className="pos" id="_150:2627" style={{ top: 2627, left: 150 }}>
          <span id="_19.5" style={{ fontSize: '16px', color: '#000000' }}>
            We receive personal data (e.g., your job title and work email
            address and other information you provide) about you when you use
            the services of our partners such as Legal professionals, Chartered
            Accountants etc.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:2764" style={{ top: 2764, left: 150 }}>
          <span
            id="_18.7"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Related Companies and Other Services
          </span>
        </div>

        <div className="pos" id="_150:2813" style={{ top: 2813, left: 150 }}>
          <span id="_19.6" style={{ fontSize: '16px', color: '#000000' }}>
            We receive data about you when you use some of the other services
            provided by us or our affiliates.{' '}
          </span>
        </div>
        <br />

        <div className="pos" id="_150:2891" style={{ top: 2891, left: 150 }}>
          <span
            id="_18.9"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2.3 Service Use
          </span>
        </div>

        <div className="pos" id="_150:2939" style={{ top: 2939, left: 150 }}>
          <span id="_18.9" style={{ fontSize: '16px', color: '#000000' }}>
            We log usage data when you visit or otherwise use our Services,
            including our sites, app and platform technology, such as when you
            view or click on content or ads (on or off our sites and apps),
            perform a search, install or update one of our mobile apps, share
            articles etc. We use log-ins, cookies, device information and
            internet protocol (“IP”) addresses to identify you and log your use.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:3135" style={{ top: 3135, left: 150 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2.4 Cookies and Similar Technologies
          </span>
        </div>

        <div className="pos" id="_150:3184" style={{ top: 3184, left: 150 }}>
          <span id="_19.7" style={{ fontSize: '16px', color: '#000000' }}>
            We use cookies and similar technologies (e.g., pixels and ad tags)
            to collect data (e.g., device IDs) to recognize you and your
            device(s) on, off and across different services and devices where
            you have engaged with our Services. We also collect (or rely on
            others who collect) information about your device where you have not
            engaged with our Services (e.g., ad ID, IP address, operating system
            and browser information) so we can provide our Members with relevant
            ads and better understand their effectiveness.{' '}
          </span>
        </div>
        <br />

        <div className="pos" id="_150:3611" style={{ top: 3611, left: 150 }}>
          <span
            id="_18.8"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2.5 Your Device and Location
          </span>
        </div>

        <div className="pos" id="_150:3660" style={{ top: 3660, left: 150 }}>
          <span id="_19.4" style={{ fontSize: '16px', color: '#000000' }}>
            When you visit or leave our Services (including some plugins and our
            cookies or similar technology on the sites of others), we receive
            the URL of both the site you came from and the one you go to and the
            time of your visit. We also get information about your network and
            device (e.g., IP address, proxy server, operating system, web
            browser and add-ons, device identifier and features, cookie IDs
            and/or ISP, or your mobile carrier). If you use our Services from a
            mobile device, that device will send us data about your location
            based on your phone settings. We will ask you to opt-in before we
            use GPS or other tools to identify your precise location.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:3973" style={{ top: 3973, left: 150 }}>
          <span
            id="_18.9"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2.6 Messages
          </span>
        </div>

        <div className="pos" id="_150:4022" style={{ top: 4022, left: 150 }}>
          <span id="_19.4" style={{ fontSize: '16px', color: '#000000' }}>
            We collect information about you when you send, receive, or engage{' '}
          </span>
        </div>
        <div className="pos" id="_150:4051" style={{ top: 4051, left: 150 }}>
          <span id="_18.9" style={{ fontSize: '16px', color: '#000000' }}>
            with messages in connection with our Services. For example, if you
            get{' '}
          </span>
        </div>
        <div className="pos" id="_150:4080" style={{ top: 4080, left: 150 }}>
          <span id="_19.6" style={{ fontSize: '16px', color: '#000000' }}>
            a <span className=" monotypepolicies">Vipar</span> connection
            request, we track whether you have acted on it{' '}
          </span>
        </div>
        <div className="pos" id="_150:4110" style={{ top: 4110, left: 150 }}>
          <span id="_18.5" style={{ fontSize: '16px', color: '#000000' }}>
            and will send you reminders. We also use automatic{' '}
          </span>
        </div>
        <div className="pos" id="_150:4139" style={{ top: 4139, left: 150 }}>
          <span id="_19.4" style={{ fontSize: '16px', color: '#000000' }}>
            We collect information about you when you send, receive, or engage
            with messages in connection with our Services. For example, if you
            get a <span className=" monotypepolicies">Vipar</span> connection
            request, we track whether you have acted on it and will send you
            reminders. We also use automatic scanning technology on messages to
            support and protect our site. For example, we use this technology to
            suggest possible responses to messages and to manage or block
            content that violates our User Agreement.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:4276" style={{ top: 4276, left: 150 }}>
          <span
            id="_19.4"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2.7 Sites and Services of Others
          </span>
        </div>

        <div className="pos" id="_150:4325" style={{ top: 4325, left: 150 }}>
          <span id="_19.4" style={{ fontSize: '16px', color: '#000000' }}>
            We receive information about your visits and interaction with
            services provided by others when you log-in with{' '}
            <span className=" monotypepolicies">Vipar</span> or visit others’
            services that include some of our plugins (such as “Apply with{' '}
            <span className=" monotypepolicies">Vipar</span>”) or our ads,
            cookies or similar technologies.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:4462" style={{ top: 4462, left: 150 }}>
          <span
            id="_17.9"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            2.8 Other
          </span>
        </div>

        <div className="pos" id="_150:4510" style={{ top: 4510, left: 150 }}>
          <span id="_18.9" style={{ fontSize: '16px', color: '#000000' }}>
            Our Services are dynamic, and we often introduce new features, which
            may require the collection of new information. If we collect
            materially different personal data or materially change how we
            collect, use or share your data, we will notify you and may also
            modify this Privacy Policy.
          </span>
        </div>
        <br />

        <div className="pos" id="_125:4890" style={{ top: 4890, left: 125 }}>
          <span
            id="_18.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3. How We Use Your Data
          </span>
        </div>

        <div className="pos" id="_150:4940" style={{ top: 4940, left: 150 }}>
          <span id="_20.0" style={{ fontSize: '16px', color: '#000000' }}>
            How we use your personal data will depend on which Services you use,
            how you use those Services and the choices you make in your
            settings. We use the data that we have about you to provide and
            personalize our Services, including with the help of automated
            systems and inferences we make, so that our Services (including ads)
            can be more relevant and useful to you and others.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:5135" style={{ top: 5135, left: 150 }}>
          <span
            id="_19.4"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3.1 Services
          </span>
        </div>

        <div className="pos" id="_150:5184" style={{ top: 5184, left: 150 }}>
          <span id="_19.4" style={{ fontSize: '16px', color: '#000000' }}>
            We use your data to authorize access to our Services and honor your
            settings.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:5262" style={{ top: 5262, left: 150 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Stay Connected
          </span>
        </div>

        <div className="pos" id="_150:5311" style={{ top: 5311, left: 150 }}>
          <span id="_19.1" style={{ fontSize: '16px', color: '#000000' }}>
            Our Services allow you to stay in touch and up to date with
            partners, clients, and other professional contacts. To do so, you
            can “connect” with the professionals who you choose, and who also
            wish to “connect” with you.{' '}
          </span>
        </div>
        <div className="pos" id="_150:5448" style={{ top: 5448, left: 150 }}>
          <span id="_18.6" style={{ fontSize: '16px', color: '#000000' }}>
            We use data about you (such as your profile, profiles you have
            viewed or data provided through address book uploads or partner
            integrations) to help others find your profile, suggest connections
            for you and others (e.g. Members who share your contacts or
            experiences) and enable you to invite others to become a Member and
            connect with you. You can also opt-in to allow us to use your
            precise location or proximity to others for certain tasks (e.g. to
            suggest other nearby Members for you to connect with, or notify your
            connections that you are at a professional event). It is your choice
            whether to invite someone to our Services, send a connection
            request, or allow another Member to become your connection. When you
            invite someone to connect with you, your invitation will include
            your network and basic profile information (e.g., name, profile
            photo, job title, region). We will send invitation reminders to the
            person you invited.{' '}
          </span>
        </div>
        <br />

        <div className="pos" id="_150:6147" style={{ top: 6147, left: 150 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Stay Informed
          </span>
        </div>

        <div className="pos" id="_150:6196" style={{ top: 6196, left: 150 }}>
          <span id="_19.1" style={{ fontSize: '16px', color: '#000000' }}>
            Our Services allow you to stay informed about news, events and ideas
            regarding professional topics you care about, and from professionals
            you respect. We use the data we have about you (e.g., data you
            provide, data we collect from your engagement with our Services and
            inferences we make from the data we have about you), to personalize
            our Services for you, such as by recommending or ranking relevant
            content and conversations on our Services. We use your content,
            activity and other data, including your name and photo, to provide
            notices to your network and others. For example, subject to your
            settings, we may notify others that you have updated your profile,
            posted content, took a social action, used a feature, made new
            connections or were mentioned in the news..
          </span>
        </div>
        <br />

        <div className="pos" id="_150:6567" style={{ top: 6567, left: 150 }}>
          <span
            id="_19.2"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3.2 Communications
          </span>
        </div>

        <div className="pos" id="_150:6616" style={{ top: 6616, left: 150 }}>
          <span id="_19.7" style={{ fontSize: '16px', color: '#000000' }}>
            We will contact you through email, mobile phone, notices posted on
            our websites or apps, messages to your{' '}
            <span className=" monotypepolicies">Vipar</span> inbox, and other
            ways through our Services, including text messages and push
            notifications. We will send you messages about the availability of
            our Services, security, or other service-related issues. We also
            send messages about how to use our Services, network updates,
            reminders, suggestions and promotional messages from us and our
            partners. Please be aware that you cannot opt out of receiving
            service messages from us, including security and legal notices.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:6900" style={{ top: 6900, left: 150 }}>
          <span
            id="_19.2"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3.3 Advertising
          </span>
        </div>

        <div className="pos" id="_150:7121" style={{ top: 7121, left: 150 }}>
          <span id="_18.6" style={{ fontSize: '16px', color: '#000000' }}>
            We target (and measure the performance of) ads to Membersand others
            both on and off our Services directly or through a variety of
            partners, using the following data, whether separately or combined:
            <br />
            <ul>
              <li>
                Data from advertising technologies on and off our Services,
                pixels, ad tags, cookies, and device identifiers;
              </li>
              <li>
                Member-provided information (e.g., profile, contact information,
                title and industry);
              </li>
              <li>
                Data from your use of our Services (e.g., search history, feed,
                content you read, who you follow or is following you,
                connections, groups participation, page visits, videos you
                watch, clicking on an ad, etc.), including as described in this
                policy;
              </li>
              <li>
                Information from advertising partners, vendors and publishers;
                and
              </li>
              <li>
                Information inferred from data described above (e.g., using job
                titles from a profile to infer industry, seniority, and
                compensation bracket; using graduation dates to infer age or
                using first names or pronoun usage to infer gender; using your
                feed activity to infer your interests; or using device data to
                recognize you as a Member).
              </li>
            </ul>
            We will show you ads called sponsored content which look similar to
            non-sponsored content, except that they are labeled as advertising
            (e.g., as “ad” or “sponsored”). If you take a social action (such as
            like, comment or share) on these ads, your action is associated with
            your name and viewable by others, including the advertiser.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:7886" style={{ top: 7886, left: 150 }}>
          <span
            id="_18.6"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Ad Choices
          </span>
        </div>

        <div className="pos" id="_150:7935" style={{ top: 7935, left: 150 }}>
          <span id="_18.6" style={{ fontSize: '16px', color: '#000000' }}>
            We adhere to self-regulatory principles for interest-based
            advertising and participate in industry opt-outs from such ads. This
            does not opt you out of receiving advertising; you will continue to
            get other ads by advertisers not listed with these self regulatory
            tools.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:8291" style={{ top: 8291, left: 150 }}>
          <span
            id="_18.6"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Info to Ad Providers
          </span>
        </div>

        <div className="pos" id="_150:8340" style={{ top: 8340, left: 150 }}>
          <span id="_19.8" style={{ fontSize: '16px', color: '#000000' }}>
            We do not share your personal data with any third-party advertisers
            or ad networks except for: (i) hashed IDs or device identifiers (to
            the extent they are personal data in some countries); (ii) with your
            separate permission (e.g., in a lead generation form) or (iii) data
            already visible to any users of the Services (e.g., profile).
            However, if you view or click on an ad on or off our Services, the
            ad provider will get a signal that someone visited the page that
            displayed the ad, and they may, through the use of mechanisms such
            as cookies, determine it is you. Advertising partners can associate
            personal data collected by the advertiser directly from you with
            hashed IDs or device identifiers received from us. In such
            instances, we seek to contractually require such advertising
            partners to obtain your explicit, opt-in consent before doing so.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:8741" style={{ top: 8741, left: 150 }}>
          <span
            id="_18.6"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3.4 Marketing
          </span>
        </div>

        <div className="pos" id="_150:8790" style={{ top: 8790, left: 150 }}>
          <span id="_18.6" style={{ fontSize: '16px', color: '#000000' }}>
            In addition to advertising our Services, we use Members’ data and
            content for invitations and communications promoting membership and
            network growth, engagement and our Services, such as by showing your
            connections that you have used a feature on our Services.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:8956" style={{ top: 8956, left: 150 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3.5 Developing Services and Research
          </span>
        </div>
        <br />
        <div className="pos" id="_150:9005" style={{ top: 9005, left: 150 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Service Development
          </span>
        </div>

        <div className="pos" id="_150:9054" style={{ top: 9054, left: 150 }}>
          <span id="_19.1" style={{ fontSize: '16px', color: '#000000' }}>
            We use data, including public feedback, to conduct research and
            development for our Services in order to provide you and others with
            a better, more intuitive and personalized experience, drive
            membership growth and engagement on our Services, and help connect
            professionals to each other and to economic opportunity.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:9220" style={{ top: 9220, left: 150 }}>
          <span
            id="_17.7"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Surveys
          </span>
        </div>

        <div className="pos" id="_150:9461" style={{ top: 9461, left: 150 }}>
          <span id="_19.0" style={{ fontSize: '16px', color: '#000000' }}>
            Polls and surveys are conducted by us and others through our
            Services. You are not obligated to respond to polls or surveys, and
            you have choices about the information you provide.{' '}
          </span>
        </div>
        <br />

        <div className="pos" id="_150:9569" style={{ top: 9569, left: 150 }}>
          <span
            id="_19.0"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3.6 Customer Support
          </span>
        </div>

        <div className="pos" id="_150:9618" style={{ top: 9618, left: 150 }}>
          <span id="_19.0" style={{ fontSize: '16px', color: '#000000' }}>
            We use data (which can include your communications) to investigate,
            respond to and resolve complaints and for Service issues (e.g.,
            bugs).
          </span>
        </div>
        <br />

        <div className="pos" id="_150:9696" style={{ top: 9696, left: 150 }}>
          <span
            id="_19.0"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3.7 Insights That Do Not Identify You
          </span>
        </div>

        <div className="pos" id="_150:9744" style={{ top: 9744, left: 150 }}>
          <span id="_19.9" style={{ fontSize: '16px', color: '#000000' }}>
            We use your data to produce and share insights that do not identify
            you. For example, we may use your data to generate statistics about
            our members, their profession or industry, to calculate ad
            impressions served or clicked on, or to publish visitor demographics
            for a Service or create demographic workforce insights.
          </span>
        </div>
        <br />

        <div className="pos" id="_150:9911" style={{ top: 9911, left: 150 }}>
          <span
            id="_19.3"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            3.8 Security and Investigations
          </span>
        </div>

        <div className="pos" id="_150:9959" style={{ top: 9959, left: 150 }}>
          <span id="_18.7" style={{ fontSize: '16px', color: '#000000' }}>
            We use your data (including your communications) for security
            purposes or to prevent or investigate possible fraud or other
            violations of our User Agreement and/or attempts to harm our Members
            or others.
          </span>
        </div>
        <br />

        <div className="pos" id="_125:10097" style={{ top: 10097, left: 125 }}>
          <span
            id="_18.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            4. How We Share Information
          </span>
        </div>
        <br />

        <div className="pos" id="_150:10147" style={{ top: 10147, left: 150 }}>
          <span
            id="_18.9"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            4.1 Our Services
          </span>
        </div>
        <br />
        <div className="pos" id="_150:10196" style={{ top: 10196, left: 150 }}>
          <span
            id="_18.9"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            Posts, Likes, Follows, Comments, Messages
          </span>
        </div>
        <div className="pos" id="_150:10245" style={{ top: 10245, left: 150 }}>
          <span id="_18.9" style={{ fontSize: '16px', color: '#000000' }}>
            Our Services allow viewing and sharing information including through
            posts, likes, follows and comments.
            <ul>
              <li>
                When you share an article or a post (e.g., an update, image,
                video or article) publicly it can be viewed by everyone and
                re-shared anywhere. Members and others will be able to find and
                see your publicly-shared content, including your name (and photo
                if you have provided one).
              </li>
              <li>
                Any information you share through companies’ or other
                organizations’ pages on our Services will be viewable by it and
                others who visit those pages.
              </li>
              <li>
                When you follow a person or organization, you are visible to
                others and that “page owner” as a follower.
              </li>
              <li>We may let senders know when you act on their message. </li>
              <li>We may let a member know when you view their profile.</li>
              <li>
                When you like or re-share or comment on another’s content
                (including ads), others will be able to view these “social
                actions” and associate it with you (e.g., your name, profile and
                photo if you provided it).
              </li>
            </ul>
          </span>
        </div>

        <div className="pos" id="_150:11033" style={{ top: 11033, left: 150 }}>
          <span
            id="_19.3"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            4.2 Related Services
          </span>
        </div>
        <div className="pos" id="_150:11082" style={{ top: 11082, left: 150 }}>
          <span id="_18.7" style={{ fontSize: '16px', color: '#000000' }}>
            We will share your personal data with our affiliates to provide and
            develop our Services. We may combine information internally across
            the different Services covered by this Privacy Policy to help our
            Services be more relevant and useful to you and others.{' '}
          </span>
        </div>
        <br />
        <div className="pos" id="_150:11219" style={{ top: 11219, left: 150 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            4.3 Service Providers
          </span>
        </div>
        <div className="pos" id="_150:11268" style={{ top: 11268, left: 150 }}>
          <span id="_19.1" style={{ fontSize: '16px', color: '#000000' }}>
            We use others to help us provide our Services (e.g., maintenance,
            analysis, audit, payments, fraud detection, marketing and
            development). They will have access to your information as
            reasonably necessary to perform these tasks on our behalf and are
            obligated not to disclose or use it for other purposes.
          </span>
        </div>
        <br />
        <div className="pos" id="_150:11434" style={{ top: 11434, left: 150 }}>
          <span
            id="_19.2"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            4.4 Legal Disclosures
          </span>
        </div>
        <div className="pos" id="_150:11483" style={{ top: 11483, left: 150 }}>
          <span id="_19.2" style={{ fontSize: '16px', color: '#000000' }}>
            It is possible that we will need to disclose information about you
            when required by law, subpoena, or other legal process or if we have
            a good faith belief that disclosure is reasonably necessary to (1)
            investigate, prevent or take action regarding suspected or actual
            illegal activities or to assist government enforcement agencies; (2)
            enforce our agreements with you; (3) investigate and defend
            ourselves against any third-party claims or allegations; (4) protect
            the security or integrity of our Services (such as by sharing with
            companies facing similar threats); or (5) exercise or protect the
            rights and safety of{' '}
            <span className=" monotypepolicies">Vipar</span>, our Members,
            personnel or others. We attempt to notify Members about legal
            demands for their personal data when appropriate in our judgment,
            unless prohibited by law or court order or when the request is an
            emergency. We may dispute such demands when we believe, in our
            discretion, that the requests are overbroad, vague or lack proper
            authority, but we do not promise to challenge every demand.{' '}
          </span>
        </div>
        <br />
        <div className="pos" id="_150:12144" style={{ top: 12144, left: 150 }}>
          <span
            id="_19.0"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            4.5 Change in Control or Sale
          </span>
        </div>
        <div className="pos" id="_150:12192" style={{ top: 12192, left: 150 }}>
          <span id="_19.0" style={{ fontSize: '16px', color: '#000000' }}>
            We can also share your personal data as part of a sale, merger or
            change in control, or in preparation for any of these events. Any
            other entity which buys us or part of our business will have the
            right to continue to use your data, but only in the manner set out
            in this Privacy Policy unless you agree otherwise.
          </span>
        </div>
        <br />
        <div className="pos" id="_125:12360" style={{ top: 12360, left: 125 }}>
          <span
            id="_18.6"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            5. Your Choices &amp; Obligations
          </span>
        </div>
        <br />
        <div className="pos" id="_150:12409" style={{ top: 12409, left: 150 }}>
          <span
            id="_19.3"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            5.1 Data Retention
          </span>
        </div>
        <div className="pos" id="_150:12458" style={{ top: 12458, left: 150 }}>
          <span id="_18.8" style={{ fontSize: '16px', color: '#000000' }}>
            We generally retain your personal data as long as you keep your
            account open or as needed to provide you Services. This includes
            data you or others provided to us and data generated or inferred
            from your use of our Services.. In some cases we choose to retain
            certain information (e.g., insights about Services use) in a
            depersonalized or aggregated form.
          </span>
        </div>
        <br />
        <div className="pos" id="_150:12654" style={{ top: 12654, left: 150 }}>
          <span
            id="_18.7"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            5.2 Account Closure
          </span>
        </div>
        <div className="pos" id="_150:12702" style={{ top: 12702, left: 150 }}>
          <span id="_18.7" style={{ fontSize: '16px', color: '#000000' }}>
            If you choose to close your{' '}
            <span className=" monotypepolicies">Vipar</span> account, your
            personal data will generally stop being visible to others on our
            Services within 48 hours. We generally delete closed account
            information within 7 days of account closure, except as noted below.
            We retain your personal data even after you have closed your account
            if reasonably necessary to comply with our legal obligations
            (including law enforcement requests), meet regulatory requirements,
            resolve disputes, maintain security, prevent fraud and abuse,
            enforce our User Agreement, or fulfill your request to "unsubscribe"
            from further messages from us. We will retain de-personalized
            information after your account has been closed. Information you have
            shared with others (e.g., through InMail, updates or group posts)
            will remain visible after you close your account or delete the
            information from your own profile or mailbox, and we do not control
            data that other Members have copied out of our Services. Groups
            content and ratings or review content associated with closed
            accounts will show an unknown user as the source. Your profile may
            continue to be displayed in the services of others (e.g., search
            engine results) until they refresh their cache.
          </span>
        </div>
        <br />
        <div className="pos" id="_125:13530" style={{ top: 13530, left: 125 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            6. Other Important Information
          </span>
        </div>
        <br />
        <div className="pos" id="_150:13579" style={{ top: 13579, left: 150 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            6.1. Security
          </span>
        </div>
        <div className="pos" id="_150:13628" style={{ top: 13628, left: 150 }}>
          <span id="_19.1" style={{ fontSize: '16px', color: '#000000' }}>
            We implement security safeguards designed to protect your data. We
            regularly monitor our systems for possible vulnerabilities and
            attacks. However, we cannot warrant the security of any information
            that you send us. There is no guarantee that data may not be
            accessed, disclosed, altered, or destroyed by breach of any of our
            physical, technical, or managerial safeguards.{' '}
          </span>
        </div>
        <br />
        <div className="pos" id="_150:13824" style={{ top: 13824, left: 150 }}>
          <span
            id="_19.1"
            style={{ fontWeight: 'bold', fontSize: '16px', color: '#000000' }}
          >
            6.2. Contact Information
          </span>
        </div>
        <div className="pos" id="_150:13872" style={{ top: 13872, left: 150 }}>
          <span id="_19.1" style={{ fontSize: '16px', color: '#000000' }}>
            If you have questions or complaints regarding this Policy, please
            reach out to us at{' '}
            <span id="_17.5" style={{ fontSize: '16px' }}>
              admin@prochure.in.
            </span>
          </span>
        </div>
        <br />
      </div>
    </>
  );
}
