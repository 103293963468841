import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { SubCat } from "./SubCat";
import axios from "axios";
import { Navoutside } from "../Navoutside/Navoutside";
import { NewNavigationBarDissabled } from "../../Pages/Free/NewNavigationBar/NewNavigationBarDissabled";

function setCookie(name, value, hours) {
    const expires = new Date();
    expires.setTime(expires.getTime() + hours * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

function getCookie(name) {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        const cookieName = cookie[0];
        const cookieValue = cookie[1];

        if (cookieName === name) {
            return cookieValue;
        }
    }

    return null;
}

export function DemoProfileViaSubCategory() {

    const [data, setdata] = useState(false);
    const [load, setload] = useState(true);
    const [error, seterror] = useState("");
    const [output, setoutput] = useState("");


    useEffect(() => {

        axios.post('/api/Free/getallprofilesbasedonsubcategory', {})
            .then((res) => {
                console.log(res.data)
                const postSummaries = res.data.map((post) => ({
                    id: post._id,
                    key: post.id,
                    sub: post.sub,
                    // num: post.num,
                    cons: post.cons.length,
                }));
                setdata(postSummaries);
                setload(false);
            })
            .catch((err) => { console.log(err) })
    }, []);


    function makeid(length) {
        length = 5;
        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    const [Available, setAvailable] = useState([])


    useEffect(() => {
        if (load) {
            setoutput(
                <>
                    <br />
                    <div className="text-center ">
                        {" "}
                        <div className="spinner-border text-dark"></div>
                    </div>
                </>
            );
        } else {
            if (data) {
                setoutput(
                    data.map((number) => {
                        if (number.cons > 0) {
                            return <SubCat key={number.id} ids={makeid()} data={number}></SubCat>
                        }
                    })
                );
            } else {
                setoutput(error);
            }
        }
    }, [error, load]);

    let alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    const [One, setOne] = useState('')
    const controller = (e) => {
        if (One === e.target.value) {
            setOne('')
        } else {
            setOne(e.target.value)
        }
    }
    useEffect(() => {
        if (data.length)
            if (One.length === 0) {
                setoutput(
                    data.map((number) => {
                        let ind = 1
                        if (number.cons > 0) {
                            return <SubCat key={number.id} index={ind} ids={makeid()} data={number}></SubCat>
                        }
                    })
                );
            } else {
                let temp = [...data]
                let render = temp.filter((ev) => {
                    let reg = new RegExp(`^${One}`, 'i')
                    if (ev.sub.match(reg)) {
                        return ev
                    } else {

                    }
                })
                setoutput(
                    render.map((number) => {
                        let ind = 1
                        if (number.cons > 0) {
                            return <SubCat key={number.id} index={ind} ids={makeid()} data={number}></SubCat>
                        }
                    })
                )
            }
        return () => {

        }
    }, [One])



    var catlength = data.length

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    useEffect(() => {
        const hasCodeExecuted = getCookie('hasCodeExecuted');

        if (!hasCodeExecuted) {
            // Code to run only once when the component is mounted
            setTimeout(() => {
                document.getElementById('demoinfomodal').click()
            }, 7000);

            // Set the cookie with an expiration of 1 day
            setCookie('hasCodeExecuted', true, 1);
        }
    }, []);

    return (
        <>
            <NewNavigationBarDissabled />


            <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
                <div className="row">
                    <center><h5 className="parapara">SUB CATEGORIES</h5></center>
                    {/* <center><p>{cate}</p></center> */}
                    <center>
                        <div className="p-2">
                            <div className='AlphaNav border grid-containerX p-1 m-0' >
                                {alpha.map((elem, index) => {
                                    return <div id="ck-button">
                                        <label>
                                            <input onChange={(e) => controller(e)} checked={elem === One ? true : false} value={elem} style={{ textTransform: 'capitalize' }} href={`#Cat-` + elem.toUpperCase() + "0"} type="checkbox" ></input>
                                            <span className='forntweightreducer'>{elem}</span>
                                        </label>
                                    </div>
                                })}
                            </div>
                        </div>
                    </center >
                    {output}
                </div>
            </div>

            <Button variant="primary" id="demoinfomodal" onClick={handleShow} className="invisible">
                Launch demo modal
            </Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="mb-4">This is only a DEMO page to show how the profiles would look.<br />It does not Reflect the entire App. This is ONLY for Marketing.</Modal.Body>

            </Modal>
        </>
    );
}

