import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./ConSubcategory.css";
import { useSearchParams } from "react-router-dom";
import { SubCat } from "./SubCat";
import axios from "axios";
import { BsQuestionCircle } from "react-icons/bs";
import { Mask } from "../../Utility/mask";

export function ConSubcategory(props) {
  const [data, setdata] = useState(false);
  const [cat, setCat] = useState([]);
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  var cate = searchParams.get("sub_category");
  useEffect(() => {
    /////getting id from the url the id of the jobs id

    var id = Mask.decode(searchParams.get("brand_id"));
    axios
      .post("/api/Free/subsubcategorycon", { id })
      .then((res) => {
        console.log(res.data);
        const postSummaries = res.data.map((post) => ({
          id: post._id,
          key: post.id,
          sub: post.sub,
          brand: post.brand,
          cons: post.cons.length,
          origin: post.origin,
        }));
        setdata(postSummaries);
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });

    // axios.get('/api/User/subconcount?subid='+id).then((res)=>{
    //   console.log(res)
    //   setadhhzd(res)
    // }).catch((err)=>{console.log(err)})

    // console.log(con)

    // console.log(JSON.parse(id));
    // id = JSON.parse(id);
    // setCat(id);
    // console.log(id);
  }, []);

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const [Available, setAvailable] = useState([]);

  useEffect(() => {
    console.log(data);
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {" "}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data) {
        let temp = "";
        setoutput(
          data.map((number) => {
            let ind = 1;
            if (temp === number.brand.slice(0, 1)) {
              ind = 1;
            } else {
              setAvailable((prev) => [...prev, number.brand.slice(0, 1)]);
              temp = number.brand.slice(0, 1);
              ind = 0;
            }
            if (number.cons > 0) {
              return (
                <SubCat
                  key={number.id}
                  index={ind}
                  ids={makeid()}
                  data={number}
                ></SubCat>
              );
            }
          })
        );
      } else {
        setoutput(error);
      }
    }
  }, [error, load]);

  const liquermodal = () => {
    document.getElementById("subsubmodalprochuresabaribtnrt").click();
  };
  let alpha = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  return (
    <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
      <div>
        <h5 className="parapara text-center">
          BRAND CATEGORISATION
          {/* &nbsp;<BsQuestionCircle style={{ 'color': 'red', 'marginTop': '-5px' }} onClick={liquermodal} /> */}
        </h5>
        <p className="text-center">{cate}</p>
        <div className="container ">
          <p className="text-lg-center text-md-start">
            Brands with exclusive categorisation are only national and
            international brands which are popular pan India.
          </p>
        </div>
      </div>
      <center>
        <div className="p-2">
          <div className="AlphaNav border grid-containerX p-1 m-0">
            {/* <li className="item1" ></li> */}
            {alpha.map((elem, index) => {
              return (
                <a
                  style={{ textTransform: "capitalize" }}
                  href={`#Cat-` + elem.toUpperCase() + "0"}
                >
                  {elem}
                </a>
              );
            })}
          </div>
        </div>
      </center>
      <div className={`row ${load === false ? "" : "notranslate"}`}>
        {output}
      </div>
    </div>
  );
}
