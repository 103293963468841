
import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import Axios from 'axios'
import { SpinnerBig } from '../../Utility/spinner';
import { Mask } from '../../Utility/mask';

export const Information_Subsubcategory_list = () => {
  let [searchParams, setSearchParams] = useSearchParams();  // id of sub category
  var id = searchParams.get("k")
  id = Mask.decode(id)
  const [Category, setCategory] = useState([])
  const [Loading, setLoading] = useState(true)
  useEffect(() => {

    Axios.post('/api/api/Information_sub_sub_category_only', { id }).then((res) => {
      if (res.status == 200) {
        console.log(res.data)
        setCategory(res.data)
        setLoading(false)
      }
    }).catch((err) => {
      setLoading(false)

    })
  }, [])



  return (
    <div className='container-fluid' style={{ "paddingTop": "80px" }}>

      <div className=''>
        {
          Loading ?
            <SpinnerBig />
            :
            <div className="row ">
              {
                Category.length > 0 ?
                  Category.map((e) => {
                    return <>

                      <div
                        className="col-12 col-md-6  col-lg-6 col-xl-4 col-xxl-4 mb-3 ">
                        <Link to={`/lobby/Information_listing?k=${Mask.encode(e._id)}`}

                          className="linkoflink"
                        >
                          <div className="gtyurie p-3 ">



                            <div className="row">
                              <div className="col-4"></div>

                              <div className="col-4"></div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="ml-3 w-100">
                                <h4 className="mb-2 mt-0 pt-3 pb-3 center">
                                  {e.sub_sub_category}
                                </h4>{" "}


                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>

                    </>
                  })
                  : <center style={{ "paddingTop": "80px" }}><h3>Nothing to show !</h3></center>
              }
            </div>
        }


      </div>
    </div>

  )
}

