import React,{useEffect} from "react";

import { Route, Routes } from "react-router";
  import Pview2 from "./Pview";
import Edit2 from "./Edit";

function ProductProfile(props) {
  useEffect(() => {
    props.Auth()
    }, [])
  return (
    <div>
      <Routes>
        <Route element={<Pview2 Auth={props.Auth}/>} exact path="/"></Route>
        <Route element={<Edit2 Auth={props.Auth}/>} path="/Edit_profile_p"></Route>
      </Routes>
    </div>
  );
}

export default ProductProfile;
