

import React, { useRef, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import Moment from 'moment';
import "react-toastify/dist/ReactToastify.css";
import { ChatContext } from "../../../Components/ChatFinal/ChatFinal";

export function Rinvite(props) {
    let chatContext = useContext(ChatContext)
    console.log(chatContext)
    console.log(props.data)
    const [Deleted, setDeleted] = useState(false)
    const [Status, setStatus] = useState(props.data.Status)
    const rinviteref = useRef()
    const InvitationResponse = async (id, stat, Tom) => {
        // console.log('invitation response')
        if (id && stat) {
            let dataSend = {
                id,
                stat
            }
            try {

                // document.getElementById('Tommy' + Tom).classList.add('d-none')
                console.log('hhe')
                var send = await axios.post('/api/user/InvitationController', { dataSend })
                // rinviteref.current.classList.add('d-none')
                console.log(send)
                if (send.status === 201) {
                    
                    setStatus(send.data);
                } else if (send.status === 200) {
                    setStatus(send.data)
                    if (send.data === 'ACCEPTED') {
                        chatContext.ReloadList()
                        return setStatus(send.data);
                    }
                }
                return
                if (send) {
                    chatContext.ReloadList()
                    // console.log(stat)
                    // console.log(send)
                    return;
                    if (stat === 'accept') {
                        // console.log('accepted')
                        // chatContext.ReloadList()
                        // props.data.ReloadList()
                    } else {
                        // console.log('no')
                    }
                }
            }
            catch (err) {
                console.log(err.response)
            }
        }
    }
    return (<div ref={rinviteref} className="row">
        <div className="col-10 ps-4 ">
            <div className="  p-1 m-2">
                <div className="row ps-3 ">
                    <div className="col-12 p-0 pb-1 m-0">
                        {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp" alt="avatar"
                                                                className="rounded-circle chatAvatar me-2 align-self-center  shadow-1-strong" width="30" /> */}
                        {props?.data?.sender?.firstname==undefined?"Deleted Account":props.data.sender.firstname} 
                    </div>
                    <div className="m-0 ms-0 p-0">

                        <div className="row p-0">
                            <div className="p-0 ms-3 mesbox">
                                <div className="message mb-0  px-3">
                                    <small>
                                        {props?.data?.sender?.firstname==undefined?"Deleted Account":props.data.sender.firstname} Invited {props.data.content}

                                    </small>
                                </div>
                                <div>
                                    {Status === 'ACTIVE' ?
                                        <> <a onClick={(e) => InvitationResponse(props.data._id, 'accept')} className="btn btn-outline-success">Accept</a> &nbsp;
                                            <a onClick={(e) => InvitationResponse(props.data._id, 'reject')} className="btn btn-outline-danger">Reject</a></>
                                        : Status === 'ACCEPTED' ? <> <a className="btn btn-outline-success disabled">{Status}</a> &nbsp;</>
                                            : Status === 'REJECTED' ? <><a className="btn btn-outline-danger disabled">{Status}</a> &nbsp;</>
                                                : <><a className="btn btn-outline-dark disabled">{Status}</a> &nbsp;</>

                                    }

                                </div>
                            </div>
                            <p className="mt-0  pe-4"> {Moment(props.data.createdAt ? props.data.createdAt.slice(0, 24) : '').format(' h:mm:ss a')}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className="col-2">
        </div>
    </div >)
}