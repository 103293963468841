import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { MdViewCarousel } from "react-icons/md";

import axios from "axios";
import { HighPreview, NewGrid } from "../../../Components/Grid/NewGrid";
import { notify } from "../../../Utility/notify";

export function GetportExternal(props) {
  const [dopper, setDooper] = useState(false);
  const [filedel, setFiledel] = useState([]);
  const [Setup, setSetup] = useState(true);
  var Acc = props.Acc;

  useEffect(() => {
    getport();

    return () => {};
  }, []);

  const getport = async () => {
    var data = await axios
      .post("/api/User/getportfolio", {
        Acc,
      })
      .then((res) => {
        setDooper(res.data);
        console.log(res.data);
        if (res) {
          return (
            <div
              className="tab-pane about fade show active"
              id="Portfolio"
              role="tabpanel"
              aria-labelledby="profile-tab"
            ></div>
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  };

  // useEffect(() => {
  //   getport();
  // }, []);

  function buds(e, iuy) {
    console.log(iuy);
    var arr = filedel;
    arr.push(iuy);
    setFiledel(arr);

    // setValue(...value, File:e.target.value)
  }

  const requestPort = () => {
    axios
      .post("/api/User/requestportfolio", { Acc })
      .then((res) => {
        notify("Requested", true);
      })
      .catch((err) => {
        console.log(err);
        notify("Something went wrong", false);
      });
  };
  const [Elem, setElem] = useState(undefined);
  return (
    <div className="row text-dark">
      {dopper.length > 0 ? (
        dopper.map((element, ind) => {
          return (
            <>
              {" "}
              <div className="col-12  col-xl-6 mb-3">
                <a
                  className="portfolioLink"
                  data-bs-toggle="modal"
                  data-bs-target={"#portfolio" + element._id}
                >
                  <div className="card">
                    <img
                      src={
                        element.File[0]
                          ? element.File[0].filename
                          : "/port.jpeg"
                      }
                      className="img-fluid wrighirhgipjpihdfpjhpidfpi"
                      alt="..."
                    />
                    <div className="card-body text-dark">
                      <center>
                        <h5 className="card-title">
                          {element.title ? element.title : ""}
                        </h5>
                      </center>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="modal fade  seiufgigfiuwegiuowebh"
                id={"portfolio" + element._id}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        {element.title ? element.title : ""}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="w-100">
                        {/* <button
                                          className="btn-danger"
                                          type="button"exampleModa
                                          onClick={(e) => lossupennu(e, ind)}
                                        >
                                          EDIT
                                        </button> */}
                        {/* <button className="btn-danger">
                                          DELETE
                                        </button> */}
                        {/* <button
                                          className="btn-danger d-none float-end"
                                          id={"trkorrr" + ind}
                                          onClick={(e) => pyari(e, ind)}
                                        >
                                          CANCEL
                                        </button> */}
                        {/* <AiFillFileAdd className="uhoihfoisbvoibs d-none float-end"  id={"uhf"+ind} onClick={(e)=>jvur(e,"wnvisnv"+ind)}/>
                    <input className="d-none" id={"wnvisnv"+ind} type="file" onChange={(e)=>setFile(e.target.value)} accept="image/,video/"/> */}
                      </div>
                      <div className=" container-fluid p-0">
                        <div className="">
                          <center>
                            <span
                              onClick={(e) => setSetup(true)}
                              style={{ cursor: "pointer" }}
                              className={`px-2 ${Setup && "apptheamcolor"}`}
                            >
                              <MdViewCarousel size={25} />
                            </span>
                            <span
                              onClick={(e) => setSetup(false)}
                              style={{ cursor: "pointer" }}
                              className={`px-2 ${!Setup && "apptheamcolor"}`}
                            >
                              <BsFillGrid1X2Fill />
                            </span>
                          </center>
                        </div>

                        {element.File.length > 0 ? (
                          <label className="ms-1"></label>
                        ) : (
                          <></>
                        )}
                        {Setup ? (
                          <div
                            id={"carouselExampleControlsre" + ind}
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-inner">
                              {element.File
                                ? element.File.map((element1, index) => {
                                    console.log(index);
                                    if (index === 0) {
                                      if (
                                        "image" ===
                                        element1.filetype.slice(0, 5)
                                      ) {
                                        return (
                                          <div className="carousel-item active brokendrtff">
                                            <AiOutlineCloseCircle
                                              id={"trkor" + index + ind}
                                              className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                              onClick={(e) =>
                                                buds(e, element1.filename)
                                              }
                                            />
                                            <img
                                              onClick={() => setElem(element1)}
                                              alt=""
                                              className="snblsnlbns"
                                              src={element1.filename}
                                            />
                                            <p>
                                              {element.titles[index] &&
                                                element.titles[index].title}
                                            </p>
                                            <p>
                                              {element.discriptions[index] &&
                                                element.discriptions[index]
                                                  .Disc}
                                            </p>
                                          </div>
                                        );
                                      } else if (
                                        "video" ===
                                        element1.filetype.slice(0, 5)
                                      ) {
                                        return (
                                          <div className="carousel-item active">
                                            <AiOutlineCloseCircle
                                              id={"trkor" + index + ind}
                                              className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                              onClick={(e) =>
                                                buds(e, element1.filename)
                                              }
                                            />
                                            <video
                                              onClick={() => setElem(element1)}
                                              className="carItem"
                                              controls
                                            >
                                              {" "}
                                              <source
                                                src={element1.filename}
                                              />{" "}
                                            </video>
                                            <p>
                                              {element.titles[index] &&
                                                element.titles[index].title}
                                            </p>
                                            <p>
                                              {element.discriptions[index] &&
                                                element.discriptions[index]
                                                  .Disc}
                                            </p>
                                          </div>
                                        );
                                      } else if (
                                        "audio" ===
                                        element1.filetype.slice(0, 5)
                                      ) {
                                        return (
                                          <div className="carousel-item active">
                                            <AiOutlineCloseCircle
                                              id={"trkor" + index + ind}
                                              className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                              onClick={(e) =>
                                                buds(e, element1.filename)
                                              }
                                            />
                                            <audio
                                              onClick={() => setElem(element1)}
                                              controls
                                              className="carItem"
                                            >
                                              {" "}
                                              <source
                                                src={element1.filename}
                                                type="audio/ogg"
                                              />{" "}
                                            </audio>
                                            <p>
                                              {element.titles[index] &&
                                                element.titles[index].title}
                                            </p>
                                            <p>
                                              {element.discriptions[index] &&
                                                element.discriptions[index]
                                                  .Disc}
                                            </p>
                                          </div>
                                        );
                                      }
                                    } else {
                                      if (
                                        "image" ===
                                        element1.filetype.slice(0, 5)
                                      ) {
                                        return (
                                          <div className="carousel-item brokendrtff">
                                            <AiOutlineCloseCircle
                                              id={"trkor" + index + ind}
                                              className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                              onClick={(e) =>
                                                buds(e, element1.filename)
                                              }
                                            />
                                            <img
                                              onClick={() => setElem(element1)}
                                              alt=""
                                              className="snblsnlbns "
                                              src={element1.filename}
                                            />
                                            <p>
                                              {element.titles[index] &&
                                                element.titles[index].title}
                                            </p>
                                            <p>
                                              {element.discriptions[index] &&
                                                element.discriptions[index]
                                                  .Disc}
                                            </p>
                                          </div>
                                        );
                                      } else if (
                                        "video" ===
                                        element1.filetype.slice(0, 5)
                                      ) {
                                        return (
                                          <div className="carousel-item ">
                                            <AiOutlineCloseCircle
                                              id={"trkor" + index + ind}
                                              className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                              onClick={(e) =>
                                                buds(e, element1.filename)
                                              }
                                            />
                                            <video
                                              onClick={() => setElem(element1)}
                                              className="carItem"
                                              controls
                                            >
                                              {" "}
                                              <source
                                                src={element1.filename}
                                              />{" "}
                                            </video>
                                            <p>
                                              {element.titles[index] &&
                                                element.titles[index].title}
                                            </p>
                                            <p>
                                              {element.discriptions[index] &&
                                                element.discriptions[index]
                                                  .Disc}
                                            </p>
                                          </div>
                                        );
                                      } else if (
                                        "audio" ===
                                        element1.filetype.slice(0, 5)
                                      ) {
                                        return (
                                          <div className="carousel-item ">
                                            <AiOutlineCloseCircle
                                              id={"trkor" + index + ind}
                                              className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                              onClick={(e) =>
                                                buds(e, element1.filename)
                                              }
                                            />
                                            <audio
                                              onClick={() => setElem(element1)}
                                              controls
                                              className="carItem"
                                            >
                                              {" "}
                                              <source
                                                src={element1.filename}
                                                type="audio/ogg"
                                              />{" "}
                                            </audio>
                                            <p>
                                              {element.titles[index] &&
                                                element.titles[index].title}
                                            </p>
                                            <p>
                                              {element.discriptions[index] &&
                                                element.discriptions[index]
                                                  .Disc}
                                            </p>
                                          </div>
                                        );
                                      }
                                    }
                                  })
                                : ""}
                            </div>
                            {element.File && !Elem ? (
                              element.File.length > 1 ? (
                                <>
                                  <button
                                    id="Bbtn"
                                    className="carousel-control-prev ekgdfukweguobwljbvuwgcuonew"
                                    type="button"
                                    data-bs-target={
                                      "#carouselExampleControlsre" + ind
                                    }
                                    data-bs-slide="prev"
                                  >
                                    <span
                                      className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                      Previous
                                    </span>
                                  </button>
                                  <button
                                    id="Fbtn"
                                    className="carousel-control-next ekgdfukweguobwljbvuwgcuonew"
                                    type="button"
                                    data-bs-target={
                                      "#carouselExampleControlsre" + ind
                                    }
                                    data-bs-slide="next"
                                  >
                                    <span
                                      className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                      Next
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <NewGrid
                            files={element.File}
                            titles={element.titles}
                            discriptions={element.discriptions}
                          ></NewGrid>
                        )}
                      </div>
                      <HighPreview
                        Elem={Elem}
                        onClose={() => {
                          setElem(undefined);
                        }}
                      />
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <>
          <p>&nbsp;&nbsp;Nothing to Display</p>
          <button
            className="btn pro-spaces-button3"
            type="button"
            onClick={requestPort}
          >
            Request Portfolio
          </button>
        </>
      )}
      {/* portfolio modal starts */}
    </div>
  );
}
