import React, { useEffect, useState } from "react";

import { Route, Routes } from "react-router";
import { CreateProfile } from "../NewProfile/profile/CreateProfile";
import { CreateConseltent } from "./CreateConseltent/CreateConseltent";
import { CreateProduct } from "../NewProfile/product/CreateProduct";
import { CreateAdmin } from "../../Pages/Admininside/Profile/CreateAdmin";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
export function ProfileCreation(props) {
  useEffect(() => {
    props.Auth();
  }, []);

  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();

  return (
    <div>
      {/* {Authenticated ? */}
      <Routes>
        <Route
          element={<CreateProfile Auth={props.Auth} />}
          exact
          path="/"
        ></Route>
        <Route
          element={<CreateConseltent Auth={props.Auth} />}
          path="create_services_profile"
        ></Route>
        <Route
          element={<CreateProduct Auth={props.Auth} />}
          path="create_products_profile"
        ></Route>
        <Route element={<CreateAdmin />} path="create_admin_profile"></Route>
      </Routes>
      {/* : ''} */}
    </div>
  );
}
