import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/signin.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Navoutside } from "../Navoutside/Navoutside";
import axios from "axios";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { NewNavigationBar } from "../../Pages/Free/NewNavigationBar/NewNavigationBar";
// import {Priceing} from "../Priceing/Priceing"

export const Signin = () => {
  const [deletedrecovery, setdeletedrecovery] = useState(false);
  let navigate = useNavigate();
  const [Authenticated, setAuthenticated] = useState(false);
  const Auth = () => {
    axios
      .get("/api/verify/authenticateduser")
      .then((res) => {
        if (res.status === 200) {
          console.log("workd");
          setAuthenticated(true);
          navigate("/");
        }
      })
      .catch((err) => {
        // console.log('no sab')
        console.log(err.response.status);
      });
  };

  useEffect(() => {
    Auth();
  }, []);

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Submit, setSubmit] = useState(false);
  const [Error, setError] = useState("");
  const [ServerError, setServerError] = useState(false);
  const [loading, setloading] = useState(false);
  const [first, setfirst] = useState(true);
  const [logFromAll, setLogFromAll] = useState(false);
  // let [searchParams, setSearchParams] = useSearchParams();
  // searchParams.get("k")

  /* eslint-disable */
  function Validate() {
    if (Email.length >= 1) {
      if (isNaN(Email)) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
          setError("");
        } else {
          setError("Enter a valid Email Id");
          return false;
        }
      } else {
        if (Email.length != 10) {
          setError("Enter a valid Phone Number");
          return false;
        }
      }
    } else {
      console.log(Email.length);
      return setError("Enter Login Id");
    }
    if (Password.length == 0) {
      setError("PLEASE ENTER Password");
      return false;
    } else if (Password.length < 8) {
      setError("PLEASE ENTER a valid password ");
      return false;
    } else {
      setError("");
    }
    return true;
  }

  const formhandler = (e) => {
    e.preventDefault();
    setLogFromAll(false);
    var status = false;
    setSubmit(true);
    status = Validate();
    if (status == false) {
      return;
    }

    setloading(true);
    console.log("status");

    var dataSend = {
      Contact: Email,
      Password,
    };
    console.log("status2");

    Axios.post("/api/auth/login", { dataSend })
      .then((res) => {
        // const persons = res.data;
        setloading(false);
        console.log(res.data);
        console.log("send success");
        if (res.status == "200") {
          // console.log('re');
          return navigate("/");
        } else if (res.status == "206") {
          console.log(res.data);
          if (res.data === "Active") {
            return navigate("/next/");
          } else if (res.data === "Passive") {
            return navigate("/passive/");
          } else if (res.data === "Activev2") {
            return navigate("/next_/");
          } else if (res.data === "Activev3") {
            return navigate("/next__/");
          }
        } else if (res.status == "207") {
          if (res.data.type === "Active" || res.data.type === "Activev2") {
            if (
              res.data.result.Type === "Consultant" ||
              res.data.result.Type === "Consultantv2"
            ) {
              return navigate(`/edit_profile_services_dce`);
            } else {
              return navigate("/edit_profile_products_dce");
            }
          } else {
            if (res.data.result.Type === "Consultantv3") {
              return navigate(`/edit_profile_for_v3_service_dce`);
            } else {
              return navigate(`/edit_profile_for_v3_product_dce`);
            }
          }
        } else {
          console.log("le");
          return;
        }
      })
      .catch((err) => {
        setError("");
        console.log("send success2");
        if (err.response.status == "400" || err.response.status == "401") {
          setError(err.response.data);
          setloading(false);
        } else if (err.response.status == "402") {
          return navigate("/payment");
          // console.log('payment')
        } else if (err.response.status == "500") {
          setError("Something went wrong !");
          setloading(false);
        } else if (err.response.status == "406") {
          console.log("7736336366hhhh21");
          setError(err.response.data);
          setloading(false);
          setLogFromAll(true);
        } else if (err.response.status == "422") {
          setdeletedrecovery(true);
          setloading(false);
        }
      });
  };
  useEffect(() => {
    if (Submit) {
      // Validate()
    }
  });
  const logoutFromAllDevices = () => {
    let status = Validate();
    if (status == false) {
      return;
    }
    console.log("status");
    var dataSend = {
      Contact: Email,
      Password,
    };
    console.log("status2");
    axios
      .post("/api/auth/logoutFromAll", { dataSend })
      .then((res) => {
        console.log(res);
        setError("");
        setLogFromAll(false);
      })
      .catch((err) => {
        console.log(err);
        setLogFromAll(false);
        setError("Something went wrong !");
      });
  };
  const cancelDelete = () => {
    let status = Validate();
    if (status == false) {
      return;
    }
    console.log("status");
    var dataSend = {
      Contact: Email,
      Password,
    };
    setdeletedrecovery(false);
    console.log("status2");
    axios
      .post("/api/auth/cancelDelete", { dataSend })
      .then((res) => {
        console.log(res);
        setError("");
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong !");
      });
  };
  return (
    <>
      <NewNavigationBar />
      {/* <Priceing /> */}
      {/* <div className="d-flex mt-5 pt-5 justify-content-center"> */}
      <div
        className="d-flex  justify-content-center notranslate"
        style={{ paddingTop: "90px" }}
      >
        <form
          onSubmit={(e) => formhandler(e)}
          className="card signin px-4 pt-5 pb-2 mt-3 "
          style={{ maxWidth: "400px" }}
        >
          <h2 className="mb-2" style={{ fontWeight: "600" }}>
            SIGN IN / LOG IN page for registered members
          </h2>
          {/* <p className="mb-2">Stay updated</p> */}
          <div className="w-100">
            <div className="mt-2 mb-4">
              <input
                type="text"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                id="floatingInput"
                placeholder="Login Id"
              />
            </div>
            <div className="mt-2 mb-3">
              <input
                type={first ? "password" : "text"}
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control sbdciuwg"
                id="floatingPassword"
                placeholder="Password"
              />
              <div className=" sjdhfkshdkjcghu me-2">
                {first ? (
                  <AiFillEyeInvisible onClick={(e) => setfirst(!first)} />
                ) : (
                  <AiFillEye onClick={(e) => setfirst(!first)} />
                )}
              </div>
            </div>
            <div className="text-danger mx-0 mt-1">{Error}</div>
            <div className="text-danger mx-0 mt-1">{ServerError}</div>
            {logFromAll && (
              <div>
                <button
                  type="button"
                  onClick={logoutFromAllDevices}
                  style={{ fontSize: "10px" }}
                  className="btn px-3 pro-spaces-button2"
                >
                  Click here{" "}
                </button>{" "}
                to Log Out from all your logged in devices !
              </div>
            )}
            {deletedrecovery && (
              <div>
                <button
                  type="button"
                  onClick={cancelDelete}
                  style={{ fontSize: "10px" }}
                  className="btn px-3 pro-spaces-button2"
                >
                  Click here{" "}
                </button>{" "}
                to activate the account
              </div>
            )}
            <Link to="/forget" className="aldfhahfoiheaf mt-2">
              forgot password?
            </Link>
          </div>
          {!loading && (
            <center>
              <input
                id="signinbtn"
                className="btn mt-2"
                type="submit"
                value="Sign in"
              />
            </center>
          )}
          {loading && (
            <center>
              <button id="signinbtn" class="btn  mt-2" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            </center>
          )}
          <center>
            <a
              a
              data-bs-toggle="modal"
              data-bs-target="#exampleModalsabariregister"
            >
              <p className="mt-3">
                I am not a member.
                <span className="  shgoshigpiscmgih aldfhahfoiheaf ">
                  {" "}
                  &nbsp;REGISTER{" "}
                </span>
              </p>
            </a>
          </center>
        </form>
      </div>
    </>
  );
};
