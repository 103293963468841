import React, { useEffect, useState, useRef } from "react"
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { AiFillExclamationCircle } from 'react-icons/ai'
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { print_state, print_city } from '../../../Utility/stateCity'
import { keyGen } from '../../../Utility/helper'
import {BsFillPlusCircleFill} from 'react-icons/bs' 
import { ProductDiscription } from "./port/PortProductDiscription";
import { ProjectDiscriptions } from "./port/ProjectDiscriptions";
export const NewLaunchPortAdd = (props) => {
    const formData = new FormData()
    const [show, setShow] = useState(false);
    const target1 = useRef(null);
    const target2 = useRef(null);
    const target3 = useRef(null);
    const target4 = useRef(null);
    const target5 = useRef(null);
    const [Image, setImage] = useState(false)
    const [Video, setVideo] = useState(false)
    const [first, setfirst] = useState();
    const [rerender, setRerender] = useState(false);
    const [showMedia, setshowMedia] = useState([])
    const [Progress, setProgress] = useState(0)
    const [error, setError] = useState();
    const [value, setValue] = useState({
        type: 'project',
        catalouge_title: '',
        
        brand: "",
        project_name: "",
        project_locality: "",
        project_type: "",
        number_of_units: "",
        number_of_floor: "",
        number_of_bedroom: "",
        area: "",
        carpet_area: "",
        project_discription: "",
        project_highlights: "",
        project_status: "",
        rera: "",
        vasstu: "",
        occupancy_avalibility: "",
        occupancy_certificate: "",
        association: '',
        rate: "",
        offers: "",
        keyword: "",
        state: "",
        city: "",
        Project_Discriptions: []
    });
    const [value2, setValue2] = useState({
        type: 'product',
        catalouge_title: '',
        product_name: "",
        brand: "",
        product_discription: "",
        product_varint: "",
        color: "",
        dimention: "",
        width: "",
        length: "",
        depth: "",
        product_usp: "",
        mrp: "",
        selling_price: "",
        keyword: "",
        Product_Discriptions: []

    });
    const [intailtext, setintailtext] = useState('')

    const Clickhandler = (cont) => {

        console.log(cont)
        if (cont == 'imagexrsreehari') {
            document.getElementById("imagexrsreehari").click();
        } else if (cont == 'videoxrsreehari') {
            document.getElementById("videoxrsreehari").click();
        } else if (cont == 'audioxr2') {
            document.getElementById("audioxr2").click();
        }
    }
    //////////////////////////////
    var arr = []
    const [Titles, setTitles] = useState([])

    const [fil, setfil] = useState([])
    const [Count, setCount] = useState(0)
    const [Disc, setDisc] = useState([])
    const [TitleC, setTitleC] = useState([])
    const [TitleB, setTitleB] = useState([])

    const DiscHandler = (elem) => {
        console.log(Disc)
        let id = elem.target.id
        let pos = id.substring(5)
        console.log(pos)
        let ob = {
            position: pos,
            Disc: elem.target.value
        }
        // let Disce = Disc
        // Disce.push(ob)
        Disc[pos] = ob
        // setDisc({ ...Disc, pos: ob })
        console.log(Disc)
        if (document.getElementsByClassName('carousel-item').length < 2) {
            // console.log('elihelifh')
            setintailtext('')
        } else {

        }
    }
    const TitleHandler = (elem) => {
        console.log(Titles)
        let id = elem.target.id
        console.log(id)
        let pos = id.substring(4)
        console.log(pos)
        let ob = {
            position: pos,
            title: elem.target.value
        }
        Titles[pos] = ob
    }
    const TitleHandlerB = (elem) => {
        console.log(TitleB)
        let id = elem.target.id
        console.log(id)
        let pos = id.substring(5)
        console.log(pos)
        let ob = {
            position: pos,
            title: elem.target.value
        }
        TitleB[pos] = ob
    }
    const TitleHandlerC = (elem) => {
        console.log(TitleC)
        let id = elem.target.id
        console.log(id)
        let pos = id.substring(5)
        console.log(pos)
        let ob = {
            position: pos,
            title: elem.target.value
        }
        TitleC[pos] = ob
    }

    const DeleteThis = (e) => {
        let data = e.target.getAttribute('data')
        console.log(data)
        console.log(data.substring(4))
        let pos = parseInt(data.substring(4))
        fil[pos] = null
        next()
        // console.log(pos)
        // console.log(showMedia)
        // console.log(showMedia.splice(pos, 1))
        check()
        document.getElementById('car' + data).remove()
        if (document.getElementsByClassName('carousel-item').length < 2) {
            // console.log('elihelifh')
            setintailtext('')


        } else {

        }

    }
    const next = () => document.getElementById('next').click()

    const check = () => {
        console.log(Titles)
        console.log(fil)
        console.log(Disc)
    }
    useEffect(() => {
        console.log(fil)
    }, [fil])

    const mediaHandler = (file, type) => {
        console.log('j')


        for (var i = 0; i < file.length; i++) {
            var sab = fil
            sab.push(file[i])
        };
        console.log(file)
        console.log('media handler');
        console.log(showMedia);
        if (showMedia.length > 0) {
            arr = showMedia
        }
        let CounterPro = 0;
        console.log(fil)
        fil.forEach((esc) => {
            console.log(esc)
            esc != null && CounterPro++
        })
        console.log(CounterPro)
        console.log(fil)
        console.log(fil.length)
        var temp = arr
        let Counter = Count
        console.log(type)
        if (type === 'img') {
            for (var i = 0; i < file.length; i++) {
                let newId = 'port' + Counter
                console.log(temp.length)
                if (temp.length === 0 || CounterPro == 1) {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item active ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                <img className='carItem' src={URL.createObjectURL(file[i])}></img><br /><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                                <input id={`B` + newId} onChange={(e) => TitleHandlerB(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                                <input id={`C` + newId} onChange={(e) => TitleHandlerC(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                                <textarea className={`${first === 'Product' ? 'Product' : ''} form-control`} placeholder="" onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                else {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item   ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                <img className='carItem' src={URL.createObjectURL(file[i])}></img><br /><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control`} /><br />
                                <input id={`B` + newId} onChange={(e) => TitleHandlerB(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control`} /><br />
                                <input id={`C` + newId} onChange={(e) => TitleHandlerC(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control`} /><br />
                                <textarea className={`${first === 'Product' ? 'Product' : ''} form-control`} onChange={(e) => DiscHandler(e)} id={`A` + newId} placeholder=""></textarea><br />
                            </div>
                        )
                }
                Counter++
            }
        }
        if (type === 'vid') {

            for (var i = 0; i < file.length; i++) {
                let newId = 'port' + Counter
                if (temp.length === 0 || CounterPro == 1) {

                    temp.push(
                        <div id={`car${newId}`} className="carousel-item  active">
                            <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                            <video className="carItem" controls>
                                <source src={URL.createObjectURL(file[i])} />
                            </video><br />
                            <input id={newId} placeholder="" onChange={(e) => TitleHandler(e)} className={`${first === 'Product' ? 'Product' : ''} form-control`} /><br />
                            <input id={`B` + newId} onChange={(e) => TitleHandlerB(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                            <input id={`C` + newId} onChange={(e) => TitleHandlerC(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                            <textarea className={`${first === 'Product' ? 'Product' : ''} form-control`} placeholder="" onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />

                        </div>
                    )
                }
                else {
                    temp.push(
                        <div id={`car${newId}`} className="carousel-item    ">
                            <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                            <video className="carItem" controls>
                                <source src={URL.createObjectURL(file[i])} />
                            </video><br />
                            <input id={newId} onChange={(e) => TitleHandler(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control`} /><br />
                            <input id={`B` + newId} onChange={(e) => TitleHandlerB(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                            <input id={`C` + newId} onChange={(e) => TitleHandlerC(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                            <textarea className={`${first === 'Product' ? 'Product' : ''} form-control`} placeholder="" onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                        </div>
                    )
                }
                Counter++

            }
        }
        if (type === 'aud') {
            let newId = 'port' + Counter
            for (var i = 0; i < file.length; i++) {
                if (temp.length === 0 || CounterPro == 1) {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item active">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>

                                <div style={{ 'minHeight': '340px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                    <audio controls>
                                        <source src={URL.createObjectURL(file[i])} type="audio/ogg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control`} /><br />
                                <input id={`B` + newId} onChange={(e) => TitleHandlerB(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                                <input id={`C` + newId} onChange={(e) => TitleHandlerC(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                                <textarea className={`${first === 'Product' ? 'Product' : ''} form-control`} placeholder="" onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                else {
                    temp.push
                        (
                            <div id={`car${newId}`} className="carousel-item   ">
                                <button data={newId} type="button" onClick={(e) => DeleteThis(e)} class="btn-close btn-close-dark float-end" aria-label="Close"></button>
                                <div style={{ 'minHeight': '340px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                    <audio controls>
                                        <source src={URL.createObjectURL(file[i])} type="audio/ogg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div><br />
                                <input id={newId} onChange={(e) => TitleHandler(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control`} /><br />
                                <input id={`B` + newId} onChange={(e) => TitleHandlerB(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                                <input id={`C` + newId} onChange={(e) => TitleHandlerC(e)} placeholder="" className={`${first === 'Product' ? 'Product' : ''} form-control `} /><br />
                                <textarea className={`${first === 'Product' ? 'Product' : ''} form-control`} placeholder="" onChange={(e) => DiscHandler(e)} id={`A` + newId}></textarea><br />
                            </div>
                        )
                }
                Counter++

            }
        }
        setfil(sab)

        setCount(Counter)
        arr = temp
        if (rerender) {
            setshowMedia(arr)
            setRerender(!rerender)
        } else {
            setshowMedia(temp)
            setRerender(!rerender)
        }
    }

    // function dorender(media) {
    //     console.log('works fine');
    //     setfirst(media)
    // }
    useEffect(() => {
        console.log('first');
        if (showMedia.length > 0) {
            // dorender(showMedia)
        }
    }, [showMedia]);

    const [ss, setss] = useState(0);
    useEffect(() => {
        console.log('works');
    }, [ss]);

    function validation() {

        if (props.type) {

        if( value2.catalouge_title.length===0){
            return setError("PLEASE ADD a Title");
        }else{
            setError("");
        }
    }else{
        if(value.catalouge_title.length===0 ){
            return setError("PLEASE ADD a Title");
        }else{
            setError("");
        }
    }

        if (fil.length > 0) {
            setError("");
        }
        else {
            return setError("PLEASE ADD a Picture or Video");
        }

        if (props.type) {
            if (value2.catalouge_title.length === 0) {
                setError("PLEASE ENTE a Title")
            }
            // if (value2.product_name.length > 0) {
            //     setError("");
            // }
            // else {
            //     return setError("PLEASE ENTER Product Name");
            // }
            // if (value2.brand.length > 0) {
            //     setError("");
            // }
            // else {
            //     return setError("PLEASE ENTER Product Brand");
            // }


            // for (const iterator of value2.Product_Discriptions) {
            //     console.log(iterator)
            //     if (iterator.color.length === 0) {
            //         return setError("PLEASE ENTER Color of Product")
            //     }
            //     if (iterator.mrp.length === 0) {
            //         return setError("PLEASE ENTER MRP of Product")
            //     }
            // }




        } else {


            if (value.project_name.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Project Name");
            }

            if (value.state.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Project Location (State)");
            }

            if (value.city.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Project Location (City)");
            }

            if (value.project_locality.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Project Location (Locality)");
            }



            if (value.number_of_units.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Total Number of Units");
            }

            if (value.number_of_floor.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Total Number of Floors");
            }
            for (const iterator of value.Project_Discriptions) {
                if (iterator.number_of_bedroom.length > 0) {
                    setError("");
                }

                else {
                    return setError("PLEASE ENTER Total Number of Bedrooms");
                }

                if (iterator.area.length > 0) {
                    setError("");
                }

                else {
                    return setError("PLEASE ENTER Unit Built-up Area");
                }


                if (iterator.carpet_area.length > 0) {
                    setError("");
                }

                else {
                    return setError("PLEASE ENTER Unit Carpet Area");
                }
            }



            if (value.project_highlights.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Project Highlights / USP");
            }

            if (value.project_status.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Project Status");
            }

            if (value.rera.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER RERA Compliant");
            }

            if (value.vasstu.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Vaastu Compliant");
            }

            if (value.occupancy_avalibility.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Occupancy Availability");
            }

            if (value.occupancy_certificate.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Occupancy Certificate");
            }

            if (value.association.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Association Formation");
            }

            if (value.rate.length > 0) {
                setError("");
            }

            else {
                return setError("PLEASE ENTER Rate per SQFT / SQCM on Built-up Area");
            }
        }

        return true;
    }
    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    const Tr = () => {
        let c = []
        console.log(fil)
        console.log(Titles)
        console.log(Disc)
        let titles = []
        let discs = []
        let titleB = []
        let titleC = []
        fil.forEach((element, index) => {
            if (element) {
                console.log(element)
                console.log(Titles[index])
                titles = [...titles, Titles[index]]
                discs = [...discs, Disc[index]]
                titleB = [...titleB, TitleB[index]]
                titleC = [...titleC, TitleC[index]]
            }
        });
        console.log(c)
        let final = {
            titles, discs, titleB, titleC
        }
        console.log(final)
        return final
        console.log(showMedia.length)
        let Tit = []
        let Disk = []

        for (let i = 0; i < Count; i++) {
            // console.log(i)
            Tit.push(document.getElementById('port' + i).value)
        }
        for (let i = 0; i < Count; i++) {
            // console.log(i)
            Disk.push(document.getElementById('Aport' + i).value)
        }
        let lit = {
            Tit, Disk
        }
        console.log(Tit)
        return lit
    }

    const dommerdup = () => {
        let c = document.getElementById(`closer9655sabarigtrisreehari${props.id}`)
        var status = false;
        setPsy(true)
        console.log(TitleC)
        console.log(TitleB)

        let TitlesToDb = Tr()
        console.log(TitlesToDb)
        status = validation();
      
        if (status == true) {
            c.setAttribute('disabled', true)
            formData.delete('data')
            formData.delete('file')

            fil.forEach(element => {
                if (element) {
                    formData.append("file", element);
                    console.log(element)
                }
            });
            let T
            console.log(props.type)
            if (props.type) {
                T = value2
            } else {
                T = value
            }
            console.log(value)
            console.log(value2)
            var sab = {
                T, TitlesToDb
            }
            console.log(sab)
            console.log(sab)
            var das = JSON.stringify(sab)
            formData.append("data", das);

            // console.log(content)
            // console.log(tittle)
            const options = {
                onUploadProgress: (progressEvent) => {
                    console.log(progressEvent)
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor(((loaded / 1000) * 100) / (total / 1000));
                    console.log(percent)
                    // move(percent)/
                    if (percent < 100) {
                        setProgress(percent);
                    }

                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            axios.post('/api/ActiveUser/NewLaunchCatalougeTempv', formData, options).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    console.log('Succesfully posed', res.data)
                    setProgress(100)
                    let ids = res.data
                    c.removeAttribute('disabled')
                    c.click()
                    props.setPortFolios([...props.PortFolios, ids])

                    // props.activate("Portfolio-tab")
                    notify("NEW Product Added ", true);
                }
                else {
                    c.removeAttribute('disabled')
                    c.click()
                    console.log('something went wrong')
                    notify("something went wrong ", false);
                }
            }).catch((err) => {
                c.removeAttribute('disabled')
                c.click()
                console.log(err)
            })
        }


    }




    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city("Cityrsab", index);
        setValue({ ...value, state: e.target.value });
    }


    const [rdbtn, setrdbtn] = useState(false)

    const tooglefn1 = () => {
        document.getElementById('cat2').classList.remove('d-none')
        document.getElementById('cat1').classList.add('d-none')
        setrdbtn(true)
        setfirst('Product')
    }

    const tooglefn2 = () => {
        document.getElementById('cat1').classList.remove('d-none')
        document.getElementById('cat2').classList.add('d-none')
        setrdbtn(true)
        setfirst('Project')
    }

    const [Stateload, setStateload] = useState(true);



    useEffect(() => {
        if (Stateload) print_state("Statersab");
        setStateload(false);
    }, []);


    const [psy, setPsy] = useState(false)
    useEffect(() => {
        if (psy) {
            validation();
        }
    }, [value, value2, fil]);
    useEffect(() => {
        if (props.type) {
            setfirst('Project')
        } else {
            setfirst('Product')
        }

        return () => {

        }
    }, [props])

    const [ProductDiscriptions, setProductDiscriptions] = useState([keyGen()])
    const [ProjectDiscription, setProjectDiscription] = useState([keyGen()])
    const HandlerProductDiscription = (e, index, boo) => {
        let pd = [...value2.Product_Discriptions]
        if (boo) {
            pd[index] = e
        } else {
            pd.splice(index, 1)
            let pds = [...ProductDiscriptions]
            pds.splice(index, 1)
            setProductDiscriptions(pds)
        }
        setValue2({ ...value2, Product_Discriptions: pd })
        console.log(e)
        console.log(index)
        console.log(boo)
    }
    const ProjectDiscriptionsHandler = (e, index, boo) => {
        let pd = [...value.Project_Discriptions]
        if (boo) {
            pd[index] = e
        } else {
            let pds = [...ProjectDiscription]
            pd.splice(index, 1)
            pds.splice(index, 1)
            setProductDiscriptions(pds)


        }
        setValue({ ...value, Project_Discriptions: pd })
        console.log(e)
        console.log(index)
        console.log(boo)
    }
    useEffect(() => {

        console.log(value2)
        return () => {

        }
    }, [value2])

    useEffect(() => {
        console.log(value)

        return () => {

        }
    }, [value])


    return <>
         <a data-bs-toggle="modal" data-bs-target={`#Post11sabsreehari${props.id}`}>
    <div className="linkPcontainer efkefefef py-2 ps-2 border" >
    Add NEW {props.type ? 'Product':'Project'}   <BsFillPlusCircleFill />
      </div>
    </a>

        <div className="modal fade podamonea" data-bs-backdrop="static" id={`Post11sabsreehari${props.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl  modal-dialog-scrollable ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">NEW {props.type ? 'Product':'Project'}</h5>
                        {/* <button type="button" onClick={(e) => check()}>check</button> */}
                        <button type="button" id={`closer9655sabarigtrisreehari${props.id}`} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {/*  */}
                    <div className="modal-body">

                        {/* <div className={`${rdbtn ? 'invisible' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" onChange={(e) => tooglefn1()} />
                                    <label className="form-check-label" htmlFor="c">Products</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onChange={(e) => tooglefn2()} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Projects (only for Real Estate vendors)</label>
                                </div>
                            </div>
                        </div> */}
                        <div className="mb-3">
                            <label className="ms-1 ">
                            NEW {props.type ? 'Product':'Project'} Title *
                            </label>
                            {props.type ?
                                <input className="eihfioehfoieh  w-100 form-control " onChange={(e) => setValue2({ ...value2, catalouge_title: e.target.value })} placeholder={''} />
                                :
                                <input className="eihfioehfoieh  w-100 form-control " onChange={(e) => setValue({ ...value, catalouge_title: e.target.value })} placeholder={''} />
                            }
                        </div>
                        <div >
                            <label className="ms-1" >
                                {props.type ? 'Product Photos / Videos *' : 'Project Photos / Videos *'}

                            </label>
                            <div id="carouselExampleIndicatorshowmanysreehari" className="carousel slide" data-bs-interval="false">

                                <div className="carousel-inner">
                                    {showMedia ? showMedia : ''}
                                </div>
                                {showMedia.length > 1 ?
                                    <>
                                        <button className="carousel-control-prev ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target="#carouselExampleIndicatorshowmanysreehari" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button id="next" className="carousel-control-next ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target="#carouselExampleIndicatorshowmanysreehari" data-bs-slide="next">
                                            <span className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </>
                                    : <></>}

                            </div>
                            <div className="ps-2">
                                <FaPhotoVideo size="25" onClick={() => Clickhandler('imagexrsreehari')} style={{ 'color': 'var(--blue)' }}></FaPhotoVideo>
                                &nbsp;&nbsp;<FaVideo style={{ 'color': 'var(--blue)' }} onClick={() => Clickhandler('videoxrsreehari')} size="25" />
                                {/* &nbsp;&nbsp;<AiFillAudio style={{ 'color': 'var(--blue)' }} onClick={() => Clickhandler('audioxr')} size="25" /> */}
                                <input id="imagexrsreehari" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'img')} multiple accept="image/*" />
                                <input id="videoxrsreehari" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'vid')} multiple accept="video/*" />
                                {/* <input id="audioxr" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'aud')} multiple accept="audio/*" /> */}
                            </div>
                        </div>
                        <hr />


                        {/* {Image ? <div className="container postMediacontainer"> <img className="luide d-flex" src={URL.createObjectURL(Image)} />  </div> : ''} */}
                        {/* {Video ? <div className="container postMediacontainer"><video className="luide d-flex" controls><source src={URL.createObjectURL(Video)} height="100%" width="100%" /></video> </div> : ''} */}
                        {props.type ?
                            <div id="cat2" className="d-one">



                                {/* first */}
                                <>
                                    <label className="ms-1">Product Name </label>
                                    <input className="mb-2 eihfioehfoieh  w-100 form-control " onChange={(e) => setValue2({ ...value2, product_name: e.target.value })} placeholder={''} />
                                    <label className="ms-1">Product Brand </label>
                                    <input className="mb-2 eihfioehfoieh  w-100 form-control " onChange={(e) => setValue2({ ...value2, brand: e.target.value })} placeholder={''} />
                                    <label className="ms-1">Product Description </label>
                                    <textarea className=" mb-2 postInputx eihfioehfoieh ps-2 form-control" onChange={(e) => setValue2({ ...value2, product_discription: e.target.value })} placeholder='Mention material specification, etc'></textarea>
                                    {/* /////////////////////////////////////// */}
                                    {ProductDiscriptions.map((elem, index) => {
                                        return <ProductDiscription  key={elem} index={index} Handler={HandlerProductDiscription} />
                                    })}
                                    {/* /////////////////////////////////////// */}
                                    <p className="mb-3">To Add more Variants, click on <button type="button" onClick={(e) => setProductDiscriptions([...ProductDiscriptions, keyGen()])} className="btn pro-spaces-button3 mt-3 mb-3 ps-3 pe-3 ms-2">ADD VARIANT</button></p>
                                    {/* To Add more Variants, click on ADD VARIANT */}
                                    <input id="image" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'img')} multiple accept="image/*" />
                                    <input id="video" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'vid')} multiple accept="video/*" />
                                    <input id="audio" type="file" className="d-none" name="Media" onChange={(e) => mediaHandler(e.target.files, 'aud')} multiple accept="audio/*" />
                                    {Image ? <div className="container postMediacontainer"> <img className="luide d-flex" src={URL.createObjectURL(Image)} />  </div> : ''}
                                    {Video ? <div className="container postMediacontainer"><video className="luide d-flex" controls><source src={URL.createObjectURL(Video)} height="100%" width="100%" /></video> </div> : ''}

                                </>
                            </div> :
                            <div id="cat1" className="d-nne">



                                <>

                                    <label className="ms-1">Project Name *</label>
                                    <input className="mb-2 eihfioehfoieh  w-100 form-control " value={value.project_name} placeholder={''} onChange={(e) => setValue({ ...value, project_name: e.target.value })} />

                                    <label className="ms-1">Project Location <span style={{ 'fontWeight': '400' }}>(State)</span> *</label>

                                    <select
                                        className="mb-2 form-select eihfioehfoieh"
                                        value={value.state}
                                        id={`Statersab`}
                                        name="City"
                                        onChange={(e) => Cityshow(e)}
                                    >

                                    </select>

                                    <label className="ms-1">Project Location <span style={{ 'fontWeight': '400' }}>(City)</span> *</label>

                                    <select
                                        className="mb-2 form-select eihfioehfoieh"
                                        name="City"
                                        value={value.city}
                                        id={`Cityrsab`}
                                        onChange={(e) =>
                                            setValue({ ...value, city: e.target.value })
                                        }
                                    >
                                        <option></option>
                                    </select>

                                    <label className="ms-1">Project Location <span style={{ 'fontWeight': '400' }}>(Locality)</span> * </label>
                                    <textarea className=" mb-2 postInputx eihfioehfoieh ps-2 form-control" value={value.project_locality} onChange={(e) => setValue({ ...value, project_locality: e.target.value })} ></textarea>




                                    <label className="ms-1" >Total Number of Units *</label>

                                    <div className="trysizeplace">

                                        <input type="number" className="mb-2 eihfioehfoieh   w-100 form-control " value={value.number_of_units} onChange={(e) => setValue({ ...value, number_of_units: e.target.value })} placeholder='Mention how many units are there in the entire project' />
                                    </div>

                                    <label className="ms-1" >Total Number of Floors * </label>

                                    <div className="trysizeplace">
                                        <input type="number" className="mb-2 eihfioehfoieh  w-100 form-control " value={value.number_of_floor} onChange={(e) => setValue({ ...value, number_of_floor: e.target.value })} placeholder='Mention how many floors are there' />
                                    </div>


                                    {/* ///////////////////////////////////// */}
                                    {ProjectDiscription.map((elem, index) => {
                                        return <ProjectDiscriptions Handler={ProjectDiscriptionsHandler} index={index} key={elem} />
                                    })}


                                    {/* //////////////////////////////// */}
                                    <p className="mb-3  mt-3">To Add different types of Units, click on <button type="button" onClick={(e) => setProjectDiscription([...ProjectDiscription, keyGen()])} className="btn pro-spaces-button3 ps-3 pe-3">ADD VARIANT</button></p>

                                    <label className="ms-1">Project Description</label>
                                    <input className="mb-2 eihfioehfoieh  w-100 form-control " value={value.project_discription} onChange={(e) => setValue({ ...value, project_discription: e.target.value })} />


                                    <label className="ms-1">Project Highlights / USP *</label>
                                    <div className="trysizeplace">
                                        <input className="mb-2 eihfioehfoieh  w-100 form-control " value={value.project_highlights} onChange={(e) => setValue({ ...value, project_highlights: e.target.value })} placeholder=' Mention amenities / facilities available in the project' />
                                    </div>


                                    <label className="ms-1">Project Status *</label>
                                    <select className="mb-2 form-select eihfioehfoieh" aria-label="Default select example" value={value.project_status} onChange={(e) => setValue({ ...value, project_status: e.target.value })}>
                                        <option value="" disabled selected></option>
                                        <option value="Yet to Commence">Yet to Commence </option>
                                        <option value="On-Going">On-Going </option>
                                        <option value="Completed">Completed </option>

                                    </select>

                                    <label className="ms-1">RERA Compliant *</label>
                                    <select className="mb-2 form-select eihfioehfoieh" aria-label="Default select example" value={value.rera} onChange={(e) => setValue({ ...value, rera: e.target.value })}>
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes </option>
                                        <option value="No">No  </option>

                                    </select>

                                    <label className="ms-1">Vaastu Compliant *</label>
                                    <select className="mb-2 form-select eihfioehfoieh" aria-label="Default select example" value={value.vasstu} onChange={(e) => setValue({ ...value, vasstu: e.target.value })}>
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes </option>
                                        <option value="No">No  </option>
                                        <option value="Partially">Partially </option>

                                    </select>


                                    <label className="ms-1" >Occupancy Availability *</label>
                                    <div className="trysizeplace">
                                        <input className="mb-2 eihfioehfoieh  w-100 form-control " value={value.occupancy_avalibility} onChange={(e) => setValue({ ...value, occupancy_avalibility: e.target.value })} placeholder='Mention whether it is Ready-to-move-in / how many days or months or years to complete' />
                                    </div>

                                    <label className="ms-1">Occupancy Certificate *</label>
                                    <select className="mb-2 form-select eihfioehfoieh" value={value.occupancy_certificate} aria-label="Default select example" onChange={(e) => setValue({ ...value, occupancy_certificate: e.target.value })}>
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes</option>
                                        <option value="Not yet">Not yet </option>

                                    </select>


                                    <label className="ms-1">Association Formation *</label>
                                    <select className="mb-2 form-select eihfioehfoieh" value={value.association} aria-label="Default select example" onChange={(e) => setValue({ ...value, association: e.target.value })}>
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes </option>
                                        <option value="Not yet">Not yet  </option>

                                    </select>

                                    <label className="ms-1">Rate per SQFT / SQCM on Built-up Area *</label>
                                    <div className="trysizeplace">
                                        <input type="number" className="mb-2 eihfioehfoieh  w-100 form-control " value={value.rate} onChange={(e) => setValue({ ...value, rate: e.target.value })} placeholder="Mention Price followed by ‘per SQFT’ or ‘per SQCM’" />
                                    </div>

                                    <label className="ms-1">Offers <span style={{ 'fontWeight': '400' }}>(if any)</span></label>
                                    <input className="mb-2 eihfioehfoieh  w-100 form-control " value={value.offers} onChange={(e) => setValue({ ...value, offers: e.target.value })} />



                                    {/* <input id="image" type="file" className="d-none" name="Media" onChange={(e) => setImage(e.target.files[0])} multiple accept="image/*" />
              <input id="video" type="file" className="d-none" name="Media" onChange={(e) => setVideo(e.target.files[0])} multiple accept="video/*" /> */}

                                </>
                            </div>
                        }




                        {/* <ProgressBar Progress={Progress} halt={halt} d_color='red' f_color='yellow' color='white' bgcolor='dark' /> */}
                        {Progress != 0 ?
                            <ProgressBar variant="SOME_NAME" now={Progress} label={`${Progress}%`} />
                            : <></>}
                    </div>
                    {/*  */}

                    <div className="modal-footer">
                        <div className="">

                            <h4 style={{ color: 'red' }}>{error ? error : ''}</h4>

                            <button type="button" className="btn pro-spaces-button me-2 ps-4 pe-4" onClick={(e) => props.closeup(`closer9655sabarigtrisreehari${props.id}`)}>discard</button>
                            <button type="button" className="btn pro-spaces-button3" onClick={(e) => dommerdup(e)} >SAVE</button>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <ToastContainer
            position="bottom-left"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        /></>
}