import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function QuickfixPassive() {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="container mayanfont" style={{ paddingTop: "80px" }}>
      {/* <center>
        <h5>For Service Provider and Product Retailer Profiles</h5>
      </center> */}
      <br />
      <br />

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                FORGOT SIGN IN / LOG IN CREDENTIALS ?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I have FORGOTTEN the SIGN IN / LOG IN CREDENTIALS (LOG
              IN ID and PASSWORD) ?
            </p>
            <p>
              <b>A.</b> Click on ‘forgot sign in / log in id’ on the Log In
              page.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                CHANGE SIGN IN / LOG IN ID&nbsp;?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I want to CHANGE my SIGN IN / LOG IN ID. Can I ?
            </p>
            <p>
              <b>A.</b> Yes. You can easily change your Log In Id from :
              <ul>
                <li>A Mobile Number to a New Mobile Number / Email Id</li>
                <li>An Email Id to a New Email Id / Mobile Number</li>
              </ul>
            </p>
            <p>Steps to Change :</p>
            <p>{`Go to your ACCOUNT SETTINGS  >  Click on ‘Subscription Details and Account Settings’  >  Click on ‘LOGIN CREDENTIALS’  >  Click on ‘CHANGE’ on the Login Id tab > Enter your existing password > Enter your New Mobile Number or New Email Id and Update it.`}</p>
            <p>
              Please note that an OTP will be sent to your New Mobile Number /
              Email Id for Validation.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel71"}
        onChange={handleChange("panel71")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>
                NEED DUPLICATE GST INVOICE&nbsp;?
              </b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> I deleted the soft copy of my Subscription GST Invoice
              by mistake. How do I get a copy of the same ?
            </p>
            <p>
              <b>A.</b>{" "}
              {`Go to your ACCOUNT SETTINGS >  Click on ‘Subscription Details and Account Settings’  >  Click on ‘MEMBERSHIP SUBSCRIPTION DETAILS’. You can see your Account Subscription Details with options to View and Download all your Invoice copies at any time.`}
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <p>
              <b style={{ color: "#55374a" }}>ACCOUNT DELETION&nbsp;?</b>
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <b>Q.</b> If I DELETE my ACCOUNT, within how many days must I Log
              In to Re-activate my account before permanent deletion ?
            </p>
            <p>
              <b>A.</b>Within 30 days of permanent Deletion, after which the
              account can Never be retrieved again.
            </p>
            {/* <p>
              When 'On A Break', only your business profile (the selected
              profile(s) if you have more than one) is hidden But you can
              Continue to Use All Features of the app.
            </p>
            <p>
              However, If you permanently Delete your account, then it can Never
              be retrieved again.
            </p> */}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <br />
      <br />
    </div>
  );
}
