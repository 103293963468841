import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { HiSpeakerphone } from "react-icons/hi";
import { useSearchParams } from 'react-router-dom'
import "./SearchResult.css";
import { Link } from "react-router-dom";
import { SearchToolbar } from "../../FreeComponents/SearchToolbar/SearchToolbar";
import { SearchComponent } from "../ConSearchResult/SearchComponentFree"
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Mask } from "../../Utility/mask";
import { ProfileCard } from "../ProfileCard/ProfileCard";

export function SearchResult(props) {
  const [open, setOpen] = useState(false);
  const [cat, setCat] = useState(false);
  const [output, setoutput] = useState('')
  const [SearchOn, setSearchOn] = useState(false)
  const [Keyer, setKeyer] = useState(1)
  const [data, setdata] = useState(false);
  const [load, setload] = useState(true)
  const [error, seterror] = useState('')
  // id=JSON.parse(id) 
  let [searchParams, setSearchParams] = useSearchParams();
  var id = Mask.decode(searchParams.get("Sub_id"))

  useEffect(() => {
    axios.post('/api/Free/consearchingbrand', {
      id
    }).then((res) => {
      setCat(res.data);
      const postSummaries = res.data.map((post) => ({ id: post._id, key: post.id, data: post }));
      setdata(postSummaries)
      setload(false)
    }).catch((err) => {
      console.log(err)
    })


  }, []);


  useEffect(() => {
    if (load) {
      setoutput(<><br /><div className="text-center "> <div className="spinner-border text-dark"></div></div></>)
    }
    else {
      if (data.length > 0) {
        setoutput(data.map((number) =>
          <ProfileCard key={number.id} ids={makeid()} data={number.data} />
          // <SearchComponent key={number.id} ids={makeid()} data={number.data}></SearchComponent>
        ))
        if (data.length > 0) {
          if (data.length === 1) {
            setDisplayNo(<center><h4>Displaying&nbsp;{data.length}&nbsp;profile</h4></center>)

          }
          else {
            setDisplayNo(<center><h4>Displaying&nbsp;{data.length}&nbsp;profiles</h4></center>)

          }
        }
      }
      else {
        setoutput(<center><h4 style={{ 'fontWeight': '400', 'color': '#5A5A5A' }}>No profiles created yet</h4></center>)
        setDisplayNo("")
      }

    }

  }, [error, load])




  const [DisplayNo, setDisplayNo] = useState()

  function makeid(length) {
    length = 5
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };



  const [ShoutMass, setShoutMass] = useState()
  const [Results, setResults] = useState()
  const shoutOut = async () => {

    var dataSend = {
      sub_id: id,
      massage: ShoutMass,
      cat_id: localStorage.getItem('Cat'),
      Results
    }
    // return
    var send = await axios.post('/api/User/shoutOut', { dataSend })
    if (send.status == 200) {
      // setViewTopicMember(data.data)
      // console.log(data.data)
      notify("SHOUT-OUT sent successfully", true);

    } else {
      notify("SHOUT-OUT sent failed", false);
    }

  }


  useEffect(() => {
    setResults()
  }, [Keyer])

  return (
    <div className=" content">
      <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
        {/* <div className="d-flex justify-content-end">
          <Link to={"/Result2?Sub_id=" + id} className="btn pro-spaces-button me-1 ps-3 pe-3" style={{ "textTransform": "none" }}>ACTIVE Profiles</Link>
        </div>
       
        <center><h5 className="parapara">PRO-ACTIVE Profiles</h5></center> */}
        <SearchToolbar key={Keyer} setResults={setResults} Keyer={Keyer} setKeyer={setKeyer} SearchOn={SearchOn} setSearchOn={setSearchOn} open={open} setOpen={setOpen} />
        <div className={SearchOn ? " d-none" : " "}>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <div className="row">
                    <div className="col-12 pb-3">
                      {/* <button
                  className="btn btnsabari px-3 py-1 me-2  btn-rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#shoutoutmodal1"
                >
                  {" "}
                  <HiSpeakerphone />
                  &nbsp;Shout-out
                </button> */}
                      <button
                        className="btn btnsabari px-3 py-1 me-2 me-2  btn-rounded"
                        data-bs-toggle="modal" data-bs-target="#exampleModalsabari"
                      >
                        {" "}
                        <HiSpeakerphone />
                        &nbsp;Shout-out
                      </button>

                      <button
                        data-bs-toggle="modal" data-bs-target="#exampleModalsabari"
                        className="btn btnsabari px-3 py-1   btn-rounded"
                      >
                        View Short-listed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col d-flex justify-content-end"><button  className="btn btn-rounded btn3 mb-2 me-3" data-bs-toggle="modal" data-bs-target="#shoutoutmodal1"> <HiSpeakerphone /> Shout-out</button><button  className="btn btn-rounded btn3 mb-2 me-2" >View Selected</button></div> */}
            {/* <!-- end col --> */}
          </div>
          {/* <!-- end row --> */}
          {/* <div className={"row"}> */}
          {DisplayNo}

          <div className="row car">{output}</div>





          {/* <!-- end col --> */}
          {/* </div> */}
        </div>
        {/* <!-- end row --> */}
      </div>

      {/* <!-- container --> */}

      <div
        className="modal fade"
        id="shoutoutmodal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Shout-Out
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <textarea
                className="form-control"
                placeholder="max 500 characters"
                maxLength="200"
                onChange={(e) => setShoutMass(e.target.value)}></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn pro-spaces-button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn pro-spaces-button3" onClick={(e) => shoutOut()} data-bs-dismiss="modal">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
