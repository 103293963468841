import React, { useEffect, useState } from 'react'
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { notify } from "../../Utility/notify";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#fff',
        '&:hover': {
            backgroundColor: alpha('#fff', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#5BC236',
    },

}));
export const LeaveSettings = () => {

    const [data, setdata] = useState([])
    const [AllStatus, setAllStatus] = useState(false)
    useEffect(() => {

        axios.get('/api/User/getUserQuick').then((res) => {
            console.log(res);
            setdata(res.data)
            let st = true
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].break_status != true) {
                    st = false
                    break;
                }
            }
            if (st) {
                setAllStatus(true)
            }
        }).catch((err) => {
            console.log(err);
        })
        return () => {
        }

    }, [])

    const updateAll = (from, to, type, cb) => {
        console.log('object');
        // return
        console.log(from, to, type, cb);
        axios.post('/api/User/setBREAKALL', { status: !AllStatus, from, to, type }).then((res) => {
            setAllStatus(!AllStatus)
            let temp = [...data]
            temp.forEach((elem) => {
                elem.break_status = !AllStatus
            })
            cb()

            setdata(temp)
        }).catch((err) => {
            console.log(err);
            notify("Something went wrong !", false)
        })
    }
    const updateThis = (id, status, index, from, to, type, cb) => {
        console.count()
        // return
        axios.post('/api/User/setBREAKSINGLE', { status: !status, account_id: id, from, to, type }).then((res) => {
            console.log(res);
            let temp = [...data]
            temp[index].break_status = !status
            setdata(temp)
            cb()
        }).catch((err) => {
            console.log(err);
            notify("Something went wrong !", false)
        })
    }
    return (
        <div>
            <div>
                <Accordion sx={{ bgcolor: "var(--blue)", color: "white", boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>DISPLAY ' ON LEAVE / CLOSED '</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {data.length > 1 && <AllLEAVESettings status={AllStatus} onChange={updateAll} />}
                            {data.map((elem, index) => {
                                return <SingleSettingLeave name={elem.pname} index={index} Change={updateThis} id={elem._id} status={elem.break_status} />
                            })}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export const AllLEAVESettings = ({ onChange, status }) => {
    const [show, setshow] = useState(false)
    const close = () => setshow(false)

    return (
        <div>
            Turn leave on all account  <GreenSwitch  {...label}
                checked={status}
                color="default"
                onChange={() => {
                    console.log('here  ', status);
                    if (status) {
                        onChange(null, null, null, () => { })
                    } else {
                        setshow(true)
                    }
                }}
            />
            <ModalConfirm
                status={show}
                call={close}
                onChange={(from, to, type, cb) => {
                    onChange(from, to, type, cb)
                }}
            />
        </div>
    )
}

export const SingleSettingLeave = ({ name, id, status, Change, index }) => {
    const [show, setshow] = useState(false)
    const close = () => setshow(false)

    return <div className='d-flex justify-content-between'>
        <span className=''>{name}</span>
        <GreenSwitch  {...label}
            checked={status}
            color="default"
            onChange={() => {
                console.log('here  ', status);

                if (status) {
                    Change(id, status, index, null, null, null, () => { })
                } else {
                    setshow(true)
                }
            }}
        />
        <ModalConfirm
            status={show}
            call={close}
            onChange={(from, to, type, cb) => {
                Change(id, status, index, from, to, type, cb)
            }}
        />
    </div>
}



function ModalConfirm({ status, onChange, call }) {
    useEffect(() => {

        setShow(status)
        return () => {

        }
    }, [status])

    const [show, setShow] = useState(false);

    const handleClose = () => {
        call()
    }
    const handleShow = () => setShow(true);
    const [from, setfrom] = useState(false)
    const [to, setto] = useState(false)
    const [type, settype] = useState('today')
    const [Error, setError] = useState('')
    const submit = () => {
        console.log(from, to);
        if (type == 'today') {
            let today = new Date()
            onChange(today, today, type, call)
        } else {
            if (!from) {
                return setError('Select a starting date')
            }
            if (!to) {
                return setError('Select a ending date')
            }
            onChange(from, to, type, call)
        }

    }
    const [lastmindate, setlastmindate] = useState(new Date().toISOString().substr(0, 10))

    useEffect(() => {
        setlastmindate(new Date(from).toISOString().substr(0, 10))
        return () => {
        }
    }, [from])

    return (
        <>
            {/* <button variant="primary" onClick={handleShow}>
                Launch demo modal
            </button> */}

            <Modal show={show} onHide={handleClose} backdrop centered>
                <Modal.Header closeButton>
                    <Modal.Title><h6>Leave on ?</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-6'>
                            <div class="form-check">
                                <input class="form-check-input" onChange={(e) => settype('today')} type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={type == 'today'} />
                                <label class="form-check-label" for="flexRadioDefault2">
                                    Today
                                </label>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div class="form-check">
                                <input class="form-check-input" onChange={(e) => settype('custom')} type="radio" name="flexRadioDefault" id="flexRadioDefault23" checked={type == 'custom'} />
                                <label class="form-check-label" for="flexRadioDefault23">
                                    Custom Days
                                </label>
                            </div>
                        </div>
                        {type == 'custom' && <>
                            <div className='col-6'>
                                <label>From</label>
                                <input className='form-control' type='date'
                                    onChange={(e) => {
                                        setfrom(e.target.value)
                                    }}
                                    min={new Date().toISOString().substr(0, 10)}
                                ></input>
                            </div>
                            <div className='col-6'>
                                <label>To</label>
                                <input className='form-control' type='date'
                                    onChange={(e) => {
                                        setto(e.target.value)
                                    }}
                                    min={lastmindate}
                                    disabled={from == false}
                                ></input>
                            </div>
                        </>}
                    </div>
                    <p className='text-danger'>{Error}</p>


                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='btn pro-spaces-button1' variant="secondary" onClick={handleClose}>
                        Close
                    </button> */}
                    <button variant="primary" className='btn pro-spaces-button3' onClick={submit}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
