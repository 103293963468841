import React from 'react'
import axios from 'axios'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import '../../Components/ConsCategories/Categories.css'

const Marketing = () => {
    const [Data, setData] = React.useState({
        AgreementPolicy1: false,
        AgreementPolicy2: false,
        AgreementPolicy3: false,
        AgreementPolicy4: false,
        AgreementPolicy5: false,
        AgreementPolicy5: false,
        AgreementPolicy6: false,
        Name: '',
        Place: '',
        Email: '',
        Phone: '',
        AccountDetails: {
            FullName: '',
            BankName: '',
            Type: '',
            AccountNumber: '',
            Ifsc: ''
        },
        Coupon: ''
    })

    const Validator = async () => {

        return new Promise(async function (resolve, reject) {
            if (!Data.AgreementPolicy1 || !Data.AgreementPolicy2 || !Data.AgreementPolicy3 || !Data.AgreementPolicy4 || !Data.AgreementPolicy5 || !Data.AgreementPolicy6) {
                setError('You should agree with all the policy to continue !')
                resolve(false)
                return
            }
            if (!Data.Name) {
                setError('Enter Name !')
                resolve(false)
                return
            }
            if (!Data.Place) {
                setError('Enter Place !')
                resolve(false)
                return
            }
            if (!Data.Email) {
                setError('Enter Email !')
                // if(Data.Email){
                //     setError('Enter a valid email address !')
                // }
                resolve(false)
                return
            }
            if (!Data.Phone) {
                setError('Enter Phone Numer !')
                if (Data.Phone.length != 10) {
                    setError('Enter a valid mobile number !')
                    resolve(false)
                    return
                }
            }
            if (!Data.AccountDetails.FullName) {
                setError('Enter Account holder Name !')
                resolve(false)
                return
            }
            if (!Data.AccountDetails.BankName) {
                setError('Enter Bank name !')
                resolve(false)
                return
            }
            if (!Data.AccountDetails.Type) {
                setError('Enter Account Type !')
                resolve(false)
                return
            }
            if (!Data.AccountDetails.AccountNumber) {
                setError('Enter Account Number !')
                resolve(false)
                return
            }
            if (!Data.AccountDetails.Ifsc) {
                setError('Enter IFSC code !')
                resolve(false)
                return
            }
            if (!Data.Coupon) {
                setError('Enter Coupon !')
                resolve(false)
                return
            }
            let coupon = Data.Coupon
            let rf = await axios.post('/api/User/couponCheck', { coupon })
            if (rf) {
                if (!rf.data) {
                    setError('This coupon already exist')
                    resolve(false)
                    return
                }
            }
            setError('')
            resolve(true)
            return
        })
    }
    const [Error, setError] = React.useState()
    React.useEffect(() => {
        console.log(Data)
    }, [Data])

    const JustDoIt = async () => {
        let validate = await Validator()
        console.log(validate)
        if (!validate) return

        console.log('here')
        axios.post('/api/User/marketingagreement', { Data }).then((res) => {
            if (res) {

            }
        }).catch((re) => {
            console.log(re)
        })
    }
    const prefix = 'PRO-'
    return (
        <div className='container  wieugfiqgefnqenc2rnewiuhgiuhq '>
            <div className='row px-1'>
                <div style={{ 'borderRadius': '10px' }} className='container-sm col-12 col-sm-8  bg-light p-3 mb-3'>
                    <div className='mb-3'>
                        <h2 style={{ 'fontSize': '20px' }} className='text-center'>MARKETING AGREEMENT FORM</h2>
                    </div>
                    <div className='col-12'>
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setData({ ...Data, AgreementPolicy1: !Data.AgreementPolicy1 })} type="checkbox" value="" id="flexCheckChecked1" />
                            <label className="form-check-label" for="flexCheckChecked1">
                                I am ready to enrol myself as a marketing executive on PRO-SPACES to get interested people to subscribe on this platform.
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setData({ ...Data, AgreementPolicy2: !Data.AgreementPolicy2 })} type="checkbox" value="" id="flexCheckChecked2" />
                            <label className="form-check-label" for="flexCheckChecked2">
                                I am aware and agree that I'll be paid only 50% of the amount of each subscription (irrespective of the subscription fees) that this platform gets through my reference via the unique coupon code that I have been given to use.
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setData({ ...Data, AgreementPolicy3: !Data.AgreementPolicy3 })} type="checkbox" value="" id="flexCheckChecked3" />
                            <label className="form-check-label" for="flexCheckChecked3">
                                I am aware and agree that I am eligible for an incentive only for those subscriptions that are created using my unique coupon codes.
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setData({ ...Data, AgreementPolicy4: !Data.AgreementPolicy4 })} type="checkbox" value="" id="flexCheckChecked4" />
                            <label className="form-check-label" for="flexCheckChecked4">
                                I am aware and agree that this is only a temporary job for me and it is purely at the discretion of the company to relieve me from this job without any notice period.
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setData({ ...Data, AgreementPolicy5: !Data.AgreementPolicy5 })} type="checkbox" value="" id="flexCheckChecked5" />
                            <label className="form-check-label" for="flexCheckChecked5">
                                I am aware and agree that my remuneration is limited to only the number of subscriptions that I get for the platform and no additional amount will be paid to me as a fixed salary/stipend.
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setData({ ...Data, AgreementPolicy6: !Data.AgreementPolicy6 })} type="checkbox" value="" id="flexCheckChecked6" />
                            <label className="form-check-label" for="flexCheckChecked6">
                                I am aware and agree that I am not liable to get any reimbursements on the conveyance or call expenses that I incur as a part of my efforts to get subscriptions for this platform.
                            </label>
                        </div>
                    </div>
                    <div className='col-12 col-sm'>
                        <div className='container-fluid'>

                        </div>
                        <div className="mb-3">
                            <label for="formFile" className="form-label">Name</label>
                            <input onChange={(e) => setData({ ...Data, Name: e.target.value })} className="form-control" type="text" />
                        </div>
                        <div className="mb-3">
                            <label for="formFile" className="form-label">Place</label>
                            <input onChange={(e) => setData({ ...Data, Place: e.target.value })} className="form-control" type="text" />
                        </div>
                        <div className="mb-3">
                            <label for="formFile" className="form-label">Email id</label>
                            <input onChange={(e) => setData({ ...Data, Email: e.target.value })} className="form-control" type="text" />
                        </div>
                        <div className="mb-3">
                            <label for="formFile" className="form-label">Phone number</label>
                            <input onChange={(e) => setData({ ...Data, Phone: e.target.value })} className="form-control" type="text" />
                        </div>
                        <div className="mb-0">
                            <label for="formFile" className="form-label">Account details</label>
                        </div>

                        <div className="mb-3">
                            <input className="form-control" onChange={(e) => setData({ ...Data, AccountDetails: { ...Data.AccountDetails, FullName: e.target.value } })} type="text" placeholder='full name' />
                        </div>
                        <div className="mb-3">
                            <input className="form-control" onChange={(e) => setData({ ...Data, AccountDetails: { ...Data.AccountDetails, BankName: e.target.value } })} type="text" placeholder='bank name' />
                        </div>
                        <div className="mb-3">
                            <input className="form-control" onChange={(e) => setData({ ...Data, AccountDetails: { ...Data.AccountDetails, Type: e.target.value } })} type="text" placeholder='type of account' />
                        </div>
                        <div className="mb-3">
                            <input className="form-control" onChange={(e) => setData({ ...Data, AccountDetails: { ...Data.AccountDetails, AccountNumber: e.target.value } })} type="number" placeholder='account number' />
                        </div>
                        <div className="mb-3">
                            <input className="form-control" onChange={(e) => setData({ ...Data, AccountDetails: { ...Data.AccountDetails, Ifsc: e.target.value } })} type="text" placeholder='ifsc code' />
                        </div>

                    </div>

                    <div className='col-12'>
                        <div className='row'>

                            <label for="formFile" className="form-label">Coupon codes </label>

                            <div className='col-12 col-sm-6'>
                                <div className="mb-1">
                                    <div className="input-group border">
                                        <span className="input-group-text bg-light border-0 ps-4">PRO-</span>
                                        <input id='splinpcopu' value={Data.Coupon} onChange={(e) => setData({ ...Data, Coupon: e.target.value.replace(/ +/g, "").toUpperCase() })} type="text"
                                            placeholder='(enter code)' aria-label="First name" className="form-control splinpcopu bg-light border-0" />
                                        <span className="input-group-text text-center bg-light border-0 ps-2">25</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className="mb-1">
                                    <div className="input-group border">
                                        <span className="input-group-text bg-light border-0 ps-4">PRO-</span>
                                        <input id='splinpcopu' value={Data.Coupon} onChange={(e) => setData({ ...Data, Coupon: e.target.value.replace(/ +/g, "").toUpperCase() })} type="text"
                                            placeholder='(enter code)' aria-label="First name" className="form-control splinpcopu bg-light border-0" />
                                        <span className="input-group-text text-center bg-light border-0 ps-2">50</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className="mb-1">
                                    <div className="input-group border">
                                        <span className="input-group-text bg-light border-0 ps-4">PRO-</span>
                                        <input id='splinpcopu' value={Data.Coupon} onChange={(e) => setData({ ...Data, Coupon: e.target.value.replace(/ +/g, "").toUpperCase() })} type="text"
                                            placeholder='(enter code)' aria-label="First name" className="form-control splinpcopu bg-light border-0" />
                                        <span className="input-group-text text-center bg-light border-0 ps-2">75</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='col-12'>
                        <p className='text-danger'>{Error ? Error : ''}</p>
                        <input type={'button'} value="Submit" onClick={(e) => JustDoIt()} className='btn w-100 pro-spaces-button3' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marketing