import React, { useEffect, useState } from 'react'
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { notify } from "../../Utility/notify";

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#fff',
        '&:hover': {
            backgroundColor: alpha('#fff', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#5BC236',
    },

}));
export const ChatSetting = () => {

    const [data, setdata] = useState([])
    const [AllStatus, setAllStatus] = useState(false)
    useEffect(() => {

        axios.get('/api/User/getUserQuick').then((res) => {
            console.log(res);
            setdata(res.data)
            let st = true
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].Chat == false) {
                    st = false
                    break;
                }
            }
            if (st) {
                setAllStatus(true)
            }
        }).catch((err) => {
            console.log(err);
        })
        return () => {
        }

    }, [])

    const updateAll = () => {
        console.log('object');
        // return
        axios.post('/api/User/setALLCHAT', { status: !AllStatus }).then((res) => {
            setAllStatus(!AllStatus)
            let temp = [...data]
            temp.forEach((elem) => {
                elem.Chat = !AllStatus
            })
            setdata(temp)
        }).catch((err) => {
            notify("Something went wrong !", false)
        })
    }
    const updateThis = (id, status, index) => {
        console.count()
        // return
        axios.post('/api/User/setSINGLECHAT', { status: !status, account_id: id }).then((res) => {
            console.log(res);
            let temp = [...data]
            temp[index].Chat = !status
            setdata(temp)
        }).catch((err) => {
            notify("Something went wrong !", false)
        })
    }
    return (
        <div>
            <div>
                <Accordion sx={{ bgcolor: "var(--blue)", color: "white", boxShadow: 'none', border: 'none' }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>One-to-One CHATTING</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <Typography>

                            {data.length > 1 && <AllChatSettings status={AllStatus} onChange={updateAll} />}
                            {data.map((elem, index) => {
                                return <SingleSettingChat name={elem.pname} index={index} Change={updateThis} id={elem._id} status={elem.Chat} />
                            })}
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </div>
        </div>
    )
}

export const AllChatSettings = ({ onChange, status }) => {

    return (
        <div>
            Turn on one-to-one on all account  <GreenSwitch  {...label}
                checked={status}
                color="default"
                onChange={onChange}
            />
        </div>
    )
}

export const SingleSettingChat = ({ name, id, status, Change, index }) => {

    return <div className='d-flex justify-content-between'>
        <span className=''>{name}</span>
        <GreenSwitch  {...label}
            checked={status}
            color="default"
            onChange={() => Change(id, status, index)}
        />
    </div>
}