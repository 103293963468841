import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { FaShareAlt } from "react-icons/fa";
import "./Pview.css";
import axios from "axios";

import { Link } from "react-router-dom";
import "./Edit";
import { MdMyLocation } from "react-icons/md";

import Portfolio from "./portfolio";
import Offerportfolio from "./Offerportfolio";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Timelines } from "../../../Components/Timeline/Timeline";

import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import NewLaunchportfolio from "./NewLaunchportfolio";
import { Mask } from "../../../Utility/mask";
import AddOn from "./addAnother";
import { BussinessInformationVendor } from "../../../Components/ProfileComponents/BussinessInformationVendor";
import { PersonalInformationVendor } from "../../../Components/ProfileComponents/PersonalInformationVendor";
import { ContactInformationVendor } from "../../../Components/ProfileComponents/ContactInformationVendor";
import { Selfdeclaration } from "../../../Components/Selfdeclaration/Selfdeclaration";
import { ShareThis } from "../../ShareThis/ShareThis";
import { bucketName } from "../../../config";

function Pview2(props) {
  useEffect(() => {
    props.Auth();
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();

  let success = searchParams.get("success");
  const [isLogo, setIsLogo] = useState(false);
  const [logo, setLogo] = useState(false);
  const [DP, setDp] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    props.Auth();

    if (success) {
      notify("Profile Updated", true);
      console.log("updated");
    }
  }, []);

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [shareshow, setshareshow] = useState(false);

  const copytoclip = () => {
    navigator.clipboard.writeText(
      `www.prochure.in/find_profile?profile=products_profile&&Ac_id=${Mask.encode(
        getval._id
      )}`
    );
    notify("URL copied to your clipboard", true);
  };

  useEffect(() => {
    axios
      .post("/api/ActiveUser/ProProfile", {})
      .then((res) => {
        setGetval(res.data[0]);
        const uniqueValue = new Date().getTime();
        setLogo(
          bucketName +
            `/${res.data[0]?._id}/company_logo.png?timestamp=${uniqueValue}`
        );
        setDp(
          bucketName +
            `/${res?.data[0]?._id}/avatar.png?timestamp=${uniqueValue}`
        );
        console.log(res.data[0]);

        // console.log(res.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const [type, settype] = useState();
  useEffect(() => {
    if (getval.formType === 1 || getval.formType === 2) {
      settype(true);
    } else if (getval.formType === 3) {
      settype(false);
    }
  }, [getval]);

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    console.log(forume);
  }, [forume]);

  const [first8, setfirst8] = useState(false);

  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(
      `See the Profile of ${
        getval.pname
      }\nwww.prochure.in/find_profile?profile=products_profile&&Ac_id=${Mask.encode(
        getval._id
      )}`
    );
  }, [getval]);

  return (
    <>
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border ">
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-3">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-center">
                <div style={{ position: "relative" }}>
                  <img id="DP" className="new-vp-image" src={DP} alt="" />
                  {logo && !isLogo && (
                    <img
                      className="new-card-logo"
                      src={logo}
                      onError={() => setIsLogo(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5 className="mb-1">
                    {getval.pname ? getval.pname.toUpperCase() : ""}
                  </h5>
                  <p className="mt-0 pt-0 mb-2">
                    {getval?.brandtagline ? getval.brandtagline : ""}
                  </p>
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5 className="mb-1">
                      {getval.pname ? getval.pname.toUpperCase() : ""}
                    </h5>
                    <p className="mt-0 pt-0 mb-2">
                      {getval?.brandtagline ? getval.brandtagline : ""}
                    </p>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                        {getval.string ? getval.string[0].category : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                          {getval.string ? getval.string[0].category : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="d-none d-md-block">
                  <Link to="/edit_profile_products">
                    <input
                      type="submit"
                      className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      name="btnAddMore"
                      value="Edit Profile"
                    />
                  </Link>
                  <button
                    className="btn pro-spaces-button ms-1 ps-2 pe-2 me-1"
                    style={{ borderRadius: "25px" }}
                    // onClick={copytoclip}
                    onClick={(e) => setshareshow(true)}
                  >
                    <FaShareAlt />
                  </button>
                  <br />
                  <button
                    className="btn pro-spaces-button mt-2 ms-1 ps-2 pe-2 me-2"
                    style={{ borderRadius: "25px" }}
                    data-bs-target="#modalofaddanother2"
                    data-bs-toggle="modal"
                  >
                    ADD THIS PROFILE TO ANOTHER SUB CATEGORY
                  </button>
                </div>
                <div className="d-block d-md-none pt-2">
                  <center>
                    <Link to="/edit_profile_products">
                      <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-2 pe-2 me-2"
                        style={{ borderRadius: "25px" }}
                        name="btnAddMore"
                        value="Edit Profile"
                      />
                    </Link>
                    <button
                      className="btn pro-spaces-button ms-3 ps-2 pe-2 me-2"
                      style={{ borderRadius: "25px" }}
                      // onClick={copytoclip}
                      onClick={(e) => setshareshow(true)}
                    >
                      <FaShareAlt />
                    </button>
                    <br />
                    <center>
                      <button
                        className="btn pro-spaces-button mt-2 ms-1 ps-2 pe-2 me-2"
                        style={{ borderRadius: "25px", fontSize: ".8rem" }}
                        data-bs-target="#modalofaddanother2"
                        data-bs-toggle="modal"
                      >
                        ADD THIS PROFILE TO ANOTHER SUB CATEGORY
                      </button>
                    </center>
                  </center>
                </div>

                <div className="row">
                  <div className="col"></div>
                </div>
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>
                        {getval
                          ? type
                            ? "Catalogue of Products"
                            : "Catalogue of Projects"
                          : "Catalogue"}
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Portfolio type={type} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel1092"}
                  onChange={handleChange("panel1092")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>
                        NEWLY LAUNCHED{" "}
                        {getval ? (type ? "Product" : "Project") : ""}
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <NewLaunchportfolio type={type} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel109"}
                  onChange={handleChange("panel109")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>
                        Exclusive <span className="">OFFERS / DISCOUNTS</span>
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Offerportfolio type={type} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <PersonalInformationVendor getval={getval} />
                <BussinessInformationVendor getval={getval} />
                <ContactInformationVendor getval={getval} />

                <Selfdeclaration type="vdp" />
              </div>
            </center>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8"></div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalofaddanother2"
        tabindex="-1"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="buttoncloseforaddantherv2"
              ></button>
            </div>
            <div className="modal-body">
              <AddOn getval={getval} />
            </div>
          </div>
        </div>
      </div>
      <ShareThis shareshow={shareshow} setshareshow={setshareshow} url={url} />

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Pview2;
