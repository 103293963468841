import { Link } from "react-router-dom"
import ActionAreaCard from "./movieTiles"
import axios from "axios"
import { useEffect, useRef, useState } from "react"

export const TileMovies = () => {
    const [language, setLanguage] = useState([])
    useEffect(() => {

        axios.get('/api/api/GetMoviesLanguage').then((res) => {
            console.log(res)
            setLanguage(res.data)
        }).catch((error) => {
            console.log(error)
        })

    }, [])


    let stylecontainer = {
        position: 'relative',
        float: 'left',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

    }
    return <div className="row  pt-0 g-1 p-2" style={{'marginTop':'80px'}}>
        {localStorage.getItem("Type")==="sponsored" ?
          <div> <div className="d-flex justify-content-end">
          <Link to="/movies_and_innovation">
              <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh" >Add New</button>
              </Link>
                 </div>
                 </div>
     :""} 
      
      

        {
            language.map((m) => {
                return <div className="col-12 col-md-6  col-lg-6 col-xl-4 col-xxl-4  pro-sabari">
                    <div className="gtyurie p-3 ">
                        <Link to={`/movie_show?language=${m._id}`
                        } className="linkoflink">
                            <div className="d-flex align-items-center">
                                <div className=" w-100">
                                    <h4 className="m-0 mt-2" style={stylecontainer}>{m.name}</h4>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

            })

        }




    </div >

}