import React, { useEffect, useState, useRef, useContext } from "react";
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import axios from "axios";
import { AiFillFolderAdd } from "react-icons/ai";
import { FolderContext } from "../folder";
export const NewFolder = () => {
    const value = useContext(FolderContext);
    console.log(value)
    const newFolderName = useRef()
   

    useEffect(() => {
        console.log(value)
        value.nameing(newFolderName)
        let elem = newFolderName.current
        elem.setAttribute('contenteditable', true)
        elem.focus()
        return () => {

        }
    }, [])


    return <div className='Folder'>
        <input className={"invisible"} type={'checkbox'}></input>
        <MdOutlineKeyboardArrowDown className="float-start pTF invisible" id="FolderDrop" data-bs-toggle="dropdown" aria-expanded="false"></MdOutlineKeyboardArrowDown>
        <div>
            <div className="d-flex justify-content-center m-0">
                <AiFillFolderAdd color="#fdb900"  size={45} />
            </div>
            <p id='nefolderName' ref={newFolderName} className="folderName ">New Folder</p>
        </div>
    </div>
}