import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { JobSearch } from '../JobSearch1/JobSearch'
import './JobReqPost2.css'
import { JobSeekersList } from '../../Components/JobSeekerList/jobSeekerList'
import { Link } from "react-router-dom";
import React, { useEffect,useState } from 'react'
import { Joblist } from "../../Components/JobVacancyList/jobList"

export const JobReqPost2 = (props) => {

    const [reset, setreset] = useState(0)


    useEffect(() => {
        props.Auth()
    }, [])

    // var id="622c50c3ec54e22bae38f6fa"

    return (
        <div className='container-fluid dslihgluho'>
            <p className='float-end'>
                <div className='row'>
                    <div className='col-12 col-sm-6 mt-1 '>  
                    <a className="btn btnpost  w-100" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        CREATE&nbsp;A&nbsp;NEW&nbsp;JOB&nbsp;PROFILE
                    </a></div>
                    <div className='col-12 col-sm-6 mt-1'><Link to="/job_my_post" className=" btn btnpost w-100" data-toggle="collapse">
                        VIEW&nbsp;EXISTING&nbsp;JOB&nbsp;PROFILE
                    </Link></div>

                </div>



                {/* <a className="ms-1 btn btnpost" data-toggle="collapse" href="JobMyPost">My Post</a> */}
            </p>

            <br />
            <div className="collapse mt-5" id="collapseExample">
                <div className=" card-body ">
                    <h4 className='mb-3'>Apply as ...</h4>
                    <div className='row g-1'>
                        <div className='col'>
                            <Link to="/intern_job_post"><button className='btn btnpost w-100'>  Intern </button></Link>
                        </div>
                        <div className='col'>
                            <Link to="/fresher_job_post"><button className='btn btnpost w-100 '>  Fresher </button></Link>
                        </div>
                        <div className='col'>
                            <Link to="/experienced_job_post"><button className='btn btnpost w-100 '>  Experienced </button></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <h3 className='mb-3'>Search a job vacancy</h3> */}
            {/* <button className='btn btnpost me-1 ' data-bs-toggle="collapse" href="#jo" role="button" aria-expanded="false" aria-controls="jo">Search</button> */}
            {/* <button className='btn btnpost me-1 '>  Search by qualification </button> */}

            <div className="collpse" id="jo">

                <JobSearch key={reset} setreset={setreset} reset={reset} />
            </div>

            <div>
                {/* <Joblist /> */}
            </div>

        </div>
    )

}
