import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Link } from "react-router-dom";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi"
import Axios from 'axios'


export function ChatSearch(props) {

    const [searchText, setsearchText] = useState()
    const [blocklist, setblocklist] = useState()
    function keyGen() {
        var length = 5;
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    // console.log(props.data)

    const SearchHandler = (e) => {
        if (e) {
            setsearchText(e.target.value)
            props.Search(e.target.value)
        } else {
            props.Search(searchText)
        }
    }

    async function uNblock(roomId, blockId) {
        // // console.log(first)

        var sendData = {
            roomId: roomId,
            blockId: blockId
        }
        Axios.post('/api/User/uNblock', { sendData })
            .then(res => {
                if (res.status === 200) {
                    // console.log('200')
                    BlockedList()
                    props.chatuserListLoad()
                    // // console.log('chat Unblocked')fix
                    //  // console.log(res.data)
                    //  setblocklist(res.data)
                    // notify("The Account is Unblocked !!");

                }
            }).catch((err) => {
                // console.log(err.response)
                // fix
                // notify("Sorry !!");


            })
    }

    async function BlockedList() {

        // console.log('blovk list')
        Axios.get('/api/User/BlockedList').then((res) => {
            // console.log('first')
            if (res.status === 200) {
                // console.log('chat blocked')
                // console.log(res.data)
                setblocklist(res.data)
                //  notify("The Account is Blocked !!");
            }else{
                setblocklist([])
            }
        }).catch((err) => {
            // console.log('end')
            // console.log(err.response)
            // notify("Sorry !!");
        })

        // console.log('fke')
    }

    return (
        <>
            <div className="col-12 d-flex  dkhs align-items-center">
                <div className="input-group   flex-nowrap">
                    <input type="text" className="form-control" placeholder="Search for Cubicles" onChange={(e) => SearchHandler(e)} aria-label="Username" aria-describedby="addon-wrapping" />
                    <span onClick={(e) => props.Search(searchText)} className="input-group-text" id="addon-wrapping"><AiOutlineSearch /></span>
                </div>

                <div className="dropdown dropstart">
                    <BiDotsVerticalRounded id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" size={40} color={'white'} />
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {localStorage.getItem('Type')==='Consultant' || localStorage.getItem('Type')==='Product' || localStorage.getItem('Type')==='Consultantv2' || localStorage.getItem('Type')==='Productv2'  ?
                    
                        <li><a className="dropdown-item" data-backdrop="false" data-bs-toggle="modal" data-bs-target="#createroom" href="#">Create Conference room</a></li>
                :<></>}
                        <li><Link className="dropdown-item" to="/LockedTopic">View Closed Cubicles</Link></li>
                        <li><Link className="dropdown-item" to="/ShoutOut_view">Enquiries</Link></li>


                        <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#BlockedList" href="#" onClick={(e) => BlockedList()}>Blocked members</a></li>
                        <li><Link to="/cubicle_features" className="dropdown-item"  >CUBICLE FEATURES</Link></li>
                    </ul>
                </div>

                {/* modals */}

         




                <div className="modal fade" id="BlockedList" data-bs-backdrop="false"
                    tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <span className="viewmemberHeading">Blocked members</span>
                                <button type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {blocklist ?
                                    blocklist.length > 0 ?
                                        (
                                            blocklist.map((elem) => {

                                                return (
                                                    <div key={keyGen()}
                                                        id="a3"
                                                        className="p-3 d-flex align-items-center border-bottom  "
                                                    >
                                                        <div className="dropdown-list-image me-3">
                                                            <img
                                                                className="imageofc"
                                                                src={`https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/profile/${elem.userid}/avatar.png`}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src =
                                                                        "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="font-weight-bold me-3">
                                                            <div className="text-truncate">
                                                                <b className="inviteName">{elem.name}</b>
                                                            </div>
                                                        </div>
                                                        <span className="ms-auto mb-auto">
                                                            <div className="">

                                                                <button
                                                                    type="button"
                                                                    className="btn px-2 pro-spaces-button2 "
                                                                    onClick={(e) => uNblock(elem.roomid, elem.blockId)}
                                                                >
                                                                    Unblock
                                                                </button>

                                                            </div>
                                                        </span>
                                                    </div>
                                                );

                                            })
                                        )
                                        : ""
                                    : (
                                        <></>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

