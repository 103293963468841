import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
// import { TimeLineExternal } from "../../../Components/Timeline/TimeLineExternal"
import { useNavigate, useSearchParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetportExternal1 } from "./GetportExternal1";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosSend } from "react-icons/io";
import { GetOfferExternal } from "./GetOfferExternal";
import { MdMyLocation } from "react-icons/md";
import { Mask } from "../../../Utility/mask";

import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import { GetNewLaunchExternal } from "./GetNewLaunchExternal";
import { BussinessInformationVendor } from "../../../Components/ProfileComponents/BussinessInformationVendor";
import { ShortlistFreee } from "../../../Components/ProfileComponents/ShortlistFreee";
import { PersonalInformationVendor } from "../../../Components/ProfileComponents/PersonalInformationVendor";
import { ContactInformationVendor } from "../../../Components/ProfileComponents/ContactInformationVendor";
import { NonMemberBussinessInformation } from "../../../Components/ProfileComponents/NonMemberBussinessInformation";
import { NonMemberContactInformation } from "../../../Components/ProfileComponents/NonMemberContactInformation";
import { Navoutside } from "../../../Components/Navoutside/Navoutside";
import { NewNavigationBarDissabled } from "../../../Pages/Free/NewNavigationBar/NewNavigationBarDissabled";
import { PersonalInformationStatic } from "../../../Components/ProfileComponents/PersonalInformationStatic";
import { Selfdeclaration } from "../../../Components/Selfdeclaration/Selfdeclaration";

function Pview(props) {
  useEffect(() => {
    // props.Auth()
  }, []);

  const [expanded, setExpanded] = useState(false);
  const [loading, setloading] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const [Follower, setFollower] = useState(false);
  let Navigate = useNavigate();
  var Ac = Mask.decode(searchParams.get("Ac_id"));
  var idd = Mask.decode(searchParams.get("Ac_id"));
  var Acc = Mask.decode(searchParams.get("Ac_id"));

  const astyle = {
    color: "#0a58ca",
    textDecoration: "underline",
  };

  const enablechat = () => {
    document
      .getElementById("closebuttonforreportkgwfiuwsgfbvsdjbfuisea")
      .click();
    document.getElementById("quicksettingmodalatag").click();
  };

  const [EmailRef, setEmailRef] = useState({
    email: [],
    subject: "",
    massage: "",

    id: Acc,
  });
  const makeEmail = (x, Lorum) => {
    console.log(Lorum);
    console.log(x);
    x = document.getElementById(x);
    x.style.color = "black";
    var val = x.value;
    if (val.charAt(val.length - 1) === " ") {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
    if (Lorum) {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
  };

  const notify = (message, stat) => {
    // console.log(message);
    // console.log(stat)

    if (stat == true) {
      // console.log("toasfted");
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == false) {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Email, setEmail] = useState([]);
  const [Arr, setArr] = useState([]);
  var listItems = "";
  const [AllEmail, setAllEmail] = useState([]);

  const addEmail = (vall) => {
    vall = vall.trim();
    var dep = AllEmail;
    dep.push(vall);
    setAllEmail(dep);
    var x = React.createElement(
      "div",
      {
        className:
          "p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1",
        role: "alert",
      },
      [
        React.createElement(
          "strong",
          {},
          vall,
          <>&nbsp;&nbsp;&nbsp;</>,
          React.createElement(
            "button",
            {
              onClick: (e) => removeEmail(vall),
              type: "button",
              className: "btn-close ms-5 Gbt p-0 pt-2 ",
              "data-bs-dismiss": "alert",
              "aria-label": "Close",
            },
            ""
          )
        ),
      ]
    );
    Arr.push(x);
    listItems = Arr.map((myList) => {
      return <>{myList}</>;
    });
    setEmail(listItems);
  };

  useEffect(() => {
    setEmailRef({ ...EmailRef, email: AllEmail });
  }, [AllEmail]);

  const removeEmail = (e) => {
    var index = AllEmail.indexOf(e);
    AllEmail.splice(index, 1);
    setEmailRef({ ...EmailRef, email: AllEmail });
  };

  const [submittrue, setsubmittrue] = useState(false);
  const SendRef = (e) => {};

  const [rateingfor, setrateingfor] = useState([]);

  const addreating = (e, status) => {
    // console.log(id)
  };

  const communicate = (e) => {
    let id = Ac;
    let dataSend = {
      id,
    };
  };

  const [mappas, setmappas] = useState();

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const [first, setfirst] = useState(false);

  const Clickhandler = (cont) => {
    console.log(cont);
    if (cont == "image") {
      document.getElementById("image").click();
    } else {
      document.getElementById("video").click();
    }
  };

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    axios
      .post("/api/Free/ProProfileexternal", {
        Ac,
      })
      .then((res) => {
        setGetval(res.data[0]);
        setEmailRef((prev) => ({ ...prev, sub: res.data[0].string2[0].sub }));
        console.log(res.data[0]);

        // console.log(res.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function cater(e) {
    // console.log(e.target.value)
    setforume(e.target.value);
  }
  useEffect(() => {
    console.log(forume);
  }, [forume]);

  function jvur(e, fr) {
    document.getElementById(fr).click();
  }

  function fastandf(e, content, ee, elem, id) {
    console.log(elem);
    var pit = document.getElementById(content).value;
    console.log(content);
    // var acer = document.getElementById(eyfye).files;
    console.log(pit);
    // console.log(acer);
    var data = {
      content: pit,
      deleted: filedel,
      elem,
      id,
    };
    var m = JSON.stringify(data);
  }

  useEffect(() => {
    try {
      if (first) {
        if (first.good) document.getElementById("GoodVendor").checked = true;

        if (first.high) {
          document.getElementById("HRvendor").checked = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [first]);

  const [referCount, setreferCount] = useState(false);
  const [yeser, setyeser] = useState(false);

  const [error, seterror] = useState(false);
  const validation = () => {
    if (EmailRef.email.length > 0) {
      seterror("");
    } else {
      return seterror("Please add a Email Id");
    }
    if (EmailRef.massage.length > 0) {
      seterror("");
    } else {
      return seterror("PLEASE ENTER Message");
    }
    return true;
  };

  const [Content, setContent] = useState(
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  useEffect(() => {
    if (submittrue) {
      validation();
    }
  });

  const [Final, setFinal] = useState();
  const [FinalVideo, setFinalVideo] = useState();

  function PortfolioHandler() {
    if (Video.length > 0) {
      var V_initId = Video[0].lastModified;
      var V_value = Object.values(Video);

      var V_r = V_value.length
        ? V_value.map((V_pos) => {
            if (V_pos.lastModified == V_initId && Media.length < 1) {
              return (
                <div
                  key={V_pos.lastModified}
                  id={V_pos.lastModified}
                  className="itemx carousel-item active"
                >
                  <video className="img-thumbnaili" controls>
                    <source
                      src={URL.createObjectURL(V_pos)}
                      height="100%"
                      width="100%"
                    />
                  </video>
                </div>
              );
            } else {
              return (
                <div
                  key={V_pos.lastModified}
                  id={V_pos.lastModified}
                  className="itemx carousel-item "
                >
                  <video className="img-thumbnaili" controls>
                    <source
                      src={URL.createObjectURL(V_pos)}
                      height="100%"
                      width="100%"
                    />
                  </video>
                </div>
              );
            }
          })
        : null;

      setFinalVideo(V_r);
    }
    if (Media.length > 0) {
      var initId = Media[0].lastModified;
      var value = Object.values(Media);

      var r = value.length
        ? value.map((pos) => {
            if (pos.lastModified == initId && Video.length < 1) {
              return (
                <div
                  key={pos.lastModified}
                  id={pos.lastModified}
                  className="itemx carousel-item active"
                >
                  <img
                    src={URL.createObjectURL(pos)}
                    className="img-thumbnaili"
                    alt="..."
                  />
                </div>
              );
            } else {
              return (
                <div
                  key={pos.lastModified}
                  id={pos.lastModified}
                  className="itemx carousel-item "
                >
                  <img
                    src={URL.createObjectURL(pos)}
                    className="img-thumbnaili"
                    alt="..."
                  />
                </div>
              );
            }
          })
        : null;

      setFinal(r);
    }
  }

  useEffect(() => {
    if (Media.length > 0) {
      PortfolioHandler();
    }
    if (Video.length > 0) {
      PortfolioHandler();
    }
  }, [Media, Video]);

  function changeDp(file) {
    var DP = document.getElementById("DP");
    DP.src = URL.createObjectURL(file);
    console.log(DP.src);
    console.log("DP.src");
  }

  const [type, settype] = useState();
  useEffect(() => {
    if (getval.formType === 1) {
      settype(true);
    } else if (getval.formType === 2) {
      settype(false);
    }
  }, [getval]);

  const [first8, setfirst8] = useState(false);

  const [sabari, setsabari] = useState("");
  const [sabarierr, setsabarierr] = useState("");

  const reprtsabari = () => {};

  const [CommunicationStatus, setCommunicationStatus] = useState("Loading");

  const [firstst, setfirstst] = useState();
  const [firstst2, setfirstst2] = useState();

  return (
    <>
      {/* <NavBar /> */}
      {props.demo ? <NewNavigationBarDissabled /> : <></>}
      <div>
        <div className="container emp-profile border ">
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-3">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="profilee-img">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={getval.link}
                  alt=""
                />
                {/* <div className="file btn btn-lg btn-primary">
                  Change Photo
                  <input onChange={(e)=>changeDp(e.target.files[0])} type="file" name="ProfilePic" accept="image/*"/>
                </div> */}
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5 className="mb-1">
                    {getval.pname ? getval.pname.toUpperCase() : ""}
                  </h5>
                  <p className="mt-0 pt-0 mb-2">
                    {getval?.taglineCheck
                      ? getval?.brandtagline
                        ? getval.brandtagline
                        : ""
                      : ""}
                  </p>
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5 className="mb-1">
                      {getval.pname ? getval.pname.toUpperCase() : ""}
                    </h5>
                    <p className="mt-0 pt-0 mb-2">
                      {getval?.taglineCheck
                        ? getval?.brandtagline
                          ? getval.brandtagline
                          : ""
                        : ""}
                    </p>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string ? getval.string[0].category : ""} |{" "}
                        {getval.string2 ? getval.string2[0].sub : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.string ? getval.string[0].category : ""} |{" "}
                          {getval.string2 ? getval.string2[0].sub : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <Link to="/My_profile_productstore/Edit_profile_p">
                      <input
                        type="submit"
                        className="profile-edit-btn"
                        name="btnAddMore"
                        value="Edit Profile"
                      />
                    </Link>
                  </div> */}
                </div>
                <div className="row pe-0">
                  <div className="col pe-0">
                    {CommunicationStatus === true ? (
                      localStorage.getItem("Chat") === "false" ? (
                        <>
                          <div className="d-none d-md-block">
                            <input
                              type="submit"
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ borderRadius: "25px" }}
                              name="btnAddMore"
                              value="Communicate"
                              data-bs-toggle="modal"
                              data-bs-target={`${
                                localStorage.getItem("Chat") === "false"
                                  ? "#turnonchat23423"
                                  : "#communi"
                              }`}
                            />
                          </div>
                          <div className="d-block d-md-none">
                            <center>
                              <input
                                type="submit"
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="Communicate"
                                data-bs-toggle="modal"
                                data-bs-target={`${
                                  localStorage.getItem("Chat") === "false"
                                    ? "#turnonchat23423"
                                    : "#communi"
                                }`}
                              />
                            </center>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-none d-md-block">
                            <input
                              type="button"
                              onClick={(e) => {
                                communicate();
                              }}
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ borderRadius: "25px" }}
                              name="btnAddMore"
                              value="Communicate"
                            />
                          </div>
                          <div className="d-block d-md-none">
                            <center>
                              <input
                                type="button"
                                onClick={(e) => {
                                  communicate();
                                }}
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="Communicate"
                              />
                            </center>
                          </div>
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="row  ms-3">
                  {getval.Type === "Productv3" ? (
                    <div
                      className="form-check col-12 d-flex justify-content-start mb-3 pe-0 invisible"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalsabari"
                    >
                      <input
                        className="form-check-input float-end checkboxpro me-3 mt-3"
                        type="checkbox"
                        disabled
                      />
                      <span className="iorughoisrhgiohs">
                        Short&#8209;list&nbsp;this&nbsp;Profile
                      </span>
                    </div>
                  ) : (
                    <div
                      className="form-check col-12 d-flex justify-content-start mb-3 pe-0"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalsabari"
                    >
                      <input
                        className="form-check-input float-end checkboxpro me-3 mt-3"
                        type="checkbox"
                        disabled
                      />
                      <span className="iorughoisrhgiohs">
                        Short&#8209;list&nbsp;this&nbsp;Profile
                      </span>
                    </div>
                  )}
                  <div className="col-3 col-md-3 col-lg-5 d-none">
                    {/* {!Follower ? (
                      <>
                        <button
                          type="button"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{ borderRadius: "25px" }}
                          onClick={(e) => follow(e, idd)}
                        >
                          Follow
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{ borderRadius: "25px" }}
                          onClick={(e) => unfollow(e, idd)}
                        >
                          UnFollow
                        </button>
                      </>
                    )} */}
                  </div>
                </div>
                {/* <p className="proile-rating">
                  About: {" "}
                  <span>
                    I'm Yuki. Full Stack Designer I enjoy creating user-centric,
                    delightful and human experiences.I'm Yuki.
                  </span>
                </p> */}
                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => activate("home-tab")}
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="profile-tab"
                      data-toggle="tab"
                     
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => activate("profile-tab")}
                    >
                      Timeline
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="Portfolio-tab"
                      data-toggle="tab"
                     
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => activate("Portfolio-tab")}
                    >
                      Catalogue
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>

            <div
              className="modal fade"
              id="reportthismodal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {getval.formType
                        ? getval.formType === 1
                          ? "Report this Profile"
                          : "Report this Profile"
                        : ""}
                    </h5>
                    <button
                      type="button"
                      id="closebuttonforreport"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                    <p>
                      Please BE INFORMED that you must Report Abuse about a
                      Profile ONLY for a genuine valid reason with evidence.
                    </p>
                    <p>
                      Fake / False reporting about a member as an act of prank
                      will result in deactivation of your membership without
                      refund.
                    </p>
                    <textarea
                      className="form-control"
                      onChange={(e) => setsabari(e.target.value)}
                    ></textarea>
                    <p className="text-danger">{sabarierr ? sabarierr : ""}</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn pro-spaces-button3"
                      onClick={reprtsabari}
                    >
                      Report
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="turnonchat23423"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      You need to Activate Chat to communicate with this member.
                    </h5>
                    <button
                      type="button"
                      id="closebuttonforreportkgwfiuwsgfbvsdjbfuisea"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                    <p>
                      Your One-to-One Chatting feature is currently Disabled.
                    </p>
                    <p
                      style={astyle}
                      onClick={enablechat}
                      to="/profile_details_and_settings"
                    >
                      Enable Chatting now
                    </p>
                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn pro-spaces-button3" onClick={reprtsabari}>Report</button> */}
                  </div>
                </div>
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {getval.Type === "Productv3" ? (
                  <>
                    <Accordion
                      expanded={expanded === "panelewrg4"}
                      onChange={handleChange("panelewrg4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Product Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p className="py-2">{getval.Description}</p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <PersonalInformationStatic getval={getval} vendor={true} />

                    {/* {props.demo ? (
                      <> */}
                    <BussinessInformationVendor getval={getval} />
                    <ContactInformationVendor getval={getval} />
                    {/* </>
                    ) : (
                      <>
                        <NonMemberBussinessInformation getval={getval} />
                        <NonMemberContactInformation getval={getval} />
                      </>
                    )} */}
                  </>
                ) : (
                  <>
                    <ShortlistFreee
                      ddreating={addreating}
                      makeEmail={makeEmail}
                      setEmailRef={setEmailRef}
                      SendRef={SendRef}
                      mappas={mappas}
                      getval={getval}
                      referCount={referCount}
                      yeser={yeser}
                      first={first}
                      EmailRef={EmailRef}
                      Ac={Ac}
                      Email={Email}
                      error={error}
                      loading={loading}
                    />
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>
                            {getval
                              ? type
                                ? "Catalogue of Products"
                                : "Catalogue of Projects"
                              : "Catalogue"}
                          </b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <GetportExternal1 Acc={Acc} sab={type} />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    {getval ? (
                      getval.Type === "Product" ||
                      getval.Type === "Productv2" ? (
                        <>
                          {firstst === 0 ? (
                            <></>
                          ) : (
                            <>
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      sab={type}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          )}
                          {firstst2 === 0 ? (
                            <></>
                          ) : (
                            <Accordion
                              expanded={expanded === "panel3322"}
                              onChange={handleChange("panel3322")}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                              >
                                <Typography
                                  sx={{ width: "100%", flexShrink: 0 }}
                                >
                                  <b>
                                    <span className="blink_me">
                                      NEWLY LAUNCHED
                                    </span>{" "}
                                    {getval
                                      ? type
                                        ? "Product"
                                        : "Project"
                                      : ""}
                                  </b>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  <GetNewLaunchExternal
                                    Acc={Acc}
                                    sab={type}
                                    setfirstst2={setfirstst2}
                                  />
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                    {/* <PersonalInformationVendor getval={getval} />
                    {props.demo ? (
                      <> */}
                    <BussinessInformationVendor getval={getval} />
                    <ContactInformationVendor getval={getval} />
                    {/* </>
                    ) : (
                      <>
                        <NonMemberBussinessInformation getval={getval} />
                        <NonMemberContactInformation getval={getval} />
                      </>
                    )} */}
                  </>
                )}
                <Selfdeclaration type="vdp" />
              </div>
            </center>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8"></div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Pview;
