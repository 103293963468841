import React, { useState, useEffect, createContext } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ActiveAccountsettings.css';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Inital } from './initial';
import { notify as sabaritost } from '../../../Utility/notify';
import Invoice_Download from './Invoice_Download';

var sab;
let ultraContact;
export let Settings = createContext();
const ActiveAccountsettings = (props) => {
  let navigate = useNavigate();
  const [first, setfirst] = useState({ pass: '', newpass: '', cnewpass: '' });
  const [firstsd, setfirstsd] = useState();
  const [first2, setfirst2] = useState({ contact: '', pass: '' });
  const [err, seterr] = useState(false);
  const [Reloader, setReloader] = useState(1);
  const notify = (message, stat) => {
    console.log(message);

    if (stat == 'success') {
      console.log('toasfted');
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == 'danger') {
      toast.error(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    props.Auth();
  }, []);

  const update = () => {
    let dataPack = {
      // Otp: 1233,
      // Contact: 8111966294,
    };

    axios
      .post('/', { dataPack })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
        if (err) {
          console.log('pass miss match');
          // toaster
        }
      });
  };

  const passwordupdatefun = (e) => {
    setfirst({
      pass: document.getElementById('oldpassword').value,
      newpass: document.getElementById('newpassword').value,
      cnewpass: document.getElementById('repassword').value,
    });
  };
  const vaid = () => {
    if (
      first.pass.length === 0 ||
      first.newpass.length === 0 ||
      first.cnewpass.length === 0
    ) {
      return false;
    }
    if (first.newpass == first.cnewpass) {
      document.getElementById('err').innerHTML = '';
      return true;
    } else {
      document.getElementById('err').innerHTML =
        'New Password and Confirm New Password Missmatch';

      return false;
    }
  };

  const [otpReq, setotpReq] = useState(false);
  const [dataSend, setdataSend] = useState({
    contact: '',
    password: '',
    otp: '',
  });
  const updatecontact = (e) => {
    let contact = document.getElementById('wifgwif').value;
    let pass = document.getElementById('wifgwifdufgdugf').value;
    let first2 = {
      contact,
      pass,
    };
    if (vaid2(contact, pass)) {
      axios
        .post('/api/AuthenticatedUser/checkPassword', {
          first2,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log('password matched');
            let Contact = first2.contact;
            notify('Password Match', 'success');
            axios
              .post('/api/auth/sendMeOtp', { Contact })
              .then((res) => {
                if (res.status === 200) {
                  setdataSend({ contact, password: pass });
                  setotpReq(true);
                  ultraContact = contact;
                  document.getElementById('gchkjbhlkbnkmnlknkjvkb').click();
                  console.log('OTP send');
                  notify('OTP sended', 'success');
                } else {
                  console.log('OTP not send');
                  // toaster
                  notify('Something went wrong', 'danger');
                }
              })
              .catch((err) => {
                console.log(err);
                if (err) {
                  notify('Something went wrong !', 'success');
                  // toaster
                }
              });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            console.log('Password not match');
          }
        });
    }
  };
  const vaid2 = (contact, pass) => {
    if (contact.length > 0) {
      if (contact.length === 10) {
        document.getElementById('err2').innerHTML = '';
        return true;
      } else {
        document.getElementById('err2').innerHTML = 'Invalid Number';
        return false;
      }
    }

    if (pass.length >= 8) {
      document.getElementById('err2').innerHTML = '';
      return true;
    } else {
      document.getElementById('err2').innerHTML = 'Enter your password';
      return false;
    }
  };

  const [UserId, setUserId] = useState('');
  const [PLoading, setPLoading] = useState(false);
  useEffect(() => {
    if (vaid()) {
      setPLoading(true);
      console.log('here');
      let dataSend = {
        Password: first.pass,
      };
      axios
        .post('/api/AuthenticatedUser/SendOtpUser', { dataSend })
        .then((res) => {
          setPLoading(false);
          console.log(res.data);
          setUserId(res.data);
          notify('OTP sended !', 'success');
          document
            .getElementById('sdexampleqweqweqweqw234923849823Modal')
            .click();

          // setReloader(Reloader + 1)
        })
        .catch((err) => {
          setPLoading(false);

          notify(err.response.data, 'danger');
          console.log(err.response);
        });
    }
  }, [first]);
  const [History, setHistory] = useState();
  const [Vali, setVali] = useState();

  function payHistory() {
    axios.get('/api/User/GetPayHistory').then((res) => {
      setHistory(res.data);
      console.log(res.data);
      setVali(res.data[0]?.string[0]?.Validity);
    });
  }
  useEffect(() => {
    payHistory();
  }, []);

  function ChangePassE() {
    let email = document.getElementById('emailasdasdasfgsrg').value;
    let password = document.getElementById('LoginIdxUpdatedxTrtr').value;
    let dataSend = {
      email,
      password,
    };
    axios
      .post('/api/AuthenticatedUser/UpdateEmail', { dataSend })
      .then((res) => {
        if (res) {
          console.log('Updated');
          notify('Updated !', 'success');
        }
      })
      .catch((err) => {
        notify('Something went wrong !', 'danger');

        console.log('not updated');
      });
  }

  useEffect(() => {
    setfirstsd(
      localStorage.getItem('Color') ? localStorage.getItem('Color') : '#55474a'
    );
  }, []);

  const handleChangeComplete = (color) => {
    // this.setState({ background: color.hex });
    var r = document.querySelector(':root');
    r.style.setProperty('--blue', color.hex);
    setfirstsd(color.hex);
    sab = color.hex;
    // console.log(firstsd)
    // console.log(color)
  };

  const colorChangeButton = () => {
    // console.log(sab)
    axios
      .post('/api/User/colorchange', {
        sab,
      })
      .then((res) => {
        sabaritost('Theme Colour Updated', true);
      })
      .catch((err) => {
        console.log(err);
        sabaritost('Something went wrong !', false);
      });
  };

  const privacy = (
    <div id="security">
      <h5 className="mb-0 mt-5">Theme Colour</h5>
      <p>
        You have the option to personally change the colour of the App Theme,
        all day everyday.
      </p>
      {/* <h5 className="mb-0 mt-5">Privacy Settings</h5>
      <p>These settings help you keep your privacy secure.</p> */}
      <div className="list-group mb-5 shadow">
        <div className="list-group-item">
          <div className="row align-items-center">
            <div className="col">
              <strong className="mb-2">Colour</strong>
            </div>
            <div className="col-auto">
              <button
                className="btn pro-spaces-button"
                data-bs-toggle="collapse"
                data-bs-target="#theme"
              >
                Change
              </button>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col">
            <div id="theme" className="collapse">
              <center>
                {' '}
                <SketchPicker
                  color={firstsd ? firstsd : 'black'}
                  onChangeComplete={handleChangeComplete}
                  presetColors={[
                    '#55374a',
                    '#333333',
                    '#666666',
                    '#999999',
                    '#000000',
                    '#34568B',
                    '#FF6F61',
                    '#6B5B95',
                    '#88B04B',
                    '#92A8D1',
                    '#955251',
                    '#B565A7',
                    '#009B77',
                    '#DD4124',
                    '#D65076',
                    '#45B8AC',
                    '#EFC050',
                    '#5B5EA6',
                    '#9B2335',
                    '#55B4B0',
                    '#E15D44',
                    '#7FCDCD',
                    '#BC243C',
                    '#C3447A',
                  ]}
                />
              </center>
              <div className="d-grid  p-2">
                <button
                  className="btn pro-spaces-button2 btn-block"
                  onClick={(e) => colorChangeButton(e)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="list-group-item">
          <div className="row align-items-center">
            <div className="col">
              <strong className="mb-2">Diable Your profile </strong>
              <p className="text-muted mb-0">Select a profile </p>
              <select className="form-select">
                <option>SABARI</option>
              </select>
            </div>
            <div className="col-auto mt-5">
              <button
                className="btn pro-spaces-button"
                data-bs-toggle="collapse"
                data-bs-target="#disable"
              >
                Disable
              </button>
            </div>
          </div>
        </div> */}
        {/* <h4 className="mt-2 mb-2 ms-2">Disabled</h4> */}
        {/* <div className="list-group-item">
          <div className="row align-items-center">
            <div className="col">
              <strong className="mb-2">SABARI</strong>

              <p className="text-muted mb-0">Disabled profile </p>
            </div>
            <div className="col-auto">
              <button
                className="btn pro-spaces-button"
                data-bs-toggle="collapse"
                data-bs-target="#disable"
              >
                Enable
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );

  const updateMe = async () => {
    console.log('reach');
    console.log(otpReq);

    console.log();
    //  Otp: { Contact: '9447208636', OTP: '1547' }
    let otp = document.getElementById('Otpy').value;

    let dataPack = {
      Otp: {
        Contact: ultraContact,
        OTP: otp,
      },
    };

    try {
      let go = await axios.post('/api/AuthenticatedUser/updateNumber', {
        dataPack,
      });
      if (go) {
        console.log(go);
        document.getElementById('errrTopSec').innerHTML = '';
        notify('Updated', 'success');
        setTimeout(() => {
          document.getElementById('errrTopSec').innerHTML = '';
          document.getElementById('Otpy').value = '';
          document.getElementById('changePassBtnId348').click();
        }, 2000);
      }
    } catch (error) {
      // console.log(error.response.data)

      if (error.response.status === 400) {
        document.getElementById('errrTopSec').innerHTML = error.response.data;
        setTimeout(() => {
          document.getElementById('errrTopSec').innerHTML = '';
          document.getElementById('Otpy').value = '';
          document.getElementById('changePassBtnId348').click();
        }, 2000);
      } else {
        notify('Something went wrong !', 'danger');
      }
    }
  };

  useEffect(() => {
    setacc(
      <Inital
        key={Reloader}
        passwordupdatefun={passwordupdatefun}
        Reloader={Reloader}
        setReloader={setReloader}
      />
    );
  }, [Reloader]);

  const [acc, setacc] = useState(
    <Inital
      key={Reloader}
      passwordupdatefun={passwordupdatefun}
      Reloader={Reloader}
      setReloader={setReloader}
    />
  );
  ///////////////////

  /////////////////

  function activate(active) {
    let navigation = document.getElementById('home-tab');
    let act = document.getElementById('profile-tab');
    let payment = document.getElementById('payment-tab');
    if (active === 'profile-tab') {
      setacc(privacy);
      act.classList.add('show');
      act.classList.add('active');
      navigation.classList.remove('active');
      payment.classList.remove('active');
    } else if (active === 'home-tab') {
      setacc(
        <Inital
          key={Reloader}
          passwordupdatefun={passwordupdatefun}
          Reloader={Reloader}
          setReloader={setReloader}
        />
      );

      navigation.classList.add('show');
      navigation.classList.add('active');
      act.classList.remove('active');
      act.classList.remove('show');
      payment.classList.remove('active');
      payment.classList.remove('show');
    } else if (active === 'payment-tab') {
      setacc(
        <div className="continer">
          <h5 className="mb-4 mt-5">Account Subscription details</h5>

          <div>
            {console.log(Vali)}

            {/* <h6 className="mb-4 mt-5">Valid Up To {Vali?Moment(Vali).format('DD-MM-YYYY'):""} </h6> */}
          </div>
          <div className="table1">
            <div className="container">
              <table className="table table-striped table-hover table-bordered bg-white">
                <thead>
                  <tr>
                    <th scope="col" colSpan={2}>
                      <center>Membership</center>
                    </th>
                    <th scope="col" colSpan={2}>
                      <center>Subscription Period</center>
                    </th>

                    <th scope="col " className="centerProMax" rowSpan={2}>
                      <div className="centerProMax2">Invoice</div>
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">
                      <center>Type</center>
                    </th>
                    <th scope="col">
                      <center>Fees</center>
                    </th>
                    <th scope="col">
                      <center>From</center>
                    </th>
                    <th scope="col">
                      <center>To</center>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {History
                    ? History.map((item) => {
                        var date = Moment(item.string[0].Created_on).format(
                          'DD-MM-YY'
                        );
                        var date1 = Moment(item.Validity).format('DD-MM-YY');
                        var s = Moment.unix(item.created_at).format('DD-MM-YY');

                        if (item.signature && item.Payment_id) {
                          return (
                            <tr>
                              {item.Account_type ? (
                                item.Account_type === 'Consultant' ||
                                item.Account_type === 'Product' ? (
                                  <td>
                                    {' '}
                                    <center>PRIME</center>
                                  </td>
                                ) : item.Account_type === 'Consultantv2' ||
                                  item.Account_type === 'Productv2' ? (
                                  <td>
                                    {' '}
                                    <center>ELITE</center>
                                  </td>
                                ) : item.Account_type === 'Consultantv3' ||
                                  item.Account_type === 'Productv3' ? (
                                  <td>
                                    {' '}
                                    <center>BASIC</center>
                                  </td>
                                ) : item.Account_type === 'Passive' ? (
                                  <td>
                                    {' '}
                                    <center>PASSIVE</center>
                                  </td>
                                ) : (
                                  ''
                                )
                              ) : (
                                <td></td>
                              )}
                              <td>
                                <center>₹&nbsp;{item.amount}/-</center>
                              </td>
                              <td>
                                {' '}
                                <center>{s}</center>
                              </td>
                              <td>
                                <center>{date1}</center>
                              </td>
                              <td>
                                <Invoice_Download id={item._id} />
                              </td>
                            </tr>
                          );
                        }
                      })
                    : ''}
                  {/* <tr>
                   <center><td>₹ 100/-</td></center>
                    <td> <center>12/2/2024</center></td>
                    <td><center>12/2/2025</center></td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );

      payment.classList.add('show');
      payment.classList.add('active');
      act.classList.remove('active');
      act.classList.remove('show');
      navigation.classList.remove('active');
      navigation.classList.remove('show');
    }
  }

  const [OTP, setOTP] = useState('');

  const changePassword = async () => {
    console.log(first, OTP, UserId);
    let dataPack = {
      Otp: {
        OTP,
        Datas: UserId,
      },
      first,
    };

    try {
      let t = await axios.post('/api/AuthenticatedUser/changepassword', {
        dataPack,
      });
      console.log(t);
      notify('Password Updated !', 'success');
      setfirst({
        pass: '',
        newpass: '',
        cnewpass: '',
      });
      setOTP('');
      document.getElementById('ColesOtpModalConfirmPassword').click();
      setUserId('');
      setReloader((prev) => prev + 1);
    } catch (error) {
      console.log(error.response);
      notify(error.response.data, 'danger');
    }
  };

  return (
    <Settings.Provider value={{ PLoading }}>
      <div className="container pt-5">
        {/* <button className="mt-5 pt-5" onClick={(e) => notify("good", "success")}>
        nitu
      </button> */}
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8 mx-auto">
            <div className="my-4">{acc}</div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary d-none"
          data-bs-toggle="modal"
          id="sdexampleqweqweqweqw234923849823Modal"
          data-bs-target="#exampleqweqweqweqw234923849823Modal"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="exampleqweqweqweqw234923849823Modal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Verify !
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="ColesOtpModalConfirmPassword"
                ></button>
              </div>
              <div className="modal-body">
                <b>Enter OTP send to your registered Number/Email</b>
                <input
                  type={'number'}
                  style={{
                    paddingLeft: '30%',
                    letterSpacing: '15px',
                    fontSize: '24px',
                  }}
                  min="1000"
                  onChange={(e) => {
                    setOTP(e.target.value);
                  }}
                  value={OTP}
                  className="form-control"
                ></input>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={(e) => changePassword()}
                  className="btn pro-spaces-button3 "
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Settings.Provider>
  );
};

export default ActiveAccountsettings;
