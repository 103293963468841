import React, { useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsFillGrid1X2Fill } from 'react-icons/bs';
import { MdViewCarousel } from 'react-icons/md';
import { NewGrids } from '../Grid/newGrid2';

function AdView({ data }) {
    console.log(data);
    const [Setup, setSetup] = useState(true);
    let ind = 91
    return (
        <div>
            <div>
                <center>
                    <span
                        onClick={(e) => setSetup(true)}
                        style={{ cursor: "pointer" }}
                        className={`px-2 ${Setup && "apptheamcolor"}`}
                    >
                        <MdViewCarousel size={25} />
                    </span>
                    <span
                        onClick={(e) => setSetup(false)}
                        style={{ cursor: "pointer" }}
                        className={`px-2 ${!Setup && "apptheamcolor"}`}
                    >
                        <BsFillGrid1X2Fill />
                    </span>
                </center>
            </div>
            {Setup ? (
                <div
                    id={"carouselExampleControlsre123123"}
                    className="carousel slide"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner">
                        {data?.File?.map((element1, index) => {
                            console.log(index);
                            if (index === 0) {

                                return (
                                    <div className="carousel-item active brokendrtff">
                                        <AiOutlineCloseCircle
                                            id={"trkor" + index + ind}
                                            className="dksjvbsoubvinvinadoivnaibviadvb d-none"

                                        />
                                        <img
                                            alt=""
                                            className="snblsnlbns"
                                            src={element1.link}
                                        />

                                    </div>
                                );
                            }
                            else {

                                return (
                                    <div className="carousel-item brokendrtff">
                                        <AiOutlineCloseCircle
                                            id={"trkor" + index + ind}
                                            className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                        />
                                        <img
                                            alt=""
                                            className="snblsnlbns "
                                            src={element1.link}
                                        />
                                    </div>
                                );
                            }
                        })
                        }
                    </div>
                    {
                        data.File.length > 1 ? (
                            <>
                                <button
                                    id="Bbtn"
                                    className="carousel-control-prev ekgdfukweguobwljbvuwgcuonew"
                                    type="button"
                                    data-bs-target={
                                        "#carouselExampleControlsre123123"
                                    }
                                    data-bs-slide="prev"
                                >
                                    <span
                                        className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                        Previous
                                    </span>
                                </button>
                                <button
                                    id="Fbtn"
                                    className="carousel-control-next ekgdfukweguobwljbvuwgcuonew"
                                    type="button"
                                    data-bs-target={
                                        "#carouselExampleControlsre123123"
                                    }
                                    data-bs-slide="next"
                                >
                                    <span
                                        className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                        Next
                                    </span>
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                </div>
            ) : (
                <NewGrids
                    files={data.File}
                    titles={[]}
                    discriptions={[]}
                ></NewGrids>
            )}
        </div>
    )
}

export default AdView