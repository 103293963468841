import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { HiSpeakerphone } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import "./SearchResult.css";
import { Link } from "react-router-dom";
import { SearchToolbar } from "../../FreeComponents/SearchToolbar/SearchToolbar";
import { SearchComponent } from "../ConSearchResult/SearchComponent";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Mask } from "../../Utility/mask";
import { useSelector } from "react-redux";
import { ProfileCard } from "../../Components/ProfileCard/ProfileCard";

export function SearchResult() {
  const [open, setOpen] = useState(false);
  const [data2, setdata2] = useState([]);
  const [error2, seterror2] = useState("");
  const [output2, setoutput2] = useState(false);
  const [data, setdata] = useState([]);
  const [cat, setCat] = useState(false);
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");
  const [SearchOn, setSearchOn] = useState(false);
  const [Keyer, setKeyer] = useState(1);
  const [sub, setSub] = useState(false);
  // id=JSON.parse(id)
  let [searchParams, setSearchParams] = useSearchParams();
  var id = Mask.decode(searchParams.get("Sub_id"));
  let totalProfiles = searchParams.get("count") ?? null;
  let userData = useSelector((state) => state.user.value);

  const fetch = () => {
    setload(true);
    axios
      .post("/api/Free/consearching", {
        id,
        id,
        quickLocation: userData.quickLocation,
        limit: 25,
        filter: data.map((elem) => elem.id).filter((ele) => ele != undefined),
      })
      .then((res) => {
        setCat(res.data);
        console.log(res.data);
        const postSummaries = res.data.map((post) => ({
          id: post._id,
          key: post.id,
          data: post,
        }));
        setdata((prev) => [...prev, ...postSummaries]);
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setload(false);
      });
  };

  useEffect(() => {
    fetch();
    axios
      .post("/api/Free/getsubcategoryname", {
        id,
      })
      .then((res) => {
        setSub(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (load) {
    } else {
      if (data.length > 0) {
        setoutput(
          data.map((number) => (
            <ProfileCard key={number.id} ids={makeid()} data={number.data} />

            // <SearchComponent
            //   key={number.id}
            //   ids={makeid()}
            //   data={number.data}
            // ></SearchComponent>
          ))
        );
        if (data.length > 0) {
          if (data.length === 1) {
            setDisplayNo(
              <center>
                <h4>
                  Displaying&nbsp;{totalProfiles}&nbsp;profile in{" "}
                  {sub ? sub[0].sub : ""}
                </h4>
              </center>
            );
          } else {
            setDisplayNo(
              <center>
                <h4>
                  Displaying&nbsp;{totalProfiles}&nbsp;profiles in{" "}
                  {sub ? sub[0].sub : ""}
                </h4>
              </center>
            );
          }
        }
      } else {
        setoutput(
          <center>
            <h4 style={{ fontWeight: "400", color: "#5A5A5A" }}>
              No profiles created yet
            </h4>
          </center>
        );
        setDisplayNo("");
      }
    }
  }, [error, load]);

  const [DisplayNo, setDisplayNo] = useState();

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [ShoutMass, setShoutMass] = useState();
  const [Results, setResults] = useState();

  useEffect(() => {
    setResults();
  }, [Keyer]);
  let prev = 0;
  async function ScrollListener(e) {
    console.log("lllllllllllll");
    let P = Math.abs(
      (e.target.scrollTop * 100) /
        (e.target.scrollHeight - e.target.clientHeight)
    );
    P = Math.round(P);
    console.log(P);
    prev = P;
    if (P >= prev) {
      if (P > 50) {
        console.log(load);
        if (!load) {
          // FetchMessage();
          fetch();
          console.log("first");
        }
      }
    }
  }
  return (
    <div className="content">
      <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq ">
        {/* <div className="d-flex justify-content-end">
          <Link to={"/home/Result2?Sub_id=" + id} className="btn pro-spaces-button me-1 ps-3 pe-3" style={{ "textTransform": "none" }}>ACTIVE Profiles</Link>
        </div>
       
        <center><h5 className="parapara">PRO-ACTIVE Profiles</h5></center> */}
        <SearchToolbar
          key={Keyer}
          setResults={setResults}
          Keyer={Keyer}
          setKeyer={setKeyer}
          SearchOn={SearchOn}
          setSearchOn={setSearchOn}
          open={open}
          setOpen={setOpen}
        />
        <div className={SearchOn ? " d-none" : " "}>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <div className="row">
                <div className="col-12 pb-3">
                  {/* <button
                  className="btn btnsabari px-3 py-1 me-2  btn-rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#shoutoutmodal1"
                >
                  {" "}
                  <HiSpeakerphone />
                  &nbsp;Shout-out
                </button> */}
                  <button
                    className="btn btnsabari px-3 py-1 me-2 me-2  btn-rounded"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalsabari"
                  >
                    {" "}
                    <HiSpeakerphone />
                    &nbsp;Shout-out
                  </button>

                  <button
                    className="btn btnsabari px-3 py-1   btn-rounded"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalsabari"
                  >
                    View Short-listed
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="col d-flex justify-content-end"><button  className="btn btn-rounded btn3 mb-2 me-3" data-bs-toggle="modal" data-bs-target="#shoutoutmodal1"> <HiSpeakerphone /> Shout-out</button><button  className="btn btn-rounded btn3 mb-2 me-2" >View Selected</button></div> */}
            {/* <!-- end col --> */}
          </div>
          {/* <!-- end row --> */}
          {/* <div className={"row"}> */}
          <div onScroll={ScrollListener} className="dfrty">
            {DisplayNo}
            <div className={`row car ${load === false ? "" : "notranslate"}`}>
              {output}
            </div>
          </div>

          {/* <!-- end col --> */}
          {/* </div> */}
        </div>
        {/* <!-- end row --> */}
      </div>

      {/* <!-- container --> */}
    </div>
  );
}
