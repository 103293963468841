import axios from "axios"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { Carousel, CarouselItem } from "react-bootstrap"
import { AiTwotoneFileAdd } from "react-icons/ai"
import { print_state, print_city, state_arr } from "../../Utility/stateCity"
let PostContext = createContext()
export const PostToolBar = (props) => {
    const formData = new FormData()
    const [UploadPercentage, setUploadPercentage] = useState(0)
    const [Titles, setTitles] = useState([])
    const [Files, setFiles] = useState([])
    const [Error, setError] = useState()
    const [Data, setData] = useState({
        titleA: '',
        titleB: '',
        Discription: '',
        User: '',
        state: '',
        cat: '',
        sub: '',
        Type: 2
    })

    useEffect(() => {
        console.log(Files)
        return () => {
        }
    }, [Files])
    useEffect(() => {
        console.log(Titles)
        return () => {
        }
    }, [Titles])

    function Validation() {

        if (!Data.cat.length) {

            setError("Select category")
            console.log("123")
            return false
        }

        if (!Data.sub.length) {

            setError("Select sub category")
            return false

        }
        setError("")

        return true
    }

    const Submit = () => {
        if (!Validation()) {
            return
        }

        formData.delete('data')
        formData.delete('file')
        Files.forEach(element => {
            formData.append("file", element);

        });
        let data = {
            Titles, Data
        }
        data = JSON.stringify(data)
        console.log(data)
        formData.append("data", data);

        ///////////////////////////
        const options = {
            onUploadProgress: (progressEvent) => {
                console.log(progressEvent)
                const { loaded, total } = progressEvent;
                let percent = Math.floor(((loaded / 1000) * 100) / (total / 1000));
                console.log(percent)
                // move(percent)/
                if (percent < 100) {
                    setUploadPercentage(percent);
                    console.log(UploadPercentage)
                }
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        ////////////////////////////////////
        axios.post('/api/User/PostOn', formData, options).then((res) => {

        })

        document.getElementById('postcheyunnabuttonproposat').click();
        // window.location.reload();
    }


    const [subcategory, setSubcategory] = useState(false);
    const [category, setCategory] = useState(false);
    const [cat, setCat] = useState([]);
    const [Params, setParams] = useState({
        cat: '', sub: '', state: '', city: ''
    })

    const [dataFetched, setdataFetched] = useState([])
    useEffect(() => {
        print_state('State1')
        axios
            .get("/api/api/getprocategory")
            .then((res) => {
                if (res.status === 200) {
                    console.log("workd");
                    setCat(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    var sab = [];
    useEffect(() => {
        if (cat.length >= 1) {
            cat.forEach((element) => {
                sab.push(<option value={element._id}>{element.category}</option>);
            });
            setCategory(sab);
        } else {
            console.log("nothing");
        }
    }, [cat]);
    let dataFetch = () => {
        console.log(Params)
        let subdata = Params.sub
        let stateeee = Params.state
        let cityeeee = Params.city

        axios.post("/api/User/virtualfindcon", {
            subdata,
            stateeee,
            cityeeee,
        }).then((res) => {
            setdataFetched(res.data)
            console.log(res)
        }).catch((error) => {
            console.log(error)
        })
    }
    function Cityshow(e) {
        setData({ ...Data, state: e.target.value })
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city("City", index);
        //     setData((prev) => ({ ...prev, State: e.target.value }))
    }
    const sabari = (e) => {
        setData({ ...Data, cat: e.target.value })
        console.log(e.target.value);
        var anadhan;
        cat.forEach((element) => {
            console.log(element._id);
            if (element._id === e.target.value) {
                anadhan = element.sub.map((elements) => {
                    return <option value={elements._id}>{elements.sub}</option>;
                });
            } else {
            }
        });
        setSubcategory(anadhan);
    };





  


    return <div className="mt-5 ">
        <PostContext.Provider value={{ setFiles, Files, setTitles, Titles, setData }}>
        {/* <div className=" ">
         <button className="btn pro-spaces-button3 w-100" data-bs-toggle="modal" data-bs-target="#posttoolbar873428736">PRO- FEATURED</button>
     </div> */}

        {localStorage.getItem('Type')==='sponsored'?
         <div className=" pt-4">
         <button className="btn pro-spaces-button3 w-100" data-bs-toggle="modal" data-bs-target="#posttoolbar873428736">PRO- FEATURED VENDOR</button>
     </div>
     :<></> 
        } 
           
            <div className="modal fade " style={{'zIndex':'10000000000'}} id="posttoolbar873428736" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <label>Category</label>
                                        <select
                                            id="sel1"
                                            className="form-select"
                                            onChange={(e) => sabari(e)}
                                        >
                                            <option value="" disabled selected>

                                            </option>
                                            {category ? category : ""}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <label>Sub Category</label>
                                        <select
                                            id="sel2"
                                            className="form-select"
                                            onChange={(e) => setData({ ...Data, sub: e.target.value })}
                                        >
                                            <option value="" disabled selected>

                                            </option>
                                            {subcategory ? subcategory : ""}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <label>State</label>

                                        <select
                                            onChange={(e) => Cityshow(e)}
                                            id="State1"
                                            name="City"
                                            className="form-select"
                                        ></select>
                                    </div>
                                </div>
                            </div >

                            <div className="row g-1 mt-2 mb-3">
                                <input placeholder="Post Title " value={Data.titleA} onChange={(e) => setData({ ...Data, titleA: e.target.value })} className="form-control"></input>
                                <input placeholder="Post Title " value={Data.titleB} onChange={(e) => setData({ ...Data, titleB: e.target.value })} className="form-control d-none"></input>
                                <textarea placeholder="Post Discription" value={Data.Discription} onChange={(e) => setData({ ...Data, Discription: e.target.value })} className="form-control" rows={6}></textarea>
                            </div>
                            <div id='uploadContainer' className="container-fluid ps-0 pe-0">
                                <SliderPost />
                            </div>
                          <hr />
                            <div className="row mt-2 mb-2"><b>Tag a person</b></div>
                            <SearchInput />

                        </div>
                        <p className='text-danger m-0'>{Error ? Error : ''}</p>
                        <div className="modal-footer">
                            <button id="postcheyunnabuttonproposat" type="button" onClick={(e)=>{
                                props.setKeyr((prev)=>prev+1)
                            }}  className="btn pro-spaces-button" data-bs-dismiss="modal">Discard</button>
                            <button type="button" onClick={(e) => Submit()} className="btn pro-spaces-button3">Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </PostContext.Provider>
    </div>
}

export const SliderPost = () => {
    const FileInput = useRef()
    const postContext = useContext(PostContext)
    // const [setFiles, files] = postContext4


    const Unload = (index) => {
        console.log(index)
        let titles = [...postContext.Titles]
        let files = [...postContext.Files]
        files.splice(index, 1)
        titles.splice(index, 1)
        postContext.setTitles(titles)
        postContext.setFiles(files)
    }

    const handleFiles = (e) => {
        console.log(e)
        let index = postContext.Files.length - 1
        postContext.setFiles((prev) => [...prev, ...e.target.files])
        if (index >= 0) {
            let titles = [...postContext.Titles]
            titles[index] = ''
            postContext.setTitles(titles)
        } else {

            let titles = ['']
            postContext.setTitles(titles)
        }
    }

    const handleTitle = (e, index) => {
        let titles = [...postContext.Titles]
        titles[index] = e.target.value
        postContext.setTitles(titles)

    }

    return <div className="container-fluid ps-0 pe-0">
        <input ref={FileInput} className="d-none" onChange={(e) => { handleFiles(e) }} type={'file'} multiple></input>
        {postContext.Files.length >= 1 &&
            <Carousel className="specscarosel23876487236">
                {postContext.Files.map((elem, index) => {
                    if (elem.type.match(/image/)) {
                        return <CarouselItem>
                            <div ><button className="btn btn-close float-end " onClick={(e) => Unload(index)}></button> </div>
                            <img className="carItem" src={URL.createObjectURL(elem)}></img>
                            <input onChange={(e) => handleTitle(e, index)} className="form-control"></input>
                        </CarouselItem>
                    } else if (elem.type.match(/video/)) {
                        return <CarouselItem>
                            <div ><button className="btn btn-close float-end " onClick={(e) => Unload(index)}></button> </div>
                            <video className="carItem" controls>
                                <source src={URL.createObjectURL(elem)} />
                            </video>
                            <input className="form-control"></input>

                        </CarouselItem>
                    }

                })}
            </Carousel>
        }
        <button className="btn p-0 mt-2 mb-2" style={{'color':'#55374a'}} onClick={(e) => FileInput.current.click()}><AiTwotoneFileAdd size={30} /></button>
    </div>
}

export const SearchInput = () => {
    const postContext = useContext(PostContext)
    const [subcategory, setSubcategory] = useState(false);
    const [category, setCategory] = useState(false);
    const [cat, setCat] = useState([]);
    const [Params, setParams] = useState({
        cat: '', sub: '', state: '', city: ''
    })

    const [dataFetched, setdataFetched] = useState([])
    useEffect(() => {
        print_state('State')
        axios
            .get("/api/api/getprocategory")
            .then((res) => {
                if (res.status === 200) {
                    console.log("workd");
                    setCat(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    var sab = [];
    useEffect(() => {
        if (cat.length >= 1) {
            cat.forEach((element) => {
                sab.push(<option value={element._id}>{element.category}</option>);
            });
            setCategory(sab);
        } else {
            console.log("nothing");
        }
    }, [cat]);
    let dataFetch = () => {
        console.log(Params)
        let subdata = Params.sub
        let stateeee = Params.state
        let cityeeee = Params.city

        axios.post("/api/User/virtualfindcon", {
            subdata,
            stateeee,
            cityeeee,
        }).then((res) => {
            setdataFetched(res.data)
            console.log(res)
        }).catch((error) => {
            console.log(error)
        })
    }
    function Cityshow(e) {
        setParams({ ...Params, state: e.target.value })
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city("City", index);
        postContext.setData((prev) => ({ ...prev, State: e.target.value }))
    }
    const sabari = (e) => {
        setParams({ ...Params, cat: e.target.value })
        console.log(e.target.value);
        var anadhan;
        cat.forEach((element) => {
            console.log(element._id);
            if (element._id === e.target.value) {
                anadhan = element.sub.map((elements) => {
                    return <option value={elements._id}>{elements.sub}</option>;
                });
            } else {
            }
        });
        setSubcategory(anadhan);
    };
    // /api/api / getprocategory
    // / api / api / getcategory
    return <div>
        <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
                <label>Category</label>
                <select
                    id="sel1"
                    className="form-select"
                    onChange={(e) => sabari(e)}
                >
                    <option value="" disabled selected>

                    </option>
                    {category ? category : ""}
                </select>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <label>Sub Category</label>
                <select
                    id="sel2"
                    className="form-select"
                    onChange={(e) => setParams({ ...Params, sub: e.target.value })}
                >
                    <option value="" disabled selected>

                    </option>
                    {subcategory ? subcategory : ""}
                </select>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <label>State</label>

                <select
                    onChange={(e) => Cityshow(e)}
                    id="State"
                    name="City"
                    className="form-select"
                ></select>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <label>City</label>
                <select
                    name="City"
                    className="form-select"
                    id="City"   
                    onChange={(e) => setParams({ ...Params, city: e.target.value })}
                >
                    <option></option>
                </select>
            </div>

            <button onClick={(e) => dataFetch()} className="btn pro-spaces-button3 mt-2 mb-3">Search</button>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <label>User</label>
                <select onChange={(e) => postContext.setData((prev) => ({ ...prev, User: e.target.value }))} className='form-select' >
                    {dataFetched.map((elements) => {
                        return <option value={elements._id}>
                            {elements.pname}
                        </option>
                    })}
                </select>
            </div>
        </div>
    </div >
}