import React,{useEffect} from 'react'
import { AiFillBulb } from 'react-icons/ai'
import { MdEmergency, MdOutlineApps } from 'react-icons/md'
import { BsCameraReelsFill, BsInfoLg } from 'react-icons/bs'
import { GiFist } from 'react-icons/gi'
import { FaUmbrellaBeach } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const Information = (props) => {

    useEffect(() => {
        props.Auth();
      }, []);

      const newStyle = {
        paddingTop: '60px'
      }
    
      const cardEdge = {
        borderRadius: '10px',
        // border:'1px solid var(--blue)',
        overflow: 'hidden',
        boxShadow:
          ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        // backgroundColor:'var(--blue)',
      }
    
      const linkStyle = {
        textDecoration: 'none',
        color: 'var(--blue)'
      }

  return (
    <>
      <div className='container'>
      <div className='row' style={newStyle}>
          <div className='col-12 mt-3 mb-2 mt-5'>
            <BsInfoLg
              size={25}
              style={{ color: 'var(--blue)', marginTop: '-5px' }}
            />
            <span
              className='text-uppercase '
              style={{
                color: 'var(--blue)',
                fontSize: '18px',
                fontWeight: 'bold'
              }}
            >
              &nbsp;&nbsp;INFORMATION
            </span>
          </div>
        </div>
        <div className='row g-3'>
          <div className=' col-6 col-lg-3'>
            <div className='card' style={cardEdge}>
              <Link
                to='/emergency_services'
                style={{ textDecoration: 'none', color: 'var(--blue)' }}
              >
                <div className='card-content'>
                  <div className='card-body'>
                    <div>
                      <center>
                        <h3>
                          <MdEmergency />
                        </h3>
                      </center>
                      <center>
                        <span>EMERGENCY</span>
                      </center>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className=' col-6 col-lg-3'>
            <div className='card' style={cardEdge}>
              <a
                data-bs-toggle='modal'
                data-bs-target='#achiversmodal1'
                style={linkStyle}
              >
                {/* <Link to="/movies" style={{'textDecoration':'none','color':'var(--blue)'}} > */}
                <div className='card-content'>
                  <div className='card-body'>
                    <div>
                      <center>
                        <h3>
                          <MdOutlineApps />
                        </h3>
                      </center>
                      <center>
                        <span>APPS</span>
                      </center>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </a>
            </div>
          </div>
          <div className=' col-6 col-lg-3'>
            <div className='card' style={cardEdge}>
              <a
                data-bs-toggle='modal'
                data-bs-target='#achiversmodal1'
                style={linkStyle}
              >
                {/* <Link to="/movies" style={{'textDecoration':'none','color':'var(--blue)'}} > */}
                <div className='card-content'>
                  <div className='card-body'>
                    <div>
                      <center>
                        <h3>
                          <BsCameraReelsFill />
                        </h3>
                      </center>
                      <center>
                        <span>ENTERTAINMENT</span>
                      </center>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </a>
            </div>
          </div>
          <div className=' col-6 col-lg-3'>
            <div className='card' style={cardEdge}>
              <a
                data-bs-toggle='modal'
                data-bs-target='#achiversmodal1'
                style={linkStyle}
              >
                {/* <Link to="/movies" style={{'textDecoration':'none','color':'var(--blue)'}} > */}
                <div className='card-content'>
                  <div className='card-body'>
                    <div>
                      <center>
                        <h3>
                          <FaUmbrellaBeach />
                        </h3>
                      </center>
                      <center>
                        <span>LEISURE</span>
                      </center>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </a>
            </div>
          </div>
          <div className=' col-6 col-lg-3'>
            <div className='card' style={cardEdge}>
              <a
                data-bs-toggle='modal'
                data-bs-target='#achiversmodal1'
                style={linkStyle}
              >
                <div className='card-content'>
                  <div className='card-body'>
                    <div>
                      <center>
                        <h3>
                          <GiFist />
                        </h3>
                      </center>
                      <center>
                        <span>ACTIVISTS</span>
                      </center>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className=' col-6 col-lg-3'>
            <div className='card' style={cardEdge}>
              <a
                data-bs-toggle='modal'
                data-bs-target='#achiversmodal1'
                style={linkStyle}
              >
                {/* <Link to="/innovations" style={{'textDecoration':'none','color':'var(--blue)'}} > */}
                <div className='card-content'>
                  <div className='card-body'>
                    <div>
                      <center>
                        <h3>
                          <AiFillBulb />
                        </h3>
                      </center>
                      <center>
                        <span>INNOVATIONS</span>
                      </center>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </a>
            </div>
          </div>
        </div>
      </div>

       
    </>
  )
}
