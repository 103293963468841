import React, { createContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import "./App.css";
import { Anime } from "./Components/Anime/Anime";
import { PublicRoutes } from "./Pages/public";
import { Notifycomponent } from "./Utility/notify";
import { ShareThis } from "./Pages/ShareThis/ShareThis";
import axios from "axios";
import { Link } from "react-router-dom";

require("dotenv").config();

export let ProContext = createContext();
function App() {
  const [first, setfirst] = useState();
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  function fk() {
    var cook = getCookie("Splash");

    if (!cook) {
      setSplash(true);
      setTimeout(() => {
        document.getElementById("sfohosfoshfwhfvpw").classList.add("d-none");
        try {
          document.getElementById("nomodalforsabaribtn").click();
        } catch (error) {
          console.log(error);
        }
      }, 6000);
      document.cookie = "Splash=True; path=/";
    }
  }
  let userY;
  useEffect(() => {
    userY = navigator.userAgent;
    setfirst(userY);
  }, []);

  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  useEffect(() => {
    const preventIncreaseGlobally = (event) => {
      const target = event.target;

      // Check if the event target is a number input
      if (target.tagName === "INPUT" && target.type === "number") {
        // Prevent the default behavior (increase or decrease) for all keys and wheel events
        if (
          event.key === "ArrowUp" ||
          event.key === "ArrowDown" ||
          event.key === "PageDown" ||
          event.key === "PageUp" ||
          (event.deltaY > 0 && event.deltaMode === 0)
        ) {
          // Prevent the default behavior (increase or decrease)
          event.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", preventIncreaseGlobally);

    // Explicitly set the wheel event listener to be non-passive
    document.addEventListener("wheel", preventIncreaseGlobally, {
      passive: false,
    });

    // Cleanup the event listeners on component unmount
    return () => {
      document.removeEventListener("keydown", preventIncreaseGlobally);
      document.removeEventListener("wheel", preventIncreaseGlobally);
    };
  }, []);
  useEffect(() => {
    let first = navigator.userAgent;
    if (first) {
      var sp = first.indexOf("Mobile");
      var sup = first.indexOf("iPad");
      var w = window.innerWidth;
      if (sup >= 0) {
        return;
      }
      if (sp >= 0) {
        document.getElementById("root").classList.add("mobileclass");
        window.addEventListener(
          "load",
          function () {
            // if(w>320)
            setTimeout(() => {
              let tommy = document.getElementById("Tommy");
              tommy.children[0].innerHTML = `Please Rotate your device`;
              tommy.children[1].innerHTML = `We don't support Landscape mode.`;
              tommy.children[2].innerHTML = `Please switch to Portrait mode.`;
            }, 4000);
          },
          false
        );
      }
    }
  });
  const [splash, setSplash] = useState(false);
  useEffect(() => {
    fk();
  }, []);

  const [Feess, setFeess] = useState("");
  useEffect(() => {
    axios
      .get("/api/free/getsubscriptionfees")
      .then((res) => {
        console.log(res);
        setFeess(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div>
        <div id="sfohosfoshfwhfvpw" className="efgeigfehf">
          {splash ? <Anime /> : <></>}
        </div>
        <ProContext.Provider value={{ splash: splash }}>
          <PublicRoutes />
        </ProContext.Provider>
      </div>
      {/* addvertisment modal */}

      {/* app overview */}
      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="appoverviewmodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div
              className="modal-header border-bottom-0 maiandra-gd-font"
              style={{ backgroundColor: "#55374a" }}
            >
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div
              className="modal-body maiandra-gd-font"
              style={{ backgroundColor: "#55374a" }}
            >
              <div style={{ backgroundColor: "#55374a", color: "#fff" }}>
                <div>
                  <div>
                    <span
                      className="notranslate"
                      style={{ color: "#fccc0a", fontSize: "27.6px" }}
                    >
                      PROchure
                      <span
                        style={{
                          fontSize: "0.5em",
                          position: "relative",
                          top: "-12px",
                          color: "white",
                        }}
                      >
                        ®
                      </span>
                    </span>
                  </div>

                  <p className="mt-0 mb-4">
                    {" "}
                    <span style={{ fontSize: "17.7px" }}>
                      professionals and products
                    </span>
                  </p>

                  <p className="mb-4" style={{ fontSize: "17.7px" }}>
                    <span style={{ color: "#fccc0a" }}>PROchure</span> is an
                    E-Brochure Showcasing and Promoting Business BRANDS around
                    the world&nbsp;!
                  </p>

                  <p className="mb-5" style={{ fontSize: "17.7px" }}>
                    CONSULTANT BRAND{"  "}|{"  "}SERVICE BRAND{"  "}|{"  "}
                    PRODUCT BRAND{"  "}|{"  "}RETAILER BRAND
                  </p>

                  <div className="mb-5">
                    <p>
                      <span style={{ color: "#fccc0a" }}>PROchure</span> is a
                      Need-of-the-Hour platform which aims to bridge the gap
                      between Professional Service Providers &amp; Product
                      Retailers and their prospective Clients &amp; Customers in
                      accessibility and approach via authentic verification
                      processes and in a systematic and organized manner.
                    </p>

                    <p className="mb-0">
                      It is primarily conceptualized as a platform to easily
                      locate Consultants, Artists, Vendors, and Products across
                      industries. Clients, Customers seeking these services,
                      products can connect and communicate with consultants,
                      service providers, product retailers and have one-on-one
                      or group discussions using the online conference room on
                      this platform.
                    </p>
                  </div>

                  <p className="mb-4">
                    <span style={{ color: "#fccc0a", fontSize: "17.7px" }}>
                      This APP is an <u>A</u>uthentic <u>P</u>rofessional{" "}
                      <u>P</u>latform
                    </span>
                  </p>

                  <p>
                    <span style={{ color: "#fccc0a" }}>PROchure</span> is a
                    platform only for Professional Discourse. And in order to
                    maintain the purpose behind this vision, Members cannot Post
                    on a common page and Spam the platform with Social,
                    Religious and Political content and dialogues.
                  </p>

                  <p>
                    <span style={{ color: "#fccc0a" }}>PROchure</span> is an
                    Information-based and Service-oriented domain where revenue
                    generation is only via user subscriptions. So be rest
                    assured that there will be NO unsolicited advertisement
                    ‘pop-ups'.
                  </p>

                  <p className="mb-5">
                    One can REPORT to PROchure about a Member with a
                    Professional / Business Profile IF they find any uploaded
                    Image / Photograph / Video / Audio or Textual content as a
                    part of a Portfolio / Catalogue / Advertisement / Pro-Forum
                    Post that is evidently Stolen, Incorrect and Inappropriate,
                    Fraudulent, Impersonating, Socially Offensive, Abusive,
                    Explicitly and/or Implicitly Sexually Offensive / Harassing
                    / Abusive / Derogatory, Pornographic, Religious and/or
                    Political in nature, information related to Firearms,
                    Ammunition, Contraband products and/or banned / illegal
                    Drugs.
                  </p>

                  <p style={{ fontSize: "17.7px", color: "#fccc0a" }}>
                    CLASSIFICATION OF USERS
                  </p>

                  <p>
                    Subscribers / Users of this platform are classified as :
                  </p>

                  <p>
                    <span style={{ color: "#fccc0a" }}>
                      Brands and Businesses
                    </span>{" "}
                    (Members with professional / business profiles)
                  </p>

                  <p>
                    Professional Consultants, Entrepreneurs, Artists, Artisans,
                    Art Dealers, Trainers, Technically Skilled Service
                    Providers, Commercial Service Providers, Product Vendors,
                    Business Enterprises
                  </p>

                  <p>
                    <span style={{ color: "#fccc0a" }}>
                      Clients and Customers
                    </span>{" "}
                    (Members without professional / business profiles)
                  </p>

                  <p className="mb-5">Anyone above 18 years of age</p>

                  <p style={{ color: "#fccc0a" }}>MEMBER / USER FEATURES</p>

                  <p>
                    1. You can <span style={{ color: "#fccc0a" }}>SEARCH</span>{" "}
                    for appropriate CONSULTANTS, SERVICE PROVIDERS, and PRODUCT
                    RETAILERS for services and products in your preferred
                    locations via state, city and pin code search options.
                  </p>

                  <p>
                    2. Members have access to the Profiles, Portfolios and
                    Catalogues of all Consultants, Service Providers and Product
                    Retailers.
                  </p>

                  <p>
                    3. Know the years of experience, type of services / works /
                    projects handled by the Consultants, Service Providers and
                    their specialization.
                  </p>

                  <p>
                    4. Without one-on-one meetings or phone conversations, put
                    forward your requirements and{" "}
                    <span style={{ color: "#fccc0a" }}>GET QUOTES</span> /
                    estimations / costing for the services you are looking for.
                  </p>

                  <p>
                    5. You can{" "}
                    <span style={{ color: "#fccc0a" }}>SHORT-LIST</span>{" "}
                    Consultants, Service Providers, Product Retailers to contact
                    them later.
                  </p>

                  <p>
                    6. You can <span style={{ color: "#fccc0a" }}>RATE</span>{" "}
                    and <span style={{ color: "#fccc0a" }}>REFER</span>{" "}
                    Consultants, Service Providers, Product Retailers based on
                    their Work Portfolio, Product Catalogue.
                  </p>

                  <p>
                    7. You can{" "}
                    <span style={{ color: "#fccc0a" }}>COMMUNICATE</span> with
                    Consultants, Service Providers, and Product Retailers
                    one-on-one{" "}
                    <span style={{ color: "#fccc0a" }}>via CHATS</span>.
                  </p>

                  <p>
                    8. To help the clients, customers avoid the hassle of
                    approaching multiple Consultants, Service Providers, Product
                    Retailers one-by-one, the client, customer can{" "}
                    <span style={{ color: "#fccc0a" }}>SHOUT-OUT</span> his /
                    her query / requirement to the Consultants, Service
                    Providers, Product Retailers in a particular sub-category.
                    This query goes to ALL the Consultants, Service Providers,
                    Product Retailers (of that sub-category) as a Direct
                    Message.
                  </p>

                  <p className="mb-5">
                    You can also send a SHOUT-OUT to only selected Consultants,
                    Service Providers, Product Retailers after SHORT-LISTING
                    them.
                  </p>

                  <p style={{ color: "#fccc0a" }}>AUTHENTICATION BADGE</p>

                  <p>
                    A tag labelled ‘
                    <span style={{ color: "#fccc0a" }}>PRO-</span> AUTHENTIC !’
                    on the profile card means that PROchure has verified the
                    authenticity of that respective Consultant, Service
                    Provider, Product Retailer. That means, we have verified the
                    following via periodic checks :
                  </p>

                  <p>
                    1. Operating Address and/or Contact Information of the
                    Consultant, Service Provider, Product Retailer.
                  </p>

                  <p>
                    2. Whether the Consultant, Service Provider is currently
                    actively providing consultation / services pertinent to the
                    category in which the profile is created.
                  </p>

                  <p>
                    3. Whether the Product Retailer is currently actively
                    running the business and if it is pertinent to the category
                    in which the profile is created.
                  </p>

                  <p>
                    4. Whether the Consultant, Service Provider is genuinely
                    registered with the statutory body (constituted by the
                    Government of India) as claimed by them.
                  </p>

                  <p>
                    5. If it is observed at any time via our periodic checks
                    that any false information has been provided or any of the
                    above mentioned criteria is incorrect, then the ‘
                    <span style={{ color: "#fccc0a" }}>PRO- </span>
                    AUTHENTIC !’ tag will be removed from that respective
                    profile.
                  </p>

                  <p>Please note :</p>

                  <p>1. Only PAID members are Eligible for this badge.</p>

                  <p>
                    2. Not all Consultants and Service Providers need to be
                    mandatorily registered with any statutory body to offer
                    professional consultation / commercial services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Profile feature Basic */}
      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="dthdjtxasjdewfwfgugafeoifhoegsjwsjymtmumyg"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>
                    Service Providers, Product Retailers
                  </span>{" "}
                  are Members with professional / business profiles
                  <br />
                  <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                    {" "}
                    (Anyone above 18 years of age)
                  </span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <p>
                    Professional Consultants, Entrepreneurs, Artists, Artisans,
                    Art Dealers, Trainers, Technically Skilled Service
                    Providers, Commercial Service Providers, Product Vendors,
                    Business Enterprises
                  </p>
                  <br />

                  <div
                    className="pos"
                    id="_50:617"
                    style={{ top: 617, left: 50 }}
                  >
                    <span
                      id="_15.2"
                      style={{ fontSize: "15.2px", color: "#ffffff" }}
                    >
                      <span style={{ color: "#c85366" }}>
                        Creation of Profiles, Portfolios and Catalogues
                      </span>
                    </span>
                  </div>

                  <p>
                    You can create only a Single Profile in any one sub-category
                    of a category either as a Service Provider or as a Product
                    Retailer.
                  </p>
                  <p>
                    Members cannot create a Portfolio / Catalogue of Services /
                    Products / Projects.
                  </p>
                  <p>
                    Members can upgrade their membership from BASIC to PRIME or
                    ELITE at any time within the current year of subscription as
                    well by paying the difference amount in subscription fees.
                  </p>
                  <br />
                  <p>
                    * FOR MORE INFO and MEMBERSHIP COMPARISON, Please Refer USER
                    FEATURES (on the navigation bar).
                  </p>
                </span>
              </div>
              <br />

              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ color: "#c85366" }}>
                  Displaying Unavailability at work / business
                </span>
              </div>

              <div className="pos" style={{ top: 1021, left: 50 }}>
                <span style={{ fontSize: "15.8px", color: "#ffffff" }}>
                  <p>If on a Short Break or Vacation for a specific period :</p>
                  <p>
                    If you have to be away from work for an urgent or unexpected
                    reason or go on vacation, for a day or few days, you can
                    intimate members about the same with just a click. You can
                    also specify the period of your unavailability. Your profile
                    card will display ‘ We are ON LEAVE / CLOSED until
                    XX/YY/ZZZZ ’. You can undo this action once you are back at
                    work. This feature is seen on ‘Quick Settings’ on your
                    navigation bar.
                  </p>
                  <br />
                  <p>
                    If on a Long Break or Sabbatical for an indefinite period :
                  </p>
                  <p>
                    If you are on a long break from work / business, you can
                    keep your profile (s) Inactive for a while WITHOUT
                    DEACTIVATING or DELETING the account. Your profile card will
                    be hidden to other members until the time you undo this
                    action. This feature is seen on ‘Profile Details and Account
                    Settings’ on your Profile tab.
                  </p>
                </span>
              </div>

              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ color: "#c85366" }}>General Features</span>
              </div>

              <div className="pos" style={{ top: 1021, left: 50 }}>
                <span style={{ fontSize: "15.8px", color: "#ffffff" }}>
                  <ol>
                    <li>
                      You can <span style={{ color: "#c85366" }}>SEARCH</span>{" "}
                      for appropriate SERVICE PROVIDERS, PRODUCT RETAILERS for
                      services, products in your preferred locations via state,
                      city and pin code search options.
                    </li>
                    <li>
                      Members have access to the Profiles, Portfolios and
                      Catalogues of all Service Providers and Product Retailers.
                    </li>
                    <li>
                      Know the years of experience, type of services / works /
                      projects handled by the Service Providers and their
                      specialization.
                    </li>
                    <li>
                      Without one-on-one meetings or phone conversations, put
                      forward your requirements and{" "}
                      <span style={{ color: "#c85366" }}>GET QUOTES</span> /
                      estimations / costing for the services you are looking
                      for.
                    </li>
                    <li>
                      You can{" "}
                      <span style={{ color: "#c85366" }}>SHORT-LIST</span>{" "}
                      Service Providers, Product Retailers to contact them
                      later.
                    </li>
                    <li>
                      You can <span style={{ color: "#c85366" }}>RATE</span> and{" "}
                      <span style={{ color: "#c85366" }}>REFER</span> Service
                      Providers, Product Retailers based on their Work
                      Portfolio, Product Catalogue.
                    </li>
                    <li>
                      You can{" "}
                      <span style={{ color: "#c85366" }}>COMMUNICATE</span> with
                      Service Providers, Product Retailers one-on-one{" "}
                      <span style={{ color: "#c85366" }}>via CHATS.</span>
                    </li>
                  </ol>
                </span>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      {/* Profile feature Elite */}
      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="dthdjtxasjdewfwfgugafgsjwsjymtmumyg"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>
                    Service Providers, Product Retailers
                  </span>{" "}
                  are Members with professional / business profiles
                  <br />
                  <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                    {" "}
                    (Anyone above 18 years of age)
                  </span>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <p>
                    Professional Consultants, Entrepreneurs, Artists, Artisans,
                    Art Dealers, Trainers, Technically Skilled Service
                    Providers, Commercial Service Providers, Product Vendors,
                    Business Enterprises
                  </p>
                  <br />

                  <div
                    className="pos"
                    id="_50:617"
                    style={{ top: 617, left: 50 }}
                  >
                    <span
                      id="_15.2"
                      style={{ fontSize: "15.2px", color: "#ffffff" }}
                    >
                      <span style={{ color: "#c85366" }}>
                        Creation of Profiles, Portfolios and Catalogues
                      </span>
                    </span>
                  </div>

                  <p>
                    The Profile cards of ELITE members are displayed in gold
                    colour which are prominently highlighted compared to the
                    other profile cards. One can search for only ELITE profiles
                    if needed.
                  </p>
                  <p>
                    You can create upto a maximum of 20 (TWENTY) Profiles in
                    total in various sub-categories of various categories in
                    both classifications, that is either as Service Providers
                    and/or as Product Retailers.
                  </p>
                  <p>
                    Members can mention details like their Qualification
                    background, Work Experience, Services offered / Product
                    brands, and all Service / Product related information.
                  </p>
                  <p>
                    Members can create Unlimited Portfolios / Catalogues of
                    Services / Products / Projects with any number of images and
                    audio / video clips.
                  </p>
                  <p>
                    Members can add any number of branches of their business
                    entity / offices, in a single profile.
                  </p>

                  <br />
                  <p>
                    * FOR MORE INFO and MEMBERSHIP COMPARISON, Please Refer USER
                    FEATURES (on the navigation bar).
                  </p>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ color: "#c85366" }}>Shout-Out</span>
              </div>

              <div className="pos" style={{ top: 1021, left: 50 }}>
                <span style={{ fontSize: "15.8px", color: "#ffffff" }}>
                  <p>
                    To help the clients / customers avoid the hassle of
                    approaching multiple Service Providers, Product Retailers
                    one-by-one, the client, customer can SHOUT-OUT his / her
                    query / requirement to the Service Providers, Product
                    Retailers in a particular sub-category. This query goes to
                    ALL the Service Providers, Product Retailers (of that
                    sub-category) as a Direct Message.
                  </p>
                  <p>
                    You can also send a SHOUT-OUT to only selected Service
                    Providers, Product Retailers after SHORT-LISTING them.
                  </p>
                </span>
              </div>
              <br />

              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ color: "#c85366" }}>Know My Client</span>
              </div>

              <div className="pos" style={{ top: 1021, left: 50 }}>
                <span style={{ fontSize: "15.8px", color: "#ffffff" }}>
                  <p>
                    To the mutual benefit of both the Service Providers and
                    their clients, ‘Know My Client’ is a feature where the
                    Service Providers have the option to prepare a set of
                    questions that their prospective clients must answer when
                    the client clicks on the Communicate button on the profile
                    of the service provider. This feature is available only for
                    service providers.
                  </p>
                  <p>
                    These questions can either be deleted or be replaced with
                    new ones at any time.
                  </p>
                </span>
              </div>
              <br />

              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ color: "#c85366" }}>
                  Displaying Unavailability at work / business
                </span>
              </div>

              <div className="pos" style={{ top: 1021, left: 50 }}>
                <span style={{ fontSize: "15.8px", color: "#ffffff" }}>
                  <p>If on a Short Break or Vacation for a specific period :</p>
                  <p>
                    If you have to be away from work for an urgent or unexpected
                    reason or go on vacation, for a day or few days, you can
                    intimate members about the same with just a click. You can
                    also specify the period of your unavailability. Your profile
                    card will display ‘ We are ON LEAVE / CLOSED until
                    XX/YY/ZZZZ ’. You can undo this action once you are back at
                    work. This feature is seen on ‘Quick Settings’ on your
                    navigation bar.
                  </p>
                  <br />
                  <p>
                    If on a Long Break or Sabbatical for an indefinite period :
                  </p>
                  <p>
                    If you are on a long break from work / business, you can
                    keep your profile (s) Inactive for a while WITHOUT
                    DEACTIVATING or DELETING the account. Your profile card will
                    be hidden to other members until the time you undo this
                    action. This feature is seen on ‘Profile Details and Account
                    Settings’ on your Profile tab.
                  </p>
                </span>
              </div>
              <br />

              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ color: "#c85366" }}>PROFILE SWITCHING</span>
              </div>

              <div className="pos" style={{ top: 1021, left: 50 }}>
                <span style={{ fontSize: "15.8px", color: "#ffffff" }}>
                  <p>
                    Profile Switching is only visible and applicable to members
                    with more than one profile from a single registration.
                  </p>
                  <p>
                    In order to Add a Portfolio / Catalogue or Communicate from
                    a particular profile, members with multiple profiles will
                    need to Switch to the Relevant Profile (accessible on the
                    Profile tab of the member).
                  </p>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ color: "#c85366" }}>General Features</span>
              </div>

              <div className="pos" style={{ top: 1021, left: 50 }}>
                <span style={{ fontSize: "15.8px", color: "#ffffff" }}>
                  <ol>
                    <li>
                      You can <span style={{ color: "#c85366" }}>SEARCH</span>{" "}
                      for appropriate SERVICE PROVIDERS, PRODUCT RETAILERS for
                      services, products in your preferred locations via state,
                      city and pin code search options.
                    </li>
                    <li>
                      Members have access to the Profiles, Portfolios and
                      Catalogues of all Service Providers and Product Retailers.
                    </li>
                    <li>
                      Know the years of experience, type of services / works /
                      projects handled by the Service Providers and their
                      specialization.
                    </li>
                    <li>
                      Without one-on-one meetings or phone conversations, put
                      forward your requirements and{" "}
                      <span style={{ color: "#c85366" }}>GET QUOTES</span> /
                      estimations / costing for the services you are looking
                      for.
                    </li>
                    <li>
                      You can{" "}
                      <span style={{ color: "#c85366" }}>SHORT-LIST</span>{" "}
                      Service Providers, Product Retailers to contact them
                      later.
                    </li>
                    <li>
                      You can <span style={{ color: "#c85366" }}>RATE</span> and{" "}
                      <span style={{ color: "#c85366" }}>REFER</span> Service
                      Providers, Product Retailers based on their Work
                      Portfolio, Product Catalogue.
                    </li>
                    <li>
                      You can{" "}
                      <span style={{ color: "#c85366" }}>COMMUNICATE</span> with
                      Service Providers, Product Retailers one-on-one{" "}
                      <span style={{ color: "#c85366" }}>via CHATS.</span>
                    </li>
                  </ol>
                </span>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      {/* Profile feature Prime */}
      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="dthdjtxfgefwweugfoefsjwsjymtmumyg"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div
              className="modal-header border-bottom-0"
              style={{ backgroundColor: "#55374a" }}
            >
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body" style={{ backgroundColor: "#55374a" }}>
              <div className="mb-4">
                <p style={{ color: "#fccc0a", fontSize: "18px" }}>
                  DEVICE USAGE
                </p>
                <p>
                  Simultaneous LOG-IN LIMIT on Multiple Devices :{" "}
                  <span style={{ color: "#fccc0a" }}>THREE</span>
                </p>
              </div>
              <div className="mb-4">
                <p style={{ color: "#fccc0a", fontSize: "18px" }}>
                  CREATION of PROFILES, PORTFOLIOS & CATALOGUES and BRANCH
                  ADDRESSES
                </p>
                <p>
                  <ul>
                    <li>
                      Create upto a maximum of{" "}
                      <span style={{ color: "#fccc0a" }}>TWENTY PROFILES</span>{" "}
                      in total in multiple sub-categories of multiple categories
                      in both classifications, that is either as Service
                      Providers and/or as Product Retailers.
                    </li>
                    <li>
                      Add{" "}
                      <span style={{ color: "#fccc0a" }}>UNLIMITED IMAGES</span>{" "}
                      and Audio / Video Clips.
                    </li>
                    <li>
                      Add{" "}
                      <span style={{ color: "#fccc0a" }}>
                        UNLIMITED BRANCHES
                      </span>
                      .
                    </li>
                  </ul>
                </p>
              </div>
              <div className="mb-4">
                <p style={{ color: "#fccc0a", fontSize: "18px" }}>SHOUT-OUT</p>
                <p>
                  To help the clients / customers avoid the hassle of
                  approaching multiple Service Providers, Product Retailers
                  one-by-one, the client, customer can SHOUT-OUT his / her query
                  / requirement to the Service Providers, Product Retailers in a
                  particular sub-category. This query goes to ALL the Service
                  Providers, Product Retailers (of that sub-category) as a
                  Direct Message.
                </p>
                <p>
                  You can also send a SHOUT-OUT to only selected Service
                  Providers, Product Retailers after SHORT-LISTING them.
                </p>
              </div>
              <div className="mb-4">
                <p style={{ color: "#fccc0a", fontSize: "18px" }}>
                  KNOW MY CLIENT
                </p>
                <p>
                  To the mutual benefit of both the Service Providers and their
                  clients, ‘Know My Client’ is a feature where the Consultant
                  Service Providers have the option to prepare a set of
                  questions that their prospective clients must answer when the
                  client clicks on the Communicate button on the profile of the
                  service provider. This feature is available only for service
                  providers.
                </p>
                <p>
                  Your list of questions will be sent as a direct message to
                  your client’s inbox.
                </p>
                <p>
                  These questions can either be deleted or be replaced with new
                  ones at any time.
                </p>
              </div>
              <div className="mb-4">
                <p style={{ color: "#fccc0a", fontSize: "18px" }}>
                  DISPLAYING UNAVAILABILITY at PLACE of WORK / BUSINESS
                </p>
                <p>If on a Short Break or Vacation for a specific period :</p>
                <p>
                  If you have to be away from work for an urgent or unexpected
                  reason or go on vacation, for a day or few days, you can
                  intimate members about the same with just a click. You can
                  also specify the period of your unavailability. Your profile
                  card will display ‘ I am / We are ON LEAVE / CLOSED until
                  XX/YY/ZZZZ ’. You can undo this action once you are back at
                  work. This feature is seen on ‘Quick Settings’ on your
                  navigation bar.
                </p>
                <p>
                  If on a Long Break or Sabbatical for an indefinite period :
                </p>
                <p>
                  If you are on a long break from work / business, you can keep
                  your profile (s) Inactive for a while WITHOUT DEACTIVATING or
                  DELETING the account. Your profile card will be hidden to
                  other members until the time you undo this action. This
                  feature is seen on ‘Profile Details and Account Settings’ on
                  your Profile tab.
                </p>
              </div>
              <div className="mb-4">
                <p style={{ color: "#fccc0a", fontSize: "18px" }}>
                  PROFILE SWITCHING
                </p>
                <p>
                  Profile Switching is only visible and applicable to members
                  with more than one profile from a single registration.
                </p>
                <p>
                  In order to Add a Portfolio / Catalogue or Communicate from a
                  particular profile, members with multiple profiles will need
                  to Switch to the Relevant Profile (accessible on the Profile
                  tab of the member).
                </p>
              </div>
              <div className="mb-4">
                <p style={{ color: "#fccc0a", fontSize: "18px" }}>PRO-FORUM</p>
                <p>
                  PRO-FORUM is an interactive zone where members (only with
                  professional profiles) belonging to the respective categories
                  (provided in this segment) put forward their views on a topic
                  related only to that particular category which is their field
                  of expertise and initiates a discussion / conversation in an
                  attempt to impart knowledge and create awareness among people
                  about socially relevant issues / concerns.
                </p>
                <p>
                  PROchure will also post articles and expert opinions, views
                  and advice on certain important topics on behalf of renowned
                  professionals with their consent.
                </p>
                <p>
                  Any member of the platform can be a participant by following
                  the strict guidelines of the platform.
                </p>
                <p>
                  These interactions and discussions will be monitored in order
                  to ensure that a civilized discourse is maintained.
                </p>
                <p>
                  The Content (images, videos, text) MUST NOT be Socially
                  Offensive, Abusive, Explicitly and/or Implicitly Sexually
                  Offensive / Harassing / Abusive / Derogatory, Pornographic,
                  Religious and/or Political in nature, information related to
                  Firearms, Ammunition, Contraband products and/or banned /
                  illegal Drugs.
                </p>
                <p>
                  If your Post or Comment is against the regulated framework of
                  the platform, your Post / Comment will be removed with
                  immediate effect and your Account will be Deleted (Membership
                  will be Cancelled) without prior notice and without refund of
                  membership subscription fee.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Profile feature Passive */}
      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="shygciasiucgwrfviklskjgcklbkjas"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>Clients, Customers </span>
                  are Members without professional / business profiles
                  <br />
                  Anyone above 12 years of age
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ color: "#c85366" }}>User Features</span>
              </div>

              <div className="pos" style={{ top: 1021, left: 50 }}>
                <span style={{ fontSize: "15.8px", color: "#ffffff" }}>
                  <ol>
                    <li>
                      You can <span style={{ color: "#c85366" }}>SEARCH</span>{" "}
                      for appropriate SERVICE PROVIDERS, PRODUCT RETAILERS for
                      services, products in your preferred locations via state,
                      city and pin code search options.
                    </li>
                    <li>
                      Members have access to the Profiles, Portfolios and
                      Catalogues of all Service Providers and Product Retailers.
                    </li>
                    <li>
                      Know the years of experience, type of services / works /
                      projects handled by the Service Providers and their
                      specialization.
                    </li>
                    <li>
                      Without one-on-one meetings or phone conversations, put
                      forward your requirements and{" "}
                      <span style={{ color: "#c85366" }}>GET QUOTES</span> /
                      estimations / costing for the services you are looking
                      for.
                    </li>
                    <li>
                      You can{" "}
                      <span style={{ color: "#c85366" }}>SHORT-LIST</span>{" "}
                      Service Providers, Product Retailers to contact them
                      later.
                    </li>
                    <li>
                      You can <span style={{ color: "#c85366" }}>RATE</span> and{" "}
                      <span style={{ color: "#c85366" }}>REFER</span> Service
                      Providers, Product Retailers based on their Work
                      Portfolio, Product Catalogue.
                    </li>
                    <li>
                      You can{" "}
                      <span style={{ color: "#c85366" }}>COMMUNICATE</span> with
                      Service Providers, Product Retailers one-on-one{" "}
                      <span style={{ color: "#c85366" }}>via CHATS.</span>
                    </li>
                    <li>
                      To help the clients / customers avoid the hassle of
                      approaching multiple Service Providers, Product Retailers
                      one-by-one, the client, customer can{" "}
                      <span style={{ color: "#c85366" }}>SHOUT-OUT</span> his /
                      her query / requirement to the Service Providers, Product
                      Retailers in a particular sub-category. This query goes to
                      ALL the Service Providers, Product Retailers (of that
                      sub-category) as a Direct Message.
                    </li>
                    <p>
                      You can also send a SHOUT-OUT to only selected Service
                      Providers, Product Retailers after SHORT-LISTING them.
                    </p>
                  </ol>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Advertisement inside */}
      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="advertisementinsdemodalid"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>
                    ANNUAL DISPLAY of Ads
                  </span>{" "}
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  Your Ads will keep changing automatically (in case of multiple
                  artworks) throughout the year as per your selection of Ad
                  artwork order and Ad Frequency setting.
                  <br />
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>
                    OCCASIONAL DISPLAY of Ads
                  </span>{" "}
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  Ads during a festival / occasion / offers / discount sale can
                  be displayed for a selected duration of time. During this
                  period, your annual rotational Ads will be stopped by default
                  and will only restart once the occasional display period ends.
                  <br />
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>
                    For Your Information :
                  </span>{" "}
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <ul className="mb-0">
                    <li>
                      You can create an Ad for each profile in case you have
                      multiple profiles.
                    </li>
                    <li>
                      You can create an Ad of a profile only after switching to
                      that respective profile.
                    </li>
                    <li>
                      You can change the artwork of your Ad of each profile, any
                      number of times during the subscription period.
                    </li>
                    <li>
                      You can create any number of artworks for each profile Ad.
                    </li>
                    <li>
                      However, only one artwork of Ad of each profile will be
                      displayed at a time.
                    </li>
                    <li>
                      Validity of Advertisement / Endorsement is only until the
                      expiration of your Membership subscription.
                    </li>
                    <li>
                      You can Replace, Pause, Restart and Delete your Ad at your
                      convenience.
                    </li>
                    <li>
                      Display of Ads is based only on a random algorithm of the
                      app without any bias or partiality, whatsoever.
                    </li>
                    <li>
                      Ads will be displayed at the top of the device screen only
                      on the following pages : Respective Sub-category, General
                      Information, Favourites.
                    </li>
                    <li>
                      On clicking your Ad, Members can either visit the
                      respective profile of the Ad or can directly visit the
                      official website of that profile.
                    </li>
                  </ul>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>Strict Guidelines :</span>{" "}
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <ul className="mb-0">
                    <li>
                      The contents (images and text) of your Ad MUST be well
                      within the rules and regulations of the platform.
                    </li>
                    <li>
                      Your Ad Content (images and text) MUST NOT be Socially
                      Offensive, Abusive, Explicitly and/or Implicitly Sexually
                      Offensive / Harassing / Abusive / Derogatory,
                      Pornographic, Religious and/or Political in nature,
                      information related to Firearms, Ammunition, Contraband
                      products and/or banned / illegal Drugs.
                    </li>
                    <li>
                      If your Ad is against the regulated framework of the
                      platform, your Ad will be removed with immediate effect
                      and your Account will be Deleted (Membership will be
                      Cancelled) without prior notice and without refund of
                      membership and advertisement subscription fees.
                    </li>
                  </ul>
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span
                  id="_15.2"
                  style={{ fontSize: "15.2px", color: "#ffffff" }}
                >
                  <span style={{ color: "#c85366" }}>
                    Advertisement Subscription Fee :
                  </span>{" "}
                </span>
              </div>
              <br />
              <div className="pos" id="_50:617" style={{ top: 617, left: 50 }}>
                <span style={{ fontSize: "15.2px", color: "#ffffff" }}>
                  <ul className="mb-0">
                    <li>
                      A fee of Rs 10,000/- (including GST) per annum will be
                      charged on pro-rata basis depending on when you subscribe
                      for the display of your advertisement, within your
                      membership period. Renewing your Ad subscription annually
                      is at your discretion and is not linked to your platform
                      membership.
                    </li>
                    <li>Fee is strictly Non-Refundable.</li>
                  </ul>
                  <br />
                </span>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      {/* DISCLAIMER */}

      <button
        type="button"
        id="liqquermodalprochuresabaribtn"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#liqquermodalprochuresabari"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="liqquermodalprochuresabari"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubtapppolicies2"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <center>
                <h5>DISCLAIMER</h5>
              </center>
              <p>
                1. This page contains information/data relating to alcohol and
                similar substances including photos, videos, articles, etc.
              </p>
              <p>
                2. Persons who are below the legal drinking (alcohol) age of
                their respective state must leave/exit this page immediately.
              </p>
              <p>
                3. If you continue with the respective page, then you confirm
                that you are of legal drinking age.
              </p>
              <p>
                4. PROchure does not sell alcohol through this website/app nor
                does it encourage/promote consumption of alcohol or any other
                similar substances.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* DISCLAIMER 2 */}

      <button
        type="button"
        id="liqquermodalprochuresabaribtn2"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#liqquermodalprochuresabari2"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="liqquermodalprochuresabari2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubtapppolicies2"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <center>
                <h5>DISCLAIMER</h5>
              </center>
              <p>
                1. This page may contain information/data relating to alcohol
                and similar substances including photos, videos, articles, etc.
              </p>
              <p>
                2. Persons who are below the legal drinking (alcohol) age of
                their respective state must leave/exit this page immediately.
              </p>
              <p>
                3. If you continue with the respective page, then you confirm
                that you are of legal drinking age.
              </p>
              <p>
                4. PROchure does not sell alcohol through this website/app nor
                does it encourage/promote consumption of alcohol or any other
                similar substances.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* DISCLAIMER 2  end*/}

      <button
        type="button"
        id="subsubmodalprochuresabaribtn"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#subsubmodalprochuresabari"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="subsubmodalprochuresabari"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubtapppolicies2"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <p>
                Find retailers / wholesalers dealing in one exclusive brand only
                and also those dealing in multiple brands.
              </p>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="subsubmodalprochuresabaribtnrt"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#subsubmodalprochuresabarit"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="subsubmodalprochuresabarit"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubtapppolicies2"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <p>
                Find retailers / wholesalers dealing in one exclusive brand only
                and also those dealing in multiple brands.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* subscrption fees */}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="subscrptionfeesmodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div
              className="modal-header border-bottom-0"
              style={{ backgroundColor: "#55374a" }}
            >
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body" style={{ backgroundColor: "#55374a" }}>
              <div
                className="subscrption_font container"
                style={{ paddingTop: "10px", height: "auto" }}
              >
                <div className="fontforsubcrption">
                  <div className="row">
                    <div className="col-12">
                      MEMBERSHIP SUBSCRIPTION&nbsp;FEES
                    </div>
                    <br />
                  </div>
                  <br />
                  <div className="row gy-2">
                    <div className="col-8">
                      For{" "}
                      <span style={{ color: "#fccc0a" }}>
                        BRANDS and BUSINESS PROFILES
                      </span>
                    </div>
                    <div className="col-4">
                      Rs {Feess.Active}/- per&nbsp;year
                    </div>
                    <br />
                    <br />
                  </div>

                  <br />
                  <div className="row gy-2">
                    <div className="col-12">
                      For{" "}
                      <span style={{ color: "#fccc0a" }}>
                        CLIENTS, CUSTOMERS
                      </span>
                    </div>
                    <div className="col-8 mb-4">
                      Viewing Profiles and Browsing through the entire app
                    </div>
                    <div className="col-4 mb-4">FREE</div>
                    <div className="col-8">
                      <div className="mb-3">
                        To Use / Access the following features :
                      </div>
                      Preferred Location,
                      <br />
                      Advanced Search Filters,
                      <br />
                      Portfolios and Catalogues,
                      <br />
                      Simultaneous usage on 3 devices,
                      <br />
                      General Information, Jobs, Cubicles,
                      <br />
                      Favourites, Shout-out, Virtual Secretary
                    </div>
                    <div className="col-4">
                      Rs {Feess.Passive}/- per&nbsp;year
                    </div>
                  </div>
                  <br />
                </div>
                <br />
                <div>
                  <h6>For Your Information :</h6>
                  <ul>
                    <li>All Fees are inclusive of 18% GST.</li>
                    <li>
                      Membership Subscription Fee is strictly Non-Refundable.
                    </li>
                  </ul>
                  {/* <h6>Be Aware :</h6>
                <ul>
                  <li>We DO NOT accept cash payments from anyone who wishes to subscribe / be a member on our platform.</li>
                  <li>We are NOT responsible / liable for anyone who makes a cash payment to those who impersonate as a marketing executive of “ PROchure ”.</li>
                  <li>Payment towards Subscription / Membership Fees must be made ONLY via the PROchure URL “ www.prochure.in ”.</li>
                </ul> */}
                </div>
              </div>

              <hr className="mt-4" />
              <div
                className="subscrption_font container"
                style={{ paddingTop: "10px", height: "auto" }}
              >
                <div className="fontforsubcrption">
                  <div className="row">
                    <div className="col-12">
                      ADVERTISEMENT SUBSCRIPTION FEES
                    </div>
                  </div>
                  <br />
                  <div className="row gy-2">
                    <div className="col-8">
                      <span style={{ color: "#fff" }}>
                        For&nbsp;
                        <span style={{ color: "#fccc0a" }}>
                          BRANDS and BUSINESS PROFILES
                        </span>
                      </span>
                    </div>
                    <div className="col-4">
                      Rs {Feess.Add_subscription}/- per&nbsp;year
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <h6>For Your Information :</h6>
                  <ul>
                    <li>Fee is inclusive of 18% GST.</li>
                    <li>
                      Advertisement Subscription Fee is strictly Non-Refundable.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* be aware modal */}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="beawaremodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <h6 style={{ color: "#c85366" }} className="pb-3">
                PLEASE BE INFORMED
              </h6>
              <ul>
                <li className="pb-3">
                  PROchure is primarily only an online advertising platform
                  where Service Providers and Product Retailers can create
                  awareness to all about their presence in respective
                  professional and commercial sectors and to showcase their
                  works and products. We are Only a medium to introduce people
                  to each other.
                </li>
                <li className="pb-3">
                  PROchure will neither give business leads nor give assurances
                  on business conversions and return on investment (subscription
                  fees).
                </li>
                <li className="pb-3">
                  PROchure is neither an active or passive participant nor has
                  any role directly or indirectly in the communication, business
                  deals and business transactions between the members and
                  non-members of this platform.
                </li>
                <li className="pb-3">
                  PROchure does a comprehensive check only on certain
                  service-oriented and business-related aspects of service
                  providers and product retailers based on the information
                  provided by them during their profile creation.
                </li>
                <li className="pb-3">
                  PROchure has not done any personal background verification of
                  any member and hence cannot and will not take responsibility
                  for the behavior / conduct / decorum of any member of the
                  platform during the course of interaction and business
                  transactions outside the realm of this platform.
                </li>
                <li className="pb-3">
                  The choice of interaction and business transactions between
                  individuals (members and non-members of the platform) is at
                  the sole discretion of each person involved in it and PROchure
                  is not liable for the same.
                </li>
                <li className="pb-3">
                  PROchure DOES NOT ACCEPT CASH payments from anyone who wishes
                  to subscribe to be a member on our platform.
                </li>
                <li className="pb-3">
                  PROchure is NOT responsible / liable to anyone who makes a
                  cash payment to those who impersonate as a marketing executive
                  of Vipar or PROchure.
                </li>
                <li className="pb-3">
                  Every official and authentic marketing executive of Vipar or
                  PROchure is supposed to carry an ID card which the customer /
                  subscriber must verify.
                </li>
                <li className="pb-3">
                  Payment towards Subscription Fees must be made ONLY via the
                  PROchure URL “ www.prochure.in ” or the Mobile App of PROchure
                  in the name of “ PROchure : a Vipar product ”.
                </li>
                <li className="pb-3">
                  PROchure DOES NOT SAVE your Card Details for future payments.
                </li>
                <li className="pb-3">
                  PROchure DOES NOT HAVE annual subscription Auto-Renewal
                  feature.
                </li>
                <li className="pb-3">
                  Certain fields in Profile Creation are mandatory only to prove
                  your genuineness and authenticity as a Professional Consultant
                  / Service Provider / Business Enterprise.{" "}
                </li>
                <li className="pb-3">
                  PROchure DOES NOT SELL Your Data :<br />
                  We do not share any information collected from you during the
                  process of registration and profile creation with a
                  third-party for monetization or otherwise. What is seen by a
                  third party on this platform is ONLY the information that you
                  have chosen to display / showcase / mention as a part of your
                  professional services / business.
                </li>

                <li className="pb-3">
                  The Original App of “ PROchure ” is available ONLY in ONE
                  SINGLE URL on the Web app version and in ONE SINGLE NAME on
                  the Mobile app versions that are mentioned below :
                  <ol>
                    <li className="pt-3">Web browser URL : www.prochure.in</li>
                    <li className="pt-3">
                      Mobile App Name on Play Store / Google Play / Android :
                      PROchure ( a Vipar product )
                    </li>
                    <li className="pt-3">
                      Mobile App Name on App Store / iOS : PROchure ( a Vipar
                      product )
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*Will be accessible shortly !*/}

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="achiversmodal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="achiversmodalbtn"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>

              <center>
                <h6>Will be accessible shortly !</h6>
              </center>
            </div>
          </div>
        </div>
      </div>

      {/* Consultant key Attributes */}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt  "
        id="exampleModalsabari2wekjfg"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">SERVICE PROVIDERS</h4>
                  <br />
                  <br />
                </div>
                <div className="row">
                  <p>
                    Qualified Professionals, Artists, Academicians, all
                    Technically and Technologically Knowledgeable Persons and
                    Service Providers, and Commercial Service Providers across
                    industries.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Vendor key Attributes */}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt  "
        id="exampleModefveelkfnvalsabari2wekjfg"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">PRODUCT RETAILERS</h4>
                  <br />
                  <br />
                </div>
                <div className="row">
                  <p>
                    All Product Retailers and Vendors, Builders and Developers,
                    Art Dealers.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pro Forum */}
      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"
        id="qiyte7tekqeiugflwihroiwgorwogruoegfsnskdbsabprofo"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">PRO-FORUM</h4>
                </div>
                <div className="row">
                  <p>
                    PRO-FORUM is an interactive zone where members (only with
                    professional profiles) belonging to the respective
                    categories (provided in this segment) put forward their
                    views on a topic related only to that particular category
                    which is their field of expertise and initiates a discussion
                    / conversation in an attempt to impart knowledge and create
                    awareness among people about socially relevant issues /
                    concerns.
                  </p>
                  <p>
                    PROchure will also post articles and expert opinions, views
                    and advice on certain important topics on behalf of renowned
                    professionals with their consent.
                  </p>
                  <p>
                    Any member of the platform can be a participant by following
                    the strict guidelines of the platform.
                  </p>
                  <p>
                    These interactions and discussions will be monitored in
                    order to ensure that a civilized discourse is maintained.
                  </p>
                  <p>
                    The Content (images, videos, text) MUST NOT be Socially
                    Offensive, Abusive, Explicitly and/or Implicitly Sexually
                    Offensive / Harassing / Abusive / Derogatory, Pornographic,
                    Religious and/or Political in nature, information related to
                    Firearms, Ammunition, Contraband products and/or banned /
                    illegal Drugs.
                  </p>
                  <p>
                    If your Post or Comment is against the regulated framework
                    of the platform, your Post / Comment will be removed with
                    immediate effect and your Account will be Deleted
                    (Membership will be Cancelled) without prior notice and
                    without refund of membership subscription fee.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Email alredy registerd in direct link*/}
      <button
        type="button"
        id="directlinkwarningbtn"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#directlinkwarning"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade elfkbnleribndnvlrwnkvwevdherio"
        id="directlinkwarning"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <button
                    id="wjrbgkvbwlvbvxobgoeuuobvbetuovoubtapppolicies2"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <p>
                This Email Id is already registered with another account
                <br />
                Click here <Link to="/signin">Click here to Log in</Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Pro Forum */}
      <div
        className="modal fade "
        id="advancesearchinfo"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                <ul>
                  <li>
                    Matching / Desired Results will entirely depend only on how
                    a member has created the profile.
                  </li>
                  <li>
                    To Avoid Viewing Multiple Branches of the same Business in
                    various Locations outside your preferred city, it is Best to
                    Select Preferred City via Advanced Search Filter. You may
                    also set Default Location to your Current / Preferred City
                    in your Account Settings.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Notifycomponent />
    </>
  );
}

export default App;
