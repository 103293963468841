import React,{useState,useEffect} from 'react'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { Link } from "react-router-dom";

import "./ProductView.css"

import axios from 'axios';
import {  useSearchParams } from "react-router-dom";



export  function ProductView(props) {
    useEffect(() => {
        props.Auth()
        }, [])

    let [searchParams, setSearchParams] = useSearchParams();



    function change_image(e){

        var img = document.getElementById("main-image");
        
        img.src = e.target.src;
        // console.log(image.src)
        }
    //    document.addEventListener("DOMContentLoaded", function(event) {
        
        
        
    //     });
const [first, setfirst] = useState(false)
var idd = searchParams.get("id")
// var Ac = searchParams.get("Ac_id")
// console.log(Ac)
useEffect(() => {
    
    axios.post('/api/User/Getproduct',{
      idd
    }).then((res)=>{
        setfirst(res.data[0])
        console.log(res.data)
    }).catch((err)=>{
    console.log(err)
    })
}, [])
var Ac = first.Account_id


// useEffect(() => {
    
//     axios.post('/User/Getproductprofileee',{
//    
//     }).then((res)=>{
//         // setfirst(res.data[0])
//         console.log(res.data)
//     }).catch((err)=>{
//     console.log(err)
//     })
// }, [])





    return (
        <div className="container pt-4 mb-5">
    <div className="row d-flex justify-content-center">
        <div className="col-md-10">
            <div className="card cardjbgwebogwe">
                <div className="row">
                    <div className="col-lg-6 ">
                        <div className="images p-3 aswq">
                            <div className="text-center p-4 "> <img id="main-image" src={first.File?first.File[0].filename:''} className='img-fluid' /> </div>
                            <div className="thumbnail text-center "> 
                            {first.File?first.File.map((element) => {
                               return <img onClick={(e)=>change_image(e)} className='yut'  src={element.filename} width="70" />
                            }):''}
                            
                            
                           </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="product p-4">
                            <div className="d-flex justify-content-between align-items-center">
                                {/* <div className="d-flex align-items-center"> <Link  to='/home/Product_Result' className='back'><IoReturnUpBackOutline /><span className="ml-1"> &nbsp;Back</span></Link> </div> */}
                            </div>
                            <div className="mt-4 mb-3"> <span className="text-uppercase  brand">Product Name</span>
                                <p className="text-uppercase fw-bold">{first.name?first.name:''}</p>
                                <span className="text-uppercase  brand mb-0">Brand<p className="text-uppercase fw-bold" style={{"fontWeight":"700","fontSize":"16px"}}>{first.brand?first.brand:''}</p></span>
                                <div className="price ">
                                <div className='row'>
                                        <div className='col-5 '><span className="text-uppercase  brand">MRP</span></div>
                                        <div className='col-7'>: &nbsp;&nbsp;<span className="fw-bolder">₹ {first.mrp?first.mrp:''}</span></div>
                                        
                                    </div>
                                     
                                </div>
                                <div className="price">
                                    <div className='row'>
                                        <div className='col-5'><span className="text-uppercase  brand">SELLING PRICE</span></div>
                                        <div className='col-7'>: &nbsp;&nbsp;<span className="fw-bolder">₹ {first.price?first.price:''}</span></div>
                                        
                                    </div>
                                     
                                    {/* <div className="ml-2"> <small className="dis-price">$59</small> <span>40% OFF</span> </div> */}
                                </div>
                               
                            </div>
                            <h6 ><b>Product Description :</b></h6>
                            <p className="about1 mt-2">{first.discription? first.discription:''}</p>
      

                            {/* <div className="sizes mt-5">
                                <h6 className="text-uppercase">Size</h6> <label className="radio"> <input type="radio" name="size" value="S" checked> <span>S</span> </label> <label className="radio"> <input type="radio" name="size" value="M"> <span>M</span> </label> <label className="radio"> <input type="radio" name="size" value="L"> <span>L</span> </label> <label className="radio"> <input type="radio" name="size" value="XL"> <span>XL</span> </label> <label className="radio"> <input type="radio" name="size" value="XXL"> <span>XXL</span> </label>
                            </div> */}
                            <h6><b>Why should you choose this product ?</b></h6>
                            <p className="about1 mt-2">{first.speciality? first.speciality:'' }</p>
                            <div className="cart mt-4 align-items-center"> <Link to={"/home/product_store_profile?Ac_id="+Ac} className="btn btn-color text-uppercase mr-2 px-4">View Shop</Link> <i className="fa fa-heart text-muted"></i> <i className="fa fa-share-alt text-muted"></i> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    )
}
