import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './jobView.css'
import { BsFillBriefcaseFill, BsFillPeopleFill,BsThreeDotsVertical } from "react-icons/bs";

import { AiFillHome } from "react-icons/ai";
import { MdLocationPin, MdSettingsAccessibility } from 'react-icons/md'
import {BiRupee} from 'react-icons/bi'
import "./JobList.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SpinnerSmall } from "../../Utility/spinner";

export function JobMyPost() {

    const notify = (message, stat) => {      
        if (stat == true) {
          // console.log("toasfted");
          toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (stat == false) {
          toast.error(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
    






    const [Loader, setLoader] = useState(<SpinnerSmall/>)

    const [Loding, setLoding] = useState(true)
    const [Error, setError] = useState(false)
    const [Content, setContent] = useState(false)
    function load() {
        // return

        Axios.get('/api/User/JobMyPostList')
            .then(res => {
                if (res.status == 200) {
                    console.log('jobView')
                    console.log(res.data)
                    // console.log(Id)

                   
                    var jobDetails = res.data.map((myList,index) => {

                        var age = Math.floor(
                            parseInt(new Date() - new Date(myList.JobSeeker_dob)) / 1000 / 60 / 60 / 24 / 365
                        );
                        return  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                        <div className="card carded shadow-sm">
                             <div className="d-flex justify-content-end">
                    <div className="dropstart">
                        <BsThreeDotsVertical className="dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"/>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                         

                         <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target={'#exampleModal'+index} >Delete</a></li>
                         <li>
                            {
                              myList.Type===1||myList.Type==="1"?
                            <Link className="dropdown-item"  to={`/job_seeker_update?k=${myList._id}`} >Edit</Link>
                              :""
                            }
                             {
                              myList.Type===2||myList.Type==="2"?
                            <Link className="dropdown-item"  to={`/job_seeker_intern_update?k=${myList._id}`} >Edit</Link>
                              :""
                            }
                             {
                              myList.Type===3||myList.Type==="3"?
                            <Link className="dropdown-item" to={`/job_seeker_fresher_update?k=${myList._id}`} >Edit</Link>
                              :""
                            }
                            </li>
                            



                        </ul>
                    </div>
                   </div>
                            {/* <div className="card-body Textuppercase">
                                <div className="clearfix mb-3">
                                    {" "}

                                    <h4 style={{
                                        'fontWeight': 700,

                                    }} className='text-center  '>{myList.JobSeeker_jobTitles?myList.JobSeeker_jobTitles:""}</h4>
                                    <h4 style={{
                                        'fontWeight': 600,
                                    }} className="text-center "> {myList.subcategory[0]?myList.subcategory[0].sub:""}| {myList.category[0]?myList.category[0].category:""}</h4>
                                  
                                </div>
                                <div className="row">
                                    <div className="entityJobs  col-12">
                                        <b>CANDIDATE </b>
                                        <p className="ms-2 JobVal ">{myList.JobSeeker_Name}</p>
                                    </div>

                                    <div className="entityJobs col-6">
                                        <b>AGE </b>
                                        <p className="ms-2 JobVal">{age}</p>
                                    </div>
                                    
                                    <div className="col-1"></div>
                                    <div className="entityJobs col-5">
                                        <b>GENDER </b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_gender}</p>
                                    </div>
                                    <div className="entityJobs col-6">
                                        <b>WORK MODE </b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_workPlace}</p>
                                    </div>
                                    <div className="col-1"></div>

                                    <div className="entityJobs col-5">
                                        <b>EMPLOYMENT TYPE </b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_empType}</p>
                                    </div>

                                    <div className="entityJobs col-6">
                                        <b>OCCUPATIONAL STATUS </b>

                                        <p className="ms-2 JobVal">{myList.JobSeeker_currentlyWorking==true?"EMPLOYED":"UNEMPLOYED"}</p>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="entityJobs col-5">
                                        <b>AVAILABLITY </b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_availability==true ||"true"?"YES":"NO"}</p>
                                    </div>
                                    <div className="entityJobs col-6">
                                        <b>EDUCATIONAL QUALIFICATION </b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_qualification?myList.JobSeeker_qualification[0].Q5_1.degree :""}&nbsp;{myList.JobSeeker_qualification?myList.JobSeeker_qualification[0].Q5_1.special :""}</p>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="entityJobs col-5    ">
                                        <b>WORK EXPERIENCE </b>
                                        <p className="ms-2 JobVal"> {myList.Type==1?myList.JobSeeker_expYears?myList.JobSeeker_expYears=="1"?myList.JobSeeker_expYears+" YEAR ":myList.JobSeeker_expYears+" YEARS ":"":""}
                                                                     {myList.Type==1?myList.JobSeeker_expMonths?myList.JobSeeker_expMonths=="1"?myList.JobSeeker_expMonths+" MONTH ":myList.JobSeeker_expMonths+" MONTHS ":"":""}
                                                                     {myList.Type==2?"Intern":""}
                                                                     {myList.Type==3?"Fresher":""}
                                                                     </p>
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <div className='text-center mt-1'>
                                        <Link to={`/job_seeker_view?k=${myList._id}`} className="btn btn-war" >VIEW CANDIDATE PROFILE</Link>
                                    </div>
                                </div>
                            </div> */}
                                    <div className="card-body">
                                    {/* Textuppercase */}
                                {/* <div className="clearfix mb-3">
                                    {" "}

                                    <h4 style={{
                                        'fontWeight': 700,

                                    }} className='text-center  '>{myList.JobSeeker_jobTitles?myList.JobSeeker_jobTitles:""}</h4>
                                    <h4 style={{
                                        'fontWeight': 600,
                                    }} className="text-center "> {myList.subcategory[0]?myList.subcategory[0].sub:""}| {myList.category[0]?myList.category[0].category:""}</h4>
                                  
                                </div> */}
                                <div className="row">
                                    <div className="entityJobs  col-12">
                                        <b>Name</b>
                                        <p className="ms-2 JobVal ">{myList.JobSeeker_Name}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Gender </b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_gender}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Age </b>
                                        <p className="ms-2 JobVal">{age}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Languages Known to Communicate Fluently </b>
                                        <p className="ms-2 JobVal">
                                        <div className="row">
                                    {myList.JobSeeker_languageFluent ?

                                      myList.JobSeeker_languageFluent                                 
                                            .map((e) => {

                                                return <div className='col'>{e}</div>

                                            }) : 'Some thing went wrong!!!'}
                                </div>
                                        </p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Category </b>
                                        <p className="ms-2 JobVal">{myList.category[0]?myList.category[0].category:''}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Sub Category </b>
                                        <p className="ms-2 JobVal">{myList.subcategory[0]?myList.subcategory[0].sub:''}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Title</b>
                                        <p className="ms-2 JobVal" >{myList.JobSeeker_jobTitles}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Job Locations</b>
                                        <p className="ms-2 JobVal">
                                        <div className="row">
                                    {myList.JobSeeker_jobLocations ?

                                      myList.JobSeeker_jobLocations                                 
                                            .map((e) => {

                                                return <div className='col'>{e}</div>

                                            }) : 'Some thing went wrong!!!'}
                                </div>
                                        </p>
                                    </div>
                                   

                                    <div className="entityJobs col-12">
                                        <b>Work Mode</b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_workPlace}</p>
                                    </div>
   

                                    <div className="entityJobs col-12">
                                        <b>Employment Type </b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_empType}</p>
                                    </div>
                                    {
                                        myList.Type==1?
                                        <>

                                  <div className="entityJobs col-12">
                                        <b>Currently (Employed / Unemployed) </b>

                                        <p className="ms-2 JobVal">{myList.JobSeeker_currentlyWorking==true?"Employed ":"Unemployed"}</p>
                                    </div>

                                    <div className="col-1"></div>
                                    <div className="entityJobs col-12">
                                        {/* <b>Available for Immediate Joining / On Notice Period </b> */}
                                        {myList.JobSeeker_availability==true ||"true"?<b>Available for Immediate Joining</b> : <b>On Notice Period</b>}
                                        <p></p>
                                    </div>

                                        
                                        </>

                                     

                                        :""
                                    }

                                    
                                    




                                   {


                                    myList.Type!=2?

                                    myList.JobSeeker_qualification.length>0?

                                        <>
                                         <div className="entityJobs col-12">
                                        <b>Degree </b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_qualification?myList.JobSeeker_qualification[0].Q5_1.degree :""}&nbsp;</p>
                                         </div>
                                         
                                         <>
                                         {myList.JobSeeker_qualification?myList.JobSeeker_qualification[0].Q5_1.special? 
                                        <div className="entityJobs col-12">
                                        <b>Specialization</b>
                                        <p className="ms-2 JobVal">{myList.JobSeeker_qualification?myList.JobSeeker_qualification[0].Q5_1.special :""}</p>
                                    </div>
                                        :""  :""}
                                         </>
                                      
                                        </>
    
                                        :''

                                    :''



                                   }
                                   
                                   {
                                    myList.Type==2?

                                    <div className="entityJobs col-12">
                                        <b>Internship period </b>

                                        <p className="ms-2 JobVal">{myList.JobSeeker_period}</p>
                                    </div>


                                    :""
                                   }
                                  

                                    

                                   

                                    {
                                        myList.JobSeeker_expMonths||myList.JobSeeker_expYears?

                                        <div className="entityJobs col-12    ">
                                        <b>Work Experience  </b>
                                        <p className="ms-2 JobVal"> 
                                                                     {myList.JobSeeker_expMonths?myList.JobSeeker_expMonths=="1"?myList.JobSeeker_expMonths+" Month ":myList.JobSeeker_expMonths+" Months ":""}

                                                                     {myList.JobSeeker_expYears?myList.JobSeeker_expYears=="1"?myList.JobSeeker_expYears+" Year ":myList.JobSeeker_expYears+" Years ":""}
                                                                     {/* {myList.Type==2?"Intern":""}
                                                                     {myList.Type==3?"Fresher":""} */}
                                                                     </p>
                                    </div>


                                        :''
                                    }
                                    <div className="col-1"></div>
                                   
                                </div>

                                <div className="mb-2">
                                    <div className='text-center mt-1'>
                                        <Link to={`/job_seeker_view?k=${myList._id}`} className="btn btn-war" >VIEW CANDIDATE PROFILE</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id={'exampleModal'+index} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered ">
                             <div class="modal-content">
                             <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Deleting Post</h5>
                            <button id="close1" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                            <div class="modal-body">
                               <h6><>Are you sure ?</></h6>
                          </div>
                     <div class="modal-footer">
               <button type="button" class="btn pro-spaces-button" data-bs-dismiss="modal">Close</button>
               <button type="button" class="btn pro-spaces-button3" onClick={(e)=>delete1(myList._id)}>Delete</button>
                   </div>
                    </div>
                   </div>
                     </div>

  
                                     </div>;
                    });
                            {/* `/home/jobseeker_view?k=${myList._id}` */}

      

                    setLoding(false)
                    setContent(jobDetails)
                }
            }).catch((err) => {
                setLoding(false);
                setError('Something went wrong!')
                console.log(err)

            })

    }
    const delete1 = async (Id)=>{
        console.log(Id)
        Axios.post('/api/User/DeletePostseeker', {Id})
        .then(res => {
            if (res.status == 200) {
                console.log('Post Delete')
                console.log(res.data)
                 load()
                 document.getElementById('close1').click()
                 notify('Post deleted',true)
    
                
            }
        }).catch((err) => {
            console.log(err.response.data)
            notify('Something went wrong')
    
        })
        
    
    }

    useEffect(() => {
        load()
      
    }, [])


    return (
        <div className="">
            {Loding ? Loader : ""}
            <div className="container-fluid my-4 p-3 ">
            {Content.length==0?<center className="mt-5"><h3>Nothing to show</h3></center>:""}
                <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-3 g-3 car">
                    {Loding ? Loader :  Content}
                </div>  </div>
                <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        

        </div>





    )
}
