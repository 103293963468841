import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from 'axios';
import Moment from "moment";
import { Deleteprofile } from '../../../Components/Delete/Deleteprofile';

export const Profiledetailsandsettings = () => {
    let navigate = useNavigate();
    const [ListAccounts1, setListAccounts1] = useState([]);

    const listALLAccounts = () => {
        axios.get("/api/User/listALLAccounts").then((res) => {
            console.log(res.data);
            setListAccounts1(res.data);
        });
    };

    useEffect(() => {
        listALLAccounts();
    }, []);


    useEffect(() => {
        let first = navigator.userAgent;
        if (first) {
            var sp = first.indexOf("Mobile");
            var sup = first.indexOf("iPad");
            var w = window.innerWidth;
            if (sup >= 0) {
                return;
            }
            if (sp >= 0) {
                document.getElementById("root").classList.remove("mobileclass");
                window.addEventListener(
                    "load",
                    function () {
                        if (w > 320)
                            setTimeout(() => {
                                let tommy = document.getElementById("Tommy");
                                tommy.children[0].innerHTML = ``;
                                tommy.children[1].innerHTML = ``;
                                tommy.classList.add("invisible");
                            }, 1);
                    },
                    false
                );
            }
        }
        return () => {
            let first = navigator.userAgent;
            if (first) {
                var sp = first.indexOf("Mobile");
                var sup = first.indexOf("iPad");
                var w = window.innerWidth;
                if (sup >= 0) {
                    return;
                }
                if (sp >= 0) {
                    document.getElementById("root").classList.add("mobileclass");
                    window.addEventListener(
                        "load",
                        function () {
                            // if(w>320)
                            setTimeout(() => {
                                let tommy = document.getElementById("Tommy");
                                tommy.classList.remove("invisible");
                                tommy.children[0].innerHTML = `Please rotate your device`;
                                tommy.children[1].innerHTML = `We don't support Landscape mode. Please switch to Portrait mode for best experience.`;
                            }, 4000);
                        },
                        false
                    );
                }
            }
        };
    });

    useEffect(() => {
        try {
            document.getElementById("Tommy").classList.add("d-none");
        } catch (error) {
            console.log(error);
        }
        return () => {
            try {
                document.getElementById("Tommy").classList.remove("d-none");
            } catch (error) {
                console.log(error);
            }
        };
    }, []);

    return (
        <>
            <div style={{ 'paddingTop': '70px' }} className="container">
                <div className='pt-3 pb-3'>
                    <h5>Profile Details and Settings</h5>
                    <p style={{ fontWeight: "400" }}>
                        Indefinite Break : You can Hide your Profile when you are on a long break for an indefinite period from work / business.
                    </p>
                    <p style={{ fontWeight: "400" }}>
                        Profile Tranfser : You can Change your profile from the existing
                        Classification (services or products) to the other.
                    </p>
                    <p>
                        <i>
                            slide to the left or switch to landscape mode to see the full
                            tabular chart.
                        </i>
                    </p>
                </div>
                <AccountList ListAccounts1={ListAccounts1} />
                <br />
                <br />
                <p>
                    Please Refer QUICK FIX (on the navigation bar) for any clarifications
                    / queries.
                </p>
                <br />
                <br />
            </div>

            <div
                className="modal fade"
                id="exampleModalforvendor"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title invisible" id="exampleModalLabel"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <h6>
                                Are you sure you want to change your Service Provider profile '
                                {localStorage.getItem("pname")}' to a Product Retailer profile ?
                            </h6>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn pro-spaces-button3"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => navigate("/convert_profile_to_products_profile")}
                            >
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="exampleModalforconsultant"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title invisible" id="exampleModalLabel"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <h6>
                                Are you sure you want to change your Product Retailer profile '
                                {localStorage.getItem("pname")}' to a Service Provider profile ?
                            </h6>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn pro-spaces-button3"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) =>
                                    navigate("/convert_profile_to_services_profile")
                                }
                            >
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function AccountList(props) {
    const { ListAccounts1 } = props;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Profile Name</TableCell>
                        <TableCell>Sub category</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Profile Classification</TableCell>
                        {/* <TableCell>Profile Type</TableCell> */}
                        <TableCell>Created&nbsp;On</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ListAccounts1.map((row, index) => (
                        <AccountsRow key={row.name + index} row={row} index={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function AccountsRow(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);
    const [up, setup] = useState(true);
    const Enable = (id, status) => {
        console.log(id, status);
        axios
            .post("/api/User/EnableChatForProfile", {
                account: id,
                status,
            })
            .then((res) => {
                row.Chat = status;
                setup(!up);
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const Visibility = (id, status) => {
        console.log(id, status);
        axios
            .post("/api/User/EnableVisibilityForProfile", {
                account: id,
                status,
            })
            .then((res) => {
                row.on_a_break = status;
                setup(!up);
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <React.Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell component="th" scope="row">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.pname}
                </TableCell>
                <TableCell>{row.string2[0]?.sub}</TableCell>
                <TableCell>{row.string[0]?.category}</TableCell>
                <TableCell>
                    {row.Type === "Consultant" ||
                        row.Type === "Consultantv2" ||
                        row.Type === "Consultantv3"
                        ? "SERVICES"
                        : row.Type === "Product" ||
                            row.Type === "Productv2" ||
                            row.Type === "Productv3"
                            ? "PRODUCTS"
                            : ""}
                </TableCell>
                {/* <TableCell>
                    {row.Type === "Consultant" || row.Type === "Product"
                        ? "Prime"
                        : row.Type === "Consultantv2" || row.Type === "Productv2"
                            ? "Basic"
                            : row.Type === "Consultantv3" || row.Type === "Productv3"
                                ? "Static"
                                : ""}
                </TableCell> */}
                <TableCell>{Moment(row.Created_on).format("DD-MM-YY")}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <div>
                                <div className="row mt-2">
                                    <div className="col-3">Indefinite Break</div>
                                    <div className="col-4">
                                        {row.on_a_break ? (
                                            <button
                                                className="btn pro-spaces-button p-0 d-flex  align-items-center justify-content-center"
                                                style={{ width: "130px" }}
                                                onClick={() => Visibility(row._id, false)}
                                            >
                                                UNHIDE PROFILE
                                            </button>
                                        ) : (
                                            <button
                                                className="btn pro-spaces-button p-0 d-flex  align-items-center justify-content-center"
                                                style={{ width: "130px" }}
                                                onClick={() => Visibility(row._id, true)}
                                            >
                                                HIDE PROFILE
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-3">Profile Transfer</div>
                                    <div className="col-4">
                                        {localStorage.getItem("User") === row._id ? (
                                            localStorage.getItem("Type") === "Consultantv3" ? (
                                                <button
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModalforvendor"
                                                    className="btn pro-spaces-button p-0 d-flex  align-items-center justify-content-center"
                                                    style={{ width: "130px" }}
                                                >
                                                    TRANSFER
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn pro-spaces-button p-0 d-flex  align-items-center justify-content-center"
                                                    style={{ width: "130px" }}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModalforconsultant"
                                                >
                                                    TRANSFER
                                                </button>
                                            )
                                        ) : (
                                            <button
                                                className="btn pro-spaces-button p-0 d-flex  align-items-center justify-content-center"
                                                style={{ width: "130px" }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalforwarning"
                                            >
                                                TRANSFER
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="row  mt-2">
                                    <div className="col-3">Profile Deletion</div>
                                    <div className="col-4">
                                    <Deleteprofile row={row}/>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal fade"
                                id="exampleModalforwarning"
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title invisible" id="exampleModalLabel"></h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        <div className="modal-body">
                                            <h6>
                                                ** To Transfer this Profile, you must first Switch to this Profile (on your Profile tab)
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
