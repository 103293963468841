import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useState } from "react";
import './recruitPostForm.css'
import Axios from 'axios'
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Insider} from "./Insider"


export const RecruiterPost = (props) => {
    useEffect(() => {
        props.Auth()
    }, [])

    const [Reset, setReset] = useState(true)
    const [Keyval, setKeyval] = useState(23)
    // const [Display, setDisplay] = useState(<Insider Reset={Reset} setReset={setReset} />)
    useEffect(() => {
        console.log(Reset)
        setKeyval(Keyval + 1)
    }, [Reset])


    return (
    <Insider key={Keyval} Reset={Reset} setReset={setReset} />
    )


   
}
