import React, { useState, useEffect, useContext, createContext } from 'react'
import { keyGen } from '../../../Utility/helper'
import AddOnField from './AddOnField'
import axios from 'axios'
import { notify } from '../../../Utility/notify'

export const SelectedSubcategoryContext = createContext()

export default function AddOn({ getval }) {
    let dlimit = localStorage.getItem('Type')=='Consultant'||localStorage.getItem('Type')=='Product'?20:10

    const [show, setShow] = useState(false);

    const [Count, setCount] = useState([])
    const [Maximum, setMaximum] = useState(dlimit)
    const [Error, setError] = useState('')
    const [Optionsub, setOptionsub] = useState([])
    useEffect(() => {
        axios
            .get("/api/User/GetListOfUserSubcategoryAccounts")
            .then((res) => {
                console.log(res.data)
                setListed((rev) => [...rev, res.data.map(ele => ele.subcategory)])
                if (res.data.length) {
                    setMaximum(dlimit - res.data.length)
                }
            }).catch((err) => {
                console.log(err)
            })

        axios
            .get('/api/api/getallcategory')
            .then(res => {
                console.log(res.data)
                let cat = res.data.filter((ele) => ele._id === localStorage.getItem('Cat'))
                console.log(cat[0])
                setOptionsub(cat[0].sub)

            })
        return () => {
        }
    }, [])

    const [subcategory, setsubcategory] = useState()
    const [Details, setDetails] = useState({
        subcategory: localStorage.getItem("Cat"),
        formType: ''
    })
    const [Disbled, setDisbled] = useState([])
    const [Addons, setAddons] = useState()

    useEffect(() => {
        let dis = []
        dis = Count.map((el) => el.subcategory)
        setAddons(Count)
        return () => {
        }
    }, [Count])
    const deleteMe = (index) => {
        let temp = [...Count]
        temp.splice(index, 1)
        setCount(temp)
        let sp = [...Listed]
        sp.splice(index, 1)
        setListed([...sp])
    }
    const [Listed, setListed] = useState([localStorage.getItem('Sub_cat')])
    const saveMe = (index, data) => {
        let temp = [...Count]
        temp[index].subcategory = data.subcategory
        temp[index].brand = data.Brand
        temp[index].brandOn = data.BrandOn
        setCount(temp)
        setListed([...Listed, data.subcategory])
    }
    const Addmore = () => {
        console.log({ Disbled, Maximum })
        if (Disbled.length >= Maximum) {
            return
        }
        if (validator(Count)) {
            setCount([...Count, { id: keyGen() }])
        }
    }
    const validator = () => {
        try {
            for (const key of Count) {
                if (!key.subcategory || key.subcategory?.length === 0) {
                    setError('PLEASE SELECT A Sub category')
                    return false
                }
                if (key.brandOn === true && key.brand.length == 0) {
                    setError('PlEASE SELECT A Brand')
                    return false
                }
            }
            setError('')
            return true
        } catch (error) {
            console.log(error)
        }
    }

    const submit = () => {
        console.log(Count);
        if (validator(Count)) {
            axios.post('/api/ActiveUser/multipleAc', { Count, getval }).then((res) => {
                console.log(res.data)
                setCount([])
                document.getElementById('buttoncloseforaddantherv2').click()
                notify('We are processing your request', true)
            }).catch((err) => {
                console.log(err);
            })
        }
    }
    return (
        <SelectedSubcategoryContext.Provider value={{ Optionsub, Listed }}>
            <div className='container' >
                <p>
                    If you wish to create profiles in multiple sub categories within the same category using the same information, Click on “Add another Sub category”. This is if your business deals with more than one type of product / commodity.
                </p>
                <></>
                <>
                    {Count.map((elem, index) => {
                        if (index < Maximum) {
                            return <AddOnField key={elem.id} id={elem.id} index={index} deleteMe={deleteMe} saveMe={saveMe} />
                        }
                    })}
                </>
                <p>{Error}</p>
                <div className='text-center p-1' >
                    <button type='button' onClick={(e) => Addmore()} disabled={Count.length >= Maximum} className='btn pro-spaces-button-semi ps-3 pe-3 mt-2' >
                        Add another Sub Category
                    </button>
                    <></>
                </div>
                <p className='text-danger' >{Count.length >= Maximum ? 'You have created maximum profiles' : ''} </p>
                <button onClick={(e) => submit()} className="btn pro-spaces-button3 float-end mt-4">Create</button>
            </div>
        </SelectedSubcategoryContext.Provider>
    )
}
