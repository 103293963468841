import { useEffect, useState, useRef } from "react"
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { AiFillExclamationCircle } from 'react-icons/ai'
import { AiOutlineCloseCircle } from 'react-icons/ai'


export default function Dsi(props) {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    // const [check, setcheck] = useState(false)
    console.log(props)
    const [Data, setData] = useState({
        serviceTitle: props.dsi ? props.dsi[props.index] ? props.dsi[props.index].serviceTitle ? props.dsi[props.index].serviceTitle : '' : '' : '',
        serviceDescription: props.dsi ? props.dsi[props.index] ? props.dsi[props.index].serviceDescription ? props.dsi[props.index].serviceDescription : '' : '' : '',
        serviceCharge: props.dsi ? props.dsi[props.index] ? props.dsi[props.index].serviceCharge ? props.dsi[props.index].serviceCharge : '' : '' : '',
        paymentTerms: props.dsi ? props.dsi[props.index] ? props.dsi[props.index].paymentTerms ? props.dsi[props.index].paymentTerms : '' : '' : '',
        // serviceKeywords: props.dsi ? props.dsi[props.index] ? props.dsi[props.index].serviceKeywords ? props.dsi[props.index].serviceKeywords : '' : '' : '',
    })
    let SpecializationRef = props.SpecializationRef

    useEffect(() => {
        props.handleDSI(Data, props.index)
        console.log(Data)
    }, [Data])
    // useEffect(() => {
    //     try {
    //         if (check) {
    //             setData((dat) => ({ ...dat, serviceKeywords: SpecializationRef.current.value }))
    //         }
    //     } catch (error) {

    //     }


    // }, [check])


    return <fieldset className=" ">
        {/* {props.id} */}
        {props.index != 0 ?
            <div className="w-100">
                <AiOutlineCloseCircle size={25} className='float-end pe-1' onClick={(e) => props.handleDSI('remove', props.index)} style={{ "cursor": 'pointer' }} ></AiOutlineCloseCircle>
            </div>
            : <></>}
        <div className="col-md-12 ">
            <label className="labels w-100 mb-2">{props.changersab ? 'Facility / Service Title' : 'Service Title'}</label>
            <input className="form-control" value={Data.serviceTitle} onChange={(e) => setData((prev) => ({
                ...prev, serviceTitle: e.target.value
            }))} type={'text'} name='serviceTitle' />
        </div>
        <div className="col-md-12">
            <label className="labels">{props.changersab ? 'Facility / Service Description' : 'Service Description'}</label>
            <textarea onChange={(e) => setData((prev) => ({
                ...prev, serviceDescription: e.target.value
            }))} className="form-control" type={'text'} name='serviceTitle' >{Data.serviceDescription}</textarea>
        </div>
        <div className="col-md-12">
            <label className="labels">Service Fees / Charges</label>
            <input onChange={(e) => setData((prev) => ({
                ...prev, serviceCharge: e.target.value
            }))} className="form-control" type={'number'} value={Data.serviceCharge} name='serviceTitle' />
        </div>
        <div className="col-md-12">
            <label className="labels">Payment Terms</label>
            <input onChange={(e) => setData((prev) => ({
                ...prev, paymentTerms: e.target.value
            }))} className="form-control" type={'text'} value={Data.paymentTerms} name='serviceTitle' />
        </div>
        {/* {props.keyword?<></>:
        <div className="col-md-12">
            <label className="labels" ref={target} onClick={() => setShow(!show)}>Service Keyword Search Preferences
                &nbsp;<AiFillExclamationCircle type="button" style={{ 'marginTop': '-3px', 'color': '#55374a' }}
                /></label>
            <label className="labels"></label>
            <input onChange={(e) => setData((prev) => ({
                ...prev, serviceKeywords: e.target.value
            }))} className="form-control" type={'text'} value={Data.serviceKeywords} name='serviceTitle' />

            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <Tooltip id="overlay-example" {...props} placement="top">
                        Mention search words that can help your prospective clients / customers find you easily
                    </Tooltip>
                )}
            </Overlay>
        </div>
        } */}
        {/* 
        <div class="form-check">
            <input onChange={(e) => setcheck( e.target.checked)} class="form-check-input" type="checkbox" value="" id="flexCheckChecrrrked" />
            <label className="labels"  for="flexCheckChecrrrked">
                I would like to add Specializations also as keywords
            </label>
        </div> */}
        <hr />
    </fieldset>

}