import React from "react";
// import {NewNavigationBar} from "../../Pages/Free/NewNavigationBar/NewNavigationBar"

export function Disclimer() {
  return (
    <div>
      {/* <NewNavigationBar /> */}
      <div className="container notranslate" style={{ paddingTop: "90px" }}>
        <div className="d-flex justify-content-end ">
          {/* <button onClick={(e)=>window.history.back()} className='btn pro-spaces-button  mt-3' >Go Back</button> */}
        </div>

        <div className="pos pb-2">
          <span id="_16.7" style={{ color: "#000000" }}>
            <h4>
              <b>
                <u>
                  <center>DISCLAIMER</center>
                </u>
              </b>
            </h4>
          </span>
        </div>
        <br />
        <div className="pos" id="_100:145" style={{ top: 145, left: 100 }}>
          <span id="_18.8" style={{ fontSize: "16px", color: "#000000" }}>
            By visiting our website or apps or other platforms provided by us
            (“Service”), you (“User”) agree to be bound by this disclaimer and
            the terms and conditions as stated herein below.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:246" style={{ top: 246, left: 100 }}>
          <span id="_19.9" style={{ fontSize: "16px", color: "#000000" }}>
            The User understands that the User is free to not accept these
            disclaimers and in such an event, the User is advised not to use our
            services in any manner. Use and access of this Website/app by a User
            in any manner shall constitute an irrevocable acceptance and the
            User shall be bound by the following terms and conditions:
          </span>
        </div>
        <br />
        <div className="pos" id="_125:406" style={{ top: 406, left: 125 }}>
          <ul>
            <li>
              <span id="_16.0" style={{ fontSize: "16px", color: "#000000" }}>
                The materials and/or information available or obtained
                at/through the Website/app is/are not guaranteed or warranted in
                the terms of completeness. Correctness, accuracy, reliability or
                otherwise howsoever by{" "}
                <span className=" monotypepolicies">Vipar</span>, a Proprietary
                concern, having its registered office at #203, Abhiman Hills,
                Light House Hill road, Hampankatta, Mangalore - 575001 ("
                <span className=" monotypepolicies">Vipar</span>")and/or its
                affiliates or its directors or employees.
              </span>
            </li>
            <li>
              <span id="_16.0" style={{ fontSize: "16px", color: "#000000" }}>
                Placing any reliance on this Website/app or any material
                available through this Website/app, is at the sole risk of the
                User. <span className=" monotypepolicies">Vipar</span> shall not
                be held responsible for any detrimental reliance the User places
                on this Website/app or its content. It is agreed that the User
                shall use this website on an "as is" basis and entirely at the
                User’s own risk.
              </span>
            </li>
            <li>
              <span id="_16.0" style={{ fontSize: "16px", color: "#000000" }}>
                Placing any reliance on this Website/app or any material
                available through this Website/app, is at the sole risk of the
                User. <span className=" monotypepolicies">Vipar</span> shall not
                be held responsible for any detrimental reliance the User places
                on this Website/app or its content. It is agreed that the User
                shall use this website on an "as is" basis and entirely at the
                User’s own risk.
              </span>
            </li>
            <li>
              <span id="_16.0" style={{ fontSize: "16px", color: "#000000" }}>
                This website/app is not intentionally designed for or directed
                at persons below the age of 18 years, and{" "}
                <span className=" monotypepolicies">Vipar</span> will not
                intentionally collect or maintain information about anyone under
                the age of 18.
              </span>
            </li>
            <li>
              <span id="_16.0" style={{ fontSize: "16px", color: "#000000" }}>
                <li>
                  <span
                    id="_16.0"
                    style={{ fontSize: "16px", color: "#000000" }}
                  >
                    <span className=" monotypepolicies">Vipar</span> shall not
                    be responsible for any disputes or disagreements between the
                    User and any third party the User interacts with using the
                    Site/app. The User shall assume all risk associated with
                    dealing with third parties. The User agrees to resolve
                    disputes directly with the other party. The User also agrees
                    to release <span className=" monotypepolicies">Vipar</span>{" "}
                    of all claims, demands, and damages in disputes among users
                    of the Site/app. The User also agree not to involve{" "}
                    <span className=" monotypepolicies">Vipar</span> in any such
                    disputes. Apart from the above{" "}
                    <span className=" monotypepolicies">Vipar</span> makes no
                    promises and disclaim all liability of specific results from
                    the use of the Site.
                  </span>
                </li>
                <li>
                  <span
                    id="_16.0"
                    style={{ fontSize: "16px", color: "#000000" }}
                  >
                    Certain links in this Website connect to other sites
                    maintained by third parties over whom{" "}
                    <span className=" monotypepolicies">Vipar</span> has no
                    control.
                    <span className=" monotypepolicies">Vipar</span> makes no
                    representations as to the accuracy or any other aspect of
                    information contained in other Web Sites.
                    <span className=" monotypepolicies">Vipar</span> provides
                    such links to the User only as a convenience and the
                    inclusion of any link does not imply endorsement by
                    <span className=" monotypepolicies">Vipar</span> of such
                    site.
                  </span>
                </li>
                <li>
                  <span
                    id="_16.0"
                    style={{ fontSize: "16px", color: "#000000" }}
                  >
                    In no event shall{" "}
                    <span className=" monotypepolicies">Vipar</span> or its
                    affiliates or its agents or any other party involved in
                    creating, producing, or delivering this Website/app be
                    liable for any direct, indirect, punitive, incidental,
                    special, consequential damages (including lost revenues or
                    profits, loss of business or loss of data) or any damages
                    whatsoever connected with the use or performance of the
                    Website/app, with the delay or inability to use the
                    Website/app or related services, the provision or failure to
                    provide services, or for any information, products, services
                    and other related contents obtained through the Website/app,
                    or otherwise arising out of the use of the Website/app,
                    whether based on contract, tort, negligence, strict
                    liability or otherwise.
                  </span>
                </li>
                <li>
                  <span
                    id="_16.0"
                    style={{ fontSize: "16px", color: "#000000" }}
                  >
                    <li>
                      <span
                        id="_16.0"
                        style={{ fontSize: "16px", color: "#000000" }}
                      >
                        <span className=" monotypepolicies">Vipar</span>{" "}
                        reserves the right to make changes in the website/app,
                        withdraw access to this website/app, Termination of
                        accounts and revise the services described in this
                        website at any time without any previous notice. Any
                        rights not expressly granted herein are reserved.
                      </span>
                    </li>
                  </span>
                </li>
              </span>
            </li>
          </ul>
        </div>
        <br />
      </div>
    </div>
  );
}
