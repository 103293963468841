import React, { useState, useEffect, useContext, useRef } from "react";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { AiFillExclamationCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { AiFillCamera } from 'react-icons/ai'
import { Port } from "../port";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios'
import { print_state, print_city, state_arr } from '../../../Utility/stateCity'

import { CatsContext } from '../FormProduct'


import { ConvertContext } from '../FormProduct'


import { EditContext } from '../FormProduct'

import AddOn from "../AddOn";



import { keyGen } from "../../../Utility/helper";
import MapWithSwitch from "../../ProMaps/MapWithSwitch";
import MobileAddOn from "../../MobileAddOn/mobileAddOn";
import { MenuProps, languages, arrayofdays, arrayofdays2 } from '../../../Utility/languages'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { TimeSelectBox } from "../../../Utility/timer";
import { Selfdeclaration } from "../../Selfdeclaration/Selfdeclaration";
import { FormHelperText } from "@mui/material";
import { StateCity } from "../../CreateConseltent/StateCity";
import { useSearchParams } from "react-router-dom";
import { Partners } from "../../FormComponents/Partners";


export function CreateProductA(props) {
    const [CONCount, setConCount] = useState([keyGen()])

    // const Edit = useContext(EditContext);
    const Edit = useContext(EditContext);
    const convert = useContext(ConvertContext);
    const cats = useContext(CatsContext);
    const [PartnerCount, setPartnerCount] = useState([keyGen()])

    let x = [...Array(100).keys()]
    // const cats = useContext(CatsContext);
    // const convert = useContext(ConvertContext);

    const [Submit2, setSubmit2] = useState(false);
    const formData = new FormData()
    const [AddressCount, setAddressCount] = useState([])
    const [value, setValue] = useState({
        id: '',
        firstname: '',
        contact: "",
        contact2: "",
        EntityName: "",
        contact3: "",
        contact4: "",
        email: '',
        address_door: "",
        address_floor: "",
        address_building: "",
        address_nearest: "",
        address_street: "",
        worktimimng: false,
        website: '',
        category: cats.value.category,
        subcategory: cats.value.subcategory,
        Brand: cats.value.Brand,
        brandlength: '',
        address: '',
        country: "",
        state: '',
        city: '',
        pincode: '',
        licence: '',
        image: '',
        yearofb: '',
        gst: '',
        who: '',
        TypeofStore: '',
        pname: '',
        WomanOwned: false,
        brandName: '',
        profileType: '',
        lastname: '',
        gender: '',
        formType: props.formType,
        keyword: '',
        portfolios: [],
        preferredlanguage: "",
        working_leave: "",
        working_hour_2: "",
        working_hour_1: "",
        working_hour_3: "",
        working_hour_4: "",
        working_days_2: "",
        working_days_1: "",
        working_days_3: "",
        working_days_4: "",
        countrycode: "",
        statecode: "",
        contactpersonsname: "",
        contactpersonsdesignation: "",
        contactpersonsname2: "",
        contactpersonsdesignation2: "",
        preferredlanguage2: "",
        contactpersonsname3: "",
        contactpersonsdesignation3: "",
        preferredlanguage3: "",
        contactpersonsname4: "",
        contactpersonsdesignation4: "",
        preferredlanguage4: "",
        workingdaysfullnew: [],
        workingdayshalfnew: [],
        workingdaysOffnew: [],
        break_time_2: '',
        break_time_1: '',
        brandNamenew: '',
        brandtagline: '',
        taglineCheck: false,
        typeoffirm: '',

    })
    // addon
    const [Addons, setAddons] = useState([])

    const validatorAddon = (Count) => {
        try {
            for (const key of Count) {
                if (!key.subcategory || key.subcategory?.length === 0) {
                    setError('PLEASE SELECT A Subcategory')
                    return false
                }
                if (key.brandOn === true && key.brand.length == 0) {
                    setError('PlEASE SELECT A Brand')
                    return false
                }
            }
            setError('')
            return true
        } catch (error) {
            console.log(error)
        }
    }
    // addon
    useEffect(() => {
        setValue({ ...value, subcategory: cats.value.subcategory, category: cats.value.category, Brand: cats.value.Brand })
        return () => {

        };
    }, [cats])

    const [error, setError] = useState('')
    const [Submit, setSubmit] = useState(false)
    const [image, setImage] = useState();
    const [cropData, setCropData] = useState(false);
    const [cropper, setCropper] = useState();
    const [Stateload, setStateload] = useState(true)
    const [cat, setCat] = useState([])
    const [category, setCategory] = useState(false)
    const [subcategory, setSubcategory] = useState(false);
    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex
        print_city('City', index)
        setValue({ ...value, state: e.target.value })
    }
    const onChange = async (e) => {
        var myModal = new bootstrap.Modal(document.getElementById('profileUpdation'))
        myModal.show()
        var myModalEl = document.getElementById('profileUpdation')
        myModalEl.addEventListener('shown.bs.modal', function (event) {
            cropData ? setCropData(false) : <></>
            e.preventDefault();
            let files;
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }
            if (files[0]) {
                const reader = new FileReader();
                reader.onload = () => {
                    setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);
            }
            e.target.value = null
        })

        var x = document.getElementById('profileUpdation').offsetWidth
        // var s=document.getElementById('profileUpdation')
    };

    const [imageinserted, setimageinserted] = useState(false)
    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            // setCropData(cropper.getCroppedCanvas().toDataURL());
            setimageinserted(true)
            setCropData()
            cropper.getCroppedCanvas().toBlob((blob) => {
                setCropData(URL.createObjectURL(blob))
                setnewb(blob)
            }, 'image/jpeg', 0.5)
        }
    };
    useEffect(() => {
        setValue({ ...value, image: cropData })
    }, [cropData])
    const uploadme = () => {
        var DP = document.getElementById('DP')
        DP.src = cropData
    }
    const photo = () => (
        document.getElementById('image').click()
    )

    const [selfDeclaration, setSelfDeclaration] = useState(false)


    function validation() {
        if (cats.brand.length > 0) {
            if (value.Brand === undefined) {
                return setError('PLEASE SELECT A BRAND')
            }
            if (value.Brand.length === 0) {
                return setError('PLEASE SELECT A BRAND')
            }
        }
        for (const element of addressBranch) {
            if (value.address_floor.length == 0) {
                return setError("PLEASE SELECT Floor for branch");
            }
            if (value.address_building.length == 0) {
                return setError("PLEASE ENTER Building / Mall / Property Name for branch");
            }
            if (value.address_street.length == 0) {
                return setError("PLEASE ENTER Street / Lane / Road Name for branch");
            }
            if (element.address1.length === 0) {
                return setError('PLEASE ENTER Area / Locality Name for branch')
            }
            if (element.state.length === 0) {
                return setError('PLEASE ENTER STATE for branch')
            }
            if (element.city.length === 0) {
                return setError('PLEASE ENTER CITY for branch')
            }
            if (element.pincode.length === 0) {
                return setError('PLEASE ENTER PINCODE for branch')
            }
            if (element.working_days_1.length === 0) {
                return setError('PLEASE ENTER Working Days (full) for branch')
            }
            if (element.working_days_2.length === 0) {
                return setError('PLEASE ENTER Working Days (full) for branch')
            }
            if (element.working_hour_1.length === 0) {
                return setError('PLEASE ENTER Working Hours for branch')
            }
            if (element.working_hour_1.length === 0) {
                return setError('PLEASE ENTER Working Hours for branch')
            }
            if (element.working_days_3.length === 0) {
                return setError('PLEASE ENTER Working Days (half) for branch')
            }
            if (element.working_days_4.length === 0) {
                return setError('PLEASE ENTER Working Days (half) for branch')
            }
            if (element.working_hour_3.length === 0) {
                return setError('PLEASE ENTER Working Hours for branch')
            }
            if (element.working_hour_4.length === 0) {
                return setError('PLEASE ENTER Working Hours for branch')
            }
            if (element.working_leave.length === 0) {
                return setError('PLEASE ENTER Weekly Off for branch')
            }

        }

        if (value.category.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Category");
        }
        if (value.subcategory.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Sub Category");
        }

        if (!Edit) {

            if (cropData) {
                var imgsize = cropData.length - 22;
                var imgsizeinkb =
                    (4 * Math.ceil(imgsize / 3) * 0.5624896334383812) / 1000;
                if (cropData.length > 0) {
                    setError();
                } else {
                    return setError("Photo size is greater than 2MB");
                }
            } else {
                return setError("PLEASE upload PROFILE DISPLAY PICTURE");
            }

        }




        if (value.pname.length === 0) {
            return setError("PLEASE ENTER PROFILE DISPLAY NAME")
        }
        else {
            setError("");
        }

        if (value.firstname.length === 0) {
            return setError("PLEASE ENTER First Name")
        } else {
            setError("");
        }
        if (value.lastname.length === 0) {
            return setError("PLEASE ENTER Last Name")
        } else {
            setError("");
        }

        if (value.who.length == 0) {
            return setError('PLEASE ENTER Type of Vendor / Business')
        } else {
            setError('')
        }
        if (value.TypeofStore.length == 0) {
            return setError('PLEASE ENTER Type of Entity')
        } else {
            setError('')
        }
        if (value.yearofb.length <= 0) {
            return setError('PLEASE ENTER Year of Business Inception')
        } else {
            setError('')
        }
        if (value.licence.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Do you have a Trade Licence ?");
        }

        if (value.gst.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Is your entity GST registered ?");
        }

        if (value.address_building.length == 0) {
            return setError("PLEASE ENTER Building / Mall / Property Name ");
        } else {
            setError("");
        }
        if (value.address_floor.length == 0) {
            return setError("PLEASE SELECT Floor");
        } else {
            setError("");
        }
        if (value.address_street.length == 0) {
            return setError("PLEASE ENTER Street / Lane / Road Name ");
        } else {
            setError("");
        }
        if (value.address.length == 0) {
            return setError("PLEASE ENTER Area / Locality Name ");
        } else {
            setError("");
        }


        if (!value.state) {
            return setError('PLEASE ENTER state')
        }
        if (!value.city) {
            return setError('PLEASE ENTER city')
        }
        if (value.pincode.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Pin code / Zip code ");
        }

        if (!value.worktimimng) {
            if (value.workingdaysfullnew.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Days (full)");
            }

            if (value.working_hour_1.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Hours");
            }

            if (value.working_hour_2.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Hours");
            }
            if (value.break_time_1.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Break Time");
            }
            if (value.workingdayshalfnew.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Days (half)");
            }

            if (value.working_hour_3.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Hours");
            }

            if (value.working_hour_4.length > 0) {
                setError("");
            } else {
                return setError("PLEASE ENTER Working Hours");
            }
        }

        if (value.workingdaysOffnew.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Weekly Off");
        }


        if (value.countrycode.length > 0) {
            setError("");
        } else {
            return setError("PLEASE SELECT Country Code");
        }
        // if (value.statecode.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE SELECT State Code");
        // }


        for (const element of CON) {
            if (CON.indexOf(element) === 0) {
                if (element.contact2.length < 6 || element.contact2.length > 12) {
                    return setError('PLEASE ENTER VALID A Mobile Number / Landline Number')
                }
                if (element.contactpersonsname2.length == 0) {
                    return setError(`PLEASE ENTER Contact Person’s Name`)
                }
                if (element.contactpersonsdesignation2.length == 0) {
                    return setError(`PLEASE ENTER Contact Person’s Designation`)
                }
                if (element.preferredlanguage2.length == 0) {
                    return setError(`PLEASE SELECT A Most Comfortable / Preferred Language for Communication for ${element.contact2}`)
                }
            }
        }

        if (value.email.length > 0) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.email)) {
                setError('')
            }
            else {
                return setError('PLEASE ENTER a valid Email Id')
            }
        }
        else {
            return setError('PLEASE ENTER Email Id')
        }



        if (convert == false) {
            if (props.formType === 1) {
                if (value.portfolios.length > 0) {
                    setError("");
                } else {
                    return setError("Please ADD Catalogue, Upload atleast 5 images of your products. Your prospective customers would like to see the types of products you are offering inorder to short-list / contact you.");

                }
            }
        } else {

            setError("");

        }

        // if (value.preferredlanguage.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE SELECT Most Comfortable / Preferred Language for Communication");
        // }




        if (validatorAddon(Addons)) {

        } else {
            return false
        }

        if (!selfDeclaration) {
            return setError("PLEASE CHECK the self declaration above !");
        }


        return true
    }

    useEffect(() => {
        if (Stateload)
            print_state("State")
        setStateload(false)
    }, [])
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [newb, setnewb] = useState()
    const submit = async (e) => {
        e.preventDefault();
        formData.delete('file')
        formData.delete('data')
        let loc = false
        if (Location.lng) {
            loc = true
        }
        var dataSend = JSON.stringify({ value, addressBranch, Location, LocationOn: loc, Addons, CON, Partner })

        let metadata = {
            type: 'image/jpeg'
        };
        if (newb) {
            let file = new File([newb], "test.jpg", metadata);
            formData.append("file", file);
        }
        formData.append("data", dataSend);
        var status = false
        setSubmit(true)
        status = validation()
        if (status == true) {
            setloading(true)
            let a = await props.postTo(formData)
            if (!a) {
                setloading(false)
            }
            // axios.post('/api/accounts/register/registerproduct', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     }
            // }).then(res => {
            //     if (res.status == 200) {
            //         console.log('product Created')
            //         // console.log(res.data)
            //         return navigate('/payment')
            //     }
            // }).catch((err) => {
            //     console.log(err.response.data)
            // })
        }
    }
    useEffect(() => {
        if (Submit) {
            validation()
        }
    }, [value, selfDeclaration])
    const [Partner, setPartner] = useState([])

    const [addressBranch, setaddressBranch] = useState([])

    const [CON, setCON] = useState([])

    const handleCON = (e, index) => {
        try {
            console.log({ e, index }, { CON });
            let CONcollection = CON
            if (e === 'remove') {
                CONcollection.splice(index, 1)
                let dsicount = CONCount.slice()
                let c = dsicount.splice(index, 1)
                setConCount(dsicount)
            } else {
                CONcollection[index] = e
            }
            setCON(CONcollection)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePartner = (e, index) => {
        try {
            console.log({ e, index }, { Partner });
            let CONcollection = Partner
            if (e === 'remove') {
                CONcollection.splice(index, 1)
                let dsicount = PartnerCount.slice()
                let c = dsicount.splice(index, 1)
                setPartnerCount(dsicount)
            } else {
                CONcollection[index] = e
            }
            setPartner(CONcollection)
        } catch (error) {
            console.log(error)
        }
    }


    const handleaddressBranch = (e, index) => {
        try {
            let addressBranchcollection = addressBranch
            if (e === 'remove') {
                addressBranchcollection.splice(index, 1)
                let dsicount = AddressCount.slice()
                let c = dsicount.splice(index, 1)
                setAddressCount(dsicount)
            } else {
                addressBranchcollection[index] = e
            }
            setaddressBranch(addressBranchcollection)
        } catch (error) {
            console.log(error)
        }

    }


    useEffect(() => {
        axios
            .get("/api/api/getprocategory")
            .then((res) => {
                if (res.status === 200) {
                    setCat(res.data);
                }
            })
            .catch((err) => {
                console.log(err.response.status);
            });
    }, []);

    var sab = [];
    useEffect(() => {
        if (cat.length >= 1) {
            cat.forEach((element) => {
                sab.push(<option value={element._id}>{element.category}</option>);
            });
            setCategory(sab);
        } else {
            console.log("nothing");
        }
    }, [cat]);
    useEffect(() => {
        if (value.profileType === 'Enterprise') {
            setValue({ ...value, firstname: '' })
            setValue({ ...value, lastname: '' })
            setValue({ ...value, gender: '' })
        }
        if (value.profileType === 'Individual') {
            setValue({ ...value, firstname: '' })
            setValue({ ...value, WomanOwned: false })
            setValue({ ...value, brandName: '' })
        }
    }, [value.profileType])

    const [Location, setLocation] = useState({ lat: '', lng: '' })
    const [LocationOn, setLocationOn] = useState()




    useEffect(() => {
        if (Edit) {


            setCON(Edit[0].CON ? Edit[0].CON : [])

            setPartner(Edit[0].Partner ? Edit[0].Partner : [])

            setaddressBranch(Edit[0].addressBranch ? Edit[0].addressBranch : [])
            setValue({
                ...value,
                id: Edit[0]._id,
                pname: Edit[0].pname ? Edit[0].pname : '',
                firstname: Edit[0].firstname,
                EntityName: Edit[0].EntityName ? Edit[0].EntityName : "",
                email: Edit[0].email,
                website: Edit[0].website,
                address_door: Edit[0].address_door ? Edit[0].address_door : '',
                address_floor: Edit[0].address_floor ? Edit[0].address_floor : '',
                address_building: Edit[0].address_building ? Edit[0].address_building : '',
                address_nearest: Edit[0].address_nearest ? Edit[0].address_nearest : '',
                address_street: Edit[0].address_street ? Edit[0].address_street : '',
                address: Edit[0].address ? Edit[0].address : '',
                country: Edit[0].country,
                state: Edit[0].state,
                city: Edit[0].city ? Edit[0].city : '',
                pincode: Edit[0].pincode ? Edit[0].pincode : '',
                licence: Edit[0].licence,
                image: Edit[0].image,
                yearofb: Edit[0].yearofb,
                gst: Edit[0].gst ? Edit[0].gst : '',
                who: Edit[0].who ? Edit[0].who : '',
                pname: Edit[0].pname,
                TypeofStore: Edit[0].TypeofStore,
                WomanOwned: Edit[0].WomanOwned,
                brandName: Edit[0].brandName,
                profileType: Edit[0].profileType,
                lastname: Edit[0].lastname,
                gender: Edit[0].gender,
                keyword: Edit[0].keyword ? Edit[0].keyword : '',
                addressBranch: Edit[0].addressBranch ? Edit[0].addressBranch : [],
                working_leave: Edit[0].working_leave ? Edit[0].working_leave : '',
                working_hour_2: Edit[0].working_hour_2 ? Edit[0].working_hour_2 : '',
                working_hour_1: Edit[0].working_hour_1 ? Edit[0].working_hour_1 : '',
                working_days_2: Edit[0].working_days_2 ? Edit[0].working_days_2 : '',
                working_days_1: Edit[0].working_days_1 ? Edit[0].working_days_1 : '',
                working_hour_3: Edit[0].working_hour_3 ? Edit[0].working_hour_3 : '',
                working_hour_4: Edit[0].working_hour_4 ? Edit[0].working_hour_4 : '',
                working_days_3: Edit[0].working_days_3 ? Edit[0].working_days_3 : '',
                working_days_4: Edit[0].working_days_4 ? Edit[0].working_days_4 : '',
                countrycode: Edit[0].countrycode ? Edit[0].countrycode : '',
                statecode: Edit[0].statecode ? Edit[0].statecode : '',
                CON: Edit[0].CON ? Edit[0].CON : [],
                Partner: Edit[0].Partner ? Edit[0].Partner : [],
                typeoffirm: Edit[0].typeoffirm ? Edit[0].typeoffirm : '',
                brandNamenew: Edit[0].brandNamenew ? Edit[0].brandNamenew : '',
                brandtagline: Edit[0].brandtagline ? Edit[0].brandtagline : '',
                taglineCheck: Edit[0].taglineCheck ? Edit[0].taglineCheck : false,

                contact: Edit[0].contact ? Edit[0].contact : '',
                contactpersonsname: Edit[0].contactpersonsname ? Edit[0].contactpersonsname : '',
                contactpersonsdesignation: Edit[0].contactpersonsdesignation ? Edit[0].contactpersonsdesignation : '',
                preferredlanguage: Edit[0].preferredlanguage ? Edit[0].preferredlanguage : '',

                contact2: Edit[0].contact2 ? Edit[0].contact2 : '',
                contactpersonsname2: Edit[0].contactpersonsname2 ? Edit[0].contactpersonsname2 : '',
                contactpersonsdesignation2: Edit[0].contactpersonsdesignation2 ? Edit[0].contactpersonsdesignation2 : '',
                preferredlanguage2: Edit[0].preferredlanguage2 ? Edit[0].preferredlanguage2 : '',

                contact3: Edit[0].contact3 ? Edit[0].contact3 : '',
                contactpersonsname3: Edit[0].contactpersonsname3 ? Edit[0].contactpersonsname3 : '',
                contactpersonsdesignation3: Edit[0].contactpersonsdesignation3 ? Edit[0].contactpersonsdesignation3 : '',
                preferredlanguage3: Edit[0].preferredlanguage3 ? Edit[0].preferredlanguage3 : '',

                contact4: Edit[0].contact4 ? Edit[0].contact4 : '',
                contactpersonsname4: Edit[0].contactpersonsname4 ? Edit[0].contactpersonsname4 : '',
                contactpersonsdesignation4: Edit[0].contactpersonsdesignation4 ? Edit[0].contactpersonsdesignation4 : '',
                preferredlanguage4: Edit[0].preferredlanguage4 ? Edit[0].preferredlanguage4 : '',

            })

            if (Edit[0].WomanOwned) {
                document.getElementById('Enterprise').checked = true
            }

            if (Edit[0].TypeofStore == 'Physical') {
                document.getElementById('flexRadioDefault1dsTypeofStore1').checked = true
                //   console.log("flexRadioDefault1fd1")

            }
            if (Edit[0].TypeofStore === 'E-commerce') {
                document.getElementById('flexRadioDefault1sdTypeofStore2').checked = true
                //   console.log('online')

            }
            if (Edit[0].TypeofStore === 'Both') {
                document.getElementById('flexRadioDefault1fdTypeofStore3').checked = true
                //   console.log("both")

            }



            if (Edit[0].who === 'Retailers') {
                document.getElementById('flexRadioDefault1ds').checked = true
                //   console.log("both")

            }
            if (Edit[0].who === 'Wholesalers') {
                document.getElementById('flexRadioDefault1sd3333').checked = true
                //   console.log("both")

            }
            if (Edit[0].who === 'Both') {
                document.getElementById('flexRadioDefault1fd4').checked = true
                //   console.log("both")

            }






            if (Edit[0].state.length >= 1) {

                var index = state_arr.indexOf(Edit[0].state)
                // var index = 2
                print_city('City', index + 1)
            }
            let demi = []
            if (Edit[0].addressBranch) {
                Edit[0].addressBranch.forEach(element => {
                    demi = [...demi, keyGen()]
                });
                setAddressCount(demi)
            }
            let come = []
            if (Edit[0].Partner) {
                Edit[0].Partner.forEach(element => {
                    come = [...come, keyGen()]
                });
                setPartnerCount(come)
            }
            let com = []
            if (Edit[0].CON) {
                Edit[0].CON.forEach(element => {
                    com = [...com, keyGen()]
                });
                setConCount(com)
            }
            if (Edit[0].Location) {
                setLocationOn(Edit[0].Location)
                setLocation(Edit[0].Location_Coordinates)
            }


        }
        return () => {

        }
    }, [])


    const [loading, setloading] = useState(false)






    // var bucketName='https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/profile'
    var bucketName = 'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/profile'

    const isOptionDisabled = (name, value) => {
        // Add your condition to disable multiple options here
        // console.log(name)
        if (value.length > 0) {
            if (value.indexOf('Nil') >= 0) {
                return name === 'Monday' || name === 'Tuesday' || name === "Wednesday" || name === "Thursday" || name === "Friday" || name === "Saturday" || name === "Sunday";
            } else {
                return name === 'Nil'
            }
        }
    };


    let pnameRef = useRef()
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("superlink")) {
            let mType = searchParams.get("mType")
            let uType = searchParams.get("uType")
            var category = searchParams.get("category")
            let subcategory = searchParams.get("subcategory")
            let profileName = searchParams.get("profileName")
            let country = searchParams.get("country")
            let state = searchParams.get("state")
            let city = searchParams.get("city")
            // setValue((prev) => ({ ...prev,}))
            setValue((prev) => ({ ...prev, pname: profileName, country: country, state: state, city: city }))
            if (profileName.length) {
                pnameRef.current.disabled = true
            }
        }
        return () => {
        }
    }, [])


    return (
        <div className="container rounded bg-white pt-5 pb-5">
            <form onSubmit={(e) => submit(e)}
                novalidate
                className={Submit2 ? 'was-validated' : ''}
            >
                <div className="modal fade" id="profileUpdation" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel">PROFILE DISPLAY PICTURE </h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div id="bod" className="modal-body ">
                                <div>
                                    <div className="d-flex justify-content-center">
                                        {cropData ? <div className="box" style={{ width: "50%", float: "right" }}>
                                            <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                                            {/* <button className="btn btn-success mt-2" style={{ float: "right" }}  >Save</button> */}
                                        </div>
                                            : <div style={{ width: "100%" }}>
                                                <Cropper
                                                    style={{ height: 400, width: "100%" }}
                                                    zoomTo={false}
                                                    aspectRatio={45 / 45}
                                                    preview=".img-preview"
                                                    src={image}
                                                    viewMode={1}
                                                    minCropBoxHeight={10}
                                                    minCropBoxWidth={10}
                                                    background={false}
                                                    responsive={true}
                                                    autoCropArea={0}
                                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                    onInitialized={(instance) => {
                                                        setCropper(instance);
                                                    }}
                                                    guides={true}
                                                />
                                                {/* <button className="btn btn-info text-light mt-2" style={{ float: "right" }} onClick={getCropData}>Ok</button> */}
                                            </div>

                                        }
                                    </div>
                                    {/* <button className="btn btn-info text-light" style={{ float: "right" }}  onClick={getCropData}>Ok</button> */}


                                    {/* <button onClick={() => uploadme()}>Start upload</button> */}

                                </div>
                            </div>
                            <div className="modal-footer">

                                {/* <button onClick={() => setcp(true)}>doit</button> */}
                                {!cropData ?
                                    <button type="button" className="btn pro-spaces-button3" onClick={getCropData}>Ok</button>
                                    :
                                    <button type="button" className="btn pro-spaces-button3" onClick={() => uploadme()} data-bs-dismiss="modal">Save</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {props.basicprofile === true ? <AddOn setError={setError} setAddons={setAddons} /> : <AddOn setError={setError} setAddons={setAddons} />}

                <div className="row">
                    <div className="col-md-3 border-right ">
                        <div className="d-flex flex-column align-items-center text-center pb-5 ps-0 pe-0 pt-5 ">
                            <img id="DP" className=" mt-5" width="150px" src={`${bucketName}/${value.id}/avatar.png`} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                    "/blank.png";
                            }} />
                            <div id="idtestingprofilepicvalidation" className="upload">
                                <input
                                    required={imageinserted === false && !Edit}
                                    type="file"
                                    accept="image/*"
                                    onChange={onChange}
                                    id="image"
                                    className="form-control"
                                />
                                <label for="image"><AiFillCamera size={"40px"} /></label>
                                <div class="invalid-feedback" style={{ 'backgroundColor': 'white' }}>
                                    PLEASE upload PROFILE DISPLAY PICTURE

                                </div>
                            </div>
                            <span className="text-50 mt-3 up" onClick={() => photo()}>PROFILE DISPLAY PICTURE *</span><span> </span>
                        </div>
                    </div>

                    <div className="col-md-9 border-right">
                        <div className="pb-4 ps-0 pe-0 pt-5">
                            <h6 >PROFILE DISPLAY NAME *</h6>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <input
                                        required
                                        ref={pnameRef}
                                        type="text"
                                        className="form-control"
                                        placeholder="Individual / Entity / Company / Brand Name"
                                        value={value.pname}
                                        onChange={(e) =>
                                            setValue({ ...value, pname: e.target.value })
                                        }
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER PROFILE DISPLAY NAME
                                    </div>
                                </div>
                            </div>
                            <h6 className="mt-2">Entity Information </h6>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label className="labels">Type of Firm *</label>
                                    <select
                                        required
                                        className="form-select"
                                        value={value.typeoffirm}
                                        onChange={(e) =>
                                            setValue({ ...value, typeoffirm: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option>Sole Proprietorship</option>
                                        <option>Partnership</option>
                                        <option>Private Limited</option>
                                        <option>Corporation</option>
                                        <option>OPC / One Person Company</option>
                                        <option>LLP / Limited Liability Partnership</option>
                                        <option>LLC / Limited Liability Company</option>
                                        <option>Financial Cooperative</option>

                                    </select>
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Type of Firm
                                    </div>
                                    {PartnerCount.map((dsi, index) => {
                                        return <Partners key={dsi} id={dsi} Partner={value.Partner} handlePartner={handlePartner} index={index} ></Partners>
                                    })}
                                    <button style={{ fontSize: '12px' }} className="btn pro-spaces-button my-3" type="button" onClick={(e) => setPartnerCount([...PartnerCount, keyGen()])} >ADD MORE </button>
                                </div>
                            </div>
                            <h6 className="mt-4">Personal Information <p style={{ 'fontWeight': '400', 'fontSize': '15px' }}>(of the Proprietor / Business Owner)</p></h6>
                            <div className="row mt-2">
                                <div className="col-md-12"><label className="labels">First Name *</label><input type="text" required className="form-control" maxLength='40' value={value.firstname} onChange={(e) => setValue({ ...value, firstname: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Last Name *</label><input type="text" required className="form-control" maxLength='40' value={value.lastname} onChange={(e) => setValue({ ...value, lastname: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Last Name
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-check">
                                        <input onChange={(e) =>
                                            setValue({ ...value, WomanOwned: !value.WomanOwned })
                                        } className="form-check-input" type="checkbox" id="Enterprise" />
                                        <label className="labels" for="Enterprise">
                                            I am a Woman Entrepreneur<span style={{ 'fontWeight': '500' }}> (check box to get a profile badge)</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <h6 className="mt-4">Business Information</h6>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label className="labels">Entity Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.EntityName}
                                        onChange={(e) =>
                                            setValue({ ...value, EntityName: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12"><label className="labels">Brand Name</label><input type="text" className="form-control" maxLength='40' value={value.brandName} onChange={(e) => setValue({ ...value, brandName: e.target.value })} /></div>
                                <div className="col-md-12">
                                    <label className="labels">Entity / Brand Tag line</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.brandtagline}
                                        onChange={(e) =>
                                            setValue({ ...value, brandtagline: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-12 pt-1">
                                    <div className="form-check">
                                        <input
                                            onChange={(e) =>
                                                setValue({
                                                    ...value,
                                                    taglineCheck: !value.taglineCheck,
                                                })
                                            }
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Enterprise"
                                            checked={value.taglineCheck}
                                        />
                                        <label className="labels" for="Enterprise">
                                            I want the tag line to be displayed below the Profile Display Name
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Type of Vendor / Business *</label>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="form-check">
                                                <input required value={'Retailers'} onChange={(e) => {
                                                    setValue({ ...value, who: e.target.value })
                                                }} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1ds" />
                                                <label className="form-check-label" style={{ fontWeight: 'normal' }}>
                                                    Retailers
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="form-check">
                                                <input required value={'Wholesalers'} onChange={(e) => {
                                                    setValue({ ...value, who: e.target.value })
                                                }} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1sd3333" />
                                                <label className="form-check-label" style={{ fontWeight: 'normal' }}>
                                                    Wholesalers
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="form-check">
                                                <input required value={'Both'} onChange={(e) => {
                                                    setValue({ ...value, who: e.target.value })
                                                }} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1fd4" />
                                                <label className="form-check-label" style={{ fontWeight: 'normal' }}>
                                                    Both
                                                </label>
                                                <div class="invalid-feedback">
                                                    PLEASE ENTER Type of Vendor / Business
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Type of Entity *</label>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="form-check">
                                                <input required value={'Physical'} onChange={(e) => {
                                                    setValue({ ...value, TypeofStore: e.target.value })
                                                }} className="form-check-input" type="radio" name="TypeofStore" id="flexRadioDefault1dsTypeofStore1" />
                                                <label className="form-check-label" style={{ fontWeight: 'normal' }}>
                                                    Physical Store
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="form-check">
                                                <input required value={'E-commerce'} onChange={(e) => {
                                                    setValue({ ...value, TypeofStore: e.target.value })
                                                }} className="form-check-input" type="radio" name="TypeofStore" id="flexRadioDefault1sdTypeofStore2" />
                                                <label className="form-check-label" style={{ fontWeight: 'normal' }}>
                                                    E-commerce
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="form-check">
                                                <input required value={'Both'} onChange={(e) => {
                                                    setValue({ ...value, TypeofStore: e.target.value })
                                                }} className="form-check-input" type="radio" name="TypeofStore" id="flexRadioDefault1fdTypeofStore3" />
                                                <label className="form-check-label" style={{ fontWeight: 'normal' }}>
                                                    Both
                                                </label>
                                                <div class="invalid-feedback">
                                                    PLEASE ENTER Type of Entity
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Year of Business Inception *</label><input type="number" required className="form-control" placeholder='Which year did you start your business ?' value={value.yearofb} onChange={(e) => { if (e.target.value.length == 5) return false; setValue({ ...value, yearofb: e.target.value }) }} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Year of Business Inception
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Do you have a Trade Licence ? *</label>
                                    <select
                                        required
                                        className="form-select"
                                        value={value.licence}
                                        onChange={(e) =>
                                            setValue({ ...value, licence: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                        <option value="N/A">N/A</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Do you have a Trade Licence ?
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Is your entity GST registered ? *</label>
                                    <select
                                        required
                                        className="form-select"
                                        value={value.gst}
                                        onChange={(e) => setValue({ ...value, gst: e.target.value })}
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Is your entity GST registered ?
                                    </div>
                                </div>
                                <h6 style={{ fontSize: '0.8rem' }} className="mt-3">Head Office / Registered Office / Main Branch </h6>

                                <label className="labels mt-3">Address *</label>
                                <div className="col-md-12">
                                    <label className="labels">Building / Mall / Property Name *</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        value={value.address_building}
                                        onChange={(e) =>
                                            setValue({ ...value, address_building: e.target.value })
                                        }
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Building / Mall / Property Name
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label className="labels">Door / Shop No.</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_door}
                                        onChange={(e) =>
                                            setValue({ ...value, address_door: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Floor *</label>
                                    <select
                                        required
                                        className="form-select"
                                        value={value.address_floor}
                                        onChange={(e) =>
                                            setValue({ ...value, address_floor: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option>Basement</option>
                                        <option>Ground</option>
                                        <option>Mezzanine</option>
                                        {x.map((elem, index) => {
                                            let temp = index + 1
                                            if (index < 10) temp += 10
                                            if (index + 1 <= 10 || index + 1 >= 20)
                                                if (temp % 10 === 1) return <option>{index + 1}st</option>
                                                else if (temp % 10 === 2) return <option>{index + 1}nd</option>
                                                else if (temp % 10 === 3) return <option>{index + 1}rd</option>
                                                else return <option>{index + 1}th</option>
                                            else return <option>{index + 1}th</option>
                                        })}
                                    </select>
                                    <div class="invalid-feedback">
                                        PLEASE SELECT Floor
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <label className="labels">Street / Lane / Road Name *</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        value={value.address_street}
                                        onChange={(e) =>
                                            setValue({ ...value, address_street: e.target.value })
                                        }
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Street / Lane / Road Name
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Area / Locality Name *</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        value={value.address}
                                        onChange={(e) =>
                                            setValue({ ...value, address: e.target.value })
                                        }
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Area / Locality Name
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Nearest Landmark</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_nearest}
                                        onChange={(e) =>
                                            setValue({ ...value, address_nearest: e.target.value })
                                        }
                                    />
                                </div>
                                <StateCity setValue={setValue} value={value} Edit={Edit} />

                                <div className="col-md-12">
                                    <label className="labels">Pin code / Zip code *</label>
                                    <input
                                        required
                                        type="tel"

                                        className="form-control"
                                        value={value.pincode}
                                        onChange={(e) => {
                                            setValue({ ...value, pincode: e.target.value });
                                        }}
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Pin code / Zip code
                                    </div>
                                </div>
                                <div className="my-3">

                                    <MapWithSwitch Location={Location} setLocation={setLocation} LocationOn={LocationOn} setLocationOn={setLocationOn} key={"aouhduahdiuqbduiqdyvq65564"} />
                                </div>
                                {/* <br /><br /> */}

                                <div className="col-md-12"><label className="labels">Website / Web App, Mobile App</label><input type="text" className="form-control" placeholder="Enter Names of all Apps with ',' separation" value={value.website} onChange={(e) => setValue({ ...value, website: e.target.value })} /></div>

                                <label className="labels mt-3">Work Timings *</label>
                                <div className="col-12 pt-1">
                                    <div className="form-check">
                                        <input
                                            onChange={(e) =>
                                                setValue({
                                                    ...value,
                                                    worktimimng: !value.worktimimng,
                                                })
                                            }
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Enterprsdffise"
                                            checked={value.worktimimng}
                                        />
                                        <label className="labels" for="Enterprsdffise">
                                            We are open 24 hours
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label className="labels">Working Days (full) {value.worktimimng ? '' : '*'}</label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple required={Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : true : false} error={Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdaysfullnew} onChange={(e) => setValue({ ...value, workingdaysfullnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays.map((arrayofdays) => (
                                                <MenuItem key={arrayofdays} value={arrayofdays}
                                                    disabled={isOptionDisabled(arrayofdays, value.workingdaysfullnew)}
                                                >
                                                    <Checkbox checked={value.workingdaysfullnew.indexOf(arrayofdays) > -1} />
                                                    <ListItemText primary={arrayofdays} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : 'PLEASE SELECT Working Days (full)' : false}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Hours {value.worktimimng ? '' : '*'}</label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_1}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_1: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_2}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_2: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />

                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Break Time {value.worktimimng ? '' : '*'}</label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.break_time_1}
                                                onChange={(e) =>
                                                    setValue({ ...value, break_time_1: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox type={'break'} />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Break Time
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.break_time_2}
                                                onChange={(e) =>
                                                    setValue({ ...value, break_time_2: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox type={'break'} />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Break Time
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Days (half) {value.worktimimng ? '' : '*'}</label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple required={Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : true : false} error={Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdayshalfnew} onChange={(e) => setValue({ ...value, workingdayshalfnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays2.map((arrayofdays2) => (
                                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                                    disabled={isOptionDisabled(arrayofdays2, value.workingdayshalfnew)}
                                                >
                                                    <Checkbox checked={value.workingdayshalfnew.indexOf(arrayofdays2) > -1} />
                                                    <ListItemText primary={arrayofdays2} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : 'PLEASE SELECT Working Days (half)' : false}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Hours {value.worktimimng ? '' : '*'}</label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_3}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_3: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_4}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_4: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />

                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Weekly Off *</label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple required error={Submit2 ? value?.workingdaysOffnew?.length != 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdaysOffnew} onChange={(e) => setValue({ ...value, workingdaysOffnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays2.map((arrayofdays2) => (
                                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                                    disabled={isOptionDisabled(arrayofdays2, value.workingdaysOffnew)}
                                                >
                                                    <Checkbox checked={value.workingdaysOffnew.indexOf(arrayofdays2) > -1} />
                                                    <ListItemText primary={arrayofdays2} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.workingdaysOffnew?.length != 0 ? false : 'PLEASE SELECT Weekly Off' : false}</FormHelperText>
                                    </FormControl>
                                </div>

                                <div className="col-md-12 ">
                                    <label className="labels" ref={target} onClick={() => setShow(!show)}>Product Keyword Search Preferences
                                        &nbsp;<AiFillExclamationCircle type="button" style={{ 'marginTop': '-3px', 'color': '#55374a' }}
                                        /></label>

                                    <input type="text" className="form-control" value={value.keyword} onChange={(e) => setValue({ ...value, keyword: e.target.value })} />
                                    <Overlay target={target.current} show={show} placement="top">
                                        {(props) => (
                                            <Tooltip id="overlay-example" {...props} placement="top">
                                                Mention search words that can help your prospective customers find you easily
                                            </Tooltip>
                                        )}
                                    </Overlay>
                                </div>
                            </div>
                            <h6 className="mt-4">Contact Information</h6>
                            <div className="mt-3">
                                <div className="col-md-12"><label className="labels">Email Id *</label><input type="email" required className="form-control" value={value.email} onChange={(e) => setValue({ ...value, email: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Email Id
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Country Code *</label>
                                    <select
                                        required
                                        className="form-select"
                                        value={value.countrycode}
                                        onChange={(e) =>
                                            setValue({ ...value, countrycode: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="+91">+91</option>

                                    </select>
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Country Code
                                    </div>
                                </div>
                                {/* <div className="col-md-12"><label className="labels">State Code *</label><input required type="number" className="form-control" value={value.statecode} onChange={(e) => setValue({ ...value, statecode: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER State Code
                                    </div>
                                </div> */}
                                <>
                                    {CONCount.map((dsi, index) => {
                                        return <MobileAddOn languages={languages} key={dsi} id={dsi} con={value.CON} handleCON={handleCON} index={index} type="2"></MobileAddOn>
                                    })}
                                    <button style={{ fontSize: '12px' }} disabled={CONCount.length >= 5} className="btn pro-spaces-button" type="button" onClick={(e) => setConCount([...CONCount, keyGen()])} >ADD ANOTHER MOBILE / LANDLINE NUMBER </button>
                                </>


                                {convert == false ?
                                    <>
                                        <div className="col-12 ">
                                            <h6 className="mt-4">Product Information {props.formType === 1 ? '*' : ''}</h6>
                                            <p>
                                                For your prospective customers to get a clear understanding of what products you are selling, the brands of the products you are dealing in, and the USP of these products, Create a catalogue with a detailed description of your products with their pricing.
                                            </p>

                                            <Port type={true} value={value} setValue={setValue} changersab={props.formType === 1 ? 'changersab' : ''} />

                                        </div>
                                    </>
                                    : <></>}
                                <div className="col-md-12 mt-4">
                                    <Selfdeclaration type='normal' setSelfDeclaration={setSelfDeclaration} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 className='text-center text-danger' >{error ? error : ''}</h6>
                <div className="mt-2 text-center">
                    {!loading &&
                        <button className="btn mb-3" style={{ 'backgroundColor': '#55374a', 'color': 'white' }} onClick={() => setSubmit2(true)} >   {Edit ? 'Update' : 'Create Profile'}</button>

                    }
                    {loading &&
                        <button className="btn  mb-3 mt-4" disabled style={{ 'backgroundColor': '#55374a', 'color': 'white' }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                        </button>

                    }
                </div>
            </form>
        </div>
    )
}
