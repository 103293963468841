import React, { useState, useEffect, useRef } from "react";
import "./post.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { CommentMain } from "./commentmain";

import { BsEmojiSmile } from "react-icons/bs";
import Picker from "emoji-picker-react";
import { notify } from "../../Utility/notify";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
var Cat = localStorage.getItem("Cat");

export function LikeAndComment({ postId }) {
  // console.log(props.dat);
  const [bool, setbool] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  const [Comment, setComment] = useState("");
  const [Viewcomment, setViewcomment] = useState("View All Comments");
  const [Loader, setLoader] = useState(
    <div className="text-center ">
      {" "}
      {/* <div className="spinner-border text-dark"></div> */}
    </div>
  );
  // var commentSendbutton = "violet"
  const [CommentContent, setCommentContent] = useState([]);
  var booler = false;

  const [chosenEmoji, setChosenEmoji] = useState(null);

  const onEmojiClick = (event, emojiObject) => {
    // console.log(emojiObject);
    let em = emojiObject.emoji;

    // console.log(emojiObject.emojiObject);
    setComment(Comment + em);
  };

  function myFunction() {
    var das = postId;
    /* Get the text field */
    var copyText = document.getElementById("myInput" + das);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    // alert("Copied the text: " + copyText.value);
  }

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const commentHandler = (e) => {
    // console.log(e.target.id);
    var btn = "combtn" + e.target.id.substring(7);

    e.preventDefault();
    var data = {
      comment: Comment,
      postid: postId,
      Type: "Main",
      id: false,
    };
    if (Comment.length > 0) {
      axios
        .post("/api/User/commentPost", { data })
        .then((response) => {
          if (response.status === 200) {
            // console.log("posted");
            setComment("");
            if (!bool) {
              document.getElementById("cc" + postId).click();
            }
          }
        })
        .catch((res) => {
          console.log("Something went wrong");
        });
    }
  };

  const [Com, setCom] = useState(false);

  const loadComment = (e) => {
    // console.log(booler);
    if (!bool) {
      setbool(true);
      e.target.innerHTML = "Loading...";
      // console.log("first");
      var id = postId;
      // console.log(id);
      axios
        .post("/api/User/getcomment", { id })
        .then((response) => {
          if (response.status === 200) {
            console.log("comment loaded");
            if (response.data.length > 0) {
              setCom(response.data);
              // console.log(response.data);
              return (e.target.innerHTML = "Hide comment");
            }

            return (e.target.innerHTML = "No comments to show !");
          }
          return (e.target.innerHTML = "Something went wrong !");
        })
        .catch((res) => {
          e.target.innerHTML = "Something went wrong !";
          return console.log("Something went wrong comment");
        });
    } else {
      e.target.innerHTML = "View All Comments";
      setbool(false);
    }
  };

  // Comment section ends

  const goforit = () => {
    // console.log("it works");
  };

  const [emoji, setemoji2] = useState(false);

  useEffect(() => {
    // setuserRole('Active-user')
    likeOrNot(postId);
    booler = false;
  }, []);

  const Like = (type) => {
    // console.log("yyyy");
    setType(type);
    var data = {
      id: postId,
      type: type,
    };
    console.log(data);
    // return
    try {
      axios
        .post("/api/User/Likepost", { data })
        .then((response) => {
          // console.log(data);
          if (response.status === 200) {
            // console.log("Like!!!");
            // if(type==1)
            // {
            //   if(type1==1){
            //     setCounts(counts-1);
            //   }
            //   else{
            //   setCounts(counts+1);
            //   }
            // }
            // if(type==2)
            // {
            //   if(type1==2)
            //   {
            //     setCount1(cont1-1)
            //   }
            //   else{
            //     setCount1(cont1+1)
            //   }
            // }
            likeOrNot();
            setLiked(true);
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            notify("You need an account !", false);
          } else {
            notify("something went wrong !", false);
          }
        });
    } catch (er) {}
  };

  const [counts, setCounts] = useState(0);
  const [cont1, setCount1] = useState(0);
  const [cont2, setCount2] = useState(0);
  const [liked, setLiked] = useState(false);
  const [type1, setType] = useState(0);

  const likeOrNot = () => {
    // console.log(postid)
    // return/
    var postid = postId;
    try {
      axios
        .post("/api/User/likeview", { postid })
        .then((response) => {
          // console.log("success");
          if (response.status === 200) {
            // console.log("Likes view");
            // console.log(response.data.likeORnote);
            if (response.data.likeORnote === 1) {
              // console.log("testing");
              setLiked(true);
            }

            console.log(response.data);
            setCounts(response.data);
            // setCount1(response.data.like1)
            // setCount2(response.data.like2)
            setType(response.data.liketype.type);
            // console.log("like->" + response.data.like);
          }
        })
        .catch((res) => {
          // console.log("Something went wrong");
        });
    } catch (er) {}
  };

  return (
    <div>
      <div className="postTrigger row mt-lg-3 my-1  align-items-center px-2">
        <select
          className="form-select pleasehelpme bg-light"
          aria-label="Default select example"
          value={type1}
          onChange={(e) => Like(e.target.value)}
        >
          <option>Rate this Post / See Reviews</option>
          <option value={4}>Highly Recommended ({counts.like4})</option>
          <option value={2}>Impressive ({counts.like2})</option>
          <option value={1}>Insightful / Informative ({counts.like1})</option>
          <option value={8}>Innovative ({counts.like8})</option>
          <option value={9}>Inspirational ({counts.like9})</option>
          <option value={3}>Interesting ({counts.like3})</option>
          <option value={5}>Excellent Service ({counts.like5})</option>
          <option value={6}>Superior Quality Product ({counts.like6})</option>
          <option value={7}>Useful Product ({counts.like7})</option>
        </select>
      </div>

      {/* <div className="postTrigger row mt-lg-3 my-1  align-items-center px-2">
    
        
        
        
        
        {
        
         
         
        liked ? 
          
        type1==1?
        
        (
          <div className="col-12  p-0 comment border liked">
            <button onClick={(e) => Like(e, postId, 1)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
              💡 &nbsp;INSIGHTFUL & INFORMATIVE <font size="3"></font>&nbsp;
              {counts == 0 ? "(0)" : "(" + counts + ")"}{" "}
            </button>
          </div>
        ):
        (
          <div className="col-12  p-0 comment border ">
            <button onClick={(e) => Like(e, postId, 1)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
              💡 &nbsp;INSIGHTFUL & INFORMATIVE <font size="3"></font>&nbsp;
              {counts == 0 ? "(0)" : "(" + counts + ")"}{" "}
            </button>
          </div>
        ) 
        : (
          <div className="col-12  p-0 comment border ">
            <button onClick={(e) => Like(e, postId, 1)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
              💡 &nbsp;INSIGHTFUL & INFORMATIVE <font size="3"></font>&nbsp;
              {counts == 0 ? "(0)" : "(" + counts + ")"}{" "}
            </button>
          </div>
        )

        }
      </div> */}

      {/* <div className="postTrigger row mt-lg-3 my-1  align-items-center px-2">

        {liked ?
        
        type1==2?
        
        
        (
          <div className="col-6  p-0 comment border liked">
            <button onClick={(e) => Like(e, postId, 2)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
               &nbsp;👏 COMMENDABLE<font size="3"></font>&nbsp;
              {cont1 == 0 ? "(0)" : "(" + cont1 + ")"}{" "}
            </button>
          </div>
        ):
        (
          <div className="col-6  p-0 comment border ">
            <button onClick={(e) => Like(e, postId, 2)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
               &nbsp;👏 COMMENDABLE<font size="3"></font>&nbsp;
              {cont1 == 0 ? "(0)" : "(" + cont1 + ")"}{" "}
            </button>
          </div>
        )
        
        
        
        : (
          <div className="col-6  p-0 comment border ">
            <button onClick={(e) =>Like(e, postId, 2)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
               &nbsp;👏 COMMENDABLE<font size="3"></font>&nbsp;
              {cont1 == 0 ? "(0)" : "(" + cont1 + ")"}{" "}
            </button>
          </div>
        )
        }
     
      
        {liked ?
        
        type1==3?
        
        
        (
          <div className="col-6  p-0 comment border liked">
            <button onClick={(e) => Like(e, postId, 3)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
               &nbsp; INTERESTING <font size="3"></font>&nbsp;
              {cont2 == 0 ? "(0)" : "(" + cont2 + ")"}{" "}
            </button>
          </div>
        ):
        (
          <div className="col-6  p-0 comment border ">
            <button onClick={(e) => Like(e, postId, 3)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
               &nbsp; INTERESTING <font size="3"></font>&nbsp;
              {cont2 == 0 ? "(0)" : "(" + cont2 + ")"}{" "}
            </button>
          </div>
        )
        
        
        
        : (
          <div className="col-6  p-0 comment border ">
            <button onClick={(e) =>Like(e, postId, 3)}>
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
               &nbsp; INTERESTING <font size="3"></font>&nbsp;
              {cont2 == 0 ? "(0)" : "(" + cont2 + ")"}{" "}
            </button>
          </div>
        )
        }
      </div> */}

      <div>
        {" "}
        {/* className="postTrigger row mt-md-3  align-items-center px-2" */}
        {/* <div className="col-6 p-0 comment  border">
          <button
            data-bs-toggle="modal"
            data-bs-target="#sharepostModal"
            onClick={(e) => myFunction()}
          >
            <a
              onClick={setInputFocus}
              style={{ textDecoration: "none", color: "black" }}
            >
              {" "}
              <i className="fa fa-wechat" aria-hidden="true"></i>
              <FaRegShareSquare />
              &nbsp; SHARE &nbsp;
            </a>

            
          </button>
        </div>
        <div className="col-6  p-0 comment  border">
        
          <button
            onClick={setInputFocus}
            data-bs-toggle="collapse"
            data-bs-target={"#com" + postId}
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            {" "}
            <i className="fa fa-wechat" aria-hidden="true"></i>
            <BiMessageRoundedDots />
            &nbsp; COMMENT &nbsp;({props.dat.com.length})
           
          </button>
        </div> */}
        <div
          className="modal fade"
          id="sharepostModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-end">
                  <AiOutlineClose data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div>
                  <input
                    className="d-none"
                    type="text"
                    defaultValue={
                      "www.pro-spaces.com/Post_single?post_id=" + postId
                    }
                    id={"myInput" + postId}
                  />
                  <p>The URl is copied to your clipboard</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id={"com" + postId}
        className="commentSection collapse  mt-2 mt-md-4 ms-1 "
      >
        <div className="tab last m-0 w-100">
          <div className="cell pThumbcontainerr p-0 cot">
            {" "}
            <img
              alt=""
              className="selfthumbnailcomment me-1"
              src={localStorage.getItem("propic")}
              onError={({ currentTarget }) => {
                currentTarget.onerror = true; // prevents looping
                currentTarget.src =
                  "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
              }}
            />
          </div>
          <div className="cell  p-0 ">
            <form
              className="grid-container"
              id={"comment" + postId}
              onSubmit={(e) => commentHandler(e)}
            >
              <textarea
                ref={inputRef}
                value={Comment}
                onChange={(e) => setComment(e.target.value)}
                className="commentInput grid-item1 pe-5"
                placeholder="Comment"
                type="text"
              >
                {Comment}
              </textarea>{" "}
              <button className="ekfheihfuewgfioue  grid-item1 p-0 rwkuviueriuvgirugv">
                <BsEmojiSmile onClick={(e) => setemoji2(!emoji)} />
              </button>
              <div className="grid-item2  ">
                <button
                  type="submit"
                  id={"combtn" + postId}
                  style={{ width: "70px" }}
                  className="btn pro-spaces-button d-flex justify-content-center"
                >
                  Post
                </button>
              </div>
            </form>
          </div>
        </div>
        {emoji ? (
          <Picker className="erjhbviuer" onEmojiClick={onEmojiClick} />
        ) : (
          <></>
        )}
      </div>
      {/* post comment input ends */}

      <div className="row my-3 d-none">
        {/* view or hide comment button */}
        <div className="col-12">
          <b
            className="viewHide "
            onClick={(e) => loadComment(e)}
            data-bs-toggle="collapse"
            id={"cc" + postId}
            href={"#c" + postId}
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample12"
          >
            View All Comments
          </b>
        </div>

        {/* <CommentMain id={'c' + postId} /> */}
        <div className="collapse" id={"c" + postId}>
          {Com.length > 0 ? (
            <>
              {" "}
              {Com.map((element) => {
                return (
                  <CommentMain
                    key={keyGen()}
                    data={element}
                    id={"c" + postId}
                  />
                );
              })}{" "}
              <h6 className="d-none">Load more</h6>
            </>
          ) : (
            Loader
          )}
          {/* comment container ends here */}
        </div>
      </div>
    </div>
  );
}

export default LikeAndComment;
