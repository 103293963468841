import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { GrMail } from "react-icons/gr";
import { FaPhoneAlt, } from 'react-icons/fa'
import { ImProfile, } from 'react-icons/im'
import Axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
export default function ExperiencedViewSingle(props) {

  let Navigate = useNavigate();


    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const [jobDetails, setjobDetails] = useState(props.value)

    // setQuli(res.data.data1[0].JobSeeker_qualification)

    const [Quli, setQuli] = useState(props.value.JobSeeker_qualification)

    const [Resume, setResume] = useState(props.resume)
    const [Work, setWork] = useState(props.work)

    var date1=Moment(jobDetails.JobSeeker_workingstart).format('MM-YYYY')
    var date2=Moment(jobDetails.JobSeeker_breakSince).format('MM-YYYY')


    const communicate = ( st) => {
      // let id = Ac;
      // document.getElementById("slr").click();
      let dataSend = {
         id:jobDetails.Account_id,
        st,
      };
      axios
        .post("/api/User/communicate", { dataSend })
        .then((res) => {
          if (res.status === 200) {
            // return
            Navigate("/cubicles?consultantTrue=" + res.data);
            return
          } else {
            Navigate("/cubicles?consultantTrue=" + res.data);
          }
        })
        .catch((res) => {
          if (res.response.status === 400) {
            // Navigate('/cubicles?consultantTrue='+id)
          } else if (res.response.status === 401) {
            // TOAST SOMETHING WRONG SAME PERSON
          }
        });
    };

var idss=localStorage.getItem("User")

  return (
    <div> 
         <form style={{
        borderRadius: '15px'
    }} className=' container conter card border pb-2 pt-2 px-4'>

<Accordion  onChange={handleChange('panel1')} defaultExpanded={true} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon /> }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Personal Information</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography>
              {/* <Portfolio type={type}/>*/}
       

   


{
  idss!==jobDetails.Account_id?
  <div className="row" >
  <div className="col-md-6">
    <span></span>
  </div>
  <div className="col-md-6 justify-content-end ">

  <div className="d-none d-md-block">
       <input
          type="button"
          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
          style={{'borderRadius':'25px'}}
         
          value="Communicate"

          onClick={(e)=>communicate(false)}
          
        />
      </div>
 

  </div>
</div>


  :""

}

           
              
              

              <div className="row" >
                <div className="col-md-6">
                  <span>Name</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.JobSeeker_Name?jobDetails.JobSeeker_Name:''}</label>
                </div>
              </div>
              
              <div className="row" >
                <div className="col-md-6">
                  <span>Gender </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.JobSeeker_gender}</label>
                </div>
              </div>

              <div className="row" >
                <div className="col-md-6">
                  <span>Age  </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {Math.floor(parseInt(new Date() - new Date(jobDetails.JobSeeker_dob)) / 1000 / 60 / 60 / 24 / 365)}</label>
                </div>
              </div>

              <div className="row" >
                <div className="col-md-6">
                  <span>Languages Known </span>
                </div>
                                        <div className="col-md-6 ">
                                            <label style={{ 'color': 'var(--blue)' }}>      
                                            <p className="ms-0 JobVal">
                                                <div className="row">
                                                    {jobDetails.JobSeeker_language ?

                                                        jobDetails.JobSeeker_language
                                                            .map((e) => {

                                                                return <div className='col'>{e}</div>

                                                            }) : 'Some thing went wrong!!!'}
                                                </div>
                                            </p>
                                            </label>
                                        </div>
              </div>

                  <div className="row" >
                <div className="col-md-6">
                  <span>Languages Known to Communicate Fluently  </span>
                </div>
                                        <div className="col-md-6 ">
                                            <label style={{ 'color': 'var(--blue)' }}>      
                                            <p className="ms-0 JobVal">
                                                <div className="row">
                                                    {jobDetails.JobSeeker_languageFluent ?

                                                        jobDetails.JobSeeker_languageFluent
                                                            .map((e) => {

                                                                return <div className='col'>{e}</div>

                                                            }) : 'Some thing went wrong!!!'}
                                                </div>
                                            </p>
                                            </label>
                                        </div>
              </div>

              <div className="row" >
                <div className="col-md-6">
                  <span>State  </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.JobSeeker_state}</label>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-6">
                  <span>City   </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.JobSeeker_city}</label>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-6">
                  <span>Email  </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> <GrMail size='25' /> <a className='Kbrn p-0' href={`mailto:${jobDetails.JobSeeker_email}`}>{jobDetails.JobSeeker_email}</a> </label>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-6">
                  <span>Contact Number   </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> <FaPhoneAlt size='20' />  <a className='Kbrn p-0' href={`tel:${jobDetails.JobSeeker_phone}`}>{jobDetails.JobSeeker_phone}</a></label>
                </div>
              </div>

          </Typography>
     
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Job Preferences</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography>

              {/* <Portfolio type={type}/> */}

              <div className="row" >
                <div className="col-md-6">
                  <span>Job Category   </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.category[0]?jobDetails.category[0].category:''}</label>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-6">
                  <span>Job Sub Category    </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.subcategory[0]?jobDetails.subcategory[0].sub:''}</label>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-6">
                  <span>Job Title    </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.JobSeeker_jobTitles}</label>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-6">
                  <span>Job Locations    </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}>
                       <div className="row">
                                    {jobDetails.JobSeeker_jobLocations ?

                                        jobDetails.JobSeeker_jobLocations                                   // JobSeeker_jobTitles
                                            .map((e) => {

                                                return <div className='col'>{e}</div>

                                            }) : 'Some thing went wrong!!!'}
                                </div>
                                </label>
                </div>
              </div>

              <div className="row" >
                <div className="col-md-6">
                  <span>Work Mode     </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.JobSeeker_workPlace}</label>
                </div>
              </div>

              <div className="row" >
                <div className="col-md-6">
                  <span>Employment Type    </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.JobSeeker_empType}</label>
                </div>
              </div>

              <div className="row" >
                <div className="col-md-6">
                  <span>Key Skills    </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}>

                   <div className="row">
                                {
                                    jobDetails.JobSeeker_skills ?

                                        jobDetails.JobSeeker_skills.map((e) => {

                                            return <div className="col mb-3">
                                                <div  className='px-3  skillItem
                             '>{e}</div>
                                            </div>
                                        }) : 'Some thing went wrong!!!'}
                            </div>
                     
                     </label>
                </div>
              </div>
              
              {jobDetails.JobSeeker_Interest!==""?

                                        <div className="row" >
                                            <div className="col-md-6">
                                                <span>Areas of Interest  </span>
                                            </div>
                                            <div className="col-md-6 ">
                                                <label style={{ 'color': 'var(--blue)' }}>  {jobDetails.JobSeeker_Interest}</label>
                                            </div>
                                        </div>
                                        : ""
              
              }

              {
                jobDetails.JobSeeker_readyBond==="true"||jobDetails.JobSeeker_readyBond===true?
                <b style={{ 'color': 'var(--blue)' }}>I am ready for a bond agreement job</b>
                :
                ""
              }

          </Typography>
     
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Current / Immediate Previous Occupational Status</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography>

              {/* <Portfolio type={type}/> */}

              <div className="row" >
                <div className="col-md-6">
                  <span>Job Title    </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}> {jobDetails.JobSeeker_jobTitles}</label>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-6">
                  <span>
                Company / Organization 
               </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}>  {jobDetails.JobSeeker_company}</label>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-6">
                  <span>
                  Job Location 
               </span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}>  {jobDetails.JobSeeker_currentJoblocations}</label>
                </div>
              </div>
              {
                 jobDetails.JobSeeker_workingstart?
                 <div className="row" >
                   <div className="col-md-6">
                     <span>
                     Start Month/Year 
                  </span>
                   </div>
                   <div className="col-md-6 ">
                     <label style={{'color':'var(--blue)'}}>  {date1}</label>
                   </div>
                 </div>
                 :''
              }

              {
                jobDetails.JobSeeker_currentlyWorking==="true"||jobDetails.JobSeeker_currentlyWorking===true?
                <b style={{ 'color': 'var(--blue)' }}>I am currently working here</b>
                :
                <b style={{ 'color': 'var(--blue)' }}>I am on a break from work</b>
              }
             
             {jobDetails.JobSeeker_breakSince? 
             <>

                  <div className="row" >
                    <div className="col-md-6">
                      <span>
                        On a break since
                      </span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ 'color': 'var(--blue)' }}>  {date2}</label>
                    </div>
                  </div>

                  <div className="row" >
                    <div className="col-md-6">
                      <span>
                        Reason
                      </span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ 'color': 'var(--blue)' }}><p>{jobDetails.JobSeeker_Reason}</p>  </label>
                    </div>
                  </div>

             
             </>
             
          


             :''}

              {jobDetails.JobSeeker_availability=='false'||jobDetails.JobSeeker_availability==false?

              <>

                                            <b style={{ 'color': 'var(--blue)' }}>I am on my Notice Period</b>
                  
                  <div className="row" >
                  <div className="col-md-6">
                    <span>
                    Notice period in days 
                 </span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{'color':'var(--blue)'}}>  {jobDetails.JobSeeker_noticeDay}&nbsp;days</label>
                  </div>
                </div>    


                        
              </>
              :
               <>
                                            <b style={{ 'color': 'var(--blue)' }}>I am available for immediate joining</b>
                   

               </> 
              }
              {
                jobDetails.JobSeeker_relocate===true||jobDetails.JobSeeker_relocate==="true"?
                <b style={{ 'color': 'var(--blue)' }}>I am ready to re-locate</b>
                :''

              }

          </Typography>
     
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Educational Qualifications</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography>

              {/* <Portfolio type={type}/> */}
              {jobDetails.JobSeeker_qualification?
             
              <>

                 {
                    Quli[0].Q5_1?
                    <div className='mt-1 container-fluid border'>

                    <div className="row" >
                    <div className="col-md-6">
                      <span>
                      Degree
                   </span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_1.degree}</label>
                    </div>
                  </div>
     
                  {Quli[0].Q5_1.special?
                   <div className="row" >
                   <div className="col-md-6">
                     <span>
                     Specialization
                  </span>
                   </div>
                   <div className="col-md-6 ">
                     <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_1.special}</label>
                   </div>
                 </div>
                   :''
                  }
     
                 <div className="row" >
                   <div className="col-md-6">
                     <div className="col-3">
                     <span>
                     Start Year     
                  </span>
                     </div>
                     <div className="col-3">
                     <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_1.sy.slice(0, 4)} </label>
                     </div>
                   </div>
                   <div className="col-md-6 ">
                   <div className="col-3">
                     <span>
                     End Year
                  </span>
                     </div>
                     <div className="col-3">
                     <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_1.ey.slice(0, 4)} </label>
                     </div>
                     {/* <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_1.special}</label> */}
                   </div>
                 </div>   
                 </div> 
                    :''
                 }

                 {
                    Quli[0].Q5_2.ey?
                    <div className='mt-1 container-fluid border'>

                    <div className="row" >
                    <div className="col-md-6">
                      <span>
                      Degree
                   </span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_2.degree}</label>
                    </div>
                  </div>
     
                  {Quli[0].Q5_2.special?
                   <div className="row" >
                   <div className="col-md-6">
                     <span>
                     Specialization
                  </span>
                   </div>
                   <div className="col-md-6 ">
                     <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_2.special}</label>
                   </div>
                 </div>
                   :''
                  }
     
                 <div className="row" >
                   <div className="col-md-6">
                     <div className="col-3">
                     <span>
                     Start Year     
                  </span>
                     </div>
                     <div className="col-3">
                     <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_2.sy.slice(0, 4)} </label>
                     </div>
                   </div>
                   <div className="col-md-6 ">
                   <div className="col-3">
                     <span>
                     End Year
                  </span>
                     </div>
                     <div className="col-3">
                     <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_2.ey.slice(0, 4)} </label>
                     </div>
                     {/* <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_1.special}</label> */}
                   </div>
                 </div>   
                 </div>

                    :''

                 }

                 {
                    Quli[0].Q5_3.ey?
                    <div className='mt-1 container-fluid border'>

                                                        <div className="row" >
                                                            <div className="col-md-6">
                                                                <span>
                                                                    Degree
                                                                </span>
                                                            </div>
                                                            <div className="col-md-6 ">
                                                                <label style={{ 'color': 'var(--blue)' }}>  {Quli[0].Q5_3.degree}</label>
                                                            </div>
                                                        </div>

                                                        {Quli[0].Q5_3.special ?
                                                            <div className="row" >
                                                                <div className="col-md-6">
                                                                    <span>
                                                                        Specialization
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6 ">
                                                                    <label style={{ 'color': 'var(--blue)' }}>  {Quli[0].Q5_3.special}</label>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }

                                                        <div className="row" >
                                                            <div className="col-md-6">
                                                                <div className="col-3">
                                                                    <span>
                                                                        Start Year
                                                                    </span>
                                                                </div>
                                                                <div className="col-3">
                                                                    <label style={{ 'color': 'var(--blue)' }}>  {Quli[0].Q5_3.sy.slice(0, 4)} </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 ">
                                                                <div className="col-3">
                                                                    <span>
                                                                        End Year
                                                                    </span>
                                                                </div>
                                                                <div className="col-3">
                                                                    <label style={{ 'color': 'var(--blue)' }}>  {Quli[0].Q5_3.ey.slice(0, 4)} </label>
                                                                </div>
                                                                {/* <label style={{'color':'var(--blue)'}}>  {Quli[0].Q5_1.special}</label> */}
                                                            </div>
                                                        </div>
                                                    </div>
                    :''

                 }

              </> 
              :''}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b> CV / Resumé and Work Portfolio </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography>

          <div className='py-1'>
                    
                    {jobDetails.JobSeeker_workefile==="Work.pdf"?
                <>
                 <div className='row g-1'>
                        <div className='col'>
                <a target='_blank' className='btn pro-spaces-button3 w-100 me-1 w-100' href={Resume} ><ImProfile size='20' />&nbsp;Resume</a>
                          
                        </div>
                        <div className='col'>
                        <a target='_blank' className='btn pro-spaces-button3 me-1 w-100' href={Work} ><ImProfile size='20' />&nbsp;Work</a>
                          
                        </div>
                        </div>
                </> 
                :
                <>
                <div className='row g-1'>
                    <div className='col'>
                <a target='_blank' className='btn pro-spaces-button3 me-1 w-100' href={Resume} ><ImProfile size='20' />&nbsp;Resume</a>
                    </div>
                </div>
                </>
                }
                </div>
                <br/>

                <div className="row" >
                         <div className="col-md-6">
                                                 <span>
                                                 Interview Process 
                                                 </span>
                         </div>
                         <div className="col-md-6 ">
                         <label style={{ 'color': 'var(--blue)' }}>  {jobDetails.JobSeeker_InterviewMode==="Ready for Online Interviews"?"Ready for Online Interviews":jobDetails.JobSeeker_InterviewMode==="One-to-One Interviews"?"One-to-One Interviews":"Ready for Online Interviews / One-to-One Interviews"}</label>
                         </div>
                         </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel155'} onChange={handleChange('panel155')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Work Experience, Latest CTC and Salary Expectation</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography>
               
               {
                jobDetails.JobSeeker_expMonths||jobDetails.JobSeeker_expYears?

                <div className="row" >
                             <div className="col-md-6">
                                  <span>
                                  Experience 
                                  </span>
                             </div>
                             <div className="col-md-6 ">
                             <label style={{ 'color': 'var(--blue)' }}>{jobDetails.JobSeeker_expYears?jobDetails.JobSeeker_expYears=="1"?jobDetails.JobSeeker_expYears+" YEAR ":jobDetails.JobSeeker_expYears+" YEARS ":""}&nbsp;{jobDetails.JobSeeker_expMonths?jobDetails.JobSeeker_expMonths=="1"?jobDetails.JobSeeker_expMonths+" MONTH ":jobDetails.JobSeeker_expMonths+" MONTHS ":""}&nbsp;</label>

                            </div>
                        </div>
                :''

               }
       



                    <div className="row" >
                             <div className="col-md-6">
                                  <span>
                                  Recent CTC 
                                  </span>
                             </div>
                             <div className="col-md-6 ">
                             <label style={{ 'color': 'var(--blue)' }}>  {jobDetails.JobSeeker_recentCTC}&nbsp;L.P.A</label>
                            </div>
                        </div>
                        <div className="row" >
                             <div className="col-md-6">
                                  <span>
                                  Expected Salary  
                                  </span>
                             </div>
                             <div className="col-md-6 ">
                             <label style={{ 'color': 'var(--blue)' }}>  {jobDetails.JobSeeker_expectedCTC}&nbsp;L.P.A</label>
                            </div>
                        </div>
              
          </Typography>
     
        </AccordionDetails>
      </Accordion>





      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Self Description</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography>
              
               {
                jobDetails.JobSeeker_about?
                <>
                 <b>About Me</b>
                <p className="ps-3">
                    {jobDetails.JobSeeker_about}
                </p>
                </>
                :""
               }

               {
                jobDetails.JobSeeker_hobbies?
                <>
                 <b>Hobbies</b>
                <p className="ps-3">
                    {jobDetails.JobSeeker_hobbies}
                </p>
                </>
                :""
               }

              {
                jobDetails.JobSeeker_JobSeeker_hobbies?
                <>
                 <b>Hobbies</b>
                <p className="ps-3">
                    {jobDetails.JobSeeker_JobSeeker_hobbies}
                </p>
                </>
                :""
               }
               

               {
                jobDetails.JobSeeker_Requisitions?
                <>
                 <b>Requisitions to Organization / Employer</b>
                <p className="ps-3">
                    {jobDetails.JobSeeker_Requisitions}
                </p>
                </>
                :""
               }


          </Typography>
     
        </AccordionDetails>
      </Accordion>


        </form>
    </div>
  )
}
