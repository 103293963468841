import React,{useEffect} from 'react'

import {JobSeekersList} from '../JobSeekerList/jobSeekerList'

export  function JobSeekMyPost(props) {
  useEffect(() => {
    props.Auth()
    }, [])
  return (
    <div>
<JobSeekersList />
    </div>
  )
}
