import React,{useState,useEffect} from 'react'
import './post.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {BsThreeDotsVertical} from "react-icons/bs"
import axios from 'axios';

export  function ThreeDotOption2(props) {
  // console.log(props)

  const [first, setfirst] = useState('')
  useEffect(() => {
    
    setfirst(props.dat.Content != 'undefined' ?props.dat.Content: '')
  }, [])
  
  useEffect(() => {
    // console.log(first)
  }, [first])
  const updatpost=(e,id)=>{
  
    var Content = first
   
    axios.post('/api/ActiveUser/UpdatePost',{
        id,Content
    }).then((res)=>{
 
    }).catch((err)=>{
      console.log(err)
    })
  
}

const delpost=(e,id)=>{
  axios.delete('/api/ActiveUser/delPost',{
    data:{
      id
    }
}).then((res)=>{

}).catch((err)=>{
  console.log(err)
})
}
  
  
  function keyGen() {
    var length = 5
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}


const posted=(e,id)=>{
  var pid=id
axios.post('/api/ActiveUser/reposting',{
pid
}).then((res)=>{
  
}).err((err)=>{
  console.log(err)
})
}


  console.log(props.dat)
  return (
    <div className="cell dropstart float-end pt-2 mt-1">
    <a type="button" href="/" className="dotoptionbtn   cell" data-bs-toggle="dropdown" aria-expanded="false">
    <BsThreeDotsVertical size={25} style={{color:'black'}}/>
    </a>

    <ul className="dropdown-menu ">
      {props.dat.Status==="Saved"?
      <>
      <li><a className="dropdown-item" onClick={(e)=>posted(e,props.dat.id)}> Go Live</a></li>
      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target={'#staticBackdrop'+props.dat.id}>Edit</a></li>
      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target={'#staticdeletep'+props.dat.id}> Delete</a></li>
      
      </>
      :
      <>
      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target={'#staticBackdrop'+props.dat.id}>Edit</a></li>
        {/* <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target={'#staticdeletep'+props.dat.id}> Delete</a></li> */}
        </>
      }
        
        

    </ul >
    <div class="modal fade" id={'staticBackdrop'+props.dat.id} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      
      <div class="modal-body">
      <div className='d-flex justify-content-end'>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div>
        <textarea className='form-control' onChange={(e)=>setfirst(e.target.value)}>
      {props.dat.Content != 'undefined' ?props.dat.Content: ''}

        </textarea>
      </div>
      <div id={'poda' + props.dat.id} className="carousel slide" data-bs-ride="carousel">

                            <div className="carousel-inner">
                                {props.dat.File.map((element, index) => {

                                    if (index === 0) {
                                        if ('image' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item active afhouweh brokendrtff">
                                                <img alt="" className="carItemmm" src={element.filename} />

                                            
                                            </div>
                                        } else if ('video' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item afhouweh active brokendrtff">
                                                <video className="carItemmm" controls controlsList="nodownload"> <source src={element.filename} />  </video>
                                            </div>
                                        }
                                    } else {
                                        if ('image' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item  afhouweh brokendrtff">
                                                <img alt="" className="carItemmm" src={element.filename} />
                                              
                                            </div>
                                        } else if ('video' === element.filetype.slice(0, 5)) {
                                            //    
                                            return <div key={keyGen()} className="carousel-item afhouweh brokendrtff">
                                                <video className="carItemmm" controls controlsList="nodownload"> <source src={element.filename} />  </video>
                                            </div>
                                        }
                                    }
                                })}



                            </div>
                            {props.dat.File.length>1?
                            <>
                            <button className="carousel-control-prev ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target={'#poda' + props.dat.id} data-bs-slide="prev" >
                                <span className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next ekgdfukweguobwljbvuwgcuonewgg" type="button" data-bs-target={'#poda' + props.dat.id} data-bs-slide="next" >
                                <span className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                            </>:<></> }
                        </div>
      
       
      </div>
      <div class="modal-footer">
        <button type="button" class="btn pro-spaces-button" data-bs-dismiss="modal">Discard</button>
        <button type="button" class="btn pro-spaces-button3" onClick={(e)=>updatpost(e,props.dat.id)}>Save</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id={'staticdeletep'+props.dat.id} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      
      <div class="modal-body">
      <div className='d-flex justify-content-end'>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div>
          <h4>Are you sure you want to Delete your PRO- post ?</h4>
      </div>
      </div>
      <div class="modal-footer">
        
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={(e)=>delpost(e,props.dat.id)}>Delete</button>
      </div>
    </div>
  </div>
</div>
   



</div>
  )
}
