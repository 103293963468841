import { SelectedSubcategoryContext } from './addAnother'
import { keyGen } from '../../../Utility/helper'
import React, { useContext, useEffect, useState } from 'react'
function AddOnField({ id, index, deleteMe, saveMe }) {


    let selectedSubcategoryContexts = useContext(SelectedSubcategoryContext) 
console.log(selectedSubcategoryContexts)
    const [Details, setDetails] = useState({
        subcategory: localStorage.getItem("Sub_cat") ,
        formType: localStorage.getItem("Type")
    })
    const [subcategory, setsubcategory] = useState()
    const [Brand, setBrand] = useState([])
    const [Selected, setSelected] = useState({
        subcategory: '',
        Brand: '',
        BrandOn: ''
    })
    // useEffect(() => {
    //     setsubcategory(catscontext.subcat.filter((elem) => {
    //         if (elem.formType.toString() === catscontext.value.formType) {
    //             return elem
    //         }
    //     }))
    //     return () => {
    //     }
    // }, [selectedSubcategoryContexts])

    const subcategorySelection = (sub) => {
        setSelected({ ...Selected, subcategory: sub, Brand: '' })
        let details = selectedSubcategoryContexts.Optionsub.filter((elem) => {
            if (elem._id === sub) {
                return elem
            }
        })
        if (details[0].brand?.length) {
            setBrand([...details[0].brand])
            setSelected({ ...Selected, subcategory: sub, Brand: '', BrandOn: true })

        } else {
            setSelected({ ...Selected, subcategory: sub, Brand: '', BrandOn: false })

            setBrand([])
        }
    }

    useEffect(() => {
        if (Selected.subcategory || Selected.Brand)
            saveMe(index, Selected)
        return () => {
        }
    }, [Selected])
    let ft = selectedSubcategoryContexts.Optionsub.find((er)=>{
      return  er._id==localStorage.getItem('Sub_cat')
    })
    ft=ft.formType
    console.log(ft);
console.log(selectedSubcategoryContexts.Optionsub);
    return (
        <div className='row border p-1 mb-3'>
            <div className='col-12 p-1 d-flex flex-row-reverse '>
                <button onClick={(e) => deleteMe(index)} type='button' className='btn btn-close ps-0' ></button>
            </div>

            <div className="col-md-12">
                <label className="labels">Sub Category *</label>
                <select className="form-select"
                    value={Selected.subcategory}
                    onChange={(e) => subcategorySelection(e.target.value)}
                >
                    <option disabled selected>
                    </option>
                    {selectedSubcategoryContexts.Optionsub?.map((sub) => {
                        console.log(ft,sub.formType);
                        if ((selectedSubcategoryContexts.Listed.indexOf(sub._id) == -1 )&& (ft!=sub.formType)) {
                            return <option key={keyGen()} formtype={sub.formType} value={sub._id}>
                                {sub.sub}
                            </option>
                        } else {
                            return <option key={keyGen()} disabled formtype={sub.formType} value={sub._id}>
                                {sub.sub}
                            </option>
                        }
                    })}
                </select>
            </div>

            {Brand.length ?
                <div className='col-md-12'>
                    <label className='labels'>Brand *</label>
                    <select className='form-select' value={Selected.Brand} onChange={(e) => setSelected({ ...Selected, Brand: e.target.value })}>
                        <option></option>
                        {Brand?.map((ele) => <option value={ele._id} >{ele.brand}</option>)}
                    </select>
                </div>
                : <></>
            }

        </div>

    )
}

export default AddOnField