import React, { useState, useEffect } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'


const MobileAddOn = (props) => {
    console.log(props);
    const [value, setValue] = useState({
        contact2: props.con ? props.con[props.index] ? props.con[props.index].contact2 ? props.con[props.index].contact2 : '' : '' : '',
        contactpersonsname2: props.con ? props.con[props.index] ? props.con[props.index].contactpersonsname2 ? props.con[props.index].contactpersonsname2 : '' : '' : '',
        contactpersonsdesignation2: props.con ? props.con[props.index] ? props.con[props.index].contactpersonsdesignation2 ? props.con[props.index].contactpersonsdesignation2 : '' : '' : '',
        preferredlanguage2: props.con ? props.con[props.index] ? props.con[props.index].preferredlanguage2 ? props.con[props.index].preferredlanguage2 : '' : '' : '',

    })
    useEffect(() => {
        props.handleCON(value, props.index)
        console.log(value);
        return () => {

        }
    }, [value])

    return (
        <fieldset>
            <div className='w-100'>{props.index != 0 && <div className='float-end pe-1 ' onClick={(e) => props.handleCON('remove', props.index)} style={{ "cursor": 'pointer' }} ><AiOutlineCloseCircle size={20} /></div>
            }</div>
            <div >
                <div className="col-md-12 mt-3">
                    <label className="labels">Mobile Number / Landline Number {props.index === 0 ? '*' : ''}</label>
                    <input type="tel" required={props.index === 0 ? true : false} className="form-control" pattern="[0-9]{6,13}" minLength="6" maxLength="13" value={value.contact2} onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }} onChange={(e) => { if (e.target.value.length == 13) return false; setValue({ ...value, contact2: e.target.value }) }} />
                    <div class="invalid-feedback">
                        {value?.contact2?.length === 0 ? 'PLEASE ENTER Mobile Number / Landline Number' : value?.contact2?.length < 6 || value?.contact2?.length > 13 ? 'PLEASE ENTER a valid Mobile Number / Landline Number' : 'PLEASE ENTER Mobile Number / Landline Number'}
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Contact Person’s Name {props.index === 0 ? '*' : ''}</label>
                    <input type="text" className="form-control" required={props.index === 0 ? true : false} value={value.contactpersonsname2} onChange={(e) => setValue({ ...value, contactpersonsname2: e.target.value })} />
                    <div class="invalid-feedback">
                        PLEASE ENTER Contact Person’s Name
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Contact Person’s Designation {props.index === 0 ? '*' : ''}</label>
                    <select
                        className="form-select"
                        required={props.index === 0 ? true : false}
                        value={value.contactpersonsdesignation2}
                        onChange={(e) =>
                            setValue({ ...value, contactpersonsdesignation2: e.target.value })
                        }
                    >
                        {props.type === '1' ? <>
                            <option value="" disabled selected></option>
                            <option value="Doctor">Doctor</option>
                            <option value="Consultant">Consultant</option>
                            <option value="Personal Secretary / Manager">Personal Secretary / Manager</option>
                            <option value="Receptionist">Receptionist</option>
                            <option value="Sub ordinate / Employee">Sub ordinate / Employee</option>
                        </>
                            : props.type === '2' ? <>
                                <option value="" disabled selected></option>
                                <option value="Propreitor / Owner">Propreitor / Owner</option>
                                <option value="Partner">Partner</option>
                                <option value="Personal Secretary / Manager">Personal Secretary / Manager</option>
                                <option value="Marketing Head">Marketing Head</option>
                                <option value="Receptionist">Receptionist</option>
                                <option value="Sub ordinate / Employee">Sub ordinate / Employee</option>
                            </>
                                : <>
                                    <option value="" disabled selected></option>
                                    <option value="Consultant">Consultant</option>
                                    <option value="Propreitor / Owner">Propreitor / Owner</option>
                                    <option value="Partner">Partner</option>
                                    <option value="Personal Secretary / Manager">Personal Secretary / Manager</option>
                                    <option value="Marketing Head">Marketing Head</option>
                                    <option value="Receptionist">Receptionist</option>
                                    <option value="Sub ordinate / Employee">Sub ordinate / Employee</option>
                                </>}

                    </select>
                    <div class="invalid-feedback">
                        PLEASE SELECT Contact Person’s Designation
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Most Comfortable / Preferred Language for Communication {props.index === 0 ? '*' : ''}</label>
                    <select
                        required={props.index === 0 ? true : false}
                        className="form-select"
                        value={value.preferredlanguage2} onChange={(e) => setValue({ ...value, preferredlanguage2: e.target.value })}
                    >
                        <option value="" disabled selected></option>
                        {props.languages.map((languages) => (
                            <option key={languages} value={languages}>{languages}</option>
                        ))}
                    </select>
                    <div class="invalid-feedback">
                        PLEASE SELECT Most Comfortable / Preferred Language for Communication
                    </div>

                </div>



            </div>
            <hr />
        </fieldset>
    )
}

export default MobileAddOn