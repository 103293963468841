import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import axios from "axios";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

import { TimeLineExternal } from "../../../Components/Timeline/TimeLineExternal"
import { useNavigate, useSearchParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {IoIosSend} from 'react-icons/io'

function Adminpexternal(props) {
  useEffect(() => {
    props.Auth()
  }, [])


  const [expanded, setExpanded] = useState(false);
  const [loading, setloading] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const [Follower, setFollower] = useState(false)
  let Navigate = useNavigate()
  var Ac = searchParams.get("Ac_id")
  var idd = searchParams.get("Ac_id")
  var Acc = searchParams.get("Ac_id")


  useEffect(() => {
   
    FollowOrNot(idd);
  }, [])

  const FollowOrNot = (followme) => {
    try {
      axios
        .post("/api/User/followornot", { followme })
        .then((response) => {
          console.log("success");
          if (response.status === 200) {
            console.log("first");
            setFollower(true);
          }
        })
        .catch((res) => {
          console.log("Something went wrong");
        });
    } catch (er) { }
  };

  const follow = (e, id) => {
    var followme = id;
    axios
      .post("/api/User/follow", { followme })
      .then((response) => {
        console.log("success");
        if (response.status === 200) {
          setFollower(true);
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };

  const unfollow = (e, id) => {
    var followme = id;
    axios
      .post("/api/User/unfollow", { followme })
      .then((response) => {
        console.log("success");
        if (response.status === 200) {
       
          setFollower(false);
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };





  const communicate = (e) => {
    var Ac = searchParams.get("Ac_id")
   
    let id = Ac
    let dataSend = {
      id
    }
    console.log(id)
    axios.post('/api/User/communicate', { dataSend }).then((res) => {
      if (res.status === 200) {
        Navigate('/cubicles?consultantTrue=' + res.data)
        console.log(res)
      } else { Navigate('/cubicles?consultantTrue=' + res.data) }
    }).catch((res) => {
      console.log(res.response)
      if (res.response.status === 400) {
        // Navigate('/cubicles')
      } else if (res.response.status === 401) {
        // TOAST SOMETHING WRONG SAME PERSON
      }
    })
  }

  const [mappas, setmappas] = useState()

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const [first, setfirst] = useState(false)






  useEffect(() => {
    axios
      .post("/api/User/prospacesprofile", {
        Ac
      })
      .then((res) => {
        setGetval(res.data[0]);
        console.log(res.data[0]);

        // console.log(res.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);






 
  useEffect(() => {
    console.log(forume);
  }, [forume]);



const [first8, setfirst8] = useState(false)



  

  



  const [Content, setContent] = useState(
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

 


  const [Final, setFinal] = useState();
  const [FinalVideo, setFinalVideo] = useState();




  return (
    <>
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border whgiowhroighsoihlznvlsnd">
          <div className="row">
            <div className="col-md-4">
              <div className="profilee-img">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={getval.link}
                  alt=""
                />
                {/* <div className="file btn btn-lg btn-primary">
                  Change Photo
                  <input onChange={(e)=>changeDp(e.target.files[0])} type="file" name="ProfilePic" accept="image/*"/>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="profile-head mt-3">
              <div className="d-none d-md-block"> <h5>{getval.pname?getval.pname.toUpperCase():''}</h5></div>
               <div className="d-block d-md-none"> <center><h5>{getval.pname?getval.pname.toUpperCase():''}</h5></center></div>
                <div className="row">
                  <div className="col-md-9">
                  <div className="d-none d-md-block">
                    <h6>
                        {getval.pname?'interaction. information. inspiration.':''}
                        
                    
                      <br />
                    </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center> <h6>
                      {getval.pname?'interaction. information. inspiration.':''}

                      <br />
                    </h6></center>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {/* <Link to="/My_profile_productstore/Edit_profile_p">
                      <input
                        type="submit"
                        className="profile-edit-btn"
                        name="btnAddMore"
                        value="Edit Profile"
                      />
                    </Link> */}
                  </div>
                </div>
               <center>
               <div className="d-flex justify-content-center">
                  <div className="">
                   <div className="me-3">
                   <input
                        type="button" onClick={(e) => { communicate() }}
                        className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                        style={{'borderRadius':'25px'}}
                        name="btnAddMore"
                        value="Communicate"
                      />
                   </div>

                  </div>       
                <div className="">
                {!Follower ? (<>
                <button
                  type="button"
                  className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                        style={{'borderRadius':'25px'}}
                  onClick={(e) => follow(e, idd)}
                >
                  Follow
                </button></>
              ) : (<>
                <button
                  type="button"
                  className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                        style={{'borderRadius':'25px'}}
                  onClick={(e) => unfollow(e, idd)}
                >
                  UnFollow
                </button></>
              )}
                </div>
                </div>
               </center>
               

              </div>
            </div>
            <center className='pt-4'>
            <div className="" style={{'maxWidth':'800px','textAlign':'left'}}>
           
           
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                <b>Contact Information</b>
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                {getval.email?
                <div className="row" >
                <div className="col-md-6">
                  <span>Email Id</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}>{getval.email?getval.email:''}</label>
                </div>
              </div>
                :<></>}
                 
                
                </Typography>
              </AccordionDetails>
            </Accordion>
           
           
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Timeline {first8?first8>1?'('+first8+ ' posts)':'('+first8+ ' post)':''}</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <TimeLineExternal acid={Acc} setfirst8={setfirst8}/>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {getval.followers?getval.followers.length==0?<></>
: <Accordion expanded={expanded === 'panel7'} onChange={handleChange('pane20')}>
<AccordionSummary
 
  aria-controls="panel4bh-content"
  id="panel4bh-header"
>
  <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Followers {getval.followers?getval.followers.length:''}</b></Typography>
</AccordionSummary>

</Accordion>:''}

            </div></center>
          </div>
          <div className="row">
            <div className="col-md-4">
             
            </div>
            <div className="col-md-8">
           
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Adminpexternal;
