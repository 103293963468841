import React, { useEffect, useRef, useState } from "react";
// import './SearchToolbar.css'
import { BiReset } from "react-icons/bi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { JobSeekersList } from "./jobSeekerList";
import { ExistingData, InitializeCountry } from "../../Utility/Country";
import { useSelector } from "react-redux";

export const JobSearch = (props) => {
  const [cat, setCat] = useState([]);
  const [data, setdata] = useState(false);
  const [load, setload] = useState(true);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [SearchValidationError, setSearchValidationError] = useState(false);
  const [SearchValidated, setSearchValidated] = useState(false);
  const [Stateload, setStateload] = useState(true);
  const [error, seterror] = useState("");
  const [err, setError] = useState("");
  const [output, setoutput] = useState("");
  let userData = useSelector((state) => state.user);

  const [Search, setSearch] = useState({
    Country: userData.value.Country ? userData.value.Country : "",
    Category: "",
    Subcategory: "",
    State: "",
    City: "",
    Experience: "",
    Workplace: "",
    Emptype: "",
  });
  const [value, setValue] = useState();
  let navigate = useNavigate();

  const sabari = (e) => {
    console.log(e.target.value);
    var anadhan;
    setSearch({ ...Search, Category: e.target.value,Subcategory:"" });
    // setValue({ ...value, category: e.target.value })
    cat.forEach((element) => {
      console.log(element._id);
      if (element._id === e.target.value) {
        anadhan = element.sub.map((elements) => {
          return <option value={elements._id}>{elements.sub}</option>;
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };

  useEffect(() => {
    axios
      .get("/api/api/getcategory")
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setCat(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          navigate("/");
        }
        console.log(err.response.status);
      });
  }, []);

  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
      console.log(cat);
    } else {
      console.log("nothing");
    }
  }, [cat]);
  useEffect(() => {
    setStateload(false);
  }, []);

  async function SearchHandler() {
    // console.log(setSearchValidated)

    console.log("hii" + Search.Pin.length);
    if (Search.Pin.length > 0) {
      if (Search.Pin.length == 6) {
        console.log("no error");
        setSearchValidationError(false);
        return true;
      } else {
        console.log(" errro");
        setSearchValidationError("Please Enter a valid Pincode");
        return false;
      }
    } else {
      setSearchValidationError(false);
      return true;
    }
  }

  async function SearchHandlerX(e) {
    setSearchValidated(true);
    var status = SearchHandler();
    if (!status) {
      e.preventDefault();
    } else {
      console.log("here we go !");
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (SearchValidated) {
      SearchHandler();
    }
  }, [Search.Pin]);

  useEffect(() => {
    setStateload(false);
  }, []);

  function validation() {
    if (Search.Category.length > 0) {
      setError("");
    } else {
      return setError("Select a category");
    }
    if (Search.Subcategory.length > 0) {
      setError("");
    } else {
      return setError("Select a Subcategory");
    }
    // if (Search.State.length > 0) {
    //     setError('')
    // }
    // else {
    //     return setError('Select a State')
    // }

    // if (Search.Pin.length == 6 || Search.Pin.length== 0) {
    //     setError('')
    // }
    // else {
    //     return setError('invalid pincode')
    // }

    return true;
  }

  const [Loding, setLoding] = useState(false);
  const knvpins = () => {
    // console.log(Search)
    var status = false;
    // setSubmit(true)
    //
    // setLoading
    setLoding(true);
    status = validation();

    if (status == true) {
      setload(true);

      // document.getElementById('gtrgtr').classList.remove('d-none')
      axios
        .post("/api/User/findseeker", { Search })
        .then((res) => {
          console.log(res.data);
          const postSummaries = res.data.map((post) => ({
            id: post._id,
            key: post.id,
            data: post,
          }));
          setdata(postSummaries);
          setload(false);
          setLoding(false);
        })
        .catch((err) => {
          console.log(err);
        }, setLoding(false));
    }
  };

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    if (load) {
      //         setoutput(<><center><div className="spinner-border " role="status">
      //     <span className="visually-hidden">Loading...</span>
      // </div></center></>)
    } else {
      if (data.length > 0) {
        setoutput(
          data.map((number) => (
            <JobSeekersList key={number.id} data={number.data}></JobSeekersList>
          ))
        );
        console.log("haiii");
      } else {
        setoutput(<h3 className="text-center">No Result Found</h3>);
      }
    }
  }, [error, load]);

  useEffect(() => {
    if (Loding) {
      setoutput(
        <>
          <center>
            <div className="spinner-border text-dark " role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </center>
        </>
      );
    }
  }, [Loding]);

  let countryRef = useRef();
  let stateRef = useRef();
  let cityRef = useRef();
  useEffect(() => {
    ExistingData(countryRef, stateRef, cityRef, userData.value.Country, "");

    InitializeCountry(countryRef, stateRef, cityRef);
    return () => { };
  }, []);


  return (
    <div className="col-12 py-4 pt-3">
      <br />
      <hr className="mt-1 hrclass" />
      <p className="mb-1 text-center" style={{ fontSize: 14 }}>
        SEARCH FOR CANDIDATES AS PER YOUR REQUIREMENT
      </p>
      <div className="container-fluid p-0  pt-0">
        <form onSubmit={(e) => SearchHandlerX(e)} action="" method="">
          {/* <div className="p-0 py-1">

                        <div className="container-fluid Scont p-0 ">
                            <input type="text" value={Search.Value} onChange={(e) => setSearch({ ...Search, Value: e.target.value })} placeholder="Search" name="searchInput" className="searchInput px-4"></input>
                            <button type="submit" className="searchIco" >   <BsSearch size="30" color="white" /></button>
                        </div>
                    </div> */}

          <div id="AdvancedSearch" className="bg-light p-2 ">
            <div>
              <p data-bs-toggle="">Advanced Search Filters</p>
            </div>

            <div className=" container-fluid p-0">
              <div className="row g-1">
                <div className="col-12 col-md-3 ">
                  <div className="form-floating">
                    <select
                      id="sub"
                      className="form-select"
                      value={Search.Category}
                      onChange={(e) => sabari(e)}
                    >
                      <option value="" disabled selected></option>
                      {category ? category : ""}
                    </select>
                    <label htmlFor="Sub">Category</label>
                  </div>
                </div>
                <div className="col-12 col-md-3 ">
                  <div className="form-floating">
                    <select
                      name="sub"
                      className="form-select"
                      id="sub"
                      value={Search.Subcategory}
                      onChange={(e) =>
                        setSearch({ ...Search, Subcategory: e.target.value })
                      }
                    >
                      <option value="" disabled selected></option>
                      {subcategory ? subcategory : ""}
                      {/* <option>Male</option>
                                            <option>Female</option> */}
                    </select>{" "}
                    <label htmlFor="Experience">Sub Category</label>
                  </div>
                </div>
                <div className="col-6 col-md-3 ">
                  <div className="form-floating">
                    <select
                      value={Search.Country}
                      ref={countryRef}
                      onChange={(e) => setSearch({ ...Search, Country: e.target.value, state: '', city: '' })}
                      name="City"
                      className="form-select"
                    ></select>
                    <label htmlFor="State">Country</label>
                  </div>
                </div>
                <div className="col-6 col-md-3 ">
                  <div className="form-floating">
                    <select
                      value={Search.State}
                      ref={stateRef}
                      onChange={(e) => setSearch({ ...Search, State: e.target.value, city: '' })}
                      name="City"
                      className="form-select"
                    ></select>
                    <label htmlFor="State">State</label>
                  </div>
                </div>
                <div className="col-6 col-md-3 ">
                  <div className="form-floating">
                    <select
                      name="City"
                      ref={cityRef}
                      className="form-select"
                      value={Search.City}
                      onChange={(e) =>
                        setSearch({ ...Search, City: e.target.value })
                      }
                    ></select>
                    <label htmlFor="City">City</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="51"
                      onChange={(e) =>
                        setSearch({ ...Search, gender: e.target.value })
                      }
                    >
                      <option value="none" selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option value={null}>Any</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Transgender</option>
                    </select>
                    <label htmlFor="51">Gender</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="52"
                      onChange={(e) =>
                        setSearch({ ...Search, age: e.target.value })
                      }
                    >
                      <option value="none" selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option value="">Any</option>
                      <option value="18 to 29">18 to 29</option>
                      <option value="30 to 45">30 to 45</option>
                      <option value="46 to 60">46 to 60</option>
                    </select>
                    <label htmlFor="52">Age</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="53"
                      onChange={(e) =>
                        setSearch({ ...Search, Employed: e.target.value })
                      }
                    >
                      <option value="none" selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option value="any">Any</option>
                      <option value={true}>Employed</option>
                      <option value={false}>Unemployed</option>
                    </select>
                    <label htmlFor="53">Occupational Status</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="54"
                      onChange={(e) =>
                        setSearch({ ...Search, Immediate: e.target.value })
                      }
                    >
                      <option value="none" selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option value="any">Any</option>
                      <option value={true}>Immediate</option>
                      <option value={false}>Not Immediate</option>
                    </select>
                    <label htmlFor="54">Availablity</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="55"
                      onChange={(e) =>
                        setSearch({ ...Search, Workplace: e.target.value })
                      }
                    >
                      <option value={null} selected>
                        {" "}
                        &nbsp;{" "}
                      </option>
                      <option>On-site</option>
                      <option>Remote</option>
                      <option>Hybrid</option>
                    </select>
                    <label htmlFor="55">Work Mode</label>
                  </div>
                </div>
                <div className="col-6 col-md-3  ">
                  <div className="form-floating">
                    <select
                      className="form-select adserjob"
                      id="56"
                      value={Search.Emptype}
                      onChange={(e) =>
                        setSearch({ ...Search, Emptype: e.target.value })
                      }
                    >
                      <option value={null} selected></option>
                      {/* <option value="" disabled selected>
                                Select Employment Type</option> */}
                      <option>Full-time</option>
                      <option>Part-time</option>
                    </select>
                    <label htmlFor="56">Employment Type </label>
                  </div>
                </div>
                <div className="col-6 col-md-3 ">
                  <div className="form-floating">
                    <select
                      name="Experience"
                      className="form-select"
                      id="Experience"
                      value={Search.Experience}
                      onChange={(e) =>
                        setSearch({ ...Search, Experience: e.target.value })
                      }
                    >
                      <option value={null} selected></option>

                      <option value="1">Intern</option>
                      <option value="2">Fresher</option>
                      <option value="3">0-1 year</option>
                      <option value="4">1-5 years</option>
                      <option value="5">5-10 years</option>
                      <option value="6">10+ years</option>

                      {/* <option>Male</option>
                                            <option>Female</option> */}
                    </select>{" "}
                    <label htmlFor="Experience">Experience</label>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end  p-2  ">
                  <button
                    className="btn btnjui2 ps-4 me-1 pe-4 "
                    type="button"
                    onClick={(e) => props.setreset(props.reset + 1)}
                  >
                    <BiReset size={25} />
                  </button>
                  <button
                    className="btn btnjui  "
                    type="button"
                    onClick={(e) => knvpins(e)}
                  >
                    Go
                  </button>
                </div>

                <span>
                  {SearchValidationError ? (
                    <div className="alert alert-danger" role="alert">
                      {SearchValidationError}
                    </div>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {/* <input type="submit" value="Search" className="btn btn-success"></input> */}
            </div>
            <span style={{ color: "red" }}>{err ? err : ""}</span>
          </div>
        </form>
        <div className="row pt-4">{output}</div>
      </div>
    </div>
  );
};
