
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { FaLock } from "react-icons/fa"
import axios from "axios";
import moment from 'moment'
import Moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Rtext } from './messages/RTEXT.js'
import { Stext } from './messages/STEXT.js'
import { SVideo } from './messages/SVIDEO.js'
import { RVideo } from './messages/RVIDEO.js'
import { Rinvite } from './messages/RINVITE'
import { Sinvite } from "./messages/SINVITE.js";
import { keyGen } from './../../Utility/helper'
import { BiUnderline } from "react-icons/bi";
let prev = null
var room
let topicRev
let topicIn
let dprev = null
const fcheck = (ev) => {
    if (ev == prev) {
        return false
    } else {
        prev = ev
        return true
    }
}





export function ChatGround(props) {

    const [loader, setloader] = useState(<div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>)
    const [LastMessId, setLastMessId] = useState()
    let [LoadedMessages, setLoadedMessages] = useState([])
    const [User, setUser] = useState()
    const [outpot, setoutpot] = useState([])
    const [outpotT, setoutpotT] = useState([])
    const [uid, setuid] = useState()
    const [Text, setText] = useState('')
    const [RoomNow, setRoomNow] = useState(props.RoomDetails.id)
    const [LastD, setLastD] = useState()
    const [FetchStatus, setFetchStatus] = useState(false)
    // 
    const [FetchedMessages, setFetchedMessages] = useState([])
    const [SocketMessages, setSocketMessages] = useState([])

    const InvitationResponse = async (id, stat, Tom) => {
        // ()=> Perform invitation responses to join group
        return new Promise(async (resolve, reject) => {


            if (id && stat) {
                let dataSend = {
                    id,
                    stat
                }
                document.getElementById('Tommy' + Tom).classList.add('d-none')
                try {
                    var send = await axios.post('/api/user/InvitationController', { dataSend })
                    resolve(send)
                    return
                    if (send) {
                        // console.log(stat)
                        // console.log(send)
                        if (stat === 'accept') {
                            // console.log('accepted')
                            props.ReloadList()
                        } else {
                            // console.log('no')
                        }
                    }
                }
                catch (err) {
                    reject(err)
                    // console.log(err)
                }
            }
        })
    }

    const notify = (message) =>
        toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    const singleLoader = () => {
        // ()=> Fetch messages for personel chat on load
        room = props.RoomDetails.id
        axios.post('/api/User/messages', { room }).then((res) => {
            console.log(res.data)
            let da = null
            let la
            if (res.data.length > 0) {
                setFetchedMessages((prev) => [...prev, ...res.data])
            }
            setLastMessId(res.data[res.data.length - 1].createdAt)
        }).catch((re) => {
            // console.log(re)
        })

    }
    const groupLoader = () => {
        // Fetch message for group chats
        room = props.RoomDetails.id
        let Topic = props.RoomDetails.topic
        let dataSend = {
            Room: room,
            Topic
        }
        let da = null
        let la
        axios.post('/api/User/messagesGroup', { dataSend }).then((res) => {
            // console.log(res.data)
            if (res.data.length > 0) {
                {
                    setFetchedMessages((prev) => [...prev, ...res.data])

                }
                setLastMessId(res.data[res.data.length - 1].createdAt)

            }
        }).catch((re) => {
            // console.log(re)
        })

    }
    useEffect(() => {
        setUser(localStorage.getItem('User'))
        setFetchStatus(false)
        setLastMessId('')
        setuid('')
        setLastD('')
        setoutpot([])
        setoutpotT([])
        setFetchedMessages([])
        setShowMessages([])
        room = props.RoomDetails.id
        if (props.RoomDetails.id) {
            if (!props.RoomDetails.group) {
                singleLoader()
            } else {
                groupLoader()
            }

        }
    }, [props.RoomDetails.id])

    useEffect(() => {
        setLastD('')
        setFetchStatus(false)
        setLastMessId('')
        setuid('')
        setoutpot([])
        setoutpotT([])
        groupLoader()
        setFetchedMessages([])
        setShowMessages([])

        topicIn = props.RoomDetails.topic
    }, [props.RoomDetails.topic])
    const deleteFromList = (key) => {
        console.log(key)
        let temp = [...outpotT]
        console.log(temp)
        console.log(outpot)
        console.log(outpotT)

    }
    useEffect(() => {
        // Render new message from socket
        console.log(uid)
        if (uid) {
            var message = uid
            if (!props.RoomDetails.group) {
                if (User != uid.sender._id) {
                    // check author of message
                    // console.log(props)
                    // console.log(props.RoomDetails.id)
                    if (!room)
                        return
                    if (message.chat._id === room) {
                        setFetchedMessages((prev) => [uid, ...prev])
                        return
                    }
                }
            } else {
                console.log('group')
                if (User != uid.sender._id) {
                    if (props.RoomDetails.topic === message.Topic) {
                        console.log('same opic')
                        if (message.chat._id === room) {
                            console.log('same room')
                            setFetchedMessages((prev) => [uid, ...prev])
                            return
                        } else {
                            console.log('dofroom')
                        }
                    } else {
                        console.log('dif topic')
                    }
                }
            }
        }
        return
        if (uid) {
            var message = uid
            if (!props.RoomDetails.group) {
                // personel chat
                if (User != uid.sender._id) {
                    // check author of message
                    // console.log(props)
                    // console.log(props.RoomDetails.id)
                    if (!room)
                        return
                    if (message.chat._id === room) {
                        // check message belong to current roon
                        var arr = []
                        var sp
                        // check type of message and assign to component
                        if (message.Type === 'TEXT') {
                            sp = <Rtext listId={message._id} key={message._id} data={message} RoomDetails={props.RoomDetails} socket={props.socket} />
                            setoutpotT([sp, ...outpotT])
                        }
                        else if (message.Type === 'VIDEO') {
                            sp = <RVideo data={message} />
                            setoutpotT([sp, ...outpotT])
                        } else if (message.Type === 'INVITE') {
                            console.log('jjj')
                            sp = <Rinvite data={message} />
                            setoutpotT([sp, ...outpotT])
                        }
                    }
                } else {
                    // render if author of message is user
                    // return
                    // {
                    //     Type: 'VIDEO',
                    //     sender: {
                    //         pname: 'ananda'
                    //     },
                    //     content: 'ananda',
                    //     createdAt: 'sadasd'
                    // }
                    var arr = []
                    var sp

                    if (message.Type === 'TEXT') {
                        sp = <Stext deleteFromList={deleteFromList} listId={message._id} key={message._id} socket={props.socket} data={message} />
                        setoutpotT([sp, ...outpotT])
                    }
                    else if (message.Type === 'VIDEO') {
                        sp = <SVideo data={message} />
                        setoutpotT([sp, ...outpotT])
                    }
                }
            } else {
                // group chat
                // console.log('group message detected')
                // console.log(message.Topic, props.RoomDetails.topic)

                if (message.Topic === props.RoomDetails.topic) {
                    // checking topic of message is current topic
                    // console.log('this topic')
                    if (User != uid.sender._id) {
                        // check author of message is not user
                        // console.log('not me')

                        // console.log(props)
                        // console.log(props.RoomDetails.id)
                        if (!props.RoomDetails.id)
                            return
                        if (message.chat._id === props.RoomDetails.id) {
                            // console.log('working hereeeeeeeeeeeeeeeeeeeee')
                            // console.log(message.chat._id)
                            // console.log(props.RoomDetails.id)

                            var arr = []
                            var sp
                            if (message.Type === 'TEXT') {
                                sp = <Rtext key={message._id} data={message} listId={message._id} socket={props.socket} RoomDetails={props.RoomDetails}></Rtext>

                                setoutpotT([sp, ...outpotT])
                            }
                            else if (message.Type === 'VIDEO') {
                                sp = <div className="row">
                                    <div className="col-10 ps-4 ">
                                        <div className="  p-1 m-2">
                                            <div className="row ps-3 ">
                                                <div className="col-12 p-0 pb-1 m-0">
                                                    {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp" alt="avatar"
                                                        className="rounded-circle chatAvatar me-2 align-self-center  shadow-1-strong" width="30" /> */}
                                                    {message.sender.pname}
                                                </div>
                                                <div className="m-0 ms-0 p-0">

                                                    <div className="row p-0">
                                                        <div className="p-0 ms-3 mesbox">
                                                            <p className="message mb-0 text-light bg-secondary px-3">
                                                                <small>
                                                                    <video className="" controls> <source src={message.content} />  </video>
                                                                </small>
                                                            </p>

                                                            <p className="mt-0  pe-4"> {Moment(message.createdAt ? message.createdAt.slice(0, 24) : '').format(' h:mm:ss a')}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                    </div>
                                </div>

                                setoutpotT([sp, ...outpotT])
                            }

                        }

                    } else {
                        // console.log('m messae')
                        // return
                        // {
                        //     Type: 'VIDEO',
                        //     sender: {
                        //         pname: 'ananda'
                        //     },
                        //     content: 'ananda',
                        //     createdAt: 'sadasd'
                        // }
                        var arr = []
                        var sp

                        if (message.Type === 'TEXT') {
                            sp = <Stext deleteFromList={deleteFromList} listId={message._id} key={message._id} socket={props.socket} data={message} />
                            setoutpotT([sp, ...outpotT])

                        }
                        else if (message.Type === 'VIDEO') {
                            sp = <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-10 pe-5 d-flex justify-content-end">
                                    <div className="   p-1 m-2" >
                                        <div className="row ps-3  text-end  ">
                                            <div className="col-12 p-0 pb-1 m-0">

                                                {message.sender.pname}
                                            </div>
                                            <div className="m-0 p-0" >
                                                <div className="row p-0 d-flex justify-content-end">
                                                    <div className="p-0 col-12" >
                                                        <p className="message mb-0 text-end text-light bg-secondary px-3">
                                                            <small>
                                                                <video width={'150px'} className="" controls> <source src={message.content} />  </video>
                                                            </small>
                                                        </p>
                                                        {/* <p className="mt-0  pe-4"> {datey(element.createdAt)}</p> */}
                                                        <p className="mt-0  pe-4"> {Moment(message.createdAt ? message.createdAt.slice(0, 24) : '').format(' h:mm:ss a')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            setoutpotT([sp, ...outpotT])
                        }

                    }
                } else {
                    // // console.log('differernt topic')
                }
            }

        }
    }, [uid])
    useEffect(() => {
        // console.log(props)
        setText('')
    }, [props])
    useEffect(() => {
        if (props.RoomDetails.id != RoomNow) {
            // console.log('changed')
        }
    }, [])
    var lastMes
    useEffect(() => {
        props.socket.on('message recieved', (val) => {
            var op = fcheck(JSON.parse(JSON.stringify(val)))
            console.log(val)
            if (!op) {
                return
            }
            setuid(val)
        })
        props.socket.on('RoomLockedByNow', (val) => {
            // // console.log(val)
            if (props.RoomDetails.id === val.roomId) {
                if (val != topicRev) {
                    RoomLockedByNow(val)
                    topicRev = val
                }
            }
        })
        props.socket.on('DeletedMessage', (val) => {
            // // console.log('deleted message')
            if (dprev != val) {
                dprev = val
                DeleteMessageCallFromHell(val)
            }
        })
    }, [])
    const DeleteMessageCallFromHell = (val) => {
        console.log(val)
        // console.log(props.RoomDetails.messid)
        try {

            if (document.getElementById('mes' + val.messid)) {
                document.getElementById('mes' + val.messid).click()
            }
        }
        catch (err) {
            // console.log(err)
        }
        // console.log(val)
    }
    const RoomLockedByNow = (id) => {
        // console.log(id)
        // console.log(props.RoomDetails)
        // console.log(topicIn)

        if (topicIn === id.Topic_id) {
            props.Changetopic('', '')
            props.ChangerToHeader()
            // alert(id.Topic_id + 'locked')
            notify("cubicle is locked by Admin !");
        }
    }
    const sendTextNow = async (t) => {
        // console.log('trig')
        if (t)
            if (t.keyCode != 13)
                return
        if (Text.trim().length === 0)
            return
        // props.socket.emit("getMesswith", Text);
        let Message = {
            sender: '',
            content: Text,
            chat: props.RoomDetails.id,
            Type: 'TEXT',
            readBy: []
        }
        setText('')
        var data = await axios.post('/api/User/sendMessage', { Message })
        if (data) {
            setuid(data.data)
            props.socket.emit('getMesswith', data.data)
            setFetchedMessages((prev) => [data.data, ...prev])

        }
    }
    const sendTextNowGroup = async (t) => {
        // console.log('triggered')
        // console.log(t)
        if (t)
            if (t.keyCode != 13)
                return
        if (Text.trim().length === 0)
            return
        // console.log('reach')
        // props.socket.emit("getMesswith", Text);
        let Message = {
            sender: '',
            content: Text,
            chat: props.RoomDetails.id,
            Topic: props.RoomDetails.topic,
            Type: 'TEXT'
        }
        setText('')
        try {
            var data = await axios.post('/api/User/sendMessage', { Message })
            if (data) {
                // console.log('send success')
                // setuid(data.data)
                setFetchedMessages((prev) => [data.data, ...prev])
                props.socket.emit('getMesswith', data.data)
            }
        }
        catch (er) {
            notify("Cubicle locked !");
            props.ChangerToHeader()
            // console.log("cubicle is locked!!")
        }
    }
    const FetchMessage = (last) => {
        console.log(props.RoomDetails, LastMessId)
        // 
        if (props.RoomDetails.group) {
            // console.log(props.RoomDetails)
            let dataSend = {
                Room: props.RoomDetails.id,
                topic: props.RoomDetails.topic,
                last: LastMessId
            }
            return new Promise(function (resolve, reject) {
                axios.post('/api/User/getMessageGroupX', { dataSend }).then((res) => {
                    console.log(res)
                    if (res.data.length > 0) {
                        setFetchedMessages((prev) => [...prev, ...res.data])
                        setLastMessId(res.data[res.data.length - 1].createdAt)
                        return resolve()

                    } else {
                        reject(setFetchStatus(false))
                    }
                }).catch((re) => {
                    console.log(re)
                })

            });
        } else {

            room = props.RoomDetails.id
            let dataSend = {
                room,
                last: LastMessId
            }
            return new Promise(function (resolve, reject) {
                axios.post('/api/User/getMessageX', { dataSend }).then((res) => {
                    console.log(res.data)
                    if (res.data.length > 0) {

                        setFetchedMessages((prev) => [...prev, ...res.data])
                        setLastMessId(res.data[res.data.length - 1].createdAt)
                        return resolve()

                    } else {
                        reject(setFetchStatus(false))
                    }
                }).catch((re) => {
                    console.log(re)
                })

            });
        }

    }
    const AutoLoadListen = async (e) => {
        try {
            let P = Math.abs(e.target.scrollTop * 100 / (e.target.scrollHeight - e.target.clientHeight))
            P = Math.round(P)
            if (P > 80) {
                if (!FetchStatus) {
                    setFetchStatus(true)
                    let ans = await FetchMessage()
                    setFetchStatus(false)
                    console.log(ans)
                    if (!ans) return
                    if (ans.length === 0) {
                        return
                    }
                    // setoutpot([...outpot, ...ans])
                    // setFetchedMessages((prev) => [...ans, ...prev])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const spaceDrop = <div style={{ 'visibility': 'hidden', 'height': '10px' }} className="row">
        <div className="col-10 ps-4 d-flex justify-content-end">
            <div className="  p-1 m-2">
                <div className="row ps-3 ">
                    <div className="col-12 p-0 pb-1 m-0">
                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp" alt="avatar"
                            className="rounded-circle chatAvatar me-2 align-self-center  shadow-1-strong" width="30" />
                        Ananda krishnan
                    </div>
                    <div className="m-0 p-0">
                        <div className="row p-0">
                            <div className="p-0 mesbox">
                                <p className="message mb-0 text-light bg-secondary px-3">
                                    <small>
                                        Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry.
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of
                                        type and scrambled it to make a type
                                        specimen book. It has survived not
                                        Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry.
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of
                                        type and scrambled it to make a type
                                        specimen book. It has survived not
                                    </small>
                                </p>
                                <p className="mt-0 text-end pe-4">2021-12-02 | 10.20 pm</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="col-2">
        </div>
    </div>
    useEffect(() => {
        console.log(outpot)
        return () => {

        }
    }, [outpot])
    let socket = 's'

    const [ShowMessages, setShowMessages] = useState([])
    useEffect(() => {
        if (FetchedMessages.length) {
            showit()
        }
        console.log(FetchedMessages)
        return () => {

        }
    }, [FetchedMessages])

    let last
    const showit = () => {
        console.log(FetchedMessages)
        let temp = []
        let lastDate
        let s = FetchedMessages.map((elem, index) => {
            if (index === 0) {
                lastDate = moment(elem.createdAt).format('MMMM Do YYYY')
            }
            console.log(lastDate)
            let cdate = moment(elem.createdAt).format('MMMM Do YYYY')
            console.log(cdate)
            if (cdate != lastDate) {
                console.log(moment().format('MMMM Do YYYY'))
                if (moment().format('MMMM Do YYYY') === lastDate) {
                    lastDate = cdate
                    return [elem, {
                        Type: 'date',
                        content: 'TODAY'
                    }]
                } else {
                    let sup = lastDate
                    lastDate = cdate
                    console.log(sup)
                    return [elem, {
                        Type: 'date',
                        content: sup
                    }]
                }
            } else {
                return elem
            }
        })
        console.log(s)
        s = s.flat()
        setShowMessages(s)
    }

    useEffect(() => {
        console.log(ShowMessages)

        return () => {

        }
    }, [ShowMessages])

    let tempdate = undefined
    let ddate = null
    let lastdate = null
    if (props.RoomDetails.group) {
        // IN GROUP
        if (props.RoomDetails.topic) {
            // IN TOPIC
            return (
                <div className="col-12 s m-0 p-0 ">
                    <div className="chatspace bg-light" onScroll={(e) => AutoLoadListen(e)} >
                        {spaceDrop}
                        {!props.RoomDetails.id && <div class="d-flex justify-content-center"><div className='mb-5' >Start a chat!</div></div>}
                        {
                            ShowMessages.map((elem, index) => {

                                if (elem.Type === 'date') {
                                    return <p className="text-center">{elem.content}</p>
                                }
                                if (elem?.sender?._id == User) {
                                    // send
                                    if (elem.Type === 'TEXT') {
                                        return <Stext key={elem._id} listId={elem._id} socket={props.socket} data={elem} />
                                    }
                                    else if (elem.Type === 'VIDEO') {
                                        return <SVideo key={elem._id} data={elem} />
                                    }
                                    else if (elem.Type === 'INVITE') {
                                        if (elem.Show)
                                            return <Sinvite key={elem._id} index={index} data={elem} />
                                    }
                                } else {
                                    if (elem.Type === 'TEXT') {
                                        return <Rtext key={elem._id} listId={elem} data={elem} socket={props.socket} RoomDetails={props.RoomDetails}></Rtext>
                                    }
                                    else if (elem.Type === 'VIDEO') {
                                        return <RVideo key={elem._id} data={elem} />
                                    }
                                    else if (elem.Type === 'INVITE') {
                                        if (elem.Show)
                                            return <Rinvite index={index} key={elem._id} data={elem} />
                                    }
                                }
                            })
                        }
                        {/* {outpotT ? outpotT : ''} */}
                        {/* {!outpot?loader:''}   */}
                        {/* {outpot ? outpot : ''} */}
                        {/* <button onClick={(e) => trt()}>test</button> */}
                        <div className="px-5 mt-2">  <p className="border encMess px-3 py-1 bg-secondary text-light "><FaLock /> {' '}Cubicle chats in Prochure are completely Encrypted. Neither Prochure nor anyone who is not a part of this Cubicle can read these chats.</p></div>
                        {FetchStatus ? <div className="d-flex justify-content-center"> <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div></div> : <></>}
                    </div>

                    {props.RoomDetails.id ?
                        <>  <div className="chatconsole row d-none d-md-block">
                            <div className='col-12 d-flex  align-items-center'>
                                <div className='input-group pt-3'>
                                    <input type='text' className='form-control' value={Text} onChange={e => setText(e.target.value)} onKeyDown={(e) => sendTextNowGroup(e)} placeholder='Type a message !' aria-label="Recipient's username" aria-describedby='button-addon2' />
                                    <button className='btn iopoioipoipoipoipoi' onClick={(e) => sendTextNowGroup()} type='button' id='button-addon2'>Send</button>
                                </div>
                            </div>
                        </div>

                            <div className="chatconsolePro row  d-block d-md-none">
                                <div className='col-12 d-flex  align-items-center'>
                                    <div className='input-group '>
                                        <input type='text' className='form-control' value={Text} onChange={e => setText(e.target.value)} onKeyDown={(e) => sendTextNowGroup(e)} placeholder='Type a message !' aria-label="Recipient's username" aria-describedby='button-addon2' />
                                        <button className='btn pro-spaces-button4' onClick={(e) => sendTextNowGroup()} type='button' id='button-addon2'>Send</button>
                                    </div>
                                </div>
                            </div>

                            <ToastContainer
                                position="bottom-left"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </>
                        : <div className="chatconsole ">
                            <div className='col-12 d-flex  align-items-center'>

                            </div>
                        </div>}
                </div>
            )
        }
        else {
            // NO TOPIC
            return <div className="col-12 s m-0 p-0 ">
                <div className="chatspace bg-light">
                    {spaceDrop}

                    <center> <p className="cubmesss">Please select a Cubicle to start discussion
                    </p></center>
                </div>

                <div className="chatconsole ">
                    <div className='col-12 d-flex  align-items-center'>

                    </div>
                </div>
            </div>
        }

    } else {
        // user
        return (
            <div className="col-12 s m-0 p-0 ">
                <div id='cSpace' onScroll={(e) => AutoLoadListen(e)} className="chatspace bg-light">
                    {spaceDrop}
                    {/* {outpotT ? outpotT : ''} */}
                    {
                        ShowMessages.map((elem, index) => {
                            console.log(elem)
                            // ddate = moment(elem.createdAt).format('MMMM Do YYYY')
                            // if (index == 0) {
                            //     lastdate = ddate
                            // }
                            // console.log(ddate)
                            // if (lastdate != ddate) {
                            //     if (moment().format('MMMM Do YYYY') === lastdate) {
                            //         tempdate = 'Today'
                            //     } else {
                            //         tempdate = lastdate
                            //     }
                            //     lastdate = ddate
                            // } else {
                            //     tempdate = null
                            // }
                            // if (index + 1 === FetchedMessages.length) {
                            //     if (moment().format('MMMM Do YYYY') === lastdate) {
                            //         tempdate = 'Today'
                            //     } else {
                            //         tempdate = lastdate
                            //     }
                            // }

                            if (elem.Type === 'date') {
                                return <p className="text-center">{elem.content}</p>
                            }

                            if (elem?.sender?._id == User) {
                                // send
                                if (elem.Type === 'TEXT') {
                                    return <Stext key={elem._id} listId={elem._id} socket={props.socket} data={elem} />

                                }
                                else if (elem.Type === 'VIDEO') {
                                    return <SVideo key={elem._id} data={elem} />

                                }
                                else if (elem.Type === 'INVITE') {
                                    // if (elem.Show)
                                    return <Sinvite key={elem._id} data={elem} />

                                }
                            } else {
                                if (elem.Type === 'TEXT') {
                                    return <Rtext key={elem._id} data={elem} socket={props.socket} RoomDetails={props.RoomDetails}></Rtext>
                                }
                                else if (elem.Type === 'VIDEO') {
                                    return <RVideo key={elem._id} data={elem} />

                                }
                                else if (elem.Type === 'INVITE') {
                                    // if (elem.Show)
                                    return <Rinvite key={elem._id} data={elem} />

                                }
                            }
                        })
                    }
                    {/* {outpot ? outpot : ''} */}
                    <div className="px-5 mt-2">  <p className="border encMess px-3 py-1 bg-secondary text-light "><FaLock /> {' '}Cubicle chats in Prochure are completely Encrypted. Neither Prochure nor anyone who is not a part of this Cubicle can read these chats.</p></div>
                    {FetchStatus ? <div className="d-flex justify-content-center"> <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div></div> : <></>}
                    {!props.RoomDetails.id && <div class="d-flex justify-content-center WECOMECUB"><div className='mb-5 welcometocub' >Welcome to CUBICLES !</div></div>}


                    {/* <button onClick={(e) => trt()}>test</button> */}
                    {/* <div className="px-5 mt-2">  <p className="border px-3 py-1 bg-secondary text-light ">Cubicle chats in Pro-spaces are completely Encrypted. Neither Pro-spaces nor anyone who is not a part of this Cubicle can read these chats.</p></div>  */}

                </div>

                {
                    props.RoomDetails.id && !props.RoomDetails.lock && localStorage.getItem("Chat") === 'true' ?
                        <>
                            <div className="chatconsole row d-none d-md-block">
                                <div className='col-12 d-flex  align-items-center'>
                                    <div className='input-group pt-3'>
                                        <input type='text' className='form-control' value={Text} onChange={e => setText(e.target.value)} onKeyDown={(e) => sendTextNow(e)} placeholder='Type a message !' aria-label="Recipient's username" aria-describedby='button-addon2' />
                                        <button className='btn iopoioipoipoipoipoi' onClick={(e) => sendTextNow()} type='button' id='button-addon2'>Send</button>
                                    </div>
                                </div>
                            </div>

                            <div className="chatconsolePro row  d-block d-md-none">
                                <div className='col-12 d-flex  align-items-center'>
                                    <div className='input-group '>
                                        <input type='text' className='form-control' value={Text} onChange={e => setText(e.target.value)} onKeyDown={(e) => sendTextNow(e)} placeholder='Type a message !' aria-label="Recipient's username" aria-describedby='button-addon2' />
                                        <button className='btn pro-spaces-button4' onClick={(e) => sendTextNow()} type='button' id='button-addon2'>Send</button>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <div className="chatconsole ">
                            <div className='col-12 d-flex  align-items-center'>

                            </div>
                        </div>
                }

            </div >
        )
    }
}