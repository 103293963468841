import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { HiSpeakerphone } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";

import { Link } from "react-router-dom";
import { SearchComponent } from "./SearchComponent";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Mask } from "../../Utility/mask";
import { Navoutside } from "../Navoutside/Navoutside";
import { NewNavigationBarDissabled } from "../../Pages/Free/NewNavigationBar/NewNavigationBarDissabled";
import { ProfileCard } from "../ProfileCard/ProfileCard";

export function SearchResult() {
  const [open, setOpen] = useState(false);
  const [data, setdata] = useState(false);
  const [cat, setCat] = useState(false);
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");
  const [SearchOn, setSearchOn] = useState(false);
  const [Keyer, setKeyer] = useState(1);
  // id=JSON.parse(id)
  let [searchParams, setSearchParams] = useSearchParams();
  var id = Mask.decode(searchParams.get("Sub_id"));

  useEffect(() => {
    axios
      .post("/api/Free/consearching", {
        id,
      })
      .then((res) => {
        setCat(res.data);
        setResults(res.data.map((sr) => sr._id));
        const postSummaries = res.data.map((post) => ({
          id: post._id,
          key: post.id,
          data: post,
        }));
        setdata(postSummaries);
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {" "}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data.length > 0) {
        setoutput(
          data.map((number) => (
            <ProfileCard key={number.id} ids={makeid()} data={number.data} />

            // <SearchComponent
            //   key={number.id}
            //   ids={makeid()}
            //   data={number.data}
            // ></SearchComponent>
          ))
        );
        if (data.length > 0) {
          if (data.length === 1) {
            setDisplayNo(
              <center>
                <h4>Displaying&nbsp;{data.length}&nbsp;profile</h4>
              </center>
            );
          } else {
            setDisplayNo(
              <center>
                <h4>Displaying&nbsp;{data.length}&nbsp;profiles</h4>
              </center>
            );
          }
        }
      } else {
        setoutput(
          <center>
            <h4 style={{ fontWeight: "400", color: "#5A5A5A" }}>
              No profiles created yet
            </h4>
          </center>
        );
        setDisplayNo("");
      }
    }
  }, [error, load]);

  const [DisplayNo, setDisplayNo] = useState();

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [ShoutMass, setShoutMass] = useState();
  const [Results, setResults] = useState();
  const shoutOut = async () => {
    var dataSend = {
      sub_id: id,
      massage: ShoutMass,
      cat_id: localStorage.getItem("Cat"),
      Results,
    };
    // return
    var send = await axios.post("/api/User/shoutOut", { dataSend });
    if (send.status == 200) {
      // setViewTopicMember(data.data)
      // console.log(data.data)
      notify("SHOUT-OUT sent successfully", true);
    } else {
      notify("SHOUT-OUT sent failed", false);
    }
  };

  useEffect(() => {
    setResults();
  }, [Keyer]);

  return (
    <>
      <NewNavigationBarDissabled />

      <div className=" content">
        <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
          <div className={SearchOn ? " d-none" : " "}>
            {/* <!-- end row --> */}
            {/* <div className={"row"}> */}
            {DisplayNo}
            <div className="row car">{output}</div>
            {/* <!-- end col --> */}
            {/* </div> */}
          </div>
          {/* <!-- end row --> */}
        </div>
        {/* <!-- container --> */}

        <div
          className="modal fade"
          id="shoutoutmodal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Shout-Out
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  To help the clients / customers avoid the hassle of
                  approaching multiple Service Providers one-by-one, the client
                  / customer can SHOUT-OUT his/her query / requirement to the
                  Service Providers in a particular sub-category. This query
                  goes to ALL the Service Providers (of that sub-category) as a
                  Direct Message.
                </p>
                <p>
                  You can also send a SHOUT-OUT to only selected SERVICE
                  PROVIDERS after SHORT-LISTING them.
                </p>
                <textarea
                  rows={5}
                  className="form-control"
                  placeholder="max 500 characters"
                  maxLength="200"
                  onChange={(e) => setShoutMass(e.target.value)}
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn pro-spaces-button"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn pro-spaces-button3"
                  onClick={(e) => shoutOut()}
                  data-bs-dismiss="modal"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}
