
import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Link } from "react-router-dom";
import { AiFillFire, AiOutlineSearch } from "react-icons/ai";
import { FaLock } from "react-icons/fa"
import axios from "axios";
import moment from 'moment'
import { Button } from "react-bootstrap";
import Moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Last } from "react-bootstrap/esm/PageItem";

function highlightLink(sentence) {
    const linkRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+\.[^\s]+)|(\b(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,}\b)/g;
    const linkMatches = sentence.match(linkRegex);

    if (!linkMatches) {
        return sentence;
    }

    const parts = sentence.split(linkRegex);
    const highlightedSentence = parts.map((part, index) => {
        if (linkMatches.includes(part)) {
            if (part.startsWith('http') || part.startsWith('https')) {
                return <a href={part} key={index} target="_blank">{part}</a>;
            } else if (part.startsWith('www')) {
                return <a href={'https://' + part} key={index} target="_blank">{part}</a>;
            }
            else {
                return <a href={'https://www.' + part} key={index} target="_blank">{part}</a>;
            }
        } else {
            return part;
        }
    });

    return highlightedSentence;
}

export function Rtext(props) {
    const [Deleted, setDeleted] = useState(false)
    const DeleteMess = () => {
        console.log('del')
        let mes = props.data
        let no = axios.post('/api/User/AdmindeleteMessage', { mes }).then((res) => {
            props.socket.emit('DeleteMess', res.data)
            console.log(res.data)
            setDeleted(true)
        }).catch((ress) => {
            console.log(ress)
        })
    }
    var content = highlightLink(props.data.content)
    return (
        <div className={Deleted ? "row d-none" : "row "}>
            <button id={'mes' + props.data._id} className="d-none" onClick={(e) => setDeleted(true)} ></button>

            <div className="col-10 ps-4 ">
                <div className="  p-1 m-2">
                    <div className="row ps-3 ">
                        <div className="col-12 p-0 pb-1 m-0">
                            {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp" alt="avatar"
                                                        className="rounded-circle chatAvatar me-2 align-self-center  shadow-1-strong" width="30" /> */}

                            {props?.data?.sender?.pname==undefined?"Deleted Account":props.data.sender.pname}
                        </div>
                        <div className="m-0 ms-0 p-0">

                            <div className="row p-0">
                                <div className="p-0 ms-3 mesbox">
                                    <p className="message mb-0 text-light bg-secondary px-3">
                                        <small style={{ wordBreak: 'break-word' }} >
                                            {props.RoomDetails.group == true && props.RoomDetails.admin === true ?
                                                <div className="dropdown float-end  ">
                                                    <span className=" dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    </span>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" onClick={(e) => DeleteMess()}>Delete</a></li>
                                                    </ul>
                                                </div>
                                                : <></>}
                                            {content}&nbsp;&nbsp;&nbsp;&nbsp;
                                        </small>
                                    </p>
                                    <p className="mt-0  pe-4"> {Moment(props.data.createdAt ? props.data.createdAt.slice(0, 24) : '').format(' h:mm:ss a')}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-2">
            </div>
        </div>)
}