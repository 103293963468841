import axios from "axios"
import { useState, useReducer, useEffect, useRef } from "react"
import { Modal } from "react-bootstrap";
import { disablePastDate } from "../../Utility/helper";
import { BsThreeDotsVertical } from "react-icons/bs";
export const Events = (props) => {

   
    return (<div>
     <div className="d-none d-md-block">
     <button className="btn pro-spaces-button3 me-3" type="button">Add&nbsp;Event</button>
     <button className="btn pro-spaces-button3  " type="button">View&nbsp;Events</button>
     </div>

     <div className="d-block d-md-none">
     <div className="row">
        <div className="col-auto  ">
        <button className="btn pro-spaces-button3 px-4" >Add&nbsp;Event</button>
        </div>
       <div className="col-auto ">

        <button className="btn pro-spaces-button3  px-4" >View&nbsp;Events</button>
       </div>
       </div>
     </div>
      
  
    </div>)
}


