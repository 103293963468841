import { useState, useEffect, useRef } from "react";
import Result from "./result";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Loader } from "../../Utility/spinner";
import { BiReset } from "react-icons/bi";
import { BsInfoCircle, BsSearch } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import "./search.css";
import { ExistingData, InitializeCountry } from "../../Utility/Country";
import { ProfileCard } from "../ProfileCard/ProfileCard";
export const Search = (props) => {
  let countryRef = useRef();
  let stateRef = useRef();
  let cityRef = useRef();
  let countryRef2 = useRef();
  let stateRef2 = useRef();
  let cityRef2 = useRef();
  const toURL = (elem) => {
    let f = elem.replace(/\s/g, "+");
    return f.replace(/[&]/g, "_and_");
  };
  const toData = (elem) => {
    let f = elem.replace(/[+]/g, " ");
    return f.replace(/_and_/g, "&");
  };
  let linkTo = props.linkto;

  const newStyle = {
    marginTop: "80px",
  };

  useEffect(() => {
    // print_state('searcstate')
    if (props.pass) {
    } else {
      // print_city_inputstate('City', toData(searchParams.get('state')))
      if (searchParams.get("keyword")) {
        SearchGo({
          Country: toData(searchParams.get("country")),
          State: toData(searchParams.get("state")),
          City: toData(searchParams.get("city")),
          Key: toData(searchParams.get("keyword")),
        });
      }
    }
    return () => {};
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const [Search, setSearch] = useState({
    Country: searchParams.get("country")
      ? toData(searchParams.get("country"))
      : "",
    State: searchParams.get("state") ? toData(searchParams.get("state")) : "",
    City: searchParams.get("city") ? toData(searchParams.get("city")) : "",
    Key: searchParams.get("keyword") ? toData(searchParams.get("keyword")) : "",
  });
  const [Summery, setSummery] = useState("");
  const [Error, setError] = useState("");
  const [Loading, setLoading] = useState(false);

  const searchNow = () => {
    if (Search.Key.length >= 3) {
      setError("");
      const to = `${linkTo}?keyword=${toURL(Search.Key)}&&country=${
        Search.Country
      }&&state=${toURL(Search.State)}&&city=${toURL(Search.City)}`;
      if (props.pass) {
        navigate(to);
        return;
      } else {
        SearchGo({ ...Search });
      }
    } else {
      setError("Enter minimum 3 characters to search");
    }
  };

  const [ResultsData, setResultsData] = useState([]);
  const SearchGo = (query) => {
    setLoading(true);
    axios
      .post("/api/Free/SearchProfile", query)
      .then((res) => {
        setLoading(false);
        setResultsData(res.data);
        if (res.data.length === 0) {
          setSummery("No profiles to display !");
        } else if (res.data.length === 1) {
          setSummery(`Displaying ${res.data.length} profile`);
        } else if (res.data.length > 1) {
          setSummery(`Displaying ${res.data.length} profiles`);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (!props.pass) {
      // setSearchParams({ ['keyword']: Search.Key, ['state']: Search.State, ['city']: Search.City })
      navigate(
        "?keyword=" +
          toURL(Search.Key) +
          "&&country=" +
          toURL(Search.Country) +
          "&&state=" +
          toURL(Search.State) +
          "&&city=" +
          toURL(Search.City),
        { replace: true }
      );
    }
    return () => {};
  }, [Search]);
  const Reset = () => {
    setSearch({
      Country: "",
      State: "",
      City: "",
      Key: "",
    });
    // navigate('/search', { replace: true })
    setResultsData([]);
    setSummery("");
  };

  const [show, setShow] = useState(false);
  const [mainshow, mainsetShow] = useState(false);

  const mainHandleClose = () => mainsetShow(false);
  const mainHandleShow = () => {
    // print_state('searcstate')
    mainsetShow(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (props.pass) {
      // InitializeCountry(countryRef, stateRef, cityRef)
    } else {
      ExistingData(
        countryRef2,
        stateRef2,
        cityRef2,
        toData(searchParams.get("country")),
        toData(searchParams.get("state"))
      );
      InitializeCountry(
        countryRef2,
        stateRef2,
        cityRef2,
        toData(searchParams.get("country")),
        toData(searchParams.get("state"))
      );
    }

    return () => {};
  }, []);
  return (
    <div className="w-100">
      {props.pass ? (
        <button
          onClick={(e) => (props.off ? "" : mainHandleShow())}
          className="btn w-100 pro-spaces-button btn-search-pro "
          style={{ borderRadius: "9px", height: "36px", textAlign: "start" }}
        >
          Looking for a Specific Brand, Business Enterprise&nbsp;&#63;
        </button>
      ) : (
        <></>
      )}
      <div
        className={props.pass ? "container-fluid p-0 d-none" : "container "}
        style={props.pass ? {} : newStyle}
      >
        <Modal
          show={mainshow}
          onHide={mainHandleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          onShow={() => {
            // print_state('searcstater');
            InitializeCountry(countryRef, stateRef, cityRef);
          }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className=" ">
              <div className="container-fluid p-0 d-flex align-items-center">
                <div
                  className="searchtollbarparent w-100 mb-2"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{ position: "absolute", marginTop: "7px" }}
                    className="ps-2"
                  >
                    <BsInfoCircle
                      onClick={(e) => handleShow()}
                      style={{ color: "var(--blue)" }}
                      size={15}
                    />
                  </div>
                  <input
                    type="text"
                    value={Search.Key}
                    onChange={(e) => {
                      setSearch((prev) => ({ ...prev, Key: e.target.value }));
                    }}
                    placeholder="&nbsp;Type Brand / Shop / Individual's Name"
                    name="searchInput"
                    className=" form-control newProsearchxv1 "
                    style={{
                      display: "inline-block",
                      backgroundColor: "white",
                      border: "1px solid #ced4da",
                      color: "black",
                      borderRadius: "10px",
                      paddingLeft: "1.8rem",
                    }}
                  ></input>
                  <button
                    type="button"
                    onClick={(e) => searchNow()}
                    className="searchIco pe-0"
                    style={{
                      marginLeft: "-40px",
                      position: "absolute",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <BsSearch size={20} style={{ color: "var(--blue)" }} />
                  </button>
                  {/* <div className="lobbySearchinfo"> */}
                  {/* </div> */}
                </div>
                <div className="resetsearchbutton mb-2">
                  <button
                    className="btn  pro-spaces-button  p-1 pb-2 "
                    type="button"
                    style={{
                      borderRadius: "10px",
                      paddingTop: "2px",
                      border: "1px solid #ced4da",
                    }}
                    onClick={(e) => Reset(e)}
                  >
                    <BiReset size={25} />
                  </button>
                </div>
              </div>
              <div className="container-fluid p-0 ">
                <div className="row g-2">
                  <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <select
                        value={Search.Country}
                        ref={countryRef}
                        onChange={(e) => {
                          console.log(e.target.selectedIndex);
                          // print_city("Cityrr", e.target.selectedIndex);
                          setSearch({ ...Search, Country: e.target.value });
                        }}
                        style={{ height: "58px ", borderRadius: "10px" }}
                        // id="searcstater"
                        name="City"
                        className="form-select"
                      ></select>
                      <label htmlFor="State">Country</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <select
                        value={Search.State}
                        ref={stateRef}
                        onChange={(e) => {
                          console.log(e.target.selectedIndex);
                          // print_city("Cityrr", e.target.selectedIndex);
                          setSearch({ ...Search, State: e.target.value });
                        }}
                        style={{ height: "58px ", borderRadius: "10px" }}
                        // id="searcstater"
                        name="City"
                        className="form-select"
                      ></select>
                      <label htmlFor="State">State</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <select
                        disabled={
                          props.linkto === "/lobby/search" ? true : false
                        }
                        style={{ height: "58px ", borderRadius: "10px" }}
                        name="City"
                        ref={cityRef}
                        className="form-select"
                        // id="Cityrr"
                        value={Search.City}
                        onChange={(e) =>
                          setSearch({ ...Search, City: e.target.value })
                        }
                      ></select>
                      <label htmlFor="City">City</label>
                    </div>
                  </div>
                </div>
                <p className="text-danger">{Error}</p>
              </div>
            </div>

            <div className="container">
              <div className="container text-center">
                {Loading ? (
                  <Loader size="10" />
                ) : (
                  <h6 className="text-center mt-3">{Summery}</h6>
                )}
              </div>
              <div className={"row bg-"}>
                {/* <Loader size="10" color="white" /> */}
                {/* {ResultsData.map(elem => <Result visibility={props.visibility} data={elem} />)} */}
                {ResultsData?.map((data, index) => {
                  return <ProfileCard key={`${index}/search`} data={data} />;
                })}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            If you are searching for a particular Brand or a Shop or a Service
            Provider / Product Retailer who you know, then you may type the name
            in the field to see their profile provided they have created a
            profile at PROChure.
          </Modal.Body>
        </Modal>

        <div
          className={`  ${
            props.pass ? "d-none" : ""
          } " mt-3 sabaricardforall p-3 pb-2"`}
        >
          <h6 style={{ fontSize: "14px" }}>
            Looking for a Specific Brand, Business Enterprise&nbsp;?
          </h6>

          <div className="container-fluid p-0 d-flex align-items-center">
            <div
              className="searchtollbarparent w-100 my-2"
              style={{ position: "relative" }}
            >
              <div
                style={{ position: "absolute", marginTop: "7px" }}
                className="ps-2"
              >
                <BsInfoCircle
                  onClick={(e) => handleShow()}
                  style={{ color: "var(--blue)" }}
                  size={15}
                />
              </div>
              <input
                type="text"
                value={Search.Key}
                onChange={(e) => {
                  setSearch((prev) => ({ ...prev, Key: e.target.value }));
                }}
                placeholder="&nbsp;&nbsp;Type Brand / Shop / Individual's Name"
                name="searchInput"
                className="ps-4 form-control newProsearchxv1 "
                style={{
                  display: "inline-block",
                  backgroundColor: "white",
                  border: "1px solid #ced4da",
                  color: "black",
                  borderRadius: "10px",
                }}
              ></input>
              <button
                type="button"
                onClick={(e) => searchNow()}
                className="searchIco pe-0"
                style={{ marginLeft: "-35px" }}
              >
                {" "}
                <BsSearch size={20} style={{ color: "var(--blue)" }} />
              </button>
              {/* <div className="lobbySearchinfo"> */}
              {/* </div> */}
            </div>
            <div className="resetsearchbutton">
              <button
                className="btn  pro-spaces-button  p-1 pb-2 "
                type="button"
                style={{
                  borderRadius: "10px",
                  paddingTop: "2px",
                  border: "1px solid #ced4da",
                }}
                onClick={(e) => Reset(e)}
              >
                <BiReset size={25} />
              </button>
            </div>
          </div>
          <div className="container-fluid p-0 ">
            <div className="row g-2">
              <div className="col-6 col-md-3">
                <div className="form-floating">
                  <select
                    value={Search.Country}
                    ref={countryRef2}
                    onChange={(e) => {
                      console.log(e.target.selectedIndex);
                      // print_city("Cityrr", e.target.selectedIndex);
                      setSearch({ ...Search, Country: e.target.value });
                    }}
                    style={{ height: "58px ", borderRadius: "10px" }}
                    // id="searcstater"
                    name="City"
                    className="form-select"
                  ></select>
                  <label htmlFor="State">Country</label>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="form-floating">
                  <select
                    ref={stateRef2}
                    value={Search.State}
                    onChange={(e) => {
                      console.log(e.target.selectedIndex);
                      // print_city("City", e.target.selectedIndex);
                      setSearch({ ...Search, State: e.target.value });
                    }}
                    style={{ height: "58px ", borderRadius: "10px" }}
                    // id="searcstate"
                    name="City"
                    className="form-select"
                  ></select>
                  <label htmlFor="State">State</label>
                </div>
              </div>
              <div className="col-6 col-md-3 ">
                <div className="form-floating">
                  <select
                    disabled={props.visibility === "public" ? true : false}
                    style={{ height: "58px ", borderRadius: "10px" }}
                    name="City"
                    ref={cityRef2}
                    className="form-select"
                    // id="City"
                    value={Search.City}
                    onChange={(e) =>
                      setSearch({ ...Search, City: e.target.value })
                    }
                  ></select>
                  <label htmlFor="City">City</label>
                </div>
              </div>
            </div>
            <p className="text-danger">{Error}</p>
          </div>
        </div>

        <div className="container">
          <div className="container text-center">
            {Loading ? (
              <Loader size="10" />
            ) : (
              <h6 className="text-center mt-3">{Summery}</h6>
            )}
          </div>
          <div className={"row bg-"}>
            {/* <Loader size="10" color="white" /> */}
            {/* {ResultsData.map(elem => <Result visibility={props.visibility} data={elem} />)} */}
            {ResultsData?.map((data, index) => {
              return <ProfileCard key={`${index}/search`} data={data} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
