import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// import { TimeLineExternal } from "../../../Components/Timeline/TimeLineExternal";
import Moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { IoIosSend } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetportExternal } from "./GetportExternal";
import { GetOfferExternal } from "./GetOfferExternal";
import { MdMyLocation } from "react-icons/md";
import { Mask } from "../../../Utility/mask";

import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import { GetNewLaunchExternal } from "./GetNewLaunchExternal";
import { ShortlistFreee } from "../../../Components/ProfileComponents/ShortlistFreee";
import { DetailedServiceInformation } from "../../../Components/ProfileComponents/DetailedServiceInformation";
import { WorkServiceRelatedInformation } from "../../../Components/ProfileComponents/WorkServiceRelatedInformation";
import { QualificationsandEnrolmentInformation } from "../../../Components/ProfileComponents/QualificationsandEnrolmentInformation";
import { PersonalEntityInfornation } from "../../../Components/ProfileComponents/PersonalEntityInfornation";
import { BussinessInformation } from "../../../Components/ProfileComponents/BussinessInformation";
import { ContactInformation } from "../../../Components/ProfileComponents/ContactInformation";
import { HospitalsClinicsAssociatedWith } from "../../../Components/ProfileComponents/HospitalsClinicsAssociatedWith";
import { NonMemberBussinessInformation } from "../../../Components/ProfileComponents/NonMemberBussinessInformation";
import { NonMemberContactInformation } from "../../../Components/ProfileComponents/NonMemberContactInformation";
import { Navoutside } from "../../../Components/Navoutside/Navoutside";
import { NewNavigationBarDissabled } from "../../../Pages/Free/NewNavigationBar/NewNavigationBarDissabled";
import { PersonalInformationStatic } from "../../../Components/ProfileComponents/PersonalInformationStatic";
import { Selfdeclaration } from "../../../Components/Selfdeclaration/Selfdeclaration";

function ExternalPview(props) {
  console.log("object");
  let [searchParams, setSearchParams] = useSearchParams();
  const [Follower, setFollower] = useState(false);
  let Navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const astyle = {
    color: "#0a58ca",
    textDecoration: "underline",
  };

  const addreating = (e, status) => {
    // console.log(id)
  };

  var Ac = Mask.decode(searchParams.get("Ac_id"));
  var idd = Mask.decode(searchParams.get("Ac_id"));
  var Acc = Mask.decode(searchParams.get("Ac_id"));

  const [rateingfor, setrateingfor] = useState([]);
  // useEffect(() => {
  //   axios
  //     .post("/api/User/getportfolio", {
  //       Acc,
  //     })
  //     .then((res) => {
  //       setrateingfor(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const enablechat = () => {
    document.getElementById("closebuttonforreportusgfugssug").click();
    document.getElementById("quicksettingmodalatag").click();
  };

  const communicate = (e, st) => {};

  const [mappas, setmappas] = useState();

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const [first, setfirst] = useState(false);

  const Clickhandler = (cont) => {
    console.log(cont);
    if (cont == "image") {
      document.getElementById("image").click();
    } else {
      document.getElementById("video").click();
    }
  };

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function lossupennu(e, ind) {
    dopper[ind].File.map((element1, index) => {
      document.getElementById("trkor" + index + ind).classList.remove("d-none");
    });

    document.getElementById("trkorrr" + ind).classList.remove("d-none");
    // document.getElementById("uhf"+ind).classList.remove('d-none')
    document.getElementById("gana" + ind).removeAttribute("disabled");
  }

  function pyari(e, ind) {
    dopper[ind].File.map((element1, index) => {
      document.getElementById("trkor" + index + ind).classList.add("d-none");
    });
    document.getElementById("trkorrr" + ind).classList.add("d-none");
    // document.getElementById("uhf"+ind).classList.add('d-none')
    document.getElementById("gana" + ind).setAttribute("disabled", true);
  }

  function cater(e) {
    // console.log(e.target.value)
    setforume(e.target.value);
  }
  useEffect(() => {
    console.log(forume);
  }, [forume]);

  var sab;

  if (getval.experience > 1) {
    sab = "years";
  } else {
    sab = "year";
  }

  var date = Moment(getval.dob).format("DD-MM-YYYY");

  function jvur(e, fr) {
    document.getElementById(fr).click();
  }

  // update port
  // console.log(idd)

  // init
  useEffect(() => {
    axios
      .post("/api/Free/ConProfileexterbal", {
        idd,
      })
      .then((res) => {
        setGetval(res.data[0]);
        setEmailRef((prev) => ({ ...prev, sub: res.data[0].string2[0].sub }));
        console.log(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [myarr, setMyarr] = useState(false);
  const [age, setage] = useState();

  const notify = (message, stat) => {
    // console.log(message);
    // console.log(stat)

    if (stat == true) {
      // console.log("toasfted");
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == false) {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Content, setContent] = useState();
  var init = "";
  function Preview(data) {
    for (var i = 0; i < data.length; i++) {}
  }

  ////////////////////Anantha krishnan S/////////////////
  const [EmailRef, setEmailRef] = useState({
    email: [],
    subject: "",
    massage: "",
    sub: "",
    id: Acc,
  });

  const makeEmail = (x, Lorum) => {
    console.log(Lorum);
    console.log(x);
    x = document.getElementById(x);
    x.style.color = "black";
    var val = x.value;
    if (val.charAt(val.length - 1) === " ") {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
    if (Lorum) {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
  };

  const [Email, setEmail] = useState([]);
  const [Arr, setArr] = useState([]);
  var listItems = "";
  const [AllEmail, setAllEmail] = useState([]);

  const addEmail = (vall) => {
    vall = vall.trim();
    var dep = AllEmail;
    dep.push(vall);
    setAllEmail(dep);
    var x = React.createElement(
      "div",
      {
        className:
          "p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1",
        role: "alert",
      },
      [
        React.createElement(
          "strong",
          {},
          vall,
          <>&nbsp;&nbsp;&nbsp;</>,
          React.createElement(
            "button",
            {
              onClick: (e) => removeEmail(vall),
              type: "button",
              className: "btn-close ms-5 Gbt p-0 pt-2 ",
              "data-bs-dismiss": "alert",
              "aria-label": "Close",
            },
            ""
          )
        ),
      ]
    );
    Arr.push(x);
    listItems = Arr.map((myList) => {
      return <>{myList}</>;
    });
    setEmail(listItems);
  };

  useEffect(() => {
    setEmailRef({ ...EmailRef, email: AllEmail });
  }, [AllEmail]);

  const removeEmail = (e) => {
    var index = AllEmail.indexOf(e);
    AllEmail.splice(index, 1);
    setEmailRef({ ...EmailRef, email: AllEmail });
  };

  const [error, seterror] = useState(false);

  const validation = () => {
    if (EmailRef.email.length > 0) {
      seterror("");
    } else {
      return seterror("PLEASE ADD a Email Id");
    }
    if (EmailRef.massage.length > 0) {
      seterror("");
    } else {
      return seterror("PLEASE ENTER Message");
    }

    return true;
  };

  const [submittrue, setsubmittrue] = useState(false);
  const SendRef = (e) => {};

  useEffect(() => {
    if (submittrue) {
      validation();
    }
  });

  useEffect(() => {
    try {
      if (first) {
        if (first.good) {
          document.getElementById("flexCheckDefault12").checked = true;
        }
        if (first.high) {
          document.getElementById("flexCheckDefault122").checked = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [first]);

  const [referCount, setreferCount] = useState(false);
  const [yeser, setyeser] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);
  console.log(getval.followers);

  const [first8, setfirst8] = useState(false);

  const [sabari, setsabari] = useState("");
  const [sabarierr, setsabarierr] = useState("");

  const reprtsabari = () => {};
  const [firstst, setfirstst] = useState();
  const [firstst2, setfirstst2] = useState();

  const [CommunicationStatus, setCommunicationStatus] = useState("Loading");

  return (
    <>
      {props.demo ? <NewNavigationBarDissabled /> : <></>}
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border">
          {/* whgiowhroighsoihlznvlsnd */}
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-2">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="profilee-img ">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={getval.link}
                  alt=""
                />
                {/* <div className="file btn btn-lg btn-primary">
                  Change Photo
                  <input onChange={(e)=>changeDp(e.target.files[0])} type="file" name="ProfilePic" accept="image/*"/>
                </div> */}
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5 className="mb-1">
                    {getval.pname ? getval.pname.toUpperCase() : ""}
                  </h5>
                  <p className="mt-0 pt-0 mb-2">
                    {getval?.taglineCheck
                      ? getval?.brandtagline
                        ? getval.brandtagline
                        : ""
                      : ""}
                  </p>
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5 className="mb-1">
                      {getval.pname ? getval.pname.toUpperCase() : ""}
                    </h5>
                    <p className="mt-0 pt-0 mb-2">
                      {getval?.taglineCheck
                        ? getval?.brandtagline
                          ? getval.brandtagline
                          : ""
                        : ""}
                    </p>
                  </center>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                        {getval.string ? getval.string[0].category : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.string2 ? getval.string2[0].sub : ""} |{" "}
                          {getval.string ? getval.string[0].category : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="row ms-3">
                  {getval.Type === "Consultantv3" ? (
                    <div
                      className="form-check col-12 d-flex  justify-content-start mb-3  pe-0 invisible"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalsabari"
                    >
                      <input
                        className="form-check-input float-end checkboxpro me-3 mt-3"
                        type="checkbox"
                        disabled
                        // onClick={(e) => checkaeijfpi(e)}
                      />
                      <span className="iorughoisrhgiohs">
                        Short&#8209;list&nbsp;this&nbsp;Profile
                      </span>
                    </div>
                  ) : (
                    <div
                      className="form-check col-12 d-flex  justify-content-start mb-3  pe-0"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalsabari"
                    >
                      <input
                        className="form-check-input float-end checkboxpro me-3 mt-3"
                        type="checkbox"
                        disabled
                        // onClick={(e) => checkaeijfpi(e)}
                      />
                      <span className="iorughoisrhgiohs">
                        Short&#8209;list&nbsp;this&nbsp;Profile
                      </span>
                    </div>
                  )}
                  <div className="col-3 col-md-3 col-lg-5 d-none">
                    {!Follower ? (
                      <>
                        <button
                          type="button"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{ borderRadius: "25px" }}
                          // onClick={(e) => follow(e, idd)}
                        >
                          Follow
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{ borderRadius: "25px" }}
                          // onClick={(e) => unfollow(e, idd)}
                        >
                          UnFollow
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="reportthismodal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Report this Profile{" "}
                        </h5>
                        <button
                          type="button"
                          id="closebuttonforreport"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                        <p>
                          Please BE INFORMED that you must Report Abuse about a
                          Profile ONLY for a genuine valid reason with evidence.
                        </p>
                        <p>
                          Fake / False reporting about a member as an act of
                          prank will result in deactivation of your membership
                          without refund.
                        </p>
                        <textarea
                          className="form-control"
                          onChange={(e) => setsabari(e.target.value)}
                        ></textarea>
                        <p className="text-danger">
                          {sabarierr ? sabarierr : ""}
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn pro-spaces-button3"
                          onClick={reprtsabari}
                        >
                          Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="turnonchat23423"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          You need to Activate Chat to communicate with this
                          member.
                        </h5>
                        <button
                          type="button"
                          id="closebuttonforreportusgfugssug"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                        <p>
                          Your One-to-One Chatting feature is currently
                          Disabled.
                        </p>
                        <p
                          style={astyle}
                          onClick={enablechat}
                          to="/profile_details_and_settings"
                        >
                          Enable Chatting now
                        </p>
                      </div>
                      <div className="modal-footer">
                        {/* <button type="button" className="btn pro-spaces-button3" onClick={reprtsabari}>Report</button> */}
                      </div>
                    </div>
                  </div>
                </div>

                {}
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {getval.Type === "Consultantv3" ? (
                  <>
                    <Accordion
                      expanded={expanded === "panelewrg4"}
                      onChange={handleChange("panelewrg4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Service Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p className="py-2">{getval.Description}</p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <PersonalInformationStatic getval={getval} vendor={false} />

                    {/* {props.demo ? (
                      <> */}
                    <BussinessInformation getval={getval} />
                    <ContactInformation getval={getval} />
                    {/* </>
                    ) : (
                      <>
                        <NonMemberBussinessInformation getval={getval} />
                        <NonMemberContactInformation getval={getval} />
                      </>
                    )} */}

                    {/* <ShortlistFreee  addreating={addreating} makeEmail={makeEmail} setEmailRef={setEmailRef} SendRef={SendRef} mappas={mappas} getval={getval} referCount={referCount} yeser={yeser} first={first} EmailRef={EmailRef} Ac={Ac} Email={Email} error={error} loading={loading} /> */}
                  </>
                ) : getval.formType ? (
                  getval.formType === 1 ? (
                    <>
                      <ShortlistFreee
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Works / Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />
                      {/* {props.demo ? (
                        <> */}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                      {/* </>
                      ) : (
                        <>
                          <NonMemberBussinessInformation getval={getval} />
                          <NonMemberContactInformation getval={getval} />
                        </>
                      )} */}
                    </>
                  ) : getval.formType === 2 ? (
                    <>
                      <ShortlistFreee
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Works / Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}

                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />
                      {/* {props.demo ? (
                        <> */}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                      {/* </>
                      ) : (
                        <>
                          <NonMemberBussinessInformation getval={getval} />
                          <NonMemberContactInformation getval={getval} />
                        </>
                      )} */}
                    </>
                  ) : getval.formType === 3 ? (
                    <>
                      <ShortlistFreee
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />

                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      {getval.Profile === "Individual" ? (
                        <PersonalEntityInfornation getval={getval} />
                      ) : (
                        <></>
                      )}
                      {/* {props.demo ? (
                        <> */}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                      {/* </>
                      ) : (
                        <>
                          <NonMemberBussinessInformation getval={getval} />
                          <NonMemberContactInformation getval={getval} />
                        </>
                      )} */}
                    </>
                  ) : getval.formType === 4 ? (
                    <>
                      <ShortlistFreee
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />

                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      {getval.Profile === "Individual" ? (
                        <PersonalEntityInfornation getval={getval} />
                      ) : (
                        <></>
                      )}
                      {/* {props.demo ? (
                        <> */}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                      {/* </>
                      ) : (
                        <>
                          <NonMemberBussinessInformation getval={getval} />
                          <NonMemberContactInformation getval={getval} />
                        </>
                      )} */}
                    </>
                  ) : getval.formType === 5 ? (
                    <>
                      <ShortlistFreee
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />
                      {getval.Profile === "Private" ||
                      getval.Profile === "Privateand" ? (
                        <DetailedServiceInformation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />

                      {getval.Profile === "Private" ? (
                        <>
                          {/* Private */}
                          <ContactInformation getval={getval} />
                        </>
                      ) : getval.Profile === "Associated" ? (
                        <>
                          {/* {props.demo ? (
                            <> */}
                          <BussinessInformation getval={getval} />
                          {/* </>
                          ) : (
                            <>
                              <NonMemberBussinessInformation getval={getval} />
                            </>
                          )} */}
                          {/* Associated */}
                          {/* <NonMemberContactInformation getval={getval} /> */}
                        </>
                      ) : (
                        <>
                          {/* Privateand */}
                          {/* {props.demo ? (
                            <> */}
                          <BussinessInformation getval={getval} />
                          <HospitalsClinicsAssociatedWith getval={getval} />
                          {/* </>
                          ) : (
                            <>
                              <NonMemberBussinessInformation getval={getval} />
                              <NonMemberContactInformation
                                getval={"Hospital Consultation Information"}
                              />
                            </>
                          )} */}
                          {/* <NonMemberBussinessInformation getval={getval} />
                          <NonMemberContactInformation
                            getval={"Hospital Consultation Information"}
                          /> */}
                        </>
                      )}
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {/* <ShortlistFreee
                              addreating={addreating}
                              makeEmail={makeEmail}
                              setEmailRef={setEmailRef}
                              SendRef={SendRef}
                              mappas={mappas}
                              getval={getval}
                              referCount={referCount}
                              yeser={yeser}
                              first={first}
                              EmailRef={EmailRef}
                              Ac={Ac}
                              Email={Email}
                              error={error}
                              loading={loading}
                            /> */}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  ""
                )}
                <Selfdeclaration type="vdp" />
              </div>
            </center>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ExternalPview;
// new
