import { useState, useEffect } from "react"
import { PortTile } from "./portTile"
import { PortAdd } from "./PortAdd"
import axios from "axios"
export const Port = (props) => {
    const [PortFolios, setPortFolios] = useState([])
    const [KeyPortAdd, setKeyPortAdd] = useState(1)
    const Finder = () => {

    }
    const Remove = async (id) => {
        try {
            let f = await axios.post('/api/Free/DelportSingle', { id })
            const newList = PortFolios.filter((item) => item !== id);
            setPortFolios(newList);
        }
        catch (err) {
            console.log(err)
        }

    }

    const Remove2 = async (id,ids) => {
        try {
            document.getElementById(ids).click()
            let f = await axios.post('/api/Free/DelportSingle', { id })
            const newList = PortFolios.filter((item) => item !== id);
            setPortFolios(newList);
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        props.setValue({ ...props.value, portfolios: PortFolios })
        setKeyPortAdd(KeyPortAdd + 1)
    }, [PortFolios])

    
    function keyGen() {
        var length = 5;
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    return <div >
        <div className="container ">
            {PortFolios.map((elem, index) => {
                return <PortTile key={elem} Remove={Remove} Remove2={Remove2} title={'here'} index={index + 1} id={elem} porttitlechanger={props.porttitlechanger}/>
            })}

            <PortAdd key={KeyPortAdd} setKeyPortAdd={setKeyPortAdd} id={KeyPortAdd} PortFolios={PortFolios} setPortFolios={setPortFolios} changersab={props.changersab} porttitlechanger={props.porttitlechanger} text={props.text}/>
        </div>
    </div>
}