import React, { useEffect, useRef, useState } from "react";
import "./SearchToolbar.css";
import { Link } from "react-router-dom";
import { HiSpeakerphone } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SearchComponent } from "../ConSearchResult/SearchComponent";
import { useSearchParams } from "react-router-dom";
import { BiReset } from "react-icons/bi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { BsSearch } from "react-icons/bs";
import { print_city, print_state } from "../../Utility/stateCity";
import { languages } from "../../Utility/languages";
import Modal from "react-bootstrap/Modal";
import { Mask } from "../../Utility/mask";
import { ExistingData, InitializeCountry } from "../../Utility/Country";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { MenuProps } from "../../Utility/languages";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ProfileCard } from "../ProfileCard/ProfileCard";
import { FcInfo } from "react-icons/fc";
export const SearchToolbar = (props) => {
  const { open } = props;
  const [modalShow, setModalShow] = React.useState(false);

  const [cat, setCat] = useState([]);

  const [data, setdata] = useState(false);
  const [load, setload] = useState(true);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [SearchValidationError, setSearchValidationError] = useState(false);
  const [SearchValidated, setSearchValidated] = useState(false);
  const [Stateload, setStateload] = useState(true);
  const [error, seterror] = useState("");
  const [err, setError] = useState("");
  const [output, setoutput] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  var ids = Mask.decode(searchParams.get("Sub_id"));
  var id = Mask.decode(searchParams.get("Sub_id"));
  let userData = useSelector((state) => state.user);
  console.log(userData);
  const [Search, setSearch] = useState({
    Subcategory: ids,
    Country: userData.value.Country ? userData.value.Country : "",
    State: "",
    City: "",
    Pin: "",
    Gender: "",
    Experience: "",
    WE: false,
    SearchKeyword: "",
    Qualification: "",
    Skill: "",
    TypeofConsultation: "",
    Street_Lane_RoadName: "",
    Area_Locality_Name: "",
    Communication_Language: "",
    DisplayOfProfile: [],
  });
  const [value, setValue] = useState();
  let navigate = useNavigate();

  const sabari = (e) => {
    console.log(e.target.value);
    var anadhan;
    setSearch({ ...Search, Category: e.target.value });
    // setValue({ ...value, category: e.target.value })
    cat.forEach((element) => {
      console.log(element._id);
      if (element._id === e.target.value) {
        anadhan = element.sub.map((elements) => {
          return <option value={elements._id}>{elements.sub}</option>;
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };

  useEffect(() => {
    axios
      .get("/api/api/getcategory")
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setCat(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          navigate("/");
        }
        console.log(err.response.status);
      });
  }, []);

  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
      console.log(cat);
    } else {
      console.log("nothing");
    }
  }, [cat]);
  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);

  async function SearchHandler() {
    // console.log(setSearchValidated)
    console.log("hii" + Search.Pin.length);
    if (Search.Pin.length > 0) {
      if (Search.Pin.length == 6) {
        console.log("no error");
        setSearchValidationError(false);
        return true;
      } else {
        console.log("no error");
        setSearchValidationError(false);
        return true;
      }
    } else {
      setSearchValidationError(false);
      return true;
    }
  }

  async function SearchHandlerX(e) {
    setSearchValidated(true);
    var status = SearchHandler();
    if (!status) {
      e.preventDefault();
    } else {
      // console.log("here we go !");
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (SearchValidated) {
      SearchHandler();
    }
  }, [Search.Pin]);

  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);

  function validation() {
    if (Search.Country.length == 0) {
      return setError("Select a country");
    }
    if (Search.State.length > 0) {
      setError("");
    } else {
      return setError("Select a State / Province");
    }

    return true;
  }

  const [onetime, setonetime] = useState(false);

  useEffect(() => {
    if (onetime) {
      validation();
    }
  }, [Search]);
  const [sub, setSub] = useState(false);

  const knvpins = () => {
    // console.log(Search)
    setonetime(true);
    var status = false;
    // setSubmit(true)
    status = validation();

    if (status == true) {
      setoutput("");
      props.setSearchOn(true);
      setload(true);
      axios
        .post("/api/Free/getsubcategoryname", {
          id,
        })
        .then((res) => {
          setSub(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      // document.getElementById('gtrgtr').classList.remove('d-none')
      axios
        .post("/api/User/searchcon", { Search })
        .then((res) => {
          if (res.data.length === 0) {
            setModalShow(true);
          }
          props.setResults(res.data.map((sr) => sr._id));
          const postSummaries = res.data.map((post) => ({
            id: post._id,
            key: post.id,
            data: post,
          }));
          setdata(postSummaries);
          setload(false);
          props.setOpen(!open);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [DisplayNo, setDisplayNo] = useState();

  useEffect(() => {
    if (data.length > 0) {
      setoutput(
        data.map((number) => (
          <ProfileCard key={number.id} ids={makeid()} data={number.data} />

          // <SearchComponent
          //   key={number.id}
          //   ids={makeid()}
          //   data={number.data}
          // ></SearchComponent>
        ))
      );
      if (data.length > 0) {
        if (data.length === 1) {
          setDisplayNo(
            <center>
              <h4>
                Displaying&nbsp;{data.length}&nbsp;profile in {sub[0]?.sub}
              </h4>
            </center>
          );
        } else {
          setDisplayNo(
            <center>
              <h4>
                Displaying&nbsp;{data.length}&nbsp;profiles in {sub[0]?.sub}
              </h4>
            </center>
          );
        }
      }
    } else {
      setDisplayNo("");
    }
  }, [error, load]);

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const Reset = () => {
    props.setSearchOn(false);
    props.setKeyer(props.Keyer + 1);
    setDisplayNo("");
  };
  const SearchKeyword = async () => {
    console.log(Search);
    if (Search.SearchKeyword.length === 0) {
      return;
    }
    // return
    props.setSearchOn(true);

    try {
      var SearchKeywords = await axios.post("/api/User/SearchKeyword", {
        Search,
      });
      console.log(SearchKeywords.data);
      if (SearchKeywords.data.length === 0) {
        setModalShow(true);
      }
      if (SearchKeywords.data.length > 0) {
        setoutput(
          SearchKeywords.data.map((number) => {
            return (
              <ProfileCard ids={makeid()} data={number} />
              // <SearchComponent ids={makeid()} data={number}></SearchComponent>
            );
          })
        );
      } else {
        setoutput(
          <center>
            <h4 style={{ fontWeight: "400", color: "#5A5A5A" }}>
              No profiles created yet
            </h4>
          </center>
        );
        setDisplayNo("");
      }

      if (SearchKeywords.data.length > 0) {
        if (SearchKeywords.data.length === 1) {
          setDisplayNo(
            <center>
              <h4>Displaying&nbsp;{SearchKeywords.data.length}&nbsp;profile</h4>
            </center>
          );
        } else {
          setDisplayNo(
            <center>
              <h4>
                Displaying&nbsp;{SearchKeywords.data.length}&nbsp;profiles
              </h4>
            </center>
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  let countryRef = useRef();
  let stateRef = useRef();
  let cityRef = useRef();
  useEffect(() => {
    ExistingData(countryRef, stateRef, cityRef, userData.value.Country, "");

    InitializeCountry(countryRef, stateRef, cityRef);
    return () => {};
  }, []);

  return (
    <div className="col-12 pb-2">
      <div className="container-fluid p-0  pt-1">
        {/* <button onClick={() => {
          console.log(countryRef.current.value);

        }} >click</button> */}
        <Modal
          show={modalShow}
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={(e) => setModalShow(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>
              No profiles to display. A difference in the spellings mentioned in
              the profile and the ones typed by you in certain fields may not
              fetch desired result. In some cases, fewer search criteria would
              fetch better results.
            </p>
          </Modal.Body>
        </Modal>
        <form onSubmit={(e) => SearchHandlerX(e)} action="" method="">
          <div className="p-0 py-1">
            <div className="sabariforsearch p-0 ">
              <div className="searchtollbarparent">
                <input
                  type="text"
                  value={Search.SearchKeyword}
                  onChange={(e) =>
                    setSearch({ ...Search, SearchKeyword: e.target.value })
                  }
                  placeholder="Type keywords to search"
                  name="searchInput"
                  className="newsearch px-4 "
                  style={{
                    backgroundColor: "white",
                    border: "1px solid var(--blue)",
                    color: "black",
                  }}
                ></input>
                <button
                  type="submit"
                  className="searchIco"
                  onClick={SearchKeyword}
                >
                  {" "}
                  <BsSearch size="25" style={{ color: "var(--blue)" }} />
                </button>
              </div>
              <div className="resetsearchbutton" style={{ marginTop: "2px" }}>
                <button
                  className="btn nfsjfbgevoug btnjui2 ps-3 pe-3 pt-1 pb-1  "
                  type="button"
                  onClick={(e) => Reset(e)}
                >
                  <BiReset size={24} />
                </button>
              </div>
            </div>
          </div>
          {open ? (
            <button
              className="btn pro-spaces-button-semi btn-rounded ps-3 pe-3 mt-1 mb-2"
              onClick={() => props.setOpen(!open)}
              aria-controls="AdvancedSearch"
              aria-expanded={open}
              id="consearchtoolbarbtn"
            >
              Advanced Search Filters&nbsp;
              <MdKeyboardArrowUp />
            </button>
          ) : (
            <>
              <button
                className="btn pro-spaces-button3 btn-rounded ps-3 pe-3 mt-1 mb-2"
                onClick={() => props.setOpen(!open)}
                aria-controls="AdvancedSearch"
                aria-expanded={open}
                id="consearchtoolbarbtn"
              >
                Advanced Search Filters&nbsp;
                <MdKeyboardArrowDown />
              </button>
              <FcInfo
                size={25}
                className="ms-2"
                data-bs-toggle="modal"
                data-bs-target="#advancesearchinfo"
              />
            </>
          )}

          {/* <button className="btn pro-spaces-button3 btn-rounded p-0 ms-1"><BsQuestionCircle /></button> */}

          <Collapse in={open}>
            <div id="AdvancedSearch" className="bg-light p-2 ">
              <div className=" container-fluid ">
                <div className="row g-2">
                  <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <select
                        id="hihish"
                        value={Search.Country}
                        ref={countryRef}
                        className="form-select"
                        onChange={(e) =>
                          setSearch({
                            ...Search,
                            Country: e.target.value,
                            State: "",
                            City: "",
                          })
                        }
                      ></select>
                      <label htmlFor="Country">Country</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="form-floating">
                      <select
                        ref={stateRef}
                        className="form-select"
                        value={Search.State}
                        onChange={(e) =>
                          setSearch({
                            ...Search,
                            State: e.target.value,
                            City: "",
                          })
                        }
                      ></select>
                      <label htmlFor="State">State / Province</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <select
                        value={Search.City}
                        className="form-select"
                        onChange={(e) =>
                          setSearch({ ...Search, City: e.target.value })
                        }
                        ref={cityRef}
                      ></select>
                      <label htmlFor="City">City</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <input
                        type="number"
                        id="PinCode"
                        className="form-control"
                        value={Search.Pin}
                        onChange={(e) =>
                          setSearch({ ...Search, Pin: e.target.value })
                        }
                      ></input>
                      <label htmlFor="PinCode">Pin code / Zip code</label>
                    </div>
                  </div>

                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <select
                        name="Gender"
                        className="form-select "
                        id="Gender"
                        value={Search.Gender}
                        onChange={(e) =>
                          setSearch({ ...Search, Gender: e.target.value })
                        }
                      >
                        <option value="" disabled selected></option>
                        <option value="">Any</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Transgender">Transgender</option>
                      </select>
                      <label htmlFor="Gender">Gender</label>
                    </div>
                  </div>

                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <select
                        name="Experience"
                        className="form-select"
                        id="Experience"
                        value={Search.Experience}
                        onChange={(e) =>
                          setSearch({ ...Search, Experience: e.target.value })
                        }
                      >
                        <option value="" disabled selected></option>
                        <option value="">Any</option>
                        <option value="1">1-5 years</option>
                        <option value="5">5-10 years</option>
                        <option value="11">10+ years</option>

                        {/* <option>Male</option>
                                            <option>Female</option> */}
                      </select>{" "}
                      <label htmlFor="Experience">Experience</label>
                    </div>
                  </div>

                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <input
                        name="Qualification"
                        className="form-control"
                        id="Qualification"
                        value={Search.Qualification}
                        onChange={(e) =>
                          setSearch({
                            ...Search,
                            Qualification: e.target.value,
                          })
                        }
                      >
                        {/* <option>Male</option>
                                            <option>Female</option> */}
                      </input>{" "}
                      <label htmlFor="Qualification">Qualification</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <input
                        name="Skill"
                        className="form-control"
                        id="Skill"
                        value={Search.Skill}
                        onChange={(e) =>
                          setSearch({ ...Search, Skill: e.target.value })
                        }
                      >
                        {/* <option>Male</option>
                                            <option>Female</option> */}
                      </input>{" "}
                      <label htmlFor="Skill">Skills</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <select
                        name="TypeofConsultation"
                        className="form-select"
                        id="TypeofConsultation"
                        value={Search.TypeofConsultation}
                        onChange={(e) =>
                          setSearch({
                            ...Search,
                            TypeofConsultation: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled selected></option>

                        <option>Any</option>
                        <option value="offline">Offline</option>
                        <option value="online">Online</option>
                        <option value="both">Both</option>
                      </select>{" "}
                      <label htmlFor="TypeofConsultation">
                        Type of Consultation{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <input
                        name="Street_Lane_RoadName"
                        className="form-control"
                        id="Street_Lane_RoadName"
                        value={Search.Street_Lane_RoadName}
                        onChange={(e) =>
                          setSearch({
                            ...Search,
                            Street_Lane_RoadName: e.target.value,
                          })
                        }
                      ></input>{" "}
                      <label htmlFor="Street_Lane_RoadName">
                        Street / Lane / Road Name
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <input
                        name="Area_Locality_Name"
                        className="form-control"
                        id="Area_Locality_Name"
                        value={Search.Area_Locality_Name}
                        onChange={(e) =>
                          setSearch({
                            ...Search,
                            Area_Locality_Name: e.target.value,
                          })
                        }
                      ></input>{" "}
                      <label htmlFor="Area_Locality_Name">
                        Area / Locality Name
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <select
                        name="Communication_Language"
                        className="form-select"
                        id="Communication_Language"
                        value={Search.Communication_Language}
                        onChange={(e) =>
                          setSearch({
                            ...Search,
                            Communication_Language: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled selected></option>

                        <option>Any</option>
                        {languages.map((language) => (
                          <option>{language}</option>
                        ))}
                      </select>{" "}
                      <label htmlFor="Communication_Language ">
                        Communication Language{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-6 col-md-3 ">
                    <div className="form-floating">
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        className="form-select"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            display: "none",
                          },
                          "& .MuiSvgIcon-root": { display: "none" },
                          "& .MuiSelect-select": { padding: "0" },
                        }}
                        value={Search.DisplayOfProfile}
                        onChange={(e) =>
                          setSearch({
                            ...Search,
                            DisplayOfProfile: e.target.value,
                          })
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value={"Show Profiles only with :"}
                          disabled
                          style={{ opacity: "0.7" }}
                        >
                          <ListItemText primary={"Show Profiles with :"} />
                        </MenuItem>
                        {/* <MenuItem
                          value={
                            "CONTACT NUMBER, EMAIL ID, ADDRESS, WORKING DAYS and HOURS"
                          }
                        >
                          <Checkbox
                            checked={
                              Search.DisplayOfProfile.indexOf(
                                "CONTACT NUMBER, EMAIL ID, ADDRESS, WORKING DAYS and HOURS"
                              ) > -1
                            }
                          />
                          <ListItemText
                            primary={
                              <div style={{ whiteSpace: "normal" }}>
                                CONTACT NUMBER, EMAIL ID, ADDRESS, WORKING DAYS
                                and HOURS
                              </div>
                            }
                          />
                        </MenuItem> */}
                        <MenuItem
                          value={
                            "CONTACT NUMBER, EMAIL ID, ADDRESS, WORKING DAYS and HOURS, PORTFOLIO / CATALOGUE"
                          }
                        >
                          <Checkbox
                            checked={
                              Search.DisplayOfProfile.indexOf(
                                "CONTACT NUMBER, EMAIL ID, ADDRESS, WORKING DAYS and HOURS, PORTFOLIO / CATALOGUE"
                              ) > -1
                            }
                          />
                          <ListItemText
                            primary={
                              <div style={{ whiteSpace: "normal" }}>
                                CONTACT NUMBER, EMAIL ID, ADDRESS, WORKING DAYS
                                and HOURS, PORTFOLIO / CATALOGUE
                              </div>
                            }
                          />
                        </MenuItem>
                      </Select>
                      <label htmlFor="demo-multiple-checkbox">
                        Display of Profiles
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 pt-2">
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) =>
                          setSearch({ ...Search, WE: !Search.WE })
                        }
                        id="flexCheckCheckedEntrepreneur"
                      />
                      <label
                        style={{ fontWeight: "500" }}
                        for="flexCheckCheckedEntrepreneur"
                      >
                        Woman Entrepreneur
                      </label>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end ">
                    <div className="   me-2 ">
                      <button
                        className="btn btnjui2 ps-4 pe-4 d-flex justify-content-center"
                        type="button"
                        onClick={(e) => Reset(e)}
                      >
                        <BiReset size={24} />
                      </button>
                    </div>
                    <div className="   ">
                      <button
                        className="btn btnjui  d-flex justify-content-center"
                        type="button"
                        onClick={(e) => knvpins(e)}
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </div>
                {/* <input type="submit" value="Search" className="btn btn-success"></input> */}
              </div>
              <span style={{ color: "red" }}>{err ? err : ""}</span>
            </div>
          </Collapse>
        </form>
        <>
          <div className={props.SearchOn ? "row" : "row d-none"}>
            <div className="col-12 d-flex justify-content-end">
              <div className="row pt-3">
                <div className="col-12 pb-3">
                  <button
                    className="btn btnsabari px-3 py-1 me-2 me-2  btn-rounded"
                    data-bs-toggle="modal"
                    data-bs-target="#shoutoutmodal1"
                  >
                    {" "}
                    <HiSpeakerphone />
                    &nbsp;Shout-out
                  </button>

                  <Link
                    to="/short_listed_services"
                    className="btn btnsabari px-3 py-1   btn-rounded"
                  >
                    View Short-listed
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {DisplayNo}

          <div className="row ">{output}</div>
        </>
      </div>
    </div>
  );
};
