import axios from 'axios';
import Moment from 'moment';
import { useState } from 'react';

export const Sinvite = (props) => {

    const [Status, setStatus] = useState(props.data.Status)

    const InvitationResponse = async (id, stat, Tom) => {
        // ()=> Perform invitation responses to join group
        return new Promise(async (resolve, reject) => {


            if (id && stat) {
                let dataSend = {
                    id,
                    stat
                }
                // document.getElementById('Tommy' + Tom).classList.add('d-none')
                try {
                    var send = await axios.post('/api/user/InvitationController', { dataSend })
                    console.log(send)
                    if (send.status === 201) {
                        setStatus(send.data)
                        // console.log(stat)
                        // console.log(send)

                    }else{
                        setStatus('CANCELLED')
                    }
                }
                catch (err) {
                    reject(err)
                    console.log(err)
                }
            }
        })
    }

    console.log(props)
    if (Status === 'Already in chat') {
        return <></>
    } else {

        return <div id={'Tommy' + props.data._id} className="row"  >
            <div className="col-2">
            </div>
            <div className="col-10 pe-5 d-flex justify-content-end">
                <div className="   p-1 m-2" >
                    <div className="row ps-3  text-end  ">
                        {/* <div className="col-12 p-0 pb-1 m-0">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp" alt="avatar"
                        className="rounded-circle chatAvatar me-2 align-self-center  shadow-1-strong" width="30" />
                    {props.data.sender.pname}
                </div> */}
                        <div className="m-0 p-0" >
                            <div className="row p-0 d-flex justify-content-end">
                                <div className="p-0 col-12" >
                                    <div className="message mb-0 text-end text-dark px-3">
                                        <small>
                                            You Send Invitation {props.data.content}
                                        </small>
                                        <div>
                                            {Status === 'ACTIVE' ?
                                                <> <a onClick={(e) => InvitationResponse(props.data._id, 'cancel', props.index)} className="btn btn-outline-success">Cancel</a>&nbsp;                                                                        </>
                                                :
                                                <> <a className="btn btn-outline-success disabled">{Status}</a>&nbsp;</>
                                            }
                                        </div>
                                    </div>
                                    {/* <p className="mt-0  pe-4"> {datey(props.data.createdAt)}</p> */}
                                    <p className="mt-0  pe-4"> {Moment(props.data.createdAt ? props.data.createdAt.slice(0, 24) : '').format(' h:mm:ss a')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }
}