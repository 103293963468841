import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const InformationDescription = ({ getval, vendor }) => {
  const { services_Provided,service_Charges  } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Accordion
        expanded={expanded === "panel299"}
        onChange={handleChange("panel299")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            <span>
              <b>
                {" "}
                Information / Description
                <br />
              </b>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="row py-2">
              <div className="col-md-6">
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-6">
                <span>Services Provided</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {services_Provided ? services_Provided : ""}
                </label>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-6">
                <span>Service Charge</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {service_Charges? service_Charges: ""}
                </label>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
