import React, { useState } from 'react'
import CommentInput from './commentInput'
import CommentMain from './commentMain'
import axios from 'axios'
import { notify } from '../../Utility/notify'
import LikeAndComment from '../Post2/LikeAndComment'

const PostFooter = ({ postId }) => {
    const [comment, setcomment] = useState(false)
    const commentSend = (data, commentId, cb) => {
        console.log(data);
        console.log(commentId);
        axios.post('/api/User/postcomment', { postId, comment: data, commentId }).then((res) => {
            console.log(res);
            notify("Comment posted", true)
            console.log(cb);
            console.log('object');
            if (cb) {
                console.log(typeof (cb));
                if (typeof (cb) == 'function')
                    cb()
            }
        }).catch((err) => {
            console.log(err.response);
            if (err.response.status == 401) {
                notify("You need an account !", false)
            } else if (err.response.status == 428) {
                notify("You need to add this topic to comment", false)
            }
            else {
                notify("something went wrong !", false)
            }
        })
    }
    return (
        <div>
            <LikeAndComment postId={postId} />
            <button className='btn pro-spaces-button w-100' onClick={(e) => { setcomment(!comment) }} >Comment</button>

            {comment && <CommentInput submit={commentSend} />}

            <CommentMain commentSend={commentSend} postId={postId} />

        </div>
    )
}

export default PostFooter