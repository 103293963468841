import React, { useState, useEffect, useRef } from "react";
import "./post.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { CommentReply } from "./commentReply";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };
  return [htmlElRef, setFocus];
};

export function CommentMain(props) {
  // console.log(props.data);
  const [Viewreply, setViewreply] = useState(false);
  const [Comment, setComment] = useState("");
  const [commenter, setcommenter] = useState();
  const [CommentReplyView, setCommentReplyView] = useState(false);
  const [cool, setcool] = useState(false);

  const commentHandlerlite = (e, id) => {
    // console.log(e.target.id);
    // console.log(id);
    var btn = "combtn" + e.target.id.substring(7);
    e.preventDefault();
    var data = {
      comment: commenter,
      postid: props?.data?.Post_id,
      Type: "Sub",
      id,
    };
    axios
      .post("/api/User/commentPost", { data })
      .then((response) => {
        if (response.status === 200) {
          // console.log("posted");
          setComment("");
          document.getElementById("spc" + id).value = "";
          document.getElementById("vazha" + props?.data?._id).click();
          if (!cool) {
            document.getElementById("Replya" + props?.data?._id).click();
          } else {
            liteLoad();
          }
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };

  const Replyhere = (val) => {
    // console.log(val);
    // console.log(props.dat.merge)
  };
  var booler;
  useEffect(() => {
    booler = true;
  }, []);

  const liteLoad = () => {
    var cid = props?.data?._id;
    var pid = props?.data?.Post_id;
    axios.post("/api/User/getreplycomment", { cid, pid }).then((response) => {
      if (response.status === 200) {
        // console.log(response.data);
        if (response.data.length > 0) {
          // console.log("first");
          setCommentReplyView(response.data);
        }
      }
    });
  };

  const viewReply = (e) => {
    if (!cool) {
      setcool(!cool);
      e.target.innerHTML = "Loading...";
      // console.log("first");
      booler = false;
      var cid = props?.data?._id;
      var pid = props?.data?.Post_id;
      // console.log(pid);
      axios
        .post("/api/User/getreplycomment", { cid, pid })
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);
            if (response.data.length > 0) {
              // console.log("first");
              setCommentReplyView(response.data);
              return (e.target.innerHTML = "Hide Reply ");
            }
            return (e.target.innerHTML = "Nothing to show !");
          }
        })
        .catch((res) => {
          console.log("Something went wrong");
          return (e.target.innerHTML = "Nothing to show !");
        });
    } else {
      setcool(!cool);
      e.target.innerHTML = "View replies";
    }
  };
  return (
    <>
      <div className="tab ms-1">
        <div className="celle cot">
          <img
            alt=""
            className="selfthumbnailcommentt"
            src={props?.data?.profilepic}
            onError={({ currentTarget }) => {
              currentTarget.onerror = true; // prevents looping
              currentTarget.src =
                "https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/SingleIcon.png";
            }}
          />
        </div>
        <div className="cell w-100 ps-0">
          <div className="Commentcontainer p-1 ">
            <div className="row">
              <p className="col-10 commentauthors m-0">
                {props?.data?.string[0].pname}

                <br />
                {/* <i className="m-0 commentauthorsdesig mb-1">Designation at world</i> */}
              </p>
              {/* <div className="col-2 text-end dropdown dropstart  pe-4">
                {" "}
                <a
                  type="button"
                  href="/"
                  className="dotoptionbtn float-end  cell"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BiDotsHorizontalRounded size="25" />
                </a>
                <ul className="dropdown-menu ">
                  <li>
                    <a className="dropdown-item" href="/">
                      <MdFlag /> Report
                    </a>
                  </li>
                 
                </ul>
              </div> */}
            </div>
            {/* comment body starts */}
            <p className="w-100 commentbody">{props?.data?.Content}</p>
            {/* comment body ends */}
            {/* <hr className="m-0"/> */}
          </div>
        </div>
      </div>
      <div className="d-flex flex-row ">
        <p className="replybtn ms-5"></p>
        <p
          className="replybtn me-2"
          onClick={(e) => viewReply(e)}
          id={"Replya" + props?.data?._id}
          data-bs-toggle="collapse"
          href={"#collapseExamplenew12" + props?.data?._id}
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          {/* {Viewreply ? 'Hide replies' : 'View replies'} */}View replies
        </p>
        <p
          onClick={(e) => Replyhere(props.ids + props.ids)}
          className="replybtn "
          id={"vazha" + props?.data?._id}
          data-bs-toggle="collapse"
          href={"#reply" + props?.data?._id}
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          | Reply
        </p>
      </div>
      {/* reply comment starts here */}
      <div id={"reply" + props?.data?._id} className="collapse ms-5">
        Replying to {props?.data?.string[0].pname}
        <div className="p-0 ">
          <form
            className="grid-container"
            onSubmit={(e) => commentHandlerlite(e, props?.data?._id)}
          >
            <textarea
              id={"spc" + props?.data?._id}
              onChange={(e) => setcommenter(e.target.value)}
              className="commentInput grid-item1"
              defaultValue={
                props?.data?.string[0].lastname
                  ? "@" + props?.data?.string[0].pname
                  : "@" + props?.data?.string[0].pname + " "
              }
              type="text"
              row="4"
            />
            <div className="grid-item2  ">
              <button
                type="submit"
                className="btn pro-spaces-button d-flex justify-content-center"
                style={{ width: "70px" }}
              >
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className="ms-5 collapse mt-1"
        id={"collapseExamplenew12" + props?.data?._id}
      >
        {CommentReplyView
          ? CommentReplyView.map((elem) => {
              return <CommentReply liteLoad={liteLoad} data={elem} />;
            })
          : ""}
      </div>
      {/* <CommentReply data={props.data} /> */}

      {/* reply comment ends here */}
    </>
  );
}
