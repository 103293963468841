import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './jobView.css'
import { BsFillBriefcaseFill, BsFillPeopleFill, } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { MdLocationPin, MdSettingsAccessibility } from 'react-icons/md'
import "./JobList.css";
import { Link } from "react-router-dom";
import Axios from "axios";

export const JobSeekersList = (props) => {
    const [Loader, setLoader] = useState(<center><div className="spinner-border " role="status">
        <span className="visually-hidden">Loading...</span>
    </div></center>)

    const [Loding, setLoding] = useState(true)
    const [Error, setError] = useState(false)
    const [Content, setContent] = useState(false)


    useEffect(() => {
        console.log(props)
    }, [])

    var a=props.data.JobRecruit_Malevacancies+props.data.JobRecruit_Femalevacancies+props.data.JobRecruit_Othervacancies+props.data.JobRecruit_Anyvacancies


    return (
        <>                   
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <div className="card carded shadow-sm">
                                <div className="card-body ">
                                      <div className="row">
                                <div className="entityJobs  col-12">
                                    <b>Job Category </b>
                                    <p className="ms-2 JobVal ">{props.data.category[0]?props.data.category[0].category:""}</p>
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Job Sub Category </b>
                                    <p className="ms-2 JobVal">{props.data.subcategory?props.data.subcategory[0].sub:""}</p>
                                </div>

                                <div className="entityJobs col-12">
                                    
                                    <b>Job Title </b>
                                    <p className="ms-2 JobVal">{props.data.JobRecruit_jobTitles?props.data.JobRecruit_jobTitles[0]:""}</p>
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Job Location (state) </b>
                                    <p className="ms-2 JobVal">{props.data.JobRecruit_companyState?props.data.JobRecruit_companyState:""}</p>
                                </div>
                                <div className="entityJobs col-12">
                                    <b>Job Location (city) </b>
                                    <p className="ms-2 JobVal">{props.data.JobRecruit_companyCity?props.data.JobRecruit_companyCity:""}</p>
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Work Mode</b>
                                    <p className="ms-2 JobVal">{props.data.JobRecruit_workPlace}</p>
                                </div>
                                <div className="col-1"></div>

                                <div className="entityJobs col-12">
                                    <b>Employment Type </b>
                                    <p className="ms-2 JobVal">{props.data.JobRecruit_empType}</p>
                                </div>

                                <div className="entityJobs col-12    ">
                                    <b>Experience Level </b>
                                    <div className="row">
                                    <p className=" JobVal"> {props.data.JobRecruit_expLvl?props.data.JobRecruit_expLvl                                 
                                            .map((e) => {

                                                return <span className='col-auto ms-2'>{e}</span>

                                            }) : 'Some thing went wrong!!!'}
                                                                 </p>

                                    </div>
                                    
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Salary Offered in LPA (Lakh Per Annum)</b>

                    
                                    <p className="ms-2 JobVal">{props.data.JobRecruit_minPay?props.data.JobRecruit_minPay+" - "+props.data.JobRecruit_maxPay:""}&nbsp;</p>
                                </div>

                                {
                                    props.data.JobRecruit_differentlyAbled==true||props.data.JobRecruit_differentlyAbled=="true"?
                                    <div className="col-12">
                                        <p className="ms-2 JobVal">We are open to employing differently-abled candidates</p>
                                    </div>
                                    :""
                                }

                                <div className="entityJobs col-12">
                                    <b>Educational Qualification </b>
                                    <p className="ms-2 JobVal">{props.data.JobRecruit_Qualification?props.data.JobRecruit_Qualification:""}&nbsp;</p>
                                </div>


                                <div className="entityJobs col-12">
                                    <b>Recruitment time</b>
                                    {
                                    props.data.JobRecruit_Immediaterecruitment==true||props.data.JobRecruit_Immediaterecruitment=="true"?
                                    <p className="ms-2 JobVal">Immediate  </p>
                                    :

                                    <p className="ms-2 JobVal">After {props.data.JobRecruit_AfterDays} days </p>
                                    

                                }
                                    
                                </div>
                            </div>
                                  








                                    <div className="mb-2">
                                        <div className='text-center mt-1'>
                                            <Link to={`/job_vacancy_view?k=${props.data._id}`} className="btn btn-war" >VIEW JOB PROFILE</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div >


            {/* <Link to={`/home/jobvacview?k=${props.data._id}`} className="btn btn-war" >View Post</Link> */}


        </>





    )
}
