import React, { useEffect } from "react";
import "./Subscrption.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

export function Subscrption() {
  useEffect(() => {
    document.getElementById("root").style.backgroundColor = "black";
    return () => {
      document.getElementById("root").style.backgroundColor = "white";
    };
  }, []);

  return (
    <div className="subscrption_font container">
      <div className="fontforsubcrption">
        <div className="row">
          <div className="col-8">SUBSCRIPTION / MEMBERSHIP FEES</div>
          <br />
          <div className="col-4">Fee per year</div>
          <br />
          <br />
        </div>
        <br />
        <div className="row gy-2">
          <div className="col-12">
            <span style={{ color: "#c85366" }}>CONSULTANTS / VENDORS</span>
          </div>
          <div className="col-8">
            <span style={{ color: "#c85366" }}>BASIC </span> membership
          </div>
          <div className="col-4">Rs 3000/- **</div>
          <div className="col-8">
            <span style={{ color: "#c85366" }}>PRIME</span> membership
          </div>
          <div className="col-4">Rs 6000/- **</div>
        </div>
        <br />
        <div className="row">
          <div className="col-8">
            <span style={{ color: "#c85366" }}>CLIENTS / CUSTOMERS</span>
          </div>
          <div className="col-4">Free *</div>
        </div>
        <br />
      </div>

      {/* <hr />
            <div className=''>
                <div className='row'>
                    <div className='col-12'>
                    <h6>for ENDORSEMENTS</h6>
                    </div>
                    <div className='col'>
                    
                    </div>
                </div><br />
                <div className='row'>
                    <div className='col'>Active members</div>
                    <div className='col'>Rs 5000/- up to 10 Service / Product-based Ads</div>
                </div><br />
                <div className='row'>
                    <div className='col'>Passive members, Non-members</div>
                    <div className='col'>Rs 5000/- for a Single Ad</div>
                </div><br />
                
            </div> */}
      <hr />

      <div>
        <h6>For Your Information :</h6>
        <ul>
          <li>
            ( * ) A Service Fee of Rs 100/- inclusive of 18% GST is applicable.{" "}
          </li>
          <li>( ** ) Fee is inclusive of 18% GST.</li>
          <li>
            A <span style={{ color: "#c85366" }}>Consultant / Vendor</span> can
            upgrade a <span style={{ color: "#c85366" }}>BASIC</span> membership
            to a <span style={{ color: "#c85366" }}>PRIME</span> membership at
            any time within the current year of subscription by paying the
            difference amount in subscription fees on pro-rata basis.
          </li>
          <li>
            A <span style={{ color: "#c85366" }}>Consultant / Vendor</span> can
            downgrade a <span style={{ color: "#c85366" }}>PRIME</span>{" "}
            membership to a <span style={{ color: "#c85366" }}>BASIC</span>{" "}
            membership only during renewal after completing one year of
            subscription.
          </li>
        </ul>

        {/* <p>2. Endorsements will be displayed only on the Home Page Advertisement Columns as a scroll.</p>
<p>3. Advertisement columns will be visible only on Tabs / iPads (in landscape mode), Laptops and Desktops.</p>
<p>4. Order of advertisements will be random.</p> */}
      </div>
    </div>
  );
}
