import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Vertual.css";
import "react-toastify/dist/ReactToastify.css";
import { Virtualmob } from "./Virtualmob";
import { Virtualbig } from "./Virtualbig";

export function Vertual() {
 

  

  const [Mobile, setMobile] = useState();
  function button() {
    var w = window.innerWidth;
    if (w < 768) {
      setMobile(true);
    } else {
    setMobile(false)
    }
  }
  useEffect(() => {
    button();
  });
  window.onresize = button;


    return (
      <>

    {Mobile?<Virtualmob />: <Virtualbig />  }
      </>
    );
  

 
}
