import React from 'react'
import './CommingSoon.css'
export  function CommingSoon() {
 
  return (
    <div class="fffnhfyfkneohf">
 <h1>COMING&nbsp;SOON</h1>
</div>
  )
}
