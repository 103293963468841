import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import './jobView.css'
import { BsFillBriefcaseFill, BsFillPeopleFill, } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { MdLocationPin, MdSettingsAccessibility } from 'react-icons/md'
// import "./JobList.css";
import {BiRupee} from 'react-icons/bi'

import { Link } from "react-router-dom";
import Axios from "axios";

export const JobSeekersList = (props) => {
    const [Loader, setLoader] = useState(<div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>)

    const [Loding, setLoding] = useState(true)
    const [Error, setError] = useState(false)
    const [Content, setContent] = useState(false)
   
         
    var age = Math.floor(
        parseInt(new Date() - new Date(props.data.JobSeeker_dob)) / 1000 / 60 / 60 / 24 / 365
    );


    return (
 


          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 mt-2">
        <div className="card carded shadow-sm">
            <div className="card-body ">
           
            <div className="row">
                                    <div className="entityJobs  col-12">
                                        <b>Name</b>
                                        <p className="ms-2 JobVal ">{props.data.JobSeeker_Name}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Gender </b>
                                        <p className="ms-2 JobVal">{props.data.JobSeeker_gender}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Age </b>
                                        <p className="ms-2 JobVal">{age}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Languages Known to Communicate Fluently </b>
                                        <p className="ms-2 JobVal">
                                        <div className="row">
                                    {props.data.JobSeeker_languageFluent ?

                                      props.data.JobSeeker_languageFluent                                 
                                            .map((e) => {

                                                return <div className='col'>{e}</div>

                                            }) : 'Some thing went wrong!!!'}
                                </div>
                                        </p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Category </b>
                                        <p className="ms-2 JobVal">{props.data.category[0]?props.data.category[0].category:''}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Sub Category </b>
                                        <p className="ms-2 JobVal">{props.data.subcategory[0]?props.data.subcategory[0].sub:''}</p>
                                    </div>
                                    <div className="entityJobs col-12">
                                        <b>Job Title</b>
                                        <p className="ms-2 JobVal" >{props.data.JobSeeker_jobTitles}</p>
                                    </div>

                                    <div className="entityJobs col-12">
                                        <b>Job Locations</b>
                                        <p className="ms-2 JobVal">
                                        <div className="row">
                                    {props.data.JobSeeker_jobLocations ?

                                      props.data.JobSeeker_jobLocations                                 
                                            .map((e) => {

                                                return <div className='col'>{e}</div>

                                            }) : 'Some thing went wrong!!!'}
                                </div>
                                        </p>
                                    </div>
                                   

                                    <div className="entityJobs col-12">
                                        <b>Work Mode</b>
                                        <p className="ms-2 JobVal">{props.data.JobSeeker_workPlace}</p>
                                    </div>
   

                                    <div className="entityJobs col-12">
                                        <b>Employment Type </b>
                                        <p className="ms-2 JobVal">{props.data.JobSeeker_empType}</p>
                                    </div>
                                    {
                                        props.data.Type==1?
                                        <>

                                  <div className="entityJobs col-12">
                                        <b>Currently (Employed / Unemployed) </b>

                                        <p className="ms-2 JobVal">{props.data.JobSeeker_currentlyWorking==true?"Employed ":"Unemployed"}</p>
                                    </div>

                                    <div className="col-1"></div>
                                    <div className="entityJobs col-12">
                                        {/* <b>Available for Immediate Joining / On Notice Period </b> */}
                                        {props.data.JobSeeker_availability==true ||"true"?<b>Available for Immediate Joining</b> : <b>On Notice Period</b>}
                                        <p></p>
                                    </div>

                                        
                                        </>

                                     

                                        :""
                                    }

                                    
                                    




                                   {


                                  props.data.Type!=2?

                                  props.data.JobSeeker_qualification.length>0?

                                        <>
                                         <div className="entityJobs col-12">
                                        <b>Degree </b>
                                        <p className="ms-2 JobVal">{props.data.JobSeeker_qualification?props.data.JobSeeker_qualification[0].Q5_1.degree :""}&nbsp;</p>
                                         </div>
                                         
                                         <>
                                         {props.data.JobSeeker_qualification?props.data.JobSeeker_qualification[0].Q5_1.special? 
                                        <div className="entityJobs col-12">
                                        <b>Specialization</b>
                                        <p className="ms-2 JobVal">{props.data.JobSeeker_qualification?props.data.JobSeeker_qualification[0].Q5_1.special :""}</p>
                                    </div>
                                        :""  :""}
                                         </>
                                      
                                        </>
    
                                        :''

                                    :''



                                   }
                                   
                                   {
                                    props.data.Type==2?

                                    <div className="entityJobs col-12">
                                        <b>Internship period </b>

                                        <p className="ms-2 JobVal">{props.data.JobSeeker_period}</p>
                                    </div>


                                    :""
                                   }
                                  

                                    

                                   

                                    {
                                        props.data.JobSeeker_expMonths|props.data.JobSeeker_expYears?

                                        <div className="entityJobs col-12    ">
                                        <b>Work Experience  </b>
                                        <p className="ms-2 JobVal"> 
                                                                     {props.data.JobSeeker_expMonths?props.data.JobSeeker_expMonths=="1"?props.data.JobSeeker_expMonths+" Month ":props.data.JobSeeker_expMonths+" Months ":""}

                                                                     {props.data.JobSeeker_expYears?props.data.JobSeeker_expYears=="1"?props.data.JobSeeker_expYears+" Year ":props.data.JobSeeker_expYears+" Years ":""}
                                                                     {/* {myList.Type==2?"Intern":""}
                                                                     {myList.Type==3?"Fresher":""} */}
                                                                     </p>
                                    </div>


                                        :''
                                    }
                                    <div className="col-1"></div>
                                   
                                </div>

                <div className="mb-2">
                    <div className='text-center mt-1'>
                        <Link to={`/view_applicants_single?k=${props.data._id}`} className="btn btn-war" >VIEW CANDIDATE PROFILE</Link>
                    </div>
                </div>
            </div>
        </div>

    </div >







    )
}
