
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { FaLock } from "react-icons/fa"

import "react-toastify/dist/ReactToastify.css";


let prev = null
// var room
// let topicRev
// let topicIn
// let dprev = null
// const fcheck = (ev) => {
//     if (ev == prev) {
//         return false
//     } else {
//         prev = ev
//         return true
//     }
// }





export function ChatGround(props) {

  
    const spaceDrop = <div style={{ 'visibility': 'hidden', 'height': '10px' }} className="row">
        <div className="col-10 ps-4 d-flex justify-content-end">
            <div className="  p-1 m-2">
                <div className="row ps-3 ">
                    <div className="col-12 p-0 pb-1 m-0">
                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp" alt="avatar"
                            className="rounded-circle chatAvatar me-2 align-self-center  shadow-1-strong" width="30" />
                        Ananda krishnan
                    </div>
                    <div className="m-0 p-0">
                        <div className="row p-0">
                            <div className="p-0 mesbox">
                                <p className="message mb-0 text-light bg-secondary px-3">
                                    <small>
                                        Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry.
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of
                                        type and scrambled it to make a type
                                        specimen book. It has survived not
                                        Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry.
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of
                                        type and scrambled it to make a type
                                        specimen book. It has survived not
                                    </small>
                                </p>
                                <p className="mt-0 text-end pe-4">2021-12-02 | 10.20 pm</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="col-2">
        </div>
    </div>
    if (props.RoomDetails.group) {
        // IN GROUP
        if (props.RoomDetails.topic) {
            // IN TOPIC
            return (
                <div className="col-12 s m-0 p-0 ">
                    <div className="chatspace bg-light"  >
                        {spaceDrop}

                       
                    </div>


                </div>
            )
        }
        else {
            // NO TOPIC
            return <div className="col-12 s m-0 p-0 ">
                <div className="chatspace bg-light">
                    {spaceDrop}

                    <center> <p className="cubmesss">Please select a Cubicle to start discussion
                    </p></center>
                </div>

                <div className="chatconsole ">
                    <div className='col-12 d-flex  align-items-center'>

                    </div>
                </div>
            </div>
        }

    } else {
        // user
        return (
            <div className="col-12 s m-0 p-0 ">
                <div id='cSpace' className="chatspace bg-light">
                    {spaceDrop}
                    
                    <div className="px-5 mt-2">  <p className="border encMess px-3 py-1 bg-secondary text-light "><FaLock /> {' '}Cubicle chats in Pro-spaces are completely Encrypted. Neither Pro-spaces nor anyone who is not a part of this Cubicle can read these chats.</p></div>
                   
                  
                </div>
  

            </div>
        )
    }
}