import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useNavigate } from "react-router-dom";
import "cropperjs/dist/cropper.css";
import axios from "axios";

import { FormProduct } from "../../../../Components/CreateProduct/FormProduct";
import { notify } from "../../../../Utility/notify";
import { Navoutside } from "../../../../Components/Navoutside/Navoutside";
import { NewNavigationBarDissabled } from "../../../Free/NewNavigationBar/NewNavigationBarDissabled";

function VendorEdit() {
  // .post("/api/ActiveUser/ProProfile", {})
  let navigate = useNavigate();
  const submit = async (formData) => {
    return new Promise((resolve, reject) => {
      // e.preventDefault();

      // formData.delete("file");
      // formData.delete("data");
      // console.log(value);
      // var dataSend = JSON.stringify(value);
      // console.log(newb);

      var status = false;
      let metadata = {
        type: "image/jpeg",
      };
      // if (newb) {
      //   let file = new File([newb], "test.jpg", metadata);
      //   console.log(file);

      //   formData.append("file", file);
      // }
      // formData.append("data", dataSend);

      // setSubmit(true);
      // status = validation();
      // if (status == true) {
      axios
        .put("/api/Free/ProProfileEdit", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          notify("Profile Updated", true);
          navigate(-1);
          resolve(true);
          return;
        })
        .catch((err) => {
          notify("Something went wrong ", false);
          navigate(-1);

          console.log(err);
          resolve(false);
        });

      // console.log('get')
      // } else {
      //   console.log("validation error");
      //   e.preventDefault();
      // }
    });
  };
  return (
    <>
      <NewNavigationBarDissabled />

      <div className="container rounded bg-white mt-5 mb-5">
        <FormProduct
          postTo={submit}
          edit={true}
          sab={true}
          get="/api/Free/ProProfileEdit"
          freeEdit="freeEdit"
        />
      </div>
    </>
  );
}

export default VendorEdit;
