import React,{useEffect,useState} from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap';
import axios, { Axios } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ShoutOutBlockedList() {

    const notify = (message,sab) =>{
        if(sab){
          toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }else{
          toast.warning(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        
      }
    const [Data, setData] = useState([])
    useEffect(() => {
        getData()
    }, [])

   const getData=()=>{
    axios.get("/api/User/BlockShoutOutGet").then((res)=>{
        console.log(res.data)
        if (res.status==200) {
            setData(res.data)
        }
      }).catch((err)=>{
        console.log(err)
      })
   }

   const unblock=(id)=>{
   console.log(id)
   axios.post("/api/User/BlockShoutOutUnblock",{id}).then((res)=>{
    console.log(res.data)
    if (res.status==200) {
        getData()
        notify("Unblocked",true);

    }
  }).catch((err)=>{
    console.log(err)
    notify("Something went wrong",false);

  })
   }
    
  return (
    <div className='container' style={{"paddingTop":"80px"}}>
    <div >
      <Row xs={1} sm={2} md={3} className="g-4">
        {Data[0]?.BlockedMembers?.map((elem, idx) => (
          <Col key={idx}>
            <Card>
              <Card.Body className="d-flex flex-column align-items-center">
                <Card.Title className="text-center mb-3">{elem.pname}</Card.Title>
                <button className="btn pro-spaces-button2" style={{"textTransform":"none"}} onClick={(e)=>unblock(elem._id)}  >Unblock</button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

    <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default ShoutOutBlockedList