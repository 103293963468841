import React from 'react'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./Job.css"
import {AiOutlineClose} from "react-icons/ai"



export const Job=()=> {

    return (
//         <div className="continer boom">
         
//         <div className="cardBoxi">
//           <div className='row'>


//             <div className='col lg-6 md-12 sm-12 mb-3 p-2'>
//       <a to="/home/postjob" className='tefnmbdfmpbompdmb'>
//       <div className="cardjob cardfgt rgkerpkgbepkgpetkg" >
//         <div>
//           <div className="numbers">I'm looking for a job... <br />Find me here ! </div>
//           <div className="cardName">
//            <br />
//            <br />
//          </div>
         
//         </div>
      
//       </div>
//       </a>
//       </div>
//       <div className='col lg-6 md-12 sm-12 p-2'>
//       <a to="/home/vacancy" className='tefnmbdfmpbompdmb'>
//       <div className="cardjob cardfgt ">
//         <div>
//           <div className="numbers">We've a job vacancy for you...<br />Are you interested ?</div>
//          <div className="cardName">
//            <br />
//            <br />
//          </div>
         
//         </div>
        
//       </div>
//       </a>
//       </div>
// </div>
     


//       </div>
//       </div>


<div className="container ">
        <div className="row slhishieo3jfwiugfiiehf">
        <div className="d-flex justify-content-end">
      <button className="btn pro-spaces-button mb-3 margintopof100px" data-bs-toggle="modal" data-bs-target="#jotunderstand">UNDERSTAND THIS FEATURE</button>
      </div>
      <hr />
          <div className="col lg-6 col-md-6 col-sm-12">

          {/* <Link to="/home/postjob"  className="wgfiuwrgiu"> */}
          <a >

            <div className="card carrrrbus">
              
              
                <div className="padunnor"><center>I'm looking for a job... <br />Find me here ! </center></div>
              
              
            </div>
            </a>
            {/* </Link> */}
          </div>
          {localStorage.getItem("Type")==="Consultant" || "Product"?
          <div className="col lg-6 col-md-6 col-sm-12">

          {/* <Link to="/home/vacancy"  className="wgfiuwrgiu"> */}
          <a>
            <div className="card carrrrbus">
            
             
                <div className="padunnor"><center>We've a job vacancy for you...<br />Are you interested ?</center></div>
              
  
            </div>
            </a>
            {/* </Link> */}
          </div>
          
          
          :""}
          
        </div>


        <div className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt"  id="jotunderstand" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header bg-black">
      
        <div className="weigh3rohbiwhbroiwehg">
      
      <AiOutlineClose style={{'color':'white'}} data-bs-dismiss="modal" aria-label="Close"></AiOutlineClose>
   
        </div>
      </div>
      <div className="modal-body bg-black">
        
      <div className="container">
          <div className="row">
            <h4 className="parapara22">JOBS</h4>
          </div>
          <div className="row">
           
            <p>Find the appropriate candidate for your organization based on the experience of the applicant and salary expectation. Vacancies can be posted in separate categories for interns, freshers and experienced candidates. Firms can post standard FAQs for interns and job seekers.</p>
            <p>Find the appropriate organization based on types of works handled and salary offered. There are application categories separately for interns, freshers and experienced candidates. Search for firms based on state and city preferences.</p>
           
          </div>
         
          
         
        </div>
        
      </div>
      
    </div>
  </div>
      </div>
    </div>



    )
}


