import React, { useState, useRef, useEffect } from 'react'
import html2canvas from "html2canvas";
import Button from 'react-bootstrap/Button';
import { BiDownload } from 'react-icons/bi'
import Modal from 'react-bootstrap/Modal';
import Handlebars from "handlebars";
import axios from 'axios';
import moment from 'moment'
function Add_Invoice_Download({ id }) {
    const [data, setData] = useState({
        DiscountAmount: "",
        GST: "",
        AmountToPay: "",
        PayAmount: "",
        order_id: "",
        GSTIN: "",
        pname: "",
        AmountToPayInWords: "",
        date: "",
    })
    const exportRef = useRef();
    const exportAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el);
        const image = canvas.toDataURL("image/png", 1.0);
        console.log(image)
        downloadImage(image, imageFileName);
    };
    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        // console.log(fileName)
        fakeLink.download = fileName;
        fakeLink.href = blob;
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        fakeLink.remove();
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const hbr = `
    <style type="text/css">
    body,
    table,
    td,
    a {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    }

    table,
    td {
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
    }

    img {
        -ms-interpolation-mode: bicubic;
    }

    img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
    }

    table {
        border-collapse: collapse !important;
    }

    body {
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
    }


    a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
    }

    @media screen and (max-width: 480px) {
        .mobile-hide {
            display: none !important;
        }

        .mobile-center {
            text-align: center !important;
        }
    }

    div[style*="margin: 16px 0;"] {
        margin: 0 !important;
    }
</style>
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
    <tr>
        <td align="center" style="background-color: #eeeeee;" bgcolor="#eeeeee">

            <table  id="xefwewe"  align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="width:600px;">
                <tr>
                    <td align="center" style="  background-color: #55374a;" bgcolor="#1b9ba3">
                        <table align="" border="0" cellpadding="0" cellspacing="0" width="100%"
                            style="max-width:600px;">
                            <tr>
                                <td align="left"
                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 20px 0px 15px 20px;">
                                    <span style="color: #ffffff; font-size: 30px; ">PROchure</span>

                                </td>
                            </tr>

                        </table>
                    </td>
                </tr>
                <tr>
                    <td align="center" style="padding: 5px 35px 0px 35px; background-color: #ffffff;"
                        bgcolor="#ffffff">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                            style="max-width:600px;">

                            <tr>
                                <td align="left"
                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;  ">
                                    <p style="font-size: 16px; font-weight: 400; line- color: #777777;">
                                        PROchure ™ is an application developed by Vipar CONNECT, a unit of Vipar&nbsp;<sup>®</sup>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" valign="top"
                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                    <p style="font-weight: 900;">ADVERTISEMENT SUBSCRIPTION INVOICE</p>
                                    <p></p>
                                </td>
                            </tr>
                            <tr>
                                <td align="left"
                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding-top: 10px;">

                                    <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span
                                            style="font-weight: 900;">Invoice Id</span><br />VcPro/22-23/{{order_id}}
                                    </p>
                                    <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span
                                            style="font-weight: 900;">Invoice Date</span><br />{{date}}</p>
                                    <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span
                                            style="font-weight: 900;">Vipar </span><br /># 203, Abhiman Hills
                                        <br />Light House Hill road, Hampankatta<br />Mangalore – 575001<br />GSTIN :
                                        29AERPV7005H2ZV</p>
                                    <hr />



                                    <p style="font-size: 16px; font-weight: 400; line-height: 24px; ">
                                        Recipient<br />{{pname}}<br />
                                        {{#if GSTIN}}
                                        GSTIN : {{GSTIN}}
                                        {{/if}}
                                    </p>
                                </td>
                            </tr>



                            <tr>
                                <td align="left" style="padding-top: 20px;">
                                    <table cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td width="75%" align="left" bgcolor="#eeeeee"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                                Order Id
                                            </td>
                                            <td width="25%" align="left" bgcolor="#eeeeee"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                                {{order_id}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="75%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                Advertisement Subscription Fee
                                            </td>
                                            <td width="25%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                ₹&nbsp;{{PayAmount}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="75%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 5px 10px;">
                                                Discount
                                            </td>
                                            <td width="25%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 5px 10px;">
                                                ₹&nbsp;{{DiscountAmount}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="75%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 5px 10px;">
                                                GST
                                            </td>
                                            <td width="25%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 5px 10px;">
                                                ₹&nbsp;{{GST}}
                                            </td>
                                        </tr>
                                        <tr>
                                        <td width="75%" align="left"
                                            style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px; border-top: 3px solid #eeeeee;">
                                            Total Amount
                                        </td>
                                        <td width="25%" align="left"
                                            style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px; border-top: 3px solid #eeeeee">
                                            ₹&nbsp;{{AmountToPay}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="75%" align="left"
                                            style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                            Total Amount ( In Words )
                                        </td>
                                        <td width="25%" align="left"
                                            style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                            {{AmountToPayInWords}}
                                        </td>
                                    </tr>
                                    </table>
                                </td>
                            </tr>

                        </table>

                    </td>
                </tr>
                <tr>
                    <td align="center" height="100%" valign="top" width="100%"
                        style="padding: 0 35px 35px 35px; background-color: #ffffff;" bgcolor="#ffffff">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                            style="max-width:660px;">
                            <tr>
                                <td align="center" valign="top" style="font-size:0;">


                                    <div style="display:inline-block;   vertical-align:top; width:100%;">
                                        <table align="center" border="0" cellpadding="0" cellspacing="0"
                                            width="100%">
                                            <tr>
                                                    <td align="left" valign="top"
                                                        style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                                        <p style="font-weight: 400;">For any queries and concerns, please visit "ASK US" (on the navigation bar of www.prochure.in)</p>
                                                        <p></p>
                                                    </td>
                                                </tr>
                                            <tr>
                                                <td align="center" valign="top"
                                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                                    <p style="font-weight: 400;">**This is a computer generated
                                                        invoice. No signature required.**</p>
                                                    <p></p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>


            </table>
        </td>
    </tr>
</table>`
    const doit = () => {
        console.log(id)
        axios.post('/api/User/ADDinvoice', { id }).then((res) => {
            console.log(res)
            let dat = res.data
            console.log(dat.created_at)
            let date = moment.unix(dat.created_at).format("DD-MM-YYYY");
            console.log(date)
            setData({
                ...dat.Notes, ...dat, date
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    const template = Handlebars.compile(hbr);
    return (
        <div className='d-flex justify-content-center'>
            <a className='' onClick={(e) => { handleShow(); doit() }} >View </a>
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tax Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: 'scroll' }} >
                    <div className='mt-2' ref={exportRef} dangerouslySetInnerHTML={{ __html: template(data) }} />
                </Modal.Body>
                <Modal.Footer style={{ 'justifyContent': 'center' }}>

                    <button className='btn pro-spaces-button3 ' onClick={(e) => exportAsImage(document.getElementById('xefwewe'), `Prochure_GST_Invoice_`)}>
                        Download
                    </button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Add_Invoice_Download