import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Navoutside } from "../Navoutside/Navoutside";
import axios from "axios";
import { NewNavigationBarDissabled } from "../../Pages/Free/NewNavigationBar/NewNavigationBarDissabled";

export function OutsidePcat() {
  const [first, setfirst] = useState("");

  useEffect(() => {
    // console.log("hai")
    axios
      .get("/api/Api/getprocategory", {})
      .then((res) => {
        // console.log(res)
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <NewNavigationBarDissabled />

      <div className="container">
        <div className="d-flex justify-content-end ">
          {/* <button onClick={(e)=>window.history.back()} className='btn pro-spaces-button  mt-3' >Go Back</button> */}
        </div>

        {first
          ? first.map((element) => {
              console.log(element.sub);
              return (
                <>
                  <div>
                    <div className="Categories pb-3 pt-3">
                      <b className="">{element.category}</b>
                    </div>
                    <div className="">
                      <ul>
                        {element.sub
                          ? element.sub.map((elm) => {
                              return (
                                <li>
                                  <div className="row">
                                    <div className="col-auto">{elm.sub}</div>
                                    {/* <div className='col-auto'>: 10</div> */}
                                  </div>
                                </li>
                              );
                            })
                          : ""}
                      </ul>
                    </div>
                  </div>
                  <hr />
                </>
              );
            })
          : ""}
      </div>
    </>
  );
}
