import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  createContext,
  useContext,
} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  AiFillCamera,
  AiFillExclamationCircle,
  AiOutlinePlusCircle,
} from 'react-icons/ai';
import {
  MenuProps,
  languages,
  arrayofdays,
  arrayofdays2,
} from '../../../Utility/languages';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useNavigate } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { keyGen } from '../../../Utility/helper';
import { print_state, print_city, state_arr } from '../../../Utility/stateCity';
import { CatsContext } from '../FormConsultent';
import { ConvertContext } from '../FormConsultent';
import { EditContext } from '../FormConsultent';
import MapWithSwitch from '../../Mapsv3/MapWithSwitch';
import MobileAddOn from '../../MobileAddOn/mobileAddOn';
import { TimeSelectBox } from '../../../Utility/timer';
import ClassNameGenerator from '@mui/utils/ClassNameGenerator';
import Modal from 'react-bootstrap/Modal';
import { Selfdeclaration } from '../../Selfdeclaration/Selfdeclaration';

import { FormHelperText } from '@mui/material';
import { ExistingData, InitializeCountry } from '../../../Utility/Country';
import { StateCity } from '../../CreateConseltent/StateCity';
import axios from 'axios';
import LogoFileUpload from '../../Inputs/LogoInput';

export function CreateConseltentA(props) {
  const currentUrl = window.location.href;
  const [dce, setdce] = useState(false);
  useEffect(() => {
    if (currentUrl.endsWith("dce")) {
      setdce(true);
    }
  }, []);
  let x = [...Array(100).keys()];
  const [Stateload, setStateload] = useState(true);
  useEffect(() => {
    if (Stateload) print_state('State');
    setStateload(false);
  }, []);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow1(false);
  const handleShow = () => setShow1(true);
  const Edit = useContext(EditContext);
  const cats = useContext(CatsContext);
  const convert = useContext(ConvertContext);
  const formData = new FormData();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [CONCount, setConCount] = useState([keyGen()]);
  const [Submit2, setSubmit2] = useState(false);
  const [loading, setloading] = useState(false);

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setValue({ ...value, state: e.target.value });
  }

  const [value, setValue] = useState({
    type: 'active',
    varient: 'consultent',
    firstname: '',
    lastname: '',
    age: '',
    language: [],
    Description: '',
    EntityName: '',
    gender: '',
    address_door: '',
    address_floor: '',
    address_building: '',
    address_nearest: '',
    address_street: '',
    address1: '',
    WomanOwned: false,
    website: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    email: '',
    worktimimng: false,
    category: cats.value.category,
    subcategory: cats.value.subcategory,
    Brand: cats.value.Brand,
    image: '',
    contact: '',
    contact2: '',
    contact3: '',
    contact4: '',
    pname: '',
    working_leave: '',
    working_hour_2: '',
    working_hour_1: '',
    working_hour_3: '',
    working_hour_4: '',
    working_days_2: '',
    working_days_1: '',
    working_days_3: '',
    working_days_4: '',
    preferredlanguage: '',
    formType: 1,
    countrycode: '',
    statecode: '',
    contactpersonsname: '',
    contactpersonsdesignation: '',
    contactpersonsname2: '',
    contactpersonsdesignation2: '',
    preferredlanguage2: '',
    contactpersonsname3: '',
    contactpersonsdesignation3: '',
    preferredlanguage3: '',
    contactpersonsname4: '',
    contactpersonsdesignation4: '',
    preferredlanguage4: '',
    licence: '',
    gst: '',
    workingdaysfullnew: [],
    workingdayshalfnew: [],
    workingdaysOffnew: [],
    break_time_2: '',
    break_time_1: '',
  });
  useEffect(() => {
    setValue({
      ...value,
      subcategory: cats.value.subcategory,
      category: cats.value.category,
      Brand: cats.value.Brand,
    });
    return () => { };
  }, [cats]);

  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  const [error, setError] = useState('');
  const [Submit, setSubmit] = useState(false);
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState(false);
  const [cropper, setCropper] = useState();

  const onChange = async (e) => {
    // $("#profileUpdation").modal("show");
    var myModal = new bootstrap.Modal(
      document.getElementById('profileUpdation')
    );
    myModal.show();
    var myModalEl = document.getElementById('profileUpdation');
    myModalEl.addEventListener('shown.bs.modal', function (event) {
      cropData ? setCropData(false) : <></>;
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      if (files[0]) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
      }
      e.target.value = null;
    });

    var x = document.getElementById('profileUpdation').offsetWidth;
    //    var s=document.getElementById('profileUpdation')
  };

  const [imageinserted, setimageinserted] = useState(false);
  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      setimageinserted(true);
      setCropData();
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          setCropData(URL.createObjectURL(blob));
          setnewb(blob);
        },
        'image/jpeg',
        0.5
      );
    }
  };

  const uploadme = () => {
    var DP = document.getElementById('DP');
    DP.src = cropData;
  };

  const photo = () => document.getElementById('image').click();

  useEffect(() => {
    setValue({ ...value, image: cropData });
  }, [cropData]);
  // useEffect(() => {
  //     console.log(value)
  // }, [value])
  // console.log('zzz')

  const [selfDeclaration, setSelfDeclaration] = useState(false);

  function validation() {
    console.log(value);

    if (!Edit) {
      if (cropData) {
        var imgsize = cropData.length - 22;
        var imgsizeinkb =
          (4 * Math.ceil(imgsize / 3) * 0.5624896334383812) / 1000;
        if (cropData) {
          setError();
        } else {
          return setError('Photo size is greater than 2MB');
        }
      } else {
        return setError('PLEASE upload PROFILE DISPLAY PICTURE');
      }
    }

    if (cats.brand.length > 0) {
      if (value.Brand === undefined) {
        return setError('PLEASE SELECT A BRAND');
      }
      if (value.Brand.length === 0) {
        return setError('PLEASE SELECT A BRAND');
      }
    }

    if (value.pname.length > 0) {
      setError('');
    } else {
      return setError('PLEASE ENTER PROFILE DISPLAY NAME');
    }

    if (value.category.length > 0) {
      setError('');
    } else {
      return setError('PLEASE ENTER Category');
    }

    if (value.subcategory.length > 0) {
      setError('');
    } else {
      return setError('PLEASE ENTER Sub Category');
    }

    // if (value.address_floor.length == 0) {
    //     return setError("PLEASE SELECT Floor");
    // } else {
    //     setError("");
    // }
    // if (value.address_building.length == 0) {
    //     return setError("PLEASE ENTER Building / Mall / Property Name ");
    // } else {
    //     setError("");
    // }
    // if (value.address_street.length == 0) {
    //     return setError("PLEASE ENTER Street / Lane / Road Name ");
    // } else {
    //     setError("");
    // }
    // if (value.address1.length == 0) {
    //     return setError("PLEASE ENTER Area / Locality Name ");
    // } else {
    //     setError("");
    // }

    // if (value.firstname.length > 0) {
    //   setError('');
    // } else {
    //   return setError('PLEASE ENTER First Name');
    // }
    // console.log(value.lastname.length);
    // if (value.lastname.length > 0) {
    //   setError('');
    // } else {
    //   return setError('PLEASE ENTER Last Name');
    // }
    // if (value.gender.length > 0) {
    //   setError('');
    // } else {
    //   return setError('PLEASE ENTER gender');
    // }

    // console.log(typeof value.age);

    // if (value.age.length > 0) {
    //   if (value.age > 17) {
    //     setError('');
    //   } else {
    //     return setError('Sorry ! You should be atleast 18 years of age');
    //   }
    // } else {
    //   return setError('PLEASE ENTER Your Age');
    // }

    // if (value.language.length > 0) {
    //   setError('');
    // } else {
    //   return setError('PLEASE ENTER Languages Known');
    // }

    if (value.state.length > 0) {
      setError('');
    } else {
      return setError('PLEASE ENTER State');
    }

    if (value.city.length > 0) {
      setError('');
    } else {
      return setError('PLEASE ENTER City');
    }

    if (value.pincode.length > 0) {
      setError('');
    } else {
      return setError('PLEASE ENTER Pin code / Zip code ');
    }

    // if (value.licence.length > 0) {
    //     setError("");
    // } else {
    //     return setError("PLEASE ENTER Do you have a Trade Licence ?");
    // }

    // if (value.gst.length > 0) {
    //     setError("");
    // } else {
    //     return setError("PLEASE ENTER Is your entity GST registered ?");
    // }
    if (!value.worktimimng) {
      if (value.workingdaysfullnew.length > 0) {
        setError('');
      } else {
        return setError('PLEASE ENTER Working Days (full)');
      }

      if (value.working_hour_1.length > 0) {
        setError('');
      } else {
        return setError('PLEASE ENTER Working Hours');
      }

      if (value.working_hour_2.length > 0) {
        setError('');
      } else {
        return setError('PLEASE ENTER Working Hours');
      }
      if (value.break_time_1.length > 0) {
        setError('');
      } else {
        return setError('PLEASE ENTER Break Time');
      }
      if (value.workingdayshalfnew.length > 0) {
        setError('');
      } else {
        return setError('PLEASE ENTER Working Days (half)');
      }

      if (value.working_hour_3.length > 0) {
        setError('');
      } else {
        return setError('PLEASE ENTER Working Hours');
      }

      if (value.working_hour_4.length > 0) {
        setError('');
      } else {
        return setError('PLEASE ENTER Working Hours');
      }
    }

    if (value.workingdaysOffnew.length > 0) {
      setError('');
    } else {
      return setError('PLEASE ENTER Weekly Off');
    }

    if (value.countrycode.length > 0) {
      setError('');
    } else {
      return setError('PLEASE SELECT Country Code');
    }
    // if (value.statecode.length > 0) {
    //     setError("");
    // } else {
    //     return setError("PLEASE SELECT State Code");
    // }

    for (const element of CON) {
      if (CON.indexOf(element) === 0) {
        if (element.contact2.length < 6 || element.contact2.length > 12) {
          return setError(
            'PLEASE ENTER VALID A Mobile Number / Landline Number'
          );
        }
        if (element.contactpersonsname2.length == 0) {
          return setError(`PLEASE ENTER Contact Person’s Name`);
        }
        if (element.contactpersonsdesignation2.length == 0) {
          return setError(`PLEASE ENTER Contact Person’s Designation`);
        }
        if (element.preferredlanguage2.length == 0) {
          return setError(
            `PLEASE SELECT A Most Comfortable / Preferred Language for Communication for ${element.contact2}`
          );
        }
      }
    }
    // if (value.email.length > 0) {
    //     if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.email)) {
    //         setError("");
    //     } else {
    //         return setError("PLEASE ENTER a valid email id");
    //     }
    // } else {
    //     return setError("PLEASE ENTER email id");
    // }

    if (!Edit) {
      if (!selfDeclaration) {
        return setError('PLEASE CHECK the self declaration above !');
      }
    }

    return true;
  }

  const [newb, setnewb] = useState();
  const submit = async (e) => {
    e.preventDefault();
    let loc = false;
    if (Location.lng) {
      loc = true;
    }
    formData.delete('file');
    formData.delete('data');
    var dataSend = JSON.stringify({ value, Location, LocationOn: loc, CON });
    var status = false;
    let metadata = {
      type: 'image/jpeg',
    };
    if (newb) {
      let file = new File([newb], 'test.jpg', metadata);
      formData.append('file', file);
    }
    if (Logo) {
      let file = new File([Logo], "company_logo.png", metadata);
      formData.append("file", file);
  }
    formData.append('data', dataSend);

    setSubmit(true);
    status = validation();
    if (status == true) {
      setloading(true);
      if (!dce) {
        let a = await props.postTo(formData);
        if (!a) {
          setloading(false);
        }
      } else {
        axios.put('/api/Free/ConProfileDce', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }).then((res) => {
          setloading(false)
          navigate('/payment?dc=true')
        }).catch((err) => {
          console.log(err);
          setloading(false)
        })
      }
    }
  };

  useEffect(() => {
    if (Submit) {
      validation();
    }
  }, [value, selfDeclaration]);

  const [CON, setCON] = useState([]);

  const handleCON = (e, index) => {
    try {
      console.log({ e, index }, { CON });
      let CONcollection = CON;
      if (e === 'remove') {
        CONcollection.splice(index, 1);
        let dsicount = CONCount.slice();
        let c = dsicount.splice(index, 1);
        setConCount(dsicount);
      } else {
        CONcollection[index] = e;
      }
      setCON(CONcollection);
    } catch (error) {
      console.log(error);
    }
  };

  const [Location, setLocation] = useState({ lat: '', lng: '' });
  const [LocationOn, setLocationOn] = useState();

  useEffect(() => {
    if (Edit) {
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];

      if (Edit[0].workingdaysfullnew) {
        if (typeof Edit[0].workingdaysfullnew === 'string') {
          arr1 = [...arr1, Edit[0].workingdaysfullnew];
        } else {
          arr1 = [...Edit[0].workingdaysfullnew];
        }
      }

      if (Edit[0].workingdayshalfnew) {
        if (typeof Edit[0].workingdayshalfnew === 'string') {
          arr2 = [...arr2, Edit[0].workingdayshalfnew];
        } else {
          arr2 = [...Edit[0].workingdayshalfnew];
        }
      }

      if (Edit[0].workingdaysOffnew) {
        if (typeof Edit[0].workingdaysOffnew === 'string') {
          arr3 = [...arr3, Edit[0].workingdaysOffnew];
        } else {
          arr3 = [...Edit[0].workingdaysOffnew];
        }
      }

      setCON(Edit[0].CON ? Edit[0].CON : []);

      setValue({
        ...value,
        id: Edit[0]._id,
        pname: Edit[0].pname ? Edit[0].pname : '',
        worktimimng: Edit[0].worktimimng ? Edit[0].worktimimng : false,
        firstname: Edit[0].firstname ? Edit[0].firstname : '',
        lastname: Edit[0].lastname ? Edit[0].lastname : '',
        age: Edit[0].age ? Edit[0].age : '',
        language: Edit[0].language ? Edit[0].language : [],
        Description: Edit[0].Description ? Edit[0].Description : '',
        EntityName: Edit[0].EntityName ? Edit[0].EntityName : '',
        gender: Edit[0].gender ? Edit[0].gender : '',

        break_time_2: Edit[0].break_time_2 ? Edit[0].break_time_2 : '',
        break_time_1: Edit[0].break_time_1 ? Edit[0].break_time_1 : '',
        address_door: Edit[0].address_door ? Edit[0].address_door : '',
        address_floor: Edit[0].address_floor ? Edit[0].address_floor : '',
        address_building: Edit[0].address_building
          ? Edit[0].address_building
          : '',
        address_nearest: Edit[0].address_nearest ? Edit[0].address_nearest : '',
        address_street: Edit[0].address_street ? Edit[0].address_street : '',
        address1: Edit[0].address1 ? Edit[0].address1 : '',
        state: Edit[0].state,
        city: Edit[0].city,
        country: Edit[0].country,
        pincode: Edit[0].pincode,
        email: Edit[0].email,
        CON: Edit[0].CON ? Edit[0].CON : [],
        WomanOwned: Edit[0].WomanOwned ? Edit[0].WomanOwned : false,
        category: cats.category,
        subcategory: cats.subcategory,
        licence: Edit[0].licence ? Edit[0].licence : '',
        gst: Edit[0].gst ? Edit[0].gst : '',
        website: Edit[0].website,
        working_leave: Edit[0].working_leave ? Edit[0].working_leave : '',
        working_hour_2: Edit[0].working_hour_2 ? Edit[0].working_hour_2 : '',
        working_hour_1: Edit[0].working_hour_1 ? Edit[0].working_hour_1 : '',
        working_days_2: Edit[0].working_days_2 ? Edit[0].working_days_2 : '',
        working_days_1: Edit[0].working_days_1 ? Edit[0].working_days_1 : '',
        working_hour_3: Edit[0].working_hour_3 ? Edit[0].working_hour_3 : '',
        working_hour_4: Edit[0].working_hour_4 ? Edit[0].working_hour_4 : '',
        working_days_3: Edit[0].working_days_3 ? Edit[0].working_days_3 : '',
        working_days_4: Edit[0].working_days_4 ? Edit[0].working_days_4 : '',
        countrycode: Edit[0].countrycode ? Edit[0].countrycode : '',
        statecode: Edit[0].statecode ? Edit[0].statecode : '',
        workingdaysfullnew: arr1,
        workingdayshalfnew: arr2,
        workingdaysOffnew: arr3,
        contact: Edit[0].contact ? Edit[0].contact : '',
        contactpersonsname: Edit[0].contactpersonsname
          ? Edit[0].contactpersonsname
          : '',
        contactpersonsdesignation: Edit[0].contactpersonsdesignation
          ? Edit[0].contactpersonsdesignation
          : '',
        preferredlanguage: Edit[0].preferredlanguage
          ? Edit[0].preferredlanguage
          : '',

        contact2: Edit[0].contact2 ? Edit[0].contact2 : '',
        contactpersonsname2: Edit[0].contactpersonsname2
          ? Edit[0].contactpersonsname2
          : '',
        contactpersonsdesignation2: Edit[0].contactpersonsdesignation2
          ? Edit[0].contactpersonsdesignation2
          : '',
        preferredlanguage2: Edit[0].preferredlanguage2
          ? Edit[0].preferredlanguage2
          : '',

        contact3: Edit[0].contact3 ? Edit[0].contact3 : '',
        contactpersonsname3: Edit[0].contactpersonsname3
          ? Edit[0].contactpersonsname3
          : '',
        contactpersonsdesignation3: Edit[0].contactpersonsdesignation3
          ? Edit[0].contactpersonsdesignation3
          : '',
        preferredlanguage3: Edit[0].preferredlanguage3
          ? Edit[0].preferredlanguage3
          : '',

        contact4: Edit[0].contact4 ? Edit[0].contact4 : '',
        contactpersonsname4: Edit[0].contactpersonsname4
          ? Edit[0].contactpersonsname4
          : '',
        contactpersonsdesignation4: Edit[0].contactpersonsdesignation4
          ? Edit[0].contactpersonsdesignation4
          : '',
        preferredlanguage4: Edit[0].preferredlanguage4
          ? Edit[0].preferredlanguage4
          : '',
      });
      console.log(arr1, arr2, arr3);
      if (Edit[0].state.length >= 1) {
        var index = state_arr.indexOf(Edit[0].state);
        print_city('City', index + 1);
      }
      let com = [];
      if (Edit[0].CON) {
        Edit[0].CON.forEach((element) => {
          com = [...com, keyGen()];
        });
        setConCount(com);
      }

      if (Edit[0].WomanOwned) {
        document.getElementById('Enterprise').checked = true;
      }
    }
    return () => { };
  }, []);

  // var bucketName='https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/profile'
  var bucketName =
    'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/profile';

  const isOptionDisabled = (name, value) => {
    // Add your condition to disable multiple options here
    // console.log(name)
    if (value.length > 0) {
      if (value.indexOf('Nil') >= 0) {
        return (
          name === 'Monday' ||
          name === 'Tuesday' ||
          name === 'Wednesday' ||
          name === 'Thursday' ||
          name === 'Friday' ||
          name === 'Saturday' ||
          name === 'Sunday'
        );
      } else {
        return name === 'Nil';
      }
    }
  };
  const [Logo, setLogo] = useState(null)
  const [logoRemove, setlogoRemove] = useState(false)


  return (
    <>
      <div className="container rounded bg-white pt-5 pb-5">
        <form
          id="f1"
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <div
            className="modal fade"
            id="profileUpdation"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered  ">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="exampleModalLabel">
                    Profile Picture
                  </h6>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div id="bod" className="modal-body ">
                  <div>
                    <div className="d-flex justify-content-center">
                      {cropData ? (
                        <div
                          className="box"
                          style={{ width: '50%', float: 'right' }}
                        >
                          <img
                            style={{ width: '100%' }}
                            src={cropData}
                            alt="cropped"
                          />
                          {/* <button className="btn btn-success mt-2" style={{ float: "right" }}  >Save</button> */}
                        </div>
                      ) : (
                        <div style={{ width: '100%' }}>
                          {' '}
                          <Cropper
                            style={{ height: 400, width: '100%' }}
                            zoomTo={false}
                            aspectRatio={45 / 45}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={0}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={true}
                          />
                          {/* <button className="btn btn-info text-light mt-2" style={{ float: "right" }} onClick={getCropData}>Ok</button> */}
                        </div>
                      )}
                    </div>
                    {/* <button className="btn btn-info text-light" style={{ float: "right" }}  onClick={getCropData}>Ok</button> */}

                    {/* <button onClick={() => uploadme()}>Start upload</button> */}
                  </div>
                </div>
                <div className="modal-footer">
                  {/* <button onClick={() => setcp(true)}>doit</button> */}
                  {!cropData ? (
                    <button
                      type="button"
                      className="btn pro-spaces-button3"
                      onClick={getCropData}
                    >
                      Ok
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn pro-spaces-button3"
                      onClick={() => uploadme()}
                      data-bs-dismiss="modal"
                    >
                      Save changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 border-right ">
              <div className="d-flex flex-column align-items-center text-center pb-3 ps-0 pe-0 pt-5 ">
                <img
                  id="DP"
                  className=" mt-5"
                  width="150px"
                  src={`${bucketName}/${value.id}/avatar.png`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = '/blank.png';
                  }}
                />
                <div id="idtestingprofilepicvalidation" className="upload">
                  <input
                    required={imageinserted === false && !Edit}
                    type="file"
                    accept="image/*"
                    onChange={onChange}
                    id="image"
                    className="form-control"
                  />
                  <label for="image">
                    <AiFillCamera size={'40px'} />
                  </label>
                  <div
                    class="invalid-feedback"
                    style={{ backgroundColor: 'white' }}
                  >
                    PLEASE upload PROFILE DISPLAY PICTURE
                  </div>
                </div>
                <span className="text-50 mt-3 up" onClick={() => photo()}>
                  PROFILE DISPLAY PICTURE
                </span>
                <span> </span>
              </div>
            </div>

            <div className="col-md-9 border-right">
              <div className="pb-3 ps-0 pe-0 pt-5">
                <h6>PROFILE DISPLAY NAME *</h6>
                <div className="row mt-2">
                  <div className="col-12">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Individual / Entity / Company Name"
                      value={value.pname}
                      onChange={(e) =>
                        setValue({ ...value, pname: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER PROFILE DISPLAY NAME
                    </div>
                  </div>
                </div>

                <div className="row mt-2 invisible">
                  <div className="col-12 pt-1">
                    <div className="form-check">
                      <input
                        onChange={(e) =>
                          setValue({
                            ...value,
                            WomanOwned: !value.WomanOwned,
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        id="Enterprise"
                      />
                      <label className="labels" for="Enterprise">
                        I am a Woman Entrepreneur
                        <span style={{ fontWeight: '500' }}>
                          {' '}
                          (check box to get a profile badge)
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <h6 className="mt-4">
                Personal Information{' '}
                <p style={{ fontWeight: '400', fontSize: '15px' }}>
                  (of the Service Provider / Proprietor)
                </p>
              </h6>
              <div className="row mt-2">
                <div className="col-12">
                  <label className="labels">First Name </label>
                  <input
                    // required
                    type="text"
                    className="form-control"
                    value={value.firstname}
                    onChange={(e) =>
                      setValue({ ...value, firstname: e.target.value })
                    }
                  />
                  {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                </div>
                <div className="col-12">
                  <label className="labels">Last Name </label>
                  <input
                    // required
                    type="text"
                    className="form-control"
                    value={value.lastname}
                    onChange={(e) =>
                      setValue({ ...value, lastname: e.target.value })
                    }
                  />
                  {/* <div class="invalid-feedback">
                                        PLEASE ENTER Last Name
                                    </div> */}
                </div>

                <div className="col-12">
                  <label className="labels">Gender </label>
                  <select
                    // required
                    className="form-select"
                    value={value.gender}
                    onChange={(e) =>
                      setValue({ ...value, gender: e.target.value })
                    }
                  >
                    <option value="" disabled selected></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Transgender">Transgender</option>
                  </select>
                  {/* <div class="invalid-feedback">
                                        PLEASE SELECT Gender
                                    </div> */}
                </div>
                <div className="col-md-12">
                  <label className="labels">Age</label>
                  <input
                    // required
                    type="tel"
                    maxLength="3"
                    className="form-control"
                    value={value.age}
                    onChange={(e) => {
                      const age = parseInt(e.target.value);
                      if (age < 18) {
                        // If age is not a number or less than 18, set an error message
                        e.target.setCustomValidity(
                          'Age must be at least 18 years'
                        );
                      } else {
                        // If age is valid, clear the error message
                        e.target.setCustomValidity('');
                      }
                      setValue({ ...value, age: e.target.value });
                    }}
                  />
                </div>
                <div className="col-md-12">
                  {/* working */}
                  <label className="labels">Languages Known </label>

                  <FormControl className="form-control">
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      //    required
                      // error={Submit2 ? value?.language?.length != 0 ? false : true : false}
                      className="form-select"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          display: 'none',
                        },
                        '& .MuiSvgIcon-root': { display: 'none' },
                        '& .MuiSelect-select': { padding: '0' },
                      }}
                      value={value.language}
                      onChange={(e) =>
                        setValue({ ...value, language: e.target.value })
                      }
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {languages.map((languages) => (
                        <MenuItem key={languages} value={languages}>
                          <Checkbox
                            checked={value.language.indexOf(languages) > -1}
                          />
                          <ListItemText primary={languages} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      className="mx-0"
                      style={{
                        marginTop: '.25rem',
                        fontSize: '.875rem',
                        color: '#dc3545',
                      }}
                    >
                      {/* {Submit2 ? value?.language?.length != 0 ? false : 'PLEASE SELECT Languages Known' : false} */}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-12 pt-1">
                  <div className="form-check">
                    <input
                      onChange={(e) =>
                        setValue({
                          ...value,
                          WomanOwned: !value.WomanOwned,
                        })
                      }
                      className="form-check-input"
                      type="checkbox"
                      id="Enterprise"
                      checked={value.WomanOwned}
                    />
                    <label className="labels" for="Enterprise">
                      I am a Woman Entrepreneur
                      <span style={{ fontWeight: '500' }}>
                        {' '}
                        (check box to get a profile badge)
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <h6 className="mt-4">Service Description</h6>
              <div className="row mt-2">
                <div className="col-12">
                  <label className="labels">
                    Write a brief Description about your Services
                  </label>
                  <textarea
                    className="form-control"
                    value={value.Description}
                    onChange={(e) =>
                      setValue({ ...value, Description: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>

              <div className=" pt-2">
                <h6 className="mt-4">Business Information</h6>

                {/* <h6 style={{ fontSize: '0.8rem' }}>Head Office / Registered Office / Main Branch </h6> */}
                <div className="col-md-12">
                  <label className="labels">Entity Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={value.EntityName}
                    onChange={(e) =>
                      setValue({ ...value, EntityName: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-12">
                    <LogoFileUpload onChange={(e) => {
                    setLogo(e);
                    if (e == null) {
                      setlogoRemove(true)
                    } else {
                      setlogoRemove(false)
                    }
                  }} Edit={Edit} />
                </div>
                <label className="labels">Address *</label>

                <div className="col-md-12">
                  <label className="labels">
                    Building / Mall / Property Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={value.address_building}
                    onChange={(e) =>
                      setValue({ ...value, address_building: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-12">
                  <label className="labels">Door / Shop No.</label>
                  <input
                    type="text"
                    className="form-control"
                    value={value.address_door}
                    onChange={(e) =>
                      setValue({ ...value, address_door: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-12">
                  <label className="labels">Floor</label>
                  <select
                    className="form-select"
                    value={value.address_floor}
                    onChange={(e) =>
                      setValue({ ...value, address_floor: e.target.value })
                    }
                  >
                    <option value="" disabled selected></option>
                    <option>Basement</option>
                    <option>Ground</option>
                    <option>Mezzanine</option>
                    {x.map((elem, index) => {
                      let temp = index + 1;
                      if (index < 10) temp += 10;
                      if (index + 1 <= 10 || index + 1 >= 20)
                        if (temp % 10 === 1)
                          return <option>{index + 1}st</option>;
                        else if (temp % 10 === 2)
                          return <option>{index + 1}nd</option>;
                        else if (temp % 10 === 3)
                          return <option>{index + 1}rd</option>;
                        else return <option>{index + 1}th</option>;
                      else return <option>{index + 1}th</option>;
                    })}
                  </select>
                </div>

                <div className="col-md-12">
                  <label className="labels">Street / Lane / Road Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={value.address_street}
                    onChange={(e) =>
                      setValue({ ...value, address_street: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-12">
                  <label className="labels">Area / Locality Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={value.address1}
                    onChange={(e) =>
                      setValue({ ...value, address1: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-12">
                  <label className="labels">Nearest Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    value={value.address_nearest}
                    onChange={(e) =>
                      setValue({ ...value, address_nearest: e.target.value })
                    }
                  />
                </div>
                <StateCity setValue={setValue} value={value} Edit={Edit} />

                <div className="col-md-12">
                  <label className="labels">Pin code / Zip code *</label>
                  <input
                    required
                    type="tel"
                    className="form-control"
                    value={value.pincode}
                    onChange={(e) => {
                      setValue({ ...value, pincode: e.target.value });
                    }}
                  />
                  <div class="invalid-feedback">
                    PLEASE ENTER Pin code / Zip code
                  </div>
                </div>
                <div className="col-md-12 py-3">
                  <MapWithSwitch
                    Location={Location}
                    setLocation={setLocation}
                    LocationOn={LocationOn}
                    setLocationOn={setLocationOn}
                    key={'aouhduahdiuqbduiqderhetjyvq65564'}
                  />
                </div>
                <div className="col-md-12">
                  <label className="labels">
                    Do you have a Trade Licence ? *
                  </label>
                  <select
                    required
                    className="form-select"
                    value={value.licence}
                    onChange={(e) =>
                      setValue({ ...value, licence: e.target.value })
                    }
                  >
                    <option value="" disabled selected></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="N/A">N/A</option>
                  </select>
                  <div class="invalid-feedback">
                    PLEASE ENTER Do you have a Trade Licence ?
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">
                    Is your entity GST registered ? *
                  </label>
                  <select
                    required
                    className="form-select"
                    value={value.gst}
                    onChange={(e) =>
                      setValue({ ...value, gst: e.target.value })
                    }
                  >
                    <option value="" disabled selected></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <div class="invalid-feedback">
                    PLEASE ENTER Is your entity GST registered ?
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Website</label>
                  <input
                    type="text"
                    className="form-control"
                    value={value.website}
                    onChange={(e) =>
                      setValue({ ...value, website: e.target.value })
                    }
                  />
                </div>

                <label className="labels mt-3">Work Timings *</label>
                <div className="col-12 pt-1">
                  <div className="form-check">
                    <input
                      onChange={(e) =>
                        setValue({
                          ...value,
                          worktimimng: !value.worktimimng,
                        })
                      }
                      className="form-check-input"
                      type="checkbox"
                      id="Enterprsdffise"
                      checked={value.worktimimng}
                    />
                    <label className="labels" for="Enterprsdffise">
                      We are open 24 hours
                    </label>
                  </div>
                </div>

                <div className="col-md-12">
                  <label className="labels">
                    Working Days (full) {value.worktimimng ? '' : '*'}
                  </label>
                  <FormControl className="form-control">
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      required={
                        Submit2
                          ? value?.worktimimng === true
                            ? false
                            : value.workingdaysfullnew.length > 0
                              ? false
                              : true
                          : false
                      }
                      error={
                        Submit2
                          ? value?.worktimimng === true
                            ? false
                            : value.workingdaysfullnew.length > 0
                              ? false
                              : true
                          : false
                      }
                      className="form-select"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          display: 'none',
                        },
                        '& .MuiSvgIcon-root': { display: 'none' },
                        '& .MuiSelect-select': { padding: '0' },
                      }}
                      value={value.workingdaysfullnew}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          workingdaysfullnew: e.target.value,
                        })
                      }
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {arrayofdays.map((arrayofdays) => (
                        <MenuItem
                          key={arrayofdays}
                          value={arrayofdays}
                          disabled={isOptionDisabled(
                            arrayofdays,
                            value.workingdaysfullnew
                          )}
                        >
                          <Checkbox
                            checked={
                              value.workingdaysfullnew.indexOf(arrayofdays) > -1
                            }
                          />
                          <ListItemText primary={arrayofdays} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      className="mx-0"
                      style={{
                        marginTop: '.25rem',
                        fontSize: '.875rem',
                        color: '#dc3545',
                      }}
                    >
                      {Submit2
                        ? value?.worktimimng === true
                          ? false
                          : value.workingdaysfullnew.length > 0
                            ? false
                            : 'PLEASE SELECT Working Days (full)'
                        : false}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-12">
                  <label className="labels">
                    Working Hours {value.worktimimng ? '' : '*'}
                  </label>
                  <div className="row">
                    <div className="col-5">
                      <select
                        required={
                          Submit2
                            ? value?.worktimimng === true
                              ? false
                              : true
                            : false
                        }
                        className="form-select"
                        value={value.working_hour_1}
                        onChange={(e) =>
                          setValue({ ...value, working_hour_1: e.target.value })
                        }
                      >
                        <TimeSelectBox />
                      </select>
                      <div class="invalid-feedback">
                        PLEASE SELECT Working Hours
                      </div>
                    </div>
                    <div className="col-2 d-flex justify-content-center pt-2 p-0">
                      to
                    </div>
                    <div className="col-5">
                      <select
                        required={
                          Submit2
                            ? value?.worktimimng === true
                              ? false
                              : true
                            : false
                        }
                        className="form-select"
                        value={value.working_hour_2}
                        onChange={(e) =>
                          setValue({ ...value, working_hour_2: e.target.value })
                        }
                      >
                        <TimeSelectBox />
                      </select>
                      <div class="invalid-feedback">
                        PLEASE SELECT Working Hours
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">
                    Break Time {value.worktimimng ? '' : '*'}
                  </label>
                  <div className="row">
                    <div className="col-5">
                      <select
                        required={
                          Submit2
                            ? value?.worktimimng === true
                              ? false
                              : true
                            : false
                        }
                        className="form-select"
                        value={value.break_time_1}
                        onChange={(e) =>
                          setValue({ ...value, break_time_1: e.target.value })
                        }
                      >
                        <TimeSelectBox type={'break'} />
                      </select>
                      <div class="invalid-feedback">
                        PLEASE SELECT Break Time
                      </div>
                    </div>
                    <div className="col-2 d-flex justify-content-center pt-2 p-0">
                      to
                    </div>
                    <div className="col-5">
                      <select
                        required={
                          Submit2
                            ? value?.worktimimng === true
                              ? false
                              : true
                            : false
                        }
                        className="form-select"
                        value={value.break_time_2}
                        onChange={(e) =>
                          setValue({ ...value, break_time_2: e.target.value })
                        }
                      >
                        <TimeSelectBox type={'break'} />
                      </select>
                      <div class="invalid-feedback">
                        PLEASE SELECT Break Time
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">
                    Working Days (half) {value.worktimimng ? '' : '*'}
                  </label>
                  <FormControl className="form-control">
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      required={
                        Submit2
                          ? value?.worktimimng === true
                            ? false
                            : value.workingdayshalfnew.length > 0
                              ? false
                              : true
                          : false
                      }
                      error={
                        Submit2
                          ? value?.worktimimng === true
                            ? false
                            : value.workingdayshalfnew.length > 0
                              ? false
                              : true
                          : false
                      }
                      className="form-select"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          display: 'none',
                        },
                        '& .MuiSvgIcon-root': { display: 'none' },
                        '& .MuiSelect-select': { padding: '0' },
                      }}
                      value={value.workingdayshalfnew}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          workingdayshalfnew: e.target.value,
                        })
                      }
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {arrayofdays2.map((arrayofdays2) => (
                        <MenuItem
                          key={arrayofdays2}
                          value={arrayofdays2}
                          disabled={isOptionDisabled(
                            arrayofdays2,
                            value.workingdayshalfnew
                          )}
                        >
                          <Checkbox
                            checked={
                              value.workingdayshalfnew.indexOf(arrayofdays2) >
                              -1
                            }
                          />
                          <ListItemText primary={arrayofdays2} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      className="mx-0"
                      style={{
                        marginTop: '.25rem',
                        fontSize: '.875rem',
                        color: '#dc3545',
                      }}
                    >
                      {Submit2
                        ? value?.worktimimng === true
                          ? false
                          : value.workingdayshalfnew.length > 0
                            ? false
                            : 'PLEASE SELECT Working Days (half)'
                        : false}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-12">
                  <label className="labels">
                    Working Hours {value.worktimimng ? '' : '*'}
                  </label>
                  <div className="row">
                    <div className="col-5">
                      <select
                        required={
                          Submit2
                            ? value?.worktimimng === true
                              ? false
                              : true
                            : false
                        }
                        className="form-select"
                        value={value.working_hour_3}
                        onChange={(e) =>
                          setValue({ ...value, working_hour_3: e.target.value })
                        }
                      >
                        <TimeSelectBox />
                      </select>
                      <div class="invalid-feedback">
                        PLEASE SELECT Working Hours
                      </div>
                    </div>
                    <div className="col-2 d-flex justify-content-center pt-2 p-0">
                      to
                    </div>
                    <div className="col-5">
                      <select
                        required={
                          Submit2
                            ? value?.worktimimng === true
                              ? false
                              : true
                            : false
                        }
                        className="form-select"
                        value={value.working_hour_4}
                        onChange={(e) =>
                          setValue({ ...value, working_hour_4: e.target.value })
                        }
                      >
                        <TimeSelectBox />
                      </select>
                      <div class="invalid-feedback">
                        PLEASE SELECT Working Hours
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Weekly Off *</label>
                  <FormControl className="form-control">
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      required
                      error={
                        Submit2
                          ? value?.workingdaysOffnew?.length != 0
                            ? false
                            : true
                          : false
                      }
                      className="form-select"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          display: 'none',
                        },
                        '& .MuiSvgIcon-root': { display: 'none' },
                        '& .MuiSelect-select': { padding: '0' },
                      }}
                      value={value.workingdaysOffnew}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          workingdaysOffnew: e.target.value,
                        })
                      }
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {arrayofdays2.map((arrayofdays2) => (
                        <MenuItem
                          key={arrayofdays2}
                          value={arrayofdays2}
                          disabled={isOptionDisabled(
                            arrayofdays2,
                            value.workingdaysOffnew
                          )}
                        >
                          <Checkbox
                            checked={
                              value.workingdaysOffnew.indexOf(arrayofdays2) > -1
                            }
                          />
                          <ListItemText primary={arrayofdays2} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      className="mx-0"
                      style={{
                        marginTop: '.25rem',
                        fontSize: '.875rem',
                        color: '#dc3545',
                      }}
                    >
                      {Submit2
                        ? value?.workingdaysOffnew?.length != 0
                          ? false
                          : 'PLEASE SELECT Weekly Off'
                        : false}
                    </FormHelperText>
                  </FormControl>
                </div>

                <h6 className="mt-4">Contact Information</h6>
                <div className="col-md-12">
                  <label className="labels">Email Id</label>
                  <input
                    type="email"
                    className="form-control"
                    value={value.email}
                    onChange={(e) =>
                      setValue({ ...value, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-12">
                  <label className="labels">Country Code *</label>
                  <select
                    required
                    className="form-select"
                    value={value.countrycode}
                    onChange={(e) =>
                      setValue({ ...value, countrycode: e.target.value })
                    }
                  >
                    <option value="" disabled selected></option>
                    <option value="+91">+91</option>
                  </select>
                  <div class="invalid-feedback">PLEASE SELECT Country Code</div>
                </div>
                {/* <div className="col-md-12"><label className="labels">State Code *</label><input type="number" required className="form-control" value={value.statecode} onChange={(e) => setValue({ ...value, statecode: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER State Code
                                    </div>
                                </div> */}
                <>
                  {CONCount.map((dsi, index) => {
                    return (
                      <MobileAddOn
                        languages={languages}
                        key={dsi}
                        id={dsi}
                        con={value.CON}
                        handleCON={handleCON}
                        index={index}
                      ></MobileAddOn>
                    );
                  })}
                  {/* <button style={{ fontSize: '12px' }} disabled={CONCount.length >= 5} className="btn pro-spaces-button my-3" type="button" onClick={(e) => setConCount([...CONCount, keyGen()])} >ADD ANOTHER MOBILE / LANDLINE NUMBER </button> */}
                </>

                <div className="col-md-12">
                  <br />
                  <button
                    style={{ fontSize: '12px' }}
                    className="btn pro-spaces-button"
                    type="button"
                    onClick={handleShow}
                  >
                    Add a branch
                  </button>
                  <br />
                </div>
                <Modal show={show1} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    To Add Additional Branches of your Service / Business
                    Entity, you must UPGRADE to a PRIME or ELITE membership
                  </Modal.Body>
                </Modal>

                {!Edit && (
                  <div className="col-md-12 mt-4">
                    <Selfdeclaration
                      type="normal"
                      setSelfDeclaration={setSelfDeclaration}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <h6 style={{ color: 'red' }}>
            <center>{error ? error : ''}</center>
          </h6>
          <div className="mt-2 text-center">
            {Edit && !dce && (
              <button
                type="button"
                className="btn mb-2 mt-4 me-2 btn-secondary"
                onClick={(e) => window.history.back()}
              >
                Discard
              </button>
            )}
            {!loading && (
              <button
                className="btn mb-2 mt-4"
                style={{ backgroundColor: '#55374a', color: 'white' }}
                onClick={
                  () => setSubmit2(true)
                  // just for testing something not required !!!!
                }
              >
                {Edit ? 'Update' : 'Create Profile'}
              </button>
            )}
            {loading && (
              <button
                className="btn  mb-2 mt-4"
                disabled
                style={{ backgroundColor: '#55374a', color: 'white' }}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
