
import { useState, } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import Moment from 'moment';

function highlightLink(sentence) {
  const linkRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+\.[^\s]+)|(\b(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,}\b)/g;
  const linkMatches = sentence.match(linkRegex);

  if (!linkMatches) {
    return sentence;
  }

  const parts = sentence.split(linkRegex);
  const highlightedSentence = parts.map((part, index) => {
    if (linkMatches.includes(part)) {
      if (part.startsWith('http') || part.startsWith('https')) {
        return <a href={part} key={index} target="_blank">{part}</a>;
      } else if (part.startsWith('www')) {
        return <a href={'https://' + part} key={index} target="_blank">{part}</a>;
      }
      else {
        return <a href={'https://www.' + part} key={index} target="_blank">{part}</a>;
      }
    } else {
      return part;
    }
  });

  return highlightedSentence;
}


export function Stext(props) {
  const [Deleted, setDeleted] = useState(false)
  const DeleteMess = () => {
    // // console.log(props)
    let mes = props.data
    let no = axios.post('/api/User/deleteMessage', { mes }).then((res) => {
      props.socket.emit('DeleteMess', res.data)
      setDeleted(true)
    }).catch((ress) => {
      console.log(ress)
    })
  }
  var content = highlightLink(props.data.content)
  return (
    <div className={Deleted ? "row d-none" : "row "}>
      <button id={'mes' + props.data._id} className="d-none" onClick={(e) => setDeleted(true)} ></button>
      <div className="col-2">

      </div>
      <div className="col-10 pe-5 d-flex justify-content-end">
        <div className="   p-1 m-2" >
          <div className="row ps-3  text-end  ">
            <div className="col-12 p-0 pb-1 m-0">
              {props.data.sender.pname}
            </div>
            <div className="p-0 col-12 m-0 d-flex flex-row-reverse" >
              <p className="message  mb-0 m-0 text-start text-light bg-secondary px-3">
                <small style={{ wordBreak: 'break-word' }} >
                  <div className="dropdown float-end  ">
                    <span className=" dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    </span>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item" onClick={(e) => DeleteMess()}>Delete</a></li>
                    </ul>
                  </div>
                  {content}&nbsp;&nbsp;&nbsp;&nbsp;
                </small>
              </p>
              {/* <p className="mt-0  pe-4"> {datey(element.createdAt)}</p> */}
            </div>
            <div className="p-0 col-12 m-0 d-flex flex-row-reverse" >
              {/* <p className="mt-0  pe-4"> {datey(element.createdAt)}</p> */}
              <p className="mt-0  pe-4">  {Moment(props.data.createdAt ? props.data.createdAt.slice(0, 24) : '').format(' h:mm:ss a')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}