import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { SpinnerBig } from '../../Utility/spinner'
import { Information_Medical_Store_card } from './Listing_Card/Information_Medical_Store_card'
import { Information_Search_Medical_Store } from './Search/Information_Search_Medical_Store'

export const Information_Medical_Store = () => {

  const [data, setData] = useState([])
  const [Loading, setLoading] = useState(true)
  useEffect(() => {
    Get()
  }, [])
  const Get = () => {
    Axios.get('/api/Free/Information_Medical_Store_listing').then((res) => {
      if (res.status == 200) {
        console.log(res.data)
        setData(res.data)
        setLoading(false)
      }
    }).catch((err) => {
      setLoading(false)

    })
  }




  const [value, setValue] = useState({
    country: "India",
    state: "",
    city: "",
    pincode: "",
    pname: "",
  });
  const Reset = () => {
    setValue({
      ...value,
      country: "India",
      state: "",
      city: "",
      pincode: "",
      pname: "",
    })
    Get()
  }

  const dva = () => {
    Axios.post('/api/Free/Information_Medical_Store_search', { value }).then((res) => {
      if (res.status == 200) {
        console.log(res.data)
        setData(res.data)
      }
    }).catch((err) => {
      console.log(err)
    })

  }


  return (
    <div className='container-fluid' style={{ "paddingTop": "80px" }}>
      <div className=' row '>
        {

          Loading ?
            <SpinnerBig />
            :
            <>
              <div>
                <Information_Search_Medical_Store setValue={setValue} value={value} dva={dva} Reset={Reset} />
              </div>
              {
                !data.length == 0 ?
                  <>
                    {
                      data.map((e) => {
                        return (
                          <>
                            <Information_Medical_Store_card data={e} />
                          </>
                        )
                      })
                    }

                  </>
                  : <>
                    <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "100vh" }}>
                      <h3>Nothing to show !</h3>
                    </div>
                  </>
              }

            </>
        }
      </div>
    </div>
  )
}
