import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const HospitalsClinicsAssociatedWith = ({ getval }) => {
  const { AddonHospitality_Available } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === "contactinformation"}
      onChange={handleChange("contactinformation")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "100%", flexShrink: 0 }}>
          <span>
            <b> Hospital Consultation Information</b>
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {AddonHospitality_Available?.map((element, ind) => {
            return (
              <>
                <hr />
                <b>Hospital Associated with / Working in</b>
                <br />
                {element.hospitalname && (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>Name of the Hospital</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>
                        {element.hospitalname ? element.hospitalname : ""}
                      </label>
                    </div>
                  </div>
                )}
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>City, State / Province, Country</span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{ color: "var(--blue)" }}>
                      {element.city ? element.city : ""},{" "}
                      {element.state ? element.state : ""}{" "}
                      {element.country ? element.country : ""}
                    </label>
                  </div>
                </div>
                {element.localityh && (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>Locality</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>
                        {element.localityh ? element.localityh : ""}
                      </label>
                    </div>
                  </div>
                )}
                {element.pincode && (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>Pin code</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>
                        {element.pincode ? element.pincode : ""}
                      </label>
                    </div>
                  </div>
                )}
                <br />
                <b>Availability at Hospital</b>
                <br />
                {element.OnlyOn ? (
                  <>
                    <div className="row py-2">
                      <div className="col-md-12">
                        <span>Only On-Call, No Specific Day</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {element.hworkingdaysfullnew ? (
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>Working Days (full)</span>
                        </div>
                        <div className="col-md-6 address">
                          <label style={{ color: "var(--blue)" }}>
                            {element.hworkingdaysfullnew
                              ? element.hworkingdaysfullnew.map(
                                  (elementpp, index) => {
                                    if (
                                      index ==
                                      element.hworkingdaysfullnew?.length - 1
                                    ) {
                                      return elementpp;
                                    } else {
                                      return elementpp + ", ";
                                    }
                                  }
                                )
                              : ""}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {element.hworking_hour_1 ? (
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>Working Hours</span>
                        </div>
                        <div className="col-md-6 address">
                          <label style={{ color: "var(--blue)" }}>
                            {element.hworking_hour_1
                              ? element.hworking_hour_1 + " to "
                              : ""}{" "}
                            {element.hworking_hour_2
                              ? element.hworking_hour_2
                              : ""}{" "}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {element?.break_time_1?.length ? (
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>Break Time</span>
                        </div>
                        <div className="col-md-6 address">
                          <label style={{ color: "var(--blue)" }}>
                            {element.break_time_1
                              ? element.break_time_1 + " to "
                              : ""}{" "}
                            {element.break_time_2 ? element.break_time_2 : ""}{" "}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {element.hworkingdayshalfnew ? (
                      <>
                        <div className="row py-2">
                          <div className="col-md-6">
                            <span>Working Days (half)</span>
                          </div>
                          <div className="col-md-6 address">
                            <label style={{ color: "var(--blue)" }}>
                              {element.hworkingdayshalfnew
                                ? element.hworkingdayshalfnew.map(
                                    (elementpp, index) => {
                                      if (
                                        index ==
                                        element.hworkingdayshalfnew?.length - 1
                                      ) {
                                        return elementpp;
                                      } else {
                                        return elementpp + ", ";
                                      }
                                    }
                                  )
                                : ""}
                            </label>
                          </div>
                        </div>
                        {element.hworking_hour_3 != "Nil" ? (
                          <div className="row py-2">
                            <div className="col-md-6">
                              <span>Working Hours</span>
                            </div>
                            <div className="col-md-6 address">
                              <label style={{ color: "var(--blue)" }}>
                                {element.hworking_hour_3
                                  ? element.hworking_hour_3 + " to "
                                  : ""}{" "}
                                {element.hworking_hour_4
                                  ? element.hworking_hour_4
                                  : ""}{" "}
                              </label>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {element.hworkingdaysOffnew ? (
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>Weekly Off</span>
                        </div>
                        <div className="col-md-6 address">
                          <label style={{ color: "var(--blue)" }}>
                            {element.hworkingdaysOffnew
                              ? element.hworkingdaysOffnew.map(
                                  (elementpp, index) => {
                                    if (
                                      index ==
                                      element.hworkingdaysOffnew?.length - 1
                                    ) {
                                      return elementpp;
                                    } else {
                                      return elementpp + ", ";
                                    }
                                  }
                                )
                              : ""}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}

                <br />
                <b>Contact Information of Hospital</b>
                <br />
                {element.hcountrycode ? (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>Country Code</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>
                        {element.hcountrycode}
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {element.hstatecode ? (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>State Code</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>
                        {element.hstatecode}
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {element.hmobilenumber ? (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>Mobile Number / Landline Number</span>
                    </div>
                    <div className="col-md-6 ">
                      <label style={{ color: "var(--blue)" }}>
                        {element.hmobilenumber}
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {element.contacthospitalforappoiment ? (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <b>
                        Please Contact the Hospital if you need an appointment
                        with the Doctor / Consultant
                      </b>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
