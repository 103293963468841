import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  createContext,
  useContext,
} from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FormProduct as FormProductForPrime } from "../../CreateProduct/FormProduct";
import { FormProduct as FormProductForBasic } from "../../CreateProductV2/FormProduct";

export function CreateProduct() {
  let navigate = useNavigate();

  const submit = async (formData) => {
    return new Promise((resolve, reject) => {
      // console.log(value);

      axios
        .post("/api/User/newProduct", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            navigate("/");
            window.location.reload();
          }
          resolve(true);
        })
        .catch((err) => {
          if (err.response.status == 304) {
            console.log(err.response.data);
            // toast warning
            return navigate("/");
          } else {
            console.log(err.response);
          }
          resolve(false);
        });
    });
  };

  return (
    <>
      <div className="container rounded bg-white pt-5 pb-0">
        {localStorage.getItem("Type") === "Product" ||
        localStorage.getItem("Type") === "Consultant" ? (
          <FormProductForPrime postTo={submit} sab={false} />
        ) : localStorage.getItem("Type") === "Productv2" ||
          localStorage.getItem("Type") === "Consultantv2" ? (
          <FormProductForBasic postTo={submit} sab={false} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
