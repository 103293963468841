import React, { useEffect, useState } from 'react'

import { Route, Routes } from "react-router";
import { CreateProfile } from "../CreateProfile/CreateProfile"
import { CreateConseltent } from "../CreateConseltent/CreateConseltent"
import { CreateProduct } from "../CreateProduct/CreateProduct"
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
export function ProfileCreation(props) {
 
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate()


  useEffect(() => {
    Axios.get('/api/verify/meatsignup').then(res => {
      if (res.status === 200) {
        console.log('workd');
        setAuthenticated(true)
      }
    }).catch((err) => {
      if (err) {
        navigate('/')
      }
      console.log(err.response.status);
    })
  }, []);

  return (

    <div >
      {Authenticated ?
        <Routes>
          <Route element={<CreateProfile />} exact path="/"></Route>
          <Route element={<CreateConseltent />} path="CreateConseltent"></Route>
          <Route element={<CreateProduct />} path="CreateProduct"></Route>
        </Routes>
        : ''}
    </div >
  )
}
