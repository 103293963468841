import React, { useState, useEffect, useReducer, createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./CreateConseltent.css";
import { Mask } from "../../Utility/mask";
import Select from "react-select";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CreateConseltentA } from "./forms/CreateConseltentA";
import { CreateConseltentC } from "./forms/CreateConseltentC";
import { CreateConseltentE } from "./forms/CreateConseltentE";
import { useRef } from "react";

export const CatsContext = createContext();
export const ConvertContext = createContext();
export const EditContext = createContext();

export function FormConsultent(props) {
  console.log(props)
  var sp_cat = props.catid; // for setting predefind category
  var sp_cat2 = props.catid2;
  var sp_cat3 = props.catid3;
  var sp_subcat = props.subid

  let [searchParams, setSearchParams] = useSearchParams();



  const reducer = (state, action) => {
    switch (action.type) {
      case "1":
        return <CreateConseltentA postTo={props.postTo} portfo={true} formType={1} basicprofile={props.basicprofile} />;
      case "2":
        return <CreateConseltentA postTo={props.postTo} formType={2} basicprofile={props.basicprofile} />;
      case "3":
        return <CreateConseltentC postTo={props.postTo} portfo={true} formType={3} basicprofile={props.basicprofile} />;
      case "4":
        return <CreateConseltentC postTo={props.postTo} formType={4} basicprofile={props.basicprofile} />;
      case "5":
        return <CreateConseltentE postTo={props.postTo} basicprofile={props.basicprofile} />;
      default:
        return <></>;
      // throw new Error();
    }
  };
  let navigate = useNavigate();
  const [value, setValue] = useState({
    category: "",
    subcategory: "",
    formType: "",
  });

  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [brand, setBrand] = useState([]);
  const [state, dispatch] = useReducer(reducer, <></>);

  const [subcat, setsubcat] = useState();
  const sabari = (e) => {
    // ..
    console.log(e, cat);
    dispatch({ type: "po" });
    setBrand([]);
    var anadhan;
    setValue((prev) => ({ ...prev, category: e }));
    cat.forEach((element) => {
      if (element._id === e) {
        console.log(element._id, e);
        setsubcat(element.sub);
        anadhan = element.sub.map((elements) => {
          return (
            <option formtype={elements.formType} value={elements._id}>
              {elements.sub}
            </option>
          );
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };

  useEffect(() => {
    axios
      .get("/api/api/getcategory")
      .then((res) => {
        if (res.status === 200) {
          setCat(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          navigate("/");
        }
      });
  }, []);
  useEffect(() => {
    if (cat.length >= 1) {
      setCategory(
        cat.map((element) => {
          console.log('object');
          if (value.category) {
            if (element._id == value.category) {
              console.log(value.category);
              console.log(element);

              setSubcategory(element.sub.map((elements) => {
                if (value.subcategory == elements._id) {
                  console.log(elements.formType);
                  setValue((prev) => ({ ...prev, formType: elements.formType }))
                  dispatch({ type: elements.formType.toString() });
                }
                return <option
                  selected
                  formtype={elements.formType}
                  value={elements._id}
                >
                  {elements.sub}
                </option>
              }))
            }
          }
          if (sp_cat) {
            if (element._id === sp_cat || element._id === sp_cat2 || element._id === sp_cat3) {
              return (
                <option value={element._id} selected>
                  {element.category}
                </option>
              );
            }
          } else {
            return (
              <option value={element._id}>
                {element.category}
              </option>
            );
          }

        })
      );
  
      if (sp_subcat) {
        setValue({ ...value, category: sp_cat, subcategory: sp_subcat });
      } if (sp_cat) {
        setValue({ ...value, category: sp_cat });
      }
      if (sp_cat) {
        setSubcategory(
          cat.map((element) => {
            if (sp_cat) {
              if (element._id === sp_cat) {
                setsubcat(element.sub);
                return element.sub.map((elements) => {
                  if (sp_subcat) {
                    if (elements._id === sp_subcat) {
                      console.log(elements.formType);
                      setValue((prev) => ({ ...prev, formType: elements.formType }))
                      dispatch({ type: elements.formType.toString() });
                      return (
                        <option
                          selected
                          formtype={elements.formType}
                          value={elements._id}
                        >
                          {elements.sub}
                        </option>
                      );
                    }
                  }
                  else {
                    return (
                      <option formtype={elements.formType} value={elements._id}>
                        {elements.sub}
                      </option>
                    );
                  }

                });
              }
            }
            // else {
            //   setsubcat(element.sub);
            //   return element.sub.map((elements) => {
            //     return (
            //       <option formtype={elements.formType} value={elements._id}>
            //         {elements.sub}
            //       </option>
            //     );
            //   });
            // }
          })
        );
      }
    } else {
    }
  }, [cat]);
  const formLoader = () => {
    let e = document.getElementById("subcatananda83389");
    let ft = e.options[e.selectedIndex].getAttribute("formtype");
    dispatch({ type: ft });

    setValue({ ...value, subcategory: e.value, formType: ft });
    cat.find((elem) => {
      if (elem._id === value.category) {
        elem.sub.find((element) => {
          if (element._id == e.value) {
            setBrand(element.brand);
          }
        });
      }
    });
  };

  const [data, setdata] = useState();
  useEffect(() => {
    if (props.edit) {
      axios
        .post("/api/ActiveUser/ConProfile", {})
        .then((res) => {
          console.log(res.data);
          // return
          cat.find((elem) => {
            if (elem._id === res.data[0].category) {
              elem.sub.find((element) => {
                if (element._id == res.data[0].subcategory) {
                  setBrand(element.brand);
                }
              });
            }
          });
          setValue({
            ...value,
            category: res.data[0].category,
            subcategory: res.data[0].subcategory,
            // formType: res.data[0].formType
            Brand: res.data[0].Brand,
          });
          let e = document.getElementById("subcatananda83389");
          let anadhan;
          let ft;
          cat.forEach((element) => {
            if (element._id === res.data[0].category) {
              anadhan = element.sub.map((elements) => {
                if (elements._id === res.data[0].subcategory) {
                  ft = elements.formType;
                } else {
                  // console.log(elements._id,res.data[0].subcategory)
                }
                return (
                  <option formtype={elements.formType} value={elements._id}>
                    {elements.sub}
                  </option>
                );
              });
            }
          });
          setSubcategory(anadhan);
          if (ft) {
            ft = ft.toString();
            dispatch({ type: ft });
          }
          setdata(res.data);
          cat.find((elem) => {
            if (elem._id === value.category) {
              elem.sub.find((element) => {
                if (element._id == e.value) {
                  setBrand(element.brand);
                }
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [category]);

  // -------------input catagory box start------------------

  const [Options, setOptions] = useState([]);
  useEffect(() => {
    axios
      .get("/api/api/getcategory")
      .then((res) => {
        let op = res.data?.map((element, index) => {
          return element.sub.map((elm, ind) => {
            let value = elm.sub.toString();
            if (elm.keyword?.length) {
              var sabari = elm.keyword.map((keywordsmap) => keywordsmap.label);
              value = value + "" + sabari.toString();
            }
            let brand;
            if (elm.brand?.length) {
              brand = true;
            } else {
              brand = false;
            }
            return {
              value,
              label: `${elm.sub} [ ${element.category} ]`,
              id: elm._id,
              categoryName: element.category,
              brand,
              type: element.type,
              catid: element._id,
              ft: elm.formType,
            };
          });
        });
        op = op.flat(1);
        setOptions(op);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const redmik20pro = (id) => {
    let catid = id.catid;
    let subcatid = id.id;
    let anadhan;
    let ft = id.ft;
    cat.forEach((element) => {
      if (element._id === catid) {
        anadhan = element.sub.map((elements) => {
          if (elements._id === subcatid) {
            ft = elements.formType;
          } else {
            // console.log(elements._id,res.data[0].subcategory)
          }
          return (
            <option formtype={elements.formType} value={elements._id}>
              {elements.sub}
            </option>
          );
        });
      }
    });
    setSubcategory(anadhan);
    setValue((rev) => ({
      ...rev,
      category: catid,
      subcategory: subcatid,
      formType: ft,
    }));
    console.log(ft);
    dispatch({ type: ft.toString() });
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selectedOption) => {
    redmik20pro(selectedOption);
  };

  const handle = (e) => {
    if (e.target.value.length > 2) {
      let elem = document.querySelector(":root");
      elem.style.setProperty("--display", "block  ");
    } else {
      let elem = document.querySelector(":root");
      elem.style.setProperty("--display", "none  ");
    }
  };

  useEffect(() => {
    try {
      rt();

      let element = document.getElementsByClassName("catSubCatSearchBox");
      element[0].children[2].children[0].children[1].children[0].addEventListener(
        "input",
        handle
      );
      element[0].children[2].children[1].classList.add("d-none");
    } catch (error) {
      console.log(error);
    }
    return () => {
      try {
        let element = document.getElementsByClassName("catSubCatSearchBox");
        element[0].children[2].children[0].children[1].children[0].removeEventListener(
          "input",
          handle
        );
      } catch (err) { }
    };
  }, []);

  const rt = () => {
    let elem = document.querySelector(":root");
    elem.style.setProperty("--display", "none ");
  };

  // -------------input catagory box end------------------


  let categoryRef = useRef()
  let subcategoryRef = useRef()


  useEffect(() => {
    if (searchParams.get("superlink")) {
      let mType = searchParams.get("mType")
      let uType = searchParams.get("uType")
      var category = searchParams.get("category")
      let subcategory = searchParams.get("subcategory")
      let profileName = searchParams.get("profileName")
      let country = searchParams.get("country")
      let state = searchParams.get("state")
      let city = searchParams.get("city")

      console.log(category, subcategory);
      console.log(subcategory);
      console.log(searchParams.get("category"));
      if (category.length) {
        categoryRef.current.disabled = true
        if (subcategory.length) {
          subcategoryRef.current.disabled = true
        }
        setValue({ ...value, category: category, subcategory: subcategory });
      }

    }


    return () => {

    }
  }, [])
  return (
    <div className="container rounded bg-white pt-5 pb-0">
      <center className="pt-4">
        <h5>Profile Creation Form</h5>
      </center>
      <center>
        <p>' * ' are all mandatory fields</p>
      </center>
      <div className="row">
        <h6 className="mt-1">Select your appropriate Sub Category</h6>
        <div className="col-md-12">
          <label className="labels">Category *</label>
          <select
            ref={categoryRef}
            className="form-select"
            value={value.category}
            onChange={(e) => sabari(e.target.value)}
          >
            {props.catid ? <></> : <option selected disabled></option>}
            {category ? category : ""}
          </select>
        </div>
        <div className="col-md-12">
          <label className="labels">Sub Category *</label>
          <select
            ref={subcategoryRef}
            id={"subcatananda83389"}
            className="form-select"
            value={value.subcategory}
            onChange={(e) => formLoader(e)}
          >
            {sp_subcat ? <></> : <option></option>}
            {subcategory ? subcategory : ""}
          </select>
          {/* {value.subcategory} */}
        </div>
      </div>
      {brand.length ? (
        <>
          <div className="col-md-12">
            <label className="labels">Brand *</label>
            <select
              value={value.Brand}
              className="form-select"
              onChange={(e) => setValue({ ...value, Brand: e.target.value })}
            >
              <option></option>
              {brand.map((elem) => {
                return <option value={elem._id}>{elem.brand}</option>;
              })}
            </select>
          </div>
        </>
      ) : (
        <></>
      )}
      <EditContext.Provider value={data}>
        <CatsContext.Provider value={{ value, brand: brand, subcat }}>
          <ConvertContext.Provider value={props.sab}>
            {state}
          </ConvertContext.Provider>
        </CatsContext.Provider>
      </EditContext.Provider>
    </div>
  );
}
