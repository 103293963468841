import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BsExclamationTriangleFill } from "react-icons/bs";
import axios from "axios";
import { Mask } from "../../Utility/mask";
import { FcInfo } from "react-icons/fc";
export function SubPro(props) {
  const liquermodal = () => {
    document.getElementById("liqquermodalprochuresabaribtn").click();
  };

  return (
    <div className="col-12 col-md-6 col-lg-6  col-xl-4 col-xxl-4 mb-3">
      {props.data.description ? (
        <div
          style={{
            position: "absolute",
            left: "-30px",
            width: "inherit",
            display: "flex",
            justifyContent: "end",
            paddingTop: "20px",
            zIndex: "1",
          }}
        >
          <FcInfo
            style={{ color: "red" }}
            className="float-end"
            onClick={() => props.handleShow(props.data.description)}
          />
        </div>
      ) : (
        <></>
      )}
      <Link
        to={
          props.data.brand > 0
            ? `/products_brand_categories?brand_id=${Mask.encode(
                props.data.id
              )}&&sub_category=${props.data.sub}`
            : "/products_result?Sub_id=" +
              Mask.encode(props.data.id) +
              "&count=" +
              props.data.cons
        }
        className="linkoflinkk"
      >
        <div className="card sdvshfihfihf p-3">
          {/* <div className="row">
              <div className="col-4"></div>
            <div className="col-4 cendvkdskvster">
            
              </div>
           <div className="col-4">
           {Follower ?
              <button
                type="button"
                style={{"fontSize":"10px"}}
                className="float-end btnbhg btn px-3  "
                onClick={(e) => follow(e, props.data.id)}
              >
                Follow
              </button>
              :
              <button
                type="button"
                style={{"fontSize":"10px"}}
                className="float-end btnbhg btn px-3  "
                onClick={(e) => unfollow(e, props.data.id)}
              >
                UnFollow
              </button>
            }
            </div>
            </div> */}

          <div>
            <div className="d-flex align-items-center">
              <div className="ml-3 w-100">
                <h4 className="mb-2 mt-0">{props.data.sub}</h4>{" "}
                {/* <div className='row'>
                    <div className='col-7'>Members</div>
                    <div className='col-2'>:</div>
                    <div className='col-3'>{props.data.cons}</div>
                  </div> */}
                <ul>
                  {/* <li>type : {props.data.num} </li> */}
                  <li>Members : {props.data.cons} </li>
                  {/* */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Link>
      {props.data.id === "63077a439b4ba432c41ee626" ? (
        <div style={{ position: "relative", right: "30px", top: "-65px" }}>
          <BsExclamationTriangleFill
            style={{ color: "red" }}
            className="float-end"
            onClick={liquermodal}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
