import React, { useState, useEffect, useRef, useReducer, createContext, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import './CreateProduct.css'
import { AiFillCamera } from 'react-icons/ai'
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {FormProduct} from './FormProduct'

export function ConvertPro() {
  
   
    let navigate = useNavigate()

    const submit = async (formData) => {
        return new Promise((resolve,reject)=>{

       
        
            axios.post('/api/ActiveUser/updatetoproduct', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                if (res.status == 200) {
                    navigate('/')
                    window.location.reload();
                }
                resolve(true)
            }).catch((err) => {

               
                resolve(false)
                return navigate('/')
            })

        })
          
    }

    return (
        <>

       <div className="container rounded bg-white pt-5 pb-5">
      
                <FormProduct postTo={submit} sab={true}/>
           
        </div>
        </>


    )
}
