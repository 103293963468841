import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/signin.css';
import Axios from 'axios';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Navoutside } from '../Navoutside/Navoutside';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { FcInfo } from 'react-icons/fc';
import { Timer } from '../../Utility/timer';
import { NewNavigationBar } from '../../Pages/Free/NewNavigationBar/NewNavigationBar';
export const ActiveRegisterv2 = () => {
  const [Authenticated, setAuthenticated] = useState(false);
  const Auth = () => {
    axios
      .get('/api/verify/authenticateduser')
      .then((res) => {
        if (res.status === 200) {
          console.log('workd');
          setAuthenticated(true);
          navigate('/');
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  };
  useEffect(() => {
    Auth();
  }, []);

  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [Contact, setContact] = useState('');
  const [Submit, setSubmit] = useState(false);
  const [Error, setError] = useState('');
  const [Errorr, setErrorr] = useState('');
  const [CPassword, setCPassword] = useState('');
  const [PassCheck, setPassCheck] = useState(false);
  const [loading, setloading] = useState(false);
  const [ErrorOTP, setErrorOTP] = useState(false);
  const [OTP, setOTP] = useState('');
  const [first, setfirst] = useState(true);
  const [LogOp, setLogOp] = useState(true);
  const [OTPSTATUS, setOTPSTATUS] = useState('');

  const [pop, setpop] = useState(false);

  const end = () => setpop(false);

  let navigate = useNavigate();
  /* eslint-disable */
  function Validate() {
    var stat;
    console.log(LogOp);
    if (!LogOp) {
      if (Contact.length == 10) {
        setError('');
      } else {
        return setError('PLEASE ENTER a valid Mobile Number');
      }
    } else {
      console.log(Contact);
      if (Contact.length > 0) {
        if (Contact.length != 10) {
          return setError('PLEASE ENTER a valid Phone number');
        }
      }
      if (Email.length > 0) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
          setError('');
        } else {
          return setError('PLEASE ENTER a valid Email Id');
        }
      } else {
        return setError('PLEASE ENTER Email Id');
      }
    }

    if (Password.length == 0) {
      return setError('PLEASE ENTER password');
    }
    if (Password.length < 8) {
      return setError('Min. 8 characters required');
    } else {
      setError('');
    }
    if (PassCheck) {
      stat = Passwordmatch();
      if (stat != true) {
        return;
      } else {
        setError('');
      }
    }

    if (CPassword.length == 0) {
      return setError('PLEASE ENTER password again');
    }
    if (!LogOp)
      if (Email.length > 0) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
          setError('');
        } else {
          return setError('PLEASE ENTER a valid email id');
        }
      } else {
        return setError('PLEASE ENTER email id');
      }

    return true;
  }
  const Passwordmatch = () => {
    if (Password.length > 0) {
      setPassCheck(true);
      if (CPassword.length == 0) {
        setError('PLEASE ENTER Password again');
        return false;
      }
      if (CPassword == Password) {
        setError('');
        return true;
      } else {
        setError('Password does not match');
        return false;
      }
    }
  };
  const spinner = (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
  const formhandler = async (e) => {
    var status = false;
    setOTPSTATUS('');
    setloading(true);
    setSubmit(true);
    status = Validate();
    if (status == true) {
      e.preventDefault();
      try {
        if (Contact.length >= 10) {
          var over = await Axios.post('/api/auth/numbermatch', { Contact });
          if (over.data === 'Exist') {
            setloading(false);
            return setErrorr(
              <>
                <p>
                  An account is already created at PROchure using this Mobile
                  Number.
                </p>
                <p>
                  Please contact admin@prochure.in IF this mobile number belongs
                  to you.
                </p>
              </>
            );
          }
        }
        let overAgain = await Axios.post('/api/auth/emailmatch', { Email });
        if (overAgain.data === 'Exist') {
          setloading(false);
          return setErrorr(
            <>
              <p>
                An account is already created at PROchure using this Email Id.
              </p>
              <p>
                Please contact admin@prochure.in IF this email id belongs to
                you.
              </p>
            </>
          );
        }
      } catch (err) {
        setloading(false);
        console.log(err);
      }
      let Datas;
      if (LogOp) {
        Datas = Email;
      } else {
        Datas = Contact;
      }
      // Otpverify()
      Axios.post('/api/auth/sendMeOtp', { Datas })
        .then((res) => {
          setloading(false);
          if (res.status == 200) {
            setOTPSTATUS('OTP sent');
            console.log('otp sent');
            console.log(res.data);
            document.getElementById('p1').classList.add('d-none');
            document.getElementById('p2').classList.remove('d-none');
            setpop(true);
          }
        })
        .catch((err) => {
          setloading(false);
          console.log(err.response.data);
          setErrorr(err.response.data);
          setpop(false);
        });
    } else {
      e.preventDefault();
      setloading(false);
      setpop(false);
    }
  };
  useEffect(() => {
    if (Submit) {
      Validate();
    }
  });
  const Otpverify = (e) => {
    e.preventDefault();
    let Datas;
    if (LogOp) {
      Datas = Email;
    } else {
      Datas = Contact;
    }

    const dataPack = {
      dataSend: {
        _id: '',
        Email,
        Password,
        Contact,
        Type: 'Activev2',
        EmailLogin: LogOp,
      },
      Otp: {
        Datas,
        OTP,
      },
    };
    Axios.post('/api/accounts/register', { dataPack })
      .then((res) => {
        console.log(res);
        console.log(res.status);
        if (res.status === 200) {
          if (res.data === 'passive') {
            console.log('first');
            return navigate('/passive');
          } else {
            console.log('first');
            return navigate('/next_');
          }
        } else {
          setErrorOTP(res.data);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setErrorOTP(err.response.data);
      });
  };
  useEffect(() => {
    if (OTP.length > 4) {
      setOTP(OTP.slice(0, 4));
    }
    console.log(OTP);
  }, [OTP]);
  const style = {
    'background-color': 'red',
  };
  useEffect(() => {
    console.log(LogOp);
  }, [LogOp]);

  const PhoneReg = (
    <>
      {' '}
      <div className="mt-2 mb-3">
        <input
          type="number"
          value={Contact}
          onChange={(e) => {
            if (e.target.value.length == 11) return false;
            setContact(e.target.value);
          }}
          className="form-control "
          id="floatingContact"
          placeholder="Mobile Number * ( your Login Id )"
          autoComplete="off"
        />
      </div>
      <div className="mt-2 mb-4">
        <input
          type={first ? 'password' : 'text'}
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-control sbdciuwg"
          id="floatingPassword"
          placeholder="Create a Password * (min 8 characters)"
          autoComplete="off"
        />
        <div className=" sjdhfkshdkjcghu me-2">
          {first ? (
            <AiFillEyeInvisible onClick={(e) => setfirst(!first)} />
          ) : (
            <AiFillEye onClick={(e) => setfirst(!first)} />
          )}
        </div>
      </div>
      <div className="mt-2 mb-4">
        <input
          type={first ? 'password' : 'text'}
          value={CPassword}
          onBlur={Passwordmatch}
          onChange={(e) => setCPassword(e.target.value)}
          className="form-control sbdciuwg"
          id="floatingPassword"
          placeholder="Confirm Password *"
          autoComplete="off"
        />
        <div className=" sjdhfkshdkjcghu me-2">
          {first ? (
            <AiFillEyeInvisible onClick={(e) => setfirst(!first)} />
          ) : (
            <AiFillEye onClick={(e) => setfirst(!first)} />
          )}
        </div>
      </div>
      <div className="mt-2 mb-3">
        <input
          type="email"
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control"
          name="Email"
          id="floatingInput"
          placeholder="Email Id *"
          autoComplete="off"
        />
      </div>
    </>
  );

  const EmailReg = (
    <>
      <div className="mt-2 mb-3">
        <input
          type="email"
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
          name="Email"
          className="form-control"
          id="floatingInput"
          placeholder="Email Id * ( your Login Id )"
          autoComplete="off"
        />
      </div>
      <div className="mt-2 mb-4">
        <input
          type={first ? 'password' : 'text'}
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-control sbdciuwg"
          id="floatingPassword"
          placeholder="Create a Password * (min 8 characters)"
          autoComplete="off"
        />
        <div className=" sjdhfkshdkjcghu me-2">
          {first ? (
            <AiFillEyeInvisible onClick={(e) => setfirst(!first)} />
          ) : (
            <AiFillEye onClick={(e) => setfirst(!first)} />
          )}
        </div>
      </div>
      <div className="mt-2 mb-4">
        <input
          type={first ? 'password' : 'text'}
          value={CPassword}
          onBlur={Passwordmatch}
          onChange={(e) => setCPassword(e.target.value)}
          className="form-control sbdciuwg"
          id="floatingPassword"
          placeholder="Confirm Password *"
          autoComplete="off"
        />
        <div className=" sjdhfkshdkjcghu me-2">
          {first ? (
            <AiFillEyeInvisible onClick={(e) => setfirst(!first)} />
          ) : (
            <AiFillEye onClick={(e) => setfirst(!first)} />
          )}
        </div>
      </div>
      <div className="mt-2 mb-3">
        <input
          type="number"
          value={Contact}
          onChange={(e) => {
            if (e.target.value.length == 11) return false;
            setContact(e.target.value);
          }}
          className="form-control "
          id="floatingContact"
          placeholder="Mobile Number "
          autoComplete="off"
        />
      </div>
    </>
  );

  return (
    <>
      <NewNavigationBar />
      {/* <button type='button' onClick={trigger}>CLick</button>   */}
      <div
        id="p1"
        className="d-flex justify-content-center notranslate"
        style={{ paddingTop: '90px' }}
      >
        <div id="contain" style={{ maxWidth: '400px' }} className="pt-2 ">
          <form
            onSubmit={(e) => formhandler(e)}
            autoComplete="off"
            className="card signin justify-content-center pt-3 px-xl-4 pt-xl-3 pb-xl-2  px-4  h-100"
          >
            <h2 className="mb-xl-2">REGISTRATION</h2>
            <p className="mb-xl-2">Create a Login Id via :</p>
            <div className="w-xl-50">
              <div style={{ maxWidth: '350px' }} className="row p-0 m-0 mb-3">
                <div className="col-6 p-0">
                  <input
                    type="button"
                    style={{ borderRadius: '0px', fontSize: '14px' }}
                    className={`btn pro-spaces-outline w-100 ${
                      LogOp ? 'RActive' : ''
                    }`}
                    onClick={(e) => setLogOp(true)}
                    value="Email Id"
                    name="btnradio"
                    id="Emd"
                    autocomplete="off"
                    checked={LogOp ? true : false}
                  />
                </div>
                <div className="col-6 p-0">
                  <input
                    type="button"
                    style={{ borderRadius: '0px', fontSize: '14px' }}
                    className={`btn pro-spaces-outline w-100 px-0 ${
                      !LogOp ? 'RActive' : ''
                    }`}
                    onClick={(e) => setLogOp(false)}
                    value="Mobile Number"
                    name="btnradio"
                    id="Mbn"
                    autocomplete="off"
                    checked={LogOp ? true : false}
                  />
                </div>
              </div>
              {LogOp ? EmailReg : PhoneReg}
              <div className="text-danger mx-xl-0 mt-xl-1">
                {Error ? Error : Errorr}
              </div>
            </div>
            {!loading && (
              <center>
                <input
                  id="signinbtn"
                  className="btn mt-xl-2"
                  type="submit"
                  value="Register"
                />
              </center>
            )}
            {loading && (
              <center>
                <button
                  id="signinbtn"
                  className="btn mt-xl-2"
                  type="submit"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              </center>
            )}
            <p className="termsandcondition m-0 mt-2 mb-3 ewufge8hg98e">
              {' '}
              By clicking ‘REGISTER’, you agree to comply with all our{' '}
              <a
                data-bs-toggle="modal"
                data-bs-target="#apppolicies"
                className="jdgf"
              >
                App Policies{' '}
              </a>
              and{' '}
              <a
                data-bs-toggle="modal"
                data-bs-target="#beawaremodal"
                className="jdgf"
              >
                ‘Be Aware{' '}
              </a>
              Disclaimer.
              {/* and <a href="" className="jdgf">Cookie Policy</a> */}
            </p>
            <center className="mb-0">
              {' '}
              <h6
                className="mb-0"
                data-bs-toggle="modal"
                data-bs-target="#exampleModaelighouegoul"
              >
                <span style={{ color: '#55374a' }}>FOR YOUR INFORMATION</span>{' '}
                <FcInfo style={{ marginTop: '-6px' }} />
              </h6>{' '}
            </center>

            <hr className="" />
            <center>
              <p>
                {' '}
                I am a{' '}
                <span className="aldfhahfoiheaf shgoshigpiscmgih">
                  PRO-
                </span>{' '}
                member...{' '}
                <Link
                  to="/signin"
                  className="  shgoshigpiscmgih aldfhahfoiheaf"
                >
                  {' '}
                  &nbsp;SIGN IN
                </Link>
              </p>
            </center>
          </form>
        </div>
        <div
          className="modal fade"
          style={{ zIndex: '1000000' }}
          id="exampleModaelighouegoul"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div>
                  <h6>
                    <b>MUST KNOW FACTS BEFORE REGISTRATION</b>
                  </h6>

                  <ul>
                    <li>
                      Create a Login Id either with a Mobile number or with an
                      Email Id.
                    </li>
                    <li>
                      OTP validation is mandatory while registering on this
                      platform in order to ensure that you are using a mobile
                      number / email id that belongs to you.
                    </li>
                    <li>
                      <b>
                        Kindly be informed that OTP validation is in your
                        benefit so that no one opens an account on this platform
                        using your mobile number / email id.
                      </b>
                    </li>
                    <li>
                      OTP is only a one-time validation process while
                      registering on this platform.
                    </li>
                    <li>
                      The next time you will require an OTP on this platform is
                      ONLY if you change your Login Id.
                    </li>
                    <li>
                      You have the options of Changing your Login Id to a new
                      mobile number / email id, irrespective of what your
                      existing login id is. (Please refer Quick Fix on
                      navigation bar to know how)
                    </li>
                    <li>
                      <b>
                        We DO NOT SAVE your Card Details for future payments.
                      </b>
                    </li>
                    <li>
                      <b>
                        We DO NOT HAVE annual subscription Auto-Renewal feature.
                      </b>
                    </li>
                    <li>
                      Certain fields in Profile Creation are mandatory only to
                      prove your genuineness and authenticity as a Professional
                      Consultant / Service Provider / Business Enterprise.
                    </li>
                    <li>
                      <b>We DO NOT SELL Your Data :</b>
                      <br />
                      We do not share any information collected from you during
                      the process of registration and profile creation with a
                      third-party for monetization or otherwise.
                      <br />
                      What is seen by a third party on this platform is ONLY the
                      information that you have chosen to display / showcase /
                      mention as a part of your professional services /
                      business.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* OTP section */}
      <div
        id="p2"
        style={{ marginTop: '80px' }}
        className="d-none d-flex justify-content-center  p-2"
      >
        <div id="contain">
          <form
            onSubmit={(e) => Otpverify(e)}
            className="card signin justify-content-center pt-3 px-xl-4 pt-xl-5 pb-xl-2  px-4  h-100"
          >
            <h2 className="mb-xl-2">User Authentication</h2>
            <p className="m-0">
              <small>An OTP has been sent to {LogOp ? Email : Contact}</small>
            </p>
            <div className="form-floating mb-xl-3 mb-3">
              <input
                type="number"
                style={{
                  paddingLeft: '30%',
                  letterSpacing: '15px',
                  fontSize: '24px',
                }}
                value={OTP}
                min="1000"
                onChange={(e) => setOTP(e.target.value)}
                className="form-control"
                id="floatingCPassword"
                placeholder="Confirm Password"
              />
              <label htmlFor="floatingCPassword">OTP</label>
            </div>
            <center className="mb-3">
              <p className="text-danger">{ErrorOTP ? ErrorOTP : ''}</p>
              <p className="text-success m-1">{OTPSTATUS}</p>
              <br />
              {pop ? <Timer minutes={2} seconds={59} callback={end} /> : ''}
              <br />

              {pop ? (
                <>
                  <button className="btn pro-spaces-outline my-1 " disabled>
                    Resend OTP ?
                  </button>
                  <br />
                </>
              ) : (
                <>
                  <a
                    id="resendOTP"
                    onClick={(e) => formhandler(e)}
                    className="btn pro-spaces-outline my-1 "
                  >
                    Resend OTP ?{' '}
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </a>
                  <br />
                </>
              )}
              {/* <a id='resendOTP' onClick={(e) => formhandler(e)} className="btn pro-spaces-outline my-1 ">Resend OTP ?   {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</a><br /> */}
              <input
                id="signinbtn"
                className="btn mt-xl-2 "
                type="submit"
                value="Verify"
              />
            </center>
          </form>
        </div>
      </div>
    </>
  );
};
