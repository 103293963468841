import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./CreateProfile.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function CreateProfile(props) {
  useEffect(() => {
    props.Auth();
  }, []);
  let navigate = useNavigate();
  const [first, setfirst] = useState("");
  useEffect(() => {
    axios
      .get("/api/Api/getcategory", {})
      .then((res) => {
        // console.log(res)
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [first2, setfirst2] = useState("");

  useEffect(() => {
    axios
      .get("/api/Api/getprocategory", {})
      .then((res) => {
        // console.log(res)
        setfirst2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [Load, setLoad] = useState(true);

  useEffect(() => {
    axios
      .get("/api/ActiveUser/accountswitch")
      .then((res) => {
        setLoad(false);
        // console.log(res.data);
        if (res.status === 200) {
          if (
            localStorage.getItem("Type") === "Product" ||
            localStorage.getItem("Type") === "Consultant"
          ) {
            if (res.data.length >= 20) {
              navigate("/");
            } else {
            }
          }
          if (
            localStorage.getItem("Type") === "Productv2" ||
            localStorage.getItem("Type") === "Consultantv2"
          ) {
            if (res.data.length >= 10) {
              navigate("/");
            } else {
              if (localStorage.getItem("Type") === "Productv2") {
                navigate("create_products_profile", { replace: true });
              } else if (localStorage.getItem("Type") === "Consultantv2") {
                navigate("create_services_profile", { replace: true });
              }
            }
          }
        }
      })
      .catch((res) => {});
  }, []);

  if (Load) {
    return <div></div>;
  } else {
    return (
      // <div className="container ">
      //     <div className="row slhishi">
      //       <div className="col lg-6 col-md-6 col-sm-12">
      //       <Link to="CreateConseltent" className="wgfiuwrgiu">
      //         <div className="card carrrr">

      //             <div className="padunnor"><center>Create a Consultant Profile</center></div>

      //         </div>
      //         </Link>
      //       </div>
      //       <div className="col lg-6 col-md-6 col-sm-12">
      //       <Link to="CreateProduct" className="wgfiuwrgiu">
      //         <div className="card carrrr">

      //             <div className="padunnor"><center>Create a Vendor Profile</center></div>

      //         </div>
      //         </Link>
      //       </div>
      //     </div>
      // </div>

      <div className="container ">
        <div className="row slhishieo3jfiehf9999">
          <center>
            <h5 style={{ color: "var(--blue)" }}>
              Select your <span>Profile Classification</span>
            </h5>
          </center>
          <center>
            <p className="mb-0" style={{ color: "var(--blue)" }}>
              (Choose between Services and Products)
            </p>
          </center>
          <div className="col-12 col-lg-6 d-flex align-items-stretch">
            <div className="card  magiccard ">
              <Link
                to="create_services_profile"
                style={{ textDecoration: "none", color: "var(--blue" }}
              >
                <center className="mb-3">
                  <span style={{ fontSize: "25px" }}>SERVICES</span>
                </center>
              </Link>
              <Link
                to="create_services_profile"
                style={{ textDecoration: "none", color: "var(--blue" }}
              >
                <center className="pt-2">
                  <p className="mb-0">
                    Qualified Professionals, Artists, Academicians, all
                    Technically and Technologically Knowledgeable Persons and
                    Service Providers, and Commercial Service Providers across
                    industries.
                  </p>
                </center>
                <center>
                  <button className="btn pro-spaces-button3 px-3 my-4">
                    CREATE PROFILE
                  </button>
                </center>
              </Link>
              <center>
                <button
                  className="btn pro-spaces-button px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#uugfuewfhfhgflqeougeoufoew"
                >
                  <span>VIEW CATEGORIES</span>
                </button>
              </center>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-stretch">
            <div className="card  magiccard ">
              <Link
                to="create_products_profile"
                style={{ textDecoration: "none", color: "var(--blue" }}
              >
                <center className="mb-3">
                  <span style={{ fontSize: "25px" }}>PRODUCTS</span>
                </center>
              </Link>
              <Link
                to="create_products_profile"
                style={{ textDecoration: "none", color: "var(--blue" }}
              >
                <center className="pt-2">
                  <p className="mb-0">
                    All Product Retailers and Vendors, Builders and Developers,
                    Art Dealers.
                  </p>
                </center>
                <center>
                  <button className="btn pro-spaces-button3 px-3 my-4">
                    CREATE PROFILE
                  </button>
                </center>
              </Link>
              <center>
                <button
                  className="btn pro-spaces-button px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#uugfuewgflqeougeoufoewdsssieryfg"
                >
                  <span>VIEW CATEGORIES</span>
                </button>
              </center>
            </div>
          </div>
        </div>

        <p className="invisible">hai</p>

        <div
          className="modal fade"
          style={{ zIndex: "10000000" }}
          id="uugfuewfhfhgflqeougeoufoew"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                {first
                  ? first.map((element) => {
                      // console.log(element.sub)
                      return (
                        <>
                          <div>
                            <div className="Categories pb-3 pt-3">
                              <b className="">{element.category}</b>
                            </div>
                            <div className="">
                              <ul>
                                {element.sub
                                  ? element.sub.map((elm) => {
                                      return (
                                        <li>
                                          <div className="row">
                                            <div className="col-auto">
                                              {elm.sub}
                                            </div>
                                            {/* <div className='col-auto'>: 10</div> */}
                                          </div>
                                        </li>
                                      );
                                    })
                                  : ""}
                              </ul>
                            </div>
                          </div>
                          <hr />
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          style={{ zIndex: "10000000" }}
          id="uugfuewgflqeougeoufoewdsssieryfg"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                {first2
                  ? first2.map((element) => {
                      // console.log(element.sub)
                      return (
                        <>
                          <div>
                            <div className="Categories pb-3 pt-3">
                              <b className="">{element.category}</b>
                            </div>
                            <div className="">
                              <ul>
                                {element.sub
                                  ? element.sub.map((elm) => {
                                      return (
                                        <li>
                                          <div className="row">
                                            <div className="col-auto">
                                              {elm.sub}
                                            </div>
                                            {/* <div className='col-auto'>: 10</div> */}
                                          </div>
                                        </li>
                                      );
                                    })
                                  : ""}
                              </ul>
                            </div>
                          </div>
                          <hr />
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>

      //  <div className="container ">
      // <div className="row slhishieo3jfiehf">
      //   <div className="col lg-6 col-md-6 col-sm-12">
      //   <Link to="CreateConseltent" className="wgfiuwrgiu">
      //     <div className="card carrrrbus">

      //         <div className="padunnor"><center>Create a Consultant Profile</center></div>

      //     </div>
      //     </Link>
      //   </div>
      //   <div className="col lg-6 col-md-6 col-sm-12">
      //   <Link to="CreateProduct" className="wgfiuwrgiu">
      //     <div className="card carrrrbus">

      //         <div className="padunnor"><center>Create a Vendor Profile</center></div>

      //     </div>
      //     </Link>
      //   </div>
      // </div>
      // </div>
    );
  }
}
