
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import Moment from 'moment';


export function SVideo(props) {

    return (<div className="row">
        <div className="col-2">
        </div>
        <div className="col-10 pe-5 d-flex justify-content-end">
            <div className="   p-1 m-2" >
                <div className="row ps-3  text-end  ">
                    <div className="col-12 p-0 pb-1 m-0">
                        {props.data.sender.firstname}
                    </div>
                    <div className="m-0 p-0" >
                        <div className="row p-0 d-flex justify-content-end">
                            <div className="p-0 col-12" >
                                <p className="message mb-0 text-end text-light bg-secondary px-3">
                                    <small>
                                        <video width={'150px'} className="" controls> <source src={props.data.content} />  </video>
                                    </small>
                                </p>
                                {/* <p className="mt-0  pe-4"> {datey(element.createdAt)}</p> */}
                                <p className="mt-0  pe-4">  {Moment(props.data.createdAt ? props.data.createdAt.slice(0, 24) : '').format(' h:mm:ss a')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}