import React,{useEffect,useState} from 'react'
import './Notification.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {BsThreeDotsVertical} from "react-icons/bs"
import axios from 'axios';
import {Link} from 'react-router-dom'

import { ChatNotification } from './ChatNotification';
import { EventNotification } from './EventNotification';




export const Notification = () => {
// const [NotificationView, setNotificationView] = useState(false)

    // function Seen(e) {
    //     if (e.target.classList.contains('unseen')) {
    //         e.target.classList.remove('unseen')
    //     }
    //     else {

    //     }
    // }

    // const DeleteNotification = (id) => {

    //     document.getElementById(id).remove();
    // }
useEffect(() => {
    var modal = document.getElementById("Notification");

    window.onclick = function (event) {
        if (event.target === modal) {
            // modal.style.display = "none";

            // console.log('hii')
        }
        
        
    }
})

const [getval, setGetval] = useState(false);

useEffect(() => {
    // console.log('notowork')
    axios.get('/api/User/getnotification').then((res)=>{
        console.log(res.data)
        setGetval(res.data)
        
    }).catch((err)=>{
        console.log(err)
    })
   
}, [])
// console.log(getval)

    return (
        
        <div >
        <div className=" notificationBg  s ">
            < >

                {/* <div id="Notification"  className="col-lg-8 col-md-3   "></div> */}
 
                < >
                    <div  className="box shadow-sm rounded bg-white mb-3">
                        {/* <div className="text-center  mt-3">NOTIFICATIONS</div>
                        <hr /> */}
                        <div className="notificationContainer">
                            {/* <div className="box-title border-bottom p-3">
                                <h6 className="m-0">Recent</h6>
                            </div> */}
                            <div className="box-body p-0">

                                {/* on notpad noti1 */}
                                

                            </div>

                            <div className="box shadow-sm rounded bg-white mb-3 esgjpisehgpishepgsip">
                                {/* <div className="box-title border-bottom p-3">
                                    <h6 className="m-0">Earlier</h6>
                                </div> */}
                                <div className="box-body p-0 mt-1">
                                  
                                <EventNotification/>
                                <ChatNotification/>
{ getval.length>0 ? getval.map((Element) => {
   if(Element.Notification_Type===11){
    if(Element.Notiview===0){
        return (
            <>
            <Link to="/Virtual_secretary" className="dkufgiwfiuwbcuwebcu activenoti">
            <div id="a12" className="p-3 border-bottom weingoucerh9guoheuozgb3o osahan-post-header mb-1" style={{"borderRadius":"7px"}}  data-bs-dismiss="offcanvas">
                                                    <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                               
                                            </div>
                                            </Link>
                                            </>
        )
    }else{
        return (
            <>
             <Link to="/Virtual_secretary" className="dkufgiwfiuwbcuwebcu notnoti">
            <div id="a12" className="p-3  border-bottom osahan-post-header mb-1" style={{"borderRadius":"7px","backgroundColor":"#DCDCDC"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                              
                                            </div>
                                            </Link>
            </>
        
        )
    }
   }else if(Element.Notification_Type===15)
   {
    if(Element.Notiview===0){
        return (
            <>
            <Link to={"/Post_single?post_id="+Element.Target_id} className="dkufgiwfiuwbcuwebcu activenoti">
            <div id="a12" className="p-3  border-bottom weingoucerh9guoheuozgb3o osahan-post-header mb-1" style={{"borderRadius":"7px"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                               
                                            </div>
                                            </Link>
            </>
        
        )
    }else{
        return (
            <>
            <Link to={"/Post_single?post_id="+Element.Target_id} className="dkufgiwfiuwbcuwebcu notnoti ">
            <div id="a12" className="p-3  border-bottom osahan-post-header mb-1" style={{"borderRadius":"7px","backgroundColor":"#DCDCDC"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                              
                                            </div>
                                            </Link>
            </>
        )
    }
   }

   else if(Element.Notification_Type===5)
   {
    if(Element.Notiview===0){
        return (
            <>
            <Link to={"/View_Applicants_Single?k="+Element.Target_id} className="dkufgiwfiuwbcuwebcu activenoti">
            <div id="a12" className="p-3  border-bottom weingoucerh9guoheuozgb3o osahan-post-header mb-1" style={{"borderRadius":"7px"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                               
                                            </div>
                                            </Link>
            </>
        
        )
    }else{
        return (
            <>
            <Link to={"/View_Applicants_Single?k="+Element.Target_id} className="dkufgiwfiuwbcuwebcu notnoti ">
            <div id="a12" className="p-3  border-bottom osahan-post-header mb-1" style={{"borderRadius":"7px","backgroundColor":"#DCDCDC"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                              
                                            </div>
                                            </Link>
            </>
        )
    }
   }
   else if(Element.Notification_Type===20)
   {
    if(Element.Notiview===0){
        return (
            <>
            <div id="a12" className="p-3  border-bottom weingoucerh9guoheuozgb3o osahan-post-header mb-1" style={{"borderRadius":"7px"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                               
                                            </div>
                                            
            </>
        
        )
    }else{
        return (
            <>
            <div id="a12" className="p-3  border-bottom osahan-post-header mb-1" style={{"borderRadius":"7px","backgroundColor":"#DCDCDC"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                              
                                            </div>
            </>
        )
    }
   }
   else if(Element.Notification_Type===25)
   {
    if(Element.Notiview===0){
        return (
            <>
            <div id="a12" className="p-3  border-bottom weingoucerh9guoheuozgb3o osahan-post-header mb-1" style={{"borderRadius":"7px"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                               
                                            </div>
                                            
            </>
        
        )
    }else{
        return (
            <>
            <div id="a12" className="p-3  border-bottom osahan-post-header mb-1" style={{"borderRadius":"7px","backgroundColor":"#DCDCDC"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                              
                                            </div>
            </>
        )
    }
   }
   else if(Element.Notification_Type===35)
   {
    if(Element.Notiview===0){
        return (
            <>
            <Link to={"/ask_us_view?k="+Element.Target_id} className="dkufgiwfiuwbcuwebcu activenoti">
            <div id="a12" className="p-3  border-bottom weingoucerh9guoheuozgb3o osahan-post-header mb-1" style={{"borderRadius":"7px"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                               
                                            </div>
                                            </Link>
            </>
        
        )
    }else{
        return (
            <>
            <Link to={"/ask_us_view?k="+Element.Target_id} className="dkufgiwfiuwbcuwebcu notnoti ">
            <div id="a12" className="p-3  border-bottom osahan-post-header mb-1" style={{"borderRadius":"7px","backgroundColor":"#DCDCDC"}} data-bs-dismiss="offcanvas">
            <div className="font-weight-bold me-1">
                                                    <div className='row'>
                                                    <div className="small alieifhoiaeh col-11 pe-0"> {Element.Notification_Content} </div>
                                                    <div className='col-1 ps-0 invisible'>
                                                    <div className="dropdown">
                                                    <BsThreeDotsVertical id="notidropdown" data-bs-toggle="dropdown" aria-expanded="false"/>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                    </ul>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                              
                                            </div>
                                            </Link>
            </>
        )
    }
   }

    }
    )
: <center> <p>Nothing to show !</p></center>}
                                     
                                    


                                    

                                    {/* <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                <div className="dropdown-list-image me-3">
                                    <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" />
                                </div>
                                <div className="font-weight-bold me-3">
                                    <div className="mb-2"><span className="font-weight-normal">Congratulate Gurdeep Singh Osahan (iamgurdeeposahan)</span> for 5 years at Askbootsrap Pvt.</div>
                                    <button type="button" className="btn btn-outline-success btn-sm">Say congrats</button>
                                </div>
                                <span className="ms-auto mb-auto">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <BiDotsVerticalRounded />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <button className="dropdown-item" type="button"><RiDeleteBin4Fill />Delete</button>
                                            <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="text-right text-muted pt-1">4d</div>
                                </span>
                            </div> */}
                                    {/* <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                <div className="dropdown-list-image me-3">
                                    <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="" />
                                </div>
                                <div className="font-weight-bold me-3">
                                    <div>
                                        <span className="font-weight-normal">Congratulate Mnadeep singh (iamgurdeeposahan)</span> for 4 years at Askbootsrap Pvt.
                                        <div className="small text-success"><i className="fa fa-check-circle"></i> You sent Mandeep a message</div>
                                    </div>
                                </div>
                                <span className="ms-auto mb-auto">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <BiDotsVerticalRounded />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <button className="dropdown-item" type="button"><RiDeleteBin4Fill />Delete</button>
                                            <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="text-right text-muted pt-1">4d</div>
                                </span>
                            </div> */}



                                </div></div>
                        </div>
                    </div>
                </>
            </>


        </div>
        </div>
    )

}
