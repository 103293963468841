import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./ForgetPass.css";
import Axios from "axios";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Navoutside } from "../Navoutside/Navoutside";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Timer } from "../../Utility/timer";
import { NewNavigationBarDissabled } from "../../Pages/Free/NewNavigationBar/NewNavigationBarDissabled";

export function ForgetPass() {
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  const Auth = () => {
    axios
      .get("/api/verify/authenticateduser")
      .then((res) => {
        if (res.status === 200) {
          console.log("workd");
          setAuthenticated(true);
          navigate("/home");
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  };

  useEffect(() => {
    Auth();
  }, []);
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [Password, setPassword] = useState("");
  const [Contact, setContact] = useState("");
  const [Submit, setSubmit] = useState(false);
  const [Error, setError] = useState("");
  const [Erroe2, setError2] = useState(false);
  const [OtpTry, setOtpTry] = useState(0);
  const [Error3, setError3] = useState(false);

  const [loading, setloading] = useState(false);

  const [pop, setpop] = useState(false);

  const end = () => setpop(false);
  /* eslint-disable */
  function Validate() {
    if (!Contact) {
      setloading(false);
      return setError("Enter a valid login Id");
    }
    if (isNaN(Contact)) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Contact)) {
        setError("");
      } else {
        setloading(false);
        return setError("PLEASE ENTER a valid email id");
      }
    } else {
      if (Contact.length != 10) {
        setloading(false);
        return setError("Enter valid Mobile number");
      }
    }

    setError("");
    return true;
  }

  const spinner = (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
  const formhandler = async (e) => {
    setpop(true);
    console.log("first");
    setloading(true);

    e.preventDefault();
    var status = false;
    setSubmit(true);
    status = Validate();
    console.log(status);
    if (status == true) {
      let Datas;
      if (!isNaN(Contact)) {
        Datas = Contact;
        var findResult = await Axios.post("/api/auth/numbermatch", { Contact });
      } else {
        let Email = Contact;
        Datas = Email;
        var findResult = await Axios.post("/api/auth/emailmatch", { Email });
      }
      console.log(findResult);
      if (findResult.data == "Exist") {
        Axios.post("/api/auth/sendMeOtp", { Datas })
          .then((res) => {
            if (res.status == 200) {
              console.log("otp send");
              console.log(res.data);
              document.getElementById("p1").classList.add("d-none");
              document.getElementById("p2").classList.remove("d-none");
              setloading(false);
            }
          })
          .catch((err) => {
            console.log(err.response.data);
            setloading(false);
          });
        setError2("");
      } else {
        console.log("Not a regiser");
        setError2("Not a Registered Account !");
        setloading(false);
      }
      // find account
    }
  };

  useEffect(() => {
    if (Submit) {
      Validate();
    }
  });
  const Otpverify = async (e) => {
    e.preventDefault();
    setOtpTry(OtpTry + 1);

    const dataPack = {
      dataSend: {
        Contact,
      },
      Otp: {
        Datas: Contact,
        OTP,
      },
    };
    console.log(dataPack);

    if (OtpTry < 5) {
      console.log(OtpTry);
      var verifyOT;
      try {
        verifyOT = await Axios.post("/api/Recovery/account", { dataPack });
        if (verifyOT.status == 200) {
          document.getElementById("p2").classList.add("d-none");
          document.getElementById("p3").classList.remove("d-none");
        }
      } catch (err) {
        console.log(err.response);

        setErrorOTP("Incorrect OTP");
      }
    } else {
      setErrorOTP("No More Attempts !");
    }
  };
  const [ErrorOTP, setErrorOTP] = useState(false);
  const [OTP, setOTP] = useState("");
  useEffect(() => {
    setError2("");
  }, [Contact]);

  useEffect(() => {
    if (OTP.length > 4) {
      setOTP(OTP.slice(0, 4));
    }
    console.log(OTP);
  }, [OTP]);

  const NewPassword = async (e) => {
    e.preventDefault();
    // validate password
    if (Password.length < 8) {
      return setError3("Password must have 8 characters");
    }
    let dataSend = {
      Password,
    };
    try {
      let Pass = await Axios.post("/api/Recovery/newPassword", { dataSend });
      // console.log(Pass)
      if (Pass) {
        // toast + navigate
        notify("Password Changed !", true);
        navigate("/signin");
      }
    } catch (err) {
      notify("Something went wrong !", false);
      navigate("/signin");
    }
  };

  useEffect(() => {
    console.log(ErrorOTP);
  }, []);

  return (
    <>
      <NewNavigationBarDissabled />

      {/* <button type='button' onClick={trigger}>CLick</button>   */}
      <div id="p1" className="d-flex justify-content-center pt-5">
        <div id="contain" className=" ">
          <form
            onSubmit={(e) => formhandler(e)}
            className="card signin justify-content-center pt-3 px-xl-4 pt-xl-5 pb-xl-2  px-4  h-100"
          >
            <h2 className="mb-xl-2">Find Your Account !</h2>
            {/* <p className="mb-xl-2">Stay updated</p> */}
            <div className="w-xl-50">
              <div className="form-floating mb-xl-3 mb-3">
                <input
                  type="text"
                  value={Contact}
                  onChange={(e) => setContact(e.target.value)}
                  className="form-control"
                  id="floatingContact"
                  placeholder="Contact"
                />
                <label htmlFor="floatingContact">Enter your Login Id</label>
              </div>

              <p className="text-danger">{Erroe2}</p>
              <div className="text-danger mx-xl-0 mt-xl-1">{Error}</div>
            </div>
            <center className="mb-3">
              {/* <input
                id="signinbtn"
                className="btn mt-xl-2"
                type="submit"
                value="Continue"
              /> */}
              {loading ? (
                <button className="btn pro-spaces-button2 " disabled>
                  Continue
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              ) : (
                <button className="btn pro-spaces-button2 ">
                  Continue
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              )}
            </center>
          </form>
        </div>
      </div>
      <div id="p2" className="d-flex justify-content-center pt-5 d-none ">
        <div id="contain">
          <form
            onSubmit={(e) => Otpverify(e)}
            className="card signin justify-content-center pt-3 px-xl-4 pt-xl-5 pb-xl-2  px-4  h-100"
          >
            <h2 className="mb-xl-2">User Authentication</h2>
            <p className="m-0">
              <small>An OTP has been send to {Contact}</small>
            </p>
            <div className="form-floating mb-xl-3 mb-3">
              <input
                type="number"
                style={{
                  paddingLeft: "30%",
                  letterSpacing: "15px",
                  fontSize: "24px",
                }}
                value={OTP}
                min="1000"
                onChange={(e) => setOTP(e.target.value)}
                className="form-control"
                id="floatingCPassword"
                placeholder="Confirm Password"
              />
              <label htmlFor="floatingCPassword">OTP</label>
            </div>
            <center className="mb-3">
              <p className="text-danger">{ErrorOTP ? ErrorOTP : ""}</p>
              <div>
                <br />
                {pop ? <Timer minutes={2} seconds={59} callback={end} /> : ""}
                <br />

                {pop ? (
                  <>
                    <button className="btn pro-spaces-outline my-1 " disabled>
                      Resend OTP ?
                    </button>
                    <br />
                  </>
                ) : (
                  <>
                    <a
                      id="resendOTP"
                      onClick={(e) => formhandler(e)}
                      className="btn pro-spaces-outline my-1 "
                    >
                      Resend OTP ?{" "}
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </a>
                    <br />
                  </>
                )}

                {/* <a onClick={(e) => formhandler(e)}>Resend OTP ?</a> */}
              </div>
              <input
                id="signinbtn"
                className="btn mt-xl-2"
                type="submit"
                value="Confirm"
              />
            </center>
          </form>
        </div>
      </div>
      <div id="p3" className="d-flex justify-content-center pt-5 d-none ">
        <div id="contain">
          <form
            onSubmit={(e) => NewPassword(e)}
            className="card signin justify-content-center pt-3 px-xl-4 pt-xl-5 pb-xl-2  px-4  h-100"
          >
            <h2 className="mb-xl-2">Account Recovery</h2>
            <p className="m-0">
              <small>Setup your new password !</small>
            </p>
            <div className="form-floating mb-xl-3 mb-3">
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                id="floatingCPassword"
                placeholder="Confirm Password"
              />
              <label htmlFor="floatingCPassword">New Password</label>
            </div>
            <center>
              <p className="text-danger">{Error3 ? Error3 : ""}</p>
              {/* <p className="text-danger">{ErrorOTP ? ErrorOTP : ""}</p> */}
              <div className="row"> </div>
              <div className="row mb-3">
                {" "}
                <input
                  id="signinbtn"
                  className="btn w-100"
                  type="submit"
                  value="Change Password"
                />
              </div>
            </center>
          </form>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
