import { GiSpeaker as SpeakerPro  } from 'react-icons/gi'
export const Speaker = () => {
    let speaking = false
    try {
        const Speak = () => {
            try {
                if (!speaking) {
                    speaking = true
                    var audio = new Audio('/prochureNewVoice.mp3');
                    audio.play();
                    audio.onended = () => speaking = false
                }
            } catch (error) {
                console.log(error)
            }
        }
        return <span style={{ 'cursor': 'pointer' }}>
            <SpeakerPro style={{'color':'white'}} onClick={(e) => Speak()} size={30} />
        </span>
    } catch (error) {
        console.log(error)
        return <></>
    }
}