function keyGen() {
    var length = 5;
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

const bloodgrouplist=['A+','A-','B+','B-','O+','O-','AB+','AB-']

const GenreList=['Action','Animation','Comedy','Crime','Drama','Experimental','Fantasy','Historical','Horror',
             'Romance','Science Fiction','Thriller','Western','Musical','War','Biopics', ]
const AdminType=['AdminType1','AdminType2','AdminType3','AdminType4','AdminType5',]   
const DTHOperator=['Airtel Digital TV','Dish TV','Sun Direct','Tata Play','Videocon d2h'] 
const DTHPlans=['Assamese','Bengali','Bhojpuri','English','Gujarati','Hindi',
                'Kannada','Malayalam','Marathi','North East','Odia','Others',
                'Punjabi','Tamil','Telugu','Urdu']  
                
const currencyList = [
  { country: "India", code: "INR", symbol: "₹" },
  { country: "America", code: "USD", symbol: "$" },
  { country: "Canada", code: "CAD", symbol: "CA$" },
  { country: "Germany", code: "EUR", symbol: "€" },
  { country: "Australia", code: "AUD", symbol: "A$" },
  // Add more currencies as needed
];               
const unitOfMeasurementList = [
  "m", // Meter
  "cm", // Centimeter
  "mm", // Millimeter
  "in", // Inch
  "ft", // Foot
  "yd", // Yard
  "mi", // Mile
  "g", // Gram
  "kg", // Kilogram
  "oz", // Ounce
  "lb", // Pound
  "L", // Liter
  "ml", // Milliliter
  "cup", // Cup
  "tsp", // Teaspoon
  "tbsp", // Tablespoon
  "day", // Day
  "week", // Week
  "month", // Month
  "year", // Year
  // Add more units as needed
];

export { keyGen, disablePastDate,bloodgrouplist,GenreList,DTHOperator,DTHPlans,currencyList,unitOfMeasurementList }