import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { ChatSearch } from "./chatSearch";
import axios from "axios";
import { ChatTab } from "./chatTab";
import "./chat.css";
let prev
let save
export const ChatLeft = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    chatuserListLoader() {
      chatuserListLoad();

    }
  }));
  useEffect(() => {
    // console.log("somethin");
    chatuserListLoad();
    // console.log(props)
  }, [props]);
  const [Loading, setLoading] = useState(false)
  const [Reload, setReload] = useState(true);
  // console.log(props)
  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [Result, setResult] = useState(false);
  const [Pass, setPass] = useState()

  const chatuserListLoad = async () => {
    setLoading(true)
    var data = await axios.get("/api/User/communications");
    setLoading(false)
    if (data) {
      save = data.data
      // console.log(prev)
      if (prev != data.data) {
        // console.lo1g('first')
        // console.log(prev)
        setResult(data.data);
        setPass(data.data)
        // // console.log(data);
        prev = data.data
      }
    } else {

    }
  };

  const [Display, setDisplay] = useState(false)
  const [loader, setloader] = useState(<p className="text-center">Nothing to show !</p>)
  useEffect(() => {
    if (Result.length > 0) {
      // console.log(Result)
      let re = Result.map((element) => { return <ChatTab Chatwith={props.Chatwith} key={keyGen()} data={element} /> })
      // console.log(re)
      setDisplay(re)
    }


  }, [Result])


  const Tab = (
    <>
      <div>
        <div className="container-fluid invisible  m-0 p-2 row w-100">
          <div className="col-2 p-0">
            {" "}
            <img

              alt="avatar"
              className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
              width="60"
            />
          </div>

          <div className="col-10 ps-0">
            <div className="row">
              <div className="col-8 py-3"></div>
              {/* <div className="col-4 text-end pe-0">Yesterday</div> */}
            </div>
            {/* <div className="m-1">Hello wolrd ! this is last mess</div> */}
          </div>
        </div>
        {/* <hr className="m-2" /> */}
      </div>
      <div>
        <div className="container-fluid invisible  m-0 p-2 row w-100">
          <div className="col-2 p-0">
            {" "}
            <img

              alt="avatar"
              className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
              width="60"
            />
          </div>

          <div className="col-10 ps-0">
            <div className="row">
              <div className="col-8 py-3"></div>
              {/* <div className="col-4 text-end pe-0">Yesterday</div> */}
            </div>
            {/* <div className="m-1">Hello wolrd ! this is last mess</div> */}
          </div>
        </div>
        {/* <hr className="m-2" /> */}
      </div>
      <div>
        <div className="container-fluid invisible  m-0 p-2 row w-100">
          <div className="col-2 p-0">
            {" "}
            <img

              alt="avatar"
              className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
              width="60"
            />
          </div>

          <div className="col-10 ps-0">
            <div className="row">
              <div className="col-8 py-3"></div>
              {/* <div className="col-4 text-end pe-0">Yesterday</div> */}
            </div>
            {/* <div className="m-1">Hello wolrd ! this is last mess</div> */}
          </div>
        </div>
        {/* <hr className="m-2" /> */}
      </div>
    </>
  );

  const Search = (searchText) => {

    var condition = new RegExp(searchText, 'i');
    var result = Pass.filter(function (el) {
      return condition.test(el.chatName);
    });
    setResult(result);

  }


  return (
    <div
      id="leftRoll"
      className="col-lg-5 col-sm-12 col-12 col-md-12 col-xl-4 px-1  verticalStu "
    >
      <div className="row f ">
        {/* SearchToolBar */}
        <ChatSearch Search={Search} data={Result} chatuserListLoad={chatuserListLoad} BlockedList={props.BlockedList} />
        {/* SearchToolBar */}
      </div>
      <div className="col-12 chatList w-100 s  py-1">
        {/* <div className=" ms-1 bg-light "> */}
        {Display ? Display : ''}
        {Loading ? loader : !Display? <p className="text-center mt-5">No chats !</p>:''}
        {Tab}
        {/* chattab */}
        {/* </div> */}
      </div>
    </div>
  );
})
