import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const PersonalInformationStatic = ({ getval, vendor }) => {
  const { firstname, lastname, gender, age, language } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      {firstname?.length > 0 ||
      lastname?.length > 0 ||
      gender?.length > 0 ||
      age?.length > 0 ||
      language?.length > 0 ? (
        <Accordion
          expanded={expanded === 'panel299'}
          onChange={handleChange('panel299')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '100%', flexShrink: 0 }}>
              <span>
                <b>
                  {' '}
                  Personal Information
                  <br />
                </b>
                {vendor
                  ? '(of the Proprietor / Business Owner)'
                  : '(of the Service Provider / Proprietor)'}
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {firstname || lastname ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>
                      {vendor
                        ? 'Name of the Proprietor / Business Owner'
                        : 'Name of the Proprietor / Service Provider'}
                    </span>
                  </div>

                  <div className="col-md-6 ">
                    <label style={{ color: 'var(--blue)' }}>
                      {firstname ? firstname.toUpperCase() : ''}{' '}
                      {lastname ? lastname.toUpperCase() : ''}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {gender ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Gender</span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{ color: 'var(--blue)' }}>
                      {gender ? gender : ''}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {age ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Age</span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{ color: 'var(--blue)' }}>
                      {age ? age + ' years' : ''}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {language?.length > 0 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Languages Known</span>
                  </div>
                  <div className="col-md-6 ">
                    <label style={{ color: 'var(--blue)' }}>
                      {language
                        ? language.map((element, index) => {
                            if (index == language?.length - 1) {
                              return element;
                            } else {
                              return element + ', ';
                            }
                          })
                        : ''}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
    </>
  );
};
