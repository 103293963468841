import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import { IoMdInformationCircle } from "react-icons/io";

export const Deleteaccount = ({ downGrade, passive }) => {
  console.log(passive);
  const [show, setShow] = useState(false);
  const [deletmodal, setdeletmodal] = useState(false);
  const [passivemodal, setpassivemodal] = useState(false);


  const [value, setvalue] = useState({
    Reason: ""
  });

  const [dataSend, setdataSend] = useState({
    Password: ""
  })

  const [err, seterr] = useState("")
  const submit = () => {
    axios.post("/api/AuthenticatedUser/DeleteAccount", { dataSend, value }).then((res) => {
      console.log("xczcz")
      localStorage.clear()
      window.location.reload()
    }).catch((err) => {
      seterr(err.response.data);

    })
  }





  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const deletemodalshow = () => setdeletmodal(true);
  const deletemodalclose = () => setdeletmodal(false);
  const passivemodalshow = () => setpassivemodal(true);
  const passivemodalclose = () => setpassivemodal(false);




  const Delete = () => {
    console.log("Axzccxz", { dataSend })
    console.log(value)

    axios.post("/api/AuthenticatedUser/ToPassive", { dataSend, value }).then((res) => {
      console.log("Axzccxz", { dataSend })
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (value.Reason.length != 0) seterr("")
  }, [value.Reason])



  return (
    <>
      <button className="btn btn-danger mt-1" onClick={handleShow}>
        delete account
      </button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>
              Reason
            </label>
          </div>
          <select class="form-select" aria-label="Default select example" onChange={(e) => { setvalue({ ...value, Reason: e.target.value }) }}>
            <option disabled selected>Select a reason</option>
            <option value="Service / Business Discontinued"> Service / Business Discontinued</option>
            <option value="Taking a Break from Work / Business" > Taking a Break from Work / Business</option>
            <option value="Subscription Fee is expensive">Subscription Fee is expensive</option>
            <option value="Not Interested in using this platform."> Not Interested in using this platform.</option>

          </select>
          {passive ? <></> :
            <div className="pt-3 fw-bolder" hidden={value.Reason !== "Taking a Break from Work / Business"}>
              <p>
                Why do you want to delete your Account when you can simply Hide your
                profile and Continue using the other features of the platform !
              </p>
              <p>
                If on a Long Break or Sabbatical for an indefinite period :
              </p>
              <p>
                If you are on a long break from work / business, you can keep your profile (s) Inactive for a while WITHOUT DEACTIVATING or DELETING the account. Your profile card will be hidden to other members until the time you undo this action. This feature is seen on ‘Profile Details and Account Settings’ on your Profile tab.
              </p> </div>
          }
          <p className="text-danger">{err}</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row g-2">
            <div className="col-12">
              <Button variant="success" onClick={handleClose} style={{ width: "100%" }}>
                GO BACK TO ACCOUNT
              </Button>
            </div>
            {downGrade !== false &&
              <div className="col-12 ">
                <Button className="pro-spaces-button3" onClick={(e) => { passivemodalshow(); handleClose() }} style={{ width: "95%" }}>
                  BE A PASSIVE MEMBER
                </Button>
                <IoMdInformationCircle data-bs-toggle="tooltip" data-bs-placement="top" title="This is your only Profile. So you have to delete your Account." />


              </div>
            }

            <div className="col-12">
              <Button variant="danger" onClick={(e) => {
                if (value.Reason.length) {
                  seterr("")
                  deletemodalshow();
                  handleClose()
                } else {
                  seterr("Select a reason");
                }
              }} style={{ width: "100%" }}>
                PERMANENTLY DELETE
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>





      <Modal show={deletmodal} onHide={deletemodalclose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Password !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input className="form-control" placeholder="Enter password here " type="password" onChange={(e) => { setdataSend({ ...dataSend, Password: e.target.value }) }}></input>


          </div>
          <p className="text-danger">{err}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={deletemodalclose}>
            close
          </Button>
          <Button variant="danger" onClick={submit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>








      <Modal show={passivemodal} onHide={passivemodalclose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Password !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input placeholder="Enter password here " onChange={(e) => { setdataSend({ ...dataSend, Password: e.target.value }) }} className='form-control' type='password' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={passivemodalclose}>
            Close
          </Button>
          <button className="btn pro-spaces-button3" onClick={Delete}>
            Convert
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
