import { configureStore } from "@reduxjs/toolkit";
import cubicleSlice from "./Prochure/cubicleSlice";
import categorySlice from "./Prochure/categorySlice";
import userSlice from "./Prochure/userSlice";
export default configureStore({
  reducer: {
    cubicle: cubicleSlice,
    category: categorySlice,
    user: userSlice,
  },
});
