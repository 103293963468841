import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './recruitPostForm.css'
import './jobView.css'
import Axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Moment from 'moment';
import ExperiencedViewSingle from "./experiencedViewSingle";
import InternViewSingle from "./internViewSingle";
import FresherViewSingle from "./fresherViewSingle";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ApplicantsSingleView = (props) => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        // props.Auth()
        }, [])

    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("k")
    var jobid=searchParams.get("p")
    // console.log(id)
    const [Answer,setAnswer]=useState(false)

    const [jobDetails, setjobDetails] = useState(false)
    const [Quli, setQuli] = useState()
    const [Qno, setQno] = useState()
    const [Resume,setResume]= useState()
    const [Work,setWork]=useState()
    const [loader, setloader] = useState(<div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>)
    const [Loading, setLoading] = useState(true)
    useEffect(() => {
        view()     
    }, [])

    function view(){
        var sendData={
            id:id,
            jobid:jobid
        }
        Axios.post('/api/User/ApplicantsSingleView', {sendData} )
        .then(res => {
            if (res.status === 200) {
                // console.log(Id)
                if(res.data.data1[0])
                {
                    setjobDetails(res.data.data1[0]);
                    setQuli(res.data.data1[0].JobSeeker_qualification)
                    setQno(res.data.data1[0].JobSeeker_qualificationNo)
                    getAge(jobDetails.JobSeeker_dob)
                
                    setResume(res.data.Resume)
                    setWork(res.data.Work)


                    if (res.data.data1[0].full) {

                        if (res.data.data1[0].full.length>0) {
                            setAnswer(res.data.data1[0].full[0])
        
                                
                            }
                        
                    }

                   

                   


                }
                else{

                    setjobDetails(res.data.data1[0]);
                    setQuli(res.data.data1.JobSeeker_qualification)
                    setQno(res.data.data1.JobSeeker_qualificationNo)
                    getAge(jobDetails.JobSeeker_dob)
                    setResume(res.data.Resume)
                    setWork(res.data.Work)

                    // setAnswer(res.data.data1[0].full[0])

                }
              
               

                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
            alert("sonthing went wrong");

        })

    }


    //   console.log("hai"+Quli)



    const [currentIndex, setcurrentIndex] = useState(1)
    var total = 5

    const Nextone = (s) => {


        if (s) {

            if (currentIndex == total) {
                return

            } else {
                document.getElementById('fq' + currentIndex).classList.add('d-none')
                document.getElementById('fq' + (currentIndex + 1)).classList.remove('d-none')
                setcurrentIndex(currentIndex + 1)

            }

        }
        else {

            if (currentIndex == 1) {

                return

            } else {
                document.getElementById('fq' + currentIndex).classList.add('d-none')
                document.getElementById('fq' + (currentIndex - 1)).classList.remove('d-none')
                setcurrentIndex(currentIndex - 1)
            }



        }
    }

    // useEffect(() => {
    //     // if (currentIndex == 1) {
    //     //     document.getElementById('bBtn').classList.add('d-none')
    //     // } else {
    //     //     document.getElementById('bBtn').classList.remove('d-none')
    //     // }
    //     if (currentIndex == total) {
    //         document.getElementById('btnNext1').classList.add('d-none')
    //         document.getElementById('btnNext2').classList.remove('d-none')
    //     }
    //     else {
    //         document.getElementById('btnNext1').classList.remove('d-none')
    //         document.getElementById('btnNext2').classList.add('d-none')
    //     }
    // }, [currentIndex])



    function getAge() {
        var today = new Date();
        var birthDate = new Date();
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
 

    var date1=Moment(jobDetails.JobSeeker_workingstart).format('DD-MM-YYYY')
    var date2=Moment(jobDetails.JobSeeker_workingend).format('DD-MM-YYYY')


    return (
        <div className='my-5 pt-5'>


            <form style={{
                borderRadius: '15px'
            }} >
                {/* className='mt-3 container cont card border pb-2 pt-2 px-4' */}





                         
                     {
                      jobDetails.Type===1?
                      <ExperiencedViewSingle value={jobDetails} resume={Resume} work={Work} /> 
                      :jobDetails.Type===2?  <InternViewSingle value={jobDetails} resume={Resume} work={Work} />:jobDetails.Type===3?<FresherViewSingle value={jobDetails} resume={Resume} work={Work} ></FresherViewSingle>:''
                     }

              


                           {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>></> */}


                {
                    Answer.JobRecruit_screenAnswer?
                   Answer.JobRecruit_screenAnswer.length>0?

                     <>

<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                   <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel4bh-content"
                     id="panel4bh-header"
                   >
                     <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Answers</b></Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                     
                     <Typography>

                     <div className='mt-1 container-fluid border'>
                                  <table className='w-100'>
                                { Answer.JobRecruit_screenAnswer.map((item)=>{


                                      return <tbody>
                                            <tr>
                                               <td className='w-50'>
                                                   {item.Question}
                                               </td>
                                            </tr>
                                         <tr>
                                         <th>
                                            {
                                                item.Answer=='true'?<p>yes</p>:item.Answer=='false'?<p>No</p>:<p>{item.Answer}</p>
                                            }
     
                                          </th>
                                          </tr>
                                      </tbody>



                                }) 
                                
                                
                                
                                }   
                           
                                </table>
                                <div className='m-0 col'></div>
                                <div className='m-0 col' style={{ fontWeight: '600' }}></div>
                            </div>
                         
                     </Typography>
                   </AccordionDetails>
                 </Accordion>


                   {/* <div>
                         <b>Answers</b>
                          <div className='mt-1 container-fluid border'>
                                  <table className='w-100'>
                                { Answer.JobRecruit_screenAnswer.map((item)=>{


                                      return <tbody>
                                            <tr>
                                               <td className='w-50'>
                                                   {item.Question}
                                               </td>
                                            </tr>
                                         <tr>
                                         <th>
                                            {
                                                item.Answer=='true'?<p>yes</p>:item.Answer=='false'?<p>No</p>:<p>{item.Answer}</p>
                                            }
     
                                          </th>
                                          </tr>
                                      </tbody>



                                }) 
                                
                                
                                
                                }   
                           
                                </table>
                                <div className='m-0 col'></div>
                                <div className='m-0 col' style={{ fontWeight: '600' }}></div>
                            </div>
                         </div> */}
                     </>

                  






                   :
                   ""
                   :""
                }

            


                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>></> */}





                {/* <div className='mt-1 container-fluid border'>
                        <table className='w-100'>
                            <tr>
                                <td className='w-50'>
                                    B.Tech in Civil
                                </td>
                                <td style={{ width: '100%' }} className=' text-end'>
                                    <small>  2012 - 2016</small>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Kerala University
                                </th>
                            </tr>
                        </table>
                        <div className='m-0 col'></div>
                        <div className='m-0 col' style={{ fontWeight: '600' }}></div>
                    </div> */}

            </form>
        </div>)
}
