import React, { useEffect, useState, useRef, createContext, useReducer, useContext } from "react";
import { AiFillFire, AiOutlineSearch, AiFillFolder, AiFillDelete, AiFillFolderOpen, AiFillFolderAdd, AiOutlineCloudUpload, AiOutlineArrowLeft } from "react-icons/ai";
import { HiPencil } from 'react-icons/hi'
import { MdDeleteSweep, MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { NewFolder } from "./Folder/newFolder.jsx";
import axios from "axios";
import { Insider, InsiderHeader, NewFilesUploader, InsiderHeaderNew } from "./Folder/inside.jsx";
import { keyGen } from "../../Utility/helper.js";

export const FolderContext = createContext();
export const root = '/root'
export function Folder(props) {
    const reducer = (state, action) => {
        switch (action.type) {
            case "INITIAL":
                return {
                    status: 'doit',
                    header: <InitialHeader />,
                    main: <InitialMain />,
                    classN: 'containerF'
                }
            case "OPENFOLDER":
                return {
                    status: '',
                    header: <InsiderHeader />,
                    main: <Insider />,
                    classNs: ''
                }
            case "NEWFILEUPLOAD":
                return {
                    status: '',
                    header: <InsiderHeaderNew />,
                    main: <NewFilesUploader />,
                    classN: ''
                }
            default:
                return console.log(state)
        }
    };
    let initialTodos = {
        status: 'doNothing',
        header: <InitialHeader />,
        main: <InitialMain />,
        classN: 'containerF'
    }
    const [AdminEdit, setAdminEdit] = useState(false)
    const [state, dispatch] = useReducer(reducer, initialTodos);
    const [Mark, setMark] = useState(false)
    const [NewFolderR, setNewFolder] = useState(false)
    const [NewFolderName, setNewFolderName] = useState('')
    const [Loaded, setLoaded] = useState(false)
    const [Folders, setFolders] = useState([])
    const [Folder, setFolder] = useState()
    const FolderLoader = () => {
        return new Promise((resolve, reject) => {
            let details = { id: props.RoomDetails.id, topic: props.RoomDetails.topic }
            axios.post('/api/User/Cubicles/FolderLoad', { details }).then((res) => {
                setFolders(res.data)
                resolve()
            }).catch((err) => {
                console.log(err)
                resolve()

            })
        });
    }

    useEffect(() => {
        console.log(state)
        console.log('herer in')
        if (state.status == 'doit') {
            FolderLoader()
        }
        return () => {
            console.log('herer pou')
        }
    }, [state])



    try {
        useEffect(() => {
            if (!props.RoomDetails.id && !props.RoomDetails.topic) {
                setLoaded(false)
            } else {
                FolderLoader()
            }
            console.log(props)
            return () => {

            }
        }, [props])
        const Save = async () => {
            try {
                let foldername = NewFolderName.current.innerHTML
                let details = { id: props.RoomDetails.id, topic: props.RoomDetails.topic, foldername }
                let res = await axios.post('/api/User/Cubicles/NewFolder', { details })
                setNewFolder(false)
                let d = [...Folders]
                d = [...d, res.data]
                setFolders(d)
            } catch (error) {
                console.log(error)
            }

        }
        const nameing = (nameer) => setNewFolderName(nameer)
        const DeleteThisFolder = async (id) => {
            try {
                let details = id
                let res = await axios.post('/api/User/Cubicles/DeleteFolder', { details })
                let d = res.data
                let rp = [...Folders]
                let rpp = rp.filter((ele) => {
                    if (ele._id == d._id) {
                    } else {
                        return ele
                    }
                })
                setFolders(rpp)
            } catch (error) {

            }
        }
        const OpenFolder = (id) => {
            console.log(id)
            setFolder(id)
            dispatch({ type: "OPENFOLDER" })
        }
        return (
            <FolderContext.Provider value={{ Folders, nameing, OpenFolder, DeleteThisFolder, setNewFolder, setMark, Save, NewFolderR, dispatch, Folder, AdminEdit, setAdminEdit, Rdetails: props.RoomDetails }}>
                <div className="modal-content">

                    <div className=" p-1">
                        <div className="row g-2">
                            <div className="col-6 p-3">
                                {/* <h5 className="modal-title" id="exampleModalLabel">Product Folder</h5> */}
                            </div>
                            <div className="col-6 p-3">
                                <button type="button " className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        {state.header}
                    </div>

                    <div className="modal-body">
                        <div className="container p-0 modal-content">
                            <div className={`${state.classN}`}>
                                {/* New FOlder */}
                                {state.status === 'doit' || state.status === 'doNothing' ?
                                    NewFolderR && <NewFolder />
                                    : <></>}
                                {state.main}
                                {/* New FOlder */}
                            </div>
                        </div>
                    </div>

                </div >
            </FolderContext.Provider>
        )
    } catch (error) {
        console.log(error)
    }

}

export function InitialHeader(props) {
    let Fcontext = useContext(FolderContext)

    return <div className="container">
        <div className="row ">
            {/* <div className="col-1">
    <BsArrowLeft onClick={(e) => NavigateBack()} size={30} />
</div> */}
            <div className="col-9 ">
                {/* <button className="btnFolder"><AiOutlineCloudUpload /></button> */}
                <button onClick={(e) => Fcontext.setNewFolder(true)} className="btnFolder"><AiFillFolderAdd style={{ color: 'var(--blue)' }} size={35} /></button>
                {Fcontext.NewFolderR && <>
                    <button style={{ fontSize: '12px' }} className={" btn pro-spaces-button3  px-2 me-1"} onClick={(e) => Fcontext.Save()} >Save</button>
                    <button style={{ fontSize: '12px' }} className={" btn pro-spaces-button3 px-2"} onClick={(e) => Fcontext.setNewFolder(false)}>Cancel</button></>
                }<>
                    <button style={{ fontSize: '12px' }} className={props.Mark ? "btn px-2 btn-outline-danger me-1" : 'btn invisible'}>Delete</button>
                    <button style={{ fontSize: '12px' }} className={props.Mark ? " btn pro-spaces-button3 px-2" : 'btn invisible'} onClick={(e) => Fcontext.setMark(false)}>Cancel</button>
                </>
            </div>
        </div>
    </div>

}

export const InitialMain = (props) => {
    let Fcontext = useContext(FolderContext)

    const Rename = (e) => {
        console.log(e)
        try {
            let element = document.getElementById(e)
            element.setAttribute('contenteditable', true)
            element.focus()
        } catch (error) {
            console.log(error)
        }
    }

    const goRename = async (e, folder) => {

        try {
            // if (ev && ev.keyCode != 13) {
            //     return
            // }
            let Folder = folder
            let element = document.getElementById(e)
            element.setAttribute('contenteditable', false)
            let name = element.innerHTML
            if (name.length === 0) {
                name = 'Untitled'
            }
            let data = {
                Folder, name
            }
            let result = await axios.post('/api/User/Cubicle/RenameFolder', { data })

        } catch (error) {
            console.log(error)
        }

    }

    return <>{
        Fcontext.Folders.length > 0 ? Fcontext.Folders.map((dt => {
            let FolderNameR = keyGen()

            return <div className={props.Mark ? "Folder border dropdown" : "Folder  dropdown"}>
                <input className={!props.Mark ? "invisible" : ''} type={'checkbox'}></input>
                {Fcontext.Rdetails.admin && <MdOutlineKeyboardArrowDown color="black" className="float-start pTF" id="FolderDrop" data-bs-toggle="dropdown" aria-expanded="false"></MdOutlineKeyboardArrowDown>}
                <div>
                    <div className="d-flex justify-content-center m-0">
                        <AiFillFolder color="#fdb900" onClick={(e) => Fcontext.OpenFolder(dt._id)} size={45} />
                    </div>
                    <p id={FolderNameR} onDoubleClick={(e) => Rename(e.target.id)} onBlur={(e) => goRename(FolderNameR, dt._id)} className="folderName p-0"> {dt.FolderName} </p>
                </div>
                <ul className="dropdown-menu fDoptionsmenu" aria-labelledby="dropdownMenuLink">
                    <li><a className="dropdown-item fDoptions" onClick={(e) => Rename(FolderNameR)} ><HiPencil style={{ 'marginTop': '-3px' }} />Rename</a></li>
                    <li><a className="dropdown-item fDoptions" onClick={(e) => Fcontext.DeleteThisFolder(dt._id)} ><AiFillDelete style={{ 'marginTop': '-3px' }} />Delete</a></li>
                </ul>
            </div>
        })) : ''
    }
        {/* const folder  */}

        <div className="Folder border dropdown invisible" >
            <input type={'checkbox'}></input>
            <MdOutlineKeyboardArrowDown className="float-start pTF" id="FolderDrop" data-bs-toggle="dropdown" aria-expanded="false"></MdOutlineKeyboardArrowDown>
            <div>
                <div className="d-flex justify-content-center m-0">
                    <AiFillFolder size={45} />
                </div>
                <p className="folderName p-0"> Foldwqeeeeeeeeeeer 1 </p>
            </div>
            <ul className="dropdown-menu fDoptionsmenu" aria-labelledby="dropdownMenuLink">
                <li><a className="dropdown-item fDoptions" ><HiPencil style={{ color: 'var(--blue)' }} />Rename</a></li>
                <li><a className="dropdown-item fDoptions "><AiFillDelete style={{ color: 'var(--blue)' }} />Delete</a></li>
            </ul>
        </div>
    </>
}

