import React, { useState, useEffect } from "react";

import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./ProductSubCategory.css";

import { BsQuestionCircle } from "react-icons/bs";

import { useSearchParams } from "react-router-dom";

import { SubPro } from "./SubPro";
import { Mask } from "../../Utility/mask";

export function ProductSubSubCategory() {
  const [data, setdata] = useState(false);
  const [cat, setCat] = useState([]);
  const [Follower, setFollower] = useState();
  const [Foll, setFoll] = useState();
  const [load, setload] = useState(true);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState("");
  let [searchParams, setSearchParams] = useSearchParams(); /////getting id from the url the id of the jobs id
  var cate = searchParams.get("sub_category");
  var id = Mask.decode(searchParams.get("brand_id"));

  useEffect(() => {
    /////getting id from the url the id of the jobs id
    //  console.log(id)
    axios
      .post("/api/Free/subsubcategorypro", { id })
      .then((res) => {
        console.log(res.data);
        const postSummaries = res.data.map((post) => ({
          id: post._id,
          key: post.id,
          brand: post.brand,
          origin: post.origin,
          cons: post.cons.length,
        }));
        setdata(postSummaries);
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });
    //    id=JSON.parse(id)
    //        setCat(id);
    //       console.log(id)
    //        const postSummaries = id.map((post) => ({ id: post._id, key: post.id, sub: post.sub,}));
    //        setdata(postSummaries)
    //        setload(false)
  }, []);

  function makeid(length) {
    length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const liquermodal = () => {
    document.getElementById("subsubmodalprochuresabaribtn").click();
  };

  const [Available, setAvailable] = useState([]);

  useEffect(() => {
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {" "}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data) {
        let temp = "";

        setoutput(
          data.map((number) => {
            if (number.cons > 0) {
              let ind = 1;
              if (temp === number.brand.slice(0, 1)) {
                ind = 1;
              } else {
                setAvailable((prev) => [...prev, number.brand.slice(0, 1)]);
                temp = number.brand.slice(0, 1);
                ind = 0;
              }
              return (
                <SubPro
                  key={number.id}
                  index={ind}
                  ids={makeid()}
                  data={number}
                ></SubPro>
              );
            }
          })
        );
      } else {
        setoutput(error);
      }
    }
  }, [error, load]);

  let alpha = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  return (
    <div className="container-fluid wieugfiqgefnqenc2rnewiuhgiuhq">
      <div>
        <h5 className="parapara text-center">
          BRAND CATEGORISATION &nbsp;
          <BsQuestionCircle
            style={{ color: "red", marginTop: "-5px" }}
            onClick={liquermodal}
          />
        </h5>
        <p className="text-center">{cate}</p>
        <div className="container ">
          <p className="text-lg-center text-md-start">
            Brands with exclusive categorisation are only national and
            international brands which are popular pan India.
          </p>
        </div>
      </div>

      <center>
        <div className="p-2">
          <div className="AlphaNav border grid-containerX p-1 m-0">
            {/* <li className="item1" ></li> */}
            {alpha.map((elem, index) => {
              return (
                <a
                  style={{ textTransform: "capitalize" }}
                  href={`#Cat-` + elem.toUpperCase() + "0"}
                >
                  {elem}
                </a>
              );
            })}
          </div>
        </div>
      </center>

      <div className={`row ${load === false ? "" : "notranslate"}`}>
        {output}
      </div>
    </div>
  );
}
