import React, { useState, useEffect, createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./CreateConseltent.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FormConsultent as FormConsultentForPrime } from "../../CreateConseltent/FormConsultent";
import { FormConsultent as FormConsultentForBasic } from "../../CreateConseltentV2/FormConsultent";

export const ConsContext = createContext();

export function CreateConseltent() {
  let navigate = useNavigate();
  const [converano, setConverano] = useState(false);

  const submit = async (formData) => {
    return new Promise((resolve, reject) => {
      // console.log('submited')
      // console.log(formData)
      axios
        .post("/api/User/newConsultant", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status == 200) {
            console.log("product Created");
            // console.log(res.data)
            return navigate("/");
          }
          resolve(true);
        })
        .catch((err) => {
          if (err.response.status == 304) {
            console.log(err.response.data);
            // toast warning
            navigate("/");
          } else {
            console.log(err.response);
          }
          resolve(false);
        });
    });
  };

  useEffect(() => {
    console.log(localStorage.getItem("Type"));
    console.log(localStorage.getItem("Cat"));
  }, []);

  return (
    <>
      <div className="container rounded bg-white pt-5 pb-0">
        {localStorage.getItem("Type") === "Product" ||
        localStorage.getItem("Type") === "Consultant" ? (
          <FormConsultentForPrime postTo={submit} sab={false} />
        ) : localStorage.getItem("Type") === "Productv2" ||
          localStorage.getItem("Type") === "Consultantv2" ? (
          <FormConsultentForBasic postTo={submit} sab={false} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
