import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './Feedback.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
export function Feedback() {
  const notify = (message) =>
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [feedback, setFeedback] = useState({ Message: '', Option: '' });
  const [Submit, setSubmit] = useState(false);

  const submit = (e) => {
    console.log(feedback);
    if (!feedback.Option) {
      return setError1('PLEASE SELECT a Subject');
    }

    if (!feedback.Message) {
      var error = '';
      if (feedback.Option == 'INVOICE RELATED') {
        error = 'INVOICE RELATED';
      } else if (feedback.Option == 'QUERY') {
        error = 'QUERY';
      } else if (feedback.Option == 'SUGGESTION') {
        error = 'SUGGESTION';
      } else {
        error = 'GRIEVANCE';
      }

      return setError1(
        'PLEASE ENTER ' + error + ' QUERIES / SUGGESTIONS / GRIEVANCES'
      );
    }
    setError('');
    // e.preventDefault();
    // return
    if (feedback.Message != '') {
      axios
        .post('/api/Free/feedback', { feedback })
        .then((res) => {
          console.log(res);
          notify('Sent Successfully');
          setFeedback({ Message: '', Option: '' });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    {
      // notify("Please write your valuable suggestions ");
    }

    var status = false;
    setSubmit(true);
  };
  const [catsug, setCatsug] = useState({
    Category: '',
    SubCategory: '',
    Type: '',
  });
  const [Error, setError] = useState('');
  const [Error1, setError1] = useState('');

  const submit2 = (e) => {
    console.log(catsug);
    if (!catsug.Type.length > 0) {
      return setError('PLEASE SELECT Business classification');
    }
    if (catsug.Category.length == 0) {
      return setError('PLEASE ENTER Category name of your choice');
    }
    if (catsug.SubCategory.length == 0) {
      return setError('PLEASE ENTER Sub category name of your choice');
    }
    setError('');
    axios
      .post('/api/Free/SuggestCatAndSubCat', { catsug })
      .then((res) => {
        notify('Sent Successfully');
        setCatsug({
          Category: '',
          SubCategory: '',
          Type: '',
        });
      })
      .then((error) => {
        console.log(error);
      });
    e.preventDefault();
    console.log(catsug);
  };
  return (
    <div
      className="row ms-1 me-1 d-flex justify-content-center dkhgsusrghoiusrhgosrio"
      style={{ paddingTop: '70px' }}
    >
      <div className="d-none d-xxl-block invisible">
        <p>1</p>
        <p>2</p>
      </div>
      <div className="col-md-6  col-sm-12">
        <p className="wgfiuwrgiuwdjbcb">
          <div
            className="card carrrrbuss "
            style={{
              paddingBottom: '10px',
              paddingTop: '20px',
              marginTop: '15px',
            }}
          >
            <div className="padunnor">
              <div className="text-center mb-3">
                <h4>Is your BUSINESS (Service / Product) CATEGORY MISSING ?</h4>
                <h6 style={{ fontWeight: '400' }}>
                  Then Choose your Business classification, Type your Category,
                  Sub Category and Send it to us.
                </h6>
              </div>
              <>
                <label>Business classification *</label>
                <select
                  className="textareafeed d-flex justify-content-center form-select "
                  value={catsug.Type}
                  onChange={(e) =>
                    setCatsug({ ...catsug, Type: e.target.value })
                  }
                >
                  <option></option>
                  <option value="Service">Services</option>
                  <option value="Products">Products</option>
                </select>
                <label>Category *</label>
                <input
                  className="textareafeed d-flex justify-content-center form-control "
                  value={catsug.Category}
                  type="text"
                  maxLength={100}
                  onChange={(e) =>
                    setCatsug({ ...catsug, Category: e.target.value })
                  }
                />
                <label>Sub category *</label>
                <input
                  className="textareafeed d-flex justify-content-center form-control "
                  value={catsug.SubCategory}
                  type="text"
                  maxLength={100}
                  onChange={(e) =>
                    setCatsug({ ...catsug, SubCategory: e.target.value })
                  }
                />
              </>
            </div>
            <p className="text-danger m-0">{Error ? Error : ''}</p>
            <center>
              {' '}
              <button
                className="btn btncolj w-50 mt-3 mb-3"
                onClick={(e) => submit2(e)}
              >
                Send
              </button>
            </center>
          </div>
        </p>
      </div>

      <div className="col-md-6  col-sm-12">
        <p className="wgfiuwrgiuwdjbcb">
          <div
            className="card carrrrbuss "
            style={{
              paddingBottom: '10px',
              paddingTop: '20px',
              marginTop: '15px',
            }}
          >
            <div className="padunnor">
              <div className="text-center mb-3">
                <h4>QUERIES / SUGGESTIONS / GRIEVANCES</h4>
              </div>
              <div className="d-flex justify-content-end ">
                <Link to={'/ask_us'}>
                  <button className="btn pro-spaces-button3">
                    VIEW&nbsp;PREVIOUS&nbsp;MESSAGES
                  </button>
                </Link>
              </div>
              <label className="d-none"> *</label>
              <div className="d-block d-md-none mt-3 mb-3">
                <select
                  className="d-flex textareafeed justify-content-center form-select"
                  value={feedback.Option}
                  onChange={(e) =>
                    setFeedback({ ...feedback, Option: e.target.value })
                  }
                >
                  <option value={null}>Select a Subject</option>
                  <option value="INVOICE RELATED">INVOICE RELATED</option>
                  <option value="QUERY">QUERY</option>
                  <option value="SUGGESTION">SUGGESTION</option>
                  <option value="GRIEVANCE">GRIEVANCE</option>
                </select>
                <textarea
                  className="textareafeed d-flex justify-content-center form-control mt-3"
                  type="textarea"
                  placeholder=""
                  rows="5"
                  value={feedback.Message}
                  onChange={(e) =>
                    setFeedback({ ...feedback, Message: e.target.value })
                  }
                ></textarea>
              </div>
              <div className="d-none d-md-block d-lg-none mt-3 mb-3">
                <select
                  className="d-flex textareafeed justify-content-center form-select"
                  value={feedback.Option}
                  onChange={(e) =>
                    setFeedback({ ...feedback, Option: e.target.value })
                  }
                >
                  <option value={null}>Select a Subject</option>
                  <option value="INVOICE RELATED">INVOICE RELATED</option>
                  <option value="QUERY">QUERY</option>
                  <option value="SUGGESTION">SUGGESTION</option>
                  <option value="GRIEVANCE">GRIEVANCE</option>
                </select>
                <textarea
                  className="textareafeed d-flex justify-content-center form-control mt-3"
                  type="textarea"
                  placeholder=""
                  rows="10"
                  value={feedback.Message}
                  onChange={(e) =>
                    setFeedback({ ...feedback, Message: e.target.value })
                  }
                ></textarea>
              </div>
              <div className="d-none d-lg-block">
                <select
                  className="d-flex textareafeed justify-content-center form-select mt-3"
                  value={feedback.Option}
                  onChange={(e) =>
                    setFeedback({ ...feedback, Option: e.target.value })
                  }
                >
                  <option value={null}>Select a Subject</option>
                  <option value="INVOICE RELATED">INVOICE RELATED</option>
                  <option value="QUERY">QUERY</option>
                  <option value="SUGGESTION">SUGGESTION</option>
                  <option value="GRIEVANCE">GRIEVANCE</option>
                </select>
                <textarea
                  className="textareafeed d-flex justify-content-center form-control mt-3"
                  type="textarea"
                  placeholder=""
                  rows="9"
                  value={feedback.Message}
                  onChange={(e) =>
                    setFeedback({ ...feedback, Message: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
            <p className="text-danger m-0">{Error1 ? Error1 : ''}</p>
            <center>
              {' '}
              <button
                className="btn btncolj w-50 mt-3 mb-3"
                onClick={(e) => submit()}
              >
                Send
              </button>
            </center>
          </div>
        </p>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
