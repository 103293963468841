import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { ThreeDotOption } from "./ThreeDotOption";
import { Link } from "react-router-dom";
import { Likenew } from "./Likenew";

export function PostSingle(props) {
  const [first, setfirst] = useState(false);
  // const [sen, setsen] = useState();
  useEffect(() => {
    props.Auth();
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();
  var Pid = searchParams.get("post_id");

  useEffect(() => {
    axios
      .post("/api/User/postSing", {
        Pid,
      })
      .then((res) => {
        if (res.data.length != 0) {
          // console.log(res.data[0]);
          res.data[0].Author = res.data[0].Account_id;
          res.data[0].id = res.data[0]._id;

          setfirst(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  var readToogle = {
    display: "none",
  };

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  function readToggle(e) {
    // console.log(e.target.id);
    var dots = document.getElementById(e.target.id + "secondary");
    var moreText = document.getElementById(e.target.id + "primary");
    var btnText = document.getElementById(e.target.id);

    // console.log(dots + moreText + btnText);

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }
  // console.log(first);
  return (
    <div className="container-fluid  dknlkenven">
      {first ? (
        <div className=" post mb-2 ">
          <div className="postHeader p-1">
            <div className="headerinfo tab mx-0 ">
              <div className=" text-start pThumbcontainer  cell px-0 cot ">
                {/* post author profile picture starts */}
                <img alt="" className="thumbnailpost " src={first.profilepic} />
                {/* post author profile picture ends */}
              </div>
              <div id="postinfo" className="postinfo px-0 ms-1 cell">
                {/* post author name  starts*/}
                {first.string[0].Type === "Consultant" ? (
                  <Link
                    to={"/services_profile?Ac_id=" + first.Author}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <h6 id="authorName" className="Textuppercase pt-1 m-0">
                      {first.string[0].pname}
                    </h6>
                  </Link>
                ) : first.string[0].Type === "Product" ? (
                  <Link
                    to={"/products_profile?Ac_id=" + first.Author}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <h6 id="authorName" className="Textuppercase pt-1 m-0">
                      {first.string[0].pname}
                    </h6>
                  </Link>
                ) : first.string[0].Type === "sponsored" ? (
                  <h6 id="authorName" className="Textuppercase pt-3 m-0">
                    {first.string[0].pname}
                  </h6>
                ) : (
                  <Link
                    to={"/profile_pro?Ac_id=" + first.Author}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <h6 id="authorName" className="Textuppercase pt-3 m-0">
                      {first.string[0].pname}
                    </h6>
                  </Link>
                )}

                {/* post author name  ends*/}

                <p>
                  {first.Posted_On.slice(0, 10)}
                  <i className="fa fa-globe" aria-hidden="true"></i>
                </p>
              </div>

              {/* post 3dot option starts*/}

              {first ? <ThreeDotOption dat={first}></ThreeDotOption> : ""}
              {/* post 3dot option ends*/}
            </div>

            {/* post body starts */}
            {first.Content != "undefined" ? (
              first.Content.length < 360 ? (
                <div className="headerContent mt-1">{first.Content}</div>
              ) : (
                <div className="headerContent mt-1">
                  {first.Content.slice(0, 360)}
                  <span id={first.id + "secondary"}>...</span>
                  <span id={first.id + "primary"} style={readToogle}>
                    {first.Content.slice(360 - first.Content.length)}
                  </span>
                  <b
                    className="text-primary"
                    id={first.id}
                    onClick={(e) => readToggle(e)}
                  >
                    Read more
                  </b>
                </div>
              )
            ) : (
              ""
            )}
            {/* post body ends */}

            {/* post image / video starts */}
            <div className="postContent container-fluid m-0">
              {/* {first.File.map((e)=>{

                    return<p>{e}</p>
                })} */}
              <div
                id={"poda" + first.id}
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {first.File.map((element, index) => {
                    {
                      /* <p>{element}</p> */
                    }
                    if (index === 0) {
                      if ("image" === element.filetype.slice(0, 5)) {
                        return (
                          <div
                            key={keyGen()}
                            className="carousel-item active afhouweh brokendrtff"
                          >
                            <img
                              alt=""
                              className="carItemmm"
                              src={element.filename}
                            />

                            {/* <img src="..." className="d-block w-100" alt="..." /> */}
                          </div>
                        );
                      } else if ("video" === element.filetype.slice(0, 5)) {
                        return (
                          <div
                            key={keyGen()}
                            className="carousel-item afhouweh active brokendrtff"
                          >
                            <video
                              className="carItemmm"
                              controls
                              controlsList="nodownload"
                            >
                              {" "}
                              <source src={element.filename} />{" "}
                            </video>
                          </div>
                        );
                      }
                    } else {
                      if ("image" === element.filetype.slice(0, 5)) {
                        return (
                          <div
                            key={keyGen()}
                            className="carousel-item  afhouweh brokendrtff"
                          >
                            <img
                              alt=""
                              className="carItemmm"
                              src={element.filename}
                            />
                            {/* <img alt="" className="carItemmm" src=" https://my-test-eg.s3.ap-south-1.amazonaws.com/post/624be1106f4b4e1899a25e56/sabari_v_r_02017-11-09-888.jpg" /> */}

                            {/* <img src="..." className="d-block w-100" alt="..." /> */}
                          </div>
                        );
                      } else if ("video" === element.filetype.slice(0, 5)) {
                        //
                        return (
                          <div
                            key={keyGen()}
                            className="carousel-item afhouweh brokendrtff"
                          >
                            <video
                              className="carItemmm"
                              controls
                              controlsList="nodownload"
                            >
                              {" "}
                              <source src={element.filename} />{" "}
                            </video>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
                {first.File.length > 0 ? (
                  <>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target={"#poda" + first.id}
                      data-bs-slide="prev"
                      style={{ height: "50px", marginTop: "20%" }}
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target={"#poda" + first.id}
                      data-bs-slide="next"
                      style={{ height: "50px", marginTop: "20%" }}
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {/* post image / video ends */}

            {/* post comment like count starts */}
            {/* <div className="postinfobottom row my-2  px-3">
                <div className="col-6">   {first.Likecount} Likes <i className="fa fa-star" aria-hidden="true"></i></div>
                <div className="col-6 text-end"> {first.Commentcount} Comments</div>
            </div> */}
            {/* post comment like count ends */}

            {/* <hr className="m-0 my-1 hrline" /> */}

            {/* post like,share button starts */}

            {/* <LikeAndComment dat={first}></LikeAndComment> */}

            <Likenew dat={first} />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
