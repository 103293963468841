import React, { useState, useEffect } from "react";
import './post.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Link } from "react-router-dom";
import { MdFlag } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
// import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";

 export function ThreeDotOption(props) {
    //  console.log(props)
// return
//   useEffect(() => {
//     // setuserRole('Active-user')
//     likeOrNot()
//     booler = false
// }, []);

const [Follower, setFollower] = useState(false)

// const [Loader, setLoader] = useState(<div className="text-center "> <div className="spinner-border text-dark"></div></div>)
useEffect(() => {
  // setuserRole('Active-user')
  FollowOrNot(props.dat.Author)
  
}, []);

useEffect(() => {
  // setuserRole('Active-user')
  reportOrNot(props.dat.id)
}, []);


const FollowOrNot = (followme) => {
  try {
      axios.post("/api/User/followornot", { followme }).then((response) => {
        //   console.log('success')
          if (response.status === 200) {
            //   console.log('first')
              setFollower(true)
          }
      }).catch(
          res => {
            //   console.log('Something went wrong')
          }
      )
  }
  catch (er) {
  }
}

const follow = (e, id) => {
  var followme = id
  axios.post("/api/User/follow", { followme }).then((response) => {
    //   console.log('success')
      if (response.status === 200) {
          setFollower(true)
      }
  }).catch(
      res => {

          console.log('Something went wrong')
      }
  )
}

const unfollow = (e, id) => {
  var followme = id
  axios.post("/api/User/unfollow", { followme }).then((response) => {
      console.log('success')
      if (response.status === 200) {
          setFollower(false)
      }
  }).catch(
      res => {
          console.log('Something went wrong')
      }
  )
}
const [reported, setreported] = useState(false)

const report = (e, id) => {
  var report = id
  axios.post("/api/User/report", { report }).then((response) => {
      // console.log('success')
      if (response.status === 200) {
        //   console.log("reported11")
          setreported(true)
      }
  }).catch(
      res => {
          console.log('Something went wrong')
      }
  )
}
const reportOrNot = (postid) => {
  try {
      axios.post("/api/User/reportornot", { postid }).then((response) => {
        //   console.log('success')
          if (response.status === 200) {
            //   console.log(response)
              if(response.data===''){
                  setreported(false)

              }
              else{
                  setreported(true)

              }
              
          }
      }).catch(
          res => {
              console.log('Something went wrong')
          }
      )
  }
  catch (er) {
  }
}

const Delete = (e, id) => {
  var id = id
  axios.post("/api/User/ProPostDelete", { id }).then((response) => {
      // console.log('success')
      if (response.status === 200) {
        //   console.log("reported11")
          // setreported(true)
          window.location.reload();
      }
  }).catch(
      res => {
          console.log('Something went wrong')
      }
  )
}

  return (
    <div className="cell dropstart float-end pt-2 mt-1">
    <a type="button" href="/" className="dotoptionbtn   cell" data-bs-toggle="dropdown" aria-expanded="false">
        <BsThreeDotsVertical size={25} style={{color:'black'}}/>
    </a>

    <ul className="dropdown-menu ">
        {/* {Follower ?
            <li onClick={(e) => unfollow(e, props.dat.Author)}><a className="dropdown-item" > Unfollow</a></li>

            :
            <li onClick={(e) => follow(e, props.dat.Author)}><a className="dropdown-item" > Follow</a></li>

        } */}
         <li>
         <Link to={"/posttoolbarview_vendor?Ac_id=" + props.dat.id} className="dropdown-item">Edit post</Link>
         </li>
         <li data-bs-toggle="modal" data-bs-target={"#exampleModa"+props.dat.id}>
         <a className="dropdown-item" > Delete</a>
         </li>
        {/* <li><a className="dropdown-item" href="/"><BsFillSave2Fill /> Save</a></li>
        <li><a className="dropdown-item" href="/"><MdLink /> Copy link</a></li> */}

         {/* { !reported
             ?
             <li data-bs-toggle="modal" data-bs-target={"#exampleModa"+props.dat.id} ><a className="dropdown-item" > Report Post</a></li>
             :   <li >&nbsp; <MdFlag /> Reported</li>
         } */}
     



    </ul >
    <div className="modal fade" id={"exampleModa"+props.dat.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
    
      <div className="modal-body">
      
        <div className="d-flex justify-content-end">
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
       <center> <h3>Are you sure ?</h3   ></center>
      </div>
     
      <div className="row">
          <div className="col-6"></div>
          <div className="col-6 d-flex justify-content-end">
          <button type="button" className="btn pro-spaces-button3 me-1 mb-1" onClick={(e) => Delete(e, props.dat.id)} data-bs-dismiss="modal" aria-label="Close">Delete</button>
          </div>
      </div>
      
    </div>
  </div>
</div>



</div>
  )
}

export default ThreeDotOption