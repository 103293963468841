import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./ProductMainCategory.css";
import { BsInfoCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Mask } from "../../Utility/mask";

export function Proin(props) {
  const [Count, setCount] = useState("");
  useEffect(() => {
    console.log(props.data.sub);
    let i = 0;
    props.data.sub.map((elem, index) => {
      if (elem.userData.length > 0) {
        i++;
      }
      setCount(i);
    });
  }, []);
  const liquermodal = () => {
    document.getElementById("liqquermodalprochuresabaribtn").click();
  };

  return (
    <div
      id={`Cat-` + props.data.category.slice(0, 1) + props.index}
      className="col-12 col-md-6 col-lg-6  col-xl-4 col-xxl-4 mb-3"
    >
      <Link
        to={`/lobby/products_sub_categories?_id=${JSON.stringify(
          Mask.encode(props.data.id)
        )}&&category=${props.data.category}`}
        className="linkoflinkk"
      >
        <div className="gtyurie p-3">
          {/* <div className="row">
                <div className="col-12">
                {Follower ? 
              <button
                type="button"
                style={{"fontSize":"10px"}}
                className="float-end btnbhg btn px-3  "
                onClick={(e) => follow(e, props.data.id)}
              >
                Follow
              </button>
             : 
              <button
                type="button"
                style={{"fontSize":"10px"}}
                className="float-end btnbhg btn px-3  "
                onClick={(e) => unfollow(e, props.data.id)}
              >
                UnFollow
              </button>
            }
                </div>
              </div> */}

          <div>
            <div className="row">
              <div className="col-4"></div>
              {/* <div className="col-4 centerwvnwrhnvoiwqn">
                <AiFillMedicineBox size="40" />{" "}
                </div> */}
              <div className="col-4"></div>
            </div>
            <div className="d-flex align-items-center">
              <div className="ml-3 w-100">
                <h4 className="mb-2 mt-0 pt-3 pb-3 center">
                  {props.data.category}
                </h4>{" "}
                <div className="row">
                  <div className="col-7">Sub Categories</div>
                  <div className="col-2">:</div>
                  <div className="col-3">{Count}</div>
                </div>
                <div className="row">
                  <div className="col-7">Members</div>
                  <div className="col-2">:</div>
                  <div className="col-3">{props?.data?.con}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
      {props.data.id === "63077a009b4ba432c41ee624" ? (
        <div style={{ position: "relative", right: "30px", top: "-105px" }}>
          <BsInfoCircle
            style={{ color: "red" }}
            className="float-end"
            onClick={liquermodal}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
