
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './JobpostedView.css'
import { BsFillBriefcaseFill, BsFillPeopleFill,BsThreeDotsVertical } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { MdLocationPin, MdSettingsAccessibility } from 'react-icons/md';
import Axios from 'axios';
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { SpinnerSmall } from "../../Utility/spinner";

export const JobPostedView=(props)=> {

    useEffect(() => {
        props.Auth()
        }, [])

        let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id

        const notify = (message, stat) => {      
            if (stat == true) {
              // console.log("toasfted");
              toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (stat == false) {
              toast.error(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          };
        var tosttype=0

        tosttype = searchParams.get("tosttype")
        let[count1,setcount1]=useState(0)
       
    


        useEffect(() => {
            
      
            console.log(tosttype)
            if(count1==0)
            {
                if(tosttype==1)
                {
                  notify("Posted successfully");
                  count1++
            
                 
                }
            
            
                else if(tosttype==2)
                {
                    console.log("Call me123")
                    notify("Posted updated successfully");
                    count1++
                }
    
            }
    
          
    
        
         
        }, [])
  
      const [Loader, setLoader] = useState(<SpinnerSmall></SpinnerSmall>)



function load ()
{
    Axios.get('/api/User/JobVaccencyListMy')
    .then(res => {
        
        if (res.status == 200) {
            // console.log('jobView')
            console.log(res.data)
            // console.log(Id)

          var  jobDetails = res?.data?.map((myList,index) => {
            var age = Math.floor(
                parseInt(new Date() - new Date(myList.JobSeeker_dob)) / 1000 / 60 / 60 / 24 / 365
            );

            var a=myList.JobRecruit_Malevacancies+myList.JobRecruit_Femalevacancies+myList.JobRecruit_Othervacancies+myList.JobRecruit_Anyvacancies
            console.log(a)
                return   (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                    <div className="card carded shadow-sm">
                        <div className="card-body Textuppercase">
                        <div className="d-flex justify-content-end">
                                    <div className="dropstart">
                                        <BsThreeDotsVertical className="dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <Link style={{ 'textDecoration': 'none' }} to={`/view_applicants?k=${myList._id}`}>
                                                <li><div className="dropdown-item" >View Applicants</div></li>
                                            </Link>
                                            <Link style={{ 'textDecoration': 'none' }} to={`/job_vacancy_post_list_edit?k=${myList._id}`}>
                                                <li><div className="dropdown-item" >Edit</div></li>
                                            </Link>

                                            <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target={"#exampleModal"+index} >Delete</a></li>
                                        </ul>
                                    </div>
                                </div> 
                            <div className="clearfix mb-3">
                                {" "}
                   
                                {/* <h4 style={{
                                    'fontWeight': 700,

                                }} className='text-center  '>{myList.JobRecruit_jobTitles?myList.JobRecruit_jobTitles[0]:""}</h4>
                                <h4 style={{
                                    'fontWeight': 600,
                                }} className="text-center "> {myList.subcategory?myList.subcategory[0].sub:""}| {myList.category[0]?myList.category[0].category:""}</h4>
                              */}
                            </div>
                            <div className="row">
                                <div className="entityJobs  col-12">
                                    <b>Job Category </b>
                                    <p className="ms-2 JobVal ">{myList.category[0]?myList.category[0].category:""}</p>
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Job Sub Category </b>
                                    <p className="ms-2 JobVal">{myList.subcategory?myList.subcategory[0]?.sub:""}</p>
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Job Title </b>
                                    <p className="ms-2 JobVal">{myList.JobRecruit_jobTitles?myList.JobRecruit_jobTitles[0]:""}</p>
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Job Location (state) </b>
                                    <p className="ms-2 JobVal">{myList.JobRecruit_companyState?myList.JobRecruit_companyState:""}</p>
                                </div>
                                <div className="entityJobs col-12">
                                    <b>Job Location (city) </b>
                                    <p className="ms-2 JobVal">{myList.JobRecruit_companyCity?myList.JobRecruit_companyCity:""}</p>
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Work Mode</b>
                                    <p className="ms-2 JobVal">{myList.JobRecruit_workPlace}</p>
                                </div>
                                <div className="col-1"></div>

                                <div className="entityJobs col-12">
                                    <b>Employment Type </b>
                                    <p className="ms-2 JobVal">{myList.JobRecruit_empType}</p>
                                </div>

                                <div className="entityJobs col-12    ">
                                    <b>Experience Level </b>
                                    <div className="row">
                                    <p className=" JobVal"> {myList.JobRecruit_expLvl?myList.JobRecruit_expLvl                                 
                                            .map((e) => {

                                                return <span className='col-auto ms-2'>{e}</span>

                                            }) : 'Some thing went wrong!!!'}
                                                                 </p>

                                    </div>
                                    
                                </div>

                                <div className="entityJobs col-12">
                                    <b>Salary Offered in LPA (Lakh Per Annum)</b>

                    
                                    <p className="ms-2 JobVal">{myList.JobRecruit_minPay?myList.JobRecruit_minPay+" - "+myList.JobRecruit_maxPay:""}&nbsp;</p>
                                </div>

                                {
                                    myList.JobRecruit_differentlyAbled==true||myList.JobRecruit_differentlyAbled=="true"?
                                    <div className="col-12">
                                        <p className="ms-2 JobVal">We are open to employing differently-abled candidates</p>
                                    </div>
                                    :""
                                }

                                <div className="entityJobs col-12">
                                    <b>Educational Qualification </b>
                                    <p className="ms-2 JobVal">{myList.JobRecruit_Qualification?myList.JobRecruit_Qualification:""}&nbsp;</p>
                                </div>


                                <div className="entityJobs col-12">
                                    <b>Recruitment time</b>
                                    {
                                    myList.JobRecruit_Immediaterecruitment==true||myList.JobRecruit_Immediaterecruitment=="true"?
                                    <p className="ms-2 JobVal">Immediate  </p>
                                    :

                                    <p className="ms-2 JobVal">After {myList.JobRecruit_AfterDays} days </p>
                                    

                                }
                                    
                                </div>
                            </div>


                            <div className="mb-2">
                                <div className='text-center mt-1'>
                                    <Link to={`/job_vacancy_view?k=${myList._id}`} className="btn btn-war" >VIEW JOB PROFILE</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal fade" id={"exampleModal"+index} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                 <div class="modal-content">
                 <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Deleting Post</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id={"close"+index}></button>
                  </div>
                <div class="modal-body">
                   <h6><>Are you sure ?</></h6>
              </div>
         <div class="modal-footer">
   <button type="button" class="btn pro-spaces-button" data-bs-dismiss="modal">Close</button>
   <button type="button" class="btn pro-spaces-button3" onClick={(e)=>delete1(myList._id,index)}>Delete</button>
       </div>
        </div>
       </div>
         </div>

                </div >

                );
            });
            setLoding(false)
            setContent(jobDetails)
            // console.log(jobDetails)
       
    }
}).catch((err) => {
    setLoding(false);
    setError('Something went wrong!')
        console.log(err)
    
       })
}

const delete1 = async (Id,index)=>{
    console.log(Id)
    Axios.post('/api/User/DeletePostRequiter', {Id})
    .then(res => {
        if (res.status == 200) {
            console.log('Post Delete')
            console.log(res.data)
             load()
         document.getElementById("close"+index).click()
         notify("Post deleted",true)
            
        }
    }).catch((err) => {
        console.log(err.response.data)
        notify("Something went wrong",false)


    })
    

}


    const [Loding, setLoding] = useState(true)
    const [Error, setError] = useState(false)
    const [Content, setContent] = useState(false)
    useEffect(() => {
        load()
      
    }, [])
    
  
    return (
        <div>
{Loding?Loader:""}
            <div className="container-fluid bg-trasparent my-4 p-3 ">
            {Content.length==0?<center className="mt-5"><h3>Nothing to show</h3></center>:""}
                <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 car"> 

                {Loding?Loader:Content} 
                </div>  </div>
              
                <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
          

        </div>




    
  )
}

// export default JobPostedView
