// import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useRef, useState } from "react";
import './recruitPostForm.css'
import Axios from 'axios'
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { print_city, print_state, } from '../../Utility/stateCity';
import { keyGen } from '../../Utility/helper';
import { AllCity, ExistingData, InitializeCountry } from '../../Utility/Country';
import { useSelector } from 'react-redux';


export const Insider = (props) => {
    let navigate = useNavigate()

    const notify = (message, stat) => {      
        if (stat == true) {
          // console.log("toasfted");
          toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (stat == false) {
          toast.error(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
    

    const [IndexX, setIndexX] = useState(0)
    const [Screenquestions, setScreenquestions] = useState([])
    const [Q, setQ] = useState([])
    const [Qall, setQall] = useState([])
    const [flag, setflag] = useState(false)
    const [cat, setCat] = useState([])
    const [category, setCategory] = useState(false)
    const [subcategory, setSubcategory] = useState(false)
    var listItems = ''


    const makeSkill = (x, Lorum) => {
        x = document.getElementById(x)

        var val = x.value
        if (val.charAt(val.length - 1) === ' ') {
            var skill = val.trim()
            skill = skill.replace(/ +/g, "");

            if (skill.length >= 3) {
                x.value = ''
                x.style.color = 'black'
                addSkill(skill)

            }
            else {
                x.style.color = 'red'
            }
        }
        if (Lorum) {
            var skill = val.trim()
            skill = skill.replace(/ +/g, "");

            if (skill.length >= 3) {
                x.value = ''
                x.style.color = 'black'
                addSkill(skill)
            }
            else {
                x.style.color = 'red'
            }
        }
    }
    const sabari = (e) => {
        var anadhan
        setQ1({ ...Q1, mainCat: e.target.value })
        cat.forEach(element => {
            if (element._id === e.target.value) {
                anadhan = element.sub.map((elements) => {
                    return <option value={elements._id}>{elements.sub}</option>
                })
            } else { }
        });
        setSubcategory(anadhan)
    }
    useEffect(() => {
        Axios.get('/api/api/getcategory').then(res => {
            if (res.status === 200) {
                setCat(res.data)
            }
        }).catch((err) => {

            console.log(err.response.status);
        })
    }, []);

    var sab = []
    useEffect(() => {
        if (cat.length >= 1) {
            cat.forEach(element => {
                sab.push(<option value={element._id}>{element.category}</option>)
            });
            setCategory(sab)
        } else {
            console.log('nothing')
        }
    }, [cat])

    const [Skill, setSkill] = useState([])
    const [Arr, setArr] = useState([])
    var listItems = ''
    const [AllSkills, setAllSkills] = useState([])
    const addSkill = (vall) => {
        vall = vall.trim()
        var dep = AllSkills
        dep.push(vall)
        setQ2({ ...Q2, skills: dep })
        setAllSkills(dep)
        var x = React.createElement('div',
            { className: 'p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1', role: 'alert' },
            [React.createElement('strong', {}, vall, <>&nbsp;&nbsp;&nbsp;</>,
                React.createElement('button', { onClick: (e) => removeSkill(vall), type: "button", className: "btn-close ms-5 Gbt p-0 pt-2 ", 'data-bs-dismiss': "alert", 'aria-label': "Close" }, ''),
            ),
            ]
        )
        Arr.push(x)
        listItems = Arr.map((myList) => {
            return <>{myList}</>;
        });
        setSkill(listItems)
    }
    const removeSkill = (e) => {
        var index = AllSkills.indexOf(e)
        AllSkills.splice(index, 1);
        setQ2({ ...Q2, skills: AllSkills })
    }

    function RandomString(length) {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < length; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }
    const [SQ, setSQ] = useState([])
    const ScreenQuestions = (e) => {
        console.count()
        var id = e.target.id
        const indexNo = id.substr(0, id.indexOf('-'))
        var cat = id.substr(id.indexOf('-') + 1)
        if (Screenquestions[indexNo]) {
            var Arr = Screenquestions
            if (cat === 'Qust') {
                Arr[indexNo].Question = e.target.value
            }
            else if (cat === 'Qtype') {
                try {
                    var x1 = indexNo + '-f2Container1'
                    var x2 = indexNo + '-f2Container2'
                    if (e.target.value === 'numeric') {
                        document.getElementById(x1).classList.add('d-none')
                        document.getElementById(x2).classList.remove('d-none')
                    }
                    if (e.target.value === 'objective') {
                        document.getElementById(x2).classList.add('d-none')
                        document.getElementById(x1).classList.remove('d-none')
                    }
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    Arr[indexNo].Type = e.target.value
                }
            }
            else if (cat === 'f2' || cat === 'f1') {
                Arr[indexNo].Answer = e.target.value
            }
            else if (cat === 'check') {
                Arr[indexNo].Compulsory = e.target.checked
            }
            setScreenquestions(Arr)
            var temp = SQ
            if (dataCollected) {
                temp.push(dataCollected)
                setSQ(temp)
            }
        }
        else {
            if (cat === 'Qust') {
                var Question = e.target.value
            }
            else if (cat === 'Qtype') {
                try {
                    var x1 = indexNo + '-f2Container1'
                    var x2 = indexNo + '-f2Container2'
                    if (e.target.value === 'numeric') {
                        document.getElementById(x1).classList.add('d-none')
                        document.getElementById(x2).classList.remove('d-none')
                    }
                    if (e.target.value === 'objective') {
                        document.getElementById(x2).classList.add('d-none')
                        document.getElementById(x1).classList.remove('d-none')
                    }
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    var Type = e.target.value
                }
            }
            else if (cat === 'f2' || cat === 'f1') {
                var Answer = e.target.value
            }
            else if (cat === 'check') {
                var Compulsory = e.target.checked
            }
            var dataCollected = {
                indexNo,
                Question,
                Type,
                Answer,
                Compulsory
            }
            var Arr = Screenquestions
            Arr[indexNo] = dataCollected
            setScreenquestions(Arr)
            var temp = SQ
            if (dataCollected) {
                temp.push(dataCollected)
                setSQ(temp)
            }
        }
    }

    useEffect(() => {

    }, [Screenquestions])
    var listItemsK = ''


    const addQuestionOnScreen = () => {
        dop()
        const Qr = <>

        </>
        setIndexX(IndexX + 1)

        Q.push(Qr)
        listItemsK = Q.map((myListi, index) => {
            return <div className="alert  alert-dismissible fade show border" id={index} role="alert">
                <textarea id={index + '-Qust'} type='text' style={{ fontWeight: '600' }} onChange={(e) => ScreenQuestions(e)} className='w-100 form-control' placeholder='Try asking questions like : Are you flexible with working hours? Or Do you have a vehicle of your own? Or Do you prefer 5 days work a week or 6 days?'></textarea>
                <button type="button" className="btn-close" onClick={(e) => makeFire(index)} aria-label="Close"></button>
                <div className='container  row p-0 m-0'>

                    <div className='col-12 col-md-5 p-0'>
                        <label htmlFor="" className="form-label">Response Type *</label>
                        <select id={index + '-Qtype'} onChange={(e) => ScreenQuestions(e)} type='email' className="form-control" aria-describedby="emailHelp" >
                            <option disabled selected></option>
                            <option value='numeric'>Numeric</option>
                            <option value='objective'>Yes/ No</option>
                        </select>
                    </div>

                    <div className='col-12 col-md-4 p-0 ps-2'>
                        <label htmlFor="" className="form-label">Ideal Answer </label>
                        <div className='row ps-3 text-center'>
                            <div id={index + '-f2Container1'} className='row d-none'>
                                <select id={index + '-f1'} onChange={(e) => ScreenQuestions(e)} className="w-25 form-control" aria-describedby="emailHelp" >
                                    <option disabled selected></option>
                                    <option value='true'>Yes</option>
                                    <option value='false'>No</option>
                                </select>
                            </div>
                            <div id={index + '-f2Container2'} className='row d-none'>
                                <input type='number' id={index + '-f2'} onChange={(e) => ScreenQuestions(e)} className="w-25 form-control m-0" aria-describedby="emailHelp" />&nbsp;
                                <small className='w-25'>minimum</small>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-3 p-0'>
                        <label htmlFor="" className="form-label">&nbsp;</label>
                        <div className="form-check">
                            <input id={index + '-check'} onChange={(e) => ScreenQuestions(e)} className="form-check-input" type="checkbox" value="" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                <small style={{
                                    fontSize: '12px'
                                }}>    Must-have qualification</small>
                            </label>
                        </div>
                    </div>

                </div>
                {myListi}
            </div>;
        });
        setQall(listItemsK)
        setflag(true)
    }

    const makeFire = (e) => {
        try {
            document.getElementById(e).classList.add('d-none')
            document.getElementById(e).classList.add('poda')
            if (Screenquestions.length !== 0) {
                var arr = Screenquestions
                var arx = SQ
                var rx = arr[e]
                var ex = arx.indexOf(rx)
                arx.splice(ex, 1)
                setSQ(arx)
            }
        }
        catch (e) {
            console.log(e)
        }
        finally {
            dop()
        }
    }

    function dop() {
        var c = document.getElementsByClassName('poda').length
        var cpr = IndexX - c
        if (cpr >= 9) {
            document.getElementById('addQbtn').classList.add('d-none')
        }
        else {
            document.getElementById('addQbtn').classList.remove('d-none')
        }
    }

    useEffect(() => {
        // console.log(Screenquestions)
    }, [Screenquestions])

    const [Pstate, setPstate] = useState('1s')
    const pageJumper = (next) => {
        document.getElementById(Pstate).classList.add('d-none')
        document.getElementById(next).classList.remove('d-none')
        setPstate(next)
        var x = parseInt(next.slice(0, 1))
        move(x * (100 / 6))
    }

    //starts 17-jan-2022

    const [Q1, setQ1] = useState({
        jobTitles: '',
        jobLocations: '',
        companyName: '',
        companyCountry:"",
        companyCity: '',
        companyState: '',
        workPlace: '',
        empType: '',
        mainCat: '',
        subCat: '',

        leaveF: false,
        maternityleave: false,
        maternityleavePaid: false,
        maternityleaveUnPaid: false,
        maternityleavePaidInput: "",
        maternityleaveUnPaidInput: "",
        casualleaves: "",
        MaxUnpaidleaves: "",
        MedicalPaidleaves: "",
        MedicalUnPaidleaves: "",
        AnnualPaidleaves: "",
        AnnualUnPaidleaves: "",
        maternityleavePaidInputMonths: "",
        maternityleaveUnPaidMonths: "",
        maternityleaveUnPaidInputMonths: "",

        WomanEN: false,
        LeaveStatus: true

    })
    const [Q2, setQ2] = useState({
        description: '',
        minPay: '',
        maxPay: '',
        vacancies: 0,
        expLvl: [],
        skills: [],

        JobDescription: '',
        CodeofConduct: '',
        Immediaterecruitment: true,
        Anyvacancies: '',
        Malevacancies: '',
        Femalevacancies: '',
        Othervacancies: '',
        AgeLvl: '',
        Qualification: '',
        Workingdays: '',
        StartTime: '',
        EndTime: '',
        SatstartTime: '',
        SatEndTime: '',
        Noticeperiod: '',
        differentlyAbled:false,
        InterviewMode:'Ready for Online Interviews',
        AfterDays:'',
        additionalPay:false,



    })
    const [Q3, setQ3] = useState({
        email: '',
        screenQustionNumber: 0,
        Screenquestions: [],
        recrutingAgency: false,
        cat: '',
        filter: false,
    })
    const [JT, setJT] = useState([])
    var lockedJT = ''
    const [viewJT, setviewJT] = useState([])
    const jtSelection = (e) => {
        if (e === ' ') {
            return
        }
        if (e.length === 0) {
            return
        }
        var st = JT.find(ef => ef === e)
        if (st) {
            return
        }
        JT.push(e)
        var len = 0
        lockedJT = JT.map((myList) => {
            return <div className='disposeBox m-1' id={'JT' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'JTB' + (len)} onClick={(e) => removeJT(e.target.id)} >
                </button>
            </div>;
        });
        setviewJT(lockedJT)
        setQ1({ ...Q1, jobTitles: JT })
    }
    const removeJT = (e) => {
        var red = e.slice(-1)
        red = red - 1
        JT.splice(red, 1);
        var len = 0
        lockedJT = JT.map((myList) => {
            return <div className='disposeBox m-1' id={'JT' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'JTB' + (len)} onClick={(e) => removeJT(e.target.id)} >
                </button>
            </div>;
        });
        setviewJT(lockedJT)
        setQ1({ ...Q1, jobTitles: JT })
    }
    const addBtn = (e) => {
        if (e) {
            document.getElementById('addBtn').classList.remove('d-none')
        }

    }
    const addJobTitile = (e) => {
        var xp = document.getElementById('jobTitle').value
        jtSelection(xp)
        document.getElementById('jobTitle').value = ''
        document.getElementById('addBtn').classList.add('d-none')
    }
    useEffect(() => {
        if (JT.length >= 1) {
            document.getElementById('jobTitle').setAttribute('disabled', 'true')
            // document.getElementById('jobTitle').setAttribute('placeholder', 'Reached maximum')
        }
        else {
            document.getElementById('jobTitle').removeAttribute('disabled')
            // document.getElementById('jobTitle').setAttribute('placeholder', 'Add more')
        }

    }, [viewJT])
    const [jobTitles, setjobTitles] = useState([])
    useEffect(() => {
        Axios.get('/api/api/getAllJobTitles')
            .then(res => {
                console.log('Api success job titles')
                setjobTitles(res.data)

            }).catch((err) => {
                console.log('error on api jobtitles')
                console.log(err.response.data)
            })
    }, [])
    const [optionLists, setoptionLists] = useState([])
    useEffect(() => {
        try {
            var rs = []
            jobTitles.forEach(element => {
                rs.push(<option key={'op' + keyGen()} value={element.jobTitle} />)

            });
            setoptionLists(rs)
        }
        catch (e) {
            console.log(e)
        }
    }, [jobTitles])


    const [Stateload, setStateload] = useState(true)

    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex
        print_city('City', index)
        setQ1({ ...Q1, companyState: e.target.value })
    }




    const [loc, setloc] = useState([])
    var locked = ''
    const [viewLoc, setviewLoc] = useState([])
    const locationSelection = (e) => {
        var st = loc.find(ef => ef === e)
        if (st) {
            return
        }
        loc.push(e)
        var len = 0
        locked = loc.map((myList) => {
            return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                </button>
            </div>;
        });
        setviewLoc(locked)
        setQ1({ ...Q1, jobLocations: loc })
    }
    const removeLocation = (e) => {
        var red = e.substring(1)
        red = red - 1
        loc.splice(red, 1);
        var len = 0
        locked = loc.map((myList) => {
            return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                {myList}
                <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                </button>
            </div>;
        });
        setviewLoc(locked)
        setQ1({ ...Q1, jobLocations: loc })

    }

    const [catId, setcatId] = useState([])
    function catSelect(e) {
        var check = e.target.checked
        if (check) {
            if (e.target.value === 'All') {
                var et = catId
                for (var i = 0; i < et.length; i++) {
                    console.log(et[i])
                    document.getElementById(et[i]).checked = !document.getElementById(et[i]).checked
                }
                setcatId([e.target.value])
            }
            else {
                var ind = catId.indexOf('All')
                if (ind >= 0) {
                    catId.splice(ind, 1)
                    document.getElementById('All').checked = !document.getElementById('All').checked
                }
                else {
                }
                var exist = catId.indexOf(e.target.id)
                if (exist === -1) {
                    catId.push(e.target.value)
                }
            }
        }
        else {
            if (e.target.value === 'All') {
                setcatId([])
            }
            else {
                var ind = catId.indexOf('All')
                if (ind >= 0) {
                    catId.splice(ind, 1)
                }
                else {
                    var indd = catId.indexOf(e.target.id)
                    catId.splice(indd, 1)
                }
            }
        }
    }
    useEffect(() => {
        setQ2({ ...Q2, expLvl: catId })
    }, [catId])
    const [Error, setError] = useState()
    const [Error2, setError2] = useState()
    const [Error3, setError3] = useState()
    const [Error3_1, setError3_1] = useState()
    const [Error4, setError4] = useState()
    const [Error5, setError5] = useState()
    const [loading, setloading] = useState()

    function setflagOff(e) {
        console.log('called')
        setloading(true)
        if (e) {
            // console.log(Q3)
            var Datasend = {
                Q1,
                Q2,
                Q3: {
                    email: Q3.email,
                    screenQustionNumber: SQ.length,
                    recrutingAgency: Q3.recrutingAgency,
                    cat: Q3.cat,
                    Screenquestions: SQ,
                    filter: Q3.filter
                }
            }
            console.log(Datasend)
            // return
            setloading(false)

            Axios.post('/api/ActiveUser/JobRecruitPostForm', { Datasend })
                .then(res => {
                    if (res.status == 200) {
                        notify("Posted successfully",true);
                        console.log(res.data)
                        setTimeout(() => {
                            setloading(false)
                            return navigate(`/job_vacancy_my_post`)
                        }, 1000);
                    setloading(false)

                    }
                }).catch((err) => {
                    console.log(err.response.data)
                    setloading(false)
                    notify('Something went wrong',false)
                })
        }
    }
    const Validator = async (ind) => {

        if (ind === '1') {

            if (!Q1.mainCat) {
                return setError('Select job category')
            }
            if (!Q1.subCat) {
                return setError('Select job sub category')
            }
            if (Q1.jobTitles.length === 0) {
                return setError('Add a job title')
            }
            // if (Q1.jobLocations.length === 0) {
            //     return setError('Select a job location')
            // }
            // if (!Q1.companyName) {
            //     return setError('Enter company name')
            // }
            if (!Q1.companyState) {

                return setError('Select job location (state)')
            }
            if (!Q1.companyCity) {

                return setError('Select job location ( city)')
            }
            if (!Q1.workPlace) {
                return setError('Select work mode')
            }
            if (!Q1.empType) {
                return setError('Select Employment type')
            }

            setError('')
            return pageJumper('2s')
        }
        if (ind === '2') {
            if (!Q1.companyName) {
                return setError2('Enter company name')
            }
            if (!Q2.description) {
                return setError2('Enter about organization')
            }

            if (!Q2.JobDescription) {
                return setError2('Enter job description')

            }
            if (Q2.expLvl < 1) {
                return setError2('Select experience level')
            }
            if (!Q2.minPay) {
                return setError2('Enter minimum pay')
            }
            if (!Q2.maxPay) {
                return setError2('Enter maximum pay')
            }
            if (!Q2.Workingdays) {
                return setError2('Select working days')
            }
            if (Toggle) {
                if (!Q2.StartTime) {
                    return setError2('Enter started time')

                }
                if (!Q2.EndTime) {
                    return setError2('Enter end time')

                }
            }


            if (Toggle === false) {
                if (!Q2.StartTime) {
                    return setError2('Enter started time')

                }
                if (!Q2.EndTime) {
                    return setError2('Enter end time')

                }
                if (!Q2.SatstartTime) {
                    return setError2('Enter saturday started time')

                }
                if (!Q2.SatEndTime) {
                    return setError2('Enter saturday end time')

                }
            }

            setError2('')
            return pageJumper('3s')
        }

        if (ind === '3') {

            var a = Q2.Anyvacancies + Q2.Malevacancies + Q2.Femalevacancies + Q2.Othervacancies
            if (a < 1) {
                return setError4('Enter number vacancies')
            }
            if (!Q2.Noticeperiod) {
                return setError4('Type \' 00 or 000 \' if nothing to be filled')
            }
            if (!Q2.AgeLvl) {
                return setError4('Select age level')
            }
            if (!Q2.Qualification) {
                return setError4('Enter the qualification you needed')
            }
            // console.log(Q2.skills)
            if (Q2.skills.length === 0) {
                // console.log(Q2)
                return setError4('Enter some skills')
            }
            setError4('')
            return pageJumper('4s')
        }

        if (ind == 4) {

            if (Q1.maternityleave) {
                if (!Q1.maternityleavePaidInput || !Q1.maternityleavePaidInputMonths) {
                    return setError5('Type \' 00 or 000 \' if nothing to be filled')
                }
                if (Q1.maternityleavePaidInputMonths > 11) {
                    return setError5('Enter valid months')
                }
                if (!Q1.maternityleaveUnPaidInput || !Q1.maternityleaveUnPaidInputMonths) {
                    return setError5('Type \' 00 or 000 \' if nothing to be filled')
                }
                if (Q1.maternityleaveUnPaidInputMonths > 11) {
                    return setError5('Enter valid months')
                }
            }

            if (!Q1.AnnualPaidleaves || !Q1.AnnualUnPaidleaves || !Q1.MaxUnpaidleaves ||
                !Q1.MedicalPaidleaves || !Q1.MedicalUnPaidleaves || !Q1.casualleaves ) {
                Q1.LeaveStatus = false
                return setError5('Type \' 00 or 000 \' if nothing to be filled')
            }
            setError5('')
            return pageJumper('5s')
        }

        if (ind == 5) {

            if (Q2.Immediaterecruitment==false||Q2.Immediaterecruitment=="false") {
                if (!Q2.AfterDays) {
                return setError3('Enter days')
                    
                }
            }

            if (!Q3.email) {
                return setError3('Enter email address')
            }
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Q3.email)) {
            }
            else {
                return setError3('Enter a valid email')
            }
            setError3('')

            try {

                for (var i = 0; i < SQ.length; i++) {
                    console.log(SQ)
                    if (SQ[i] !== 'undefined') {
                        if (!SQ[i].Type) {
                            var r = document.getElementById(i + '-Qtype').focus();
                            return setError3_1('select a response type')
                        }
                        if (SQ[i].Compulsory) {
                            if (!SQ[i].Answer) {
                                if (SQ[i].Type === 'numeric') {
                                    var r = document.getElementById(i + '-f2').focus();
                                } else if (SQ[i].Type === 'objective') {
                                    var r = document.getElementById(i + '-f1').focus();
                                }
                                return setError3_1('Enter ideal answer')
                            }
                        }
                    }
                }
            }
            catch (e) {

            }

            for (var j = 0; j < Q3.Screenquestions.length; j++) {
                if (!Q3.Screenquestions[j]) {
                    Q3.Screenquestions.splice(j, 1)
                }
            }
            setQ3({ ...Q3, Screenquestions: SQ })
            setflagOff(true)
            setError3_1('')
            console.log('done')

        }
    }

    useEffect(() => {
        if (Stateload)
            print_state("State")
        setStateload(false)
    })

    const [Toggle, setToggle] = useState()
    var i = 0;
    function move(x) {
        if (i === 0) {
            i = 1;
            var elem = document.getElementById("myBar");
            var width = 1;
            var id = setInterval(frame, 100);
            function frame() {
                if (width >= 100 || width >= x) {
                    clearInterval(id);
                    i = 0;
                }
                else {
                    width += x;
                    elem.style.width = width + "%";
                    if (width > 99) {
                        elem.style.background = 'green'
                    }
                    else {
                        elem.style.background = '#733380'
                    }
                }
            }
        }
    }
    useEffect(() => {
        console.log(Q2.Workingdays)
        if (Q2.Workingdays > 2) {
            setToggle(false)
            setQ2({ ...Q2, SatstartTime: '', SatEndTime: '' })
        } else if (Q2.Workingdays >= 1) {
            setToggle(true)
        }
    }, [Q2.Workingdays])
    let userData = useSelector((state) => state.user);

    let countryRef = useRef();
    let stateRef = useRef();
    let cityRef = useRef();
    let AllCityRef = useRef()

    useEffect(() => {
        ExistingData(countryRef, stateRef, cityRef, userData.value.Country, "");
        InitializeCountry(countryRef, stateRef, cityRef);
        return () => { };
    }, []);
    return (
        <div className='p-1 pt-5'>
            <br />
            <div className='container-fluid'>
                <div className='bg-ligjht container cont py-3 px-0' style={{
                    position: '',
                    width: '100%',
                    right: '0'
                }} >
                    <center>
                        <div className="" id="myProgress">
                            <div id="myBar"></div>
                        </div>
                    </center>
                </div>

                <div id='1s' className="container border p-3 d-nonee cont ">
                    <div className="container-fluid text-dark">
                        <h4 className="m-0 text-center py-1 text-dark mt-1">
                            Job Details
                        </h4>
                    </div>
                    <div>
                        <form className=" py-2 ">
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light">Job Category *</label>
                                <select value={Q1.mainCat} onChange={(e) => sabari(e)} type="text" className="form-select" >
                                    {/* <option value="none" selected>Select</option> */}
                                    <option value="" disabled selected>
                                    </option>
                                    {category ? category : ''}
                                </select>  {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light">Job Sub Category *</label>
                                <select value={Q1.subCat} onChange={(e) => setQ1({ ...Q1, subCat: e.target.value })} type="text" className="form-select" >
                                    <option value="" disabled selected>

                                    </option>
                                    {subcategory ? subcategory : ''}
                                </select> {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            {/* <div className="mb-1 mb-md-3">
                            <label htmlFor="" className="form-label">Job title *</label>
                            <div id='jobTit'>
                                {viewJT ? viewJT : ''}
                            </div>
                            <div className="input-group ">
                                <input id='jobTitle' type="text" list="browsers" onChange={(e) => addBtn(e.target.value)} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                <datalist id="browsers">
                                    {optionLists ? optionLists : ''}
                                </datalist>
                                <div id='addBtn' className="input-group-append d-none">
                                    <button onClick={() => addJobTitile()} className="btn btn-outline-secondary" type="button">Add</button>
                                </div>
                            </div>
                            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                        </div> */}
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light">Job Title *</label>

                                <input onChange={(e) => setQ1({ ...Q1, jobTitles: e.target.value })} type="text" id='jobTitle' className="form-control" >
                                </input>
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            {/* <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light">Job Location *</label>
                                <div id='loc'>
                                    {viewLoc ? viewLoc : ''}
                                </div>
                                <select onChange={(e) => locationSelection(e.target.value)} type="text" id='jobLocation' className="form-select" >
                                </select>
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div> */}
<div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light"> Job Location (country)  *</label>
                                <select ref={countryRef} type="text"  className="form-select" 
                                value={Q1.companyCountry}
                                onChange={(e) => setQ1({ ...Q1, companyCountry: e.target.value ,companyState:'',companyCity:''})}
                                >
                                </select>
                            </div>
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light"> Job Location (state)  *</label>
                                <select ref={stateRef} type="text" value={Q1.companyState} onChange={(e) => setQ1({ ...Q1, companyState: e.target.value,companyCity:'' })} className="form-select" >
                                </select>
                            </div>
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light">Job Location (city) *</label>

                                <select name="City" ref={cityRef} className="form-select" id="City" value={Q1.companyCity} onChange={(e) => setQ1({ ...Q1, companyCity: e.target.value })}>
                                    <option></option>
                                </select>

                            </div>
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light">Work Mode *</label>
                                <select onChange={(e) => setQ1({ ...Q1, workPlace: e.target.value })} type="text" className="form-select" >
                                    <option value="none" selected></option>
                                    <option>On-site</option>
                                    <option>Remote</option>
                                    <option>Hybrid</option>
                                </select> {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="labels fw-light">Employment Type *</label>
                                <select type="text" onChange={(e) => setQ1({ ...Q1, empType: e.target.value })} className="form-select" >
                                    <option value="none" selected></option>
                                    <option>Full-time</option>
                                    <option>Part-time</option>
                                    <option>Internship</option>
                                </select> {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <div className="form-check col-md-12  mt-2 ps-0 ">

                                <div className="form-check ms-0">
                                    <input className="form-check-input" onChange={(e) => setQ1({ ...Q1, WomanEN: (!Q1.WomanEN) })} type="checkbox" value="" id="flexCheckCheckedDisabledxyz" />
                                    <label className="form-check-label labels" htmlFor="flexCheckCheckedDisabledxyz">
                                        This is a Woman-Owned Enterprise
                                    </label>
                                </div>
                            </div>
                            {/* <div className="form-check col-md-12  mt-2 ">
                        <div className="form-check ms-0">
                            <input className="form-check-input" onChange={(e) => setQ1({ ...Q1, leaveF: (!Q1.leaveF) })} type="checkbox" value="" id="flexCheckCheckedDisabledxyz" />
                            <label className="form-check-label" htmlFor="flexCheckCheckedDisabledxyz">
                            We are ready to grant 3 days of menstrual leave every month to our women employees
                            </label>
                        </div>
                    </div> */}
                            <p className='text-danger'>{Error ? Error : ''}</p>
                            <input onClick={() => Validator('1')} type="button" className="btn btnpost w-100 text-center" value="Next" />
                        </form>
                    </div>
                </div>
                <div id='2s' className="container border p-3 d-none cont">

                    <div className='container-fluid p-0 py-2'>
                        <p className="mb-0  "> <button onClick={() => pageJumper('1s')} className="spBtn"><BsArrowLeft size="20" /></button>
                        <center> <div style={{ display: 'inline-block' }} className='text-center'> Job Details</div></center>

                        </p>
                    </div>
                    <hr className='p-0 m-0 mb-2 ' />
                    {/* <div>
                    <h4 className='ps-4'>Add a job description</h4>
                </div> */}
                    <div className="mb-1 mb-md-3">
                        <label htmlFor="" className="labels fw-light">Company Name *</label>
                        <input type="text" onChange={(e) => setQ1({ ...Q1, companyName: e.target.value })} className="form-control" id="" aria-describedby="emailHelp" />
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="labels fw-light">About the Organization *</label>
                        <textarea id='abtOrganisation' style={{

                        }} className="form-control" onChange={(e) => setQ2({ ...Q2, description: e.target.value })} />
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="labels fw-light">Employee Code of Conduct </label>
                        <textarea id='EmpRespo' style={{

                        }} className="form-control" onChange={(e) => setQ2({ ...Q2, CodeofConduct: e.target.value })} />
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="labels fw-light">Job Description / Responsibilities *</label>
                        <textarea className="form-control" onChange={(e) => setQ2({ ...Q2, JobDescription: e.target.value })} id="JDfkxssxxssxx" />
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>
                    <div className="mb-3 row">
                        <div className='col-12'>
                            <label htmlFor="" className="labels fw-light">Experience Level *</label>
                            {/* <select className="form-select" onChange={(e) => setQ2({ ...Q2, expLvl: e.target.value })} >
                            <option value="none" selected></option>
                            <option>Intern</option>
                            <option>Fresher</option>
                            <option>0 - 1 year</option>
                            <option>1 - 5 years</option>
                            <option>5 - 10 years</option>
                            <option> 10+  years</option>
                        </select>  */}
                            <div className='mt-2 row ms-0'>
                                {/* <h6 className='ps-0'>
                            Select the categories where you want to post *
                        </h6> */}
                                <div className="col form-check">
                                    <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="Intern" id="Intern" />
                                    <label className="form-check-label labels fw-light" htmlFor="Intern">
                                        Intern
                                    </label>
                                </div>
                                <div className="col form-check">
                                    <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="Fresher" id="Fresher" />
                                    <label className="form-check-label labels fw-light" htmlFor="Fresher">
                                        Fresher
                                    </label>
                                </div>
                                <div className="col form-check">
                                    <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="0 - 1 year" id="0 - 1year" />
                                    <label className="form-check-label labels fw-light" htmlFor="0 - 1year">
                                        0&nbsp;-&nbsp;1&nbsp;year
                                    </label>
                                </div>
                                <div className="col form-check">
                                    <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="1 - 5 years" id="1 - 5 years" />
                                    <label className="form-check-label labels fw-light" htmlFor="0 - 5year">
                                        0&nbsp;-&nbsp;5&nbsp;years
                                    </label>
                                </div>
                                <div className="col form-check">
                                    <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="5 - 10 years" id="5 - 10 years" />
                                    <label className="form-check-label labels fw-light" htmlFor="5 - 10 year">
                                        5&nbsp;-&nbsp;10&nbsp;years
                                    </label>
                                </div>
                                <div className="col form-check">
                                    <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="10+ years" id="10+ years" />
                                    <label className="form-check-label labels fw-light" htmlFor="10+ years">
                                        10+&nbsp;years
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <small className='m-0 labels'>Salary Offered in LPA (Lakh Per Annum)</small>
                        <div className='col-6'>
                            <label htmlFor="" className="labels fw-light">Minimum *</label>
                            <input className="form-control" type='number' value={Q2.minPay} onChange={(e) => { if (e.target.value.length == 5) return false; setQ2({ ...Q2, minPay: e.target.value }) }} />
                        </div>
                        <div className='col-6'>
                            <label htmlFor="" className="labels fw-light">Maximum *</label>
                            <input className="form-control" type='number' value={Q2.maxPay} onChange={(e) => { if (e.target.value.length == 5) return false; setQ2({ ...Q2, maxPay: e.target.value }) }} />
                        </div>
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>
                    <div className="mb-3 row">
                        <div className='col-12'>
                            <label htmlFor="" className="labels fw-light">Working Days *</label>
                            <select className="form-select" onChange={(e) => setQ2({ ...Q2, Workingdays: e.target.value })} >
                                <option value="none" selected></option>
                                <option value="1">MON - FRI</option>
                                <option value="2">MON - SAT</option>
                                <option value="3">MON - SAT (every SAT : half day)</option>
                                <option value="4">MON - SAT (alt SAT : half day)</option>
                                <option value="5">MON - SAT (alt SAT : holiday)</option>
                            </select> </div>
                    </div>

                    {
                        Toggle == true ? <><dive className="row">
                            <label htmlFor="" className="labels fw-light">Work Timings *</label>
                            <div className='col-5'><input type="time" className='form-control ' onChange={(e) => setQ2({ ...Q2, StartTime: e.target.value, })} value={Q2.StartTime} ></input></div>
                            <div className='col-2 text-center mt-1'>to</div>
                            <div className='col-5'><input type="time" className='form-control ' onChange={(e) => setQ2({ ...Q2, EndTime: e.target.value })} value={Q2.EndTime} ></input></div>
                        </dive></> : ""
                    }


                    {
                        Toggle == false ? <><div className="row">
                            <div className='col-6'>
                                <label htmlFor="" className="labels fw-light">Weekdays Work Timings *</label>
                                <div className='row'>
                                    <div className='col-5'><input type="time" className='form-control ' onChange={(e) => setQ2({ ...Q2, StartTime: e.target.value })} value={Q2.StartTime} ></input></div>
                                    <div className='col-2 text-center mt-1'>to</div>
                                    <div className='col-5'><input type="time" className='form-control ' onChange={(e) => setQ2({ ...Q2, EndTime: e.target.value })} value={Q2.EndTime} ></input></div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <label htmlFor="" className="labels fw-light">Saturday Work Timings *</label>
                                <div className='row'>
                                    <div className='col-5'><input type="time" className='form-control ' onChange={(e) => setQ2({ ...Q2, SatstartTime: e.target.value })} value={Q2.SatstartTime} ></input></div>
                                    <div className='col-2 text-center mt-1'>to</div>
                                    <div className='col-5'><input type="time" className='form-control ' onChange={(e) => setQ2({ ...Q2, SatEndTime: e.target.value })} value={Q2.SatEndTime} ></input></div>
                                </div>
                            </div>
                        </div>

                        </> : ""
                    }
                    <br/>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flex1453412344" onChange={(e) => setQ2({ ...Q2, additionalPay: (!Q2.additionalPay)})} />
                        <label class="form-check-label labels" for="flex1453412344" >
                            We will pay our employees for additional hours of work
                        </label>
                    </div>


                    <div className="mb-3">

                        {/* <hr /> */}
                        <p className='text-danger'>{Error2 ? Error2 : ''}</p>
                        <div >
                            <button onClick={() => Validator('2')} className="btn btnpost w-100 mt-1 ">Continue</button>
                        </div>
                    </div>
                </div>
                <div id='3s' className="container border p-3 d-none cont">
                    <div className='container-fluid row p-0 py-2'>
                        <p className="mb-0 "> <button onClick={() => pageJumper('2s')} className="spBtn"><BsArrowLeft size="20" /></button>
                          <center> <div style={{ display: 'inline-block' }} className='text-center'> Job Details</div></center>
                        </p>
                    </div>
                    <hr className='p-0 m-0 mb-2 ' />
                    {/* <div>
                    <h4 className='ps-4'>Add a job description</h4>
                </div> */}



                    <div className="mb-3 row">
                        <div className=' col-12'>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flex1453412344" onChange={(e) =>  setQ2({ ...Q2,differentlyAbled:(!Q2.differentlyAbled) }) } />
                                <label class="form-check-label labels" for="flex1453412344">
                                             We are open to employing differently-abled candidates
                                </label>
                            </div>
                        </div>
                        <label htmlFor="" className="labels fw-light">Number of Vacancies *</label>
                        <div className='col-sm-4 col-6'>
                            <h6 htmlFor="" className="labels fw-light">Any Group</h6>
                            <input type='number' value={Q2.Anyvacancies} onChange={(e) => { if (e.target.value.length == 5) return false; setQ2({ ...Q2, Anyvacancies: e.target.value }) }} className="form-control" />
                        </div>
                        <div className='col-sm-4 col-6'>
                            <h6 htmlFor="" className="labels fw-light">Male </h6>
                            <input type='number' value={Q2.Malevacancies} onChange={(e) => { if (e.target.value.length == 5) return false; setQ2({ ...Q2, Malevacancies: e.target.value }) }} className="form-control" />
                        </div>
                        <div className='col-sm-4 col-6'>
                            <label htmlFor="" max='999' className="labels fw-light">Female </label>
                            <input type='number' value={Q2.Femalevacancies} onChange={(e) => { if (e.target.value.length == 5) return false; setQ2({ ...Q2, Femalevacancies: e.target.value }) }} className="form-control" />
                        </div>
                        <div className='col-sm-4 col-6' max='999' >
                            <label htmlFor="" className="labels fw-light">Transgender </label>
                            <input type='number' value={Q2.Othervacancies} onChange={(e) => { if (e.target.value.length == 5) return false; setQ2({ ...Q2, Othervacancies: e.target.value }) }} className="form-control" />
                        </div>

                    </div>

                    <div className="mb-3">
                        <label htmlFor="" className="labels fw-light">Notice&nbsp;Period&nbsp;(to&nbsp;and&nbsp;from&nbsp;employee)&nbsp;*</label>
                        <input type='number' value={Q2.Noticeperiod} onChange={(e) => { if (e.target.value.length == 3) return false; setQ2({ ...Q2, Noticeperiod: e.target.value }) }} className="form-control" />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="" className="labels fw-light">Age Group*</label>
                        <select className='form-select adserjob' onChange={(e) => setQ2({ ...Q2, AgeLvl: e.target.value })}>
                            <option value="none" selected > &nbsp; </option>
                            <option>Any</option>
                            <option>18 to 30</option>
                            <option>30 to 45</option>
                            <option>45 to 60</option>
                        </select>
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="" className="labels fw-light">Educational Qualification *</label>
                        <textarea className='form-control adserjob' onChange={(e) => setQ2({ ...Q2, Qualification: e.target.value })} >

                        </textarea>
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>
                    <div className='mb-3'>     <label htmlFor="" className="labels fw-light">Add skills *</label><br />
                        <small className='labels'>
                            Add skill keywords to make your job more visible to the right candidates (Mention up to 10)
                        </small>
                        <div className="col-md-12 p-1 mt-2">
                            <label className="labels  ms-0 fw-light">Key Skills </label>
                            <div className="row ps-4">
                                {Skill ? Skill : ''}
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <div class="input-group w-100">
                                <input maxLength='18' className='form-control' id="skills" onChange={(e) => makeSkill(e.target.id)} type="text" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button class="btn pro-spaces-button3" onClick={(e) => makeSkill('skills', true)} type="button" id="button-addon2">Add</button>
                            </div>
                        </div></div>

                    <div className="mb-3">

                        <p className='text-danger'>{Error4 ? Error4 : ''}</p>
                        <div >
                            <button onClick={() => Validator('3')} className="btn btnpost w-100 mt-1 ">Continue</button>
                        </div>
                    </div>
                </div>
                <div id='4s' className="container border p-3 d-none cont ">
                    <div className='container-fluid p-0 py-2'>
                        <p className="mb-0 "> <button onClick={() => pageJumper('3s')} className="spBtn"><BsArrowLeft size="20" /></button>
                        <center> <div style={{ display: 'inline-block' }} className='text-center'> Job Details</div></center>

                        </p>
                    </div>


                    <hr className='p-0 m-0 mb-2 ' />
                    {/* <div>
                    <h4 className='ps-4'>Add a job description</h4>
                </div> */}
                    <div><h6 className='text-center pt-1 pb-3'>LEAVES</h6></div>

                    <div className='mt-1 mb-2'>
                        <small className='labels'>Menstrual leave <span style={{ 'fontWeight': '300' }}>(for women)</span></small>
                    </div>

                    <div className="form-check col-md-12  mt-2 ">
                        <div className="form-check ms-0">
                            <input className="form-check-input" onChange={(e) => setQ1({ ...Q1, leaveF: (!Q1.leaveF) })} type="checkbox" value="" id="flhhh" />
                            <label className="form-check-label labels" htmlFor="flhhh">
                                We are ready to grant 3 days of paid menstrual leave every month to our women employees on request
                            </label>
                        </div>
                    </div>

                    <div className='mt-4 mb-2'>
                        <small className='labels'>Maternity leave <span style={{ 'fontWeight': '300' }}>(for women)</span></small>
                    </div>

                    <div className="form-check col-md-12  my-2 ">
                        <div className="form-check ms-0">
                            <input className="form-check-input" onChange={(e) => setQ1({ ...Q1, maternityleave: (!Q1.maternityleave) })} type="checkbox" value="" id="flbbbb" />
                            <label className="form-check-label labels" htmlFor="flbbbb">
                                We are ready to grant maternity leave to our women employees on request
                            </label>
                        </div>
                    </div>



                    {Q1.maternityleave ? <div className='row container '>

                        <div className='col-6'>
                            <h6 htmlFor="" className="labels fw-light">Paid&nbsp;leave&nbsp;*</h6>
                            <div className='row'>

                                <div className='col-6 pe-0'>
                                    <input type='number' placeholder='Years'
                                        value={Q1.maternityleavePaidInput}
                                        onChange={(e) => {
                                            if (e.target.value.length == 3) return false;
                                            setQ1({ ...Q1, maternityleavePaidInput: e.target.value })
                                        }}
                                        className="form-control w-5" />

                                </div>
                                <div className='col-6'>
                                    <input type='number' placeholder='Months' value={Q1.maternityleavePaidInputMonths} onChange={(e) => { if (e.target.value.length == 3) return false; setQ1({ ...Q1, maternityleavePaidInputMonths: e.target.value }) }} max="9999" className="form-control w-0" />

                                </div>

                            </div>
                        </div>




                        {/* sweek days Work timing, saturday */}
                        <div className='col-6'>

                            <h6 htmlFor="" className="labels fw-light ">Unpaid&nbsp;leave&nbsp;*</h6>
                            {/* 2 digit limit */}

                            <div className='row'>
                                <div className='col-6 pe-0'>
                                    <input type='number' placeholder='Years' value={Q1.maternityleaveUnPaidInput} onChange={(e) => { if (e.target.value.length == 3) return false; setQ1({ ...Q1, maternityleaveUnPaidInput: e.target.value }) }} max="9999" className="form-control w-5 0" />

                                </div>
                                <div className='col-6'>
                                    <input type='number' placeholder='Months' value={Q1.maternityleaveUnPaidInputMonths} onChange={(e) => { if (e.target.value.length == 3) return false; setQ1({ ...Q1, maternityleaveUnPaidInputMonths: e.target.value }) }} max="9999" className="form-control w-5 0" />
                                </div>
                            </div>

                        </div>
                    </div> : ""}






                    {/* annual, 3 digit, month -2 */}
                    <div className='mt-4'>
                        <small className='labels'>Casual, Medical and Annual leaves</small>
                    </div>

                    <div className='row container'>
                        <div className="mb-3 col-6 ">

                            <label htmlFor="exampleInputEmail1" className="labels fw-light">Monthly&nbsp;Casual&nbsp;paid&nbsp;leave&nbsp;* </label>
                            <input type='number' placeholder='Days' value={Q1.casualleaves} onChange={(e) => { if (e.target.value.length == 2) return false; setQ1({ ...Q1, casualleaves: e.target.value }) }} max="9" className="form-control w-50" id="exampleInputEmail1" />
                        </div>
                        <div className="mb-3 col-6 ">

                            <label htmlFor="exampleInputEmail1" className="labels fw-light">Monthly&nbsp;Casual&nbsp;unpaid&nbsp;leave&nbsp;* </label>
                            <input type='number' placeholder='Days' value={Q1.MaxUnpaidleaves} onChange={(e) => { if (e.target.value.length == 3) return false; setQ1({ ...Q1, MaxUnpaidleaves: e.target.value }) }} max="99" className="form-control w-50" id="exampleInputEmail1" />




                            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>

                    </div>


                    <div className='row container'>
                        <div className="mb-3 col-6">
                            <label htmlFor="exampleInputEmail1" className="labels fw-light">Monthly&nbsp;Medical&nbsp;paid&nbsp;leave&nbsp;*  </label>
                            <input type='number' placeholder='Days' value={Q1.MedicalPaidleaves} onChange={(e) => { if (e.target.value.length == 3) return false; setQ1({ ...Q1, MedicalPaidleaves: e.target.value }) }} max="99" className="form-control w-50" id="exampleInputEmail1" />
                            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>
                        <div className="mb-3 col-6">
                            <label htmlFor="exampleInputEmail1" className="labels fw-light">Monthly&nbsp;Medical&nbsp;unpaid&nbsp;leave&nbsp;* </label>
                            <input type='number' placeholder='Days' value={Q1.MedicalUnPaidleaves} onChange={(e) => { if (e.target.value.length == 3) return false; setQ1({ ...Q1, MedicalUnPaidleaves: e.target.value }) }} max="99" className="form-control w-50" id="exampleInputEmail1" />
                            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>
                    </div>
                    <div className='row container'>
                        <div className="mb-3 col-6">
                            <label htmlFor="exampleInputEmail1" className="labels fw-light"> Annual&nbsp;paid&nbsp;leave&nbsp;*  </label>
                            <input type='number' placeholder='Days' value={Q1.AnnualPaidleaves} onChange={(e) => { if (e.target.value.length == 4) return false; setQ1({ ...Q1, AnnualPaidleaves: e.target.value }) }} max="99" className="form-control w-50" id="exampleInputEmail1" />
                            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>
                        <div className="mb-3 col-6">
                            <label htmlFor="exampleInputEmail1" className="labels fw-light"> Annual&nbsp;unpaid&nbsp;leave&nbsp;*  </label>
                            <input type='number' placeholder='Days' value={Q1.AnnualUnPaidleaves} onChange={(e) => { if (e.target.value.length == 4) return false; setQ1({ ...Q1, AnnualUnPaidleaves: e.target.value }) }} max="99" className="form-control w-50 " id="exampleInputEmail1" />
                            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>
                    </div>
                    <div className="mb-3">

                        <p className='text-danger'>{Error5 ? Error5 : ''}</p>
                        <div >
                            <button onClick={() => Validator('4')} className="btn btnpost w-100 mt-1 ">Continue</button>
                        </div>
                    </div>
                </div>
                <div id='5s' className="container border p-3 d-none cont">
                    <div className='container-fluid px-0 py-2'>
                        <p className="mb-0 "> <button onClick={() => pageJumper('4s')} className="spBtn"><BsArrowLeft size="20" /></button>
                        <center> <div style={{ display: 'inline-block' }} className='text-center'> Job Details</div></center>
 </p>
                    </div>
                    <hr className='p-0 m-0 mb-2 ' />
                    <label className="labels  ms-0 fw-light">Recruitment time * </label>
                    <div className="mb-3 mt-2">
                        <div className='row'>
                            <div className='col-6'>


                                <div class="form-check">
                                    <input class="form-check-input mt-2" name='retime' type="radio" value="" id="flex112344" onChange={(e) => setQ2({ ...Q2, Immediaterecruitment: (!Q2.Immediaterecruitment) })} defaultChecked />
                                    <label htmlFor="" className="labels fw-light"> Immediate</label><input className=' invisible form-control m-0' style={{ width: '70px', display: 'inline' }} type={'number'}></input>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div class="form-check">
                                    <input class="form-check-input mt-2" name='retime' type="radio" value="" id="flex112344" onChange={(e) => setQ2({ ...Q2, Immediaterecruitment: (!Q2.Immediaterecruitment) })} />
                                    <label htmlFor="" className="labels fw-light">After </label> &nbsp;&nbsp;{
                                        Q2.Immediaterecruitment===false||Q2.Immediaterecruitment==="false"?
                                        <>
                                    <input className='form-control m-0' style={{ width: '70px', display: 'inline' }} type={'number'} onChange={(e) => setQ2({ ...Q2, AfterDays: e.target.value  })}   ></input>&nbsp;<label htmlFor="" className="labels fw-light">days</label>

                                        </>

                                        :''
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                     
                    <div className="col-md-12 mt-3">
                        <label className="labels fw-light">Interview Process *</label><br></br>
                        <input onChange={(e) => setQ2({ ...Q2, InterviewMode: e.target.value })} type="radio" className="form-check-input" name="InterviewMode" value="Ready for Online Interviews" defaultChecked />
                        &nbsp;&nbsp;Ready&nbsp;for&nbsp;Online&nbsp;Interviews&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp; <input onChange={(e) => setQ2({ ...Q2, InterviewMode: e.target.value })} type="radio" className="form-check-input" name="InterviewMode" value="One-to-One Interviews" />
                        &nbsp;&nbsp;<span style={{ whiteSpace: "nowrap" }}>One-to-One Interviews</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp; <span style={{ whiteSpace: "nowrap" }}><input onChange={(e) => setQ2({ ...Q2, InterviewMode: e.target.value })} type="radio" className="form-check-input" name="InterviewMode" value="Both" />&nbsp;&nbsp;Both</span>
                    </div>

                    <div className="mb-3">
                        <h6>How would you like to receive applications?</h6>
                        <label htmlFor="exampleInputEmail1" className="labels fw-light">Email address *</label>
                        <input type='email' onChange={(e) => { setQ3({ ...Q3, email: e.target.value }) }} className="form-control" id="exampleInputEmail1" />
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>
                    <div id='hii' className="mb-3" >
                        <h6>Add Screening questions</h6>
                        <small>We recommend adding 3 or more questions. Applicants must answer each question.</small>
                        {Qall ? Qall : ''}
                    </div>
                    <div className="mb-3">
                        <p className='text-danger'>{Error3_1 ? Error3_1 : ''} </p>
                        <button id='addQbtn' className='btn btnpost' onClick={() => addQuestionOnScreen()} htmlFor="" >Add screening question</button><br />
                    </div>
                    <div className="mb-3">
                        <label className='labels fw-light'>Posted by *</label>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="form-check">
                                    <input onChange={(e) => setQ3({ ...Q3, recrutingAgency: (!Q3.recrutingAgency) })} className="form-check-input" type="radio" value={false} defaultChecked id="paa" name='paaa' />
                                    <label className="form-check-label labels fw-light" htmlFor="flexCheckDefault11">
                                        Organization
                                    </label>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-check">
                                    <input onChange={(e) => setQ3({ ...Q3, recrutingAgency: (!Q3.recrutingAgency) })} className="form-check-input" type="radio" value={true} id="paa" name='paaa'/>
                                    <label className="form-check-label labels fw-light" htmlFor="flexCheckDefault11">
                                        Recruiting agency
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col form-check">
                            <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="All" id="All" />
                            <label className="form-check-label" htmlFor="All">
                                All
                            </label>
                        </div> */}
                        {/* <div className='mt-2 row ms-0'>
                        <h6 className='ps-0'>
                            Select the categories where you want to post *
                        </h6>
                   
                        <div className="col form-check">
                            <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="Intern" id="Intern" />
                            <label className="form-check-label labels fw-light" htmlFor="Intern">
                                Intern
                            </label>
                        </div>
                        <div className="col form-check">
                            <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="Fresher" id="Fresher" />
                            <label className="form-check-label labels fw-light" htmlFor="Fresher">
                                Fresher
                            </label>
                        </div>
                        <div className="col form-check">
                            <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="0-1 year" id="0-1year" />
                            <label className="form-check-label labels fw-light" htmlFor="0-1year">
                                0&nbsp;-&nbsp;1&nbsp;year
                            </label>
                        </div>
                        <div className="col form-check">
                            <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="0-5 years" id="0-5year" />
                            <label className="form-check-label labels fw-light" htmlFor="0-5year">
                                0&nbsp;-&nbsp;5&nbsp;years
                            </label>
                        </div>
                        <div className="col form-check">
                            <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="5-10 years" id="5-10 year" />
                            <label className="form-check-label labels fw-light" htmlFor="5-10 year">
                                5&nbsp;-&nbsp;10&nbsp;years
                            </label>
                        </div>
                        <div className="col form-check">
                            <input onChange={(e) => catSelect(e)} className="form-check-input" type="checkbox" value="10+ years" id="10+ years" />
                            <label className="form-check-label labels fw-light" htmlFor="10+ years">
                                10+&nbsp;years
                            </label>
                        </div>
                    </div> */}

                    </div>


                    <div className="mb-3">
                        <h6>Qualification settings</h6>
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setQ3({ ...Q3, filter: (!Q3.filter) })} type="checkbox" value={true} id="Filter" />
                            <label className="form-check-label labels fw-light" htmlFor="Filter">
                                <small style={{
                                    fontSize: '14px'
                                }}>    Filter out and send rejections who don't meet any must-have qualifications</small>
                            </label>
                        </div>
                        <p className='text-danger'>{Error3 ? Error3 : ''} </p>
                        {/* <button type='button' onClick={(e) => Validator('3')} className="btn btnpost w-100 mb-2">Post</button> */}
                        {/* {!loading && <center>
                        <input onClick={(e) => Validator('5')} className="btn w-100 btnpost mt-2" type="submit" value="Post" /></center>}
                    {loading && <center>
                        <button class="btn btnpost w-100  mt-2" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Posting...
                        </button></center>} */}


                        <div className='row'>

                            <div className='col-6'>
                                <input className="btn btnpost w-100 mt-2 pro-spaces-button" data-bs-toggle="modal" data-bs-target="#SureYouWanna" type="submit" value="Reset" />

                            </div>

                            <div className='col-6'>
                                {!loading && <center>
                                    <input onClick={(e) => Validator('5')} className="btn w-100 btnpost mt-2" type="submit" value="Post" />
                                </center>}
                                {loading && <center>
                                    <button className="btn btnpost w-100  mt-2" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;Posting...
                                    </button>
                                </center>}
                            </div>

                        </div>

                    </div>
                    <div className="modal fade" id="SureYouWanna" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Are you sure? !!! This will erase all the fields on all the pages.
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn pro-spaces-button" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" onClick={(e) => {
                                        props.setReset(!props.Reset)
                                    }} data-bs-dismiss="modal" className="btn pro-spaces-button3">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div >
    )
}

