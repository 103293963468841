import React,{useEffect} from 'react'

export  function Cub() {
  useEffect(() => {
    document.getElementById('root').style.backgroundColor='black'
      return () => {
        document.getElementById('root').style.backgroundColor='white'
      }
    }, [])
  return (
    <div className="container-fluid nefhoehfliwrhvguwrogfvowrufv bg-black jrgfrjflobjgt pt-5" style={{'color':'white'}}>
        <div className='d-flex justify-content-end pt-3'><button className='btn srhglhsrbih mt-3 mb-3 ' onClick={(e)=>window.history.back()}>Go Back</button></div>
          <div className="row">
            <h4 className="parapara22 ">CUBICLES</h4>
          </div>
          <div className="row">
            <p>Cubicle (in our context) = topic of discussion.</p><br /><br />
            <p>CUBICLES is a feature wherein any member can create a conference room by inviting and adding any number of members and initiate multiple topics of discussion called Cubicles within the same group. This feature is extremely useful for professional consultants and business enterprises.</p>
            <p>If you are a Consultant firm / a group of Independent Consultants working together on different projects / cases or a Business enterprise that wishes to have discussions / updates on various projects / cases / sales with your colleagues / employees without your discussions on these multiple topics getting jumbled up, all you need to do is create a single conference room with differently named cubicles (topics).</p>
            <p>NO mixing up of topics. NO multiple groups for multiple topics.</p>
            <p>The member who creates the conference room becomes the Admin of the group and can invite anyone to join the group. It is only the Admin who can add / remove members to / from each Cubicle.</p>
            <p>The Admin (only) can also delete messages in a Cubicle.</p>
            <p>Once a participant in a Cubicle, Any Member of that Cubicle can use the feature called PRODUCT FOLDERS in order to Short-list their choice / selection of products during their project discussions with their respective service providers / product retailers / clients / customers. And, also Save these Options in various folders for future references.</p>
            <p>Once the discussion is over, the Admin can LOCK the Cubicle and Save it as a document for future references. The members of the particular Cubicle only can access the Closed Cubicle to read or print. The Closed Cubicles cannot be edited though. If at any time in the future, this Closed Cubicle needs to be Re-opened to continue the discussion, it can be done so by the Admin only by UNLOCKING the Cubicle.</p>
          </div>
          
        
          
        </div>
  )
}
