import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';


export const Information_table = ({data}) => {
  var columns = [
    {
      field: 'id',
      headerName: 'No. ',
      width: 60,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 150,
    },
    {
      field: 'state',
      headerName: 'Country',
      width: 150,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 100,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 50,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 250,
    },
    // {
    //   field: "createdBy",
    //   headerName: "Account created",
    //   width: 200,
    // },,
  ];

  return (
    <div >   
      {data && (
      <Box sx={{ height: 800, width: '100%' }}>
        <DataGrid
          rows={data}
          columns={columns}
          // Other DataGrid props...
        />
      </Box>
    )}</div>
  )
}
