import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Link } from "react-router-dom";
import { AiFillFire, AiOutlineSearch } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ChatHeader from "./chatHeader";
import { ChatGround } from "./chatGround";
import axios from "axios";
import io from "socket.io-client";
import {keyGen} from '../../Utility/helper'

export const ChatRight = forwardRef((props, ref) => {

  const [GroundKey, setGroundKey] = useState(keyGen())
  const childFunc = React.useRef(null);
  const childRef = useRef(null);
  useEffect(() => {
    props.socket.emit("cubicles", props.RoomDetails.id);
    if (props.group)
      props.socket.emit("cubicle", props.RoomDetails.id + props.RoomDetails.topic);
  }, [props.RoomDetails.id]);
  useEffect(() => {
    // console.log(props.RoomDetails.id + props.RoomDetails.topic)
    props.socket.emit("cubicles", props.RoomDetails.id + props.RoomDetails.topic);
  }, [props.RoomDetails.topic]);
  const [Counter, setCounter] = useState(true);
  useImperativeHandle(ref, () => ({
    ChangerToHeader(pass) {
      // console.log('at right')
      // console.log(pass)
      childRef.current.TheHeader(pass)
    }
  }))
  const ChangerToHeaderlite = () => {
    // console.log('reached')
    childRef.current.TheHeader('pass')
  }
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8  verticalStu  dkhs">
      <ChatHeader
        ref={childRef}
        force={props.force}
        socket={props.socket}
        NoMembers={props.NoMembers}
        viewTopicMember={props.viewTopicMember}
        Changetopic={props.Changetopic}
        TopicMembersAddView={props.TopicMembersAddView}
        ShowMember={props.ShowMember}
        GroupInvite={props.GroupInvite}
        CreateNewTopic={props.CreateNewTopic}
        LockTopic={props.LockTopic}
        RoomDetails={props.RoomDetails}
        Block={props.Block}
        setGroundKey={setGroundKey} />
      <ChatGround
      key={GroundKey}
        ReloadList={props.ReloadList}
        ChangerToHeader={ChangerToHeaderlite}
        Changetopic={props.Changetopic}
        force={props.force}
        Counter={Counter}
        socket={props.socket}
        RoomDetails={props.RoomDetails}
        childFunc={childFunc} />
    </div>
  );
})
