import React, {
  useReducer,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { AiFillCamera } from 'react-icons/ai';
import Carousel from 'react-bootstrap/Carousel';
import { Cropper } from 'react-cropper';
import axios from 'axios';
import { keyGen } from '../../Utility/helper';
import { notify } from '../../Utility/notify';
import { BsThreeDotsVertical } from 'react-icons/bs';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PostFooter from '../ProForum/postFooter';

function PostToolBarv2({ isDisableFooter = false }) {
  const activeComponentRef = useRef();

  const [ProForumTopic, setProForumTopic] = useState([]);
  useEffect(() => {
    axios
      .get('/api/ProtectedApi/ProForumTopics')
      .then((res) => { 
        console.log(res);
        setProForumTopic(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => { };
  }, []);

  const reducer = (state, action) => {
    switch (action.type) {
      case 1:
        return {
          ...state,
          render: <TextOnly ref={activeComponentRef} />,
          type: action.type,
        };
      case 2:
        return {
          ...state,
          render: <ImageOnly ref={activeComponentRef} />,
          type: action.type,
        };
      case 3:
        return {
          ...state,
          render: <TextWithImage ref={activeComponentRef} />,
          type: action.type,
        };
      case 4:
        return {
          ...state,
          render: (
            <ImageUploadWithDiscriptionComponent ref={activeComponentRef} />
          ),
          type: action.type,
        };
      case 5:
        return {
          ...state,
          render: (
            <ImageUploadWithTextandDiscriptionComponent
              ref={activeComponentRef}
            />
          ),
          type: action.type,
        };

      default:
        return { ...state, render: <h1>default</h1> };
    }
  };
  const [state, dispatch] = useReducer(reducer, { render: <></> });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [value, setvalue] = useState({
    Topic: '',
  });
  const [Error, setError] = useState('');
  const SelectController = (e) => {
    let value = e.target.value;
    dispatch({ type: parseInt(value) });
  };

  const submit = () => {
    if (value.Topic.length == 0) {
      return setError('Please select a Topic');
    }
    if (![1, 2, 3, 4, 5].includes(state.type)) {
      return setError('Please select a type');
    }

    setError('');

    if (activeComponentRef.current) {
      const data = activeComponentRef.current.getData();
      let formData = new FormData();
      let Titles = [];
      if (data.Data) {
        data.Data.forEach((element, index) => {
          let metadata = {
            type: element.file.type,
          };
          console.log(element);
          if (element.file.type.indexOf('video') != -1) {
            var ads = `prochure_pro_forum_${index}_${keyGen() + Date.now()
              }.mp4`;
          }
          if (element.file.type.indexOf('image') != -1) {
            var ads = `prochure_pro_forum_${index}_${keyGen() + Date.now()
              }.png`;
          }
          let file = new File([element.file], ads, metadata);
          formData.append('file', file);
          Titles = [...Titles, element.title];
        });
      }

      formData.append(
        'data',
        JSON.stringify({
          Description: data.Discription,
          Topic: value.Topic,
          Type: state.type,
          Titles,
        })
      );

      axios
        .post('/api/User/ProForum', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log(res);
          notify('Posted', true);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          notify('Something went wrong !', false);
        });
    }
  };

  return (
    <div>


      <PostView isDisableFooter={isDisableFooter} handleClose={handleClose} handleShow={handleShow} ProForumTopic={ProForumTopic} />

      <Modal show={show} onHide={handleClose} centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Pro-Forum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group py-2">
              <label htmlFor="selectFormatr">Topic</label>
            </div>
            <select
              id="selectFormatr"
              className="form-select"
              onChange={(e) => {
                setvalue((prev) => ({ ...prev, Topic: e.target.value }));
              }}
            >
              <option disabled selected></option>
              {ProForumTopic.map((element) => {
                return <option value={element._id}> {element.Topic}</option>;
              })}
            </select>
          </div>

          <div>
            <div className="form-group py-2">
              <label htmlFor="selectFormat">Select Format</label>
              <select
                id="selectFormat"
                className="form-select"
                onChange={(e) => SelectController(e)}
              >
                <option disabled selected></option>
                <option value={1}>Only Text</option>
                <option value={2}>Only Images</option>
                <option value={4}>Text with Images</option>
                <option value={3}>Images with Description</option>
                <option value={5}>Images with Text and Description</option>
              </select>
            </div>
            {/* <TextOnly ref={activeComponentRef} /> */}
            {state.render}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-danger">{Error}</p>
          <button className="btn pro-spaces-button3" onClick={submit}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PostToolBarv2;

const Topics = () => {
  const [show, setShow] = useState(false);
  const [topic, settopic] = useState([]);
  const [data, setdata] = useState([]);
  const [Prev, setPrev] = useState(false);
  const [all, setall] = useState(false);

  const submit = () => {
    console.log(topic);
    if (!Prev) {
      axios
        .post('/api/User/proForumUserTopic', { data: topic, all })
        .then((res) => {
          console.log('asd');
          notify('Changes saved !', true);
          handleClose();
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          notify('Something went wrong !', true);
        });
    } else {
      axios
        .put('/api/User/proFormUserupdate', { data: topic, all })
        .then((res) => {
          console.log(res.data);
          notify('Changes saved !', true);
          handleClose();
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          notify('Something went wrong !', true);
        });
    }
  };

  useEffect(() => {
    axios
      .get('/api/User/proForumUserTopicfind')
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          setall(res.data.allStatus);
          settopic(res.data.Topics);
          setPrev(true);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/User/proFormTopic')
      .then((res) => {
        console.log('Ad');
        console.log(res.data);
        setdata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {localStorage.getItem('Type')?.length ? (
        <>
          <div className="d-block d-md-none">
            <div className="w-100">
              <button
                className="btn w-100 px-0 pro-spaces-button "
                onClick={handleShow}
              >
                PRO-FORUM TOPICS
              </button>
            </div>
          </div>
          <div className="d-none d-md-block">
            <button
              className="btn pro-spaces-button "
              onClick={handleShow}
              style={{ maxWidth: '400px' }}
            >
              PRO-FORUM TOPICS
            </button>
          </div>
        </>
      ) : (
        // <div className='d-flex justify-content-center justify-content-md-end mt-2'>
        //     <button className='btn pro-spaces-button ' onClick={handleShow}>
        //         PRO-FORUM TOPICS
        //     </button>
        // </div>
        <></>
      )}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Topics</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value={'all'}
              id="flexCheckDefault"
              checked={all}
              onChange={(e) => {
                if (e.target.checked) {
                  setall(true);
                  settopic(data.map((elem) => elem._id));
                } else {
                  setall(false);

                  settopic([]);
                }
              }}
            />
            <label class="form-check-label" for="flexCheckDefault">
              All
            </label>
          </div>
          {data?.map((elem) => {
            return (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value={elem._id}
                  disabled={all}
                  id="flexCheckDefault"
                  checked={topic?.indexOf(elem._id) >= 0}
                  onChange={(e) => {
                    if (e.target.checked) {
                      settopic([...topic, e.target.value]);
                    } else {
                      let temp = [...topic];
                      temp.splice(temp.indexOf(e.target.value), 1);
                      settopic([...temp]);
                    }
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault">
                  {elem.Topic}
                </label>
              </div>
            );
          })}
          <div className="d-flex justify-content-end">
            <button className="btn pro-spaces-button3" onClick={submit}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const TextOnly = forwardRef((props, ref) => {
  const [Discription, setDiscription] = useState('');

  useImperativeHandle(ref, () => ({
    getData() {
      return { Discription };
    },
  }));
  return <TextComponent onChange={(e) => setDiscription(e.target.value)} />;
});

const ImageOnly = forwardRef((props, ref) => {
  const [Data, setData] = useState([]);

  const FileInput = (files) => {
    setData((prev) => [...prev, { title: '', file: files }]);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return { Data };
    },
  }));
  return (
    <div className="py-2">
      <MyCarousel data={Data} />
      <ImageUploadComponent onChange={FileInput} />
    </div>
  );
});

const TextWithImage = forwardRef((props, ref) => {
  const [Data, setData] = useState([]);

  const FileInput = (files) => {
    setData((prev) => [...prev, { title: '', file: files }]);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return { Data };
    },
  }));

  const ModifyData = (data, index) => {
    let temp = [...Data];
    temp[index].title = data;
    setData(temp);
  };

  const FileDelete = (data, index) => {
    let temp = [...Data];
    temp.splice(index, 1);
    setData(temp);
  };

  return (
    <div>
      <MyCarousel
        titleEnabled={true}
        data={Data}
        FileDelete={FileDelete}
        onChange={ModifyData}
      />
      <ImageUploadComponent onChange={FileInput} />
    </div>
  );
});
const ImageUploadWithDiscriptionComponent = forwardRef((props, ref) => {
  const [Data, setData] = useState([]);
  const [Discription, setDiscription] = useState('');

  const FileInput = (files) => {
    setData((prev) => [...prev, { title: '', file: files }]);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return { Discription, Data };
    },
  }));

  const ModifyData = (data, index) => {
    let temp = [...Data];
    temp[index].title = data;
    setData(temp);
  };

  return (
    <div>
      <MyCarousel titleEnabled={false} data={Data} onChange={ModifyData} />
      <ImageUploadComponent onChange={FileInput} />
      <TextComponent
        onChange={(e) => {
          setDiscription(e.target.value);
        }}
      />
    </div>
  );
});

const ImageUploadWithTextandDiscriptionComponent = forwardRef((props, ref) => {
  const [Data, setData] = useState([]);
  const [Discription, setDiscription] = useState('');

  const FileInput = (files) => {
    setData((prev) => [...prev, { title: '', file: files }]);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return { Discription, Data };
    },
  }));

  const ModifyData = (data, index) => {
    let temp = [...Data];
    temp[index].title = data;
    setData(temp);
  };

  return (
    <div>
      <MyCarousel titleEnabled={true} data={Data} onChange={ModifyData} />
      <ImageUploadComponent onChange={FileInput} />
      <TextComponent
        onChange={(e) => {
          setDiscription(e.target.value);
        }}
      />
    </div>
  );
});

const MyCarousel = ({ titleEnabled, data, onChange, FileDelete }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setActiveIndex((prevIndex) => (prevIndex + 1) % 3); // Adjust the number of slides here
  //     }, 2000); // Adjust the interval time as per your needs (in milliseconds)
  //     return () => clearInterval(interval);
  //   }, []);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };
  const preview = (file) => {
    return URL.createObjectURL(file);
  };

  return (
    <div className="caro-pro-249882">
      {data.length ? (
        <Carousel
          indicators={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          {data.map((elem, index) => {
            console.log(elem);
            if (elem.file.type.indexOf('image') != -1) {
              return (
                <Carousel.Item>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-close p-1"
                      onClick={() => FileDelete(data, index)}
                    ></button>
                  </div>
                  <img
                    className="d-block w-100 caro-toolbarv2"
                    src={preview(elem.file)}
                    alt="Image 1"
                  />
                  {titleEnabled && (
                    <div className="py-2">
                      <input
                        placeholder="Type your Image Description"
                        onChange={(e) => onChange(e.target.value, index)}
                        className="form-control"
                      ></input>
                    </div>
                  )}
                </Carousel.Item>
              );
            } else {
              return (
                <Carousel.Item>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-close p-1"></button>
                  </div>

                  <video
                    controls
                    className="d-block w-100 caro-toolbarv2"
                    alt="Image 1"
                  >
                    <source src={preview(elem.file)} />
                  </video>
                  {titleEnabled && (
                    <div className="py-2">
                      <input
                        placeholder="Type your Image Description"
                        onChange={(e) => onChange(e.target.value, index)}
                        className="form-control"
                      ></input>
                    </div>
                  )}
                </Carousel.Item>
              );
            }
          })}
        </Carousel>
      ) : (
        <></>
      )}
    </div>
  );
};
const TextComponent = ({ label, onChange }) => {
  const getData = () => { };
  return (
    <div className="form-group">
      <label htmlFor="exampleTextarea">{label}</label>
      <textarea
        onChange={onChange}
        className="form-control"
        id="exampleTextarea"
        placeholder="Type your Text"
        rows="3"
      ></textarea>
    </div>
  );
};

const ImageUploadComponent = ({ onChange }) => {
  const fileInputRef = useRef(null);

  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleOpen = () => {
    setshow(true);
  };
  const [ImageToCrop, setImageToCrop] = useState();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    if (event.target.files[0].type.indexOf('image') != -1) {
      let file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageToCrop(reader.result);
        handleOpen();
      };
      reader.readAsDataURL(file);
      // onChange(event.target.files)
    } else if (event.target.files[0].type.indexOf('video') != -1) {
      onChange(event.target.files[0]);
    }
    event.target.value = null;
  };
  const [cropper, setCropper] = useState();
  const crop = () => {
    if (typeof cropper !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          handleClose();
          onChange(blob);
          setCropper();
          setImageToCrop();
        },
        'image/jpeg',
        1
      );
    }
  };
  return (
    <>
      <div className="form-group">
        <div className="input-group">
          <input
            ref={fileInputRef}
            type="file"
            className="d-none"
            accept="image/*,video/*"
            onChange={handleImageUpload}
          />
          <div className="input-group-append">
            <button
              className="btn pro-spaces-button3"
              type="button"
              onClick={handleButtonClick}
            >
              Upload Image
              <AiFillCamera className="ps-2" size={24} />
            </button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} backdrop={'static'} centered>
        <Modal.Header>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ImageToCrop && (
            <>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={false}
                aspectRatio={4 / 3}
                preview=".img-preview"
                src={ImageToCrop}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={0}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
              <div>
                {' '}
                <button
                  type="button"
                  className="btn pro-spaces-button3 float-end"
                  onClick={crop}
                >
                  ok
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export const PostView = ({ isDisableFooter = false, ProForumTopic, handleShow }) => {
  const [Loading, setLoading] = useState(true);
  const [Posts, setPosts] = useState([]);
  const [Info, setInfo] = useState('');

  const FetchMessage = (last) => {
    console.log('object');
    setLoading(true);
    axios
      .get(`/api/Free/getposts?page=${Posts.length}`)
      .then((response) => {
        console.log(response);
        setPosts((prev) => [...prev, ...response.data]);
        if (response.data.length == 0) {
          if (Posts.length == 0) {
            setInfo('No posts to show !');
          } else {
            setInfo('No more post !');
          }
        }
      })
      .catch((res) => {
        notify('Something went wrong !', false);
      })
      .finally((e) => {
        setLoading(false);
      });
  };
  let prev = 0;
  async function ScrollListener(e) {
    console.log('lllllllllllll');
    let P = Math.abs(
      (e.target.scrollTop * 100) /
      (e.target.scrollHeight - e.target.clientHeight)
    );
    P = Math.round(P);
    console.log(P);
    prev = P;
    if (P >= prev) {
      if (P > 50) {
        console.log(Loading);
        if (!Loading) {
          FetchMessage();
        }
      }
    }
  }

  useEffect(() => {
    FetchMessage();
    return () => { };
  }, []);

  var loader = {
    width: '2rem',
    height: '2rem',
  };

  return (
    <div id="postFt"
      onScroll={(e) => ScrollListener(e)}
      // style={{ maxWidth: '600px' }}
      className="dfrty ijfsvwijiviwrvnvnjw  ">
      <div className="d-block d-md-none">
        <table className="w-100">
          <tr>
            <td rowspan="2">
              {ProForumTopic.length ? (
                <>
                  {localStorage.getItem('Type') === 'Consultant' ||
                    localStorage.getItem('Type') === 'Consultantv2' ||
                    localStorage.getItem('Type') === 'Product' ||
                    localStorage.getItem('Type') === 'Productv2' ? (
                    <button
                      onClick={handleShow}
                      className="btn pro-spaces-button3 px-3 w-100"
                      style={{ height: '75px' }}
                    >
                      ADD
                      <br />
                      NEW POST
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </td>
            <td colspan="2">
              {localStorage.getItem('Type') === 'Consultant' ||
                localStorage.getItem('Type') === 'Consultantv2' ||
                localStorage.getItem('Type') === 'Product' ||
                localStorage.getItem('Type') === 'Productv2' ? (
                <Link
                  to="/my-pro-forums"
                  onClick={handleShow}
                  className="btn  pro-spaces-button px-3 w-100"
                >
                  PRO-FORUM HISTORY
                </Link>
              ) : (
                <></>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Topics data={ProForumTopic} />
            </td>
          </tr>
        </table>
      </div>
      <div className="d-none d-md-block">
        <div className="d-flex justify-content-between">
          {ProForumTopic.length ? (
            <>
              {localStorage.getItem('Type') === 'Consultant' ||
                localStorage.getItem('Type') === 'Consultantv2' ||
                localStorage.getItem('Type') === 'Product' ||
                localStorage.getItem('Type') === 'Productv2' ? (
                <button
                  onClick={handleShow}
                  className="btn pro-spaces-button3 "
                  style={{ maxWidth: '400px' }}
                >
                  ADD NEW POST
                </button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {localStorage.getItem('Type') === 'Consultant' ||
            localStorage.getItem('Type') === 'Consultantv2' ||
            localStorage.getItem('Type') === 'Product' ||
            localStorage.getItem('Type') === 'Productv2' ? (
            <Link
              to="/my-pro-forums"
              onClick={handleShow}
              className="btn pro-spaces-button "
              style={{ maxWidth: '400px' }}
            >
              PRO-FORUM HISTORY
            </Link>
          ) : (
            <></>
          )}
          <Topics data={ProForumTopic} />
        </div>
      </div>
      <div className="container px-0">
        <div

        >
          {Posts.map((element, index) => {
            return (
              <PostSingle data={element} index={index} key={'post' + index} isDisableFooter={isDisableFooter} />
            );
          })}
          <div
            style={{ height: '90px', overflow: 'hidden', marginTop:'50px' }}
          >
            <div
              className={`${Loading ? '' : 'd-none'}`}

            >
              <div className="text-center ">
                {' '}
                <div style={loader} className="spinner-border text-dark"></div>
              </div>
            </div>
            <div 
            className={`${Loading ? 'd-none' : ''}`}
            >
             
                <p className="text-center">{Info}</p>
              
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export const PostSingle = ({ data, isDisableFooter = false, }) => {
  let { Description, Files, User, profilepic, Posted_On, _id, } = !!data && data

  const getRelativeDate = (posted) => {
    // return moment(posted).calendar().toString();

    return moment(posted).format('DD/MM/YY');
  };
  return (
    <div className="p-1 m-1  border bg-light postView mx-auto">
      <div className="d-flex justify-content-between py-2">
        <div className="row">
          <div className="col-auto">
            <img
              src={profilepic}
              style={{ borderRadius: 50 }}
              height={50}
              alt="prochure"
            />
          </div>
          <div className="col-auto px-0">
            <div className="d-flex flex-column">
              <span style={{ textTransform: 'capitalize' }}>
                {User[0]?.pname}
              </span>
              <span
                style={{
                  fontSize: '14px',
                  color: '#0000008f',
                  fontWeight: 500,
                }}
              >
                {getRelativeDate(Posted_On)}
              </span>
            </div>
          </div>
        </div>

        {/* <div className="d-flex align-items-center">
                    <Dropdown className="dropv2Head">
                        <Dropdown.Toggle className="dropV2" id="dropdown-basic">
                            <BsThreeDotsVertical color="black" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/">Test</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
      </div>
      <hr className="mt-0"></hr>

      <p style={{ whiteSpace: 'pre-line' }}>{Description}</p>
      <div className="caro-pro-249882">
        <Carousel
          indicators={false}
          controls={Files?.length > 1 ? true : false}
        >
          {Files.map((elem) => {
            if (elem.typeOf.indexOf('image') != -1) {
              return (
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img className=" img-fluid-for-post" src={elem.filename} />
                  </div>
                  <p style={{ whiteSpace: 'pre-line' }}>{elem.title}</p>
                </Carousel.Item>
              );
            }
            if (elem.typeOf.indexOf('video') != -1) {
              return (
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <video className="img-fluid-for-post" controls>
                      <source src={elem.filename}></source>
                    </video>
                  </div>
                  <p style={{ whiteSpace: 'pre-line' }}>{elem.title}</p>
                </Carousel.Item>
              );
            }
          })}
        </Carousel>
      </div>
      {!isDisableFooter &&
        <PostFooter postId={_id} />
      }
    </div>
  );
};
