import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Vertual.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineClose } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import axios from "axios";
import Moment from "moment";
import { Modal } from "react-bootstrap";
import { print_city, print_state } from "../../Utility/stateCity";
import { Events } from "./Events";

export function Virtualmob() {
  const [LogOp, setLogOp] = useState(true);
  const [tick, settick] = useState(false);
  const [load, setload] = useState(true);
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [city, setCity] = useState(false);
  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [error5, setError5] = useState("");
  const [col, setcol] = useState(false);
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);

  const [vertual1, setVertual1] = useState({
    task: "",
    taskdate: "",
    tick: false,
    tasktime: "",
  });
  const [vertual, setVertual] = useState({
    main: "",
    sub: "",
    state: "",
    conseltent: "",
    calldate: "",
    calltime: "",
    tick: false,
  });
  const [Submit, setSubmit] = useState(false);
  const [Stateload, setStateload] = useState(true);

  const [getval, setGetval] = useState(false);

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const sabari = (e) => {
    console.log(e.target.value);
    var anadhan;
    setVertual({ ...vertual, main: e.target.value });
    cat.forEach((element) => {
      console.log(element._id);
      if (element._id === e.target.value) {
        anadhan = element.sub.map((elements) => {
          return <option value={elements._id}>{elements.sub}</option>;
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  useEffect(() => {
    var urlforcategory;
    if (LogOp === true) {
      urlforcategory = "/api/api/getcategory";
    } else if (LogOp === false) {
      urlforcategory = "/api/api/getprocategory";
    }
    axios
      .get(urlforcategory)
      .then((res) => {
        if (res.status === 200) {
          console.log("workd");
          setCat(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LogOp]);

  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
    } else {
      console.log("nothing");
    }
  }, [cat]);

  const valid = () => {
    if (vertual1.task.length > 1) {
      setError5("");
    } else {
      return setError5("Enter a Task");
    }
    if (vertual1.taskdate.length > 1) {
      setError5("");
    } else {
      return setError5("Select a date");
    }
    return true;
  };

  const submit = (e) => {
    // console.log(vertual)
    // console.log(vertual1)
    console.log(e);

    var status = false;
    setSubmit(true);

    if (e === "f") {
      status = valid();
      if (status == true) {
        setloading(true);
        var virt = vertual1;
        console.log("first");
        axios
          .post("/api/User/virtualAdd", {
            virt,
          })
          .then((res) => {
            virget();
            setVertual1({
              task: "",
              taskdate: "",
              tick: false,
              tasktime: "",
            });
            setloading(false);
            notify("Task Added", true);
            console.log(res);
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            notify("Something went wrong", false);
          });
      }
    } else if (e === "s") {
      var dse = validation2();
      document.getElementById("fcb").click();

      if (!dse) {
        return;
      }
      setloading1(true);
      var virt = vertual;
      axios
        .post("/api/User/virtualAdd", {
          virt,
        })
        .then((res) => {
          virget();
          setVertual({
            main: "",
            sub: "",
            state: "",
            conseltent: "",
            calldate: "",
            calltime: "",
            tick: false,
          });
          setloading1(false);
          notify("Successfuly added", true);
          window.location.reload();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          notify("Something went wrong", false);
        });
    }

    // status = validation()
    // if (status == true)
    // console.log(value)
  };
  const virget = () => {
    axios
      .post("/api/User/virtualGet", {})
      .then((res) => {
        setGetval(res.data);
        // setloading(true);
        // setloading1(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    virget();
  }, []);

  useEffect(() => {
    console.log(getval);
  }, [getval]);

  const strike = (e, val, id) => {
    var ttr;
    // console.log(id);
    var sab = document.getElementById("e" + val);
    var erd = document.getElementById(e.target.id);
    console.log(sab);
    console.log(erd.id);
    if (!erd.checked) {
      sab.classList.remove("strike");
      ttr = false;
    } else {
      sab.className = "strike";
      ttr = true;
    }
    var data = {
      id,
      ttr,
    };
    axios
      .post("/api/User/virtualtick", {
        data,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const deletetask = (e, oid) => {
    var swe = { oid };

    axios
      .post("/api/User/virtualdelete", {
        swe,
      })
      .then(() => {
        virget();
        console.log("successfuly deleted");
        notify("Task deleted", true);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        notify("Something went wrong", false);
      });
  };

  const savetodo = (e, oid) => {
    var id = e.target.id.slice(0, -3);
    var task = document.getElementById(id + "tsk").value;
    var taskdate = document.getElementById(id + "tsd").value;
    var tasktime = document.getElementById(id + "tsde").value;

    var obj = {
      oid,
      task,
      taskdate,
      tasktime,
    };
    axios
      .put("/api/User/virtualupdate", {
        obj,
      })
      .then((res) => {
        console.log("successfuly updated", true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        notify("Something went wrong", false);
      });
  };

  function validation1() {
    if (vertual.main.length > 0) {
      setError("");
    } else {
      setError("*Select a category");
      return false;
    }

    if (vertual.sub.length > 0) {
      setError("");
    } else {
      setError("*Select a sub category");
      return false;
    }

    if (vertual.state.length > 0) {
      setError("");
    } else {
      setError("*Select a state");
      return false;
    }

    return true;
  }

  function validation2() {
    if (vertual.conseltent.length > 0) {
      setError1("");
      setcol(true);
    } else {
      setError1("*Select a Service Provider");
      setcol(false);
      return false;
    }

    if (vertual.calldate.length > 0) {
      setError1("");
      setcol(true);
    } else {
      setError1("*Select a date ");
      setcol(false);
      return false;
    }

    if (vertual.calltime.length > 0) {
      setError1("");
      setcol(true);
    } else {
      setError1("*Select a time");
      setcol(false);
      return false;
    }

    return true;
  }

  function Cityshow(e) {
    setCity(false);
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city("City", index);
    setVertual({ ...vertual, state: e.target.value });
  }

  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  });
  const [sw, setsw] = useState(false);
  const [op, setop] = useState(false);

  const disablefunction = () => {
    var wer = validation1();
    console.log(wer);
    if (!wer) {
      return;
    }
    console.log("d");
    if (!sw) {
      setsw(!sw);
      document.getElementById("State").setAttribute("disabled", true);
      document.getElementById("City").setAttribute("disabled", true);
      document.getElementById("sel2").setAttribute("disabled", true);
      document.getElementById("sel1").setAttribute("disabled", true);
      document.getElementById("sabari").innerHTML = "Select Again";
      document.getElementById("colbut").click();

      var subdata = vertual.sub;
      var stateeee = vertual.state;
      var cityeeee = city;
      var urlforsearch;
      if (LogOp === true) {
        urlforsearch = "/api/User/virtualfindcon";
      } else if (LogOp === false) {
        urlforsearch = "/api/User/virtualfindven";
      }
      axios
        .post(urlforsearch, {
          subdata,
          stateeee,
          cityeeee,
        })
        .then((res) => {
          if (res.status === 200) {
            setload(false);
            if (res.data.length > 0) {
              var frt = res.data.map((elements) => {
                return (
                  <option value={elements._id}>
                    {elements.firstname + " " + elements.lastname}
                  </option>
                );
              });
              setop(frt);
            } else {
              // document.getElementById('difselval').innerHTML = "No Consultants Fount"
            }
          }
        })
        .catch((err) => {
          setload(false);
          console.log(err);
        });
    } else {
      setsw(!sw);
      document.getElementById("State").removeAttribute("disabled", true);
      document.getElementById("City").removeAttribute("disabled", true);
      document.getElementById("sel2").removeAttribute("disabled", true);
      document.getElementById("sel1").removeAttribute("disabled", true);
      document.getElementById("sabari").innerHTML = "Next";
      document.getElementById("colbut").click();
      setop(false);
    }
  };

  const [Mobile, setMobile] = useState();
  function button() {
    var w = window.innerWidth;
    if (w < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }
  useEffect(() => {
    button();
  });
  window.onresize = button;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setEditIndex(undefined);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [EditIndex, setEditIndex] = useState(undefined);

  useEffect(() => {
    if (EditIndex >= 0) {
      handleShow();
    }
    return () => {};
  }, [EditIndex]);

  let newTime = useRef();
  let newDate = useRef();
  const saveChange = async () => {
    try {
      let calldate = newDate.current.value;
      let calltime = newTime.current.value;
      let id = getval[EditIndex]._id;
      let data = {
        calldate,
        calltime,
        id,
      };
      console.log(data);
      let reach = await axios.post("/api/User/updateVirtualPro", { data });
      if (reach) {
        let temp = [...getval];
        temp[EditIndex].calldate = calldate;
        temp[EditIndex].calltime = calltime;
        setGetval(temp);
        notify("Updated", true);
        handleClose();
      }
    } catch (error) {
      notify("Something went wrong !", false);
      console.log(error);
    }
  };
  const colorstyle = {
    color: "var(--blue)",
  };
  return (
    <div className="container fiwjgpjwjgwjgpwjfpxmpxm  d-block d-md-none">
      {/* <div className="row mb-2">
        <div className="col d-flex justify-content-end">
         
          <button
            type="button"
            className="btn pro-spaces-button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModefeqaeifhiehflsabari2wekjfg"
          >
            UNDERSTAND THIS FEATURE
          </button>
        </div>
      </div>

      <hr /> */}
      <div className="mt-4">
        <h4 style={colorstyle}>OFFICIAL / PERSONAL TASKS</h4>
        <p>
          Add an official task or a personal task such as purchase list,
          household chores, etc
        </p>
        <button
          className="btn btn-vs"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExampleaejfgug"
          aria-expanded="false"
          aria-controls="collapseExampleaejfgug"
        >
          Add a new task
        </button>
        <div className="collapse" id="collapseExampleaejfgug">
          <div className="card card-body eds mt-4 mb-3">
            <div>
              <div className="pt-3 g-1 row">
                <div className="col-12">
                  <label>Add a new task</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={vertual1.task}
                    onChange={(e) =>
                      setVertual1({ ...vertual1, task: e.target.value })
                    }
                  ></input>
                </div>
                <div className="col-12 ">
                  <label>Time</label>
                  <input
                    className="form-control "
                    type="time"
                    value={vertual1.tasktime}
                    onChange={(e) =>
                      setVertual1({
                        ...vertual1,
                        tasktime: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-12">
                  <label>Date</label>
                  <input
                    className="form-control "
                    type="date"
                    min={disablePastDate()}
                    value={vertual1.taskdate}
                    onChange={(e) =>
                      setVertual1({ ...vertual1, taskdate: e.target.value })
                    }
                  ></input>

                  <span style={{ color: "red" }}>{error5 ? error5 : ""}</span>
                </div>
              </div>
              <div className="row pt-3">
                <div className="d-flex justify-content-end">
                  {!loading && (
                    <button
                      className="btn pro-spaces-button3"
                      type="button"
                      onClick={(e) => submit("f")}
                    >
                      Add
                    </button>
                  )}
                  {loading && (
                    <button
                      className="btn pro-spaces-button3"
                      type="button"
                      disabled
                      onClick={(e) => submit("f")}
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Adding...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="mt-4">
        <h4 style={colorstyle}>BIRTHDAYS, ANNIVERASRIES, OCCASIONS</h4>
        <p>
          Add Birthdays, Anniversaries and other Occassions to set Reminders
          either permanently or for a day
        </p>
      </div>
      <Events notify={notify} />

      {/* <div className="ewhjbgfiuwbgfuowrnviwrnobviwr">


        </div> */}

      <div
        className="modal fade wekjgvuckugeqqjeqeojceucgh jrgfrjflobjgt  "
        id="exampleModefeqaeifhiehflsabari2wekjfg"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-black">
              <div className="weigh3rohbiwhbroiwehg">
                <AiOutlineClose
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></AiOutlineClose>
              </div>
            </div>
            <div className="modal-body bg-black">
              <div className="container">
                <div className="row">
                  <h4 className="parapara22">VIRTUAL SECRETARY</h4>
                </div>
                <div className="row">
                  <p>
                    Any member can use this feature to Assign personal and
                    professional Tasks and Set Reminders of Birthdays,
                    Anniversaries, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <hr />
      {/* space for shedule */}

      <div className="afmpnvanvznvknwfmslknv">
        {getval
          ? getval.map((Element, index) => {
              if (Element.task) {
                return (
                  <>
                    <div
                      key={'"' + keyGen() + '"'}
                      className="row p-2 bg-white"
                    >
                      <div className="col-1">{index + 1}.</div>
                      <div className="col-5 sbhugiug">
                        {Element.tick ? (
                          <h6 id={"e" + index} className="task strike">
                            {Element.task}
                          </h6>
                        ) : (
                          <h6 id={"e" + index} className="task">
                            {Element.task}
                          </h6>
                        )}
                      </div>
                      <div className="col-1">
                        {Element.tick ? (
                          <input
                            type="checkbox"
                            id={"check" + index}
                            className="form-check-input "
                            defaultChecked={true}
                            onChange={(e) => strike(e, index, Element._id)}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            id={"check" + index}
                            className="form-check-input "
                            onChange={(e) => strike(e, index, Element._id)}
                          />
                        )}
                      </div>

                      <div className="col-4">
                        {Moment(Element.taskdate.slice(0, 10)).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                      <div className="col-1">
                        <BiDotsVerticalRounded
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target={"#jjjjjjjt" + index}
                            >
                              Edit
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => deletetask(e, Element._id)}
                            >
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id={`jjjjjjjt${index}`}
                      tabIndex="-1"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Edit Task
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div>
                              <label>Task</label>
                              <input
                                id={Element.task + index + "tsk"}
                                type="text"
                                className="form-control"
                                defaultValue={Element.task}
                              />
                            </div>
                            <div>
                              <label className="mt-3">Date</label>
                              <input
                                id={Element.task + index + "tsd"}
                                type="date"
                                className="form-control "
                                defaultValue={Element.taskdate.slice(0, 10)}
                              />
                            </div>
                            <div>
                              <label className="mt-3">Time</label>
                              <input
                                id={Element.task + index + "tsde"}
                                type="time"
                                className="form-control"
                                defaultValue={Element.tasktime}
                              />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              id={Element.task + index + "btn"}
                              className="btn btn-vs"
                              onClick={(e) => savetodo(e, Element._id)}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              } else {
                return (
                  <>
                    <div
                      key={'"' + keyGen() + '"'}
                      className="row p-2 bg-white"
                    >
                      <div className="col-1">{index + 1}.</div>
                      <div className="col-5 sbhugiug">
                        {Element.tick ? (
                          <h6 id={"e" + index} className="task strike">
                            {Element.string[0].firstname +
                              " " +
                              Element.string[0].lastname}{" "}
                            [{Element.calltime}]
                          </h6>
                        ) : (
                          <h6 id={"e" + index} className="task">
                            {Element.string[0].firstname +
                              " " +
                              Element.string[0].lastname}{" "}
                            [{Element.calltime}]
                          </h6>
                        )}
                      </div>
                      <div className="col-1">
                        {Element.tick ? (
                          <input
                            type="checkbox"
                            id={"check" + index}
                            className="form-check-input "
                            defaultChecked={true}
                            onChange={(e) => strike(e, index, Element._id)}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            id={"check" + index}
                            className="form-check-input "
                            onChange={(e) => strike(e, index, Element._id)}
                          />
                        )}
                      </div>

                      <div className="col-4">
                        {Moment(Element.calldate.slice(0, 10)).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                      <div className="col-1">
                        <BiDotsVerticalRounded
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                setEditIndex(index);
                              }}
                            >
                              Edit
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => deletetask(e, Element._id)}
                            >
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              }
            })
          : ""}

        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {EditIndex >= 0 ? (
              <>
                <div className="p-1">
                  <label>Date</label>
                  <input
                    ref={newDate}
                    type={"date"}
                    min={disablePastDate()}
                    defaultValue={getval[EditIndex].calldate.slice(0, 10)}
                    className="form-control"
                  ></input>
                </div>
                <div className="p-1">
                  <label>Time</label>
                  <input
                    ref={newTime}
                    type={"time"}
                    defaultValue={getval[EditIndex].calltime}
                    className="form-control"
                  ></input>
                </div>
              </>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn pro-spaces-button3"
              variant="primary"
              onClick={(e) => saveChange()}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
