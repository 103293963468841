import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Pview.css";
import Moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./Edit";
import { useNavigate, useSearchParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetportExternal } from "./GetportExternal";
import { GetOfferExternal } from "./GetOfferExternal";
import { PersonalEntityInfornation } from "../../../Components/ProfileComponents/PersonalEntityInfornation";
import { QualificationsandEnrolmentInformation } from "../../../Components/ProfileComponents/QualificationsandEnrolmentInformation";
import { DetailedServiceInformation } from "../../../Components/ProfileComponents/DetailedServiceInformation";
import { WorkServiceRelatedInformation } from "../../../Components/ProfileComponents/WorkServiceRelatedInformation";
import { BussinessInformation } from "../../../Components/ProfileComponents/BussinessInformation";
import { ContactInformation } from "../../../Components/ProfileComponents/ContactInformation";
import { GetNewLaunchExternal } from "./GetNewLaunchExternal";
import { Mask } from "../../../Utility/mask";
import { HospitalsClinicsAssociatedWith } from "../../../Components/ProfileComponents/HospitalsClinicsAssociatedWith";
import { ShorotlistAccodian } from "../../../Components/ProfileComponents/ShorotlistAccodian";
import { TimeSelectBox } from "../../../Utility/timer";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { reportTags } from "../../../Utility/languages";
import { Dialog } from "@mui/material";
import { Selfdeclaration } from "../../../Components/Selfdeclaration/Selfdeclaration";
import { PersonalInformationStatic } from "../../../Components/ProfileComponents/PersonalInformationStatic";
import { bucketName } from '../../../config'

function ExternalPview(props) {
  useEffect(() => {
    props.Auth();
  }, []);
  var toogleButtonDummy = true;

  let [searchParams, setSearchParams] = useSearchParams();
  const [Follower, setFollower] = useState(false);
  let Navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const astyle = {
    color: "#0a58ca",
    textDecoration: "underline",
  };

  var Ac = Mask.decode(searchParams.get("Ac_id"));
  var idd = Mask.decode(searchParams.get("Ac_id"));
  var Acc = Mask.decode(searchParams.get("Ac_id"));

  const [value, setValue] = useState({
    time_1: "",
    time_2: "",
  });

  const [rateingfor, setrateingfor] = useState([]);
  useEffect(() => {
    axios
      .post("/api/User/getportfolio", {
        Acc,
      })
      .then((res) => {
        setrateingfor(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const enablechat = () => {
    document.getElementById("closebuttonforreportusgfugssug").click();
    document.getElementById("quicksettingmodalatag").click();
  };

  const communicate = (e) => {
    e.preventDefault();
    let st = e.target.elements.option.value === "cc" ? true : false;
    let id = Ac;
    document.getElementById("slr").click();
    let dataSend = {
      id,
      st,
    };
    e.target.reset();
    axios
      .post("/api/User/communicate", { dataSend })
      .then((res) => {
        if (res.status === 200) {
          Navigate("/cubicles?consultantTrue=" + res.data);
          console.log(res);
        } else {
          Navigate("/cubicles?consultantTrue=" + res.data);
        }
      })
      .catch((res) => {
        console.log(res.response);
        if (res.response.status === 400) {
          // Navigate('/cubicles?consultantTrue='+id)
        } else if (res.response.status === 401) {
          // TOAST SOMETHING WRONG SAME PERSON
        }
      });
  };

  const [shortlistcheck, setshortlistcheck] = useState(false);
  const checkaeijfpi = () => {
    var id = Ac;
    try {
      if (document.getElementById(id).checked) {
        axios
          .post("/api/User/selectcon", {
            id,
          })
          .then((res) => {
            setmappas(true);
            setshortlistcheck((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post("/api/User/disselectcon", {
            id,
          })
          .then((res) => {
            setmappas(false);
            setshortlistcheck((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkaeijfpi2 = () => {
    var id = Ac;
    try {
      if (document.getElementById(id + "2").checked) {
        axios
          .post("/api/User/selectcon", {
            id,
          })
          .then((res) => {
            setmappas(true);
            setshortlistcheck((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post("/api/User/disselectcon", {
            id,
          })
          .then((res) => {
            setmappas(false);
            setshortlistcheck((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [mappas, setmappas] = useState();

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();
  const [isLogo, setIsLogo] = useState(false)
  const [logo, setLogo] = useState(false)
  const [first, setfirst] = useState(false);
  useEffect(() => {
    var accid = Acc;
    axios
      .post("/api/User/rateingforconCheck", {
        accid,
      })
      .then((res) => {
        console.log(res.data);
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Clickhandler = (cont) => {
    console.log(cont);
    if (cont == "image") {
      document.getElementById("image").click();
    } else {
      document.getElementById("video").click();
    }
  };

  const addreating = (e, status) => {
    // console.log(id)
    if (e.target.checked) {
      var status = status;
      axios
        .post("/api/User/rateingforcon", {
          status,
          idd,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .delete("/api/User/rateingdelcon", {
          data: {
            status,
            idd,
          },
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function lossupennu(e, ind) {
    dopper[ind].File.map((element1, index) => {
      document.getElementById("trkor" + index + ind).classList.remove("d-none");
    });

    document.getElementById("trkorrr" + ind).classList.remove("d-none");
    // document.getElementById("uhf"+ind).classList.remove('d-none')
    document.getElementById("gana" + ind).removeAttribute("disabled");
  }

  function pyari(e, ind) {
    dopper[ind].File.map((element1, index) => {
      document.getElementById("trkor" + index + ind).classList.add("d-none");
    });
    document.getElementById("trkorrr" + ind).classList.add("d-none");
    // document.getElementById("uhf"+ind).classList.add('d-none')
    document.getElementById("gana" + ind).setAttribute("disabled", true);
  }

  function cater(e) {
    // console.log(e.target.value)
    setforume(e.target.value);
  }
  useEffect(() => {
    console.log(forume);
  }, [forume]);

  var sab;

  if (getval.experience > 1) {
    sab = "years";
  } else {
    sab = "year";
  }

  var date = Moment(getval.dob).format("DD-MM-YYYY");

  function jvur(e, fr) {
    document.getElementById(fr).click();
  }

  // update port
  // console.log(idd)

  // init\
  const [button, setButton] = useState();
  useEffect(() => {
    axios
      .post("/api/User/ConProfileexterbal", {
        idd,
      })
      .then((res) => {
        setGetval(res.data[0]);
        const uniqueValue = new Date().getTime();
        setLogo(bucketName + `/${idd}/company_logo.png?timestamp=${uniqueValue}`)
        setEmailRef((prev) => ({
          ...prev,
          sub: res?.data[0]?.string2[0]?.sub,
        }));
        setButton(res.data[0]?.string2[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [myarr, setMyarr] = useState(false);
  const [age, setage] = useState();

  const notify = (message, stat) => {
    // console.log(message);
    // console.log(stat)

    if (stat == true) {
      // console.log("toasfted");
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == false) {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Content, setContent] = useState();

  var init = "";

  function Preview(data) {
    for (var i = 0; i < data.length; i++) {}
  }

  ////////////////////Anantha krishnan S/////////////////
  const [EmailRef, setEmailRef] = useState({
    email: [],
    subject: "",
    massage: "",
    sub: "",
    id: Acc,
  });
  const makeEmail = (x, Lorum) => {
    console.log(Lorum);
    console.log(x);
    x = document.getElementById(x);
    x.style.color = "black";
    var val = x.value;
    if (val.charAt(val.length - 1) === " ") {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
    if (Lorum) {
      var email = val.trim();
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = "";
        // return console.log(skill.length+fina)
        x.style.color = "black";
        addEmail(email);
      } else {
        x.style.color = "red";
      }
    }
  };

  const [Email, setEmail] = useState([]);
  const [Arr, setArr] = useState([]);
  var listItems = "";
  const [AllEmail, setAllEmail] = useState([]);

  const addEmail = (vall) => {
    vall = vall.trim();
    var dep = AllEmail;
    dep.push(vall);
    setAllEmail(dep);
    var x = React.createElement(
      "div",
      {
        className:
          "p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1",
        role: "alert",
      },
      [
        React.createElement(
          "strong",
          {},
          vall,
          <>&nbsp;&nbsp;&nbsp;</>,
          React.createElement(
            "button",
            {
              onClick: (e) => removeEmail(vall),
              type: "button",
              className: "btn-close ms-5 Gbt p-0 pt-2 ",
              "data-bs-dismiss": "alert",
              "aria-label": "Close",
            },
            ""
          )
        ),
      ]
    );
    Arr.push(x);
    listItems = Arr.map((myList) => {
      return <>{myList}</>;
    });
    setEmail(listItems);
  };

  useEffect(() => {
    setEmailRef({ ...EmailRef, email: AllEmail });
  }, [AllEmail]);

  const removeEmail = (e) => {
    var index = AllEmail.indexOf(e);
    AllEmail.splice(index, 1);
    setEmailRef({ ...EmailRef, email: AllEmail });
  };

  const [error, seterror] = useState(false);

  const validation = () => {
    if (EmailRef.email.length > 0) {
      seterror("");
    } else {
      return seterror("PLEASE ADD a Email Id");
    }
    if (EmailRef.massage.length > 0) {
      seterror("");
    } else {
      return seterror("PLEASE ENTER Message");
    }

    return true;
  };

  const [submittrue, setsubmittrue] = useState(false);
  const SendRef = (e) => {
    setsubmittrue((prev) => !prev);
    console.log("haii");
    console.log(EmailRef);
    var status = false;
    status = validation();
    if (status) {
      setloading(true);
      axios
        .post("/api/User/Refer", {
          EmailRef,
        })
        .then((res) => {
          console.log(res.data);
          //  alert("Refereed successfully !!!!!")
          if (res.status === 200) {
            document.getElementById("poweclose").click();
            notify("Referred successfully", true);
            setloading(false);
            setEmailRef({
              email: [],
              subject: "",
              massage: "",
              sub: getval.string2[0].sub ? getval.string2[0].sub : "",
              id: Acc,
            });
            setAllEmail([]);
            setEmail([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
          document.getElementById("poweclose").click();
          notify("Something went wrong !", false);
        });
    }
  };

  useEffect(() => {
    if (submittrue) {
      validation();
    }
  });

  useEffect(() => {
    try {
      if (first) {
        if (first.good) {
          document.getElementById("flexCheckDefault12").checked = true;
        }
        if (first.high) {
          document.getElementById("flexCheckDefault122").checked = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [first]);

  const [referCount, setreferCount] = useState(false);
  const [yeser, setyeser] = useState(false);

  useEffect(() => {
    var ids = Acc;
    axios
      .post("/api/User/ReferCount", {
        ids,
      })
      .then((res) => {
        if (res.data === "0" || res.data === 0) {
          return;
        } else if (res.data === "1" || res.data === 1) {
          setreferCount(res.data);
          setyeser("time");
        } else {
          setreferCount(res.data);
          setyeser("times");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);
  console.log(getval.followers);

  const [first8, setfirst8] = useState(false);

  const [sabari, setsabari] = useState({
    report: "",
    tags: [],
  });
  const [sabarierr, setsabarierr] = useState("");

  const reprtsabari = () => {
    if (sabari.report.length === 0) {
      return setsabarierr("Please enter a reason");
    } else {
      axios
        .post("api/User/reportabuse", {
          sabari,
          Acc,
        })
        .then((res) => {
          document.getElementById("closebuttonforreport").click();
          notify("Reported", true);
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("closebuttonforreport").click();
          notify("Something went wrong !", false);
        });
    }
  };

  const [CommunicationStatus, setCommunicationStatus] = useState("Loading");

  useEffect(() => {
    axios
      .post("/api/User/CommunicationStatus", { id: Acc })
      .then((res) => {
        console.log(res);
        setCommunicationStatus(res.data.Chat);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(CommunicationStatus);
    return () => {};
  }, []);

  const [firstst, setfirstst] = useState();
  const [firstst2, setfirstst2] = useState();

  useEffect(() => {
    try {
      let id = Ac;
      console.log(Ac);
      axios
        .post("/api/User/selselectcon", {
          id,
        })
        .then((res) => {
          if (res.status === 200) {
            try {
              document.getElementById(id).checked = true;
              document.getElementById(id + "2").checked = true;
              // setFollower(true);
              setmappas(true);
              console.log(res.status);
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch((err) => {
          try {
            console.log(err);
            setmappas(false);
            document.getElementById(id).checked = false;
            console.log(id);
            document.getElementById(id + "2").checked = false;
          } catch (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
    // FollowOrNot(idd);
  }, [shortlistcheck]);

  const [inProgress, setinProgress] = useState(false);
  const [ErrorBookAppoinment, setErrorBookAppoinment] = useState();

  const bookAppoinment = async (e) => {
    try {
      e.preventDefault();
      setinProgress(true);
      if (!Ac) throw new Error("Invalid recipient");
      console.log(e.target.elements);
      let dataCollected = e.target.elements;
      console.log(value);
      let data = {
        recipient: Ac,
        date: dataCollected.date.value,
        urgent: dataCollected.urgent.checked,
        purpose: dataCollected.purpose.value.trim(),
        mentionInfo: dataCollected.mentionInfo.checked,
        time_1: value.time_1,
        time_2: value.time_2,
      };

      if (!data.date) {
        setinProgress(false);
        return setErrorBookAppoinment("Please Select a Date !");
      }
      if (!data.time_1 || !data.time_2) {
        setinProgress(false);
        return setErrorBookAppoinment("Please Select a Preferred Time Slot !");
      }
      if (!data.purpose) {
        setinProgress(false);
        return setErrorBookAppoinment("Please Enter Purpose of Visit !");
      }
      setErrorBookAppoinment("");

      let result = await axios.post("/api/ActiveUser/bookAppoinment", { data });
      console.log(result);
      setinProgress(false);
      e.target.reset();
      document.getElementById("slranan").click();
      if (result.status === 200) {
        notify("Completed", true);
        console.log("done");
      } else {
        notify("Something went wrong !", false);
      }
    } catch (error) {
      console.log(error);
      setinProgress(false);

      notify("Something went wrong !", false);
    }
  };

  useEffect(() => {
    console.log(localStorage.getItem("Chat"));

    return () => {};
  }, [localStorage.getItem("Chat")]);

  const [Low, setLow] = useState(0);

  return (
    <>
      <div>
        <div className="container emp-profile border ">
          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned && (
                  <div className="col-12 col-md-auto">
                    <img width="30" height={30} src="/wpower.png" alt="w" />
                    &nbsp;WOMAN ENTREPRENEUR
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 col-md-auto">
                    {" "}
                    <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                    <span className="notranslate">AUTHENTIC !</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-2">
              <div className="row  ">
                {getval.WomanOwned && (
                  <div className="col-12 ">
                    <center>
                      <img width="30" height={30} src="/wpower.png" alt="w" />
                      &nbsp;WOMAN ENTREPRENEUR
                    </center>
                  </div>
                )}
                {getval.verified && (
                  <div className="col-12 ">
                    {" "}
                    <center>
                      <span className="puskaran2 notranslate">PRO-</span>&nbsp;
                      <span className="notranslate">AUTHENTIC !</span>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-center">
                <div style={{ position: 'relative' }}>
                <img
                  id="DP"
                  className="new-vp-image"
                  src={getval.link}
                  alt=""
                />
                {logo && !isLogo &&
                    <img className="new-card-logo" src={logo} onError={() => setIsLogo(true)} />
                }
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5 className="mb-1">
                    {getval.pname ? getval.pname.toUpperCase() : ""}
                  </h5>
                  <p className="mt-0 pt-0 mb-2">
                    {getval?.brandtagline
                        ? getval.brandtagline
                        : ""}
                  </p>
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5 className="mb-1">
                      {getval.pname ? getval.pname.toUpperCase() : ""}
                    </h5>
                    <p className="mt-0 pt-0 mb-2">
                      {getval?.brandtagline
                          ? getval.brandtagline
                          : ""}
                    </p>
                  </center>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string
                          ? getval.string2[0].sub +
                            " | " +
                            getval.string[0].category
                          : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        <h6>
                          {getval.string
                            ? getval.string2[0].sub +
                              " | " +
                              getval.string[0].category
                            : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                  <div className="row pe-0">
                    <div className="col pe-0">
                      <div className="d-none d-md-block">
                        {CommunicationStatus === true && (
                          <>
                            <input
                              type="submit"
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ borderRadius: "25px" }}
                              name="btnAddMore"
                              value="COMMUNICATE VIA CHAT"
                              data-bs-toggle="modal"
                              data-bs-target={`${
                                localStorage.getItem("Chat") === "false"
                                  ? "#turnonchat23423"
                                  : "#communi"
                              }`}
                            />
                          </>
                        )}

                        {toogleButtonDummy ? (
                          <>
                            {button?.book_an_appointment ? (
                              <input
                                type="submit"
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="BOOK AN APPOINTMENT"
                                data-bs-toggle="modal"
                                data-bs-target={`${
                                  localStorage.getItem("Chat") === "false"
                                    ? "#turnonchat23423"
                                    : "#bookappoimentmodal"
                                }`}
                                // data-bs-target='#bookappoimentmodal'
                              />
                            ) : (
                              <></>
                            )}
                            {button?.book_a_table ? (
                              <input
                                type="submit"
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="BOOK A TABLE"
                                data-bs-toggle="modal"
                                data-bs-target={`${
                                  localStorage.getItem("Chat") === "false"
                                    ? "#turnonchat23423"
                                    : "#bookatablemodal"
                                }`}
                                // data-bs-target='#bookatablemodal'
                              />
                            ) : (
                              <></>
                            )}
                            {button?.place_an_order ? (
                              <input
                                type="submit"
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="PLACE AN ORDER"
                                data-bs-toggle="modal"
                                data-bs-target={`${
                                  localStorage.getItem("Chat") === "false"
                                    ? "#turnonchat23423"
                                    : "#placeanordermodal"
                                }`}
                                // data-bs-target='#placeanordermodal'
                              />
                            ) : (
                              <></>
                            )}
                            {button?.make_a_purchase ? (
                              <input
                                type="submit"
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="MAKE A PURCHASE"
                                data-bs-toggle="modal"
                                data-bs-target={`${
                                  localStorage.getItem("Chat") === "false"
                                    ? "#turnonchat23423"
                                    : "#makeapurchasemodal"
                                }`}
                                // data-bs-target='#makeapurchasemodal'
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="d-block d-md-none">
                        <center>
                          {CommunicationStatus === true && (
                            <>
                              <input
                                type="submit"
                                className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                style={{ borderRadius: "25px" }}
                                name="btnAddMore"
                                value="COMMUNICATE VIA CHAT"
                                data-bs-toggle="modal"
                                data-bs-target={`${
                                  localStorage.getItem("Chat") === "false"
                                    ? "#turnonchat23423"
                                    : "#communi"
                                }`}
                              />
                            </>
                          )}
                          {toogleButtonDummy ? (
                            <>
                              {button?.book_an_appointment ? (
                                <input
                                  type="submit"
                                  className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                  style={{ borderRadius: "25px" }}
                                  name="btnAddMore"
                                  value="BOOK AN APPOINTMENT"
                                  data-bs-toggle="modal"
                                  data-bs-target={`${
                                    localStorage.getItem("Chat") === "false"
                                      ? "#turnonchat23423"
                                      : "#bookappoimentmodal"
                                  }`}
                                  // data-bs-target='#bookappoimentmodal'
                                />
                              ) : (
                                <></>
                              )}
                              <div>
                                {button?.book_a_table ? (
                                  <input
                                    type="submit"
                                    className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                    style={{ borderRadius: "25px" }}
                                    name="btnAddMore"
                                    value="BOOK A TABLE"
                                    data-bs-toggle="modal"
                                    data-bs-target={`${
                                      localStorage.getItem("Chat") === "false"
                                        ? "#turnonchat23423"
                                        : "#bookatablemodal"
                                    }`}
                                    // data-bs-target='#bookatablemodal'
                                  />
                                ) : (
                                  <></>
                                )}
                                {button?.place_an_order ? (
                                  <input
                                    type="submit"
                                    className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                    style={{ borderRadius: "25px" }}
                                    name="btnAddMore"
                                    value="PLACE AN ORDER"
                                    data-bs-toggle="modal"
                                    data-bs-target={`${
                                      localStorage.getItem("Chat") === "false"
                                        ? "#turnonchat23423"
                                        : "#placeanordermodal"
                                    }`}
                                    // data-bs-target='#placeanordermodal'
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                              {button?.make_a_purchase ? (
                                <input
                                  type="submit"
                                  className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                                  style={{ borderRadius: "25px" }}
                                  name="btnAddMore"
                                  value="MAKE A PURCHASE"
                                  data-bs-toggle="modal"
                                  data-bs-target={`${
                                    localStorage.getItem("Chat") === "false"
                                      ? "#turnonchat23423"
                                      : "#makeapurchasemodal"
                                  }`}
                                  // data-bs-target='#makeapurchasemodal'
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ms-3">
                  {getval.Type === "Consultantv3" ? (
                    <div className="form-check col-12 d-flex justify-content-start mb-3  pe-0 invisible">
                      <input
                        className="form-check-input float-end checkboxpro me-3 mt-3"
                        type="checkbox"
                        value=""
                        id={Ac}
                        onClick={(e) => checkaeijfpi(e)}
                      />
                      <span className="iorughoisrhgiohs">
                        {!mappas ? (
                          <span>Short&#8209;list&nbsp;this&nbsp;Profile</span>
                        ) : (
                          "Short-listed"
                        )}
                      </span>
                    </div>
                  ) : (
                    <div className="form-check col-12 d-flex justify-content-start mb-3 pe-0">
                      <input
                        className="form-check-input float-end checkboxpro me-3 mt-3"
                        type="checkbox"
                        value=""
                        id={Ac}
                        onClick={(e) => checkaeijfpi(e)}
                      />
                      <span className="iorughoisrhgiohs">
                        {!mappas ? (
                          <span>Short&#8209;list&nbsp;this&nbsp;Profile</span>
                        ) : (
                          "Short-listed"
                        )}
                      </span>
                    </div>
                  )}
                  <div className="col-3 col-md-3 col-lg-5 d-none"></div>
                </div>
                <div
                  className="modal fade"
                  id="reportthismodal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  data-bs-backdrop="static"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Report this Profile
                        </h5>
                        <button
                          type="button"
                          id="closebuttonforreport"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                        <p>
                          Please BE INFORMED that you must Report Abuse about a
                          Profile ONLY for a genuine valid reason which you MUST
                          mention with evidence.
                        </p>
                        <p>
                          <b>
                            Fake / False reporting about a member as an act of
                            prank will result in deactivation of your membership
                            without refund.
                          </b>
                        </p>
                        <p>Why are you Reporting this Profile ?</p>
                        <span>This is :</span>
                        <FormControl
                          className="form-control"
                          style={{ zIndex: 99999999999 }}
                        >
                          <>
                            <Select
                              id="demo-multiple-checkbox"
                              multiple
                              className="form-select"
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  display: "none",
                                },
                                "& .MuiSvgIcon-root": { display: "none" },
                                "& .MuiSelect-select": { padding: "0" },
                              }}
                              value={sabari.tags}
                              onChange={(e) =>
                                setsabari({ ...sabari, tags: e.target.value })
                              }
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) => selected.join(", ")}
                            >
                              {reportTags.map((languages) => (
                                <MenuItem key={languages} value={languages}>
                                  <Checkbox
                                    checked={
                                      sabari.tags.indexOf(languages) > -1
                                    }
                                  />
                                  <ListItemText primary={languages} />
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        </FormControl>

                        <textarea
                          className="form-control mt-2"
                          rows={6}
                          onChange={(e) =>
                            setsabari({ ...sabari, report: e.target.value })
                          }
                          placeholder="Type your Reason"
                        ></textarea>
                        <p className="text-danger">
                          {sabarierr ? sabarierr : ""}
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn pro-spaces-button3"
                          onClick={reprtsabari}
                        >
                          Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="turnonchat23423"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          You need to Activate Chat to communicate with this
                          member.
                        </h5>
                        <button
                          type="button"
                          id="closebuttonforreportusgfugssug"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                        <p>
                          Your One-to-One Chatting feature is currently
                          Disabled.
                        </p>
                        <p
                          style={astyle}
                          onClick={enablechat}
                          to="/profile_details_and_settings"
                        >
                          Enable Chatting now
                        </p>
                      </div>
                      <div className="modal-footer">
                        {/* <button type="button" className="btn pro-spaces-button3" onClick={reprtsabari}>Report</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {getval.Type === "Consultantv3" ? (
                  <>
                    <ShorotlistAccodian
                      checkaeijfpi2={checkaeijfpi2}
                      addreating={addreating}
                      makeEmail={makeEmail}
                      setEmailRef={setEmailRef}
                      SendRef={SendRef}
                      mappas={mappas}
                      getval={getval}
                      referCount={referCount}
                      yeser={yeser}
                      first={first}
                      EmailRef={EmailRef}
                      Ac={Ac}
                      Email={Email}
                      error={error}
                      loading={loading}
                    />
                    <Accordion
                      expanded={expanded === "panelewrg4"}
                      onChange={handleChange("panelewrg4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography sx={{ width: "100%", flexShrink: 0 }}>
                          <b>Service Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p
                            style={{ whiteSpace: "pre-line" }}
                            className="py-2"
                          >
                            {getval.Description}
                          </p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <PersonalInformationStatic getval={getval} vendor={false} />
                    <BussinessInformation getval={getval} />
                    <ContactInformation getval={getval} />
                    {/* <ShorotlistAccodian checkaeijfpi2={checkaeijfpi2} addreating={addreating} makeEmail={makeEmail} setEmailRef={setEmailRef} SendRef={SendRef} mappas={mappas} getval={getval} referCount={referCount} yeser={yeser} first={first} EmailRef={EmailRef} Ac={Ac} Email={Email} error={error} loading={loading} /> */}
                  </>
                ) : getval.formType ? (
                  getval.formType === 1 ? (
                    <>
                      <ShorotlistAccodian
                        checkaeijfpi2={checkaeijfpi2}
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Works / Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 2 ? (
                    <>
                      <ShorotlistAccodian
                        checkaeijfpi2={checkaeijfpi2}
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Works / Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 3 ? (
                    <>
                      <ShorotlistAccodian
                        checkaeijfpi2={checkaeijfpi2}
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      {getval.Profile === "Individual" ? (
                        <PersonalEntityInfornation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 4 ? (
                    <>
                      <ShorotlistAccodian
                        checkaeijfpi2={checkaeijfpi2}
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio of Services</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <DetailedServiceInformation getval={getval} />
                      <WorkServiceRelatedInformation getval={getval} />
                      {getval.Profile === "Individual" ? (
                        <PersonalEntityInfornation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <BussinessInformation getval={getval} />
                      <ContactInformation getval={getval} />
                    </>
                  ) : getval.formType === 5 ? (
                    <>
                      <ShorotlistAccodian
                        checkaeijfpi2={checkaeijfpi2}
                        addreating={addreating}
                        makeEmail={makeEmail}
                        setEmailRef={setEmailRef}
                        SendRef={SendRef}
                        mappas={mappas}
                        getval={getval}
                        referCount={referCount}
                        yeser={yeser}
                        first={first}
                        EmailRef={EmailRef}
                        Ac={Ac}
                        Email={Email}
                        error={error}
                        loading={loading}
                      />
                      {getval.Profile === "Private" ||
                      getval.Profile === "Privateand" ? (
                        <DetailedServiceInformation getval={getval} />
                      ) : (
                        <></>
                      )}
                      <WorkServiceRelatedInformation getval={getval} />
                      <QualificationsandEnrolmentInformation getval={getval} />
                      <PersonalEntityInfornation getval={getval} />

                      {getval.Profile === "Private" ? (
                        <>
                          {/* Private */}
                          <BussinessInformation getval={getval} />
                          <ContactInformation getval={getval} />
                        </>
                      ) : getval.Profile === "Associated" ? (
                        <>
                          {/* Associated */}
                          <BussinessInformation getval={getval} />
                          {/* <ContactInformation getval={getval} /> */}
                        </>
                      ) : (
                        <>
                          {/* Privateand */}
                          <BussinessInformation getval={getval} />
                          <HospitalsClinicsAssociatedWith getval={getval} />
                          {/* <ContactInformation getval={getval} /> */}
                        </>
                      )}

                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            <b>Portfolio</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <GetportExternal Acc={Acc} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {getval ? (
                        getval.Type === "Consultant" ||
                        getval.Type === "Consultantv2" ? (
                          <>
                            {firstst === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel3322"}
                                onChange={handleChange("panel3322")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      <span className="blink_me">
                                        NEWLY LAUNCHED
                                      </span>{" "}
                                      Service
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetNewLaunchExternal
                                      Acc={Acc}
                                      setfirstst={setfirstst}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {firstst2 === 0 ? (
                              <></>
                            ) : (
                              <Accordion
                                expanded={expanded === "panel332"}
                                onChange={handleChange("panel332")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <Typography
                                    sx={{ width: "100%", flexShrink: 0 }}
                                  >
                                    <b>
                                      Exclusive{" "}
                                      <span className="blink_me">
                                        OFFERS / DISCOUNTS
                                      </span>
                                    </b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <GetOfferExternal
                                      Acc={Acc}
                                      setfirstst2={setfirstst2}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {/* <ShorotlistAccodian
                              checkaeijfpi2={checkaeijfpi2}
                              addreating={addreating}
                              makeEmail={makeEmail}
                              setEmailRef={setEmailRef}
                              SendRef={SendRef}
                              mappas={mappas}
                              getval={getval}
                              referCount={referCount}
                              yeser={yeser}
                              first={first}
                              EmailRef={EmailRef}
                              Ac={Ac}
                              Email={Email}
                              error={error}
                              loading={loading}
                            /> */}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  ""
                )}
                <Selfdeclaration type="vdp" />
              </div>
            </center>
          </div>
          <button
            className="d-none"
            id="prochureButtonSpecialPviewRe"
            onClick={(e) => {
              setLow((rev) => rev + 1);
            }}
          >
            up
          </button>
        </div>
        <div
          className="modal fade"
          id="communi"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Specify your Requirement *
                </h5>
                <button
                  id="slr"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form onSubmit={(e) => communicate(e)}>
                <div className="modal-body">
                  <div className="form-check  ms-5 mt-2 mb-3">
                    <input
                      type="radio"
                      className="form-check-input"
                      value="cc"
                      id="flexCheckDefault"
                      name="option"
                      // onChange={(e) => communicate(e, true)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Consultation (as a Client)
                    </label>
                  </div>
                  <div className="form-check ms-5 mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="si"
                      name="option"
                      id="flexCheckDefaultw"
                      // onChange={(e) => communicate(e, false)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefaultw"
                    >
                      Service Introduction
                    </label>
                  </div>
                  <div className="form-check ms-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="pm"
                      name="option"
                      id="flexCheckDefaulte"
                      // onChange={(e) => communicate(e, false)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefaulte"
                    >
                      Product Marketing
                    </label>
                  </div>
                  {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
                </div>

                <div class="modal-footer">
                  <button
                    className="btn pro-spaces-button2 float-end"
                    type="submit"
                  >
                    Go
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Book An Appoiment */}
        <div
          className="modal fade"
          id="bookappoimentmodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  BOOK AN APPOINTMENT
                </h6>
                <button
                  id="slranan"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form onSubmit={(e) => bookAppoinment(e)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <label className="labels">Date *</label>
                      <input name="date" type="date" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label className="labels">Preferred Time Slot *</label>

                      <div className="row">
                        <div className="col-5">
                          <select
                            className="form-select"
                            value={value.time_1}
                            onChange={(e) =>
                              setValue({ ...value, time_1: e.target.value })
                            }
                          >
                            <TimeSelectBox />
                          </select>
                        </div>
                        <div className="col-2 d-flex justify-content-center pt-2 p-0">
                          to
                        </div>
                        <div className="col-5">
                          <select
                            className="form-select"
                            value={value.time_2}
                            onChange={(e) =>
                              setValue({ ...value, time_2: e.target.value })
                            }
                          >
                            <TimeSelectBox />
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label className="labels">Purpose of Visit *</label>
                      <textarea
                        rows={3}
                        name="purpose"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-check  mt-2 mb-3">
                    <input
                      className="form-check-input mt-2"
                      type="checkbox"
                      value=""
                      name="mentionInfo"
                      id="flexChsourgeckDefault"
                    />
                    <label className="labels" htmlFor="flexChsourgeckDefault">
                      Please mention your consultation fees / service charges
                      for my requirement
                    </label>
                  </div>
                  <div className="form-check  mb-3">
                    <input
                      className="form-check-input mt-2"
                      type="checkbox"
                      name="urgent"
                      value=""
                      id="flexChecugfwwkDefault"
                    />
                    <label className="labels" htmlFor="flexChecugfwwkDefault">
                      This is an Urgent Requirement
                    </label>
                  </div>
                  {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
                  <p className="text-danger">{ErrorBookAppoinment}</p>
                </div>
                <div class="modal-footer">
                  {inProgress ? (
                    <button disabled={true} class="btn pro-spaces-button3">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button type="submit" class="btn pro-spaces-button3">
                      SEND REQUEST
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* BOOK A TABLE */}
        <div
          className="modal fade"
          id="bookatablemodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  BOOK A TABLE
                </h6>
                <button
                  id="slr"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <label className="labels">Date *</label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="labels">Time *</label>
                    <input type="time" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="labels">Table for *</label>
                    <input
                      type="number"
                      placeholder="Mention number of people"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn pro-spaces-button3">
                  SEND REQUEST
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* PLACE AN ORDER */}
        <div
          className="modal fade"
          id="placeanordermodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  PLACE AN ORDER
                </h6>
                <button
                  id="slr"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <label className="labels">Type of Order *</label>
                    <select className="form-select">
                      <option></option>
                      <option>Take Away</option>
                      <option>Home Delivery</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="labels">Preferred Payment Option *</label>
                    <select className="form-select">
                      <option></option>
                      <option>Cash</option>
                      <option>Card</option>
                      <option>UPI</option>
                      <option>Cheque</option>
                      <option>Net transfer</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="labels">Select from Menu *</label>
                    <select className="form-select">
                      <option></option>
                    </select>
                  </div>
                </div>

                {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn pro-spaces-button3">
                  PLACE ORDER
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* MAKE A PURCHASE */}
        <div
          className="modal fade"
          id="makeapurchasemodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  MAKE A PURCHASE
                </h6>
                <button
                  id="slr"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <label className="labels">
                      Delivery Requirement Date *
                    </label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="labels">
                      Delivery Requirement Time *
                    </label>
                    <input type="time" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="labels">Preferred Payment Option *</label>
                    <select className="form-select">
                      <option></option>
                      <option>Cash</option>
                      <option>Card</option>
                      <option>UPI</option>
                      <option>Cheque</option>
                      <option>Net transfer</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="labels">Select from Menu *</label>
                    <select className="form-select">
                      <option></option>
                    </select>
                  </div>
                </div>
                {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn pro-spaces-button3">
                  PLACE ORDER
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
export default ExternalPview;
