import React, { useState, useEffect, useReducer } from "react";
import Occational from './occational';
import Rotational from './rotational';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { notify } from "../../Utility/notify";
import { keyGen } from "../../Utility/helper";
import { SpinnerSmall } from "../../Utility/spinner";
import { AddPayment } from "../../Pages/Payment/PayForAd";
import { createContext } from "react";
import { Modal } from 'react-bootstrap'
export const AdContext = createContext()


export const Advertisementt = () => {
  // loads content based on status
  const reducer = (state, action) => {
    switch (action.type) {
      case 'LOADING':
        return {
          component: <SpinnerSmall />,
          type: action.type
        };
      case 'PAY':
        return {
          component: <AddPayment />,
          type: action.type
        };
      case 'ACCESS':
        return {
          component: <Content />,
          type: action.type
        };
      case 'ERROR':
        return {
          component: <Error />,
          type: action.type
        };
      default:
        return {
          component: <Error />,
          type: action.type
        };
    }
  };

  const [Pay, setPay] = useState(false) //store payment statuss
  const [Archieved, setArchieved] = useState(null) //store gallery assets

  var [state, dispatch] = useReducer(reducer, {
    component: <SpinnerSmall />,
    type: 'LOADING',
  });

  useEffect(() => {
    // axios to check the member has access rights
    axios.get('/api/Verify/adCheck').then((res) => {
      let rs = res.data
      if (rs.payment === 'Expired') {
        dispatch({ type: 'PAY' })
      }
      else if (rs.payment) {
        dispatch({ type: 'ACCESS' })
        setPay(true)
      } else {
        dispatch({ type: 'ACCESS' })
        setPay(false)
      }
    }).catch((err) => {
      console.log(err);
      dispatch({ type: 'ERROR' })
    })

    // axios to fetch all media assets which was uploaded
    axios.get('/api/User/GetAllFromAdvertisementGallery').then((res) => {
      setArchieved(res.data)
    }).catch((err) => {
      console.log(err);
    })
    return () => {
    }
  }, [])

  const deleteAd = (items, cb, cb2) => {
    // axios to delete a media asset from gallery
    axios.post('/api/User/deleteOneFromAdvertisementGallery', { items }).then((res) => {
      notify("Deleted ", true)
      cb()
      cb2()
    }).catch((err) => {
      console.log(err);
      notify("Something went wrong ! ", false)

    })
  }
  return <AdContext.Provider value={{ dispatch, Archieved, deleteAd, Pay, setPay }}>
    {state.component}
  </AdContext.Provider>
}


const Content = () => {

  const [expanded, setExpanded] = useState("rotationaladcollpase");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [expanded2, setExpanded2] = useState(false);
  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };

  const [status, setstatus] = useState(null)
  const [Loading, setLoading] = useState(true)


  useEffect(() => {
    // axios to load advertisement 
    axios.get('/api/User/getAllAdd').then((res) => {
      let data = res.data
      if (data.length) {
        setstatus(data[0]?.Status)
      }
    }).catch((err) => {
      // setstatus(false)
      console.log(err);
    })

    return () => {

    }
  }, [])

  const [retri, setretri] = useState(keyGen())
  const [show, setShow] = useState(false)

  const pauseAll = () => {
    console.log(status);
    axios.put('/api/User/putPauseAllAdd', { status: !status }).then((res) => {
      // notify("Updated", true)
      setstatus(!status)
    }).catch((err) => {
      console.log(err);
      notify("Something went wrong !", false)
    })
  }

  const deleteAll = () => {
    axios.delete('/api/User/putDeleteAllAdd').then((res) => {
      notify("Deleted", true)
      handleClose();
      setstatus(null)
      setretri(keyGen())
    }).catch((err) => {
      console.log(err);
      notify("Something went wrong !", false)
    })

  }

  const handleClose = () => {
    setShow(false)
  }; //delete_warning modal handle
  const handleShow = (ev) => {
    setShow(true)
  };  //delete_warning modal handle


  return (
    <>
      <div className='container' style={{ paddingTop: '80px' }}>
        <div className="row py-2">
          <div className="col-12 d-flex justify-content-end">
            <button className="btn pro-spaces-button" data-bs-toggle="modal" data-bs-target="#advertisementinsdemodalid">FYI, GUIDELINES, FEES</button>
          </div>
        </div>
        <hr />
        {status != null &&
          <div className='d-flex justify-content-end mb-3'>
            <button className='btn px-4 pro-spaces-button me-2' style={{ 'textTransform': 'none' }} onClick={() => pauseAll()} > {status == true ? 'PAUSE' : 'RESTART'} AD DISPLAY</button>
            <button className='btn pro-spaces-button px-4' style={{ 'textTransform': 'none' }} onClick={handleShow} >DELETE ALL ADs</button>
          </div>
        }
        <Accordion
          expanded={expanded === "rotationaladcollpase"}
          onChange={handleChange("rotationaladcollpase")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              <b>
                ANNUAL DISPLAY of ADs
              </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Rotational key={retri + '12'} />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br />
        <Accordion
          expanded={expanded2 === "occationaladcollpase"}
          onChange={handleChange2("occationaladcollpase")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              <b>
                OCCASIONAL DISPLAY of ADs
              </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Occational key={retri + '24'} />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
        <>
          <Modal
            show={show}
            onHide={() => {
              handleClose();
            }}
            backdrop={'static'}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className='text-danger'>
                Are you sure you want to delete this image ? This action cannot be undone.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn pro-spaces-button3"
                onClick={deleteAll}
              >ok</button>
            </Modal.Footer>
          </Modal>

        </>
      </div>
    </>
  )
}

const Error = () => {
  console.log('errro');
  return <div style={{ height: '100vh' }} className="d-flex justify-content-center align-items-center">Something went wrong !</div>
}
