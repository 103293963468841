import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { Mask } from "../../Utility/mask";
export function SubCat(props) {
  return (
    <div
      id={`Cat-` + props.data.sub.slice(0, 1) + props.index}
      className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4   mb-3"
    >
      <Link
        to={"/Profile_results?Sub_id=" + Mask.encode(props.data.id)}
        className="twedfqtfudc"
      >
        <div className="card carderghibgibgp p-3 pb-2 pt-2">
          {/* <div className="row">
        <div className="col-4"></div>
        <div className="col-4 center"></div>
        <div className="col-4">
        
        {Follower ?
              <button
                type="button"
                style={{"fontSize":"10px"}}
                className="float-end btnbhg btn px-3 "
                onClick={(e) => follow(e, props.data.id)}
              > 
                Follow
              </button>
              :
              <button
                type="button"
                style={{"fontSize":"10px"}}
                className="float-end btnbhg btn px-3  "
                onClick={(e) => unfollow(e, props.data.id)}
              >
                UnFollow
              </button>
            }
        </div>
      </div> */}
          <div>
            <div className="d-flex align-items-center">
              <div className="ml-3 w-100">
                <h4 className="mb-2 mt-0">{props.data.sub}</h4>{" "}
                <ul>
                  {/* <li>type : {props.data.num} </li> */}
                  <li>Members : {props.data.cons} </li>
                  {/* */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
