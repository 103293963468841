import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Termsofuse.css";

export function Termsofuse2() {
  return (
    <>
      <div
        className="container notranslate"
        style={{ paddingTop: "90px" }}
        id="termsofuserfgu"
      >
        <div className="d-flex justify-content-end ">
          {/* <button onClick={(e)=>window.history.back()} className='btn pro-spaces-button mt-3' >Go Back</button> */}
        </div>

        <div className="pos" id="_75:51" style={{ top: 51, left: 75 }}>
          <span style={{ color: "#000000" }}>
            <h4>
              <center>
                <u>
                  <b>TERMS OF USE</b>
                </u>
              </center>
            </h4>
          </span>
        </div>
        <div className="pos pb-3 pt-3">
          <span id="_18.8" style={{ fontSize: "16px", color: "#000000" }}>
            We value the trust you place in{" "}
            <span className=" monotypepolicies">Vipar</span>. That is why we
            insist upon your reading these Terms of Use carefully. The Terms of
            Use and policy about Privacy together shall form the entire Terms of
            Use.
          </span>
        </div>
        <div className="pos" id="_75:208" style={{ top: 208, left: 75 }}>
          <span
            id="_18.8"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            1. Introduction
          </span>
        </div>
        <br />
        <div className="pos" id="_100:257" style={{ top: 257, left: 100 }}>
          <span
            id="_18.8"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            1.1 Contract
          </span>
        </div>

        <div className="pos" id="_100:306" style={{ top: 306, left: 100 }}>
          <span id="_19.8" style={{ fontSize: "16px", color: "#000000" }}>
            These terms of use, read together with the (i) Privacy Policy and
            (ii) Disclaimer constitute a legal and binding agreement between you
            and <span className=" monotypepolicies">Vipar</span>, a Proprietary
            concern, having its registered office at #203, Abhiman Hills, Light
            House Hill road, Hampankatta, Mangalore - 575001 ("
            <span className=" monotypepolicies">Vipar</span>").When you use our
            Services, you agree to all of these terms.
          </span>
        </div>
        <div className="pos" id="_100:472" style={{ top: 472, left: 100 }}>
          <span id="_18.4" style={{ fontSize: "16px", color: "#000000" }}>
            You agree that by clicking "Register", "Get Started" "Join
            Prochure", "Sign Up" or similar, registering, accessing or using our
            services (described below), you are agreeing to enter into a legally
            binding contract with{" "}
            <span className=" monotypepolicies">Vipar</span> (even if you are
            using our Services on behalf of a company). If you do not agree to
            this contract (“Contract” or “User Agreement”), do not click “Join
            Now” (or similar) and do not access or otherwise use any of our
            Services. If you wish to terminate this contract, at any time you
            can do so by closing your account and no longer accessing or using
            our Services.
          </span>
        </div>
        <div className="pos py-2">
          <span
            id="_18.0"
            style={{ fontWeight: "bold", fontSize: "18.0px", color: "#000000" }}
          >
            Services
          </span>
        </div>
        <div className="pos" id="_100:746" style={{ top: 746, left: 100 }}>
          <span id="_18.8" style={{ fontSize: "16px", color: "#000000" }}>
            This Contract applies to www.PROchure.in,{" "}
            <span className=" monotypepolicies">Vipar</span>-branded apps, and
            other
            <span className=" monotypepolicies">Vipar</span>-related sites,
            apps, communications and other services that state that they are
            offered under this Contract (“Services”), including the offsite
            collection of data for those Services, such as our ads and the
            “Apply with <span className=" monotypepolicies">Vipar</span>” and
            “Share with
            <span className=" monotypepolicies">Vipar</span>” plugins.
            Registered users of our Services are “Members”.
          </span>
        </div>
        <div className="pos" id="_100:912" style={{ top: 912, left: 100 }}>
          <span id="_19.1" style={{ fontSize: "16px", color: "#000000" }}>
            As a Member of our Services, the collection, use and sharing of your
            personal data is subject to our privacy policy and other documents
            referenced in this Privacy Policy and updates.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:1020" style={{ top: 1020, left: 100 }}>
          <span
            id="_19.0"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            1.2 Members{" "}
          </span>
        </div>

        <div className="pos" id="_100:1068" style={{ top: 1068, left: 100 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            When you register and join the{" "}
            <span className=" monotypepolicies">Vipar</span> services, you
            become a member.{" "}
          </span>
        </div>
        <br />
        <div className="pos" id="_100:1221" style={{ top: 1221, left: 100 }}>
          <span
            id="_19.0"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            1.3 Change
          </span>
        </div>

        <div className="pos" id="_100:1270" style={{ top: 1270, left: 100 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            We may modify this Contract and our Privacy Policy from time to
            time. If we make material changes to it, we will provide you notice
            through our Services, or by other means, to provide you the
            opportunity to review the changes before they become effective. We
            agree that changes cannot be retroactive. If you object to any
            changes, you may close your account. Your continued use of our
            Services after we publish or send a notice about our changes to
            these terms means that you are consenting to the updated terms as of
            their effective date.
          </span>
        </div>
        <br />
        <div className="pos" id="_75:1496" style={{ top: 1496, left: 75 }}>
          <span
            id="_19.0"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            2. Obligations
          </span>
        </div>
        <br />
        <div className="pos" id="_100:1546" style={{ top: 1546, left: 100 }}>
          <span
            id="_19.0"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            2.1 Service Eligibility
          </span>
        </div>
        <div className="pos" id="_100:1595" style={{ top: 1595, left: 100 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            The Services are not for use by anyone under the age of 18.
          </span>
        </div>
        <div className="pos" id="_100:1643" style={{ top: 1643, left: 100 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            To use the Services, you agree that: (1) you must be the "Minimum
            Age"(described below) or older; (2) you will only have one{" "}
            <span className=" monotypepolicies">Vipar</span> account, which must
            be in your real name; and (3) you are not already restricted by{" "}
            <span className=" monotypepolicies">Vipar</span> from using the
            Services. Creating an account with false information is a violation
            of our terms, including accounts registered on behalf of others or
            persons under the age of 18. “Minimum Age” means 18 years old.
            However, if law requires that you must be older in order for{" "}
            <span className=" monotypepolicies">Vipar</span> to lawfully provide
            the Services to you without parental consent (including using of
            your personal data) then the Minimum Age is such older age.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:1946" style={{ top: 1946, left: 100 }}>
          <span
            id="_18.7"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            2.2 Your Account
          </span>
        </div>
        <div className="pos" id="_100:1995" style={{ top: 1995, left: 100 }}>
          <span id="_19.2" style={{ fontSize: "16px", color: "#000000" }}>
            Members are account holders. You agree to: (1) use a strong password
            and keep it confidential; (2) not transfer any part of your account
            (e.g., connections) and (3) follow the law and our list of Dos and
            Don’ts. You are responsible for anything that happens through your
            account unless you close it or report misuse.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:2132" style={{ top: 2132, left: 100 }}>
          <span
            id="_19.2"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            2.3 Payment
          </span>
        </div>
        <div className="pos" id="_100:2181" style={{ top: 2181, left: 100 }}>
          <span id="_19.2" style={{ fontSize: "16px", color: "#000000" }}>
            When you buy any of our paid Services (“Premium Services”), you
            agree to pay us the applicable fees and taxes and to additional
            terms specific to the paid Services. Failure to pay these fees will
            result in the termination of your paid Services. Also, you agree
            that:
          </span>
        </div>
        <div className="pos" id="_125:2391" style={{ top: 2391, left: 125 }}>
          <span id="_14.3" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              Your{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              purchase{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              may{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              be{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              subject{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              to{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              foreign{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              exchange{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              fees{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              or{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              differences{" "}
            </span>
            <span id="_18.6" style={{ fontSize: "16px" }}>
              {" "}
              in{" "}
            </span>
          </span>
        </div>
        <div className="pos" id="_150:2421" style={{ top: 2421, left: 150 }}>
          <span id="_18.6" style={{ fontSize: "16px", color: "#000000" }}>
            prices based on location (e.g. exchange rates).
          </span>
        </div>
        <div className="pos" id="_125:2450" style={{ top: 2450, left: 125 }}>
          &nbsp;
          <span id="_19.6" style={{ fontSize: "16px" }}>
            {" "}
            We may store and continue billing your payment method (e.g. credit
            card) even after it has expired, to avoid interruptions in your
            Services and to use to pay other Services you may buy.
          </span>
        </div>
        <div className="pos" id="_125:2538" style={{ top: 2538, left: 125 }}>
          <span id="_14.9" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.4" style={{ fontSize: "16px" }}>
              {" "}
              If you purchase a subscription, your payment method automatically
              will be charged at the start of each subscription period for the
              fees and taxes applicable to that period. To avoid future charges,
              cancel before the renewal date.{" "}
            </span>
          </span>
        </div>
        <div className="pos" id="_125:2655" style={{ top: 2655, left: 125 }}>
          &nbsp;
          <span id="_19.0" style={{ fontSize: "16px" }}>
            {" "}
            We may calculate taxes payable by you based on the billing
            information that you provide us at the time of purchase.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:2735" style={{ top: 2735, left: 100 }}>
          <span
            id="_19.0"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            2.4 Notices and Messages
          </span>
        </div>
        <div className="pos" id="_100:2783" style={{ top: 2783, left: 100 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            You agree that we will provide notices and messages to you in the
            following ways:{" "}
          </span>
        </div>
        <div className="pos" id="_100:2813" style={{ top: 2813, left: 100 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            (1) within the Service, or (2) sent to the contact information you
            provided us (e.g., email, mobile number, physical address). You
            agree to keep your contact information up to date.
          </span>
        </div>
        <div className="pos" id="_100:2920" style={{ top: 2920, left: 100 }}>
          <span
            id="_19.0"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          ></span>
          <br />
        </div>
        <div className="pos" id="_100:2735" style={{ top: 2735, left: 100 }}>
          <span
            id="_19.0"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            2.5 Sharing
          </span>
        </div>
        <div className="pos" id="_100:2969" style={{ top: 2969, left: 100 }}>
          <span id="_19.7" style={{ fontSize: "16px", color: "#000000" }}>
            Our Services allow messaging and sharing of information in many
            ways, such as your profile, articles, group posts, links to news
            articles, job postings, messages and InMails. Information and
            content that you share or post may be seen by other Members,
            Visitors or others. Where we have made settings available, we will
            honour the choices you make about who can see content or information
            (e.g., message content to your addressees, sharing content only to{" "}
            <span className=" monotypepolicies">Vipar</span> connections,
            restricting your profile visibility from search engines, or opting
            not to notify others of your{" "}
            <span className=" monotypepolicies">Vipar</span> profile update).{" "}
          </span>
        </div>
        <div className="pos" id="_100:3223" style={{ top: 3223, left: 100 }}>
          <span id="_19.1" style={{ fontSize: "16px", color: "#000000" }}>
            We are not obligated to publish any information or content on our
            Service and can remove it with or without notice.
          </span>
        </div>
        <div className="pos" id="_100:3301" style={{ top: 3301, left: 100 }}>
          <span
            id="_19.1"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            Key terms{" "}
          </span>
        </div>
        <br />
        <div className="pos" id="_75:3351" style={{ top: 3351, left: 75 }}>
          <span
            id="_19.1"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            3. Rights and Limits
          </span>
        </div>
        <br />
        <div className="pos" id="_100:3401" style={{ top: 3401, left: 100 }}>
          <span
            id="_18.4"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            3.1. Your License to{" "}
            <span className=" monotypepolicies">Vipar</span>
          </span>
        </div>
        <div className="pos" id="_100:3561" style={{ top: 3561, left: 100 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            As between you and <span className=" monotypepolicies">Vipar</span>,
            you own the content and information that you submit or post to the
            Services, and you are only granting{" "}
            <span className=" monotypepolicies">Vipar</span> and our affiliates
            the following non-exclusive license:
          </span>
        </div>
        <div className="pos" id="_100:3669" style={{ top: 3669, left: 100 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            A worldwide, transferable and sub-licensable right to use, copy,
            modify, distribute, publish and process, information and content
            that you provide through our Services and the services of others,
            without any further consent, notice and/or compensation to you or
            others. These rights are limited in the following ways:
          </span>
        </div>
        <div className="pos" id="_125:3807" style={{ top: 3807, left: 125 }}>
          &nbsp;
          <span id="_19.4" style={{ fontSize: "16px" }}>
            {" "}
            You can end this license for specific content by deleting such
            content from the Services, or generally by closing your account,
            except (a) to the extent you shared it with others as part of the
            Service and they copied, re-shared it or stored it and (b) for the
            reasonable time it takes to remove from backup and other systems.
          </span>
        </div>
        <div className="pos" id="_125:3953" style={{ top: 3953, left: 125 }}>
          <span id="_14.4" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              We{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              will{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              not{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              include{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              your{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              content{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              in{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              advertisements{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              for{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              the{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              products{" "}
            </span>
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              and{" "}
            </span>
            services of third parties to others without your separate consent
            (including sponsored content). However, we have the right, without
            payment to you or others, to serve ads near your content and
            information, and your social actions may be visible and included
            with ads, as noted in the Privacy Policy.{" "}
          </span>
        </div>
        <div className="pos" id="_150:4100" style={{ top: 4100, left: 150 }}>
          <span
            id="_19.0"
            style={{ fontSize: "16px", color: "#000000" }}
          ></span>
        </div>
        <div className="pos" id="_150:4129" style={{ top: 4129, left: 150 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            If you use a Service feature, we may mention that with your name or
            photo to promote that feature within our Services, subject to your
            settings.
          </span>
        </div>
        <div className="pos" id="_125:4159" style={{ top: 4159, left: 125 }}>
          <span id="_14.6" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              We will get your consent if we want to give others the right to
              publish your{" "}
            </span>
            content beyond the Services. However, if you choose to share your
            post as "public, everyone or similar", we will enable a feature that
            allows other Members to embed that public post onto third-party
            services, and we enable search engines to make that public content
            findable though their services.{" "}
          </span>
        </div>
        <div className="pos" id="_125:4305" style={{ top: 4305, left: 125 }}>
          <span id="_14.7" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              While{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              we{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              may{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              edit{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              and{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              make{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              format{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              changes{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              to{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              your{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              content{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              (such{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              as{" "}
            </span>
            translating or transcribing it, modifying the size, layout or file
            type or removing metadata), we will not modify the meaning of your
            expression.
          </span>
        </div>
        <div className="pos" id="_100:4414" style={{ top: 4414, left: 100 }}>
          <span id="_19.1" style={{ fontSize: "16px", color: "#000000" }}>
            You and <span className=" monotypepolicies">Vipar</span> agree that
            we may access, store, process and use any information and personal
            data that you provide in accordance with, the terms of the Privacy
            Policy and your choices (including settings).
          </span>
        </div>
        <div className="pos" id="_100:4522" style={{ top: 4522, left: 100 }}>
          <span id="_18.8" style={{ fontSize: "16px", color: "#000000" }}>
            By submitting suggestions or other feedback regarding our Services
            to <span className=" monotypepolicies">Vipar</span>, you agree that{" "}
            <span className=" monotypepolicies">Vipar</span> can use and share
            (but does not have to) such feedback for any purpose without
            compensation to you.
          </span>
        </div>
        <div className="pos" id="_100:4731" style={{ top: 4731, left: 100 }}>
          <span id="_19.2" style={{ fontSize: "16px", color: "#000000" }}>
            You agree to only provide content or information that does not
            violate the law nor anyone’s rights (including intellectual property
            rights). You also agree that your profile information will be
            truthful. <span className=" monotypepolicies">Vipar</span> may be
            required by law to remove certain information or content in certain
            countries.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:4868" style={{ top: 4868, left: 100 }}>
          <span
            id="_19.2"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            3.2 Service Availability
          </span>
        </div>
        <div className="pos" id="_100:4917" style={{ top: 4917, left: 100 }}>
          <span id="_19.2" style={{ fontSize: "16px", color: "#000000" }}>
            We may change, suspend or discontinue any of our Services. We may
            also modify our prices effective prospectively upon reasonable
            notice to the extent allowed under the law.
          </span>
        </div>
        <div className="pos" id="_100:5024" style={{ top: 5024, left: 100 }}>
          <span id="_18.7" style={{ fontSize: "16px", color: "#000000" }}>
            We don’t promise to store or keep showing any information and
            content that you’ve posted.{" "}
            <span className=" monotypepolicies">Vipar</span> is not a storage
            service. You agree that we have no obligation to store, maintain or
            provide you a copy of any content or information that you or others
            provide, except to the extent required by applicable law and as
            noted in our Privacy Policy.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:5191" style={{ top: 5191, left: 100 }}>
          <span
            id="_19.1"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            3.3 Other Content, Sites and Apps
          </span>
        </div>
        <div className="pos" id="_100:5239" style={{ top: 5239, left: 100 }}>
          <span id="_19.9" style={{ fontSize: "16px", color: "#000000" }}>
            By using the Services, you may encounter content or information that
            might be inaccurate, incomplete, delayed, misleading, illegal,
            offensive or otherwise harmful.{" "}
            <span className=" monotypepolicies">Vipar</span> generally does not
            review content provided by our Members or others. You agree that we
            are not responsible for others’ (including other Members’) content
            or information. We cannot always prevent this misuse of our
            Services, and you agree that we are not responsible for any such
            misuse. You also acknowledge the risk that you or your organization
            may be mistakenly associated with content about others when we let
            connections and followers know you or your organization were
            mentioned in the news.{" "}
          </span>
        </div>
        <div className="pos" id="_100:5523" style={{ top: 5523, left: 100 }}>
          <span id="_19.3" style={{ fontSize: "16px", color: "#000000" }}>
            <span className=" monotypepolicies">Vipar</span> may help connect
            Members offering their services with Members seeking services.{" "}
            <span className=" monotypepolicies">Vipar</span> does not perform
            nor employs individuals to perform these services. You must be at
            least 18 years of age to offer, perform or procure these services.
            You acknowledge that{" "}
            <span className=" monotypepolicies">Vipar</span> does not supervise,
            direct, control or monitor Members in the performance of these
            services and agree that (1){" "}
            <span className=" monotypepolicies">Vipar</span> is not responsible
            for the offering, performance or procurement of these services, (2)
            <span className=" monotypepolicies">Vipar</span> does not endorse
            any particular Member’s offered services, and (3) nothing shall
            create an employment, agency, or joint venture relationship between{" "}
            <span className=" monotypepolicies">Vipar</span>
            and any Member offering services.{" "}
          </span>
        </div>
        <div className="pos" id="_100:5901" style={{ top: 5901, left: 100 }}>
          <span id="_18.7" style={{ fontSize: "16px", color: "#000000" }}>
            Similarly, <span className=" monotypepolicies">Vipar</span> may help
            you register for and/or attend events organized by Members and
            connect with other Members who are attendees at such events. You
            agree that (1) <span className=" monotypepolicies">Vipar</span> is
            not responsible for the conduct of any of the Members or other
            attendees at such events, (2){" "}
            <span className=" monotypepolicies">Vipar</span> does not endorse
            any particular event listed on our Services, (3){" "}
            <span className=" monotypepolicies">Vipar</span> does not review
            and/or vet any of these events, and (4) that you will adhere to
            these terms and conditions that apply to such events.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:6126" style={{ top: 6126, left: 100 }}>
          <span
            id="_19.4"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            3.4 Limits
          </span>
        </div>
        <div className="pos" id="_100:6175" style={{ top: 6175, left: 100 }}>
          <span id="_19.4" style={{ fontSize: "16px", color: "#000000" }}>
            <span className=" monotypepolicies">Vipar</span> reserves the right
            to limit your use of the Services, including the number of your
            connections and your ability to contact other Members.{" "}
            <span className=" monotypepolicies">Vipar</span> reserves the right
            to restrict, suspend, or terminate your account if you breach this
            Contract or the law or are misusing the Services (e.g., violating
            any of the Dos and Don’ts)
          </span>
        </div>
        <br />
        <div className="pos" id="_100:6312" style={{ top: 6312, left: 100 }}>
          <span
            id="_18.8"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            3.5 Intellectual Property Rights
          </span>
        </div>
        <div className="pos" id="_100:6361" style={{ top: 6361, left: 100 }}>
          <span id="_19.6" style={{ fontSize: "16px", color: "#000000" }}>
            <span className=" monotypepolicies">Vipar</span> reserves all of its
            intellectual property rights in the Services. Trademarks and logos
            used in connection with the Services are the trademarks of their
            respective owners. <span className=" monotypepolicies">Vipar</span>,
            and “in” logos and other{" "}
            <span className=" monotypepolicies">Vipar</span> trademarks, service
            marks, graphics and logos used for our Services are trademarks or
            registered trademarks of{" "}
            <span className=" monotypepolicies">Vipar</span>.
          </span>
        </div>
        <br />
        <div className="pos" id="_75:6528" style={{ top: 6528, left: 75 }}>
          <span
            id="_18.7"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            4. Disclaimer and Limit of Liability
          </span>
        </div>
        <br />
        <div className="pos" id="_100:6578" style={{ top: 6578, left: 100 }}>
          <span
            id="_18.7"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            4.1 No Warranty
          </span>
        </div>
        <div className="pos" id="_100:6626" style={{ top: 6626, left: 100 }}>
          <span id="_17.1" style={{ fontSize: "16px", color: "#000000" }}>
            <span className=" monotypepolicies">Vipar</span>AND ITS AFFILIATES
            MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY
            REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR
            ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND
            INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST
            EXTENT PERMITTED UNDER APPLICABLE LAW,
            <span className=" monotypepolicies">Vipar</span>AND ITS AFFILIATES
            DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED
            WARRANTY OF TITLE, ACCURACY OF DATA, NON- INFRINGEMENT,
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:6910" style={{ top: 6910, left: 100 }}>
          <span
            id="_19.0"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            4.2 Exclusion of Liability
          </span>
        </div>
        <div className="pos" id="_100:7071" style={{ top: 7071, left: 100 }}>
          <span id="_17.2" style={{ fontSize: "16px", color: "#000000" }}>
            TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS{" "}
            <span className=" monotypepolicies">Vipar</span>HAS ENTERED INTO A
            SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT),{" "}
            <span className=" monotypepolicies">Vipar</span>, INCLUDING ITS
            AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR
            LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G.,
            OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME
            OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.
            <br />
            <span className=" monotypepolicies">Vipar</span> AND ITS AFFILIATES
            WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR ANY
            AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO{" "}
            <span className=" monotypepolicies">Vipar</span> FOR THE SERVICES
            DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) Rs.10,000.
          </span>
        </div>
        <br />
        <div className="pos" id="_100:7462" style={{ top: 7462, left: 100 }}>
          <span
            id="_19.1"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            4.3 Basis of the Bargain; Exclusions
          </span>
        </div>
        <div className="pos" id="_100:7511" style={{ top: 7511, left: 100 }}>
          <span id="_20.3" style={{ fontSize: "16px", color: "#000000" }}>
            The limitations of liability in this Section 4 are part of the basis
            of the bargain between you and{" "}
            <span className=" monotypepolicies">Vipar</span> and shall apply to
            all claims of liability (e.g., warranty, tort, negligence, contract
            and law) even if <span className=" monotypepolicies">Vipar</span> or
            its affiliates has been told of the possibility of any such damage,
            and even if these remedies fail their essential purpose.
          </span>
        </div>
        <div className="pos" id="_100:7677" style={{ top: 7677, left: 100 }}>
          <span id="_19.1" style={{ fontSize: "16px", color: "#000000" }}>
            These limitations of liability do not apply to liability for death
            or personal injury or for fraud, gross negligence or intentional
            misconduct, or in cases of negligence where a material obligation
            has been breached, a material obligation being such which forms a
            prerequisite to our delivery of services and on which you may
            reasonably rely, but only to the extent that the damages were
            directly caused by the breach and were foreseeable upon conclusion
            of this Contract and to the extent that they are typical in the
            context of this Contract.
          </span>
        </div>
        <br />
        <div className="pos" id="_75:7903" style={{ top: 7903, left: 75 }}>
          <span
            id="_18.8"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            5. 5. Termination
          </span>
        </div>
        <div className="pos" id="_100:7953" style={{ top: 7953, left: 100 }}>
          <span id="_19.6" style={{ fontSize: "16px", color: "#000000" }}>
            Both you and <span className=" monotypepolicies">Vipar</span> may
            terminate this Contract at any time with notice to the other. On
            termination, you lose the right to access or use the Services. The
            following shall survive termination:
          </span>
        </div>
        <div className="pos" id="_125:8061" style={{ top: 8061, left: 125 }}>
          <span id="_14.5" style={{ fontSize: "14.5px", color: "#000000" }}>
            &nbsp;
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              Our rights to use and disclose your feedback;
            </span>
          </span>
        </div>
        <div className="pos" id="_125:8241" style={{ top: 8241, left: 125 }}>
          <span id="_14.6" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              Members{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              rights{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              to{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              further{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              re-share{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              content{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              and{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              information{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              you{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              shared{" "}
            </span>
            through the Services;
          </span>
        </div>
        <div className="pos" id="_125:8300" style={{ top: 8300, left: 125 }}>
          <span id="_14.6" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              Sections 4, 6, 7, and 8.2 of this Contract;
            </span>
          </span>
        </div>
        <div className="pos" id="_125:8329" style={{ top: 8329, left: 125 }}>
          <span id="_15.1" style={{ fontSize: "15.1px", color: "#000000" }}>
            &nbsp;
            <span id="_19.7" style={{ fontSize: "16px" }}>
              {" "}
              Any amounts owed by either party prior to termination remain owed
              after termination.
            </span>
          </span>
        </div>
        <br />
        <div className="pos" id="_75:8389" style={{ top: 8389, left: 75 }}>
          <span
            id="_18.8"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            6. Governing Law and Dispute Resolution
          </span>
        </div>
        <div className="pos" id="_100:8439" style={{ top: 8439, left: 100 }}>
          <span id="_18.8" style={{ fontSize: "16px", color: "#000000" }}>
            These Terms and the relationship between you and{" "}
            <span className=" monotypepolicies">Vipar</span> will be governed by
            the laws of Karnataka, India without regard to its conflict of law
            provisions. You and
            <span className=" monotypepolicies">Vipar</span> agree that all
            disputes will be subject to arbitration at Mangaluru, Karnataka in
            accordance with the Arbitration and Conciliation Act, 1996. The
            arbitration proceedings shall be conducted in the English language.
            That you and
            <span className=" monotypepolicies">Vipar</span> hereby expressly
            and irrevocably attorn to the jurisdiction of the courts of
            Mangaluru with respect to any matter or claim, suit, action or
            proceeding arising under or related to this Agreement. You covenant
            not to sue <span className=" monotypepolicies">Vipar</span> in any
            other{" "}
          </span>
        </div>
        <div className="pos" id="_100:8674" style={{ top: 8674, left: 100 }}>
          <span id="_17.5" style={{ fontSize: "16px", color: "#000000" }}>
            forum.
          </span>
        </div>
        <br />
        <div className="pos" id="_75:8722" style={{ top: 8722, left: 75 }}>
          <span
            id="_18.8"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            7. 7. General Terms
          </span>
        </div>
        <div className="pos" id="_100:8772" style={{ top: 8772, left: 100 }}>
          <span id="_19.3" style={{ fontSize: "16px", color: "#000000" }}>
            If a court with authority over this Contract finds any part of it
            unenforceable, you and we agree that the court should modify the
            terms to make that part enforceable while still achieving its
            intent. If the court cannot do that, you and we agree to ask the
            court to remove that unenforceable part and still enforce the rest
            of this{" "}
          </span>
        </div>
        <div className="pos" id="_100:8890" style={{ top: 8890, left: 100 }}>
          <span id="_17.9" style={{ fontSize: "16px", color: "#000000" }}>
            Contract.
          </span>
        </div>
        <div className="pos" id="_100:8938" style={{ top: 8938, left: 100 }}>
          <span id="_19.8" style={{ fontSize: "16px", color: "#000000" }}>
            This Contract (including additional terms that may be provided by us
            when you engage with a feature of the Services) is the only
            agreement between us regarding the Services and supersedes all prior
            agreements for the Services.
          </span>
        </div>
        <div className="pos" id="_100:9046" style={{ top: 9046, left: 100 }}>
          <span id="_19.3" style={{ fontSize: "16px", color: "#000000" }}>
            If we don't act to enforce a breach of this Contract, that does not
            mean that <span className=" monotypepolicies">Vipar</span>
            has waived its right to enforce this Contract. You may not assign or
            transfer this Contract (or your membership or use of Services) to
            anyone without our consent.{" "}
          </span>
        </div>
        <div className="pos" id="_100:9134" style={{ top: 9134, left: 100 }}>
          <span id="_19.3" style={{ fontSize: "16px", color: "#000000" }}>
            However, you agree that{" "}
            <span className=" monotypepolicies">Vipar</span> may assign this
            Contract to its affiliates or a party that buys it without your
            consent.{" "}
          </span>
        </div>
        <br />
        <div className="pos" id="_75:9213" style={{ top: 9213, left: 75 }}>
          <span
            id="_18.2"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            8. <span className=" monotypepolicies">Vipar</span> “Dos and Don’ts”
          </span>
        </div>
        <br />
        <div className="pos" id="_100:9263" style={{ top: 9263, left: 100 }}>
          <span
            id="_18.2"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            8.1. Dos
          </span>
        </div>
        <div className="pos" id="_100:9411" style={{ top: 9411, left: 100 }}>
          <span
            id="_18.7"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            You agree that you will:
          </span>
        </div>
        <div className="pos" id="_125:9461" style={{ top: 9461, left: 125 }}>
          <span id="_14.7" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.2" style={{ fontSize: "16px" }}>
              {" "}
              Comply with all applicable laws, including, without limitation,
              privacy laws,{" "}
            </span>
            intellectual property laws, anti-spam laws, export control laws, tax
            laws, and regulatory requirements;
          </span>
        </div>
        <div className="pos" id="_125:9549" style={{ top: 9549, left: 125 }}>
          <span id="_14.7" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.2" style={{ fontSize: "16px" }}>
              {" "}
              Provide accurate information to us and keep it updated;
            </span>
            &nbsp;
            <span id="_19.2" style={{ fontSize: "16px" }}>
              {" "}
              Use your real name on your profile; and
            </span>
            &nbsp;
            <span id="_19.2" style={{ fontSize: "16px" }}>
              {" "}
              Use the Services in a professional manner.
            </span>
          </span>
        </div>
        <br />
        <div className="pos" id="_100:9658" style={{ top: 9658, left: 100 }}>
          <span
            id="_19.2"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            8.2. Don’ts
          </span>
        </div>
        <div className="pos" id="_100:9706" style={{ top: 9706, left: 100 }}>
          <span
            id="_19.2"
            style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}
          >
            You agree that you will{" "}
            <span style={{ fontStyle: "italic" }}> not </span> :
          </span>
        </div>
        <div className="pos" id="_125:9756" style={{ top: 9756, left: 125 }}>
          <span id="_14.2" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              Create{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              a{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              false{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              identity{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              on{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              <span className=" monotypepolicies">Vipar</span>,{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              misrepresent{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              your{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              identity,{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              create{" "}
            </span>
            <span id="_18.5" style={{ fontSize: "16px" }}>
              {" "}
              a{" "}
            </span>
            Member profile for anyone other than yourself (a real person), or
            use or attempt to use another’s account;
          </span>
        </div>
        <div className="pos" id="_125:9844" style={{ top: 9844, left: 125 }}>
          <span id="_14.6" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              Develop,{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              support{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              or{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              use{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              software,{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              devices,{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              scripts,{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              robots{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              or{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              any{" "}
            </span>
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              other{" "}
            </span>
            means or processes (including crawlers, browser plugins and add-ons
            or any other technology) to scrape the Services or otherwise copy
            profiles and other data from the Services;
          </span>
        </div>
        <div className="pos" id="_125:9962" style={{ top: 9962, left: 125 }}>
          <span id="_14.6" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              Override any security feature or bypass or circumvent any access
              controls or{" "}
            </span>
            use limits of the Service (such as caps on keyword searches or
            profile views);
          </span>
        </div>
        <div className="pos" id="_125:10020" style={{ top: 10020, left: 125 }}>
          <span id="_14.6" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.1" style={{ fontSize: "16px" }}>
              {" "}
              Copy, use, disclose or distribute any information obtained from
              the Services,{" "}
            </span>
            whether directly or through third parties (such as search engines),
            without the consent of{" "}
            <span className=" monotypepolicies">Vipar</span>;
          </span>
        </div>
        <div className="pos" id="_125:10108" style={{ top: 10108, left: 125 }}>
          <span id="_15.0" style={{ fontSize: "15.0px", color: "#000000" }}>
            &nbsp;
            <span id="_19.6" style={{ fontSize: "16px" }}>
              {" "}
              Disclose information that you do not have the consent to disclose
              (such as confidential information of others (including your
              employer));
            </span>
          </span>
        </div>
        <div className="pos" id="_125:10167" style={{ top: 10167, left: 125 }}>
          <span id="_14.3" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              Violate{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              the{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              intellectual{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              property{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              rights{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              of{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              others,{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              including{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              copyrights,{" "}
            </span>
            patents, trademarks, trade secrets or other proprietary rights. For
            example, do not copy or distribute (except through the available
            sharing functionality) the posts or other content of others without
            their permission, which they may give by posting under a Creative
            Commons license;
          </span>
        </div>
        <div className="pos" id="_125:10314" style={{ top: 10314, left: 125 }}>
          <span id="_14.4" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.8" style={{ fontSize: "16px" }}>
              {" "}
              Violate the intellectual property or other rights of{" "}
              <span className=" monotypepolicies">Vipar</span>, including,
              without limitation, (i) copying or distributing our learning
              videos or other materials or (ii) copying or distributing our
              technology, unless it is released under
            </span>
          </span>
        </div>
        <div className="pos" id="_150:10402" style={{ top: 10402, left: 150 }}>
          <span id="_18.3" style={{ fontSize: "16px", color: "#000000" }}>
            open source licenses; (iii) using the word “
            <span className=" monotypepolicies">Vipar</span>” or our logos in
            any business name, email, or URL;
          </span>
        </div>
        <div className="pos" id="_125:10581" style={{ top: 10581, left: 125 }}>
          <span id="_15.1" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.7" style={{ fontSize: "16px" }}>
              {" "}
              Post anything that contains software viruses, worms, or any other
              harmful code;
            </span>
          </span>
        </div>
        <div className="pos" id="_125:10640" style={{ top: 10640, left: 125 }}>
          <span id="_14.7" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.2" style={{ fontSize: "16px" }}>
              {" "}
              Reverse engineer, decompile, disassemble, decipher or otherwise
              attempt to{" "}
            </span>
            derive the source code for the Services or any related technology
            that is not open source;
          </span>
        </div>
        <div className="pos" id="_125:10728" style={{ top: 10728, left: 125 }}>
          <span id="_14.7" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.2" style={{ fontSize: "16px" }}>
              {" "}
              Imply or state that you are affiliated with or endorsed by{" "}
              <span className=" monotypepolicies">Vipar</span> without our{" "}
            </span>
            express consent (e.g., representing yourself as an accredited{" "}
            <span className=" monotypepolicies">Vipar</span>
            representative);
          </span>
        </div>
        <div className="pos" id="_125:10816" style={{ top: 10816, left: 125 }}>
          <span id="_15.3" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_20.0" style={{ fontSize: "16px" }}>
              {" "}
              Rent, lease, loan, trade, sell/re-sell or otherwise monetize the
              Services or related data or access to the same, without{" "}
              <span className=" monotypepolicies">Vipar</span>’s consent;
            </span>
          </span>
        </div>
        <div className="pos" id="_125:10875" style={{ top: 10875, left: 125 }}>
          &nbsp;
          <span id="_18.7" style={{ fontSize: "16px" }}>
            {" "}
            Deep-link to our Services for any purpose other than to promote your
            profile or a Group on our Services, without{" "}
            <span className=" monotypepolicies">Vipar</span>’s consent;
          </span>
        </div>
        <div className="pos" id="_125:10933" style={{ top: 10933, left: 125 }}>
          <span id="_14.4" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              Use{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              bots{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              or{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              other{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              automated{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              methods{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              to{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              access{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              the{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              Services,{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              add{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              or{" "}
            </span>
            download contacts, send or redirect messages;
          </span>
        </div>
        <div className="pos" id="_125:10992" style={{ top: 10992, left: 125 }}>
          <span id="_14.4" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              Monitor{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              the{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              Services’{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              availability,{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              performance{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              or{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              functionality{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              for{" "}
            </span>
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              any{" "}
            </span>
            competitive purpose;
          </span>
        </div>
        <div className="pos" id="_125:11051" style={{ top: 11051, left: 125 }}>
          <span id="_14.4" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.7" style={{ fontSize: "16px" }}>
              {" "}
              Engage in “framing,” “mirroring,” or otherwise simulating the
              appearance or function of the Services;
            </span>
          </span>
        </div>
        <div className="pos" id="_125:11109" style={{ top: 11109, left: 125 }}>
          &nbsp;
          <span id="_19.8" style={{ fontSize: "16px" }}>
            {" "}
            Overlay or otherwise modify the Services or their appearance (such
            as by inserting elements into the Services or removing, covering, or
            obscuring an advertisement included on the Services);
          </span>
        </div>
        <div className="pos" id="_125:11197" style={{ top: 11197, left: 125 }}>
          <span id="_14.5" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              Interfere{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              with{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              the{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              operation{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              of,{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              or{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              place{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              an{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              unreasonable{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              load{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              on,{" "}
            </span>
            <span id="_18.9" style={{ fontSize: "16px" }}>
              {" "}
              the{" "}
            </span>
            Services (e.g., spam, denial of service attack, viruses, gaming
            algorithms);{" "}
          </span>
        </div>
        <div className="pos" id="_150:11256" style={{ top: 11256, left: 150 }}>
          <span id="_19.0" style={{ fontSize: "16px", color: "#000000" }}>
            and/or
          </span>
        </div>
        <div className="pos" id="_125:11285" style={{ top: 11285, left: 125 }}>
          <span id="_14.6" style={{ fontSize: "16px", color: "#000000" }}>
            &nbsp;
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              Violate{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              the{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              terms{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              of{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              use{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              or{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              any{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              additional{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              terms{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              concerning{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              a{" "}
            </span>
            <span id="_19.0" style={{ fontSize: "16px" }}>
              {" "}
              specific{" "}
            </span>
            Service that are provided when you sign up for or start using such
            Service.
          </span>
        </div>
        <br />
      </div>
    </>
  );
}
