import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { AiFillCamera, AiFillExclamationCircle } from "react-icons/ai";

import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export function CreateAdmin() {
  const formData = new FormData();
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [Stateload, setStateload] = useState(true);
  const [loading, setloading] = useState(false);

  const [value, setValue] = useState({
    type: "active",

    pname: "FEATURED",
    firstname: "FEATURED",
    category: "62de8d43f2b2d12241ec1267",
    subcategory: "62de8d43f2b2d12241ec1267",
  });
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  const [error, setError] = useState("");

  const [Submit, setSubmit] = useState(false);

  const [image, setImage] = useState();
  const [cropData, setCropData] = useState(false);
  const [cropper, setCropper] = useState();

  const onChange = async (e) => {
    // $("#profileUpdation").modal("show");

    var myModal = new bootstrap.Modal(
      document.getElementById("profileUpdation")
    );
    myModal.show();

    var myModalEl = document.getElementById("profileUpdation");

    myModalEl.addEventListener("shown.bs.modal", function (event) {
      cropData ? setCropData(false) : <></>;
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      if (files[0]) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
      }
    });

    var x = document.getElementById("profileUpdation").offsetWidth;
    console.log(x);
    //    var s=document.getElementById('profileUpdation')
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      setCropData();
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          console.log(blob);
          console.log(URL.createObjectURL(blob));
          setCropData(URL.createObjectURL(blob));
          setnewb(blob);
        },
        "image/jpeg",
        0.5
      );
    }
  };

  const uploadme = () => {
    console.log("started");
    // console.log(cropData)
    var DP = document.getElementById("DP");
    DP.src = cropData;
  };

  const photo = () => document.getElementById("image").click();

  function doit() {
    let width = document.getElementById("profileUpdation").offsetWidth;
    console.log(width);
    document.getElementById("profileUpdation").style.width = "150px";
    if (width < 470) {
    }
    var xx = (document.getElementById("bod").style.display = "none");
    document.getElementById("bod").style.display = "block";
  }

  useEffect(() => {
    setValue({ ...value, image: cropData });
  }, [cropData]);

  function validation() {
    if (cropData) {
      var imgsize = cropData.length - 22;

      var imgsizeinkb =
        (4 * Math.ceil(imgsize / 3) * 0.5624896334383812) / 1000;
      console.log(imgsize);
      console.log(imgsizeinkb);
      if (cropData) {
        setError();
      } else {
        return setError("Photo size is greater than 2MB");
      }
    } else {
      return setError("PLEASE upload PROFILE DISPLAY PICTURE");
    }

    return true;
  }
  const [newb, setnewb] = useState();
  const submit = (e) => {
    console.log(value);
    e.preventDefault();
    formData.delete("file");
    formData.delete("data");
    console.log(value);
    var dataSend = JSON.stringify(value);
    console.log(newb);

    var status = false;
    let metadata = {
      type: "image/jpeg",
    };
    if (newb) {
      let file = new File([newb], "test.jpg", metadata);
      console.log(file);

      formData.append("file", file);
    }
    formData.append("data", dataSend);

    setSubmit(true);
    status = validation();
    if (status == true) {
      setloading(true);
      console.log(value);
      axios
        .post("/api/User/adminprofilecreation", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status == 200) {
            console.log("product Created");
            // console.log(res.data)
            setloading(false);
            return navigate("/");
          }
        })
        .catch((err) => {
          if (err.response.status == 304) {
            console.log(err.response.data);
            // toast warning
            setloading(false);
            navigate("/");
          } else {
          }
          setloading(false);
        });
    }
  };

  useEffect(() => {
    if (Submit) {
      validation();
    }
  }, [value]);

  return (
    <>
      <div className="container rounded bg-white pt-5 pb-5">
        <form id="f1" onSubmit={(e) => submit(e)}>
          <div
            className="modal fade"
            id="profileUpdation"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="exampleModalLabel">
                    Profile Picture
                  </h6>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div id="bod" className="modal-body ">
                  <div>
                    <div className="d-flex justify-content-center">
                      {cropData ? (
                        <div
                          className="box"
                          style={{ width: "50%", float: "right" }}
                        >
                          <img
                            style={{ width: "100%" }}
                            src={cropData}
                            alt="cropped"
                          />
                          {/* <button className="btn btn-success mt-2" style={{ float: "right" }}  >Save</button> */}
                        </div>
                      ) : (
                        <div style={{ width: "100%" }}>
                          {" "}
                          <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={false}
                            aspectRatio={45 / 45}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={0}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={true}
                          />
                          {/* <button className="btn btn-info text-light mt-2" style={{ float: "right" }} onClick={getCropData}>Ok</button> */}
                        </div>
                      )}
                    </div>
                    {/* <button className="btn btn-info text-light" style={{ float: "right" }}  onClick={getCropData}>Ok</button> */}

                    {/* <button onClick={() => uploadme()}>Start upload</button> */}
                  </div>
                </div>
                <div className="modal-footer">
                  {/* <button onClick={() => setcp(true)}>doit</button> */}
                  {!cropData ? (
                    <button
                      type="button"
                      className="btn pro-spaces-button3"
                      onClick={getCropData}
                    >
                      Ok
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn pro-spaces-button3"
                      onClick={() => uploadme()}
                      data-bs-dismiss="modal"
                    >
                      Save changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <center className="pt-4">
              <h5>Profile Creation</h5>
            </center>

            <div className="col-md-3 border-right ">
              <div className="d-flex flex-column align-items-center text-center p-3 pt-5 ">
                <img id="DP" className=" mt-5" width="150px" src="/blank.png" />
                <div className="upload">
                  <AiFillCamera size={"40px"} onClick={() => photo()} />
                </div>
                <span className="text-50 mt-3 up" onClick={() => photo()}>
                  PROFILE DISPLAY PICTURE *
                </span>
                <span> </span>
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={onChange}
              id="image"
              className="form-control d-none"
            />
            <div className="col-md-9 border-right">
              <div className="p-3 pt-5"></div>

              <div className="p-3 pt-5"></div>
            </div>
          </div>

          <h6 style={{ color: "red" }}>
            <center>{error ? error : ""}</center>
          </h6>
          <div className="mt-2 text-center">
            {!loading && (
              <button
                className="btn mb-3 mt-4"
                style={{ backgroundColor: "#55374a", color: "white" }}
              >
                Create Profile
              </button>
            )}
            {loading && (
              <button
                className="btn  mb-3 mt-4"
                disabled
                style={{ backgroundColor: "#55374a", color: "white" }}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
