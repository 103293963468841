import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Mask } from '../Utility/mask';
import { Carousel, Dropdown } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import moment from 'moment';
import PostFooter from './ProForum/postFooter';

export const Newfind = () => {
  const [value, setvalue] = useState([]);
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get('/api/User/Newfind')
      .then((res) => {
        console.log(res.data);
        setvalue(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div style={{ paddingTop: '80px' }}></div>

      <div className="container">
        {value?.map((elem, index) => {
          return (
            <>
              <div>
                <PostSingle
                  data={elem}
                  index={index}
                  key={'post' + index}
                  to={`/Newidfind?post=${Mask.encode(elem._id)}`}
                />
              </div>
            </>
          );
        })}
        {Loading == false && value.length == 0 ? (
          <p className="text-center">No post to show !</p>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

const PostSingle = (props) => {
  console.log(props);
  let to = props.to;
  let { Description, Files, User, profilepic, Posted_On, _id } = {
    ...props.data,
  };
  console.log(User);

  const getRelativeDate = (posted) => {
    // return moment(posted).calendar().toString();
    return moment(posted).format('DD/MM/YY');
  };
  return (
    <div className="p-1 m-1  border bg-light postView mx-auto">
      <div className="d-flex justify-content-between py-2">
        <div className="row">
          <div className="col-auto">
            <img
              src={profilepic}
              style={{ borderRadius: 50 }}
              height={50}
              alt="prochure"
            />
          </div>
          <div className="col-auto px-0">
            <div className="d-flex flex-column">
              <span style={{ textTransform: 'capitalize' }}>
                {User[0]?.pname}
              </span>
              <span
                style={{
                  fontSize: '14px',
                  color: '#0000008f',
                  fontWeight: 500,
                }}
              >
                {getRelativeDate(Posted_On)}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <Dropdown className="dropv2Head">
            <Dropdown.Toggle className="dropV2" id="dropdown-basic">
              <BsThreeDotsVertical color="black" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to={to} className="drop-downlink-pro-forum">
                  Edit
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <hr className="mt-0"></hr>

      <p style={{ whiteSpace: 'pre-line' }}>{Description}</p>
      <div className="caro-pro-249882">
        <Carousel
          indicators={false}
          controls={Files?.length > 1 ? true : false}
        >
          {Files.map((elem) => {
            console.log(elem);
            if (elem.typeOf.indexOf('image') != -1) {
              return (
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img className=" img-fluid-for-post" src={elem.filename} />
                  </div>
                  <p style={{ whiteSpace: 'pre-line' }}>{elem.title}</p>
                </Carousel.Item>
              );
            }
            if (elem.typeOf.indexOf('video') != -1) {
              return (
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <video className="img-fluid-for-post" controls>
                      <source src={elem.filename}></source>
                    </video>
                  </div>
                  <p style={{ whiteSpace: 'pre-line' }}>{elem.title}</p>
                </Carousel.Item>
              );
            }
          })}
        </Carousel>
      </div>
      <PostFooter postId={_id} />
    </div>
  );
};
